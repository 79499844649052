import { DEBUG_ENABLED } from "@jamango/generated";
import type { nullish } from "@jamango/helpers";

export type LogLevel = "debug" | "info" | "warn" | "error";

const LOG_LEVEL_ORDER: Record<LogLevel, number> = {
	debug: 0,
	info: 1,
	warn: 2,
	error: 3,
};

const JAMANGO_LOG_LEVEL = DEBUG_ENABLED ? "debug" : "info";

export const logImpl = {
	stdout: console.log,
	stderr: console.error,
};

const LOG_LEVEL_COLORS: Record<LogLevel, string> = {
	debug: "\x1b[36m", // cyan
	info: "\x1b[32m", // green
	warn: "\x1b[43m", // yellow background
	error: "\x1b[41m", // red background
};

const RESET_COLOR = "\x1b[0m";

function createLogFn(name: string | nullish, level: LogLevel) {
	if (LOG_LEVEL_ORDER[level] < LOG_LEVEL_ORDER[JAMANGO_LOG_LEVEL]) return () => {};

	const log = level === "error" ? logImpl.stderr : logImpl.stdout;

	function format(args: unknown[]) {
		const fileNameFormatted = name ? `[${name}]: ` : "";
		const color = LOG_LEVEL_COLORS[level];

		return (
			color +
			fileNameFormatted +
			args
				.map((v) => {
					if (typeof v === "object") {
						if (v instanceof Error) return v.stack;

						return JSON.stringify(v);
					}

					return v;
				})
				.join(" ") +
			RESET_COLOR
		);
	}

	if (DEBUG_ENABLED && typeof window !== "undefined") {
		return (...args: unknown[]) => {
			console.groupCollapsed(format(args));
			console.trace();
			console.groupEnd();
		};
	}

	return (...args: unknown[]) => {
		log(format(args));
	};
}

export type Logger = ReturnType<typeof createLogger>;

export const createLogger = (name?: string | nullish) => {
	return {
		debug: createLogFn(name, "debug"),
		info: createLogFn(name, "info"),
		warn: createLogFn(name, "warn"),
		error: createLogFn(name, "error"),
	} as const;
};

export const bigScaryConsoleWarning = () => {
	console.log(
		"%cVERY VERY BIG WARNING: If a stranger tells you to copy and paste some dubious code here...DO NOT LISTEN!!!!",
		`color: #f00; font-weight: bold; font-size: 48px;`,
	);
};
