export class ItemBaseComponent {
	name = "base";

	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.def = {
			armoryMode: false,
			geometryHeight: o.geom?.height ?? 0,
			nameplateOffsetY: o.nameplateOffsetY ?? 0.2, // TODO: nameplate refactor - should this be a default value in util/nameplate.js?
		};

		this.state = {
			used: false,
		};
	}
}
