import type { z } from "zod";
import type { BlockOptionsSchema, BlockSchema, BlockScriptAttachmentSchema } from "../validations/block";
import type { IParsedBlockTexture } from "./block-textures";
import type { IColorString } from "./general";

export type BlockScriptAttachment = z.infer<typeof BlockScriptAttachmentSchema>;

export type IBlock = z.infer<typeof BlockSchema>;

export type IParsedBlock = Omit<IBlock, "material"> & {
	material: {
		[key in BlockMaterialSide]?: IColorString | IParsedBlockTexture | null;
	};
};

export const BlockMaterialSide = {
	DEFAULT: "default",
	NX: "nx",
	NY: "ny",
	NZ: "nz",
	PX: "px",
	PY: "py",
	PZ: "pz",
} as const;

export type BlockMaterialSide = (typeof BlockMaterialSide)[keyof typeof BlockMaterialSide];

export const BlockCollision = {
	ENABLED: "enabled",
	DISABLED: "disabled",
} as const;

export type BlockCollision = (typeof BlockCollision)[keyof typeof BlockCollision];

export const BlockTransparency = {
	OPAQUE: "opaque",
	TRANSPARENT: "transparent",
	TRANSLUCENT: "translucent",
} as const;

export type BlockTransparency = (typeof BlockTransparency)[keyof typeof BlockTransparency];

export const BlockTransparencyBehaviour = {
	SINGLE: "single",
	JOIN: "join",
} as const;

export type BlockTransparencyBehaviour =
	(typeof BlockTransparencyBehaviour)[keyof typeof BlockTransparencyBehaviour];

export type IBlockOptions = z.infer<typeof BlockOptionsSchema>;

export type IMaterial = {
	default?: string | null;
	nx?: string | null;
	ny?: string | null;
	nz?: string | null;
	px?: string | null;
	py?: string | null;
	pz?: string | null;
};
