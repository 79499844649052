import { LoopRepeat } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { angleDistance } from "base/util/math/Math.ts";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack";

export class LocomotionStateIdle extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Idle", animMesh, locomotionInput, transitions);

		this.transitionToJoggingTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToTurningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.0;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToFlyingIdleTime = 0.2;

		this.idleTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Idle", this);
	}

	startCompute(transitionData) {
		this.idleTrack.action.reset();
		this.idleTrack.action.loop = LoopRepeat;
		this.idleTrack.switchOn();

		if (transitionData.prvState) {
			const walkAction = this.mesh.getAction("JMGO_Unarmed_Jog_Forward");
			if (walkAction) {
				this.idleTrack.action.syncWith(walkAction);
			}

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.idleTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.idleTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.idleTrack.updateCurrentWeight();
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.item) {
			outTransitionToState.ptr = map.get("WeaponsIdle");
			outTransitionToState.blendTime = this.transitionToDeathTime;
			return true;
		}

		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("CrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (this.inputState.rotateToCamera) {
			//transition to turning state
			const shortestDistance = angleDistance(
				this.inputState.cameraRotation,
				this.inputState.meshRotation,
			);
			const turningLeft = map.get("TurningLeft");
			const turningRight = map.get("TurningRight");
			if (shortestDistance > 0 && turningLeft.state !== "transitionOut") {
				outTransitionToState.ptr = turningLeft;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
			if (shortestDistance < 0 && turningRight.state !== "transitionOut") {
				outTransitionToState.ptr = turningRight;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
		}

		// transition to jogging state
		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Running");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("Jogging");
				outTransitionToState.blendTime = this.transitionToWalkingTime;
			}

			return true;
		}
		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		return false;
	}
}
