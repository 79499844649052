import { netState } from "router/Parallelogram";

export function proximityUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const state = entity.proximity.state;
	const target = entity.world.client.camera.target;

	if (target === entity) {
		state.camDistance = 0;
		return;
	}

	state.camDistance = entity.position.distanceTo(target.position);

	const threshold = entity.proximity.def.threshold;
	const inProximity = state.camDistance < threshold;

	if (threshold > 0 && inProximity !== state.inCamProximity) {
		state.inCamProximity = inProximity;

		if (inProximity) entity.onProximityEnter({ distance: state.camDistance });
		else entity.onProximityLeave({ distance: state.camDistance });
	}
}

/**
 * @param {import("base/world/entity/Entity").Entity} entity
 * @returns {entity is import("base/world/entity/types").EntityWithComponents<'proximity'>}
 */
function canUpdateSystem(entity) {
	return netState.isClient && entity.proximity !== undefined;
}
