import { node } from "base/rete/Types";
import { NODE_TYPE_ID } from "base/rete/Constants";
import type { Character } from "base/world/entity/Character";
import { getEntity } from "../modules/validate";
import { entityIsCharacter } from "../../world/entity/component/Type";

const type = NODE_TYPE_ID.function.character;

export const CharacterNodes = [
	node({
		name: "Character Set Can Attack",
		description: "Set whether a character can attack or damage another entity",
		type,
		id: "0ee9e30e-56ae-4bb1-9b49-09765c9cdca9",
		inputs: {
			exec: { type: "exec" },
			attacker: { name: "Attacker", type: "entity" },
			victim: { name: "Victim", type: "entity" },
			canAttack: {
				name: "Can Attack",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: false },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const interactionSet = (inputs.attacker as Character).disabledInteractions.state.cantAttack;
			const interactedWith = inputs.victim.entityID;

			if (inputs.canAttack) {
				interactionSet.delete(interactedWith);
			} else {
				interactionSet.add(interactedWith);
			}
		},
	}),
	node({
		name: "Character Get Can Attack",
		description: "Get whether a character can attack or damage another entity",
		type,
		id: "c9eb7378-b864-4e02-9525-293ef64a0418",
		inputs: {
			attacker: { name: "Attacker", type: "entity" },
			victim: { name: "Victim", type: "entity" },
		},
		outputs: {
			canAttack: { name: "Can Attack", type: "boolean", control: "boolean" },
		},
		resolve(inputs) {
			return {
				canAttack: !(inputs.attacker as Character).disabledInteractions.state.cantAttack.has(
					inputs.victim.entityID,
				),
			};
		},
	}),
	node({
		name: "Character Set Camera",
		description: "Sets the camera for a character using spherical coordinates",
		type,
		id: "15179beb-b8b5-49a4-996d-e47bef5a6123",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Character", type: "entity" },
			phi: { name: "Phi", type: "number", config: { defaultValue: Math.PI / 2 } },
			theta: { name: "Theta", type: "number", config: { defaultValue: 0 } },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entityIsCharacter(entity)) return;

			entity.setCamera(inputs.phi, inputs.theta);
		},
	}),
];
