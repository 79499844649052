import type { AssetType, IWorldBundle } from "../../lib/types";
import { assertNever, deepEqual } from "@jamango/helpers";
import { getAssetType } from "../../lib/helpers/general";
import type { JacyContentState } from "../JacyContentState";

type State = IWorldBundle;

export type WorldBundlePatch =
	| { type: "add" | "update"; pk: string; value: any }
	| { type: "remove"; pk: string };

export function getWorldBundlePatches(currentState: State, nextState: State): WorldBundlePatch[] {
	const oldAssets = currentState.assets;
	const newAssets = nextState.assets;

	const patches: WorldBundlePatch[] = [];

	//
	// Check for added or updated assets
	//
	for (const [key, value] of Object.entries(newAssets)) {
		const oldValue = oldAssets[key as keyof typeof oldAssets];

		// single asset type
		if ("type" in value && "pk" in value) {
			if (oldValue === undefined) {
				patches.push({ type: "add", pk: value.pk as string, value });
			} else if (!deepEqual(value, oldValue)) {
				patches.push({ type: "update", pk: value.pk as string, value });
			}
		}
		// map of assets: Record<AssetPK, Asset>
		else {
			if (oldValue === undefined) {
				Object.entries(value).forEach(([pk, asset]) => {
					patches.push({ type: "add", pk, value: asset });
				});
			} else {
				for (const [pk, asset] of Object.entries(value)) {
					const oldAsset = oldValue[pk as keyof typeof oldValue] as any;
					if (oldAsset === undefined) {
						patches.push({ type: "add", pk, value: asset });
					} else if (!deepEqual(asset, oldAsset)) {
						patches.push({ type: "update", pk, value: asset });
					}
				}
			}
		}
	}

	//
	// Check for removed assets
	//
	for (const [key, oldValue] of Object.entries(oldAssets)) {
		const newValue = newAssets[key as keyof typeof newAssets];

		if (newValue === undefined) {
			patches.push({ type: "remove", pk: oldValue.pk as string });
		}
		// single asset type
		else if ("type" in oldValue) {
			continue;
		}
		// map of assets: Record<AssetPK, Asset>
		else {
			for (const [pk] of Object.entries(oldValue)) {
				const newAsset = newValue[pk as keyof typeof newValue];
				if (newAsset === undefined) {
					patches.push({ type: "remove", pk });
				}
			}
		}
	}

	return patches;
}

export function applyWorldBundlePatches(currentState: State, patches: WorldBundlePatch[]): State {
	const newState = structuredClone(currentState);

	for (const patch of patches) {
		const pk = patch.pk;
		const assetType = getAssetType(pk) as AssetType;

		switch (patch.type) {
			case "add":
			case "update": {
				switch (assetType) {
					case "worldData":
						newState.assets.worldData = patch.value;
						break;
					case "map":
						newState.assets.map = patch.value;
						break;
					case "settings":
						newState.assets.settings = patch.value;
						break;
					case "gameMechanics":
						newState.assets.gameMechanics = patch.value;
						break;
					case "customLoader":
						newState.assets.customLoader = patch.value;
						break;
					case "thumbnail":
						newState.assets.thumbnail = patch.value;
						break;
					case "skybox":
						newState.assets.skyboxes ||= {};
						newState.assets.skyboxes[pk] = patch.value;
						break;
					case "environmentPreset":
						newState.assets.environmentPresets ||= {};
						newState.assets.environmentPresets[pk] = patch.value;
						break;
					case "blockTexture":
						newState.assets.blockTextures ||= {};
						newState.assets.blockTextures[pk] = patch.value;
						break;
					case "block":
						newState.assets.blocks ||= {};
						newState.assets.blocks[pk] = patch.value;
						break;
					case "audio":
						newState.assets.audios ||= {};
						newState.assets.audios[pk] = patch.value;
						break;
					case "avatar":
						newState.assets.avatars ||= {};
						newState.assets.avatars[pk] = patch.value;
						break;
					case "character":
						newState.assets.characters ||= {};
						newState.assets.characters[pk] = patch.value;
						break;
					case "resource":
						newState.assets.resources ||= {};
						newState.assets.resources[pk] = patch.value;
						break;
					case "script":
						newState.assets.scripts ||= {};
						newState.assets.scripts[pk] = patch.value;
						break;
					case "customUI":
						newState.assets.customUI ||= {};
						newState.assets.customUI[pk] = patch.value;
						break;
					case "particleSystem":
						newState.assets.particleSystems ||= {};
						newState.assets.particleSystems[pk] = patch.value;
						break;
					case "avatarComponent":
						newState.assets.avatarComponents ||= {};
						newState.assets.avatarComponents[pk] = patch.value;
						break;
					case "blockSoundsPack":
						newState.assets.blockSoundsPacks ||= {};
						newState.assets.blockSoundsPacks[pk] = patch.value;
						break;
					case "blockStructure":
						newState.assets.blockStructures ||= {};
						newState.assets.blockStructures[pk] = patch.value;
						break;
					case "packageData":
						break;
					case "prop":
						newState.assets.props ||= {};
						newState.assets.props[pk] = patch.value;
						break;
					case "item":
						newState.assets.items ||= {};
						newState.assets.items[pk] = patch.value;
						break;
					default:
						assertNever(assetType);
				}
				break;
			}
			case "remove": {
				switch (assetType) {
					case "thumbnail":
						delete newState.assets.thumbnail;
						break;
					case "skybox":
						if (newState.assets.skyboxes) {
							delete newState.assets.skyboxes[pk];
						}
						break;
					case "environmentPreset":
						if (newState.assets.environmentPresets) {
							delete newState.assets.environmentPresets[pk];
						}
						break;
					case "blockTexture":
						if (newState.assets.blockTextures) {
							delete newState.assets.blockTextures[pk];
						}
						break;
					case "block":
						if (newState.assets.blocks) {
							delete newState.assets.blocks[pk];
						}
						break;
					case "audio":
						if (newState.assets.audios) {
							delete newState.assets.audios[pk];
						}
						break;
					case "avatar":
						if (newState.assets.avatars) {
							delete newState.assets.avatars[pk];
						}
						break;
					case "avatarComponent":
						if (newState.assets.avatarComponents) {
							delete newState.assets.avatarComponents[pk];
						}
						break;
					case "character":
						if (newState.assets.characters) {
							delete newState.assets.characters[pk];
						}
						break;
					case "resource":
						if (newState.assets.resources) {
							delete newState.assets.resources[pk];
						}
						break;
					case "script":
						if (newState.assets.scripts) {
							delete newState.assets.scripts[pk];
						}
						break;
					case "customUI":
						if (newState.assets.customUI) {
							delete newState.assets.customUI[pk];
						}
						break;
					case "particleSystem":
						if (newState.assets.particleSystems) {
							delete newState.assets.particleSystems[pk];
						}
						break;
					case "blockSoundsPack":
						if (newState.assets.blockSoundsPacks) {
							delete newState.assets.blockSoundsPacks[pk];
						}
						break;
					case "blockStructure":
						if (newState.assets.blockStructures) {
							delete newState.assets.blockStructures[pk];
						}
						break;
					case "prop":
						if (newState.assets.props) {
							delete newState.assets.props[pk];
						}
						break;
					case "item":
						if (newState.assets.items) {
							delete newState.assets.items[pk];
						}
						break;
					// These assets can't be removed
					case "customLoader":
					case "map":
					case "settings":
					case "gameMechanics":
					case "worldData":
					case "packageData":
						break;
					default:
						assertNever(assetType);
				}
				break;
			}
		}
	}

	return newState;
}

const ASSET_TYPE_PATCHING_ORDER: AssetType[] = ["resource", "audio", "avatar", "blockTexture"];

export function patchJacyState(state: JacyContentState, patches: WorldBundlePatch[]) {
	const groups = {} as Record<AssetType, WorldBundlePatch[]>;

	patches.forEach((patch) => {
		const assetType = getAssetType(patch.pk) as AssetType;

		groups[assetType] ||= [];
		groups[assetType].push(patch);
	});

	ASSET_TYPE_PATCHING_ORDER.forEach((assetType) => {
		if (!groups[assetType]) return;

		groups[assetType].forEach((patch) => applyPatchToState(state, patch));
		delete groups[assetType];
	});

	Object.values(groups).forEach((group) => {
		group.forEach((patch) => applyPatchToState(state, patch));
	});
}

function applyPatchToState(state: JacyContentState, patch: WorldBundlePatch) {
	const pk = patch.pk;
	const assetType = getAssetType(pk) as AssetType;

	switch (patch.type) {
		case "add":
		case "update":
			switch (assetType) {
				case "worldData":
					state.worldData.set(patch.value);
					break;
				case "map":
					// Ignore changes for map asset
					break;
				case "settings":
					state.settings.import(patch.value);
					break;
				case "gameMechanics":
					state.gameMechanics.import(patch.value);
					break;
				case "customLoader":
					state.customLoader.import(patch.value);
					break;
				case "thumbnail":
					state.thumbnail = patch.value;
					break;
				case "skybox":
					state.skybox.set(patch.value);
					break;
				case "environmentPreset":
					state.environmentPreset.set(patch.value);
					//TODO handle USE/UNUSE
					break;
				case "blockTexture":
					state.blockTextures.set(patch.value);
					break;
				case "block":
					state.blocks.set(patch.value);
					break;
				case "audio":
					state.audios.set(patch.value);
					break;
				case "avatar":
					state.avatars.set(patch.value);
					break;
				case "character":
					state.characters.set(patch.value);
					break;
				case "resource":
					state.resources.addResource(patch.value);
					break;
				case "script":
					state.scripts.set(patch.value);
					break;
				case "customUI":
					state.customUI.set(patch.value);
					break;
				case "blockStructure":
					state.blockStructures.set(patch.value);
					break;
				case "prop":
					state.props.set(patch.value);
					break;
				case "item":
					state.items.set(patch.value);
					break;
				case "packageData":
				case "particleSystem":
				case "avatarComponent":
				case "blockSoundsPack":
					break;
				default:
					assertNever(assetType);
			}
			break;
		case "remove":
			switch (assetType) {
				case "thumbnail":
					state.thumbnail.clearThumbnail();
					break;
				case "skybox":
					state.environmentPreset.delete(pk);
					break;
				case "environmentPreset":
					state.environmentPreset.delete(pk);
					break;
				case "blockTexture":
					state.blockTextures.delete(pk);
					break;
				case "block":
					state.blocks.delete(pk);
					break;
				case "audio":
					state.audios.delete(pk);
					break;
				case "avatar":
					state.avatars.delete(pk);
					break;
				case "character":
					state.characters.delete(pk);
					break;
				case "resource":
					state.resources.remove(pk);
					break;
				case "script":
					state.scripts.delete(pk);
					break;
				case "customUI":
					state.customUI.delete(pk);
					break;
				case "blockStructure":
					state.blockStructures.delete(pk);
					break;
				case "prop":
					state.props.delete(pk);
					break;
				case "item":
					state.items.delete(pk);
					break;
				// These assets can't be removed
				case "map":
				case "settings":
				case "gameMechanics":
				case "customLoader":
				case "worldData":
				case "packageData":
				case "particleSystem":
				case "avatarComponent":
				case "blockSoundsPack":
					break;
				default:
					assertNever(assetType);
			}
			break;
	}
}
