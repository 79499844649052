//new runtime name: original glb name
//should see at least 2 of every glb name in here

export const ANIM_COPIES = {
	JMGO_Pencil_AO_CC_pose: `JMGO_Spade_AO_CC_pose`,
	JMGO_Pencil_Idle: `JMGO_Spade_Idle`,
	JMGO_Pencil_Walk_Backward: `JMGO_Spade_Walk_Backward`,
	JMGO_Pencil_Walk_Forward: `JMGO_Spade_Walk_Forward`,
	JMGO_Pencil_Walk_Left: `JMGO_Spade_Walk_Left`,
	JMGO_Pencil_Walk_Right: `JMGO_Spade_Walk_Right`,
	JMGO_Pencil_Jog_Backward: `JMGO_Spade_Jog_Backward`,
	JMGO_Pencil_Jog_Forward: `JMGO_Spade_Jog_Forward`,
	JMGO_Pencil_Jog_Left: `JMGO_Spade_Jog_Left`,
	JMGO_Pencil_Jog_Right: `JMGO_Spade_Jog_Right`,
	JMGO_Pencil_Sprint: `JMGO_Spade_Sprint`,
	JMGO_Pencil_Crouch_Backward: `JMGO_Spade_Crouch_Backward`,
	JMGO_Pencil_Crouch_Entry: `JMGO_Spade_Crouch_Entry`,
	JMGO_Pencil_Crouch_Exit: `JMGO_Spade_Crouch_Exit`,
	JMGO_Pencil_Crouch_Forward: `JMGO_Spade_Crouch_Forward`,
	JMGO_Pencil_Crouch_Idle: `JMGO_Spade_Crouch_Idle`,
	JMGO_Pencil_Crouch_Left: `JMGO_Spade_Crouch_Left`,
	JMGO_Pencil_Crouch_Right: `JMGO_Spade_Crouch_Right`,
	JMGO_Pencil_Jump_Fall_Forward: `JMGO_Spade_Jump_Fall_Forward`,
	JMGO_Pencil_Jump_Fall_Left: `JMGO_Spade_Jump_Fall_Left`,
	JMGO_Pencil_Jump_Kickoff_Static_Forward: `JMGO_Spade_Jump_Kickoff_Static_Forward`,
	JMGO_Pencil_Jump_Kickoff_Static_Left: `JMGO_Spade_Jump_Kickoff_Static_Left`,
	JMGO_Pencil_Jump_Land_Jog_Backward: `JMGO_Spade_Jump_Land_Jog_Backward`,
	JMGO_Pencil_Jump_Land_Jog_Forward: `JMGO_Spade_Jump_Land_Jog_Forward`,
	JMGO_Pencil_Jump_Land_Jog_Left: `JMGO_Spade_Jump_Land_Jog_Left`,
	JMGO_Pencil_Jump_Land_Jog_Right: `JMGO_Spade_Jump_Land_Jog_Right`,
	JMGO_Pencil_Jump_Land_Static_Forward: `JMGO_Spade_Jump_Land_Static_Forward`,
	JMGO_Pencil_Jump_Land_Static_Left: `JMGO_Spade_Jump_Land_Static_Left`,
	JMGO_Pencil_Turn_Crouch_Left: `JMGO_Spade_Turn_Crouch_Left`,
	JMGO_Pencil_Turn_Crouch_Right: `JMGO_Spade_Turn_Crouch_Right`,
	JMGO_Pencil_Turn_Stand_Left: `JMGO_Spade_Turn_Stand_Left`,
	JMGO_Pencil_Turn_Stand_Right: `JMGO_Spade_Turn_Stand_Right`,
	JMGO_Pencil_Shoot_Forward: `JMGO_Spade_Shoot_Forward`,
	JMGO_Pencil_Shoot_Left: `JMGO_Spade_Shoot_Left`,

	JMGO_Wrench_AO_CC_pose: `JMGO_Spade_AO_CC_pose`,
	JMGO_Wrench_Idle: `JMGO_Spade_Idle`,
	JMGO_Wrench_Walk_Backward: `JMGO_Spade_Walk_Backward`,
	JMGO_Wrench_Walk_Forward: `JMGO_Spade_Walk_Forward`,
	JMGO_Wrench_Walk_Left: `JMGO_Spade_Walk_Left`,
	JMGO_Wrench_Walk_Right: `JMGO_Spade_Walk_Right`,
	JMGO_Wrench_Jog_Backward: `JMGO_Spade_Jog_Backward`,
	JMGO_Wrench_Jog_Forward: `JMGO_Spade_Jog_Forward`,
	JMGO_Wrench_Jog_Left: `JMGO_Spade_Jog_Left`,
	JMGO_Wrench_Jog_Right: `JMGO_Spade_Jog_Right`,
	JMGO_Wrench_Sprint: `JMGO_Spade_Sprint`,
	JMGO_Wrench_Crouch_Backward: `JMGO_Spade_Crouch_Backward`,
	JMGO_Wrench_Crouch_Entry: `JMGO_Spade_Crouch_Entry`,
	JMGO_Wrench_Crouch_Exit: `JMGO_Spade_Crouch_Exit`,
	JMGO_Wrench_Crouch_Forward: `JMGO_Spade_Crouch_Forward`,
	JMGO_Wrench_Crouch_Idle: `JMGO_Spade_Crouch_Idle`,
	JMGO_Wrench_Crouch_Left: `JMGO_Spade_Crouch_Left`,
	JMGO_Wrench_Crouch_Right: `JMGO_Spade_Crouch_Right`,
	JMGO_Wrench_Jump_Fall_Forward: `JMGO_Spade_Jump_Fall_Forward`,
	JMGO_Wrench_Jump_Fall_Left: `JMGO_Spade_Jump_Fall_Left`,
	JMGO_Wrench_Jump_Kickoff_Static_Forward: `JMGO_Spade_Jump_Kickoff_Static_Forward`,
	JMGO_Wrench_Jump_Kickoff_Static_Left: `JMGO_Spade_Jump_Kickoff_Static_Left`,
	JMGO_Wrench_Jump_Land_Jog_Backward: `JMGO_Spade_Jump_Land_Jog_Backward`,
	JMGO_Wrench_Jump_Land_Jog_Forward: `JMGO_Spade_Jump_Land_Jog_Forward`,
	JMGO_Wrench_Jump_Land_Jog_Left: `JMGO_Spade_Jump_Land_Jog_Left`,
	JMGO_Wrench_Jump_Land_Jog_Right: `JMGO_Spade_Jump_Land_Jog_Right`,
	JMGO_Wrench_Jump_Land_Static_Forward: `JMGO_Spade_Jump_Land_Static_Forward`,
	JMGO_Wrench_Jump_Land_Static_Left: `JMGO_Spade_Jump_Land_Static_Left`,
	JMGO_Wrench_Turn_Crouch_Left: `JMGO_Spade_Turn_Crouch_Left`,
	JMGO_Wrench_Turn_Crouch_Right: `JMGO_Spade_Turn_Crouch_Right`,
	JMGO_Wrench_Turn_Stand_Left: `JMGO_Spade_Turn_Stand_Left`,
	JMGO_Wrench_Turn_Stand_Right: `JMGO_Spade_Turn_Stand_Right`,
	JMGO_Wrench_Shoot_Forward: `JMGO_Spade_Shoot_Forward`,
	JMGO_Wrench_Shoot_Left: `JMGO_Spade_Shoot_Left`,

	JMGO_NovaGun_AO_CC_pose: `JMGO_ZapperPistol_AO_CC_pose`,
	JMGO_NovaGun_Idle: `JMGO_ZapperPistol_Idle`,
	JMGO_NovaGun_Walk_Forward: `JMGO_ZapperPistol_Walk_Forward`,
	JMGO_NovaGun_Walk_Backward: `JMGO_ZapperPistol_Walk_Backward`,
	JMGO_NovaGun_Walk_Left: `JMGO_ZapperPistol_Walk_Left`,
	JMGO_NovaGun_Walk_Right: `JMGO_ZapperPistol_Walk_Right`,
	JMGO_NovaGun_Jog_Forward: `JMGO_ZapperPistol_Jog_Forward`,
	JMGO_NovaGun_Jog_Backward: `JMGO_ZapperPistol_Jog_Backward`,
	JMGO_NovaGun_Jog_Left: `JMGO_ZapperPistol_Jog_Left`,
	JMGO_NovaGun_Jog_Right: `JMGO_ZapperPistol_Jog_Right`,
	JMGO_NovaGun_Sprint: `JMGO_ZapperPistol_Sprint`,
	JMGO_NovaGun_Crouch_Entry: `JMGO_ZapperPistol_Crouch_Entry`,
	JMGO_NovaGun_Crouch_Exit: `JMGO_ZapperPistol_Crouch_Exit`,
	JMGO_NovaGun_Crouch_Idle: `JMGO_ZapperPistol_Crouch_Idle`,
	JMGO_NovaGun_Crouch_Forward: `JMGO_ZapperPistol_Crouch_Forward`,
	JMGO_NovaGun_Crouch_Backward: `JMGO_ZapperPistol_Crouch_Backward`,
	JMGO_NovaGun_Crouch_Left: `JMGO_ZapperPistol_Crouch_Left`,
	JMGO_NovaGun_Crouch_Right: `JMGO_ZapperPistol_Crouch_Right`,
	JMGO_NovaGun_Jump_Fall_Forward: `JMGO_ZapperPistol_Jump_Fall_Forward`,
	JMGO_NovaGun_Jump_Fall_Left: `JMGO_ZapperPistol_Jump_Fall_Left`,
	JMGO_NovaGun_Jump_Kickoff_Static_Forward: `JMGO_ZapperPistol_Jump_Kickoff_Static_Forward`,
	JMGO_NovaGun_Jump_Kickoff_Static_Left: `JMGO_ZapperPistol_Jump_Kickoff_Static_Left`,
	JMGO_NovaGun_Jump_Land_Jog_Backward: `JMGO_ZapperPistol_Jump_Land_Jog_Backward`,
	JMGO_NovaGun_Jump_Land_Jog_Forward: `JMGO_ZapperPistol_Jump_Land_Jog_Forward`,
	JMGO_NovaGun_Jump_Land_Jog_Left: `JMGO_ZapperPistol_Jump_Land_Jog_Left`,
	JMGO_NovaGun_Jump_Land_Jog_Right: `JMGO_ZapperPistol_Jump_Land_Jog_Right`,
	JMGO_NovaGun_Jump_Land_Static_Forward: `JMGO_ZapperPistol_Jump_Land_Static_Forward`,
	JMGO_NovaGun_Jump_Land_Static_Left: `JMGO_ZapperPistol_Jump_Land_Static_Left`,
	JMGO_NovaGun_Turn_Crouch_Left: `JMGO_ZapperPistol_Turn_Crouch_Left`,
	JMGO_NovaGun_Turn_Crouch_Right: `JMGO_ZapperPistol_Turn_Crouch_Right`,
	JMGO_NovaGun_Turn_Stand_Left: `JMGO_ZapperPistol_Turn_Stand_Left`,
	JMGO_NovaGun_Turn_Stand_Right: `JMGO_ZapperPistol_Turn_Stand_Right`,
	JMGO_NovaGun_Shoot_Forward: `JMGO_ZapperPistol_Shoot_Forward`,
	JMGO_NovaGun_Shoot_Left: `JMGO_ZapperPistol_Shoot_Left`,

	JMGO_NovaRifle_AO_CC_pose: `JMGO_ZapperAssault_AO_CC_pose`,
	JMGO_NovaRifle_Idle: `JMGO_ZapperAssault_Idle`,
	JMGO_NovaRifle_Walk_Backward: `JMGO_ZapperAssault_Walk_Backward`,
	JMGO_NovaRifle_Walk_Forward: `JMGO_ZapperAssault_Walk_Forward`,
	JMGO_NovaRifle_Walk_Left: `JMGO_ZapperAssault_Walk_Left`,
	JMGO_NovaRifle_Walk_Right: `JMGO_ZapperAssault_Walk_Right`,
	JMGO_NovaRifle_Jog_Backward: `JMGO_ZapperAssault_Jog_Backward`,
	JMGO_NovaRifle_Jog_Forward: `JMGO_ZapperAssault_Jog_Forward`,
	JMGO_NovaRifle_Jog_Left: `JMGO_ZapperAssault_Jog_Left`,
	JMGO_NovaRifle_Jog_Right: `JMGO_ZapperAssault_Jog_Right`,
	JMGO_NovaRifle_Sprint: `JMGO_ZapperAssault_Sprint`,
	JMGO_NovaRifle_Crouch_Backward: `JMGO_ZapperAssault_Crouch_Backward`,
	JMGO_NovaRifle_Crouch_Entry: `JMGO_ZapperAssault_Crouch_Entry`,
	JMGO_NovaRifle_Crouch_Exit: `JMGO_ZapperAssault_Crouch_Exit`,
	JMGO_NovaRifle_Crouch_Forward: `JMGO_ZapperAssault_Crouch_Forward`,
	JMGO_NovaRifle_Crouch_Idle: `JMGO_ZapperAssault_Crouch_Idle`,
	JMGO_NovaRifle_Crouch_Left: `JMGO_ZapperAssault_Crouch_Left`,
	JMGO_NovaRifle_Crouch_Right: `JMGO_ZapperAssault_Crouch_Right`,
	JMGO_NovaRifle_Jump_Fall_Forward: `JMGO_ZapperAssault_Jump_Fall_Forward`,
	JMGO_NovaRifle_Jump_Fall_Left: `JMGO_ZapperAssault_Jump_Fall_Left`,
	JMGO_NovaRifle_Jump_Kickoff_Static_Forward: `JMGO_ZapperAssault_Jump_Kickoff_Static_Forward`,
	JMGO_NovaRifle_Jump_Kickoff_Static_Left: `JMGO_ZapperAssault_Jump_Kickoff_Static_Left`,
	JMGO_NovaRifle_Jump_Land_Jog_Backward: `JMGO_ZapperAssault_Jump_Land_Jog_Backward`,
	JMGO_NovaRifle_Jump_Land_Jog_Forward: `JMGO_ZapperAssault_Jump_Land_Jog_Forward`,
	JMGO_NovaRifle_Jump_Land_Jog_Left: `JMGO_ZapperAssault_Jump_Land_Jog_Left`,
	JMGO_NovaRifle_Jump_Land_Jog_Right: `JMGO_ZapperAssault_Jump_Land_Jog_Right`,
	JMGO_NovaRifle_Jump_Land_Static_Forward: `JMGO_ZapperAssault_Jump_Land_Static_Forward`,
	JMGO_NovaRifle_Jump_Land_Static_Left: `JMGO_ZapperAssault_Jump_Land_Static_Left`,
	JMGO_NovaRifle_Turn_Crouch_Left: `JMGO_ZapperAssault_Turn_Crouch_Left`,
	JMGO_NovaRifle_Turn_Crouch_Right: `JMGO_ZapperAssault_Turn_Crouch_Right`,
	JMGO_NovaRifle_Turn_Stand_Left: `JMGO_ZapperAssault_Turn_Stand_Left`,
	JMGO_NovaRifle_Turn_Stand_Right: `JMGO_ZapperAssault_Turn_Stand_Right`,

	JMGO_ZapperRifle_AO_CC_pose: `JMGO_ZapperAssault_AO_CC_pose`,
	JMGO_ZapperRifle_Idle: `JMGO_ZapperAssault_Idle`,
	JMGO_ZapperRifle_Walk_Backward: `JMGO_ZapperAssault_Walk_Backward`,
	JMGO_ZapperRifle_Walk_Forward: `JMGO_ZapperAssault_Walk_Forward`,
	JMGO_ZapperRifle_Walk_Left: `JMGO_ZapperAssault_Walk_Left`,
	JMGO_ZapperRifle_Walk_Right: `JMGO_ZapperAssault_Walk_Right`,
	JMGO_ZapperRifle_Jog_Backward: `JMGO_ZapperAssault_Jog_Backward`,
	JMGO_ZapperRifle_Jog_Forward: `JMGO_ZapperAssault_Jog_Forward`,
	JMGO_ZapperRifle_Jog_Left: `JMGO_ZapperAssault_Jog_Left`,
	JMGO_ZapperRifle_Jog_Right: `JMGO_ZapperAssault_Jog_Right`,
	JMGO_ZapperRifle_Sprint: `JMGO_ZapperAssault_Sprint`,
	JMGO_ZapperRifle_Crouch_Backward: `JMGO_ZapperAssault_Crouch_Backward`,
	JMGO_ZapperRifle_Crouch_Entry: `JMGO_ZapperAssault_Crouch_Entry`,
	JMGO_ZapperRifle_Crouch_Exit: `JMGO_ZapperAssault_Crouch_Exit`,
	JMGO_ZapperRifle_Crouch_Forward: `JMGO_ZapperAssault_Crouch_Forward`,
	JMGO_ZapperRifle_Crouch_Idle: `JMGO_ZapperAssault_Crouch_Idle`,
	JMGO_ZapperRifle_Crouch_Left: `JMGO_ZapperAssault_Crouch_Left`,
	JMGO_ZapperRifle_Crouch_Right: `JMGO_ZapperAssault_Crouch_Right`,
	JMGO_ZapperRifle_Jump_Fall_Forward: `JMGO_ZapperAssault_Jump_Fall_Forward`,
	JMGO_ZapperRifle_Jump_Fall_Left: `JMGO_ZapperAssault_Jump_Fall_Left`,
	JMGO_ZapperRifle_Jump_Kickoff_Static_Forward: `JMGO_ZapperAssault_Jump_Kickoff_Static_Forward`,
	JMGO_ZapperRifle_Jump_Kickoff_Static_Left: `JMGO_ZapperAssault_Jump_Kickoff_Static_Left`,
	JMGO_ZapperRifle_Jump_Land_Jog_Backward: `JMGO_ZapperAssault_Jump_Land_Jog_Backward`,
	JMGO_ZapperRifle_Jump_Land_Jog_Forward: `JMGO_ZapperAssault_Jump_Land_Jog_Forward`,
	JMGO_ZapperRifle_Jump_Land_Jog_Left: `JMGO_ZapperAssault_Jump_Land_Jog_Left`,
	JMGO_ZapperRifle_Jump_Land_Jog_Right: `JMGO_ZapperAssault_Jump_Land_Jog_Right`,
	JMGO_ZapperRifle_Jump_Land_Static_Forward: `JMGO_ZapperAssault_Jump_Land_Static_Forward`,
	JMGO_ZapperRifle_Jump_Land_Static_Left: `JMGO_ZapperAssault_Jump_Land_Static_Left`,
	JMGO_ZapperRifle_Turn_Crouch_Left: `JMGO_ZapperAssault_Turn_Crouch_Left`,
	JMGO_ZapperRifle_Turn_Crouch_Right: `JMGO_ZapperAssault_Turn_Crouch_Right`,
	JMGO_ZapperRifle_Turn_Stand_Left: `JMGO_ZapperAssault_Turn_Stand_Left`,
	JMGO_ZapperRifle_Turn_Stand_Right: `JMGO_ZapperAssault_Turn_Stand_Right`,
	JMGO_ZapperRifle_Reload: "JMGO_ZapperAssault_Reload",
	JMGO_ZapperRifle_Shoot_Forward: "JMGO_NovaRifle_Shoot_Forward",

	//
	// First Person
	//

	JMGO_Pencil_FP_NotAim_Idle: `JMGO_Spade_FP_NotAim_Idle`,
	JMGO_Pencil_FP_NotAim_Jump: `JMGO_Spade_FP_NotAim_Jump`,
	JMGO_Pencil_FP_NotAim_Shoot: `JMGO_Spade_FP_NotAim_Shoot`,
	JMGO_Pencil_FP_NotAim_Sprint: `JMGO_Spade_FP_NotAim_Sprint`,
	JMGO_Pencil_FP_NotAim_Walk: `JMGO_Spade_FP_NotAim_Walk`,

	JMGO_Wrench_FP_NotAim_Idle: `JMGO_Spade_FP_NotAim_Idle`,
	JMGO_Wrench_FP_NotAim_Jump: `JMGO_Spade_FP_NotAim_Jump`,
	JMGO_Wrench_FP_NotAim_Shoot: `JMGO_Spade_FP_NotAim_Shoot`,
	JMGO_Wrench_FP_NotAim_Sprint: `JMGO_Spade_FP_NotAim_Sprint`,
	JMGO_Wrench_FP_NotAim_Walk: `JMGO_Spade_FP_NotAim_Walk`,

	JMGO_NovaGun_FP_Aim_Idle: `JMGO_ZapperPistol_FP_Aim_Idle`,
	JMGO_NovaGun_FP_Aim_Jump: `JMGO_ZapperPistol_FP_Aim_Jump`,
	JMGO_NovaGun_FP_Aim_Shoot: `JMGO_ZapperPistol_FP_Aim_Shoot`,
	JMGO_NovaGun_FP_Aim_Walk: `JMGO_ZapperPistol_FP_Aim_Walk`,
	JMGO_NovaGun_FP_NotAim_Idle: `JMGO_ZapperPistol_FP_NotAim_Idle`,
	JMGO_NovaGun_FP_NotAim_Jump: `JMGO_ZapperPistol_FP_NotAim_Jump`,
	JMGO_NovaGun_FP_NotAim_Shoot: `JMGO_ZapperPistol_FP_NotAim_Shoot`,
	JMGO_NovaGun_FP_NotAim_Sprint: `JMGO_ZapperPistol_FP_NotAim_Sprint`,
	JMGO_NovaGun_FP_NotAim_Walk: `JMGO_ZapperPistol_FP_NotAim_Walk`,

	JMGO_NovaRifle_FP_Aim_Idle: `JMGO_ZapperAssault_FP_Aim_Idle`,
	JMGO_NovaRifle_FP_Aim_Jump: `JMGO_ZapperAssault_FP_Aim_Jump`,
	JMGO_NovaRifle_FP_Aim_Walk: `JMGO_ZapperAssault_FP_Aim_Walk`,
	JMGO_NovaRifle_FP_NotAim_Idle: `JMGO_ZapperAssault_FP_NotAim_Idle`,
	JMGO_NovaRifle_FP_NotAim_Jump: `JMGO_ZapperAssault_FP_NotAim_Jump`,
	JMGO_NovaRifle_FP_NotAim_Sprint: `JMGO_ZapperAssault_FP_NotAim_Sprint`,
	JMGO_NovaRifle_FP_NotAim_Walk: `JMGO_ZapperAssault_FP_NotAim_Walk`,

	JMGO_ZapperRifle_FP_Aim_Idle: `JMGO_ZapperAssault_FP_Aim_Idle`,
	JMGO_ZapperRifle_FP_Aim_Jump: `JMGO_ZapperAssault_FP_Aim_Jump`,
	JMGO_ZapperRifle_FP_Aim_Walk: `JMGO_ZapperAssault_FP_Aim_Walk`,
	JMGO_ZapperRifle_FP_NotAim_Idle: `JMGO_ZapperAssault_FP_NotAim_Idle`,
	JMGO_ZapperRifle_FP_NotAim_Jump: `JMGO_ZapperAssault_FP_NotAim_Jump`,
	JMGO_ZapperRifle_FP_NotAim_Sprint: `JMGO_ZapperAssault_FP_NotAim_Sprint`,
	JMGO_ZapperRifle_FP_NotAim_Walk: `JMGO_ZapperAssault_FP_NotAim_Walk`,
	JMGO_ZapperRifle_FP_NotAim_Reload: `JMGO_ZapperAssault_FP_NotAim_Reload`,

	JMGO_ZapperRifle_FP_Aim_Shoot: `JMGO_NovaRifle_FP_Aim_Shoot`,
	JMGO_ZapperRifle_FP_NotAim_Shoot: `JMGO_NovaRifle_FP_NotAim_Shoot`,
};
