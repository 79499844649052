import type { z } from "zod";
import type { ScriptSchema } from "../validations/scripts";

//@ts-ignore
import type { World } from "@jamango/engine/Runtime/base/world/World.js";
import type { NODE_TYPE } from "@jamango/engine/Runtime/base/rete/Constants.ts";
import type { Entity } from "@jamango/engine/Runtime/base/world/entity/Entity.js";

export enum ScriptType {
	UTILITY = 0,
	WORLD = 1,
}

export const SOCKET = {
	exec: "exec",
	number: "number",
	boolean: "boolean",
	string: "string",
	vector3: "vector3",
	euler: "euler",
	quaternion: "quaternion",
	entity: "entity",
	blockGroup: "blockGroup",
	sound: "sound",
	any: "any",
	object: "object",
	physicsConstraint: "physicsConstraint",
} as const;

export const SOCKET_NAMES = {
	exec: "Exec",
	number: "Number",
	boolean: "Boolean",
	string: "String",
	vector3: "Vector3",
	euler: "Euler",
	quaternion: "Quaternion",
	entity: "Entity",
	blockGroup: "Block Group",
	sound: "Sound",
	any: "Any",
	object: "Object",
	physicsConstraint: "Physics Constraint",
} satisfies Record<keyof SocketTypes, string>;

export const SOCKET_TYPE = {
	primitive: "primitive",
	list: "list",
	any: "any",
};

export const NODE_INPUT_DYNAMIC_EVENT = {
	add: "add",
	remove: "remove",
	update: "update",
};

export const CONTROL = {
	number: "number",
	string: "string",
	boolean: "boolean",
	vector3: "vector3",
	euler: "euler",
	quaternion: "quaternion",
	blockGroup: "blockGroup",
	propSceneTreeNode: "propSceneTreeNode",
	characterSceneTreeNode: "characterSceneTreeNode",
	select: "select", // multiple string
	checkboxGroup: "checkboxGroup", // multiple boolean
	color: "color", // color picker
	textarea: "textarea", // text area for messages
};

export const CONTROL_DEFAULT_VALUES = {
	[CONTROL.number]: 0,
	[CONTROL.string]: "",
	[CONTROL.boolean]: false,
	[CONTROL.vector3]: { x: 0, y: 0, z: 0 },
	[CONTROL.euler]: { x: 0, y: 0, z: 0, order: "XYZ" },
	[CONTROL.quaternion]: { x: 0, y: 0, z: 0, w: 1 },
	[CONTROL.select]: null,
	[CONTROL.checkboxGroup]: [],
	[CONTROL.color]: "#000000",
	[CONTROL.textarea]: "",
};

export type IDataType = keyof typeof SOCKET;
export type IDataStructure = keyof typeof SOCKET_TYPE;

export type IDefId = string;

export type AnyControlDef = SocketDef<"C", keyof SocketTypes>;
export type AnyInputDef = SocketDef<"I", keyof SocketTypes>;
export type AnyOutputDef = SocketDef<"O", keyof SocketTypes>;

export type AnyControlsDefs = { [key: string]: AnyControlDef };
export type AnyInputsDefs = { [key: string]: AnyInputDef };
export type AnyOutputsDefs = { [key: string]: AnyOutputDef };

export type AnyNodeDef = NodeUIDef<CategoryDef, AnyControlsDefs, AnyInputsDefs, AnyOutputsDefs> & {
	// added by NodeDefRegistry while parsing, from NODE_TYPE
	style: {
		title: string;
		border: string;
		indicator: string;
		icon: string;
		folder: string;
	};
	folder: string;
	expanded: boolean;
	priority: number;
	isMissing?: boolean;
};

export type Node = {
	id: string;
	defId: string;
	position: [number, number];
	collapsed?: boolean;
	data: Record<string, any>;
	def?: Partial<AnyNodeDef>;
	parentId?: string;
	custom?: boolean;
};

export type NodeConnection = {
	id: string;
	source: string;
	sourceOutput: "exec" | string;
	target: string;
	targetInput: "exec" | string;
};

export type Comment = {
	id: string;
	type: "inline";
	text: string;
	position: [number, number];
	width: number;
	height: number;
	parentId?: string;
};

export type Frame = {
	id: string;
	height: number;
	width: number;
	position: [number, number];
};

export type Events = {
	nodes: Record<string, Node>;
	connections: NodeConnection[];
	frames: Record<string, Frame>;
	comments: Record<string, Comment>;
};

export type IScript = z.infer<typeof ScriptSchema>;

type OverrideDefaultIcon = "MapPin" | "Angle" | "Speedometer";

export type SelectOption = { value: string | number | undefined; label: string };

type Options = SelectOption[];

export type SelectOptionsContext = { world: World };
export type SelectOptions = Options | ((ctx: SelectOptionsContext) => Options);

export type IODef = "C" | "I" | "O";
export type CategoryDef = keyof typeof NODE_TYPE;

export type SocketTypes = {
	exec: never;
	string: string;
	number: number;
	boolean: boolean;
	entity: Entity;
	vector3: { x: number; y: number; z: number };
	euler: { x: number; y: number; z: number; order: string };
	quaternion: { x: number; y: number; z: number; w: number };
	object: object; //really not ideal to have this as a type
	sound: string;
	blockGroup: string;
	physicsConstraint: number;
	any: SocketTypes[Exclude<keyof SocketTypes, "any">];
};

export type StructureDef = "list" | "any" | "primitive" | undefined;

export type SocketDef<IO extends IODef, SocketType extends keyof SocketTypes> = {
	type: SocketType;
	structure?: SocketType extends "exec" ? never : StructureDef;
	optional?: SocketType extends "exec" ? never : IO extends "O" ? never : boolean;

	name?: string;
	control?: keyof typeof CONTROL;

	icon?: OverrideDefaultIcon;
	removable?: boolean;
	dynamic?: boolean;
	hidden?: boolean;
	index?: number;
	reorderable?: boolean;
} & (IO extends "O"
	? {
			config?: {
				collapsible?: boolean;
			};
		}
	: // narrow types based on 'type'
		{
			optional?: boolean;

			config?: {
				defaultValue?: any;

				scalar?: boolean;

				explicitSortOptions?: SelectOptions;
				autoSortOptions?: SelectOptions;
				options?: Options;

				validation?: Array<{
					condition: (value: any) => boolean;
					message: (value: any) => string;
				}>;
				onChange?: (e: { value: any; node: any; editor: any; world: any }) => void;
			};
		} & (
			| {
					type: keyof SocketTypes;
			  }
			| {
					type: "exec";
			  }
			| {
					type: "string" | "number";
					control: "select";
					config: {
						defaultValue?: unknown;
						explicitSortOptions?: SelectOptions;
						autoSortOptions?: SelectOptions;
					};
			  }
			| { type: "string"; control: "string"; config?: { defaultValue?: string } }
			| {
					type: "string";
					control: "checkboxGroup";
					structure: "list";
					config?: { options: Options; defaultValue?: string[] };
			  }
			| { type: "number"; control: "number"; config?: { defaultValue?: number } }
			| { type: "boolean"; control: "boolean"; config?: { defaultValue?: boolean } }
			| { type: "vector3"; control: "vector3"; config?: { scalar?: boolean } }
			| { type: "entity" | "object" | "any" }
		)); //no ui

export type SocketsDef<IO extends IODef> = { [internalName: string]: SocketDef<IO, keyof SocketTypes> };

export type OptionalSocketsDef<IO extends IODef> = SocketsDef<IO> | undefined;

export type NodeUIDef<
	Category extends CategoryDef,
	C extends OptionalSocketsDef<"C">,
	I extends OptionalSocketsDef<"I">,
	O extends OptionalSocketsDef<"O">,
> = {
	id: string;
	name: string;
	description: string;
	info?: string[];
	warning?: string[];
	keywords?: string[];
	predictable?: boolean;
	experimental?: boolean;
	deprecated?: boolean;
	type: Category;

	custom?: boolean;
	locked?: boolean;

	resizable?: boolean;
	menu?: boolean;

	height?: number;
	width?: number;

	dynamic?:
		| boolean
		| {
				addText?: string;
				controls?: AnyControlsDefs;
				inputs?: AnyInputsDefs;
				outputs?: AnyOutputsDefs;
				add?: (
					index: number,
					def: NodeUIDef<Category, C, I, O>,
					data: { [key: string]: unknown },
				) => {
					controls?: AnyControlsDefs;
					inputs?: AnyInputsDefs;
					outputs?: AnyOutputsDefs;
				};
		  };

	controls?: C;
	inputs?: I;
	outputs?: O;
};
