import * as THREE from "three";
import { sweep } from "./Sweep.js";

/**
 * @typedef {{
 *   solid: (pos: THREE.Vector3Like) => boolean
 * }} SearchWorld
 */

/**
 * @template T
 */
class PriorityQueue {
	/**
	 * @type {{ element: T, priority: number[] }[]}
	 */
	heap = [];

	/**
	 * @param {T} element
	 * @param {number} priority
	 */
	enqueue(element, priority) {
		this.heap.push({ element, priority });
		this.heapifyUp(this.heap.length - 1);
	}

	/**
	 * @returns {T | undefined}
	 */
	dequeue() {
		if (this.heap.length === 0) {
			return undefined;
		}

		const root = this.heap[0];
		const lastElement = this.heap.pop();

		if (this.heap.length > 0) {
			this.heap[0] = lastElement;
			this.heapifyDown(0);
		}

		return root.element;
	}

	/**
	 * @param {number} index
	 */
	heapifyUp(index) {
		const parentIndex = Math.floor((index - 1) / 2);

		if (parentIndex >= 0 && this.heap[parentIndex].priority > this.heap[index].priority) {
			this.swap(index, parentIndex);
			this.heapifyUp(parentIndex);
		}
	}

	/**
	 * @param {number} index
	 */
	heapifyDown(index) {
		const leftChildIndex = 2 * index + 1;
		const rightChildIndex = 2 * index + 2;
		let smallestIndex = index;

		if (
			leftChildIndex < this.heap.length &&
			this.heap[leftChildIndex].priority < this.heap[smallestIndex].priority
		) {
			smallestIndex = leftChildIndex;
		}

		if (
			rightChildIndex < this.heap.length &&
			this.heap[rightChildIndex].priority < this.heap[smallestIndex].priority
		) {
			smallestIndex = rightChildIndex;
		}

		if (smallestIndex !== index) {
			this.swap(index, smallestIndex);
			this.heapifyDown(smallestIndex);
		}
	}

	/**
	 * @param {number} i
	 * @param {number} j
	 * @returns {void}
	 */
	swap(i, j) {
		const temp = this.heap[i];
		this.heap[i] = this.heap[j];
		this.heap[j] = temp;
	}
}

/**
 * @param {SearchWorld} world
 * @param {number} height
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @returns {boolean}
 */
const canGoThrough = (world, height, x, y, z) => {
	for (let h = 0; h < height; h++) {
		if (world.solid({ x, y: y + h, z })) {
			return false;
		}
	}
	return true;
};

/**
 * @param {SearchWorld} world
 * @param {number} height
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @returns {boolean}
 */
const canStepAt = (world, height, x, y, z) => {
	if (!world.solid({ x, y: y - 1, z })) {
		return false;
	}

	return canGoThrough(world, height, x, y, z);
};

/**
 * @type {THREE.Vector3[]}
 */
const directions = [
	new THREE.Vector3(-1, 0, 0),
	new THREE.Vector3(-1, 1, 0),
	new THREE.Vector3(-1, -1, 0),
	new THREE.Vector3(1, 0, 0),
	new THREE.Vector3(1, 1, 0),
	new THREE.Vector3(1, -1, 0),
	new THREE.Vector3(0, 0, -1),
	new THREE.Vector3(0, 1, -1),
	new THREE.Vector3(0, -1, -1),
	new THREE.Vector3(0, 0, 1),
	new THREE.Vector3(0, 1, 1),
	new THREE.Vector3(0, -1, 1),
];

/**
 * @typedef {{
 *   cost: number;
 *   direction: THREE.Vector3;
 *   newPosition: THREE.Vector3;
 *   jump: boolean;
 *   drop: boolean
 * }} Action
 */

const _actionDirection = new THREE.Vector3();

const agentHeight = 3;

/**
 * @param {SearchWorld} world
 * @param {THREE.Vector3Like} position
 * @returns
 */
const getActions = (world, position) => {
	/**
	 * @type {Action[]}
	 */
	const actions = [];

	for (const direction of directions) {
		_actionDirection.copy(position).add(direction);
		const x = _actionDirection.x;
		const y = _actionDirection.y;
		const z = _actionDirection.z;

		if (canStepAt(world, agentHeight, x, y, z)) {
			const cost = 1;

			// todo: vertical movement cost?
			// const cost = direction.y === 0 ? 1 : 2

			const jump = direction.y === 1;
			const drop = direction.y === -1;

			actions.push({
				direction,
				newPosition: _actionDirection.clone(),
				cost,
				jump,
				drop,
			});
		}
	}

	return actions;
};

/**
 * @typedef {{
 *   position: THREE.Vector3
 *   action?: Action
 *   parent: Node | null
 *   g: number
 *   h: number
 *   f: number
 *}} Node
 */

const _heuristicStart = new THREE.Vector3();
const _heuristicGoal = new THREE.Vector3();

/**
 * @param {THREE.Vector3Like} start
 * @param {THREE.Vector3Like} goal
 * @param {Action | null} action
 * @returns
 */
const heuristic = (start, goal, _action) => {
	// TODO: tweak heuristic! :)

	_heuristicStart.copy(start);
	_heuristicGoal.copy(goal);

	return _heuristicStart.distanceTo(_heuristicGoal);
};

/**
 * @param {THREE.Vector3Like} position
 * @returns {string}
 */
const vector3ToString = (position) => {
	return `${position.x},${position.y},${position.z}`;
};

/**
 * @typedef {'greedy' | 'shortest'} SearchType
 */

/**
 * @typedef {{
 *   world: SearchWorld
 *   start: THREE.Vector3
 *   goal: THREE.Vector3
 *   searchType: SearchType
 *   earlyExit?: ComputePathEarlyExit
 * }} FindPathProps
 */

/**
 * @typedef {{
 *   success: boolean
 *   path: Node[]
 *   iterations: number
 *   explored: Map<string, Node>
 * }}
 */

/**
 *
 * @param {FindPathProps} props
 * @returns {FindPathResult}
 */
const findPath = ({ world, start, goal, searchType, earlyExit }) => {
	/**
	 * @type {PriorityQueue<Node>}
	 */
	const frontier = new PriorityQueue();

	/**
	 * @type {Map<string, Node>}
	 */
	const explored = new Map();

	/**
	 * @type {Node}
	 */
	const initialNode = {
		position: start,
		parent: null,
		g: 0,
		h: heuristic(start, goal, null),
		f: heuristic(start, goal, null),
	};
	frontier.enqueue(initialNode, initialNode.f);

	let iterations = 0;

	const fail = () => {
		return { success: false, path: [], iterations, explored };
	};

	/**
	 * @param {Node[]} path
	 * @returns {FindPathResult}
	 */
	const succeed = (path) => {
		return { success: true, path, iterations, explored };
	};

	while (frontier.heap.length > 0) {
		if (earlyExit && iterations >= earlyExit.searchIterations) return fail();
		iterations++;

		const currentNode = frontier.dequeue();

		if (currentNode.position.equals(goal)) {
			/**
			 * @type {Node[]}
			 */
			const path = [];
			let current = currentNode;
			while (current !== null) {
				path.unshift(current);
				current = current.parent;
			}

			return succeed(path);
		}

		explored.set(vector3ToString(currentNode.position), currentNode);

		const nodeActions = getActions(world, currentNode.position);

		for (const action of nodeActions) {
			const existingNode = explored.get(vector3ToString(action.newPosition));

			if (existingNode) continue;

			const g = currentNode.g + action.cost;
			const h = heuristic(action.newPosition, goal, action);
			const f = searchType === "greedy" ? h : g + h;

			// todo: f as a parameter
			// shortest path
			// const f = g + h
			// greedy best-first search
			// const f = h

			const openNode = frontier.heap.find((item) => item.element.position.equals(action.newPosition));

			if (!openNode || f < openNode.element.f) {
				/**
				 * @type {Node}
				 */
				const newNode = {
					position: action.newPosition,
					parent: currentNode,
					g,
					h,
					f,
					action,
				};

				if (openNode) {
					const openNodeIndex = frontier.heap.indexOf(openNode);
					frontier.heap.splice(openNodeIndex, 1);
				}

				frontier.enqueue(newNode, f);
			}
		}
	}

	return fail();
};

/**
 * computes the diagonal path from (0,0) to (x,z)
 * @param {number} x
 * @param {number} z
 * @returns {THREE.Vector3[]}
 */
const computeDiagonal = (x, z) => {
	/**
	 * @type {THREE.Vector3[]}
	 */
	const result = [];
	result.push(new THREE.Vector3(0, 0, 0));

	/**
	 * @param {number} x
	 * @param {number} y
	 * @param {number} z
	 * @returns {boolean}
	 */
	const addToPath = (x, y, z) => {
		result.push(new THREE.Vector3(x, y, z));
		return true;
	};

	const min = [0, 0, 0];
	const max = [1, 1, 1];
	const delta = [x, 0, z];
	const impacts = [0, 0, 0];

	sweep(min, max, delta, impacts, addToPath);

	return result;
};

const kSweepDistance = 16;

/**
 * @type {THREE.Vector3[][]}
 */
const kSweeps = [];

for (let z = 0; z < kSweepDistance; z++) {
	for (let x = 0; x < kSweepDistance; x++) {
		kSweeps.push(computeDiagonal(x, z));
	}
}

/**
 * @param {SearchWorld} world
 * @param {THREE.Vector3} source
 * @param {THREE.Vector3} target
 * @returns {boolean}
 */
const hasDirectPath = (world, source, target) => {
	if (source.y < target.y) return false;

	const sx = source.x;
	const sz = source.z;
	const dx = target.x - sx;
	const dz = target.z - sz;
	const ax = Math.abs(dx);
	const az = Math.abs(dz);
	if (ax >= kSweepDistance || az >= kSweepDistance) return false;

	const index = ax + az * kSweepDistance;
	const sweep = kSweeps[index];
	const limit = sweep.length - 1;

	let y = source.y;
	for (let i = 1; i < limit; i++) {
		const p = sweep[i];
		const x = dx > 0 ? sx + p.x : sx - p.x;
		const z = dz > 0 ? sz + p.z : sz - p.z;

		if (!canStepAt(world, agentHeight, x, y, z)) return false;
		while (y >= target.y && canStepAt(world, agentHeight, x, y - 1, z)) y--;

		if (y < target.y) return false;
	}

	return true;
};

/**
 * @param {SearchWorld} world
 * @param {Node[]} pathNodes
 * @returns {Node[]}
 */
const smoothPath = (world, pathNodes) => {
	const smoothedPath = [pathNodes[0]];

	for (let i = 2; i < pathNodes.length; i++) {
		const prev = smoothedPath[smoothedPath.length - 1];
		const next = pathNodes[i];

		if (!prev.action?.jump && !next.action?.jump && hasDirectPath(world, prev.position, next.position))
			continue;

		smoothedPath.push(pathNodes[i - 1]);
	}

	if (pathNodes.length > 1) smoothedPath.push(pathNodes[pathNodes.length - 1]);

	return smoothedPath;
};

/**
 * @typedef {{
 *   world: SearchWorld
 *   start: THREE.Vector3
 *   goal: THREE.Vector3
 *   smooth?: boolean
 *   searchType: SearchType
 *   earlyExit?: { searchIterations: number }
 *   keepIntermediates?: boolean
 * }} ComputePathProps
 */

/**
 * @typedef {{
 *   success: boolean
 *   path: Node[]
 *   intermediates?: {
 *     explored: Map<string, Node>
 *     iterations: number
 *   }
 * }} ComputePathResult
 */

/**
 * @param {ComputePathProps} props
 * @returns {ComputePathResult}
 */
export const computePath = ({
	world,
	start,
	goal,
	smooth = true,
	searchType,
	earlyExit,
	keepIntermediates = false,
}) => {
	const { success, path, iterations, explored } = findPath({
		world,
		start,
		goal,
		searchType,
		earlyExit,
	});

	const intermediates = keepIntermediates ? { explored, iterations } : undefined;

	if (!success) return { success: false, path: [], intermediates };

	const resultPath = smooth ? smoothPath(world, path) : path;

	return { success, path: resultPath, intermediates };
};
