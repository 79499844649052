import { generateUUID, type ChunkScene } from "@jamango/content-client";
import { err, ok } from "@jamango/helpers";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as BlockGroups from "base/world/block/BlockGroups";
import type { World } from "base/world/World";
import * as InteractLabelsClient from "client/world/fx/InteractLabels";
import * as SelectorVfxClient from "client/world/fx/SelectorVfx";
import { NET_SERVER, type Peer } from "@jamango/ibs";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";
import { UI } from "client/dom/UI";

const _position = new Vector3();
const _spawn = new Vector3();

export const update = (state: BlockGroups.BlockGroupsState, world: World) => {
	const anyDirty =
		state.dirtyGroupIds.size > 0 || state.dirtyPositions.size() > 0 || state.allPositionsDirty;

	// patch clients
	if (netState.isHost) {
		if (anyDirty) {
			const patch = createPatch(state);

			if (Object.keys(patch).length > 0) {
				blockGroupsPatchCommand.sendToAll(patch);
			}
		}
	}

	if (netState.isClient && anyDirty) {
		const changedGroups = new Set<string>();

		if (state.allPositionsDirty) {
			for (const groupId of state.groups.keys()) {
				changedGroups.add(groupId);
			}
		} else {
			for (const id of state.dirtyGroupIds.keys()) {
				changedGroups.add(id);
			}

			for (const _ of state.dirtyPositions.keys(_position)) {
				const groupIds = BlockGroups.getIdsAtPosition(state, _position.x, _position.y, _position.z);

				if (groupIds) {
					for (const id of groupIds) {
						changedGroups.add(id);
					}
				}
			}
		}

		for (const id of changedGroups) {
			BlockGroups.updateIslands(state, world, id);
			SelectorVfxClient.markBlockGroupDirty(world.client!.selectorVfx, id);
			InteractLabelsClient.markGroupDirty(world.client!.interactLabels, id);
		}

		UI.state.editor().refreshEngine();
	}

	// reset dirty states
	state.dirtyGroupIds.clear();
	state.dirtyPositions.clear();
	state.allPositionsDirty = false;
};

type BlockGroupsPatch = {
	groups?: {
		[id: string]: {
			name: string | null;
			scripts: BlockGroups.BlockGroupScriptAttachment[];
		};
	};
	blocks?: [number, number, number, string[] | null][];
};

const createPatch = (state: BlockGroups.BlockGroupsState) => {
	const patch: BlockGroupsPatch = {};

	if (state.dirtyGroupIds.size > 0) {
		patch.groups = {};

		for (const [id, group] of state.groups.entries()) {
			patch.groups[id] = {
				name: group.name,
				scripts: group.scripts,
			};
		}
	}

	if (state.allPositionsDirty) {
		patch.blocks = [];

		for (const blockIds of state.blocks.entries(_position)) {
			patch.blocks.push([_position.x, _position.y, _position.z, blockIds]);
		}
	} else if (state.dirtyPositions.size() > 0) {
		const blocks: BlockGroupsPatch["blocks"] = [];

		for (const _ of state.dirtyPositions.keys(_position)) {
			blocks.push([
				_position.x,
				_position.y,
				_position.z,
				BlockGroups.getIdsAtPosition(state, _position.x, _position.y, _position.z),
			]);
		}

		patch.blocks = blocks;
	}

	return patch;
};

const blockGroupsPatchCommand = Net.command(
	"block groups patch",
	Net.CLIENT,
	(patch: BlockGroupsPatch, world) => {
		if (patch.groups) {
			const unseenGroups = new Set<string>(world.blockGroups.groups.keys());

			for (const id in patch.groups) {
				const { name, scripts } = patch.groups[id];

				const group = world.blockGroups.groups.get(id);

				if (group) {
					BlockGroups.setName(world.blockGroups, id, name);
					BlockGroups.setScripts(world.blockGroups, world.rete, id, scripts);
				} else {
					BlockGroups.create(world.blockGroups, world.rete, id, name, scripts);
				}

				unseenGroups.delete(id);
			}

			for (const id of unseenGroups) {
				BlockGroups.deleteGroup(world.blockGroups, world.rete, id);
			}
		}

		if (patch.blocks) {
			for (const [x, y, z, groupIds] of patch.blocks) {
				if (groupIds) {
					BlockGroups.setAtPosition(world.blockGroups, groupIds, x, y, z);
				} else {
					BlockGroups.clearAtPosition(world.blockGroups, x, y, z);
				}
			}
		}
	},
);

export const onBuild = (
	state: BlockGroups.BlockGroupsState,
	world: World,
	x: number,
	y: number,
	z: number,
) => {
	const groupIds = BlockGroups.getIdsAtPosition(state, x, y, z);

	if (!groupIds) return;

	for (const id of groupIds) {
		if (netState.isClient) {
			InteractLabelsClient.markGroupDirty(world.client!.interactLabels, id);
		}
	}
};

type CreateRequest = {
	name: string | null;
	id?: string;
	blocks?: [number, number, number][];
	scripts?: BlockGroups.BlockGroupScriptAttachment[];
};

export const create = Net.rpc("block group create", ({ id, name, blocks, scripts }: CreateRequest, world, peer) => {
	if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

	const blockGroupId = id ?? generateUUID();

	if (name) {
		const existing = BlockGroups.getByName(world.blockGroups, name);

		if (existing) {
			return err({ message: "A block group with that name already exists.", existingId: existing.id });
		}
	}

	BlockGroups.create(world.blockGroups, world.rete, blockGroupId, name);

	if (blocks) {
		for (const [x, y, z] of blocks) {
			BlockGroups.addBlock(world.blockGroups, blockGroupId, x, y, z);
		}
	}

	if (scripts) {
		BlockGroups.setScripts(world.blockGroups, world.rete, blockGroupId, scripts);
	}

	return ok({ id: blockGroupId });
});

type SetNameRequest = {
	id: string;
	name: string | null;
};

export const setName = Net.rpc("block group set name", ({ id, name }: SetNameRequest, world, peer) => {
	if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

	BlockGroups.setName(world.blockGroups, id, name);
});

type SetScriptsRequest = {
	id: string;
	scripts: BlockGroups.BlockGroupScriptAttachment[];
};

export const setScripts = Net.rpc(
	"block group set scripts",
	({ id, scripts }: SetScriptsRequest, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		BlockGroups.setScripts(world.blockGroups, world.rete, id, scripts);
	},
);

export const deleteGroup = Net.rpc("block group delete", (id: string, world, peer) => {
	if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

	BlockGroups.deleteGroup(world.blockGroups, world.rete, id);
});

type SetBlockGroupsAtPositionRequest = {
	ids: null | string[];
	x: number;
	y: number;
	z: number;
};

export const setBlockGroupsAtPosition = Net.rpc(
	"block group set at position",
	({ ids, x, y, z }: SetBlockGroupsAtPositionRequest, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		BlockGroups.setAtPosition(world.blockGroups, ids ?? undefined, x, y, z);
	},
);

type AddBlocksRequest = {
	id: string;
	blocks: [number, number, number][];
};

export const addBlocks = Net.rpc(
	"block group add blocks",
	({ id, blocks }: AddBlocksRequest, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		for (const [x, y, z] of blocks) {
			BlockGroups.addBlock(world.blockGroups, id, x, y, z);
		}
	},
);

type RemoveBlocksRequest = {
	id: string;
	blocks: [number, number, number][];
};

export const removeBlocks = Net.rpc(
	"block group remove blocks",
	({ id, blocks }: RemoveBlocksRequest, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		for (const [x, y, z] of blocks) {
			BlockGroups.removeBlock(world.blockGroups, id, x, y, z);
		}
	},
);

export const clearAtPositions = Net.rpc(
	"block group clear positions",
	(positions: [number, number, number][], world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		for (const [x, y, z] of positions) {
			BlockGroups.clearAtPosition(world.blockGroups, x, y, z);
		}
	},
);

export const respawn = (blockGroups: BlockGroups.BlockGroupsState, scene: ChunkScene, groupId: string) => {
	if (!netState.isHost) return;

	BlockGroups.getSpawnPosition(blockGroups, groupId, _spawn);

	scene.dispatchBlockGroupEvent(
		NET_SERVER,
		{
			type: "spawn",
			container: scene,
			x: _spawn.x,
			y: _spawn.y,
			z: _spawn.z,
			mapLoad: false,
		},
		groupId,
	);
};

const hasWrenchPowers = (peer: Peer) => {
	return getPeerMetadata(peer).permissions.canUseWrench;
};

const NON_WRENCHY_MESSAGE = "This action requires wrench permissions";
