import { Vector3 } from "three";
import type { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import type { World } from "base/world/World";
import type { Projectile } from "base/world/ProjectileManager";
import type { Item } from "base/world/entity/Item";
import type { ParticleParameters } from "./ParticleEngine";
import type { Character } from "base/world/entity/Character";

const _particleOptionsVelocity = new Vector3();
const _hitNormalVelocity = new Vector3();
const _velocity = new Vector3();
let _id = 0;

export const createProjectileHitEffects = (
	world: World,
	projectile: Projectile,
	hitEntity: PhysicalEntity | null,
	hitType: "character" | null,
	critical: boolean,
	hitPosition: Vector3,
	hitNormal: Vector3,
) => {
	const particleEngine = world.client!.particleEngine;
	const sfxManager = world.sfxManager;
	const camera = world.client!.camera;

	const hitCharacter = hitType === "character";

	const projectileFiredByCameraTarget =
		projectile.shooterID === camera.target.entityID && camera.target.type.def.isCharacter;

	if (projectileFiredByCameraTarget && hitCharacter && !hitEntity?.isDead()) {
		const item = (camera.target as Character).getEquippedItem() as Item;
		if (item?.hitmarker) {
			item.hitmarker.state.hitmarkerTimer = item.hitmarker.def.hitmarkerTime;
		}
	}

	let particles;

	if (hitCharacter) {
		particles = projectile.def.onHitPlayerParticles;
	} else {
		particles = projectile.def.onHitParticles;
	}

	if (particles) {
		for (let i = 0; i < particles.length; i++) {
			const { velocityHitNormalIntensity, ...baseParticleOptions } = particles[i];

			const particleOptions: Partial<ParticleParameters> = {
				...baseParticleOptions,
				name: "projectile_hit_" + ++_id + "_" + i,
				position: hitPosition.toArray(),
			};

			if (velocityHitNormalIntensity && hitNormal) {
				const particleOptionsVelocity = _particleOptionsVelocity.set(0, 0, 0);

				if ("velocity" in particleOptions) {
					particleOptionsVelocity.add(
						_velocity.set(...(particleOptions.velocity as [number, number, number])),
					);
				}

				particleOptionsVelocity.add(
					_hitNormalVelocity.copy(hitNormal).multiplyScalar(velocityHitNormalIntensity),
				);

				particleOptions.velocity = particleOptionsVelocity.toArray();
			}

			particleEngine.add(particleOptions);
		}
	}

	let sound;

	if (hitCharacter) {
		if (critical) {
			sound = projectile.def.sound?.headShot ?? { asset: "snd-head-shot" };
		} else {
			sound = projectile.def.sound?.bodyShot ?? { asset: "snd-body-shot" };
		}
	} else {
		sound = projectile.def.sound?.impact ?? { asset: "snd-laser-impact" };
	}

	if (sound) {
		if (projectileFiredByCameraTarget && hitCharacter) {
			sfxManager.play(sound);
		} else {
			sfxManager.playAtPos({ ...sound, x: hitPosition.x, y: hitPosition.y, z: hitPosition.z });
		}
	}
};
