import { V0 } from "base/util/math/Math";
import { CharacterMovementMode } from "base/world/entity/component/CharacterMovement";
import { UI } from "client/dom/UI";
import { netState } from "router/Parallelogram";
import { MathUtils, Vector3 } from "three";

const _velocity = new Vector3();
const _horizontalVelocity = new Vector3();
const _speedScaledWishDirection = new Vector3();
const _velocityChange = new Vector3();
const _displacementActual = new Vector3();
const _redirectedVelocity = new Vector3();
const _gravityVelocity = new Vector3();

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterMovementVelocityUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	const horizontalMovement =
		entity.movement.state.inputDisplacement.x !== 0 || entity.movement.state.inputDisplacement.z !== 0;

	entity.movement.state.omnidirectionalAngle = horizontalMovement
		? Math.atan2(-entity.movement.state.inputDisplacement.z, entity.movement.state.inputDisplacement.x)
		: 0;

	if (horizontalMovement) {
		entity.movement.state.isIdle = false;
		if (netState.isClient) {
			UI.state.helpers().markMoveKnown();
		}
	}

	const maxSpeed = calculateMaxSpeed(entity);
	const velocityRequest = entity.movement.state.velocityRequest.copy(V0);

	const binaryMovement =
		entity.movement.state.isFlying || entity.movement.def.mode === CharacterMovementMode.BINARY;
	const kinematicMovement =
		!entity.movement.state.isFlying && entity.movement.def.mode === CharacterMovementMode.KINEMATIC;
	const dynamicMovement =
		!entity.movement.state.isFlying && entity.movement.def.mode === CharacterMovementMode.DYNAMIC;

	if (binaryMovement) {
		velocityRequest
			.copy(entity.movement.state.inputDisplacement)
			.applyQuaternion(entity.quaternion)
			.setLength(maxSpeed);

		entity.movement.state.wishSpeed = velocityRequest.length();
	} else if (kinematicMovement) {
		handleDisplacementDifference(entity, stepDeltaTime);

		// calculate wish direction and speed
		entity.movement.state.wishDirection
			.copy(entity.movement.state.inputDisplacement)
			.applyQuaternion(entity.quaternion)
			.normalize();

		entity.movement.state.wishSpeed = _speedScaledWishDirection
			.copy(entity.movement.state.wishDirection)
			.multiplyScalar(maxSpeed)
			.length();

		if (entity.collision.state.hitFoot) {
			calculateGroundVelocity(entity, stepDeltaTime);
		} else {
			calculateAirVelocity(entity, stepDeltaTime);
		}

		limitHorizontalVelocity(entity);

		// clamp movement velocity to zero if small
		if (entity.movement.state.velocity.lengthSq() < 0.0001) {
			entity.movement.state.velocity.set(0, 0, 0);
		}
	} else if (dynamicMovement) {
		_velocityChange
			.copy(entity.movement.state.inputDisplacement)
			.applyQuaternion(entity.quaternion)
			.setLength(maxSpeed)
			.multiplyScalar(stepDeltaTime);

		entity.movement.state.velocity.add(_velocityChange);
		entity.movement.state.wishSpeed = entity.movement.state.velocity.length();
	}

	if (!entity.movement.state.isFlying && entity.movement.def.mode !== CharacterMovementMode.DYNAMIC) {
		applyGravity(entity, stepDeltaTime);
	}

	// terminal velocity
	if (entity.movement.state.velocity.length() > entity.movement.def.terminalVelocity) {
		entity.movement.state.velocity.setLength(entity.movement.def.terminalVelocity);
	}

	velocityRequest.add(entity.movement.state.velocity);

	// update current speed
	entity.movement.state.speed = entity.movement.state.velocity.length();

	// update horizontal speed
	if (kinematicMovement) {
		const horizontalVelocity = _horizontalVelocity.set(
			entity.movement.state.velocity.x,
			0,
			entity.movement.state.velocity.z,
		);

		entity.movement.state.horizontalSpeed = horizontalVelocity.length();
	} else {
		entity.movement.state.horizontalSpeed = maxSpeed;
	}
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 */
function canUpdateSystem(entity) {
	return (
		entity.type.def.isCharacter &&
		!entity.mount.state.parent &&
		(!entity.world.editor.paused || entity.type.def.isPlayer) &&
		!entity.prophecy.state.isProphecy
	);
}

const BLOCKING_HIT_SLOW_SPEED_THRESHOLD = 5;
const BLOCKING_HIT_SMALL_DISPLACEMENT_REQUEST_THRESHOLD = 0.5;
const BLOCKING_HIT_MULTIPLIER = 0.05;
const BLOCKING_HIT_VELOCITY_REDUCTION = 0.01;

const _requestLen = new Vector3();

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 */
function handleDisplacementDifference(entity, deltaTime) {
	// early out if the entity is barely moving
	if (entity.movement.state.speed < 0.01) {
		return;
	}

	// dek: I made this test take into account delta time. otherwise, at larger steps, this logic would trigger (especially on dedi)
	// simply due to the fact that the cutoff for the BLOCKING_HIT_SMALL_DISPLACEMENT_REQUEST_THRESHOLD would be triggered more easily
	// TODO: jolt is passed prvVelocityRequest and a deltaTime, requestLen here is an approximation to fit the existing code.
	// this can probably be revisited, perhaps this logic could move to jolt contact callbacks to respond to contacts more directly.
	const requestLen = _requestLen
		.copy(entity.movement.state.prvVelocityRequest)
		.multiplyScalar(deltaTime)
		.length();
	const actualLen = entity.movement.state.prvDisplacementActual.length();

	const blockCutoff = BLOCKING_HIT_SMALL_DISPLACEMENT_REQUEST_THRESHOLD * deltaTime;
	const blockMultiplier = BLOCKING_HIT_MULTIPLIER * deltaTime;

	const isBlockingHit =
		entity.movement.state.speed > BLOCKING_HIT_SLOW_SPEED_THRESHOLD &&
		requestLen > blockCutoff &&
		actualLen <= requestLen * blockMultiplier;

	if (isBlockingHit) {
		const blockingHitVelocityFactor = BLOCKING_HIT_VELOCITY_REDUCTION * deltaTime;
		// quickly reduce velocity if the requested displacement could not be achieved, and there was minimal sliding
		// we do not limit vertical velocity here, otherwise jumping is prevented
		entity.movement.state.velocity.x *= blockingHitVelocityFactor;
		entity.movement.state.velocity.z *= blockingHitVelocityFactor;
		return;
	}

	if (entity.movement.state.isSliding) {
		// redirect velocity towards the actual displacement when sliding
		const velocityDirection = _velocity.copy(entity.movement.state.velocity).normalize();

		const displacementActualDirection = _displacementActual
			.copy(entity.movement.state.prvDisplacementActual)
			.normalize();

		const redirectLerpFactor = 6 * deltaTime;
		const redirectedVelocity = _redirectedVelocity
			.copy(velocityDirection)
			.lerp(displacementActualDirection, redirectLerpFactor);

		redirectedVelocity.setLength(entity.movement.state.velocity.length());

		entity.movement.state.velocity.copy(redirectedVelocity);
	}
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 */
export function calculateMaxSpeed(entity) {
	let speed;

	if (entity.movement.state.isFlying) {
		speed = entity.movement.def.flySpeed;
	} else if (entity.movement.state.isCrouching) {
		speed = entity.movement.def.crouchSpeed;
	} else if (!entity.collision.state.hitFoot) {
		speed = entity.movement.def.airSpeed;
	} else {
		speed = entity.movement.def.walkSpeed;
	}

	if (entity.movement.state.isIronSights) {
		speed *= entity.movement.def.sightsSpeedMultiplier;
	}

	if (entity.movement.state.isSprinting) {
		speed *= entity.movement.def.sprintSpeedMultiplier;
	}

	return speed;
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 * @param {number} currentSpeed
 * @param {number} acceleration
 * @param {number} friction
 */
function calculateAcceleration(entity, deltaTime, currentSpeed, acceleration, friction = 1) {
	const wishSpeed = entity.movement.state.wishSpeed;
	const wishDirection = entity.movement.state.wishDirection;

	const maxAcceleration = acceleration * friction * wishSpeed * deltaTime;

	const addSpeed = MathUtils.clamp(wishSpeed - currentSpeed, 0, maxAcceleration);

	_velocityChange.copy(wishDirection).setLength(addSpeed);

	entity.movement.state.velocity.add(_velocityChange);
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 * @param {number} friction
 * @param {number} stopSpeed
 */
function calculateFriction(entity, deltaTime, friction, stopSpeed = -Infinity) {
	const currentSpeed = entity.movement.state.speed;

	if (currentSpeed === 0) {
		return;
	}

	const control = currentSpeed < stopSpeed ? stopSpeed : currentSpeed;

	const drop = deltaTime * control * friction;

	let newSpeed = currentSpeed - drop;
	if (newSpeed < 0) newSpeed = 0;
	newSpeed /= currentSpeed;

	const horizontalVelocity = _horizontalVelocity.set(
		entity.movement.state.velocity.x,
		0,
		entity.movement.state.velocity.z,
	);
	horizontalVelocity.multiplyScalar(newSpeed);

	entity.movement.state.velocity.set(
		horizontalVelocity.x,
		entity.movement.state.velocity.y,
		horizontalVelocity.z,
	);
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 */
function calculateAirVelocity(entity, deltaTime) {
	const wishDirection = entity.movement.state.wishDirection;

	let friction = entity.movement.def.airFriction;

	if (wishDirection.lengthSq() === 0) {
		friction *= entity.movement.def.airFrictionStopMultiplier;
	}

	calculateFriction(entity, deltaTime, friction);

	const horizontalSpeedInWishDirection = _horizontalVelocity
		.set(entity.movement.state.velocity.x, 0, entity.movement.state.velocity.z)
		.dot(wishDirection);

	calculateAcceleration(
		entity,
		deltaTime,
		horizontalSpeedInWishDirection,
		entity.movement.def.airAcceleration,
	);
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 */
function calculateGroundVelocity(entity, deltaTime) {
	const wishDirection = entity.movement.state.wishDirection;

	let friction = entity.movement.def.groundFriction * entity.movement.state.friction;

	if (wishDirection.lengthSq() === 0) {
		friction *= entity.movement.def.groundFrictionStopMultiplier;
	}

	calculateFriction(entity, deltaTime, friction, entity.movement.def.groundStopSpeed);

	const horizontalVelocity = _horizontalVelocity.set(
		entity.movement.state.velocity.x,
		0,
		entity.movement.state.velocity.z,
	);

	const horizontalSpeed = horizontalVelocity.length();

	calculateAcceleration(
		entity,
		deltaTime,
		horizontalSpeed,
		entity.movement.def.groundAcceleration,
		entity.movement.state.friction,
	);
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 */
function limitHorizontalVelocity(entity) {
	const maxHorizontalSpeedMultiplier = entity.movement.def.maxHorizontalSpeedMultiplier;

	const maxHorizontalSpeed =
		entity.movement.def.walkSpeed *
		entity.movement.def.sprintSpeedMultiplier *
		maxHorizontalSpeedMultiplier;

	const horizontalVelocity = _horizontalVelocity.set(
		entity.movement.state.velocity.x,
		0,
		entity.movement.state.velocity.z,
	);

	if (horizontalVelocity.length() > maxHorizontalSpeed) {
		horizontalVelocity.setLength(maxHorizontalSpeed);
		entity.movement.state.velocity.x = horizontalVelocity.x;
		entity.movement.state.velocity.z = horizontalVelocity.z;
	}
}

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 */
function applyGravity(entity, deltaTime) {
	const gravityVelocity = _gravityVelocity.copy(entity.world.physics.gravity).multiplyScalar(deltaTime);

	if (entity.movement.state.coyoteTimer >= entity.movement.def.coyoteTime) {
		entity.movement.state.velocity.add(gravityVelocity);
	}
}
