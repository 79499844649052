import { type Object3D, type Scene } from "three";
import { BatchedRenderer, QuarksLoader, QuarksUtil, type ParticleSystem } from "three.quarks";
import type { IParticleSystemConfig } from "@jamango/content-client";

export type ParticleSystemConfiguration = IParticleSystemConfig & { configId: string };

function iterateParticleSystems(obj: any, callback: (system: ParticleSystem) => void) {
	obj.traverse((child: any) => {
		if (child.type === "ParticleEmitter") {
			callback((child as any).system);
		}
	});
	if (obj.type === "ParticleEmitter") {
		callback((obj as any).system);
	}
}

export class ParticleEngineV2 {
	batchRenderer = new BatchedRenderer();
	loader = new QuarksLoader();

	private readonly parsedConfigs = new Map<string, Object3D>();

	constructor(readonly scene: Scene) {
		this.loader.setCrossOrigin("");

		this.scene.add(this.batchRenderer);
	}

	add(config: ParticleSystemConfiguration) {
		const exists = this.parsedConfigs.get(config.configId);
		let obj: Object3D;
		if (exists) {
			obj = exists.clone(true);
		} else {
			obj = this.loader.parse(config);
			this.parsedConfigs.set(config.configId, obj);
			obj = obj.clone(true);
		}

		QuarksUtil.setAutoDestroy(obj, false);
		QuarksUtil.addToBatchRenderer(obj, this.batchRenderer);
		QuarksUtil.play(obj);

		this.scene.add(obj);

		return obj;
	}

	remove(obj: Object3D) {
		obj.removeFromParent();
		iterateParticleSystems(obj, (system) => this.batchRenderer.deleteSystem(system));
		obj.clear();
	}

	restart(obj: Object3D) {
		QuarksUtil.restart(obj);
	}

	play(obj: Object3D) {
		QuarksUtil.play(obj);
	}

	pause(obj: Object3D) {
		QuarksUtil.pause(obj);
	}

	stop(obj: Object3D) {
		QuarksUtil.stop(obj);
	}

	update(frameDeltaTime: number) {
		this.batchRenderer.update(frameDeltaTime);
	}

	dispose() {
		this.batchRenderer.removeFromParent();
		Object.values(this.parsedConfigs).forEach((obj) => this.remove(obj));
		this.parsedConfigs.clear();
	}
}
