import type {
	Events,
	IParsedAudio,
	IParsedCharacter,
	IParticleSystem,
	IProp,
	IScript,
} from "@jamango/content-client";
import { generateId, PropMeshType } from "@jamango/content-client";
import { BB } from "base/BB.js";
import { NODE } from "base/rete/InternalNameMap";
import { VY } from "base/util/math/Math";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { getBlockStructureWorldSize } from "base/world/block/BlockStructureMesh";
import * as SceneTree from "base/world/SceneTree";
import type { World } from "base/world/World";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import * as SpawnerRouter from "router/world/tools/Spawner";
import type { Camera } from "three";
import { Quaternion, Vector3 } from "three";

const _quaternion = new Quaternion();

export const onItemUse = (world: World, primary: boolean, _secondary: boolean) => {
	if (!primary) return;

	const cameraTarget = world.client!.camera.target;
	const viewRaycast = cameraTarget.viewRaycast;
	const selector = cameraTarget.selector;
	const hitBlock = viewRaycast?.state.block;

	if (!hitBlock) return;
	if (!selector?.state.selectedSlot) return;
	if (!getPeerMetadata(world.client!.loopbackPeer).permissions.canUseWrench) return;

	const selectedSlotPk = selector.state.selectedSlot.pk;

	handleAsset(world, selectedSlotPk, hitBlock);
};

const createCreateParticleSystemScriptEvents = (pk: string, hitBlock: Vector3): Events | null => {
	const spawnParticleSystemNodeId = generateId();
	const onBlockSpawnNodeId = generateId();

	return {
		nodes: {
			[spawnParticleSystemNodeId]: {
				id: spawnParticleSystemNodeId,
				defId: "b2c4e0a8-7f1e-4853-87e1-9a53f2a8c2c9",
				position: [440, 0],
				data: { config: pk, position: { x: hitBlock.x, y: hitBlock.y, z: hitBlock.z } },
			},
			[onBlockSpawnNodeId]: {
				id: onBlockSpawnNodeId,
				defId: NODE.OnBlockSpawn,
				position: [0, 0],
				data: { group: "\u0000" },
			},
		},
		connections: [
			{
				id: "13e7df5d-75b3-4fc6-9542-1f7ffc5ff6c3",
				source: onBlockSpawnNodeId,
				sourceOutput: "exec",
				target: spawnParticleSystemNodeId,
				targetInput: "exec",
			},
		],
		comments: {},
		frames: {},
	};
};

const createPlaySound3DScriptEvents = (pk: string, hitBlock: Vector3): Events => {
	const play3dSoundmNodeId = generateId();
	const onBlockSpawnNodeId = generateId();

	return {
		nodes: {
			[play3dSoundmNodeId]: {
				id: play3dSoundmNodeId,
				defId: "0002-08-0002",
				position: [440, 0],
				data: { audio: pk, position: { x: hitBlock.x, y: hitBlock.y, z: hitBlock.z } },
			},
			[onBlockSpawnNodeId]: {
				id: onBlockSpawnNodeId,
				defId: NODE.OnBlockSpawn,
				position: [0, 0],
				data: { group: "\u0000" },
			},
		},
		connections: [
			{
				id: "13e7df5d-75b3-4fc6-9542-1f7ffc5ff6c3",
				source: onBlockSpawnNodeId,
				sourceOutput: "exec",
				target: play3dSoundmNodeId,
				targetInput: "exec",
			},
		],
		comments: {},
		frames: {},
	};
};

function generateScriptName(nameBase: string) {
	let scriptName = nameBase;

	const matchingScripts = BB.world.content.state.scripts
		.getAll()
		.filter((s) => s.name.startsWith(nameBase));

	let scriptCounter = 1;

	while (matchingScripts.some((s) => s.name === scriptName)) {
		scriptCounter++;
		scriptName = `${nameBase} ${scriptCounter}`;
	}

	return scriptName;
}

function generateBlockGroupName(nameBase: string) {
	let blockGroupName = nameBase;

	let blockGroupCounter = 1;

	while (BB.world.blockGroups.groupNameToId[blockGroupName] !== undefined) {
		blockGroupCounter++;
		blockGroupName = `${nameBase} ${blockGroupCounter}`;
	}

	return blockGroupName;
}

function createScriptAndBlockGroup(
	scriptName: string,
	blockGroupName: string,
	block: Vector3,
	scriptEvents: Events,
) {
	const world = BB.world;

	const script = world.content.state.scripts.makeEmptyScript(scriptName);
	script.events = scriptEvents;
	world.content.state.scripts.set(script);

	return createBlockGroup(block, blockGroupName, script.pk);
}

async function createBlockGroup(block: Vector3, blockGroupName: string, scriptPk: string) {
	// create block group
	const result = await BlockGroupsRouter.create({ name: blockGroupName });
	if (!result.ok) return;

	const blockGroupId = result.data.id;
	await BlockGroupsRouter.setScripts({ id: blockGroupId, scripts: [{ script: scriptPk }] });
	await BlockGroupsRouter.addBlocks({ id: blockGroupId, blocks: [block.toArray()] });
}

const _cameraDirection = new Vector3();

const getSpawnAngle = (camera: Camera) => {
	const direction = camera.getWorldDirection(_cameraDirection);
	const yaw = Math.atan2(direction.x, direction.z);

	return yaw;
};

function handleCharacter(world: World, asset: IParsedCharacter, hitBlock: Vector3) {
	const position = hitBlock.clone();
	position.x += 0.5;
	position.y += 1;
	position.z += 0.5;

	const angle = getSpawnAngle(world.client!.camera);

	SpawnerRouter.createSceneTreeNode({
		name: null,
		type: SceneTree.SceneNodeType.CHARACTER,
		characterPk: asset.pk,
		position: position.toArray(),
		angle: angle,
		scripts: [],
	});
}

function handleProp(world: World, asset: IProp, hitBlock: Vector3) {
	const position = hitBlock.clone();
	position.x += 0.5;
	position.y += 1;
	position.z += 0.5;

	if (asset.mesh.type === PropMeshType.BLOCK_STRUCTURE) {
		const blockStructure = world.content.state.blockStructures.get(asset.mesh.blockStructurePk);
		if (!blockStructure) return;

		const size = getBlockStructureWorldSize(blockStructure, new Vector3());
		const height = size.y * asset.scale;
		position.y += height / 2;
	}

	const angle = getSpawnAngle(world.client!.camera);
	const quaternion = _quaternion.setFromAxisAngle(VY, angle);

	SpawnerRouter.createSceneTreeNode({
		name: null,
		type: SceneTree.SceneNodeType.PROP,
		propPk: asset.pk,
		position: position.toArray(),
		quaternion: quaternion.toArray(),
		scale: asset.scale,
		motionType: asset.physics.motionType,
		scripts: [],
	});
}

function handleParticleSystem(asset: IParticleSystem, hitBlock: Vector3) {
	const nameBase = `${asset.name} Spawner`;
	const scriptEvents = createCreateParticleSystemScriptEvents(asset.pk, hitBlock);

	if (!scriptEvents) {
		return null;
	}

	makeBlockGroupWithScript(nameBase, scriptEvents, hitBlock);
	return {
		nameBase,
		scriptEvents,
	};
}

function handleAudio(asset: IParsedAudio, hitBlock: Vector3) {
	const nameBase = `${asset.resource.name} 3D Sound`;
	const scriptEvents = createPlaySound3DScriptEvents(asset.pk, hitBlock);

	makeBlockGroupWithScript(nameBase, scriptEvents, hitBlock);
}

// Special case - lets attach existing script to a new group
async function handleScript(asset: IScript, hitBlock: Vector3) {
	const nameBase = `${asset.name} Group`;

	const blockGroupName = generateBlockGroupName(nameBase);

	await createBlockGroup(hitBlock, blockGroupName, asset.pk);

	return null;
}

function makeBlockGroupWithScript(nameBase: string, scriptEvents: Events, hitBlock: Vector3) {
	const scriptName = generateScriptName(nameBase);
	const blockGroupName = generateBlockGroupName(nameBase);

	return createScriptAndBlockGroup(scriptName, blockGroupName, hitBlock, scriptEvents);
}

function handleAsset(world: World, pk: string, hitBlock: Vector3) {
	const asset = BB.world.content.state.findAsset(pk);
	if (!asset) return;

	if (asset.type === "character") {
		handleCharacter(world, asset, hitBlock);
	} else if (asset.type === "prop") {
		handleProp(world, asset, hitBlock);
	} else if (asset.type === "particleSystem") {
		handleParticleSystem(asset, hitBlock);
	} else if (asset.type === "audio") {
		handleAudio(asset, hitBlock);
	} else if (asset.type === "script") {
		handleScript(asset, hitBlock);
	}
}
