import { createLogger, isColor, toTitleCase, isNullish } from "@jamango/helpers";
import type {
	IAvatar,
	IAvatarComponent,
	IBlock,
	IBlockSoundsPack,
	IBlockStructure,
	IBlockTexture,
	ICharacter,
	IEngineAsset,
	IEngineDef,
	IEngineSettings,
	IEngineWorldData,
	IEnvironmentPreset,
	IWorldBundle,
	JacyContent,
	Resource,
	TerrainGenerationOptions,
} from "@jamango/content-client";
import {
	BlockMaterialSide,
	DEFAULT_ENVIRONMENT_PRESET,
	DEFAULT_SETTINGS,
	getAvatarConfig,
	getAvatarConfigAssets,
	getAvatarObjectNodeConfig,
	BLOCKS_AUDIO_ASSETS,
	DEFAULT_BLOCK_SOUNDS_PACK_ID,
} from "@jamango/content-client";
import { getBuiltIn } from "mods/Builtins";
import { Text3D } from "mods/defs/Text3D";
import { ParticleSystem } from "mods/defs/ParticleSystem";
import { ItemPencil } from "mods/defs/ItemPencil";

const logger = createLogger("ContentToDefConverter");

type IMaterial = {
	[key in BlockMaterialSide]: string | IBlockTexture;
};

const BUILT_IN_DEFS = [
	"CharacterDefault",
	"PlayerAI",
	"PlayerDefault",
	"ItemWrench",
	"ItemPencil",
	"ItemNovaGun",
	"ProjectileNovaGun",
	"ItemNovaRifle",
	"ProjectileNovaRifle",
	"ItemNovAssaultRifle",
	"ProjectileNovAssaultRifle",
	"ItemZapperAssault",
	"ProjectileZapperAssault",
	"ItemZapperRifle",
	"ProjectileZapperRifle",
	"ItemZapperPistol",
	"ProjectileZapperPistol",
	"ItemSpade",
	"ItemSpawner",
	"ItemPathTester",
	Text3D.name,
	ParticleSystem.name,
] as const;

export class ContentToDefConverter {
	#getGenerationOptions(bundle: IWorldBundle): TerrainGenerationOptions {
		const terrainGenerationOptions = bundle.assets.map
			.terrainGenerationOptions as TerrainGenerationOptions;

		return terrainGenerationOptions ?? { type: "blank" };
	}

	getWorldDef(content: JacyContent) {
		const bundle = content.export();

		if (!bundle) {
			throw new Error("Failed to start a new world, missing bundle data.");
		}

		const avatarComponents = content.state.avatarComponents.getAll();

		const settings = this.getWorldSettings(bundle);

		const usedEnvPreset = Object.values(bundle.assets.environmentPresets).find((e) => e.isUsed);
		const environment = this.convertToDefEnvironmentPresets(usedEnvPreset);

		const builtInDefs = this.#getBuiltInDefs(bundle);
		const customWorldDefs = this.#getWorldDefs(bundle, avatarComponents);

		const defs = [...builtInDefs, ...customWorldDefs];
		const terrainGenerationOptions = this.#getGenerationOptions(bundle);

		const worldData: IEngineWorldData = {
			characterCollisions: bundle.assets.gameMechanics.characterCollisions,
			environment,
			sky: {
				dayLength: settings.dayLength,
				envMap: settings.skybox,
				time: environment.general.hour / 24,
			},
			defs,
			chunkScene: {
				waterEnabled: settings.waterEnabled,
				searchSpawnAboveground: true,
				fakeWater: { waterHeight: settings.waterHeight },
			},
			experiments: settings.experiments,
			terrainGenerationOptions: this.#getGenerationOptions(bundle),
		};

		if (terrainGenerationOptions.type === "custom") {
			if (terrainGenerationOptions.water) {
				worldData.chunkScene.waterEnabled = true;
				worldData.chunkScene.fakeWater.waterHeight = terrainGenerationOptions.water.level + 0.3;
			} else {
				worldData.chunkScene.waterEnabled = false;
			}
		} else if (terrainGenerationOptions.type === "mvr") {
			worldData.chunkScene.waterEnabled = true;
			worldData.chunkScene.fakeWater.waterHeight = 4.3;
		}

		return worldData;
	}

	private getWorldSettings(bundle: IWorldBundle) {
		const { worldData, settings, skyboxes = {}, resources = {} } = bundle.assets;

		const experiments = worldData?.experiments ?? [];
		const dayCycle = settings?.dayCycle ?? DEFAULT_SETTINGS.dayCycle;

		const skybox = Object.values(skyboxes).find((s) => s.isUsed) || Object.values(skyboxes)[0];

		const engineSettings: IEngineSettings = {
			dayLength: dayCycle ? -1 : undefined,
			waterEnabled: false,
			waterHeight: 0,
			experiments,
		};

		if (skybox && !dayCycle) {
			const skyboxNxResource = resources[skybox.nxResourcePk];
			const skyboxPxResource = resources[skybox.pxResourcePk];
			const skyboxNyResource = resources[skybox.nyResourcePk];
			const skyboxPyResource = resources[skybox.pyResourcePk];
			const skyboxNzResource = resources[skybox.nzResourcePk];
			const skyboxPzResource = resources[skybox.pzResourcePk];

			engineSettings.skybox =
				skyboxNxResource &&
				skyboxPxResource &&
				skyboxNyResource &&
				skyboxPyResource &&
				skyboxNzResource &&
				skyboxPzResource
					? {
							assets: [
								skyboxNxResource.pk,
								skyboxPxResource.pk,
								skyboxNyResource.pk,
								skyboxPyResource.pk,
								skyboxNzResource.pk,
								skyboxPzResource.pk,
							],
						}
					: undefined;
		}

		return engineSettings;
	}

	#getBuiltInDefs(bundle: IWorldBundle) {
		const defs: IEngineDef[] = [];

		BUILT_IN_DEFS.forEach((defName) => {
			const def = getBuiltIn(defName);
			if (isNullish(def.experiment)) {
				defs.push(def);
			} else if (bundle.assets.worldData.experiments.includes(def.experiment)) {
				defs.push(def);
			}
		});

		return defs;
	}

	#getWorldDefs(bundle: IWorldBundle, avatarComponents: IAvatarComponent[]) {
		const assets = bundle.assets;

		const resources = assets.resources ?? {};
		const audioResources = Object.values(resources).filter((r) => r.resourceType === "audio");

		const defs: IEngineDef[] = [
			...this.convertToDefCharacters(
				assets.characters ? Object.values(assets.characters) : [],
				assets.avatars ? Object.values(assets.avatars) : [],
				avatarComponents,
				resources,
			),
		];

		defs.push({
			name: "content_sounds",
			assets: audioResources.map((r) => this.convertResourceToAsset(r)),
		});

		const dayCycle = assets.settings?.dayCycle ?? false;
		const skyboxes = assets.skyboxes ?? {};
		const skybox = Object.values(skyboxes).find((s) => s.isUsed) || Object.values(skyboxes)[0];

		if (!dayCycle && skybox) {
			const skyboxNxResource = resources[skybox.nxResourcePk];
			const skyboxPxResource = resources[skybox.pxResourcePk];
			const skyboxNyResource = resources[skybox.nyResourcePk];
			const skyboxPyResource = resources[skybox.pyResourcePk];
			const skyboxNzResource = resources[skybox.nzResourcePk];
			const skyboxPzResource = resources[skybox.pzResourcePk];

			if (
				skyboxNxResource &&
				skyboxPxResource &&
				skyboxNyResource &&
				skyboxPyResource &&
				skyboxNzResource &&
				skyboxPzResource
			) {
				defs.push({
					name: "EnvMapCustom",
					assets: [
						this.convertResourceToAsset(skyboxNxResource),
						this.convertResourceToAsset(skyboxPxResource),
						this.convertResourceToAsset(skyboxNyResource),
						this.convertResourceToAsset(skyboxPyResource),
						this.convertResourceToAsset(skyboxNzResource),
						this.convertResourceToAsset(skyboxPzResource),
					],
				});
			}
		}

		Object.values(assets.blockStructures ?? {}).forEach((blockStructure) => {
			defs.push(this.convertToDefBlockStructure(blockStructure));
		});

		return defs;
	}

	convertToDefBlockStructure(blockStructure: IBlockStructure) {
		return {
			...ItemPencil,
			name: ItemPencil.blockStructurePrefix + blockStructure.pk,
			client() {},
		};
	}

	convertResourceToAsset(resource: Resource) {
		return {
			id: resource.pk,
			url: resource.file.url,
			type: resource.file.mimeType,
		} satisfies IEngineAsset;
	}

	convertToDefEnvironmentPresets(envPreset?: IEnvironmentPreset) {
		const environment = structuredClone(DEFAULT_ENVIRONMENT_PRESET.preset);

		if (!envPreset || !envPreset.preset) {
			return {
				general: {
					...environment.general,
				},
				light: {
					...environment.light,
				},
				sky: {
					...environment.sky,
				},
				fog: {
					...environment.fog,
				},
			} satisfies IEnvironmentPreset["preset"];
		}

		const preset = structuredClone(envPreset.preset);

		return {
			general: {
				...environment.general,
				...(preset.general || {}),
			},
			light: {
				...environment.light,
				...(preset.light || {}),
			},
			sky: {
				...environment.sky,
				...(preset.sky || {}),
			},
			fog: {
				...environment.fog,
				...(preset.fog || {}),
			},
		} satisfies IEnvironmentPreset["preset"];
	}

	convertToDefBlocks(
		blocks: IBlock[],
		blockTextures: IBlockTexture[],
		resources: Record<string, Resource>,
		blockSoundsPacks: IBlockSoundsPack[],
	) {
		const blockDefs: any[] = [];

		const assets: IEngineAsset[] = [...BLOCKS_AUDIO_ASSETS];

		for (const blockTexture of blockTextures) {
			const resource = resources[blockTexture.resourcePk];

			if (resource) {
				assets.push(this.convertResourceToAsset(resource));
			}
		}

		for (const block of blocks) {
			const material = this.getBlockMaterial(block, blockTextures);

			const isMissingMaterial =
				!material.default &&
				(!material.px ||
					!material.nx ||
					!material.py ||
					!material.ny ||
					!material.pz ||
					!material.nz);

			if (isMissingMaterial) {
				logger.error(`Failed to load block ${block.displayName}, missing material side.`);
				continue;
			}

			const blockDef = this.convertToDefBlock(block, material, resources, blockSoundsPacks);
			blockDefs.push(blockDef);
		}

		return {
			name: "BlocksEditor",
			assets,
			blocks: blockDefs,
		};
	}

	getBlockMaterialSide(block: IBlock, side: BlockMaterialSide, blockTextures: IBlockTexture[]) {
		const value = block.material[side];
		if (isNullish(value)) return false;
		if (isColor(value)) return value;

		const texture = blockTextures.find((item) => item.pk === value);
		if (!texture) return false;
		return texture;
	}

	getBlockMaterial(block: IBlock, blockTextures: IBlockTexture[]) {
		const material: IMaterial = {} as any;

		for (const side of Object.values(BlockMaterialSide)) {
			const sideKey = side as BlockMaterialSide;
			const sideValue = this.getBlockMaterialSide(block, sideKey, blockTextures);

			if (sideValue) {
				material[sideKey] = sideValue;
			}
		}

		return material satisfies IMaterial;
	}

	convertToDefBlock(
		block: IBlock,
		material: IMaterial,
		resources: Record<string, Resource>,
		soundsPacks: IBlockSoundsPack[],
	) {
		const id = block.pk;
		const blockDef: Record<string, any> = {
			...block.options,
			id,
			name: id,
			display: block.displayName,
			scripts: block.scripts,
			faces: [],
		};

		const soundsPackId = block.soundsPackId ?? DEFAULT_BLOCK_SOUNDS_PACK_ID;

		if (soundsPackId) {
			const soundPack = soundsPacks.find((pack) => pack.id === soundsPackId);
			if (soundPack) {
				const onBuild = soundPack.onBuild?.map((asset) => ({ asset }));
				const onBreak = soundPack.onBreak?.map((asset) => ({ asset }));
				const onFootstep = soundPack.onFootstep?.map((asset) => ({ asset }));
				blockDef.sound = {
					build: onBuild,
					break: onBreak,
					footstep: onFootstep,
				};
			}
		}

		const defaultSide = material.default;

		if (typeof defaultSide === "string") {
			blockDef.faces = [{ color: defaultSide }];
		} else {
			const faces: Record<string, any> = {
				default: null,
			};

			if (!isNullish(defaultSide)) {
				const resource = resources[defaultSide.resourcePk];

				if (!resource) {
					logger.error(`Failed to load block ${block.displayName}, missing resource.`);
					return;
				}

				faces.default = resource.pk;
			}

			for (const side of ["nx", "px", "ny", "py", "nz", "pz"]) {
				const sideKey = side as keyof typeof material;
				const materialSide = material[sideKey];

				if (typeof materialSide === "string" || isNullish(materialSide)) {
					faces[side] = faces.default;
				} else if (materialSide) {
					const resource = resources[materialSide.resourcePk];

					if (!resource) {
						logger.error(`Failed to load block ${block.displayName}, missing resource.`);
						return;
					}

					faces[side] = resource.pk;
				} else {
					faces[side] = faces.default;
				}
			}

			blockDef.faces =
				Object.keys(faces).length > 1
					? [
							{ texture: { asset: faces.px ?? faces.default } },
							{ texture: { asset: faces.nx ?? faces.default } },
							{ texture: { asset: faces.ny ?? faces.default } },
							{ texture: { asset: faces.py ?? faces.default } },
							{ texture: { asset: faces.pz ?? faces.default } },
							{ texture: { asset: faces.nz ?? faces.default } },
						]
					: [{ texture: { asset: faces.default } }];
		}

		return blockDef;
	}

	convertToDefCharacters(
		characters: ICharacter[],
		avatars: IAvatar[],
		avatarComponents: IAvatarComponent[],
		resources: Record<string, Resource>,
	) {
		return characters.map((character) =>
			this.convertToDefCharacter(character, avatars, avatarComponents, resources),
		);
	}

	convertToDefCharacter(
		character: ICharacter,
		avatars: IAvatar[],
		avatarComponents: IAvatarComponent[],
		resources: Record<string, Resource>,
	) {
		const defName = `PlayerAI${toTitleCase(character.name).replaceAll(" ", "")}`;

		const meta: ICharacter & {
			config?: object | null;
			avatarObject?: object | null;
		} = structuredClone(character);
		meta.config = null;

		let characterBase = structuredClone(getBuiltIn("PlayerAI"));

		if (character.aiAvatar) {
			const avatar = avatars.find((a) => a.pk === character.aiAvatar);

			if (avatar) {
				const config = getAvatarConfig(avatar, avatarComponents);
				const assets = getAvatarConfigAssets(config);
				meta.config = structuredClone(config);
				meta.avatarObject = getAvatarObjectNodeConfig(avatar, avatarComponents);

				const avatarThumbnail = resources[avatar.thumbnailResourcePk].file.url;

				characterBase = {
					...characterBase,
					assets,
					avatarThumbnail,
					server() {},
				};
			} else {
				logger.error(`Missing avatar for character ${character.name}, using default as a fallback.`);
			}
		}

		return {
			...characterBase,
			name: defName,
			pk: character.pk,
			id: character.id,
			showNameplate: character.showNametag,
			aiType: character.aiType,
			characterName: character.name,
			aiDropItem: character.dropItem,
			aiCanKillNPCs: character.canKillNPCs,
			meta,
		};
	}
}
