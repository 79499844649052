import * as Physics from "base/world/Physics";
import type { Character } from "base/world/entity/Character";
import type { World } from "base/world/World";
import * as GameWorldUI from "client/dom/GameWorldUI";
import type { Material } from "three";
import { ArrowHelper, Group, Vector3 } from "three";

type CharacterDebugState = {
	entityId: number;
	group: Group;
	velocityArrowHelper: ArrowHelper;
	elementId: string;
	domElement: HTMLElement;
};

export const init = () => {
	const characters = new Map<number, CharacterDebugState>();

	return { characters };
};

type CharacterPhysicsDebugState = ReturnType<typeof init>;

const createDebugVisuals = (state: CharacterPhysicsDebugState, world: World, entityId: number) => {
	const character = world.entities.find((e) => e.entityID === entityId) as Character;

	// velocity arrow
	const group = new Group();

	const velocityArrowHelper = new ArrowHelper();
	velocityArrowHelper.line.renderOrder = 1;
	(velocityArrowHelper.line.material as Material).depthTest = false;
	velocityArrowHelper.cone.renderOrder = 1;
	(velocityArrowHelper.cone.material as Material).depthTest = false;
	group.add(velocityArrowHelper);

	// dom element
	const domElement = document.createElement("div");
	const elementId = `character-debug-${character.entityID}`;
	GameWorldUI.add(world.client!.gameWorldUI, elementId, "html", domElement);

	const debugState = {
		entityId: character.entityID,
		group,
		velocityArrowHelper,
		domElement,
		elementId,
	};

	state.characters.set(character.entityID, debugState);

	world.scene.add(group);
};

const _cameraDirectionOffset = new Vector3();
const _linearVelocity = new Vector3();
const _linearVelocityDirection = new Vector3();
const _shapeBoundsSize = new Vector3();

const updateDebugVisuals = (state: CharacterPhysicsDebugState, world: World, character: Character) => {
	const debugState = state.characters.get(character.entityID);
	if (!debugState) return;

	const domElement = debugState.domElement;

	const camera = world.client!.camera;
	_cameraDirectionOffset.set(1, 1, 0);
	_cameraDirectionOffset.applyQuaternion(camera.quaternion);

	const element = GameWorldUI.get(world.client!.gameWorldUI, debugState.elementId);
	element.object3D.position.copy(character.position);
	element.object3D.position.add(_cameraDirectionOffset);

	const controller = character.characterPhysics.state.controller;

	const linearVelocity = _linearVelocity;
	const linearVelocityDirection = _linearVelocityDirection;
	const shapeBoundsSize = _shapeBoundsSize;

	debugState.velocityArrowHelper.traverse((o) => {
		o.visible = !!controller && !character.movement.state.isFlying;
	});

	if (controller) {
		Physics.getCharacterControllerShapeBoundsSize(controller, shapeBoundsSize);
		Physics.getCharacterControllerLinearVelocity(controller, linearVelocity);

		_linearVelocityDirection.copy(linearVelocity).normalize();

		debugState.velocityArrowHelper.position.copy(character.position);
		debugState.velocityArrowHelper.setDirection(linearVelocityDirection);
		debugState.velocityArrowHelper.setLength(linearVelocity.length());
		debugState.velocityArrowHelper.setColor(0xff0000);
	}

	const getControllerDebugHtml = () => {
		if (!controller) return "<div>No controller</div>";

		const supported = Physics.getIsCharacterControllerSupported(controller);
		const onGround = Physics.getIsCharacterControllerOnGround(controller);
		const onSteepGround = Physics.getIsCharacterControllerOnSteepGround(controller);
		const inAir = Physics.getIsCharacterControllerInAir(controller);

		const boolColor = (b: boolean) => (b ? "#0f0" : "#f00");

		const valueToElement = (label: string, value: boolean | number | Vector3) => {
			if (typeof value === "boolean") {
				return `<div style="color: ${boolColor(value)}">${label}: ${value}</div>`;
			} else if (typeof value === "number") {
				return `<div>${label}: ${value.toFixed(2)}</div>`;
			} else if (value instanceof Vector3) {
				return `<div>${label}: ${value
					.toArray()
					.map((v) => v.toFixed(2))
					.map((v) => (v[0] === "-" ? v : ` ${v}`))
					.join(", ")}</div>`;
			}
		};

		return `
			${valueToElement("Supported", supported)}
			${valueToElement("On ground", onGround)}
			${valueToElement("On steep ground", onSteepGround)}
			${valueToElement("In air", inAir)}
			${valueToElement("Crouching", character.movement.state.isCrouching)}
			${valueToElement("Sprinting", character.movement.state.isSprinting)}
			${valueToElement("Position", character.position)}
			${valueToElement("Linear velocity", linearVelocity)}
			${valueToElement("Shape Bounds", shapeBoundsSize)}
			${valueToElement("Movement mode", character.movement.def.mode)}
		`;
	};

	domElement.innerHTML = `
		<div style="background-color: #3333; color: #fff; padding: 5px; border-radius: 5px; min-width: 350px; font-family: monospace;">
			<div>Character: ${character.entityID}</div>
			${getControllerDebugHtml()}
		</div>
    `;
};

const removeDebugVisuals = (state: CharacterPhysicsDebugState, world: World, entityId: number) => {
	const debugState = state.characters.get(entityId);
	if (!debugState) return;

	GameWorldUI.remove(world.client!.gameWorldUI, debugState.elementId);

	debugState.group.removeFromParent();
	debugState.velocityArrowHelper.dispose();

	state.characters.delete(entityId);
};

export const update = (state: CharacterPhysicsDebugState, world: World, enabled: boolean) => {
	if (!enabled) {
		for (const entityId of state.characters.keys()) {
			removeDebugVisuals(state, world, entityId);
		}

		return;
	}

	const existingCharactersIds = world.entities
		.filter((e) => e.type.def.isCharacter)
		.map((e) => e.entityID as number);
	const visualsCharactersIds = Array.from(state.characters.keys());

	const toCheck = new Set<number>([...existingCharactersIds, ...visualsCharactersIds]);

	for (const characterId of toCheck) {
		const character = world.entities.find((e) => e.entityID === characterId) as Character;

		const isCameraTarget = world.client!.camera.target === character;
		const isFirstPerson = world.client!.camera.is1stPerson();

		const visuals = state.characters.get(characterId);
		const needsVisuals =
			existingCharactersIds.includes(characterId) && (!isCameraTarget || !isFirstPerson);

		if (visuals && !needsVisuals) {
			removeDebugVisuals(state, world, characterId);
		} else if (!visuals && needsVisuals) {
			createDebugVisuals(state, world, characterId);
		}

		if (needsVisuals) {
			updateDebugVisuals(state, world, character);
		}
	}
};
