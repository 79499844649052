import type { World } from "base/world/World";
import type { PerspectiveCamera } from "three";
import { Matrix4, Object3D, Quaternion, Vector3 } from "three";

export type GameWorldUIElement = {
	id: string;
	screenPosition: [number, number];
	object3D: Object3D;
	worldPosition: Vector3;
	order: number;
	culled: boolean;
	visible: boolean;
	distance: number;
	scale: number;
	zIndex: number;
} & (
	| {
			type: "blockGroup";
			data: string;
	  }
	| {
			type: "sceneTreeNode";
			data: { nodeId: string; prophecy: boolean };
	  }
	| {
			type: "interactLabel";
			data: string;
	  }
	| {
			type: "html";
			data: HTMLElement;
	  }
);

export type BlockGroupElement = GameWorldUIElement & { type: "blockGroup" };
export type SceneTreeNodeElement = GameWorldUIElement & { type: "sceneTreeNode" };
export type InteractLabelElement = GameWorldUIElement & { type: "interactLabel" };
export type HtmlElement = GameWorldUIElement & { type: "html" };

export const init = () => {
	const elements: Record<string, GameWorldUIElement> = {};

	return { elements };
};

export type GameWorldUIState = ReturnType<typeof init>;

const createElement = (id: string, type: GameWorldUIElement["type"], data: any, position?: Vector3) => {
	const object3D = new Object3D();

	if (position) {
		object3D.position.copy(position);
	}

	return {
		id,
		type,
		data,
		object3D,
		worldPosition: new Vector3(),
		screenPosition: [0, 0],
		order: 0,
		culled: true,
		visible: true,
		distance: 0,
		scale: 1,
		zIndex: 0,
	} satisfies GameWorldUIElement;
};

export const add = <T extends GameWorldUIElement["type"]>(
	state: GameWorldUIState,
	id: string,
	type: T,
	data: (GameWorldUIElement & { type: T })["data"],
	position?: Vector3,
) => {
	const element = createElement(id, type, data, position);
	state.elements[id] = element;

	return element;
};

export const remove = (state: GameWorldUIState, id: string) => {
	const element = state.elements[id];
	if (!element) return;

	element.object3D.removeFromParent();
	delete state.elements[id];
};

export const get = (state: GameWorldUIState, id: string) => {
	return state.elements[id];
};

const IDENTITY_QUATERNION = new Quaternion();
const IDENTITY_SCALE = new Vector3(1, 1, 1);

const _projectedPosition = new Vector3();
const _matrix = new Matrix4();
const _objectPosition = new Vector3();
const _cameraPosition = new Vector3();
const _cameraDirection = new Vector3();

const SCALE_DISTANCE_FACTOR = 7.5;

const getScale = (position: Vector3, camera: PerspectiveCamera) => {
	_matrix.compose(position, IDENTITY_QUATERNION, IDENTITY_SCALE);

	const cameraPos = _cameraPosition.setFromMatrixPosition(camera.matrixWorld);
	const vFOV = (camera.fov * Math.PI) / 180;
	const dist = position.distanceTo(cameraPos);
	const scaleFOV = 2 * Math.tan(vFOV / 2) * dist;

	return (1 / scaleFOV) * SCALE_DISTANCE_FACTOR;
};

const isObjectBehindCamera = (position: Vector3, cameraPosition: Vector3, cameraDirection: Vector3) => {
	const deltaCamObj = _objectPosition.copy(position).sub(cameraPosition);
	return deltaCamObj.angleTo(cameraDirection) > Math.PI / 2;
};

export const update = (state: GameWorldUIState, world: World) => {
	const camera = world.client!.camera;
	const cameraWorldPosition = camera.getWorldPosition(_cameraPosition);
	const cameraWorldDirection = camera.getWorldDirection(_cameraDirection);

	for (const el of Object.values(state.elements)) {
		el.object3D.getWorldPosition(el.worldPosition);
		el.distance = el.worldPosition.distanceTo(cameraWorldPosition);
	}

	const sortedElements = Object.values(state.elements).sort((a, b) => {
		if (b.zIndex !== a.zIndex) {
			return a.zIndex - b.zIndex;
		}
		return b.distance - a.distance;
	});

	for (let i = 0; i < sortedElements.length; i++) {
		const el = sortedElements[i];

		el.order = i;

		el.culled =
			el.distance < 0.3 ||
			isObjectBehindCamera(el.worldPosition, cameraWorldPosition, cameraWorldDirection);

		_projectedPosition.copy(el.worldPosition).project(camera);
		const x = ((1 + _projectedPosition.x) * window.innerWidth) / 2;
		const y = ((1 - _projectedPosition.y) * window.innerHeight) / 2;

		el.screenPosition[0] = x;
		el.screenPosition[1] = y;

		el.scale = getScale(el.worldPosition, camera);
	}
};
