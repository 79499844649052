import { EDIT_WORLD_MODE } from "@jamango/content-client";
import * as Rete from "base/rete/rete";
import type { World } from "base/world/World";
import type { WorldEditorSnapshot, WorldEditorState } from "base/world/WorldEditor";
import { updateStatus, WorldEditorAction, WorldEditorStatus } from "base/world/WorldEditor";
import { Euler } from "three";

const TESTING_TRANSITIONS = {
	[WorldEditorAction.RESTART]: restart,
	[WorldEditorAction.STOP]: stop,
};

type TransitionFn = (state: WorldEditorState, world: World) => void;

const TRANSITIONS: {
	[status: string]: { [action: string]: TransitionFn };
} = {
	[WorldEditorStatus.EDITING]: {
		[WorldEditorAction.ENTER_PLAY_MODE]: enterPlayMode,
		[WorldEditorAction.START_GAME]: startGame,
		[WorldEditorAction.START_TESTING_FROM_POSITION]: startTestingFromPosition,
		[WorldEditorAction.START_TESTING_FROM_SPAWN]: startTestingFromSpawn,
	},
	[WorldEditorStatus.TESTING_PAUSED]: {
		[WorldEditorAction.RESUME]: resume,
		...TESTING_TRANSITIONS,
	},
	[WorldEditorStatus.TESTING_ACTIVE]: {
		[WorldEditorAction.PAUSE]: pause,
		...TESTING_TRANSITIONS,
	},
	[WorldEditorStatus.PREVIEWING]: {
		[WorldEditorAction.STOP]: stop,
	},
	[WorldEditorStatus.PLAYING]: {
		[WorldEditorAction.ENTER_EDIT_MODE]: enterEditMode,
		[WorldEditorAction.START_GAME]: startGame,
	},
};

export function update(state: WorldEditorState, world: World, action: WorldEditorAction) {
	const fn = TRANSITIONS?.[state.status]?.[action];

	if (fn) {
		fn(state, world);
	}
}

/* transition functions */

function enterPlayMode(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.PLAYING);

	onModeChange(state, world);
}

function enterEditMode(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.EDITING);

	onModeChange(state, world);
}

function startGame(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.PREVIEWING);

	state.snapshot = snapshot(world);
	state.restartTestingSnapshot = null;

	world.scene.loadMapSameGenerators(state.snapshot!.map, false);
}

function startTestingFromPosition(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.TESTING_ACTIVE);

	state.snapshot = snapshot(world, false);
	state.restartTestingSnapshot = state.snapshot;

	Rete.loadMap(world.content, world, false);
}

function startTestingFromSpawn(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.TESTING_ACTIVE);

	state.snapshot = snapshot(world, true);

	world.scene.loadMapSameGenerators(state.snapshot!.map, false);

	state.restartTestingSnapshot = snapshot(world);
}

function stop(state: WorldEditorState, world: World) {
	const mode = world.content.state.worldData.mode;

	const prevStatus = state.status;
	const newStatus = mode === EDIT_WORLD_MODE ? WorldEditorStatus.EDITING : WorldEditorStatus.PLAYING;

	updateStatus(state, newStatus);

	if (!state.snapshot) return;

	const keepCurrentPlayerPositions =
		state.snapshot.playerPositions === undefined &&
		(prevStatus === WorldEditorStatus.TESTING_ACTIVE || prevStatus === WorldEditorStatus.TESTING_PAUSED);
	restore(state.snapshot, world, keepCurrentPlayerPositions);

	state.snapshot = null;
	state.restartTestingSnapshot = null;
}

function pause(state: WorldEditorState) {
	updateStatus(state, WorldEditorStatus.TESTING_PAUSED);
}

function resume(state: WorldEditorState) {
	updateStatus(state, WorldEditorStatus.TESTING_ACTIVE);
}

function restart(state: WorldEditorState, world: World) {
	updateStatus(state, WorldEditorStatus.TESTING_ACTIVE);

	if (!state.restartTestingSnapshot) return;

	restore(state.restartTestingSnapshot, world);
}

/* utilities */

const _euler = new Euler();

function snapshotPlayerPositions(world: World) {
	return world.entities
		.filter((e) => e.type.def.isPlayer)
		.map((player) => {
			const peer = world.playerToPeer.get(player);

			if (!peer) {
				return undefined;
			}

			const sph = {
				phi: player.input?.camera.phi ?? 0,
				theta: player.input?.camera.theta ?? _euler.setFromQuaternion(player.quaternion, "YXZ").y,
			};

			return {
				peer,
				position: player.position.clone(),
				sph,
			};
		})
		.filter((p) => p !== undefined);
}

function snapshot(world: World, includePositions = true): WorldEditorSnapshot {
	const map = world.scene.saveMap();

	const playerPositions = includePositions ? snapshotPlayerPositions(world) : undefined;

	return {
		map,
		playerPositions,
	};
}

function restore(snapshot: WorldEditorSnapshot, world: World, keepCurrentPlayerPositions = false) {
	let playerPositions: WorldEditorSnapshot["playerPositions"];

	if (keepCurrentPlayerPositions) {
		playerPositions = snapshotPlayerPositions(world);
	} else if (snapshot.playerPositions) {
		playerPositions = snapshot.playerPositions;
	}

	world.scene.loadMapSameGenerators(snapshot.map, false);

	if (playerPositions) {
		for (const { peer, position, sph } of playerPositions) {
			const player = world.peerToPlayer.get(peer);

			if (player) {
				player.setPosition(position, true);
				player.setCamera(sph.phi, sph.theta);
			}
		}
	}
}

function onModeChange(state: WorldEditorState, world: World) {
	// reset world non-destructively
	state.snapshot = snapshot(world);
	restore(state.snapshot, world);

	state.snapshot = null;
	state.restartTestingSnapshot = null;
}
