import type { PerlinNoiseObjectPlacerOptions, PerlinOptions } from "@jamango/content-client";
import { defPerlin } from "base/util/Defs";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import type { PerlinNoiseGenerator } from "base/util/math/Perlin";
import { JSFRandom } from "base/util/math/Random";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import type { Chunk } from "base/world/block/chunk/Chunk";
import { ModelBuilder } from "./ModelBuilder";
import type { IChunkGenerator } from "./types";

type ObjectData = {
	type: number;
	object: PerlinNoiseObjectPlacerOptions["objects"][number];
};

export class GeneratorPerlinNoiseObjectPlacer implements IChunkGenerator {
	static name = "bb.generator.perlinnoiseobjectplacer" as const;
	static display = "PerlinNoiseObjectPlacer" as const;

	private tcache = new LegacyVectorMap(); //tree cache

	private perlin?: PerlinNoiseGenerator;

	private perlinOptions: PerlinOptions;
	private minHeight: number;
	private maxHeight: number;

	private fprob: number;
	private tnfprob: number;
	private tfprob: number;

	private objects: PerlinNoiseObjectPlacerOptions["objects"] = [];
	private chunkSize = 0;
	private seed = 0;

	private modelBuilder?: ModelBuilder;

	constructor(options: PerlinNoiseObjectPlacerOptions) {
		this.perlinOptions = options.perlinOptions;
		this.fprob = -2 * options.forestProbability + 1;
		this.tnfprob = options.probabilityNonForest;
		this.tfprob = options.probabilityForest;
		this.minHeight = options.minHeight;
		this.maxHeight = options.maxHeight;
		this.objects = options.objects;
	}

	init({
		seed,
		chunkSize,
	}: { seed: number; blockTypeRegistry: BlockTypeRegistryState; chunkSize: number }) {
		this.seed = seed;
		this.chunkSize = chunkSize;
		this.modelBuilder = new ModelBuilder(chunkSize);
		this.modelBuilder.buildObjects(this.objects);

		this.tcache = new LegacyVectorMap(); //tree cache

		this.perlin = defPerlin(this.perlinOptions, seed, GeneratorPerlinNoiseObjectPlacer.name);
	}

	apply(chunk: Chunk) {
		const scene = chunk.scene;
		const chunkSize = this.chunkSize;
		const modelBuilder = this.modelBuilder;
		const terrain = scene.terrain;

		if (!modelBuilder) return;

		const cx = chunk.position.x;
		const cy = chunk.position.y;
		const cz = chunk.position.z;

		const min = modelBuilder.modelsChunkBounds.min;
		const max = modelBuilder.modelsChunkBounds.max;

		for (let rz = min.z; rz < max.z; rz++) {
			for (let rx = min.x; rx < max.x; rx++) {
				const ax = rx + cx * chunkSize;
				const az = rz + cz * chunkSize;
				let ay;

				if (terrain) {
					const ayTL = scene.getTerrainHeight(ax, az);
					const ayBL = scene.getTerrainHeight(ax, az + 1);
					const ayTR = scene.getTerrainHeight(ax + 1, az);
					const ayBR = scene.getTerrainHeight(ax + 1, az + 1);
					ay = Math.min(ayTL, ayBL, ayTR, ayBR);
				} else {
					ay = 0;
				}

				if (ay > this.minHeight && ay <= this.maxHeight) {
					const ry = ay - cy * chunkSize;
					if (ry >= min.y && ry < max.y) {
						const objectData = this.getObjectExcludingOverlaps(ax, az);
						if (objectData) {
							modelBuilder.models[objectData.type].build(chunk, rx, ry, rz, false, objectData);
						}
					}
				}
			}
		}
	}

	//same as getTree, but filters out overlapping trees to prevent it from looking terrible
	private getObjectExcludingOverlaps(ax: number, az: number) {
		const modelBuilder = this.modelBuilder;

		if (!modelBuilder) return;

		const w = modelBuilder.modelsBounds.max.x - modelBuilder.modelsBounds.min.x;
		const d = modelBuilder.modelsBounds.max.z - modelBuilder.modelsBounds.min.z;
		const axMin = ax - w;
		const azMin = az - d;
		const axMax = ax + w;
		const azMax = az + d;

		//keep objects away from spawn
		if (axMin <= 0 && axMax >= 0 && azMin <= 0 && azMax >= 0) return;

		const objectData = this.getObjectData(ax, az);
		if (!objectData) return;

		for (let asz = azMin; asz <= azMax; asz++) {
			for (let asx = axMin; asx <= axMax; asx++) {
				if (asx === ax && asz === az) return objectData; //safe to stop searching here
				if (this.getObjectData(asx, asz)) return; //overlap detected; cancel tree
			}
		}
	}

	private getRandomObjectIndex(random: JSFRandom) {
		const totalChance = this.objects.reduce((acc, item) => acc + item.probability, 0);

		let lot = random.float64();

		if (totalChance > 1) {
			lot *= totalChance;
		}

		for (let i = 0; i < this.objects.length; i++) {
			const obj = this.objects[i];

			if (lot > obj.probability) {
				lot -= obj.probability;
			} else {
				return i;
			}
		}

		return -1;
	}

	//returns tree's branch data if there is a tree at (ax, az), or undefined
	private getObjectData(ax: number, az: number): ObjectData | undefined {
		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);
		let t = this.tcache.get(cx, cz) as LegacyVectorMap | undefined;

		if (!t) {
			t = new LegacyVectorMap();
			const random = new JSFRandom(this.seed, "x", cx, "z", cz, "object");

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const atx = cx * chunkSize + rx;
					const atz = cz * chunkSize + rz;

					if (random.probability(this.getObjectProbability(atx, atz))) {
						const objectType = this.getRandomObjectIndex(random);

						if (objectType > -1) {
							const objectData: ObjectData = {
								type: objectType,
								object: this.objects[objectType],
							};

							t.set(rx, rz, objectData);
						}
					}
				}
			}

			this.tcache.set(cx, cz, t);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return t.get(rx, rz);
	}

	private getObjectProbability(ax: number, az: number) {
		const noise = this.perlin?.getNoise(ax, az) ?? 0;
		return noise > this.fprob ? this.tfprob : this.tnfprob;
	}

	clearCache() {
		this.tcache.clear();
		this.perlin?.clearCache();
	}
}
