import { Group, Mesh, MeshBasicMaterial, SphereGeometry, Vector3 } from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";

export class PathfindingHelper extends Group {
	constructor() {
		super();

		this.startGoalGeometry = new SphereGeometry(0.5);
		this.startMaterial = new MeshBasicMaterial({
			color: "blue",
			opacity: 0.75,
			transparent: true,
			depthWrite: false,
		});
		this.goalMaterial = new MeshBasicMaterial({
			color: "green",
			opacity: 0.75,
			transparent: true,
			depthWrite: false,
		});

		this.pathPointGeometry = new SphereGeometry(0.25);
		this.pathPointMaterial = new MeshBasicMaterial({
			color: "orange",
			opacity: 0.75,
			transparent: true,
			depthWrite: false,
		});

		this.exploredPointGeometry = new SphereGeometry(0.1);
		this.exploredPointMaterial = new MeshBasicMaterial({
			color: "lightblue",
			opacity: 0.5,
			transparent: true,
			depthWrite: false,
		});

		this.startMesh = new Mesh(this.startGoalGeometry, this.startMaterial);
		this.startMesh.visible = false;
		this.add(this.startMesh);

		this.goalMesh = new Mesh(this.startGoalGeometry, this.goalMaterial);
		this.goalMesh.visible = false;
		this.add(this.goalMesh);

		this.pathLineMaterial = new LineMaterial({
			color: "orange",
			linewidth: 7,
		});
		this.pathLineGeometry = new LineGeometry();
		this.pathLine = new Line2(this.pathLineGeometry, this.pathLineMaterial);
		this.pathLine.frustumCulled = false;
		this.pathLine.visible = false;
		this.add(this.pathLine);

		this.pathMeshes = [];
		this.exploredMeshes = [];
	}

	/**
	 * @param {THREE.Vector3[]} path
	 * @param {THREE.Vector3[]} explored
	 */
	setPath(path, explored) {
		this.clearPath();

		const pathPoints = [];
		const pathLinePositions = [];

		for (const position of path) {
			const worldPosition = new Vector3().copy(position).addScalar(0.5);
			pathPoints.push(worldPosition);
			pathLinePositions.push(worldPosition.x, worldPosition.y, worldPosition.z);
		}

		// path line
		const prevPathLineGeometry = this.pathLineGeometry;

		this.pathLineGeometry = new LineGeometry();
		this.pathLineGeometry.setPositions(pathLinePositions);
		this.pathLine.geometry = this.pathLineGeometry;
		this.pathLine.visible = true;

		prevPathLineGeometry.dispose();

		// path points
		for (const point of pathPoints) {
			const mesh = new Mesh(this.pathPointGeometry, this.pathPointMaterial);
			mesh.position.copy(point);

			this.add(mesh);
			this.pathMeshes.push(mesh);
		}

		// explored points
		for (const position of explored) {
			const worldPosition = new Vector3().copy(position).addScalar(0.5);

			const mesh = new Mesh(this.exploredPointGeometry, this.exploredPointMaterial);
			mesh.position.copy(worldPosition);

			this.add(mesh);
			this.exploredMeshes.push(mesh);
		}
	}

	clearPath() {
		this.pathLine.visible = false;

		for (const mesh of this.pathMeshes) {
			this.remove(mesh);
		}

		for (const mesh of this.exploredMeshes) {
			this.remove(mesh);
		}

		this.pathMeshes = [];
		this.exploredMeshes = [];
	}

	/**
	 * @param {THREE.Vector3} position
	 */
	setStart(position) {
		this.startMesh.position.copy(position);
		this.startMesh.position.addScalar(0.5);
		this.startMesh.visible = true;
	}

	/**
	 * @param {THREE.Vector3} position
	 */
	setGoal(position) {
		this.goalMesh.position.copy(position);
		this.goalMesh.position.addScalar(0.5);
		this.goalMesh.visible = true;
	}

	reset() {
		this.startMesh.visible = false;
		this.goalMesh.visible = false;

		this.clearPath();
	}

	/**
	 * @param {number} width
	 * @param {number} height
	 */
	resize(width, height) {
		this.pathLineMaterial.resolution.set(width, height);
	}

	dispose() {
		this.startGoalGeometry.dispose();
		this.pathPointGeometry.dispose();
		this.exploredPointGeometry.dispose();

		this.pathLineMaterial.dispose();
		this.startMaterial.dispose();
		this.goalMaterial.dispose();
		this.pathPointMaterial.dispose();
		this.exploredPointMaterial.dispose();
	}
}
