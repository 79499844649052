import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { LoopRepeat } from "three";

export class LocomotionStateRunningFP extends LocomotionOutputState {
	constructor(animMesh, locomotionInput) {
		super("RunningFP", animMesh, locomotionInput);

		this.transitionToIdleTime = 0.2;
		this.transitionToWalkingTime = 0.2;

		this.itemNovaGunTrack = new AnimationTrack(animMesh, "JMGO_NovaGun_FP_NotAim_Sprint", this);

		this.itemZapperPistolTrack = new AnimationTrack(animMesh, "JMGO_ZapperPistol_FP_NotAim_Sprint", this);

		this.itemNovaRifleTrack = new AnimationTrack(animMesh, "JMGO_NovaRifle_FP_NotAim_Sprint", this);

		this.itemNovaAssaultTrack = new AnimationTrack(animMesh, "JMGO_NovaAssault_FP_NotAim_Sprint", this);

		this.itemZapperRifleTrack = new AnimationTrack(animMesh, "JMGO_ZapperRifle_FP_NotAim_Sprint", this);

		this.itemZapperAssaultTrack = new AnimationTrack(
			animMesh,
			"JMGO_ZapperAssault_FP_NotAim_Sprint",
			this,
		);

		this.idleTrackMap = new Map();
		this.idleTrackMap.set("NovaGun", this.itemNovaGunTrack, this);
		this.idleTrackMap.set("ZapperPistol", this.itemZapperPistolTrack, this);
		this.idleTrackMap.set("NovaRifle", this.itemNovaRifleTrack, this);
		this.idleTrackMap.set("NovaAssault", this.itemNovaAssaultTrack, this);
		this.idleTrackMap.set("ZapperRifle", this.itemZapperRifleTrack, this);
		this.idleTrackMap.set("ZapperAssault", this.itemZapperAssaultTrack, this);

		this.lastTrack = this.itemNovaGunTrack;
	}

	onStateOff() {
		super.onStateOff();
		for (const track of this.animationTracks) {
			track.setCurrentWeight(0);
		}
	}

	startCompute(transitionData) {
		this.lastTrack = this.idleTrackMap.get(this.inputState.item) ?? this.itemNovaGunTrack;

		this.lastTrack.action.reset();
		this.lastTrack.action.loop = LoopRepeat;
		this.lastTrack.switchOn();

		if (transitionData.prvState) {
			const walkAction = this.mesh.getAction("JMGO_Pencil_FP_NotAim_Walk");
			if (walkAction) {
				this.lastTrack.action.syncWith(walkAction);
			}

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.lastTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		if (
			this.lastTrack !== this.idleTrackMap.get(this.inputState.item) &&
			this.idleTrackMap.get(this.inputState.item)
		) {
			this.lastTrack.startFadeOut(0.2);
			this.lastTrack = this.idleTrackMap.get(this.inputState.item);
			this.lastTrack.action.reset();
			this.lastTrack.action.loop = LoopRepeat;
			this.lastTrack.startFadeIn(0.2);
			this.lastTrack.action.play();
		}

		this.updateWeight();

		this.itemNovaGunTrack.update();

		this.itemZapperPistolTrack.update();
		this.itemZapperRifleTrack.update();
		this.itemZapperAssaultTrack.update();
		this.itemNovaAssaultTrack.update();
		this.itemNovaRifleTrack.update();
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				return false;
			} else {
				outTransitionToState.ptr = map.get("WalkingFP");
				outTransitionToState.blendTime = this.transitionToWalkingTime;
				return true;
			}
		}

		outTransitionToState.ptr = map.get("IdleFP");
		outTransitionToState.blendTime = this.transitionToIdleTime;
		return true;
	}
}
