import type { CustomUIComponent, UIData } from "@jamango/content-client";
import * as Net from "router/Net";
import { UI } from "client/dom/UI";
import { netState } from "router/Parallelogram";
import { LoopbackPeer, type Peer } from "@jamango/ibs";

// currently this system is stateless - things are"written to the UI layer directy from net commands
export type CustomUIState = { components: Map<string, CustomUIComponent> };
export type CustomUINetData = { id: string; show: boolean; inputs: UIData };

export const init = (): CustomUIState => {
	return { components: new Map() };
};

export function load(state: CustomUIState, data: Record<string, any>[]) {
	if (!netState.isClient) return;

	// upsert into UI / state track
	const ui = UI.state.customUI();
	ui.clearCustomUI();
	state.components.clear();

	for (const component of data) {
		// upon re-load, customUI is always set to be hidden.
		// this way we can modify this state at runtime without causing any jacy updates
		// only updates from within the ui editor are saved.
		const clone = structuredClone(component);
		clone.show = false;
		ui.upsertComponent(clone);
		state.components.set(component.id, component as CustomUIComponent);
	}
}

export function setInputs(state: CustomUIState, id: string, show: boolean, inputs: UIData) {
	if (!netState.isClient) return;
	if (!state.components.has(id)) {
		console.warn(`Unknown Custom UI component target: ${id}`);
		return;
	}

	// on client write values into the component
	const component = state.components.get(id)!;
	component.show = show;

	// write state / values into component inputs
	for (const k in inputs) {
		const key = k as keyof UIData;
		const val = inputs[key];
		const inp = component.inputs[key];

		if (inp !== undefined && val !== undefined) {
			inp.value = val || inp.defaultValue;
		}
	}

	UI.state.customUI().upsertComponent(component);
}

const sendCustomUi = Net.command("send custom ui", Net.CLIENT, (val: CustomUINetData, world) => {
	setInputs(world.customUI, val.id, val.show, val.inputs);
});

export function broadcastInputs(state: CustomUIState, data: CustomUINetData, peers: Peer[]) {
	// on host, redistribute to peers
	if (!netState.isHost) return;

	// loopback instead displays locally
	for (const peer of peers) {
		if (peer instanceof LoopbackPeer) {
			setInputs(state, data.id, data.show, data.inputs);
		} else {
			sendCustomUi.send(data, peer);
		}
	}
}
