import { netState } from "router/Parallelogram";
import * as Crosshair from "client/world/hud/Crosshair";
import { MathUtils } from "three";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function playerWeaponSpreadUpdate(entity, dt) {
	if (!canUpdateSystem(entity)) return;

	const item = entity.getEquippedItem();
	if (!item || item.itemType.def.isTool) {
		entity.weaponSpread = 0;
	} else {
		// if we have been shooting, or take damage, we accumulate spread. this value recovers over time
		let acc = item.weapon.state.spreadAccumulator;
		acc = MathUtils.clamp(acc - (acc * 5 + 3) * dt, 0, 20);
		item.weapon.state.spreadAccumulator = acc;

		// based on our weapon and movement, we have a spread basline, this is combined with the accumulator
		let spread = item.weapon.def.spread + acc;
		if (entity.movement.state.isIronSights) {
			spread = spread * 0.25;
		} else if (entity.movement.state.isCrouching) {
			spread = spread * 0.5;
		} else if (entity.movement.state.isSprinting) {
			spread = spread + 3;
		} else if (!entity.movement.state.isIdle) {
			spread = spread + 1.3;
		}

		entity.weaponSpread = MathUtils.clamp(spread, 0, 13);
	}

	const crosshairOffset = MathUtils.mapLinear(entity.weaponSpread, 0, 7.5, 0, 0.235);

	Crosshair.updateOffset(entity.world.client.crosshair, crosshairOffset, dt);
}

function canUpdateSystem(entity) {
	return (
		netState.isClient &&
		entity.type.def.isPlayer &&
		entity.world.client.camera.target === entity &&
		entity.hasLocalAuthority() &&
		!entity.mount.state.parent
	);
}
