import * as InputManagerBase from "base/world/InputManager";
import * as InputManagerServer from "server/world/InputManager";
import * as InputManagerClient from "client/world/InputManager";

import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";

export function init() {
	const input = InputManagerBase.init();
	if (netState.isClient) InputManagerClient.init(input);
	if (netState.isHost) InputManagerServer.init(input);
	return input;
}

export function initCommandListeners() {
	if (netState.isHost) InputManagerServer.initCommandListeners();
}

export function tick(world: World, time: number, stepDeltaTime: number) {
	if (netState.isClient) InputManagerClient.pollInputs(world, stepDeltaTime);
	if (netState.isHost) InputManagerServer.tick(world, time, stepDeltaTime);
}

export function reset(world: World) {
	InputManagerBase.reset(world);
}
