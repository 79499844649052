import {
	ItemAnimationType,
	ItemBehaviourType,
	ItemColliderType,
	ItemRangedWeaponType,
	type ItemAsset,
} from "@jamango/content-client";
import type { ItemType } from "./ItemType";
import { Vector3 } from "three";

export const createItemAssetDef = (asset: ItemAsset): ItemType => {
	let type: ItemType["type"];
	if (asset.itemBehaviour.type === ItemBehaviourType.CUSTOM) {
		type = "tool";
	} else if (asset.itemBehaviour.type === ItemBehaviourType.MELEE) {
		type = "melee";
	} else if (asset.itemBehaviour.type === ItemBehaviourType.RANGED_WEAPON) {
		type = "ranged";
	} else {
		type = "tool";
	}

	let geom: ItemType["geom"];
	if (asset.collider.type === ItemColliderType.BOX) {
		geom = {
			type: "box",
			width: asset.collider.size[0],
			height: asset.collider.size[1],
			depth: asset.collider.size[2],
		};
	} else {
		geom = {
			type: "box",
			width: 1,
			height: 1,
			depth: 1,
		};
	}

	const item: ItemType = {
		name: asset.pk,
		display: asset.name,
		itemPk: asset.pk,
		type,
		geom,
		cooldown: asset.itemBehaviour.cooldown,
		ejectImpulse: 0.3,
	};

	if (asset.itemAnimation.type === ItemAnimationType.ONE_HANDED_TOOL) {
		item.animations = "Wrench";
		item.shootAnimationName = {
			RigDefault: {
				first: {
					hip: "JMGO_Wrench_FP_NotAim_Shoot",
				},
				third: "JMGO_Wrench_Shoot_Forward",
			},
		};
	} else if (asset.itemAnimation.type === ItemAnimationType.ONE_HANDED_MELEE) {
		item.animations = "Spade";
		item.shootAnimationName = {
			RigDefault: {
				first: { hip: "JMGO_Spade_FP_NotAim_Shoot" },
				third: "JMGO_Spade_Shoot_Forward",
			},
		};
	} else if (asset.itemAnimation.type === ItemAnimationType.ONE_HANDED_RANGED_WEAPON) {
		item.animations = "NovaGun";
		item.shootAnimationName = {
			RigDefault: {
				first: {
					hip: "JMGO_NovaGun_FP_NotAim_Shoot",
					sight: "JMGO_NovaGun_FP_Aim_Shoot",
				},
				third: "JMGO_NovaGun_Shoot_Forward",
			},
		};
		item.projectilePos = new Vector3(0, 55, -10);
	} else if (asset.itemAnimation.type === ItemAnimationType.TWO_HANDED_RANGED_WEAPON) {
		item.animations = "NovaRifle";
		item.shootAnimationName = {
			RigDefault: {
				first: {
					hip: "JMGO_NovaRifle_FP_NotAim_Shoot",
					sight: "JMGO_NovaRifle_FP_Aim_Shoot",
				},
				third: "JMGO_NovaRifle_Shoot_Forward",
			},
		};
		item.projectilePos = new Vector3(0, 85, -13);
	} else {
		item.animations = "Wrench";
		item.shootAnimationName = {
			RigDefault: {
				first: {
					hip: "JMGO_Wrench_FP_NotAim_Shoot",
				},
				third: "JMGO_Wrench_Shoot_Forward",
			},
		};
	}

	if (
		asset.itemBehaviour.type === ItemBehaviourType.RANGED_WEAPON ||
		asset.itemBehaviour.type === ItemBehaviourType.MELEE
	) {
		item.weapon = true;
	}

	// TODO: Add option to builder. Ammo is not actually consumed yet for "ranged weapon" custom asset items.
	if (asset.itemBehaviour.type === ItemBehaviourType.RANGED_WEAPON) {
		item.projectileCartridgeLimit = 1;
	}

	if (
		asset.itemBehaviour.type === ItemBehaviourType.RANGED_WEAPON &&
		asset.itemBehaviour.rangedWeaponType === ItemRangedWeaponType.AUTOMATIC
	) {
		item.automatic = true;
	}

	if (asset.itemAnimation.type === ItemAnimationType.ONE_HANDED_RANGED_WEAPON) {
		item.shakeVertical = 1;
		item.shakeVerticalMax = 5;
		item.shakeRecovery = 0.95;
		item.recoil = 0.04;
	} else if (asset.itemAnimation.type === ItemAnimationType.TWO_HANDED_RANGED_WEAPON) {
		item.shakeVertical = 0.05;
		item.shakeVerticalMax = 0.5;
		item.shakeHorizontal = 0.2;
		item.shakeHorizontalMax = 1;
		item.shakeRecovery = 0.8;
		item.recoil = 0.02;
	}

	return item;
};
