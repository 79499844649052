import type { Entity } from "base/world/entity/Entity";
import type { Prop } from "base/world/entity/Prop";
import * as Physics from "base/world/Physics";

/**
 * Recreates prop physics bodies if properties have changed, and copies entity velocity
 * physics states to the physics body, which may have been modified by nodes or other systems.
 *
 * NOTE: if setting body transforms and velocities every frame is found to be expensive, we can add dirty flagging.
 */

export function propPhysicsBeforeStepUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const prop = entity as Prop;
	const physics = entity.world.physics;

	// prophecy props have no collision and are always static
	prop.propPhysics.state.desiredMotionType = prop.prophecy.state.isProphecy
		? Physics.MotionType.STATIC
		: prop.propPhysics.def.motionType;
	prop.propPhysics.state.desiredCollisionEnabled = !prop.prophecy.state.isProphecy;

	const physicsNeedsUpdate = prop.physicsNeedsUpdate();

	if (physicsNeedsUpdate) {
		prop.removePhysics();
		prop.addPhysics();
	}

	// update the motion type if necessary
	const motionTypeNeedsUpdate =
		prop.propPhysics.state.desiredMotionType !== prop.propPhysics.state.motionType;

	if (motionTypeNeedsUpdate && prop.propPhysics.state.body) {
		Physics.setBodyMotionType(
			physics,
			prop.propPhysics.state.body,
			prop.propPhysics.state.desiredMotionType,
		);
		prop.propPhysics.state.motionType = prop.propPhysics.state.desiredMotionType;
	}

	// update the degrees of freedom if necessary
	const degreesOfFreedomNeedsUpdate = prop.propPhysics.state.dof !== prop.propPhysics.def.dof;

	if (degreesOfFreedomNeedsUpdate && prop.propPhysics.state.body) {
		Physics.setBodyDegreesOfFreedom(physics, prop.propPhysics.state.body, prop.propPhysics.def.dof);
		prop.propPhysics.state.dof = prop.propPhysics.def.dof;
	}

	// set position and rotation
	// TODO: dirty flag?
	if (prop.propPhysics.state.body) {
		Physics.setBodyPosition(physics, prop.propPhysics.state.body, prop.position);
		Physics.setBodyRotation(physics, prop.propPhysics.state.body, prop.quaternion);
	}

	// set linear and angular velocity
	if (prop.propPhysics.state.motionType === Physics.MotionType.STATIC) {
		prop.propPhysics.state.linearVelocity.set(0, 0, 0);
		prop.propPhysics.state.angularVelocity.set(0, 0, 0);
	} else if (prop.propPhysics.state.body) {
		Physics.setBodyLinearVelocity(
			physics,
			prop.propPhysics.state.body,
			prop.propPhysics.state.linearVelocity,
		);
		Physics.setBodyAngularVelocity(
			physics,
			prop.propPhysics.state.body,
			prop.propPhysics.state.angularVelocity,
		);
	}
}

function canUpdateSystem(entity: Entity) {
	return entity.type.def.isProp;
}
