import { getDefaultPermissions } from "@jamango/engine/PeerMetadata.ts";
import * as Multiplayer from "base/Multiplayer.js";
import * as trigger from "base/rete/modules/trigger";
import { changePermissions } from "server/world/Permissions";
import * as PersistentDataServer from "server/world/persistent-data/PersistentData";
import * as SpawnServer from "server/world/Spawn";
import * as AutoSaveServer from "server/AutoSave";

export class WorldServer {
	/** @type {import('base/world/World').World} */
	base;

	constructor(base) {
		this.base = base;

		this.multiplayer = Multiplayer.initServer();
		this.persistentData = PersistentDataServer.init();
		this.autoSave = AutoSaveServer.init();

		this.spawn = SpawnServer.init();
	}

	initScene() {
		SpawnServer.resetSpawnPoint(this.spawn, this.base.scene);
	}

	clearAll(isMapChange) {
		const noDeleteMe = []; //preserve players and any items

		for (let i = this.base.entities.length - 1; i >= 0; i--) {
			const entity = this.base.entities[i];
			if (!entity.type.def.isPlayer) continue;

			const peer = this.base.playerToPeer.get(entity);
			if (!peer) continue;

			entity.setSpawn(this.spawn.position, this.spawn.angle);
			entity.respawn(true);
			trigger.onPeerJoin(this.base, entity);

			noDeleteMe.push(entity);
		}

		for (let i = this.base.entities.length - 1; i >= 0; i--) {
			const entity = this.base.entities[i];
			if (!noDeleteMe.includes(entity)) entity.dispose();
		}

		for (const peer of this.base.router.getPeers())
			changePermissions(this.base, peer, getDefaultPermissions());

		this.base.rete.state.global = {};
		for (const entityID in this.base.rete.state.entity) this.base.rete.state.entity[entityID] = {};

		this.base.clearAllTimers(isMapChange);
		this.base.sfxManager.serverRemoveAll();
	}
}
