export class SceneTreeComponent {
	name = "sceneTree";

	state: {
		sceneTreeNode?: string;
	};

	constructor(sceneTreeNode: string | undefined) {
		this.state = {
			sceneTreeNode,
		};
	}
}
