import { NODE_TYPE_ID } from "base/rete/Constants";
import { interpreterAnyToString } from "base/rete/NodeSharedUtil";
import { node } from "../Types";

export const STRINGS_NODES = [
	node({
		id: "0005-01-0001",
		name: "Strings Merge",
		type: NODE_TYPE_ID.string,
		description: "Merges (concatenates) two strings",
		predictable: true,
		inputs: {
			string1: { name: "Value", type: "any", structure: "any", control: "string" },
			string2: { name: "Value", type: "any", structure: "any", control: "string" },
		},
		outputs: {
			result: { name: "Result", type: "string" },
		},
		resolve(inputs) {
			return {
				result: interpreterAnyToString(inputs.string1) + interpreterAnyToString(inputs.string2),
			};
		},
	}),
	node({
		id: "29ad4e44-6123-4fb0-af01-c5548a5e5d11",
		name: "Template String",
		type: NODE_TYPE_ID.string,
		description: `Builds a string with a template and variables. You can add a variable by using "{}".`,
		predictable: true,
		info: ["Example:", "Hi {name}! Welcome to {place}!"],
		dynamic: true,
		controls: {
			template: {
				name: "Template",
				type: "string",
				control: "string",
				config: {
					onChange: ({ value, node, editor }: { value: string; node: any; editor: any }) => {
						const placeholders = String(value).match(/{(.*?)}/g);

						const inputs: Record<string, unknown> = {};

						if (placeholders) {
							for (const placeholder of placeholders) {
								const name = placeholder.replace(/{|}/g, "");

								inputs[`_${name}`] = { name, type: "any" };
							}
						}

						editor.setDynamicDefNodeSockets(node.id, { inputs });
					},
				},
			},
		},
		outputs: {
			result: { name: "Result", type: "string" },
		},
		resolve: (inputs) => {
			const dynamicInputs = Object.fromEntries(
				Object.entries(inputs)
					.filter(([key]) => key.startsWith("_"))
					.map(([key, value]) => {
						return [key.slice(1), interpreterAnyToString(value)];
					}),
			);

			let result = inputs.template;

			for (const [key, value] of Object.entries(dynamicInputs)) {
				result = result.replaceAll(`{${key}}`, value);
			}

			return { result };
		},
	}),
];
