import { Vector3, MathUtils } from "three";
import { now } from "base/util/Time.js";
import { netState } from "router/Parallelogram";

const _shake = new Vector3();

export function itemFireUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	const parent = entity.mount.state.parent;
	const isLocal = parent.isLocalInputTarget();
	if (entity.base.state.used) {
		const viewRaycast = parent.viewRaycast.state;

		if (!entity.weapon.def.isAutomatic || (entity.weapon.def.isAutomatic && !entity.fireButtonDown)) {
			entity.base.state.used = false;
		}

		let shouldFire = false;

		if (!entity.weapon.state.isReloading) {
			if (entity.weapon.def.isAutomatic) {
				const curFire = now();

				if (
					entity.weapon.state.firingFirstShot ||
					entity.prvFire + entity.weapon.def.cooldown < curFire
				) {
					shouldFire = true;
					entity.prvFire = now();
					shouldFire = true;
				}
			} else {
				shouldFire = true;
			}
		}

		let fired = false;

		if (shouldFire) {
			const shouldSpawn = (parent.type.def.isNPC && netState.isHost) || (isLocal && netState.isClient);
			fired = entity.fire(shouldSpawn, viewRaycast, parent.weaponSpread);
		}

		if (fired && entity.itemType.def.isRanged) {
			entity.cameraShake.state.shakeVertical += entity.cameraShake.def.shakeVertical;
			entity.cameraShake.state.shakeVertical = Math.min(
				entity.cameraShake.state.shakeVertical,
				entity.cameraShake.def.shakeVerticalMax,
			);

			entity.cameraShake.state.shakeHorizontal += entity.cameraShake.def.shakeHorizontal;
			entity.cameraShake.state.shakeHorizontal = Math.min(
				entity.cameraShake.state.shakeHorizontal,
				entity.cameraShake.def.shakeHorizontalMax,
			);

			entity.weapon.state.recoil += entity.weapon.def.recoil;
			entity.weapon.state.spreadAccumulator += entity.weapon.def.recoil * 100;
		}

		if (isLocal) {
			const camera = entity.world.client.camera;
			const shouldShake =
				fired &&
				parent?.type.def.isCharacter &&
				// camera.target === parent &&
				entity.cameraShake.def.shakeRecovery > 0 &&
				(entity.cameraShake.def.shakeVertical !== 0 || entity.cameraShake.def.shakeHorizontal !== 0);

			if (shouldShake) {
				let shakeMultiplier = 1;

				if (parent.movement.state.isCrouching) {
					shakeMultiplier -= 0.5;
				}

				if (parent.movement.state.isJumping) {
					shakeMultiplier += 0.5;
				}

				camera.makeShake({
					shake: _shake.set(
						entity.cameraShake.state.shakeVertical * shakeMultiplier,
						entity.cameraShake.state.shakeHorizontal * shakeMultiplier,
						0,
					),
					shakeRecovery: entity.cameraShake.def.shakeRecovery,
				});
			}
		}

		entity.weapon.state.firingFirstShot = false;
	} else {
		entity.cameraShake.state.shakeVertical = MathUtils.lerp(
			entity.cameraShake.state.shakeVertical,
			0,
			stepDeltaTime * 10,
		);
		entity.cameraShake.state.shakeHorizontal = MathUtils.lerp(
			entity.cameraShake.state.shakeHorizontal,
			0,
			stepDeltaTime * 10,
		);
	}

	if (entity.weapon.state.recoil > 0 && entity.weapon.state.isReloading) {
		entity.weapon.state.recoil = Math.min(entity.weapon.state.recoil, 0.025);
	}

	if (isLocal) {
		if (entity.weapon.state.recoil > 0) {
			const camera = entity.world.client.camera;
			const easedRecoilAmount = entity.weapon.state.recoil * 0.99;
			const recoilStep = easedRecoilAmount * stepDeltaTime * 15;

			camera.targetPhi += recoilStep;

			entity.weapon.state.recoil -= recoilStep;

			if (entity.weapon.state.recoil < 0) {
				entity.weapon.state.recoil = 0;
			}
		}
	}
}

function canUpdateSystem(entity) {
	return entity.type.def.isItem && entity.mount.state.parent;
}
