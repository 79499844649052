import { WorldUserRole } from "@jamango/content-client";
import { isNullish } from "@jamango/helpers";
import TWEEN from "@tweenjs/tween.js";
import { BB } from "base/BB";
import { Multiplayer } from "base/Multiplayer.js";
import * as CustomUIManager from "base/dom/CustomUI";
import { LoadingScreen } from "base/dom/LoadingScreen";
import * as trigger from "base/rete/modules/trigger";
import * as Rete from "base/rete/rete";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { VY } from "base/util/math/Math";
import * as ChunkManager from "base/world/ChunkManager.ts";
import * as ConstraintManager from "base/world/ConstraintManager";
import * as Metrics from "base/world/MetricsManager";
import * as Physics from "base/world/Physics";
import * as ProjectileManager from "base/world/ProjectileManager.ts";
import * as SceneTree from "base/world/SceneTree";
import { deltaUpdatePipeline, fixedUpdatePipeline, runUpdatePipeline } from "base/world/UpdatesPipeline.js";
import { World } from "base/world/World.js";
import * as WorldEditor from "base/world/WorldEditor";
import * as BlockGroups from "base/world/block/BlockGroups";
import * as BlockTypeRegistry from "base/world/block/BlockTypeRegistry";
import { ChunkScene, SCENE_BUILD_ALREADY } from "base/world/block/Scene";
import { entityObject3DTransformUpdate } from "base/world/entity/system/EntityObject3DTransform";
import * as JacyContentSyncer from "base/world/entity/system/JacyContentSyncer";
import { BBClient } from "client/BB";
import * as GameUIClient from "client/dom/GameWorldUI";
import { UI } from "client/dom/UI.ts";
import * as QuestClient from "client/world/Quest";
import { WorldClient } from "client/world/World.js";
import * as BulletTrailsClient from "client/world/fx/BulletTrails";
import * as InteractLabelsClient from "client/world/fx/InteractLabels";
import * as SelectorVfxClient from "client/world/fx/SelectorVfx";
import * as PencilClient from "client/world/tools/Pencil";
import * as WrenchClient from "client/world/tools/Wrench";
import { getBuiltIn } from "mods/Builtins.js";
import { createItemAssetDef } from "mods/defs/ItemAsset";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { HUDPopupRouter } from "router/dom/HUDPopup.js";
import * as InputManagerRouter from "router/world/InputManager.ts";
import * as PermissionsRouter from "router/world/Permissions";
import * as QuestRouter from "router/world/Quest";
import { SettingsRouter } from "router/world/Settings.js";
import * as WorldEditorRouter from "router/world/WorldEditor";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import * as BlockTypeRegistryRouter from "router/world/block/BlockTypeRegistry";
import * as SkyRouter from "router/world/fx/Sky.js";
import { WorldServer } from "server/world/World.js";
import * as PersistentDataServer from "server/world/persistent-data/PersistentData";
import { Quaternion, Vector3 } from "three";
import * as AutoSaveServer from "server/AutoSave";

//entity classes
import { Character } from "base/world/entity/Character";
import { Item } from "base/world/entity/Item";
import { ParticleSystemEntity } from "base/world/entity/ParticleSystem";
import { Prop } from "base/world/entity/Prop";
import { Text3DEntity } from "base/world/entity/Text3D";

const _gravity = new Vector3();

const _quaternion = new Quaternion();

export class WorldRouter {
	/** @type {import('base/world/World').World} */
	base;

	/** @type {import('ibs').IBSBase?} */
	ibs;

	/** @type {InputManagerRouter} */
	input;

	/** The fixed time step size */
	fixedTimeStep = 1 / 60;

	/** The fixed time step accumulator */
	fixedTimeStepAccumulator = 0;

	//STAGE 1
	/**
	 * @param {import('@jamango/content-client/lib/types/engine.ts').IEngineWorldData} o
	 * @param {import("@jamango/content-client").JacyContent} content
	 */
	constructor(o, content) {
		this.isFullyLoaded = false;

		const base = (this.base = new World(o, content));
		if (netState.isHost) base.server = new WorldServer(base);
		if (netState.isClient) base.client = new WorldClient(base, o);

		base.router = this;

		this.initComplete = false;
		this.initStartTime = performance.now();
	}

	//STAGE 2
	//client downloads assets

	//STAGE 3
	async init() {
		const o = this.base.def;

		this.base.init();

		this.base.editor = WorldEditor.init();

		if (netState.isClient) this.base.client.init();

		this.base.sceneTree = SceneTree.init();

		this.base.physics = Physics.init();
		Physics.setGravity(this.base.physics, o?.gravity ?? _gravity.set(0, -30, 0));

		this.base.quest = QuestRouter.init();
		this.base.input = InputManagerRouter.init();
		this.base.projectiles = ProjectileManager.init();
		this.base.constraints = ConstraintManager.init();
		this.base.chunks = ChunkManager.init();
		this.base.hudPopup = new HUDPopupRouter(this.base).base;
		this.base.customUI = CustomUIManager.init(this.base);
		this.base.blockGroups = BlockGroups.init();
		this.base.scene = new ChunkScene(this.base, o.chunkScene); //main 3D scene
		this.base.blockTypeRegistry = BlockTypeRegistry.init();
		this.base.sky = SkyRouter.init(this.base, o.sky);
		this.base.environmentSettings = new SettingsRouter(this.base, o).base;
		this.base.jacySyncer = JacyContentSyncer.init();
		this.base.rete = Rete.init();

		BlockTypeRegistryRouter.createBlockTypes(this.base, o.blockTypeRegistry?.mapReorderBlocks);

		if (o.blockGroups) {
			BlockGroups.load(this.base.blockGroups, this.base.rete, o.blockGroups);
		}

		if (o.sceneTree) {
			SceneTree.load(this.base.sceneTree, o.sceneTree);
		}

		if (o.editor !== undefined) {
			WorldEditorRouter.applyPatch(this.base.editor, o.editor);
		}

		let mountLater; //must wait for client.initScene before mounting

		this.base.initScene();
		if (netState.isHost) this.base.server.initScene();

		if (!netState.isHost) {
			mountLater = this.clientInitDownloadedEntities();
		}

		if (netState.isClient) {
			this.base.client.initScene(
				netState.isHost ? "loopback" : Net.getPeerId(),
				this.initWorldPlayer(),
			);

			if (netState.isHost) {
				const loopback = this.base.client.loopbackPeer;
				const metadata = getPeerMetadata(loopback);

				metadata.role = o?.role ?? WorldUserRole.OWNER;
				UI.gameMultiplayer.setPeers([metadata]);

				// retrieve persistent data before running on peer join rete trigger
				// note: `await` isn't strictly necessary right now as the client is not passed an impl,
				// so nothing asyncronous is done within `onPeerJoin`. however this may change in future,
				// so awaiting to adhere to the interface
				await PersistentDataServer.onPeerJoin(
					this.base.rete,
					this.base.server.persistentData,
					this.base.server.multiplayer.worldId,
					loopback,
				);

				trigger.onPeerJoin(this.base, this.base.client.getPeerPlayer());
			}
		}

		if (!netState.isHost) this.clientInitDownloadedMounts(mountLater);

		this.netUpdateStamp = performance.now();
		this.initModScripts();

		if (netState.isHost) {
			this.onMultiplayerChange(false);
		}

		this.initComplete = true;
	}

	initWorldPlayer() {
		let player;
		if (netState.isHost) {
			player = this.createPlayer(UI.state.player().username, UI.user.avatarId());
		} else {
			player = this.base.getEntity(this.base.def.targetID);
			player.setOwner(Net.getPeerId());
		}

		return player;
	}

	clientInitDownloadedEntities() {
		const entities = this.base.def.entities;

		if (!entities) return;

		const mountLater = [];

		for (const e of entities) {
			const [
				def,
				x,
				y,
				z,
				qx,
				qy,
				qz,
				qw,
				entityID,
				sceneTreeNode,
				serializedState,
				mountEntityId,
				mountIndex,
			] = e;

			const entity = this.createEntity({
				def: def,
				id: entityID,
				sceneTreeNode: sceneTreeNode ?? undefined,
				x: x,
				y: y,
				z: z,
				qx,
				qy,
				qz,
				qw,
			});

			entity.deserialize(serializedState);

			if (!isNullish(mountEntityId))
				mountLater.push({ entity, parent: mountEntityId, index: mountIndex });
		}

		return mountLater;
	}

	clientInitDownloadedMounts(mountLater) {
		for (const m of mountLater) m.entity.onMount(this.base.getEntity(m.parent), true, m.index);
	}

	initModScripts() {
		for (const def of this.base.defs.values()) {
			if (netState.isHost) {
				def.server?.();
				if (netState.isClient) def.client?.();
			} else {
				evalClientScript(def);
			}
		}

		function evalClientScript(def) {
			let ogDef;
			try {
				ogDef = getBuiltIn(def.name); //temporary until we get shadowrealms
			} catch {} //rest-client defs are not built-ins

			ogDef?.client?.();
		}
	}

	//STAGE 3 ends after first call to update()
	update(dt) {
		this.base.time += dt;

		// metrics manager ticks at any time regardless of loading
		Metrics.update(this.base, dt);

		// iterate entities and check their surroundings. if any entity doesnt have nearby chunks, we load them
		// the client will interrupt its loop until everything is loaded
		const didInvokeChunks = this.invokeChunks() > 0;

		// clients shut down their loop while chunks are being invoked
		if (!netState.isHost && netState.isClient) {
			if (didInvokeChunks) {
				LoadingScreen.show();
				return;
			} else {
				LoadingScreen.hide();
			}
		}

		// if we get to this point, we know for sure that an active loading screen can be removed
		if (netState.isClient) LoadingScreen.hide();

		if (!this.isFullyLoaded) {
			BB.logger.info(
				`Time to first frame: ${((performance.now() - this.initStartTime) / 1000).toFixed(2)}s`,
			);
			this.isFullyLoaded = true;
		}
		Metrics.perfStart("update", "update sum");

		if (netState.isClient) {
			this.base.client.camera.preUpdate(dt);
			UI.updateKeyboardInput();
		}

		Metrics.perfStart("update", "input manager");
		InputManagerRouter.tick(this.base, this.base.time, dt);
		Metrics.perfEnd("update", "input manager");

		/* fixed time step */
		this.fixedTimeStepAccumulator += dt;

		Metrics.setStatic("update", "fixedTimeStepAccumulator", this.fixedTimeStepAccumulator);

		// client death spiral prevention - if 10 frames behind, set this.fixedTimeStepAccumulator to this.fixedTimeStep
		if (netState.isClient && this.fixedTimeStepAccumulator > this.fixedTimeStep * 10) {
			Metrics.increment("update", "fixedTimeStepAccumulatorReset", 1);
			this.fixedTimeStepAccumulator = this.fixedTimeStep;
		}

		Metrics.perfStart("update", "fixedUpdate");
		let fixedUpdateSteps = 0;
		while (this.fixedTimeStepAccumulator >= this.fixedTimeStep) {
			fixedUpdateSteps++;
			this.fixedTimeStepAccumulator -= this.fixedTimeStep;

			Metrics.perfStart("update", "fixedUpdatePipeline");
			runUpdatePipeline(this.base, fixedUpdatePipeline, this.fixedTimeStep);
			Metrics.perfEnd("update", "fixedUpdatePipeline");

			SceneTree.update(this.base.sceneTree, this.base);

			Metrics.perfStart("update", "rete");
			Rete.update(BB.world.content, this.base, this.fixedTimeStep, this.base.time);
			Metrics.perfEnd("update", "rete");

			// consumes discrete input commands
			InputManagerRouter.reset(this.base);

			BlockTypeRegistryRouter.update(this.base);
			BlockGroupsRouter.update(this.base.blockGroups, this.base);
			ProjectileManager.update(this.base, this.base.projectiles, this.base.time, this.fixedTimeStep);
		}
		Metrics.perfEnd("update", "fixedUpdate");

		Metrics.setStatic("update", "fixedUpdateSteps", fixedUpdateSteps);

		// interpolate entity visuals
		Metrics.perfStart("update", "interpolate");
		const fixedTimeStepAlpha = this.fixedTimeStepAccumulator / this.fixedTimeStep;
		for (const e of this.base.entities) {
			entityObject3DTransformUpdate(e, fixedTimeStepAlpha);
		}
		Metrics.perfEnd("update", "interpolate");

		// update chunk manager
		Metrics.perfStart("update", "chunks");
		ChunkManager.update(this.base, this.base.chunks, this.base.time, dt);
		Metrics.perfEnd("update", "chunks");

		// delta update entity pipeline
		Metrics.perfStart("update", "deltaUpdatePipeline");
		runUpdatePipeline(this.base, deltaUpdatePipeline, dt);
		Metrics.perfEnd("update", "deltaUpdatePipeline");

		// update tools
		if (netState.isClient) {
			PencilClient.update(this.base.client.pencil, this.base);
			WrenchClient.update(this.base.client.wrench, this.base);
		}

		// run other misc systems
		SkyRouter.update(this.base, dt);

		if (netState.isClient) {
			if (this.base.scene.water) {
				this.base.scene.water.client.update(dt);
			}
			QuestClient.update(this.base.quest, this.base);
			Metrics.perfStart("update", "vfx particles");
			this.base.client.particleEngine.update(dt);
			this.base.client.particleEngineV2.update(dt);
			Metrics.perfEnd("update", "vfx particles");
			this.base.client.vignette.update();
			Metrics.perfStart("update", "vfx selector");
			SelectorVfxClient.update(this.base.client.selectorVfx, this.base, this.base.time, dt);
			Metrics.perfEnd("update", "vfx selector");
			Metrics.perfStart("update", "vfx labels");
			InteractLabelsClient.update(this.base.client.interactLabels, this.base);
			Metrics.perfEnd("update", "vfx labels");
			Metrics.perfStart("update", "vfx bullet trails");
			BulletTrailsClient.update(this.base, this.base.projectiles, this.base.client.bulletTrails, dt);
			Metrics.perfEnd("update", "vfx bullet trails");
		}

		this.base.scene.postUpdate();

		TWEEN.update();

		if (netState.isClient) {
			BB.client.debug.update(dt);

			GameUIClient.update(this.base.client.gameWorldUI, this.base);
		}

		// render + scene graph
		this.base.scene.updateMatrixWorld();
		if (netState.isClient && !document.hidden) {
			this.base.client.render();
		}

		this.base.dispatcher.dispatchEvent(null, { type: "render", deltaTime: dt }, false);

		this.base.frame++;

		//safe to dispose now that everything is rendererd
		while (this.base.deferredDispose.length > 0) {
			this.base.deferredDispose[this.base.deferredDispose.length - 1].dispose();
		}

		if (netState.isHost) {
			PersistentDataServer.updateBackgroundJobs(
				this.base.rete,
				this.base.server.persistentData,
				this.base.server.multiplayer.worldId,
				Array.from(Net.getPeers()),
			);
		}

		// dispatch net
		Net.flush();

		// handle content changes
		JacyContentSyncer.updateJacyContentSyncer(this.base);

		// auto save update
		if (netState.isHost) {
			AutoSaveServer.update(this.base.server.autoSave, this.base);
		} 

		// update the input poll (reset stateful fields like "just pressed")
		if (netState.isClient) {
			BBClient.inputPoll.update();
		}

		Metrics.perfEnd("update", "update sum");

		return true;
	}

	invokeChunks() {
		// we check chunks around our entities based on their chunkInvoker component
		// on the host, we do this for all (we generate them ourselves)
		// on the clients, we only do this for all entities on first load. afterwards, only 2x2 around player
		// this is to prevent loading screens caused by non-player entities mid-gameplay
		const chunkSize = this.base.scene.chunkSize;
		const entities = netState.isHost ? this.base.entities : [this.base.client.getPeerPlayer()];

		let invokeCount = 0;
		for (const e of entities) {
			if (!e.shouldInvokeChunks()) continue;

			// this ensures a 2x2x2 set of chunks around entities to allow for nearby collisions
			// we math.round the position first, to ensure unstable positions on chunk borders are less noisy
			const cxMin = Math.floor(Math.round(e.position.x) / chunkSize - 0.5);
			const cyMin = Math.floor(Math.round(e.position.y) / chunkSize - 0.5);
			const czMin = Math.floor(Math.round(e.position.z) / chunkSize - 0.5);

			// skip if chunk is same
			const inv = e.chunkInvoker.state;
			if (cxMin === inv.lastX && cyMin === inv.lastY && czMin === inv.lastZ) {
				continue;
			}
			if (netState.isHost) {
				// on the host, as soon as a chunk is invoked, we get that data. therefore, we can stop checking for entities
				// on the non-host, this system works differently - we wait for chunks to be transmitted. therefore we need to re-check every frame, and this we cant use this early exit logic
				inv.lastX = cxMin;
				inv.lastY = cyMin;
				inv.lastZ = czMin;
			}

			for (let cy = cyMin; cy <= cyMin + 1; cy++) {
				for (let cz = czMin; cz <= czMin + 1; cz++) {
					for (let cx = cxMin; cx <= cxMin + 1; cx++) {
						if (netState.isHost) {
							// host - actually makes chunks
							const result = this.base.scene.buildChunk(cx, cy, cz, false);
							if (result !== SCENE_BUILD_ALREADY && !e.mount.state.parent) {
								invokeCount++;
							}
						} else {
							// peer - doesnt make chunk. checks if it exists (without allocation)
							// if it doesnt exist, we know that we're missing a crucial chunk
							const chunk = this.base.scene.chunks.getRaw(cx, cy, cz);
							if (chunk === undefined || !chunk.built) {
								invokeCount++;
							}
						}
					}
				}
			}
		}

		return invokeCount;
	}

	createPlayer(username, avatarID) {
		const p = this.base.server.spawn.position;
		const r = this.base.server.spawn.angle;

		// note - avatar must at this point be parsed by jacy content machine!
		const avatar = BB.world.content.state.avatars.get(BB.world.content.state.avatars.generatePK(avatarID));
		const avatarObject = avatar
			? BB.world.content.state.avatars.convertConfigToAvatarObject(avatar)
			: undefined;

		const quaternion = _quaternion.setFromAxisAngle(VY, r);
		const entity = this.createEntity({
			def: this.base.playerDef,
			x: p.x,
			y: p.y,
			z: p.z,
			qx: quaternion.x,
			qy: quaternion.y,
			qz: quaternion.z,
			qw: quaternion.w,
		});
		entity.setAvatar(avatarObject);
		entity.nameplate.def.nameplate.setText(username);

		return entity;
	}

	/**
	 * Create an entity in the world.
	 * @param {Omit<import("base/world/entity/Entity").EntityCreateOptions, "id"> & { id?: import("@jamango/engine/EntityID").EntityID}} o
	 * @returns {import("base/world/entity/Entity").Entity} The created entity.
	 */
	createEntity(o) {
		if (o.id === undefined) {
			if (netState.isHost) {
				o.id = this.base.entityIdCounter++;
			} else {
				throw new Error("Client is attempting to create an entity without an ID");
			}
		}

		const defName = o.def;
		const armoryItem = defName.startsWith("ArmoryItem"); //this is admittedly bretty hacky

		let EntityConstructor;
		if (defName.startsWith("Player")) EntityConstructor = Character;
		else if (defName.startsWith("Item") || defName.startsWith("item#") || armoryItem)
			EntityConstructor = Item;
		else if (defName === "Text3D") EntityConstructor = Text3DEntity;
		else if (defName === "ParticleSystem") EntityConstructor = ParticleSystemEntity;
		else if (defName === "Prop") EntityConstructor = Prop;
		else throw Error(`Can't instantiate def as an entity: "${defName}"`);

		let def;
		if (defName.startsWith("item#")) {
			const asset = this.base.content.state.items.get(defName);
			def = createItemAssetDef(asset);
		} else if (armoryItem) {
			def = this.base.defs.get(defName.substr("Armory".length));
		} else if (EntityConstructor === Prop) {
			def = { name: "Prop" };
		} else {
			def = this.base.defs.get(defName);
		}
		if (!def) throw Error(`Unknown def "${defName}"`);

		const entity = new EntityConstructor(o, def, this.base);

		this.base.scene.add(entity.object3D);
		this.base.addEntity(entity);

		if (armoryItem) {
			entity.setArmoryMode();
		}

		if (netState.isHost && entity.authority.state.public < 2) {
			this.base.input.commands.push([
				"create",
				this.base.scene.mapID,
				defName,
				o.x,
				o.y,
				o.z,
				o.qx,
				o.qy,
				o.qz,
				o.qw,
				entity.entityID,
				o.sceneTreeNode ?? null,
			]);
		}
		this.base.dispatcher.dispatchEvent(null, { type: "createentity", entity });

		return entity;
	}

	/**
	 * @returns {Array<import('ibs').Peer>}
	 */
	getPeers() {
		if (!netState.isHost) throw Error("Cant access Peers on non-host environments");

		/**
		 * @type {import('ibs').Peer[]}
		 */
		const peers = [];

		if (netState.isClient) {
			peers.push(this.base.client.loopbackPeer);
		}

		peers.push(...Net.getPeers());

		return peers;
	}

	getPeersMap() {
		if (!netState.isHost) throw Error("Cant access Peers on non-host environments");

		// TODO: remove all of this. the peers object should be on the Net. layer and always contain the loopback.
		const peers = new Map([...Net.getPeersMap()]);
		if (netState.isClient) {
			peers.set(this.base.client.loopbackPeer.peerID, this.base.client.loopbackPeer);
		}
		return peers;
	}

	getMaxPeers() {
		if (!netState.isHost) throw Error("Cant access Peers on non-host environments");

		let max = 0;
		if (netState.isClient) max++;

		max += Net.getMaxPeers();

		return max;
	}

	onMultiplayerChange(isOnline) {
		if (netState.isClient) UI.state.multiplayer().setStatus(!isOnline ? "Offline" : null);
	}

	initCommandListeners() {
		this.base.scene.initCommandListeners();
		this.base.sfxManager.initCommandListeners();
		this.base.environmentSettings.router.initCommandListeners();
		InputManagerRouter.initCommandListeners();
		this.base.hudPopup.router.initCommandListeners();
		QuestRouter.initCommandListeners();
		PermissionsRouter.initCommandListeners();
		JacyContentSyncer.initCommandListeners();
		Metrics.initCommandListeners();

		this.base.dispatcher.dispatchEvent(null, { type: "initcommandlisteners" });
	}

	dispose() {
		this.base.dispose();
		TWEEN.removeAll();

		if (!this.initComplete) return;

		Multiplayer.disconnect(true);

		if (netState.isClient) this.base.client.dispose();

		BlockTypeRegistry.dispose(this.base.blockTypeRegistry);
		ConstraintManager.dispose(this.base.constraints);
		Physics.dispose(this.base.physics);
	}
}
