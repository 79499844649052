import { NODE_TYPE_ID } from "base/rete/Constants";
import { DEGRAD, PI, RADDEG, rand, randInt } from "base/util/math/Math.ts";
import { node } from "base/rete/Types";
import { createLogger } from "@jamango/helpers";
import { MathUtils } from "three";

const logger = createLogger("Math nodes");

export const MATH = [
	// Arithmetic
	node({
		id: "0004-01-0001",
		name: "Add (+)",
		type: NODE_TYPE_ID.math,
		description: "Adds two values",
		predictable: true,
		inputs: {
			num1: { name: "Value", type: "number", control: "number" },
			num2: { name: "Value", type: "number", control: "number" },
		},
		outputs: {
			sum: { name: "Sum", type: "number" },
		},
		resolve(inputs) {
			return { sum: Number(inputs.num1 ?? 0) + Number(inputs.num2 ?? 0) };
		},
	}),
	node({
		id: "0004-01-0002",
		name: "Subtract (-)",
		type: NODE_TYPE_ID.math,
		description: "Subtracts two values",
		predictable: true,
		inputs: {
			num1: { name: "Value", type: "number", control: "number" },
			num2: { name: "Value", type: "number", control: "number" },
		},
		outputs: {
			difference: { name: "Difference", type: "number" },
		},
		resolve(inputs) {
			return { difference: Number(inputs.num1 ?? 0) - Number(inputs.num2 ?? 0) };
		},
	}),
	node({
		id: "0004-01-0003",
		name: "Multiply (×)",
		type: NODE_TYPE_ID.math,
		description: "Multiplies two values",
		predictable: true,
		inputs: {
			num1: { name: "Value", type: "number", control: "number" },
			num2: { name: "Value", type: "number", control: "number" },
		},
		outputs: {
			product: { name: "Product", type: "number" },
		},
		resolve(inputs) {
			return { product: Number(inputs.num1 ?? 0) * Number(inputs.num2 ?? 0) };
		},
	}),
	node({
		id: "0004-01-0004",
		name: "Divide (÷)",
		type: NODE_TYPE_ID.math,
		description: "Divides two values",
		predictable: true,
		inputs: {
			num1: { name: "Value", type: "number", control: "number" },
			num2: { name: "Value", type: "number", control: "number" },
		},
		outputs: {
			quotient: { name: "Quotient", type: "number" },
		},
		resolve(inputs) {
			return { quotient: Number(inputs.num1 ?? 0) / Number(inputs.num2 ?? 1) };
		},
	}),
	node({
		id: "0004-01-0005",
		name: "Modulo (%)",
		type: NODE_TYPE_ID.math,
		description: "Divides two values and returns the remainder",
		predictable: true,
		inputs: {
			num1: { name: "Value", type: "number", control: "number" },
			num2: { name: "Value", type: "number", control: "number" },
		},
		outputs: {
			remainder: { name: "Remainder", type: "number" },
		},
		resolve(inputs) {
			return { remainder: Number(inputs.num1 ?? 0) % Number(inputs.num2 ?? 0) };
		},
	}),
	node({
		id: "0004-01-0006",
		name: "Random Float",
		type: NODE_TYPE_ID.math,
		description: "Returns a random floating point number in the range [min, max)",
		inputs: {
			min: {
				name: "Minimum (inclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			max: {
				name: "Maximum (exclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 1 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return { value: rand(inputs.min ?? 0, inputs.max ?? 1) };
		},
	}),
	node({
		id: "0004-01-0007",
		name: "Random Int",
		type: NODE_TYPE_ID.math,
		description: "Returns a random integer number in the range [min, max]",
		inputs: {
			min: {
				name: "Minimum (inclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			max: {
				name: "Maximum (inclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 5 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return { value: randInt(inputs.min ?? 0, inputs.max ?? 1) };
		},
	}),
	node({
		id: "0004-01-0008",
		name: "Floor",
		type: NODE_TYPE_ID.math,
		description: "Rounds down and returns the largest integer less than or equal to a given number",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return { value: Math.floor(inputs.number) };
		},
	}),
	node({
		id: "0004-01-0009",
		name: "Ceil",
		type: NODE_TYPE_ID.math,
		description: "Rounds up and returns the smallest integer greater than or equal to a given number",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return { value: Math.ceil(inputs.number) };
		},
	}),
	node({
		id: "0004-01-0010",
		name: "Square Root",
		type: NODE_TYPE_ID.math,
		description: "Returns the square root of a number",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return { value: Math.sqrt(inputs.number) };
		},
	}),
	node({
		id: "0004-01-0011",
		name: "Round",
		type: NODE_TYPE_ID.math,
		description: "Rounds a number to the nearest integer",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}

			return { value: Math.round(Number(inputs.number ?? 0)) };
		},
	}),
	node({
		id: "0004-01-0012",
		name: "Power",
		type: NODE_TYPE_ID.math,
		description: "Raises a number to the power of X",
		predictable: true,
		inputs: {
			number: {
				name: "Base",
				type: "number",
				config: { defaultValue: 0 },
				control: "number",
			},
			power: {
				name: "Exponent",
				type: "number",
				config: { defaultValue: 0 },
				control: "number",
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}

			return {
				value: Math.pow(Number(inputs.number ?? 0), Number(inputs.power ?? 0)),
			};
		},
	}),
	node({
		id: "0004-01-0015",
		name: "Log",
		type: NODE_TYPE_ID.math,
		description: "Returns the logarithm of y with base x (i.e. log 𝑥 (𝑦))",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				config: { defaultValue: 0 },
				control: "number",
			},
			base: {
				name: "Base",
				type: "number",
				config: { defaultValue: 0 },
				control: "number",
			},
		},
		outputs: {
			value: { name: "Result", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}
			if (Number.isNaN(inputs.base)) {
				logger.warn("Base is NaN");
			}

			switch (inputs.base) {
				case 10:
					return { value: Math.log10(inputs.number) };
				case Math.E:
					return { value: Math.log(inputs.number) };
				default:
					return {
						value: Math.log(Number(inputs.number ?? 0)) / Math.log(Number(inputs.base ?? 0)),
					};
			}
		},
	}),
	node({
		id: "e6171082-30f0-4a66-ad62-cc85da49fe47",
		name: "Euler's Number (e)",
		type: NODE_TYPE_ID.math,
		description: "Returns the mathematical constant e, the base of natural logartithms",
		predictable: true,
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve() {
			return { value: Math.E };
		},
	}),
	// Trigonometry
	node({
		id: "0004-02-0001",
		name: "Pi (π)",
		type: NODE_TYPE_ID.math,
		description: "Returns the mathematical constant PI",
		predictable: true,
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve() {
			return { value: PI };
		},
	}),
	node({
		id: "0004-02-0002",
		name: "Degrees To Radians",
		type: NODE_TYPE_ID.math,
		description: "Converts degrees to radians",
		predictable: true,
		inputs: {
			degrees: {
				name: "Degrees",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			radians: { name: "Radians", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.degrees)) {
				logger.warn("Degrees is NaN");
			}

			return { radians: Number(inputs.degrees ?? 0) * DEGRAD };
		},
	}),
	node({
		id: "0004-02-0003",
		name: "Radians To Degrees",
		type: NODE_TYPE_ID.math,
		description: "Converts radians to degrees",
		predictable: true,
		inputs: {
			radians: {
				name: "Radians",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			degrees: { name: "Degrees", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.radians)) {
				logger.warn("Radians is NaN");
			}

			return { degrees: Number(inputs.radians ?? 0) * RADDEG };
		},
	}),
	node({
		id: "0004-02-0004",
		name: "Sin θ",
		type: NODE_TYPE_ID.math,
		description: "Returns the sine of an angle",
		predictable: true,
		inputs: {
			angle: {
				name: "Angle (radians)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.angle)) {
				logger.warn("Angle is NaN");
			}

			return { value: Math.sin(Number(inputs.angle ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0005",
		name: "Cos θ",
		type: NODE_TYPE_ID.math,
		description: "Returns the cosine of an angle",
		predictable: true,
		inputs: {
			angle: {
				name: "Angle (radians)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.angle)) {
				logger.warn("Angle is NaN");
			}

			return { value: Math.cos(Number(inputs.angle ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0006",
		name: "Tan θ",
		type: NODE_TYPE_ID.math,
		description: "Returns the tangent of an angle",
		predictable: true,
		inputs: {
			angle: {
				name: "Angle (radians)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.angle)) {
				logger.warn("Angle is NaN");
			}

			return { value: Math.tan(Number(inputs.angle ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0007",
		name: "Asin (o:h)",
		type: NODE_TYPE_ID.math,
		description: "Returns the arcsine (or inverse sine) of a number, in radians",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}

			return { value: Math.asin(Number(inputs.number ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0008",
		name: "Acos (a:h)",
		type: NODE_TYPE_ID.math,
		description: "Returns the arccosine (or inverse cosine) of a number, in radians",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}

			return { value: Math.acos(Number(inputs.number ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0009",
		name: "Atan (o:a)",
		type: NODE_TYPE_ID.math,
		description: "Returns the arctangent (or inverse tangent) of a number, in radians",
		predictable: true,
		inputs: {
			number: {
				name: "Number",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.number)) {
				logger.warn("Number is NaN");
			}

			return { value: Math.atan(Number(inputs.number ?? 0)) };
		},
	}),
	node({
		id: "0004-02-0010",
		name: "Atan Quadrant-Aware",
		type: NODE_TYPE_ID.math,
		description:
			"Returns the counter-clockwise angle, in radians, between a 2D vector (x, y) and the positive X axis (1, 0)",
		predictable: true,
		inputs: {
			x: {
				name: "X",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			y: {
				name: "Y",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			if (Number.isNaN(inputs.y)) {
				logger.warn("Y is NaN");
			}

			if (Number.isNaN(inputs.x)) {
				logger.warn("X is NaN");
			}

			return { value: Math.atan2(Number(inputs.y ?? 0), Number(inputs.x ?? 0)) };
		},
	}),
	node({
		id: "47860b00-53f4-4cca-93d9-7c3361ebc32e",
		name: "Clamp Number",
		type: NODE_TYPE_ID.math,
		description: "Clamps a number between a minimum and maximum value",
		predictable: true,
		inputs: {
			value: { name: "Value", type: "number", control: "number" },
			min: { name: "Min", type: "number", control: "number" },
			max: { name: "Max", type: "number", control: "number" },
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			return {
				value: MathUtils.clamp(inputs.value, inputs.min, inputs.max),
			};
		},
	}),
	node({
		id: "aabc73d7-cee9-4238-a78a-0b0a111362c2",
		name: "Remap Number",
		type: NODE_TYPE_ID.math,
		description: "Remaps a number from one range to another",
		predictable: true,
		inputs: {
			number: { name: "Number", type: "number", control: "number" },
			inLow: { name: "In Low", type: "number", control: "number" },
			inHigh: { name: "In High", type: "number", control: "number" },
			outLow: { name: "Out Low", type: "number", control: "number" },
			outHigh: { name: "Out High", type: "number", control: "number" },
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			const scale = (inputs.number - inputs.inLow) / (inputs.inHigh - inputs.inLow);
			const clamped = inputs.outLow + scale * (inputs.outHigh - inputs.outLow);
			return {
				value: clamped,
			};
		},
	}),
	node({
		id: "274d0fb1-573a-406e-bf13-3a44bd121722",
		name: "Remap Clamp Number",
		type: NODE_TYPE_ID.math,
		description: "Remaps a number from one range to another and clamps the result",
		predictable: true,
		inputs: {
			value: { name: "Value", type: "number", control: "number" },
			inLow: { name: "In Low", type: "number", control: "number" },
			inHigh: { name: "In High", type: "number", control: "number" },
			outLow: { name: "Out Low", type: "number", control: "number" },
			outHigh: { name: "Out High", type: "number", control: "number" },
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			const scale = (inputs.value - inputs.inLow) / (inputs.inHigh - inputs.inLow);
			const remapped = inputs.outLow + scale * (inputs.outHigh - inputs.outLow);
			const clamped = Math.max(inputs.outLow, Math.min(inputs.outHigh, remapped));
			return {
				value: clamped,
			};
		},
	}),
];
