import type { ChunkScene } from "base/world/block/Scene";
import type { World } from "base/world/World";
import { Vector3 } from "three";
import { entityIsPlayer } from "../../base/world/entity/component/Type";

export const init = () => {
	return {
		position: new Vector3(),
		angle: 0,
	};
};

export const setGlobalSpawn = (
	state: SpawnPointState,
	world: World,
	x: number,
	y: number,
	z: number,
	angle: number,
) => {
	state.position.set(x, y, z);
	state.angle = angle;

	for (const e of world.entities) {
		if (!entityIsPlayer(e)) continue;
		e.setSpawn(state.position, angle);
	}
};

export const resetSpawnPoint = (state: SpawnPointState, scene: ChunkScene) => {
	const y = scene.getTerrainHeight(0, 0);
	state.position.set(0, y, 0);
	state.angle = 0;
};

export type SpawnPointState = ReturnType<typeof init>;
