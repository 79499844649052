import { BlockType } from "base/world/block/Type.js";
import { FakeWaterClient } from "client/world/fx/FakeWater.js";
import * as SkyClient from "client/world/fx/Sky.js";
import { assets as questAssets } from "client/world/Quest";

export const BuiltInAssets = [
	//contains all default sfx
	{ id: "snd-default", url: "assets/engine/sfx/snd-default.ogg", type: "audio/ogg" },
	{
		id: "snd-inventory-open",
		url: "assets/engine/sfx/snd-inventory-open.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-inventory-close",
		url: "assets/engine/sfx/snd-inventory-close.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-block-scroll",
		url: "assets/engine/sfx/snd-game/snd-ui-block-scroll.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-basic-selection-start",
		url: "assets/engine/sfx/snd-game/snd-ui-basic-selection-start.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-basic-selection-end",
		url: "assets/engine/sfx/snd-game/snd-ui-basic-selection-end.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-menu-open",
		url: "assets/engine/sfx/snd-game/snd-ui-menu-open.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-menu-close",
		url: "assets/engine/sfx/snd-game/snd-ui-menu-close.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-menu-drop",
		url: "assets/engine/sfx/snd-game/snd-ui-menu-drop.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-menu-pickup",
		url: "assets/engine/sfx/snd-game/snd-ui-menu-pickup.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-menu-tab",
		url: "assets/engine/sfx/snd-game/snd-ui-menu-tab.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-pencil-selection-clear",
		url: "assets/engine/sfx/snd-game/snd-ui-pencil-selection-clear.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-pencil-selection-fill",
		url: "assets/engine/sfx/snd-game/snd-ui-pencil-selection-fill.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-wrench-editor-close",
		url: "assets/engine/sfx/snd-game/snd-ui-wrench-editor-close.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-wrench-editor-open",
		url: "assets/engine/sfx/snd-game/snd-ui-wrench-editor-open.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-wrench-menu-close",
		url: "assets/engine/sfx/snd-game/snd-ui-wrench-menu-close.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-ui-wrench-menu-open",
		url: "assets/engine/sfx/snd-game/snd-ui-wrench-menu-open.ogg",
		type: "audio/ogg",
	},
	// 1 - 3 Man Grunt
	{
		id: "snd-grunt-1",
		url: "assets/engine/sfx/snd-death/snd-grunt-1.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-grunt-2",
		url: "assets/engine/sfx/snd-death/snd-grunt-2.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-grunt-3",
		url: "assets/engine/sfx/snd-death/snd-grunt-3.ogg",
		type: "audio/ogg",
	},
	// 4 - 6 Woman Grunt
	{
		id: "snd-grunt-4",
		url: "assets/engine/sfx/snd-death/snd-grunt-4.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-grunt-5",
		url: "assets/engine/sfx/snd-death/snd-grunt-5.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-grunt-6",
		url: "assets/engine/sfx/snd-death/snd-grunt-6.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-body-shot",
		url: "assets/engine/sfx/snd-hit/snd-body-shot.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-head-shot",
		url: "assets/engine/sfx/snd-hit/snd-head-shot.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-damage",
		url: "assets/engine/sfx/snd-hurt/snd-damage.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-assault-fire",
		url: "assets/engine/sfx/snd-item-zap-gun/snd-item-zap-assault-fire.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-pistol-fire",
		url: "assets/engine/sfx/snd-item-zap-gun/snd-item-zap-pistol-fire.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-rifle-fire",
		url: "assets/engine/sfx/snd-item-zap-gun/snd-item-zap-rifle-fire.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-assault-reload",
		url: "assets/engine/sfx/snd-reload-zap/snd-item-zap-assault-reload.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-pistol-reload",
		url: "assets/engine/sfx/snd-reload-zap/snd-item-zap-pistol-reload.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-item-zap-rifle-reload",
		url: "assets/engine/sfx/snd-reload-zap/snd-item-zap-rifle-reload.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-jump",
		url: "assets/engine/sfx/snd-jump/snd-jump.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-laser-impact",
		url: "assets/engine/sfx/snd-laser-impact/snd-laser-impact.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-pickup-weapon",
		url: "assets/engine/sfx/snd-pickup/snd-pickup-weapon.ogg",
		type: "audio/ogg",
	},
	{
		id: "occlusion-character-volume",
		url: "assets/engine/other/occlusion-character-volume.glb",
		type: "glb",
	},
	...SkyClient.assets,
	...FakeWaterClient.assets,
	...BlockType.assets,
	...questAssets,
	// used by ItemHitmarker
	{
		id: "tex-hitmarker",
		url: "assets/engine/textures/tex-hitmarker.png",
		type: "image/png",
	},
];
