import { z } from "zod";
import {
	ACCEPTED_AUDIO_TYPES,
	ACCEPTED_IMAGE_TYPES,
	ACCEPTED_TEXTURE_TYPES,
	MAX_AUDIO_FILE_SIZE,
	MAX_IMAGE_FILE_SIZE,
	MAX_TEXTURE_FILE_SIZE,
} from "../constants/files";
import { formatBytes } from "../helpers/files";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export const FileSchema = z.object({
	buffer: z.any().optional(),
	path: z.string().optional(),
	url: z.string(),
	name: z.string(),
	size: z.number(),
	mimeType: z.string(),
});

const ResourceCommonSchema = z.object({
	pk: assetKeySchema(AssetType.RESOURCE),
	type: z.literal(AssetType.RESOURCE),
	name: z.string(),
	file: FileSchema,
	packageId: PackageIdSchema.optional(),
	isBuiltIn: z.boolean().optional(),
	globalResourceId: z.string().optional(),
});

const AudioSizeSchema = z
	.number()
	.min(0)
	.max(MAX_AUDIO_FILE_SIZE, {
		message: `Max audio file size is ${formatBytes(MAX_AUDIO_FILE_SIZE)}.`,
	});

const AudioFormatSchema = z
	.enum(ACCEPTED_AUDIO_TYPES)
	.refine(
		(value) => ACCEPTED_AUDIO_TYPES.includes(value),
		`Only ${ACCEPTED_AUDIO_TYPES.join(", ")} formats are supported for audio.`,
	);

const TextureSizeSchema = z.number().max(MAX_TEXTURE_FILE_SIZE, {
	message: `Max texture size is ${formatBytes(MAX_TEXTURE_FILE_SIZE)}.`,
});

const TextureFormatSchema = z.enum(ACCEPTED_TEXTURE_TYPES, {
	message: `Only ${ACCEPTED_TEXTURE_TYPES.join(", ")} formats are supported for textures.`,
});

const ImageSizeSchema = z
	.number()
	.min(0)
	.max(MAX_IMAGE_FILE_SIZE, {
		message: `Max image size is ${formatBytes(MAX_IMAGE_FILE_SIZE)}.`,
	});

const ImageFormatSchema = z.enum(ACCEPTED_IMAGE_TYPES, {
	message: `Only ${ACCEPTED_IMAGE_TYPES.join(", ")} formats are supported for images.`,
});

export const audioFile = (value: { size: unknown; type: unknown }) => {
	return {
		size: AudioSizeSchema.parse(value.size),
		type: AudioFormatSchema.parse(value.type),
	} as const;
};

export const textureFile = (value: File) => {
	TextureSizeSchema.parse(value.size);
	TextureFormatSchema.parse(value.type);
};

export const imageFile = (value: File) => {
	return {
		size: ImageSizeSchema.parse(value.size),
		type: ImageFormatSchema.parse(value.type),
	} as const;
};

export const AudioResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("audio"),
		duration: z.number(),
	}),
);

export const BlockTextureResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("blockTexture"),
	}),
);

export const SkyboxSideTextureResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("skyboxSide"),
	}),
);

export const ThumbnailImageResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("thumbnail"),
	}),
);

export const BannerImageResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("banner"),
	}),
);

export const AvatarThumbnailResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("avatarThumbnail"),
	}),
);

export const AvatarPhotoResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("avatarPhoto"),
	}),
);

export const MapResourceSchema = ResourceCommonSchema.merge(
	z.object({
		resourceType: z.literal("map"),
	}),
);

export const ResourceSchema = z.discriminatedUnion("resourceType", [
	AudioResourceSchema,
	BlockTextureResourceSchema,
	SkyboxSideTextureResourceSchema,
	ThumbnailImageResourceSchema,
	BannerImageResourceSchema,
	AvatarThumbnailResourceSchema,
	AvatarPhotoResourceSchema,
	MapResourceSchema,
]);
