import { createLogger } from "@jamango/helpers";

const logger = createLogger("LoadingScreen");

export class LoadingScreen {
	static show(_disablePointerLock) {}

	static hide() {
		logger.info("LOADING COMPLETE");
	}

	static showProgress(_show) {}

	static setProgress(_text, _percent) {}

	static setText(_text) {}
}
