import { UI } from "client/dom/UI";
import { isNullish } from "@jamango/helpers";

export class HUDPopupClient {
	constructor(base) {
		this.base = base;
	}

	set(popup) {
		this.clear();
		if (!popup) return;

		if (isNullish(popup.entityID)) {
			UI.state.gameHudPopup().setHudPopup(popup.position, popup.title, popup.message, popup.imageURL);
		} else {
			if (!popup.message) return;

			const character = this.base.world.getEntity(popup.entityID);
			if (!character?.type.def.isCharacter) return;

			const parts = popup.message.split("[next]");

			UI.state.dialogue().setDialogues(
				parts.map((part) => ({
					name: character?.nameplate?.def.nameplate.getText() || character.characterName || "",
					text: part,
					imageUrl: character.avatarThumbnail,
				})),
			);

			// This is a workaround to wait for the character dialogue to finish
			// because we don't have a way to get notified here when the dialogue is done
			return new Promise((resolve) => {
				const AUTO_RESOLVE_TIMEOUT = 25_000;
				let interval;

				// Safety timeout in case the interval fails to resolve
				const autoResolveTimeout = setTimeout(() => {
					clearInterval(interval);
					resolve();
				}, AUTO_RESOLVE_TIMEOUT);

				interval = setInterval(() => {
					if (UI.state.dialogue().dialogues.length === 0) {
						clearInterval(interval);
						clearTimeout(autoResolveTimeout);
						resolve();
					}
				}, 500);
			});
		}
	}

	clear() {
		UI.state.gameHudPopup().clearHudPopup();
		UI.state.dialogue().clear();
	}
}
