export type IUser = {
	id: string;
	username: string;
	photoUrl?: string;
};

export type IAuthUser = IUser & {
	globalAvatarId?: string;
	acceptedAlphaTOS: boolean;
	featureFlags: string[];
	email: string;
	hasPlayedTutorial: boolean;
	role: "user" | "admin";
};

export enum WorldUserRole {
	OWNER = "owner",
	COLLABORATOR = "collaborator",
	NONE = "none",
}
