import { BB } from "base/BB";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { DEGRAD } from "base/util/math/Math";
import { ParticleSystemState } from "base/world/entity/component/ParticleSystem";
import type { ParticleSystemEntity } from "base/world/entity/ParticleSystem";
import { Vector3 } from "three";
import { node } from "base/rete/Types";

const type = NODE_TYPE_ID.particleSystem;

export const PARTICLE_SYSTEM_NODES = [
	node({
		id: "b2c4e0a8-7f1e-4853-87e1-9a53f2a8c2c9",
		name: "Particles Spawn",
		type,
		description: "Spawn a Particle System in the world.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			config: {
				name: "Config",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => {
						return BB.world.content.state.particleSystems.getAll().map((ps) => ({
							value: ps.pk,
							label: ps.name,
						}));
					},
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "vector3",
				control: "vector3",
			},
			scale: {
				name: "Scale",
				type: "number",
				config: {
					defaultValue: 1,
				},
			},
			parent: {
				name: "Parent",
				type: "entity",
			},
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
			parent: {
				name: "Parent",
				type: "entity",
			},
		},
		execute(inputs, ctx, nodeId, scope) {
			const inputPos = inputs.position;
			const world = ctx.world;

			const configId = inputs.config;
			if (!configId) return;

			const psConfig = BB.world.content.state.particleSystems.getConfig(configId);
			if (!psConfig) return;

			const x = inputPos?.x ?? 0;
			const y = inputPos?.y ?? 0;
			const z = inputPos?.z ?? 0;

			const inputRot = inputs.rotation;
			const rotX = inputRot?.x ?? 0;
			const rotY = inputRot?.y ?? 0;
			const rotZ = inputRot?.z ?? 0;
			const rotation = new Vector3(rotX, rotY, rotZ);
			rotation.multiplyScalar(DEGRAD);

			const inputScale = inputs.scale;
			const scale = inputScale ?? 1;

			const entity = world.router.createEntity({
				def: "ParticleSystem",
				x,
				y,
				z,
				qx: 0,
				qy: 0,
				qz: 0,
				qw: 1,
			}) as ParticleSystemEntity;
			entity.setRotation(rotation, true);
			entity.setScale(scale);

			entity.particleSystem.psPK = configId;
			entity.particleSystem.config = null;

			if (inputs.parent) {
				entity.particleSystem.parentEntityId = inputs.parent.entityID;
			}
			entity.particleSystem.nextState = ParticleSystemState.Running;
			entity.particleSystem.dirty = true;

			scope[nodeId] = entity.entityID;
		},
		resolve(inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])!, parent: inputs.parent };
		},
	}),
	node({
		id: "d1f7e2b3-8a4d-4c7b-91f3-a8f2c9b3d7e1",
		name: "Particles Restart",
		type,
		description: "Restart a Particle System.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const entity = inputs.entity as ParticleSystemEntity;
			entity.particleSystem.nextState = ParticleSystemState.Restart;
			entity.particleSystem.dirty = true;

			scope[nodeId] = entity.entityID;
		},
		resolve(inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
	node({
		id: "e2c3d4f5-6a7b-8c9d-0e1f-2a3b4c5d6e7f",
		name: "Particles Stop",
		type,
		description: "Stop a Particle System.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		execute(inputs, _ctx, nodeId, scope) {
			const entity = inputs.entity as ParticleSystemEntity;
			entity.particleSystem.nextState = ParticleSystemState.Stopped;
			entity.particleSystem.dirty = true;

			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
	node({
		id: "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
		name: "Particles Pause",
		type,
		description: "Pause a Particle System.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		execute(inputs, _ctx, nodeId, scope) {
			const entity = inputs.entity as ParticleSystemEntity;
			entity.particleSystem.nextState = ParticleSystemState.Paused;
			entity.particleSystem.dirty = true;

			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
	node({
		id: "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p7",
		name: "Particles Resume",
		type,
		description: "Play a Particle System.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
			entity: { name: "Particle System", type: "entity" },
		},
		execute(inputs, _ctx, nodeId, scope) {
			const entity = inputs.entity as ParticleSystemEntity;
			entity.particleSystem.nextState = ParticleSystemState.Running;
			entity.particleSystem.dirty = true;

			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
];
