import * as BlockTypeRegistry from "base/world/block/BlockTypeRegistry";
import * as BlockTypeRegistryClient from "client/world/block/BlockTypeRegistry";
import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";
import * as Net from "router/Net";

export const createBlockTypes = (world: World, reorder: BlockTypeRegistry.BlockIdsReorderMap | undefined) => {
	BlockTypeRegistry.createBlockTypes(world.blockTypeRegistry, world.content, world);

	if (reorder) {
		BlockTypeRegistry.reorderBlockIDs(world.blockTypeRegistry, world, reorder);
	} else {
		BlockTypeRegistry.updateBlockTypeScripts(world.blockTypeRegistry, world);
	}

	world.scene.rebuildChunks();

	if (netState.isClient) {
		BlockTypeRegistryClient.initMaterialsAndGeometry(
			world.blockTypeRegistry,
			world.client!.blockTypeRegistry,
			world,
		);
	}
};

export const handleBlockTypeContentChange = (world: World) => {
	if (!netState.isHost) return;

	const blockIndexToName = BlockTypeRegistry.getBlockIndexToNameMap(world.blockTypeRegistry);

	const reorder = BlockTypeRegistry.createReorderBlockIdsMap(world.content, blockIndexToName);

	createBlockTypes(world, reorder);

	blockTypesUpdateCommand.sendToAll(reorder);
};

const blockTypesUpdateCommand = Net.command(
	"block types update",
	Net.CLIENT,
	(reorder: BlockTypeRegistry.BlockIdsReorderMap, world) => {
		createBlockTypes(world, reorder);
	},
);

export const update = (world: World) => {
	const blockTypeRegistry = world.blockTypeRegistry;

	if (blockTypeRegistry.reteLastReloadTime !== world.rete.lastReloadTime) {
		blockTypeRegistry.reteLastReloadTime = world.rete.lastReloadTime;
	}

	if (blockTypeRegistry.blockTypePhysicalAttributesChanged) {
		handleBlockTypeContentChange(world);

		blockTypeRegistry.blockTypePhysicalAttributesChanged = false;
	}

	if (netState.isClient) {
		const blockTypeRegistryClient = world.client!.blockTypeRegistry;

		BlockTypeRegistryClient.update(blockTypeRegistry, blockTypeRegistryClient, world);
	}
};
