import { V0 } from "base/util/math/Math.ts";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterMovementInitUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	entity.movement.state.inputDisplacement.copy(V0);

	entity.movement.state.prvVelocityRequest.copy(entity.movement.state.velocityRequest);
	entity.movement.state.velocityRequest.copy(V0);

	entity.movement.state.prvDisplacementActual.copy(entity.movement.state.displacementActual);
	entity.movement.state.displacementActual.copy(V0);

	entity.movement.state.isIdle = true;
}

function canUpdateSystem(entity) {
	return entity.type.def.isCharacter;
}
