export const AssetType = {
	MAP: "map",
	SETTINGS: "settings",
	GAME_MECHANICS: "gameMechanics",
	CUSTOM_LOADER: "customLoader",
	SKYBOX: "skybox",
	THUMBNAIL: "thumbnail",
	ENVIRONMENT_PRESET: "environmentPreset",
	BLOCK_TEXTURE: "blockTexture",
	BLOCK: "block",
	AUDIO: "audio",
	AVATAR: "avatar",
	AVATAR_COMPONENT: "avatarComponent",
	CHARACTER: "character",
	SCRIPT: "script",
	RESOURCE: "resource",
	WORLD_DATA: "worldData",
	PACKAGE_DATA: "packageData",
	CUSTOM_UI: "customUI",
	PARTICLE_SYSTEM: "particleSystem",
	BLOCK_SOUNDS_PACK: "blockSoundsPack",
	BLOCK_STRUCTURE: "blockStructure",
	PROP: "prop",
	ITEM: "item",
} as const;

export type AssetType = (typeof AssetType)[keyof typeof AssetType];

export const AssetTypeLabel: Record<AssetType, string> = {
	[AssetType.MAP]: "Map",
	[AssetType.SETTINGS]: "Settings",
	[AssetType.GAME_MECHANICS]: "Game Mechanics",
	[AssetType.CUSTOM_LOADER]: "Custom Loader",
	[AssetType.SKYBOX]: "Skybox",
	[AssetType.THUMBNAIL]: "Thumbnail",
	[AssetType.ENVIRONMENT_PRESET]: "Environment Preset",
	[AssetType.BLOCK_TEXTURE]: "Block Texture",
	[AssetType.BLOCK]: "Block",
	[AssetType.AUDIO]: "Audio",
	[AssetType.AVATAR]: "Avatar",
	[AssetType.AVATAR_COMPONENT]: "Avatar Component",
	[AssetType.CHARACTER]: "Character",
	[AssetType.SCRIPT]: "Script",
	[AssetType.RESOURCE]: "Resource",
	[AssetType.WORLD_DATA]: "World Data",
	[AssetType.PACKAGE_DATA]: "Package Data",
	[AssetType.CUSTOM_UI]: "Custom UI",
	[AssetType.PARTICLE_SYSTEM]: "Particle System",
	[AssetType.BLOCK_SOUNDS_PACK]: "Block Sounds Pack",
	[AssetType.BLOCK_STRUCTURE]: "Blueprint",
	[AssetType.PROP]: "Prop",
	[AssetType.ITEM]: "Item",
};
