import type { IPackageBundle, Resource, S3FilePath, WorldUserRole } from "@jamango/content-client";

export type IId = string;
export type ISemanticVersion = string;
export type IIdentifier = string;
export type ICode = string;
export type IDateString = string;

export type IFileMeta = {
	name: string;
	resourceId: string;
};

export type IWorld = {
	id: IIdentifier;
	shortCode: ICode;
	meta: {
		isTutorial: boolean;
		isFeatured: boolean;
		priority: number;
	};
	analytics: {
		visits: number;
	};
	liveVersionId?: IId;
	latestVersionId?: IId;
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
	likes: number;
	remixedFromWorldId?: IIdentifier;
};

export type IWorldPermission = {
	id: number;
	worldId: IIdentifier;
	accountId: IIdentifier;
	role: WorldUserRole;
};

type UploadableResourceType = Resource["resourceType"];

export type IResource = {
	id: IIdentifier;
	type: UploadableResourceType;
	meta: {
		path: string;
		size: number;
		duration?: number;
		mimeType: string;
		name: string;
		uploaderAccountId: string;
	};
	createdAt: Date;
};

export type IBundleResourceUsage = {
	id: number;
	resources: { ids: IResource["id"][] };
	bundleId: string;
	versionId: string;
	createdAt: Date;
};

export type IResourceUploadRequest = {
	id: number;
	requestId: IIdentifier;
	accountId: IIdentifier;
	s3Key: string;
	resource: {
		id: IResource["id"];
		type: IResource["type"];
		fileName: string;
	};
	createdAt: Date;
};

export enum PublishStatus {
	UNDER_REVIEW = "under-review",
	APPROVED = "approved",
	REJECTED = "rejected",
}

export type IWorldVersion = {
	id: IId;
	previousVersionId?: IId;
	worldId: IIdentifier;
	name?: string;
	bundleVersion: number;
	meta: {
		saveType: "manual" | "autosave";
		publicData: {
			name: string;
			metaJamFile: Pick<IFileMeta, "name">;
			bundleVersions: Record<string, string>; // bundleVersion -> world jam file path
			maxPlayers: number;
			thumbnailFile?: IFileMeta;
			customLoader: {
				show: boolean;
				title?: string;
				description?: string;
				bannerFile?: IFileMeta;
			};
			tags: string[];
			experiments: string[];
		};
		publish: {
			status?: "under-review" | "rejected" | "approved";
			comments?: string;
			updatedAt: Date;
		};
	};
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
};

export type IWorldAccountPersistentData = {
	worldId: IIdentifier;
	accountId: IIdentifier;
	value?: Record<string, unknown>;
};

export type IUploadRequest = {
	id: number;
	worldId: IIdentifier;
	currentVersionId?: IId;
	newVersionId?: IId;
	accountId: IIdentifier;
	requestId: string;
	s3Key: string;
	meta: {
		type: S3FilePath.SAVE_WORLD;
		autosave: boolean;
		status: "pending" | "in-progress" | "done" | "failed";
		attempts: number;
		handler?: "content-worker";
		logs?: string;
		startedAt?: IDateString;
		finishedAt?: IDateString;
	};
	createdAt: Date;
};

export type IPackageUploadRequest = {
	id: number;
	packageId: IIdentifier;
	currentVersionId?: IId;
	newVersionId?: IId;
	accountId: IIdentifier;
	requestId: string;
	s3Key: string;
	meta: {
		type: S3FilePath.UPLOADING_PACKAGE;
		status: "pending" | "in-progress" | "done" | "failed";
		attempts: number;
		handler?: "content-worker";
		logs?: string;
		startedAt?: IDateString;
		finishedAt?: IDateString;
	};
	createdAt: Date;
};

export type IAccountProfile = {
	id: IIdentifier;
	username: string;
	photoUrl?: string;
	globalAvatarId?: IAccountAvatar["id"];
	email: string;
	role: "user" | "admin";
	accessCode?: string;
	acceptedAlphaTOS: boolean;
	emailVerified: boolean;
	featureFlags?: string[];
	createdAt: Date;
	updatedAt: Date;
	banned?: boolean;
	banReason?: string;
	banExpires?: number;
};

export type IAccountAvatar = {
	id: IIdentifier;
	accountId: string;
	bundle: IPackageBundle;
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
};

export type IPackage = {
	id: IIdentifier;
	name: string;
	latestVersionId: IId;
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
	visibility: "public" | "private";
	shortDescription?: string;
	longDescription?: string;
	thumbnailUrl?: string;
	price?: number;
};

export type IPackageVersion = {
	id: IId;
	packageId: IIdentifier;
	meta: {
		jamfileS3Path: string;
		bundleVersion: number;
		bundleVersions: Record<string, string>; // bundleVersion -> world jam file path
	};
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
};

export enum PackageUserRole {
	OWNER = "owner",
	COLLABORATOR = "collaborator",
}

export type IPackagePermission = {
	id: number;
	packageId: IIdentifier;
	accountId: IIdentifier;
	role: PackageUserRole;
};

export type IPackageInstallation = {
	id: number;
	packageId: IIdentifier;
	versionId: IId;
	accountId: IIdentifier;
	createdAt: Date;
	updatedAt: Date;
	removedAt?: Date;
};

export enum EventSourceType {
	USER = 0,
	P2P = 1,
	DEDI = 2,
}

export type IAnalyticsEvent = {
	id: number;
	name: string;
	ts: Date;
	value: number;
	sourceType: EventSourceType;
	sourceId: string;
	meta: Record<string, string | number>;
};

export type IWorldAnalytics = {
	totalVisits: number;
	uniqueVisits: number;
};

export type IJamfileUploadRequest = {
	id: number;
	accountId: IIdentifier;
	requestId: string;
	s3Key: string;
	meta: {
		type: S3FilePath.UPLOADING_JAMFILE;
		status: "pending" | "in-progress" | "done" | "failed";
		attempts: number;
		handler?: "content-worker";
		logs?: string;
		startedAt?: IDateString;
		finishedAt?: IDateString;
	};
	createdAt: Date;
};
