import { V0, V1 } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";
import TWEEN from "@tweenjs/tween.js";
import { sceneMatrix } from "base/util/FasterScene.js";
import { Vector3, Quaternion, Object3D, Box3, EventDispatcher, AnimationAction, Matrix4 } from "three";

//extensions to three.js and browser classes

//original methods that initExtensions replaces
// biome-ignore lint/style/noVar: <explanation>
export var Object3DUpdateMatrixWorld;

export function initExtensions() {
	Vector3.prototype.isAxisAligned = function (other = V0) {
		if (this.x !== other.x && this.y === other.y && this.z === other.z) return "x";
		if (this.x === other.x && this.y !== other.y && this.z === other.z) return "y";
		if (this.x === other.x && this.y === other.y && this.z !== other.z) return "z";

		return false;
	};

	Vector3.prototype.toString = function () {
		return `(${this.x}, ${this.y}, ${this.z})`;
	};

	Vector3.prototype.isZero = function () {
		return this.x === 0 && this.y === 0 && this.z === 0;
	};

	//performance improvement haxx for not doing unnecessary matrix math
	Object3DUpdateMatrixWorld = Object3D.prototype.updateMatrixWorld;
	Object3D.prototype.updateMatrixWorld = function (force) {
		if (this.visible) Object3DUpdateMatrixWorld.call(this, force);
	};

	const Matrix4MultiplyMatrices = Matrix4.prototype.multiplyMatrices;
	Matrix4.prototype.multiplyMatrices = function (a, b) {
		if (a === sceneMatrix) return this.copy(b);
		return Matrix4MultiplyMatrices.call(this, a, b);
	};

	const Matrix4ExtractRotation = Matrix4.prototype.extractRotation;
	Matrix4.prototype.extractRotation = function (m) {
		if (m === sceneMatrix) return this.identity();
		return Matrix4ExtractRotation.call(this, m);
	};

	//o is a defTransform. returns promise
	Object3D.prototype.animateTween = function (o, duration, easing = TWEEN.Easing.Linear.None) {
		this.stopTween();

		const posStart = this.position.clone();
		const rotStart = this.quaternion.clone();
		const sclStart = this.scale.clone();

		const posEnd = new Vector3().copy(o?.pos ?? V0);

		const rotEnd = new Quaternion();
		if (!isNullish(o?.rot)) rotEnd.setFromEuler(o.rot);

		const sclEnd = new Vector3();
		if (typeof o?.scl === "number") sclEnd.setScalar(o.scl);
		else sclEnd.copy(o?.scl ?? V1);

		const t = { t: 0 };

		return new Promise((resolve) => {
			this.tween = new TWEEN.Tween(t)
				.to({ t: 1 }, duration * 1000)
				.easing(easing)
				.onUpdate(() => {
					this.position.lerpVectors(posStart, posEnd, t.t);
					this.quaternion.slerpQuaternions(rotStart, rotEnd, t.t);
					this.scale.lerpVectors(sclStart, sclEnd, t.t);
				})
				.onComplete(() => {
					this.position.copy(posEnd);
					this.quaternion.copy(rotEnd);
					this.scale.copy(sclEnd);

					delete this.tween;
					resolve();
				})
				.start();
		});
	};

	Object3D.prototype.stopTween = function () {
		if (this.tween) {
			TWEEN.remove(this.tween);
			delete this.tween;
		}
	};

	Box3.prototype.setFromCorners = function (corner1, corner2) {
		return this.makeEmpty().expandByPoint(corner1).expandByPoint(corner2);
	};

	Box3.prototype.isPointOnEdge = function (point) {
		if (this.min.x === point.x || this.max.x === point.x) return true;
		if (this.min.y === point.y || this.max.y === point.y) return true;
		if (this.min.z === point.z || this.max.z === point.z) return true;
		return false;
	};

	EventDispatcher.prototype.clear = function () {
		delete this._listeners;
	};

	AnimationAction.prototype.getRemainingTime = function () {
		return (this.getClip().duration - this.time) / this.timeScale;
	};

	//polyfill because chrome displays name and message as part of stack
	Error.prototype.toString = function () {
		let message = this.name;
		if (this.message) message += ": " + this.message;

		let str = this.stack;

		if (!str?.startsWith(message)) str = message + "\n" + str;

		return str.trim();
	};
}
