import { z } from "zod";
import { ACCEPTED_AVATAR_THUMBNAIL_TYPES, MAX_AVATAR_THUMBNAIL_FILE_SIZE } from "../constants/files";
import { MAX_AVATAR_NAME_LENGTH, MIN_AVATAR_NAME_LENGTH } from "../constants/avatars";
import { formatBytes } from "../helpers/files";
import { AssetType, AvatarType, BodyTypeValue } from "../types";
import type { IAvatar, IAvatarMonitoringConfig } from "../types";
import { assetKeySchema, colorSchema, urlSchema } from "./general";
import { PackageIdSchema } from "./package";

const nameSchema = z
	.string()
	.min(MIN_AVATAR_NAME_LENGTH, {
		message: `Avatar name must be at least ${MIN_AVATAR_NAME_LENGTH} characters long.`,
	})
	.max(MAX_AVATAR_NAME_LENGTH, {
		message: `Avatar name must be at most ${MAX_AVATAR_NAME_LENGTH} characters long.`,
	});

export const name = (value: string) => {
	nameSchema.parse(value);
};

const fileSizeSchema = z
	.number()
	.min(0)
	.max(MAX_AVATAR_THUMBNAIL_FILE_SIZE, {
		message: `Max avatar thumbnail size is ${formatBytes(MAX_AVATAR_THUMBNAIL_FILE_SIZE)}.`,
	});

const FormatSchema = z.enum(ACCEPTED_AVATAR_THUMBNAIL_TYPES, {
	message: `Only ${ACCEPTED_AVATAR_THUMBNAIL_TYPES.join(", ")} formats are supported.`,
});

export const file = (value: File) => {
	return {
		size: fileSizeSchema.parse(value.size),
		type: FormatSchema.parse(value.type),
	};
};

const avatarTypeSchema = z.nativeEnum(AvatarType, {
	message: "Invalid avatar type.",
});

export const avatarType = (value: AvatarType) => {
	avatarTypeSchema.parse(value);
};

const avatarComponentKeySchema = assetKeySchema(AssetType.AVATAR_COMPONENT);

const bodyTypeSchema = z.object({
	bodyType1: z.string().nullish(),
	bodyType2: z.string().nullish(),
});

export const AvatarComponentSchema = z.object({
	pk: assetKeySchema(AssetType.AVATAR_COMPONENT),
	id: z.string(),
	name: z.string(),
	type: z.literal(AssetType.AVATAR_COMPONENT),
	category: z.string(),
	thumbnail: bodyTypeSchema,
	glbFile: bodyTypeSchema,
	parent: z.enum(["head", "outfit"]),
	isUniversal: z.boolean(),
	showOnEditor: z.boolean(),
	options: z
		.object({
			attached: z.object({ arms: z.string().nullish().optional() }).optional().nullish(),
			fullCoverHead: z.boolean().optional().nullable(),
			removeHairOnUse: z.boolean().optional().nullable(),
		})
		.optional()
		.nullish(),
});

export const BodyPartConfigKeySchema = z
	.string()
	.refine((value) => value === "EMPTY", { message: "Invalid body part." })
	.or(avatarComponentKeySchema.nullable());

export const AvatarMonitoringConfigSchema = z.object({
	bodyType: z.nativeEnum(BodyTypeValue, { message: "Invalid body type." }),
	arms: BodyPartConfigKeySchema.nullish(),
	backpack: BodyPartConfigKeySchema.nullish(),
	hat: BodyPartConfigKeySchema.nullish(),
	mask: BodyPartConfigKeySchema.nullish(),
	face: BodyPartConfigKeySchema.nullish(),
	hair: BodyPartConfigKeySchema.nullish(),
	legs: BodyPartConfigKeySchema.nullish(),
	torso: BodyPartConfigKeySchema.nullish(),
	skinColor: colorSchema,
	hairColor: colorSchema,
});

export const BodyPartSchema = z.object({
	id: z.string().nullish().optional(),
	isUniversal: z.boolean().optional(),
	fullCoverHead: z.boolean().optional().nullish(),
	removeHairOnUse: z.boolean().optional().nullish(),
	body_type_1: z.string().nullish(),
	body_type_2: z.string().nullish(),
	name: z.string().nullish().optional(),
});

export const OptionalBodyPartSchema = BodyPartSchema.or(z.literal("EMPTY")).or(z.null());

export const AvatarConfigSchema = z.object({
	bodyType: z.nativeEnum(BodyTypeValue, { message: "Invalid body type." }),
	arms: BodyPartSchema,
	backpack: OptionalBodyPartSchema,
	hat: OptionalBodyPartSchema,
	mask: OptionalBodyPartSchema,
	face: BodyPartSchema,
	hair: OptionalBodyPartSchema,
	legs: BodyPartSchema,
	torso: BodyPartSchema,
	skinColor: colorSchema,
	hairColor: colorSchema,
});

export const BodyPartConfigSchema = z.object({
	id: avatarComponentKeySchema,
	name: z.string(),
});

export const OptionalBodyPartConfigSchema = BodyPartConfigSchema.or(z.literal("EMPTY")).or(z.null());

export const AvatarObjectSchema = z.object({
	bodyType: z.nativeEnum(BodyTypeValue, { message: "Invalid body type." }),
	arms: OptionalBodyPartConfigSchema,
	backpack: OptionalBodyPartConfigSchema,
	hat: OptionalBodyPartConfigSchema,
	mask: OptionalBodyPartConfigSchema,
	face: OptionalBodyPartConfigSchema,
	hair: OptionalBodyPartConfigSchema,
	legs: OptionalBodyPartConfigSchema,
	torso: OptionalBodyPartConfigSchema,
	skinColor: colorSchema,
	hairColor: colorSchema,
});

export const config = (value: IAvatarMonitoringConfig) => {
	AvatarMonitoringConfigSchema.parse(value);
};

export const schema = z.object({
	name: nameSchema,
	displayPhoto: urlSchema,
	thumbnail: urlSchema,
	avatarType: avatarTypeSchema,
	config: AvatarMonitoringConfigSchema,
});

export const create = (payload: {
	name: string;
	avatarType: AvatarType;
	config: IAvatarMonitoringConfig;
}) => {
	name(payload.name);
	avatarType(payload.avatarType);
	config(payload.config);
};

export const update = (payload: { config: IAvatarMonitoringConfig }) => {
	config(payload.config);
};

export const all = (value: IAvatar) => {
	schema.parse(value);
};

export const CostumeSchema = z.object({
	config: AvatarMonitoringConfigSchema,
	name: z.string(),
	thumbnailResourcePk: assetKeySchema(AssetType.RESOURCE),
	type: z.literal(AssetType.AVATAR),
	displayPhotoResourcePk: assetKeySchema(AssetType.RESOURCE),
	id: z.string(),
	pk: assetKeySchema(AssetType.AVATAR),
	isDeleted: z.boolean(),
	createdAt: z.string(),
	updatedAt: z.string(),
	packageId: PackageIdSchema.optional(),
});

const UserAvatarSpecificSchema = z.object({
	avatarType: z.literal(AvatarType.USER_AVATAR),
	owner: z.string(),
});

const WorldAvatarSpecificSchema = z.object({
	avatarType: z.literal(AvatarType.CHARACTER),
});

export const AvatarSchema = z.union([
	CostumeSchema.merge(UserAvatarSpecificSchema),
	CostumeSchema.merge(WorldAvatarSpecificSchema),
]);
