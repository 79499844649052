import { NET_SERVER } from "@jamango/ibs";
import { BB } from "base/BB";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as Resources from "client/Resources";
import { Avatar } from "client/avatar/Avatar";
import { getUserAvatar } from "rest-client";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

import { TPAimOffsetNode } from "mods/defs/CharacterDefault/third-person/TPAimOffsetNode.js";
import { TransitionToDeathStartTP } from "mods/defs/CharacterDefault/third-person/TransitionToDeathStartTP.js";

/***************************** START - Locomotion State for TP Unarmed ************************************************************/
import { LocomotionStateIdle } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateIdle.js";
import { LocomotionStateJogging } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateJogging.js";
import { LocomotionStateRunning } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateRunning.js";
import { LocomotionStateTurnLeft } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateTurnLeft.js";
import { LocomotionStateTurnRight } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateTurnRight.js";
import { LocomotionStateWalking } from "mods/defs/CharacterDefault/third-person/unarmed/LocomotionStateWalking.js";
import { LocomotionStateCrouchEnd } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchEnd.js";
import { LocomotionStateCrouchIdle } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchIdle.js";
// Crouch
import { LocomotionStateCrouchStart } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchStart.js";
import { LocomotionStateCrouchTurnLeft } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchTurnLeft.js";
import { LocomotionStateCrouchTurnRight } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchTurnRight.js";
import { LocomotionStateCrouchWalking } from "mods/defs/CharacterDefault/third-person/unarmed/crouch/LocomotionStateCrouchWalking.js";
import { LocomotionStateDeathEnd } from "mods/defs/CharacterDefault/third-person/unarmed/death/LocomotionStateDeathEnd.js";
import { LocomotionStateDeathIdle } from "mods/defs/CharacterDefault/third-person/unarmed/death/LocomotionStateDeathIdle.js";
// Death
import { LocomotionStateDeathStart } from "mods/defs/CharacterDefault/third-person/unarmed/death/LocomotionStateDeathStart.js";
import { LocomotionStateFlying } from "mods/defs/CharacterDefault/third-person/unarmed/flying/LocomotionStateFlying.js";
//Flying
import { LocomotionStateFlyingIdle } from "mods/defs/CharacterDefault/third-person/unarmed/flying/LocomotionStateFlyingIdle.js";
import { LocomotionStateFlyingSprint } from "mods/defs/CharacterDefault/third-person/unarmed/flying/LocomotionStateFlyingSprint.js";
import { LocomotionStateFalling } from "mods/defs/CharacterDefault/third-person/unarmed/jump/LocomotionStateFalling.js";
// Jump
import { LocomotionStateJumping } from "mods/defs/CharacterDefault/third-person/unarmed/jump/LocomotionStateJumping.js";
import { LocomotionStateLanding } from "mods/defs/CharacterDefault/third-person/unarmed/jump/LocomotionStateLanding.js";
/***************************** END - Locomotion State for TP Unarmed ************************************************************/

/************************ START - Locomotion State for TP Weapons ************************************************************/
import { LocomotionStateWeaponsIdle } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsIdle.js";
import { LocomotionStateWeaponsJogging } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsJogging.js";
import { LocomotionStateWeaponsRunning } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsRunning.js";
import { LocomotionStateWeaponsTurnLeft } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsTurnLeft.js";
import { LocomotionStateWeaponsTurnRight } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsTurnRight.js";
import { LocomotionStateWeaponsWalking } from "mods/defs/CharacterDefault/third-person/weapons/LocomotionStateWeaponsWalking.js";
import { LocomotionStateWeaponsCrouchEnd } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchEnd.js";
import { LocomotionStateWeaponsCrouchIdle } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchIdle.js";
// Crouch
import { LocomotionStateWeaponsCrouchStart } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchStart.js";
import { LocomotionStateWeaponsCrouchTurnLeft } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchTurnLeft.js";
import { LocomotionStateWeaponsCrouchTurnRight } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchTurnRight.js";
import { LocomotionStateWeaponsCrouchWalking } from "mods/defs/CharacterDefault/third-person/weapons/crouch/LocomotionStateWeaponsCrouchWalking.js";
import { LocomotionStateWeaponsFalling } from "mods/defs/CharacterDefault/third-person/weapons/jump/LocomotionStateWeaponsFalling.js";
// Jump
import { LocomotionStateWeaponsJumping } from "mods/defs/CharacterDefault/third-person/weapons/jump/LocomotionStateWeaponsJumping.js";
import { LocomotionStateWeaponsLanding } from "mods/defs/CharacterDefault/third-person/weapons/jump/LocomotionStateWeaponsLanding.js";
/************************** END - Locomotion State for TP Weapons ************************************************************/

/************************ START - Locomotion State for FP Weapons ************************************************************/
import { LocomotionStateIdleFP } from "mods/defs/CharacterDefault/first-person/LocomotionStateIdleFP.js";
import { LocomotionStateJumpingFP } from "mods/defs/CharacterDefault/first-person/LocomotionStateJumpingFP.js";
import { LocomotionStateReloadFP } from "mods/defs/CharacterDefault/first-person/LocomotionStateReloadFP.js";
import { LocomotionStateRunningFP } from "mods/defs/CharacterDefault/first-person/LocomotionStateRunningFP.js";
import { LocomotionStateWalkingFP } from "mods/defs/CharacterDefault/first-person/LocomotionStateWalkingFP.js";
/************************** END - Locomotion State for FP Weapons ************************************************************/

import { ANIM_COPIES } from "./animCopies";

export const CharacterDefault = {
	name: "CharacterDefault",
	assets: [
		{
			id: "mdl-character-default-type1-tp-anim",
			url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-tp-anim.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type1-tp-geom",
			url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-tp-geom.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type1-fp-anim",
			url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-fp-anim.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type1-fp-geom",
			url: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-fp-geom.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type2-tp-anim",
			url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-tp-anim.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type2-tp-geom",
			url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-tp-geom.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type2-fp-anim",
			url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-fp-anim.glb",
			type: "glb",
		},
		{
			id: "mdl-character-default-type2-fp-geom",
			url: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-fp-geom.glb",
			type: "glb",
		},
	],

	invincible: false,
	camera: { maxDst: 6 },
	mountPoints: [{ mount: new Vector3(1, 2, -1.2) }],
	labelOffsetY: 2,

	rigName: "RigDefault",
	rootBone: "Root_jnt",
	pelvisBone: "Spine_jnt",
	spineBone01: "Spine1_jnt",
	spineBone02: "Spine2_jnt",
	neckBone: "Neck_jnt",
	leftArmBone: "Sk_LeftArm_jnt",
	rightArmBone: "Sk_RightArm_jnt",
	itemNode: "weapons_jnt",
	fpItemNode: "weapons_jnt",

	server() {},

	client() {},
};

export function insertAnimCopies() {
	// this function needs to run before any creation of avatarmeshes
	// since cahracterDefault def .client runs after connection to server was made,it is too late.
	// therefore this was moved to a direct call from world asset instantiation

	const type1TPAnimations = Resources.get("mdl-character-default-type1-tp-anim");
	const type2TPAnimations = Resources.get("mdl-character-default-type2-tp-anim");
	const type1FPAnimations = Resources.get("mdl-character-default-type1-fp-anim");
	const type2FPAnimations = Resources.get("mdl-character-default-type2-fp-anim");

	if (!animationsEqual(type1TPAnimations, type2TPAnimations))
		throw Error("CharacterDefault - TP animation sets don't match");

	if (!animationsEqual(type1FPAnimations, type2FPAnimations))
		throw Error("CharacterDefault - FP animation sets don't match");

	const allGroups = [
		type1TPAnimations.animations,
		type2TPAnimations.animations,
		type1FPAnimations.animations,
		type2FPAnimations.animations,
	];

	Object.entries(ANIM_COPIES).forEach(([newName, originalName]) => {
		allGroups.forEach((anims) => {
			[...anims].forEach((animClip) => {
				if (animClip.name !== originalName) return;
				const newAnimClip = animClip.clone();
				newAnimClip.name = newName;
				anims.push(newAnimClip);
			});
		});
	});

	function animationsEqual(a, b) {
		a = a.animations.map((e) => e.name);
		b = b.animations.map((e) => e.name);
		return a.length === b.length && a.every((x) => b.includes(x));
	}
}

/**
 * @param {import("base/world/entity/Character").Character} character
 * @param {import("@jamango/content-client").IAvatarObject} avatarObject
 */
export function setAvatarMesh(character, avatarObject) {
	const config = BB.world.content.state.avatars.convertAvatarObjectToConfig(avatarObject);

	const mesh = new Avatar(
		Resources.idToResource,
		Resources.idToPromise,
		config,
		"TP",
		character.avatarObject,
	);
	mesh.loadAvatarFromConfig();

	const fpmesh = new Avatar(
		Resources.idToResource,
		Resources.idToPromise,
		config,
		"FP",
		character.avatarObject,
	);
	fpmesh.loadFPAvatarFromConfig();

	const def = BB.world.defs.get(character.def);
	const nodes = {
		rootBone: mesh.mesh.getObjectByName(def.rootBone),
		pelvisBone: mesh.mesh.getObjectByName(def.pelvisBone),
		spineBone01: mesh.mesh.getObjectByName(def.spineBone01),
		spineBone02: mesh.mesh.getObjectByName(def.spineBone02),
		neckBone: mesh.mesh.getObjectByName(def.neckBone),
		fpLeftArmBone: fpmesh.mesh.getObjectByName(def.leftArmBone),
		fpRightArmBone: fpmesh.mesh.getObjectByName(def.rightArmBone),
		tpLeftArmBone: mesh.mesh.getObjectByName(def.leftArmBone),
		tpRightArmBone: mesh.mesh.getObjectByName(def.rightArmBone),
		itemNode: mesh.mesh.getObjectByName(def.itemNode),
		fpItemNode: fpmesh.mesh.getObjectByName(def.fpItemNode),
	};

	character.setMesh(mesh, nodes);
	character.setFPMesh(fpmesh, nodes);

	let m = mesh;
	const i = character.locomotionInput;
	const t = [new TransitionToDeathStartTP()];

	character.meshLocomotion.setInitialState(new LocomotionStateIdle(m, i, t)).add(
		// ------- TP Unarmed -------
		new LocomotionStateWalking(m, i, t),
		new LocomotionStateJogging(m, i, t),
		new LocomotionStateRunning(m, i, t),
		new LocomotionStateTurnLeft(m, i, t),
		new LocomotionStateTurnRight(m, i, t),

		new LocomotionStateCrouchStart(m, i, t),
		new LocomotionStateCrouchEnd(m, i, t),
		new LocomotionStateCrouchIdle(m, i, t),
		new LocomotionStateCrouchTurnLeft(m, i, t),
		new LocomotionStateCrouchTurnRight(m, i, t),
		new LocomotionStateCrouchWalking(m, i, t),

		new LocomotionStateFalling(m, i, t),
		new LocomotionStateJumping(m, i, t),
		new LocomotionStateLanding(m, i, t),

		new LocomotionStateDeathStart(m, i),
		new LocomotionStateDeathIdle(m, i),
		new LocomotionStateDeathEnd(m, i),

		new LocomotionStateFlyingIdle(m, i, t),
		new LocomotionStateFlying(m, i, t),
		new LocomotionStateFlyingSprint(m, i, t),

		// ------- TP Weapons -------
		new LocomotionStateWeaponsIdle(m, i, t),
		new LocomotionStateWeaponsWalking(m, i, t),
		new LocomotionStateWeaponsJogging(m, i, t),
		new LocomotionStateWeaponsRunning(m, i, t),
		new LocomotionStateWeaponsTurnLeft(m, i, t),
		new LocomotionStateWeaponsTurnRight(m, i, t),

		new LocomotionStateWeaponsCrouchStart(m, i, t),
		new LocomotionStateWeaponsCrouchEnd(m, i, t),
		new LocomotionStateWeaponsCrouchIdle(m, i, t),
		new LocomotionStateWeaponsCrouchTurnLeft(m, i, t),
		new LocomotionStateWeaponsCrouchTurnRight(m, i, t),
		new LocomotionStateWeaponsCrouchWalking(m, i, t),

		new LocomotionStateWeaponsFalling(m, i, t),
		new LocomotionStateWeaponsJumping(m, i, t),
		new LocomotionStateWeaponsLanding(m, i, t),

		new TPAimOffsetNode(m, i),
	);

	m = character.fpmesh;

	character.fpmeshLocomotion.setInitialState(new LocomotionStateIdleFP(m, i)).add(
		// ------- FP Weapons -------
		new LocomotionStateJumpingFP(m, i),
		new LocomotionStateWalkingFP(m, i),
		new LocomotionStateRunningFP(m, i),
		new LocomotionStateReloadFP(m, i),
	);
}

export const PlayerAI = {
	// TODO replace with structuredClone after migrating to plain-data
	...JSON.parse(JSON.stringify(CharacterDefault)),
	name: "PlayerAI",

	isNPC: true,
	walkSpeed: 2,
	healthRegenRate: 0,
	canPickUpItem: false,
	aiShootHitPercentage: 0.7,
	weaponSpread: 0,
};

export const PlayerDefault = {
	// TODO replace with structuredClone after migrating to plain-data
	...JSON.parse(JSON.stringify(CharacterDefault)),
	name: "PlayerDefault",

	/**
	 * @type {import("@jamango/content-client").IAvatarConfig | null}
	 */
	config: null,

	server() {
		const world = BB.world;

		// listener for arbitrary changing of avatars mid-play session
		world.dispatcher.addEventListener(NET_SERVER, "initcommandlisteners", function () {
			Net.listen("CharacterDefault_avatar", async function (a, _world, peer) {
				const [avatarId] = a;
				const accountID = getPeerMetadata(peer).accountID;
				if (accountID && avatarId) {
					const pk = BB.world.content.state.avatars.generatePK(avatarId);
					let avatar = BB.world.content.state.avatars.get(pk);
					if (!avatar) {
						// if avatar not in jacy rn, fetch avatar via rest api
						const avatarResult = await getUserAvatar(accountID, avatarId);
						if (avatarResult.data) {
							BB.world.content.importPackage(avatarResult.data.avatar);
							avatar = BB.world.content.state.avatars.get(pk);
						}
					}
					const avatarObject = avatar
						? BB.world.content.state.avatars.convertConfigToAvatarObject(avatar)
						: undefined;

					const entity = world.peerToPlayer.get(peer);
					if (entity && avatarObject) entity.setAvatar(avatarObject);
				}
			});
		});
	},

	//then client sets their player to their custom avatar after loading in
	client() {},
};

export function setLocalPlayerAvatar(avatarId, avatarObject) {
	// this is called on the clients, if you locally change your own player avatar in the avatar editor
	if (!BB.world || !netState.isClient) return;

	if (netState.isHost) {
		// as host we can just set the object, and it will serialize
		const player = BB.world.client.getPeerPlayer();
		player.setAvatar(avatarObject);
	} else {
		// as client we tell the server to re-confirm
		Net.send("CharacterDefault_avatar", [avatarId]);
	}
}
