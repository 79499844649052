import type { ItemPhysics, PhysicsBody, PhysicsSensor } from "base/world/Physics";
import { MotionType } from "base/world/Physics";
import { Vector3 } from "three";

export class ItemPhysicsComponent {
	name = "itemPhysics";

	def: {
		// non-serialized
		enabled: boolean;
		motionType: MotionType;
		geom: { type: "box"; width: number; height: number; depth: number };
	};

	state: {
		// non-serialized
		enabled: boolean | undefined;
		body: PhysicsBody | undefined;
		sensor: PhysicsSensor | undefined;
		itemPhysics: ItemPhysics | undefined;
		motionType: MotionType | undefined;

		// serialized
		linearVelocity: Vector3;
	};

	constructor(o: any) {
		this.def = {
			enabled: false,
			motionType: o?.motionType ?? MotionType.STATIC,
			geom: o?.geom ?? { type: "box", width: 1, height: 1, depth: 1 },
		};

		this.state = {
			enabled: undefined,
			body: undefined,
			sensor: undefined,
			itemPhysics: undefined,
			motionType: undefined,
			linearVelocity: new Vector3(),
		};
	}
}
