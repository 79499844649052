import type { ReteState } from "base/rete/Types";
import type { BlockIsland } from "base/util/BlockIslands";
import { getBlockIslands } from "base/util/BlockIslands";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import { Vector3Map, Vector3Set } from "base/util/math/VectorStorage";
import { isPointOnEdgeOfBox } from "base/util/ThreeJS";
import type { ChunkScene } from "base/world/block/Scene";
import { BLOCK_AIR } from "base/world/block/Util.js";
import type { World } from "base/world/World";
import { Box3, Vector3 } from "three";

const _vector3 = new Vector3();
const _currentBlockPos = new Vector3();

const N_BLOCKS_SKIP_ISLANDS = 200;

export type BlockGroupScriptAttachment = {
	script: string;
	data?: Record<string, any>;
};

export type BlockGroup = {
	id: string;
	name: string | null;
	scripts: BlockGroupScriptAttachment[];
	blocks: Vector3Set;
	bounds: Box3;
	islands: BlockIsland[];
	// used when calculating islands is skipped due to too many blocks being in the group
	fallbackIslands: BlockIsland[];
};

export type BlockGroupsState = ReturnType<typeof init>;

export const init = () => {
	return {
		groups: new Map<string, BlockGroup>(),
		groupNameToId: {} as {
			[name: string]: string;
		},
		blocks: new Vector3Map<string[]>(),
		dirtyGroupIds: new Set<string>(),
		dirtyPositions: new Vector3Map<boolean>(),
		allPositionsDirty: false,
	};
};

export type BlockGroupsSave = {
	groups: {
		[id: string]: {
			name: string | null;
			scripts: BlockGroupScriptAttachment[];
		};
	};
	blocks: [number, number, number, string[]][];
};

export const saveGroups = (state: BlockGroupsState) => {
	const groups: BlockGroupsSave["groups"] = {};

	for (const [id, group] of state.groups) {
		groups[id] = {
			name: group.name,
			scripts: group.scripts,
		};
	}

	return groups;
};

export const saveBlocks = (state: BlockGroupsState) => {
	const blocks: BlockGroupsSave["blocks"] = [];

	for (const groupIds of state.blocks.entries(_vector3)) {
		blocks.push([_vector3.x, _vector3.y, _vector3.z, groupIds]);
	}

	return blocks;
};

export const save = (state: BlockGroupsState): BlockGroupsSave => {
	return {
		groups: saveGroups(state),
		blocks: saveBlocks(state),
	};
};

const loadGroups = (state: BlockGroupsState, rete: ReteState, groups: BlockGroupsSave["groups"]) => {
	state.groups.clear();

	for (const id in groups) {
		const { name, scripts } = groups[id];

		create(state, rete, id, name, scripts);

		state.dirtyGroupIds.add(id);
	}
};

const loadBlocks = (state: BlockGroupsState, blocks: BlockGroupsSave["blocks"]) => {
	state.blocks.clear();
	state.dirtyPositions.clear();

	for (const [x, y, z, groupIds] of blocks) {
		setAtPosition(state, groupIds, x, y, z);
	}

	state.allPositionsDirty = true;
};

export const load = (state: BlockGroupsState, rete: ReteState, blockGroupsSave: BlockGroupsSave) => {
	loadGroups(state, rete, blockGroupsSave.groups);
	loadBlocks(state, blockGroupsSave.blocks);
};

export const create = (
	state: BlockGroupsState,
	rete: ReteState,
	id: string,
	name: string | null,
	scripts: BlockGroupScriptAttachment[] = [],
) => {
	const blocks = new Vector3Set();
	const bounds = new Box3();

	const fallbackIslands = [
		{
			blocks,
			bounds,
		},
	];

	state.groups.set(id, {
		id,
		name,
		scripts,
		blocks,
		bounds,
		islands: fallbackIslands,
		fallbackIslands,
	});

	if (name) {
		state.groupNameToId[name] = id;
	}

	state.dirtyGroupIds.add(id);

	if (scripts.length > 0) {
		rete.needsReload = true;
	}
};

export const deleteGroup = (state: BlockGroupsState, rete: ReteState, groupId: string) => {
	const group = state.groups.get(groupId);
	if (!group) return;

	for (const _ of group.blocks.iterate(_vector3)) {
		removeBlock(state, groupId, _vector3.x, _vector3.y, _vector3.z);
	}

	state.groups.delete(groupId);
	if (group.name) {
		delete state.groupNameToId[group.name];
	}

	state.dirtyGroupIds.add(groupId);

	if (group.scripts.length > 0) {
		rete.needsReload = true;
	}
};

export const setName = (state: BlockGroupsState, id: string, name: string | null) => {
	const group = state.groups.get(id);
	if (!group) return;

	if (group.name) {
		delete state.groupNameToId[group.name];
	}

	if (name) {
		state.groupNameToId[name] = id;
	}

	group.name = name;

	state.dirtyGroupIds.add(id);
};

export const setScripts = (
	state: BlockGroupsState,
	rete: ReteState,
	id: string,
	scripts: BlockGroupScriptAttachment[],
) => {
	const group = state.groups.get(id);
	if (!group) return;

	group.scripts = scripts;

	state.dirtyGroupIds.add(id);

	rete.needsReload = true;
};

export const getIdsAtPosition = (state: BlockGroupsState, x: number, y: number, z: number) => {
	const groups = state.blocks.get(_vector3.set(x, y, z));

	if (!groups) return null;

	return groups;
};

export const getAtPosition = (state: BlockGroupsState, x: number, y: number, z: number) => {
	const groupIds = getIdsAtPosition(state, x, y, z);

	if (!groupIds) return [];

	return groupIds.map((id) => state.groups.get(id)!);
};

export const getById = (state: BlockGroupsState, id: string) => {
	return state.groups.get(id);
};

export const getByName = (state: BlockGroupsState, name: string) => {
	const groupId = state.groupNameToId[name];
	if (!groupId) return null;

	const group = state.groups.get(groupId);
	if (!group) return null;

	return group;
};

export const updateIslands = (state: BlockGroupsState, _world: World, groupId: string) => {
	const group = state.groups.get(groupId);
	if (!group) return;

	if (group.blocks.size() <= N_BLOCKS_SKIP_ISLANDS) {
		group.islands = getBlockIslands(group.blocks);
	} else {
		group.islands = group.fallbackIslands;
	}
};

const onBlockAdded = (state: BlockGroupsState, groupId: string, position: Vector3) => {
	const group = state.groups.get(groupId);
	if (!group) return;

	// mark dirty
	state.dirtyPositions.set(position, true);
	state.dirtyGroupIds.add(groupId);

	// update group bounds and blocks
	group.blocks.add(position);
	group.bounds.expandByPoint(position);
};

const onBlockRemoved = (state: BlockGroupsState, groupId: string, position: Vector3) => {
	const group = state.groups.get(groupId);
	if (!group) return;

	// mark dirty
	state.dirtyPositions.set(position, true);
	state.dirtyGroupIds.add(groupId);

	// update group bounds and blocks
	group.blocks.delete(position);

	if (group.blocks.size() > 0) {
		if (isPointOnEdgeOfBox(group.bounds, position)) {
			group.bounds.makeEmpty();

			const curBlock = _currentBlockPos;
			for (const _ of group.blocks.iterate(curBlock)) {
				group.bounds.expandByPoint(curBlock);
			}
		}
	} else {
		group.bounds.makeEmpty();
	}
};

export const addBlock = (state: BlockGroupsState, groupId: string, x: number, y: number, z: number) => {
	const cursor = _vector3.set(x, y, z);
	const existingGroups = state.blocks.get(cursor);

	const newGroups = new Set(existingGroups);
	newGroups.add(groupId);

	state.blocks.set(cursor, Array.from(newGroups));

	onBlockAdded(state, groupId, cursor);
};

export const removeBlock = (state: BlockGroupsState, groupId: string, x: number, y: number, z: number) => {
	const cursor = _vector3.set(x, y, z);
	const existingGroups = state.blocks.get(cursor);

	const newGroups = new Set(existingGroups);
	newGroups.delete(groupId);

	if (newGroups.size === 0) {
		state.blocks.delete(cursor);
	} else {
		state.blocks.set(cursor, Array.from(newGroups));
	}

	onBlockRemoved(state, groupId, cursor);
};

export const setAtPosition = (
	state: BlockGroupsState,
	groupIds: string[] | undefined,
	x: number,
	y: number,
	z: number,
) => {
	const cursor = _vector3.set(x, y, z);
	const existingGroups = state.blocks.get(cursor);

	if (groupIds) {
		for (const id of groupIds) {
			if (!existingGroups || !existingGroups.includes(id)) {
				onBlockAdded(state, id, cursor);
			}
		}
	}

	if (existingGroups) {
		for (const id of existingGroups) {
			if (!groupIds || !groupIds.includes(id)) {
				onBlockRemoved(state, id, cursor);
			}
		}
	}

	if (typeof groupIds === "undefined" || groupIds.length === 0) {
		state.blocks.delete(cursor);
	} else {
		state.blocks.set(cursor, groupIds);
	}
};

export const clearAtPosition = (state: BlockGroupsState, x: number, y: number, z: number) => {
	setAtPosition(state, undefined, x, y, z);
};

export const getCenterPosition = (state: BlockGroupsState, groupId: string, out: Vector3) => {
	const group = state.groups.get(groupId);
	if (!group) return out;

	group.bounds.getCenter(out);

	return out;
};

export const getSpawnPosition = (state: BlockGroupsState, groupId: string, out: Vector3) => {
	const group = state.groups.get(groupId);
	if (!group) return out;

	group.bounds.getCenter(out);
	out.y = group.bounds.max.y;

	return out;
};

export const moveBlocks = (
	state: BlockGroupsState,
	scene: ChunkScene,
	groupId: string,
	offset: Vector3,
	triggerEvents: boolean,
) => {
	const group = state.groups.get(groupId);
	if (!group) return;

	const backups = new LegacyVectorMap();
	const curBlock = new Vector3();

	for (const _ of group.blocks.iterate(curBlock)) {
		const backup = {
			shape: scene.getShape(curBlock),
			type: scene.getType(curBlock),
		};

		scene.setBlock(curBlock, BLOCK_AIR, -1, triggerEvents);
		removeBlock(state, groupId, curBlock.x, curBlock.y, curBlock.z);

		backups.set(curBlock.add(offset), backup);
	}

	// group is now empty

	for (const backup of backups.iterate(curBlock)) {
		scene.setBlock(curBlock, backup.shape, backup.type, triggerEvents);
		addBlock(state, groupId, curBlock.x, curBlock.y, curBlock.z);
	}
};

export const getBlocks = (state: BlockGroupsState, groupId: string) => {
	const group = state.groups.get(groupId);
	if (!group) return [];

	const blocks: [number, number, number][] = [];

	const curBlock = new Vector3();

	for (const _ of group.blocks.iterate(curBlock)) {
		blocks.push([curBlock.x, curBlock.y, curBlock.z]);
	}

	return blocks;
};

export const setBlocks = (
	blockGroups: BlockGroupsState,
	scene: ChunkScene,
	groupId: string,
	shape: number,
	type: string,
	triggerEvents: boolean,
) => {
	const group = blockGroups.groups.get(groupId);
	if (!group) return;

	const curBlock = new Vector3();
	for (const _ of group.blocks.iterate(curBlock)) {
		scene.setBlock(curBlock, shape, type, triggerEvents);
	}
};

export const getFallbackBlockGroupName = (state: BlockGroupsState, groupId: string) => {
	const group = state.groups.get(groupId);
	if (!group) return "Unnamed Group";

	const center = group.bounds.getCenter(_vector3);

	return `Group at ${center.x.toFixed(0)}, ${center.y.toFixed(0)}, ${center.z.toFixed(0)}`;
};
