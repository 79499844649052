import { MathUtils } from "three";

export const init = () => {
	return {
		offset: 0,
	};
};

export type CrosshairState = ReturnType<typeof init>;

export const updateOffset = (state: CrosshairState, targetOffset: number, dt: number) => {
	state.offset = MathUtils.lerp(state.offset, targetOffset, Math.min(1, (1 - Math.pow(0.001, dt)) * 5));
};
