import { z } from "zod";

import { type IWorldBundle } from "../types";
import { AudioSchema } from "./audio";
import { CharacterSchema } from "./character";
import { BlockTextureSchema } from "./block-texture";
import { EnvironmentPresetSchema } from "./environment-preset";
import { SettingsSchema } from "./setttings";
import { SkyboxSchema } from "./skybox";
import { ThumbnailSchema } from "./thumbnail";
import { GameMechanicsSchema } from "./game-mechanics";
import { CustomLoaderSchema } from "./custom-loader";
import { AvatarComponentSchema, AvatarSchema, CostumeSchema } from "./avatar";
import { BlockSchema } from "./block";
import { MapSchema, WorldDataSchema } from "./world";
import { ASSET_NAME } from "../constants/bundle";
import { PackageDataSchema } from "./package";
import { ResourceSchema } from "./resources";
import { CustomUISchema } from "./custom-ui";
import { ScriptSchema } from "./scripts";
import { ParticleSystemSchema } from "./particle-system";
import { BlockSoundsPackSchema } from "./block-sounds-pack";
import { BlockStructureSchema } from "./block-structure";
import { PropSchema } from "./prop";
import { ItemSchema } from "./item";

export const LATEST_BUNDLE_VERSION = 13;

export const WorldBundleSchema = z.object({
	version: z.number(),
	type: z.literal("world"),
	id: z.string(),
	assets: z.object({
		worldData: WorldDataSchema,
		map: MapSchema,
		thumbnail: ThumbnailSchema.optional(),
		customLoader: CustomLoaderSchema,
		gameMechanics: GameMechanicsSchema,
		settings: SettingsSchema,
		audios: z.record(AudioSchema).optional(),
		avatarComponents: z.record(AvatarComponentSchema).optional(),
		avatars: z.record(AvatarSchema).optional(),
		blockTextures: z.record(BlockTextureSchema).optional(),
		blocks: z.record(BlockSchema).optional(),
		characters: z.record(CharacterSchema).optional(),
		environmentPresets: z.record(EnvironmentPresetSchema),
		skyboxes: z.record(SkyboxSchema).optional(),
		costumes: z.record(CostumeSchema).optional(),
		scripts: z.record(ScriptSchema).optional(),
		particleSystems: z.record(ParticleSystemSchema).optional(),
		resources: z.record(ResourceSchema).optional(),
		customUI: z.record(CustomUISchema).optional(),
		blockSoundsPacks: z.record(BlockSoundsPackSchema).optional(),
		blockStructures: z.record(BlockStructureSchema).optional(),
		props: z.record(PropSchema).optional(),
		items: z.record(ItemSchema).optional(),
	}),
});

export const PackageBundleSchema = z.object({
	version: z.number(),
	type: z.literal("package"),
	id: z.string(),
	assets: z.object({
		packageData: PackageDataSchema.optional(),
		audios: z.record(AudioSchema).optional(),
		avatarComponents: z.record(AvatarComponentSchema).optional(),
		avatars: z.record(AvatarSchema).optional(),
		blockTextures: z.record(BlockTextureSchema).optional(),
		blocks: z.record(BlockSchema).optional(),
		characters: z.record(CharacterSchema).optional(),
		environmentPresets: z.record(EnvironmentPresetSchema).optional(),
		skyboxes: z.record(SkyboxSchema).optional(),
		costumes: z.record(CostumeSchema).optional(),
		scripts: z.record(ScriptSchema).optional(),
		particleSystems: z.record(ParticleSystemSchema).optional(),
		resources: z.record(ResourceSchema).optional(),
		customUI: z.record(CustomUISchema).optional(),
		blockSoundsPacks: z.record(BlockSoundsPackSchema).optional(),
		blockStructures: z.record(BlockStructureSchema).optional(),
		props: z.record(PropSchema).optional(),
		items: z.record(ItemSchema).optional(),
	}),
});

export function all(bundle: IWorldBundle) {
	return WorldBundleSchema.safeParse(bundle);
}

export const formatBundleErrors = (obj: any, errorCompiler: any = {}, parentKey = "") => {
	// Iterate through each key-value pair in the object
	for (const key in obj) {
		if (!Object.hasOwn(obj, key)) continue;

		const value = obj[key];

		// Check if the current value is an object
		if (typeof value === "object" && value !== null) {
			// Check if the object has an "_errors" array with at least one item
			if (Array.isArray(value._errors) && value._errors.length > 0) {
				const main = ASSET_NAME[parentKey.split(".")[0] as keyof typeof ASSET_NAME];
				if (Object.hasOwn(errorCompiler, main)) {
					errorCompiler[main].push(...value._errors);
				} else {
					errorCompiler[main] = value._errors;
				}
			}

			// Recursively search in nested objects, passing the key as parentKey
			formatBundleErrors(value, errorCompiler, parentKey ? `${parentKey}.${key}` : key);
		}
	}
};
