export class AnimationNode {
	constructor(animMesh, locomotionInput) {
		this.mesh = animMesh;
		this.inputState = locomotionInput.state;

		this.wasUpdatedLastFrame = false;
	}

	shouldUpdate() {
		return true;
	}

	startCompute() {}

	stopCompute() {}

	updateImplementation() {}

	update() {
		const shouldExecute = this.shouldUpdate();

		if (!shouldExecute) {
			if (this.wasUpdatedLastFrame) {
				this.stopCompute();
			}
			this.wasUpdatedLastFrame = false;
			return;
		}

		if (!this.wasUpdatedLastFrame) {
			this.startCompute();
		}

		this.updateImplementation();

		this.wasUpdatedLastFrame = true;
	}
}
