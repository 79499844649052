import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { LoopRepeat } from "three";

export class LocomotionStateWalkingFP extends LocomotionOutputState {
	constructor(animMesh, locomotionInput) {
		super("WalkingFP", animMesh, locomotionInput);

		this.transitionToIdleTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToJumpingTime = 0.1;

		this.itemPencilTrack = new AnimationTrack(animMesh, "JMGO_Pencil_FP_NotAim_Walk", this);
		this.itemNovaGunTrack = new AnimationTrack(animMesh, "JMGO_NovaGun_FP_NotAim_Walk", this);
		this.itemSpadeTrack = new AnimationTrack(animMesh, "JMGO_Spade_FP_NotAim_Walk", this);
		this.itemWrenchTrack = new AnimationTrack(animMesh, "JMGO_Wrench_FP_NotAim_Walk", this);
		this.itemZapperPistolTrack = new AnimationTrack(animMesh, "JMGO_ZapperPistol_FP_NotAim_Walk", this);
		this.itemNovaRifleTrack = new AnimationTrack(animMesh, "JMGO_NovaRifle_FP_NotAim_Walk", this);
		this.itemNovaAssaultTrack = new AnimationTrack(animMesh, "JMGO_NovaAssault_FP_NotAim_Walk", this);
		this.itemZapperRifleTrack = new AnimationTrack(animMesh, "JMGO_ZapperRifle_FP_NotAim_Walk", this);
		this.itemZapperAssaultTrack = new AnimationTrack(animMesh, "JMGO_ZapperAssault_FP_NotAim_Walk", this);

		this.itemNovaGunAimTrack = new AnimationTrack(animMesh, "JMGO_NovaGun_FP_Aim_Walk", this);
		this.itemZapperPistolAimTrack = new AnimationTrack(animMesh, "JMGO_ZapperPistol_FP_Aim_Walk", this);
		this.itemNovaRifleAimTrack = new AnimationTrack(animMesh, "JMGO_NovaRifle_FP_Aim_Walk", this);
		this.itemNovaAssaultAimTrack = new AnimationTrack(animMesh, "JMGO_NovaAssault_FP_Aim_Walk", this);
		this.itemZapperRifleAimTrack = new AnimationTrack(animMesh, "JMGO_ZapperRifle_FP_Aim_Walk", this);
		this.itemZapperAssaultAimTrack = new AnimationTrack(animMesh, "JMGO_ZapperAssault_FP_Aim_Walk", this);

		this.walkTrackMap = new Map();
		this.walkTrackMap.set("Pencil", this.itemPencilTrack);
		this.walkTrackMap.set("NovaGun", this.itemNovaGunTrack);
		this.walkTrackMap.set("Spade", this.itemSpadeTrack);
		this.walkTrackMap.set("Wrench", this.itemWrenchTrack);
		this.walkTrackMap.set("ZapperPistol", this.itemZapperPistolTrack);
		this.walkTrackMap.set("NovaRifle", this.itemNovaRifleTrack);
		this.walkTrackMap.set("NovaAssault", this.itemNovaAssaultTrack);
		this.walkTrackMap.set("ZapperRifle", this.itemZapperRifleTrack);
		this.walkTrackMap.set("ZapperAssault", this.itemZapperAssaultTrack);

		this.walkAimTrackMap = new Map();
		this.walkAimTrackMap.set("NovaGun", this.itemNovaGunAimTrack);
		this.walkAimTrackMap.set("ZapperPistol", this.itemZapperPistolAimTrack);
		this.walkAimTrackMap.set("NovaRifle", this.itemNovaRifleAimTrack);
		this.walkAimTrackMap.set("NovaAssault", this.itemNovaAssaultAimTrack);
		this.walkAimTrackMap.set("ZapperRifle", this.itemZapperRifleAimTrack);
		this.walkAimTrackMap.set("ZapperAssault", this.itemZapperAssaultAimTrack);

		this.lastTrack = this.itemWrenchTrack;
	}

	getTrack() {
		const map = this.inputState.isAiming ? this.walkAimTrackMap : this.walkTrackMap;
		return map.get(this.inputState.item);
	}

	onStateOff() {
		super.onStateOff();
		for (const track of this.animationTracks) {
			track.setCurrentWeight(0);
		}
	}

	startCompute(transitionData) {
		const track = this.getTrack();

		this.lastTrack = track ?? this.itemWrenchTrack;
		this.lastTrack.action.loop = LoopRepeat;
		this.lastTrack.switchOn();

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.lastTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		const previousTrack = this.lastTrack;
		const newTrack = this.getTrack();
		if (previousTrack !== newTrack && this.walkTrackMap.get(this.inputState.item)) {
			previousTrack.startFadeOut(0.2);

			newTrack.action.reset();
			newTrack.action.loop = LoopRepeat;
			newTrack.startFadeIn(0.2);
			newTrack.action.play();
			this.lastTrack = newTrack;
		}

		for (const track of this.animationTracks) {
			if (track !== previousTrack && track !== newTrack && track.state === "on") {
				track.switchOff();
			}
		}

		this.updateWeight();
		this.itemPencilTrack.update();
		this.itemNovaGunTrack.update();
		this.itemSpadeTrack.update();
		this.itemWrenchTrack.update();
		this.itemZapperPistolTrack.update();
		this.itemZapperRifleTrack.update();
		this.itemZapperAssaultTrack.update();
		this.itemNovaAssaultTrack.update();
		this.itemNovaRifleTrack.update();
		this.itemNovaGunAimTrack.update();
		this.itemNovaRifleAimTrack.update();
		this.itemNovaAssaultAimTrack.update();
		this.itemZapperPistolAimTrack.update();
		this.itemZapperRifleAimTrack.update();
		this.itemZapperAssaultAimTrack.update();
	}

	shouldTransitionCustom(outTransitionToState, map) {
		// this is a hack, ideally throw completion event when reload animation is finished
		const reloadAnimationPlaying =
			this.mesh.getAction("JMGO_NovaGun_FP_NotAim_Reload").getEffectiveWeight() > 0 ||
			this.mesh.getAction("JMGO_ZapperPistol_FP_NotAim_Reload").getEffectiveWeight() > 0 ||
			this.mesh.getAction("JMGO_NovaRifle_FP_NotAim_Reload").getEffectiveWeight() > 0 ||
			this.mesh.getAction("JMGO_NovaAssault_FP_NotAim_Reload").getEffectiveWeight() > 0 ||
			this.mesh.getAction("JMGO_ZapperRifle_FP_NotAim_Reload").getEffectiveWeight() > 0 ||
			this.mesh.getAction("JMGO_ZapperAssault_FP_NotAim_Reload").getEffectiveWeight() > 0;

		if (!reloadAnimationPlaying) {
			if (this.inputState.isReloading) {
				outTransitionToState.ptr = map.get("ReloadFP");
				outTransitionToState.blendTime = 0.2;
				return true;
			}

			if (this.inputState.isFalling) {
				if (this.inputState.isJumping) {
					outTransitionToState.ptr = map.get("JumpingFP");
					outTransitionToState.blendTime = this.transitionToJumpingTime;
					return true;
				}
			}
		}

		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("IdleFP");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isSprinting) {
			outTransitionToState.ptr = map.get("RunningFP");
			outTransitionToState.blendTime = this.transitionToRunningTime;
			return true;
		}

		return false;
	}
}
