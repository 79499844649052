export const SKY_MIDNIGHT = 0;
export const SKY_SUNRISE = 0.25;
export const SKY_NOON = 0.5;
export const SKY_SUNSET = 0.75;

const LIGHT_PROPERTIES = ["sunColor", "sunIntensity", "nightLuminosity"];
const GENERAL_PROPERTIES = ["hour", "azimuth"];
const SKY_PROPERTIES = [
	"fogy",
	"haze",
	"step",
	"sample",
	"fogColor",
	"skyColor",
	"cloudSize",
	"cloudColor",
	"saturation",
	"groundColor",
	"toneMapping",
	"cloudDensity",
	"cloudCoverage",
	"cloudDistance",
	"toneMappingExposure",
];
const FOG_PROPERTIES = ["fogFar", "fogNear"];

export class Settings {
	/**
	 * @type {import('server/world/Settings').SettingsServer | undefined}
	 */
	server;

	/**
	 * @type {import('client/world/Settings').SettingsClient | undefined}
	 */
	client;

	/**
	 * @type {import('router/world/Settings').SettingsRouter}
	 */
	router;

	/**
	 * @type {import("@jamango/content-client").IEnvironmentPreset["preset"]}
	 */
	environment;

	constructor(world, options = {}) {
		this.world = world;
		this.sky = this.world.sky;
		// Deep copy prevents obj by reference and creates a whole new object for env settings
		this.environment = structuredClone(options.environment); //set by client or by server/
	}

	/**
	 * change - Saves the env settings configuration. Only saves the data.
	 * @param {Object} settings - Environment settings
	 */
	change(settings) {
		for (const property in settings ?? {}) {
			if (FOG_PROPERTIES.includes(property)) {
				this.environment.fog[property] = settings[property];
			}
			if (SKY_PROPERTIES.includes(property)) {
				this.environment.sky[property] = settings[property];
			}
			if (LIGHT_PROPERTIES.includes(property)) {
				this.environment.light[property] = settings[property];
			}
			if (GENERAL_PROPERTIES.includes(property)) {
				this.environment.general[property] = settings[property];
			}
		}
	}
}
