import { V0 } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";
import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, Vector3 } from "three";

export class ItemWeaponComponent {
	name = "weapon";

	/**
	 * @param {import('mods/defs/ItemType').ItemType} o
	 * @param {any} entity - Item entity.
	 */
	constructor(o, entity) {
		this.def = {
			// melee
			entityDamage: o.entityDamage ?? 0,
			// projectile
			projectile: o.projectile,
			projectilePos: o.projectilePos ? new Vector3().copy(o.projectilePos ?? V0) : null,
			projectilesPerShot: o.projectilesPerShot ?? 1,
			// ranged weapon
			fovAim: o.fovAim ?? null,
			muzzleFlashNode: isNullish(o.muzzleFlashNode)
				? null
				: entity.mesh?.mesh.getObjectByName(o.muzzleFlashNode),
			/**
			 * @type {Array<Record<string, any>>|null}
			 */
			muzzleFlashParticles: structuredClone(o.muzzleFlashParticles ?? null),
			// animation times
			shootAnimationName: structuredClone(o.shootAnimationName ?? null),
			reloadAnimationName: o.reloadAnimationName,
			reloadAnimationTime: o.reloadAnimationTime ?? 0,
			// reloading
			cooldown: o.cooldown ?? 0.25,
			isAutomatic: o.automatic ?? false,
			// accuracy
			spread: o.spread ?? 1,
			// recoil
			recoil: o.recoil ?? 0,
			// magazine capacity
			projectileCartridgeLimit: o.projectileCartridgeLimit ?? 1,
		};

		this.sounds = o.sound;

		this.state = {
			// reloading
			cooldownTimer: 0,
			used: false,
			firingFirstShot: false,
			// accuracy
			isReloading: false,
			// recoil
			recoil: 0,
			// spread
			spreadAccumulator: 0,
			// magazine capacity
			projectileAvailableCount: o.projectileCartridgeLimit ?? 1, // initialise to be full capacity
		};

		if (this.def.isAutomatic && this.def.cooldown === 0)
			throw RangeError("Automatic item's cooldown cannot be 0");

		if (this.def.muzzleFlashNode) {
			// hack: transform muzzle flash box skinned mesh into a plane
			// we will change models to just describe the position muzzle flashes should originate from instead
			const muzzleFlashSkinnedMesh = this.def.muzzleFlashNode;

			const muzzleFlashMaterial = new MeshBasicMaterial({
				transparent: true,
				side: DoubleSide,
				map: muzzleFlashSkinnedMesh.material.map,
			});

			muzzleFlashSkinnedMesh.geometry.computeBoundingBox();

			const muzzleFlashBoundingBox = muzzleFlashSkinnedMesh.geometry.boundingBox;
			const muzzleFlashSize = muzzleFlashBoundingBox.getSize(new Vector3());

			const muzzleFlashGeometry = new PlaneGeometry(muzzleFlashSize.x, muzzleFlashSize.z);
			muzzleFlashGeometry.rotateX(-Math.PI / 2);

			const muzzleFlashMesh = new Mesh(muzzleFlashGeometry, muzzleFlashMaterial);
			muzzleFlashMesh.position.set(
				(muzzleFlashBoundingBox.max.x + muzzleFlashBoundingBox.min.x) / 2,
				muzzleFlashBoundingBox.max.y,
				(muzzleFlashBoundingBox.max.z + muzzleFlashBoundingBox.min.z) / 2,
			);

			muzzleFlashSkinnedMesh.parent.add(muzzleFlashMesh);
			muzzleFlashSkinnedMesh.removeFromParent();

			this.def.muzzleFlashNode = muzzleFlashMesh;

			// Make flash invisible by default
			this.def.muzzleFlashNode.visible = false;
		}
	}
}
