import { NODE } from "base/rete/InternalNameMap";
import * as BlockGroups from "base/world/block/BlockGroups.ts";
import { Vector3 } from "three";
import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";
import type { EntryPoint, InterpreterTrigger, TriggerContext, BlockTriggerExtraInfo } from "base/rete/Types";
import type { Entity } from "base/world/entity/Entity";
import type * as InputManagerClient from "client/world/InputManager";

//tigger a single entry point to run
export const triggerEntryPoint = (
	info: Record<string, any> | undefined,
	entryPoint: EntryPoint,
	world: World,
): void => {
	if (world.editor.paused) return;

	// we queue trigger events. they are generated by the world, e.g. stepping on a block
	world.rete.triggerQueue.push({ info, entryPoint } as InterpreterTrigger);
};

export const onGameTick = (world: World, deltaTime: number): void => {
	world.rete.triggers.onGameTick.forEach(({ entryPoint }) => {
		triggerEntryPoint({ deltaTime }, entryPoint, world);
	});
};

export const onWorldStateChange = (world: World, state: string, value: any): void => {
	// check if we have a listener for this world state
	if (world.rete.triggers.onWorldStateChange[state] !== undefined) {
		world.rete.triggers.onWorldStateChange[state].forEach(({ entryPoint }) => {
			triggerEntryPoint({ state, value }, entryPoint, world);
		});
	}
};

export const onEntityStateChange = (world: World, entity: Entity, state: string, value: any): void => {
	// check if we have a listener for this entity state
	if (world.rete.triggers.onEntityStateChange[state] !== undefined) {
		world.rete.triggers.onEntityStateChange[state].forEach(({ entryPoint }) => {
			triggerEntryPoint({ entity, state, value }, entryPoint, world);
		});
	}
};

export const onPeerPersistentStateChange = (world: World, peer: Entity, state: string, value: any): void => {
	// check if we have a listener for this peer persistent state
	if (world.rete.triggers.onPeerPersistentStateChange[state] !== undefined) {
		world.rete.triggers.onPeerPersistentStateChange[state].forEach(({ entryPoint }) => {
			triggerEntryPoint({ peer, state, value }, entryPoint, world);
		});
	}
};

// this function calls events for both blockgroup and blocktype events.
export const onBlockEvent = (
	defID: string,
	world: World,
	x: number,
	y: number,
	z: number,
	extraInfo: BlockTriggerExtraInfo,
): void => {
	onBlockGroupEvent(defID, world, x, y, z, extraInfo);
	onBlockTypeEvent(defID, world, x, y, z, extraInfo);
};

export function onBlockGroupEvent(
	defID: string,
	world: World,
	x: number,
	y: number,
	z: number,
	extraInfo: BlockTriggerExtraInfo,
	eventGroupId: string | null = null,
): void {
	// triggers all events that have a character context and relate to a block with a position

	// can super-early exit if we have no listeners for this
	if (world.rete.triggers.onBlock[defID] === undefined) return;

	// get the block group at this coordinate. if there is no group, early exit.
	const groups = BlockGroups.getIdsAtPosition(world.blockGroups, x, y, z);
	if (!groups) return;

	for (const groupId of groups) {
		if (eventGroupId !== null && groupId !== eventGroupId) continue;

		const listenerKey = extraInfo?.bodyPart ? `${extraInfo.bodyPart}${groupId}` : groupId;
		const entryPoints = world.rete.triggers.onBlock[defID][listenerKey];
		if (entryPoints === undefined) continue;

		// eventType is only used by all feet enter
		entryPoints.forEach(({ entryPoint }) => {
			if (whoCanTriggerCheck(world, entryPoint, extraInfo.character))
				triggerEntryPoint(
					{ ...extraInfo, position: new Vector3(x, y, z), eventType: defID },
					entryPoint,
					world,
				);
		});
	}
}

export function onBlockTypeEvent(
	defID: string,
	world: World,
	x: number,
	y: number,
	z: number,
	extraInfo: BlockTriggerExtraInfo,
): void {
	// triggers all events that have a character context and relate to a block with a position

	// can super-early exit if we have no listeners for this
	if (world.rete.triggers.onBlock[defID] === undefined) return;

	// get the block type at this coordinate
	const blockId = world.scene.getType(x, y, z);
	const type = world.content.state.blocks.get(blockId);
	if (type === undefined || type === null || type.scripts === undefined || type.scripts.length === 0)
		return;

	// check if this type is registered as a listener for this block event type
	const typeReteId = blockId;

	const listenerKey = extraInfo?.bodyPart ? `${extraInfo.bodyPart}${typeReteId}` : typeReteId;
	const triggers = world.rete.triggers.onBlock[defID][listenerKey];
	if (triggers === undefined) return;

	//eventType is only used by all feet enter
	triggers.forEach(({ entryPoint }) => {
		if (whoCanTriggerCheck(world, entryPoint, extraInfo?.character))
			triggerEntryPoint(
				{ ...extraInfo, position: new Vector3(x, y, z), eventType: defID },
				entryPoint,
				world,
			);
	});
}

export const onPeerJoin = (world: World, player: Entity): void => {
	world.rete.triggers.onPeerJoin.forEach(({ entryPoint }) => {
		triggerEntryPoint({ peer: player }, entryPoint, world);
	});
};

export const onPeerLeave = (world: World, player: Entity): void => {
	world.rete.triggers.onPeerLeave.forEach(({ entryPoint }) => {
		triggerEntryPoint({ peer: player }, entryPoint, world);
	});
};

export const onChat = (world: World, peer: Entity, msg: string): void => {
	// do not attempt to do anything if message is empty
	if (msg.length === 0) return;

	// get the key - e.g. "test" and see if we have any listeners to this event
	const key = msg.split(" ")[0];

	// check if we have a listener for this chat message
	if (world.rete.triggers.onChat[key] !== undefined) {
		// for each listener of this message, parse the values into an object
		const values = msg.split(/\s+/).slice(1);
		world.rete.triggers.onChat[key].forEach(({ keys, entryPoint }) => {
			// create object of shape { hey: "dsa" }
			const obj = Object.fromEntries((keys as string[]).map((k, i) => [k, values[i] || null]));
			triggerEntryPoint({ peer, obj }, entryPoint, world);
		});
	}
};

export const onSoundEnd = (world: World, soundId: number, soundName: string): void => {
	world.rete.triggers.onSoundEnd.forEach(({ entryPoint }) => {
		triggerEntryPoint({ soundId, soundName }, entryPoint, world);
	});
};

export const onControl = (world: World, peer: Entity, key: string, press: string): void => {
	// look up the key and press combination
	if (
		world.rete.triggers.onControl[key] !== undefined &&
		world.rete.triggers.onControl[key][press] !== undefined
	) {
		// iterate all listeners and fire entry points
		world.rete.triggers.onControl[key][press].forEach(({ entryPoint }) => {
			triggerEntryPoint({ peer }, entryPoint, world);
		});
	}
};

export const onItemUse = (
	world: World,
	itemEntity: Entity,
	characterEntity: Entity,
	item: string,
	primary: boolean,
	secondary: boolean,
): void => {
	if (world.rete.triggers.onItemUse[item] !== undefined) {
		world.rete.triggers.onItemUse[item].forEach(({ entryPoint }) => {
			triggerEntryPoint(
				{ item: itemEntity, character: characterEntity, primary, secondary },
				entryPoint,
				world,
			);
		});
	}
};

export const onItemUnuse = (
	world: World,
	itemEntity: Entity,
	characterEntity: Entity,
	item: string,
): void => {
	if (world.rete.triggers.onItemUnuse[item] !== undefined) {
		world.rete.triggers.onItemUnuse[item].forEach(({ entryPoint }) => {
			triggerEntryPoint({ item: itemEntity, character: characterEntity }, entryPoint, world);
		});
	}
};

export const onItemRangedWeaponFire = (
	world: World,
	itemEntity: Entity,
	characterEntity: Entity,
	item: string,
): void => {
	if (world.rete.triggers.onItemRangedWeaponFire[item] !== undefined) {
		world.rete.triggers.onItemRangedWeaponFire[item].forEach(({ entryPoint }) => {
			triggerEntryPoint({ item: itemEntity, character: characterEntity }, entryPoint, world);
		});
	}
};

export const onMapLoad = (world: World): void => {
	if (netState.isClient) {
		// parse control triggers for input system
		// this lets a client know which buttons may potentially trigger rete actions, even non-predictable
		// which is necessary to scan for button presses
		const inputs = world.input as InputManagerClient.State;
		inputs.keyCombos.length = 0;
		for (const [_, script] of world.rete.scripts) {
			for (const id in script.entryNodes) {
				const { defId, data } = script.entryNodes[id].node;
				if (defId === NODE.OnControlPress || defId === NODE.OnControlRelease) {
					if (data === undefined) {
						console.warn("undefined onControl node data");
						continue;
					}
					// "ctrl + b" -> "ctrl+b" -> ['ctrl','b']
					const combo = data.name.replace(/\s/g, "");
					const split = combo.split("+");
					inputs.keyCombos.push({ combo, split, press: defId === NODE.OnControlPress });
				}
			}
		}
	}

	// spawn triggers
	const spawnTriggers = world.rete.triggers.onBlock[NODE.OnBlockSpawn];

	if (spawnTriggers) {
		for (const [blockGroup, entryPoints] of Object.entries(spawnTriggers)) {
			for (const { entryPoint } of entryPoints as TriggerContext[]) {
				if (entryPoint.type !== "group") continue;
				const position = BlockGroups.getSpawnPosition(world.blockGroups, blockGroup, new Vector3());
				triggerEntryPoint({ position }, entryPoint, world);
			}
		}

		for (const chunk of world.scene.chunks.values()) {
			chunk.spawnBlocks(true);
		}
	}

	// on game start
	world.rete.triggers.onGameStart.forEach(({ entryPoint }) => {
		triggerEntryPoint(undefined, entryPoint, world);
	});
};

// this does the "who can trigger" check (NPC/player)
// ideally this would be part of the initial trigger setup, but requires more sophistication. TODO: improve.
const whoCanTriggerCheck = (world: World, entryPoint: EntryPoint, entity: any): boolean => {
	const who = world.rete.nodes.get(entryPoint.targetId)?.data?.whoCanTrigger;

	// not every trigger has a whoCanTrigger
	// for example, on block spawn isn't triggered by an entity
	if (who === undefined) return true;

	let type = "";
	if (entity) {
		if (entity.type.def.isPlayer) type = "player";
		if (entity.type.def.isNPC) type = "npc";
	}

	return (who as string[]).includes(type);
};
