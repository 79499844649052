export class CharacterSizeComponent {
	constructor(o, entity) {
		this.name = "size";

		this.def = {
			radius: o?.radius ?? 0.45,
			height: o?.height ?? 2.9,
			contactOffset: o?.contactOffset ?? 0.01,
			padding: o?.padding ?? 0.02,

			eyeHeight: o?.eyeHeight ?? 2.55,
			headshotHeight: o?.headHeightMin ?? 2.125,
			nameplateHeight: o?.nameplateHeight ?? 2.99,
			crouchOffset: o?.crouchOffset ?? 1.1,
		};

		this.state = {
			scale: o?.scale ?? 1,
			currentHeight: this.def.height,
			radius: 0,
			height: 0,
			contactOffset: 0,
			eyeHeight: 0,
			headshotHeight: 0,
			nameplateHeight: 0,
			crouchOffset: 0,
		};

		this.updateScaledProperties();

		if (this.def.contactOffset <= 0) entity.defError("contactOffset <= 0");
		if (this.def.radius - this.def.contactOffset <= 0) entity.defError("radius - contactOffset <= 0");
		if (this.def.height - this.def.crouchOffset - 2 * (this.def.radius - this.def.contactOffset) <= 0)
			entity.defError("height - crouchOffset- 2 * (radius - contactOffset) <= 0");
	}

	updateScale(scale) {
		this.state.scale = scale;

		this.updateScaledProperties();
	}

	updateScaledProperties() {
		const s = this.state.scale;
		this.state.radius = this.def.radius * s;
		this.state.height = this.def.height * s;
		this.state.contactOffset = this.def.contactOffset * s;
		this.state.eyeHeight = this.def.eyeHeight * s;
		this.state.headshotHeight = this.def.headshotHeight * s;
		this.state.nameplateHeight = this.def.nameplateHeight * s;
		this.state.crouchOffset = this.def.crouchOffset * s;
	}
}
