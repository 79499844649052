import { BB } from "base/BB";
import { Multiplayer } from "base/Multiplayer.js";
import { VNX, VNY, VNZ, VX, VY, VZ } from "base/util/math/Math.ts";
import * as Physics from "base/world/Physics";
import { calculateAO } from "base/world/block/AmbientOcclusion.js";
import { MISSING_BLOCK_TYPE } from "base/world/block/BlockTypeRegistry";
import { ChunkBuilder } from "base/world/block/Builder.js";
import { triggerBlockChangeEvents } from "base/world/block/Type.js";
import { BLOCK_AIR, ChunkUtil } from "base/world/block/Util.js";
import {
	AGGREGATE_EMPTY,
	AGGREGATE_FULL,
	BlockStorageComponent,
} from "base/world/block/chunk/StorageComponent.js";
import { Layers } from "client/Layers.js";
import { netState } from "router/Parallelogram";
import { Sphere, Vector3 } from "three";

export const CHUNK_DISPOSE_PRESERVE = 0; //force data preservation on dispose so that the chunk can be rebuilt without needing to redownload/regenerate
export const CHUNK_DISPOSE_DEFAULT = 1; //only preserve data on the host if it was modified after generation
export const CHUNK_DISPOSE_BYEBYE = 2; //force data delete
export const CHUNK_FADE = 0.2; //visual fade length in seconds

const tmpVec = new Vector3();

let aoRequestID = 0;
const aoPromises = [];

export class Chunk {
	constructor(scene, cx, cy, cz, data) {
		this.batchPointers = {
			opaque: null,
			alphaTested: null,
			translucent: null,
		};

		this.geometries = {
			opaque: null,
			alphaTested: null,
			translucent: null,
		};

		/**
		 * Populated with block indices of non-air blocks during chunk building
		 * @type {Set<number> | undefined}
		 */
		this.blockTypes = undefined;

		this.freeMemory = false;

		const hosting = netState.isHost;

		/**
		 * @type {import('base/world/block/Scene').ChunkScene}
		 */
		this.scene = scene;
		this.position = new Vector3(cx, cy, cz);
		scene.chunks.set(this.position, this);

		this.worldPositionOffset = this.position.clone().multiplyScalar(scene.chunkSize);

		this.blocksSpawned = false;

		this.built = false;
		this.buildAttempted = false;
		this.renderFrame = -69;

		this.storage = new BlockStorageComponent(this);

		this.aoPromise = {
			opaque: null,
			alphaTested: null,
			transparent: null,
		};

		this.neighborCount = 0;

		if (data) {
			this.load(data);
		} else {
			if (hosting) this.storage.createEmptyData();

			for (const gen of scene.generators) gen.apply(this);

			if (hosting) this.storage.compress();

			this.modified = false;
		}
	}

	isVisibleInAggregate(scene) {
		// if our aggregate is not zero, compare to neighbors
		const agg = this.storage.getAggregate();
		if (agg === AGGREGATE_EMPTY) {
			// we are fully air. we can 100% be skipped
			return false;
		} else if (agg === AGGREGATE_FULL) {
			// we are fully solid. we can be skipped if all neighbors are fully solid
			const neighbors = this.getNeighborChunks(scene);
			for (const n of neighbors) {
				if (n.storage.getAggregate() !== AGGREGATE_FULL) {
					return true;
				}
			}
			return false;
		}
		return true;
	}

	getIndex(rx, ry, rz) {
		const s = this.scene.chunkSize;
		return ChunkUtil.getIndex(s, s, s, rx, ry, rz);
	}

	getPosition(i, out) {
		const s = this.scene.chunkSize;
		return ChunkUtil.getPosition(s, s, s, i, out);
	}

	getShape(rx, ry, rz) {
		const storage = this.storage;
		storage.onReadOp();

		// TODO: move to storage! in the chunk build function, for the neighbor checks, if the aggregate is fully solid/empty, we can early exit and dont actually have to read the values

		if (this.storage.shapes === null) return 0;
		return storage.shapes[this.getIndex(rx, ry, rz)];
	}

	getType(rx, ry, rz) {
		const blockTypeRegistry = this.scene.world.blockTypeRegistry;
		const blockType =
			blockTypeRegistry.blockIdToType.get(this.getTypeID(rx, ry, rz)) ?? MISSING_BLOCK_TYPE;

		return blockType.name;
	}

	getTypeID(rx, ry, rz) {
		const storage = this.storage;
		storage.onReadOp();

		// TODO: move to storage! in the chunk build function, for the neighbor checks, if the aggregate is fully solid/empty, we can early exit and dont actually have to read the values

		if (this.storage.types === null) return 0;
		return storage.types[this.getIndex(rx, ry, rz)];
	}

	setBlock(rx, ry, rz, shape, blockTypeName, triggerEvents, triggerCharacter) {
		const storage = this.storage;
		storage.onWriteOp();

		if (this.storage.shapes === null)
			throw "Attempting to set block on a storage that could not yet be decompressed";

		const scene = this.scene;
		const i = this.getIndex(rx, ry, rz);

		const prvShape = storage.shapes[i];
		const prvType = storage.types[i];

		const blockTypeRegistry = scene.world.blockTypeRegistry;
		const blockTypeId = blockTypeRegistry.blockNameToType.get(blockTypeName)?.id ?? 0;

		if (ChunkUtil.discardShape(shape)) shape = BLOCK_AIR;

		storage.shapes[i] = shape;
		storage.types[i] = blockTypeId;

		if (triggerEvents) {
			const s = scene.chunkSize;
			const ax = this.position.x * s + rx;
			const ay = this.position.y * s + ry;
			const az = this.position.z * s + rz;

			triggerBlockChangeEvents(
				this.scene,
				ax,
				ay,
				az,
				prvShape,
				shape,
				prvType,
				blockTypeId,
				triggerCharacter,
			);
		}

		this.modified = true;

		return true;
	}

	save() {
		return { data: this.storage.compress() };
	}

	load(save) {
		this.storage.compressedData = save.data;
		this.modified = true;
	}

	spawnBlocks(respawn = false) {
		if (
			!netState.isHost ||
			this.scene.world.editor.paused ||
			!this.blockTypes ||
			(this.blocksSpawned && !respawn)
		)
			return;

		const shapes = this.storage.shapes;
		const types = this.storage.types;

		if (!shapes) return;

		const blockTypeRegistry = this.scene.world.blockTypeRegistry;
		const spawnBlockTypes = blockTypeRegistry.spawnBlockTypes;

		const anySpawnBlocks = Array.from(spawnBlockTypes).some((id) => this.blockTypes.has(id));

		if (anySpawnBlocks) {
			for (let blockIndex = 0; blockIndex < shapes.length; blockIndex++) {
				const shape = shapes[blockIndex];
				if (shape === BLOCK_AIR) continue;

				const typeID = types[blockIndex];

				const isSpawnBlockType = spawnBlockTypes.has(typeID);
				if (!isSpawnBlockType) continue;

				const chunkLocalPosition = this.getPosition(blockIndex, tmpVec);
				const wx = this.worldPositionOffset.x + chunkLocalPosition.x;
				const wy = this.worldPositionOffset.y + chunkLocalPosition.y;
				const wz = this.worldPositionOffset.z + chunkLocalPosition.z;

				this.scene.respawnBlock(wx, wy, wz);
			}
		}

		this.blocksSpawned = true;
	}

	getNeighborChunks(scene) {
		const pos = this.position;
		const neighborChunks = [
			scene.getChunk(tmpVec.copy(pos).add(VNX)),
			scene.getChunk(tmpVec.copy(pos).add(VX)),
			scene.getChunk(tmpVec.copy(pos).add(VNY)),
			scene.getChunk(tmpVec.copy(pos).add(VY)),
			scene.getChunk(tmpVec.copy(pos).add(VNZ)),
			scene.getChunk(tmpVec.copy(pos).add(VZ)),
		];
		return neighborChunks;
	}

	//returns false if download needed, promise if download needed and needPromise true, true on success
	//the returned promise doesn't wait for ao thread
	build(aoMainThread) {
		const scene = this.scene;

		const storage = this.storage;

		this.dispose(CHUNK_DISPOSE_PRESERVE, true);
		this.buildAttempted = true;

		if (!this.isVisibleInAggregate(scene)) {
			this.built = true;
			return true;
		}

		const pos = this.position;

		//cache these for the visibility test to avoid thousands of vectormap lookups per build()
		const neighborChunks = this.getNeighborChunks(scene);
		neighborChunks.push(this);

		const chunkSize = scene.chunkSize;

		//optimization: ignore the received aoMainThread argument if the chunk is outside camera frustum
		if (aoMainThread) {
			if (netState.isClient) {
				const frustum = scene.world.client.renderCamera.frustum;
				if (frustum) {
					const frustumSphere = new Sphere(
						pos.clone().addScalar(0.5).multiplyScalar(chunkSize),
						ChunkUtil.getRadius(chunkSize),
					);

					aoMainThread = frustum.intersectsSphere(frustumSphere);
				}
			} else {
				aoMainThread = false; //no ao on dedi. need to make sure this is set correctly for storage.build
			}
		}

		const {
			physicsCollisionEnabledBuffer,
			physicsCollisionDisabledBuffer,
			opaqueBuffer,
			alphaTestedBuffer,
			translucentBuffer,
			blockTypes,
		} = storage.build(neighborChunks, aoMainThread);

		this.blockTypes = blockTypes;

		this.spawnBlocks();

		const posOffset = tmpVec.copy(pos).multiplyScalar(chunkSize);

		// physics
		if (
			physicsCollisionDisabledBuffer.index.length > 0 ||
			physicsCollisionEnabledBuffer.index.length > 0
		) {
			this.freeMemory = true;
			this.physics = Physics.createChunkPhysics(
				this.scene.world.physics,
				this,
				physicsCollisionEnabledBuffer,
				physicsCollisionDisabledBuffer,
			);
		}

		// visuals
		if (netState.isClient) {
			const { opaqueChunksBatchManager, alphaTestedChunksBatchManager, translucentChunksBatchManager } =
				scene.world.client;

			for (const { type, buffer, batchManager, material, layer } of [
				{
					type: "opaque",
					buffer: opaqueBuffer,
					batchManager: opaqueChunksBatchManager,
					material: scene.mat,
					layer: Layers.DEFAULT,
				},
				{
					type: "alphaTested",
					buffer: alphaTestedBuffer,
					batchManager: alphaTestedChunksBatchManager,
					material: scene.alphaTestedMaterial,
					chunkScene: scene,
				},
				{
					type: "translucent",
					buffer: translucentBuffer,
					batchManager: translucentChunksBatchManager,
					material: scene.translucentMaterial,
					layer: Layers.ORDER_INDEPENDENT_TRANSPARENCY,
				},
			]) {
				if (buffer.index.length <= 0) {
					continue;
				}

				const geom = ChunkBuilder.buildGeometry(buffer);

				// change position buffer to world space
				const posBuffer = geom.attributes.position.array;

				for (let i = 0; i < posBuffer.length; i += 3) {
					posBuffer[i] += posOffset.x;
					posBuffer[i + 1] += posOffset.y;
					posBuffer[i + 2] += posOffset.z;
				}

				// apply geometry to batch manager
				const batchPointer = batchManager.applyGeometry(
					scene.chunkSize,
					scene,
					geom,
					this,
					material,
					layer,
				);

				this.batchPointers[type] = batchPointer;
				this.geometries[type] = geom;

				if (!aoMainThread) {
					// calculate ao in a separate thread
					const index = geom.getIndex().array.buffer;
					const position = geom.getAttribute("position").array.buffer;
					const normal = geom.getAttribute("normal").array.buffer;

					const id = aoRequestID++;
					BB.client.thread?.ao.sendCommand(
						"chunk_ao",
						{
							id,
							chunkSize,
							index,
							position,
							normal,
							blockIndex: buffer.blockIndex,
						},
						[index, position, normal],
					);

					let res;
					const promise = new Promise((resolve) => (res = resolve));
					promise.resolve = res;
					promise.id = id;

					this.aoPromise[type] = promise;

					aoPromises.push(promise);

					promise.then((result) => {
						aoPromises.splice(
							aoPromises.findIndex((e) => e.id === id),
							1,
						);

						if (this.aoPromise[type] !== promise) return;

						if (result) {
							const ao_indices = result.index;
							const ao_vertices = result.ao;

							batchManager.setAttribute(batchPointer, "ao", ao_vertices);
							batchManager.setAttribute(batchPointer, "index", ao_indices);
						}

						this.aoPromise[type] = null;
					});
				}
			}
		}

		this.built = true;

		return true;
	}

	//dataAction is a CHUNK_DISPOSE_ constant that describes what to do with the chunk data
	//noDisposePacket is for internal use only to optimize the number of prioritizer packets in chunk.build()
	//returns true if the chunk was fully deleted from the scene
	dispose(dataAction) {
		const scene = this.scene;

		if (this.freeMemory) {
			if (netState.isClient) {
				const {
					opaqueChunksBatchManager,
					alphaTestedChunksBatchManager,
					translucentChunksBatchManager,
				} = scene.world.client;

				if (this.batchPointers.opaque !== null) {
					opaqueChunksBatchManager.clearGeometry(this.batchPointers.opaque);
					this.batchPointers.opaque = null;
				}

				if (this.batchPointers.alphaTested !== null) {
					alphaTestedChunksBatchManager.clearGeometry(this.batchPointers.alphaTested);
					this.batchPointers.alphaTested = null;
				}

				if (this.batchPointers.translucent !== null) {
					translucentChunksBatchManager.clearGeometry(this.batchPointers.translucent);
					this.batchPointers.translucent = null;
				}
			}

			Physics.disposeChunkPhysics(scene.world.physics, this.physics);

			this.freeMemory = false;
		}

		let deletedFromScene = false;

		if (
			dataAction === CHUNK_DISPOSE_BYEBYE ||
			(dataAction === CHUNK_DISPOSE_DEFAULT &&
				((netState.isHost && !this.modified) || (!netState.isHost && Multiplayer.isConnected())))
		) {
			scene.chunks.delete(this.position);

			deletedFromScene = true;
		}

		// deletes from aoPromises array
		this.aoPromise.opaque?.resolve();
		this.aoPromise.transparent?.resolve();

		if (this.built) {
			scene.rebuildBatch.delete(this);
		}

		this.built = false;
		this.buildAttempted = false;

		return deletedFromScene;
	}

	static aoMainThread(workerThread) {
		workerThread.setCommandListener("chunk_ao", function (a) {
			const [id, index, ao] = a;
			aoPromises
				.find((e) => e.id === id)
				?.resolve({
					index: new Uint32Array(index),
					ao: new Float32Array(ao),
				});
		});
	}

	static aoWorkerThread(mainThread) {
		mainThread.setCommandListener("chunk_ao", function (a) {
			const buffer = {
				index: new Uint32Array(a.index),
				blockIndex: a.blockIndex,
				position: new Float32Array(a.position),
				normal: new Float32Array(a.normal),
			};

			const s = a.chunkSize;
			calculateAO(buffer, s, s, s);

			const index = buffer.index.buffer;
			const ao = buffer.ao.buffer;
			mainThread.sendCommand("chunk_ao", [a.id, index, ao], [index, ao]);
		});
	}
}
