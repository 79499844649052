import { netState } from "router/Parallelogram";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} dt
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterHealthRegenUpdate(entity, dt) {
	if (!canUpdateSystem(entity)) return;

	entity.regen.state.timer -= dt;
	if (entity.regen.state.timer <= 0) entity.addHealth(entity.regen.def.rate * dt);
}

function canUpdateSystem(entity) {
	return entity.type.def.isCharacter && netState.isHost && !entity.isDead();
}
