import { NODE_THIS_BLOCK } from "../NodeSharedUtil";

export const validateInput = (input, inputType, ctx) => {
	// TODO: implement stronger type validations and error throwing

	// Transform NODE_THIS_BLOCK special value for blockGroup sockets.
	// If the entrypoint is a block group, get the entrypoint from the interpreter context.
	// Otherwise, set the input to undefined, so that scripts attached to block types
	// do not receive the NODE_THIS_BLOCK special value.
	if (inputType.type === "blockGroup" && input === NODE_THIS_BLOCK) {
		if (ctx.entryPoint.type === "group") {
			input = ctx.entryPoint.targetId;
		} else {
			input = undefined;
		}
	}

	// transform a primitive into an array
	if (inputType.structure === "list" && !Array.isArray(input)) {
		input = [input];
	}

	return input;
};

// TODO: Functions below are currently used *inside* of node implementations - this should be removed and all happen inside of the validation function a bove
/**
 * @returns {import("base/world/entity/Entity").Entity}
 */
export const getEntity = (inputs, _, inputName) => {
	return inputs[inputName];
};

/**
 * @returns {import("base/world/entity/Character").Character}
 */
export const getNPC = (inputs, ctx, inputName) => {
	const entity = getEntity(inputs, ctx, inputName);

	if (!entity) return;

	if (entity.type.def.isNPC) {
		return entity;
	}
};

/**
 * @returns {import("base/world/entity/Character").Character}
 */
export const getPlayer = (inputs, ctx, inputName) => {
	const entity = getEntity(inputs, ctx, inputName);

	if (!entity) return;

	if (entity.type.def.isPlayer) {
		return entity;
	}
};
