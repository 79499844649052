export class HealthComponent {
	constructor(o) {
		this.name = "health";

		this.def = {
			max: o?.health ?? 100,
			invincible: o?.invincible ?? true,
		};

		this.state = {
			current: this.def.max,
		};
	}
}
