import type { IBlockSoundsPack, IPackageBundle } from "../types";
import { LATEST_BUNDLE_VERSION } from "../validations/bundle";

export const BLOCKS_AUDIO_ASSETS = [
	{
		id: "snd-blocks-world-cobblestone1",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-cobblestone1.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-blocks-world-cobblestone2",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-cobblestone2.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-blocks-world-cobblestone3",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-cobblestone3.ogg",
		type: "audio/ogg",
	},
	{
		id: "snd-blocks-world-wood-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-break.ogg",
	},
	{
		id: "snd-blocks-world-wood-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-build.ogg",
	},
	{
		id: "snd-blocks-world-wood-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-step-02.ogg",
	},
	{
		id: "snd-blocks-world-wood-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-step-03.ogg",
	},
	{
		id: "snd-blocks-world-wood-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-step-01.ogg",
	},
	{
		id: "snd-blocks-world-dirt-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-break.ogg",
	},
	{
		id: "snd-blocks-world-dirt-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-build.ogg",
	},
	{
		id: "snd-blocks-world-grass-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-grass-step-01.ogg",
	},
	{
		id: "snd-blocks-world-grass-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-grass-step-03.ogg",
	},
	{
		id: "snd-blocks-world-grass-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-grass-step-02.ogg",
	},
	{
		id: "snd-blocks-world-Ice-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-Ice-break.ogg",
	},
	{
		id: "snd-blocks-world-Ice-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-Ice-build.ogg",
	},
	{
		id: "snd-blocks-world-Ice-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-Ice-step-01.ogg",
	},
	{
		id: "snd-blocks-world-Ice-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-Ice-step-02.ogg",
	},
	{
		id: "snd-blocks-world-Ice-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-Ice-step-03.ogg",
	},
	{
		id: "snd-blocks-world-gravel-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-gravel-break.ogg",
	},
	{
		id: "snd-blocks-world-gravel-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-gravel-build.ogg",
	},
	{
		id: "snd-blocks-world-gravel-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-gravel-step-02.ogg",
	},
	{
		id: "snd-blocks-world-gravel-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-gravel-step-03.ogg",
	},
	{
		id: "snd-blocks-world-gravel-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-gravel-step-01.ogg",
	},
	{
		id: "snd-blocks-world-sludge-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sludge-break.ogg",
	},
	{
		id: "snd-blocks-world-sludge-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sludge-build.ogg",
	},
	{
		id: "snd-blocks-world-sludge-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sludge-step-02.ogg",
	},
	{
		id: "snd-blocks-world-sludge-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sludge-step-03.ogg",
	},
	{
		id: "snd-blocks-world-sludge-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sludge-step-01.ogg",
	},
	{
		id: "snd-blocks-world-wood-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-break.ogg",
	},
	{
		id: "snd-blocks-world-wood-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wood-build.ogg",
	},
	{
		id: "snd-blocks-world-woodpolished-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-woodpolished-step-02.ogg",
	},
	{
		id: "snd-blocks-world-woodpolished-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-woodpolished-step-03.ogg",
	},
	{
		id: "snd-blocks-world-woodpolished-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-woodpolished-step-01.ogg",
	},
	{
		id: "snd-blocks-world-plastic-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-plastic-break.ogg",
	},
	{
		id: "snd-blocks-world-plastic-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-plastic-build.ogg",
	},
	{
		id: "snd-blocks-world-plastic-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-plastic-step-01.ogg",
	},
	{
		id: "snd-blocks-world-plastic-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-plastic-step-03.ogg",
	},
	{
		id: "snd-blocks-world-plastic-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-plastic-step-02.ogg",
	},
	{
		id: "snd-blocks-world-stone-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-break.ogg",
	},
	{
		id: "snd-blocks-world-stone-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-build.ogg",
	},
	{
		id: "snd-blocks-world-rocks-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-rocks-step-01.ogg",
	},
	{
		id: "snd-blocks-world-rocks-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-rocks-step-03.ogg",
	},
	{
		id: "snd-blocks-world-rocks-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-rocks-step-02.ogg",
	},
	{
		id: "snd-blocks-world-dirt-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-break.ogg",
	},
	{
		id: "snd-blocks-world-dirt-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-build.ogg",
	},
	{
		id: "snd-blocks-world-dirt-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-step-01.ogg",
	},
	{
		id: "snd-blocks-world-dirt-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-step-02.ogg",
	},
	{
		id: "snd-blocks-world-dirt-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-dirt-step-03.ogg",
	},
	{
		id: "snd-blocks-world-sand-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sand-break.ogg",
	},
	{
		id: "snd-blocks-world-sand-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sand-build.ogg",
	},
	{
		id: "snd-blocks-world-sand-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sand-step-02.ogg",
	},
	{
		id: "snd-blocks-world-sand-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sand-step-03.ogg",
	},
	{
		id: "snd-blocks-world-sand-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-sand-step-01.ogg",
	},
	{
		id: "snd-blocks-world-metal-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-metal-break.ogg",
	},
	{
		id: "snd-blocks-world-metal-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-metal-build.ogg",
	},
	{
		id: "snd-blocks-world-metal-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-metal-step-01.ogg",
	},
	{
		id: "snd-blocks-world-metal-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-metal-step-02.ogg",
	},
	{
		id: "snd-blocks-world-metal-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-metal-step-03.ogg",
	},
	{
		id: "snd-blocks-world-stone-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-break.ogg",
	},
	{
		id: "snd-blocks-world-stone-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-build.ogg",
	},
	{
		id: "snd-blocks-world-stone-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-step-02.ogg",
	},
	{
		id: "snd-blocks-world-stone-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-step-03.ogg",
	},
	{
		id: "snd-blocks-world-stone-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-stone-step-01.ogg",
	},
	{
		id: "snd-blocks-world-leaves-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-leaves-break.ogg",
	},
	{
		id: "snd-blocks-world-leaves-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-leaves-build.ogg",
	},
	{
		id: "snd-blocks-world-leaves-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-leaves-step-01.ogg",
	},
	{
		id: "snd-blocks-world-leaves-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-leaves-step-03.ogg",
	},
	{
		id: "snd-blocks-world-leaves-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-leaves-step-02.ogg",
	},
	{
		id: "snd-blocks-world-glass-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-glass-break.ogg",
	},
	{
		id: "snd-blocks-world-glass-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-glass-build.ogg",
	},
	{
		id: "snd-blocks-world-glass-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-glass-step-01.ogg",
	},
	{
		id: "snd-blocks-world-glass-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-glass-step-02.ogg",
	},
	{
		id: "snd-blocks-world-glass-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-glass-step-03.ogg",
	},
	{
		id: "snd-blocks-world-wool-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wool-break.ogg",
	},
	{
		id: "snd-blocks-world-wool-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wool-build.ogg",
	},
	{
		id: "snd-blocks-world-wool-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wool-step-03.ogg",
	},
	{
		id: "snd-blocks-world-wool-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wool-step-02.ogg",
	},
	{
		id: "snd-blocks-world-wool-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-wool-step-01.ogg",
	},
	{
		id: "snd-blocks-world-tile-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-tile-break.ogg",
	},
	{
		id: "snd-blocks-world-tile-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-tile-build.ogg",
	},
	{
		id: "snd-blocks-world-tiles-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-tiles-step-02.ogg",
	},
	{
		id: "snd-blocks-world-tiles-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-tiles-step-03.ogg",
	},
	{
		id: "snd-blocks-world-tiles-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-tiles-step-01.ogg",
	},
	{
		id: "snd-blocks-world-snow-break",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-snow-break.ogg",
	},
	{
		id: "snd-blocks-world-snow-build",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-snow-build.ogg",
	},
	{
		id: "snd-blocks-world-snow-step-01",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-snow-step-01.ogg",
	},
	{
		id: "snd-blocks-world-snow-step-03",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-snow-step-03.ogg",
	},
	{
		id: "snd-blocks-world-snow-step-02",
		type: "audio/ogg",
		url: "assets/engine/block-sounds-packs/snd-blocks-world-snow-step-02.ogg",
	},
] as const;

export const DEFAULT_BLOCK_SOUNDS = {
	footstep: {
		asset: "snd-default",
		start: 1.200408,
		end: 1.555918,
		volume: 0.3,
	},
	build: { asset: "snd-default", start: 0.701315, end: 0.939592 },
	break: { asset: "snd-default", start: 0.939592, end: 1.200408 },
} as const;

type AudioAsset = (typeof BLOCKS_AUDIO_ASSETS)[number]["id"];

type Pack = Omit<Pick<IBlockSoundsPack, "name" | "id">, "onBreak" | "onBuild" | "onFootstep"> & {
	onBreak?: AudioAsset[];
	onBuild?: AudioAsset[];
	onFootstep?: AudioAsset[];
};

export const DEFAULT_BLOCK_SOUNDS_PACK_ID = "74AD798E054A420AA6B27DB9F5905A0C";

const packs: Pack[] = [
	{
		id: DEFAULT_BLOCK_SOUNDS_PACK_ID,
		name: "Default",
		onBreak: ["snd-blocks-world-plastic-break"],
		onBuild: ["snd-blocks-world-plastic-build"],
		onFootstep: [
			"snd-blocks-world-plastic-step-01",
			"snd-blocks-world-plastic-step-02",
			"snd-blocks-world-plastic-step-03",
		],
	},
	{
		id: "00921E4889134AF9808C953EF83B5551",
		name: "Dirt",
		onBreak: ["snd-blocks-world-dirt-break"],
		onBuild: ["snd-blocks-world-dirt-build"],
		onFootstep: [
			"snd-blocks-world-dirt-step-01",
			"snd-blocks-world-dirt-step-02",
			"snd-blocks-world-dirt-step-03",
		],
	},
	{
		id: "08EAE0BDB7134A0C81759687B4C910D5",
		name: "Glass",
		onBreak: ["snd-blocks-world-glass-break"],
		onBuild: ["snd-blocks-world-glass-build"],
		onFootstep: [
			"snd-blocks-world-glass-step-01",
			"snd-blocks-world-glass-step-02",
			"snd-blocks-world-glass-step-03",
		],
	},
	{
		id: "49DE8397CE4F4A2D8DEDA0C9565E579C",
		name: "Grass",
		onBreak: ["snd-blocks-world-dirt-break"],
		onBuild: ["snd-blocks-world-dirt-build"],
		onFootstep: [
			"snd-blocks-world-grass-step-01",
			"snd-blocks-world-grass-step-02",
			"snd-blocks-world-grass-step-03",
		],
	},
	{
		id: "7848D559692741F58C9BE75AEB35C67E",
		name: "Gravel",
		onBreak: ["snd-blocks-world-gravel-break"],
		onBuild: ["snd-blocks-world-gravel-build"],
		onFootstep: [
			"snd-blocks-world-gravel-step-01",
			"snd-blocks-world-gravel-step-02",
			"snd-blocks-world-gravel-step-03",
		],
	},
	{
		id: "CA4CE420C58F4344964697BF3C0F1FB3",
		name: "Ice",
		onBreak: ["snd-blocks-world-Ice-break"],
		onBuild: ["snd-blocks-world-Ice-build"],
		onFootstep: [
			"snd-blocks-world-Ice-step-01",
			"snd-blocks-world-Ice-step-02",
			"snd-blocks-world-Ice-step-03",
		],
	},
	{
		id: "2F45FCBB443446E296B651BB3EEB98E6",
		name: "Leaves",
		onBreak: ["snd-blocks-world-leaves-break"],
		onBuild: ["snd-blocks-world-leaves-build"],
		onFootstep: [
			"snd-blocks-world-leaves-step-01",
			"snd-blocks-world-leaves-step-02",
			"snd-blocks-world-leaves-step-03",
		],
	},
	{
		id: "22337DB1A49C4C07B8BEBC075880663C",
		name: "Metal",
		onBreak: ["snd-blocks-world-metal-break"],
		onBuild: ["snd-blocks-world-metal-build"],
		onFootstep: [
			"snd-blocks-world-metal-step-01",
			"snd-blocks-world-metal-step-02",
			"snd-blocks-world-metal-step-03",
		],
	},
	{
		id: "40C05C424AEF43119E52DB871F1E3CA4",
		name: "Plastic",
		onBreak: ["snd-blocks-world-plastic-break"],
		onBuild: ["snd-blocks-world-plastic-build"],
		onFootstep: [
			"snd-blocks-world-plastic-step-01",
			"snd-blocks-world-plastic-step-02",
			"snd-blocks-world-plastic-step-03",
		],
	},
	{
		id: "626EF58323BF40C19C36B1DADC5B16B2",
		name: "Polished Wood",
		onBreak: ["snd-blocks-world-wood-break"],
		onBuild: ["snd-blocks-world-wood-build"],
		onFootstep: [
			"snd-blocks-world-woodpolished-step-01",
			"snd-blocks-world-woodpolished-step-02",
			"snd-blocks-world-woodpolished-step-03",
		],
	},
	{
		id: "E6C215D7F63C4B8683B3372CD82031E9",
		name: "Rocks",
		onBreak: ["snd-blocks-world-stone-break"],
		onBuild: ["snd-blocks-world-stone-build"],
		onFootstep: [
			"snd-blocks-world-rocks-step-01",
			"snd-blocks-world-rocks-step-02",
			"snd-blocks-world-rocks-step-03",
		],
	},
	{
		id: "073FD07B2B5145FEB11EE050F49743F7",
		name: "Sand",
		onBreak: ["snd-blocks-world-sand-break"],
		onBuild: ["snd-blocks-world-sand-build"],
		onFootstep: [
			"snd-blocks-world-sand-step-01",
			"snd-blocks-world-sand-step-02",
			"snd-blocks-world-sand-step-03",
		],
	},
	{
		id: "95A6CB93890F447CB541726A2A1F1BB0",
		name: "Sludge",
		onBreak: ["snd-blocks-world-sludge-break"],
		onBuild: ["snd-blocks-world-sludge-build"],
		onFootstep: [
			"snd-blocks-world-sludge-step-01",
			"snd-blocks-world-sludge-step-02",
			"snd-blocks-world-sludge-step-03",
		],
	},
	{
		id: "0DD823763B5A47539EF5C5F3E33CF527",
		name: "Snow",
		onBreak: ["snd-blocks-world-snow-break"],
		onBuild: ["snd-blocks-world-snow-build"],
		onFootstep: [
			"snd-blocks-world-snow-step-01",
			"snd-blocks-world-snow-step-02",
			"snd-blocks-world-snow-step-03",
		],
	},
	{
		id: "86035EA534E44318A7AB2AA3215325BB",
		name: "Stone",
		onBreak: ["snd-blocks-world-stone-break"],
		onBuild: ["snd-blocks-world-stone-build"],
		onFootstep: [
			"snd-blocks-world-stone-step-01",
			"snd-blocks-world-stone-step-02",
			"snd-blocks-world-stone-step-03",
		],
	},
	{
		id: "EE8B6FDF8FAD4814A7E699FEA94BF62A",
		name: "Tiles",
		onBreak: ["snd-blocks-world-tile-break"],
		onBuild: ["snd-blocks-world-tile-build"],
		onFootstep: [
			"snd-blocks-world-tiles-step-01",
			"snd-blocks-world-tiles-step-02",
			"snd-blocks-world-tiles-step-03",
		],
	},
	{
		id: "3C513DD31EF341B6B5E53DF5330B2DD5",
		name: "Wood",
		onBreak: ["snd-blocks-world-wood-break"],
		onBuild: ["snd-blocks-world-wood-build"],
		onFootstep: [
			"snd-blocks-world-wood-step-01",
			"snd-blocks-world-wood-step-02",
			"snd-blocks-world-wood-step-03",
		],
	},
	{
		id: "157437B212D643478B982C77C81473BC",
		name: "Wool",
		onBreak: ["snd-blocks-world-wool-break"],
		onBuild: ["snd-blocks-world-wool-build"],
		onFootstep: [
			"snd-blocks-world-wool-step-01",
			"snd-blocks-world-wool-step-02",
			"snd-blocks-world-wool-step-03",
		],
	},
	{
		id: "8D9BBEDEE3ED4267B7DC649DAFEB424C",
		name: "Cobblestone",
		onBreak: ["snd-blocks-world-stone-break"],
		onBuild: ["snd-blocks-world-stone-build"],
		onFootstep: [
			"snd-blocks-world-cobblestone1",
			"snd-blocks-world-cobblestone2",
			"snd-blocks-world-cobblestone3",
		],
	},
];

export const BLOCK_SOUNDS_PACKS_PACKAGE = {
	version: LATEST_BUNDLE_VERSION,
	type: "package",
	id: "BLOCK_SOUNDS_PACKS",
	assets: {
		blockSoundsPacks: packs.reduce(
			(acc, pack) => {
				const pk = `blockSoundsPack#${pack.id}`;
				acc[pk] = {
					pk,
					type: "blockSoundsPack",
					...pack,
				};

				return acc;
			},
			{} as Record<string, IBlockSoundsPack>,
		),
	},
} satisfies IPackageBundle;
