import type { PropMotionType } from "@jamango/content-client";
import { generateUUID } from "@jamango/content-client";
import { createLogger, deepEqual } from "@jamango/helpers";
import { VY } from "base/util/math/Math";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import { WorldEditorStatus } from "base/world/WorldEditor";
import type { Character } from "base/world/entity/Character";
import type { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import type { Prop } from "base/world/entity/Prop";
import { mapPropMotionType } from "base/world/entity/component/PropPhysics";
import { UI } from "client/dom/UI";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { Quaternion, type Vector3Tuple, type Vector4Tuple } from "three";

const logger = createLogger("SceneTree");

const _quaternion = new Quaternion();

export enum SceneNodeType {
	PROP = 0,
	CHARACTER = 1,
}

export type CharacterSceneNode = {
	id: string;
	name: string | null;
	type: SceneNodeType.CHARACTER;
	characterPk: string;
	position: Vector3Tuple;
	angle: number;
	scripts: Array<{
		script: string;
		data?: Record<string, any>;
	}>;
};

export type PropSceneNode = {
	id: string;
	name: string | null;
	type: SceneNodeType.PROP;
	propPk: string;
	position: Vector3Tuple;
	quaternion: Vector4Tuple;
	scale: number;
	motionType: PropMotionType;
	scripts: Array<{
		script: string;
		data?: Record<string, any>;
	}>;
};

export type SceneNode = CharacterSceneNode | PropSceneNode;

export type SceneTreeState = {
	/* networked */
	nodes: { [id: string]: SceneNode };

	/* host state */
	// on clients positions and rotations currently aren't treated as "reliable data" and don't cause a dirty flag.
	// they are considered "eventually correct" and will be updated from the node entity positions.
	// only the host needs correct positions and rotations for saving purposes.
	spawnedNodes: Set<string>;
	dirty: Set<string>;
	needsRecreate: Set<string>;
};

type Patch = {
	nodes: { [id: string]: SceneNode | null };
};

export const init = (): SceneTreeState => {
	return {
		nodes: {},
		spawnedNodes: new Set(),
		dirty: new Set(),
		needsRecreate: new Set(),
	};
};

const createEntity = (world: World, node: SceneNode, prophecy: boolean): PhysicalEntity | undefined => {
	if (node.type === SceneNodeType.PROP) {
		const prop = world.content.state.props.get(node.propPk);

		if (!prop) {
			logger.error(`Prop not found: ${node.propPk}`);
			return undefined;
		}

		const entity = world.router.createEntity({
			def: "Prop",
			sceneTreeNode: node.id,
			x: node.position[0],
			y: node.position[1],
			z: node.position[2],
			qx: node.quaternion[0],
			qy: node.quaternion[1],
			qz: node.quaternion[2],
			qw: node.quaternion[3],
		}) as Prop;

		entity.setScale(node.scale);

		entity.propMesh.updateMesh(prop.mesh);

		entity.propPhysics.updateCollider(prop.physics.collider);

		entity.propPhysics.def.motionType = prophecy
			? Physics.MotionType.STATIC
			: mapPropMotionType(node.motionType);
		entity.propPhysics.def.mass = prop.physics.mass;
		entity.propPhysics.def.friction = prop.physics.friction;
		entity.propPhysics.def.restitution = prop.physics.restitution;

		return entity;
	} else if (node.type === SceneNodeType.CHARACTER) {
		const characterDef = Array.from(world.defs.values()).find((def) => def.pk === node.characterPk);
		if (!characterDef) {
			logger.error(`Character definition not found: ${node.characterPk}`);
			return undefined;
		}

		_quaternion.setFromAxisAngle(VY, node.angle);

		const entity = world.router.createEntity({
			def: characterDef.name,
			sceneTreeNode: node.id,
			x: node.position[0],
			y: node.position[1],
			z: node.position[2],
			qx: _quaternion.x,
			qy: _quaternion.y,
			qz: _quaternion.z,
			qw: _quaternion.w,
		}) as Character;

		return entity;
	}

	return undefined;
};

const patchCommand = Net.command("scene tree patch", Net.CLIENT, (patch: Patch, world) => {
	for (const nodeId in patch.nodes) {
		const nodePatch = patch.nodes[nodeId];

		if (nodePatch === null) {
			removeNode(world.sceneTree, nodeId);
		} else {
			world.sceneTree.nodes[nodeId] = nodePatch;
		}

		world.sceneTree.dirty.add(nodeId);
	}
});

export const update = (state: SceneTreeState, world: World) => {
	for (const entity of world.entities) {
		if (entity.sceneTree?.state.sceneTreeNode === undefined) continue;

		const node = state.nodes[entity.sceneTree.state.sceneTreeNode];

		if (node) {
			// update node transform from entity
			if (node.type === SceneNodeType.PROP) {
				const prop = entity as Prop;
				node.position[0] = prop.position.x;
				node.position[1] = prop.position.y;
				node.position[2] = prop.position.z;
				node.quaternion[0] = prop.quaternion.x;
				node.quaternion[1] = prop.quaternion.y;
				node.quaternion[2] = prop.quaternion.z;
				node.quaternion[3] = prop.quaternion.w;
			} else if (node.type === SceneNodeType.CHARACTER) {
				const character = entity as Character;
				node.position[0] = character.position.x;
				node.position[1] = character.position.y;
				node.position[2] = character.position.z;
				node.angle = character.input.camera.theta;
			}
		} else if (netState.isHost) {
			// on the host, dispose of entities that have had their scene tree node removed
			entity.dispose();
		}
	}

	if (netState.isHost) {
		// create / recreate entities on the host
		for (const node of Object.values(state.nodes)) {
			// NOTE: later we may derive a "needsEntity" bool for this loop
			// e.g. we may later oly spawn entities if chunks are loaded, rather than spawning entities
			// which forces chunks to be invoked.
			let entity = world.sceneTreeNodeToEntity.get(node.id);
			const spawned = state.spawnedNodes.has(node.id);
			const recreate = state.needsRecreate.has(node.id);

			// if we are in some "sandbox" or "play" mode, the entity has been spawned, but now no longer exists,
			// then we should remove the scene tree node
			if (
				!entity &&
				world.editor.status !== WorldEditorStatus.EDITING &&
				world.editor.status !== WorldEditorStatus.TESTING_ACTIVE &&
				world.editor.status !== WorldEditorStatus.TESTING_PAUSED &&
				spawned
			) {
				removeNode(state, node.id);
				continue;
			}

			// dispose entity if it needs recreating
			if (entity && recreate) {
				entity.dispose();
				entity = undefined;
			}

			// create entity
			if (!entity && (!spawned || recreate)) {
				entity = createEntity(world, node, false);

				if (entity) {
					state.spawnedNodes.add(node.id);
				}
			}
		}

		// patch clients
		if (state.dirty.size > 0) {
			const patch: Patch = {
				nodes: {},
			};

			for (const nodeId of state.dirty) {
				const node = state.nodes[nodeId];
				if (node) {
					patch.nodes[nodeId] = node;
				} else {
					patch.nodes[nodeId] = null;
				}
			}

			patchCommand.sendToAll(structuredClone(patch));
		}
	}

	if (netState.isClient && state.dirty.size > 0) {
		// update ui
		UI.state.editor().refreshSceneTreeNodes();
	}

	// reset dirty sets
	state.dirty.clear();
	state.needsRecreate.clear();
};

export const createNode = (
	state: SceneTreeState,
	world: World,
	inNode: Omit<PropSceneNode, "id"> | Omit<CharacterSceneNode, "id">,
) => {
	const id = generateUUID();

	const node = {
		...inNode,
		id,
	};

	state.nodes[node.id] = node as SceneNode;
	state.dirty.add(node.id);

	world.rete.needsReload = true;

	return node;
};

export const updateNode = (state: SceneTreeState, world: World, updatedNode: SceneNode) => {
	const node = state.nodes[updatedNode.id];

	if (!node) {
		logger.error(`Node not found: ${updatedNode.id}`);
		return;
	}

	if (!deepEqual(node.scripts, updatedNode.scripts)) {
		world.rete.needsReload = true;
	}

	state.nodes[updatedNode.id] = updatedNode;
	state.dirty.add(node.id);
	state.needsRecreate.add(node.id);
};

export const removeNode = (state: SceneTreeState, nodeId: string) => {
	delete state.nodes[nodeId];
	state.dirty.add(nodeId);
	state.needsRecreate.delete(nodeId);
	state.spawnedNodes.delete(nodeId);
};

type SceneTreeSave = {
	nodes: SceneTreeState["nodes"];
};

export const save = (state: SceneTreeState): SceneTreeSave => {
	return structuredClone({ nodes: state.nodes });
};

export const load = (state: SceneTreeState, save: SceneTreeSave) => {
	state.nodes = structuredClone(save.nodes);
	state.spawnedNodes.clear();
	state.dirty.clear();
	state.needsRecreate.clear();
};
