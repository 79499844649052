import type { Character } from "base/world/entity/Character";
import type { Entity } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";
import type { Prop } from "base/world/entity/Prop";
import { ParticleSystemEntity } from "base/world/entity/ParticleSystem";

export class TypeComponent {
	name = "type";

	def: {
		isPlayer: boolean;
		isNPC: boolean;
		isItem: boolean;
		isProp: boolean;
		isCharacter: boolean;
		isParticleSystem: boolean;
	};

	constructor(o: { name: string; isNPC?: boolean }) {
		const isPlayer = o.name.startsWith("Player") && !o.isNPC;
		const isNPC = o.name.startsWith("NPC") || !!o.isNPC;
		const isItem = o.name.startsWith("Item") || o.name.startsWith("item#");
		const isProp = o.name.startsWith("Prop");
		const isCharacter = isPlayer || isNPC;
		const isParticleSystem = o.name.startsWith("ParticleSystem");

		this.def = {
			// remove o.isNPC check after modding system refactor
			isPlayer,
			isNPC,
			isItem,
			isProp,
			isCharacter,
			isParticleSystem,
		};
	}
}

export const entityIsNPC = (entity: Entity): entity is Character => entity.type.def.isNPC;
export const entityIsPlayer = (entity: Entity): entity is Character => entity.type.def.isPlayer;
export const entityIsCharacter = (entity: Entity): entity is Character => entity.type.def.isCharacter;
export const entityIsProp = (entity: Entity): entity is Prop => entity.type.def.isProp;
export const entityIsItem = (entity: Entity): entity is Item => entity.type.def.isItem;
export const entityIsParticleSystem = (entity: Entity): entity is ParticleSystemEntity => entity.type.def.isParticleSystem;
