import type { SelectOptionsContext } from "@jamango/content-client";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import type { World } from "base/world/World";
import type { Prop } from "base/world/entity/Prop";

export const getItemSelectOptions = (world: World) => {
	const defs = Array.from(world.defs.values())
		.filter((def) => def.name.startsWith("Item"))
		.filter((def) => !def.name.startsWith("ItemBuildTool"));
	const itemAssets = world.content.state.items.getAll();

	const options: { label: string; value: string }[] = [];

	for (const def of defs) {
		options.push({ label: def.name, value: def.name });
	}

	for (const itemAsset of itemAssets) {
		options.push({ label: itemAsset.name, value: itemAsset.pk });
	}

	return options;
};

export const ITEM_NODES = [
	node({
		id: "55d1f350-dd42-4532-94fd-a615332570bf",
		name: "Item",
		type: NODE_TYPE_ID.util.literal,
		description: "Returns an item type constant",
		controls: {
			item: {
				name: "Item",
				type: "string",
				control: "select",
				config: {
					defaultValue: "item",
					autoSortOptions: (ctx: SelectOptionsContext) => getItemSelectOptions(ctx.world),
				},
			},
		},
		outputs: {
			item: { name: "Item", type: "string" },
		},
		resolve(inputs) {
			return { item: inputs.item };
		},
	}),
	node({
		id: "032bfa9f-debf-4f87-880b-1055c5643cee",
		name: "Spawn Item",
		type: NODE_TYPE_ID.function.entity,
		description: "Creates an invisible prop and adds it to the scene.",
		inputs: {
			exec: { type: "exec" },
			item: {
				name: "Item Type",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: SelectOptionsContext) => getItemSelectOptions(ctx.world),
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Item", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const entity = world.router.createEntity({
				def: inputs.itemType,
				x: inputs.position.x,
				y: inputs.position.y,
				z: inputs.position.z,
				qx: 0,
				qy: 0,
				qz: 0,
				qw: 1,
			}) as Prop;

			// store the most recent prop entity id in the node state, this is what is returned upon resolve
			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeId, scope) {
			return { entity: ctx.world.getEntity(scope[nodeId])! };
		},
	}),
];
