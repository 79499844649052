import { BLOCK_AIR } from "base/world/block/Util.js";
import { computePath } from "base/world/pathfinding/Search.js";
import { Vector3 } from "three";

export class Pathfinding {
	constructor(world) {
		this.world = world;
	}

	/**
	 * @param {{
	 *   start: THREE.Vector3Like,
	 *   goal: THREE.Vector3Like,
	 *   searchType: 'greedy' | 'shortest',
	 * }} props
	 * @returns {{
	 *   success: boolean;
	 *   path?: THREE.Vector3[];
	 *   intermediates?: {
	 *     explored: THREE.Vector3[];
	 *     iterations: number;
	 *   };
	 * }}
	 */
	findPath({ start, goal, searchType }) {
		const scene = this.world.scene;

		const searchWorld = {
			solid: ({ x, y, z }) => {
				const shape = scene.getShape(x, y, z);

				return shape && shape !== BLOCK_AIR;
			},
		};

		const earlyExitSearchIterations = 1000;
		const keepIntermediates = true;

		const result = computePath({
			world: searchWorld,
			start,
			goal,
			smooth: true,
			earlyExit: { searchIterations: earlyExitSearchIterations },
			keepIntermediates,
			searchType,
		});

		if (!result.success) {
			return { success: false };
		}

		/**
		 * @type {THREE.Vector3[]}
		 */
		const path = [];

		for (const node of result.path) {
			path.push(new Vector3(node.position.x, node.position.y, node.position.z));
		}

		/**
		 * @type {THREE.Vector3[]}
		 */
		const explored = [];
		for (const node of result.intermediates?.explored.values() ?? []) {
			explored.push(new Vector3(node.position.x, node.position.y, node.position.z));
		}

		const iterations = result.intermediates?.iterations ?? 0;

		return { success: true, path, intermediates: { explored, iterations } };
	}
}
