import { netState } from "router/Parallelogram";
import { PIH, RADDEG } from "base/util/math/Math.ts";
import {
	LOCOMOTION_MOVEMENTINPUT_IDLE,
	LOCOMOTION_MOVEMENTINPUT_LEFT,
	LOCOMOTION_MOVEMENTINPUT_RIGHT,
	LOCOMOTION_MOVEMENTINPUT_DOWN,
	LOCOMOTION_MOVEMENTINPUT_UP,
	LOCOMOTION_MOVEMENTINPUT_FORWARD,
	LOCOMOTION_MOVEMENTINPUT_BACKWARD,
} from "base/world/entity/component/CharacterLocomotionInput";
import { Vector3 } from "three";
import { angleDistance } from "base/util/math/Math.ts";

const _verticalVelocity = new Vector3();

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} dt
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterLocomotionInputStateUpdate(entity, dt, input) {
	if (!canUpdateSystem(entity)) return;

	const state = entity.locomotionInput.state;

	//fill out "redundant over wire" input states
	state.isDead = entity.isDead();
	state.isSprinting = entity.movement.state.isSprinting;
	state.isCrouching = entity.movement.state.isCrouching;
	state.isMoving = !entity.movement.state.isIdle; // TODO @evgeny.muralev hack for now as we don't have acceleration
	state.isFlying = entity.movement.state.isFlying;
	state.isAiming = entity.movement.state.isIronSights;
	state.isReloading = entity.getEquippedItem()?.weapon.state.isReloading ?? false;
	state.isSwappingTool = entity.movement.state.isSwappingTool;

	const item = entity.getEquippedItem();
	state.item = item?.animations.def.animations;

	let firstPerson = false;
	if (netState.isClient) {
		const cam = entity.world.client.camera;
		firstPerson = cam.is1stPerson() && cam.target === entity;
	}

	state.lastFrameFirstPerson = state.isFirstPerson;
	state.isFirstPerson = firstPerson;

	//hack because the locomotion output states don't check if an animation exists before trying to play it, causing crash
	if (item?.itemType.def.isBlock) {
		state.item = undefined;
	}

	state.isFalling = false;
	state.isJumping = false;

	// setup movement state of the character
	if (!entity.mount.state.parent) {
		if (entity.movement.state.isFlying) {
			const vertical = entity.movement.state.inputDisplacement.y;
			if (vertical > 0) state.movementInput = LOCOMOTION_MOVEMENTINPUT_UP;
			else if (vertical < 0) state.movementInput = LOCOMOTION_MOVEMENTINPUT_DOWN;
		} else {
			const verticalVelocity = _verticalVelocity.set(0, entity.movement.state.velocity.y, 0);

			const vertical = verticalVelocity.dot(entity.world.physics.gravity);

			state.isFalling = vertical !== 0;
			state.isJumping = vertical < 0;
		}
	}

	if (input !== undefined) {
		// mesh rotation towards camera goal by moving the feet
		// we do this if difference to real rotation is too big, or if we're iron sighted / firing
		const diff = angleDistance(state.meshRotation, input.camera.theta);
		const isUsingWeapon = input.isPullingTrigger || input.isIronSights;
		if (Math.abs(diff) > 0.7 || isUsingWeapon || entity.prophecy.state.isProphecy) {
			state.rotateToCamera = true;
		}

		if (!entity.isDead() && entity.movement.state.horizontalSpeed > 0) {
			// if we are moving, automatically set our rotation
			state.meshRotation = input.camera.theta;
			state.rotateToCamera = false;
		} else if (state.rotateToCamera) {
			// dont overshoot in the correction - if our FPS are too low, this could end in an endless ping pong, therefore we limit it
			state.meshRotation -= Math.sign(diff) * Math.min(Math.abs(diff), dt * 7);
			// check if this ended the rotation - need to recalc diff here
			if (Math.abs(angleDistance(state.meshRotation, input.camera.theta)) < 0.01) {
				state.rotateToCamera = false;
				state.meshRotation = input.camera.theta;
			}
		}

		state.cameraRotation = input.camera.theta;
		state.rotation = input.camera.phi - PIH;
		state.rotationTheta = input.camera.theta;
	} else {
		state.cameraRotation = state.meshRotation;
	}

	state.speed = entity.movement.state.horizontalSpeed;
	if (state.speed > 0) {
		state.omnidirectionalAngle = entity.movement.state.omnidirectionalAngle;
		const prettyAngle = state.omnidirectionalAngle * RADDEG;

		if (prettyAngle >= 20 && prettyAngle <= 160) {
			state.movementInput = LOCOMOTION_MOVEMENTINPUT_FORWARD;
		} else if (prettyAngle >= -20 && prettyAngle <= 20) {
			state.movementInput = LOCOMOTION_MOVEMENTINPUT_RIGHT;
		} else if (
			(prettyAngle >= -180 && prettyAngle <= -160) ||
			(prettyAngle >= 160 && prettyAngle <= 180)
		) {
			state.movementInput = LOCOMOTION_MOVEMENTINPUT_LEFT;
		} else {
			state.movementInput = LOCOMOTION_MOVEMENTINPUT_BACKWARD;
		}
	} else {
		if (!entity.movement.state.isFlying || entity.movement.state.velocityRequest.y === 0) {
			state.omnidirectionalAngle = 0;
			state.movementInput = LOCOMOTION_MOVEMENTINPUT_IDLE;
		}
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && !entity.canSkipUpdate && entity.type.def.isCharacter;
}
