import { createLogger } from "@jamango/helpers";
import { JoltModule } from "base/world/Physics";
import {
	Vector2,
	Vector3,
	Matrix4,
	BufferGeometry,
	BufferAttribute,
	InterleavedBuffer,
	InterleavedBufferAttribute,
	Mesh,
	LineSegments,
	MeshPhongMaterial,
	LineBasicMaterial,
	DoubleSide,
	FrontSide,
	BackSide,
} from "three";
import { CSS2DObject, CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";

const THREE = {
	FrontSide,
	BackSide,
	DoubleSide,
	Vector3,
	Matrix4,
	BufferGeometry,
	InterleavedBuffer,
	InterleavedBufferAttribute,
	BufferAttribute,
	Vector2,
	Mesh,
	LineSegments,
	MeshPhongMaterial,
	LineBasicMaterial,
};

const logger = createLogger("JoltDebugRenderer");

// source: https://github.com/jrouwe/JoltPhysics.js/blob/027229f51dfbc0845644e3ecfcefca4743652379/Examples/js/debug-renderer.js#L4

const wrapVec3 = (v) => new THREE.Vector3(v.GetX(), v.GetY(), v.GetZ());
const unwrapV3 = (ptr) => wrapVec3(JoltModule.Jolt.wrapPointer(ptr, JoltModule.Jolt.RVec3));

const textDecoder = new TextDecoder();

export class JoltDebugRenderer {
	materialCache = {};
	lineCache = {};
	lineMesh = {};
	triangleCache = {};
	triangleMesh = {};
	meshList = [];
	geometryList = [];
	geometryCache = [];
	textCache = [];
	textList = [];

	debugRenderer;
	css2dRenderer;

	constructor(renderer, scene, camera) {
		this.renderer = renderer;
		this.scene = scene;
		this.camera = camera;

		const debugRenderer = (this.debugRenderer = new JoltModule.Jolt.DebugRendererJS());
		debugRenderer.DrawLine = this.drawLine.bind(this);
		debugRenderer.DrawTriangle = this.drawTriangle.bind(this);
		debugRenderer.DrawText3D = this.drawText3D.bind(this);
		debugRenderer.DrawGeometryWithID = this.drawGeometryWithID.bind(this);
		debugRenderer.CreateTriangleBatchID = this.createTriangleBatchID.bind(this);
		debugRenderer.CreateTriangleBatchIDWithIndex = this.createTriangleBatchIDWithIndex.bind(this);

		this.bodyDrawSettings = new JoltModule.Jolt.BodyManagerDrawSettings();
		this.bodyDrawSettings.mDrawShapeColor = JoltModule.Jolt.EShapeColor_InstanceColor;

		// todo: expose more settings
		// this.bodyDrawSettings.mDrawVelocity = true;
		// this.bodyDrawSettings.mDrawWorldTransform = true;
		// this.bodyDrawSettings.mDrawBoundingBox = true;
		// this.bodyDrawSettings.mDrawSleepStats = true;
		// this.bodyDrawSettings.mDrawMassAndInertia = true;
	}

	initialized = false;

	initialize() {
		if (!this.initialized) {
			this.debugRenderer.Initialize();
			this.initialized = true;
		}
	}

	// Draws all bodies, assuming DrawSettings has mDrawShape enabled
	drawBodies(system) {
		this.debugRenderer.DrawBodies(system, this.bodyDrawSettings);
	}

	// Draws constraint relationships as lines. Some constraints include additional Text Data
	drawConstraints(system) {
		this.debugRenderer.DrawConstraints(system);
	}

	// Draws text indicating limits on constraints, such as the distance of a distance constraint
	drawConstraintLimits(system) {
		this.debugRenderer.DrawConstraintLimits(system);
	}

	drawLine(inFrom, inTo, inColor) {
		const colorU32 = JoltModule.Jolt.wrapPointer(inColor, JoltModule.Jolt.Color).mU32 >>> 0;
		const arr = (this.lineCache[colorU32] = this.lineCache[colorU32] || []);
		const v0 = unwrapV3(inFrom);
		const v1 = unwrapV3(inTo);
		arr.push(v0, v1);
	}

	drawTriangle(inV1, inV2, inV3, inColor) {
		const colorU32 = JoltModule.Jolt.wrapPointer(inColor, JoltModule.Jolt.Color).mU32 >>> 0;
		const arr = (this.lineCache[colorU32] = this.lineCache[colorU32] || []);
		const v0 = unwrapV3(inV1);
		const v1 = unwrapV3(inV2);
		const v2 = unwrapV3(inV3);
		arr.push(v0, v1);
		arr.push(v1, v2);
		arr.push(v2, v0);
	}

	drawText3D(inPosition, inStringPtr, inStringLen, inColor, inHeight) {
		const color = JoltModule.Jolt.wrapPointer(inColor, JoltModule.Jolt.Color).mU32 >>> 0;
		const position = unwrapV3(inPosition);
		const height = inHeight;
		const text = textDecoder.decode(
			JoltModule.Jolt.HEAPU8.subarray(inStringPtr, inStringPtr + inStringLen),
		);
		this.textList.push({ color, position, height, text });
	}

	// Assuming a Render Geometry/Batch has been created, the following is a request to render the Geometry at a given model location
	drawGeometryWithID(
		inModelMatrix,
		_inWorldSpaceBounds,
		_inLODScaleSq,
		inModelColor,
		inGeometryID,
		inCullMode,
		_inCastShadow,
		inDrawMode,
	) {
		const colorU32 = JoltModule.Jolt.wrapPointer(inModelColor, JoltModule.Jolt.Color).mU32 >>> 0;
		const modelMatrix = JoltModule.Jolt.wrapPointer(inModelMatrix, JoltModule.Jolt.RMat44);
		const v0 = wrapVec3(modelMatrix.GetAxisX());
		const v1 = wrapVec3(modelMatrix.GetAxisY());
		const v2 = wrapVec3(modelMatrix.GetAxisZ());
		const v3 = wrapVec3(modelMatrix.GetTranslation());
		const matrix = new THREE.Matrix4().makeBasis(v0, v1, v2).setPosition(v3);

		const geometry = this.geometryCache[inGeometryID];

		if (!geometry) {
			logger.warn("drawGeometryWithID: Geometry not found", inGeometryID);
			return;
		}

		this.geometryList.push({
			matrix: matrix,
			geometry,
			color: colorU32,
			drawMode: inDrawMode,
			cullMode: inCullMode,
		});
	}

	// On initializing the Renderer, or adding new rigid Mesh, the following methods will send the vertex data here to construct a Render Geometry
	createTriangleBatchID(inTriangles, inTriangleCount) {
		const batchID = this.geometryCache.length;
		const { mPositionOffset, mNormalOffset, mUVOffset, mSize } =
			JoltModule.Jolt.DebugRendererVertexTraits.prototype;
		const interleaveBufferF32 = new Float32Array((inTriangleCount * 3 * mSize) / 4);

		// Assuming a triangle is tightly packed (always 3 vertex with no leading or trailing space), we can treat the data chunk
		// as a whole as if it was an interleaved vertex buffer, assuming no alignment issues such that element N+1 is more than (size) from element N+0
		// This case is always true as of this coding, but should it not be, the following [else] case will extract just the 3 vertex
		if (
			JoltModule.Jolt.DebugRendererTriangleTraits.prototype.mVOffset === 0 &&
			JoltModule.Jolt.DebugRendererTriangleTraits.prototype.mSize === mSize * 3
		) {
			interleaveBufferF32.set(
				new Float32Array(JoltModule.Jolt.HEAPF32.buffer, inTriangles, interleaveBufferF32.length),
			);
		} else {
			const vertexChunk = (mSize / 4) * 3;
			for (let i = 0; i < inTriangleCount; i++) {
				const triOffset =
					inTriangles +
					i * JoltModule.Jolt.DebugRendererTriangleTraits.prototype.mSize +
					JoltModule.Jolt.DebugRendererTriangleTraits.prototype.mVOffset;
				interleaveBufferF32.set(
					new Float32Array(JoltModule.Jolt.HEAPF32.buffer, triOffset, i * vertexChunk),
				);
			}
		}
		// Create a three mesh
		const geometry = new THREE.BufferGeometry();
		const interleavedBuffer = new THREE.InterleavedBuffer(interleaveBufferF32, mSize / 4);
		geometry.setAttribute(
			"position",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 3, mPositionOffset / 4),
		);
		geometry.setAttribute(
			"normal",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 3, mNormalOffset / 4),
		);
		geometry.setAttribute(
			"uv",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 2, mUVOffset / 4),
		);
		this.geometryCache.push(geometry);
		return batchID;
	}

	createTriangleBatchIDWithIndex(inVertices, inVertexCount, inIndices, inIndexCount) {
		const batchID = this.geometryCache.length;
		const { mPositionOffset, mNormalOffset, mUVOffset, mSize } =
			JoltModule.Jolt.DebugRendererVertexTraits.prototype;
		const interleaveBufferF32 = new Float32Array((inVertexCount * mSize) / 4);
		interleaveBufferF32.set(
			new Float32Array(JoltModule.Jolt.HEAPF32.buffer, inVertices, interleaveBufferF32.length),
		);
		const index = new Uint32Array(inIndexCount);

		// Unlike triangles, by definition this data will be an interleaved data buffer
		index.set(JoltModule.Jolt.HEAPU32.subarray(inIndices / 4, inIndices / 4 + inIndexCount));
		// Create a three mesh
		const geometry = new THREE.BufferGeometry();
		const interleavedBuffer = new THREE.InterleavedBuffer(interleaveBufferF32, mSize / 4);
		geometry.setAttribute(
			"position",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 3, mPositionOffset / 4),
		);
		geometry.setAttribute(
			"normal",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 3, mNormalOffset / 4),
		);
		geometry.setAttribute(
			"uv",
			new THREE.InterleavedBufferAttribute(interleavedBuffer, 2, mUVOffset / 4),
		);
		geometry.setIndex(new THREE.BufferAttribute(index, 1));
		this.geometryCache.push(geometry);
		return batchID;
	}

	getMeshMaterial(color, cullMode, drawMode) {
		const key = `${color}|${cullMode}|${drawMode}`;

		if (!this.materialCache[key]) {
			const material = new THREE.MeshPhongMaterial({
				color,
				polygonOffset: true,
				polygonOffsetUnits: -4,
			});

			// Debug Renderer supports applying color, Front and Back face culling, and drawing as solid or wire frame.
			// These all correspond to different Three Materials, so cache them here.
			if (drawMode === JoltModule.Jolt.EDrawMode_Wireframe) {
				material.wireframe = true;
			}

			if (cullMode !== undefined) {
				switch (cullMode) {
					case JoltModule.Jolt.ECullMode_Off:
						material.side = THREE.DoubleSide;
						break;
					case JoltModule.Jolt.ECullMode_CullBackFace:
						material.side = THREE.FrontSide;
						break;
					case JoltModule.Jolt.ECullMode_CullFrontFace:
						material.side = THREE.BackSide;
						break;
				}
			}

			this.materialCache[key] = material;
		}

		return this.materialCache[key];
	}

	// The following call flushes all accumulated Draw calls to new or existing Meshes that have been cached.
	// Line/Triangle calls are combined into single Meshes per material.
	// Text3D calls trigger a lazy initialization of CSS32 Render to render the text as transformed DIVs
	render() {
		this.clear();

		Object.entries(this.lineCache).forEach(([colorU32, points]) => {
			const color = parseInt(colorU32, 10);
			if (this.lineMesh[color]) {
				this.lineMesh[color].geometry = new THREE.BufferGeometry().setFromPoints(points);
				const mesh = this.lineMesh[color];
				mesh.visible = true;
			} else {
				const material = new THREE.LineBasicMaterial({ color: color });
				const geometry = new THREE.BufferGeometry().setFromPoints(points);
				const mesh = (this.lineMesh[color] = new THREE.LineSegments(geometry, material));

				this.scene.add(mesh);
			}
		});

		Object.entries(this.triangleCache).forEach(([colorU32, points]) => {
			const color = parseInt(colorU32, 10);
			if (this.triangleMesh[color]) {
				this.triangleMesh[color].geometry = new THREE.BufferGeometry().setFromPoints(points);
				const mesh = this.triangleMesh[color];
				mesh.visible = true;
			} else {
				const material = this.getMeshMaterial(color, undefined, undefined);
				const geometry = new THREE.BufferGeometry().setFromPoints(points);
				const mesh = (this.triangleMesh[color] = new THREE.Mesh(geometry, material));

				this.scene.add(mesh);
			}
		});

		this.geometryList.forEach(({ geometry, color, matrix, cullMode, drawMode }, i) => {
			const material = this.getMeshMaterial(color, cullMode, drawMode);
			let mesh = this.meshList[i];
			if (!mesh) {
				mesh = this.meshList[i] = new THREE.Mesh(geometry, material);

				this.scene.add(mesh);
			} else {
				mesh.material = material;
				mesh.geometry = geometry;
			}
			matrix.decompose(mesh.position, mesh.quaternion, mesh.scale);
			mesh.visible = true;
		});

		this.textList.forEach(({ position, text, color, height: _height }, i) => {
			let mesh = this.textCache[i];
			if (!this.css2dRenderer) {
				// Lazy construct a CSS2D Renderer.
				this.css2dRenderer = new CSS2DRenderer();
				const renderSize = new THREE.Vector2();
				this.renderer.getSize(renderSize);
				this.css2dRenderer.setSize(renderSize.x, renderSize.y);
				const css2dRendererDomElement = this.css2dRenderer.domElement;
				this.renderer.domElement.parentElement?.append(css2dRendererDomElement);
				css2dRendererDomElement.id = "joltCss2dRenderer";
				css2dRendererDomElement.style.position = "absolute";
				css2dRendererDomElement.style.top = "0";
				css2dRendererDomElement.style.left = "0";
				css2dRendererDomElement.style.pointerEvents = "none";
				css2dRendererDomElement.style.zIndex = "1";
				css2dRendererDomElement.style.width = "100%";
				css2dRendererDomElement.style.height = "100%";
				window.addEventListener(
					"resize",
					() => {
						this.renderer.getSize(renderSize);
						this.css2dRenderer.setSize(renderSize.x, renderSize.y);
					},
					false,
				);
			}
			if (!mesh) {
				mesh = this.textCache[i] = new CSS2DObject(document.createElement("div"));
				mesh.element.style.display = "block";
				mesh.element.style.fontSize = "30px";
				mesh.element.style.width = "fit-content";

				this.scene.add(mesh);
			} else {
				mesh.element.innerText = text;
				mesh.element.style.color = "#" + ("000000" + color.toString(16)).substr(-6);
			}
			mesh.position.copy(position);
			mesh.visible = true;
		});

		// Render the CSS 2D here (updates the DIV locations and css transforms)
		this.css2dRenderer && this.css2dRenderer.render(this.scene, this.camera);

		// Clear the accumulators of [Draw] requests
		this.geometryList = [];
		this.textList = [];
		this.lineCache = {};
		this.triangleCache = {};
	}

	clear() {
		// Clear previous frames meshes, in case this frame no longer has these meshes.
		[
			Object.values(this.lineMesh),
			Object.values(this.triangleMesh),
			this.meshList,
			this.textCache,
		].forEach((meshes) => {
			meshes.forEach((mesh) => (mesh.visible = false));
		});
	}

	dispose() {
		this.meshList.forEach((mesh) => this.scene.remove(mesh));
		this.textCache.forEach((mesh) => this.scene.remove(mesh));
		Object.values(this.lineMesh).forEach((mesh) => this.scene.remove(mesh));
		Object.values(this.triangleMesh).forEach((mesh) => this.scene.remove(mesh));

		this.css2dRenderer && this.css2dRenderer.domElement.remove();

		JoltModule.Jolt.destroy(this.bodyDrawSettings);
		JoltModule.Jolt.destroy(this.debugRenderer);
	}
}
