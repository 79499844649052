import type { Input } from "@jamango/engine/Input.ts";
import { isNullish } from "@jamango/helpers";
import { NODE } from "base/rete/InternalNameMap";
import { onBlockGroupEvent, onBlockTypeEvent } from "base/rete/modules/trigger";
import { Vector3Map } from "base/util/math/VectorStorage";
import * as Physics from "base/world/Physics";
import * as BlockGroups from "base/world/block/BlockGroups";
import type { ChunkScene } from "base/world/block/Scene";
import { BLOCK_AIR, BLOCK_COLLISION_DISABLED } from "base/world/block/Util";
import type { Character } from "base/world/entity/Character";
import type { BlockCollision } from "base/world/entity/component/CharacterCollision";
import { BlockCollisionBodyPart } from "base/world/entity/component/CharacterCollision";
import type { World } from "base/world/World";
import { NET_CLIENT, NET_SERVER } from "@jamango/ibs";
import { netState } from "router/Parallelogram";
import type { Vector3Like } from "three";
import { Vector3 } from "three";
import type { BlockTriggerExtraInfo } from "base/rete/Types";

const NORMAL_Y_THRESHOLD = 0.05; //mathematically a side hit should always have normal.y of 0
const TIP_EPSILON = 0.025;
const SIDE_EPSILON = 0.005;
const DIFFERENCE_THRESHOLD = 0.02;
const BLOCK_CENTER_DISTANCE_THRESHOLD = 0.55;

const _hitPos = new Vector3();
const _blockPos = new Vector3();
const _blockCenterPos = new Vector3();
const _distanceFromBlockCenter = new Vector3();
const _tipToHitDifference = new Vector3();
const _prvGroups = new Set<string>();
const _curGroups = new Set<string>();
const _prvBlocks = new Vector3Map<BlockCollision>();
const _curBlocks = new Vector3Map<BlockCollision>();

export function characterBlockCollisionUpdate(entity: Character, _deltaTime: number, _input: Input) {
	if (!canUpdateSystem(entity)) return;

	findCollisionBlocks(entity); //detect collisions in this current tick
	dispatchCollisionEvents(entity); //detect collision state changes since last tick
}

function canUpdateSystem(entity: Character) {
	return entity.type.def.isCharacter && !entity.mount.state.parent && !entity.movement.state.isFlying;
}

//---find---//

function findCollisionBlocks(character: Character) {
	let nearestFootToHitDistance = Infinity;
	let nearestHeadToHitDistance = Infinity;

	const collisionState = character.collision.state;

	// determine if the character is standing on a block using shape hits
	// This is trading off precision for simplicity. In most cases the extra accuracy we would get from
	// raycasting or intersection equations is not missed.
	// The physics simulation has already confirmed there was an intersection and has de-penetrated the character shape from the hit shape.
	for (
		let contactIndex = 0;
		contactIndex < character.characterPhysics.state.activeContacts.length;
		contactIndex++
	) {
		const { bodyIndexAndSequence, position, normal } =
			character.characterPhysics.state.activeContacts[contactIndex];
		const hitPos = _hitPos.copy(position).add(character.movement.state.displacementActual);

		// Ignore hits that are not against chunk shapes
		const isChunk = Physics.isBodyChunk(character.world.physics, bodyIndexAndSequence);
		if (!isChunk) {
			continue;
		}

		//use surface normal of the hit triangle to determine whether this was foot/side/head
		if (normal.y >= NORMAL_Y_THRESHOLD) {
			if (collisionState.hitFoot) {
				nearestFootToHitDistance = findTipBlock(
					character,
					false,
					hitPos,
					normal,
					nearestFootToHitDistance,
				);
			}
		} else if (normal.y <= -NORMAL_Y_THRESHOLD) {
			if (collisionState.hitHead) {
				nearestHeadToHitDistance = findTipBlock(
					character,
					true,
					hitPos,
					normal,
					nearestHeadToHitDistance,
				);
			}
		} else {
			if (collisionState.hitSide) {
				findSideBlocks(character, hitPos, normal);
			}
		}
	}

	//crouch barrier rescue: footBlock.found is false because character is dangling off block
	//use the prv tick's value so that the system doesn't lose track of where the barrier should be
	if (
		character.movement.state.isCrouching &&
		character.movement.def.hasCrouchBarriers &&
		!collisionState.footBlock.found &&
		collisionState.prvFootBlock.found &&
		collisionState.isOnEdge
	) {
		collisionState.footBlock.found = true;

		const cur = collisionState.footBlock;
		const prv = collisionState.prvFootBlock;
		cur.pos.copy(prv.pos);
		cur.type = prv.type;
		cur.groups = prv.groups;
	}

	findZoneBlock(character);
}

function findTipBlock(
	character: Character,
	headCheck: boolean,
	hitPos: Vector3,
	hitNor: Vector3Like,
	nearestHitDistance: number,
): number {
	const scene = character.world.scene;
	const collisionState = character.collision.state;
	const tipBlock = headCheck ? collisionState.headBlock : collisionState.footBlock;

	const headCheckSign = headCheck ? 1 : -1;
	// If footBlock.found is already true, only update the standing block if
	// the new hit is closer to the character's foot position.
	const tipToHitDifference = _tipToHitDifference.copy(hitPos).sub(character.position);
	if (headCheck) tipToHitDifference.y += character.size.state.height;

	const hitDistance = tipToHitDifference.length();
	if (
		(headCheck ? collisionState.headBlock.found : collisionState.footBlock.found) &&
		hitDistance >= nearestHitDistance
	) {
		return nearestHitDistance;
	}

	nearestHitDistance = hitDistance;

	// Find the hit block using the shape hit world position plus small adjustments.

	// Add a small y offset so if we are directly touching a block's surface, the point is inside the touched block
	hitPos.y += headCheckSign * (TIP_EPSILON + character.size.def.padding);

	// attempt 1/3: check if the hit point is inside a non-air block
	const blockPos = _blockPos.copy(hitPos).floor();
	if (scene.getShape(blockPos) !== BLOCK_AIR) {
		outputBlock(scene, tipBlock, blockPos, hitNor);
		return nearestHitDistance;
	}

	// attempt 2/3: if the hit point is inside an air block, we may be on the edge of a block.
	// offset the hit point in the horizontal direction of the tip to hit position difference
	//height/y is not used in the calculation
	if (Math.abs(tipToHitDifference.x) > DIFFERENCE_THRESHOLD) {
		hitPos.x += Math.sign(tipToHitDifference.x) * TIP_EPSILON;
	}

	if (Math.abs(tipToHitDifference.z) > DIFFERENCE_THRESHOLD) {
		hitPos.z += Math.sign(tipToHitDifference.z) * TIP_EPSILON;
	}

	blockPos.copy(hitPos).floor();
	if (scene.getShape(blockPos) !== BLOCK_AIR) {
		outputBlock(scene, tipBlock, blockPos, hitNor);
		return nearestHitDistance;
	}

	//attempt 3/3: if we still haven't found a hit block, find the closest adjacent walkable block
	// x x x
	// x o x
	// x x x

	// this scenario can happen when e.g. perfectly in the middle of 4 flat blocks

	let nearestAdjacentWalkableBlock = Infinity;
	for (let x = -1; x <= 1; x++) {
		for (let z = -1; z <= 1; z++) {
			if (x === 0 && z === 0) {
				continue;
			}

			blockPos.copy(hitPos).floor();
			blockPos.x += x;
			blockPos.z += z;

			// test if the block is walkable
			if (
				scene.getShape(blockPos) === BLOCK_AIR ||
				scene.getShape(blockPos.x, blockPos.y - headCheckSign, blockPos.z) !== BLOCK_AIR
			) {
				continue;
			}

			const blockCenterPosition = _blockCenterPos.copy(blockPos);
			blockCenterPosition.x += 0.5;
			blockCenterPosition.z += 0.5;

			const horizontalDifference = _distanceFromBlockCenter.copy(hitPos).sub(blockCenterPosition);
			horizontalDifference.y = 0;

			// test if close enough to the block center, eg right on the edge
			if (
				Math.abs(horizontalDifference.x) >= BLOCK_CENTER_DISTANCE_THRESHOLD ||
				Math.abs(horizontalDifference.z) >= BLOCK_CENTER_DISTANCE_THRESHOLD
			) {
				continue;
			}

			const horizontalDistance = horizontalDifference.length();
			if (horizontalDistance < nearestAdjacentWalkableBlock) {
				nearestAdjacentWalkableBlock = horizontalDistance;
				outputBlock(scene, tipBlock, blockPos, hitNor);
			}
		}
	}

	return nearestHitDistance;
}

function findSideBlocks(character: Character, hitPos: Vector3, hitNor: Vector3Like) {
	const charPos = character.position;
	const radius = character.size.state.radius + character.size.def.padding;
	const sideBlock = character.collision.state.sideBlocks;
	const scene = character.world.scene;

	//push the hit point slightly away from the character to ensure that it's inside the contacted block
	hitPos.y = 0;
	hitPos.sub(charPos);
	hitPos.y = 0; //height/y of the hit is not used in the calculation. setting to 0 here so that setLength doesn't take it into account
	hitPos.setLength(radius + SIDE_EPSILON);
	hitPos.add(charPos);

	//shoot a vertical "raycast" at hitPoint, from the bottom to the top of the character's cylindrical portion
	//shaving off the top+bottom hemispheres down into a cylinder because they are foot/head
	//this isn't a true raycast. it assumes all blocks in the vertical stack are of the same shape

	const blockX = Math.floor(hitPos.x);
	const blockZ = Math.floor(hitPos.z);
	const blockYMin = Math.floor(charPos.y + radius); //cylinder bottom rounded down
	const blockYMax = Math.ceil(charPos.y + character.size.state.height - radius); //cylinder top rounded up

	NEXT_RAY_HIT: for (let blockY = blockYMin; blockY < blockYMax; blockY++) {
		const blockPos = _blockPos.set(blockX, blockY, blockZ);

		if (scene.getShape(blockPos) === BLOCK_AIR) continue; //can't collide with air
		if (
			scene.world.blockTypeRegistry.blockNameToType.get(scene.getType(blockPos))!.collision ===
			BLOCK_COLLISION_DISABLED
		)
			continue; //block type has no collision

		for (const prvOutBlock of sideBlock) if (prvOutBlock.pos.equals(blockPos)) continue NEXT_RAY_HIT; //already found

		const collision: BlockCollision = {
			found: true,
			pos: new Vector3(),
			nor: new Vector3(),
			type: null!,
			groups: null,
		};

		outputBlock(scene, collision, blockPos, hitNor);
		sideBlock.push(collision);
	}
}

function findZoneBlock(character: Character) {
	const scene = character.world.scene;
	const zone = character.collision.state.zoneBlock;

	const pos = zone.pos.copy(character.position);
	pos.y += TIP_EPSILON;
	pos.floor();

	if (scene.getShape(pos) === BLOCK_AIR) {
		zone.groups = BlockGroups.getIdsAtPosition(scene.world.blockGroups, pos.x, pos.y, pos.z);
		zone.found = !isNullish(zone.groups);
	} else {
		zone.found = false;
	}
}

function outputBlock(scene: ChunkScene, output: BlockCollision, hitPos: Vector3, hitNor: Vector3Like) {
	output.found = true;
	output.pos.copy(hitPos);
	output.nor.copy(hitNor);
	output.type = scene.getType(hitPos);
	output.groups = BlockGroups.getIdsAtPosition(scene.world.blockGroups, hitPos.x, hitPos.y, hitPos.z);
}

//---dispatch---//

export function dispatchCollisionEvents(character: Character) {
	const collisionState = character.collision.state;

	if (!character.movement.state.isFlying) {
		dispatchTipEvent(
			character,
			BlockCollisionBodyPart.foot,
			collisionState.prvFootBlock,
			collisionState.footBlock,
		);

		dispatchSideEvents(character);

		dispatchTipEvent(
			character,
			BlockCollisionBodyPart.head,
			collisionState.prvHeadBlock,
			collisionState.headBlock,
		);
	}

	dispatchZoneEvent(character);
}

function dispatchTipEvent(
	character: Character,
	tip: BlockCollisionBodyPart,
	prvCol: BlockCollision,
	curCol: BlockCollision,
) {
	const world = character.world;
	const colEnd = NODE.OnBlockCollisionEnd;
	const colStart = NODE.OnBlockCollisionStart;

	const prvFound = prvCol.found;
	const curFound = curCol.found;
	const prvPos = prvCol.pos;
	const curPos = curCol.pos;

	const prvGroups = prvCol.groups;
	const curGroups = curCol.groups;

	if (curFound && curGroups) {
		for (const curGroup of curGroups) {
			if (!prvFound || !prvGroups || !prvGroups.includes(curGroup)) {
				onBlockGroupEvent(
					colStart,
					world,
					curPos.x,
					curPos.y,
					curPos.z,
					{
						character,
						bodyPart: tip,
						blockNormal: curCol.nor,
					},
					curGroup,
				);
			}
		}
	}

	if (prvFound && prvGroups) {
		for (const prvGroup of prvGroups) {
			if (!curFound || !curGroups || !curGroups.includes(prvGroup)) {
				onBlockGroupEvent(
					colEnd,
					world,
					prvPos.x,
					prvPos.y,
					prvPos.z,
					{
						character,
						bodyPart: tip,
						blockNormal: prvCol.nor,
					},
					prvGroup,
				);
			}
		}
	}

	const prvType = prvCol.type;
	const curType = curCol.type;
	const hasCharacterMoved = prvFound && curFound && !curPos.equals(prvPos);
	const hasBlockTypeChanged = (prvFound && prvType) !== (curFound && curType);

	// block type events (defs)
	if (hasCharacterMoved || hasBlockTypeChanged) {
		if (prvFound)
			dispatchBlockTypeEvent(
				colEnd,
				world,
				prvPos,
				{ character, bodyPart: tip, blockNormal: prvCol.nor },
				prvType!,
			);
		if (curFound)
			dispatchBlockTypeEvent(
				colStart,
				world,
				curPos,
				{ character, bodyPart: tip, blockNormal: curCol.nor },
				curType!,
			);
	}
}

function dispatchSideEvents(character: Character) {
	//for side block GROUP events, only the "bottom"/lowest block in the stack dispatches an event
	//because the loop in detectSideBlocks starts pushing positions to the array starting at blockYMin
	//also assuming this is closer to user expectations:
	//only ever dispatch 1 start and 1 end block position for block group collision events

	const world = character.world;
	const colStart = NODE.OnBlockCollisionStart;
	const colEnd = NODE.OnBlockCollisionEnd;
	const collisionState = character.collision.state;

	const prvGroups = _prvGroups;
	prvGroups.clear();
	for (const prvCol of collisionState.prvSideBlocks) {
		if (prvCol.groups) {
			for (const group of prvCol.groups) {
				prvGroups.add(group);
			}
		}
	}

	const curGroups = _curGroups;
	curGroups.clear();
	for (const curCol of collisionState.sideBlocks) {
		if (curCol.groups) {
			for (const group of curCol.groups) {
				curGroups.add(group);
			}
		}
	}

	for (const prvGroup of prvGroups) {
		//filter out common block groups in both sets
		if (curGroups.has(prvGroup)) {
			curGroups.delete(prvGroup);
			prvGroups.delete(prvGroup);

			continue;
		}

		const dispatchCol = collisionState.prvSideBlocks.find((e) => e.groups?.includes(prvGroup))!;
		onBlockGroupEvent(
			colEnd,
			world,
			dispatchCol.pos.x,
			dispatchCol.pos.y,
			dispatchCol.pos.z,
			{
				character,
				bodyPart: BlockCollisionBodyPart.side,
				blockNormal: dispatchCol.nor,
			},
			prvGroup,
		);
	}

	for (const curGroup of curGroups) {
		const dispatchCol = collisionState.sideBlocks.find((e) => e.groups?.includes(curGroup))!;
		onBlockGroupEvent(
			colStart,
			world,
			dispatchCol.pos.x,
			dispatchCol.pos.y,
			dispatchCol.pos.z,
			{
				character,
				bodyPart: BlockCollisionBodyPart.side,
				blockNormal: dispatchCol.nor,
			},
			curGroup,
		);
	}

	//block type collision events on the other hand triggers for every single contacted block

	const prvBlocks = _prvBlocks;
	prvBlocks.clear();
	for (const prvCol of collisionState.prvSideBlocks) prvBlocks.set(prvCol.pos, prvCol);

	const curBlocks = _curBlocks;
	curBlocks.clear();
	for (const curCol of collisionState.sideBlocks) curBlocks.set(curCol.pos, curCol);

	for (const prvCol of prvBlocks.values()) {
		if (curBlocks.has(prvCol.pos)) {
			//if block pos is still in the list of side collisions but the type has changed, dispatch end+start
			const curCol: BlockCollision = curBlocks.get(prvCol.pos)!;
			if (curCol.type !== prvCol.type) {
				dispatchBlockTypeEvent(
					colEnd,
					world,
					prvCol.pos,
					{ character, bodyPart: BlockCollisionBodyPart.side, blockNormal: prvCol.nor },
					prvCol.type!,
				);
				dispatchBlockTypeEvent(
					colStart,
					world,
					curCol.pos,
					{ character, bodyPart: BlockCollisionBodyPart.side, blockNormal: curCol.nor },
					curCol.type!,
				);
			}

			//filter out common blocks in both sets
			curBlocks.delete(prvCol.pos);
			prvBlocks.delete(prvCol.pos);
			continue;
		}

		dispatchBlockTypeEvent(
			colEnd,
			world,
			prvCol.pos,
			{ character, bodyPart: BlockCollisionBodyPart.side, blockNormal: prvCol.nor },
			prvCol.type!,
		);
	}

	for (const curCol of curBlocks.values()) {
		dispatchBlockTypeEvent(
			colStart,
			world,
			curCol.pos,
			{ character, bodyPart: BlockCollisionBodyPart.side, blockNormal: curCol.nor },
			curCol.type!,
		);
	}
}

function dispatchZoneEvent(character: Character) {
	const world = character.world;
	const collisionState = character.collision.state;
	const prvZone = collisionState.prvZoneBlock;
	const curZone = collisionState.zoneBlock;

	if (curZone.groups) {
		for (const curGroup of curZone.groups) {
			if (!prvZone.found || !prvZone.groups || !prvZone.groups.includes(curGroup)) {
				const curPos = curZone.pos;
				onBlockGroupEvent(
					NODE.OnBlockZoneEnter,
					world,
					curPos.x,
					curPos.y,
					curPos.z,
					{ character },
					curGroup,
				);
			}
		}
	}

	if (prvZone.groups) {
		for (const prvGroup of prvZone.groups) {
			if (!curZone.found || !curZone.groups || !curZone.groups.includes(prvGroup)) {
				const prvPos = prvZone.pos;
				onBlockGroupEvent(
					NODE.OnBlockZoneLeave,
					world,
					prvPos.x,
					prvPos.y,
					prvPos.z,
					{ character },
					prvGroup,
				);
			}
		}
	}
}

function dispatchBlockTypeEvent(
	eventType: typeof NODE.OnBlockCollisionStart | typeof NODE.OnBlockCollisionEnd,
	world: World,
	block: Vector3,
	extraInfo: BlockTriggerExtraInfo,
	blockType: string,
) {
	// TODO: dek - for the time being (indev - networking) we simply fire the legacy event system here
	// this allows us to run both the server sided blocks (explosion, trapdoor) as well as client sided (movement etc)
	// TODO: store and fire these through rete
	if (netState.isHost) {
		[NET_CLIENT, NET_SERVER].forEach((net) => {
			world.blockTypeRegistry.blockNameToType.get(blockType)!.dispatcher.dispatchEvent(net, {
				...extraInfo,
				type: eventType === NODE.OnBlockCollisionStart ? "collisionstart" : "collisionend",
				container: world.scene,
				...block,
			});
		});
	}

	// this is the actual rete part, above event dispatches are for inbuilt defs
	onBlockTypeEvent(eventType, world, block.x, block.y, block.z, extraInfo);
}
