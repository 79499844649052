import type { ItemMesh } from "@jamango/content-client";
import { ItemMeshType } from "@jamango/content-client";
import { entityIsItem } from "base/world/entity/component/Type";
import type { Entity } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";
import { netState } from "router/Parallelogram";
import type { EulerOrder } from "three";
import { Euler, Group, Quaternion, type Object3D } from "three";
import { DEGRAD } from "base/util/math/Math";
import { createBlockStructureMesh } from "base/world/block/BlockStructureMesh";
import type { World } from "base/world//World";

const _quaternion = new Quaternion();
const _euler = new Euler();

export function itemMeshUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const needsUpdate = entity.itemMesh.def.meshHash !== entity.itemMesh.state.meshHash;

	if (needsUpdate) {
		removeItemMesh(entity);
		addItemMesh(entity);
	}

	const mounted = !!entity.mount.state.parent;

	const needsTransform = entity.itemMesh.state.group && entity.itemMesh.state.mounted !== mounted;

	if (needsTransform) {
		const group = entity.itemMesh.state.group!;

		if (mounted) {
			group.rotation.set(-90 * DEGRAD, 180 * DEGRAD, 0);

			if (entity.itemMesh.def.mesh.type === ItemMeshType.BLOCK_STRUCTURE) {
				const transform = entity.itemMesh.def.mesh.transform;

				// TODO: why does mounting to the fp node make things so smol?
				group.scale.fromArray(transform.scale).multiplyScalar(100);

				// TODO: what is happening with the bones & transforms here?
				const x = -transform.offset[0];
				const y = -transform.offset[2];
				const z = -transform.offset[1];
				group.position.set(x, y, z);

				const rx = transform.rotation[0];
				const ry = transform.rotation[2];
				const rz = transform.rotation[1];
				const order = transform.rotation[3] as EulerOrder;
				const itemHandOffsetQuaternion = _quaternion.setFromEuler(
					_euler.set(rx * DEGRAD, ry * DEGRAD, rz * DEGRAD, order),
				);
				group.quaternion.multiplyQuaternions(itemHandOffsetQuaternion, group.quaternion);
			}
		} else {
			group.position.set(0, 0, 0);
			group.rotation.set(0, 0, 0);

			if (entity.itemMesh.def.mesh.type === ItemMeshType.BLOCK_STRUCTURE) {
				const transform = entity.itemMesh.def.mesh.transform;
				group.scale.fromArray(transform.scale);
			} else {
				group.scale.set(1, 1, 1);
			}
		}

		entity.itemMesh.state.mounted = mounted;
	}
}

function canUpdateSystem(entity: Entity): entity is Item {
	return netState.isClient && entityIsItem(entity) && entity.itemPk !== undefined;
}

function createItemMesh(world: World, itemMeshDesc: ItemMesh, parent: Object3D) {
	if (itemMeshDesc.type === ItemMeshType.BLOCK_STRUCTURE) {
		const blockStructure = world.content.state.blockStructures.get(itemMeshDesc.blockStructurePk);
		if (!blockStructure) return false;

		return createBlockStructureMesh(world, blockStructure, parent);
	}

	return false;
}

function addItemMesh(item: Item) {
	const world = item.world;

	const mesh = item.itemMesh.def.mesh;

	const group = new Group();

	const result = createItemMesh(world, mesh, group);
	if (!result) return;

	for (const geometry of result.geometries) {
		item.itemMesh.state.geometries.push(geometry);
	}

	for (const mesh of result.meshes) {
		item.itemMesh.state.meshes.push(mesh);
	}

	item.object3D.add(group);

	item.itemMesh.state.group = group;

	item.itemMesh.state.meshHash = item.itemMesh.def.meshHash;
}

export function removeItemMesh(item: Item) {
	for (const mesh of item.itemMesh.state.meshes) {
		mesh.removeFromParent();
	}
	for (const geometry of item.itemMesh.state.geometries) {
		geometry.dispose();
	}
	for (const material of item.itemMesh.state.materials) {
		material.dispose();
	}
	item.itemMesh.state.meshes.length = 0;
	item.itemMesh.state.geometries.length = 0;
	item.itemMesh.state.materials.length = 0;
}
