import type { Input } from "@jamango/engine/Input.ts";
import { BLOCK_AIR } from "base/world/block/Util.js";
import type { Character } from "base/world/entity/Character";
import { resetCollisionState } from "base/world/entity/system/CharacterAnyCollision";
import * as Physics from "base/world/Physics";
import { Vector3 } from "three";

const _displacement = new Vector3();
const _flyingNewPosition = new Vector3();

const edgeDetectionAxes = ["x", "z"] as const;
const edgeDetectionDirections = [-1, 1] as const;

const _edgeTestBlock = new Vector3();

export function characterMovementControllerUpdate(entity: Character, stepDeltaTime: number, _input: Input) {
	if (!canUpdateSystem(entity)) return;

	/* update collider */
	const character = entity as Character;
	const world = character.world;

	const physicsNeedsUpdate = character.physicsNeedsUpdate();

	if (physicsNeedsUpdate) {
		character.removePhysics();
		character.addPhysics();
	}

	if (character.characterPhysics.state.controller) {
		// set position from entity
		Physics.setCharacterControllerPosition(
			character.characterPhysics.state.controller,
			character.position,
		);

		// update crouching
		if (character.characterPhysics.state.isCrouching !== character.movement.state.isCrouching) {
			character.characterPhysics.state.isCrouching = character.movement.state.isCrouching;

			Physics.setCharacterControllerCrouching(
				world.physics,
				character.characterPhysics.state.controller,
				character.characterPhysics.state.isCrouching,
			);
		}
	}

	/* don't proceed controller if prophecy or mounted */
	if (entity.prophecy.state.isProphecy || entity.mount.state.parent) return;

	/* before physics step controller logic */
	if (!entity.characterPhysics.state.controller) return;

	if (entity.movement.state.isFlying) {
		const flyingDisplacement = _displacement
			.copy(entity.movement.state.velocityRequest)
			.multiplyScalar(stepDeltaTime);

		_flyingNewPosition.copy(entity.position).add(flyingDisplacement);

		Physics.setCharacterControllerPosition(entity.characterPhysics.state.controller, _flyingNewPosition);
	} else {
		resetCollisionState(entity, false);

		// edge detection, can modify displacementRequest
		edgeDetection(entity);

		// stick to floor if the character is grounded
		const stickToFloorStepDown = entity.collision.state.prvOnGround
			? entity.movement.def.stickToFloorStepDown
			: 0;

		const autoStepHeight =
			entity.collision.state.prvOnGround && !entity.movement.state.isSlipperySurface
				? entity.movement.def.autoStepHeight
				: 0;

		const characterCollisions = entity.world.content.state.gameMechanics.characterCollisions;

		Physics.updateCharacterController(
			entity.world.physics,
			entity.characterPhysics.state.controller,
			stepDeltaTime,
			entity.world.editor.paused,
			entity.movement.state.velocityRequest,
			entity.movement.state.wishDirection,
			autoStepHeight,
			stickToFloorStepDown,
			entity.movement.state.isSlipperySurface,
			characterCollisions,
			entity.movement.def.mode,
		);
	}
}

function canUpdateSystem(entity: Character) {
	return entity.type.def.isCharacter;
}

function edgeDetection(character: Character) {
	if (!character.edgeDetection || !character.collision.state.prvFootBlock.found) return;

	const footBlockPos = character.collision.state.prvFootBlock.pos;

	for (const axis of edgeDetectionAxes) {
		for (const dir of edgeDetectionDirections) {
			const testBlock = _edgeTestBlock.copy(footBlockPos);
			testBlock[axis] += dir;

			const disp = getEdgeDisplacement(character, axis, dir, footBlockPos, testBlock);

			if (disp !== null) {
				character.collision.state.isOnEdge = true;

				if (
					character.movement.def.hasCrouchBarriers &&
					character.movement.state.isCrouching &&
					!character.movement.state.isSliding
				) {
					character.movement.state.velocityRequest[axis] = disp;
				}
			}
		}
	}
}

function getEdgeDisplacement(
	character: Character,
	axis: (typeof edgeDetectionAxes)[number],
	dir: (typeof edgeDetectionDirections)[number],
	curBlock: Vector3,
	testBlock: Vector3,
) {
	if (character.world.scene.getShape(testBlock) !== BLOCK_AIR) {
		return null;
	}

	const size = character.movement.def.crouchBarrierSize;
	const curPos = character.position[axis];
	const newPos = curPos + character.movement.state.velocityRequest[axis];
	let limit;

	if (dir < 0) {
		limit = curBlock[axis] - size;
	} else {
		limit = curBlock[axis] + 1 + size;
	}

	if ((dir < 0 && newPos < limit) || (dir > 0 && newPos > limit)) {
		return limit - curPos;
	}

	return null;
}
