import { BUILD_TIME, SHORT_HASH } from "@jamango/generated";
import { BB } from "base/BB";
import { bytesToString } from "base/util/math/Math.ts";
import { get12HrTime } from "base/world/fx/Sky.js";
import * as Metric from "base/world/MetricsManager";
import * as CharacterPhysicsDebug from "client/debug/CharacterPhysicsDebug";
import { UI } from "client/dom/UI";
import { JoltDebugRenderer } from "client/util/JoltDebugRenderer";
import { getPeers } from "router/Net";
import { netState } from "router/Parallelogram";

export class DebugClient {
	constructor() {
		this.enabled = UI.state.debug().open;
		this.occlusionCullingDebug = false;
		this.locomotionLogsEnabled = false;

		this.debugPhysics = false;

		this.debugVisualsEnabled = false;
		this.debugRendererMode = 0;
		this.numDebugRendererModes = 2;
		this.threeInfo = {};

		this.characterPhysicsDebug = CharacterPhysicsDebug.init();
		this.characterPhysicsDebugEnabled = false;

		this.timer = 0;
		this.fps = 0;
	}

	setDebugEnabled(enabled) {
		this.enabled = enabled;
		this.debugVisualsEnabled = enabled;
	}

	init() {
		Metric.setStatic("info", "build", SHORT_HASH + BUILD_TIME);
	}

	setRendererInfo(info) {
		Metric.setStatic("gpu", "draw calls", info.render.calls);
		Metric.setStatic("gpu", "triangles", info.render.triangles);
		Metric.setStatic("gpu", "geometries", info.memory.geometries);
		Metric.setStatic("gpu", "textures", info.memory.textures);
		Metric.setStatic("gpu", "programs", info.programs.length);
	}

	updateKeyboardInput() {
		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.debug)) {
			UI.state.debug().toggleOpen();
		}

		if (!UI.state.debug().open) return;

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleOcclusionCulling))
			UI.state.debug().toggleOcclusionCulling();

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleOcclusionCullingDebug))
			UI.state.debug().toggleOcclusionCullingDebug();

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleLocomotionLogs))
			UI.state.debug().toggleLocomotionLogs();

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleDebugRendererMode))
			UI.state.debug().toggleDebugRendererMode();

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleZPrepass))
			UI.state.debug().toggleZPrepass();

		if (BB.client.inputPoll.keysAreJustPressed(BB.client.settings.key.toggleAASetting))
			UI.state.debug().toggleAASetting();
	}

	update(dt) {
		if (this.debugPhysics) {
			// lazy create the debug renderer
			if (!this.joltDebugRenderer) {
				this.joltDebugRenderer = new JoltDebugRenderer(
					BB.client.renderer,
					BB.world.scene,
					BB.world.client.camera,
				);
			}

			const physicsSystem = BB.world.physics.physicsSystem;
			this.joltDebugRenderer.initialize();
			this.joltDebugRenderer.drawBodies(physicsSystem);
			this.joltDebugRenderer.drawConstraints(physicsSystem);
			this.joltDebugRenderer.drawConstraintLimits(physicsSystem);
			this.joltDebugRenderer.render();
		} else {
			if (this.joltDebugRenderer) {
				this.joltDebugRenderer.clear();
			}
		}

		CharacterPhysicsDebug.update(this.characterPhysicsDebug, BB.world, this.characterPhysicsDebugEnabled);

		if (!this.enabled) return;

		this.fps++;
		if ((this.timer -= dt) < 0) {
			this.timer = 1;
		} else {
			return;
		}

		Metric.setStatic("gpu", "fps", this.fps / this.timer);
		this.fps = 0;

		Metric.setStatic("info", "time", get12HrTime(BB.world.sky));
		Metric.setStatic("net", "hosting", netState.isHost ? "yes" : "no");
		Metric.setStatic("net", "peers", getPeers().length + 1);
		Metric.setStatic("info", "seed", BB.world.scene.seed);

		const physicsMemoryTotal = BB.world.physics.jolt.sGetTotalMemory();
		const physicsMemoryRemaining = BB.world.physics.jolt.sGetFreeMemory();
		const physicsMemoryUsed = physicsMemoryTotal - physicsMemoryRemaining;
		Metric.setStatic("memory", "jolt Total", bytesToString(physicsMemoryTotal));
		Metric.setStatic("memory", "jolt Remaining", bytesToString(physicsMemoryRemaining));
		Metric.setStatic("memory", "jolt Used", bytesToString(physicsMemoryUsed));

		const heap = performance.memory ? bytesToString(performance.memory.usedJSHeapSize) : "Unknown";
		Metric.setStatic("memory", "heap", heap);

		// structured clone to force mutation
		const values = structuredClone(Metric.getMetrics());
		UI.state.debug().setDebug({ metrics: values });
	}
}
