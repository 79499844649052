import { NetEventDispatcher } from "base/util/NetEventDispatcher.js";

export class ModdingComponent {
	constructor() {
		this.name = "modding";

		this.state = {
			metadata: new Map(),
			dispatcher: new NetEventDispatcher(),
		};
	}
}
