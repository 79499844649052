import * as Net from "router/Net";
import { UI } from "client/dom/UI";
import { netState } from "./Parallelogram";

export const showWorldPortal = (worldShortcode: string) => {
	if (!netState.isClient) return;

	UI.state.worldPortal().show(worldShortcode);
};

export const showWorldPortalCommand = Net.command(
	"show world portal",
	Net.CLIENT,
	(worldShortcode: string) => {
		showWorldPortal(worldShortcode);
	},
);
