import type { World } from "base/world/World.js";
import { makeClosure } from "./interpreter.ts";
import type { ListenerContext } from "base/rete/Types";

const dispatch = ({ ctx, closure }: ListenerContext, world: World, data: any) => {
	// clones the scope, adds event data to node in scope
	const scope = { ...closure.scope };
	scope[closure.node.node.id as string] = data;
	// makes a new closure
	const eventClosure = makeClosure(closure.node, scope, closure.predictable);
	eventClosure.executed = true; // prevents the trigger node itself re-activating
	// push cloned exec context to queue
	world.rete.contextQueue.push({
		...ctx, // clones original context
		closure: eventClosure,
	});
};

export const onEntityEvent = (defID: string, world: World, entityID: number, outputs: any) => {
	// instantiate & dispatch each allocated listener for this entityId (listeners[defId][entityID])
	const listeners = world.rete.listeners[defID];
	if (listeners !== undefined && listeners[entityID] !== undefined) {
		listeners[entityID].forEach((l: ListenerContext) => dispatch(l, world, outputs));
	}
};
