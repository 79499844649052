import { BB } from "base/BB";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as BlockGroups from "base/world/block/BlockGroups";
import type { Character } from "base/world/entity/Character";
import type { SelectorTarget } from "base/world/entity/component/CharacterSelector";
import {
	BulkSelectionBlocksBrushState,
	resetSelector,
	SelectorTargetType,
} from "base/world/entity/component/CharacterSelector";
import type { Entity } from "base/world/entity/Entity";
import { UI } from "client/dom/UI";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

const _block = new Vector3();
const _hitBlock = new Vector3();
const _characterBlock = new Vector3();

const NEW_GROUP_SCRIPT_TARGET = {
	type: SelectorTargetType.NEW_GROUP,
	id: "newGroup",
	name: "New Group",
} as const;

export function characterSelectorUpdate(character: Entity, _dt: number) {
	if (!canUpdateSystem(character)) return;

	const selectorState = character.selector?.state;
	const viewRaycastState = character.viewRaycast?.state;
	if (!selectorState) return;

	// update selector entities
	const viewRaycastEntity = viewRaycastState?.character ?? viewRaycastState?.prop ?? viewRaycastState?.item;
	selectorState.selectorEntities = new Set(
		[
			viewRaycastEntity?.entityID,
			character.input.hold?.[0],
			...(selectorState?.bulkSelectionEntities ?? []),
		].filter((v) => v !== undefined) as number[],
	);

	// store view raycast hit interact label hud and mobile controls
	const world = character.world;

	let hitInteractable = false;
	let hitInteractLabel: string | null = null;

	if (viewRaycastEntity?.interact.state.enable) {
		hitInteractable = true;
		hitInteractLabel = viewRaycastEntity.interact.state.label;
	} else if (viewRaycastState.block) {
		const blockTypeInteract = world.blockTypeRegistry.interactBlockTypes.get(
			viewRaycastState.blockHitTypeIndex,
		);

		if (blockTypeInteract) {
			hitInteractable = true;
			hitInteractLabel = blockTypeInteract.label;
		} else {
			const groups = BlockGroups.getIdsAtPosition(
				world.blockGroups,
				viewRaycastState.block.x,
				viewRaycastState.block.y,
				viewRaycastState.block.z,
			);
			if (groups) {
				for (const groupId of groups) {
					const vfx = world.client.interactLabels.groupIdToVfx.get(groupId);
					if (vfx) {
						hitInteractable = true;
						hitInteractLabel = vfx.label;
						break;
					}
				}
			}
		}
	}

	UI.state.selector().updateHitInteractable(hitInteractable, hitInteractLabel);

	// only update selector scripts info if in a creator context
	const updateCreatorSelector = viewRaycastState && isCreatorSelectorEnabled(character);

	if (!updateCreatorSelector) {
		const hasSelection =
			selectorState.bulkSelectionBlocks.size() > 0 ||
			selectorState.bulkSelectionEntities.size > 0 ||
			selectorState.bulkSelectionBlocksBrushState !== BulkSelectionBlocksBrushState.DISABLED;

		if (hasSelection) {
			resetSelector(character.selector);
		}

		return;
	}

	// update selector targets
	updateTargets(character);
}

function updateTargets(character: Character) {
	const world = character.world;

	const selectorState = character.selector?.state;
	const viewRaycastState = character.viewRaycast?.state;

	const blocks: Vector3[] = [];

	const multipleBlocksSelected = selectorState.bulkSelectionBlocks.size() > 1;

	if (selectorState.bulkSelectionBlocks.size() > 0) {
		for (const block of selectorState.bulkSelectionBlocks.iterate(_block)) {
			blocks.push(block.clone());
		}
	} else if (viewRaycastState.block) {
		blocks.push(_hitBlock.copy(viewRaycastState.block));
	}

	const blockGroupIds = new Set<string>();

	for (const block of blocks) {
		const groups = BlockGroups.getIdsAtPosition(world.blockGroups, block.x, block.y, block.z);
		if (!groups) continue;

		for (const groupId of groups) {
			blockGroupIds.add(groupId);
		}
	}

	const blockGroupsDetails = UI.state.editor().blockGroups;

	const blockGroups: SelectorTarget[] = [];

	for (const id of blockGroupIds) {
		const group = world.blockGroups.groups.get(id);
		if (!group) continue;

		const name = group.name !== null ? group.name : (blockGroupsDetails[id]?.name ?? "Unnamed Group");

		blockGroups.push({
			id,
			name,
			type: SelectorTargetType.GROUP,
		});
	}

	if (viewRaycastState.block) {
		blockGroups.push(NEW_GROUP_SCRIPT_TARGET);
	}

	const hitBlock = viewRaycastState.block;

	// try to predict what the player would want to select
	blockGroups.sort((a, b) => {
		const aIsNew = a.type === SelectorTargetType.NEW_GROUP;
		const bIsNew = b.type === SelectorTargetType.NEW_GROUP;

		const bgA = aIsNew ? null : world.blockGroups.groups.get(a.id as string);
		const bgB = bIsNew ? null : world.blockGroups.groups.get(b.id as string);

		// demote groups that the player is inside of
		_characterBlock.copy(character.position).round();
		const playerInsideA = bgA?.blocks.has(_characterBlock);
		if (playerInsideA) return 1;
		const playerInsideB = bgB?.blocks.has(_characterBlock);
		if (playerInsideB) return -1;

		// if multiple blocks are selected, "newGroup" should come first
		// otherwise, it should come last
		if (aIsNew && !bIsNew) return multipleBlocksSelected ? -1 : 1;
		if (!aIsNew && bIsNew) return multipleBlocksSelected ? 1 : -1;

		// promote groups that the selector hit block is in
		if (hitBlock && multipleBlocksSelected) {
			const aInHitBlock = bgA?.blocks.has(hitBlock) ?? false;
			const bInHitBlock = bgB?.blocks.has(hitBlock) ?? false;

			if (aInHitBlock && !bInHitBlock) return -1;
			if (!aInHitBlock && bInHitBlock) return 1;
		}

		const bgASize = bgA ? bgA.blocks.size() : 0;
		const bgBSize = bgB ? bgB.blocks.size() : 0;

		// sort by group size
		return bgASize - bgBSize;
	});

	const scriptTargets: SelectorTarget[] = [...blockGroups];

	// scene tree node target
	const sceneTreeNodeDetails = UI.state.editor().sceneTreeNodes;

	const hitEntity = viewRaycastState?.prop ?? viewRaycastState?.character ?? viewRaycastState?.item;

	if (hitEntity) {
		if (hitEntity.sceneTree.state.sceneTreeNode !== undefined) {
			const sceneTreeNodeId = hitEntity.sceneTree.state.sceneTreeNode!;

			const name = sceneTreeNodeDetails[sceneTreeNodeId]?.name ?? "Unnamed Spawner";

			let type: SelectorTargetType | undefined = undefined;

			if (viewRaycastState?.prop) {
				type = SelectorTargetType.PROP_SCENE_TREE_NODE;
			} else if (viewRaycastState?.item) {
				type = SelectorTargetType.ITEM_SCENE_TREE_NODE;
			} else if (viewRaycastState?.character) {
				type = SelectorTargetType.CHARACTER_SCENE_TREE_NODE;
			}

			if (type) {
				scriptTargets.unshift({
					id: sceneTreeNodeId,
					name,
					type,
				});
			}
		} else {
			scriptTargets.unshift({
				id: hitEntity.entityID,
				name: `Entity [${hitEntity.entityID}]`,
				type: SelectorTargetType.ENTITY_INSTANCE,
			});
		}
	}

	// block type script target
	const blockType =
		hitBlock && selectorState.bulkSelectionBlocks.size() === 0 && viewRaycastState.block
			? world.content.state.blocks.get(world.scene.getType(hitBlock.x, hitBlock.y, hitBlock.z))
			: null;

	const blockPK = blockType ? blockType.pk : null;

	// always add the block type to the end of the list
	if (blockType && blockPK && blockType.scripts && blockType.scripts.length > 0) {
		scriptTargets.push({
			id: blockPK,
			name: blockType.displayName,
			type: SelectorTargetType.BLOCK_TYPE,
		});
	}

	if (scriptTargets.length === 0) {
		selectorState.chosenTarget = null;
	}

	if (
		selectorState.chosenTarget &&
		(!selectorState.targets ||
			selectorState.targets.every((t) => t.id !== selectorState.chosenTarget!.id))
	) {
		selectorState.chosenTarget = null;
	}

	selectorState.targets = scriptTargets;

	// HACK/TODO: where should this input handling live?
	const input = BB.client.inputPoll;
	if (input.keysAreDown(["control"])) {
		selectorState.currentTarget = NEW_GROUP_SCRIPT_TARGET;
	} else {
		selectorState.currentTarget = selectorState.chosenTarget ?? scriptTargets[0];
	}

	// update the UI
	UI.state
		.selector()
		.updateSelector(
			selectorState.targets,
			selectorState.currentTarget,
			selectorState.chosenTarget,
			selectorState.bulkSelectionBlocks.size(),
			!!viewRaycastState.block,
		);
}

function canUpdateSystem(entity: Entity): entity is Character {
	return netState.isClient && entity === entity.world.client.camera.target;
}

function isCreatorSelectorEnabled(character: Character) {
	const peer = character.world.playerToPeer.get(character);
	if (!peer) return false;

	const permissions = getPeerMetadata(peer).permissions;
	if (!permissions.canUseWrench && !permissions.canUsePencil) return false;

	const equippedItem = character.getEquippedItem();
	return equippedItem?.def.includes("ItemPencil") || equippedItem?.def === "ItemWrench";
}
