import type { Object3D } from "three";
import { type ParticleSystemConfiguration } from "client/world/fx/ParticleEngineV2";
import type { EntityID } from "@jamango/engine/EntityID.ts";

export enum ParticleSystemState {
	Created,
	Running,
	Restart,
	Paused,
	Stopped,
}
export class ParticleSystemComponent {
	readonly name = "particleSystem";

	dirty = true;

	obj: Object3D | null = null;
	config: ParticleSystemConfiguration | null = null;
	psPK: string | null = null;
	nextState = ParticleSystemState.Created;
	currentState = ParticleSystemState.Created;
	time = 0;
	camDistanceSquared = -1;
	inCamProximity = true;

	constructor(public parentEntityId: EntityID) {}
}
