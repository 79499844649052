import type { EntityID } from "@jamango/engine/EntityID.ts";

export class CharacterDisabledInteractionsComponent {
	name = "disabledInteractions";

	state = {
		cantAttack: new Set<EntityID>(),
		//cantSeeNameplate, cantInteract, etc.
	};
}
