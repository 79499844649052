import { Box3 } from "three";
import { BLOCK_AIR } from "base/world/block/Util";
import type { Chunk } from "./chunk/Chunk";

type BlockType = string;
type BlockShape = number;
export type BlockCallback = (chunk: Chunk, x: number, y: number, z: number, cbarg?: any) => boolean;

export class BlockModel {
	modelBounds = new Box3();
	chunkBounds = new Box3();
	data: [number, number, number, BlockShape, BlockType, BlockCallback | undefined][] = [];

	constructor(readonly chunkSize: number) {}

	//callback should return true if block needs to be built, false if skip. see bottom of this file for examples
	//(mx, my, mz) are model coordinates, relative to wherever the model is being built
	setBlock(mx: number, my: number, mz: number, shape: BlockShape, type: BlockType, cb?: BlockCallback) {
		const data = this.data;
		data.push([mx, my, mz, shape, type, cb]);

		const mmin = this.modelBounds.min;
		const mmax = this.modelBounds.max;
		if (mx < mmin.x) mmin.x = mx;
		if (my < mmin.y) mmin.y = my;
		if (mz < mmin.z) mmin.z = mz;
		if (mx > mmax.x) mmax.x = mx;
		if (my > mmax.y) mmax.y = my;
		if (mz > mmax.z) mmax.z = mz;

		const s = this.chunkSize;
		const cmin = this.chunkBounds.min;
		const cmax = this.chunkBounds.max;
		const w = mmax.x - mmin.x;
		const h = mmax.y - mmin.y;
		const d = mmax.z - mmin.z;
		cmin.x = -w;
		cmin.y = -h;
		cmin.z = -d;
		cmax.x = s + w;
		cmax.y = s + h;
		cmax.z = s + d;
	}

	//blocks are guaranteed to be built in the same order they were added using setBlock
	build(chunk: Chunk, rx: number, ry: number, rz: number, triggerEvents: boolean, cbarg: any) {
		const chunkSize = this.chunkSize;

		for (const block of this.data) {
			const [mx, my, mz, shape, type, cb] = block;

			const rmx = rx + mx;
			const rmy = ry + my;
			const rmz = rz + mz;

			if (rmx < 0 || rmx >= chunkSize) continue;
			if (rmy < 0 || rmy >= chunkSize) continue;
			if (rmz < 0 || rmz >= chunkSize) continue;

			if (cb && !cb(chunk, rmx, rmy, rmz, cbarg)) continue;

			chunk.setBlock(rmx, rmy, rmz, shape, type, triggerEvents);
		}
	}

	static noOverwriteCB(chunk: Chunk, rx: number, ry: number, rz: number) {
		return chunk.getShape(rx, ry, rz) === BLOCK_AIR;
	}
}
