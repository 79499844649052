import { netState } from "router/Parallelogram";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterLocomotionStateMachineUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	if (entity.prophecy.state.isProphecy) {
		entity.setEmote("JMGO_Unarmed_Idle", true);
	}

	if (entity.meshLocomotion && entity.mesh.mesh.visible) entity.meshLocomotion.update();
	if (entity.fpmeshLocomotion && entity.fpmesh.mesh.parent) entity.fpmeshLocomotion.update();
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && !entity.canSkipUpdate;
}
