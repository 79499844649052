export type TopicListener<T extends Array<unknown>> = (...args: [...T]) => void;
export type TopicUnsubscribe = () => void;

export class Topic<T extends Array<unknown>> {
	listeners = new Set<TopicListener<T>>();

	add(listener: TopicListener<T>): TopicUnsubscribe {
		this.listeners.add(listener);

		return () => this.remove(listener);
	}

	remove(handler: TopicListener<T>) {
		this.listeners.delete(handler);
	}

	emit(...data: [...T]) {
		for (const handler of this.listeners) {
			handler(...data);
		}
	}

	clear() {
		this.listeners.clear();
	}
}
