import { BB } from "base/BB";
import { PathfindingHelper } from "client/world/debug/PathfindingHelper.js";
import { NET_CLIENT } from "@jamango/ibs";
import { BLOCK_AIR } from "base/world/block/Util.js";

export const ItemPathTester = {
	name: "ItemPathTester",
	display: "Path Tester",
	assets: [
		{
			url: "assets/engine/mods/ItemWrench/mdl-item-wrench.glb",
			id: "mdl-item-wrench",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-wrench",
		animated: true,
		node: "item",
	},
	geom: { type: "box", width: 0.25, height: 1.2, depth: 0.25 },
	type: "tool",
	ejectImpulse: 0.3,
	cooldown: 0,
	animations: "Wrench",

	client() {
		const world = BB.world;

		const scene = world.scene;
		const pathfinding = world.pathfinding;
		const camera = world.client.camera;

		const state = {
			start: null,
			goal: null,
			path: null,
			searchType: "greedy",
			pathfindingHelper: null,
			updateInterval: null,
		};

		const resolution = { width: 0, height: 0 };
		world.dispatcher.addEventListener(NET_CLIENT, "resize", ({ width, height }) => {
			resolution.width = width;
			resolution.height = height;

			if (state.pathfindingHelper) {
				state.pathfindingHelper.resize(width, height);
			}
		});

		const updatePath = () => {
			if (!state.start || !state.goal || !state.searchType || !state.pathfindingHelper) return;

			const result = pathfinding.findPath({
				start: state.start,
				goal: state.goal,
				searchType: state.searchType,
			});

			state.path = result.path;

			if (!result.success) {
				state.pathfindingHelper.clearPath();
			} else {
				state.pathfindingHelper.setPath(result.path, result.intermediates.explored);
			}
		};

		world.dispatcher.addEventListener(NET_CLIENT, "createentity", ({ entity }) => {
			if (entity.def !== "ItemPathTester") return;

			if (!state.pathfindingHelper) {
				const pathfindingHelper = new PathfindingHelper();
				pathfindingHelper.resize(resolution.width, resolution.height);

				scene.add(pathfindingHelper);
				state.pathfindingHelper = pathfindingHelper;
			}

			state.updateInterval = setInterval(() => {
				updatePath();
			}, 1000);

			entity.modding.state.dispatcher.addEventListener(NET_CLIENT, "use", ({ primary, secondary }) => {
				const block = camera.target?.viewRaycast?.state.block;
				if (!block) return;

				const position = block.clone();

				if (scene.getShape(position) !== BLOCK_AIR) {
					position.y += 1;
				}

				if (primary) {
					state.start = position;
					state.pathfindingHelper.setStart(position);
				} else if (secondary) {
					state.goal = position;
					state.pathfindingHelper.setGoal(position);
				}

				updatePath();
			});
		});
	},
};
