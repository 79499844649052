import { DEBUG_ENABLED } from "@jamango/generated";
import * as SkyClient from "client/world/fx/Sky.js";
import * as Crosshair from "client/world/hud/Crosshair";
import * as QuestClient from "client/world/Quest";
import * as Resources from "client/Resources";
import { FasterScene } from "base/util/FasterScene.js";
import { PlayerContext } from "client/world/PlayerContext.js";
import { MouseLookCamera } from "client/world/Camera.js";
import { Vignette } from "client/world/hud/Vignette.js";
import { ParticleEngine } from "client/world/fx/ParticleEngine";
import { ParticleEngineV2 } from "client/world/fx/ParticleEngineV2";
import { UI } from "client/dom/UI";
import { NET_CLIENT } from "@jamango/ibs";
import { BB } from "base/BB";
import {
	Audio,
	PositionalAudio,
	AudioListener,
	OrthographicCamera,
	AudioContext,
	MeshBasicMaterial,
	SphereGeometry,
	BoxGeometry,
	CapsuleGeometry,
	BufferGeometry,
} from "three";
import { DebugDrawMesh } from "client/world/debug/DebugDrawMesh.js";
import { DebugDrawLine } from "client/world/debug/DebugDrawLine.js";
import { BatchManager } from "base/world/block/chunk/BatchChunk.js";
import { RenderPipeline } from "client/world/RenderPipeline.js";
import { isNullish } from "@jamango/helpers";
import { LoopbackPeer } from "@jamango/ibs";
import { getPeerMetadata, setPeerMetadata } from "base/util/PeerMetadata";
import * as BulletTrailsClient from "client/world/fx/BulletTrails";
import * as SelectorVfxClient from "client/world/fx/SelectorVfx";
import * as InteractLabelsClient from "client/world/fx/InteractLabels";
import * as GameUIClient from "client/dom/GameWorldUI";
import { BuiltInAssets } from "./BuiltInResources";
import { insertAnimCopies } from "mods/defs/CharacterDefault/Character";
import { wait } from "base/util/Time";
import { netState } from "router/Parallelogram";
import * as BlockTypeRegistryClient from "client/world/block/BlockTypeRegistry";
import * as PencilClient from "client/world/tools/Pencil";
import * as WrenchClient from "client/world/tools/Wrench";

export class WorldClient {
	/**
	 * @type {import('base/world/World').World}
	 */
	base;

	/**
	 * @type {Map<string, any>}
	 */
	assets;

	/**
	 * @type {import('base/util/FasterScene').FasterScene
	 */
	sceneHUD;

	/**
	 * @type {import('client/world/Camera').MouseLookCamera}
	 */
	camera;

	/**
	 * @type {import('ibs').LoopbackPeer}
	 */
	loopbackPeer;

	/** @type {import('client/world/fx/BulletTrails').BulletTrails} */
	bulletTrails;

	/** @type {import('client/world/fx/SelectorVfx').SelectorVfxState} */
	selectorVfx;

	/** @type {import('client/world/block/BlockTypeRegistry').BlockTypeRegistryClientState} */
	blockTypeRegistry;

	/** @type {import('client/world/fx/InteractLabels').InteractLabelsClientState} */
	interactLabels;

	/** @type {import('client/dom/GameWorldUI').GameWorldUIState} */
	gameWorldUI;

	/** @type {ParticleEngine} */
	particleEngine;

	/** @type {ParticleEngineV2} */
	particleEngineV2;

	/** @type {import('client/world/hud/Crosshair').CrosshairState}*/
	crosshair;

	/** @type {import('client/world/tools/Pencil').PencilState} */
	pencil;

	/** @type {import('client/world/tools/Wrench').WrenchState} */
	wrench;

	constructor(base, o) {
		this.base = base;

		this.cacheDistance = o?.cacheDistance ?? 8;

		this.playerContext = new PlayerContext(this.base);

		this.opaqueChunksBatchManager = new BatchManager();
		this.alphaTestedChunksBatchManager = new BatchManager();
		this.translucentChunksBatchManager = new BatchManager();
		this.blockTypeRegistry = BlockTypeRegistryClient.init();
		this.pencil = PencilClient.init();
		this.wrench = WrenchClient.init();
	}

	//STAGE 2
	async downloadAssets() {
		const assets = [...BuiltInAssets];

		for (const def of this.base.defs.values()) {
			if (!isNullish(def.assets)) for (const asset of def.assets) assets.push(asset);
		}

		const requests = [];

		const lazyRequests = [];

		for (const asset of assets) {
			if (Resources.isLazyLoadable(asset.type)) {
				lazyRequests.push({ ...asset });
			} else {
				requests.push({ ...asset });
			}
		}

		await Resources.queue(requests);

		insertAnimCopies();

		setTimeout(() => {
			Resources.queue(lazyRequests);
		}, 1000);
	}

	async downloadLazyAssets(assets) {
		await Resources.queue(assets.map((asset) => ({ ...asset })));
	}

	init() {
		this.listener = new AudioListener();
		this.initMobileAudio();

		this.sceneSky = new FasterScene(); //sky background
		this.sceneFP = new FasterScene();
		this.sceneHUD = new FasterScene(); //2D UI overlay, only contains sprites
		this.sceneOcclusion = new FasterScene();

		// default material for sceneOcclusion
		const material = new MeshBasicMaterial();
		this.sceneOcclusion.overrideMaterial = material;
		this.sceneOcclusion.overrideMaterial.depthWrite = false;
		this.sceneOcclusion.overrideMaterial.colorWrite = false;
		this.sceneOcclusion.overrideMaterial.wireframe = true;

		this.sceneDebug = new FasterScene();
	}

	// Mobile browsers don't allow audio to play unless it's in response to a user action
	// Because of this, we need to play and pause all audio assets when the user taps on the screen so that we can programmatically play them later.
	// For playAtObj, we add empty buffers to unlock them, so when it is used dynamically using Rete.js we can switch out the empty buffers
	// with the sound assets and it will work.
	initMobileAudio() {
		this.audios = new Map();
		this.globalAudio = new Audio(this.listener);

		if (BB.client.inputPoll.isMobileBrowser()) {
			const emptyBuffer = this.listener.context.createBuffer(1, 1, 22050);

			// Global Audio
			this.globalAudio.setBuffer(emptyBuffer);
			this.audios.set("global-audio", this.globalAudio);

			// Audio and Positional Audio assets
			Resources.idToResource.forEach((value, key) => {
				if (value instanceof AudioBuffer) {
					const audio = new Audio(this.listener);
					audio.setBuffer(emptyBuffer);
					this.audios.set(key, audio);

					const positionalAudio = new PositionalAudio(this.listener);
					positionalAudio.setBuffer(emptyBuffer);
					this.audios.set(`${key}-positional`, positionalAudio);
				}
			});

			this.unlockAudio = () => {
				// Add empty buffers to entities so that playAtObj will work
				this.base.entities.forEach((entity) => {
					entity.audio = new PositionalAudio(this.listener);
					entity.audio.setBuffer(emptyBuffer);
					this.audios.set(`${entity.entityID}-audio`, entity.audio);
				});

				this.audios.forEach((audio, _key) => {
					audio.play();
					audio.pause();
				});
			};
		}
	}

	initScene(peerID, player) {
		const loopback = (this.loopbackPeer = new LoopbackPeer(peerID));
		const peerMetadata = {
			username: UI.state.player().username,
			accountID: UI.user.accountId() ?? peerID,
			avatarID: UI.user.avatarId(),

			peerID: loopback.peerID,
			isHost: netState.isHost,
			displayPhotoURL: UI.user.displayPhotoURL(),
			avatarURL: UI.user.avatarThumbnailURL(),
		};

		setPeerMetadata(loopback, peerMetadata);
		this.base.remapPeerPlayer(loopback, player);
		UI.state.permission().setPermissions(getPeerMetadata(loopback).permissions);

		const o = this.base.def;

		this.camera = new MouseLookCamera(this.base, player);
		this.camera2D = new OrthographicCamera(0, 0, 0, 0, 0, 1);
		this.renderCamera = this.camera;

		this.base.onGravityChange();

		this.particleEngine = new ParticleEngine(this.base.scene);
		this.particleEngineV2 = new ParticleEngineV2(this.base.scene);
		this.vignette = new Vignette(this.base, o?.vignette);
		this.crosshair = Crosshair.init();
		this.playerContext.init();

		this.bulletTrails = BulletTrailsClient.init(this.base.scene);
		this.selectorVfx = SelectorVfxClient.init(this.base.scene);
		this.interactLabels = InteractLabelsClient.init();
		this.gameWorldUI = GameUIClient.init();

		this.renderPipeline = new RenderPipeline({
			world: this,
			renderer: BB.client.renderer,
			camera: this.renderCamera,
			camera2D: this.camera2D,
			sceneDebug: this.sceneDebug,
			sceneSky: this.sceneSky,
			sceneFP: this.sceneFP,
			sceneHUD: this.sceneHUD,
			sceneOcclusion: this.sceneOcclusion,
			sceneWorld: this.base.scene,
			opaqueChunksBatchManager: this.opaqueChunksBatchManager,
			alphaTestedChunksBatchManager: this.alphaTestedChunksBatchManager,
			translucentChunksBatchManager: this.translucentChunksBatchManager,
			lights: [
				this.base.router.sunProbeLink,
				this.base.router.lightGroupLink,
				this.base.router.lensGroupLink,
			],
		});
		this.renderPipeline.init();

		this.base.dispatcher.addEventListener(NET_CLIENT, "itemchange", ({ prvItem, curItem }) => {
			UI.state.item().setEquippedItem(curItem?.def ?? null);
			PencilClient.onItemChange(this.base, prvItem, curItem);
			WrenchClient.onItemChange(this.base, prvItem, curItem);
		});
	}

	getPeerPlayer() {
		return this.base.peerToPlayer.get(this.loopbackPeer);
	}

	render() {
		this.renderPipeline.render();
	}

	getEquippedItem() {
		return this.getPeerPlayer().getEquippedItem();
	}

	async addDebugShape(debugDrawShape, color, durationInSeconds = Infinity) {
		if (!debugDrawShape.isLine) debugDrawShape.material.wireframe = true; //crazy bug in three.js treats line as a triangle with wireframe enabled
		debugDrawShape.material.color = color;
		debugDrawShape.material.depthWrite = false;

		this.sceneDebug.add(debugDrawShape);
		if (durationInSeconds < Infinity) {
			await wait(durationInSeconds);
			debugDrawShape.dispose();
		}
	}

	addDebugBox(width, height, depth, position, color, durationInSeconds) {
		if (!DEBUG_ENABLED) return;

		const debugBoxMesh = new DebugDrawMesh(new BoxGeometry(width, height, depth));
		debugBoxMesh.position.copy(position);

		this.addDebugShape(debugBoxMesh, color, durationInSeconds);
	}

	addDebugSphere(radius, position, color, durationInSeconds) {
		if (!DEBUG_ENABLED) return;

		const debugSphereMesh = new DebugDrawMesh(new SphereGeometry(radius));
		debugSphereMesh.position.copy(position);

		this.addDebugShape(debugSphereMesh, color, durationInSeconds);
	}

	addDebugCapsule(radius, length, position, color, durationInSeconds) {
		if (!DEBUG_ENABLED) return;

		const debugCapsuleMesh = new DebugDrawMesh(new CapsuleGeometry(radius, length));
		debugCapsuleMesh.position.set(position.x, position.y, position.z);

		this.addDebugShape(debugCapsuleMesh, color, durationInSeconds);
	}

	addDebugLine(startPosition, endPosition, color, durationInSeconds) {
		if (!DEBUG_ENABLED) return;

		const debugLine = new DebugDrawLine(new BufferGeometry().setFromPoints([startPosition, endPosition]));

		this.addDebugShape(debugLine, color, durationInSeconds);
	}

	resize(w, h) {
		if (!this.base.router.initComplete) return;

		const c = this.camera2D;
		c.right = w;
		c.top = h;
		c.updateProjectionMatrix();

		this.base.scene.water?.client.onresize(w, h);
		this.particleEngine.onresize(h);
		this.renderPipeline.setSize(w, h);

		for (const e of this.base.entities) {
			if (e.type.def.isItem) {
				e.onresize();
			}
		}

		this.base.dispatcher.dispatchEvent(NET_CLIENT, {
			type: "resize",
			width: w,
			height: h,
		});
	}

	dispose() {
		for (let i = this.base.entities.length - 1; i >= 0; i--) this.base.entities[i].dispose(true);

		this.vignette.dispose();
		this.particleEngine.dispose();
		this.camera.dispose();
		this.playerContext.dispose();

		BulletTrailsClient.dispose(this.bulletTrails);
		SelectorVfxClient.dispose(this.selectorVfx);
		BlockTypeRegistryClient.dispose(this.blockTypeRegistry);

		SkyClient.dispose(this.base.sky);
		this.base.scene.dispose();

		this.base.hudPopup.client.clear();
		QuestClient.dispose(this.base.quest);

		this.renderPipeline.dispose();

		this.listener.context.close();
		AudioContext.setContext(undefined);

		Resources.dispose();

		BB.client.hud.dispose();
	}
}
