import { node } from "base/rete/Types";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { addWhoCanTrigger } from "base/rete/NodeSharedUtil";
import type { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import type { Entity } from "base/world/entity/Entity";

const proximityTrackOptions = (
	inputs: ReturnType<typeof addWhoCanTrigger> & {
		entity: Entity;
		radius: number;
		viewAngle: number;
	},
) => {
	return {
		inRange: [],
		radius: inputs.radius,
		viewAngle: inputs.viewAngle,
		canTrigger: inputs.whoCanTrigger,
	};
};

export const ENTITY_TRIGGERS = [
	node({
		id: "cee68a1b-9ebf-46ce-99db-eac490d75ff0",
		name: "On Entity Damaged",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when an entity loses health.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Victim", type: "entity" },
			attacker: { name: "Attacker", type: "entity" },
			amount: { name: "Amount", type: "number" },
			killed: { name: "Fatality", type: "boolean" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},

		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				entity: scope[nodeId].entity,
				attacker: scope[nodeId].attacker,
				amount: scope[nodeId].amount,
				killed: scope[nodeId].killed,
			};
		},
	}),
	node({
		id: "0001-00-0002",
		name: "On Entity Killed",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when an entity kills another entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Killer", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Killer", type: "entity" },
			victim: { name: "Victim", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity, victim: scope[nodeId].victim };
		},
	}),
	node({
		id: "0001-00-0003",
		name: "On Entity Death",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when the given entity dies. There will not always be a killer entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Victim", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Victim", type: "entity" },
			killer: { name: "Killer", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity, killer: scope[nodeId].killer };
		},
	}),
	node({
		id: "0001-00-0004",
		name: "On Character Enter Proximity",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when a character enters the proximity of a set entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			radius: {
				name: "Radius",
				type: "number",
				control: "number",
				config: { defaultValue: 5 },
			},
			viewAngle: {
				name: "View Angle",
				type: "number",
				control: "number",
				config: { defaultValue: 360 },
			},
			...addWhoCanTrigger(),
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Input entity", type: "entity" },
			character: { name: "Character", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined)
				return { key: inputs.entity.entityID, options: proximityTrackOptions(inputs as any) };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity, character: scope[nodeId].character };
		},
	}),
	node({
		id: "0001-00-0005",
		name: "On Character Leave Proximity",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when a character exits the proximity of a set entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			radius: {
				name: "Radius",
				type: "number",
				control: "number",
				config: { defaultValue: 5 },
			},
			viewAngle: {
				name: "View Angle",
				type: "number",
				control: "number",
				config: { defaultValue: 360 },
			},
			...addWhoCanTrigger(),
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Input entity", type: "entity" },
			character: { name: "Character", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined)
				return { key: inputs.entity.entityID, options: proximityTrackOptions(inputs as any) };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity, character: scope[nodeId].character };
		},
	}),
	node({
		id: "0001-00-0006",
		name: "On Interact With Entity",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description:
			"Triggered when a player interacts with another entity. Currently only works with items and NPC's",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Target entity", type: "entity" },
			label: {
				name: "Label Text",
				type: "string",
				control: "string",
				optional: true,
				config: {
					defaultValue: "Interact",
				},
			},
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Target entity", type: "entity" },
			triggeredBy: { name: "Triggered by", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			const entity = inputs.entity as PhysicalEntity;
			if (entity === undefined) return;

			if (inputs.label !== undefined) {
				entity.setInteract(true, inputs.label);
			}

			return { key: entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity, triggeredBy: scope[nodeId].triggeredBy };
		},
	}),
	node({
		id: "0007-00-0004",
		name: "On Character Respawn",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when a character respawns.",
		keywords: ["Entity", "Player"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Target entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { entity: scope[nodeId].entity };
		},
	}),
	node({
		id: "2e724f22-cac3-49b2-815c-c1a0c11f9852",
		name: "On Character Equip Item",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when a character equips or unequips an item.",
		keywords: ["Entity", "Player", "Item"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Target character", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			character: { name: "Character", type: "entity" },
			curItem: { name: "Current Item", type: "entity" },
			prvItem: { name: "Previous Item", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				character: scope[nodeId].character,
				curItem: scope[nodeId].curItem,
				prvItem: scope[nodeId].prvItem,
			};
		},
	}),
];
