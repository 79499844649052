import { BB } from "base/BB";
import { BufferGeometry, BufferAttribute, Vector3, Box3, Sphere } from "three";
import { FreeList } from "./FreeList.js";
import { ShadowMesh } from "client/util/ShadowMesh.js";

const batchSize = 4;
const batchYSize = 2;
const maxDimension = 2;

function createBinaryString(nMask) {
	// nMask must be between -2147483648 and 2147483647
	let nFlag = 0,
		nShifted = nMask,
		sMask = "";
	while (nFlag < 32) {
		nFlag++;
		sMask += String(nShifted >>> 31);
		nShifted <<= 1;
	}
	return sMask;
}

function getIndexString(batchPosX, batchPosY, batchPosZ) {
	const batchIndexInMapX = Math.floor(batchPosX / maxDimension);
	const batchIndexInMapY = Math.floor(batchPosY / maxDimension);
	const batchIndexInMapZ = Math.floor(batchPosZ / maxDimension);

	const xString = createBinaryString(batchIndexInMapX);
	const yString = createBinaryString(batchIndexInMapY);
	const zString = createBinaryString(batchIndexInMapZ);

	return xString + yString + zString;
}

/*
    Simplified version of BatchedMesh.js to static meshes for more optimizations.

    Dynamic batch in:
        https://github.com/takahirox/three.js/blob/cd1224cb9c9d9f4fe009caefa7d58170a1f8f7d5/examples/jsm/objects/BatchedMesh.js
*/

export class StaticBatch extends ShadowMesh {
	constructor(maxGeometryCount, maxVertexCount, maxIndexCount = maxVertexCount * 2, material) {
		super(new BufferGeometry(), material);

		this.vertexFreeList = null;
		this.indexFreeList = null;

		this.lastOffset = 0;

		this.initialized = false;

		this.maxGeometryCount = maxGeometryCount;
		this.maxVertexCount = maxVertexCount;
		this.maxIndexCount = maxIndexCount;

		this.geometryCount = 0;
		this.vertexCount = 0;
		this.indexCount = 0;
		this.full = false;

		this.matrixAutoUpdate = false;
	}

	getGeometryCount() {
		return this.geometryCount;
	}

	getVertexCount() {
		return this.vertexCount;
	}

	getIndexCount() {
		return this.indexCount;
	}

	setDataAttributFromPointer(pointer, dst, src) {
		// Set array for offset in pointer
		dst.set(src.array, pointer.offset * dst.itemSize);
		// add uptate range from pointer to src.count * src.itemSize
		dst.addUpdateRange(pointer.offset * dst.itemSize, src.count * src.itemSize);
		dst.needsUpdate = true;
	}

	setDataArrayAttributFromPointer(pointer, dst, srcArray) {
		// Set array for offset in pointer
		dst.set(srcArray, pointer.offset * dst.itemSize);
		// add uptate range from pointer to srcArray.length
		dst.addUpdateRange(pointer.offset * dst.itemSize, srcArray.length);
		dst.needsUpdate = true;
	}

	setDataIndexFromPointer(pointer, dst, src, offset) {
		const firstIndex = pointer.offset;
		for (let i = 0; i < src.count; i++) {
			dst.setX(firstIndex + i, offset + src.getX(i));
		}

		dst.addUpdateRange(firstIndex, src.count);
		dst.needsUpdate = true;
	}

	setDataArrayIndexFromPointer(pointer, dst, srcArray, offset) {
		const firstIndex = pointer.offset;
		for (let i = 0; i < srcArray.length; i++) {
			dst.setX(firstIndex + i, offset + srcArray[i]);
		}

		dst.addUpdateRange(firstIndex, srcArray.length);
		dst.needsUpdate = true;
	}

	clearGeometry(meshPointers) {
		if (meshPointers === null) {
			return;
		}

		// In gpu index -1 return always zero.
		// (v3(0), v3(0), v3(0)) is degenerated triangled (discard)

		let pointer = meshPointers.pointers["index"];
		const firstIndex = pointer.offset;
		let count = pointer.size;
		const dst = this.geometry.getIndex();
		for (let i = 0; i < count; i++) {
			dst.setX(firstIndex + i, -1);
		}

		dst.addUpdateRange(firstIndex, count);
		dst.needsUpdate = true;

		// Free memory in dynamic allocator
		this.indexFreeList.free(pointer);
		this.indexCount -= count;

		// Remove batch statistics
		pointer = meshPointers.pointers["vertex"];
		this.vertexFreeList.free(pointer);
		count = pointer.size;
		this.vertexCount -= count;

		this.geometryCount--;
		this.full = false;
	}

	setAttribute(pointer, attr, array) {
		if (attr === "index") {
			const dstAttribute = this.geometry.getIndex();
			const offSet = pointer.pointers["vertex"].offset;
			this.setDataArrayIndexFromPointer(pointer.pointers.index, dstAttribute, array, offSet);
		} else {
			const dstAttribute = this.geometry.getAttribute(attr);
			this.setDataArrayAttributFromPointer(pointer.pointers.vertex, dstAttribute, array);
		}
	}

	// Add Geometry to batch
	applyGeometry(geometry) {
		if (this.full) {
			return null;
		}
		if (this.geometryCount >= this.maxGeometryCount) {
			this.full = true;
			// Full batch
			return null;
		}

		if (!this.initialized) {
			this.vertexFreeList = new FreeList(this.maxVertexCount);
			this.indexFreeList = new FreeList(this.maxIndexCount);

			// Define batch atributtes equals first mesh atributes
			for (const attributeName in geometry.attributes) {
				const srcAttribute = geometry.getAttribute(attributeName);
				const { array, itemSize, normalized } = srcAttribute;

				const dstArray = new array.constructor(this.maxVertexCount * itemSize);
				const dstAttribute = new srcAttribute.constructor(dstArray, itemSize, normalized);
				dstAttribute.setUsage(srcAttribute.usage);

				this.geometry.setAttribute(attributeName, dstAttribute);
				this.geometry.getAttribute(attributeName).isBatch = true;
			}

			// Define if batch is indexed based on firts mesh
			if (geometry.getIndex() !== null) {
				const indexArray =
					this.maxVertexCount > 65536
						? new Uint32Array(this.maxIndexCount)
						: new Uint16Array(this.maxIndexCount);

				this.geometry.setIndex(new BufferAttribute(indexArray, 1));
				this.geometry.getIndex().isBatch = true;
			}

			this.initialized = true;
		}

		// Assuming geometry has position attribute
		const srcPositionAttribute = geometry.getAttribute("position");

		const hasIndex = this.geometry.getIndex() !== null;
		const dstIndex = this.geometry.getIndex();
		const srcIndex = geometry.getIndex();

		this.meamTotal += srcPositionAttribute.count;
		this.meamCount++;
		this.meamMemoryUse = Math.round(this.meamTotal / this.meamCount);

		if (this.vertexCount + srcPositionAttribute.count > this.maxVertexCount) {
			// Full vertices in batch (Alloc next batch)
			this.full = true;
			return null;
		}

		if (this.indexCount + srcIndex.count > this.maxIndexCount) {
			// Full index in batch (Alloc next batch)
			this.full = true;
			return null;
		}

		const meshPointers = {
			pointers: {},
			batch: this,
		};

		const vptr = this.vertexFreeList.alloc(srcPositionAttribute.count);
		if (!vptr.isValid()) {
			// Vertex buffer memory fragmentation (Alloc next batch)
			return null;
		}

		const iptr = this.indexFreeList.alloc(srcIndex.count);
		if (!iptr.isValid()) {
			// Index buffer memory fragmentation (Alloc next batch)
			this.vertexFreeList.free(vptr);
			return null;
		}

		meshPointers.pointers["vertex"] = vptr;

		for (const attributeName in geometry.attributes) {
			const srcAttribute = geometry.getAttribute(attributeName);
			const dstAttribute = this.geometry.getAttribute(attributeName);

			this.setDataAttributFromPointer(vptr, dstAttribute, srcAttribute);
		}

		// Indice do primeiro triangulo

		if (hasIndex) {
			const offSet = meshPointers.pointers["vertex"].offset;
			this.lastOffset = Math.max(iptr.offset + iptr.size, this.lastOffset);

			this.setDataIndexFromPointer(iptr, dstIndex, srcIndex, offSet);
			meshPointers.pointers["index"] = iptr;

			this.indexCount += srcIndex.count;
		}

		this.geometry.setDrawRange(0, this.lastOffset);

		this.vertexCount += srcPositionAttribute.count;
		this.geometryCount++;

		this.frustumCulled = false;

		return meshPointers;
	}

	optimize() {
		return this;
	}

	dispose() {
		this.geometry.dispose();
		return this;
	}
}

export class BatchManager {
	batches = new Set();
	batchArraysMap = new Map();
	generatedIndicesArraysMap = new Map();
	generatedBatchArrayIndices = [];
	currentScene;

	applyGeometry(chunkSize, scene, geometry, chunk, material, layer) {
		let ptr = null;

		/* Reset batch manager. The memory will still be freed because the pointers have references to the batches that allocated it */
		if (this.currentScene !== scene) {
			this.currentScene = scene;

			for (let j = 0; j < this.generatedBatchArrayIndices.length; j++) {
				const batches = this.batchArraysMap.get(this.generatedBatchArrayIndices[j]);
				for (let i = 0; i < batches.length; i++) {
					if (batches[i]) {
						batches[i].removeFromParent();
						batches[i].dispose();
					}
				}

				this.batches.clear();
			}

			const defaultIndex = getIndexString(0, 0, 0);
			this.batchArraysMap.set(
				defaultIndex,
				new Array(maxDimension * maxDimension * maxDimension).fill(null),
			);
			this.generatedIndicesArraysMap.set(defaultIndex, []);
			this.generatedBatchArrayIndices = [defaultIndex];
		}

		const batchPosX = Math.floor(chunk.position.x / batchSize); // + maxHalfDimension;
		const batchPosY = Math.floor(chunk.position.y / batchYSize); // + maxHalfDimension;
		const batchPosZ = Math.floor(chunk.position.z / batchSize); // + maxHalfDimension;

		const batchIndexInMap = getIndexString(batchPosX, batchPosY, batchPosZ);
		let batchesArray = null;
		let indicesArray = null;

		if (!this.batchArraysMap.has(batchIndexInMap)) {
			this.batchArraysMap.set(
				batchIndexInMap,
				new Array(maxDimension * maxDimension * maxDimension).fill(null),
			);
			this.generatedIndicesArraysMap.set(batchIndexInMap, []);
			this.generatedBatchArrayIndices.push(batchIndexInMap);
		}

		batchesArray = this.batchArraysMap.get(batchIndexInMap);
		indicesArray = this.generatedIndicesArraysMap.get(batchIndexInMap);

		const batchIndexInArrayX = Math.abs(batchPosX) % maxDimension;
		const batchIndexInArrayY = Math.abs(batchPosY) % maxDimension;
		const batchIndexInArrayZ = Math.abs(batchPosZ) % maxDimension;

		const batchIndex =
			batchIndexInArrayX +
			batchIndexInArrayY * maxDimension +
			batchIndexInArrayZ * maxDimension * maxDimension;

		if (batchesArray[batchIndex]) {
			ptr = batchesArray[batchIndex].applyGeometry(geometry);
			if (ptr !== null) {
				return ptr;
			}
		}

		/* Create new batch */
		// Batch size = 1 Mega vertex per batch
		//var vertexCount = 1024 * 1024;
		const vertexCount = 256 * 256;
		// const chunkSizeDiv2 = chunkSize / 2;
		const maxGeometry = batchSize * batchYSize * batchSize;
		const batch = new StaticBatch(maxGeometry, vertexCount, 2 * vertexCount, material);
		batch.layers.set(layer);
		// batch.batchIndex = new Vector3(batchPosX - maxHalfDimension, batchPosY - maxHalfDimension, batchPosZ - maxHalfDimension);
		batch.batchIndex = new Vector3(batchPosX, batchPosY, batchPosZ);
		batch.indexInArray = batchIndex;
		batch.userData.world = BB.world;
		batch.renderOrder = -1;
		batch.indexOfArrayInMap = batchIndexInMap;
		scene.add(batch);
		batchesArray[batchIndex] = batch;
		indicesArray.push(batchIndex);
		this.batches.add(batch);

		/* Add geometry to new batch */
		ptr = batch.applyGeometry(geometry);

		//var batchPosXAbs = batchPosX - maxHalfDimension;
		//var batchPosYAbs = batchPosY - maxHalfDimension;
		//var batchPosZAbs = batchPosZ - maxHalfDimension;
		const batchPosXAbs = batchPosX;
		const batchPosYAbs = batchPosY;
		const batchPosZAbs = batchPosZ;
		const batchSizeInBlocks = batchSize * chunkSize;
		const batchSizeInBlocksY = batchYSize * chunkSize;
		/*var center = new Vector3(   batchPosXAbs * batchSizeInBlocks + batchSize / 2 * chunkSize,
                                    batchPosYAbs * batchSizeInBlocks + batchSize / 2 * chunkSize,
                                    batchPosZAbs * batchSizeInBlocks + batchSize / 2 * chunkSize);
        var radius = Math.sqrt(batchSize*chunkSizeDiv2*batchSize*chunkSizeDiv2 + batchSize*chunkSizeDiv2*batchSize*chunkSizeDiv2) * 1.2;
        batch.geometry.boundingSphere = new Sphere(center, radius); */

		const boxMin = new Vector3(
			batchPosXAbs * batchSizeInBlocks,
			batchPosYAbs * batchSizeInBlocksY,
			batchPosZAbs * batchSizeInBlocks,
		);
		const boxMax = new Vector3(
			boxMin.x + batchSizeInBlocks,
			boxMin.y + batchSizeInBlocksY,
			boxMin.z + batchSizeInBlocks,
		);
		batch.geometry.boundingBox = new Box3(boxMin, boxMax);
		batch.geometry.boundingSphere = new Sphere();
		batch.geometry.boundingBox.getBoundingSphere(batch.geometry.boundingSphere);

		if (ptr === null) {
			// Geometry size > batch size
			throw "Fatal chunk batch error";
		}

		return ptr;
	}

	clearGeometry(pointer) {
		pointer.batch.clearGeometry(pointer);

		if (pointer.batch.getGeometryCount() === 0) {
			const batches = this.batchArraysMap.get(pointer.batch.indexOfArrayInMap);
			const indices = this.generatedIndicesArraysMap.get(pointer.batch.indexOfArrayInMap);
			const index = batches.indexOf(pointer.batch);
			const indexOfIndex = indices.indexOf(pointer.batch.indexInArray);

			if (index !== -1) {
				batches[index] = null;
				indices.splice(indexOfIndex, 1);
			}

			this.batches.delete(pointer.batch);
			pointer.batch.removeFromParent();
			pointer.batch.geometry.dispose();
			delete pointer.batch;
		}
	}

	setAttribute(pointer, attr, array) {
		pointer.batch.setAttribute(pointer, attr, array);
	}
}
