import { CharacterDefault, PlayerAI, PlayerDefault } from "mods/defs/CharacterDefault/Character.js";
import { ItemPencil } from "mods/defs/ItemPencil.js";
import { ItemNovAssaultRifle, ProjectileNovAssaultRifle } from "mods/defs/ItemNovAssaultRifle.js";
import { ItemNovaGun, ProjectileNovaGun } from "mods/defs/ItemNovaGun.js";
import { ItemNovaRifle, ProjectileNovaRifle } from "mods/defs/ItemNovaRifle.js";
import { ItemPathTester } from "mods/defs/ItemPathTester.js";
import { ItemSpade } from "mods/defs/ItemSpade.js";
import { ItemWrench } from "mods/defs/ItemWrench.js";
import { ItemZapperAssault, ProjectileZapperAssault } from "mods/defs/ItemZapperAssault.js";
import { ItemZapperPistol, ProjectileZapperPistol } from "mods/defs/ItemZapperPistol.js";
import { ItemZapperRifle, ProjectileZapperRifle } from "mods/defs/ItemZapperRifle.js";
import { Text3D } from "mods/defs/Text3D";
import { ParticleSystem } from "mods/defs/ParticleSystem";
import { ItemSpawner } from "mods/defs/ItemSpawner";

/**
 * Returns a built-in definition for the given name.
 *
 * @param {string} name Name of the built-in definition.
 * @returns {any} The built-in definition.
 * @throws {Error} If the built-in definition is not found.
 */
export function getBuiltIn(name) {
	switch (name) {
		case CharacterDefault.name:
			return CharacterDefault;
		case PlayerDefault.name:
			return PlayerDefault;
		case PlayerAI.name:
			return PlayerAI;
		case ItemWrench.name:
			return ItemWrench;
		case ItemPencil.name:
			return ItemPencil;
		case ItemNovaGun.name:
			return ItemNovaGun;
		case ItemNovaRifle.name:
			return ItemNovaRifle;
		case ItemNovAssaultRifle.name:
			return ItemNovAssaultRifle;
		case ItemZapperAssault.name:
			return ItemZapperAssault;
		case ItemZapperRifle.name:
			return ItemZapperRifle;
		case ItemZapperPistol.name:
			return ItemZapperPistol;
		case ItemSpade.name:
			return ItemSpade;
		case ItemSpawner.name:
			return ItemSpawner;
		case ItemPathTester.name:
			return ItemPathTester;
		case ProjectileZapperAssault.name:
			return ProjectileZapperAssault;
		case ProjectileZapperRifle.name:
			return ProjectileZapperRifle;
		case ProjectileZapperPistol.name:
			return ProjectileZapperPistol;
		case ProjectileNovaGun.name:
			return ProjectileNovaGun;
		case ProjectileNovaRifle.name:
			return ProjectileNovaRifle;
		case ProjectileNovAssaultRifle.name:
			return ProjectileNovAssaultRifle;
		case Text3D.name:
			return Text3D;
		case ParticleSystem.name:
			return ParticleSystem;
		default:
			throw Error(`Can't find built-in def: "${name}"`);
	}
}
