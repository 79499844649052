import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import type { Vector3Set } from "base/util/math/VectorStorage";
import type { World } from "base/world/World";
import { WorldEditorStatus } from "base/world/WorldEditor";
import type { BlockGroup } from "base/world/block/BlockGroups";
import { BLOCK_AIR, BLOCK_CUBE, ChunkUtil } from "base/world/block/Util";
import type { Character } from "base/world/entity/Character";
import type { Entity } from "base/world/entity/Entity";
import type { CharacterSelectorComponent } from "base/world/entity/component/CharacterSelector";
import {
	BulkSelectionBlocksBrushState,
	CharacterSculptMode,
	SelectorTargetType,
} from "base/world/entity/component/CharacterSelector";
import { entityIsCharacter, entityIsItem, entityIsProp } from "base/world/entity/component/Type";
import { Layers } from "client/Layers";
import * as GameWorldUI from "client/dom/GameWorldUI";
import { UI } from "client/dom/UI";
import { BoxLineSegmentsGeometry } from "client/util/BoxLineSegmentsGeometry";
import { mergeVerticesByPositions, scaleGeometryAlongNormals } from "client/util/Geometry";
import { OverlayBuilder } from "client/world/block/OverlayBuilder";
import type { BufferGeometry, Material, Scene } from "three";
import {
	Box3,
	BoxGeometry,
	Color,
	DoubleSide,
	EdgesGeometry,
	Group,
	MathUtils,
	Mesh,
	MeshBasicMaterial,
	Vector3,
} from "three";
import { LineMaterial, LineSegmentsGeometry } from "three/examples/jsm/Addons.js";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2.js";

const _vector3 = new Vector3();
const _block = new Vector3();
const _box3 = new Box3();
const _size = new Vector3();
const _offset = new Vector3();
const _overlaySize = new Vector3();
const _color = new Color();
const _quadrant = new Vector3();
const _center = new Vector3();

const COLOR_WHITE = new Color(0xffffff);

const BLOCK_GROUP_VFX_PROXIMITY = 40;
const BLOCK_GROUP_VFX_ELEMENT_PROXIMITY = 20;

const ENTITY_VFX_ELEMENT_PROXIMITY = 20;

const BLOCK_GROUP_OVERLAY_COLOR_HEX = 0xff3300;

const ENTITY_OVERLAY_COLORS = {
	PROP_PROPHECY: 0xda77f2,
	CHARACTER_PROPHECY: 0x9775fa,
	DEFAULT: 0x126cf3,
};

const SELECTOR_OUTLINE_COLORS = {
	default: "black",
	invertSculpt: "red",
};

const SELECTOR_BULK_DEFAULT_COLOR = 0x126cf3;

const BULK_SELECTION_BRUSH_MESH_OPACITY = {
	NO_SELECTION_PLAYER_MOVING: 0.05,
	NO_SELECTION_PLAYER_IDLE: 0.15,
	SELECTION_IN_PROGRESS: 0.22,
	SELECTION_IN_PROGRESS_PULSE: 0.008,
	PREVIEW: 0.22,
	PREVIEW_PULSE: 0.008,
};

const BULK_SELECTION_BRUSH_OUTLINE_OPACITY = {
	DEFAULT: 1,
	NO_SELECTION_PLAYER_MOVING: 0.3,
};

type EntityVfx = {
	overlayMaterial: MeshBasicMaterial;
	overlayColor: number;
	meshes: Mesh[];
	materials: Material[];
	geometries: BufferGeometry[];
	sceneTreeNodeElementId: string;
};

type SelectedBlockVfx = {
	lineMaterial: LineMaterial;
	cubeLineSegments: LineSegments2;
	sculptLineSegments: LineSegments2;
};

type BulkSelectionBlocksVfx = {
	group: Group;
	mesh: Mesh | null;
	outline: LineSegments2 | null;
	material: MeshBasicMaterial;
	bulkSelectionBlocksVersion: number | null;
};

type BulkSelectionBrushVfx = {
	geometry: BoxGeometry;
	material: MeshBasicMaterial;
	lineMaterial: LineMaterial;
	mesh: Mesh;
	outline: LineSegments2;
	group: Group;
	bulkSelectionBrushVersion: number | null;
};

type BlockGroupVfx = {
	labels: string[];
	overlay: Mesh;
	outline: LineSegments2;
};

export const init = (scene: Scene) => {
	// line material shared by block overlays vfx
	const blocksOverlayOutlineMaterial = new LineMaterial({
		color: 0xffffff,
		linewidth: 3,
		polygonOffset: true,
		polygonOffsetFactor: -4,
		polygonOffsetUnits: -10,
		transparent: true,
		depthWrite: true,
	});

	// single block outline
	const selectLineMaterial = new LineMaterial({
		linewidth: 3,
		color: SELECTOR_OUTLINE_COLORS.default,
		polygonOffset: true,
		polygonOffsetFactor: -4,
		polygonOffsetUnits: -10,
	});

	const cubeLineSegments = new LineSegments2(new BoxLineSegmentsGeometry(1, 1, 1), selectLineMaterial);
	cubeLineSegments.layers.set(Layers.SELECTOR);

	const sculptLineSegments = new LineSegments2(new BoxLineSegmentsGeometry(1, 1, 1), selectLineMaterial);
	sculptLineSegments.layers.set(Layers.SELECTOR);

	const selectedBlockVfx: SelectedBlockVfx = {
		lineMaterial: selectLineMaterial,
		cubeLineSegments,
		sculptLineSegments,
	};

	// bulk selection mesh and outline
	const bulkSelectorMaterial = new MeshBasicMaterial({
		transparent: true,
		opacity: 0.3,
		side: DoubleSide,
		depthWrite: false,
		color: SELECTOR_BULK_DEFAULT_COLOR,
	});
	const bulkSelectorSelectionOverlay = new Group();
	bulkSelectorSelectionOverlay.visible = false;
	bulkSelectorSelectionOverlay.layers.set(Layers.SELECTOR);

	const bulkSelectionBlocksVfx: BulkSelectionBlocksVfx = {
		group: bulkSelectorSelectionOverlay,
		mesh: null,
		outline: null,
		material: bulkSelectorMaterial,
		bulkSelectionBlocksVersion: null,
	};

	// bulk selection brush vfx
	const bulkSelectionBrushGeometry = new BoxGeometry();
	const bulkSelectionBrushMaterial = new MeshBasicMaterial({
		transparent: true,
		opacity: BULK_SELECTION_BRUSH_MESH_OPACITY.NO_SELECTION_PLAYER_IDLE,
		side: DoubleSide,
		depthWrite: false,
		polygonOffset: true,
		polygonOffsetFactor: -4,
		polygonOffsetUnits: -10,
	});
	const bulkSelectionBrushMesh = new Mesh(bulkSelectionBrushGeometry, bulkSelectionBrushMaterial);
	bulkSelectionBrushMesh.layers.set(Layers.SELECTOR);

	const bulkSelectionBrushLineMaterial = new LineMaterial({
		color: 0xffffff,
		linewidth: 3,
		polygonOffset: true,
		polygonOffsetFactor: -4,
		polygonOffsetUnits: -10,
		transparent: true,
		depthWrite: true,
	});

	const bulkSelectionBrushOutline = new LineSegments2(
		new BoxLineSegmentsGeometry(1, 1, 1),
		blocksOverlayOutlineMaterial,
	);
	bulkSelectionBrushOutline.layers.set(Layers.SELECTOR);

	const bulkSelectionBrushOverlayGroup = new Group();
	bulkSelectionBrushOverlayGroup.visible = false;
	bulkSelectionBrushOverlayGroup.layers.set(Layers.SELECTOR);

	bulkSelectionBrushOverlayGroup.add(bulkSelectionBrushMesh);
	bulkSelectionBrushOverlayGroup.add(bulkSelectionBrushOutline);

	const bulkSelectionBrushVfx: BulkSelectionBrushVfx = {
		geometry: bulkSelectionBrushGeometry,
		material: bulkSelectionBrushMaterial,
		lineMaterial: bulkSelectionBrushLineMaterial,
		mesh: bulkSelectionBrushMesh,
		outline: bulkSelectionBrushOutline,
		group: bulkSelectionBrushOverlayGroup,
		bulkSelectionBrushVersion: null,
	};

	// entity selector vfx
	const entityIdToVfx = new Map<number, EntityVfx>();

	// block group selector vfx
	const blockGroupIdToVfx = new Map<string, BlockGroupVfx>();
	const dirtyBlockGroups = new Set<string>();

	// add meshes to scene
	scene.add(selectedBlockVfx.cubeLineSegments, selectedBlockVfx.sculptLineSegments);
	scene.add(bulkSelectionBlocksVfx.group);
	scene.add(bulkSelectionBrushVfx.group);

	return {
		entityIdToVfx,
		blockGroupIdToVfx,
		dirtyBlockGroups,
		selectorBlockGroup: null as string | null,
		selectorBlockGroupStartTime: 0,
		blocksOverlayOutlineMaterial,
		selectedBlockVfx,
		bulkSelectionBlocksVfx,
		bulkSelectionBrushVfx,
	};
};

export type SelectorVfxState = ReturnType<typeof init>;

export const markBlockGroupDirty = (state: SelectorVfxState, blockGroupId: string) => {
	state.dirtyBlockGroups.add(blockGroupId);
};

export const markScriptDirty = (world: World, script: string) => {
	for (const group of world.blockGroups.groups.values()) {
		if (group.scripts.some((s) => s.script === script)) {
			markBlockGroupDirty(world.client!.selectorVfx, group.id);
		}
	}
};

const createEntityVfx = (state: SelectorVfxState, entity: Entity) => {
	let color: number | null = null;

	const isProphecy = entity.prophecy?.state.isProphecy;

	if (isProphecy) {
		if (entityIsProp(entity)) {
			color = ENTITY_OVERLAY_COLORS.PROP_PROPHECY;
		} else if (entityIsCharacter(entity)) {
			color = ENTITY_OVERLAY_COLORS.CHARACTER_PROPHECY;
		}
	}

	if (color === null) {
		color = ENTITY_OVERLAY_COLORS.DEFAULT;
	}

	const size = _size.set(0, 0, 0);
	const offset = _offset.set(0, 0, 0);

	if (entityIsProp(entity)) {
		size.copy(entity.propMesh.state.meshSize);
	} else if (entityIsCharacter(entity)) {
		size.set(entity.size.state.radius * 4, entity.size.state.height, entity.size.state.radius * 4);
		offset.set(0, entity.size.def.height * 0.6, 0);
	} else if (entityIsItem(entity)) {
		size.set(
			entity.itemPhysics.def.geom.width,
			entity.itemPhysics.def.geom.height,
			entity.itemPhysics.def.geom.depth,
		).multiply(entity.scale);
	} else {
		// TODO: don't rely on geometry ever
		_box3.setFromObject(entity.object3D);
		_box3.getSize(size);
	}

	const overlaySize = _overlaySize.copy(size);
	overlaySize.addScalar(0.02);

	const geometry = new BoxGeometry(overlaySize.x, overlaySize.y, overlaySize.z);
	const material = new MeshBasicMaterial({
		transparent: true,
		opacity: 0.1,
		color,
		depthWrite: false,
	});

	const mesh = new Mesh(geometry, material);
	mesh.position.add(offset);
	mesh.updateMatrix();
	mesh.matrixAutoUpdate = false;

	entity.object3D.add(mesh);

	const edges = new EdgesGeometry(geometry);
	const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edges);
	const lineMaterial = new LineMaterial({
		color: 0xffffff,
		linewidth: 3,
		polygonOffset: true,
		polygonOffsetUnits: -1,
		polygonOffsetFactor: -4,
	});

	const outline = new LineSegments2(lineGeometry, lineMaterial);
	outline.position.add(offset);
	outline.updateMatrix();
	outline.matrixAutoUpdate = false;

	entity.object3D.add(outline);

	const sceneTreeNode = entity.sceneTree!.state.sceneTreeNode!;
	const prophecy = !!entity.prophecy?.state.isProphecy;

	const element = GameWorldUI.add(
		entity.world.client!.gameWorldUI,
		`${sceneTreeNode}-${entity.entityID}-node-${prophecy ? "prophecy" : "instance"}`,
		"sceneTreeNode",
		{ nodeId: sceneTreeNode, prophecy },
	);
	element.zIndex = 10;

	element.object3D.position.copy(offset);
	entity.object3D.add(element.object3D);

	const nodeElement = element.id;

	state.entityIdToVfx.set(entity.entityID, {
		overlayMaterial: material,
		overlayColor: color,
		meshes: [mesh, outline],
		geometries: [geometry, lineGeometry],
		materials: [material, lineMaterial],
		sceneTreeNodeElementId: nodeElement,
	});
};

const updateEntityVfx = (_state: SelectorVfxState, world: World, entity: Entity, vfx: EntityVfx) => {
	const gameWorldUI = world.client.gameWorldUI;
	const camera = world.client.camera;
	const pencil = world.client.pencil;

	// element visibility
	const distance = camera.position.distanceTo(entity.position);
	const elementVisible =
		distance < ENTITY_VFX_ELEMENT_PROXIMITY && pencil.manipulatingState?.entityId !== entity.entityID;

	const element = GameWorldUI.get(gameWorldUI, vfx.sceneTreeNodeElementId);
	if (element) {
		element.visible = elementVisible;
	}

	// selection color
	_color.set(vfx.overlayColor);

	if (camera.target.selector?.state.selectorEntities.has(entity.entityID)) {
		_color.multiplyScalar(2);
	}

	vfx.overlayMaterial.color.copy(_color);
	vfx.overlayMaterial.needsUpdate = true;
};

const disposeEntityVfx = (
	state: SelectorVfxState,
	gameWorldUI: GameWorldUI.GameWorldUIState | undefined,
	entityID: number,
) => {
	const vfx = state.entityIdToVfx.get(entityID);
	if (!vfx) return;

	for (const mesh of vfx.meshes) {
		mesh.removeFromParent();
	}
	for (const geometry of vfx.geometries) {
		geometry.dispose();
	}
	for (const material of vfx.materials) {
		material.dispose();
	}

	state.entityIdToVfx.delete(entityID);

	if (gameWorldUI) {
		GameWorldUI.remove(gameWorldUI, vfx.sceneTreeNodeElementId);
	}
};

const createBlockGroupVfx = (state: SelectorVfxState, world: World, blockGroup: BlockGroup) => {
	/* create a block model for the overlay */
	const overlayBox3 = new Box3();

	for (const _ of blockGroup.blocks.iterate(_vector3)) {
		overlayBox3.expandByPoint(_vector3);
	}

	const localOverlayBox3 = new Box3().copy(overlayBox3);
	localOverlayBox3.min.sub(overlayBox3.min);
	localOverlayBox3.max.sub(overlayBox3.min);

	const overlayBlocks = new LegacyVectorMap();

	const localPosition = new Vector3();
	for (const _ of blockGroup.blocks.iterate(_vector3)) {
		localPosition.copy(_vector3).sub(overlayBox3.min);

		overlayBlocks.set(localPosition.x, localPosition.y, localPosition.z, {
			shape: BLOCK_CUBE,
		});
	}

	const overlay = OverlayBuilder.createMesh({
		world,
		blocks: overlayBlocks,
		box: localOverlayBox3,
		material: new MeshBasicMaterial({
			color: BLOCK_GROUP_OVERLAY_COLOR_HEX,
			opacity: 0.2,
			transparent: true,
			side: DoubleSide,
			depthWrite: false,
		}),
	});

	overlay.geometry.center();

	overlay.geometry = mergeVerticesByPositions(overlay.geometry, 0.001);
	overlay.geometry.computeVertexNormals();
	overlay.geometry = scaleGeometryAlongNormals(overlay.geometry, 0.01);

	overlay.position.copy(overlayBox3.getCenter(new Vector3()).addScalar(0.5));
	overlay.updateMatrix();
	overlay.matrixAutoUpdate = false;

	world.scene.add(overlay);

	/* create an outline of the blocks */
	const edges = new EdgesGeometry(overlay.geometry);

	const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edges);
	const outline = new LineSegments2(lineGeometry, state.blocksOverlayOutlineMaterial);

	outline.position.copy(overlay.position);
	outline.updateMatrix();
	outline.matrixAutoUpdate = false;

	world.scene.add(outline);

	/* create a label per island */
	const labels: string[] = [];

	const gameWorldUI = world.client!.gameWorldUI;

	for (let i = 0; i < blockGroup.islands.length; i++) {
		const island = blockGroup.islands[i];

		const position = island.bounds.getCenter(new Vector3()).addScalar(0.5);

		const id = `${blockGroup.id}-${i}`;

		const element = GameWorldUI.add(gameWorldUI, id, "blockGroup", blockGroup.id, position);
		element.zIndex = 10;

		labels.push(id);
	}

	const blockGroupVfx: BlockGroupVfx = {
		labels,
		overlay,
		outline,
	};

	state.blockGroupIdToVfx.set(blockGroup.id, blockGroupVfx);
};

const disposeBlockGroupVfx = (
	state: SelectorVfxState,
	gameWorldUI: GameWorldUI.GameWorldUIState | undefined,
	blockGroupId: string,
) => {
	const vfx = state.blockGroupIdToVfx.get(blockGroupId);
	if (!vfx) return;

	vfx.overlay.removeFromParent();
	vfx.overlay.geometry.dispose();
	(vfx.overlay.material as MeshBasicMaterial).dispose();

	vfx.outline.removeFromParent();
	vfx.outline.geometry.dispose();

	if (gameWorldUI) {
		for (const label of vfx.labels) {
			GameWorldUI.remove(gameWorldUI, label);
		}
	}

	state.blockGroupIdToVfx.delete(blockGroupId);
};

// TODO: this impl sucks, ported over from Selector.js, needs optimisation!
const createBlocksOverlay = (
	world: World,
	blocks: Vector3Set,
	material: Material,
	outlineMaterial: LineMaterial,
) => {
	const overlayBlocks = new LegacyVectorMap();
	const overlayBox3 = new Box3();

	for (const position of blocks.iterate(_block)) {
		overlayBox3.expandByPoint(position);
	}

	const localOverlayBox3 = new Box3().copy(overlayBox3);
	localOverlayBox3.min.sub(overlayBox3.min);
	localOverlayBox3.max.sub(overlayBox3.min);

	const localPosition = new Vector3();

	for (const position of blocks.iterate(_block)) {
		localPosition.copy(position).sub(overlayBox3.min);

		overlayBlocks.set(localPosition.x, localPosition.y, localPosition.z, {
			shape: BLOCK_CUBE,
		});
	}

	const overlayMesh = OverlayBuilder.createMesh({
		world,
		blocks: overlayBlocks,
		box: localOverlayBox3,
		material,
	});

	overlayMesh.geometry.center();

	overlayMesh.geometry = mergeVerticesByPositions(overlayMesh.geometry, 0.001);
	overlayMesh.geometry.computeVertexNormals();
	overlayMesh.geometry = scaleGeometryAlongNormals(overlayMesh.geometry, 0.01);

	overlayMesh.position.copy(overlayBox3.getCenter(new Vector3()).addScalar(0.5));

	/* create an outline of the blocks */
	const edges = new EdgesGeometry(overlayMesh.geometry);
	const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edges);
	const overlayOutline = new LineSegments2(lineGeometry, outlineMaterial);

	overlayOutline.position.copy(overlayMesh.position);
	overlayOutline.quaternion.copy(overlayMesh.quaternion);
	overlayOutline.scale.copy(overlayMesh.scale);

	return { overlayMesh, overlayOutline };
};

const updateBlockBulkSelectionVfx = (
	state: SelectorVfxState,
	world: World,
	selector: CharacterSelectorComponent,
) => {
	const needsUpdate =
		state.bulkSelectionBlocksVfx.bulkSelectionBlocksVersion !== selector.state.bulkSelectionBlocksVersion;
	if (!needsUpdate) return;

	const vfx = state.bulkSelectionBlocksVfx;

	if (vfx.mesh) {
		vfx.mesh.removeFromParent();
		vfx.mesh.geometry.dispose();
		vfx.mesh = null;
	}

	if (vfx.outline) {
		vfx.outline.removeFromParent();
		vfx.outline.geometry.dispose();
		vfx.outline = null;
	}

	if (selector.state.bulkSelectionBlocks.size() <= 0) {
		vfx.group.visible = false;
		return;
	}

	vfx.group.visible = true;

	const { overlayMesh, overlayOutline } = createBlocksOverlay(
		world,
		selector.state.bulkSelectionBlocks,
		vfx.material,
		state.blocksOverlayOutlineMaterial,
	);

	overlayMesh.layers.set(Layers.SELECTOR);
	overlayOutline.layers.set(Layers.SELECTOR);

	vfx.group.add(overlayMesh);
	vfx.group.add(overlayOutline);

	vfx.mesh = overlayMesh;
	vfx.outline = overlayOutline;

	vfx.bulkSelectionBlocksVersion = selector.state.bulkSelectionBlocksVersion;
};

const _zFightingOffset = new Vector3();

const updateBlockBulkSelectionBrushVfx = (
	state: BulkSelectionBrushVfx,
	world: World,
	character: Character,
	selector: CharacterSelectorComponent,
	dt: number,
	time: number,
) => {
	const needsUpdate = state.bulkSelectionBrushVersion !== selector.state.bulkSelectionBlocksBrushVersion;

	if (needsUpdate) {
		state.bulkSelectionBrushVersion = selector.state.bulkSelectionBlocksBrushVersion;

		if (selector.state.bulkSelectionBlocksBrushState === BulkSelectionBlocksBrushState.DISABLED) {
			state.group.visible = false;
			return;
		}

		const bulkSelectionBlocksBrush = selector.state.bulkSelectionBlocksBrush;

		bulkSelectionBlocksBrush.getCenter(state.mesh.position).addScalar(0.5);
		bulkSelectionBlocksBrush.getSize(state.mesh.scale).addScalar(1);

		state.outline.rotation.set(0, 0, 0);
		state.outline.position.copy(bulkSelectionBlocksBrush.min);
		state.outline.scale.copy(bulkSelectionBlocksBrush.max).sub(bulkSelectionBlocksBrush.min).addScalar(1);

		// move slightly in the direction of the player as a scrappy z-fighting mitigation
		_zFightingOffset.copy(world.client.camera.position).sub(state.mesh.position).normalize().multiplyScalar(0.01);
		state.mesh.position.add(_zFightingOffset);
		state.outline.position.add(_zFightingOffset);

		state.group.visible = true;
	}

	if (state.group.visible) {
		const bulkSelectionBlocksBrushState = selector.state.bulkSelectionBlocksBrushState;
		// update the bulk selector brush visuals if it's active
		if (bulkSelectionBlocksBrushState !== BulkSelectionBlocksBrushState.DISABLED) {
			// vary the opacity of the bulk selector brush based on the current mode
			let opacityTarget = BULK_SELECTION_BRUSH_MESH_OPACITY.NO_SELECTION_PLAYER_IDLE;
			let outlineOpacityTarget = BULK_SELECTION_BRUSH_OUTLINE_OPACITY.DEFAULT;
			let opacityPulseRange = 0;
			if (bulkSelectionBlocksBrushState === BulkSelectionBlocksBrushState.SELECTING_FIRST_CORNER) {
				if (character.type.def.isCharacter && character.movement.state.isIdle) {
					opacityTarget = BULK_SELECTION_BRUSH_MESH_OPACITY.NO_SELECTION_PLAYER_IDLE;
				} else {
					opacityTarget = BULK_SELECTION_BRUSH_MESH_OPACITY.NO_SELECTION_PLAYER_MOVING;
					outlineOpacityTarget = BULK_SELECTION_BRUSH_OUTLINE_OPACITY.NO_SELECTION_PLAYER_MOVING;
				}
			} else if (
				bulkSelectionBlocksBrushState === BulkSelectionBlocksBrushState.SELECTING_SECOND_CORNER
			) {
				opacityTarget = BULK_SELECTION_BRUSH_MESH_OPACITY.SELECTION_IN_PROGRESS;
				opacityPulseRange = BULK_SELECTION_BRUSH_MESH_OPACITY.SELECTION_IN_PROGRESS_PULSE;
			} else if (bulkSelectionBlocksBrushState === BulkSelectionBlocksBrushState.PREVIEWING) {
				opacityTarget = BULK_SELECTION_BRUSH_MESH_OPACITY.PREVIEW;
				opacityPulseRange = BULK_SELECTION_BRUSH_MESH_OPACITY.PREVIEW_PULSE;
			}

			const opacityInterpolationFactor = Math.pow(0.1, dt) * 0.15;
			const lerpedOpacity = MathUtils.lerp(
				state.material.opacity,
				opacityTarget,
				opacityInterpolationFactor,
			);
			const outlineLerpedOpacity = MathUtils.lerp(
				state.lineMaterial.opacity,
				outlineOpacityTarget,
				opacityInterpolationFactor,
			);
			const opacityPulse = opacityPulseRange ? Math.sin(time * 6) * opacityPulseRange : 0;

			state.material.opacity = lerpedOpacity + opacityPulse;
			state.lineMaterial.opacity = outlineLerpedOpacity;
		}
	}
};

const _hitPos = new Vector3();

const updateSelectedBlockVfx = (state: SelectedBlockVfx, world: World, character: Character) => {
	const viewRaycast = character.viewRaycast;
	const selector = character.selector;
	const inSculptContext = world.client.playerContext.inSculptContext;

	const hitBlock = viewRaycast.state.block;
	const hitShape = hitBlock !== null ? world.scene.getShape(hitBlock) : BLOCK_AIR;
	const hitAir = hitShape === BLOCK_AIR;

	const visible =
		!!hitBlock &&
		world.client.pencil.manipulatingState?.entityId === undefined &&
		selector.state.bulkSelectionBlocksBrushState === BulkSelectionBlocksBrushState.DISABLED &&
		selector.state.bulkSelectionBlocks.size() <= 0;

	const sculptVisible =
		visible && inSculptContext && selector.state.sculptMode !== CharacterSculptMode.CUBE && !hitAir;

	state.cubeLineSegments.visible = visible;
	state.sculptLineSegments.visible = sculptVisible;

	const sculptMode = selector.state.sculptMode;

	if (!visible) return;

	const hitSide = viewRaycast.state.blockHitSide;
	const blockHitPos = viewRaycast.state.blockHitPos!;

	// update the block selector visuals
	state.cubeLineSegments.position.copy(viewRaycast.state.block!);
	state.cubeLineSegments.rotation.set(0, 0, 0);
	state.cubeLineSegments.scale.set(1, 1, 1);
	state.cubeLineSegments.visible = true;

	// place the sculpt selector box if in sculpt mode and not selecting air
	if (sculptVisible) {
		state.sculptLineSegments.visible = true;

		state.sculptLineSegments.position.copy(hitBlock);
		state.sculptLineSegments.rotation.set(0, 0, 0);
		state.sculptLineSegments.scale.set(1, 1, 1);

		const quadrant = _quadrant.set(0, 0, 0);

		const hitPosUnrot = ChunkUtil.unrotateVec(hitSide, _hitPos.copy(blockHitPos), true);

		if (hitPosUnrot.x > 0.5) quadrant.x += 0.5;
		if (hitPosUnrot.z > 0.5) quadrant.z += 0.5;

		state.sculptLineSegments.position.add(ChunkUtil.rotateVec(hitSide, quadrant, true));
		ChunkUtil.rotateVec(hitSide, state.sculptLineSegments.scale.set(0.5, 1, 0.5), false);

		// make scale positive, LineMaterial has issues with negative scales
		if (state.sculptLineSegments.scale.x < 0) {
			state.sculptLineSegments.position.x += state.sculptLineSegments.scale.x;
			state.sculptLineSegments.scale.x *= -1;
		}
		if (state.sculptLineSegments.scale.y < 0) {
			state.sculptLineSegments.position.y += state.sculptLineSegments.scale.y;
			state.sculptLineSegments.scale.y *= -1;
		}
		if (state.sculptLineSegments.scale.z < 0) {
			state.sculptLineSegments.position.z += state.sculptLineSegments.scale.z;
			state.sculptLineSegments.scale.z *= -1;
		}
	}

	state.lineMaterial.color.set(
		sculptMode === CharacterSculptMode.INVERT
			? SELECTOR_OUTLINE_COLORS.invertSculpt
			: SELECTOR_OUTLINE_COLORS.default,
	);
};

const updateBlockGroups = (state: SelectorVfxState, world: World) => {
	/* create and dispose block group vfx */
	const gameWorldUI = world.client!.gameWorldUI;

	const canSeeBlockGroups = world.client!.playerContext.inCreatorEventsContext;

	if (canSeeBlockGroups) {
		const blockGroupIds = new Set([
			...state.dirtyBlockGroups,
			...world.blockGroups.groups.keys(),
			...state.blockGroupIdToVfx.keys(),
		]);

		for (const blockGroupId of blockGroupIds) {
			const blockGroup = world.blockGroups.groups.get(blockGroupId);

			const existingVfx = state.blockGroupIdToVfx.get(blockGroupId);
			const needsVfx = blockGroup && blockGroup.blocks.size() !== 0;
			const recreate = existingVfx && needsVfx && state.dirtyBlockGroups.has(blockGroupId);

			if (existingVfx && (!needsVfx || recreate)) {
				disposeBlockGroupVfx(state, gameWorldUI, blockGroupId);
			}

			if ((!existingVfx && needsVfx) || recreate) {
				createBlockGroupVfx(state, world, blockGroup);
			}

			state.dirtyBlockGroups.delete(blockGroupId);
		}
	}

	/* update visibility of block group vfx */
	const camera = world.client!.camera;

	for (const [id, vfx] of state.blockGroupIdToVfx) {
		const group = world.blockGroups.groups.get(id);
		if (!group) continue;

		let visible = canSeeBlockGroups;

		if (visible) {
			const bounds = group.bounds;
			bounds.getCenter(_center);
			bounds.getSize(_size);

			const halfDiagonal = _size.length() / 2;
			const distance = camera.position.distanceTo(_center);

			visible = distance < halfDiagonal + BLOCK_GROUP_VFX_PROXIMITY;
		}

		vfx.overlay.visible = visible;
		vfx.outline.visible = visible;

		for (const elementId of vfx.labels) {
			const element = GameWorldUI.get(gameWorldUI, elementId);
			element.visible =
				visible &&
				element.worldPosition.distanceTo(camera.position) < BLOCK_GROUP_VFX_ELEMENT_PROXIMITY;
		}
	}

	/* selector block group visuals */
	const prevSelectorBlockGroup = state.selectorBlockGroup;
	const selector = world.client!.camera.target?.selector;
	const currentTarget = selector?.state.currentTarget;
	state.selectorBlockGroup = currentTarget?.type === SelectorTargetType.GROUP ? currentTarget.id : null;

	if (prevSelectorBlockGroup && prevSelectorBlockGroup !== state.selectorBlockGroup) {
		const prevVfx = state.blockGroupIdToVfx.get(prevSelectorBlockGroup);

		if (prevVfx) {
			const material = prevVfx.overlay.material as MeshBasicMaterial;
			material.color.set(BLOCK_GROUP_OVERLAY_COLOR_HEX);
		}

		if (state.selectorBlockGroup) {
			state.selectorBlockGroupStartTime = world.time;
		}
	}

	if (state.selectorBlockGroup) {
		const vfx = state.blockGroupIdToVfx.get(state.selectorBlockGroup);
		const group = world.blockGroups.groups.get(state.selectorBlockGroup);

		if (vfx && group) {
			// pulse color of selector block group
			const material = vfx.overlay.material as MeshBasicMaterial;
			const time = world.time - state.selectorBlockGroupStartTime;
			const pulse = Math.sin(time * 4) * 0.2 + 0.5;

			const baseColor = BLOCK_GROUP_OVERLAY_COLOR_HEX;

			_color.setHex(baseColor);
			_color.lerp(COLOR_WHITE, pulse);

			material.color.copy(_color);
		}
	}
};

const updateEntities = (state: SelectorVfxState, world: World) => {
	const canSeeEvents =
		world.client!.playerContext.inCreatorEventsContext ||
		world.editor.status === WorldEditorStatus.EDITING;

	for (const entity of world.entities) {
		const isProphecy = entity.prophecy?.state.isProphecy;

		const needsVfx =
			canSeeEvents &&
			(isProphecy || world.client.camera.target.selector?.state.selectorEntities.has(entity.entityID));

		// set prophecy entity visibility based on whether events can be seen
		const entityVisible = !isProphecy || canSeeEvents;
		entity.object3D.visible = entityVisible;

		const hasVfx = state.entityIdToVfx.has(entity.entityID);

		if (needsVfx && !hasVfx) {
			createEntityVfx(state, entity);
		} else if (!needsVfx && hasVfx) {
			disposeEntityVfx(state, world.client.gameWorldUI, entity.entityID);
		}
	}

	for (const [entityID, vfx] of state.entityIdToVfx.entries()) {
		const entity = world.getEntity(entityID);

		// dispose orphaned vfx
		if (!entity) {
			disposeEntityVfx(state, world.client.gameWorldUI, entityID);
			continue;
		}

		updateEntityVfx(state, world, entity, vfx);
	}
};

const updateSelector = (state: SelectorVfxState, world: World, time: number, dt: number) => {
	const entity = world.client.camera.target;

	// update block and block bulk selection vfx
	if (entityIsCharacter(entity)) {
		const bulkSelectorColor =
			entity.getEquippedItem()?.itemSelectorBehavior.def.bulkSelectorColor ??
			SELECTOR_BULK_DEFAULT_COLOR;
		state.bulkSelectionBlocksVfx.material.color.set(bulkSelectorColor);
		state.bulkSelectionBrushVfx.material.color.set(bulkSelectorColor);

		updateBlockBulkSelectionVfx(state, world, entity.selector);

		updateBlockBulkSelectionBrushVfx(
			state.bulkSelectionBrushVfx,
			world,
			entity as Character,
			entity.selector,
			dt,
			time,
		);

		updateSelectedBlockVfx(state.selectedBlockVfx, world, entity);
	}

	// update stores
	if (entity.viewRaycast && UI.state.controls().selectionMode !== entity.viewRaycast.state.mode) {
		UI.state.controls().onSelectorSelectionModeChange(entity.viewRaycast.state.mode);
	}
};

export const update = (state: SelectorVfxState, world: World, time: number, dt: number) => {
	updateBlockGroups(state, world);
	updateEntities(state, world);
	updateSelector(state, world, time, dt);
};

export const dispose = (state: SelectorVfxState) => {
	for (const entityId of state.entityIdToVfx.keys()) {
		disposeEntityVfx(state, undefined, entityId);
	}

	for (const blockGroupId of state.blockGroupIdToVfx.keys()) {
		disposeBlockGroupVfx(state, undefined, blockGroupId);
	}

	state.blocksOverlayOutlineMaterial.dispose();

	state.selectedBlockVfx.lineMaterial.dispose();
	state.selectedBlockVfx.cubeLineSegments.geometry.dispose();

	state.bulkSelectionBlocksVfx.material.dispose();
	state.bulkSelectionBlocksVfx.mesh?.geometry.dispose();
	state.bulkSelectionBlocksVfx.outline?.geometry.dispose();

	state.bulkSelectionBrushVfx.material.dispose();
	state.bulkSelectionBrushVfx.lineMaterial.dispose();
	state.bulkSelectionBrushVfx.mesh.geometry.dispose();
	state.bulkSelectionBrushVfx.outline.geometry.dispose();
};
