export class ItemTypeComponent {
	name = "itemType";

	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.def = {
			// used to store type as string
			type: o.type,
			// type checkers
			isWeapon: o.weapon ?? false,
			isMelee: o.type === "melee",
			isRanged: o.type === "ranged",
			isBlock: o.type === "block",
			isTool: o.type === "tool",
		};

		this.def.isTool ||= this.def.isBlock;
	}
}
