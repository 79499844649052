import { LoopRepeat } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { angleDistance } from "base/util/math/Math.ts";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsIdle extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsIdle", animMesh, locomotionInput, transitions);

		this.transitionToJoggingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.transitionToTurningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.0;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToFlyingIdleTime = 0.2;

		this.trackMap = new Map();
		ITEMS.forEach((item) => {
			this.trackMap.set(`${item}_Idle`, new AnimationTrack(animMesh, `JMGO_${item}_Idle`, this));
		});

		this.lastTrack = this.trackMap.get("Spade_Idle");
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;
		this.previousItem = transitionData.prvState.inputState.item;

		if (currentItemAnimations) {
			this.lastTrack = this.trackMap.get(`${currentItemAnimations}_Idle`);
		} else {
			this.lastTrack = this.trackMap.get("Spade_Idle");
		}

		this.lastTrack.action.reset();
		this.lastTrack.action.loop = LoopRepeat;

		if (transitionData.prvState) {
			const walkAction = this.mesh.getAction("JMGO_Wrench_Idle");
			if (walkAction) {
				this.lastTrack.action.syncWith(walkAction);
			}

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.lastTrack.action.play();
		this.startFadeIn(transitionData);

		this.updateWeightsInternal();
	}

	// @todo: update main action in parent
	onStateOn() {
		super.onStateOn();

		if (this.lastTrack) this.lastTrack.action.setEffectiveWeight(1.0);
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.updateWeightsInternal();
		if (this.lastTrack) {
			this.lastTrack.action.setEffectiveWeight(this.weight);
		}
		this.previousItem = this.inputState.item;
	}

	updateWeightsInternal() {
		if (this.inputState.item) {
			if (this.previousItem !== this.inputState.item) {
				const currentItemAnimations = this.inputState.item;

				this.lastTrack.action.fadeOut(0.2);

				if (currentItemAnimations) {
					this.lastTrack = this.trackMap.get(`${currentItemAnimations}_Idle`);
				} else {
					this.lastTrack = this.trackMap.get("Spade_Idle");
				}

				if (this.lastTrack) {
					this.lastTrack.action.reset();
					this.lastTrack.action.play();
				}
			}

			this.lastTrack.update();
		}
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("WeaponsCrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;

			return true;
		}

		//transition to turning state
		if (this.inputState.rotateToCamera) {
			//transition to turning state
			const shortestDistance = angleDistance(
				this.inputState.cameraRotation,
				this.inputState.meshRotation,
			);
			const turningLeft = map.get("WeaponsTurningLeft");
			if (shortestDistance > 0 && turningLeft.state !== "transitionOut") {
				outTransitionToState.ptr = turningLeft;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
			const turningRight = map.get("WeaponsTurningRight");
			if (shortestDistance < 0 && turningRight.state !== "transitionOut") {
				outTransitionToState.ptr = turningRight;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
		}

		if (!this.inputState.item) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = 0.2;
			return true;
		}

		// transition to jogging state
		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("WeaponsRunning");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsJogging");
				outTransitionToState.blendTime = this.transitionToWalkingTime;
			}

			return true;
		}

		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		return false;
	}
}
