import { netState } from "router/Parallelogram";

import { angleDistance, PIH, wrapAngle } from "base/util/math/Math.ts";
import {
	LOCOMOTION_MOVEMENTINPUT_BACKWARD,
	LOCOMOTION_MOVEMENTINPUT_FORWARD,
} from "base/world/entity/component/CharacterLocomotionInput";
import { MathUtils } from "three";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterAnimationPPUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	const cam = entity.world.client.camera;
	const firstPerson = cam.is1stPerson() && cam.target === entity;
	const locomotionInput = entity.locomotionInput;

	const lerpFactor = locomotionInput.def.rootLerp * stepDeltaTime;

	//manual bone rotation
	if (!firstPerson) {
		// rotate hips and spine for diagonal movements
		if (locomotionInput.def.rootBone) {
			let movementDirection = NaN;
			if (locomotionInput.state.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
				movementDirection = locomotionInput.state.omnidirectionalAngle - PIH;
			} else if (locomotionInput.state.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
				movementDirection = locomotionInput.state.omnidirectionalAngle + PIH;
			}

			if (!isNaN(movementDirection)) {
				locomotionInput.state.rootRotation = MathUtils.lerp(
					locomotionInput.state.rootRotation,
					movementDirection,
					lerpFactor,
				);

				locomotionInput.def.rootBone.rotation.y = locomotionInput.state.rootRotation;

				locomotionInput.state.rotationThetaPelvis = MathUtils.lerp(
					locomotionInput.state.rotationThetaPelvis,
					locomotionInput.def.pelvisBone.rotation.x - movementDirection * 0.33,
					lerpFactor,
				);

				locomotionInput.def.pelvisBone.rotation.x = locomotionInput.state.rotationThetaPelvis;

				locomotionInput.state.spine01Rotation = MathUtils.lerp(
					locomotionInput.state.spine01Rotation,
					locomotionInput.def.spineBone01.rotation.x - movementDirection * 0.33,
					lerpFactor,
				);

				locomotionInput.def.spineBone01.rotation.x = locomotionInput.state.spine01Rotation;

				locomotionInput.state.spine02Rotation = MathUtils.lerp(
					locomotionInput.state.spine02Rotation,
					locomotionInput.def.spineBone02.rotation.x - movementDirection * 0.33,
					lerpFactor,
				);

				locomotionInput.def.spineBone02.rotation.x = locomotionInput.state.spine02Rotation;
			}
		}

		if (locomotionInput.def.pelvisBone && !locomotionInput.state.isFlying) {
			const hipLerpFactor = stepDeltaTime * 30;

			locomotionInput.state.decalHipRotation.lerp(
				locomotionInput.state.decalHipRotationTarget,
				hipLerpFactor,
			);

			locomotionInput.def.pelvisBone.rotation.y = locomotionInput.state.decalHipRotation.y;
			locomotionInput.def.pelvisBone.rotation.z = locomotionInput.state.decalHipRotation.x;
		} else {
			locomotionInput.state.decalHipRotationTarget.set(0, 0);
		}

		if (
			locomotionInput.def.neckBone &&
			locomotionInput.def.pelvisBone &&
			locomotionInput.def.tpLeftArmBone &&
			locomotionInput.def.tpRightArmBone
		) {
			const neckBone = locomotionInput.def.neckBone;
			const pelvisBone = locomotionInput.def.pelvisBone;
			const leftArmBone = locomotionInput.def.tpLeftArmBone;
			const rightArmBone = locomotionInput.def.tpRightArmBone;

			if (!locomotionInput.state.isFlying && !locomotionInput.state.isDead) {
				locomotionInput.state.rotationNeck = MathUtils.clamp(
					locomotionInput.state.rotation,
					-0.2,
					0.5,
				);

				const armsRotationTarget = MathUtils.clamp(locomotionInput.state.rotation, -0.2, 1);

				if (locomotionInput.state.item) {
					const rightArmOnly =
						locomotionInput.state.item === "Pencil" || locomotionInput.state.item === "Wrench";

					locomotionInput.state.rotationLeftArm = rightArmOnly ? 0 : armsRotationTarget;
					locomotionInput.state.rotationRightArm = armsRotationTarget;
				} else {
					locomotionInput.state.rotationLeftArm = 0;
					locomotionInput.state.rotationRightArm = 0;
				}

				locomotionInput.state.rotationPelvis = MathUtils.clamp(
					locomotionInput.state.rotation - armsRotationTarget,
					-0.7,
					0.5,
				);
			} else {
				locomotionInput.state.rotationNeck = 0;
				locomotionInput.state.rotationLeftArm = 0;
				locomotionInput.state.rotationRightArm = 0;
				locomotionInput.state.rotationPelvis = 0;
			}

			const rotationThetaShortestDistance = angleDistance(
				locomotionInput.state.rotationTheta,
				locomotionInput.state.meshRotation,
			);

			const rotationNeckThetaMaxAngle = 0.7;

			locomotionInput.state.rotationNeckTheta = wrapAngle(
				MathUtils.clamp(
					rotationThetaShortestDistance,
					-rotationNeckThetaMaxAngle,
					rotationNeckThetaMaxAngle,
				),
			);

			// manual bone rotation
			neckBone.rotation.y = locomotionInput.state.rotationNeck;
			neckBone.rotation.x = locomotionInput.state.rotationNeckTheta;

			// additive bone rotation
			locomotionInput.state.beforePPLeftArmBoneX = leftArmBone.rotation.x;
			locomotionInput.state.beforePPRightArmBoneX = rightArmBone.rotation.x;
			locomotionInput.state.beforePPPelvisBoneY = pelvisBone.rotation.y;

			leftArmBone.rotation.x -= locomotionInput.state.rotationLeftArm;
			rightArmBone.rotation.x -= locomotionInput.state.rotationRightArm;
			pelvisBone.rotation.y += locomotionInput.state.rotationPelvis;
		}
	}

	// swapping tool adjustments
	locomotionInput.state.swappingToolAnimationProgress = MathUtils.lerp(
		locomotionInput.state.swappingToolAnimationProgress,
		locomotionInput.state.isSwappingTool ? 1 : 0,
		1 - Math.pow(0.001, stepDeltaTime),
	);

	// lower item when swapping from a block or tool
	const equippedItem = entity.getEquippedItem();
	const equippedItemIsBlock = equippedItem?.itemType.def.isBlock;
	const equippedItemIsTool = equippedItem?.itemType.def.isTool;

	if (firstPerson && equippedItemIsBlock) {
		const loweringItemOffset = locomotionInput.state.swappingToolAnimationProgress * -0.2;

		const blockIdlePositionY = equippedItem.tweenAnimation?.def.idleTransform.y ?? -1.04;

		equippedItem.position.y = blockIdlePositionY + loweringItemOffset;
	} else if (equippedItemIsTool) {
		const rightArmBone = firstPerson
			? locomotionInput.def.fpRightArmBone
			: locomotionInput.def.tpRightArmBone;

		if (rightArmBone) {
			rightArmBone.rotation.x += locomotionInput.state.swappingToolAnimationProgress * 0.3;
		}
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && !entity.canSkipUpdate;
}
