import { getStaticFile } from "@jamango/content-client";
import { DEGRAD } from "base/util/math/Math.ts";
import { Euler } from "three";

export const ItemWrench = {
	name: "ItemWrench",
	display: "Wrench",
	icon: getStaticFile("assets/frontend/items/wrench-small.webp"),
	assets: [
		{
			url: "assets/engine/mods/ItemWrench/mdl-item-wrench.glb",
			id: "mdl-item-wrench",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-wrench",
		animated: true,
		node: "item",
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	geom: { type: "box", width: 0.25, height: 1.2, depth: 0.25 },
	type: "tool",
	ejectImpulse: 0.3,
	animations: "Wrench",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_Wrench_FP_NotAim_Shoot",
			},
			third: "JMGO_Wrench_Shoot_Forward",
		},
	},
	cooldown: 0,
	bulkSelectorColor: 0xff3300,
};
