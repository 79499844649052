import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsJumping extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsJumping", animMesh, locomotionInput, transitions);

		this.transitionToFallingTime = 0.2;
		this.transitionToDeathTime = 0.2;
		this.transitionToLandingTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Jump`,
				new AnimationTrack(animMesh, `JMGO_${item}_Jump_Kickoff_Static_Forward`, this),
			);
		});

		this.jumpingAction = this.trackMap.get("Spade_Jump");
	}

	// @todo: update main action in parent
	onStateOff() {
		super.onStateOff();

		this.jumpingAction.action.setEffectiveWeight(0.0);
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;

		if (currentItemAnimations) {
			this.jumpingAction = this.trackMap.get(`${currentItemAnimations}_Jump`);
		} else {
			this.jumpingAction = this.trackMap.get("Spade_Jump");
		}

		this.jumpingAction.action.reset();
		this.jumpingAction.action.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.jumpingAction.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.jumpingAction.action.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.jumpingAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (!this.inputState.isFalling) {
			if (this.inputState.lastFrameFirstPerson) {
				outTransitionToState.ptr = map.get("Idle");
				outTransitionToState.blendTime = 0.0;
			} else {
				outTransitionToState.ptr = map.get("Landing");
				outTransitionToState.blendTime = this.transitionToLandingTime;
			}

			return true;
		}

		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		if (this.jumpingAction.action.getRemainingTime() < this.transitionToFallingTime) {
			outTransitionToState.ptr = map.get("WeaponsFalling");
			outTransitionToState.blendTime = this.transitionToFallingTime;
			return true;
		}

		return false;
	}
}
