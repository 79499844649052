import { BB } from "base/BB";
import { PI } from "base/util/math/Math.ts";
import {
	DoubleSide,
	BackSide,
	Mesh,
	WebGLRenderTarget,
	NearestFilter,
	RGBADepthPacking,
	NoBlending,
	RepeatWrapping,
	Color,
	PlaneGeometry,
	MeshDepthMaterial,
	MeshPhongMaterial,
	Vector2,
} from "three";
import * as Resources from "client/Resources";

export class FakeWaterClient {
	static assets = [
		{
			id: "tex-water",
			url: "assets/engine/textures/tex-water.png",
			type: "image/png",
		},
		{
			id: "tex-water-normal",
			url: "assets/engine/textures/tex-water-normal.jpg",
			type: "image/jpeg",
		},
	];

	constructor(base, o) {
		this.base = base;

		this.foam = o?.waterFoam ?? "white";

		this.debug = false;
		this.activeRender = false;

		this.viewRatio = 0.5;
		this.threshold = 0.2;
		this.scaler = 70;
		this.time = 1;

		this.resolution = new Vector2(
			window.innerWidth * this.viewRatio,
			window.innerHeight * this.viewRatio,
		);
		this.baseResolution = new Vector2();

		this.geom = new PlaneGeometry(1, 1);
		this.geom.rotateX(PI * -0.5);

		this.initTarget();
		this.initMaterial();

		this.mesh = new Mesh(this.geom, this.mat);
		this.mesh.name = "FakeWaterMesh";
		this.mesh.receiveShadow = true;
		this.mesh.castShadow = false;
		this.mesh.matrixAutoUpdate = false;
		this.mesh.renderOrder = -1;

		base.scene.add(this.mesh);
	}

	initTarget() {
		const renderTarget = new WebGLRenderTarget(this.resolution.x, this.resolution.y);

		renderTarget.texture.minFilter = NearestFilter;
		renderTarget.texture.magFilter = NearestFilter;
		renderTarget.texture.generateMipmaps = false;
		renderTarget.stencilBuffer = false;

		this.renderTarget = renderTarget;
	}

	initMaterial() {
		const depthMaterial = new MeshDepthMaterial();
		depthMaterial.depthPacking = RGBADepthPacking;
		depthMaterial.blending = NoBlending;

		this.depthMaterial = depthMaterial;

		this.map = Resources.get("tex-water");
		this.map.wrapS = this.map.wrapT = RepeatWrapping;
		this.normal = Resources.get("tex-water-normal");
		this.normal.wrapS = this.normal.wrapT = RepeatWrapping;

		this.mat = new MeshPhongMaterial({
			shininess: 60,
			transparent: true,
			side: DoubleSide,
			shadowSide: BackSide,
			opacity: this.base.opacity,
			normalMap: this.normal,
			normalScale: new Vector2(-0.25, -0.25),
			map: this.map,
			depthWrite: false,
			name: "FakeWaterMaterial",
		});

		const self = this;
		this.uniforms = null;
		this.shader = null;

		this.mat.onBeforeCompile = function (shader) {
			const uniforms = shader.uniforms;
			uniforms["activeRender"] = { value: self.activeRender ? 1 : 0 };
			uniforms["viewRatio"] = { value: self.viewRatio };
			uniforms["time"] = { value: 0 };
			uniforms["resolution"] = { value: self.resolution };
			uniforms["threshold"] = { value: self.threshold };
			uniforms["tDepth"] = { value: self.renderTarget.texture };
			uniforms["cameraNear"] = { value: 0 };
			uniforms["cameraFar"] = { value: 0 };
			uniforms["foamColor"] = { value: new Color(self.foam) };
			uniforms["waterColor"] = { value: new Color(self.base.color) };

			let fragment = shader.fragmentShader;

			fragment = fragment.replace(
				"#include <clipping_planes_pars_fragment>",
				`
				#include <clipping_planes_pars_fragment>
				uniform int activeRender;
				uniform int depthPack;
				uniform sampler2D tDepth;
				uniform vec3 waterColor;
				uniform vec3 foamColor;
				uniform float viewRatio;
				uniform float cameraNear;
				uniform float cameraFar;
				uniform float threshold;
				uniform vec2 resolution;

				float getDepth( const in vec2 screenPosition ) {
						return unpackRGBAToDepth( texture2D( tDepth, screenPosition ) );
				}

				float getViewZ( const in float depthhh ) {
						return perspectiveDepthToViewZ( depthhh, cameraNear, cameraFar );
				}

			`,
			);

			fragment = fragment.replace(
				"#include <map_fragment>",
				`

				if( activeRender == 1 ){
					vec2 screenUV = gl_FragCoord.xy * viewRatio / resolution;
					float fragmentLinearEyeDepth = getViewZ( gl_FragCoord.z );
					float linearEyeDepth = getViewZ( getDepth( screenUV ) );

					float diff = saturate( fragmentLinearEyeDepth - linearEyeDepth );

					vec2 displacement = texture2D( map, vMapUv ).rg;
					displacement = ( displacement * 2. - 1. ) * 1.;
					diff += displacement.x;

					diffuseColor = mix( vec4(foamColor, 0.9), vec4(waterColor, opacity), step( threshold, diff ) );
				}
				else diffuseColor = vec4(waterColor, opacity);

			`,
			);

			shader.fragmentShader = fragment;
			shader.name = "FakeWaterShader";
			self.shader = shader;
		};
	}

	activate(b) {
		this.activeRender = b;
		if (this.shader) this.shader.uniforms.activeRender.value = this.activeRender ? 1 : 0;
	}

	onresize(w, h) {
		this.resolution.set(w * this.viewRatio, h * this.viewRatio);
		this.renderTarget.setSize(this.resolution.x, this.resolution.y);
	}

	update(frameDeltaTime) {
		this.time += frameDeltaTime;

		const cam = this.base.scene.world.client.renderCamera;
		const scale = cam.far * 2;
		this.mesh.position.copy(cam.position).y = this.base.height;
		this.mesh.scale.setScalar(scale);
		this.mesh.updateMatrix();

		this.scaler = BB.client.settings.renderDistance * 3;

		const sy = 1 / (scale / this.scaler);
		this.map.repeat.set(this.scaler, this.scaler);
		this.map.offset.set(
			this.mesh.position.x * sy - this.time * 0.05,
			-this.mesh.position.z * sy - this.time * 0.05,
		);

		this.normal.repeat.copy(this.map.repeat);
		this.normal.offset.copy(this.map.offset);

		if (this.shader) {
			this.shader.uniforms.threshold.value = this.threshold;
			this.shader.uniforms.cameraNear.value = cam.near;
			this.shader.uniforms.cameraFar.value = cam.far;
		}
	}

	render() {
		if (!this.activeRender) return;

		const r = BB.client.renderer;
		const world = this.base.scene.world;
		const cam = world.client.camera;

		r.getSize(this.baseResolution);

		//disable some meshes to prevent them being rendered into depth material
		this.mesh.visible = false;
		cam.visible = false;
		this.base.scene.overrideMaterial = this.depthMaterial;

		r.setViewport(0, 0, this.resolution.x, this.resolution.y);
		r.setRenderTarget(this.renderTarget);
		if (!r.autoClear) r.clear();
		r.render(this.base.scene, cam);
		r.setRenderTarget(null);
		r.setViewport(0, 0, this.baseResolution.x, this.baseResolution.y);

		this.base.scene.overrideMaterial = null;
		this.mesh.visible = true;
		cam.visible = true;
	}

	dispose() {
		this.mesh.removeFromParent();
		this.renderTarget.dispose();
		this.mat.dispose();
		this.geom.dispose();
	}
}
