import { md5 } from "base/util/math/MD5.js";

//http://burtleburtle.net/bob/rand/smallprng.html
export class JSFRandom {
	constructor(...seed) {
		//convert the seed to a 64 bit integer by joining the arguments into a string and taking the first 64 bits (half) of that string's md5 hash
		seed = BigInt("0x" + md5(seed.join("")).substr(0, 16));
		this.s = new BigUint64Array([0xf1ea5eedn, seed, seed, seed]);
		for (let i = 0; i < 20; i++) this.cycle();
	}

	cycle() {
		const s = this.s;
		const e = s[0] - ((s[1] << 7n) | (s[1] >> 57n));
		s[0] = s[1] ^ ((s[2] << 13n) | (s[2] >> 51n));
		s[1] = s[2] + ((s[3] << 37n) | (s[3] >> 27n));
		s[2] = s[3] + e;
		s[3] = s[0] + e;
	}

	//returns bigint
	uint64() {
		this.cycle();
		return this.s[3];
	}

	//[0, 1)
	float64() {
		const ret = Number(this.uint64()) / 18446744073709551616; //2 ** 64
		return ret === 1 ? 0 : ret; //prevent from returning 1 due to floating point rounding error
	}

	//[min, max)
	float64Range(min, max) {
		return this.float64() * (max - min) + min;
	}

	//[min, max], both must be integers
	float64IntRange(min, max) {
		return Math.floor(this.float64() * (max - min + 1)) + min;
	}

	//returns bool, p% chance of true, p is in the range [0-1]
	probability(p) {
		return this.float64() < p;
	}

	bool() {
		return this.uint64() < 9223372036854775808n; //2 ** 63
	}
}
