import { UI } from "client/dom/UI";

export class UIPlayer {
	sync(player, type, args) {
		if (!player.hasLocalAuthority() || player.world.client.camera?.target !== player) return;

		if (type === "username") return UI.state.player().setUsername(args);
		else if (type === "flying") return UI.state.movement().setFlying(args);
		else if (type === "crouching") return UI.state.movement().setCrouching(args);
		else if (type === "mode") return UI.state.player().setMode(args);
		else if (type === "health") return UI.state.player().setHealth(args);
		else if (type === "maxHealth") return UI.state.player().setMaxHealth(args);
	}
}
