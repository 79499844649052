import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { Euler, Quaternion, Vector3 } from "three";
import { EULER_ORDERS } from "base/util/math/Math";

const _euler = new Euler();

export const CONVERSION_NODES = [
	node({
		id: "4cb431a7-e8ab-4590-9bd1-aed023f0f04e",
		name: "Convert Euler to Quaternion",
		type: NODE_TYPE_ID.util.conversion,
		description: "Converts a Euler angle to a quaternion.",
		predictable: true,
		inputs: {
			euler: { name: "Euler", type: "euler", control: "euler" },
		},
		outputs: {
			result: { name: "Quaternion", type: "quaternion" },
		},
		resolve(inputs) {
			return { result: new Quaternion().setFromEuler(_euler.copy(inputs.euler)) };
		},
	}),
	node({
		id: "a53edac6-3201-4ed3-b307-34b7600f692b",
		name: "Convert Euler to Vector3",
		type: NODE_TYPE_ID.util.conversion,
		description: "Converts a Euler angle to a vector3 and order string",
		predictable: true,
		inputs: {
			euler: { name: "Euler", type: "euler", control: "euler" },
		},
		outputs: {
			vector3: { name: "Vector 3", type: "vector3" },
			order: { name: "Order", type: "string" },
		},
		resolve(inputs) {
			return {
				vector3: new Vector3(inputs.euler.x, inputs.euler.y, inputs.euler.z),
				order: inputs.euler.order ?? "XYZ",
			};
		},
	}),
	node({
		id: "570939a5-814b-4f49-b8c7-7c6f72a7f580",
		name: "Convert Vector3 to Euler",
		type: NODE_TYPE_ID.util.conversion,
		description: "Converts a Vector3 to Euler with a specified order",
		predictable: true,
		inputs: {
			vector3: { name: "Vector3", type: "vector3", control: "vector3" },
			order: {
				name: "Order",
				type: "string",
				control: "string",
				config: {
					autoSortOptions: () =>
						EULER_ORDERS.map((o) => ({
							label: o,
							value: o,
						})),
				},
			},
		},
		outputs: {
			euler: { name: "Euler", type: "euler" },
		},
		resolve(inputs) {
			return {
				euler: new Euler(inputs.vector3.x, inputs.vector3.y, inputs.vector3.z, inputs.order ?? "XYZ"),
			};
		},
	}),
	node({
		id: "8f5ceda7-3fa4-4b31-a791-eaa61942be3d",
		name: "Convert Quaternion to Euler",
		type: NODE_TYPE_ID.quaternion,
		description: "Converts a quaternion to an Euler angle.",
		predictable: true,
		inputs: {
			quaternion: { name: "Quaternion", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Euler", type: "euler" },
		},
		resolve(inputs) {
			const result = new Euler().setFromQuaternion(inputs.quaternion);
			return { result };
		},
	}),
];
