import { TONE_MAPPING } from "../types";

export const SLIDER_MIN_HOUR = 0;
export const SLIDER_MAX_HOUR = 24;
export const SLIDER_MIN_AZIMUTH = 0;
export const SLIDER_MAX_AZIMUTH = 350;
export const SLIDER_MIN_SUN_INTENSITY = 0;
export const SLIDER_MAX_SUN_INTENSITY = 10;
export const SLIDER_MIN_NIGHT_LUMINOSITY = 0;
export const SLIDER_MAX_NIGHT_LUMINOSITY = 10;
export const SLIDER_MIN_TONE_MAPPING_EXPOSURE = 0;
export const SLIDER_MAX_TONE_MAPPING_EXPOSURE = 10;
export const SLIDER_MIN_SATURATION = 0;
export const SLIDER_MAX_SATURATION = 3;
export const SLIDER_MIN_HAZE = 0;
export const SLIDER_MAX_HAZE = 1;
export const SLIDER_MIN_FOGY = 0;
export const SLIDER_MAX_FOGY = 1;
export const SLIDER_MIN_CLOUD_SIZE = 0;
export const SLIDER_MAX_CLOUD_SIZE = 1;
export const SLIDER_MIN_CLOUD_COVERAGE = 0;
export const SLIDER_MAX_CLOUD_COVERAGE = 1;
export const SLIDER_MIN_CLOUD_DENSITY = 0;
export const SLIDER_MAX_CLOUD_DENSITY = 1;
export const SLIDER_MIN_CLOUD_DISTANCE = 0;
export const SLIDER_MAX_CLOUD_DISTANCE = 1;
export const SLIDER_MIN_SAMPLE = 0;
export const SLIDER_MAX_SAMPLE = 128;
export const SLIDER_MIN_STEP = 0;
export const SLIDER_MAX_STEP = 16;
export const SLIDER_MIN_FOG_NEAR = 0;
export const SLIDER_MAX_FOG_NEAR = 200;
export const SLIDER_MIN_FOG_FAR = 0;
export const SLIDER_MAX_FOG_FAR = 200;

export const DEFAULT_ENVIRONMENT_PRESET = {
	name: "Default",
	preset: {
		general: {
			hour: 17.29,
			azimuth: 21,
		},
		light: {
			sunIntensity: 1.26,
			nightLuminosity: 0.81,
			sunColor: "#ffffff",
		},
		sky: {
			toneMapping: TONE_MAPPING.ACES,
			toneMappingExposure: 1.26,
			saturation: 1.3,
			haze: 0.1,
			fogy: 0,
			cloudSize: 0.29,
			cloudCoverage: 0.56,
			cloudDensity: 1,
			cloudDistance: 0.64,
			sample: 128,
			step: 16,
			skyColor: "#2e9bc8",
			cloudColor: "#fddfae",
			fogColor: "#add7ff",
			groundColor: "#bb7831",
		},
		fog: {
			fogNear: 1,
			fogFar: 200,
		},
	},
};
