import { netState } from "router/Parallelogram";
import { VY } from "../../../util/math/Math";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterOrientationUpdate(entity, stepDeltaTime, input) {
	if (!canUpdateSystem(entity, input)) return;

	entity.quaternion.setFromAxisAngle(VY, input.camera.theta);
	if (netState.isClient && entity.mesh) {
		// rotate the child mesh (body) towards its supposed target according to locomotion
		// counteracts the parent mesh rotation
		const parent = input.camera.theta;
		const goal = entity.locomotionInput.state.meshRotation;
		const final = goal - parent;
		// need to subtract Math.PI/2 because we're facing the wrong way in the default state
		entity.mesh.mesh.rotation.y = final - Math.PI;
		entity.mesh.mesh.updateMatrix();
	}
}

function canUpdateSystem(entity, input) {
	return (
		entity.type.def.isCharacter && !entity.isDead() && !entity.mount.state.parent && input !== undefined
	);
}
