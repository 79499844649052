import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateTurnLeft extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("TurningLeft", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToJoggingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;
	}

	get turnLeftAction() {
		return this.mesh.getAction("JMGO_Unarmed_Turn_Stand_Left");
	}

	startCompute(transitionData) {
		this.turnLeftAction.reset();
		this.turnLeftAction.loop = LoopOnce;
		this.turnLeftAction.setEffectiveTimeScale(2.0);

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.turnLeftAction.play();
		this.startFadeIn(transitionData);
	}

	onStateOff() {}

	compute() {
		this.updateWeight();

		if (this.state === "off") {
			this.turnLeftAction.setEffectiveWeight(0);

			return;
		}

		this.turnLeftAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("CrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (this.turnLeftAction.getRemainingTime() < this.transitionToIdleTime && !this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Running");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("Jogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
			}
			return true;
		}

		return false;
	}
}
