import { OutputPass as THREEOutputPass } from "three/examples/jsm/postprocessing/OutputPass.js";

/**
 * A modified OutputPass that sets alpha to 1.0
 *
 * @see https://github.com/mrdoob/three.js/blob/7a62164566e030611c0e4c92e9747bc005cdab26/examples/jsm/postprocessing/OutputPass.js
 */
export class OutputPass extends THREEOutputPass {
	constructor() {
		super();
		this.material.fragmentShader = this.material.fragmentShader.replace(
			/(\bgl_FragColor\s*=\s*texture2D\([^)]*\);)/g,

			//alpha must be 1.0 otherwise opaque and translucent blocks with the same texture will display differently
			`$1
			gl_FragColor.a = 1.0;`,
		);
	}

	render(renderer, writeBuffer, readBufferTexture) {
		const { _outputColorSpace, toneMapping, toneMappingExposure } = renderer;
		renderer.outputColorSpace = renderer.outputPassOutputColorSpace;
		renderer.toneMapping = renderer.outputPassToneMapping;
		renderer.toneMappingExposure = renderer.outputPassToneMappingExposure;

		super.render(renderer, writeBuffer, { texture: readBufferTexture });

		renderer._outputColorSpace = _outputColorSpace;
		renderer.toneMapping = toneMapping;
		renderer.toneMappingExposure = toneMappingExposure;
	}
}
