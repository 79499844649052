import { z } from "zod";

function zodTemplateLiteral<T extends string>(
	_regex?: RegExp,
	_errorMessage?: string,
): z.ZodType<T, z.ZodTypeDef, T> {
	//TODO
	return z.string().min(1) as unknown as z.ZodType<T, z.ZodTypeDef, T>;
}

function messageSchemaWithError<TChannel extends string, T>(
	_channel: TChannel,
	result: z.ZodType<T, z.ZodTypeDef, T>,
) {
	return z.object({
		channel: zodTemplateLiteral<TChannel>(),
		data: z.union([
			z.object({
				result,
				error: z.null(),
			}),
			z.object({
				result: z.null(),
				error: z.string(),
			}),
		]),
	});
}

export const PubSubMessagesSchema = z.union([
	z.object({
		channel: zodTemplateLiteral<`hello:${string}`>(),
		abc: z.literal(123),
	}),
	z.object({
		channel: zodTemplateLiteral<`users:${string}:profile`>(),
		abc: z.literal(`custom`),
	}),
	z.object({
		channel: zodTemplateLiteral<`world:saving:${string}`>(),
		total: z.number(),
		current: z.number(),
		result: z
			.object({
				shortCode: z.string(),
				version: z.any(),
			})
			.optional(),
		error: z.string().optional(),
	}),
	messageSchemaWithError(
		`package:create:${z.string()}`,
		z.object({
			versionId: z.string(),
			packageId: z.string(),
		}),
	),
	messageSchemaWithError(
		`asset:create:${z.string()}`,
		z.object({
			id: z.string(),
			url: z.string(),
			path: z.string(),
			size: z.number(),
			name: z.string(),
			mimeType: z.string(),
			duration: z.number().optional(),
		}),
	),
	messageSchemaWithError(
		`world:imported:${z.string()}`,
		z.object({
			worldId: z.string(),
			versionId: z.string(),
			shortCode: z.string(),
		}),
	),
	z.object({
		channel: zodTemplateLiteral<`ibs:server:${string}`>(),
		msg: z.discriminatedUnion("type", [
			z.object({
				type: z.literal("ice"),
				senderId: z.string(),
				targetId: z.string(),
				rtc: z.record(z.any()),
			}),
			z.object({
				type: z.literal("full"),
				peerId: z.string(),
			}),
			z.object({
				type: z.literal("offer"),
				peerId: z.string(),
				offer: z.record(z.any()),
			}),
			z.object({
				type: z.literal("answer"),
				peerId: z.string(),
				rtc: z.record(z.any()),
			}),
			z.object({
				type: z.literal("create-peer"),
				peer: z.record(z.any()),
			}),
			z.object({
				type: z.literal("remove-peer"),
				peerId: z.string(),
			}),
			z.object({
				type: z.literal("update-shortcode"),
				shortCode: z.string(),
			}),
		]),
	}),
	z.object({
		channel: zodTemplateLiteral<`ibs:peers`>(),
		msg: z.discriminatedUnion("type", [
			z.object({
				type: z.literal("create-peer"),
				peer: z.record(z.any()),
			}),
			z.object({
				type: z.literal("remove-peer"),
				peerId: z.string(),
			}),
			z.object({
				type: z.literal("update-shortcode"),
				peerId: z.string(),
				shortCode: z.string(),
			}),
			z.object({
				type: z.literal("servers-updated"),
			}),
		]),
	}),
] as const);

export type PubSubMessages = z.infer<typeof PubSubMessagesSchema>;

export type PubSubChannel = PubSubMessages["channel"];
export type PubSubMessageByChannel<TChannel extends PubSubChannel> = Extract<
	PubSubMessages,
	{ channel: TChannel }
>;
