import type { IBlockStructure, JacyContent } from "@jamango/content-client";
import { BlockStructureColliderType } from "@jamango/content-client";
import { PropColliderType } from "@jamango/content-client";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import { createLogger } from "@jamango/helpers";
import type JoltPhysics from "@jamango/jolt-physics/wasm-compat";
import { BB } from "base/BB";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import { DEGRAD } from "base/util/math/Math";
import * as Metrics from "base/world/MetricsManager";
import { findHitBlock } from "base/world/Raycast";
import { type BlockTypeRegistryState, MISSING_BLOCK_TYPE } from "base/world/block/BlockTypeRegistry";
import { ChunkBuilder, type ChunkBuilderPhysicsBuffer } from "base/world/block/Builder";
import { BLOCK_COLLISION_ENABLED, BLOCK_CUBE, ChunkUtil } from "base/world/block/Util";
import type { Chunk } from "base/world/block/chunk/Chunk";
import type { Entity } from "base/world/entity/Entity";
import { CharacterMovementMode } from "base/world/entity/component/CharacterMovement";
import type { PropCollider } from "base/world/entity/component/PropPhysics";
import { entityIsCharacter, entityIsItem, entityIsProp } from "base/world/entity/component/Type";
import type { Quaternion, QuaternionLike, Vector3Like } from "three";
import { Box3, Vector3 } from "three";

/*
 * IMPORTANT README:
 * This is the only file that should interact with the Jolt Physics module directly,
 * excluding only `client/util/JoltDebugRenderer.js` for now, and some other select simple debug code.
 *
 * This is done intentionally to reduce coupling between our chosen physics library and the rest
 * of the engine.
 *
 * When storing emscripten webassembly wrapped objects outside of this file, treat them as
 * if they are only numeric pointers to be passed back into this file for actual handling.
 * This lets us maintain flexibility to reimplement the physics solution again in future much
 * more easily, or to pull this physics code out into another webassembly module that uses
 * Jolt Physics or something else internally.
 */

const logger = createLogger("Physics");

let Jolt: typeof JoltPhysics;

export const JoltModule = {
	Jolt: null! as typeof JoltPhysics,
};

const tmpVariables: unknown[] = [];

const moduleTmp = <T>(obj: T) => {
	tmpVariables.push(obj);
	return obj;
};

export const initJoltModule = async () => {
	/* init the jolt wasm module */
	const module = await import("@jamango/jolt-physics/wasm");
	Jolt = await module.default({ locateFile: () => BB.joltPhysicsWasmUrl });
	JoltModule.Jolt = Jolt;

	/* create temporary variables */
	_jolt_jamango_array_utils = moduleTmp(new Jolt.JamangoArrayUtils());
	_jolt_vec3 = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_direction = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_linearVelocity = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_angularVelocity = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_halfExtents = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_position = moduleTmp(new Jolt.Vec3(0, 0, 0));
	_jolt_r_position = moduleTmp(new Jolt.RVec3(0, 0, 0));
	_jolt_quat = moduleTmp(new Jolt.Quat(0, 0, 0, 1));
	_jolt_scale = moduleTmp(new Jolt.Vec3(1, 1, 1));
	_jolt_baseOffset = moduleTmp(new Jolt.RVec3(0, 0, 0));
	_jolt_ray = moduleTmp(new Jolt.RRayCast());
	_jolt_raySettings = moduleTmp(new Jolt.RayCastSettings());
	_jolt_castRayClosestHitCollisionCollector = moduleTmp(new Jolt.CastRayClosestHitCollisionCollector());
	_jolt_shapecastSettings = moduleTmp(new Jolt.ShapeCastSettings());
	_jolt_castShapeClosestHitCollisionCollector = moduleTmp(new Jolt.CastShapeClosestHitCollisionCollector());
	_jolt_emptyShape = moduleTmp(new Jolt.EmptyShape());

	_jolt_emptyShape.AddRef();
};

export const disposeJoltModule = () => {
	for (const obj of tmpVariables) {
		Jolt.destroy(obj);
	}

	// todo: dispose of jolt wasm instance
	JoltModule.Jolt = null!;
};

// utils
let _jolt_jamango_array_utils: JoltPhysics.JamangoArrayUtils;

// general
let _jolt_vec3: JoltPhysics.Vec3;
let _jolt_direction: JoltPhysics.Vec3;
let _jolt_linearVelocity: JoltPhysics.Vec3;
let _jolt_angularVelocity: JoltPhysics.Vec3;
let _jolt_halfExtents: JoltPhysics.Vec3;
let _jolt_position: JoltPhysics.Vec3;
let _jolt_r_position: JoltPhysics.RVec3;
let _jolt_quat: JoltPhysics.Quat;
let _jolt_scale: JoltPhysics.Vec3;
let _jolt_baseOffset: JoltPhysics.RVec3;

// raycasting
let _jolt_ray: JoltPhysics.RRayCast;
let _jolt_raySettings: JoltPhysics.RayCastSettings;
let _jolt_castRayClosestHitCollisionCollector: JoltPhysics.CastRayClosestHitCollisionCollector;

// shapecasting
let _jolt_shapecastSettings: JoltPhysics.ShapeCastSettings;
let _jolt_castShapeClosestHitCollisionCollector: JoltPhysics.CastShapeClosestHitCollisionCollector;

// shapes
let _jolt_emptyShape: JoltPhysics.EmptyShape;

type RaycastResult = {
	hit: boolean;
	hitPosition: Vector3;
	hitNormal: Vector3;
	body: JoltPhysics.Body | undefined;
	bodyIndexAndSequence: number;
	bodyObjectLayer: PhysicsObjectLayer;
	shapeId: number;
	userData: BodyUserData | undefined;
};

const release = <T extends { Release(): void }>(obj: T) => {
	obj.Release();
};

const destroy = <T>(obj: T) => {
	Jolt.destroy(obj);
};

const destroyShapeResult = (result: JoltPhysics.ShapeResult) => {
	result.Clear();
};

export function initRaycastResult(): RaycastResult {
	return {
		hit: false,
		hitPosition: new Vector3(),
		hitNormal: new Vector3(),
		body: undefined,
		bodyIndexAndSequence: 0,
		bodyObjectLayer: 0,
		shapeId: 0,
		userData: undefined,
	};
}

const _box3 = new Box3();
const _size = new Vector3();
const _vector3 = new Vector3();
const _raycastDirection = new Vector3();
const _groundVelocity = new Vector3();
const _rayOrigin = new Vector3();
const _rayHitPos = new Vector3();
const _raycastResult = initRaycastResult();
const _blockStructureOffset = new Vector3();

const QUATERNION_IDENTITY = { x: 0, y: 0, z: 0, w: 1 };

const BROADPHASE_LAYER_NON_MOVING = 0;
const BROADPHASE_LAYER_MOVING = 1;
const N_BROADPHASE_LAYERS = 2;

export const OBJECT_LAYER_CHUNK = 0;
export const OBJECT_LAYER_CHUNK_COLLISION_DISABLED = 1;
export const OBJECT_LAYER_CHARACTER = 2;
export const OBJECT_LAYER_PROP_MOVING = 3;
export const OBJECT_LAYER_PROP_NON_MOVING = 4;
export const OBJECT_LAYER_ENTITY_COLLISION_DISABLED = 5;
export const OBJECT_LAYER_ITEM = 6;
export const OBJECT_LAYER_SENSOR = 7;
const N_OBJECT_LAYERS = 8;

// used with ObjectLayerPairFilterTable to make a filter for multiple object layers
const PLACEHOLDER_OBJECT_LAYER_FOR_FILTERS = 0;

type PhysicsObjectLayer =
	| typeof OBJECT_LAYER_CHUNK
	| typeof OBJECT_LAYER_CHUNK_COLLISION_DISABLED
	| typeof OBJECT_LAYER_CHARACTER
	| typeof OBJECT_LAYER_PROP_MOVING
	| typeof OBJECT_LAYER_PROP_NON_MOVING
	| typeof OBJECT_LAYER_ENTITY_COLLISION_DISABLED
	| typeof OBJECT_LAYER_ITEM
	| typeof OBJECT_LAYER_SENSOR;

type PhysicsProperties = {
	mass: number;
	friction: number;
	restitution: number;
};

export type BodyUserData = {
	isChunk?: boolean;

	isCharacter?: boolean;
	isEntity?: boolean;
	isItem?: boolean;
	isProp?: boolean;
	entityId?: EntityID;
};

type Contact = {
	body1IndexAndSequence: number;
	body1UserData?: BodyUserData;
	body2IndexAndSequence: number;
	body2UserData?: BodyUserData;
	worldSpaceNormalX: number;
	worldSpaceNormalY: number;
	worldSpaceNormalZ: number;
};

type RemovedContact = {
	body1IndexAndSequence: number;
	body1UserData?: BodyUserData;
	body2IndexAndSequence: number;
	body2UserData?: BodyUserData;
};

type Contacts = {
	added: Contact[];
	persisted: Contact[];
	removed: RemovedContact[];
};

const DEFAULT_CHUNK_MATERIAL: PhysicsProperties = {
	mass: 0,
	friction: 0.5,
	restitution: 0.0,
};

const DEFAULT_ITEM_ENTITY_MATERIAL: PhysicsProperties = {
	mass: 1,
	friction: 0.5,
	restitution: 0.0,
};

export enum MotionType {
	STATIC,
	DYNAMIC,
	KINEMATIC,
}

const mapMotionType = (motionType: MotionType) => {
	switch (motionType) {
		case MotionType.STATIC:
			return Jolt.EMotionType_Static;
		case MotionType.DYNAMIC:
			return Jolt.EMotionType_Dynamic;
		case MotionType.KINEMATIC:
			return Jolt.EMotionType_Kinematic;
		default:
			throw new Error(`Unknown motion type: ${motionType}`);
	}
};

export enum MotionQuality {
	DISCRETE,
	LINEAR_CAST,
}

const mapMotionQuality = (motionQuality: MotionQuality) => {
	switch (motionQuality) {
		case MotionQuality.DISCRETE:
			return Jolt.EMotionQuality_Discrete;
		case MotionQuality.LINEAR_CAST:
			return Jolt.EMotionQuality_LinearCast;
		default:
			throw new Error(`Unknown motion quality: ${motionQuality}`);
	}
};

export enum DegreesOfFreedom {
	ALL = 0b00111111,
	TRANSLATION_X = 0b00000001,
	TRANSLATION_Y = 0b00000010,
	TRANSLATION_Z = 0b00000100,
	ROTATION_X = 0b00001000,
	ROTATION_Y = 0b00010000,
	ROTATION_Z = 0b00100000,
	TRANSLATION = 0b00000111,
	ROTATION = 0b00111000,
}

export const makeDegreesOfFreedom = (
	translationX: boolean,
	translationY: boolean,
	translationZ: boolean,
	rotationX: boolean,
	rotationY: boolean,
	rotationZ: boolean,
) => {
	let dof = 0;

	if (translationX) {
		dof |= DegreesOfFreedom.TRANSLATION_X;
	}
	if (translationY) {
		dof |= DegreesOfFreedom.TRANSLATION_Y;
	}
	if (translationZ) {
		dof |= DegreesOfFreedom.TRANSLATION_Z;
	}
	if (rotationX) {
		dof |= DegreesOfFreedom.ROTATION_X;
	}
	if (rotationY) {
		dof |= DegreesOfFreedom.ROTATION_Y;
	}
	if (rotationZ) {
		dof |= DegreesOfFreedom.ROTATION_Z;
	}

	return dof;
};

export const mapDegreesOfFreedom = (dof: number) => {
	let joltDof = 0;

	if (dof & DegreesOfFreedom.TRANSLATION_X) {
		joltDof |= Jolt.EAllowedDOFs_TranslationX;
	}
	if (dof & DegreesOfFreedom.TRANSLATION_Y) {
		joltDof |= Jolt.EAllowedDOFs_TranslationY;
	}
	if (dof & DegreesOfFreedom.TRANSLATION_Z) {
		joltDof |= Jolt.EAllowedDOFs_TranslationZ;
	}
	if (dof & DegreesOfFreedom.ROTATION_X) {
		joltDof |= Jolt.EAllowedDOFs_RotationX;
	}
	if (dof & DegreesOfFreedom.ROTATION_Y) {
		joltDof |= Jolt.EAllowedDOFs_RotationY;
	}
	if (dof & DegreesOfFreedom.ROTATION_Z) {
		joltDof |= Jolt.EAllowedDOFs_RotationZ;
	}

	return joltDof;
};

/**
 * Creates a temporary object that will be destroyed with Jolt.destroy when it goes out of scope when used with the `using` keyword.
 *
 * Better than writing this manually yourself everywhere:
 *
 * ```
 * let vec;
 * try {
 *   vec = new Jolt.Vec3(0, 0, 0);
 * } finally {
 *   Jolt.destroy(vec);
 * }
 * ```
 *
 * Until this becomes part of the JavaScript standard (stage 3 proposal currently), TypeScript 5.2 transpiles
 * `using` to something like the above code.
 */
const tmp = <T>(value: T, cleanup: (value: T) => void) => {
	return {
		value,
		cleanup,
		[Symbol.dispose]: () => {
			cleanup(value);
		},
	} satisfies Disposable & { value: T; cleanup?: (value: T) => void };
};

export const init = () => {
	/* physics state */
	const gravity = new Vector3(0, 0, 0);

	/* construct jolt settings */
	const settings = new Jolt.JoltSettings();

	// todo: try
	// settings.mMaxWorkerThreads = 3;

	// Layer that objects can be in, determines which other objects it can collide with
	// Typically you at least want to have 1 layer for moving bodies and 1 layer for static bodies, but you can have more
	// layers if you want. E.g. you could have a layer for high detail collision (which is not used by the physics simulation
	// but only if you do collision testing).
	const objectFilterTable = new Jolt.ObjectLayerPairFilterTable(N_OBJECT_LAYERS);

	objectFilterTable.EnableCollision(OBJECT_LAYER_CHARACTER, OBJECT_LAYER_CHUNK);
	objectFilterTable.EnableCollision(OBJECT_LAYER_CHARACTER, OBJECT_LAYER_PROP_MOVING);
	objectFilterTable.EnableCollision(OBJECT_LAYER_CHARACTER, OBJECT_LAYER_PROP_NON_MOVING);

	objectFilterTable.EnableCollision(OBJECT_LAYER_PROP_MOVING, OBJECT_LAYER_PROP_NON_MOVING);

	objectFilterTable.EnableCollision(OBJECT_LAYER_PROP_MOVING, OBJECT_LAYER_CHUNK);
	objectFilterTable.EnableCollision(OBJECT_LAYER_PROP_MOVING, OBJECT_LAYER_PROP_MOVING);

	objectFilterTable.EnableCollision(OBJECT_LAYER_PROP_NON_MOVING, OBJECT_LAYER_CHUNK);
	objectFilterTable.EnableCollision(OBJECT_LAYER_PROP_NON_MOVING, OBJECT_LAYER_PROP_NON_MOVING);

	objectFilterTable.EnableCollision(OBJECT_LAYER_ITEM, OBJECT_LAYER_CHUNK);
	objectFilterTable.EnableCollision(OBJECT_LAYER_ITEM, OBJECT_LAYER_PROP_MOVING);
	objectFilterTable.EnableCollision(OBJECT_LAYER_ITEM, OBJECT_LAYER_PROP_NON_MOVING);

	objectFilterTable.EnableCollision(OBJECT_LAYER_SENSOR, OBJECT_LAYER_CHARACTER);
	objectFilterTable.EnableCollision(OBJECT_LAYER_SENSOR, OBJECT_LAYER_PROP_MOVING);
	objectFilterTable.EnableCollision(OBJECT_LAYER_SENSOR, OBJECT_LAYER_PROP_NON_MOVING);

	// Each broadphase layer results in a separate bounding volume tree in the broad phase. You at least want to have
	// a layer for non-moving and moving objects to avoid having to update a tree full of static objects every frame.
	// You can have a 1-on-1 mapping between object layers and broadphase layers (like in this case) but if you have
	// many object layers you'll be creating many broad phase trees, which is not efficient.
	const bpLayerNonMoving = new Jolt.BroadPhaseLayer(BROADPHASE_LAYER_NON_MOVING);
	const bpLayerMoving = new Jolt.BroadPhaseLayer(BROADPHASE_LAYER_MOVING);

	const bpInterface = new Jolt.BroadPhaseLayerInterfaceTable(N_OBJECT_LAYERS, N_BROADPHASE_LAYERS);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_CHUNK, bpLayerNonMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_CHUNK_COLLISION_DISABLED, bpLayerNonMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_CHARACTER, bpLayerMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_PROP_MOVING, bpLayerMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_PROP_NON_MOVING, bpLayerNonMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_ENTITY_COLLISION_DISABLED, bpLayerNonMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_ITEM, bpLayerMoving);
	bpInterface.MapObjectToBroadPhaseLayer(OBJECT_LAYER_SENSOR, bpLayerMoving);

	// layers have been copied into bpInterface
	Jolt.destroy(bpLayerNonMoving);
	Jolt.destroy(bpLayerMoving);

	settings.mObjectLayerPairFilter = objectFilterTable;
	settings.mBroadPhaseLayerInterface = bpInterface;
	settings.mObjectVsBroadPhaseLayerFilter = new Jolt.ObjectVsBroadPhaseLayerFilterTable(
		settings.mBroadPhaseLayerInterface,
		N_BROADPHASE_LAYERS,
		settings.mObjectLayerPairFilter,
		N_OBJECT_LAYERS,
	);

	/* create "world" / jolt interfaces using settings */
	const jolt = new Jolt.JoltInterface(settings);

	// Everything in 'settings' has now been copied into 'jolt', the 3 interfaces above are now owned by 'jolt'
	Jolt.destroy(settings);

	// owned by 'jolt', don't need to be destroyed later
	const physicsSystem = jolt.GetPhysicsSystem();
	const bodyInterface = physicsSystem.GetBodyInterface();

	/* filters */
	const defaultBroadPhaseLayerFilter = new Jolt.BroadPhaseLayerFilter();
	const defaultObjectLayerFilter = new Jolt.ObjectLayerFilter();
	const defaultBodyFilter = new Jolt.BodyFilter();
	const defaultShapeFilter = new Jolt.ShapeFilter();

	const createObjectLayersFilter = (objectLayers: number[]) => {
		const chunkFilterTable = new Jolt.ObjectLayerPairFilterTable(N_OBJECT_LAYERS);
		for (const layer of objectLayers) {
			chunkFilterTable.EnableCollision(layer, PLACEHOLDER_OBJECT_LAYER_FOR_FILTERS);
		}

		const defaultObjectLayerFilter = new Jolt.DefaultObjectLayerFilter(
			chunkFilterTable,
			PLACEHOLDER_OBJECT_LAYER_FOR_FILTERS,
		);

		return defaultObjectLayerFilter;
	};

	const chunkObjectLayerFilter = createObjectLayersFilter([
		OBJECT_LAYER_CHUNK,
		OBJECT_LAYER_CHUNK_COLLISION_DISABLED,
	]);
	const collidableChunkObjectLayerFilter = createObjectLayersFilter([OBJECT_LAYER_CHUNK]);

	const collidableObjectLayerFilter = createObjectLayersFilter([
		OBJECT_LAYER_CHUNK,
		OBJECT_LAYER_PROP_MOVING,
		OBJECT_LAYER_PROP_NON_MOVING,
		OBJECT_LAYER_ITEM,
		OBJECT_LAYER_CHARACTER,
	]);

	const noCharactersCollidableObjectLayerFilter = createObjectLayersFilter([
		OBJECT_LAYER_CHUNK,
		OBJECT_LAYER_PROP_MOVING,
		OBJECT_LAYER_PROP_NON_MOVING,
		OBJECT_LAYER_SENSOR,
	]);

	const viewRaycastObjectLayerFilter = createObjectLayersFilter([
		OBJECT_LAYER_ENTITY_COLLISION_DISABLED,
		OBJECT_LAYER_CHUNK,
		OBJECT_LAYER_CHUNK_COLLISION_DISABLED,
		OBJECT_LAYER_CHARACTER,
		OBJECT_LAYER_PROP_MOVING,
		OBJECT_LAYER_PROP_NON_MOVING,
		OBJECT_LAYER_ITEM,
	]);

	/* materials */
	const defaultMaterial = new Jolt.PhysicsMaterial();
	// ...

	/* contacts */
	const contacts: Contacts = {
		added: [],
		persisted: [],
		removed: [],
	};

	// see: https://github.com/JamangoGame/JoltPhysics.js/blob/main/Jamango-ContactListener.h
	const contactListener = new Jolt.JamangoContactListener();

	physicsSystem.SetContactListener(contactListener);

	return {
		// jolt interfaces
		jolt,
		physicsSystem,
		bodyInterface,
		JoltModule: Jolt, // for console debugging purposes
		// contacts
		contactListener,
		contacts,
		// settings
		gravity,
		// materials - placeholder
		defaultMaterial,
		// filters
		defaultBroadPhaseLayerFilter,
		defaultObjectLayerFilter,
		chunkObjectLayerFilter,
		collidableChunkObjectLayerFilter,
		collidableObjectLayerFilter,
		noCharactersCollidableObjectLayerFilter,
		viewRaycastObjectLayerFilter,
		defaultBodyFilter,
		defaultShapeFilter,
		// user data
		userData: new Map<number, BodyUserData>(),
		// prop shapes cache
		propShapes: new Map<string, JoltPhysics.Shape>(),
		// constraints
		constraintIdCounter: 0,
		constraints: new Map<number, Constraint>(),
		bodyConstraints: new Map<number, number[]>(),
	};
};

export type PhysicsState = ReturnType<typeof init>;

export const dispose = (state: PhysicsState) => {
	for (const id of state.propShapes.keys()) {
		disposePropColliderShape(state, id);
	}

	for (const id of state.constraints.keys()) {
		disposeConstraint(state, id);
	}

	Jolt.destroy(state.jolt);
	Jolt.destroy(state.contactListener);
};

export const setGravity = (state: PhysicsState, gravity: Vector3Like) => {
	state.gravity.copy(gravity);
	_jolt_vec3.Set(gravity.x, gravity.y, gravity.z);
	state.physicsSystem.SetGravity(_jolt_vec3);
};

export const resetContacts = (state: PhysicsState) => {
	/* clear contacts */
	state.contactListener.Clear();
	state.contacts.added.length = 0;
	state.contacts.persisted.length = 0;
	state.contacts.removed.length = 0;
};

export const update = (state: PhysicsState, dt: number, paused: boolean) => {
	Metrics.perfStart("update", "physics");

	/* don't step if paused */
	if (paused) return;

	/* step the simulation */
	state.jolt.Step(dt, 1);

	/* populate contacts */
	const nAddedContacts = state.contactListener.GetAddedSize();
	for (let i = 0; i < nAddedContacts; i++) {
		const contact = state.contactListener.GetAddedAt(i);
		const body1IndexAndSequenceNumber = contact.body1IndexAndSequenceNumber;
		const body2IndexAndSequenceNumber = contact.body2IndexAndSequenceNumber;

		state.contacts.added.push({
			body1IndexAndSequence: body1IndexAndSequenceNumber,
			body1UserData: state.userData.get(body1IndexAndSequenceNumber),
			body2IndexAndSequence: body2IndexAndSequenceNumber,
			body2UserData: state.userData.get(body2IndexAndSequenceNumber),
			worldSpaceNormalX: contact.worldSpaceNormalX,
			worldSpaceNormalY: contact.worldSpaceNormalY,
			worldSpaceNormalZ: contact.worldSpaceNormalZ,
		});
	}

	const nPersistedContacts = state.contactListener.GetPersistedSize();
	for (let i = 0; i < nPersistedContacts; i++) {
		const contact = state.contactListener.GetPersistedAt(i);
		const body1IndexAndSequenceNumber = contact.body1IndexAndSequenceNumber;
		const body2IndexAndSequenceNumber = contact.body2IndexAndSequenceNumber;

		state.contacts.persisted.push({
			body1IndexAndSequence: body1IndexAndSequenceNumber,
			body1UserData: state.userData.get(body1IndexAndSequenceNumber),
			body2IndexAndSequence: body2IndexAndSequenceNumber,
			body2UserData: state.userData.get(body2IndexAndSequenceNumber),
			worldSpaceNormalX: contact.worldSpaceNormalX,
			worldSpaceNormalY: contact.worldSpaceNormalY,
			worldSpaceNormalZ: contact.worldSpaceNormalZ,
		});
	}

	const nRemovedContacts = state.contactListener.GetRemovedSize();
	for (let i = 0; i < nRemovedContacts; i++) {
		const contact = state.contactListener.GetRemovedAt(i);
		const body1IndexAndSequenceNumber = contact.body1IndexAndSequenceNumber;
		const body2IndexAndSequenceNumber = contact.body2IndexAndSequenceNumber;

		state.contacts.removed.push({
			body1IndexAndSequence: body1IndexAndSequenceNumber,
			body1UserData: state.userData.get(body1IndexAndSequenceNumber),
			body2IndexAndSequence: body2IndexAndSequenceNumber,
			body2UserData: state.userData.get(body2IndexAndSequenceNumber),
		});
	}

	Metrics.perfEnd("update", "physics");
};

/* Raycasting and Shapecasting */

const parseJoltRayCastResult = (
	state: PhysicsState,
	ray: JoltPhysics.RRayCast,
	hit: JoltPhysics.RayCastResult,
	result: RaycastResult,
) => {
	// get jolt data
	const shapeIdValue = hit.mSubShapeID2.GetValue();
	const bodyIndexAndSequence = hit.mBodyID.GetIndexAndSequenceNumber();

	const hitPosition = ray.GetPointOnRay(hit.mFraction);
	let hitNormal: JoltPhysics.Vec3 | null = null;

	const bodyID = new Jolt.BodyID(bodyIndexAndSequence);
	const shapeID = new Jolt.SubShapeID();

	const body = state.physicsSystem.GetBodyLockInterfaceNoLock().TryGetBody(bodyID);
	result.body = body;

	shapeID.SetValue(shapeIdValue);
	hitNormal = body.GetWorldSpaceSurfaceNormal(shapeID, hitPosition);

	// populate result
	result.hit = true;
	result.hitPosition.set(hitPosition.GetX(), hitPosition.GetY(), hitPosition.GetZ());
	if (hitNormal) {
		result.hitNormal.set(hitNormal.GetX(), hitNormal.GetY(), hitNormal.GetZ());
	} else {
		// todo: warn? can this regularly ever happen?
		result.hitNormal.set(0, 0, 0);
	}

	result.bodyIndexAndSequence = bodyIndexAndSequence;
	result.bodyObjectLayer = body.GetObjectLayer() as PhysicsObjectLayer;
	result.body = body;
	result.shapeId = shapeIdValue;

	const userData = state.userData.get(bodyIndexAndSequence);
	result.userData = userData;

	// cleanup
	Jolt.destroy(hitPosition);
	if (hitNormal) {
		Jolt.destroy(hitNormal);
	}
	Jolt.destroy(bodyID);
	Jolt.destroy(shapeID);
};

const raycastClosest = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	broadPhaseLayerFilter: JoltPhysics.BroadPhaseLayerFilter,
	objectFilter: JoltPhysics.ObjectLayerFilter,
	bodyFilter: JoltPhysics.BodyFilter,
	shapeFilter: JoltPhysics.ShapeFilter,
	result: RaycastResult,
) => {
	const raycastDirection = _raycastDirection.copy(direction).setLength(length);

	// configure raycast
	const ray = _jolt_ray;
	ray.mOrigin.Set(origin.x, origin.y, origin.z);
	ray.mDirection.Set(raycastDirection.x, raycastDirection.y, raycastDirection.z);

	const raySettings = _jolt_raySettings;

	const collector = _jolt_castRayClosestHitCollisionCollector;
	collector.Reset();

	// perform raycast
	state.physicsSystem
		.GetNarrowPhaseQuery()
		.CastRay(ray, raySettings, collector, broadPhaseLayerFilter, objectFilter, bodyFilter, shapeFilter);

	// parse result
	const hit = collector.HadHit() ? collector.mHit : undefined;

	if (!hit) {
		result.hit = false;
		return result;
	}

	parseJoltRayCastResult(state, ray, hit, result);

	return result;
};

export const getEntityBodyID = (entity: Entity | undefined): JoltPhysics.BodyID | undefined => {
	if (!entity) {
		return undefined;
	}

	if (entityIsCharacter(entity)) {
		return entity.characterPhysics.state.controller?.characterVirtual.GetInnerBodyID();
	}

	if (entityIsProp(entity)) {
		return entity.propPhysics.state.body?.GetID();
	}

	if (entityIsItem(entity)) {
		return entity.itemPhysics.state.body?.GetID();
	}

	return undefined;
};

export const getEntityBody = (
	state: PhysicsState,
	entity: Entity | undefined,
): JoltPhysics.Body | undefined => {
	if (!entity) {
		return undefined;
	}

	if (entityIsCharacter(entity) && entity.characterPhysics.state.controller) {
		return getCharacterControllerBody(state, entity.characterPhysics.state.controller);
	}

	if (entityIsProp(entity)) {
		return entity.propPhysics.state.body;
	}

	if (entityIsItem(entity)) {
		return entity.itemPhysics.state.body;
	}

	return undefined;
};

export const raycastCharacterViewRaycast = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	ignoreEntity: Entity | undefined,
	result: RaycastResult,
) => {
	const entityBodyID = getEntityBodyID(ignoreEntity);

	using bodyFilter = tmp(
		entityBodyID ? new Jolt.IgnoreSingleBodyFilter(entityBodyID) : new Jolt.BodyFilter(),
		destroy,
	);

	return raycastClosest(
		state,
		origin,
		direction,
		length,
		state.defaultBroadPhaseLayerFilter,
		state.viewRaycastObjectLayerFilter,
		bodyFilter.value,
		state.defaultShapeFilter,
		result,
	);
};

const createEntitiesBodyFilter = (entities: Entity[]) => {
	const bodyIds = entities.map((entity) => getEntityBodyID(entity)).filter((id) => id !== undefined);

	if (bodyIds.length === 0) {
		return new Jolt.BodyFilter();
	}

	if (bodyIds.length === 1) {
		return new Jolt.IgnoreSingleBodyFilter(bodyIds[0]!);
	}

	// TODO: reuse one IgnoreMultipleBodiesFilter always?
	const multipleBodyFilter = new Jolt.IgnoreMultipleBodiesFilter();
	multipleBodyFilter.Reserve(bodyIds.length);

	for (const bodyId of bodyIds) {
		multipleBodyFilter.IgnoreBody(bodyId!);
	}

	return multipleBodyFilter;
};

export const raycastAnyCollidable = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	ignoreEntities: Entity[] | undefined,
	result: RaycastResult,
) => {
	using bodyFilter = tmp(
		ignoreEntities ? createEntitiesBodyFilter(ignoreEntities) : new Jolt.BodyFilter(),
		destroy,
	);

	return raycastClosest(
		state,
		origin,
		direction,
		length,
		state.defaultBroadPhaseLayerFilter,
		state.collidableObjectLayerFilter,
		bodyFilter.value,
		state.defaultShapeFilter,
		result,
	);
};

export const raycastStaticCollidableWorld = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	result: RaycastResult,
) => {
	return raycastClosest(
		state,
		origin,
		direction,
		length,
		state.defaultBroadPhaseLayerFilter,
		state.collidableChunkObjectLayerFilter,
		state.defaultBodyFilter,
		state.defaultShapeFilter,
		result,
	);
};

export type RaycastBlockResult = {
	hit: boolean;
	worldPos: Vector3;
	pos: Vector3;
	block: Vector3;
};

export function initRaycastBlockResult() {
	return {
		hit: false,
		worldPos: new Vector3(),
		pos: new Vector3(),
		block: new Vector3(),
	};
}

export const raycastBlock = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	ignoreEntity: Entity | undefined,
	ignoreNonCollidableBlocks: boolean,
	result: RaycastBlockResult,
) => {
	const entityBodyID = getEntityBodyID(ignoreEntity);

	using bodyFilter = tmp(
		entityBodyID ? new Jolt.IgnoreSingleBodyFilter(entityBodyID) : new Jolt.BodyFilter(),
		destroy,
	);

	const objectLayerFilter = ignoreNonCollidableBlocks
		? state.collidableChunkObjectLayerFilter
		: state.chunkObjectLayerFilter;

	result.hit = false;

	const raycastClosestResult = _raycastResult;

	raycastClosest(
		state,
		origin,
		direction,
		length,
		state.defaultBroadPhaseLayerFilter,
		objectLayerFilter,
		bodyFilter.value,
		state.defaultShapeFilter,
		raycastClosestResult,
	);

	if (!raycastClosestResult.hit) {
		return result;
	}

	result.hit = true;

	result.worldPos.copy(raycastClosestResult.hitPosition);

	findHitBlock(
		_rayOrigin.copy(origin),
		_rayHitPos.copy(raycastClosestResult.hitPosition),
		raycastClosestResult.hitNormal,
		result.block,
	);

	result.pos.copy(result.worldPos).sub(result.block);

	return result;
};

export const raycastEntity = (
	state: PhysicsState,
	origin: Vector3Like,
	direction: Vector3Like,
	length: number,
	ignoreEntity?: Entity,
	outPos?: Vector3,
	outNormal?: Vector3,
): number | undefined => {
	const raycastClosestResult = _raycastResult;

	const entityBodyID = getEntityBodyID(ignoreEntity);

	using bodyFilter = tmp(
		entityBodyID ? new Jolt.IgnoreSingleBodyFilter(entityBodyID) : new Jolt.BodyFilter(),
		destroy,
	);

	raycastClosest(
		state,
		origin,
		direction,
		length,
		state.defaultBroadPhaseLayerFilter,
		state.defaultObjectLayerFilter,
		bodyFilter.value,
		state.defaultShapeFilter,
		raycastClosestResult,
	);

	if (!raycastClosestResult.hit || !raycastClosestResult.body) {
		return undefined;
	}

	const userData = raycastClosestResult.userData;

	if (!userData || !userData.isEntity) {
		return undefined;
	}

	if (outPos) {
		outPos.copy(raycastClosestResult.hitPosition);
	}

	if (outNormal) {
		outNormal.copy(raycastClosestResult.hitNormal);
	}

	return userData.entityId;
};

const parseJoltShapeCastResult = (
	state: PhysicsState,
	shapecast: JoltPhysics.RShapeCast,
	hit: JoltPhysics.ShapeCastResult,
	result: RaycastResult,
) => {
	// get jolt data
	const shapeIdValue = hit.mSubShapeID2.GetValue();
	const bodyIndexAndSequence = hit.mBodyID2.GetIndexAndSequenceNumber();

	const hitPosition = shapecast.GetPointOnRay(hit.mFraction);
	let hitNormal: JoltPhysics.Vec3 | null = null;

	const bodyID = new Jolt.BodyID(bodyIndexAndSequence);
	const shapeID = new Jolt.SubShapeID();

	const body = state.physicsSystem.GetBodyLockInterfaceNoLock().TryGetBody(bodyID);
	result.body = body;

	shapeID.SetValue(shapeIdValue);
	hitNormal = body.GetWorldSpaceSurfaceNormal(shapeID, hitPosition);

	// populate result
	result.hit = true;
	result.hitPosition.set(hitPosition.GetX(), hitPosition.GetY(), hitPosition.GetZ());
	if (hitNormal) {
		result.hitNormal.set(hitNormal.GetX(), hitNormal.GetY(), hitNormal.GetZ());
	} else {
		// todo: warn? can this regularly ever happen?
		result.hitNormal.set(0, 0, 0);
	}

	result.bodyIndexAndSequence = bodyIndexAndSequence;
	result.bodyObjectLayer = body.GetObjectLayer() as PhysicsObjectLayer;
	result.body = body;
	result.shapeId = shapeIdValue;

	const userData = state.userData.get(bodyIndexAndSequence);
	result.userData = userData;

	// cleanup
	Jolt.destroy(hitPosition);
	if (hitNormal) {
		Jolt.destroy(hitNormal);
	}
	Jolt.destroy(bodyID);
	Jolt.destroy(shapeID);
};

const shapecastClosest = (
	state: PhysicsState,
	shape: JoltPhysics.Shape,
	shapePosition: Vector3Like,
	shapeQuaternion: QuaternionLike,
	direction: Vector3Like,
	length: number,
	broadPhaseLayerFilter: JoltPhysics.BroadPhaseLayerFilter,
	objectLayerFilter: JoltPhysics.ObjectLayerFilter,
	bodyFilter: JoltPhysics.BodyFilter,
	shapeFilter: JoltPhysics.ShapeFilter,
	result: RaycastResult,
) => {
	result.hit = false;

	// configure shapecast
	const raycastDirection = _raycastDirection.copy(direction).setLength(length);

	const joltShapePosition = _jolt_r_position;
	joltShapePosition.Set(shapePosition.x, shapePosition.y, shapePosition.z);

	const joltShapeQuaternion = _jolt_quat;
	joltShapeQuaternion.Set(shapeQuaternion.x, shapeQuaternion.y, shapeQuaternion.z, shapeQuaternion.w);

	const joltShapeScale = _jolt_scale;
	joltShapeScale.Set(1, 1, 1);

	const shapecastDirection = _jolt_direction;
	shapecastDirection.Set(raycastDirection.x, raycastDirection.y, raycastDirection.z);

	const collector = _jolt_castShapeClosestHitCollisionCollector;
	collector.Reset();

	using centerOfMassStart = tmp(
		Jolt.RMat44.prototype.sRotationTranslation(joltShapeQuaternion, joltShapePosition),
		destroy,
	);

	using shapecast = tmp(
		new Jolt.RShapeCast(shape, joltShapeScale, centerOfMassStart.value, shapecastDirection),
		destroy,
	);

	_jolt_baseOffset.Set(0, 0, 0);

	// perform shapecast
	state.physicsSystem
		.GetNarrowPhaseQuery()
		.CastShape(
			shapecast.value,
			_jolt_shapecastSettings,
			_jolt_baseOffset,
			collector,
			broadPhaseLayerFilter,
			objectLayerFilter,
			bodyFilter,
			shapeFilter,
		);

	// parse result
	const hit = collector.HadHit() ? collector.mHit : undefined;

	if (!hit) {
		return result;
	}

	parseJoltShapeCastResult(state, shapecast.value, hit, result);

	return result;
};

export const boxCast = (
	state: PhysicsState,
	boxHalfExtents: Vector3Like,
	boxPosition: Vector3Like,
	boxQuaternion: QuaternionLike,
	direction: Vector3Like,
	length: number,
	objectLayerFilter: JoltPhysics.ObjectLayerFilter,
	result: RaycastResult,
) => {
	const raycastDirection = _raycastDirection.copy(direction).setLength(length);

	using halfExtents = tmp(new Jolt.Vec3(boxHalfExtents.x, boxHalfExtents.y, boxHalfExtents.z), destroy);
	using shape = tmp(new Jolt.BoxShape(halfExtents.value, 0, state.defaultMaterial), release);

	shapecastClosest(
		state,
		shape.value,
		boxPosition,
		boxQuaternion,
		raycastDirection,
		length,
		state.defaultBroadPhaseLayerFilter,
		objectLayerFilter,
		state.defaultBodyFilter,
		state.defaultShapeFilter,
		result,
	);

	return result;
};

/* Memory utils */
const createFloatArrayHeapView = (array: JoltPhysics.JamangoFloatArray) => {
	const dataPointer = array.getDataPointer();
	return new Float32Array(Jolt.HEAPF32.buffer, dataPointer, array.size);
};

const createUnsignedIntArrayHeapView = (array: JoltPhysics.JamangoUnsignedIntArray) => {
	const dataPointer = array.getDataPointer();
	return new Uint32Array(Jolt.HEAPU32.buffer, dataPointer, array.size);
};

/* Shape utils */

const createBoxShape = (state: PhysicsState, halfExtents: Vector3Like) => {
	const joltHalfExtents = _jolt_halfExtents;
	joltHalfExtents.Set(halfExtents.x, halfExtents.y, halfExtents.z);

	const shape = new Jolt.BoxShape(joltHalfExtents, 0, state.defaultMaterial);

	return shape;
};

const createMeshShapeSettings = (vertices: ArrayLike<number>, indices: ArrayLike<number>) => {
	using verticesArray = tmp(new Jolt.JamangoFloatArray(), destroy);
	using indicesArray = tmp(new Jolt.JamangoUnsignedIntArray(), destroy);

	verticesArray.value.resize(vertices.length);
	indicesArray.value.resize(indices.length);

	const verticesArrayHeapView = createFloatArrayHeapView(verticesArray.value);
	verticesArrayHeapView.set(vertices);

	const indicesArrayHeapView = createUnsignedIntArrayHeapView(indicesArray.value);
	indicesArrayHeapView.set(indices);

	const shapeSettings = _jolt_jamango_array_utils.createMeshShapeSettings(
		verticesArray.value,
		indicesArray.value,
	);
	shapeSettings.AddRef();

	// TODO: does mActiveEdgeCosThresholdAngle need adjusting?
	// e.g. shapeSettings.mActiveEdgeCosThresholdAngle = Math.cos(5 * DEGRAD); // default

	return shapeSettings;
};

const createConvexHullShapeSettings = (_state: PhysicsState, points: ArrayLike<number>) => {
	using pointsArray = tmp(new Jolt.JamangoFloatArray(), destroy);

	pointsArray.value.resize(points.length);

	const pointsArrayHeapView = createFloatArrayHeapView(pointsArray.value);
	pointsArrayHeapView.set(points);

	const shapeSettings = _jolt_jamango_array_utils.createConvexHullShapeSettings(pointsArray.value, 0.05);
	shapeSettings.AddRef();

	return shapeSettings;
};

/**
 * Remember to call shape.Release() when the shape reference is no longer needed, e.g. after passing to BodyCreationSettings
 */
const createMeshShape = (_state: PhysicsState, vertices: ArrayLike<number>, indices: ArrayLike<number>) => {
	using shapeSettings = tmp(createMeshShapeSettings(vertices, indices), release);

	return createShapeFromSettings(shapeSettings.value);
};

type ShapeResult =
	| {
			shape: JoltPhysics.Shape;
			error: null;
	  }
	| {
			shape: null;
			error: string;
	  };

const createShapeFromSettings = (shapeSettings: JoltPhysics.ShapeSettings): ShapeResult => {
	using shapeResult = tmp(shapeSettings.Create(), destroyShapeResult);

	const isValid = shapeResult.value.IsValid();

	const shape = isValid ? shapeResult.value.Get() : null;

	if (shape) {
		shape.AddRef();
	}

	const error = isValid ? null : shapeResult.value.GetError().c_str();

	return { shape, error } as ShapeResult;
};

/* Body creation, addition, removal, destruction */

const createBody = (
	state: PhysicsState,
	position: Vector3Like,
	rotation: QuaternionLike,
	shape: JoltPhysics.Shape,
	motionType: MotionType,
	motionQuality: MotionQuality,
	layer: PhysicsObjectLayer,
	mass: number,
	friction: number,
	restitution: number,
	enhancedInternalEdgeRemoval: boolean,
) => {
	const joltPosition = _jolt_r_position;
	joltPosition.Set(position.x, position.y, position.z);

	const joltRotation = _jolt_quat;
	joltRotation.Set(rotation.x, rotation.y, rotation.z, rotation.w);

	using bodySettings = tmp(
		new Jolt.BodyCreationSettings(shape, joltPosition, joltRotation, mapMotionType(motionType), layer),
		destroy,
	);

	bodySettings.value.mMotionQuality = mapMotionQuality(motionQuality);
	bodySettings.value.mMassPropertiesOverride.mMass = mass;
	bodySettings.value.mFriction = friction;
	bodySettings.value.mRestitution = restitution;
	bodySettings.value.mEnhancedInternalEdgeRemoval = enhancedInternalEdgeRemoval;

	const body = state.bodyInterface.CreateBody(bodySettings.value);

	return body;
};

export const addBody = (state: PhysicsState, body: JoltPhysics.Body) => {
	state.bodyInterface.AddBody(body.GetID(), Jolt.EActivation_Activate);
};

export const removeBody = (state: PhysicsState, body: JoltPhysics.Body) => {
	const bodyID = body.GetID();
	const bodyIndexAndSequence = bodyID.GetIndexAndSequenceNumber();

	// remove any constraints
	const constraints = state.bodyConstraints.get(bodyIndexAndSequence);
	if (constraints) {
		for (const constraintId of constraints) {
			disposeConstraint(state, constraintId);
		}

		state.bodyConstraints.delete(bodyIndexAndSequence);
	}

	// remove the body
	state.bodyInterface.RemoveBody(bodyID);
};

export const destroyBody = (state: PhysicsState, body: JoltPhysics.Body) => {
	state.userData.delete(body.GetID().GetIndexAndSequenceNumber());
	state.bodyInterface.DestroyBody(body.GetID());
};

export const setBodyMotionType = (state: PhysicsState, body: JoltPhysics.Body, motionType: MotionType) => {
	state.bodyInterface.SetMotionType(body.GetID(), mapMotionType(motionType), Jolt.EActivation_Activate);
};

export const setBodyDegreesOfFreedom = (
	_state: PhysicsState,
	body: JoltPhysics.Body,
	dof: DegreesOfFreedom,
) => {
	body.GetMotionProperties().SetMassProperties(
		mapDegreesOfFreedom(dof),
		body.GetShape().GetMassProperties(),
	);
};

export const getBodyIsActive = (body: JoltPhysics.Body) => {
	return body.IsActive();
};

export const getBodyPosition = (body: JoltPhysics.Body, out: Vector3) => {
	const position = body.GetPosition();
	out.set(position.GetX(), position.GetY(), position.GetZ());
};

export const setBodyPosition = (state: PhysicsState, body: JoltPhysics.Body, position: Vector3Like) => {
	_jolt_r_position.Set(position.x, position.y, position.z);
	state.bodyInterface.SetPosition(body.GetID(), _jolt_r_position, Jolt.EActivation_Activate);
};

export const setBodyRotation = (state: PhysicsState, body: JoltPhysics.Body, quaternion: QuaternionLike) => {
	_jolt_quat.Set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
	state.bodyInterface.SetRotation(body.GetID(), _jolt_quat, Jolt.EActivation_Activate);
};

export const getBodyPositionAndRotation = (
	body: JoltPhysics.Body,
	outPosition: Vector3,
	outQuaternion: Quaternion,
) => {
	const position = body.GetPosition();
	const rotation = body.GetRotation();

	outPosition.set(position.GetX(), position.GetY(), position.GetZ());
	outQuaternion.set(rotation.GetX(), rotation.GetY(), rotation.GetZ(), rotation.GetW());
};

export const setBodyPositionAndRotation = (
	state: PhysicsState,
	body: JoltPhysics.Body,
	position: Vector3Like,
	quaternion: QuaternionLike,
) => {
	_jolt_r_position.Set(position.x, position.y, position.z);
	_jolt_quat.Set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
	state.bodyInterface.SetPositionAndRotation(
		body.GetID(),
		_jolt_r_position,
		_jolt_quat,
		Jolt.EActivation_Activate,
	);
};

export const getBodyLinearVelocity = (body: JoltPhysics.Body, out: Vector3) => {
	const velocity = body.GetLinearVelocity();
	out.set(velocity.GetX(), velocity.GetY(), velocity.GetZ());
};

export const setBodyLinearVelocity = (state: PhysicsState, body: JoltPhysics.Body, velocity: Vector3Like) => {
	_jolt_linearVelocity.Set(velocity.x, velocity.y, velocity.z);
	state.bodyInterface.SetLinearVelocity(body.GetID(), _jolt_linearVelocity);
};

export const getBodyAngularVelocity = (body: JoltPhysics.Body, out: Vector3) => {
	const velocity = body.GetAngularVelocity();
	out.set(velocity.GetX(), velocity.GetY(), velocity.GetZ());
};

export const setBodyAngularVelocity = (
	state: PhysicsState,
	body: JoltPhysics.Body,
	velocity: Vector3Like,
) => {
	_jolt_linearVelocity.Set(velocity.x, velocity.y, velocity.z);
	state.bodyInterface.SetAngularVelocity(body.GetID(), _jolt_linearVelocity);
};

export const getBodyAABB = (body: JoltPhysics.Body, out: Box3) => {
	const bounds = body.GetTransformedShape().GetWorldSpaceBounds();
	const min = bounds.mMin;
	const max = bounds.mMax;

	out.min.set(min.GetX(), min.GetY(), min.GetZ());
	out.max.set(max.GetX(), max.GetY(), max.GetZ());
};

export const activateBody = (state: PhysicsState, body: JoltPhysics.Body) => {
	state.bodyInterface.ActivateBody(body.GetID());
};

export const addBodyImpulse = (body: JoltPhysics.Body, impulse: Vector3Like) => {
	_jolt_linearVelocity.Set(impulse.x, impulse.y, impulse.z);
	body.AddImpulse(_jolt_linearVelocity);
};

/* Chunk Physics */

export const isBodyChunk = (state: PhysicsState, bodyIndexAndSequence: number) => {
	const userData = state.userData.get(bodyIndexAndSequence);
	return userData?.isChunk ?? false;
};

export const createChunkPhysics = (
	state: PhysicsState,
	chunk: Chunk,
	physicsCollisionEnabledBuffer: ChunkBuilderPhysicsBuffer,
	physicsCollisionDisabledBuffer: ChunkBuilderPhysicsBuffer,
) => {
	let physicsCollisionEnabledBody: JoltPhysics.Body | null = null;
	let physicsCollisionDisabledBody: JoltPhysics.Body | null = null;

	const chunkPosition = _vector3.copy(chunk.position).multiplyScalar(chunk.scene.chunkSize);

	if (physicsCollisionEnabledBuffer.position.length > 0) {
		const physicsCollisionEnabledShapeResult = createMeshShape(
			state,
			physicsCollisionEnabledBuffer.position,
			physicsCollisionEnabledBuffer.index,
		);

		if (physicsCollisionEnabledShapeResult.shape) {
			physicsCollisionEnabledBody = createBody(
				state,
				chunkPosition,
				QUATERNION_IDENTITY,
				physicsCollisionEnabledShapeResult.shape,
				MotionType.STATIC,
				MotionQuality.DISCRETE,
				OBJECT_LAYER_CHUNK,
				0,
				DEFAULT_CHUNK_MATERIAL.friction,
				DEFAULT_CHUNK_MATERIAL.restitution,
				true,
			);

			state.userData.set(physicsCollisionEnabledBody.GetID().GetIndexAndSequenceNumber(), {
				isChunk: true,
			});

			// the body now holds the reference to the shape
			physicsCollisionEnabledShapeResult.shape.Release();
		} else {
			logger.error(
				"Failed to create physics collision enabled shape for chunk:",
				physicsCollisionEnabledShapeResult.error,
			);
		}
	}

	if (physicsCollisionDisabledBuffer.position.length > 0) {
		const physicsCollisionDisabledShapeResult = createMeshShape(
			state,
			physicsCollisionDisabledBuffer.position,
			physicsCollisionDisabledBuffer.index,
		);

		if (physicsCollisionDisabledShapeResult.shape) {
			physicsCollisionDisabledBody = createBody(
				state,
				chunkPosition,
				QUATERNION_IDENTITY,
				physicsCollisionDisabledShapeResult.shape,
				MotionType.STATIC,
				MotionQuality.DISCRETE,
				OBJECT_LAYER_CHUNK_COLLISION_DISABLED,
				0,
				DEFAULT_CHUNK_MATERIAL.friction,
				DEFAULT_CHUNK_MATERIAL.restitution,
				false,
			);

			state.userData.set(physicsCollisionDisabledBody.GetID().GetIndexAndSequenceNumber(), {
				isChunk: true,
			});

			// the body now holds the reference to the shape
			physicsCollisionDisabledShapeResult.shape.Release();
		} else {
			logger.error(
				"Failed to create physics collision disabled shape for chunk:",
				physicsCollisionDisabledShapeResult.error,
			);
		}
	}

	if (physicsCollisionEnabledBody) {
		addBody(state, physicsCollisionEnabledBody);
	}

	if (physicsCollisionDisabledBody) {
		addBody(state, physicsCollisionDisabledBody);
	}

	return {
		physicsCollisionEnabledBody,
		physicsCollisionDisabledBody,
	};
};

export const disposeChunkPhysics = (state: PhysicsState, physics: ReturnType<typeof createChunkPhysics>) => {
	if (physics.physicsCollisionEnabledBody) {
		removeBody(state, physics.physicsCollisionEnabledBody);
		destroyBody(state, physics.physicsCollisionEnabledBody);
	}

	if (physics.physicsCollisionDisabledBody) {
		removeBody(state, physics.physicsCollisionDisabledBody);
		destroyBody(state, physics.physicsCollisionDisabledBody);
	}
};

/* types for entity physics */
export type PhysicsBody = JoltPhysics.Body;

export type PhysicsSensor = JoltPhysics.Body;

/* Constraints */

export type Constraint = { id: number; constraint: JoltPhysics.Constraint };

const makeTwoBodyConstraint = (
	state: PhysicsState,
	constraintSettings: JoltPhysics.TwoBodyConstraintSettings,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
) => {
	const id = state.constraintIdCounter++;

	const constraint = constraintSettings.Create(bodyA, bodyB);

	constraint.AddRef();

	const constraintState = {
		id,
		constraint,
	};

	const bodyAIndexAndSequence = bodyA.GetID().GetIndexAndSequenceNumber();
	const bodyBIndexAndSequence = bodyB.GetID().GetIndexAndSequenceNumber();

	state.constraints.set(id, constraintState);

	let bodyAConstraintIds = state.bodyConstraints.get(bodyAIndexAndSequence);
	if (!bodyAConstraintIds) {
		bodyAConstraintIds = [];
		state.bodyConstraints.set(bodyAIndexAndSequence, bodyAConstraintIds);
	}
	bodyAConstraintIds.push(id);

	let bodyBConstraintIds = state.bodyConstraints.get(bodyBIndexAndSequence);
	if (!bodyBConstraintIds) {
		bodyBConstraintIds = [];
		state.bodyConstraints.set(bodyBIndexAndSequence, bodyBConstraintIds);
	}
	bodyBConstraintIds.push(id);

	state.physicsSystem.AddConstraint(constraint);

	return constraintState;
};

export const createFixedConstraint = (state: PhysicsState, bodyA: PhysicsBody, bodyB: PhysicsBody) => {
	using constraintSettings = tmp(new Jolt.FixedConstraintSettings(), release);
	constraintSettings.value.mAutoDetectPoint = true;

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const createPointConstraint = (
	state: PhysicsState,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
	pointA: Vector3Like,
	pointB: Vector3Like,
) => {
	using constraintSettings = tmp(new Jolt.PointConstraintSettings(), release);
	constraintSettings.value.mSpace = Jolt.EConstraintSpace_LocalToBodyCOM;

	constraintSettings.value.mPoint1.Set(pointA.x, pointA.y, pointA.z);
	constraintSettings.value.mPoint2.Set(pointB.x, pointB.y, pointB.z);

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const createDistanceConstraint = (
	state: PhysicsState,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
	pointA: Vector3Like,
	pointB: Vector3Like,
	minDistance: number,
	maxDistance: number,
) => {
	using constraintSettings = tmp(new Jolt.DistanceConstraintSettings(), release);
	constraintSettings.value.mSpace = Jolt.EConstraintSpace_LocalToBodyCOM;

	constraintSettings.value.mPoint1.Set(pointA.x, pointA.y, pointA.z);
	constraintSettings.value.mPoint2.Set(pointB.x, pointB.y, pointB.z);
	constraintSettings.value.mMinDistance = minDistance;
	constraintSettings.value.mMaxDistance = maxDistance;

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const setDistanceConstraintDistance = (
	state: PhysicsState,
	constraintId: number,
	minDistance: number,
	maxDistance: number,
) => {
	const constraint = state.constraints.get(constraintId);
	if (!constraint) return;

	const distanceConstraint = Jolt.castObject(constraint.constraint, Jolt.DistanceConstraint);

	distanceConstraint.SetDistance(minDistance, maxDistance);
};

export const createHingeConstraint = (
	state: PhysicsState,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
	pointA: Vector3Like,
	pointB: Vector3Like,
	hingeAxisA: Vector3Like,
	hingeAxisB: Vector3Like,
	normalAxisA: Vector3Like,
	normalAxisB: Vector3Like,
	limitMin: number,
	limitMax: number,
) => {
	using constraintSettings = tmp(new Jolt.HingeConstraintSettings(), release);
	constraintSettings.value.mSpace = Jolt.EConstraintSpace_LocalToBodyCOM;

	constraintSettings.value.mPoint1.Set(pointA.x, pointA.y, pointA.z);
	constraintSettings.value.mPoint2.Set(pointB.x, pointB.y, pointB.z);
	constraintSettings.value.mHingeAxis1.Set(hingeAxisA.x, hingeAxisA.y, hingeAxisA.z);
	constraintSettings.value.mHingeAxis2.Set(hingeAxisB.x, hingeAxisB.y, hingeAxisB.z);
	constraintSettings.value.mNormalAxis1.Set(normalAxisA.x, normalAxisA.y, normalAxisA.z);
	constraintSettings.value.mNormalAxis2.Set(normalAxisB.x, normalAxisB.y, normalAxisB.z);
	constraintSettings.value.mLimitsMin = limitMin;
	constraintSettings.value.mLimitsMax = limitMax;

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const updateHingeConstraintMotorSettings = (
	state: PhysicsState,
	constraintId: number,
	minForceLimit: number,
	maxForceLimit: number,
	minTorqueLimit: number,
	maxTorqueLimit: number,
) => {
	const constraint = state.constraints.get(constraintId);
	if (!constraint) return;

	const hingeConstraint = Jolt.castObject(constraint.constraint, Jolt.HingeConstraint);

	const motorSettings = hingeConstraint.GetMotorSettings();
	motorSettings.mMaxForceLimit = maxForceLimit;
	motorSettings.mMinForceLimit = minForceLimit;
	motorSettings.mMaxTorqueLimit = maxTorqueLimit;
	motorSettings.mMinTorqueLimit = minTorqueLimit;
};

export const disableHingeConstraintMotor = (state: PhysicsState, constraintId: number) => {
	const constraint = state.constraints.get(constraintId);
	if (!constraint) return;

	const hingeConstraint = Jolt.castObject(constraint.constraint, Jolt.HingeConstraint);

	hingeConstraint.SetMotorState(Jolt.EMotorState_Off);
};

export const setHingeConstraintMotorTargetAngularVelocity = (
	state: PhysicsState,
	constraintId: number,
	targetVelocity: number,
) => {
	const constraint = state.constraints.get(constraintId);
	if (!constraint) return;

	const hingeConstraint = Jolt.castObject(constraint.constraint, Jolt.HingeConstraint);

	hingeConstraint.SetMotorState(Jolt.EMotorState_Velocity);
	hingeConstraint.SetTargetAngularVelocity(targetVelocity);
};

export const setHingeConstraintMotorTargetAngle = (
	state: PhysicsState,
	constraintId: number,
	targetAngle: number,
) => {
	const constraint = state.constraints.get(constraintId);
	if (!constraint) return;

	const hingeConstraint = Jolt.castObject(constraint.constraint, Jolt.HingeConstraint);

	hingeConstraint.SetMotorState(Jolt.EMotorState_Position);
	hingeConstraint.SetTargetAngle(targetAngle);
};

export const createSliderConstraint = (
	state: PhysicsState,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
	pointA: Vector3Like,
	pointB: Vector3Like,
	sliderAxisA: Vector3Like,
	sliderAxisB: Vector3Like,
	normalAxisA: Vector3Like,
	normalAxisB: Vector3Like,
	limitMin: number,
	limitMax: number,
) => {
	using constraintSettings = tmp(new Jolt.SliderConstraintSettings(), release);
	constraintSettings.value.mSpace = Jolt.EConstraintSpace_LocalToBodyCOM;

	constraintSettings.value.mPoint1.Set(pointA.x, pointA.y, pointA.z);
	constraintSettings.value.mPoint2.Set(pointB.x, pointB.y, pointB.z);
	constraintSettings.value.mSliderAxis1.Set(sliderAxisA.x, sliderAxisA.y, sliderAxisA.z);
	constraintSettings.value.mSliderAxis2.Set(sliderAxisB.x, sliderAxisB.y, sliderAxisB.z);
	constraintSettings.value.mNormalAxis1.Set(normalAxisA.x, normalAxisA.y, normalAxisA.z);
	constraintSettings.value.mNormalAxis2.Set(normalAxisB.x, normalAxisB.y, normalAxisB.z);
	constraintSettings.value.mLimitsMin = limitMin;
	constraintSettings.value.mLimitsMax = limitMax;

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const createConeConstraint = (
	state: PhysicsState,
	bodyA: PhysicsBody,
	bodyB: PhysicsBody,
	pointA: Vector3Like,
	pointB: Vector3Like,
	twistAxisA: Vector3Like,
	twistAxisB: Vector3Like,
	halfConeAngle: number,
) => {
	using constraintSettings = tmp(new Jolt.ConeConstraintSettings(), release);
	constraintSettings.value.mSpace = Jolt.EConstraintSpace_LocalToBodyCOM;

	constraintSettings.value.mPoint1.Set(pointA.x, pointA.y, pointA.z);
	constraintSettings.value.mPoint2.Set(pointB.x, pointB.y, pointB.z);
	constraintSettings.value.mTwistAxis1.Set(twistAxisA.x, twistAxisA.y, twistAxisA.z);
	constraintSettings.value.mTwistAxis2.Set(twistAxisB.x, twistAxisB.y, twistAxisB.z);
	constraintSettings.value.mHalfConeAngle = halfConeAngle;

	return makeTwoBodyConstraint(state, constraintSettings.value, bodyA, bodyB);
};

export const disposeConstraint = (state: PhysicsState, constraintId: number) => {
	const constraint = state.constraints.get(constraintId);

	if (constraint) {
		constraint.constraint.Release();
		state.physicsSystem.RemoveConstraint(constraint.constraint);
	}

	state.constraints.delete(constraintId);
};

/* Item Physics */
type ItemGeom = {
	type: "box";
	height: number;
	width: number;
	depth: number;
};

const createItemShape = (state: PhysicsState, geom: ItemGeom) => {
	if (geom.type === "box") {
		const halfExtents = _vector3.set(geom.width * 0.5, geom.height * 0.5, geom.depth * 0.5);
		return createBoxShape(state, halfExtents);
	}

	return createBoxShape(state, { x: 0.5, y: 0.5, z: 0.5 });
};

export type ItemPhysics = {
	body: PhysicsBody;
	sensor: PhysicsBody;
};

export const createItemPhysics = (
	state: PhysicsState,
	geom: ItemGeom,
	position: Vector3Like,
	quaternion: QuaternionLike,
	motionType: MotionType,
	prophecy: boolean,
	itemEntityId: EntityID,
): ItemPhysics => {
	/* create and add body */
	const shape = createItemShape(state, geom);

	const objectLayer = prophecy ? OBJECT_LAYER_ENTITY_COLLISION_DISABLED : OBJECT_LAYER_ITEM;

	const body = createBody(
		state,
		position,
		quaternion,
		shape,
		motionType,
		MotionQuality.DISCRETE,
		objectLayer,
		DEFAULT_ITEM_ENTITY_MATERIAL.mass,
		DEFAULT_ITEM_ENTITY_MATERIAL.friction,
		DEFAULT_ITEM_ENTITY_MATERIAL.restitution,
		false,
	);

	addBody(state, body);

	/* restrict DOF: no rotation, only translation */
	const degreesOfFreedom =
		Jolt.EAllowedDOFs_TranslationX | Jolt.EAllowedDOFs_TranslationY | Jolt.EAllowedDOFs_TranslationZ;

	body.GetMotionProperties().SetMassProperties(degreesOfFreedom, body.GetShape().GetMassProperties());

	/* create sensor */
	const sensor = createBody(
		state,
		position,
		quaternion,
		shape,
		MotionType.KINEMATIC,
		MotionQuality.DISCRETE,
		OBJECT_LAYER_SENSOR,
		0,
		0,
		0,
		false,
	);

	addBody(state, sensor);

	sensor.SetIsSensor(true);

	/* userdata */
	const userData: BodyUserData = {
		isEntity: true,
		isItem: true,
		entityId: itemEntityId,
	};

	state.userData.set(body.GetID().GetIndexAndSequenceNumber(), userData);
	state.userData.set(sensor.GetID().GetIndexAndSequenceNumber(), userData);

	return {
		body,
		sensor: sensor,
	};
};

export const disposeItemPhysics = (state: PhysicsState, itemPhysics: ItemPhysics) => {
	removeBody(state, itemPhysics.body);
	destroyBody(state, itemPhysics.body);

	removeBody(state, itemPhysics.sensor);
	destroyBody(state, itemPhysics.sensor);
};

/* Prop Physics */

const createBlockStructureAccurateShape = (
	blockTypeRegistry: BlockTypeRegistryState,
	blockStructure: IBlockStructure,
) => {
	/* create shape */
	using staticCompoundShapeSettings = tmp(new Jolt.StaticCompoundShapeSettings(), release);

	// get size of block structure
	const box = _box3.makeEmpty();
	for (const [x, y, z] of blockStructure.data.blocks) {
		box.expandByPoint(_vector3.set(x, y, z));
	}
	box.max.addScalar(1);
	const size = box.getSize(_size);

	// get offset to center the block structure
	const offset = _blockStructureOffset.copy(size).multiplyScalar(0.5);

	for (const [x, y, z, shape, blockTypeIndex] of blockStructure.data.blocks) {
		const adjustedX = x - box.min.x;
		const adjustedY = y - box.min.y;
		const adjustedZ = z - box.min.z;
		
		const blockTypeId = blockStructure.data.blockTypes[blockTypeIndex];

		const type = blockTypeRegistry.blockNameToType.get(blockTypeId);

		if (!type || type.collision !== BLOCK_COLLISION_ENABLED) continue;

		const blockPosition = _vector3.set(adjustedX, adjustedY, adjustedZ).sub(offset);

		const joltPosition = _jolt_position;
		joltPosition.Set(blockPosition.x + 0.5, blockPosition.y + 0.5, blockPosition.z + 0.5);

		const joltRotation = _jolt_quat;
		joltRotation.Set(0, 0, 0, 1);

		if (shape === BLOCK_CUBE) {
			const halfExtents = _jolt_halfExtents;
			halfExtents.Set(0.5, 0.5, 0.5);
			const boxShapeSettings = new Jolt.BoxShapeSettings(halfExtents);
			staticCompoundShapeSettings.value.AddShape(joltPosition, joltRotation, boxShapeSettings, 0);
		} else {
			const {
				physicsCollisionEnabledBuffer: { position: physicsPositions },
			} = ChunkBuilder.build([shape], [type.id], 1, 1, 1, blockTypeRegistry, false);

			const convexHullShapeSettings = new Jolt.ConvexHullShapeSettings();
			const nPoints = physicsPositions.length / 3;

			if (nPoints < 1) {
				continue;
			}

			for (let i = 0; i < physicsPositions.length; i++) {
				physicsPositions[i] = (physicsPositions[i] - 0.5) * 0.99;
			}

			convexHullShapeSettings.mPoints.resize(nPoints);
			for (let i = 0; i < nPoints; i++) {
				const idx = i * 3;
				convexHullShapeSettings.mPoints
					.at(i)
					.Set(physicsPositions[idx], physicsPositions[idx + 1], physicsPositions[idx + 2]);
			}

			staticCompoundShapeSettings.value.AddShape(
				joltPosition,
				joltRotation,
				convexHullShapeSettings,
				0,
			);
		}
	}

	const staticCompoundShapeResult = tmp(staticCompoundShapeSettings.value.Create(), destroyShapeResult);

	const staticCompoundShape = staticCompoundShapeResult.value.IsValid()
		? staticCompoundShapeResult.value.Get()
		: null;

	if (!staticCompoundShape) {
		logger.error(
			"Failed to create static compound shape for block structure:",
			staticCompoundShapeResult.value.GetError().c_str(),
		);
		return undefined;
	}

	staticCompoundShape.AddRef();

	return staticCompoundShape;
};

const _point = new Vector3();
const _box = new Box3();
const _offset = new Vector3();

const createBlockStructureConvexHullShape = (
	state: PhysicsState,
	blockTypeRegistry: BlockTypeRegistryState,
	blockStructure: IBlockStructure,
) => {
	/* create shape */
	using staticCompoundShapeSettings = tmp(new Jolt.StaticCompoundShapeSettings(), release);

	const size = new Vector3();

	const blocks = new LegacyVectorMap();
	const box = _box.makeEmpty();

	blockStructure.data.blocks.forEach(([x, y, z, shape, typeIndex]) => {
		blocks.set(x, y, z, {
			shape,
			type: blockStructure.data.blockTypes[typeIndex],
		});

		box.expandByPoint(_point.set(x, y, z));
		box.expandByPoint(_point.set(x + 1, y + 1, z + 1));
	});

	box.getSize(size);

	const offset = _offset.copy(size).multiplyScalar(0.5);

	const volume = size.x * size.y * size.z;
	const shapes = new Uint8Array(volume);
	const types = new Uint16Array(volume);

	blockStructure.data.blocks.forEach(([x, y, z, shape, typeIndex]) => {
		const type = blockStructure.data.blockTypes[typeIndex];

		const adjustedX = x - box.min.x;
		const adjustedY = y - box.min.y;
		const adjustedZ = z - box.min.z;

		const index = ChunkUtil.getIndex(size.x, size.y, size.z, adjustedX, adjustedY, adjustedZ);

		shapes[index] = shape;
		types[index] = blockTypeRegistry.blockNameToType.get(type)?.id ?? MISSING_BLOCK_TYPE.id;
	});

	const { physicsCollisionEnabledBuffer } = ChunkBuilder.build(
		shapes,
		types,
		size.x,
		size.y,
		size.z,
		blockTypeRegistry,
		true,
	);

	if (physicsCollisionEnabledBuffer.position.length > 0) {
		const physicsCollisionEnabledShapeSettings = createConvexHullShapeSettings(
			state,
			physicsCollisionEnabledBuffer.position,
		);

		if (physicsCollisionEnabledShapeSettings) {
			_jolt_position.Set(-offset.x, -offset.y, -offset.z);
			_jolt_quat.Set(0, 0, 0, 1);

			staticCompoundShapeSettings.value.AddShape(
				_jolt_position,
				_jolt_quat,
				physicsCollisionEnabledShapeSettings,
				0,
			);
		} else {
			logger.error("Failed to create convex hull shape settings for block structure.");
		}
	}

	const staticCompoundShapeResult = tmp(staticCompoundShapeSettings.value.Create(), destroyShapeResult);

	const staticCompoundShape = staticCompoundShapeResult.value.IsValid()
		? staticCompoundShapeResult.value.Get()
		: null;

	if (!staticCompoundShape) {
		logger.error(
			"Failed to create static compound shape for block structure:",
			staticCompoundShapeResult.value.GetError().c_str(),
		);
		return undefined;
	}

	staticCompoundShape.AddRef();

	return staticCompoundShape;
};

export const allocatePropColliderShape = (
	state: PhysicsState,
	content: JacyContent,
	blockTypeRegistry: BlockTypeRegistryState,
	collider: PropCollider,
	colliderId: string,
) => {
	if (collider.type === PropColliderType.NONE) {
		return true;
	}

	if (state.propShapes.has(colliderId)) {
		return true;
	}

	if (collider.type === PropColliderType.BLOCK_STRUCTURE) {
		const blockStructure = content.state.blockStructures.get(collider.blockStructurePk);
		if (!blockStructure) return false;

		if (collider.blockStructureColliderType === BlockStructureColliderType.ACCURATE) {
			const shape = createBlockStructureAccurateShape(blockTypeRegistry, blockStructure);
			if (!shape) return false;

			state.propShapes.set(colliderId, shape);
		} else if (collider.blockStructureColliderType === BlockStructureColliderType.CONVEX_HULL) {
			const shape = createBlockStructureConvexHullShape(state, blockTypeRegistry, blockStructure);
			if (!shape) return false;

			state.propShapes.set(colliderId, shape);
		} else {
			logger.error("Unknown block structure collider type:", collider.blockStructureColliderType);
			return false;
		}

		return true;
	}

	return false;
};

export const disposePropColliderShape = (state: PhysicsState, colliderId: string) => {
	const shape = state.propShapes.get(colliderId);

	if (shape) {
		state.propShapes.delete(colliderId);
		shape.Release();
	}
};

export type PropPhysics = {
	body: PhysicsBody;
	scaledShape?: JoltPhysics.ScaledShape;
};

export const createPropPhysics = (
	state: PhysicsState,
	collider: PropCollider,
	colliderId: string,
	collisionEnabled: boolean,
	position: Vector3Like,
	quaternion: QuaternionLike,
	motionType: MotionType,
	mass: number,
	friction: number,
	restitution: number,
	scale: number,
	propEntityId: EntityID,
): PropPhysics | undefined => {
	let scaledShape: JoltPhysics.ScaledShape | undefined = undefined;
	let propShape: JoltPhysics.Shape | JoltPhysics.ScaledShape;

	if (collider.type === PropColliderType.NONE) {
		propShape = _jolt_emptyShape;
	} else {
		const shape = state.propShapes.get(colliderId);

		if (!shape) {
			logger.error("Failed to find shape for prop collider:", colliderId);
			return undefined;
		}

		if (scale === 1) {
			propShape = shape;
		} else {
			_jolt_scale.Set(scale, scale, scale);
			scaledShape = new Jolt.ScaledShape(shape, _jolt_scale);
			scaledShape.AddRef();

			propShape = scaledShape;
		}
	}

	/* create and add body */
	let objectLayer: PhysicsObjectLayer;
	if (!collisionEnabled) {
		objectLayer = OBJECT_LAYER_ENTITY_COLLISION_DISABLED;
	} else if (motionType === MotionType.STATIC) {
		objectLayer = OBJECT_LAYER_PROP_NON_MOVING;
	} else {
		objectLayer = OBJECT_LAYER_PROP_MOVING;
	}

	const motionQuality =
		motionType === MotionType.DYNAMIC ? MotionQuality.LINEAR_CAST : MotionQuality.DISCRETE;

	const body = createBody(
		state,
		position,
		quaternion,
		propShape,
		motionType,
		motionQuality,
		objectLayer,
		mass,
		friction,
		restitution,
		false,
	);

	addBody(state, body);

	/* user data */
	const userData: BodyUserData = {
		isEntity: true,
		isProp: true,
		entityId: propEntityId,
	};

	state.userData.set(body.GetID().GetIndexAndSequenceNumber(), userData);

	return {
		body,
		scaledShape,
	};
};

export const disposePropPhysics = (state: PhysicsState, blockStructurePhysics: PropPhysics) => {
	removeBody(state, blockStructurePhysics.body);

	if (blockStructurePhysics.scaledShape) {
		blockStructurePhysics.scaledShape.Release();
	}

	destroyBody(state, blockStructurePhysics.body);
};

/* Character Controllers */

export type CharacterController = NonNullable<ReturnType<typeof createCharacterController>>;

export const createCharacterController = (
	state: PhysicsState,
	radius: number,
	padding: number,
	heightStanding: number,
	heightCrouching: number,
	crouching: boolean,
	prophecy: boolean,
	characterEntityId: EntityID,
) => {
	const cylinderHeightStanding = heightStanding - radius * 2;
	const cylinderHeightCrouching = heightCrouching - radius * 2;

	const cylinderHalfHeightStanding = cylinderHeightStanding * 0.5;
	const cylinderHalfHeightCrouching = cylinderHeightCrouching * 0.5;

	const positionStanding = new Jolt.Vec3(0, 0.5 * heightStanding, 0);
	const positionCrouching = new Jolt.Vec3(0, 0.5 * heightCrouching, 0);

	const rotation = _jolt_quat;
	rotation.Set(0, 0, 0, 1);

	using standingShapeSettings = tmp(
		new Jolt.RotatedTranslatedShapeSettings(
			positionStanding,
			rotation,
			new Jolt.CapsuleShapeSettings(cylinderHalfHeightStanding, radius),
		),
		release,
	);

	const standingShapeResult = tmp(standingShapeSettings.value.Create(), destroyShapeResult);

	if (!standingShapeResult.value.IsValid()) {
		logger.error(
			"Failed to create standing shape for character controller:",
			standingShapeResult.value.GetError().c_str(),
		);
		return undefined;
	}

	const standingShape = standingShapeResult.value.Get();
	standingShape.AddRef();

	using crouchingShapeSettings = tmp(
		new Jolt.RotatedTranslatedShapeSettings(
			positionCrouching,
			rotation,
			new Jolt.CapsuleShapeSettings(cylinderHalfHeightCrouching, radius),
		),
		release,
	);

	const crouchingShapeResult = tmp(crouchingShapeSettings.value.Create(), destroyShapeResult);

	if (!crouchingShapeResult.value.IsValid()) {
		logger.error(
			"Failed to create crouching shape for character controller:",
			crouchingShapeResult.value.GetError().c_str(),
		);
		return undefined;
	}

	const crouchingShape = crouchingShapeResult.value.Get();
	crouchingShape.AddRef();

	const currentShape = crouching ? crouchingShape : standingShape;

	using characterVirtualSettings = tmp(new Jolt.CharacterVirtualSettings(), release);
	characterVirtualSettings.value.mShape = currentShape;
	characterVirtualSettings.value.mInnerBodyShape = currentShape;
	characterVirtualSettings.value.mInnerBodyLayer = !prophecy
		? OBJECT_LAYER_CHARACTER
		: OBJECT_LAYER_ENTITY_COLLISION_DISABLED;
	characterVirtualSettings.value.mMass = 70;
	characterVirtualSettings.value.mMaxSlopeAngle = 55 * DEGRAD;
	characterVirtualSettings.value.mMaxStrength = 100;
	characterVirtualSettings.value.mBackFaceMode = Jolt.EBackFaceMode_IgnoreBackFaces;
	characterVirtualSettings.value.mCharacterPadding = padding;
	characterVirtualSettings.value.mPenetrationRecoverySpeed = 1.0;
	characterVirtualSettings.value.mPredictiveContactDistance = 0.05;

	// accept contacts as supporting that touch the lower sphere of the capsule
	characterVirtualSettings.value.mSupportingVolume = new Jolt.Plane(
		Jolt.Vec3.prototype.sAxisY(),
		-radius * 0.5,
	);

	const characterVirtual = new Jolt.CharacterVirtual(
		characterVirtualSettings.value,
		Jolt.RVec3.prototype.sZero(),
		Jolt.Quat.prototype.sIdentity(),
		state.physicsSystem,
	);

	characterVirtual.AddRef();

	const updateSettings = new Jolt.ExtendedUpdateSettings();

	// see: https://github.com/JamangoGame/JoltPhysics.js/blob/main/Jamango-CharacterController.h
	const characterController = new Jolt.JamangoCharacterController(characterVirtual);
	const characterContactListener = new Jolt.JamangoCharacterContactListener(characterController);

	characterVirtual.SetListener(characterContactListener);

	/* userdata */
	const userData: BodyUserData = {
		isCharacter: true,
		isEntity: true,
		entityId: characterEntityId,
	};

	state.userData.set(characterVirtual.GetInnerBodyID().GetIndexAndSequenceNumber(), userData);

	/* active contacts */
	const activeContacts: CharacterControllerContact[] = [];

	return {
		characterVirtual,
		standingShape,
		crouchingShape,
		updateSettings,
		characterController,
		characterContactListener,
		activeContacts,
	};
};

export const setCharacterControllerCrouching = (
	state: PhysicsState,
	controller: CharacterController,
	crouching: boolean,
) => {
	const shape = crouching ? controller.crouchingShape : controller.standingShape;

	// TODO: jolt should ideally be the authority on whether uncrouching is possible.
	// for now, provide a large inMaxPenetrationDepth value so uncrouching is basically always successful.
	const maxPenetrationDepth = 100;
	const success = controller.characterVirtual.SetShape(
		shape,
		maxPenetrationDepth, // TODO: more reasonable value could be `1.5 * state.physicsSystem.GetPhysicsSettings().mPenetrationSlop`
		state.defaultBroadPhaseLayerFilter,
		state.collidableChunkObjectLayerFilter,
		state.defaultBodyFilter,
		state.defaultShapeFilter,
		state.jolt.GetTempAllocator(),
	);

	if (success) {
		controller.characterVirtual.SetInnerBodyShape(shape);
	}
};

export const getCharacterControllerBody = (
	state: PhysicsState,
	controller: CharacterController,
): PhysicsBody => {
	const id = controller.characterVirtual.GetInnerBodyID();

	return state.physicsSystem.GetBodyLockInterfaceNoLock().TryGetBody(id);
};

export const getCharacterControllerLinearVelocity = (controller: CharacterController, out: Vector3) => {
	const velocity = controller.characterVirtual.GetLinearVelocity();
	out.set(velocity.GetX(), velocity.GetY(), velocity.GetZ());
};

export const getIsCharacterControllerSupported = (controller: CharacterController) => {
	return controller.characterVirtual.IsSupported();
};

export const getIsCharacterControllerOnGround = (controller: CharacterController) => {
	const groundState = controller.characterVirtual.GetGroundState();
	return groundState === Jolt.EGroundState_OnGround;
};

export const getIsCharacterControllerOnSteepGround = (controller: CharacterController) => {
	const groundState = controller.characterVirtual.GetGroundState();
	return groundState === Jolt.EGroundState_OnSteepGround;
};

export const getIsCharacterControllerInAir = (controller: CharacterController) => {
	const groundState = controller.characterVirtual.GetGroundState();
	return groundState === Jolt.EGroundState_InAir || groundState === Jolt.EGroundState_NotSupported;
};

export const getCharacterControllerShapeBoundsSize = (controller: CharacterController, out: Vector3) => {
	const bounds = controller.characterVirtual.GetTransformedShape().GetWorldSpaceBounds();
	const min = bounds.mMin;
	const max = bounds.mMax;

	out.set(max.GetX() - min.GetX(), max.GetY() - min.GetY(), max.GetZ() - min.GetZ());
};

export type CharacterControllerContact = {
	bodyIndexAndSequence: number;
	bodyUserData: BodyUserData | undefined;
	position: Vector3Like;
	normal: Vector3Like;
};

export type CharacterControllerRemovedContact = {
	bodyIndexAndSequence: number;
	bodyUserData: BodyUserData | undefined;
};

export const getCharacterControllerAABB = (controller: CharacterController, out: Box3) => {
	const bounds = controller.characterVirtual.GetTransformedShape().GetWorldSpaceBounds();
	const min = bounds.mMin;
	const max = bounds.mMax;

	out.min.set(min.GetX(), min.GetY(), min.GetZ());
	out.max.set(max.GetX(), max.GetY(), max.GetZ());
};

export const refreshCharacterControllerContacts = (
	state: PhysicsState,
	controller: CharacterController,
	characterCollisions: boolean,
) => {
	const objectLayerFilter = characterCollisions
		? state.collidableObjectLayerFilter
		: state.noCharactersCollidableObjectLayerFilter;

	controller.characterVirtual.RefreshContacts(
		state.defaultBroadPhaseLayerFilter,
		objectLayerFilter,
		state.defaultBodyFilter,
		state.defaultShapeFilter,
		state.jolt.GetTempAllocator(),
	);
};

export const updateCharacterControllerActiveContacts = (
	state: PhysicsState,
	controller: CharacterController,
) => {
	// update active contacts
	const joltActiveContacts = controller.characterVirtual.GetActiveContacts();

	controller.activeContacts.length = 0;

	for (let i = 0; i < joltActiveContacts.size(); i++) {
		const contact = joltActiveContacts.at(i);

		if (!contact.mHadCollision) continue;

		const bodyId = contact.mBodyB.GetIndexAndSequenceNumber();

		const position = {
			x: contact.mPosition.GetX(),
			y: contact.mPosition.GetY(),
			z: contact.mPosition.GetZ(),
		};

		const normal = {
			x: contact.mContactNormal.GetX(),
			y: contact.mContactNormal.GetY(),
			z: contact.mContactNormal.GetZ(),
		};

		const characterControllerContact: CharacterControllerContact = {
			bodyIndexAndSequence: bodyId,
			bodyUserData: state.userData.get(bodyId),
			position,
			normal,
		};

		controller.activeContacts.push(characterControllerContact);
	}
};

export const updateCharacterController = (
	state: PhysicsState,
	controller: CharacterController,
	dt: number,
	physicsPaused: boolean,
	velocity: Vector3Like,
	wishDir: Vector3Like,
	autoStepHeight: number,
	stickToFloorStepDown: number,
	isSlipperySurface: boolean,
	characterCollisions: boolean,
	mode: CharacterMovementMode,
) => {
	const characterVirtual = controller.characterVirtual;
	const innerBodyID = characterVirtual.GetInnerBodyID();

	// if in 'dynamic' mode, set the inner body to dynamic,
	// otherwise set it to kinematic, as the character virtual controller will maintain it's position
	const innerBodyMotionType =
		mode === CharacterMovementMode.DYNAMIC ? Jolt.EMotionType_Dynamic : Jolt.EMotionType_Kinematic;
	const innerBodyMotionQuality =
		mode === CharacterMovementMode.DYNAMIC
			? Jolt.EMotionQuality_LinearCast
			: Jolt.EMotionQuality_Discrete;

	state.bodyInterface.SetMotionType(innerBodyID, innerBodyMotionType, Jolt.EActivation_Activate);
	state.bodyInterface.SetMotionQuality(innerBodyID, innerBodyMotionQuality);

	if (mode === CharacterMovementMode.DYNAMIC) {
		// set the velocity of the inner body for the physics step
		_jolt_linearVelocity.Set(velocity.x, velocity.y, velocity.z);
		_jolt_angularVelocity.Set(0, 0, 0);
		state.bodyInterface.SetLinearVelocity(innerBodyID, _jolt_linearVelocity);
		state.bodyInterface.SetAngularVelocity(innerBodyID, _jolt_angularVelocity);
	} else {
		/* step the character controller with the kinematic character controller / jolt character virtual interface */

		// update character controller state - used by contact callbacks
		controller.characterController.SetOnSlipperySurface(isSlipperySurface);
		controller.characterController.SetWishDirection(wishDir.x, wishDir.y, wishDir.z);

		// update stair slope settings
		controller.updateSettings.mStickToFloorStepDown.Set(0, stickToFloorStepDown, 0);
		controller.updateSettings.mWalkStairsStepUp.Set(0, autoStepHeight, 0);

		// create step velocity, accounting for ground velocity / inertia
		const stepLinearVelocity = _vector3.copy(velocity);

		// todo: setting? based on some user data
		const onGround = characterVirtual.GetGroundState() === Jolt.EGroundState_OnGround;

		// update ground velocity
		characterVirtual.UpdateGroundVelocity();

		// NOTE: not accounting for up vector
		const currentVerticalVelocity = characterVirtual.GetLinearVelocity().GetY();

		const groundVelocity = characterVirtual.GetGroundVelocity();
		const movingTowardsGround = currentVerticalVelocity - groundVelocity.GetY() < 0.1;

		// don't apply inertia if the physics simulation is paused
		const enableInertia = !physicsPaused && onGround && movingTowardsGround;

		if (enableInertia && movingTowardsGround) {
			_groundVelocity.set(groundVelocity.GetX(), groundVelocity.GetY(), groundVelocity.GetZ());
			stepLinearVelocity.add(_groundVelocity);
		}

		_jolt_linearVelocity.Set(stepLinearVelocity.x, stepLinearVelocity.y, stepLinearVelocity.z);
		characterVirtual.SetLinearVelocity(_jolt_linearVelocity);

		const objectLayerFilter = characterCollisions
			? state.collidableObjectLayerFilter
			: state.noCharactersCollidableObjectLayerFilter;

		// before stepping, reset the contact listener contacts
		controller.characterContactListener.ClearContacts();

		// step the character virtual controller
		controller.characterController.Update(
			dt,
			characterVirtual.GetUp(),
			controller.updateSettings,
			state.defaultBroadPhaseLayerFilter,
			objectLayerFilter,
			state.defaultBodyFilter,
			state.defaultShapeFilter,
			state.jolt.GetTempAllocator(),
		);

		// update the inner body position
		// TODO: Why do we need to do this?
		const position = controller.characterVirtual.GetPosition();
		_jolt_r_position.Set(position.GetX(), position.GetY(), position.GetZ());
		state.bodyInterface.SetPosition(innerBodyID, _jolt_r_position, Jolt.EActivation_Activate);

		// gather contacts from step
		updateCharacterControllerActiveContacts(state, controller);

		const nAddedContacts = controller.characterContactListener.GetAddedSize();
		for (let i = 0; i < nAddedContacts; i++) {
			const contact = controller.characterContactListener.GetAddedAt(i);
			const body1IndexAndSequenceNumber = controller.characterVirtual
				.GetInnerBodyID()
				.GetIndexAndSequenceNumber();
			const body2IndexAndSequenceNumber = contact.bodyIndexAndSequenceNumber;

			state.contacts.added.push({
				body1IndexAndSequence: body1IndexAndSequenceNumber,
				body1UserData: state.userData.get(body1IndexAndSequenceNumber),
				body2IndexAndSequence: body2IndexAndSequenceNumber,
				body2UserData: state.userData.get(body2IndexAndSequenceNumber),
				worldSpaceNormalX: contact.worldSpaceNormalX,
				worldSpaceNormalY: contact.worldSpaceNormalY,
				worldSpaceNormalZ: contact.worldSpaceNormalZ,
			});
		}

		const nPersistedContacts = controller.characterContactListener.GetPersistedSize();
		for (let i = 0; i < nPersistedContacts; i++) {
			const contact = controller.characterContactListener.GetPersistedAt(i);
			const body1IndexAndSequenceNumber = controller.characterVirtual
				.GetInnerBodyID()
				.GetIndexAndSequenceNumber();
			const body2IndexAndSequenceNumber = contact.bodyIndexAndSequenceNumber;

			state.contacts.persisted.push({
				body1IndexAndSequence: body1IndexAndSequenceNumber,
				body1UserData: state.userData.get(body1IndexAndSequenceNumber),
				body2IndexAndSequence: body2IndexAndSequenceNumber,
				body2UserData: state.userData.get(body2IndexAndSequenceNumber),
				worldSpaceNormalX: contact.worldSpaceNormalX,
				worldSpaceNormalY: contact.worldSpaceNormalY,
				worldSpaceNormalZ: contact.worldSpaceNormalZ,
			});
		}

		const nRemovedContacts = state.contactListener.GetRemovedSize();
		for (let i = 0; i < nRemovedContacts; i++) {
			const contact = state.contactListener.GetRemovedAt(i);
			const body1IndexAndSequenceNumber = contact.body1IndexAndSequenceNumber;
			const body2IndexAndSequenceNumber = contact.body2IndexAndSequenceNumber;

			state.contacts.removed.push({
				body1IndexAndSequence: body1IndexAndSequenceNumber,
				body1UserData: state.userData.get(body1IndexAndSequenceNumber),
				body2IndexAndSequence: body2IndexAndSequenceNumber,
				body2UserData: state.userData.get(body2IndexAndSequenceNumber),
			});
		}
	}
};

export const setCharacterControllerPosition = (controller: CharacterController, position: Vector3Like) => {
	const joltPosition = _jolt_r_position;
	joltPosition.Set(position.x, position.y, position.z);

	controller.characterVirtual.SetPosition(joltPosition);
};

export const getCharacterControllerPosition = (controller: CharacterController, out: Vector3) => {
	const position = controller.characterVirtual.GetPosition();
	out.set(position.GetX(), position.GetY(), position.GetZ());
};

export const disposeCharacterController = (controller: CharacterController) => {
	controller.standingShape.Release();
	controller.crouchingShape.Release();
	controller.characterVirtual.Release();

	Jolt.destroy(controller.characterController);
	Jolt.destroy(controller.characterContactListener);
};

/* Contact utils */

export const areEntitiesInContact = (state: PhysicsState, entityA: Entity, entityB: Entity): boolean => {
	const bodyIdA = getEntityBodyID(entityA);
	const bodyIdB = getEntityBodyID(entityB);

	if (!bodyIdA || !bodyIdB) {
		return false;
	}

	const bodyAIndexAndSequence = bodyIdA.GetIndexAndSequenceNumber();
	const bodyBIndexAndSequence = bodyIdB.GetIndexAndSequenceNumber();

	if (entityIsCharacter(entityA)) {
		const activeContact = entityA.characterPhysics.state.activeContacts.some(
			(c) => c.bodyIndexAndSequence === bodyBIndexAndSequence,
		);

		if (activeContact) {
			return true;
		}
	}

	if (entityIsCharacter(entityB)) {
		const activeContact = entityB.characterPhysics.state.activeContacts.some(
			(c) => c.bodyIndexAndSequence === bodyAIndexAndSequence,
		);

		if (activeContact) {
			return true;
		}
	}

	return state.physicsSystem.WereBodiesInContact(bodyIdA, bodyIdB);
};
