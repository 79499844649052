import { MathUtils } from "three";
import { Character } from "base/world/entity//Character";
import { Entity } from "base/world/entity/Entity";
import { netState } from "router/Parallelogram";
import { CHARACTER_BOB_STATE_VALUES } from "base/world/entity/component/CharacterMovement";

export function characterMovementBobUpdate(entity: Entity, dt: number) {
	if (!canUpdateSystem(entity)) return;

	updateBobbing(entity, dt);
}

function canUpdateSystem(entity: Entity): entity is Character {
	return netState.isClient && entity.type.def.isCharacter;
}

function getBobStatus(entity: Character) {
	let status;

	if (entity.movement.state.isFlying) {
		status = "fly";
	} else if (!entity.collision.state.hitFoot) {
		status = "fall";
	} else if (entity.movement.state.horizontalSpeed > 0) {
		if (entity.movement.state.isSprinting) {
			status = "run";
		} else if (entity.movement.state.isCrouching) {
			status = "crouch";
		} else status = "walk";
	} else {
		status = "idle";
	}

	return status;
}

function updateBobbing(entity: Character, deltaTime: number) {
	const movementState = entity.movement.state;
	const characterCameraState = entity.characterCamera.state;

	if (entity.mount.state.parent) {
		movementState.bobPhaseTime = 0;
		movementState.bobSineValue = 0;
		movementState.bobSineValuePrevious = 0;

		characterCameraState.bobCameraOffset.set(0, 0, 0);

		return;
	}

	let phaseVelocity = 0;

	if (entity.collision.state.hitFoot && movementState.wishSpeed !== 0) {
		const minSpeed = 0;
		const maxSpeed = 10;
		const minPhaseVelocity = 10;
		const maxPhaseVelocity = 22;

		phaseVelocity = MathUtils.mapLinear(
			movementState.wishSpeed,
			minSpeed,
			maxSpeed,
			minPhaseVelocity,
			maxPhaseVelocity,
		);

		if (phaseVelocity > maxPhaseVelocity) {
			phaseVelocity = maxPhaseVelocity;
		}
	}

	if (phaseVelocity > 0) {
		movementState.bobPhaseTime += phaseVelocity * deltaTime;
	} else if (movementState.inputDisplacement.lengthSq() === 0) {
		movementState.bobPhaseTime = 0;
	}

	const bobSineValue = Math.sin(movementState.bobPhaseTime);
	const bobSineValueHalf = Math.sin(movementState.bobPhaseTime / 2);

	movementState.bobSineValuePrevious = movementState.bobSineValue;
	movementState.bobSineValue = bobSineValue;

	const bobStatus = getBobStatus(entity);

	const {
		itemSwayAmplitude: targetBobItemSwayAmplitude,
		horizontalAmplitude: targetBobHorizontalAmplitude,
		verticalAmplitude: targetBobVerticalAmplitude,
	} = CHARACTER_BOB_STATE_VALUES[bobStatus as keyof typeof CHARACTER_BOB_STATE_VALUES] ??
	CHARACTER_BOB_STATE_VALUES.default;

	if (phaseVelocity > 0) {
		const bobTargetLerpFactor = deltaTime * 15;

		movementState.bobItemSwayAmplitude = MathUtils.lerp(
			movementState.bobItemSwayAmplitude,
			targetBobItemSwayAmplitude,
			bobTargetLerpFactor,
		);

		if (movementState.bobItemSwayAmplitude > 0) {
			const bobPositionLerpFactor = deltaTime * 15;

			const bobValue = Math.pow(Math.abs(bobSineValueHalf), 2);
			const arcValue = Math.cos(movementState.bobPhaseTime / 2);

			characterCameraState.bobItemSwayOffset.x = MathUtils.lerp(
				characterCameraState.bobItemSwayOffset.x,
				arcValue * movementState.bobItemSwayAmplitude,
				bobPositionLerpFactor,
			);
			characterCameraState.bobItemSwayOffset.y = MathUtils.lerp(
				characterCameraState.bobItemSwayOffset.y,
				-bobValue * movementState.bobItemSwayAmplitude,
				bobPositionLerpFactor,
			);
		}

		movementState.bobHorizontalAmplitude = MathUtils.lerp(
			movementState.bobHorizontalAmplitude,
			targetBobHorizontalAmplitude,
			bobTargetLerpFactor,
		);

		if (movementState.bobHorizontalAmplitude > 0) {
			characterCameraState.bobCameraOffset.x = bobSineValueHalf * movementState.bobHorizontalAmplitude;
		}

		movementState.bobVerticalAmplitude = MathUtils.lerp(
			movementState.bobVerticalAmplitude,
			targetBobVerticalAmplitude,
			bobTargetLerpFactor,
		);

		if (movementState.bobVerticalAmplitude > 0) {
			characterCameraState.bobCameraOffset.y = bobSineValue * movementState.bobVerticalAmplitude;
		}
	} else {
		movementState.bobItemSwayAmplitude = 0;
		movementState.bobHorizontalAmplitude = 0;
		movementState.bobVerticalAmplitude = 0;

		const resetLerpFactor = deltaTime * 15;

		characterCameraState.bobCameraOffset.x = MathUtils.lerp(
			characterCameraState.bobCameraOffset.x,
			0,
			resetLerpFactor,
		);
		characterCameraState.bobCameraOffset.y = MathUtils.lerp(
			characterCameraState.bobCameraOffset.y,
			0,
			resetLerpFactor,
		);

		characterCameraState.bobItemSwayOffset.x = MathUtils.lerp(
			characterCameraState.bobItemSwayOffset.x,
			0,
			resetLerpFactor,
		);
		characterCameraState.bobItemSwayOffset.y = MathUtils.lerp(
			characterCameraState.bobItemSwayOffset.y,
			0,
			resetLerpFactor,
		);
	}
}
