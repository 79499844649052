import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { getEntity } from "base/rete/modules/validate";
import * as Physics from "base/world/Physics";
import { findHitBlock } from "base/world/Raycast";
import type { Entity } from "base/world/entity/Entity";
import { entityIsCharacter, entityIsItem, entityIsProp } from "base/world/entity/component/Type";
import { Vector3 } from "three";

const _velocity = new Vector3();
const _direction = new Vector3();

const _raycastResult = Physics.initRaycastResult();

export const PHYSICS_NODES = [
	node({
		id: "9015a3ad-d148-4c97-b512-0b80f69962d1",
		name: "Entity Get Velocity",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current velocity of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			velocity: { name: "Velocity", type: "vector3", icon: "Speedometer" },
		},
		resolve(inputs) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const velocity = new Vector3();

			if (entityIsCharacter(entity) || entityIsProp(entity) || entityIsItem(entity)) {
				entity.getLinearVelocity(velocity);
			}

			return {
				velocity,
			};
		},
	}),
	node({
		id: "0efe4808-a010-440e-aede-2f5e6c2dfd6b",
		name: "Entity Get Angular Velocity",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current angular velocity of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			velocity: { name: "Velocity", type: "vector3", icon: "Speedometer" },
		},
		resolve(inputs) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const velocity = new Vector3();

			if (entityIsProp(entity)) {
				entity.getAngularVelocity(velocity);
			}

			return {
				velocity,
			};
		},
	}),
	node({
		id: "c1cbb9ad-b832-4179-b3ed-5b9d1d5d6e82",
		name: "Entity Set Velocity",
		type: NODE_TYPE_ID.function.entity,
		predictable: true,
		description: "Sets the velocity of the entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			velocity: {
				name: "Velocity",
				type: "vector3",
				control: "vector3",
				icon: "Speedometer",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const velocity = inputs.velocity;
			if (entityIsCharacter(entity) || entityIsProp(entity) || entityIsItem(entity)) {
				// desync if non-predictable
				entity.setLinearVelocity(velocity, !closure.predictable);
			}
		},
	}),
	node({
		id: "66cdc87d-2075-4767-88a3-b0151d12a401",
		name: "Entity Set Angular Velocity",
		type: NODE_TYPE_ID.function.entity,
		predictable: true,
		description: "Sets the angular velocity of the entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			velocity: {
				name: "Velocity",
				type: "vector3",
				control: "vector3",
				icon: "Speedometer",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const velocity = inputs.velocity;

			if (entityIsProp(entity)) {
				// desync if non-predictable
				entity.setAngularVelocity(velocity, !closure.predictable);
			}
		},
	}),
	node({
		id: "f9208814-791d-483b-a94f-3b0cf1498f22",
		name: "Entity Add Velocity",
		type: NODE_TYPE_ID.function.entity,
		predictable: true,
		description: "Adds velocity to the entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			velocity: {
				name: "Velocity",
				type: "vector3",
				control: "vector3",
				icon: "Speedometer",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const addVelocity = inputs.velocity;

			if (entityIsCharacter(entity) || entityIsProp(entity) || entityIsItem(entity)) {
				// desync if non-predictable
				const newVelocity = entity.getLinearVelocity(_velocity);
				newVelocity.add(addVelocity);
				entity.setLinearVelocity(newVelocity, !closure.predictable);
			}
		},
	}),
	node({
		id: "875f2e46-ad08-43c2-bf5d-60862f74c11c",
		name: "Entity Add Angular Velocity",
		type: NODE_TYPE_ID.function.entity,
		predictable: true,
		description: "Adds angular velocity to the entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			velocity: {
				name: "Velocity",
				type: "vector3",
				control: "vector3",
				icon: "Speedometer",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, _ctx, _id, _scope, closure) {
			const entity = inputs.entity;

			if (!entity) {
				throw Error("No entity provided");
			}

			const addVelocity = inputs.velocity;

			if (entityIsProp(entity)) {
				// desync if non-predictable
				const newVelocity = entity.getAngularVelocity(_velocity);
				newVelocity.add(addVelocity);
				entity.setAngularVelocity(newVelocity, !closure.predictable);
			}
		},
	}),
	node({
		id: "4b319abc-c2a1-4eb2-a67c-3e5e5eafe57b",
		name: "Raycast",
		type: NODE_TYPE_ID.function.world,
		predictable: true,
		description: "Raycast from a point in a direction.",
		inputs: {
			exec: { type: "exec" },
			origin: { name: "Origin", type: "vector3", control: "vector3" },
			direction: { name: "Direction", type: "vector3", control: "vector3" },
			distance: {
				name: "Distance",
				type: "number",
				control: "number",
				config: { defaultValue: 100 },
			},
			ignoreEntities: {
				name: "Ignore Entities",
				type: "entity",
				structure: "list",
				control: "entity",
				optional: true,
				config: { defaultValue: undefined },
			},
		},
		outputs: {
			exec: { type: "exec" },
			hit: { name: "Hit", type: "boolean" },
			hitPoint: { name: "Hit Point", type: "vector3" },
			hitNormal: { name: "Hit Normal", type: "vector3" },
			hitDistance: { name: "Hit Distance", type: "number" },
			hitEntity: { name: "Hit Entity", type: "entity" },
			hitBlock: { name: "Hit Block", type: "vector3" },
		},
		execute: (inputs, ctx, nodeId, scope) => {
			const direction = _direction.copy(inputs.direction).normalize();

			Physics.raycastAnyCollidable(
				ctx.world.physics,
				inputs.origin,
				direction,
				inputs.distance,
				inputs.ignoreEntities,
				_raycastResult,
			);

			const hit = _raycastResult.hit;
			const isChunk = (hit && _raycastResult.userData?.isChunk) ?? false;
			const isEntity = (hit && _raycastResult.userData?.isEntity) ?? false;
			const entityId = isEntity ? _raycastResult.userData?.entityId : undefined;

			let hitBlock: Vector3 | undefined = undefined;
			let hitEntity: Entity | undefined = undefined;
			let hitDistance = inputs.distance;

			if (hit) {
				if (isChunk) {
					hitBlock = new Vector3();
					findHitBlock(
						inputs.origin,
						_raycastResult.hitPosition,
						_raycastResult.hitNormal,
						hitBlock,
					);
				} else if (isEntity && entityId !== undefined) {
					hitEntity = ctx.world.getEntity(entityId);
				}

				hitDistance = _raycastResult.hitPosition.distanceTo(inputs.origin);
			}

			scope[nodeId] = {
				hit,
				hitBlock,
				hitEntity,
				hitDistance,
				hitPosition: _raycastResult.hitPosition.clone(),
				hitNormal: _raycastResult.hitNormal.clone(),
			};
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				hit: scope[nodeId].hit,
				hitPoint: scope[nodeId].hitPosition,
				hitNormal: scope[nodeId].hitNormal,
				hitDistance: scope[nodeId].hitDistance,
				hitEntity: scope[nodeId].hitEntity,
				hitBlock: scope[nodeId].hitBlock,
			};
		},
	}),
	node({
		id: "403883cd-2448-4a49-b748-7b11325f2cd4",
		name: "Character Ray Result",
		type: NODE_TYPE_ID.function.character,
		predictable: true,
		description: "Gets the result of the view ray for a character.",
		info: [
			"NOTE: Currently only player characters have raycast results populated.",
			"Pass 'origin' and 'direction' to Raycast to get results for NPC characters.",
		],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Character", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
			hit: { name: "Hit", type: "boolean" },
			hitPoint: { name: "Hit Point", type: "vector3" },
			hitNormal: { name: "Hit Normal", type: "vector3" },
			hitDistance: { name: "Hit Distance", type: "number" },
			hitEntity: { name: "Hit Entity", type: "entity" },
			hitBlock: { name: "Hit Block", type: "vector3" },
			origin: { name: "Origin", type: "vector3" },
			direction: { name: "Direction", type: "vector3" },
			maxDistance: { name: "Max Distance", type: "number" },
		},
		execute: (inputs, ctx, nodeId, scope) => {
			const entity = getEntity(inputs, ctx, "entity");

			if (!entityIsCharacter(entity)) {
				throw new Error("Entity is not a character");
			}

			scope[nodeId] = {
				hit: entity.viewRaycast.state.hitNormal,
				hitBlock: entity.viewRaycast.state.block,
				hitEntity:
					entity.viewRaycast.state.character ??
					entity.viewRaycast.state.prop ??
					entity.viewRaycast.state.item,
				hitDistance: entity.viewRaycast.state.hitDistance,
				hitPosition: _raycastResult.hitPosition,
				hitNormal: _raycastResult.hitNormal,
				origin: entity.viewRaycast.state.rayPos,
				direction: entity.viewRaycast.state.rayDir,
				maxDistance: entity.viewRaycast.state.distance,
			};
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				hit: scope[nodeId].hit,
				hitPoint: scope[nodeId].hitPosition,
				hitNormal: scope[nodeId].hitNormal,
				hitDistance: scope[nodeId].hitDistance,
				hitEntity: scope[nodeId].hitEntity,
				hitBlock: scope[nodeId].hitBlock,
				origin: scope[nodeId].origin,
				direction: scope[nodeId].direction,
				maxDistance: scope[nodeId].maxDistance,
			};
		},
	}),
	node({
		id: "ba4ed3e6-1c5a-4e1c-8e41-f45c5e8a5640",
		name: "Entity Set Degrees Of Freedom",
		type: NODE_TYPE_ID.function.character,
		predictable: true,
		description: "Sets the degrees of freedom for an entities physics body.",
		info: ["Currently only supports Prop entities"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			translationX: {
				name: "Translation X",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			translationY: {
				name: "Translation Y",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			translationZ: {
				name: "Translation Z",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			rotationX: {
				name: "Rotation X",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			rotationY: {
				name: "Rotation Y",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			rotationZ: {
				name: "Rotation Z",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute: (inputs, ctx, _nodeId, _scope) => {
			const entity = getEntity(inputs, ctx, "entity");

			if (!entityIsProp(entity)) {
				throw new Error("Entity is not a prop");
			}

			entity.propPhysics.def.dof = Physics.makeDegreesOfFreedom(
				inputs.translationX,
				inputs.translationY,
				inputs.translationZ,
				inputs.rotationX,
				inputs.rotationY,
				inputs.rotationZ,
			);
		},
	}),
];
