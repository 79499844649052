export class CameraShakeComponent {
	name = "cameraShake";

	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.def = {
			shakeVertical: o.shakeVertical ?? 0,
			shakeHorizontal: o.shakeHorizontal ?? 0,
			shakeVerticalMax: o.shakeVerticalMax ?? 0,
			shakeHorizontalMax: o.shakeHorizontalMax ?? 0,
			shakeRecovery: o.shakeRecovery ?? 0,
		};

		this.state = {
			shakeVertical: 0,
			shakeHorizontal: 0,
		};
	}
}
