import { BufferAttribute, BufferGeometry, Vector3 } from "three";

/**
 * A utility function that takes a geometry, and returns an indexed geometry with merged positions and indices.
 *
 * The resulting geometry does not retain other attributes like normals, uvs, etc.
 *
 * Attributes other than position are not considered when merging.
 *
 * Assumes that the input geometry has a position attribute with itemSize 3.
 *
 * @param {THREE.BufferGeometry} geometry
 * @param {number} tolerance
 */
export function mergeVerticesByPositions(geometry, tolerance = 0.001) {
	const positionAttribute = geometry.attributes.position;

	let index = geometry.getIndex()?.array;

	if (!index) {
		const ascendingIndex = [];
		for (let i = 0; i < positionAttribute.count; i++) {
			ascendingIndex.push(i);
		}

		geometry.setIndex(ascendingIndex);
		index = ascendingIndex;
	}

	const mergedPositions = [];
	const mergedIndices = [];

	const positionToIndex = {};

	let mergedIndexCounter = 0;

	const positions = positionAttribute.array;

	const position = new Vector3();

	for (let i = 0; i < index.length; i++) {
		const pt = index[i] * 3;

		position.set(positions[pt], positions[pt + 1], positions[pt + 2]);

		position.x = Math.round(position.x / tolerance) * tolerance;
		position.y = Math.round(position.y / tolerance) * tolerance;
		position.z = Math.round(position.z / tolerance) * tolerance;

		const key = `${position.x}_${position.y}_${position.z}`;

		let idx = positionToIndex[key];

		if (idx === undefined) {
			positionToIndex[key] = idx = mergedIndexCounter;
			mergedPositions.push(position.x, position.y, position.z);
			mergedIndexCounter++;
		}

		mergedIndices.push(idx);
	}

	const mergedGeometry = new BufferGeometry();
	mergedGeometry.setAttribute("position", new BufferAttribute(new Float32Array(mergedPositions), 3));
	mergedGeometry.setIndex(mergedIndices);

	return mergedGeometry;
}

/**
 * Scales the input geometry along its normals.
 *
 * This mutates the positions of the input geometry.
 *
 * @param {THREE.BufferGeometry} geometry the geometry to scale
 * @param {number} scaleFactor the scale factor
 * @returns the input geometry
 */
export function scaleGeometryAlongNormals(geometry, scaleFactor) {
	const positions = geometry.getAttribute("position");
	const normals = geometry.getAttribute("normal");

	const vertexNormal = new Vector3();
	for (let i = 0; i < positions.count; i++) {
		vertexNormal.fromBufferAttribute(normals, i).normalize();

		positions.array[i * 3] += vertexNormal.x * scaleFactor;
		positions.array[i * 3 + 1] += vertexNormal.y * scaleFactor;
		positions.array[i * 3 + 2] += vertexNormal.z * scaleFactor;
	}

	return geometry;
}
