import type { IProp } from "@jamango/content-client";
import { PropMeshType } from "@jamango/content-client";
import type { BufferGeometry, Material, Mesh } from "three";
import { Vector3 } from "three";

export type PropMesh = IProp["mesh"];

const hashMesh = (mesh: PropMesh) => {
	if (mesh.type === PropMeshType.BLOCK_STRUCTURE)
		return `${PropMeshType.BLOCK_STRUCTURE}#${mesh.blockStructurePk}`;

	return PropMeshType.NONE.toString();
};

export class PropMeshComponent {
	name = "propMesh";

	def: {
		mesh: PropMesh;
		meshHash: string;
	};

	state: {
		meshHash: string;
		meshes: Mesh[];
		geometries: BufferGeometry[];
		materials: Material[];
		meshSize: Vector3;
	};

	constructor(_o: any, _entity: any) {
		const mesh: PropMesh = {
			type: PropMeshType.NONE,
		};

		this.def = {
			mesh,
			meshHash: hashMesh(mesh),
		};

		this.state = {
			meshHash: this.def.meshHash,
			meshes: [],
			geometries: [],
			materials: [],
			meshSize: new Vector3(),
		};
	}

	updateMesh(mesh: PropMesh) {
		this.def.mesh = mesh;
		this.def.meshHash = hashMesh(mesh);
	}
}
