export class ItemEjectComponent {
	name = "eject";

	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.def = {
			impulse: o.ejectImpulse ?? 5,
		};
	}
}
