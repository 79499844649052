import type { IGameMechanics } from "../types";

export const SLIDER_MIN_PLAYERS = 1;
export const SLIDER_MAX_PLAYERS = 24;
export const P2P_MAX_PLAYERS = 4;

export const DEFAULT_GAME_MECHANICS: Pick<IGameMechanics, "maxPlayers" | "characterCollisions"> = {
	maxPlayers: SLIDER_MAX_PLAYERS,
	characterCollisions: true,
};
