import { Box3, Vector3 } from "three";
import { Vector3Set } from "base/util/math/VectorStorage";

const _position = new Vector3();

const _neighbour = new Vector3();

export type BlockIsland = {
	blocks: Vector3Set;
	bounds: Box3;
};

const AXES = ["x", "y", "z"] as const;
const DIRS = [-1, 1] as const;

/**
 * Finds connected "islands" of blocks within a set of blocks
 */
export const getBlockIslands = (
	blocks: Vector3Set,
	include?: (x: number, y: number, z: number) => boolean,
) => {
	const islands: BlockIsland[] = [];

	const visited = new Vector3Set();

	const stack: Vector3[] = [];

	const visit = (position: Vector3, island: BlockIsland) => {
		if (visited.has(position)) return;
		if (include && !include(position.x, position.y, position.z)) return;

		visited.add(position);

		const cloned = position.clone();

		island.blocks.add(cloned);
		island.bounds.expandByPoint(cloned);

		stack.push(cloned);
	};

	for (const position of blocks.iterate(_position)) {
		if (visited.has(position) || (include && !include(position.x, position.y, position.z))) continue;

		const island = { blocks: new Vector3Set(), bounds: new Box3() };

		visit(position, island);

		while (stack.length > 0) {
			const current = stack.pop()!;

			for (const axis of AXES) {
				for (const dir of DIRS) {
					const neighbor = _neighbour.copy(current);
					neighbor[axis as "x" | "y" | "z"] += dir;

					if (blocks.has(neighbor)) {
						visit(neighbor, island);
					}
				}
			}
		}

		if (islands) islands.push(island);
	}

	return islands;
};
