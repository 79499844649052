import { type IPackage, PackageUserRole } from "./schema";

export type IPublicPackageData = {
	packageId: string;
	versionId: string;
	role?: PackageUserRole;
	name: string;
	installed?: {
		versionId: string;
	};
	price: number;
	shortDescription?: string;
	longDescription?: string;
	thumbnailUrl?: string;
	removed?: boolean;
	visibility: IPackage["visibility"];
};

export const CREATE_PACKAGE_PERMISSONS: PackageUserRole[] = [
	PackageUserRole.OWNER,
	PackageUserRole.COLLABORATOR,
];
export const REMOVE_PACKAGE_PERMISSONS: PackageUserRole[] = [PackageUserRole.OWNER];
export const UPDATE_PACKAGE_PERMISSONS: PackageUserRole[] = [
	PackageUserRole.OWNER,
	PackageUserRole.COLLABORATOR,
];
