import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsRunning extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsRunning", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToFallingTime = 0.2;
		this.transitionToJumpingTime = 0.1;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.forwardWeight = 1.0;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(`${item}_Sprint`, new AnimationTrack(animMesh, `JMGO_${item}_Sprint`, this));
		});

		this.forwardTrack = this.trackMap.get(`Wrench_Sprint`);
		this.lastTrack = this.forwardTrack;
		this.trackToSyncWith = this.lastTrack;
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;

		const currentItem = this.inputState.item;
		this.previousItem = transitionData.prvState.inputState.item;

		if (currentItem) {
			this.forwardTrack = this.trackMap.get(`${currentItem}_Sprint`);
		}

		this.forwardTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Idle");
			this.forwardTrack.action.syncWith(idleAction);

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();

		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			// temporary hack, shouldn't be needed
			this.trackMap.forEach((track) => {
				track.action.setEffectiveWeight(0);
			});

			return;
		}

		this.updateWeight();

		if (this.inputState.item) {
			if (this.previousItem !== this.inputState.item) {
				const currentItemAnimations = this.inputState.item;
				this.previousItem = currentItemAnimations;

				if (currentItemAnimations) {
					this.forwardTrack = this.trackMap.get(`${currentItemAnimations}_Sprint`);
				} else {
					this.forwardTrack = this.trackMap.get(`Spade_Sprint`);
				}

				this.trackMap.forEach((track) => {
					track.action.setEffectiveWeight(0);
				});

				this.trackToSyncWith = this.lastTrack;
				this.lastTrack = this.forwardTrack;

				if (this.lastTrack) {
					this.forwardTrack.action.reset();

					this.forwardTrack.action.syncWith(this.trackToSyncWith.action);

					this.forwardTrack.action.play();
					this.forwardTrack.action.setEffectiveWeight(this.weight);
				}
			}
		}

		this.forwardTrack.action.setEffectiveWeight(this.weight);

		this.prvMovementInput = this.inputState.movementInput;
		this.previousItem = this.inputState.item;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingSprint");
			outTransitionToState.blendTime = 0.2;
			return true;
		}

		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("WeaponsCrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (!this.inputState.item) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = 0.2;
			return true;
		}

		if (this.inputState.isMoving) {
			if (!this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("WeaponsJogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
				return true;
			}
		}

		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("WeaponsIdle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
	}
}
