import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateFlyingSprint extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("FlyingSprint", animMesh, locomotionInput, transitions);

		this.directionsBlendTime = 0.3;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToFlyingIdleTime = 0.2;
		this.transitionToFlyingTime = 0.2;

		this.forwardWeight = 1.0;

		this.forwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Sprint", this);
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;

		this.forwardTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Fly_Idle");
			this.forwardTrack.action.syncWith(idleAction);
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.forwardTrack.action.setEffectiveWeight(0);
			return;
		}
		this.updateWeight();
		this.updateWeightsInternal();
		this.forwardTrack.action.setEffectiveWeight(this.weight);

		this.prvMovementInput = this.inputState.movementInput;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (!this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (!this.inputState.isSprinting) {
			outTransitionToState.ptr = map.get("Flying");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		// transition to flyingIdle state
		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
	}

	updateWeightsInternal() {
		this.updateBlendPoses();
	}
}
