import { Settings } from "base/world/Settings.js";
import { SettingsClient } from "client/world/Settings.js";
import { netState } from "router/Parallelogram";
import * as Net from "router/Net";

// See excalidraw for explanation: https://app.excalidraw.com/s/4ImBfDoFuN3/7EpuHijKOb9
export class SettingsRouter {
	constructor(world, options) {
		// Initializing the Base
		this.base = new Settings(world, options);

		// If it's not a dedicated server, we're gonna setup the client settings
		if (netState.isClient) {
			this.base.client = new SettingsClient(this.base, world);
		}

		// The router symbol will always point to this class
		this.base.router = this;
	}

	/**
	 * change - responsible for handling the setting changes to the client
	 * @param {Object} settings - Environment settings
	 * @param {boolean} isNet - apply env settings to everyone
	 */
	change(settings, isNet) {
		if (netState.isHost) {
			this.serverChange(settings, isNet);
		} else {
			if (isNet) {
				// Since a client only has connection to the host server, we will inform the server to apply the settings to everyone connected to the server
				Net.sendToAll("scene_envpreset_sync", [JSON.stringify(settings), isNet]);
			} else {
				this.serverChange(settings, isNet);
			}
		}
	}

	/**
	 * serverChange - responsible for networking. Directs the command to the client / server
	 * @param {Object} settings - Environment settings
	 * @param {boolean} isNet - apply env settings to everyone
	 */
	serverChange(settings, isNet) {
		if (isNet) {
			Net.sendToAll("scene_envpreset", JSON.stringify(settings));
		}

		if (netState.isClient) {
			this.base.client.change(settings);
		}

		this.base.change(settings);
	}

	getEnvironment() {
		return this.base.environment;
	}

	/**
	 * initCommandListeners - initialize the event listeners for multiplayer
	 */
	initCommandListeners() {
		const base = this.base;

		if (netState.isHost) {
			// When it's a server add this listener which will be responsible for applying the settings in all clients connected
			Net.listen("scene_envpreset_sync", function (args) {
				const settings = args[0];
				const isNet = args[1];
				base.router.serverChange(JSON.parse(settings), isNet);
			});
		} else {
			// When it's a client, this will fire the change function which applies the env settings
			Net.listen("scene_envpreset", function (args) {
				base.client.change(JSON.parse(args));
				base.change(JSON.parse(args));
			});
		}
	}
}
