import { BB } from "base/BB";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { DEGRAD } from "base/util/math/Math";
import { Box3, Euler, Matrix4, Vector3 } from "three";
import { node } from "base/rete/Types";
import { ShapeUtil } from "base/world/block/Util";
import type { IBlockStructure } from "@jamango/content-client";

const type = NODE_TYPE_ID.blueprint;

const ROTATION_OPTIONS = [0, 90, 180, 270].map((deg) => ({
	value: deg,
	label: `${deg}°`,
}));

const tmpVector3 = new Vector3();
const tmpRotationOrigin = new Vector3();
const tmpBox = new Box3();

function findBottomMostCenterPoint(blockStructure: IBlockStructure) {
	const blocks = blockStructure.data.blocks;

	let minY = blocks[0][1];

	blocks.forEach(([_x, y, _z]) => {
		if (y < minY) {
			minY = y;
		}
	});

	tmpBox.makeEmpty();

	blocks.forEach(([x, y, z]) => {
		if (y !== minY) return;
		tmpVector3.set(x, y, z);
		tmpBox.expandByPoint(tmpVector3);
	});

	tmpBox.getCenter(tmpRotationOrigin).floor();

	return tmpRotationOrigin;
}

export const BLUEPRINT_NODES = [
	node({
		id: "641e2bcc-c312-4284-b000-8580d042cce7",
		name: "Blueprint Spawn",
		type,
		description: "Spawn a Blueprint in the world.",
		inputs: {
			exec: { name: "Exec", type: "exec" },
			config: {
				name: "Config",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => {
						return BB.world.content.state.blockStructures.getAll().map((blockStructure) => ({
							value: blockStructure.pk,
							label: blockStructure.name,
						}));
					},
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "number",
				control: "select",
				config: {
					autoSortOptions: () => ROTATION_OPTIONS,
				},
			},
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
		},
		execute(inputs, ctx) {
			const inputPos = inputs.position;
			const world = ctx.world;

			const config = inputs.config;

			if (config === undefined) return;

			let blockStructure = BB.world.content.state.blockStructures.get(config);
			if (!blockStructure) {
				// could not find direct id - search by name
				blockStructure = Object.values(BB.world.content.state.blockStructures.blockStructures).find((b) => {
					return b.name === config;
				})!; // TODO fix the return type inferrence of BB.world.content.state.blockStructures.get, so this "!" can be removed
			}
			if (!blockStructure) {
				console.error("cound not find block structure: " + config);
				return;
			}

			let xOffset = inputPos?.x ?? 0;
			let yOffset = (inputPos?.y ?? 0) + 1; // will be placed on top of it
			let zOffset = inputPos?.z ?? 0;

			const yDegrees = inputs.rotation;
			const yRotations = yDegrees / 90;
			// negate degrees when converting to rotate clockwise
			const yRadians = -yDegrees * DEGRAD;

			const rotationMatrix = new Matrix4().makeRotationFromEuler(new Euler(0, yRadians, 0, "XYZ"));

			const triggerEvents = true;

			tmpRotationOrigin.copy(findBottomMostCenterPoint(blockStructure));

			xOffset -= tmpRotationOrigin.x;
			yOffset -= tmpRotationOrigin.y;
			zOffset -= tmpRotationOrigin.z;

			blockStructure.data.blocks.forEach(([x, y, z, shape, typeIndex]) => {
				const type = blockStructure.data.blockTypes[typeIndex];

				const position = tmpVector3.set(x, y, z);
				position.sub(tmpRotationOrigin);
				position.applyMatrix4(rotationMatrix);
				position.add(tmpRotationOrigin);
				position.round();

				if (ShapeUtil.isSculpted(shape)) {
					shape = ShapeUtil.rotate(shape, 0, yRotations, 0);
				}

				position.x += xOffset;
				position.y += yOffset;
				position.z += zOffset;

				world.scene.setBlock(position, shape, type, triggerEvents);
			});
		},
	}),
];
