import { PI, AXES } from "base/util/math/Math.ts";
import { Vector3, Vector2 } from "three";
import { ChunkUtil } from "base/world/block/Util.js";

export const AO_FACTOR = 0.8;

const triangleEdges = [
	[0, 1, 2],
	[1, 2, 0],
	[2, 0, 1],
	[3, 0, 1],
	[3, 0, 2],
];
const tmpVec = new Vector3();
const vec31 = new Vector3();
const vec32 = new Vector3();
const vec33 = new Vector3();
const vec21 = new Vector2();
const vec22 = new Vector2();
const vec23 = new Vector2();

export function calculateAO(buffer, w, h, d) {
	/*
	variable name key
	ii = "index index" (index of buffer.index)
	bi = block index
	vi = vertex index
	vp = vertex position
	vn = vertex normal
	ao = ambient occlusion
	ei = edge index 
	added s at the end = array
	*/
	buffer.ao = new Float32Array(ChunkUtil.getVertexCount(buffer));

	const totalBlocks = w * h * d;

	const quads = [];
	const blockEdges = new Map();
	const blockVertices = new Map();

	const sharedEdges = [];
	const sharedVertices = [];

	//step 1/5: loop through each triangle in the buffer. organize the data into quads, edges, and vertices
	for (let ii = 0; ii < buffer.index.length; ii += 3) {
		const vi1 = buffer.index[ii];
		const vi2 = buffer.index[ii + 1];
		const vi3 = buffer.index[ii + 2];

		const vis = [vi1, vi2, vi3];

		const vps = [
			new Vector3().fromArray(buffer.position, vis[0] * 3),
			new Vector3().fromArray(buffer.position, vis[1] * 3),
			new Vector3().fromArray(buffer.position, vis[2] * 3),
		];

		const vn = new Vector3().fromArray(buffer.normal, vis[0] * 3);
		const vnId = ChunkUtil.encodeNormal(vn.x, vn.y, vn.z); // Normal encoding (0-26 range): 5 bits (since 2^5 = 32, which can accommodate 27 values)

		//check if this triangle is part of a quad because it may need to have its diagonal flipped later
		let quad = null;
		if (ii < buffer.index.length - 3) {
			//the last triangle in the buffer can't be the start of a quad because there isn't a second triangle after it
			const vi4 = buffer.index[ii + 3];
			const vi5 = buffer.index[ii + 4];
			const vi6 = buffer.index[ii + 5];

			//to check if a triangle belongs to quad, search for this pattern of vertex indices in the indices array: 0 1 2 3 2 1
			//first three triangle's vis will always have 0 1 2 pattern so it is only necessary to check the next three vis
			if (vi4 - vi1 === 3 && vi5 - vi1 === 2 && vi6 - vi1 === 1) {
				quad = { vi1, vi2, vi3, vi4, ii, edges23: 0, edges14: 0 };
				quads.push(quad);

				vis.push(buffer.index[ii + 3]);
				vps.push(new Vector3().fromArray(buffer.position, vis[3] * 3));
			}
		}

		//for each edge of the triangle
		for (let ei = 0; ei < triangleEdges.length; ei++) {
			//last 2 edges in triangleEdges make up a quad's alternate diagonal. they are not applicable if no quad was detected
			if (!quad && ei === 3) break;

			const eis = triangleEdges[ei];

			//v1 and v2 make up the edge, v3 is the other point in the triangle
			const vp1 = vps[eis[0]];
			const vp2 = vps[eis[1]];
			const vp3 = vps[eis[2]];

			const vi1 = vis[eis[0]];
			const vi2 = vis[eis[1]];
			const vi3 = vis[eis[2]];
			const axis = vp1.isAxisAligned(vp2);

			const id1 = ChunkUtil.getIndex(w + 1, h + 1, d + 1, vp1.x, vp1.y, vp1.z);
			const id2 = ChunkUtil.getIndex(w + 1, h + 1, d + 1, vp2.x, vp2.y, vp2.z);

			const edge = { vp1, vp2, vp3, vi1, vi2, vn, axis };
			if (quad && (ei === 1 || ei === 3)) {
				edge.quad = quad;
				edge.ei = ei;
			}

			// push edge
			const edgeId = id1 > id2 ? id1 + id2 * totalBlocks : id2 + id1 * totalBlocks;
			if (!blockEdges.has(edgeId)) {
				const a = [];
				blockEdges.set(edgeId, a);
				sharedEdges.push(a);
			}
			blockEdges.get(edgeId).push(edge);

			if (ei >= 3) continue;

			// push vert
			const posId = ChunkUtil.getIndex(w + 1, h + 1, d + 1, vp3.x, vp3.y, vp3.z);
			const vertId = (posId << 5) | vnId;
			if (!blockVertices.has(vertId)) {
				const a = [];
				blockVertices.set(vertId, a);
				sharedVertices.push(a);
			}
			blockVertices.get(vertId).push({ vp: vp3, vi: vi3, vn });
		}
	}

	//step 3/5: calculate ao based on the angle between shared edges
	for (let i = 0; i < sharedEdges.length; ++i) {
		const edges = sharedEdges[i];
		if (edges.length < 2) continue;

		//let the quad flipper know how many edges are shared along each quad diagonal
		for (let e = 0; e < edges.length; ++e) {
			const { quad, ei } = edges[e];
			if (quad) {
				if (ei === 1)
					//normal quad diagonal
					quad.edges23 = edges.length;
				else if (ei === 3)
					//flipped quad diagonal
					quad.edges14 = edges.length;
			}
		}

		//loop through every possible pair of edges (i and j)
		for (let ei1 = 0; ei1 < edges.length - 1; ei1++) {
			const edge1 = edges[ei1];

			NEXT_EDGE_PAIR: for (let ei2 = ei1 + 1; ei2 < edges.length; ei2++) {
				const edge2 = edges[ei2];

				//measure angle between faces
				const angle = PI - edge1.vn.angleTo(edge2.vn);
				if (angle >= PI) continue; //mathematically angleTo will never return > PI

				//both positive: facing each other not at a reflex angle (creates ao)
				//both negative: facing each other at a reflex angle
				//different signs: not facing each other, can't determine angle
				if (
					edge1.vn.dot(tmpVec.copy(edge2.vp3).sub(edge1.vp3)) <= 0 ||
					edge2.vn.dot(tmpVec.copy(edge1.vp3).sub(edge2.vp3)) <= 0
				)
					continue;

				//make sure there isn't a third face inbetween the other 2 faces
				//axis property is the same for each edge in edges
				if (edges.length >= 3 && edge1.axis) {
					for (let ei3 = 0; ei3 < edges.length; ei3++) {
						if (ei3 === ei1 || ei3 === ei2) continue;
						const edge3 = edges[ei3];

						vec31.copy(edge1.vp3).sub(edge1.vp1);
						vec32.copy(edge2.vp3).sub(edge2.vp1);
						vec33.copy(edge3.vp3).sub(edge3.vp1);
						vec21.set(0, 0);
						vec22.set(0, 0);
						vec23.set(0, 0);
						//condense the vec3 into vec2 by removing the axis/component that the edge is aligned to
						let vec2Axis = 0;
						for (const vec3Axis of AXES) {
							if (vec3Axis !== edge1.axis) {
								vec21[AXES[vec2Axis]] = vec31[vec3Axis];
								vec22[AXES[vec2Axis]] = vec32[vec3Axis];
								vec23[AXES[vec2Axis]] = vec33[vec3Axis];
								vec2Axis++;
							}
						}

						if (
							vec23.equals(vec22) ||
							vec23.equals(vec21) ||
							(vec21.cross(vec23) * vec21.cross(vec22) >= 0 &&
								vec22.cross(vec23) * vec22.cross(vec21) >= 0)
						)
							continue NEXT_EDGE_PAIR;
					}
				}

				const ao = (1 - angle / PI) * AO_FACTOR; //0 = no ao, 1 = black vertex
				buffer.ao[edge1.vi1] = Math.max(ao, buffer.ao[edge1.vi1]);
				buffer.ao[edge1.vi2] = Math.max(ao, buffer.ao[edge1.vi2]);
				buffer.ao[edge2.vi1] = Math.max(ao, buffer.ao[edge2.vi1]);
				buffer.ao[edge2.vi2] = Math.max(ao, buffer.ao[edge2.vi2]);
			}
		}
	}

	//step 4/5: calculate ao based on the angle between shared vertices. this part of the ao algorithm is incomplete
	//to make it good enough for now, all vertices with identical position and normal should have the same ao value
	for (let i = 0; i < sharedVertices.length; i++) {
		let ao = 0;
		for (let j = 0; j < sharedVertices[i].length; j++) {
			const vertex = sharedVertices[i][j];
			ao = Math.max(ao, buffer.ao[vertex.vi]);
		}
		if (ao > 0) {
			for (let k = 0; k < sharedVertices[i].length; k++) {
				const vertex = sharedVertices[i][k];
				buffer.ao[vertex.vi] = ao;
			}
		}
	}

	//step 5/5: quad diagonal flipping to properly interpolate ao across 2 triangles
	//the geometry builder always runs the diagonal between vi2 and vi3 and this detects when to flip it between vi1 and vi4
	for (let i = 0; i < quads.length; ++i) {
		const quad = quads[i];
		//quad.edges length of 2 means that the diagonals are not shared with any other block/face. 3 or more and they are

		let flipQuad;
		if (quad.edges14 >= 3) {
			flipQuad = true;
		} else if (quad.edges23 >= 3) {
			flipQuad = false;
		} //they are both be 2
		else {
			const ao1 = buffer.ao[quad.vi1];
			const ao2 = buffer.ao[quad.vi2];
			const ao3 = buffer.ao[quad.vi3];
			const ao4 = buffer.ao[quad.vi4];

			//do not even bother trying to interpret this
			flipQuad =
				(ao1 + ao4 > ao2 + ao3 && !(ao1 > ao2 + ao3 + ao4 || ao4 > ao1 + ao2 + ao3)) ||
				ao2 > ao1 + ao3 + ao4 ||
				ao3 > ao1 + ao2 + ao4;

			if (ao1 === ao4 && ao2 === ao3 && (ao1 > ao2 || ao2 > ao1)) flipQuad = !flipQuad;
		}

		if (flipQuad) {
			//before: 0 1 2 3 2 1
			//after:  0 1 3 3 2 0
			buffer.index[quad.ii + 2] = quad.vi1 + 3;
			buffer.index[quad.ii + 5] = quad.vi1;
		}
	}
}
