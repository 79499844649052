import { ParticleSetting } from "./ParticleSetting";
import type { Blending, EulerOrder, Object3D, Scene, Vector4Like } from "three";
import {
	Vector3,
	BufferGeometry,
	Points,
	InstancedBufferGeometry,
	NormalBlending,
	AdditiveBlending,
	MultiplyBlending,
	SubtractiveBlending,
	Vector4,
	Quaternion,
	Euler,
	BufferAttribute,
	InstancedInterleavedBuffer,
	InterleavedBufferAttribute,
	InterleavedBuffer,
	DynamicDrawUsage,
	ShaderMaterial,
	DoubleSide,
	FrontSide,
	DataTexture,
	Texture,
	RGBAFormat,
	LinearFilter,
	Sphere,
} from "three";

type Mutable<T> = {
	-readonly [P in keyof T]: Mutable<T[P]>;
};

type TweenType =
	| "linear"
	| "inQuad"
	| "outQuad"
	| "inOutQuad"
	| "inCubic"
	| "outCubic"
	| "inOutCubic"
	| "inQuart"
	| "outQuart"
	| "inOutQuart"
	| "inQuint"
	| "outQuint"
	| "inOutQuint"
	| "outSine"
	| "inSine"
	| "inOutSine"
	| "outBack"
	| "inBack"
	| "inOutBack"
	| "outElastic"
	| "inElastic"
	| "inOutElastic"
	| "outBounce"
	| "inBounce"
	| "inOutBounce"
	| "outCircle"
	| "inCircle"
	| "inOutCircle"
	| "outCirc"
	| "inCirc"
	| "inOutCirc"
	| "inExpo"
	| "outExpo"
	| "inOutExpo";

type BlendingType = "additive" | "normal" | "multiply" | "multi" | "sub" | "subtractive";

type PredefinedShapes =
	| "pixel"
	| "basic"
	| "cube"
	| "cloud"
	| "round"
	| "donut"
	| "bubble"
	| "circle"
	| "field";

const DEFAULT_PARTICLE_SPEC = {
	parent: null as Object3D | null,
	position: [0, 0, 0],
	rotation: [0, 0, 0],
	name: "default",
	type: "round" as PredefinedShapes,
	tween: "linear" as TweenType,
	numParticles: 1,
	numFrames: 1,
	frameDuration: 1,
	frameStart: 0,
	frameStartRange: 0,
	timeRange: 99999999,
	startTime: null as number | null,
	lifeTime: 1,
	endTime: -1,
	lifeTimeRange: 0,
	sizeRange: 0,
	startSize: 1,
	startSizeRange: 0,
	endSize: 1,
	endSizeRange: 0,
	pposition: [0, 0, 0],
	positionRange: [0, 0, 0],
	velocity: [0, 0, 0],
	velocityRange: [0, 0, 0],
	acceleration: [0, 0, 0],
	accelerationRange: [0, 0, 0],
	spinStart: 0,
	spinStartRange: 0,
	spinSpeed: 0,
	spinSpeedRange: 0,
	colorMult: [1, 1, 1, 1],
	colorMultRange: [0, 0, 0, 0],
	worldVelocity: [0, 0, 0],
	gravity: [0, 0, 0],
	oriented: false,
	orientation: [0, 0, 0, 1],
	colors: [1, 1, 1, 1],
	blending: "additive" as BlendingType,
	radius: 0,
	radiusRange: 0,
	tmpRotation: null as [number, number, number, EulerOrder] | null,
	alphaTest: 0,
	renderOrder: 0,
	luma: true,
	depthWrite: false,
	transparent: true,
};

export type ParticleParameters = typeof DEFAULT_PARTICLE_SPEC;

export class ParticleEngine {
	private readonly emitters = new Map<string, Emitter>();
	readonly textures = new TextureMap();

	delta = 0;
	private num = 0;
	hscale = window.innerHeight * 0.5;
	luminosity = 1.0;
	private waterLevel = 4.3;

	constructor(readonly scene: Scene) {}

	get<T extends Emitter>(name: string) {
		if (!this.emitters.has(name)) return null;
		return this.emitters.get(name) as T;
	}

	add(o: Partial<ParticleParameters>) {
		if (!o.name) o.name = "PP" + this.num++;

		// remove old if same name
		this.remove(o.name);

		const emitter = new Emitter(this, o);
		this.emitters.set(o.name, emitter);

		return emitter;
	}

	addTrail(o: ConstructorParameters<typeof Trail>[1]) {
		if (!o.name) o.name = "PP" + this.num++;
		// remove old if same name
		this.remove(o.name);

		const trail = new Trail(this, o);
		this.emitters.set(o.name, trail);

		return trail;
	}

	remove(name: string) {
		const exists = this.emitters.get(name);

		if (!exists) return;

		exists.dispose();

		this.emitters.delete(name);
	}

	onresize(h: number) {
		this.hscale = h * 0.5;
	}

	update(frameDeltaTime: number) {
		this.delta = frameDeltaTime;

		this.emitters.forEach((e) => {
			e.draw();
		});
	}

	dispose() {
		this.emitters.forEach((e) => {
			e.dispose();
		});
		this.emitters.clear();
		// clear all temp textures
		this.textures.dispose();
		this.num = 0;
	}

	///// FOR BLOCK ADD / REMOVE /////

	addBlock(pos: number[]) {
		// underwater
		const order = pos[1] <= this.waterLevel ? -2 : 0;

		pos[1] -= 0.5;

		this.add({
			position: pos,
			renderOrder: order,
			...(ParticleSetting.addBlock as Mutable<typeof ParticleSetting.addBlock>),
		});
	}

	delBlock(p: number[]) {
		// underwater
		const order = p[1] <= this.waterLevel ? -2 : 0;

		this.add({
			position: p,
			positionRange: [0.5, 0.5, 0.5],
			numParticles: 30,
			renderOrder: order,
			...(ParticleSetting.removeBlock as Mutable<typeof ParticleSetting.removeBlock>),
		});
	}

	///// FOR PLAYER /////

	removePlayerTrail(uuid: string) {
		this.remove("PlayerTrail_" + uuid);
	}

	onPlayerWalk(p: Vector3, uuid: string) {
		let trail = this.get<Trail>("PlayerTrail_" + uuid);
		if (!trail) {
			trail = this.addTrail({
				name: "PlayerTrail_" + uuid,
				...(ParticleSetting.playerMove as Mutable<typeof ParticleSetting.playerMove>),
			});
		}

		const pos = p.toArray();
		pos[1] += 0.2;
		trail.birthParticles(pos, [1, 1, 1, 1, 1, 1, 1, 0]);
	}
}

const CORNERS_ = [
	[-0.5, -0.5],
	[+0.5, -0.5],
	[+0.5, +0.5],
	[-0.5, +0.5],
];

const POSITION_START_TIME_IDX = 0;
const UV_LIFE_TIME_FRAME_START_IDX = 4;
const VELOCITY_START_SIZE_IDX = 8;
const ACCELERATION_END_SIZE_IDX = 12;
const SPIN_START_SPIN_SPEED_IDX = 16;
const ORIENTATION_IDX = 20;
const COLOR_MULT_IDX = 24;
const LAST_IDX = 28;

const singleParticleArray_ = new Float32Array(1 * LAST_IDX);

export function validateParticleParameters<T extends Partial<ParticleParameters>>(o: T): ParticleParameters {
	const defaults = DEFAULT_PARTICLE_SPEC;

	for (const key in o) {
		if (typeof defaults[key as any as keyof typeof defaults] === "undefined")
			throw `unknown particle parameter "${key}"`;
	}

	for (const key in defaults) {
		if (typeof o[key as any as keyof typeof o] === "undefined") {
			// @ts-expect-error ignore ts error
			o[key] = defaults[key];
		}
	}

	return o as ParticleParameters;
}

const tools = {
	torad: Math.PI / 180,
	todeg: 180 / Math.PI,
	random: () => Math.random(),
	rand: (low: number, high: number) => low + tools.random() * (high - low),
	randInt: (low: number, high: number) => low + Math.floor(tools.random() * (high - low + 1)),
	plusMinus: (range: number) => (tools.random() - 0.5) * range * 2,
	plusMinusVector: (range: number[]) => {
		const v = [];
		let i = range.length;
		while (i--) v.push(tools.plusMinus(range[i]));
		return v;
	},
	createTextureFromFloats: (
		width: number,
		height: number,
		pixels: ArrayLike<number>,
		opt_texture?: Texture,
	) => {
		if (opt_texture) return opt_texture;

		const data = new Uint8Array(pixels.length);
		let t;
		for (let i = 0; i < pixels.length; i++) {
			t = pixels[i] * 255;
			data[i] = t;
		}

		const texture = new DataTexture(data, width, height, RGBAFormat);
		texture.minFilter = LinearFilter;
		texture.magFilter = LinearFilter;
		texture.needsUpdate = true;

		return texture;
	},
};

class Emitter extends Points {
	private count = 0;
	private color: Texture | null = null;
	private texture: Texture | null = null;
	private localTime = 0;
	protected time = 0;
	protected endTime = -1;
	private num = 0;
	private luma = true;
	private isMesh = false;

	private interleavedBuffer: InterleavedBuffer;

	declare material: ShaderMaterial;

	constructor(
		protected readonly pe: ParticleEngine,
		o?: Partial<ParticleParameters>,
	) {
		super();

		this.matrixAutoUpdate = false;
		this.frustumCulled = false;
		this.receiveShadow = false;
		this.castShadow = false;

		if (o) this.setParameters(o);
	}

	setParameters(o: Partial<ParticleParameters>) {
		const parameters = validateParticleParameters(o);
		this.name = parameters.name;
		const numParticles = parameters.numParticles;

		if (this.geometry) this.geometry.dispose();
		if (this.material) this.material.dispose();

		this.geometry = parameters.oriented ? new InstancedBufferGeometry() : new BufferGeometry();
		this.isMesh = parameters.oriented;

		this.allocateParticles_(numParticles, parameters);
		this.createParticles_(0, numParticles, parameters);

		const parent = parameters.parent ?? this.pe.scene;
		parent.add(this);
	}

	setColorRamp(colorRamp: number[]) {
		const width = colorRamp.length / 4;
		if (width % 1 !== 0) throw "colorRamp must have multiple of 4 entries";
		//if ( this.color == this.pe.defaultColor ) this.color = null;
		this.color = tools.createTextureFromFloats(width, 1, colorRamp);
	}

	perParticle(_index: number, _parameters: ParticleParameters) {}

	createParticles_(firstParticleIndex: number, numParticles: number, o: ParticleParameters) {
		/*if( o.position ) this.position.fromArray( o.position );
		this.setColorRamp( o.colors )
		this.texture = this.pe.textures.make(o.type);
		this.endTime = o.endTime || -1;*/

		const plusMinus = tools.plusMinus;
		const plusMinusVector = tools.plusMinusVector;
		const inter = this.interleavedBuffer.array;

		let i = numParticles;
		let n;

		while (i--) {
			this.perParticle(i, o);

			const pLifeTime = o.lifeTime + plusMinus(o.lifeTimeRange);
			const pStartTime = o.startTime === null ? (i * o.lifeTime) / numParticles : o.startTime;
			const pFrameStart = o.frameStart + plusMinus(o.frameStartRange);
			let pPosition = new Vector3().addVectors(
				new Vector3().fromArray(o.pposition),
				new Vector3().fromArray(plusMinusVector(o.positionRange)),
			);
			const pVelocity = new Vector3().addVectors(
				new Vector3().fromArray(o.velocity),
				new Vector3().fromArray(plusMinusVector(o.velocityRange)),
			);
			const pAcceleration = new Vector3().addVectors(
				new Vector3().fromArray(o.acceleration),
				new Vector3().fromArray(plusMinusVector(o.accelerationRange)),
			);
			const pColorMult = new Vector4().addVectors(
				new Vector4().fromArray(o.colorMult),
				new Vector4().fromArray(plusMinusVector(o.colorMultRange)),
			);
			const pSpinStart = o.spinStart + plusMinus(o.spinStartRange);
			const pSpinSpeed = o.spinSpeed + plusMinus(o.spinSpeedRange);
			const pStartSize = o.startSize + plusMinus(o.startSizeRange ?? o.sizeRange);
			const pEndSize = o.endSize + plusMinus(o.endSizeRange ?? o.sizeRange);
			let pOrientation: Vector4Like = new Vector4().fromArray(o.orientation);

			let t;

			const pRangeZero =
				o.positionRange[0] + o.positionRange[1] + o.positionRange[2] === 0 ? true : false;

			if (o.radius) {
				const angle = tools.rand(0, 2 * Math.PI);
				o.tmpRotation = [90, -angle * tools.todeg, 90, "YXZ"];
				const distance = o.radius;
				const radialP = new Vector3(Math.cos(angle), 0, Math.sin(angle)).multiplyScalar(distance); //.add( new Vector3().fromArray(plusMinusVector(o.positionRange)));
				//pPosition = new Vector3(Math.cos(angle), 0, Math.sin(angle)).multiplyScalar(distance).add( new Vector3().fromArray(plusMinusVector(o.positionRange)));
				if (pRangeZero) pPosition = radialP;
				// const len = pPosition.length();
				//pVelocity.copy(pPosition).multiplyScalar(len)
				t = pAcceleration.y;
				pAcceleration.multiply(radialP); //pPosition)
				pAcceleration.y = t;

				t = pVelocity.y;
				pVelocity.multiply(radialP); //pPosition)
				//pVelocity.y = t
			}

			if (o.tmpRotation) {
				pOrientation = new Quaternion().setFromEuler(
					new Euler(
						o.tmpRotation[0] * tools.torad,
						o.tmpRotation[1] * tools.torad,
						o.tmpRotation[2] * tools.torad,
						o.tmpRotation[3],
					),
				);
			}

			const jj = 0;
			n = LAST_IDX * jj + i * LAST_IDX * 4 + firstParticleIndex * LAST_IDX * 4;

			inter[POSITION_START_TIME_IDX + n] = pPosition.x;
			inter[POSITION_START_TIME_IDX + n + 1] = pPosition.y;
			inter[POSITION_START_TIME_IDX + n + 2] = pPosition.z;
			inter[POSITION_START_TIME_IDX + n + 3] = pStartTime;

			inter[UV_LIFE_TIME_FRAME_START_IDX + n] = CORNERS_[jj][0];
			inter[UV_LIFE_TIME_FRAME_START_IDX + n + 1] = CORNERS_[jj][1];
			inter[UV_LIFE_TIME_FRAME_START_IDX + n + 2] = pLifeTime;
			inter[UV_LIFE_TIME_FRAME_START_IDX + n + 3] = pFrameStart;

			inter[VELOCITY_START_SIZE_IDX + n] = pVelocity.x;
			inter[VELOCITY_START_SIZE_IDX + n + 1] = pVelocity.y;
			inter[VELOCITY_START_SIZE_IDX + n + 2] = pVelocity.z;
			inter[VELOCITY_START_SIZE_IDX + n + 3] = pStartSize;

			inter[ACCELERATION_END_SIZE_IDX + n] = pAcceleration.x;
			inter[ACCELERATION_END_SIZE_IDX + n + 1] = pAcceleration.y;
			inter[ACCELERATION_END_SIZE_IDX + n + 2] = pAcceleration.z;
			inter[ACCELERATION_END_SIZE_IDX + n + 3] = pEndSize;

			inter[SPIN_START_SPIN_SPEED_IDX + n] = pSpinStart;
			inter[SPIN_START_SPIN_SPEED_IDX + n + 1] = pSpinSpeed;
			inter[SPIN_START_SPIN_SPEED_IDX + n + 2] = 0;
			inter[SPIN_START_SPIN_SPEED_IDX + n + 3] = 0;

			inter[ORIENTATION_IDX + n] = pOrientation.x;
			inter[ORIENTATION_IDX + n + 1] = pOrientation.y;
			inter[ORIENTATION_IDX + n + 2] = pOrientation.z;
			inter[ORIENTATION_IDX + n + 3] = pOrientation.w;

			inter[COLOR_MULT_IDX + n] = pColorMult.x;
			inter[COLOR_MULT_IDX + n + 1] = pColorMult.y;
			inter[COLOR_MULT_IDX + n + 2] = pColorMult.z;
			inter[COLOR_MULT_IDX + n + 3] = pColorMult.w;
		}

		this.interleavedBuffer.needsUpdate = true;

		this.material.uniforms.worldVelocity.value.fromArray(o.worldVelocity);
		this.material.uniforms.gravity.value.fromArray(o.gravity);
		this.material.uniforms.timeRange.value = o.timeRange;
		this.material.uniforms.frameDuration.value = o.frameDuration;
		this.material.uniforms.numFrames.value = o.numFrames;
		this.material.uniforms.rampSampler.value = this.color;
		this.material.uniforms.colorSampler.value = this.texture;

		this.material.blending = o.blending === "normal" ? NormalBlending : AdditiveBlending;

		this.updateMatrix();
	}

	protected allocateParticles_(numParticles: number, o: ParticleParameters) {
		if (this.count !== numParticles) {
			if (!o.oriented) o.oriented = false;

			if (o.position) this.position.fromArray(o.position);
			if (o.rotation)
				this.quaternion.setFromEuler(
					new Euler(
						o.rotation[0] * tools.torad,
						o.rotation[1] * tools.torad,
						o.rotation[2] * tools.torad,
					),
				);

			this.setColorRamp(o.colors);

			this.texture = this.pe.textures.make(o.type);
			this.endTime = o.endTime || -1;

			// if endTime not set use upper bound for lifeTime
			if (this.endTime === -1) this.endTime = o.lifeTime + o.lifeTimeRange;

			this.luma = o.luma;

			//var numIndices = 6 * numParticles;

			//if (numIndices > 65536 && BufferGeometry.MaxIndex < 65536) throw "can't have more than 10922 particles per emitter";

			this.count = numParticles;

			if (o.oriented) {
				// Use vertexBuffer, starting at offset 0, 3 items in position attribute
				// Use vertexBuffer, starting at offset 4, 2 items in uv attribute
				const vertexBuffer = new InterleavedBuffer(
					new Float32Array([
						// Front
						0, 0, 0, 0, -0.5, -0.5, 0, 0, 0, 0, 0, 0, 0.5, -0.5, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0, 0,
						0, 0, 0, 0, -0.5, 0.5, 0, 0,
					]),
					8,
				);

				this.geometry.setAttribute("position", new InterleavedBufferAttribute(vertexBuffer, 3, 0));
				this.geometry.setAttribute("uv", new InterleavedBufferAttribute(vertexBuffer, 2, 4));
				this.geometry.setIndex(new BufferAttribute(new Uint16Array([0, 1, 2, 0, 2, 3]), 1));
				this.interleavedBuffer = new InstancedInterleavedBuffer(
					new Float32Array(numParticles * singleParticleArray_.byteLength),
					LAST_IDX,
					1,
				).setUsage(DynamicDrawUsage);
			} else {
				this.interleavedBuffer = new InterleavedBuffer(
					new Float32Array(numParticles * singleParticleArray_.byteLength),
					LAST_IDX,
				).setUsage(DynamicDrawUsage);
			}

			this.geometry.setAttribute(
				"position",
				new InterleavedBufferAttribute(this.interleavedBuffer, 3, POSITION_START_TIME_IDX),
			);
			this.geometry.setAttribute(
				"startTime",
				new InterleavedBufferAttribute(this.interleavedBuffer, 1, 3),
			);
			this.geometry.setAttribute(
				"uvLifeTimeFrameStart",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, UV_LIFE_TIME_FRAME_START_IDX),
			);
			this.geometry.setAttribute(
				"velocityStartSize",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, VELOCITY_START_SIZE_IDX),
			);
			this.geometry.setAttribute(
				"accelerationEndSize",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, ACCELERATION_END_SIZE_IDX),
			);
			this.geometry.setAttribute(
				"spinStartSpinSpeed",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, SPIN_START_SPIN_SPEED_IDX),
			);
			this.geometry.setAttribute(
				"orientation",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, ORIENTATION_IDX),
			);
			this.geometry.setAttribute(
				"colorMult",
				new InterleavedBufferAttribute(this.interleavedBuffer, 4, COLOR_MULT_IDX),
			);

			//this.geometry.computeBoundingSphere();
			this.geometry.boundingSphere = new Sphere();
			this.geometry.boundingSphere.radius = 3;

			// const isAlpha = false;
			// let isDepthWrite = false;

			let blending: Blending = AdditiveBlending;
			switch (o.blending) {
				case "sub":
				case "subtractive":
					blending = SubtractiveBlending;
					break;
				case "multi":
				case "multiply":
					blending = MultiplyBlending;
					break;
				case "normal":
					blending = NormalBlending;
					break;
				default:
					blending = AdditiveBlending;
				// isDepthWrite = false;
			}

			const uniforms = {
				worldVelocity: { value: new Vector3() },
				gravity: { value: new Vector3() },
				timeRange: { value: 0 },
				time: { value: 0 },
				timeOffset: { value: 0 },
				frameDuration: { value: 0 },
				numFrames: { value: 0 },
				rampSampler: { value: null },
				colorSampler: { value: null },
				scale: { value: window.innerHeight * 0.5 },
				luma: { value: this.luma ? this.pe.luminosity : 1.0 },
				alphaTest: { value: o.alphaTest },
			};

			this.material = new ShaderMaterial({
				defines: {
					USE_ORIENTATION: o.oriented,
				},
				uniforms: uniforms,
				vertexShader: glTween(o.tween || "linear") + particleVertex,
				fragmentShader: particleFragment,
				side: o.oriented ? DoubleSide : FrontSide,
				blending,
				depthTest: true,
				depthWrite: o.depthWrite,
				transparent: o.transparent,
				//alphaToCoverage: isAlpha ? true : false,
			});

			this.renderOrder = o.renderOrder || 0;
		}
	}

	draw(timeOffset = 0) {
		if (!this.material.uniforms) return;
		const uniforms = this.material.uniforms;
		this.time += this.pe.delta;
		uniforms.time.value = this.time;
		uniforms.timeOffset.value = timeOffset;
		uniforms.scale.value = this.pe.hscale;
		uniforms.luma.value = this.luma ? this.pe.luminosity : 1.0;

		if (this.endTime !== -1) {
			if (this.time >= this.endTime) this.pe.remove(this.name);
		}
	}

	dispose() {
		this.removeFromParent();
		this.geometry.dispose();
		this.material.dispose();
		this.color?.dispose();
		//this.texture.dispose();
	}

	raycast() {}

	clone(object: any) {
		if (object === undefined) {
			throw new Error(`not implemeneted!`);
			//  object = this.pe.createEmitter(this.texture);
		}

		object.time = 0;
		object.endTime = this.endTime;
		object.geometry = this.geometry;
		object.material = this.material.clone();
		object.material.uniforms.rampSampler.value = this.color;
		object.material.uniforms.colorSampler.value = this.texture;

		super.copy(object);
		this.num++;
		object.name = this.name + this.num;

		return object;
	}
}

class Trail extends Emitter {
	isTrail = true;
	birthIndex = 0;
	maxParticles: number;

	readonly parameters: ParticleParameters;

	constructor(pe: ParticleEngine, o: Partial<ParticleParameters> & { maxParticles: number }) {
		super(pe);

		const { maxParticles, ...rest } = o;

		this.name = o.name || "trail";
		this.maxParticles = maxParticles;

		const parameters = validateParticleParameters(rest);
		this.allocateParticles_(this.maxParticles, parameters);

		this.parameters = parameters;

		// add to scene
		if (o.parent) o.parent.add(this);
		else this.pe.scene.add(this);
	}

	birthParticles(position: number[], colors: number[]) {
		const numParticles = this.parameters.numParticles;

		this.parameters.pposition = position;
		this.parameters.startTime = this.time;

		// auto delete trail !!
		this.endTime = this.time + this.parameters.lifeTime;

		// change color
		if (colors) this.setColorRamp(colors);

		/*while ( this.birthIndex + numParticles >= this.maxParticles ) {

			var numParticlesToEnd = this.maxParticles - this.birthIndex;

			this.createParticles_( this.birthIndex, numParticlesToEnd,	this.parameters, this.perParticleParamSetter );
			numParticles -= numParticlesToEnd;

			this.birthIndex = 0;

		}*/

		this.createParticles_(this.birthIndex, numParticles, this.parameters);

		this.birthIndex += numParticles;
		if (this.birthIndex + numParticles >= this.maxParticles) {
			this.birthIndex = 0;
			//this.time = 0
		}
	}
}

// AUTO TEXTURES //

class TextureMap extends Map<string, Texture> {
	dispose() {
		this.forEach((e) => {
			e.dispose();
		});
		this.clear();
	}

	make(name: PredefinedShapes) {
		const texture = this.get(name);
		if (texture) return texture;

		const capitalizedName = (name[0].toUpperCase() + name.substring(1)) as Capitalize<typeof name>;
		const funcName = `make${capitalizedName}` as const;

		const t = this[funcName]();
		this.set(name, t);

		return t;
	}

	private toTexture(canvas: HTMLCanvasElement) {
		const t = new Texture(canvas);
		t.minFilter = LinearFilter;
		t.magFilter = LinearFilter;
		t.flipY = false;
		t.needsUpdate = true;
		return t;
	}
	makePixel() {
		const pixels = [];
		for (let yy = 0; yy < 2; ++yy) {
			for (let xx = 0; xx < 2; ++xx) {
				pixels.push(1, 1, 1, 1);
			}
		}
		return tools.createTextureFromFloats(2, 2, pixels);
	}
	makeBasic() {
		const pixelBase = [0, 0.2, 0.7, 1, 0.7, 0.2, 0, 0];
		const pixels = [];
		for (let yy = 0; yy < 8; ++yy) {
			for (let xx = 0; xx < 8; ++xx) {
				const pixel = pixelBase[xx] * pixelBase[yy];
				pixels.push(pixel, pixel, pixel, 1);
			}
		}
		return tools.createTextureFromFloats(8, 8, pixels);
	}
	makeCube() {
		const s = 8;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");
		//ctx.fillStyle = 'rgba(255,255,255,0.25)';
		//ctx.fillRect(0, 0, s, s);
		ctx.fillStyle = "rgba(255,255,255,1.0)";
		ctx.fillRect(s * 0.25, s * 0.25, s * 0.5, s * 0.5);
		//let t = new Texture( canvas )
		//t.needsUpdate = true;
		return this.toTexture(canvas);
	}
	makeCloud() {
		const s = 16;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const c1 = "rgba(255,255,255,1)";
		const c2 = "rgba(255,255,255,0)";

		let grd = ctx.createRadialGradient(s * 0.5, s * 0.4, 0, s * 0.5, s * 0.4, s * 0.4);
		grd.addColorStop(0.3, c1);
		grd.addColorStop(1, c2);
		ctx.fillStyle = grd;
		ctx.fillRect(0, 0, s, s);
		grd = ctx.createRadialGradient(s * 0.4, s * 0.62, 0, s * 0.4, s * 0.62, s * 0.35);
		grd.addColorStop(0.3, c1);
		grd.addColorStop(1, c2);
		ctx.fillStyle = grd;
		ctx.fillRect(0, 0, s, s);
		grd = ctx.createRadialGradient(s * 0.27, s * 0.4, 0, s * 0.27, s * 0.4, s * 0.26);
		grd.addColorStop(0.2, c1);
		grd.addColorStop(1, c2);
		ctx.fillStyle = grd;
		ctx.fillRect(0, 0, s, s);
		grd = ctx.createRadialGradient(s * 0.76, s * 0.6, 0, s * 0.76, s * 0.6, s * 0.23);
		grd.addColorStop(0.2, c1);
		grd.addColorStop(1, c2);
		ctx.fillStyle = grd;
		ctx.fillRect(0, 0, s, s);

		return this.toTexture(canvas);
	}
	makeRound() {
		const s = 16;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const gradient = ctx.createRadialGradient(s * 0.5, s * 0.5, 0, s * 0.5, s * 0.5, s * 0.5);
		gradient.addColorStop(0, "rgba(255,255,255,1)");
		gradient.addColorStop(0.3, "rgba(255,255,255,0.1)");
		gradient.addColorStop(0.9, "rgba(255,255,255,0)");
		gradient.addColorStop(1, "rgba(255,255,255,0)");
		ctx.fillStyle = gradient;
		ctx.fillRect(0, 0, s, s);
		return this.toTexture(canvas);
	}
	makeDonut() {
		const s = 32;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const gradient = ctx.createRadialGradient(s * 0.5, s * 0.5, 0, s * 0.5, s * 0.5, s * 0.5);
		gradient.addColorStop(0, "rgba(255,255,255,1)");
		gradient.addColorStop(0.9, "rgba(255,255,255,0.1)");
		gradient.addColorStop(1, "rgba(255,255,255,0)");
		ctx.fillStyle = gradient;
		//ctx.fillRect(0, 0, s, s);
		ctx.beginPath();
		ctx.arc(s * 0.5, s * 0.5, s * 0.5, 0, Math.PI * 2, false); // outer (filled)
		ctx.arc(s * 0.5, s * 0.5, s * 0.25, 0, Math.PI * 2, true); // inner (unfills it)
		ctx.fill();
		return this.toTexture(canvas);
	}
	makeBubble() {
		const s = 64;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const c1 = "rgba(0,255,255,0)";
		const c2 = "rgba(0,255,255,0.6)";
		const c3 = "rgba(0,255,255,1)";
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const gradient = ctx.createRadialGradient(s * 0.4, s * 0.4, 0, s * 0.5, s * 0.5, s * 0.5);
		gradient.addColorStop(0.4, c1);
		gradient.addColorStop(0.9, c2);
		gradient.addColorStop(0.99, c3);
		gradient.addColorStop(1, c1);
		ctx.fillStyle = gradient;
		ctx.fillRect(0, 0, s, s);
		ctx.fillStyle = "rgba(255,255,255,1)";
		ctx.beginPath();
		ctx.arc(s * 0.7, s * 0.4, s * 0.14, 0, Math.PI * 2, false);
		ctx.fill();
		ctx.beginPath();
		ctx.arc(s * 0.2, s * 0.65, s * 0.05, 0, Math.PI * 2, false);
		ctx.fill();
		return this.toTexture(canvas);
	}
	makeCircle() {
		const s = 64;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		ctx.strokeStyle = "white";
		ctx.lineWidth = 4;
		ctx.beginPath();
		ctx.arc(s * 0.5, s * 0.5, s * 0.5 - 2, 0, Math.PI * 2);
		ctx.stroke();
		return this.toTexture(canvas);
	}
	makeField() {
		const s = 64;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const gradient = ctx.createLinearGradient(0, 0, 0, s);
		gradient.addColorStop(0, "rgba(255,255,255,0)");
		gradient.addColorStop(0.8, "rgba(255,255,255,0.4)");
		gradient.addColorStop(1.0, "rgba(255,255,255,0)");
		ctx.fillStyle = gradient;
		ctx.fillRect((s - s * 0.25) * 0.5, 0, s * 0.25, s);
		return this.toTexture(canvas);
	}
	makeStar() {
		const s = 64;
		const canvas = document.createElement("canvas");
		canvas.width = canvas.height = s;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("no 2d context");

		const gradient = ctx.createRadialGradient(s * 0.5, s * 0.5, 0, s * 0.5, s * 0.5, s * 0.5);
		gradient.addColorStop(0, "rgba(255,255,255,0.5)");
		gradient.addColorStop(0.5, "rgba(255,255,255,0.1)");
		gradient.addColorStop(0.9, "rgba(255,255,255,0)");
		ctx.fillStyle = gradient;
		this.star(ctx, s * 0.5, s * 0.1, s * 0.5, s * 0.5, 3);
		this.star(ctx, s * 0.5, s * 0.4, s * 0.5, s * 0.5, 3);
		return this.toTexture(canvas);
	}

	private star(ctx: CanvasRenderingContext2D, r: number, r2: number, cX: number, cY: number, N: number) {
		ctx.beginPath();
		ctx.moveTo(cX + r, cY);
		let x, y, theta;
		for (let i = 1; i <= N * 2; i++) {
			if (i % 2 === 0) {
				theta = (i * (Math.PI * 2)) / (N * 2);
				x = cX + r * Math.cos(theta);
				y = cY + r * Math.sin(theta);
			} else {
				theta = (i * (Math.PI * 2)) / (N * 2);
				x = cX + r2 * Math.cos(theta);
				y = cY + r2 * Math.sin(theta);
			}
			ctx.lineTo(x, y);
		}
		ctx.closePath();
		ctx.fill();
	}
}

// SHADER //

const particleVertex = /* glsl */ `
precision mediump float;
precision mediump int;

#ifdef USE_ORIENTATION
	//uniform mat4 worldViewProjection;
	//uniform mat4 world;
	attribute vec3 offset;
	attribute vec4 orientation;
#else
    uniform float scale;
#endif

uniform vec3 worldVelocity;
uniform vec3 gravity;
uniform float timeRange;
uniform float time;
uniform float timeOffset;
uniform float frameDuration;
uniform float numFrames;

attribute vec4 uvLifeTimeFrameStart;
attribute float startTime;
attribute vec4 velocityStartSize;
attribute vec4 accelerationEndSize;
attribute vec4 spinStartSpinSpeed;
attribute vec4 colorMult;

varying vec2 outputTexcoord;
varying float outputPercentLife;
varying vec4 outputColorMult;
varying mat2 rotationMtx;

#include <fog_pars_vertex>
#include <logdepthbuf_pars_vertex>
//#include <clipping_planes_pars_vertex>

vec3 lerp( vec3 a, vec3 b, float p ){ return a + (b - a) * p; }

void main() 
{
    float lifeTime = uvLifeTimeFrameStart.z;

    float startSize = velocityStartSize.w;
    float endSize = accelerationEndSize.w;

    float localTime = mod((time - timeOffset - startTime), timeRange);

    float percentLife = localTime / lifeTime;
    percentLife = tween(percentLife);
    
    float size = mix(startSize, endSize, percentLife);
    size = (percentLife < 0. || percentLife > 1.0) ? 0.0 : size;

    // if size is 0 or below, move vertex outside the view frustum
    if (size <= 0.0) {
        gl_Position = vec4(2.0, 2.0, 2.0, 1.0);
        return;
    }

    float spinStart = spinStartSpinSpeed.x;
    float spinSpeed = spinStartSpinSpeed.y;

    vec3 velocity = velocityStartSize.xyz + (inverse(modelMatrix) * vec4(worldVelocity, 0.0)).xyz;
    vec3 acceleration = accelerationEndSize.xyz + (inverse(modelMatrix) * vec4(gravity, 0.0)).xyz;

    vec3 posEnd = velocity * lifeTime + acceleration * lifeTime * lifeTime;

    float frameStart = uvLifeTimeFrameStart.w;
    float frame = mod(floor(localTime / frameDuration + frameStart), numFrames);
    float uOffset = frame / numFrames;
    float u = uOffset + (uv.x + 0.5) * (1. / numFrames);

    outputTexcoord = vec2(u, uv.y + 0.5);
    outputColorMult = colorMult;

	float s = sin(spinStart + spinSpeed * localTime);
	float c = cos(spinStart + spinSpeed * localTime);

    #ifdef USE_ORIENTATION
		
		vec4 rotatedPoint = vec4((uv.x * c + uv.y * s) * size, 0., (uv.x * s - uv.y * c) * size, 1.);
		//vec3 center = velocity * localTime + acceleration * localTime * localTime + position + offset;
		vec3 center = (posEnd * percentLife) + position + offset;

		vec4 q2 = orientation + orientation;
		vec4 qx = orientation.xxxw * q2.xyzx;
		vec4 qy = orientation.xyyw * q2.xyzy;
		vec4 qz = orientation.xxzw * q2.xxzz;

		mat4 localMatrix = mat4(
		    (1.0 - qy.y) - qz.z,  qx.y + qz.w,  qx.z - qy.w, 0,
		    qx.y - qz.w, (1.0 - qx.x) - qz.z, qy.z + qx.w, 0,
		    qx.z + qy.w, qy.z - qx.w, (1.0 - qx.x) - qy.y, 0,
		    center.x, center.y, center.z, 1
		);
		rotatedPoint = localMatrix * rotatedPoint;
		gl_Position = projectionMatrix * modelViewMatrix * rotatedPoint;

	#else

	    //vec3 pos = position + velocity * localTime + acceleration * localTime * localTime;

	    vec3 pos = (posEnd * percentLife) + position;
	    
	    vec4 mvPosition = modelViewMatrix * vec4( pos, 1.0 );
        //gl_PointSize = size * 1.5 * ( scale / length( mvPosition.xyz ) );
        gl_PointSize = size * 1.5 * ( scale / - mvPosition.z );

        mat2 r = mat2( c, -s, s, c);
        r *= 0.5; r += 0.5;  r = r * 2.0 - 1.0;
        rotationMtx = r;

        gl_Position = projectionMatrix * mvPosition;

	#endif

	outputPercentLife = percentLife;

	#include <logdepthbuf_vertex>
	//#include <clipping_planes_vertex>
	#include <fog_vertex>
}
`;

const particleFragment = `
precision mediump float;
precision mediump int;

uniform sampler2D rampSampler;
uniform sampler2D colorSampler;
uniform float luma;
uniform float alphaTest;

varying vec2 outputTexcoord;
varying float outputPercentLife;
varying vec4 outputColorMult;
varying mat2 rotationMtx;

#include <fog_pars_fragment>
#include <logdepthbuf_pars_fragment>
//#include <clipping_planes_pars_fragment>

void main() {

	//#include <clipping_planes_fragment>
	#include <logdepthbuf_fragment>

	vec4 diffuseColor = texture2D( rampSampler, vec2(outputPercentLife, 0.5) ) * outputColorMult;

    vec2 uv = vec2(0.0);
    #ifdef USE_ORIENTATION
        uv = outputTexcoord;
	#else
	    uv = gl_PointCoord;
	    uv -= 0.5;
			uv = uv * rotationMtx;
			uv += 0.5;
	#endif

	// texture
	diffuseColor *= texture2D( colorSampler, uv );

	if ( diffuseColor.a < alphaTest ) discard;

	diffuseColor.rgb *= luma;

	gl_FragColor = diffuseColor; 
	#include <fog_fragment>

}
`;

const glTween = (type: TweenType) => {
	let s;

	switch (type) {
		case "linear":
			s = `float tween( float k ) { return k; }`;
			break;

		case "inQuad":
			s = `float tween( float k ) { return k * k; }`;
			break;
		case "outQuad":
			s = `float tween( float k ) { return k * ( 2.0 - k ); }`;
			break;
		case "inOutQuad":
			s = `float tween( float k ) { 
			if ( ( k *= 2.0 ) < 1.0 ) return 0.5 * k * k;
            return - 0.5 * ( --k * ( k - 2.0 ) - 1.0 ); 
        }`;
			break;
		case "inCubic":
			s = `float tween( float k ) { return k * k * k; }`;
			break;
		case "outCubic":
			s = `float tween( float k ) { return --k * k * k + 1.0; }`;
			break;
		case "inOutCubic":
			s = `float tween( float k ) { 
			if ( ( k *= 2.0 ) < 1.0 ) return 0.5 * k * k * k;
			return 0.5 * ( ( k -= 2.0 ) * k * k + 2.0 ); 
        }`;
			break;
		case "inQuart":
			s = `float tween( float k ) { return k * k * k * k; }`;
			break;
		case "outQuart":
			s = `float tween( float k ) { return 1.0 - ( --k * k * k * k ); }`;
			break;
		case "inOutQuart":
			s = `float tween( float k ) { 
			if ( ( k *= 2.0 ) < 1.0) return 0.5 * k * k * k * k;
			return - 0.5 * ( ( k -= 2.0 ) * k * k * k - 2.0 ); 
        }`;
			break;
		case "inQuint":
			s = `float tween( float k ) { return k * k * k * k * k; }`;
			break;
		case "outQuint":
			s = `float tween( float k ) { return --k * k * k * k * k + 1.0; }`;
			break;
		case "inOutQuint":
			s = `float tween( float k ) { 
			if ( ( k *= 2.0 ) < 1.0 ) return 0.5 * k * k * k * k * k;
			return 0.5 * ( ( k -= 2.0 ) * k * k * k * k + 2.0 );
        }`;
			break;
		case "inSine":
			s = `#define PI_90 1.570796326794896
        float tween( float k ) { float j = k * PI_90; return 1.0 - cos( j ); }`;
			break;
		case "outSine":
			s = `#define PI_90 1.570796326794896
		float tween( float k ) { float j = k * PI_90; return sin( j ); }`;
			break;
		case "inOutSine":
			s = `#define M_PI 3.14159265358979323846
		float tween( float k ) { 
			float j = k * M_PI; return 0.5 * (1.0-cos(j));
        }`;
			break;
		case "inExpo":
			s = `float tween( float k ) { return k == 0.0 ? 0.0 : pow( 1024.0, k - 1.0 ); }`;
			break;
		case "outExpo":
			s = `float tween( float k ) { return k == 1.0 ? 1.0 : 1.0 - pow( 2.0, - 10.0 * k ); }`;
			break;
		case "inOutExpo":
			s = `float tween( float k ) { 
			if ( k == 0.0 ) return 0.0;
		    if ( k == 1.0 ) return 1.0;
		    if ( ( k *= 2.0 ) < 1.0 ) return 0.5 * pow( 1024.0, k - 1.0 );
		    return 0.5 * ( - pow( 2.0, - 10.0 * ( k - 1.0 ) ) + 2.0 );
        }`;
			break;
		case "inCirc":
			s = `float tween( float k ) { return 1.0 - sqrt( 1.0 - k * k ); }`;
			break;
		case "outCirc":
			s = `float tween( float k ) { return sqrt( 1.0 - ( --k * k ) ); }`;
			break;
		case "inOutCirc":
			s = `float tween( float k ) { 
			if ( ( k *= 2.0 ) < 1.0) return - 0.5 * ( sqrt( 1.0 - k * k ) - 1.0 );
			return 0.5 * ( sqrt( 1.0 - ( k -= 2.0 ) * k ) + 1.0 ); 
        }`;
			break;
		case "inElastic":
			s = `#define TWO_PI 6.28318530717958647692
        float tween(float k) {
		    float s;
		    float a = 0.1;
		    float p = 0.4;
		    if ( k == 0.0 ) return 0.0;
		    if ( k == 1.0 ) return 1.0;
		    if ( a < 1.0 ) { a = 1.0; s = p * 0.25; }
		    else s = p * asin( 1.0 / a ) / TWO_PI;
		    return - ( a * pow( 2.0, 10.0 * ( k -= 1.0 ) ) * sin( ( k - s ) * TWO_PI / p ) );
		}`;
			break;
		case "outElastic":
			s = `#define TWO_PI 6.28318530717958647692
		float tween(float k) {
		    float s;
		    float a = 0.1; 
		    float p = 0.4;
		    if ( k == 0.0 ) return 0.0;
		    if ( k == 1.0 ) return 1.0;
		    if ( a < 1.0 ) { a = 1.0; s = p * 0.25; }
		    else s = p * asin( 1.0 / a ) / TWO_PI;
		    return ( a * pow( 2.0, - 10.0 * k) * sin( ( k - s ) * TWO_PI / p ) + 1.0 );
		}`;
			break;
		case "inOutElastic":
			s = `#define TWO_PI 6.28318530717958647692
		float tween(float k) {
		    float s;
		    float a = 0.1;
		    float p = 0.4;
		    if ( k == 0.0 ) return 0.0;
		    if ( k == 1.0 ) return 1.0;
		    if ( a < 1.0 ) { a = 1.0; s = p * 0.25; }
		    else s = p * asin( 1.0 / a ) / TWO_PI;
		    if ( ( k *= 2.0 ) < 1.0 ) return - 0.5 * ( a * pow( 2.0, 10.0 * ( k -= 1.0 ) ) * sin( ( k - s ) * TWO_PI / p ) );
		    return a * pow( 2.0, -10.0 * ( k -= 1.0 ) ) * sin( ( k - s ) * TWO_PI / p ) * 0.5 + 1.0;
		}`;
			break;
		case "inBack":
			s = `float tween(float k) {
		    float s = 1.70158;
		    return k * k * ( ( s + 1.0 ) * k - s );
		}`;
			break;
		case "outBack":
			s = `float tween(float k) {
		    float s = 1.70158;
		    return --k * k * ( ( s + 1.0 ) * k + s ) + 1.0;
		}`;
			break;
		case "inOutBack":
			s = `float tween(float k) {
		    float s = 1.70158 * 1.525;
		    if ( ( k *= 2.0 ) < 1.0 ) return 0.5 * ( k * k * ( ( s + 1.0 ) * k - s ) );
		    return 0.5 * ( ( k -= 2.0 ) * k * ( ( s + 1.0 ) * k + s ) + 2.0 );
		}`;
			break;

		case "inBounce":
			s = `float outBounce(float k) {
		    if ( k < ( 1.0 / 2.75 ) ) return 7.5625 * k * k;
		    else if ( k < ( 2.0 / 2.75 ) ) return 7.5625 * ( k -= ( 1.5 / 2.75 ) ) * k + 0.75;
		    else if ( k < ( 2.5 / 2.75 ) ) return 7.5625 * ( k -= ( 2.25 / 2.75 ) ) * k + 0.9375;
		    else return 7.5625 * ( k -= ( 2.625 / 2.75 ) ) * k + 0.984375;
		}
		float tween(float k) { return 1.0 - outBounce( 1.0 - k ); }`;
			break;
		case "outBounce":
			s = `float tween(float k) {
		    if ( k < ( 1.0 / 2.75 ) ) return 7.5625 * k * k;
		    else if ( k < ( 2.0 / 2.75 ) ) return 7.5625 * ( k -= ( 1.5 / 2.75 ) ) * k + 0.75;
		    else if ( k < ( 2.5 / 2.75 ) ) return 7.5625 * ( k -= ( 2.25 / 2.75 ) ) * k + 0.9375;
		    else return 7.5625 * ( k -= ( 2.625 / 2.75 ) ) * k + 0.984375;
		}`;
			break;
		case "inOutBounce":
			s = `float outBounce(float k) {
		    if ( k < ( 1.0 / 2.75 ) ) return 7.5625 * k * k;
		    else if ( k < ( 2.0 / 2.75 ) ) return 7.5625 * ( k -= ( 1.5 / 2.75 ) ) * k + 0.75;
		    else if ( k < ( 2.5 / 2.75 ) ) return 7.5625 * ( k -= ( 2.25 / 2.75 ) ) * k + 0.9375;
		    else return 7.5625 * ( k -= ( 2.625 / 2.75 ) ) * k + 0.984375;
		}
		float inBounce(float k) { return 1.0 - outBounce( 1.0 - k ); }
		float tween(float k) {
		    if ( k < 0.5 ) return inBounce( k * 2.0 ) * 0.5;
		    return outBounce( k * 2.0 - 1.0 ) * 0.5 + 0.5;
		}`;
			break;
	}

	return s;
};
