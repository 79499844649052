import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { getEntity } from "base/rete/modules/validate";
import { DEGRAD, getYawFromQuaternion, RADDEG } from "base/util/math/Math";
import { entityIsCharacter, entityIsParticleSystem, entityIsProp } from "base/world/entity/component/Type";
import { Euler, Quaternion, Vector3 } from "three";

export const ENTITY_NODES = [
	node({
		id: "0002-03-1001",
		name: "Entity Get Position",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current position of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			position: { name: "Position", type: "vector3", icon: "MapPin" },
		},
		resolve(inputs, ctx) {
			const position = new Vector3();

			const entity = getEntity(inputs, ctx, "entity");
			if (entity) position.copy(entity.position);

			return {
				position,
			};
		},
	}),
	node({
		id: "0002-03-2001",
		name: "Entity Set Position",
		type: NODE_TYPE_ID.function.entity,
		predictable: true,
		keywords: ["Teleport"],
		description: "Set position of entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = getEntity(inputs, ctx, "entity");
			if (entity) {
				const pos = new Vector3(
					inputs.position?.x ?? 0,
					inputs.position?.y ?? 0,
					inputs.position?.z ?? 0,
				);

				entity.setPosition(pos, !closure.predictable);
			}
		},
	}),
	node({
		id: "0002-03-1004",
		name: "Entity Get Angle",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current angle (y rotation) of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			rotation: { name: "Angle", type: "number", icon: "Angle" },
		},
		resolve(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");

			let rotation = 0;

			if (entity) {
				rotation = getYawFromQuaternion(entity.quaternion) * RADDEG;
			}

			return {
				rotation,
			};
		},
	}),
	node({
		id: "0002-03-2006",
		name: "Entity Set Angle",
		type: NODE_TYPE_ID.function.entity,
		description: "Set angle (y rotation) of entity in degrees",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			rotation: {
				name: "Rotation",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity) return;

			const rot = new Euler();
			rot.y = (inputs.rotation ?? 0) * DEGRAD;
			entity.setRotation(rot, !closure.predictable);
		},
	}),
	node({
		id: "d5047174-31e6-47d5-a20f-d394a62359d9",
		name: "Entity Get Rotation",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current rotation of the entity as euler angles in radians.",
		predictable: true,
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			rotation: { name: "Rotation", type: "euler" },
		},
		resolve(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");

			const result = new Euler();

			if (entity) {
				result.setFromQuaternion(entity.quaternion);
			}

			return { rotation: result };
		},
	}),
	node({
		id: "922d64d0-e4da-4fd4-b01a-2cfa41b2b4d9",
		name: "Entity Set Rotation",
		type: NODE_TYPE_ID.function.entity,
		description: "Set rotation of entity as euler angles in radians",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			rotation: {
				name: "Rotation",
				type: "euler",
				control: "euler",
				config: { defaultValue: { x: 0, y: 0, z: 0, order: "XZY" } },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity) return;

			entity.setRotation(
				{
					x: inputs.rotation.x,
					y: inputs.rotation.y,
					z: inputs.rotation.z,
					order: inputs.rotation.order,
				},
				!closure.predictable,
			);
		},
	}),
	node({
		id: "3323d8e5-dea6-45c1-8c8f-325615fc8ca1",
		name: "Entity Get Quaternion",
		predictable: true,
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current rotation of the entity as a quaternion.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			quaternion: { name: "Quaternion", type: "quaternion" },
		},
		resolve(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");

			const quaternion = new Quaternion();

			if (quaternion) {
				quaternion.copy(entity.quaternion);
			}

			return { quaternion };
		},
	}),
	node({
		id: "76daeb68-5c98-4f45-94a9-6509ababe940",
		name: "Entity Set Quaternion",
		predictable: true,
		type: NODE_TYPE_ID.function.entity,
		description: "Set rotation of entity as quaternion",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			quaternion: {
				name: "Quaternion",
				type: "quaternion",
				control: "quaternion",
				config: { defaultValue: { x: 0, y: 0, z: 0, w: 1 } },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity) return;

			entity.setQuaternion(
				{
					x: inputs.quaternion?.x ?? 0,
					y: inputs.quaternion?.y ?? 0,
					z: inputs.quaternion?.z ?? 0,
					w: inputs.quaternion?.w ?? 1,
				},
				!closure.predictable,
			);
		},
	}),
	node({
		id: "56e48cfe-786e-46d2-83f2-28e814680c05",
		name: "Entity Get Scale",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current scale of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			scale: { name: "Scale", type: "number" },
		},
		resolve(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");

			let scale = 1;

			if (entity) {
				if (entityIsCharacter(entity)) {
					scale = entity.size.state.scale;
				} else if (entityIsProp(entity)) {
					scale = entity.propPhysics.def.scale;
				}
			}

			return { scale };
		},
	}),
	node({
		id: "3b8422de-7434-4d7d-bba4-812e11b088ea",
		name: "Entity Set Scale",
		type: NODE_TYPE_ID.function.entity,
		description: "Sets the scale of an entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			scale: {
				name: "Scale",
				type: "number",
				config: { defaultValue: { x: 1, y: 1, z: 1 } },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity) return;

			const scale = inputs.scale ?? 1;

			if (entityIsCharacter(entity) || entityIsProp(entity) || entityIsParticleSystem(entity)) {
				entity.setScale(scale);
			}
		},
	}),
];
