import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";

export class LocomotionStateRunning extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Running", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToJoggingTime = 0.2;
		this.transitionToWalkingTime = 0.2;

		this.forwardWeight = 1.0;

		this.forwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Sprint", this);
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;

		this.forwardTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Idle");
			this.forwardTrack.action.syncWith(idleAction);

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();

		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.forwardTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.forwardTrack.action.setEffectiveWeight(this.weight);
		this.prvMovementInput = this.inputState.movementInput;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		// transition to flying state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("CrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (this.inputState.item) {
			outTransitionToState.ptr = map.get("WeaponsRunning");
			outTransitionToState.blendTime = this.transitionToDeathTime;
			return true;
		}

		if (this.inputState.isMoving) {
			if (!this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Jogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
				return true;
			}
		}

		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
	}

	updateWeightsInternal() {
		this.updateBlendPoses();
	}
}
