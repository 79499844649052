import { Entity } from "base/world/entity/Entity";
import type { ParticleSystemEntity } from "base/world/entity/ParticleSystem";
import type { Text3DEntity } from "base/world/entity/Text3D";
import type { SocketDef, SocketsDef } from "@jamango/content-client";
import type { Vector3 } from "three";
import type { World } from "base/world/World.js";
import type { Peer } from "@jamango/ibs";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("NodeSharedUtil");

// needed a value that is a string but unlikely to ever be used as a name
export const NODE_THIS_BLOCK = "\0";
export const NODE_THIS_SCENE_TREE_NODE = "\0";

export function addWhoCanTrigger() {
	return {
		whoCanTrigger: {
			name: "Who Can Trigger",
			type: "string",
			control: "checkboxGroup",
			structure: "list",
			config: {
				options: [
					{ label: "Player", value: "player" },
					{ label: "NPC", value: "npc" },
				],
				defaultValue: ["player"],
			},
		},
	} satisfies SocketsDef<"C">;
}

export function addSelectGroup() {
	const input = {
		name: "Group Name",
		type: "blockGroup",
		config: {
			defaultValue: NODE_THIS_BLOCK,
		},
	} satisfies SocketDef<"I", "blockGroup">;

	return input;
}

export function addSelectCharacterSceneTreeNode(label: string) {
	const input = {
		name: label,
		type: "string",
		control: "characterSceneTreeNode",
		config: {
			defaultValue: NODE_THIS_SCENE_TREE_NODE,
		},
	} satisfies SocketDef<"I", "string">;

	return input;
}

export function addSelectPropSceneTreeNode(label: string) {
	const input = {
		name: label,
		type: "string",
		control: "propSceneTreeNode",
		config: {
			defaultValue: NODE_THIS_SCENE_TREE_NODE,
		},
	} satisfies SocketDef<"I", "string">;

	return input;
}

//deliberately not handling nullish here. would prefer if the interpreter validation system blocks it from ever entering a node
export function interpreterAnyToString(input: any): string {
	if (Array.isArray(input)) return `[${input.map((e) => interpreterAnyToString(e))}]`;
	if (input === undefined) return "undefined";
	if (typeof input === "string") return input;
	if (typeof input === "number" || typeof input === "boolean" || (input as Vector3).isVector3)
		return String(input);

	if (input instanceof Entity) {
		if (input.type.def.isPlayer) return `Entity<Player, ${input.entityID}>`;
		if (input.type.def.isNPC) return `Entity<NPC, ${input.entityID}>`;
		if (input.type.def.isItem) return `Entity<Item, ${input.entityID}>`;
		if ((input as Text3DEntity).text3D) return `Entity<Text3D, ${input.entityID}>`;
		if ((input as ParticleSystemEntity).particleSystem)
			return `Entity<ParticleSystem, ${input.entityID}>`;
		if (input.type.def.isProp) return `Entity<Prop, ${input.entityID}>`;

		// implies we've forgotten to add an entity type to this list
		logger.error("Failed to convert entity to string", input.type.def);
		return "Mystery entity";
	}

	if (typeof input === "object") return JSON.stringify(input);

	throw Error("Value can't be converted to string");
}

export const entitiesToPeers = (entities: Entity[], world: World): Peer[] => {
	// turns undefined into all peers
	if (entities.length === 0 || (entities.length === 1 && entities[0] === undefined)) {
		return world.router.getPeers();
	}

	return entities.map((player) => world.playerToPeer.get(player)).filter((peer) => peer !== undefined);
};
