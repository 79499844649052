import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import {
	LOCOMOTION_MOVEMENTINPUT_LEFT,
	LOCOMOTION_MOVEMENTINPUT_RIGHT,
	LOCOMOTION_MOVEMENTINPUT_DOWN,
	LOCOMOTION_MOVEMENTINPUT_UP,
	LOCOMOTION_MOVEMENTINPUT_FORWARD,
	LOCOMOTION_MOVEMENTINPUT_BACKWARD,
} from "base/world/entity/component/CharacterLocomotionInput";

export class LocomotionStateFlying extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Flying", animMesh, locomotionInput, transitions);

		this.directionsBlendTime = 0.3;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToFlyingIdleTime = 0.2;
		this.transitionToFlyingSprintTime = 0.2;

		this.forwardWeight = 1.0;
		this.backwardWeight = 0.0;
		this.rightWeight = 0.0;
		this.leftWeight = 0.0;
		this.upWeight = 0.0;
		this.downWeight = 0.0;

		this.forwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Forward", this);

		this.backwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Backward", this);

		this.leftTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Left", this);
		this.rightTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Right", this);
		this.upTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Up", this);
		this.downTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Fly_Down", this);
		this.lastTrack = this.forwardTrack;
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;

		this.forwardTrack.action.reset();
		this.backwardTrack.action.reset();
		this.rightTrack.action.reset();
		this.leftTrack.action.reset();
		this.upTrack.action.reset();
		this.downTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Idle");
			this.forwardTrack.action.syncWith(idleAction);
			this.rightTrack.action.syncWith(idleAction);
			this.leftTrack.action.syncWith(idleAction);
			this.backwardTrack.action.syncWith(idleAction);
			this.upTrack.action.syncWith(idleAction);
			this.downTrack.action.syncWith(idleAction);

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();
		this.backwardTrack.action.play();
		this.leftTrack.action.play();
		this.rightTrack.action.play();
		this.upTrack.action.play();
		this.downTrack.action.play();

		this.startFadeIn(transitionData);

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_FORWARD;
			this.forwardTrack.state = "on";
			this.forwardTrack.currentWeight = 1;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.upTrack.state = "off";
			this.upTrack.currentWeight = 0;
			this.downTrack.state = "off";
			this.downTrack.currentWeight = 0;
			this.lastTrack = this.forwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_BACKWARD;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "on";
			this.backwardTrack.currentWeight = 1;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.upTrack.state = "off";
			this.upTrack.currentWeight = 0;
			this.downTrack.state = "off";
			this.downTrack.currentWeight = 0;
			this.lastTrack = this.backwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
			const weightExceptLeft =
				this.forwardTrack.currentWeight +
				this.rightTrack.currentWeight +
				this.backwardTrack.currentWeight;
			this.leftTrack.currentWeight = 1 - weightExceptLeft;
			this.leftTrack.startFadeIn(this.directionsBlendTime);
			this.leftTrack.isLeft = true;
			this.rightTrack.startFadeOut(this.directionsBlendTime);
			this.forwardTrack.startFadeOut(this.directionsBlendTime);
			this.backwardTrack.startFadeOut(this.directionsBlendTime);
			this.upTrack.startFadeOut(this.directionsBlendTime);
			this.downTrack.startFadeOut(this.directionsBlendTime);

			this.lastTrack = this.leftTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_RIGHT;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "on";
			this.rightTrack.currentWeight = 1;
			this.upTrack.state = "off";
			this.upTrack.currentWeight = 0;
			this.downTrack.state = "off";
			this.downTrack.currentWeight = 0;
			this.lastTrack = this.rightTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_UP) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_UP;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.upTrack.state = "on";
			this.upTrack.currentWeight = 1;
			this.downTrack.state = "off";
			this.downTrack.currentWeight = 0;
			this.lastTrack = this.upTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_DOWN) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_DOWN;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.upTrack.state = "off";
			this.upTrack.currentWeight = 0;
			this.downTrack.state = "on";
			this.downTrack.currentWeight = 1;
			this.lastTrack = this.downTrack;
		}

		this.updateWeightsInternal();
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.updateWeightsInternal();

		this.prvMovementInput = this.inputState.movementInput;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		// transition to flyingIdle state
		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		// transition to Flying Sprint state
		if (this.inputState.isSprinting) {
			outTransitionToState.ptr = map.get("FlyingSprint");
			outTransitionToState.blendTime = this.transitionToFlyingSprintTime;
			return true;
		}

		if (!this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
		this.backwardTrack.update();
		this.leftTrack.update();
		this.rightTrack.update();
		this.upTrack.update();
		this.downTrack.update();
	}

	updateWeightsInternal() {
		if (
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_UP ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_DOWN
		) {
			if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_FORWARD &&
					this.lastTrack !== this.forwardTrack
				) {
					this.forwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.forwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_BACKWARD &&
					this.lastTrack !== this.backwardTrack
				) {
					this.backwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.backwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_LEFT &&
					this.lastTrack !== this.leftTrack
				) {
					this.leftTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.leftTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_RIGHT &&
					this.lastTrack !== this.rightTrack
				) {
					this.rightTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.rightTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_UP) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_UP &&
					this.lastTrack !== this.upTrack
				) {
					this.upTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.upTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_DOWN) {
				if (this.prvMovementInput !== "down" && this.lastTrack !== this.downTrack) {
					this.downTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.downTrack;
			}
		}

		this.updateBlendPoses();
	}
}
