import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateCrouchTurnLeft extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("CrouchTurnLeft", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToDeathTime = 0.2;
	}

	get crouchTurnLeftAction() {
		return this.mesh.getAction("JMGO_Unarmed_Turn_Crouch_Left");
	}

	startCompute(transitionData) {
		this.crouchTurnLeftAction.reset();
		this.crouchTurnLeftAction.loop = LoopOnce;
		this.crouchTurnLeftAction.setEffectiveTimeScale(2.0);

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchTurnLeftAction.play();
		this.startFadeIn(transitionData);
	}

	onStateOff() {}

	compute() {
		this.updateWeight();

		if (this.state === "off") {
			this.crouchTurnLeftAction.setEffectiveWeight(0);

			return;
		}

		this.crouchTurnLeftAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (
			this.crouchTurnLeftAction.getRemainingTime() < this.transitionToIdleTime &&
			!this.inputState.isMoving
		) {
			outTransitionToState.ptr = map.get("CrouchIdle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("CrouchWalking");
			outTransitionToState.blendTime = this.transitionToWalkingTime;

			return true;
		}

		return false;
	}
}
