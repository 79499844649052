import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { Euler } from "three";

export const EULER_NODES = [
	node({
		id: "0b461ebe-ea45-41e3-afa6-be3557032b37",
		name: "Euler Reorder",
		type: NODE_TYPE_ID.euler,
		description: "Reorders an Euler angle with a new order",
		predictable: true,
		inputs: {
			euler: { name: "Euler", type: "euler", control: "euler" },
			order: { name: "Order", type: "string", control: "string" },
		},
		outputs: {
			result: { name: "Euler", type: "euler" },
		},
		resolve(inputs) {
			const euler = new Euler(inputs.euler.x, inputs.euler.y, inputs.euler.z, inputs.order ?? "XYZ");
			return { result: euler };
		},
	}),
];
