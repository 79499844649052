import { netState } from "router/Parallelogram";
import { Vector3, Quaternion } from "three";
import { OCCLUSION_ENABLE_CUSTOM_BV } from "client/OcclusionCulling.js";

const _position = new Vector3();
const _scale = new Vector3();
const _quat = new Quaternion();

export function npcOcclusionUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const position = _position;
	const quaternion = _quat;
	const scale = _scale;
	entity.occlusionMesh.matrixWorld.decompose(position, quaternion, scale);
	position.copy(entity.position);

	if (OCCLUSION_ENABLE_CUSTOM_BV) {
		scale.setScalar(2 * entity.size.state.scale); // HACK. we need to remove scalign altogether for character
	} else {
		scale.setScalar(1.1 * entity.size.state.scale);
		position.y += 1.15 * entity.size.state.scale;
	}

	entity.occlusionMesh.matrixWorld.compose(position, quaternion, scale);
	entity.occlusionMesh.position.set(position.x, position.y, position.z);
	entity.occlusionMesh.scale.set(scale.x, scale.y, scale.z);
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isNPC;
}
