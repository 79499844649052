import { NODE_TYPE_ID } from "base/rete/Constants";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { node } from "base/rete/Types";
import { generateUUID } from "@jamango/content-client";
import { interpreterAnyToString } from "base/rete/NodeSharedUtil";
import { BB } from "base/BB";
import type { SocketDef } from "@jamango/content-client";
import * as CustomUIManager from "base/dom/CustomUI";
import { entitiesToPeers } from "base/rete/NodeSharedUtil";

function makeCustomUISelect(type: string) {
	return {
		name: "Custom UI",
		type: "string",
		control: "select",
		config: {
			autoSortOptions: () =>
				BB.world.content.state.customUI
					.getAllComponents()
					.filter((ui) => ui.type === type)
					.map((ui) => ({
						value: ui.id,
						label: ui.name,
					})),
		},
	} satisfies SocketDef<"I", "string">;
}

export const CUSTOM_UI_NODES = [
	node({
		id: "1000-5000-0001",
		name: "HUD Show Basic Indicator",
		type: NODE_TYPE_ID.customUI,
		description: "User interface for general use cases",
		inputs: {
			exec: { type: "exec" },
			customUI: makeCustomUISelect("basic-indicator"),
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			show: {
				name: "Show UI",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			value: { name: "Value", type: "any", structure: "any", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const peers = entitiesToPeers(inputs.peers, ctx.world);
			const data = {
				id: inputs.customUI,
				show: inputs.show,
				inputs: { value: interpreterAnyToString(inputs.value) },
			};
			CustomUIManager.broadcastInputs(ctx.world.customUI, data, peers);
		},
	}),
	node({
		id: "1000-5000-0002",
		name: "HUD Show Health Bar",
		type: NODE_TYPE_ID.customUI,
		description: "Show the player's health",
		inputs: {
			exec: { type: "exec" },
			customUI: makeCustomUISelect("health-bar"),
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			show: {
				name: "Show UI",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},

			text: { name: "Text", type: "any", structure: "any", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const peers = entitiesToPeers(inputs.peers, ctx.world);

			const data = {
				id: inputs.customUI,
				show: inputs.show,
				inputs: { text: interpreterAnyToString(inputs.text) },
			};

			CustomUIManager.broadcastInputs(ctx.world.customUI, data, peers);
		},
	}),
	node({
		id: "1000-5000-0003",
		name: "HUD Show Leaderboard",
		type: NODE_TYPE_ID.customUI,
		description:
			"Display a leaderboard based on peer's 'Entity state'. 'Filter' can be used to narrow down peers in the leaderboard, or left empty to include all peers.",
		inputs: {
			exec: { type: "exec" },
			customUI: makeCustomUISelect("leaderboard-local"),
			peers: { name: "Show To", type: "entity", structure: "list" },
			filter: { name: "Filter", type: "entity", structure: "list" },
			show: {
				name: "Show UI",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
		},

		dynamic: {
			addText: "Add State",
			inputs: {},
			add: (index, _def, _data) => {
				return {
					inputs: {
						["item-" + generateUUID()]: {
							name: "State",
							type: "string",
							index,
							reorderable: true,
							removable: true,
							dynamic: true,
						},
					},
				};
			},
		},

		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const peers = entitiesToPeers(inputs.peers, ctx.world);
			const filter = entitiesToPeers(inputs.filter, ctx.world);
			// makes an array of string items
			const states = Object.keys(inputs)
				.filter((key) => key !== "item" && key.startsWith("item"))
				.map((key) => inputs[key as keyof typeof inputs]) as string[];

			// create columns
			const columns = ["Rank", "Name", ...states];
			const rows = filter.map((p) => [
				0,
				getPeerMetadata(p)?.username || "?",
				...states.map((s) =>
					Number(
						ctx.world.peerToPlayer.has(p)
							? interpreterAnyToString(
									ctx.world.rete.state.entity[ctx.world.peerToPlayer.get(p)!.entityID]?.[
										s
									] ?? 0,
								)
							: 0,
					),
				),
			]);

			// sort by
			const sortCol = columns.length - 1;
			rows.sort((a, b) => (b[sortCol] as number) - (a[sortCol] as number));
			rows.forEach((r, i) => (r[0] = i + 1));

			const data = {
				id: inputs.customUI,
				show: inputs.show,
				inputs: { stateData: { columns, rows } },
			};

			CustomUIManager.broadcastInputs(ctx.world.customUI, data, peers);
		},
	}),
	node({
		id: "1000-5000-0004",
		name: "HUD Show Progress Bar",
		type: NODE_TYPE_ID.customUI,
		description: "Customizable progress bar",
		inputs: {
			exec: { type: "exec" },
			customUI: makeCustomUISelect("progress-bar"),
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			show: {
				name: "Show UI",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			text: { name: "Text", type: "any", structure: "any", control: "string" },
			value: { name: "Value", type: "number", control: "number" },
			maxValue: { name: "Max Value", type: "number", control: "number" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const { text, value, maxValue, show } = inputs;
			const peers = entitiesToPeers(inputs.peers, ctx.world);

			const data = {
				id: inputs.customUI,
				show,
				inputs: {
					text: interpreterAnyToString(text as string),
					value: interpreterAnyToString(value),
					maxValue: interpreterAnyToString(maxValue),
				},
			};

			CustomUIManager.broadcastInputs(ctx.world.customUI, data, peers);
		},
	}),
];
