export const HAIR_COLORS = [
	{ id: 1, name: "Hair1", color: "#FFFFFF" },
	{ id: 2, name: "Hair2", color: "#352214" },
	{ id: 3, name: "Hair3", color: "#61473A" },
	{ id: 4, name: "Hair4", color: "#6E5941" },
	{ id: 5, name: "Hair5", color: "#6D3E21" },
	{ id: 6, name: "Hair6", color: "#AB8560" },
	{ id: 7, name: "Hair7", color: "#CBB190" },
	{ id: 8, name: "Hair8", color: "#E7DFD4" },
	{ id: 9, name: "Hair9", color: "#FF36BA" },
	{ id: 10, name: "Hair10", color: "#FC9C35" },
	{ id: 11, name: "Hair11", color: "#3498db" },
	{ id: 12, name: "Hair12", color: "#e74c3c" },
];

// Note: If you update the default hair color, make sure to update what's in `user_costume.rb`
export const DEFAULT_HAIR_COLOR = HAIR_COLORS.find((color) => color.id === 2)!;

export const SKIN_COLORS = [
	{ id: 1, name: "Skin1", color: "#FFE7D1" },
	{ id: 2, name: "Skin2", color: "#E7BD99" },
	{ id: 3, name: "Skin3", color: "#FFC4A1" },
	{ id: 4, name: "Skin4", color: "#E6B090" },
	{ id: 5, name: "Skin5", color: "#F09776" },
	{ id: 6, name: "Skin6", color: "#A16E4B" },
	{ id: 7, name: "Skin7", color: "#3B2219" },
	{ id: 8, name: "Skin8", color: "#7929AB" },
	{ id: 9, name: "Skin9", color: "#8e44ad" },
	{ id: 10, name: "Skin10", color: "#3AAFD8" },
	{ id: 11, name: "Skin11", color: "#e74c3c" },
	{ id: 12, name: "Skin12", color: "#2ecc71" },
];

export const DEFAULT_SKIN_COLOR = SKIN_COLORS.find((color) => color.id === 3)!;
