import { DEGRAD } from "base/util/math/Math.ts";
import { Euler, Vector3 } from "three";
import { OnHitParticlesDefault, OnHitPlayerParticlesDefault } from "./DefaultParticles";

const s = new Vector3(0.5, 0.5, 2); //collider size

export const ItemNovaGun = {
	name: "ItemNovaGun",
	display: "Wild West Pistol",
	assets: [
		{
			id: "mdl-item-nova-gun",
			url: "assets/engine/mods/ItemNovaGun/mdl-item-nova-gun.glb",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-nova-gun",
		node: "item",
		animated: true,
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	muzzleFlashNode: "muzzle_flash_GEO",
	geom: { type: "box", width: s.x, height: s.y, depth: s.z },
	type: "ranged",
	weapon: true,
	automatic: false,
	animations: "NovaGun",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_NovaGun_FP_NotAim_Shoot",
				sight: "JMGO_NovaGun_FP_Aim_Shoot",
			},
			third: "JMGO_NovaGun_Shoot_Forward",
		},
	},
	reloadAnimationName: {
		item: "JMGO_NovaGun_Reload_rig",
		RigDefault: {
			third: "JMGO_NovaGun_Reload",
			first: "JMGO_NovaGun_FP_NotAim_Reload",
		},
	},
	reloadAnimationTime: 2.1,
	cooldown: 0.15,
	projectile: "ProjectileNovaGun",
	projectilePos: new Vector3(0, 55, -10),
	projectileCartridgeLimit: 6,
	projectilesPerShot: 1,
	shakeVertical: 1,
	shakeVerticalMax: 5,
	shakeRecovery: 0.95,
	spread: 1.2,
	recoil: 0.04,
	sound: {
		fire: { asset: "snd-item-nova-gun-fire", volume: 1 },
	},
	muzzleFlashParticles: [
		{
			colors: [1, 1, 1, 0.2],
			type: "cube",
			numParticles: 30,
			positionRange: [0.1, 0.1, 0.1],
			lifeTime: 1,
			endTime: 1,
			startTime: 0,
			startSize: 0.15,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			radius: 0.15,
			velocityAimDirectionIntensity: 1,
			velocity: [0, 0, 0],
			velocityRange: [0.2, 0.2, 0.2],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.1,
		},
	],
};

export const ProjectileNovaGun = {
	name: "ProjectileNovaGun",
	assets: [
		{
			url: "assets/engine/mods/ItemNovaGun/snd-item-nova-gun-fire.ogg",
			id: "snd-item-nova-gun-fire",
			type: "audio/ogg",
		},
	],
	velocity: 200,
	entityDamage: 24,
	headshotDamage: 64,
	bulletTrail: true,
	bulletTrailColor: "#fff58a",
	onHitParticles: OnHitParticlesDefault,
	onHitPlayerParticles: OnHitPlayerParticlesDefault,
};
