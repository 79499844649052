import { Vector3, Box3, MathUtils } from "three";
import { AXES } from "base/util/math/Math.ts";

const tmpVec1 = new Vector3();
const tmpVec2 = new Vector3();
const tmpVec3 = new Vector3();
const tmpBox = new Box3();

//rip off of libgdx intersector class
export class Intersector {
	//rayDir is expected to be normalized
	static intersectBoxRay(box, rayPos, rayDir, out) {
		if (
			box.min.x <= rayPos.x &&
			box.max.x >= rayPos.x &&
			box.min.y <= rayPos.y &&
			box.max.y >= rayPos.y &&
			box.min.z <= rayPos.z &&
			box.max.z >= rayPos.z
		) {
			out.copy(rayPos);
			return true;
		}

		let lowest = 0;
		let hit = false;
		let t;

		if (rayPos.x <= box.min.x && rayDir.x > 0) {
			t = (box.min.x - rayPos.x) / rayDir.x;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.y >= box.min.y &&
					tmpVec1.y <= box.max.y &&
					tmpVec1.z >= box.min.z &&
					tmpVec1.z <= box.max.z &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (rayPos.x >= box.max.x && rayDir.x < 0) {
			t = (box.max.x - rayPos.x) / rayDir.x;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.y >= box.min.y &&
					tmpVec1.y <= box.max.y &&
					tmpVec1.z >= box.min.z &&
					tmpVec1.z <= box.max.z &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (rayPos.y <= box.min.y && rayDir.y > 0) {
			t = (box.min.y - rayPos.y) / rayDir.y;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.x >= box.min.x &&
					tmpVec1.x <= box.max.x &&
					tmpVec1.z >= box.min.z &&
					tmpVec1.z <= box.max.z &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (rayPos.y >= box.max.y && rayDir.y < 0) {
			t = (box.max.y - rayPos.y) / rayDir.y;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.x >= box.min.x &&
					tmpVec1.x <= box.max.x &&
					tmpVec1.z >= box.min.z &&
					tmpVec1.z <= box.max.z &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (rayPos.z <= box.min.z && rayDir.z > 0) {
			t = (box.min.z - rayPos.z) / rayDir.z;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.x >= box.min.x &&
					tmpVec1.x <= box.max.x &&
					tmpVec1.y >= box.min.y &&
					tmpVec1.y <= box.max.y &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (rayPos.z >= box.max.z && rayDir.z < 0) {
			t = (box.max.z - rayPos.z) / rayDir.z;
			if (t >= 0) {
				tmpVec1.copy(rayDir).multiplyScalar(t).add(rayPos);
				if (
					tmpVec1.x >= box.min.x &&
					tmpVec1.x <= box.max.x &&
					tmpVec1.y >= box.min.y &&
					tmpVec1.y <= box.max.y &&
					(!hit || t < lowest)
				) {
					hit = true;
					lowest = t;
				}
			}
		}

		if (hit) {
			out.copy(rayDir).multiplyScalar(lowest).add(rayPos);
			out.x = MathUtils.clamp(out.x, box.min.x, box.max.x);
			out.y = MathUtils.clamp(out.y, box.min.y, box.max.y);
			out.z = MathUtils.clamp(out.z, box.min.z, box.max.z);
		}

		return hit;
	}

	static intersectBoxes(box1, box2) {
		const lx = Math.abs((box1.min.x + box1.max.x) / 2 - (box2.min.x + box2.max.x) / 2);
		const sumx = (box1.max.x - box1.min.x) / 2 + (box2.max.x - box2.min.x) / 2;
		if (lx > sumx) return false;

		const ly = Math.abs((box1.min.y + box1.max.y) / 2 - (box2.min.y + box2.max.y) / 2);
		const sumy = (box1.max.y - box1.min.y) / 2 + (box2.max.y - box2.min.y) / 2;
		if (ly > sumy) return false;

		const lz = Math.abs((box1.min.z + box1.max.z) / 2 - (box2.min.z + box2.max.z) / 2);
		const sumz = (box1.max.z - box1.min.z) / 2 + (box2.max.z - box2.min.z) / 2;
		return lz <= sumz;
	}

	/**
	 * Checks for intersection between a given box and a list of entities' axis-aligned bounding boxes (AABBs).
	 *
	 * @param {Box3} box - The box to check for intersections.
	 * @param {Array<import("base/world/entity/Entity").Entity>} entities - An array of entities to check against.
	 * @param {string} _excludeBlockType - The block type to exclude from intersection checks.
	 * @param {number} [fudgeFactor=0.25] - A small value to adjust AABB sizes to compensate for floating point rounding errors.
	 * @returns {Object|null} - The first entity that intersects with the box, or null if none do.
	 */
	static intersectBoxEntity(box, entities, _excludeBlockType = null, fudgeFactor = 0.25) {
		const aabb = tmpBox;

		for (const e of entities) {
			if (e.type.def.isCharacter && e.movement.state.isFlying) continue;
			if (e.getAABB === undefined) continue;

			//fudge factor compensates for floating point rounding error
			e.getAABB(aabb).expandByScalar(-fudgeFactor);

			if (Intersector.intersectBoxes(aabb, box)) return e;
		}
	}

	//derived from http://playtechs.blogspot.com/2007/03/raytracing-on-grid.html
	//this function creates garbage
	//rayDir does not need to be normalized
	static intersectVoxelsRay(rayPos, rayDir, n) {
		const ret = [];
		if (n <= 0) return ret;

		const cur = rayPos.clone().floor();
		const inc = tmpVec1.set(Math.sign(rayDir.x), Math.sign(rayDir.y), Math.sign(rayDir.z));
		const next = tmpVec2;
		const dt = tmpVec3.set(1 / Math.abs(rayDir.x), 1 / Math.abs(rayDir.y), 1 / Math.abs(rayDir.z));

		for (const axis of AXES) {
			if (inc[axis] === 0)
				next[axis] = dt[axis]; //infinity
			else if (inc[axis] === 1) next[axis] = (Math.floor(rayPos[axis]) + 1 - rayPos[axis]) * dt[axis];
			//-1
			else next[axis] = (rayPos[axis] - Math.floor(rayPos[axis])) * dt[axis];
		}

		for (let i = 0; i < n; i++) {
			ret.push(cur.clone());

			const map = Object.entries(next).sort((a, b) => a[1] - b[1]);

			getNext(map[0][0]);
			for (let j = 1; j < map.length; j++) {
				if (map[j - 1][1] === map[j][1]) getNext(map[j][0]);
				else break;
			}
		}

		function getNext(axis) {
			cur[axis] += inc[axis];
			next[axis] += dt[axis];
		}

		return ret;
	}

	static distanceLinePoint(l1, l2, p) {
		return (
			tmpVec1.copy(l2).sub(l1).cross(tmpVec2.copy(l1).sub(p)).length() /
			tmpVec1.copy(l2).sub(l1).length()
		);
	}
}
