import type { Entity } from "base/world/entity/Entity";

/**
 * Updates the previous position and quaternion fields of the entity at the beginning of the fixed update pipeline,
 * before positions and quaternions are modified.
 */
export function entityPreviousTransformUpdate(entity: Entity) {
	entity.prevPosition.copy(entity.position);
	entity.prevQuaternion.copy(entity.quaternion);
}
