import type { WorldUserRole } from "@jamango/content-client";

export type PeerMetadata = {
	username: string;
	accountID: string;
	isGuest: boolean; //missing from loopback peer
	role: WorldUserRole; //CONTENT permissions. missing from joined client loopback peer
	avatarID?: string; //from UserState.avatarID

	permissions: {
		//GAMEPLAY permissions

		cameraPOV: "first" | "third" | "both";

		canUseIndividualBlocks: boolean;
		canUseWrench: boolean;
		canUsePencil: boolean;

		canFly: boolean;
		canInteract: boolean;
		canForceRespawn: boolean;
	};

	//frontend-specific
	peerID: string;
	displayPhotoURL?: string | null;
	avatarURL?: string | null;
	isHost: boolean;
};

export function getDefaultPermissions(): PeerMetadata["permissions"] {
	//by default, give all permissions
	return {
		cameraPOV: "both",
		canUseIndividualBlocks: true,
		canUseWrench: true,
		canUsePencil: true,
		canFly: true,
		canInteract: true,
		canForceRespawn: true,
	};
}
