import { UI } from "client/dom/UI";
import { NET_CLIENT } from "@jamango/ibs";
import { PencilMode } from "client/world/tools/Pencil";

/**
 * @typedef {{
 *   equippedItem?: {
 *     def?: string,
 *     component?: string,
 *   }
 * }} EquippedItemContextCondition
 */

/**
 * @typedef {{
 *   fn?: ({ equippedItem, cameraTarget }) => boolean,
 * }} FnContextCondition
 */

/**
 * @typedef {{
 *   any?: ContextRule[],
 * }} AnyContextCondition
 */

/**
 * @typedef {{
 *   all?: ContextRule[],
 * }} AllContextCondition
 */

/**
 * @typedef {{
 *   not?: ContextRule[],
 * }} AllContextCondition
 */

/**
 * @typedef {EquippedItemContextCondition | AnyContextCondition} ContextCondition
 */

/**
 * @type {ContextCondition}
 */
export const CREATOR_CONTEXT = {
	any: [
		{ equippedItem: { def: "ItemPencil" } },
		{ equippedItem: { def: "ItemWrench" } },
		{ equippedItem: { def: "ItemSpawner" } },
		{ equippedItem: { component: "block" } },
	],
};

/**
 * @type {ContextCondition}
 */
export const CREATOR_EVENTS_CONTEXT = {
	any: [
		{ equippedItem: { def: "ItemPencil" } },
		{ equippedItem: { def: "ItemWrench" } },
		{ equippedItem: { def: "ItemSpawner" } },
	],
};

/**
 * @type {ContextCondition}
 */
export const SCULPT_CONTEXT = {
	any: [{ equippedItem: { component: "block" } }],
};

/**
 * @type {ContextCondition}
 */
const RELOAD_CONTEXT = { equippedItem: { component: "itemReload" } };

/**
 * @type {ContextCondition}
 */
const PENCIL_CONTEXT = { equippedItem: { def: "ItemPencil" } };

/**
 * @type {ContextCondition}
 */
const PENCIL_CREATING_SELECTION = {
	all: [
		PENCIL_CONTEXT,
		{
			fn: ({ world }) =>
				PencilMode.START === world.client.pencil.pencilMode ||
				PencilMode.ONE_CORNER_SELECTED === world.client.pencil.pencilMode ||
				PencilMode.TWO_CORNERS_SELECTED === world.client.pencil.pencilMode ||
				PencilMode.ENTITIES_SELECTED === world.client.pencil.pencilMode,
		},
	],
};

/**
 * @type {ContextCondition}
 */
const PENCIL_TWO_CORNERS_SELECTED = {
	all: [
		PENCIL_CONTEXT,
		{
			fn: ({ world }) => world.client.pencil.pencilMode === PencilMode.TWO_CORNERS_SELECTED,
		},
	],
};

/**
 * @type {ContextCondition}
 */
const PENCIL_PASTE_PREVIEW = {
	all: [
		PENCIL_CONTEXT,
		{
			fn: ({ world }) => world.client.pencil.pencilMode === PencilMode.PASTE_PREVIEW,
		},
	],
};

/**
 * @type {ContextCondition}
 */
const WRENCH_CONTEXT = { equippedItem: { def: "ItemWrench" } };

/**
 * @type {ContextCondition}
 */
const SELECTOR_NUDGE_CONTEXT = {
	any: [PENCIL_CONTEXT, WRENCH_CONTEXT],
};

/**
 * @type {ContextCondition}
 */
const BULK_SELECTION_CONTEXT = {
	any: [WRENCH_CONTEXT, PENCIL_CREATING_SELECTION],
};

/**
 * @type {ContextCondition}
 */
const CONTROLLING_PLAYER = {
	fn: ({ cameraTarget }) => cameraTarget?.type.def.isPlayer,
};

/**
 * @type {ContextCondition}
 */
const ITEM_PATH_TESTER_CONTEXT = {
	equippedItem: { def: "ItemPathTester" },
};

/**
 * @type {ContextCondition}
 */
export const SELECT_AIR_BLOCKS_CONTEXT = {
	any: [WRENCH_CONTEXT, PENCIL_CONTEXT, ITEM_PATH_TESTER_CONTEXT],
};

/**
 * @type {ContextCondition}
 */
export const SELECTOR_MOUSE_WHEEL_CONTEXT = CREATOR_CONTEXT;

/**
 * @type {Record<string, ContextCondition>}
 */
export const CONTROL_CONTEXTS = {
	reload: RELOAD_CONTEXT,
	sculpt: SCULPT_CONTEXT,
	selectorToggleSelectionMode: CREATOR_CONTEXT,
	pencilSolidFill: PENCIL_TWO_CORNERS_SELECTED,
	pencilHollowFill: PENCIL_TWO_CORNERS_SELECTED,
	pencilRemoveAllBlocks: PENCIL_TWO_CORNERS_SELECTED,
	pencilRotateClipboard: PENCIL_PASTE_PREVIEW,
	pencilFlipClipboard: PENCIL_PASTE_PREVIEW,
	pencilCreateBlockStructure: PENCIL_CONTEXT,
	copy: PENCIL_CONTEXT,
	paste: PENCIL_CONTEXT,
	pasteImmediately: PENCIL_CONTEXT,
	undo: PENCIL_CONTEXT,
	redo: PENCIL_CONTEXT,
	selectorResetBulkSelection: BULK_SELECTION_CONTEXT,
	selectorNudgeLeft: SELECTOR_NUDGE_CONTEXT,
	selectorNudgeRight: SELECTOR_NUDGE_CONTEXT,
	selectorNudgeForward: SELECTOR_NUDGE_CONTEXT,
	selectorNudgeBack: SELECTOR_NUDGE_CONTEXT,
	selectorNudgeUp: SELECTOR_NUDGE_CONTEXT,
	selectorNudgeDown: SELECTOR_NUDGE_CONTEXT,
	wrenchRemoveBlockGroups: WRENCH_CONTEXT,
	selectorCycleScriptTargets: WRENCH_CONTEXT,
};

/**
 * Evaluates whether the given context conditions are satisfied.
 *
 * Currently supports conditions that operate on:
 * - equipped item defs and components
 * - camera target
 *
 * @param {{
 *   context: ContextCondition,
 *   equippedItem?: any,
 *   cameraTarget?: any,
 *   world?: any,
 * }} props
 */
export const evaluateContext = ({ context, world, equippedItem, cameraTarget }) => {
	const equippedItemDef = equippedItem?.def;

	/**
	 * @param {ContextCondition} condition
	 * @returns {boolean}
	 */
	const evaluate = (condition) => {
		if (condition?.equippedItem && equippedItem) {
			if (
				condition.equippedItem?.def &&
				(condition.equippedItem.def === equippedItemDef ||
					equippedItemDef.startsWith(condition.equippedItem.def))
			) {
				return true;
			}

			if (condition.equippedItem?.component && condition.equippedItem.component in equippedItem) {
				return true;
			}
		}

		if (condition.fn) {
			return condition.fn({ world, equippedItem, cameraTarget });
		}

		if (condition.any) {
			for (const child of condition.any) {
				if (evaluate(child)) {
					return true;
				}
			}
		}

		if (condition.all) {
			for (const child of condition.all) {
				if (!evaluate(child)) {
					return false;
				}
			}

			return true;
		}

		if (condition.not) {
			for (const child of condition.not) {
				if (evaluate(child)) {
					return false;
				}
			}

			return true;
		}

		return false;
	};

	return evaluate(context);
};

export class PlayerContext {
	constructor(world) {
		this.world = world;
		this.inCreatorContext = false;
		this.inCreatorEventsContext = false;
		this.inSculptContext = false;
		this.controllingPlayer = false;
	}

	init() {
		this.world.dispatcher.addEventListener(NET_CLIENT, "itemchange", this.updateContexts);
		this.world.dispatcher.addEventListener(NET_CLIENT, "cameratargetchange", this.updateContexts);
	}

	dispose() {
		this.world.dispatcher.removeEventListener(NET_CLIENT, "itemchange", this.updateContexts);
		this.world.dispatcher.removeEventListener(NET_CLIENT, "cameratargetchange", this.updateContexts);
	}

	updateContexts = ({ curItem: equippedItem }) => {
		const world = this.world;
		const cameraTarget = world.client.camera.target;

		this.inCreatorContext = evaluateContext({
			context: CREATOR_CONTEXT,
			equippedItem,
			cameraTarget,
			world,
		});

		this.inCreatorEventsContext = evaluateContext({
			context: CREATOR_EVENTS_CONTEXT,
			equippedItem,
			cameraTarget,
			world,
		});

		this.inSculptContext = evaluateContext({
			context: SCULPT_CONTEXT,
			equippedItem,
			cameraTarget,
			world,
		});

		this.controllingPlayer = evaluateContext({
			context: CONTROLLING_PLAYER,
			equippedItem,
			cameraTarget,
			world,
		});

		UI.state.playerContext().setContexts({
			inCreatorContext: this.inCreatorContext,
			inCreatorEventsContext: this.inCreatorEventsContext,
			inSculptContext: this.inSculptContext,
			controllingPlayer: this.controllingPlayer,
		});
	};
}
