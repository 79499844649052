import type { Item } from "base/world/entity/Item";
import type { ParticleEngine, ParticleParameters } from "client/world/fx/ParticleEngine";
import { Vector3 } from "three";
import type { Character } from "base/world/entity/Character";

const _muzzleWorldPosition = new Vector3();
const _muzzleParticlesDirection = new Vector3();
const _particleOptionsVelocity = new Vector3();
const _velocity = new Vector3();
let _muzzle_id = 0; // every muzzle flash requires an ID in the particle system. we increment

export const createMuzzleFlash = (item: Item, particleEngine: ParticleEngine) => {
	const muzzleFlashNode = item.weapon.def.muzzleFlashNode;
	const muzzleFlashParticles = item.weapon.def.muzzleFlashParticles;

	if (!item.itemType.def.isRanged || !muzzleFlashParticles || !muzzleFlashNode) {
		return;
	}

	const parent = item.mount.state.parent?.type.def.isCharacter
		? (item.mount.state.parent as Character)
		: null;

	// this system assumes that the gun is being shot while being held from a character
	// if we at some point need "loose cannons" shooting in some other way, this needs to read pos/rot from other places
	if (parent === null) return;

	const isIronSights = parent && parent.type.def.isCharacter && parent.movement.state.isIronSights;

	const muzzleWorldPosition = muzzleFlashNode.getWorldPosition(_muzzleWorldPosition);

	if (isIronSights) {
		muzzleWorldPosition.y -= 0.1;
	}

	for (let i = 0; i < muzzleFlashParticles.length; i++) {
		const { velocityAimDirectionIntensity, ...baseParticleOptions } = muzzleFlashParticles[i];
		const particleOptions: Partial<ParticleParameters> = {
			...baseParticleOptions,
			name: `muzzle_flash_${_muzzle_id++}`,
			position: muzzleWorldPosition.toArray(),
		};

		_muzzleParticlesDirection.copy(parent.viewRaycast.state.rayDir); //0, isIronSights ? 0.2 : 0, 1).applyEuler(item.rotation);
		_muzzleParticlesDirection.y += 0.2;

		if (velocityAimDirectionIntensity) {
			const particleOptionsVelocity = _particleOptionsVelocity.set(0, 0, 0);

			if ("velocity" in particleOptions) {
				particleOptionsVelocity.add(
					_velocity.set(...(particleOptions.velocity as [number, number, number])),
				);
			}

			particleOptionsVelocity.add(
				_muzzleParticlesDirection
					.copy(_muzzleParticlesDirection)
					.multiplyScalar(velocityAimDirectionIntensity),
			);

			particleOptions.velocity = particleOptionsVelocity.toArray();
		}

		particleEngine.add(particleOptions);
	}
};
