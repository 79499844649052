import type { IBlockStructure } from "@jamango/content-client";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import type { World } from "base/world/World";
import { MISSING_BLOCK_TYPE } from "base/world/block/BlockTypeRegistry";
import { ChunkBuilder } from "base/world/block/Builder";
import { ChunkUtil } from "base/world/block/Util";
import { Layers } from "client/Layers";
import { ShadowMesh } from "client/util/ShadowMesh.js";
import { BufferGeometry, Object3D } from "three";
import { Box3, Vector3 } from "three";

const _point = new Vector3();
const _box = new Box3();
const _offset = new Vector3();

export function getBlockStructureWorldSize(blockStructure: IBlockStructure, out: Vector3) {
	for (const [x, y, z] of blockStructure.data.blocks) {
		_box.expandByPoint(_point.set(x, y, z));
	}

	_box.getSize(out);

	out.addScalar(1);

	return out;
}

export function createBlockStructureMesh(world: World, blockStructure: IBlockStructure, parent: Object3D = new Object3D()) {
	const meshes: ShadowMesh[] = [];
	const geometries: BufferGeometry[] = [];

	const voxelSize = new Vector3();
	const worldSize = new Vector3();

	const blocks = new LegacyVectorMap();
	const box = new Box3();

	blockStructure.data.blocks.forEach(([x, y, z, shape, typeIndex]) => {
		blocks.set(x, y, z, { shape, type: blockStructure.data.blockTypes[typeIndex] });

		box.expandByPoint(_point.set(x, y, z));
	});

	box.getSize(voxelSize);
	worldSize.copy(voxelSize).addScalar(1);

	const offset = _offset.copy(worldSize).multiplyScalar(0.5);

	const volume = worldSize.x * worldSize.y * worldSize.z;
	const shapes = new Uint8Array(volume);
	const types = new Uint16Array(volume);

	blockStructure.data.blocks.forEach(([x, y, z, shape, typeIndex]) => {
		const type = blockStructure.data.blockTypes[typeIndex];

		const adjustedX = x - box.min.x;
		const adjustedY = y - box.min.y;
		const adjustedZ = z - box.min.z;

		const index = ChunkUtil.getIndex(worldSize.x, worldSize.y, worldSize.z, adjustedX, adjustedY, adjustedZ);

		shapes[index] = shape;
		types[index] = world.blockTypeRegistry.blockNameToType.get(type)?.id ?? MISSING_BLOCK_TYPE.id;
	});

	const { opaqueBuffer, alphaTestedBuffer, translucentBuffer } = ChunkBuilder.build(
		shapes,
		types,
		worldSize.x,
		worldSize.y,
		worldSize.z,
		world.blockTypeRegistry,
		true,
	);

	if (opaqueBuffer.position.length > 0) {
		const opaqueGeometry = ChunkBuilder.buildGeometry(opaqueBuffer);
		opaqueGeometry.computeBoundingBox();
		opaqueGeometry.computeBoundingSphere();

		const opaqueMesh = new ShadowMesh(opaqueGeometry, world.scene.mat);
		opaqueMesh.matrixAutoUpdate = false;

		opaqueMesh.position.sub(offset);
		opaqueMesh.updateMatrix();

		parent.add(opaqueMesh);
		geometries.push(opaqueGeometry);
		meshes.push(opaqueMesh);
	}

	if (alphaTestedBuffer.position.length > 0) {
		const alphaTestedGeometry = ChunkBuilder.buildGeometry(alphaTestedBuffer);
		alphaTestedGeometry.computeBoundingBox();
		alphaTestedGeometry.computeBoundingSphere();

		const alphaTestedMesh = new ShadowMesh(alphaTestedGeometry, world.scene.alphaTestedMaterial);
		alphaTestedMesh.matrixAutoUpdate = false;

		alphaTestedMesh.position.sub(offset);
		alphaTestedMesh.updateMatrix();

		parent.add(alphaTestedMesh);
		geometries.push(alphaTestedGeometry);
		meshes.push(alphaTestedMesh);
	}

	if (translucentBuffer.position.length > 0) {
		const translucentGeometry = ChunkBuilder.buildGeometry(translucentBuffer);
		translucentGeometry.computeBoundingBox();
		translucentGeometry.computeBoundingSphere();

		const translucentMesh = new ShadowMesh(translucentGeometry, world.scene.translucentMaterial);
		translucentMesh.layers.set(Layers.ORDER_INDEPENDENT_TRANSPARENCY);
		translucentMesh.matrixAutoUpdate = false;

		translucentMesh.position.sub(offset);
		translucentMesh.updateMatrix();

		parent.add(translucentMesh);
		geometries.push(translucentGeometry);
		meshes.push(translucentMesh);
	}

	return {
		group: parent,
		meshes,
		geometries,
		box,
		worldSize,
		voxelSize,
	};
}
