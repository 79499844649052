import type { EulerOrder } from "three";
import { Vector3, Euler, Quaternion, MathUtils } from "three";

export const AXES = ["x", "y", "z"];
export const V0 = new Vector3();
export const V1 = new Vector3(1, 1, 1);
export const VX = new Vector3(1, 0, 0);
export const VY = new Vector3(0, 1, 0);
export const VZ = new Vector3(0, 0, 1);
export const VNX = new Vector3(-1, 0, 0);
export const VNY = new Vector3(0, -1, 0);
export const VNZ = new Vector3(0, 0, -1);

export type EulerLike = { x: number; y: number; z: number; order?: EulerOrder };

export const EULER_ORDERS: EulerOrder[] = ["YXZ", "YZX", "ZXY", "ZYX", "XYZ", "XZY"];

export const Q0 = new Quaternion();
export const E0 = new Euler();

export const PI = Math.PI; //180 degrees
export const PI2 = Math.PI * 2; //360 degrees
export const PIH = Math.PI / 2; // 90 degrees
export const RADDEG = 180 / PI; //Convert radians to degrees by multiplying
export const DEGRAD = PI / 180; //Convert degrees to radians by multiplying

export const EPSILON = 0.000001;

const tmpVec1 = new Vector3();

//random in range [low, high)
export function rand(low: number, high: number) {
	return low + Math.random() * (high - low);
}

/**
 * Generates a random number within a given range, with a skew.
 * The skew parameter adjusts the distribution of the random numbers.
 *
 * @param {number} low - The lower bound of the range.
 * @param {number} high - The upper bound of the range.
 * @param {number} skew - The skew factor. A value greater than 1 makes lower numbers more likely, while a value less than 1 makes higher numbers more likely.
 * @returns {number} A random number within the range [low, high], skewed according to the skew parameter.
 */
export function skewedRand(low: number, high: number, skew: number) {
	let rand = Math.random();
	rand = Math.pow(rand, skew);
	return low + rand * (high - low);
}

//random integer in range [low, high]
export function randInt(low: number, high: number) {
	return low + Math.floor(Math.random() * (high - low + 1));
}

//random positive or negative sign
export function randSign() {
	return (Math.random() - 0.5) * 2;
}

//shortest distance between two angles in range [-PI, PI)
export function angleDistance(cur: number, prv: number) {
	return wrapAngle(cur - prv);
}

//wrap angle in range [-PI, PI)
export function wrapAngle(angle: number) {
	const diff = ((angle + PI) % PI2) - PI;
	return diff < -PI ? diff + PI2 : diff;
}

export function bytesToString(inBytes: number) {
	// just convert to number if inBytes is a bigint 🤠 this is just for display purposes
	const bytes = typeof inBytes === "bigint" ? Number(inBytes) : inBytes;

	const units = ["B", "KB", "MB", "GB", "TB", "PB"];
	let size = bytes;
	let unitIndex = 0;

	while (size >= 1024 && unitIndex < units.length - 1) {
		size /= 1024;
		unitIndex++;
	}

	return `${Math.round(size * 100) / 100}${units[unitIndex]}`;
}

export function distanceFromRange(x: number, min: number, max: number) {
	if (x >= min && x <= max) return 0;
	else return Math.min(Math.abs(x - min), Math.abs(x - max));
}

//https://gist.github.com/mfirmin/456e1c6dcf7b0e1bda6e940add32adad
//This function converts a Float16 stored as the bits of a Uint16 into a Javascript Number.
export function float16ToNumber(v: number) {
	const arr = new ArrayBuffer(4);
	const dv = new DataView(arr);
	dv.setUint16(2, v, false);
	const asInt32 = dv.getInt32(0, false);
	let rest = asInt32 & 0x7fff;
	let sign = asInt32 & 0x8000;
	const exponent = asInt32 & 0x7c00;
	rest <<= 13;
	sign <<= 16;
	rest += 0x38000000;
	rest = exponent === 0 ? 0 : rest;
	rest |= sign;
	dv.setInt32(0, rest, false);
	return dv.getFloat32(0, false);
}

export function sInterpTo(s: number, target: number, deltaTime: number, interpSpeed: number) {
	if (interpSpeed <= 0.0) {
		return target;
	}

	let dist = target - s;
	if (Math.abs(target - s) < EPSILON) {
		return target;
	}

	const deltaMove = MathUtils.clamp(deltaTime * interpSpeed, 0.0, 1.0);
	dist = dist * deltaMove;
	return s + dist;
}

export function aInterpTo(a: number, target: number, deltaTime: number, interpSpeed: number) {
	if (interpSpeed <= 0.0) {
		return target;
	}

	let dist = angleDistance(target, a);
	if (Math.abs(dist) < EPSILON) {
		return target;
	}

	const deltaMove = MathUtils.clamp(deltaTime * interpSpeed, 0.0, 1.0);
	dist = dist * deltaMove;
	return wrapAngle(a + dist);
}

export function vInterpTo(v: Vector3, target: Vector3, deltaTime: number, interpSpeed: number) {
	if (interpSpeed <= 0.0) {
		v.copy(target);
		return;
	}

	tmpVec1.copy(target).sub(v);

	if (tmpVec1.lengthSq() < EPSILON) {
		v.copy(target);
		return;
	}

	const deltaMove = MathUtils.clamp(deltaTime * interpSpeed, 0.0, 1.0);
	tmpVec1.multiplyScalar(deltaMove);
	v.add(tmpVec1);
	return;
}

export function qInterpTo(q: Quaternion, target: Quaternion, deltaTime: number, interpSpeed: number) {
	if (interpSpeed <= 0.0) {
		q.copy(target);
		return;
	}

	if (q === target) {
		q.copy(target);
		return q;
	}

	q.slerp(target, MathUtils.clamp(deltaTime * interpSpeed, 0.0, 1.0));
	return;
}

export function hermiteCubic(t: number) {
	return -2 * t * t * t + 3 * t * t;
}

export function getYawFromQuaternion(q: Quaternion) {
	return Math.atan2(2.0 * (q.y * q.z + q.x * q.w), -1.0 + 2.0 * (q.x * q.x + q.y * q.y));
}
