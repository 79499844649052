import type { definePublications } from "./pubs";
import type { defineMethods } from "./methods";

export type Publications = ReturnType<typeof definePublications>[number];
export type PubNames = Publications["subName"];
export type PubDataByName<TName extends PubNames> = Extract<Publications, { subName: TName }>;
export type PubParamsByName<TName extends PubNames> = Parameters<PubDataByName<TName>["cb"]>[0]["params"];
export type PubEventByName<TName extends PubNames> = Parameters<
	Parameters<PubDataByName<TName>["cb"]>[0]["send"]
>[0];
export type PubParamsByNameAsArgs<T extends PubNames> = {} extends PubParamsByName<T>
	? []
	: [PubParamsByName<T>];

export type Methods = ReturnType<typeof defineMethods>[number];
export type MethodNames = Methods["methodName"];
export type MethodDataByName<TName extends MethodNames> = Extract<Methods, { methodName: TName }>;
export type MethodParamsByName<TName extends MethodNames> = Parameters<
	MethodDataByName<TName>["cb"]
>[0]["params"];
export type MethodResultByName<TName extends MethodNames> = Awaited<
	ReturnType<MethodDataByName<TName>["cb"]>
>;
export type MethodParamsByNameAsArgs<T extends MethodNames> = {} extends MethodParamsByName<T>
	? []
	: [MethodParamsByName<T>];

export class ProtocolError extends Error {
	constructor(
		readonly error: "unauthorized" | "internal-error",
		message?: string,
		readonly reason?: string,
	) {
		super(message);
	}
}

export type ClientMessages =
	| {
			type: "login";
			id: string;
			token?: string;
	  }
	| {
			type: "sub";
			id: string;
			name: PubNames;
			params: PubParamsByName<PubNames>;
	  }
	| {
			type: "unsub";
			id: string;
	  }
	| {
			type: "method";
			id: string;
			name: MethodNames;
			params: MethodParamsByName<MethodNames>;
	  };

export type ServerMessages =
	| {
			type: "connected";
			session: string;
			buildVersion: string;
	  }
	| {
			type: "failed";
			reason: string;
	  }
	| {
			type: "sub";
			id: string;
			msg: "no-sub" | "disposed";
			error?: ProtocolError;
	  }
	| {
			type: "event"; //sub's data
			id: string;
			result: PubEventByName<PubNames>;
	  }
	| {
			type: "result";
			id: string;
			result: null;
			error: ProtocolError;
	  }
	| {
			type: "result";
			id: string;
			result: MethodResultByName<MethodNames>;
			error: null;
	  }
	| {
			type: "ping";
	  };

export type Protocol = ClientMessages | ServerMessages;
