import { Analytics } from "@jamango/analytics";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { entityIsPlayer } from "base/world/entity/component/Type";

const EVENT_NAME_REGEX = /^[a-zA-Z0-9-:]+$/;

export const ANALYTICS_NODES = [
	node({
		id: "71024b60-21d4-4bc0-ba90-1ef57915670a",
		name: "Analytics Track Player Event",
		type: NODE_TYPE_ID.function.analytics,
		description: "Tracks an event for a player.",
		info: [
			"Currently only useful for the Jamango! team.",
			"A world analytics feature that uses this node will come in the future!",
		],
		inputs: {
			exec: { type: "exec" },
			player: { name: "Player", type: "entity" },
			event: {
				name: "Event",
				type: "string",
				config: {
					defaultValue: "scope:your-event-name",
					validation: [
						{
							condition: (value: string) =>
								!EVENT_NAME_REGEX.test(value) || value.length <= 0 || value.length > 100,
							message: () =>
								"Event name must be between 1 to 100 characters, and only contain alphanumeric characters, dashes (-), and colons (:)",
						},
					],
				},
			}, // validation
			value: { name: "Value", type: "number", config: { defaultValue: 1 } },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const player = inputs.player;
			if (!player || !entityIsPlayer(player)) {
				throw new Error("A player entity was not provided.");
			}

			const peer = ctx.world.playerToPeer.get(player);
			if (!peer) {
				throw new Error("Peer for Player entity not found.");
			}

			const event = String(inputs.event).slice(0, 100);
			if (!EVENT_NAME_REGEX.test(event)) {
				throw new Error("Event name must only contain alphanumeric characters, dashes, and colons.");
			}

			const metadata = getPeerMetadata(peer);
			const userId = metadata.accountID;

			const shortcode = ctx.world.content.state.worldData.shortCode;
			const worldId = shortcode === "" ? `new-${userId}` : shortcode;
			const analyicsEventName = `game:world:${worldId}:nodes:${event}` as const;

			const value = Number.isNaN(inputs.value) ? 1 : inputs.value;

			Analytics.track(analyicsEventName, value, {
				userId,
				worldId,
			});
		},
	}),
];
