import {
	AddEquation,
	Color,
	CustomBlending,
	FloatType,
	HalfFloatType,
	NearestFilter,
	OneFactor,
	RGBAFormat,
	UnsignedByteType,
	WebGLRenderTarget,
	ZeroFactor,
} from "three";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";

function fuzzyEqual(a, b, epsilon = 0.01) {
	return a < b + epsilon && a > b - epsilon;
}

const FillShader = {
	uniforms: {
		color: { value: new Color(0xffffff) },
		opacity: { value: 1.0 },
	},

	vertexShader: /* glsl */ `

		void main() {

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader: /* glsl */ `

		uniform vec3 color;
		uniform float opacity;

		void main() {

			gl_FragColor = vec4( color, opacity );

		}`,
};

/**
 * Find the best float target type for the current renderer.
 * gl.getExtension( 'EXT_color_buffer_float' ) - lacking support, see:
 * https://stackoverflow.com/questions/28827511/webgl-ios-render-to-floating-point-texture
 *
 * @param {import('three').WebGLRenderer} renderer
 * @returns {number}
 */
export function findBestFloatTargetType(renderer) {
	const testPass = new ShaderPass(FillShader);
	const testR = 1.0;
	const testG = 1.0;
	const testB = 1.0;
	const testA = 0.0;
	testPass.material.uniforms["color"].value = new Color(testR, testG, testB);
	testPass.material.uniforms["opacity"].value = testA;
	testPass.material.blending = CustomBlending;
	testPass.material.blendEquation = AddEquation;
	testPass.material.blendSrc = OneFactor;
	testPass.material.blendDst = ZeroFactor;

	const gl = renderer.getContext();

	const oldTarget = renderer.getRenderTarget();
	const oldClearAlpha = renderer.getClearAlpha();
	const oldClearColor = new Color();
	renderer.getClearColor(oldClearColor);

	const targetTypes = [FloatType, HalfFloatType, UnsignedByteType];
	const targetGlTypes = [gl.FLOAT, gl.HALF_FLOAT, gl.UNSIGNED_BYTE];
	const targetBuffers = [new Float32Array(4), new Uint16Array(4), new Uint8Array(4)];
	const targetDivisor = [1, 15360, 255];

	let targetType;

	for (let i = 0; i < targetTypes.length; i++) {
		const testTarget = new WebGLRenderTarget(1, 1, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetTypes[i],
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: true,
		});

		testPass.render(renderer, testTarget);

		gl.readPixels(0, 0, 1, 1, gl.RGBA, targetGlTypes[i], targetBuffers[i]);
		const rgba = Array.apply([], targetBuffers[i]);
		rgba[0] /= targetDivisor[i];
		rgba[1] /= targetDivisor[i];
		rgba[2] /= targetDivisor[i];
		rgba[3] /= targetDivisor[i];

		let complete = gl.checkFramebufferStatus(gl.FRAMEBUFFER) === gl.FRAMEBUFFER_COMPLETE;
		complete = complete && fuzzyEqual(rgba[0], testR);
		complete = complete && fuzzyEqual(rgba[1], testG);
		complete = complete && fuzzyEqual(rgba[2], testB);
		complete = complete && fuzzyEqual(rgba[3], testA);
		complete = complete || i === targetTypes.length - 1;

		testTarget.dispose();

		if (complete) {
			targetType = targetTypes[i];
			break;
		}
	}

	if (testPass.dispose) testPass.dispose();

	renderer.setRenderTarget(oldTarget);
	renderer.setClearColor(oldClearColor, oldClearAlpha);

	return targetType;
}
