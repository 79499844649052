import { BoxGeometry, EdgesGeometry } from "three";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry.js";

export class BoxLineSegmentsGeometry extends LineSegmentsGeometry {
	constructor(width = 1, height = 1, depth = 1) {
		super();

		const halfWidth = width / 2;
		const halfHeight = height / 2;
		const halfDepth = depth / 2;

		const edgesGeometry = new EdgesGeometry(new BoxGeometry(width, height, depth));

		for (let i = 0; i < edgesGeometry.attributes.position.count; i++) {
			const x = edgesGeometry.attributes.position.getX(i);
			const y = edgesGeometry.attributes.position.getY(i);
			const z = edgesGeometry.attributes.position.getZ(i);

			edgesGeometry.attributes.position.setXYZ(i, x + halfWidth, y + halfHeight, z + halfDepth);
		}

		this.fromEdgesGeometry(edgesGeometry);
	}
}
