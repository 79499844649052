import {
	Color,
	DoubleSide,
	EqualDepth,
	Matrix3,
	MeshBasicMaterial,
	MeshLambertMaterial,
	RawShaderMaterial,
	ShaderMaterial,
	SpriteMaterial,
} from "three";
import { WboitStages } from "client/util/OrderIndependentTransparency.js";

export function initFog() {}

export class DepthOnlyMaterial extends RawShaderMaterial {
	constructor() {
		super();

		this.name = "DepthOnlyMaterial";

		this.vertexShader = `precision highp float;
			precision highp int;
			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;
			attribute vec3 position;
			void main() {
				vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
				gl_Position = projectionMatrix * mvPosition;
			}`;
		this.fragmentShader = `void main() {}`;

		this.colorWrite = false;
	}
}

export class DepthOnlyAphaTestedMaterial extends RawShaderMaterial {
	constructor() {
		super();

		this.name = "DepthOnlyAphaTestMaterial";

		this.color = new Color(0xffffff);
		this.alphaTest = 0.5;
		this.side = DoubleSide;

		this.uniforms = {
			diffuse: { value: new Color(0xffffff) },
			opacity: { value: 1.0 },
			map: { value: null },
			mapTransform: { value: new Matrix3() },
			alphaTest: { value: 0 },
		};

		this.isMeshDepthMaterial = true;

		this.vertexShader = `precision highp float;
			precision highp int;
			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;
			attribute vec3 position;
			attribute vec2 uv;

			uniform mat3 mapTransform;
    		varying vec2 vMapUv;

			void main() {
				vMapUv = ( mapTransform * vec3( uv, 1 ) ).xy;
				vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
				gl_Position = projectionMatrix * mvPosition;
			}`;

		this.fragmentShader = `precision highp float;
			precision highp int;

			uniform vec3 diffuse;
			uniform float opacity;

			varying vec2 vMapUv;
			uniform sampler2D map;
			uniform float alphaTest;

			void main() {
				float alphaValue = opacity;

				if(vMapUv.x >= 0. && vMapUv.y >= 0.)
					alphaValue *= texture2D( map, vMapUv ).a;

				if ( alphaValue < alphaTest ) discard;
			}`;

		this.colorWrite = false;
	}
}

export class DepthOnlyCharacterMaterial extends RawShaderMaterial {
	constructor() {
		super();

		this.name = "DepthOnlyCharacterMaterial";

		this.glslVersion = "300 es";

		this.skinning = true;

		this.vertexShader = `precision highp float;
			precision highp int;
			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;
			in vec3 position;

			in vec4 skinIndex;
    		in vec4 skinWeight;

			uniform mat4 bindMatrix;
			uniform mat4 bindMatrixInverse;
			uniform highp sampler2D boneTexture;
			mat4 getBoneMatrix( const in float i ) {
				int size = textureSize( boneTexture, 0 ).x;
				int j = int( i ) * 4;
				int x = j % size;
				int y = j / size;
				vec4 v1 = texelFetch( boneTexture, ivec2( x, y ), 0 );
				vec4 v2 = texelFetch( boneTexture, ivec2( x + 1, y ), 0 );
				vec4 v3 = texelFetch( boneTexture, ivec2( x + 2, y ), 0 );
				vec4 v4 = texelFetch( boneTexture, ivec2( x + 3, y ), 0 );
				return mat4( v1, v2, v3, v4 );
			}

			void main() {
				mat4 boneMatX = getBoneMatrix( skinIndex.x );
				mat4 boneMatY = getBoneMatrix( skinIndex.y );
				mat4 boneMatZ = getBoneMatrix( skinIndex.z );
				mat4 boneMatW = getBoneMatrix( skinIndex.w );

				vec3 transformed = vec3( position );

				vec4 skinVertex = bindMatrix * vec4( transformed, 1.0 );
				vec4 skinned = vec4( 0.0 );
				skinned += boneMatX * skinVertex * skinWeight.x;
				skinned += boneMatY * skinVertex * skinWeight.y;
				skinned += boneMatZ * skinVertex * skinWeight.z;
				skinned += boneMatW * skinVertex * skinWeight.w;
				transformed = ( bindMatrixInverse * skinned ).xyz;

				vec4 mvPosition = vec4( transformed, 1.0 );
				mvPosition = modelViewMatrix * mvPosition;
				gl_Position = projectionMatrix * mvPosition;
			}`;
		this.fragmentShader = `void main() {}`;

		this.colorWrite = false;
	}
}

//extension of lambert/gouraud shading, to be able to render the block palette twice (color and texture)
export class JamangoMaterial extends MeshLambertMaterial {
	constructor(o) {
		super();

		this.name = "JamangoMaterial";

		this.ao = false;
		this.shadowSide = DoubleSide;
		this.depthFunc = EqualDepth;
		this.depthWrite = false;

		const { wboitEnabled, ...meshLambertMaterialProps } = o ?? {};

		this.setValues(meshLambertMaterialProps);

		this.wboitEnabled = wboitEnabled ?? false;
	}

	copy(source) {
		super.copy(source);

		this.ao = source.ao;

		this.wboitEnabled = source.wboitEnabled;

		return this;
	}

	onBeforeCompile(shader) {
		if (this.ao) {
			shader.defines ??= {};
			shader.defines.USE_AO = "";
		}

		if (this.wboitEnabled) {
			shader.defines ??= {};
			shader.defines.WBOIT_ENABLED = "";

			shader.uniforms.wboitRenderStage = { value: WboitStages.Normal };
			shader.uniforms.wboitWeight = { value: 1 };
		}

		//---VERTEX---//
		shader.vertexShader =
			`
centroid varying vec2 uvCentroid;
#ifdef USE_AO
	attribute float ao;
	varying float aoVar;
#endif
` + shader.vertexShader;

		shader.vertexShader = shader.vertexShader.replace(
			"#include <uv_vertex>",
			`#include <uv_vertex>
	#ifdef USE_AO
		aoVar = ao;
	#endif
	uvCentroid = uv;
	`,
		);

		//---FRAGMENT---//
		shader.fragmentShader =
			`
#ifdef WBOIT_ENABLED
uniform float wboitRenderStage;
uniform float wboitWeight;
#endif

#ifdef USE_AO
	varying float aoVar;
#endif

centroid varying vec2 uvCentroid;
` + shader.fragmentShader;

		shader.fragmentShader = shader.fragmentShader.replace(
			"#include <map_fragment>",
			`
	#ifdef USE_MAP
	vec4 sampledDiffuseColor = texture2D( map, uvCentroid );
	diffuseColor *= sampledDiffuseColor;
	#endif

	#ifdef USE_AO
		//diffuseColor = vec4(1., 1., 1., 1.); //make everything white for ao testing
		diffuseColor.xyz *= 1. - aoVar;
	#endif
`,
		);

		shader.fragmentShader = shader.fragmentShader.replace(
			/}$/gm,
			`
	#ifdef WBOIT_ENABLED

	if (wboitRenderStage == ${WboitStages.Accumulation.toFixed(1)}) {
		vec4 accum = gl_FragColor.rgba;

		#ifndef PREMULTIPLIED_ALPHA
			accum.rgb *= accum.a;
		#endif

		float a = accum.a;
		float z = gl_FragCoord.z;

		/* Equation #9 */
		// float w = accum.a * clamp( 0.03 / ( 1e-5 + pow( abs( z ) / 200.0, 4.0 ) ), 0.01, 300.0 );

		/* McGuire 10/2013 */
		// float w = clamp( pow( ( accum.a * 8.0 + 0.01 ) * ( - z * 0.95 + 1.0 ), 3.0 ) * 1e3, 1e-2, 3e2 );

		/* Stevinz, Adjustable Weight */
		// float scaleWeight = 0.7 + ( 0.3 * wboitWeight );
		// float w = clamp( pow( ( accum.a * 8.0 + 0.001 ) * ( - z * scaleWeight + 1.0 ), 3.0 ) * 1000.0, 0.001, 300.0 );

		float w = clamp( pow( ( accum.a * 8.0 + 0.01 ) * ( - z * 0.95 + 1.0 ), 3.0 ) * 1e3, 1e-2, 3e2 );

		gl_FragColor = accum * w;
	} else if (wboitRenderStage == ${WboitStages.Revealage.toFixed(1)}) {
		/* McGuire 10/2013 */
		// gl_FragColor = vec4( gl_FragColor.a );

		/* Stevinz, Distance Weighted */
		gl_FragColor = vec4( gl_FragColor.a * gl_FragCoord.z );
	}

	#endif
}
			`,
		);
	}

	customProgramCacheKey() {
		return `ao${Number(this.ao)},wboitEnabled${Number(this.wboitEnabled)}`;
	}
}

export class DebugAOMaterial extends MeshLambertMaterial {
	constructor(o) {
		super();

		this.name = "DebugAOMaterial";

		this.ao = o.ao;
		this.shadowSide = DoubleSide;
	}

	copy(source) {
		super.copy(source);

		this.ao = source.ao;

		this.wboitEnabled = source.wboitEnabled;

		return this;
	}

	onBeforeCompile(shader) {
		if (this.ao) {
			shader.defines ??= {};
			shader.defines.USE_AO = "";
		}

		//---VERTEX---//
		shader.vertexShader =
			`
			#ifdef USE_AO
			attribute float ao;
			varying float aoVar;
			#endif
			` + shader.vertexShader;

		shader.vertexShader = shader.vertexShader.replace(
			"#include <uv_vertex>",
			`#include <uv_vertex>

			#ifdef USE_AO
			aoVar = ao;
			#endif`,
		);

		//---FRAGMENT---//
		shader.fragmentShader = `
			varying float aoVar;

			void main()
			{
				gl_FragColor =
					vec4(1.0 - aoVar, 1.0 - aoVar, 1.0 - aoVar, 1.0);
			}
			`;
	}
}

export class VignetteMaterial extends ShaderMaterial {
	constructor() {
		super({
			name: "VignetteMaterial",
			transparent: true,
			depthWrite: false,
			depthTest: false,

			uniforms: {
				waterColor: { value: new Color() },
				waterOpacity: { value: 1 },
				painColor: { value: new Color() },
				painOpacity: { value: 1 },
				painSoftness: { value: 1 },
				vignetteColor: { value: new Color() },
				vignetteOpacity: { value: 1 },
				vignetteSoftness: { value: 1 },
				vignetteStartRadius: { value: 1 },
			},

			vertexShader: `
varying vec3 pos;

void main()
{
	gl_Position = vec4(position, 1.0);
	pos = position;

}
`,

			//https://apoorvaj.io/alpha-compositing-opengl-blending-and-premultiplied-alpha/
			//the glsl here is compositing the 3 different vignette effects on top of each other
			fragmentShader: `
uniform vec3 waterColor;
uniform float waterOpacity;
uniform vec3 painColor;
uniform float painOpacity;
uniform float painSoftness;
uniform vec3 vignetteColor;
uniform float vignetteOpacity;
uniform float vignetteSoftness;
uniform float vignetteStartRadius;

varying vec3 pos;

vec4 blend(vec4 background, vec4 foreground)
{
	float finalAlpha = foreground.a + background.a * (1. - foreground.a);
	return clamp(vec4((foreground.rgb * foreground.a + background.rgb * background.a * (1. - foreground.a)) / finalAlpha, finalAlpha), 0., 1.);
}

void main()
{
	float painRoundedOpacity     = clamp((length(pos) + (painSoftness - 1.)) *     painOpacity /     painSoftness, 0., 1.);
	float vignetteRoundedOpacity = clamp((length(pos) - vignetteStartRadius) * vignetteOpacity / vignetteSoftness, 0., 1.);

	gl_FragColor = blend(blend(
			vec4(waterColor, waterOpacity),
			vec4(painColor, painRoundedOpacity)),
			vec4(vignetteColor, vignetteRoundedOpacity));
}
`,
		});
	}
}

export class UIMeshBasicMaterial extends MeshBasicMaterial {
	constructor(o) {
		super(o);

		this.name = "UIMeshBasicMaterial";
	}

	onBeforeCompile(shader) {
		shader.fragmentShader = shader.fragmentShader.replace(
			`#include <colorspace_fragment>`,
			`gl_FragColor = sRGBTransferOETF( gl_FragColor );`,
		);
	}
}

export class UISpriteMaterial extends SpriteMaterial {
	constructor(o) {
		super(o);

		this.name = "UISpriteMaterial";
	}

	onBeforeCompile(shader) {
		shader.fragmentShader = shader.fragmentShader.replace(
			`#include <colorspace_fragment>`,
			`gl_FragColor = sRGBTransferOETF( gl_FragColor );`,
		);
	}
}
