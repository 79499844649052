import type { IBlockOptions } from "../types";
import { BlockCollision, BlockTransparency } from "../types";

export const MIN_DISPLAY_NAME = 1;
export const MAX_DISPLAY_NAME = 50;
export const MIN_DESCRIPTION = 0;
export const MAX_DESCRIPTION = 50;
export const MIN_FRICTION = -1;
export const MAX_FRICTION = 1;

export const DEFAULT_COLOR = "#808080";

export const DEFAULT_BLOCK_OPTIONS = {
	friction: 1,
	collision: BlockCollision.ENABLED,
	transparency: BlockTransparency.OPAQUE,
} satisfies IBlockOptions;
