import { Vector3 } from "three";
import type { Input } from "@jamango/engine/Input.ts";
import type { Character } from "base/world/entity/Character";
import { CharacterMovementMode } from "base/world/entity/component/CharacterMovement";

const _shapeHitPosition = new Vector3();
const _groundNormal = new Vector3();

export function characterSurfaceMovementUpdate(entity: Character, stepDeltaTime: number, _input: Input) {
	if (!canUpdateSystem(entity)) return;

	updateFriction(entity);
	updateSliding(entity, stepDeltaTime);
}

function updateFriction(character: Character) {
	if (character.collision.state.footBlock.found) {
		const blockPosition = character.collision.state.footBlock.pos;

		const blockType = character.world.scene.getBlockType(blockPosition);

		character.movement.state.friction = blockType.friction;
	} else {
		character.movement.state.friction = 1;
	}

	character.movement.state.isSlipperySurface = character.movement.state.friction < 0.5;
}

function updateSliding(character: Character, stepDeltaTime: number) {
	// find the ground hit closest to the character's foot
	const contacts = character.characterPhysics.state.activeContacts;

	let primaryGroundHit = null;
	let primaryGroundHitFootDistance = Infinity;

	for (let i = 0; i < contacts.length; i++) {
		const { position, normal } = contacts[i];

		if (normal.y < 0.3) {
			continue;
		}

		const footDistance = _shapeHitPosition.copy(position).distanceTo(character.position);

		if (footDistance < primaryGroundHitFootDistance && footDistance < character.size.state.height / 2) {
			primaryGroundHit = contacts[i];
			primaryGroundHitFootDistance = footDistance;
		}
	}

	let startSliding = false;

	if (primaryGroundHit) {
		const normal = _groundNormal.copy(primaryGroundHit.normal);
		const onSlope = Math.abs(normal.x) > 0.3 || Math.abs(normal.z) > 0.3;

		startSliding = onSlope && character.movement.state.isSlipperySurface;

		if (startSliding) {
			character.movement.state.slidingDirection.set(0, -1, 0).projectOnPlane(normal).normalize();
		}
	}

	if (startSliding) {
		character.movement.state.isSliding = true;
		character.movement.state.slidingGraceTimer = 0;
	} else {
		character.movement.state.slidingGraceTimer += stepDeltaTime;

		if (character.movement.state.slidingGraceTimer > character.movement.def.slidingGraceTime) {
			character.movement.state.isSliding = false;
			character.movement.state.slidingGraceTimer = 0;
		}
	}

	if (!character.movement.state.isSliding) return;

	character.movement.state.velocity.addScaledVector(
		character.movement.state.slidingDirection,
		-character.world.physics.gravity.y * stepDeltaTime,
	);
}

function canUpdateSystem(entity: Character) {
	return (
		entity.type.def.isCharacter &&
		!entity.mount.state.parent &&
		!entity.movement.state.isFlying &&
		entity.movement.def.mode !== CharacterMovementMode.BINARY
	);
}
