export function debounce<TArgs extends any[], T extends (...args: TArgs) => any>(func: T, delay: number) {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (...args: TArgs) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
}

export async function delay(ms: number) {
	return await new Promise<void>((resolve) => setTimeout(resolve, ms));
}
