import { isNullish } from "@jamango/helpers";
import * as Physics from "base/world/Physics";
import { UI } from "client/dom/UI";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

const tmpVec = new Vector3();

/**
 * Updates an entity's nameplate.
 * @param {import("../Entity").Entity} entity
 */
export function nameplateUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const nameplate = entity.nameplate.def.nameplate;

	if (entity.canSkipUpdate) {
		nameplate.visible = false;
		return;
	}

	// update nameplate position
	const object3D = entity.mount.state.parent ? entity.mount.state.parent.object3D : entity.object3D;
	nameplate.position.copy(object3D.position);

	// adjust nameplate position for characters
	if (entity.type.def.isCharacter && !isNullish(entity.size)) {
		let nameplateHeight = entity.size.state.nameplateHeight;

		if (!isNullish(entity.movement) && entity.movement.state.isCrouching) {
			nameplateHeight -= entity.size.state.crouchOffset;
		}

		nameplate.position.y += nameplateHeight;
	}

	// don't render nameplate if it's hiding behind something from camera's perspective
	const camera = entity.world.client.camera;

	if (
		!entity.nameplate.state.show ||
		UI.state.gameHud().zenMode ||
		nameplate.isEmpty() || //don't render empty string
		(camera.target === entity && camera.is1stPerson()) || //don't render camera target nameplate in 1st person
		(camera.target.type.def.isCharacter &&
			camera.target.disabledInteractions.state.cantAttack.has(entity.entityID)) //don't render if attack interaction privilege is disabled
	) {
		nameplate.visible = false;
	} else {
		const pos = camera.position;
		let dir = nameplate.getWorldPosition(tmpVec).sub(pos);
		let len = dir.length();

		if (len > camera.far) {
			nameplate.visible = false;
		} else {
			dir = dir.normalize();
			len += 1;
			nameplate.visible = nameplateRaycast(entity, pos, dir, len, camera.target);
		}
	}

	if (nameplate.visible && !isNullish(entity.health)) {
		if (entity.health.def.invincible) nameplate.removeHealthBar();
		else nameplate.setHealthBar(entity.health.state.current / entity.health.def.max);
	}
}

/**
 * Whether the Nameplate system should update for the given entity.
 *
 * @param {import("base/world/entity/Entity").Entity} entity
 * @returns {entity is import("base/world/entity/types").EntityWithComponents<'nameplate'>}
 */
function canUpdateSystem(entity) {
	return netState.isClient && !isNullish(entity.nameplate);
}

const _raycastResult = Physics.initRaycastResult();

/**
 * Raycasts from the client camera to determine if the entity's nameplate should
 * be visible.
 *
 * If the raycast hits something, the nameplate is hidden. Otherwise, it is
 * shown.
 *
 * @param {import("base/world/entity/types").EntityWithComponents<'nameplate'>} entity - The entity to
 * update.
 */
function nameplateRaycast(entity, pos, dir, len, ignoreEntity) {
	const result = Physics.raycastAnyCollidable(
		entity.world.physics,
		pos,
		dir,
		len,
		[ignoreEntity],
		_raycastResult,
	);

	return !result.hit;
}
