import type { z } from "zod";
import type { EnvironmentPresetSchema } from "../validations/environment-preset";

type EnvPreset = z.infer<typeof EnvironmentPresetSchema>["preset"];

export type IEnvironmentPreset = z.infer<typeof EnvironmentPresetSchema>;

export type IEnvironmentPresetSettings = EnvPreset["general"] &
	EnvPreset["fog"] &
	EnvPreset["sky"] &
	EnvPreset["light"];

export const TONE_MAPPING = {
	NONE: "NoToneMapping",
	LINEAR: "LinearToneMapping",
	REINHARD: "ReinhardToneMapping",
	CINEON: "CineonToneMapping",
	ACES: "ACESFilmicToneMapping",
	CUSTOM: "CustomToneMapping",
} as const;

export type ToneMapping = (typeof TONE_MAPPING)[keyof typeof TONE_MAPPING];
