import { v4 as uuid } from "uuid";
import type { AssetType, IAssetKey } from "../types";

const SHORT_ID_LENGTH = 5;
const HASH_LENGTH = 8;

export function generateUUID() {
	return uuid();
}

export function generateId() {
	return uuid().replace(/-/gi, "").toUpperCase();
}

export function generatePk(assetType: AssetType) {
	return `${assetType}#${generateId()}`;
}

export function generateShortId() {
	return generateId().substring(0, SHORT_ID_LENGTH);
}

export function getHash(uuid?: string) {
	if (!uuid) return null;
	return uuid.substring(0, HASH_LENGTH);
}

export function getIdentifier(pk: IAssetKey) {
	const [_assetType, id] = pk.split("#");
	return id;
}

export function getAssetType(pk: IAssetKey) {
	const [assetType, _id] = pk.split("#");
	return assetType as AssetType;
}
