import { getPeerMetadata } from "base/util/PeerMetadata.ts";
import { Intersector } from "base/util/math/Intersector.js";
import { V1 } from "base/util/math/Math.ts";
import type { World } from "base/world/World";
import {
	BLOCK_AIR,
	BLOCK_BL,
	BLOCK_BR,
	BLOCK_CUBE,
	BLOCK_MASK_CORNERS,
	BLOCK_MASK_INVERT,
	BLOCK_MASK_SIDE,
	BLOCK_TL,
	BLOCK_TR,
	ChunkUtil,
} from "base/world/block/Util.js";
import type { Character } from "base/world/entity/Character";
import { CharacterSculptMode } from "base/world/entity/component/CharacterSelector";
import { UI } from "client/dom/UI";
import * as BlockVfxClient from "client/world/fx/BlockVfx";
import { netState } from "router/Parallelogram";
import { Box3, Vector3 } from "three";

const _box = new Box3();
const _blockHitPos = new Vector3();

const _hitBlock = new Vector3();

const rayIntersectsBlock = (hitBlock: Vector3, rayPos: Vector3, rayDir: Vector3, out: Vector3) => {
	_box.max.copy(_box.min.copy(hitBlock)).add(V1);
	return Intersector.intersectBoxRay(_box, rayPos, rayDir, out);
};

const entityIntersectsBlock = (world: World, hitBlock: Vector3) => {
	_box.max.copy(_box.min.copy(hitBlock)).add(V1);
	return Intersector.intersectBoxEntity(_box, world.entities);
};

// TODO: this was lifted from Selector.js as-is, it's currently a bit of a brain breaker.
// We can do a readability pass on this!

export const onBlockItemUse = (
	world: World,
	character: Character,
	blockType: string,
	primary: boolean,
	secondary: boolean,
) => {
	if (!getPeerMetadata(world.client.loopbackPeer).permissions.canUseIndividualBlocks) return;

	const scene = world.scene;
	const viewRaycastState = character.viewRaycast.state;

	if (!viewRaycastState.block) return;

	const sculptMode = character.selector.state.sculptMode;
	const rayPos = viewRaycastState.rayPos;
	const rayDir = viewRaycastState.rayDir;
	const hitBlock = _hitBlock.copy(viewRaycastState.block);
	const blockHitPos = viewRaycastState.blockHitPos!;
	const blockShape = scene.getShape(hitBlock);
	let hitShape = viewRaycastState.blockHitShape;
	let hitSide = viewRaycastState.blockHitSide;
	const hitType = scene.getType(hitBlock);

	// break the block
	if (primary) {
		// can't break or sculpt air!
		if (blockShape === BLOCK_AIR) return;

		if (sculptMode === CharacterSculptMode.CUBE) {
			scene.setBlock(hitBlock, BLOCK_AIR, hitType, true, character);
			UI.state.helpers().markBuildKnown();
		} else {
			if (sculptMode === CharacterSculptMode.INVERT && (hitShape & BLOCK_MASK_CORNERS) === BLOCK_CUBE) {
				hitShape |= BLOCK_MASK_INVERT;
			}

			const hitPosUnrot = ChunkUtil.unrotateVec(hitSide, _blockHitPos.copy(blockHitPos), true);

			if (hitPosUnrot.x <= 0.5 && hitPosUnrot.z <= 0.5) {
				hitShape &= ~BLOCK_TL;
			} else if (hitPosUnrot.x <= 0.5 && hitPosUnrot.z > 0.5) {
				hitShape &= ~BLOCK_BL;
			} else if (hitPosUnrot.x > 0.5 && hitPosUnrot.z <= 0.5) {
				hitShape &= ~BLOCK_TR;
			} else if (hitPosUnrot.x > 0.5 && hitPosUnrot.z > 0.5) {
				hitShape &= ~BLOCK_BR;
			}

			if ((hitShape & BLOCK_MASK_CORNERS) === BLOCK_AIR) {
				hitShape = BLOCK_AIR;
			}

			scene.setBlock(hitBlock, hitShape, hitType, true, character);
			UI.state.helpers().markBuildKnown();
		}

		if (netState.isClient) {
			BlockVfxClient.onBreakBlock(world, hitType, hitBlock.x, hitBlock.y, hitBlock.z);
		}
	}
	// build block (use else if here only to prevent build+break in the same frame)
	else if (secondary) {
		if (sculptMode === CharacterSculptMode.CUBE) {
			if (blockShape !== BLOCK_AIR) {
				if ((hitShape & BLOCK_MASK_CORNERS) !== BLOCK_CUBE) {
					rayIntersectsBlock(hitBlock, rayPos, rayDir, blockHitPos);
					blockHitPos.sub(hitBlock);
					hitSide = ChunkUtil.getHitSide(blockHitPos);
				}

				ChunkUtil.unrotateVec(hitSide, hitBlock, true).y++;
				ChunkUtil.rotateVec(hitSide, hitBlock, true);
			}

			if (scene.getShape(hitBlock) === BLOCK_AIR && !entityIntersectsBlock(world, hitBlock)) {
				scene.setBlock(hitBlock, BLOCK_CUBE, blockType, true, character);
				UI.state.helpers().markBuildKnown();

				if (netState.isClient) {
					BlockVfxClient.onBuildBlock(world, hitType, hitBlock.x, hitBlock.y, hitBlock.z);
				}
			}
		} else {
			let blocked = false;
			const hitPosUnrot = ChunkUtil.unrotateVec(hitSide, _blockHitPos.copy(blockHitPos), true);

			if (
				(hitPosUnrot.x <= 0.5 && hitPosUnrot.z <= 0.5 && (hitShape & BLOCK_TL) === BLOCK_TL) ||
				(hitPosUnrot.x <= 0.5 && hitPosUnrot.z > 0.5 && (hitShape & BLOCK_BL) === BLOCK_BL) ||
				(hitPosUnrot.x > 0.5 && hitPosUnrot.z <= 0.5 && (hitShape & BLOCK_TR) === BLOCK_TR) ||
				(hitPosUnrot.x > 0.5 && hitPosUnrot.z > 0.5 && (hitShape & BLOCK_BR) === BLOCK_BR)
			) {
				//can't sculpt this block anymore; sculpt its neighbor instead
				if ((hitShape & BLOCK_MASK_CORNERS) !== BLOCK_CUBE) {
					rayIntersectsBlock(hitBlock, rayPos, rayDir, blockHitPos);
					blockHitPos.sub(hitBlock);
					hitSide = ChunkUtil.getHitSide(blockHitPos);
				}

				ChunkUtil.unrotateVec(hitSide, hitBlock, true).y++;
				ChunkUtil.rotateVec(hitSide, hitBlock, true);

				let offsetHitShape = scene.getShape(hitBlock);
				if (offsetHitShape === BLOCK_AIR) {
					offsetHitShape = hitSide;
				}

				if ((offsetHitShape & BLOCK_MASK_SIDE) === hitSide) {
					hitShape = offsetHitShape;
					ChunkUtil.unrotateVec(hitSide, hitPosUnrot.copy(blockHitPos), true);
				} else {
					blocked = true; //can't build here
				}
			}

			if (!blocked && !entityIntersectsBlock(world, hitBlock)) {
				const beforeShape = hitShape & BLOCK_MASK_CORNERS;
				if (sculptMode === CharacterSculptMode.INVERT && beforeShape === BLOCK_AIR) {
					hitShape |= BLOCK_MASK_INVERT;
				}

				if (hitPosUnrot.x <= 0.5 && hitPosUnrot.z <= 0.5) {
					hitShape |= BLOCK_TL;
				} else if (hitPosUnrot.x <= 0.5 && hitPosUnrot.z > 0.5) {
					hitShape |= BLOCK_BL;
				} else if (hitPosUnrot.x > 0.5 && hitPosUnrot.z <= 0.5) {
					hitShape |= BLOCK_TR;
				} else if (hitPosUnrot.x > 0.5 && hitPosUnrot.z > 0.5) {
					hitShape |= BLOCK_BR;
				}

				if ((hitShape & BLOCK_MASK_CORNERS) === BLOCK_CUBE) {
					hitShape = BLOCK_CUBE;
				}

				const buildType = beforeShape === BLOCK_AIR ? blockType : scene.getType(hitBlock);

				scene.setBlock(hitBlock, hitShape, buildType, true, character);
				UI.state.helpers().markBuildKnown();

				if (netState.isClient) {
					BlockVfxClient.onBuildBlock(world, hitType, hitBlock.x, hitBlock.y, hitBlock.z);
				}
			}
		}
	}
};
