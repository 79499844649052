import type { IBlockStructure } from "@jamango/content-client";
import {
	AssetType,
	BlockStructureColliderType,
	generateUUID,
	getAssetType,
	PropColliderType,
	PropMeshType,
	PropMotionType,
} from "@jamango/content-client";
import { Vector3Set } from "base/util/math/VectorStorage";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as BlockGroups from "base/world/block/BlockGroups";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import { BLOCK_AIR, BLOCK_SPY, ShapeUtil } from "base/world/block/Util";
import type { Character } from "base/world/entity/Character";
import {
	addBrushToSelection,
	BulkSelectionBlocksBrushState,
	nudgeBulkSelection,
	removePositionFromSelection,
	resetBulkSelection,
	resetBulkSelectionBrush,
	setBulkSelectionBrush,
} from "base/world/entity/component/CharacterSelector";
import { CharacterViewRaycastMode } from "base/world/entity/component/CharacterViewRaycast";
import type { Entity } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";
import * as SceneTree from "base/world/SceneTree";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import { ItemPencil } from "mods/defs/ItemPencil";
import * as SpawnerRouter from "router/world/tools/Spawner";
import { Group } from "three";
import { Quaternion } from "three";
import { Box3, Euler, MathUtils, Matrix4, Vector3 } from "three";
import { createBlockStructureMesh } from "base/world/block/BlockStructureMesh";

const _block = new Vector3();
const _blockPosition = new Vector3();

export enum PencilMode {
	START,
	ONE_CORNER_SELECTED,
	TWO_CORNERS_SELECTED,
	MANIPULATING_ENTITIES,
	PASTE_PREVIEW,
}

type Clipboard = {
	blocks: {
		corners: {
			cornerOne: Vector3;
			cornerTwo: Vector3;
		};
		firstCornerSelectedRelative: Vector3;
		blockStructure: ReturnType<typeof getSelectorBlocks>;
	};
};

type ClipboardPastePreview = {
	detached: boolean;
	pastePreview: ReturnType<typeof createBlockStructureMesh>;
	nudgeOffset: Vector3;
	pastePreviewBox: Box3;
	offsetPosition: Vector3;
	pastePreviewPosition: Vector3;
};

type UndoItemCreateGroup = {
	id: string;
	group: { name: string | null; scripts: BlockGroups.BlockGroupScriptAttachment[] | undefined };
};

type UndoItemBlock = {
	dst: Vector3;
	oldShape: number;
	oldType: string;
	newShape: number;
	newType: string;
};

type UndoItemBlockGroup = {
	dst: Vector3;
	oldGroups: string[] | null;
	newGroups: string[] | null;
};

type UndoItem = {
	actionName: string;
	blocks: UndoItemBlock[];
	blockGroups: UndoItemBlockGroup[];
	blockGroupDefs: UndoItemCreateGroup[];
};

type ManipulatingState = {
	entityId: number;
	distance: number;
	hitOffsetLocal: Vector3;
	playerStartQuaternion: Quaternion;
	entityStartQuaternion: Quaternion;
};

export type CopyBlockGroupsOption = false | "reference" | "clone";

export const init = () => {
	const undoHistory = [] as Array<UndoItem>;
	const redoHistory = [] as Array<UndoItem>;

	return {
		pencilMode: PencilMode.START,
		firstCornerSelected: null as Vector3 | null,
		cornerOne: null as Vector3 | null,
		cornerTwo: null as Vector3 | null,
		undoHistory,
		redoHistory,
		clipboard: null as Clipboard | null,
		clipboardPastePreview: null as ClipboardPastePreview | null,
		pencilUseActive: false,
		pencilUseTime: 0,
		pencilUsePrimary: false,
		pencilUseSecondary: false,
		manipulatingState: null as null | ManipulatingState,
		viewRaycastModeChangedDuringPaste: false,
		viewRaycastModeBeforePaste: null as CharacterViewRaycastMode | null,
	};
};

export type PencilState = ReturnType<typeof init>;

export const onItemChange = (world: World, prvItem: Item, currentItem: Item) => {
	const state = world.client.pencil;

	// possible but very unlikely that both prvItem and item are both blogans
	// more likely one of them will be null and we attempt to reset both either way
	if (ItemPencil.isPencil(prvItem?.def)) {
		resetPencil(state, world);
	}

	if (ItemPencil.isPencil(currentItem?.def)) {
		resetPencil(state, world);

		if (ItemPencil.isBlockStructure(currentItem?.def)) {
			pasteBlockStructureWithPreview(state, world);
		}
	}
};

export const onItemUse = (world: World, primary: boolean, secondary: boolean) => {
	if (!getPeerMetadata(world.client.loopbackPeer).permissions.canUsePencil) return;

	const pencilState = world.client.pencil;

	pencilState.pencilUseActive = true;
	pencilState.pencilUsePrimary = primary;
	pencilState.pencilUseSecondary = secondary;
	pencilState.pencilUseTime = world.time;

	const mode = pencilState.pencilMode;

	if (mode === PencilMode.START) {
		if (primary) {
			startSelection(pencilState, world);
		} else if (secondary) {
			showSelectionOptions(pencilState, world);
		}
	} else if (mode === PencilMode.ONE_CORNER_SELECTED) {
		if (primary) {
			selectSecondCorner(pencilState, world);
		} else if (secondary) {
			cancelSecondCorner(pencilState, world);
		}
	} else if (mode === PencilMode.TWO_CORNERS_SELECTED) {
		if (primary) {
			addToSelection(pencilState, world);
		} else if (secondary) {
			showSelectionOptions(pencilState, world);
		}
	} else if (mode === PencilMode.PASTE_PREVIEW) {
		if (primary) {
			cancelPastePreview(pencilState, world);
		} else if (secondary) {
			confirmPastePreview(pencilState, world);
		}
	}
};

export const onItemUnuse = (world: World) => {
	const state = world.client.pencil;
	const mode = state.pencilMode;

	if (mode === PencilMode.MANIPULATING_ENTITIES) {
		stopManipulatingEntity(state, world);
	}

	// reset
	state.pencilUseActive = false;
	state.pencilUsePrimary = false;
	state.pencilUseSecondary = false;
	state.pencilUseTime = 0;
};

export const update = (state: PencilState, world: World) => {
	const equippedItem = world.client.getEquippedItem();

	const isBlockStructure = ItemPencil.isBlockStructure(equippedItem?.def);
	const isPencil = ItemPencil.isPencil(equippedItem?.def);

	if (!isPencil && !isBlockStructure) {
		return;
	}

	const camera = world.client.camera;
	const selector = camera.target.selector;
	const viewRaycast = camera.target.viewRaycast;
	if (!selector || !viewRaycast) return;

	const mode = state.pencilMode;

	const firstPerson = camera.is1stPerson();
	if (!firstPerson && mode !== PencilMode.START) {
		resetPencil(state, world);
		return;
	}

	if (isBlockStructure && mode !== PencilMode.PASTE_PREVIEW) {
		pasteBlockStructureWithPreview(state, world);
		return;
	}

	if (mode === PencilMode.PASTE_PREVIEW) {
		updatePastePreviewPosition(state, world);
		return;
	}

	if (mode === PencilMode.MANIPULATING_ENTITIES) {
		resetBulkSelectionBrush(selector);
		return;
	}

	if (mode === PencilMode.START) {
		if (!viewRaycast.state.block) {
			resetBulkSelectionBrush(selector);
		} else {
			setBulkSelectionBrush(
				selector,
				viewRaycast.state.block!,
				viewRaycast.state.block!,
				BulkSelectionBlocksBrushState.SELECTING_FIRST_CORNER,
			);
		}

		return;
	}

	if (!viewRaycast.state.block) {
		return;
	}

	if (mode === PencilMode.ONE_CORNER_SELECTED) {
		setBulkSelectionBrush(
			selector,
			state.cornerOne!,
			viewRaycast.state.block,
			BulkSelectionBlocksBrushState.SELECTING_SECOND_CORNER,
		);
	} else if (mode === PencilMode.TWO_CORNERS_SELECTED) {
		setBulkSelectionBrush(
			selector,
			viewRaycast.state.block,
			viewRaycast.state.block,
			BulkSelectionBlocksBrushState.SELECTING_FIRST_CORNER,
		);
	}
};

export const displayNotification = (world: World, message: string) => {
	world.hudPopup.router.setLocal({
		position: "bottom",
		message,
	});
};

export const resetPencil = (state: PencilState, world: World) => {
	state.cornerOne = null;
	state.cornerTwo = null;

	setPencilMode(state, world, PencilMode.START);

	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;

	if (selector) {
		resetBulkSelection(selector);
		resetBulkSelectionBrush(selector);
	}

	state.firstCornerSelected = null;

	removePastePreview(state, world);

	state.pencilUseActive = false;
	state.pencilUsePrimary = false;
	state.pencilUseSecondary = false;
	state.pencilUseTime = 0;

	state.manipulatingState = null;
};

const startManipulatingEntity = (state: PencilState, world: World, character: Character, entity: Entity) => {
	const camera = world.client.camera;

	const hitOffsetLocal = character.viewRaycast.state.hitPos.clone();
	hitOffsetLocal.sub(entity.position);
	hitOffsetLocal.applyQuaternion(entity.quaternion.clone().invert());

	const manipulatingState: ManipulatingState = {
		entityId: entity.entityID,
		hitOffsetLocal,
		playerStartQuaternion: world.client.camera.target.quaternion.clone(),
		entityStartQuaternion: entity.quaternion.clone(),
		distance: camera.position.distanceTo(character.viewRaycast.state.hitPos),
	};

	state.manipulatingState = manipulatingState;

	setPencilMode(state, world, PencilMode.MANIPULATING_ENTITIES);
};

const stopManipulatingEntity = (state: PencilState, world: World) => {
	if (!state.manipulatingState) return;

	resetPencil(state, world);
};

export const startSelection = (state: PencilState, world: World) => {
	const cameraTarget = world.client.camera.target;
	const character = cameraTarget?.type.def.isCharacter ? cameraTarget : null;
	const viewRaycastState = character?.viewRaycast?.state;

	const viewRaycastEntity = viewRaycastState?.prop ?? viewRaycastState?.character ?? viewRaycastState?.item;

	if (viewRaycastEntity) {
		startManipulatingEntity(state, world, character as Character, viewRaycastEntity);
	} else {
		selectFirstCorner(state, world);
	}
};

const selectFirstCorner = (state: PencilState, world: World) => {
	const mode = state.pencilMode;
	if (mode !== PencilMode.START) return;

	const cameraTarget = world.client.camera.target;
	const viewRaycast = cameraTarget?.viewRaycast;

	if (!viewRaycast?.state.block) return;

	// select first corner
	state.cornerOne = viewRaycast.state.block.clone();
	state.firstCornerSelected = state.cornerOne.clone();
	setPencilMode(state, world, PencilMode.ONE_CORNER_SELECTED);

	world.sfxManager.play({
		asset: "snd-ui-basic-selection-start",
		volume: 5,
		loop: false,
	});
};

export const selectSecondCorner = (state: PencilState, world: World) => {
	const mode = state.pencilMode;
	if (mode !== PencilMode.ONE_CORNER_SELECTED) return;

	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	const viewRaycast = cameraTarget?.viewRaycast;
	if (!selector || !viewRaycast?.state.block) return;

	// select second corner
	state.cornerTwo = viewRaycast.state.block.clone();
	setPencilMode(state, world, PencilMode.TWO_CORNERS_SELECTED);

	setBulkSelectionBrush(
		selector,
		state.cornerOne!,
		state.cornerTwo!,
		BulkSelectionBlocksBrushState.SELECTING_SECOND_CORNER,
	);
	addBrushToSelection(selector);
	resetBulkSelectionBrush(selector);

	world.sfxManager.play({
		asset: "snd-ui-basic-selection-end",
		volume: 5,
		loop: false,
	});
};

export const cancelSecondCorner = (state: PencilState, world: World) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	if (!selector) return;

	if (selector.state.bulkSelectionBlocks.size() <= 0) {
		resetPencil(state, world);
	} else {
		setPencilMode(state, world, PencilMode.TWO_CORNERS_SELECTED);
	}
};

export const addToSelection = (state: PencilState, world: World) => {
	const mode = state.pencilMode;
	if (mode !== PencilMode.TWO_CORNERS_SELECTED) return;

	const cameraTarget = world.client.camera.target;
	const viewRaycast = cameraTarget?.viewRaycast;
	if (!viewRaycast?.state.block) return;

	// select another corner
	state.cornerOne = viewRaycast.state.block.clone();
	setPencilMode(state, world, PencilMode.ONE_CORNER_SELECTED);
};

const modifyScene = (
	state: PencilState,
	world: World,
	actionName: string,
	fn: (
		setBlock: (position: Vector3, shape: number, type: string, triggerEvents: boolean) => void,
		createBlockGroup: (
			id: string,
			name: string | null,
			scripts: BlockGroups.BlockGroupScriptAttachment[] | undefined,
		) => void,
		setBlockGroupsAtPosition: (position: Vector3, blockGroups: string[] | null) => void,
	) => void,
) => {
	const scene = world.scene;

	const undoAction: UndoItem = {
		actionName,
		blocks: [],
		blockGroups: [],
		blockGroupDefs: [],
	};

	const createBlockGroup = (
		id: string,
		name: string | null,
		scripts: BlockGroups.BlockGroupScriptAttachment[] | undefined,
	) => {
		BlockGroupsRouter.create({
			id,
			name,
			scripts,
		});

		undoAction.blockGroupDefs.push({
			id,
			group: { name, scripts}
		})
	};

	const setBlockGroupsAtPosition = (position: Vector3, blockGroups: string[] | null) => {
		const oldBlockGroups = BlockGroups.getIdsAtPosition(
			world.blockGroups,
			position.x,
			position.y,
			position.z,
		);

		BlockGroupsRouter.setBlockGroupsAtPosition({
			ids: blockGroups,
			x: position.x,
			y: position.y,
			z: position.z,
		});

		undoAction.blockGroups.push({
			dst: position.clone(),
			oldGroups: oldBlockGroups,
			newGroups: blockGroups,
		});
	};

	const setBlock = (position: Vector3, shape: number, type: string, triggerEvents: boolean) => {
		const oldShape = scene.getShape(position.x, position.y, position.z);
		const oldType = scene.getType(position.x, position.y, position.z);

		scene.setBlock(position, shape, type, triggerEvents);

		undoAction.blocks.push({
			dst: position.clone(),
			oldShape,
			oldType,
			newShape: shape,
			newType: type,
		});
	};

	fn(setBlock, createBlockGroup, setBlockGroupsAtPosition);

	state.undoHistory.push(undoAction);
	state.redoHistory.length = 0;
};

export const getSelectorBlocks = (
	world: World,
	copyAir = false,
	copyBlockGroups: CopyBlockGroupsOption = false,
) => {
	const box = new Box3();

	const selectorBlocks = getSelectorPositions(world);

	for (const block of selectorBlocks.iterate(_block)) {
		box.expandByPoint(block);
	}

	const blocks: Array<[number, number, number, number, string]> = [];
	const blockGroups: NonNullable<IBlockStructure["data"]["blockGroups"]> = [];
	const blockGroupDefs = {} as NonNullable<IBlockStructure["data"]["blockGroupDefs"]>;
	const existingToClonedBlockGroupId = {} as Record<string, string>;

	for (const block of selectorBlocks.iterate(_block)) {
		const worldPosition = new Vector3().copy(block);

		const shape = world.scene.getShape(block.x, block.y, block.z);
		if (shape === BLOCK_AIR && !copyAir) continue;

		const type = world.scene.getType(block.x, block.y, block.z);

		const x = worldPosition.x - box.min.x;
		const y = worldPosition.y - box.min.y;
		const z = worldPosition.z - box.min.z;

		blocks.push([x, y, z, shape, type] as const);

		if (copyBlockGroups !== false) {
			const blockGroupsAtPosition = BlockGroups.getIdsAtPosition(
				world.blockGroups,
				block.x,
				block.y,
				block.z,
			);

			let blockGroupIdCounter = 0;

			if (blockGroupsAtPosition !== null) {
				if (copyBlockGroups === "reference") {
					blockGroups.push([x, y, z, blockGroupsAtPosition] as const);
				} else if (copyBlockGroups === "clone") {
					const newIds: string[] = [];
					for (const blockGroup of blockGroupsAtPosition) {
						if (existingToClonedBlockGroupId[blockGroup] === undefined) {
							const newId = String(blockGroupIdCounter++);

							existingToClonedBlockGroupId[blockGroup] = newId;

							const group = world.blockGroups.groups.get(blockGroup);

							blockGroupDefs[newId] = {
								name: group?.name ?? undefined,
								scripts: structuredClone(group?.scripts),
							};
						}

						newIds.push(existingToClonedBlockGroupId[blockGroup]);
					}

					blockGroups.push([x, y, z, newIds] as const);
				}
			}
		}
	}

	const blockStructure = world.content.state.blockStructures.createNewBlockStructure(
		world.content.state.blockStructures.generateNewName(),
		blocks,
		blockGroups,
		blockGroupDefs,
	);

	return blockStructure;
};

const _emptyVector3Set = new Vector3Set();

export const getSelectorPositions = (world: World) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	const viewRaycast = cameraTarget?.viewRaycast;

	if (!selector) return _emptyVector3Set;

	if (selector.state.bulkSelectionBlocks.size() === 0) {
		if (!viewRaycast?.state.block) return _emptyVector3Set;

		const set = new Vector3Set();
		set.add(viewRaycast.state.block);
		return set;
	}

	return selector.state.bulkSelectionBlocks;
};

export const solidFillSelection = (
	state: PencilState,
	world: World,
	shape: number,
	type: string,
	triggerEvents = false,
) => {
	const blocks = getSelectorPositions(world);

	modifyScene(state, world, "solid fill", (setBlock) => {
		for (const block of blocks.iterate(_block)) {
			setBlock(block, shape, type, triggerEvents);
		}
	});

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-fill",
		volume: 5,
		loop: false,
	});

	displayNotification(world, `Solid filled ${blocks.size()} blocks!`);

	resetPencil(state, world);
};

const NEIGHBOUR_DIRECTIONS = [
	new Vector3(1, 0, 0),
	new Vector3(-1, 0, 0),
	new Vector3(0, 1, 0),
	new Vector3(0, -1, 0),
	new Vector3(0, 0, 1),
	new Vector3(0, 0, -1),
];

export const getSelectedSlotPKIfBlockSelected = (world: World) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	if (!selector) return null;

	const selectedSlotPK = selector.state.selectedSlot?.pk;
	if (!selectedSlotPK) return null;

	const selectedSlotType = getAssetType(selectedSlotPK);

	if (selectedSlotType === AssetType.BLOCK) return selectedSlotPK;

	return null;
};

export const hollowFillSelection = (
	state: PencilState,
	world: World,
	shape: number,
	type: string,
	triggerEvents = false,
) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	if (!selector) return;

	const boundaryBlocks = new Vector3Set();

	const blocks = getSelectorPositions(world);

	for (const block of blocks.iterate(_block)) {
		for (const direction of NEIGHBOUR_DIRECTIONS) {
			const neighbor = block.clone().add(direction);
			if (!selector.state.bulkSelectionBlocks.has(neighbor)) {
				boundaryBlocks.add(block);
				break;
			}
		}
	}

	modifyScene(state, world, "hollow fill", (setBlock) => {
		for (const block of boundaryBlocks.iterate(_blockPosition)) {
			setBlock(block, shape, type, triggerEvents);
		}
	});

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-fill",
		volume: 5,
		loop: false,
	});

	displayNotification(world, `Hollow filled ${boundaryBlocks.size()} blocks!`);

	resetPencil(state, world);
};

export const deleteSelection = (state: PencilState, world: World, triggerEvents = false) => {
	const blocks = getSelectorPositions(world);

	modifyScene(state, world, "remove", (setBlock) => {
		for (const block of blocks.iterate(_block)) {
			setBlock(block, BLOCK_AIR, "air", triggerEvents);
		}
	});

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-clear",
		volume: 5,
		loop: false,
	});

	displayNotification(world, `Removed ${blocks.size()} blocks!`);

	resetPencil(state, world);
};

export const onViewRaycastModeChange = (state: PencilState) => {
	state.viewRaycastModeChangedDuringPaste = true;
};

const restoreOriginalViewRaycastMode = (state: PencilState, world: World) => {
	const viewRaycast = world.client.camera.target.viewRaycast;
	if (viewRaycast && state.viewRaycastModeBeforePaste !== null) {
		viewRaycast.state.mode = state.viewRaycastModeBeforePaste;
	}
};

export const cancelPastePreview = (state: PencilState, world: World) => {
	restoreOriginalViewRaycastMode(state, world);
	resetPencil(state, world);
};

export const confirmPastePreview = (state: PencilState, world: World) => {
	if (!state.clipboardPastePreview) return;

	paste(state, world, state.clipboardPastePreview.pastePreviewPosition);

	restoreOriginalViewRaycastMode(state, world);

	const blockStructure = getSelectedBlockStructure(world);

	if (blockStructure) {
		setPencilMode(state, world, PencilMode.PASTE_PREVIEW);
	} else {
		resetPencil(state, world);
	}
};

const removePastePreview = (state: PencilState, world: World) => {
	if (!state.clipboardPastePreview) return;

	const scene = world.scene;

	if (state.clipboardPastePreview.pastePreview.group) {
		scene.remove(state.clipboardPastePreview.pastePreview.group);
	}

	for (const geometry of state.clipboardPastePreview.pastePreview.geometries) {
		geometry.dispose();
	}

	state.clipboardPastePreview = null;
};

const setPencilMode = (state: PencilState, _world: World, mode: PencilMode) => {
	state.pencilMode = mode;

	UI.state.pencil().setMode(mode);
};

export const showSelectionOptions = (_state: PencilState, _world: World) => {
	UI.state.pencil().showSelectionOptions();
};

const getClipboardDataFromSelector = (
	state: PencilState,
	world: World,
	copyAir = false,
	copyBlockGroups: CopyBlockGroupsOption = false,
) => {
	const box = new Box3();

	const selectorPositions = getSelectorPositions(world);

	for (const block of selectorPositions.iterate(_block)) {
		box.expandByPoint(block);
	}

	const firstCornerSelectedRelative = state.firstCornerSelected
		? state.firstCornerSelected.clone().sub(box.min)
		: new Vector3(0, 0, 0);

	const blocks = getSelectorBlocks(world, copyAir, copyBlockGroups);

	const copy: Clipboard = {
		blocks: {
			corners: {
				cornerOne: new Vector3(0, 0, 0), // equiv to `box.min.clone().sub(box.min)`
				cornerTwo: box.max.clone().sub(box.min),
			},
			firstCornerSelectedRelative,
			blockStructure: blocks,
		},
	};

	return copy;
};

function copySelectionToClipboard(
	state: PencilState,
	world: World,
	copyAir = false,
	copyBlockGroups: CopyBlockGroupsOption = false,
) {
	const clipboardData = getClipboardDataFromSelector(state, world, copyAir, copyBlockGroups);

	if (!clipboardData) return;

	state.clipboard = clipboardData;

	updateClipboardStore(state);
}

export const cut = (state: PencilState, world: World, triggerEvents = false, copyBlockGroups: CopyBlockGroupsOption) => {
	copySelectionToClipboard(state, world, false, copyBlockGroups);

	const scene = world.scene;
	const blocks = getSelectorPositions(world);

	// remove selection
	modifyScene(state, world, "cut", (setBlock, _, setBlockGroupsAtPosition) => {
		for (const block of blocks.iterate(_block)) {
			setBlock(block, 0, scene.getType(block.z, block.y, block.z), triggerEvents);
			setBlockGroupsAtPosition(block, null);
		}
	});

	resetPencil(state, world);

	displayNotification(world, `Cut ${blocks.size()} blocks!`);
};

export const copy = (
	state: PencilState,
	world: World,
	copyAir = false,
	copyBlockGroups: CopyBlockGroupsOption = false,
) => {
	copySelectionToClipboard(state, world, copyAir, copyBlockGroups);

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-copy",
		volume: 5,
		loop: false,
	});

	resetPencil(state, world);

	displayNotification(world, `Copied ${state.clipboard?.blocks.blockStructure.data.blocks.length} blocks!`);
};

const getSelectedBlockStructure = (world: World) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	if (!selector) return null;

	const pk = selector.state.selectedSlot?.pk;

	if (!pk) return null;

	return world.content.state.blockStructures.get(pk);
};

export const pasteBlockStructureWithPreview = (state: PencilState, world: World) => {
	const blockStructure = getSelectedBlockStructure(world);
	if (!blockStructure) return;

	const box = new Box3();

	const _tmpVec3 = new Vector3();
	blockStructure.data.blocks.forEach(([x, y, z]) => {
		_tmpVec3.set(x, y, z);
		box.expandByPoint(_tmpVec3);
	});

	const firstCornerSelectedRelative = new Vector3(0, 0, 0);

	state.clipboard = {
		blocks: {
			corners: {
				cornerOne: new Vector3(0, 0, 0), // equiv to `box.min.clone().sub(box.min)`
				cornerTwo: box.max.clone().sub(box.min),
			},
			firstCornerSelectedRelative,
			blockStructure,
		},
	};

	pasteWithPreview(state, world);
};

export const pasteWithPreview = (state: PencilState, world: World) => {
	if (!state.clipboard) {
		displayNotification(world, "Clipboard is empty");
		return;
	}

	// change to 'laser' selection mode when pasting
	const previousPastePreview = state.clipboardPastePreview;

	if (previousPastePreview) {
		removePastePreview(state, world);
	} else {
		const viewRaycast = world.client.camera.target?.viewRaycast;

		if (viewRaycast) {
			state.viewRaycastModeChangedDuringPaste = false;
			state.viewRaycastModeBeforePaste =
				world.client.camera.target?.viewRaycast?.state.mode ?? CharacterViewRaycastMode.LASER;
			viewRaycast.state.mode = CharacterViewRaycastMode.LASER;
		}
	}

	const blockStructureGroup = new Group();
	const pastePreview = createBlockStructureMesh(
		world,
		state.clipboard.blocks.blockStructure,
		blockStructureGroup,
	);
	world.scene.add(blockStructureGroup);

	/* store paste preview */
	state.clipboardPastePreview = {
		detached: false,
		pastePreview,
		nudgeOffset: new Vector3(),
		pastePreviewPosition: new Vector3(),
		pastePreviewBox: new Box3(),
		offsetPosition: new Vector3(),
	};

	setPencilMode(state, world, PencilMode.PASTE_PREVIEW);
};

const _pastePreviewPosition = new Vector3();
const _pastePreviewOffsetPosition = new Vector3();
const _pastePreviewZFightingOffset = new Vector3();

const updatePastePreviewPosition = (state: PencilState, world: World) => {
	const equippedItem = world.client.getEquippedItem();

	if (
		(!ItemPencil.isPencil(equippedItem?.def) && !ItemPencil.isBlockStructure(equippedItem?.def)) ||
		!state.clipboardPastePreview ||
		!state.clipboard
	) {
		return;
	}

	const scene = world.scene;
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	const viewRaycast = cameraTarget?.viewRaycast;
	if (!selector || !viewRaycast || !viewRaycast.state.block) return;

	const pastePreview = state.clipboardPastePreview.pastePreview;

	const clipboardVoxelSize = state.clipboardPastePreview.pastePreview.voxelSize;

	/* position paste preview */
	const offsetPosition = _pastePreviewOffsetPosition.set(0, 0, 0);

	if (!state.clipboardPastePreview.detached) {
		if (
			viewRaycast.state.mode !== CharacterViewRaycastMode.LASER &&
			state.clipboard.blocks.firstCornerSelectedRelative
		) {
			// use the first corner selected as the origin point
			offsetPosition.sub(state.clipboard.blocks.firstCornerSelectedRelative);
		} else {
			// horizontally center the clipboard on the block
			offsetPosition.x = -Math.floor(clipboardVoxelSize.x / 2);
			offsetPosition.z = -Math.floor(clipboardVoxelSize.z / 2);

			if (
				viewRaycast.state.block &&
				scene.getShape(
					viewRaycast.state.block.x,
					viewRaycast.state.block.y,
					viewRaycast.state.block.z,
				) !== BLOCK_AIR &&
				viewRaycast.state.blockHitSide === BLOCK_SPY
			) {
				// put the bottom of the clipboard 1 block above the block
				offsetPosition.y = 1;
			} else {
				// vertically center the clipboard on the block
				offsetPosition.y = -Math.floor(clipboardVoxelSize.y / 2);
			}
		}

		offsetPosition.add(viewRaycast.state.block);
	} else {
		offsetPosition.copy(state.clipboardPastePreview.offsetPosition);
	}

	const pastePreviewPosition = _pastePreviewPosition.copy(offsetPosition);
	pastePreviewPosition.add(state.clipboardPastePreview.nudgeOffset);

	pastePreview.group.visible = true;
	pastePreview.group.position.copy(pastePreview.worldSize).multiplyScalar(0.5);
	pastePreview.group.position.add(pastePreviewPosition);

	// move slightly in the direction of the player as a scrappy z-fighting mitigation
	_pastePreviewZFightingOffset.copy(world.client.camera.position).sub(pastePreviewPosition).normalize().multiplyScalar(0.005);
	pastePreview.group.position.add(_pastePreviewZFightingOffset);

	pastePreview.group.rotation.set(0, 0, 0);
	pastePreview.group.scale.set(1, 1, 1);

	pastePreview.group.updateMatrix();

	/* update bulk selection */
	const pastePreviewBox = new Box3().setFromPoints([
		pastePreviewPosition,
		pastePreviewPosition.clone().add(clipboardVoxelSize),
	]);

	setBulkSelectionBrush(
		selector,
		pastePreviewBox.min,
		pastePreviewBox.max,
		BulkSelectionBlocksBrushState.PREVIEWING,
	);

	/* store updated paste preview position */
	state.clipboardPastePreview.pastePreviewPosition.copy(pastePreviewPosition);
	state.clipboardPastePreview.pastePreviewBox.copy(pastePreviewBox);
	state.clipboardPastePreview.offsetPosition.copy(offsetPosition);
};

const updateClipboardStore = (state: PencilState) => {
	UI.state.pencil().setClipboardHasContents(!!state.clipboard);
	UI.state.pencil().setCanUndo(state.undoHistory.length > 0);
	UI.state.pencil().setCanRedo(state.redoHistory.length > 0);
};

export const undo = (state: PencilState, world: World, triggerEvents = false) => {
	const undoAction = state.undoHistory.pop();

	if (!undoAction) {
		displayNotification(world, "No more undo history");
		return;
	}

	const scene = world.scene;

	state.redoHistory.push(undoAction);

	if (undoAction.blocks) {
		for (const changedBlock of undoAction.blocks) {
			const pos = changedBlock.dst;

			scene.setBlock(pos, changedBlock.oldShape, changedBlock.oldType, triggerEvents, null);
		}
	}

	if (undoAction.blockGroups) {
		for (const changedBlockGroup of undoAction.blockGroups) {
			const pos = changedBlockGroup.dst;

			BlockGroupsRouter.setBlockGroupsAtPosition({
				ids: changedBlockGroup.oldGroups,
				x: pos.x,
				y: pos.y,
				z: pos.z,
			});
		}
	}

	if (undoAction.blockGroupDefs) {
		for (const changedBlockGroupDefinition of undoAction.blockGroupDefs) {
			BlockGroupsRouter.deleteGroup(changedBlockGroupDefinition.id);
		}
	}

	if (undoAction.blockGroups) {
		for (const changedBlockGroup of undoAction.blockGroups) {
			BlockGroupsRouter.setBlockGroupsAtPosition({
				ids: changedBlockGroup.oldGroups,
				x: changedBlockGroup.dst.x,
				y: changedBlockGroup.dst.y,
				z: changedBlockGroup.dst.z,
			});
		}
	}

	updateClipboardStore(state);

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-clear",
		volume: 5,
		loop: false,
	});

	displayNotification(world, `Undid ${undoAction.actionName} of ${undoAction.blocks.length} blocks`);
};

export const redo = (state: PencilState, world: World, triggerEvents = false) => {
	const redoAction = state.redoHistory.pop();

	if (!redoAction) {
		return displayNotification(world, "No more redo history");
	}

	const scene = world.scene;

	state.undoHistory.push(redoAction);

	if (redoAction.blocks) {
		for (const changedBlock of redoAction.blocks) {
			const pos = changedBlock.dst;

			scene.setBlock(pos, changedBlock.newShape, changedBlock.newType, triggerEvents, null);
		}
	}

	if (redoAction.blockGroupDefs) {
		for (const blockGroupDefinition of redoAction.blockGroupDefs) {
			
			BlockGroupsRouter.create({
				id: blockGroupDefinition.id,
				name: blockGroupDefinition.group.name ?? null,
				scripts: blockGroupDefinition.group.scripts,
			});
		}
	}

	if (redoAction.blockGroups) {
		for (const changedBlockGroup of redoAction.blockGroups) {
			const pos = changedBlockGroup.dst;

			BlockGroupsRouter.setBlockGroupsAtPosition({
				ids: changedBlockGroup.newGroups,
				x: pos.x,
				y: pos.y,
				z: pos.z,
			});
		}
	}

	updateClipboardStore(state);

	return displayNotification(world, `Redid ${redoAction.actionName} of ${redoAction.blocks.length} blocks`);
};

export const paste = (state: PencilState, world: World, position?: Vector3) => {
	const cameraTarget = world.client.camera.target;
	const selector = cameraTarget?.selector;
	const viewRaycast = cameraTarget?.viewRaycast;
	if (!selector || !viewRaycast || !viewRaycast.state.block) return;

	const clipboard = state.clipboard;

	if (!clipboard) {
		return displayNotification(world, "Clipboard is empty");
	}

	const pastePosition = position ?? viewRaycast.state.block.clone();

	modifyScene(state, world, "paste", (setBlock, createBlockGroup, setBlockGroupsAtPosition) => {
		const blockStructure = clipboard.blocks.blockStructure;

		const remappedGroupIds: Record<string, string> = {};

		for (const [x, y, z, shape, blockStructureTypeIndex] of blockStructure.data.blocks) {
			const dst = _block.set(x, y, z).add(pastePosition);
			const blockStructureType = blockStructure.data.blockTypes[blockStructureTypeIndex];

			setBlock(dst, shape, blockStructureType, false);
		}

		if (blockStructure.data.blockGroupDefs) {
			for (const [groupId, group] of Object.entries(blockStructure.data.blockGroupDefs)) {
				const newBlockGroupId = generateUUID();

				remappedGroupIds[groupId] = newBlockGroupId;

				let name = group.name ?? null;

				if (name !== null) {
					// make a unique block group name
					const nMatching = Array.from(world.blockGroups.groups.values()).filter((g) => g.name && g.name.includes(name!)).length;
					
					let attempts = 1000;
					while (attempts > 0 && nMatching > 0) {
						name = `${group.name} (${nMatching + 1})`;
						attempts--;
					}
				}

				createBlockGroup(newBlockGroupId, name, group.scripts);
			}
		}

		if (blockStructure.data.blockGroups) {
			for (const [dstX, dstY, dstZ, blockGroups] of blockStructure.data.blockGroups) {
				const dst = _block.set(dstX, dstY, dstZ).add(pastePosition);

				setBlockGroupsAtPosition(
					dst,
					blockGroups.map((id) => remappedGroupIds[id] ?? id),
				);
			}
		}
	});

	updateClipboardStore(state);

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-fill",
		volume: 5,
		loop: false,
	});

	return displayNotification(world, `Pasted ${clipboard.blocks.blockStructure.data.blocks.length} blocks!`);
};

const _nudgeDirectionVector = new Vector3();

export const nudge = (state: PencilState, world: World, nudge: Vector3) => {
	const camera = world.client.camera;
	const selector = camera.target.selector;
	if (!selector) return;

	const cameraWorldDirection = camera.getWorldDirection(new Vector3()).multiplyScalar(-1);

	const compassDirection = new Vector3();
	if (Math.abs(cameraWorldDirection.x) > Math.abs(cameraWorldDirection.z)) {
		compassDirection.x = Math.sign(cameraWorldDirection.x);
	} else {
		compassDirection.z = Math.sign(cameraWorldDirection.z);
	}

	const gridNudge = _nudgeDirectionVector
		.copy(nudge)
		.applyAxisAngle(new Vector3(0, 1, 0), Math.atan2(compassDirection.x, compassDirection.z))
		.round();

	if (state.clipboardPastePreview) {
		state.clipboardPastePreview.nudgeOffset.add(gridNudge);
		state.clipboardPastePreview.detached = true;

		return;
	}

	nudgeBulkSelection(selector, gridNudge);
};

export const rotateClipboard = (
	state: PencilState,
	world: World,
	xDegrees = 0,
	yDegrees = 0,
	zDegrees = 0,
	rotateAround: "center" | "origin",
) => {
	if (!state.clipboard) {
		return displayNotification(world, "Clipboard is empty");
	}

	const cameraTarget = world.client.camera.target;
	const viewRaycast = cameraTarget?.viewRaycast;
	if (!viewRaycast) return;

	const clipboard = state.clipboard;

	// validate multiple of 90
	const validRotation = (deg: number) => deg % 90 === 0;

	if (!validRotation(xDegrees) || !validRotation(yDegrees) || !validRotation(zDegrees)) {
		return displayNotification(world, "Invalid input degrees, must be multiples of 90");
	}

	// number of rotations
	const xRotations = xDegrees / 90;
	const yRotations = yDegrees / 90;
	const zRotations = zDegrees / 90;

	// negate degrees when converting to rotate clockwise
	const xRadians = MathUtils.degToRad(-xDegrees);
	const yRadians = MathUtils.degToRad(-yDegrees);
	const zRadians = MathUtils.degToRad(-zDegrees);

	// rotation origin
	const rotationOrigin = new Vector3();

	if (rotateAround === undefined) {
		if (viewRaycast.state.mode === CharacterViewRaycastMode.LASER) {
			rotateAround = "center";
		} else {
			rotateAround = "origin";
		}
	}

	if (rotateAround === "origin") {
		rotationOrigin.copy(clipboard.blocks.firstCornerSelectedRelative);
	} else {
		// default to 'center'
		rotationOrigin
			.addVectors(clipboard.blocks.corners.cornerOne, clipboard.blocks.corners.cornerTwo)
			.multiplyScalar(0.5)
			.floor();
	}

	const rotationMatrix = new Matrix4().makeRotationFromEuler(
		new Euler(xRadians, yRadians, zRadians, "XYZ"),
	);

	const tmpVector3 = new Vector3();

	// rotate blocks
	for (const block of clipboard.blocks.blockStructure.data.blocks) {
		const [x, y, z, shape] = block;
		const position = tmpVector3.set(x, y, z);

		position.sub(rotationOrigin);
		position.applyMatrix4(rotationMatrix);
		position.add(rotationOrigin);
		position.round();

		block[0] = position.x;
		block[1] = position.y;
		block[2] = position.z;

		if (ShapeUtil.isSculpted(shape)) {
			block[3] = ShapeUtil.rotate(shape, xRotations, yRotations, zRotations);
		}
	}
	for (const block of clipboard.blocks.blockStructure.data.blockGroups ?? []) {
		const [x, y, z] = block;
		const position = tmpVector3.set(x, y, z);

		position.sub(rotationOrigin);
		position.applyMatrix4(rotationMatrix);
		position.add(rotationOrigin);
		position.round();

		block[0] = position.x;
		block[1] = position.y;
		block[2] = position.z;
	}

	// rotate corners
	const cornerOne = clipboard.blocks.corners.cornerOne.clone();
	const cornerTwo = clipboard.blocks.corners.cornerTwo.clone();

	cornerOne.sub(rotationOrigin);
	cornerTwo.sub(rotationOrigin);

	cornerOne.applyMatrix4(rotationMatrix);
	cornerTwo.applyMatrix4(rotationMatrix);

	cornerOne.add(rotationOrigin);
	cornerTwo.add(rotationOrigin);

	cornerOne.round();
	cornerTwo.round();

	clipboard.blocks.corners.cornerOne.copy(cornerOne);
	clipboard.blocks.corners.cornerTwo.copy(cornerTwo);

	// update corners and relative positions to be positive / anchored at 0,0,0
	const rotatedCornersBox = new Box3().setFromPoints([
		clipboard.blocks.corners.cornerOne,
		clipboard.blocks.corners.cornerTwo,
	]);
	const minCorner = rotatedCornersBox.min;
	const maxCorner = rotatedCornersBox.max;

	const offset = minCorner.clone();

	for (const block of clipboard.blocks.blockStructure.data.blocks) {
		block[0] -= offset.x;
		block[1] -= offset.y;
		block[2] -= offset.z;
	}
	for (const block of clipboard.blocks.blockStructure.data.blockGroups ?? []) {
		block[0] -= offset.x;
		block[1] -= offset.y;
		block[2] -= offset.z;
	}

	minCorner.sub(offset);
	maxCorner.sub(offset);

	clipboard.blocks.corners.cornerOne.copy(minCorner);
	clipboard.blocks.corners.cornerTwo.copy(maxCorner);

	// rotate first corner selected relative position
	clipboard.blocks.firstCornerSelectedRelative.sub(rotationOrigin);
	clipboard.blocks.firstCornerSelectedRelative.applyMatrix4(rotationMatrix);
	clipboard.blocks.firstCornerSelectedRelative.add(rotationOrigin);
	clipboard.blocks.firstCornerSelectedRelative.round();
	clipboard.blocks.firstCornerSelectedRelative.sub(offset);

	if (state.clipboardPastePreview) {
		pasteWithPreview(state, world);
	}
};

const flipClipboard = (state: PencilState, world: World, axis: "x" | "y" | "z") => {
	if (!state.clipboard) {
		return displayNotification(world, "The clipboard is empty, nothing to flip");
	}

	// flip blocks within clipboard selection bounds
	const tmpVector3 = new Vector3();

	for (const block of state.clipboard.blocks.blockStructure.data.blocks) {
		const [x, y, z, shape] = block;
		const position = tmpVector3.set(x, y, z);

		if (axis === "x") {
			position.x = state.clipboard.blocks.corners.cornerTwo.x - position.x;
		} else if (axis === "y") {
			position.y = state.clipboard.blocks.corners.cornerTwo.y - position.y;
		} else if (axis === "z") {
			position.z = state.clipboard.blocks.corners.cornerTwo.z - position.z;
		}

		block[0] = position.x;
		block[1] = position.y;
		block[2] = position.z;

		if (ShapeUtil.isSculpted(shape)) {
			block[3] = ShapeUtil.flip(shape, axis);
		}
	}
	for (const block of state.clipboard.blocks.blockStructure.data.blockGroups ?? []) {
		const [x, y, z] = block;
		const position = tmpVector3.set(x, y, z);

		if (axis === "x") {
			position.x = state.clipboard.blocks.corners.cornerTwo.x - position.x;
		} else if (axis === "y") {
			position.y = state.clipboard.blocks.corners.cornerTwo.y - position.y;
		} else if (axis === "z") {
			position.z = state.clipboard.blocks.corners.cornerTwo.z - position.z;
		}

		block[0] = position.x;
		block[1] = position.y;
		block[2] = position.z;
	}

	// flip first corner selected relative position
	if (axis === "x") {
		state.clipboard.blocks.firstCornerSelectedRelative.x =
			state.clipboard.blocks.corners.cornerTwo.x - state.clipboard.blocks.firstCornerSelectedRelative.x;
	} else if (axis === "y") {
		state.clipboard.blocks.firstCornerSelectedRelative.y =
			state.clipboard.blocks.corners.cornerTwo.y - state.clipboard.blocks.firstCornerSelectedRelative.y;
	} else if (axis === "z") {
		state.clipboard.blocks.firstCornerSelectedRelative.z =
			state.clipboard.blocks.corners.cornerTwo.z - state.clipboard.blocks.firstCornerSelectedRelative.z;
	}

	if (state.clipboardPastePreview) {
		pasteWithPreview(state, world);
	}

	return displayNotification(
		world,
		`Flipped ${state.clipboard.blocks.blockStructure.data.blocks.length} block${state.clipboard.blocks.blockStructure.data.blocks.length === 1 ? "" : "s"}`,
	);
};

export const flipClipboardByCameraDirection = (state: PencilState, world: World) => {
	const camera = world.client.camera;

	const cameraWorldDirection = camera.getWorldDirection(new Vector3()).multiplyScalar(-1);

	const cameraDirectionAxis = new Vector3();

	if (Math.abs(cameraWorldDirection.x) > Math.abs(cameraWorldDirection.z)) {
		cameraDirectionAxis.x = Math.sign(cameraWorldDirection.x);
	} else {
		cameraDirectionAxis.z = Math.sign(cameraWorldDirection.z);
	}

	flipClipboard(state, world, cameraDirectionAxis.x ? "z" : "x");
};

export const createBlockStructureFromBlocks = (
	state: PencilState,
	world: World,
	blockStructure: ReturnType<typeof getSelectorBlocks>,
) => {
	if (
		state.pencilMode !== PencilMode.TWO_CORNERS_SELECTED &&
		state.pencilMode !== PencilMode.PASTE_PREVIEW
	) {
		return;
	}

	world.content.state.blockStructures.set(blockStructure);

	return blockStructure;
};

export const createPropInInventoryFromBlockStructure = (
	_state: PencilState,
	world: World,
	blockStructure: IBlockStructure,
) => {
	const prop = world.content.state.props.makeEmptyProp();

	prop.mesh = {
		type: PropMeshType.BLOCK_STRUCTURE,
		blockStructurePk: blockStructure.pk,
	};

	prop.physics = {
		collider: {
			type: PropColliderType.BLOCK_STRUCTURE,
			blockStructurePk: blockStructure.pk,
			blockStructureColliderType: BlockStructureColliderType.CONVEX_HULL,
		},
		mass: 1,
		friction: 0.5,
		restitution: 0.2,
		motionType: PropMotionType.DYNAMIC,
	};

	prop.scale = 1;

	world.content.state.props.set(prop);

	return prop;
};

export const createPropInWorldFromSelection = (state: PencilState, world: World) => {
	// get selector positions
	const positions = getSelectorPositions(world);

	// find center for prop spawn position
	const box3 = new Box3();
	for (const position of positions.iterate(_block)) {
		box3.expandByPoint(position);
	}
	box3.max.addScalar(1);
	const center = box3.getCenter(new Vector3());

	// create block structure
	const blocks = getSelectorBlocks(world);
	const blockStructure = createBlockStructureFromBlocks(state, world, blocks);
	if (!blockStructure) return null;

	// create prop
	const prop = createPropInInventoryFromBlockStructure(state, world, blockStructure);
	if (!prop) return null;

	// add prop instance to scene tree
	const position = center;
	const quaternion = new Quaternion().identity();
	const scale = 1;

	SpawnerRouter.createSceneTreeNode({
		name: null,
		type: SceneTree.SceneNodeType.PROP,
		propPk: prop.pk,
		position: [position.x, position.y, position.z],
		quaternion: [quaternion.x, quaternion.y, quaternion.z, quaternion.w],
		scale,
		motionType: prop.physics.motionType,
		scripts: [],
	});

	// remove blocks
	deleteSelection(state, world);
};

export const removeAirFromSelection = (_state: PencilState, world: World) => {
	const camera = world.client.camera;
	const selector = camera.target.selector;
	if (!selector) return;

	for (const block of selector.state.bulkSelectionBlocks.iterate()) {
		if (world.scene.getShape(block.x, block.y, block.z) === BLOCK_AIR) {
			removePositionFromSelection(selector, block);
		}
	}

	world.sfxManager.play({
		asset: "snd-ui-pencil-selection-clear",
		volume: 5,
		loop: false,
	});

	displayNotification(world, `Removed air blocks from selection!`);
};
