import type { IAvatarConfig } from "../types";

export const MAX_AVATARS = 10;
export const MIN_AVATAR_NAME_LENGTH = 3;
export const MAX_AVATAR_NAME_LENGTH = 70;

export enum AVATAR_OBJECT {
	bodyType = "bodyType",
	hairColor = "hairColor",
	skinColor = "skinColor",
	hat = "hat",
	arms = "arms",
	backpack = "backpack",
	face = "face",
	hair = "hair",
	legs = "legs",
	mask = "mask",
	torso = "torso",
}

export const BODY_PARTS = [
	"bodyType",
	"skinColor",
	"hairColor",
	"face",
	"hair",
	"hat",
	"mask",
	"torso",
	"arms",
	"legs",
	"backpack",
];

type AvatarConfigKey = keyof IAvatarConfig;

export const AVATAR_CONFIG_KEYS_WITHOUT_RESOURCE = ["bodyType", "skinColor", "hairColor"] as const;

type AvatarConfigKeyWithResource = Exclude<
	AvatarConfigKey,
	(typeof AVATAR_CONFIG_KEYS_WITHOUT_RESOURCE)[number]
>;

export const AVATAR_CONFIG_RESOURCE_TYPES: Record<AvatarConfigKeyWithResource, "glb" | "png"> = {
	hat: "glb",
	arms: "glb",
	backpack: "glb",
	face: "png",
	hair: "glb",
	legs: "glb",
	mask: "glb",
	torso: "glb",
} as const;

export const BODY_TYPE_VALUE = {
	BODY_TYPE_1: "body_type_1",
	BODY_TYPE_2: "body_type_2",
};

export const DEFAULT_BODY_PARTS = {
	bodyType: BODY_TYPE_VALUE.BODY_TYPE_1,
	torso: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/base-torso-type-1.glb",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/base-torso-type-2.glb",
	},
	arms: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/mdl-character-default-type1-fp-geom.glb",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/mdl-character-default-type2-fp-geom.glb",
	},
	legs: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/base-legs-type-1.glb",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/base-legs-type-2.glb",
	},
	hair: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/base-hair-type-1.glb",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/base-hair-type-2.glb",
	},
	head: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/base-head-type-1.glb",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/base-head-type-2.glb",
	},
	face: {
		body_type_1: "assets/engine/mods/CharacterDefault/type1/base-face-type-1.png",
		body_type_2: "assets/engine/mods/CharacterDefault/type2/base-face-type-2.png",
	},
};

export const AVATAR_NODES = {
	backpack: "Backpacks_GRP",
	hair: "Hairs_GRP",
	hat: "Hats_GRP",
	mask: "Masks_GRP",
	arms: "Arms_GRP",
	face: "Heads_GEO",
	head: "Heads_GRP",
	head_type: "Heads_GRP",
	legs: "Legs_GRP",
	torso: "Torsos_GRP",
};

export const ACCESSORY_NODES = [
	AVATAR_NODES.backpack,
	AVATAR_NODES.hair,
	AVATAR_NODES.mask,
	AVATAR_NODES.hat,
];

export const BODY_NODES = [AVATAR_NODES.arms, AVATAR_NODES.legs, AVATAR_NODES.torso, AVATAR_NODES.head];

export function isObject(object: any) {
	return object !== null && typeof object === "object";
}

// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
function splitToNChunks(arr: any[], n: number) {
	const array = [...arr];
	const result = [];
	for (let i = n; i > 0; i--) {
		result.push(array.splice(0, Math.ceil(array.length / i)));
	}
	return result;
}

export function formatMissingBodyParts(missingBodyParts: string[] = []) {
	let splitArr = [] as any[];

	missingBodyParts = missingBodyParts.map((part) => `${part[0].toUpperCase()}${part.substring(1)}`);

	// divide array into chunks
	if (missingBodyParts.length >= 6) {
		splitArr = splitToNChunks(missingBodyParts, 2);
	} else if (missingBodyParts.length >= 9) {
		splitArr = splitToNChunks(missingBodyParts, 3);
	}

	splitArr = splitArr.map((chunk) => chunk.join(", "));

	return splitArr.join("\n");
}
