import * as Resources from "client/Resources";
import { EasySprite } from "client/util/EasySprite.js";
import type { ItemType } from "mods/defs/ItemType";
import type { Entity } from "base/world/entity/Entity";

export class ItemHitmarkerComponent {
	name = "hitmarker";

	def: {
		hitmarker: EasySprite;
		hitmarkerScl: number;
		hitmarkerTime: number;
		asset: string;
	};

	state: {
		hitmarkerTimer: number;
	};

	constructor(o: ItemType, entity: Entity) {
		this.def = {
			asset: o.assetHitmarker ?? "tex-hitmarker",
			hitmarkerScl: o.hitmarkerScl ?? 1,
			hitmarkerTime: o.hitmarkerTime ?? 0.1,
			hitmarker: null!,
		};

		this.def.hitmarker = new EasySprite(
			Resources.get(this.def.asset),
			entity.world.client.sceneHUD,
			this.def.hitmarkerScl,
		);
		this.def.hitmarker.visible = false;

		this.state = {
			hitmarkerTimer: 0,
		};
	}

	dispose() {
		this.def.hitmarker.dispose();
	}
}
