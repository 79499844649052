import { WorldUserRole } from "@jamango/content-client";
import { err } from "@jamango/helpers";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { World } from "base/world/World";
import * as WorldEditor from "base/world/WorldEditor";
import { UI } from "client/dom/UI";
import type { Peer } from "@jamango/ibs";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import * as WorldEditorServer from "server/world/WorldEditor";

type WorldEditorStatePatch = WorldEditor.WorldEditorStatus;

export const createPatch = (state: WorldEditor.WorldEditorState): WorldEditorStatePatch => {
	return state.status;
};

export const applyPatch = (state: WorldEditor.WorldEditorState, status: WorldEditor.WorldEditorStatus) => {
	WorldEditor.updateStatus(state, status);

	if (netState.isClient) {
		UI.state.worldEditor().updateStatus(state.status);
	}
};

const worldEditorPatchCommand = Net.command(
	"world editor patch",
	Net.CLIENT,
	(changed: WorldEditorStatePatch, world) => {
		if (netState.isClient) {
			const state = world.editor;

			applyPatch(state, changed);
		}
	},
);

const serverPeerCanUpdate = (peer: Peer | null) => {
	if (!peer) return true;

	const role = getPeerMetadata(peer).role;

	return role === WorldUserRole.OWNER || role === WorldUserRole.COLLABORATOR;
};

const serverOnChange = (world: World) => {
	const patch = createPatch(world.editor);

	if (netState.isClient) {
		applyPatch(world.editor, patch);
	}

	worldEditorPatchCommand.sendToAll(patch);
};

const NO_PERMISSIONS_ERROR = err("You do not have permission to change the world editor state");

export const update = Net.rpc("world editor update", (action: WorldEditor.WorldEditorAction, world, peer) => {
	const canUpdate = serverPeerCanUpdate(peer);
	if (!canUpdate) return NO_PERMISSIONS_ERROR;

	WorldEditorServer.update(world.editor, world, action);

	serverOnChange(world);
});
