import { ws } from "rest-client";
import { NET_CLIENT } from "../share/Const";
import { IBSBase } from "./Base";
import { Peer } from "./Peer";

export class IBSClient extends IBSBase {
	oniceselected?: ((a: string) => any) | null;

	constructor(
		readonly o: ConstructorParameters<typeof IBSBase>[0] & {
			oniceselected?: (a: string) => any;
			username: string;
		},
	) {
		super(o);

		this.oniceselected = o.oniceselected ?? null;
	}

	/**
	 * This is only meant to be called internally
	 */
	init() {
		const serverId = this.o.serverID;
		const server = new Peer(this, serverId);
		const rtc = server.rtc;

		rtc.addEventListener("connectionstatechange", async () => {
			if (rtc.connectionState !== "connected") return;

			//https://stackoverflow.com/a/64172130/14621380
			const stats = await rtc.getStats();
			if (!stats) return;

			let selectedPairID;
			for (const [_, stat] of stats) {
				if (stat.type === "transport") {
					selectedPairID = stat.selectedCandidatePairId;
					break;
				}
			}

			let candidatePair = stats.get(selectedPairID);
			if (!candidatePair) {
				for (const [_, stat] of stats) {
					if (stat.type === "candidate-pair" && stat.selected) {
						//stat.selected is a non-standard firefox property
						candidatePair = stat;
						break;
					}
				}
			}

			if (candidatePair) {
				for (const [key, stat] of stats) {
					if (key === candidatePair.remoteCandidateId) {
						this.oniceselected?.(stat.candidateType);
						return;
					}
				}
			}
		});

		const sub = ws.client.subscribe("ibs:login", {
			version: this.o.version,
			mode: NET_CLIENT,
			serverId,
			dedicated: false,
			username: this.o.username,
		});

		sub.listen(async (msg) => {
			const result = msg.result;
			if (!result) {
				console.error(msg.error ?? "Unknown error");
				return;
			}

			switch (result.type) {
				case "success": {
					this.peerID = result.peerId;
					this.accessToken = result.accessToken;

					const offer = await rtc.createOffer();
					await rtc.setLocalDescription(offer);
					await ws.client.call("ibs:offer", {
						offer: offer as any,
						accessToken: this.accessToken!,
					});
					return;
				}
				case "ice": {
					rtc.addIceCandidate(new RTCIceCandidate(result.rtc));
					return;
				}
				case "answer": {
					try {
						await rtc.setRemoteDescription(new RTCSessionDescription(result.rtc as any));
					} catch (oops) {
						console.error(oops);
						rtc.close();
					}
					return;
				}
				case "server-full": {
					// TODO handle
					console.log(`server-full`);
					return;
				}
			}
		});

		this.setCommandListener("open", () => {
			this.mode = NET_CLIENT;
			sub.unsubscribe();
			this.initPromise.resolve();
		});

		this.setCommandListener("close", () => {
			sub.unsubscribe();
			this.onfail?.("slave");
			this.close();
			this.rejectConstructor();
		});

		return this.initPromise;
	}
}
