import { type Input } from "@jamango/engine/Input.ts";
import { V0 } from "base/util/math/Math";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { Character } from "base/world/entity/Character";
import { entityIsCharacter, entityIsItem, entityIsProp } from "base/world/entity/component/Type";
import type { Entity } from "base/world/entity/Entity";
import { Quaternion, Vector3 } from "three";

const _cameraDirection = new Vector3();
const _targetQuaternion = new Quaternion();
const _targetPosition = new Vector3();
const _holdPoint = new Vector3();
const _hitOffsetWorld = new Vector3();
const _characterStartQuaternion = new Quaternion();
const _characterQuaternionDifference = new Quaternion();
const _entityStartQuaternion = new Quaternion();

export function characterHoldUpdate(character: Entity, _stepDeltaTime: number, input: Input) {
	if (!canUpdateSystem(character)) return;
	if (input === undefined) return;

	const world = character.world;

	if (!input.hold) return;

	const [entityId, distance, hitOffsetLocal, characterStartQuaternionArray, entityStartQuaternionArray] =
		input.hold;

	const holdingEntity = world.getEntity(entityId);
	if (!holdingEntity) return;

	// determine the held entity's new position and rotation
	const characterStartQuaternion = _characterStartQuaternion.fromArray(characterStartQuaternionArray);
	const characterCurrentQuaternion = character.quaternion;
	const characterQuaternionDifference = _characterQuaternionDifference
		.copy(characterStartQuaternion)
		.invert()
		.multiply(characterCurrentQuaternion);

	const entityStartQuaternion = _entityStartQuaternion.fromArray(entityStartQuaternionArray);
	const targetQuaternion = _targetQuaternion
		.copy(characterQuaternionDifference)
		.multiply(entityStartQuaternion);

	const cameraDirection = _cameraDirection.setFromSpherical(input.camera).normalize().multiplyScalar(-1);
	const holdPoint = _holdPoint.copy(cameraDirection).multiplyScalar(distance).add(character.position);

	holdPoint.y += character.size.state.eyeHeight;
	if (character.movement.state.isCrouching) {
		holdPoint.y -= character.size.state.crouchOffset;
	}

	const hitOffsetWorld = _hitOffsetWorld.fromArray(hitOffsetLocal).applyQuaternion(targetQuaternion);
	const targetPosition = _targetPosition.copy(holdPoint).sub(hitOffsetWorld);

	// TODO: switch between setting positions/rotations and setting velocities
	// on some flag, e.g. isProphecy, or flag in input.hold
	holdingEntity.setPosition(targetPosition, true);

	if (!holdingEntity.type.def.isPlayer) {
		holdingEntity.setQuaternion(targetQuaternion, true);
	}

	// reset velocities while dragging by modifying positon/rotation directly
	if (entityIsProp(holdingEntity) || entityIsCharacter(holdingEntity) || entityIsItem(holdingEntity)) {
		holdingEntity.setLinearVelocity(V0);
	}
	if (entityIsProp(holdingEntity)) {
		holdingEntity.setAngularVelocity(V0);
	}
}

function canUpdateSystem(entity: Entity): entity is Character {
	return entity.type.def.isPlayer && hasPencilPowers(entity);
}

// TODO: blanket creator permission for now
// in future holding items and props will be a gameplay feature, and this will need to be revisited
function hasPencilPowers(entity: Entity) {
	const peer = entity.world.playerToPeer.get(entity);
	if (!peer) return false;
	return getPeerMetadata(peer).permissions.canUsePencil;
}
