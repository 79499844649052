//these classes can only handle vectors with integer components

import { Vector3 } from "three";

const _vector3 = new Vector3();

export class Vector3Set {
	#backingSet = new Set<string>();

	add(v: Vector3) {
		this.#backingSet.add(flatten(v));
		return this;
	}

	has(v: Vector3) {
		return this.#backingSet.has(flatten(v));
	}

	size() {
		return this.#backingSet.size;
	}

	*iterate(cur: Vector3 = _vector3) {
		for (const flattened of this.#backingSet) yield unflatten(flattened, cur);
	}

	delete(v: Vector3) {
		return this.#backingSet.delete(flatten(v));
	}

	clear() {
		this.#backingSet.clear();
	}
}

export class Vector3Map<V> {
	#backingMap = new Map<string, V>();

	set(k: Vector3, v: V) {
		this.#backingMap.set(flatten(k), v);
		return this;
	}

	get(k: Vector3) {
		return this.#backingMap.get(flatten(k));
	}

	getRaw(x: number, y: number, z: number) {
		return this.#backingMap.get(flattenRaw(x, y, z));
	}

	hasRaw(x: number, y: number, z: number) {
		return this.#backingMap.has(flattenRaw(x, y, z));
	}

	has(k: Vector3) {
		return this.#backingMap.has(flatten(k));
	}

	size() {
		return this.#backingMap.size;
	}

	*keys(cur: Vector3) {
		for (const flattened of this.#backingMap.keys()) {
			unflatten(flattened, cur);
			yield;
		}
	}

	//only use values() to iterate if you can. doesn't require unflattening
	*values() {
		for (const v of this.#backingMap.values()) yield v;
	}

	*entries(cur: Vector3 = _vector3) {
		for (const [flattened, v] of this.#backingMap) {
			unflatten(flattened, cur);
			yield v;
		}
	}

	delete(k: Vector3) {
		return this.#backingMap.delete(flatten(k));
	}

	clear() {
		this.#backingMap.clear();
	}
}

export function flatten(input: Vector3) {
	return `${input.x},${input.y},${input.z}`;
}

export function flattenRaw(x: number, y: number, z: number) {
	return `${x},${y},${z}`;
}

export function unflatten(input: string, output: Vector3) {
	const n = input.split(",");
	output.x = Number(n[0]);
	output.y = Number(n[1]);
	output.z = Number(n[2]);
	return output;
}
