import { apiResponseToResult, apiClient, nonNullishQuery } from "./index";
import type { IIdentifier } from "@jamango/content-client";
import { WorldUserRole } from "@jamango/content-client";
import type { IId } from "@jamango/content-service/types/schema.ts";

export async function loadUserWorlds() {
	return apiResponseToResult(await apiClient.api.worlds.index.get());
}

export async function getWorldVersions(worldId: string) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).versions.index.get());
}

export async function getDefaultWorld({ packagesIds }: { packagesIds: string[] }) {
	return apiResponseToResult(await apiClient.api.worlds.default.post({ packagesIds }));
}

export async function getWorld({ shortCode, versionId }: { shortCode: string; versionId?: string }) {
	return apiResponseToResult(
		await apiClient.api.worlds({ worldId: shortCode }).get({ query: nonNullishQuery({ versionId }) }),
	);
}

export async function getWorldByDedi(shortCode: string) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId: shortCode }).dedi.get());
}

/**
 * World likes and world favorites
 */

export async function toggleWorldLike(worldId: string) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).like.post({}));
}

export async function toggleWorldFavorite(worldId: string) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).favorite.post({}));
}

export async function requestSaveWorld({ worldId, versionId, autosave }: { worldId: string; versionId?: string; autosave: boolean }) {
	return apiResponseToResult(
		await apiClient.api.worlds({ worldId }).request_save_world.post({ versionId, autosave }),
	);
}

export async function getLiveWorlds() {
	return apiResponseToResult(await apiClient.api.live_worlds.index.get());
}

export async function getWorldsFeed() {
	return apiResponseToResult(await apiClient.api.worlds.feed.get());
}

export async function getWorldFeedItem({ shortCode, versionId }: { shortCode: string; versionId?: string }) {
	return apiResponseToResult(
		await apiClient.api.worlds.feed
			.world({ worldId: shortCode })
			.get({ query: nonNullishQuery({ versionId }) }),
	);
}

export async function remixWorld(worldId: string) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).remix.post({}));
}

export function getDefaultCreatorTutorial() {
	return Promise.resolve(undefined);
}

export async function archiveWorld({ worldId }: { worldId: string }) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).archive.post({}));
}

export async function requestPublishWorld({ worldId, versionId }: { worldId: string; versionId: string }) {
	return apiResponseToResult(
		await apiClient.api.worlds({ worldId }).versions({ versionId }).publish.request.patch({}),
	);
}

export async function unpublishWorld(worldId: IIdentifier) {
	return apiResponseToResult(await apiClient.api.worlds({ worldId }).versions.unpublish.patch({}));
}

export async function setWoldVersionAsLive(worldId: IIdentifier, versionId: IId) {
	return apiResponseToResult(
		await apiClient.api.worlds({ worldId }).versions({ versionId }).live.patch({}),
	);
}

export async function getWorldRole({ shortCode, accountID }: { shortCode: string; accountID: string }) {
	if (!accountID) return WorldUserRole.NONE;

	return apiResponseToResult(
		await apiClient.api.worlds({ worldId: shortCode }).permissions.role({ accountId: accountID }).get(),
	);
}

export async function createImportWorldRequest() {
	return apiResponseToResult(await apiClient.api.worlds["create-import-world-request"].post());
}
