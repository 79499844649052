import { LoopRepeat } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateFlyingIdle extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("FlyingIdle", animMesh, locomotionInput, transitions);

		this.transitionToJumpingTime = 0.2;
		this.transitionToIdleTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToDeathTime = 0.2;
		this.transitionToFlyingMovingTime = 0.2;
	}

	get idleAction() {
		return this.mesh.getAction("JMGO_Unarmed_Fly_Idle");
	}

	startCompute(transitionData) {
		this.idleAction.reset();
		this.idleAction.loop = LoopRepeat;

		if (transitionData.prvState) {
			const walkAction = this.mesh.getAction("JMGO_Unarmed_Fly_Forward");
			if (walkAction) {
				this.idleAction.syncWith(walkAction);
			}

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.idleAction.play();
		this.startFadeIn(transitionData);
	}

	onStateOff() {}

	compute() {
		if (this.state === "off") {
			this.idleAction.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.idleAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isSprinting) {
			outTransitionToState.ptr = map.get("FlyingSprint");
			outTransitionToState.blendTime = this.transitionToFlyingMovingTime;
			return true;
		}

		// transition to flying state
		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("Flying");
			outTransitionToState.blendTime = this.transitionToFlyingMovingTime;
			return true;
		}

		if (!this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}
}
