import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateDeathStart extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("DeathStart", animMesh, locomotionInput, transitions);

		this.transitionToDeathIdleTime = 0.2;
	}

	get deathStartAction() {
		return this.mesh.getAction("JMGO_Unarmed_Death");
	}

	startCompute(transitionData) {
		this.deathStartAction.reset();
		this.deathStartAction.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.deathStartAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.deathStartAction.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.deathStartAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.deathStartAction.getRemainingTime() < this.transitionToDeathIdleTime) {
			outTransitionToState.ptr = map.get("DeathIdle");
			outTransitionToState.blendTime = this.transitionToDeathIdleTime;
			return true;
		}

		return false;
	}
}
