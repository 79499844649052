import { z } from "zod";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export enum ItemMeshType {
	NONE = 0,
	BLOCK_STRUCTURE = 1,
}

export enum ItemColliderType {
	BOX = 0,
}

export enum ItemBehaviourType {
	CUSTOM = 0,
	MELEE = 1,
	RANGED_WEAPON = 2,
}

export enum ItemAnimationType {
	ONE_HANDED_TOOL = 0,
	ONE_HANDED_MELEE = 1,
	ONE_HANDED_RANGED_WEAPON = 2,
	TWO_HANDED_RANGED_WEAPON = 3,
}

export enum ItemRangedWeaponType {
	MANUAL = 0,
	AUTOMATIC = 1,
}

export const ItemSchema = z.object({
	pk: assetKeySchema(AssetType.ITEM),
	type: z.literal(AssetType.ITEM),
	name: z.string(),
	mesh: z.discriminatedUnion("type", [
		z.object({
			type: z.literal(ItemMeshType.NONE),
		}),
		z.object({
			type: z.literal(ItemMeshType.BLOCK_STRUCTURE),
			blockStructurePk: z.string(),
			transform: z.object({
				offset: z.tuple([z.number(), z.number(), z.number()]),
				rotation: z.tuple([z.number(), z.number(), z.number(), z.string()]),
				scale: z.tuple([z.number(), z.number(), z.number()]),
			}),
		}),
	]),
	collider: z.discriminatedUnion("type", [
		z.object({
			type: z.literal(ItemColliderType.BOX),
			size: z.tuple([z.number(), z.number(), z.number()]),
		}),
	]),
	itemBehaviour: z
		.object({
			cooldown: z.number(),
		})
		.and(
			z.discriminatedUnion("type", [
				z.object({
					type: z.literal(ItemBehaviourType.CUSTOM),
				}),
				z.object({
					type: z.literal(ItemBehaviourType.MELEE),
				}),
				z.object({
					type: z.literal(ItemBehaviourType.RANGED_WEAPON),
					rangedWeaponType: z
						.literal(ItemRangedWeaponType.MANUAL)
						.or(z.literal(ItemRangedWeaponType.AUTOMATIC)),
					cooldown: z.number(),
				}),
			]),
		),
	itemAnimation: z.discriminatedUnion("type", [
		z.object({
			type: z.literal(ItemAnimationType.ONE_HANDED_TOOL),
		}),
		z.object({
			type: z.literal(ItemAnimationType.ONE_HANDED_MELEE),
		}),
		z.object({
			type: z.literal(ItemAnimationType.ONE_HANDED_RANGED_WEAPON),
		}),
		z.object({
			type: z.literal(ItemAnimationType.TWO_HANDED_RANGED_WEAPON),
		}),
	]),
	packageId: PackageIdSchema.optional(),
});
