import { z } from "zod";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

// use engine's asset id
const SoundsPackAudioAssetIds = z.array(z.string()).optional();

export const BlockSoundsPackSchema = z.object({
	pk: assetKeySchema(AssetType.BLOCK_SOUNDS_PACK),
	type: z.literal(AssetType.BLOCK_SOUNDS_PACK),
	id: z.string(),
	name: z.string(),
	onBreak: SoundsPackAudioAssetIds,
	onBuild: SoundsPackAudioAssetIds,
	onFootstep: SoundsPackAudioAssetIds,
	packageId: PackageIdSchema.optional(),
});
