import JSZip from "jszip";
import asyncPool from "tiny-async-pool";
import { getExtension, getFileNameWithoutExtension } from "../lib/helpers/files";
import type { IBundle } from "../lib/types";
import type { JacyContent } from "./JacyContent";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("JacyContentSerializer");

const BUNDLE_FILENAME = "bundle.json";

export class JacyContentSerializer {
	#content: JacyContent;

	constructor(content: JacyContent) {
		this.#content = content;
	}

	async import(
		zipFile: Parameters<typeof JSZip.loadAsync>[0],
	): Promise<{ bundle: IBundle; files: { [pk: string]: File } }> {
		const files: Record<string, File> = {};
		const zip = await JSZip.loadAsync(zipFile);

		const bundleZipEntry = zip.files[BUNDLE_FILENAME];

		if (!bundleZipEntry) {
			throw new Error(`No ${BUNDLE_FILENAME} file found in zip`);
		}

		const bundleText = await bundleZipEntry.async("text");

		if (!bundleText) {
			throw new Error(`Failed to read ${BUNDLE_FILENAME} file`);
		}

		const bundle = JSON.parse(bundleText) as IBundle;

		const filesInDir = Object.entries(zip.files);
		for (const [relativePath, zipEntry] of filesInDir) {
			if (zipEntry.dir || !relativePath.startsWith("files/")) continue;

			const [_, pk] = relativePath.split("/");

			const resource = bundle.assets.resources?.[pk];

			if (!resource) {
				throw new Error(`Failed to find resource for pk ${pk}`);
			}

			resource.file.url = `files://${pk}`;
			resource.file.path = undefined;

			const blob = await zipEntry.async("uint8array");

			const file = new File([blob], resource.file.name, { type: resource.file.mimeType });

			files[pk] = file;
		}

		if (!bundle) {
			throw new Error(`No ${BUNDLE_FILENAME} file found in zip`);
		}

		return { bundle, files };
	}

	async export(bundle: IBundle) {
		const files = await this.#extractFilesInBundle(bundle, this.#content);

		const zip = new JSZip();
		zip.file(BUNDLE_FILENAME, JSON.stringify(bundle), { base64: false });

		const filesZipFolder = zip.folder("files");

		if (!filesZipFolder) {
			throw new Error("Failed to create files folder in zip");
		}

		for (const [filename, data] of files) {
			filesZipFolder.file(filename, data, { base64: true });
		}

		return await zip.generateAsync({ type: "uint8array", compression: "DEFLATE" });
	}

	async #extractFilesInBundle(bundle: IBundle, content: JacyContent): Promise<Map<string, File>> {
		const files = new Map<string, File>();

		const needsToExtract = bundle.assets.resources ?? {};

		const resources = content.state.resources.getAll();
		const EXTRACT_FILES_CONCURRENCY = 5;

		for await (const _ of asyncPool(
			EXTRACT_FILES_CONCURRENCY,
			resources,
			async (resource: (typeof resources)[number]) => {
				if (resource.isBuiltIn) return;
				if (!needsToExtract[resource.pk]) return;

				const url = resource.file.url;
				const buffer = resource.file.buffer;

				let file: File | undefined = undefined;

				if (buffer instanceof File) {
					file = buffer;
				} else if (typeof url === "string" && (url.startsWith("http") || url.startsWith("blob:"))) {
					const filename = getFileNameWithoutExtension(url);
					const extension = getExtension(url);
					const name = `${filename}.${extension}`;

					if (!filename || !extension) {
						logger.error("Failed to get filename or extension for data url", url);
						return;
					}

					logger.info("Exporting file", name, "...");
					file = await this.#content.getFile(url);
				}

				if (!file) return;

				resource.file.url = `files://${resource.pk}`;
				resource.file.path = undefined;
				resource.globalResourceId = undefined;

				files.set(resource.pk, file);
			},
		)) {
		}

		return files;
	}
}
