import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import type { Chunk } from "base/world/block/chunk/Chunk";
import type { IChunkGenerator } from "./types";
import type { StaticObjectPlacerOptions } from "@jamango/content-client/lib/types/terrainGeneration.ts";
import { ModelBuilder } from "./ModelBuilder";

type ObjectData = {
	type: number;
	object: StaticObjectPlacerOptions["objects"][number];
	x: number;
	y: number;
	z: number;
};

export class GeneratorStaticObjectPlacer implements IChunkGenerator {
	static name = "bb.generator.staticobjectplacer" as const;
	static display = "StaticObjectPlacer" as const;

	private tcache = new LegacyVectorMap();

	private objects: StaticObjectPlacerOptions["objects"] = [];

	private modelBuilder?: ModelBuilder;

	constructor(options: StaticObjectPlacerOptions) {
		this.objects = options.objects;
	}

	init({ chunkSize }: { seed: number; blockTypeRegistry: BlockTypeRegistryState; chunkSize: number }) {
		this.tcache = new LegacyVectorMap(); //tree cache

		this.modelBuilder = new ModelBuilder(chunkSize);
		this.modelBuilder.buildObjects(this.objects);

		for (let i = 0; i < this.objects.length; i++) {
			const obj = this.objects[i];
			const m = this.modelBuilder.models[i];

			obj.positions.forEach(([x, y, z]) => {
				const minX = Math.floor((x - m.modelBounds.min.x) / chunkSize);
				const minY = Math.floor((y - m.modelBounds.min.y) / chunkSize);
				const minZ = Math.floor((z - m.modelBounds.min.z) / chunkSize);
				const maxX = Math.floor((x + m.modelBounds.max.x) / chunkSize);
				const maxY = Math.floor((y + m.modelBounds.max.y) / chunkSize);
				const maxZ = Math.floor((z + m.modelBounds.max.z) / chunkSize);

				for (let cz = minZ; cz <= maxZ; cz++) {
					for (let cx = minX; cx <= maxX; cx++) {
						for (let cy = minY; cy <= maxY; cy++) {
							let t = this.tcache.get(cx, cy, cz) as ObjectData[] | undefined;

							if (!t) {
								t = [];

								this.tcache.set(cx, cy, cz, t);
							}

							t.push({
								type: i,
								object: obj,
								x: x - cx * chunkSize,
								y: y - cy * chunkSize,
								z: z - cz * chunkSize,
							});
						}
					}
				}
			});
		}
	}

	apply(chunk: Chunk) {
		const cx = chunk.position.x;
		const cy = chunk.position.y;
		const cz = chunk.position.z;

		const objects = this.getObjectData(cx, cy, cz);

		if (objects) {
			objects.forEach((objectData) => {
				this.modelBuilder?.models[objectData.type].build(
					chunk,
					objectData.x,
					objectData.y,
					objectData.z,
					false,
					objectData,
				);
			});
		}
	}

	private getObjectData(cx: number, cy: number, cz: number): ObjectData[] | undefined {
		return this.tcache.get(cx, cy, cz);
	}

	clearCache() {
		this.tcache.clear();
	}
}
