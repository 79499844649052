export class ChunkInvokerComponent {
	constructor(o) {
		this.name = "chunkInvoker";

		this.def = {
			enableForType: false,
		};

		// can use either infinity or undefined here for initial value to be non-zero
		// went for infinity because its type correct
		this.state = {
			lastX: Infinity,
			lastY: Infinity,
			lastZ: Infinity,
		};
	}
}
