import type { TerrainGenerationOptions } from "@jamango/content-client/lib/types/terrainGeneration.ts";
import {
	GeneratorBlankTerrain,
	GeneratorMVRTerrain,
	GeneratorTree,
	GeneratorPerlinNoiseObjectPlacer,
	GeneratorStaticObjectPlacer,
	GeneratorCustomTerrain,
} from "./index";
import type { IChunkGenerator } from "./types";

export function createTerrainGenerator(options?: TerrainGenerationOptions | null) {
	if (!options || options.type === "blank") return [new GeneratorBlankTerrain({ revision: 1 })];

	if (options.type === "mvr") {
		const terrain = new GeneratorMVRTerrain({ revision: 1 });
		const waterHeight = terrain.rivery;
		const treeGenerator = new GeneratorTree({
			revision: 2,
			minHeight: waterHeight + 1,
			maxHeight: terrain.amp.VR,
		});
		return [terrain, treeGenerator];
	}

	if (options.type === "custom") {
		const terrain = new GeneratorCustomTerrain(options);

		const generators: IChunkGenerator[] = [terrain];

		options.objectsPlacers?.forEach((placerOptions) => {
			if (placerOptions.type === "perlinNoise") {
				const objectPlacerGenerator = new GeneratorPerlinNoiseObjectPlacer(placerOptions);
				generators.push(objectPlacerGenerator);
			} else if (placerOptions.type === "static") {
				const objectPlacerGenerator = new GeneratorStaticObjectPlacer(placerOptions);
				generators.push(objectPlacerGenerator);
			}
		});

		return generators;
	}

	return [];
}
