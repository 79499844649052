import { isNullish } from "@jamango/helpers";
import { Vector3 } from "three";

export class CameraSettingsComponent {
	name = "cameraSettings";

	def: {
		distanceScale: number | null;
		itemSwayMaxDisplacement: number | null;
		offset: Vector3;
		camera: any | undefined;
	};

	constructor(o: any) {
		this.def = {
			distanceScale: null,
			itemSwayMaxDisplacement: null,
			offset: new Vector3(),
			camera: undefined,
		};

		if (!isNullish(o?.camera)) {
			this.def.camera = structuredClone(o.camera);

			if (o.camera.maxDst === -1) {
				this.def.camera.maxDst = Infinity;
			}
		} else {
			this.def.camera = {};
		}
	}
}
