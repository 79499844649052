export const ASSET_NAME = {
	audios: "audio",
	avatarComponents: "Avatar Components",
	avatars: "Avatars",
	blockTextures: "Block Textures",
	blocks: "blocks",
	characters: "Characters",
	customLoader: "Custom Loader",
	environmentPreset: "Environment Preset",
	gameMechanics: "Game Mechanics",
	map: "Map",
	settings: "Settings",
	skybox: "Skybox",
	tags: "Tags",
	thumbnail: "Thumbnail",
	worldData: "World Data",
};
