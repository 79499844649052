import {
	LinearFilter,
	Euler,
	Vector3,
	type SkinnedMesh,
	type Skeleton,
	type Material,
	type Mesh,
	type MeshBasicMaterial,
	type Object3D,
	type Texture,
	type Bone,
} from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import { PI } from "base/util/math/Math.ts";
import { createLogger } from "@jamango/helpers";
import {
	ACCESSORY_NODES,
	AVATAR_NODES,
	BODY_NODES,
	BODY_TYPE_VALUE,
	DEFAULT_BODY_PARTS,
	getAvatarBodyPart,
	getStaticFile,
	type BodyTypeValue,
	type IAvatarConfig,
	type IAvatarObject,
} from "@jamango/content-client";
import * as Resources from "client/Resources";
import { AnimatableMesh } from "client/world/entity/util/AnimatableMesh.js";

const logger = createLogger("Avatar");

type AvatarNode = keyof typeof AVATAR_NODES;

export class Avatar extends AnimatableMesh {
	skin: { name: string; color: string | null } = {
		name: "Skin_Mat",
		color: null,
	};

	hair: { name: string; color: string | null } = {
		name: "Hairs_GRP",
		color: null,
	};

	bodyType: BodyTypeValue;

	skeletons = {} as Record<string, Skeleton>;
	faceMaterial: MeshBasicMaterial | null = null;
	hairMaterial: MeshBasicMaterial | null = null;
	skinMaterials = new Set<Material>();

	constructor(
		readonly avatarAssets: Map<string, any>,
		readonly avatarAssetPromises: Map<string, any>,
		readonly initialConfig: IAvatarConfig = DEFAULT_BODY_PARTS as any as IAvatarConfig,
		POV: "TP" | "FP" = "TP",
		readonly avatarObject?: IAvatarObject,
	) {
		const bodyType = initialConfig.bodyType ?? BODY_TYPE_VALUE.BODY_TYPE_1;

		if (POV === "TP") super(getTPMeshDef(bodyType.slice(-1)), avatarAssets);
		else super(getFPMeshDef(bodyType.slice(-1)), avatarAssets);

		this.bodyType = bodyType;

		this.#initSkeleton();
	}

	#initSkeleton() {
		this.mesh.traverse((node) => {
			const skeleton = (node as SkinnedMesh).skeleton;
			if (skeleton) {
				switch (node.name) {
					case "Arms_GEO":
						// ARMS
						this.skeletons["Arms_GRP"] = skeleton;
						this.skeletons["Arms_GEO"] = skeleton;
						this.skeletons["Arms_GEO_1"] = skeleton;
						this.skeletons["Arms_GEO_2"] = skeleton;
						break;
					case "Legs_GRP":
						this.skeletons["Legs_GRP"] = skeleton;
						this.skeletons["Legs_GEO"] = skeleton;
						this.skeletons["Legs_GEO_1"] = skeleton;
						break;
					case "Torsos_GRP":
						// TORSO
						this.skeletons["Torsos_GRP"] = skeleton;
						this.skeletons["Torsos_GEO"] = skeleton;
						this.skeletons["Torsos_GEO_1"] = skeleton;
						this.skeletons["Torsos_GEO_2"] = skeleton;
						// BACKPACK
						this.skeletons["Backpacks_GRP"] = skeleton;
						this.skeletons["Backpacks_GEO"] = skeleton;
						this.skeletons["Backpacks_GEO_1"] = skeleton;
						this.skeletons["Backpacks_GEO_2"] = skeleton;
						break;
					case "Heads_GEO":
						// HEAD
						this.skeletons["Heads_GRP"] = skeleton;
						this.skeletons["Heads_GEO"] = skeleton;
						this.skeletons["Heads_GEO_1"] = skeleton;
						this.skeletons["Heads_GEO_2"] = skeleton;
						// HAT
						this.skeletons["Hats_GRP"] = skeleton;
						this.skeletons["Hats_GEO"] = skeleton;
						this.skeletons["Hats_GEO_1"] = skeleton;
						this.skeletons["Hats_GEO_2"] = skeleton;
						// MASK
						this.skeletons["Masks_GRP"] = skeleton;
						this.skeletons["Masks_GEO"] = skeleton;
						this.skeletons["Masks_GEO_1"] = skeleton;
						this.skeletons["Masks_GEO_2"] = skeleton;
						// HAIR
						this.skeletons["Hairs_GRP"] = skeleton;
						this.skeletons["Hairs_GEO"] = skeleton;
						this.skeletons["Hairs_GEO_1"] = skeleton;
						break;
					default:
						this.skeletons[node.name] = skeleton;
				}
			}
		});
	}

	dispose() {
		for (const m of this.skinMaterials) m.dispose();
		this.hairMaterial?.dispose();
		this.faceMaterial?.dispose();

		for (const s in this.skeletons) this.skeletons[s].dispose();
		super.dispose();
	}

	private async fetchFromAvatarAssets(path: string) {
		const url = getStaticFile(path);
		const initialURL = new URL(url);
		const extension = initialURL.pathname.split(".").pop() ?? "";
		const isModel = ["gltf", "glb"].includes(extension);

		let asset;

		if (this.avatarAssets.has(url)) {
			asset = this.avatarAssets.get(url);
		} else {
			const inProgress = this.avatarAssetPromises.get(url);
			if (inProgress) {
				asset = await inProgress;
			} else {
				asset = await this.downloadNewAsset(url, isModel);
			}
		}

		//always return a clone because the avatar editor makes edits
		if (isModel) {
			return clone(asset.scene);
		}

		return asset.clone();
	}

	private async downloadNewAsset(url: string, isModel: boolean) {
		const assetPromise = isModel ? Resources.gltfLoadAsync(url, false) : Resources.textureLoadAsync(url);

		this.avatarAssetPromises.set(url, assetPromise);
		const asset = await assetPromise;
		this.avatarAssetPromises.delete(url);
		this.avatarAssets.set(url, asset);

		return asset;
	}

	async loadAvatarFromConfig(currentConfig: IAvatarConfig = this.initialConfig, isCostume = false) {
		if (!currentConfig) throw Error("MISSING AVATAR CONFIG");

		const avatarUpdater = [];
		const alwaysVisibleNodes: AvatarNode[] = ["legs", "arms", "torso"];

		for (const k in currentConfig) {
			const key = k as keyof IAvatarConfig;

			const bodyPartURL = getAvatarBodyPart(this.bodyType, currentConfig[key] as any);

			if (key === "bodyType" || key === "skinColor" || key === "hairColor") {
				continue;
			} else if (isCostume && key === "hair" && (bodyPartURL !== "EMPTY" || !bodyPartURL)) {
				avatarUpdater.push(this.returnNodeToDefault(key));
			} else {
				if (!bodyPartURL || bodyPartURL === "EMPTY" || bodyPartURL?.includes("hair00")) {
					if (
						!alwaysVisibleNodes.includes(key) ||
						bodyPartURL === "EMPTY" ||
						bodyPartURL?.includes("hair00")
					) {
						avatarUpdater.push(this.removeAccessory(key));
					} else {
						avatarUpdater.push(this.returnBodyPartToDefault(key, currentConfig));
					}
				} else {
					const hat = currentConfig.hat;
					if (key === "hat" && typeof hat === "object" && hat !== null && hat.removeHairOnUse) {
						avatarUpdater.push(this.removeAccessory("hair"));
					}
					avatarUpdater.push(this.applyAccessory(key, bodyPartURL));
				}
			}
		}

		try {
			await Promise.all(avatarUpdater);
		} catch (error) {
			logger.error(error);
		}

		this.applyPostProcessing();

		this.changeHairColor(currentConfig.hairColor);
		this.changeSkinColor(currentConfig.skinColor);

		return this;
	}

	async loadFPAvatarFromConfig(currentConfig = this.initialConfig) {
		const bodyPartURL = currentConfig.arms?.[currentConfig.bodyType];

		if (!bodyPartURL) {
			await this.returnBodyPartToDefault("arms", currentConfig);
		} else {
			await this.applyAccessory("arms", currentConfig.arms[currentConfig.bodyType]);
		}

		this.applyPostProcessing();

		this.changeSkinColor(currentConfig.skinColor);
		return this;
	}

	private swapBodyParts(nodeName: string, newPart: Object3D, targetAttachment: Object3D) {
		const targetBodyNode = newPart.getObjectByName(nodeName); // find target node by name on accessory glb file
		const oldBodyNode = targetAttachment.getObjectByName(nodeName); // find corresponding target node by name on character glb file
		this.handleNodeError(targetBodyNode, nodeName);
		this.handleAccessoryNodeSkeleton(targetBodyNode);

		const parentNode = this.removeAccessoryNode(oldBodyNode, nodeName);
		if (parentNode && targetBodyNode) {
			this.addAccessoryNode(parentNode, targetBodyNode);
		}
	}

	async applyAccessory(bodyPart: AvatarNode, sourceUrl: string | null | undefined) {
		const modelNodeName = AVATAR_NODES[bodyPart]; // get the node name for the customised part as it appears in the 3D file

		if (bodyPart === "face") {
			if (sourceUrl) {
				await this.changeFace(sourceUrl);
			} else {
				await this.changeFace(DEFAULT_BODY_PARTS[bodyPart][this.bodyType]);
			}
			return;
		}

		if (bodyPart === "hair" && sourceUrl === "EMPTY") {
			this.removeAccessory("hair");
			return;
		}

		if (!sourceUrl) return;

		const glb = await this.fetchFromAvatarAssets(sourceUrl);

		if (glb.getObjectByName("Heads_GRP") && bodyPart === "mask") {
			this.swapBodyParts("Heads_GRP", glb, this.mesh); // REAPPLY HAIR , FACE TEXTURE AND SKIN COLOR
		} else {
			this.swapBodyParts(modelNodeName, glb, this.mesh);
		}

		if (bodyPart === "hair") {
			this.changeHairColor(this.hair.color); // REAPPLY THE HAIR COLOR
		}

		this.applyPostProcessing();
		this.changeSkinColor(this.skin.color);
	}

	async removeAccessory(bodyPart: AvatarNode) {
		const modelNodeName = AVATAR_NODES[bodyPart]; // get the node name for the customised part as it appears in the 3D file

		if (bodyPart === "face") {
			await this.changeFace("");
			return;
		} else {
			const targetNodeOnCharacter = this.mesh.getObjectByName(modelNodeName);
			if (!targetNodeOnCharacter) return;
			this.removeAccessoryNode(targetNodeOnCharacter, modelNodeName);
		}
	}

	// faceTexture = url of the PNG texture image
	async changeFace(faceTexture: string | null = null) {
		const faceNode = this.mesh.getObjectByName(AVATAR_NODES.face) as Mesh | undefined;

		if (!faceNode) throw Error("FaceNode not found");

		this.faceMaterial?.dispose();
		const faceMaterial =
			(this.faceMaterial =
			faceNode.material =
				(faceNode.material as MeshBasicMaterial).clone());

		if (!faceMaterial) throw Error("No face material found");

		let texture: Texture;
		if (faceTexture) {
			texture = await this.fetchFromAvatarAssets(faceTexture);
		} else {
			texture = await this.fetchFromAvatarAssets(DEFAULT_BODY_PARTS.face[this.bodyType]);
		}

		faceMaterial.map = texture;
		faceMaterial.map.minFilter = LinearFilter;
		faceMaterial.map.magFilter = LinearFilter;
		faceMaterial.map.flipY = false;
		faceMaterial.opacity = 1.0;

		faceMaterial.needsUpdate = true;
		faceMaterial.alphaTest = 0.5;
	}

	changeSkinColor(color: string | null) {
		this.skin.color = color;

		for (const mat of this.skinMaterials) mat.dispose();
		this.skinMaterials.clear();

		// TRAVERSE AND GET ALL SKIN MATERIAL AND REAPPLY COLOR
		this.mesh.traverse((object) => {
			const mesh = object as Mesh;

			if (!mesh.isMesh) return;

			let material = mesh.material as MeshBasicMaterial;
			if (material.name === this.skin.name) {
				material = mesh.material = material.clone();
				material.color.set(color as any);
				this.skinMaterials.add(material);
			}
		});
	}

	changeHairColor(colorHex: string | null) {
		this.hair.color = colorHex;

		this.hairMaterial?.dispose();

		const hairColorNode = this.mesh.getObjectByName(this.hair.name) as Mesh;

		if (!hairColorNode) return;

		const hairMaterial =
			(this.hairMaterial =
			hairColorNode.material =
				(hairColorNode.material as MeshBasicMaterial).clone());

		hairMaterial.color.set(this.hair.color as any);
	}

	private applyPostProcessing() {
		this.disableFrustumCulling();
		this.mesh.traverse(function (node) {
			if ((node as Bone).isBone) return;

			node.matrixAutoUpdate = false;
			node.updateMatrix();
		});
	}

	/******************** 
        NODE METHOD RELATED 
    ******************/

	private async returnNodeToDefault(currentBodyPart: AvatarNode) {
		if (
			currentBodyPart === "backpack" ||
			currentBodyPart === "hat" ||
			currentBodyPart === "mask" ||
			currentBodyPart === "head_type"
		)
			return;

		const defaultValue = DEFAULT_BODY_PARTS[currentBodyPart][this.bodyType];
		if (currentBodyPart === "face") {
			await this.changeFace(defaultValue);
			return;
		}

		const glb = await this.fetchFromAvatarAssets(defaultValue);

		// get the node name for the part as it appears in the 3D file
		const modelNodeName = AVATAR_NODES[currentBodyPart];

		this.swapBodyParts(modelNodeName, glb, this.mesh);
	}

	async returnBodyPartToDefault(currentBodyPart: AvatarNode, currentConfig: IAvatarConfig) {
		await this.returnNodeToDefault(currentBodyPart);

		if (currentBodyPart === "torso") {
			await this.returnNodeToDefault("arms");
		} else if (currentBodyPart === "head") {
			// WE REAPPLY THE FACE TEXTURE IN CASE THE HEAD GETS SWAPPED SO WE STILL HAVE THE FACE TEXTURE FROM BEFORE
			const faceTextureURL = currentConfig["face"][this.bodyType];
			if (faceTextureURL) {
				await this.changeFace(faceTextureURL);
			}
		} else if (currentBodyPart === "hair") {
			this.changeHairColor(null);
		}
	}

	private handleNodeError(newNode: Object3D | undefined, nodeName: string) {
		if (!newNode) {
			logger.error(
				"No node found for the target node. Avatar component must be missing the node group.",
			);
			logger.error(
				"No node called",
				nodeName,
				"found on accessory. Expecting a node called",
				nodeName,
				"on both accessory and character",
			);
			return;
		}
	}

	private addAccessoryNode(parent: Object3D, node: Object3D) {
		parent.add(node);
	}

	private removeAccessoryNode(node: Object3D | undefined, modelNodeName: string) {
		let parent = null;
		if (!node) {
			if (ACCESSORY_NODES.includes(modelNodeName) && modelNodeName !== "Heads_GRP") {
				parent = this.mesh.getObjectByName("Accessories_GRP");
			} else if (BODY_NODES.includes(modelNodeName)) {
				parent = this.mesh.getObjectByName("Emmet_Bendy");
			}
		} else if (ACCESSORY_NODES.includes(node.name) && node.name !== "Heads_GRP") {
			parent = this.mesh.getObjectByName("Accessories_GRP");
			parent?.remove(node);
		} else if (BODY_NODES.includes(node.name)) {
			parent = this.mesh.getObjectByName("Emmet_Bendy");
			parent?.remove(node);
		}

		return parent;
	}

	private handleAccessoryNodeSkeleton(targetAccessoryNode: Object3D | undefined) {
		if (!targetAccessoryNode) return;

		const nodes = [];
		if (targetAccessoryNode.children && targetAccessoryNode.children.length) {
			nodes.push(...targetAccessoryNode.children);
		} else {
			nodes.push(targetAccessoryNode);
		}

		for (const node in nodes) {
			// replace skeleton in the model from the component
			if ((nodes[node] as SkinnedMesh).isSkinnedMesh) {
				(nodes[node] as SkinnedMesh).skeleton = this.skeletons[nodes[node].name];
			}
		}
	}
}

function getAvatarAssetId(shortURL: string, type: string) {
	return `mdl-character-default-type${type}-${shortURL}`;
}

function getTPMeshDef(type: string) {
	const ret = {
		asset: getAvatarAssetId("tp-geom", type),
		transform: { rot: new Euler(0, PI, 0, "XZY"), scl: 1.8 },
		animated: true,
		animationSources: [getAvatarAssetId("tp-anim", type)],
	};

	return ret;
}

function getFPMeshDef(type: string) {
	const ret = {
		asset: getAvatarAssetId("fp-geom", type),
		transform: {
			pos: new Vector3(0, -1.38, -0.15),
			rot: new Euler(0, PI, 0, "XZY"),
		},
		animated: true,
		animationSources: [getAvatarAssetId("fp-anim", type)],
	};

	return ret;
}
