import { getScriptEvents } from "base/rete/Editor.js";
import { NODE } from "base/rete/InternalNameMap";
import { defs, triggers } from "base/rete/Nodes";
import { NODE_THIS_BLOCK } from "base/rete/NodeSharedUtil";
import { BlockCollisionBodyPart } from "base/world/entity/component/CharacterCollision";
import { makeClosure } from "./interpreter";
import type {
	ParsedNode,
	ParsedNodeExec,
	ParsedNodeInput,
	InterpreterScope,
	EntryPoint,
	NodeDef,
	TriggerContext,
	ReteState,
	ParsedScript,
} from "base/rete/Types";
import type { IScript } from "@jamango/content-client";

const parseInput = (
	step: ParsedNodeExec | ParsedNodeInput,
	inputs: ParsedNodeInput[],
	rete: ReteState,
	entry: ParsedNodeExec,
	stack: any[],
): void => {
	const id = step.node.id;
	if (!id) return;

	// find all non-exec nodes that end in this node (input values)
	rete.targets.get(id)?.forEach((connection) => {
		const { targetInput, sourceOutput, source } = connection;
		if (targetInput !== "exec") {
			// push step into chain of inputs
			const { node, name } = resolveNode(source, rete);
			if (node === undefined) return;
			// ensure this target input exists. rete data can get corrupted unfortunately
			inputs.push({ node, name, targetInput, sourceOutput, prev: [] });
		}
	});

	// recursively continue
	inputs.forEach((c) => parseInput(c, c.prev, rete, entry, stack));
};

// Parses execution flow for a step in the Rete graph
const parseExec = (
	step: ParsedNodeExec,
	rete: ReteState,
	entry: ParsedNodeExec,
	stack: any[],
): ParsedNodeExec => {
	const id = step.node.id;
	if (!id) return step;

	// find all nodes that are triggered by this node (exec flow)
	rete.sources.get(id)?.forEach((connection) => {
		const { targetInput, sourceOutput, target } = connection;
		if (targetInput === "exec") {
			const { node, name, script } = resolveNode(target, rete);
			if (node === undefined) return;
			// nodes can have different execution outputs for control flow. for example "then" and "else" for if-condition
			if (step.exec[sourceOutput] === undefined) step.exec[sourceOutput] = [];
			step.exec[sourceOutput].push({ node, name, exec: {}, inputs: [], script });
		}
	});

	// find all non-exec nodes that end in this node (input values)
	parseInput(step, step.inputs, rete, entry, stack);

	// we now have all next exec steps and inputs. we can recursively iterate those
	for (const id in step.exec) step.exec[id].forEach((s) => parseExec(s, rete, entry, stack));

	return step;
};

// Resolves a node and some contextual info by its ID
const resolveNode = (id: string, rete: ReteState) => {
	// utility for quick lookup, gathering name and script reference
	const node = rete.nodes.get(id);
	if (!node) throw `Could not resolve node: ${id}`;
	const def = defs.get(node.defId);
	if (node.custom) {
		const script = rete.scripts.get(node.defId);
		const name = `Script Entry: ${node.defId}`;
		return { def, node, name, script };
	} else {
		return { def, node, name: def?.name || node.defId };
	}
};

/**
 * Gathers script information for parsing
 */
export const gather = (rete: ReteState, unparsedScripts: IScript[]): void => {
	const { nodes, sources, targets, scripts } = rete;

	// we pre-gather all script info for parsing usage
	for (const script of unparsedScripts) {
		const events = getScriptEvents(script);

		// enriching the data with some relevant info for parser
		// TODO: this may as well just be done on the content exporter
		scripts.set(script.pk, {
			...events,
			enabled: script.enabled,
			entryId: events.entryPoints[0],
			entryNodes: {},
		});
	}

	// gather some node information and store it on the rete object for quicker lookups
	for (const [scriptId, script] of scripts) {
		try {
			for (const id in script.nodes) {
				const node = script.nodes[id];
				const def = defs.get(node.defId);

				// inputs and outputs can come from different places. combine this for simplicity
				let inTypes: Record<string, any>;
				if (node.custom) {
					// custom script reference nodes get inputs from entry node of that script
					const targetScript = scripts.get(node.defId);
					if (targetScript === undefined) throw "Unknown Custom Script: " + node.defId;
					inTypes = targetScript.nodes[targetScript.entryId]?.def?.inputs || {}; // entry id not existing as a node should be ok
				} else {
					if (def === undefined) throw "Unknown Node Def: " + node.defId;
					// regular nodes merge input def and local def inputs (dynamic)
					inTypes = { ...def.inputs, ...node.def?.inputs };
				}
				nodes.set(id, { ...node, inTypes });
			}

			script.connections.forEach((c) => {
				if (!sources.has(c.source)) sources.set(c.source, []);
				if (!targets.has(c.target)) targets.set(c.target, []);
				sources.get(c.source)!.push(c);
				targets.get(c.target)!.push(c);
			});
		} catch (e: any) {
			console.error(`Error while pre-parsing script ${scriptId}`);
			console.error(e);
			rete.compilationLogs.push({
				script: scriptId,
				type: "error",
				message: e.toString(),
			});
		}
	}
};

/**
 * Parses scripts into graphs
 */
export const parseScripts = (rete: ReteState): void => {
	// we parse scripts into graphs, these do not yet care about attachments
	for (const [scriptId, script] of rete.scripts) {
		try {
			for (const id in script.nodes) {
				const { node, name, def } = resolveNode(id, rete);
				// identify all trigger nodes. those can be considered "entry points"
				const isTrigger = triggers.has(def?.type as any); // wow typescript very cool
				const isStart = node.defId === NODE.Start;
				const isCustomExit = node.custom !== undefined;
				if (isTrigger || isStart || isCustomExit) {
					// parse from the entry point. we store whether this entry point ends up being predictable by checking for nodes that are unpredictable
					const entry = { node, name, exec: {}, inputs: [] } as ParsedNodeExec;
					parseExec(entry, rete, entry, []);
					script.entryNodes[id] = entry;
				}
			}
		} catch (e: any) {
			console.error(`Error while parsing script ${scriptId}`);
			console.error(e);
			rete.compilationLogs.push({
				script: scriptId,
				type: "error",
				message: e.toString(),
			});
		}
	}
};

/**
 * Creates attachments for scripts to entities or block groups
 */
export const createAttachment = (
	rete: ReteState,
	script: ParsedScript,
	targetId: string,
	controls: Record<string, any> | undefined,
	type: string,
	scope: InterpreterScope,
	depth: number,
): void => {
	if (script === undefined) return;
	if (!script.enabled) return;
	if (depth >= 100) throw "Maximum attachment recursion depth reached: " + depth;

	// given the block group/type and the targeted script, iterate that scripts relevant entry points
	for (const [_, entry] of Object.entries(script.entryNodes)) {
		// we check if this particular entry point is relevant for the attachment
		const def = defs.get(entry.node.defId);
		if (def !== undefined && triggers.has(def?.type as any)) {
			// clone entry point and enrich it with attachment data
			const clone = { ...entry, targetId, type, controls, scope };
			// add trigger entry
			parseTrigger(rete, targetId, clone, def, controls);
		} else if (entry.node.custom) {
			// if entry point is a custom node, recursively scan for attachments while inheriting scope
			const childScript = rete.scripts.get(entry.node.defId);
			if (childScript === undefined) throw "Child script not found";
			const parentClosure = makeClosure(entry, { parentClosure: scope?.parentClosure, controls }, true);
			createAttachment(
				rete,
				childScript,
				targetId,
				entry.node.data,
				type,
				{ parentClosure },
				depth + 1,
			);
		}
	}
};

/**
 * Resolves trigger data for a node
 */
function resolveTriggerData(
	rete: ReteState,
	node: ParsedNode,
	targetId: string,
	controls: Record<string, any> | undefined,
): Record<string, any> {
	// check for connections that end inside of this node
	const data = structuredClone(node.data || {});
	rete.targets.get(node.id)?.forEach((connection) => {
		const { targetInput, sourceOutput, source } = connection;
		const sourceNode = rete.nodes.get(source);
		if (sourceNode === undefined || sourceNode.defId !== NODE.Controls) return;
		if (controls === undefined) throw "Trying to resolve trigger data from nonexistant control node";
		// if we are reading from a controls node, overwrite the final data
		data[targetInput] = controls[sourceOutput];
	});

	// resolve "group" input field
	if (data.group === undefined || data.group === NODE_THIS_BLOCK) data.group = targetId;
	return data;
}

/**
 * Parses a trigger node and adds it to the appropriate trigger list
 */
const parseTrigger = (
	rete: ReteState,
	targetId: string,
	entryPoint: EntryPoint,
	def: NodeDef,
	controls: Record<string, any> | undefined,
): void => {
	const triggers = rete.triggers; // Using any because the trigger structure is complex
	const data = resolveTriggerData(rete, entryPoint.node, targetId, controls);
	const name = data.name;

	const trigger: TriggerContext = { entryPoint, data };

	// Handle different trigger types
	if (def.id === NODE.OnGameStart) {
		triggers.onGameStart.push(trigger);
	} else if (def.id === NODE.OnGameTick) {
		triggers.onGameTick.push(trigger);
	} else if (def.id === NODE.OnWorldStateChange) {
		// on world state change
		if (triggers.onWorldStateChange[name] === undefined) triggers.onWorldStateChange[name] = [];
		triggers.onWorldStateChange[name].push(trigger);
	} else if (def.id === NODE.OnEntityStateChange) {
		// on entity state change
		if (triggers.onEntityStateChange[name] === undefined) triggers.onEntityStateChange[name] = [];
		triggers.onEntityStateChange[name].push(trigger);
	} else if (def.id === NODE.OnPeerPersistentStateChange) {
		// on peer persistent state change
		if (triggers.onPeerPersistentStateChange[name] === undefined)
			triggers.onPeerPersistentStateChange[name] = [];
		triggers.onPeerPersistentStateChange[name].push(trigger);
	} else if (def.id === NODE.OnChatSent) {
		// chat triggers: extract the trigger key (first string)
		const key = name.split(" ")[0];
		// extract the {keys}
		const parse = name.match(/{(\w+)}/g);
		// if no regex result, there are no keys. return empty array
		const keys = parse !== null ? parse.map((k: string) => k.slice(1, -1)) : [];
		if (triggers.onChat[key] === undefined) triggers.onChat[key] = [];
		triggers.onChat[key].push({ ...trigger, keys });
	} else if (def.id === NODE.OnSoundEnd) {
		triggers.onSoundEnd.push(trigger);
	} else if (
		//block triggers
		def.id === NODE.OnInteractWithBlock ||
		def.id === NODE.OnBlockSculpt ||
		def.id === NODE.OnBlockBreak ||
		def.id === NODE.OnBlockBuild ||
		def.id === NODE.OnBlockSpawn ||
		def.id === NODE.OnBlockZoneEnter ||
		def.id === NODE.OnBlockZoneLeave ||
		def.id === NODE.OnProjectileHitBlock
	) {
		const triggerKey = data.group;
		triggers.onBlock[def.id] ??= {};
		triggers.onBlock[def.id][triggerKey] ??= [];
		triggers.onBlock[def.id][triggerKey].push(trigger);
	} else if (def.id === NODE.OnBlockCollisionStart || def.id === NODE.OnBlockCollisionEnd) {
		const bodyPartInput = data.bodyPart ?? BlockCollisionBodyPart.any; //older worlds do not have this data
		const bodyParts =
			bodyPartInput === BlockCollisionBodyPart.any
				? Object.values(BlockCollisionBodyPart).filter((e) => e !== BlockCollisionBodyPart.any)
				: [bodyPartInput];

		const triggerKey = data.group;
		for (const bodyPart of bodyParts) {
			const listenerName = `${bodyPart}${triggerKey}`;
			triggers.onBlock[def.id] ??= {};
			triggers.onBlock[def.id][listenerName] ??= [];
			triggers.onBlock[def.id][listenerName].push(trigger);
		}
	} else if (def.id === NODE.OnAllPlayerFeetEnter) {
		const listenerName = `${BlockCollisionBodyPart.foot}${data.group}`;

		triggers.onBlock[NODE.OnBlockCollisionStart] ??= {};
		triggers.onBlock[NODE.OnBlockCollisionStart][listenerName] ??= [];
		triggers.onBlock[NODE.OnBlockCollisionStart][listenerName].push(trigger);

		triggers.onBlock[NODE.OnBlockCollisionEnd] ??= {};
		triggers.onBlock[NODE.OnBlockCollisionEnd][listenerName] ??= [];
		triggers.onBlock[NODE.OnBlockCollisionEnd][listenerName].push(trigger);
	} else if (def.id === NODE.OnControlPress || def.id === NODE.OnControlRelease) {
		// Control press triggers
		const pressType = def.id === NODE.OnControlPress ? "press" : "release";
		const combo = name.replace(/\s/g, "");
		if (triggers.onControl[combo] === undefined) triggers.onControl[combo] = {};
		if (triggers.onControl[combo][pressType] === undefined) triggers.onControl[combo][pressType] = [];
		triggers.onControl[combo][pressType].push(trigger);
	} else if (def.id === NODE.OnPeerJoin) {
		triggers.onPeerJoin.push(trigger);
	} else if (def.id === NODE.OnPeerLeave) {
		triggers.onPeerLeave.push(trigger);
	} else if (def.id === NODE.OnItemUse) {
		const triggerKey = data.item;
		triggers.onItemUse[triggerKey] ??= [];
		triggers.onItemUse[triggerKey].push(trigger);
	} else if (def.id === NODE.OnItemUnuse) {
		const triggerKey = data.item;
		triggers.onItemUnuse[triggerKey] ??= [];
		triggers.onItemUnuse[triggerKey].push(trigger);
	} else if (def.id === NODE.OnItemRangedWeaponFire) {
		const triggerKey = data.item;
		triggers.onItemRangedWeaponFire[triggerKey] ??= [];
		triggers.onItemRangedWeaponFire[triggerKey].push(trigger);
	}
};
