import { Mesh } from "three";

export class ShadowMesh extends Mesh {
	constructor(geometry, material) {
		super(geometry, material);
		ShadowMesh.make(this);
	}

	copy(...args) {
		return ShadowMesh.make(super.copy(...args));
	}

	static make(mesh) {
		mesh.castShadow = true;
		mesh.receiveShadow = true;
		return mesh;
	}
}
