import { netState } from "router/Parallelogram";
import { Vector3 } from "three";
import { CharacterMovementMode } from "base/world/entity/component/CharacterMovement";

const _positionCurrent = new Vector3();

/**
 * @param {import("base/world/entity/Character").Character} entity
 */
export function characterFootstepEffectsUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const isLanding = entity.collision.state.hitFoot && !entity.collision.state.prvHitFoot;

	if (isLanding) {
		onFootstep(entity);
		entity.characterFootsteps.state.footstepSoundPlayed = true;

		// change bob progress values such that footstep sounds will be evenly spaced
		// -1 = Math.sin(x)
		// x = (3 * Math.PI) / 2
		entity.movement.state.bobPhaseTime = (3 * Math.PI) / 2;
		entity.movement.state.bobSineValue = -1;
	}

	const bobSineValue = entity.movement.state.bobSineValue;
	const bobSineValueDelta = bobSineValue - entity.movement.state.bobSineValuePrevious;

	if (
		!entity.characterFootsteps.state.footstepSoundPlayed &&
		((bobSineValue <= -0.9 && bobSineValueDelta <= 0) || (bobSineValue <= -0.5 && bobSineValueDelta > 0))
	) {
		onFootstep(entity);
		entity.characterFootsteps.state.footstepSoundPlayed = true;
	} else if (bobSineValue >= 0 && entity.characterFootsteps.state.footstepSoundPlayed) {
		entity.characterFootsteps.state.footstepSoundPlayed = false;
	}

	entity.characterFootsteps.state.bobSineValuePrevious = bobSineValue;
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && !entity.mount.state.parent;
}

/**
 * Plays the footstep sound for the given entity and optionally plays a particle effect.
 *
 * @param {import("base/world/entity/Character").Character} entity
 */
export function onFootstep(entity) {
	if (
		!netState.isClient ||
		!entity.collision.state.footBlock.found ||
		entity.movement.def.mode === CharacterMovementMode.DYNAMIC
	) {
		return;
	}

	if (entity.movement.state.wishSpeed === 0 && entity.movement.state.isSlipperySurface) return;

	const world = entity.world;
	const isCameraTarget = entity === world.client.camera.target;

	const blockType = world.blockTypeRegistry.blockNameToType.get(entity.collision.state.footBlock.type);
	const footstepSound = blockType?.getFootstepSound();

	if (footstepSound) {
		if (isCameraTarget) {
			world.sfxManager.play(footstepSound);
		} else {
			world.sfxManager.playAtObj({ ...footstepSound, obj: entity });
		}
	}

	if (!isCameraTarget || !world.client.camera.is1stPerson() || entity.movement.state.isSprinting) {
		world.client.particleEngine.onPlayerWalk(entity.position, entity.uuid);
	}
}
