import { BB } from "base/BB";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import { BBClient } from "client/BB.js";
import { UI } from "client/dom/UI";
import { WorldRouter } from "router/world/World.js";
import { BBServerDedi } from "server/BB.js";
import { netState } from "router/Parallelogram";
import type { IEngineWorldData, WorldUserRole, JacyContent } from "@jamango/content-client";
import { ContentToDefConverter } from "base/content/ContentToDefConverter";
import type { World } from "base/world/World";
import { initBuiltInDefs } from "base/content/BuiltInContent";
import * as Net from "router/Net";

let netFlushInterval = null as null | Timer;

export class BBRouter {
	static async startWorld(content: JacyContent, role: WorldUserRole, mapBlob: ArrayBuffer | null) {
		const worldDef = new ContentToDefConverter().getWorldDef(content);
		worldDef.role = role;

		const world = await BBRouter.#createWorld(content, worldDef, true);

		if (mapBlob) {
			world.scene.loadMap(mapBlob, worldDef.terrainGenerationOptions, true);
		} else {
			world.scene.newMap({
				seed: null,
				generation: worldDef.terrainGenerationOptions,
			});
		}

		return world;
	}

	static async joinWorld(content: JacyContent, worldDef: IEngineWorldData) {
		const world = await BBRouter.#createWorld(content, worldDef, false);

		return world;
	}

	static async #createWorld(content: JacyContent, worldDef: IEngineWorldData, isHost: boolean) {
		let world: World | undefined = undefined;

		try {
			world = await BBRouter.attemptCreateWorld(worldDef, content, isHost);
		} catch (oops) {
			if (netState.isClient) {
				UI.state.errorNotification().setError(oops);
			}
			throw oops;
		}

		if (!world) {
			throw new Error("Could not create world for joining");
		}

		initBuiltInDefs(content);

		return world;
	}

	static isWorldDead() {
		// during the await periods inside attemptCreateWorld(), it's possible for the world to be disposed or lose connection to the game server
		return !BB.world;
	}

	static async attemptCreateWorld(o: IEngineWorldData, content: JacyContent, isHost: boolean) {
		//STAGE 0: dispose old world
		BBRouter.stopUpdateLoop();
		BB.world?.router.dispose();

		netState.isHost = isHost;

		//STAGE 1: construct new world (this is fast; no loading screen required)
		BB.world = new WorldRouter(o, content).base;

		//STAGE 2: download assets
		LoadingScreen.show(true);
		if (netState.isClient) await BBClient.createWorldStage2();

		//STAGE 3: initialize
		await LoadingScreen.setText("Firing up world");

		if (this.isWorldDead()) return;

		await BB.world.router.init();
		if (netState.isClient) await BBClient.createWorldStage3();

		if (this.isWorldDead()) return;

		BB.world.scene.postReset();

		return BB.world;
	}

	static isLoopActive = false;
	static time = 0;

	static initUpdateLoop() {
		if (netState.isClient) {
			//path for browser to use requestAnimationFrame/setTimeout
			BBClient.initUpdateLoop(BBRouter.update);
		} else {
			//path for node.js to use setInterval
			BBServerDedi.initUpdateLoop(BBRouter.update);
		}
	}

	//do not call synchronously inside world.update
	static startUpdateLoop() {
		BBRouter.isLoopActive = true;
		if (netFlushInterval) {
			clearInterval(netFlushInterval);
			netFlushInterval = null;
		}
	}

	static stopUpdateLoop() {
		BBRouter.isLoopActive = false;

		netFlushInterval = setInterval(() => {
			Net.flush();
		}, 1000 / 10);
	}

	static update() {
		const timeNow = performance.now() / 1000;
		const deltaTime = timeNow - BBRouter.time;
		BBRouter.time = timeNow;
		if (BBRouter.isLoopActive) {
			// subtract amount of steps, but keep fraction
			BB.world.router.update(deltaTime);
			if (netState.isClient) BBClient.update(deltaTime);
		}
	}

	static endWorld() {
		BBRouter.stopUpdateLoop();
		BB.world?.router.dispose();
		BB.world = null!; //allow gc
	}
}
