import { Group } from "three";
import { Text3D } from "client/world/fx/Text3D";
import { netState } from "router/Parallelogram";

export class Label extends Group {
	/**
	 * OPT Object label [interact label] - Text3D constructor options. scl is overwritten with 1 so use the below property instead
	 */
	constructor(world, o) {
		super();

		this.world = world;
		this.labelOptions = structuredClone(o?.label ?? null);
		this.text = "";
	}

	getText() {
		return this.text;
	}

	setText(text) {
		this.removeText();
		this.text = text;

		if (!text || !netState.isClient) return;

		this.label = new Text3D(this.world, this, this.labelOptions, text);
	}

	removeText() {
		if (this.label) {
			this.label.dispose();
			delete this.label;
		}
	}

	isEmpty() {
		return !this.label;
	}

	dispose() {
		this.removeText();
	}
}
