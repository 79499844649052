import type { Box3, BufferAttribute, BufferGeometry, InterleavedBuffer, Mesh, Vector3 } from "three";
import { InterleavedBufferAttribute } from "three";
export {
	Box3,
	Vector3,
	PerspectiveCamera,
	WebGLRenderer,
	HemisphereLight,
	DirectionalLight,
	Scene,
	Euler,
} from "three";

function disposeArray(this: BufferAttribute | InterleavedBuffer) {
	this.array = null as any;
}

export function freeRAMAfterAttributeUpload(attribute: BufferAttribute | InterleavedBufferAttribute) {
	if (attribute instanceof InterleavedBufferAttribute) {
		// @types/three doesn't have this but source code has:
		// https://github.com/mrdoob/three.js/blob/2898f5b1ba10b1e94174c0a62d072f5f7b80442c/src/core/InterleavedBuffer.js#L114
		(attribute.data as any as { onUpload: (callback: () => void) => void }).onUpload(disposeArray);
		return;
	}

	attribute.onUpload(disposeArray);
}

export function freeRAMAfterGeometryUpload(geometry: BufferGeometry, ignoreMorphTargets = false) {
	if (geometry.index) {
		freeRAMAfterAttributeUpload(geometry.index);
	}

	for (const i in geometry.attributes) {
		freeRAMAfterAttributeUpload(geometry.attributes[i]);
	}

	if (ignoreMorphTargets) return;

	for (const i in geometry.morphAttributes) {
		for (const attribute of geometry.morphAttributes[i]) {
			if (!attribute) continue;

			freeRAMAfterAttributeUpload(attribute);
		}
	}
}

export function freeRAMAfterMeshGeometryUpload(mesh: Mesh, ignoreMorphTargets = false) {
	mesh.traverse(function (node) {
		const mesh = node as Mesh;
		if (!mesh.isMesh) return;
		if (!mesh.geometry) return;

		freeRAMAfterGeometryUpload(mesh.geometry, ignoreMorphTargets);
	});
}

export const isPointOnEdgeOfBox = (bounds: Box3, point: Vector3) => {
	if (bounds.min.x === point.x || bounds.max.x === point.x) return true;
	if (bounds.min.y === point.y || bounds.max.y === point.y) return true;
	if (bounds.min.z === point.z || bounds.max.z === point.z) return true;
	return false;
};
