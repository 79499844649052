import { BB } from "base/BB";
import { DOMListener } from "client/dom/Listener.js";
import { UIPlayer } from "client/dom/Player.js";
import type { JamangoEngineOptions } from "@jamango/engine/EngineOptions.ts";

export class UI {
	static gameMultiplayer: NonNullable<JamangoEngineOptions["ui"]>["gameMultiplayer"] = null!;
	static gameLifecycle: NonNullable<JamangoEngineOptions["ui"]>["gameLifecycle"] = null!;
	static jacy: NonNullable<JamangoEngineOptions["ui"]>["jacy"] = null;
	static user: NonNullable<JamangoEngineOptions["ui"]>["user"] = null!;
	static player: UIPlayer = null!;
	static state: {
		editor: () => any;
		editorModal: () => any;
		chat: () => any;
		confirmPrompt: () => any;
		controls: () => any;
		debug: () => any;
		engine: () => any;
		hub: () => any;
		inventory: () => any;
		keyboard: () => any;
		loadingScreen: () => any;
		movement: () => any;
		multiplayer: () => any;
		permission: () => any;
		generalPrompt: () => any;
		onboarding: () => any;
		prompt: () => any;
		player: () => any;
		playerContext: () => any;
		inventorySelector: () => any;
		selector: () => any;
		item: () => any;
		settings: () => any;
		worldEditor: () => any;
		weapon: () => any;
		wrench: () => any;
		pencil: () => any;
		world: () => any;
		errorNotification: () => any;
		alertNotification: () => any;
		dialogue: () => any;
		helpers: () => any;
		game: () => any;
		gameHud: () => any;
		gameHudPopup: () => any;
		customUI: () => any;
		worldPortal: () => any;
	} = null!;

	static async init(ui: NonNullable<JamangoEngineOptions["ui"]>) {
		//note all these states are functions
		UI.state = {
			chat: await UI.requestState("useChat"),
			confirmPrompt: await UI.requestState("useConfirmPrompt"),
			controls: await UI.requestState("useControls"),
			debug: await UI.requestState("useDebug"),
			engine: await UI.requestState("useEngine"),
			hub: await UI.requestState("useHub"),
			editor: await UI.requestState("useEditor"),
			editorModal: await UI.requestState("useEditorModal"),
			inventory: await UI.requestState("useInventory"),
			keyboard: await UI.requestState("useKeyboard"),
			loadingScreen: await UI.requestState("useLoadingScreen"),
			movement: await UI.requestState("useMovement"),
			multiplayer: await UI.requestState("useMultiplayer"),
			permission: await UI.requestState("usePermission"),
			generalPrompt: await UI.requestState("useGeneralPrompt"),
			onboarding: await UI.requestState("useOnboarding"),
			prompt: await UI.requestState("usePrompt"),
			player: await UI.requestState("usePlayer"),
			playerContext: await UI.requestState("usePlayerContext"),
			inventorySelector: await UI.requestState("useInventorySelector"),
			selector: await UI.requestState("useSelector"),
			item: await UI.requestState("useItem"),
			settings: await UI.requestState("useSettings"),
			worldEditor: await UI.requestState("useWorldEditor"),
			weapon: await UI.requestState("useWeapon"),
			wrench: await UI.requestState("useWrench"),
			pencil: await UI.requestState("usePencil"),
			world: await UI.requestState("useWorld"),
			errorNotification: await UI.requestState("useError"),
			alertNotification: await UI.requestState("useAlertDialog"),
			dialogue: await UI.requestState("useDialogue"),
			helpers: await UI.requestState("useHelpers"),
			game: await UI.requestState("useGame"),
			gameHud: await UI.requestState("useGameHud"),
			gameHudPopup: await UI.requestState("useGameHudPopup"),
			customUI: await UI.requestState("useCustomUI"),
			worldPortal: await UI.requestState("useWorldPortal"),
		};

		UI.gameMultiplayer = ui.gameMultiplayer;
		UI.gameLifecycle = ui.gameLifecycle;
		UI.jacy = ui.jacy;
		UI.user = ui.user;
		UI.player = new UIPlayer();

		document.onfullscreenchange = function () {
			UI.state.settings().setFullscreen(Boolean(document.fullscreenElement));
		};

		DOMListener.removeEngineListeners();
	}

	static async requestState(args: string): Promise<any> {
		return await new Promise((resolve, reject) => DOMListener.triggerState(args, resolve, reject));
	}

	static updateKeyboardInput() {
		if (BB.client.inputPoll?.isMobileBrowser()) return;

		BB.client.debug.updateKeyboardInput();
		BB.client.settings.updateKeyboardInput();
	}
}
