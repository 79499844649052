import { generateUUID } from "@jamango/content-client";
import { NODE_TYPE_ID } from "base/rete/Constants";

const startID = "e75eb0b2-cdd2-47d9-a7b1-d962762247af";
const endID = "50b6ebdd-e461-44d9-9c59-2dfe3b717434";

export const SCRIPT_NODES = [
	{
		id: "2af8d334-6e46-4d81-b7c8-889f01adddbf",
		name: "Controls",
		type: NODE_TYPE_ID.scripts.controls,
		description: "Adds controls to a script",
		predictable: true,
		dynamic: {
			addText: "Add Control",
			add: (index) => {
				const id = "socket-" + generateUUID();

				return {
					controls: {
						[id]: {
							name: "Control",
							type: "string",
							dynamic: true,
							index,
						},
					},
					outputs: {
						[id]: {
							name: "Input",
							type: "string",
							dynamic: true,
							index,
						},
					},
				};
			},
		},
		resolve(inputs) {
			return inputs;
		},
	},
	{
		id: startID,
		name: "Start",
		type: NODE_TYPE_ID.scripts.entry,
		description: "Entry point for a script.",
		predictable: true,
		dynamic: {
			addText: "Add Input",
			add: (index) => {
				const id = "socket-" + generateUUID();

				return {
					inputs: {
						[id]: {
							name: "Input",
							type: "any",
							dynamic: true,
							index,
						},
					},
					outputs: {
						[id]: {
							name: "Input",
							type: "any",
							dynamic: true,
							index,
						},
					},
				};
			},
		},
		inputs: {
			exec: { name: "Exec", type: "exec" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
		},
		resolve(inputs) {
			return inputs;
		},
	},
	{
		id: endID,
		name: "End",
		type: NODE_TYPE_ID.scripts.exit,
		description: "Exit point for a script.",
		predictable: true,
		dynamic: {
			addText: "Add Output",
			add: (index) => {
				const id = "socket-" + generateUUID();

				return {
					inputs: {
						[id]: {
							name: "Output",
							type: "any",
							dynamic: true,
							index,
						},
					},
					outputs: {
						[id]: {
							name: "Output",
							type: "any",
							dynamic: true,
							index,
						},
					},
				};
			},
		},
		inputs: {
			exec: { name: "Exec", type: "exec" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
		},
		resolve: (inputs) => {
			return inputs;
		},
	},
];
