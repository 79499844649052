import { UI } from "client/dom/UI";
import { BB } from "base/BB";
import { LoadingScreen } from "base/dom/LoadingScreen.js";

//patch the base loading screen
export class LoadingScreenClient {
	static isShowing = false;

	static init() {
		UI.state.loadingScreen().init({
			show: LoadingScreen.show,
			hide: LoadingScreen.hide,
			showProgress: LoadingScreen.showProgress,
			setProgress: LoadingScreen.setProgress,
			setText: LoadingScreen.setText,
		});

		LoadingScreen.show = LoadingScreenClient.show;
		LoadingScreen.hide = LoadingScreenClient.hide;
		LoadingScreen.showProgress = UI.state.loadingScreen().showProgress;
		LoadingScreen.setProgress = UI.state.loadingScreen().setProgress;
		LoadingScreen.setText = UI.state.loadingScreen().setText;
	}

	static show(disablePointerLock) {
		if (LoadingScreenClient.isShowing) return;
		LoadingScreenClient.isShowing = true;
		if (disablePointerLock) BB.client.inputPoll.setAllowPointerLock(false);
		UI.state.loadingScreen().show(disablePointerLock);
	}

	static hide() {
		if (!LoadingScreenClient.isShowing) return;
		LoadingScreenClient.isShowing = false;
		if (!BB.client.inputPoll.getAllowPointerLock()) {
			BB.client.inputPoll.setAllowPointerLock(true);
			BB.client.inputPoll.reset();
		}
		UI.state.loadingScreen().hide();
	}
}
