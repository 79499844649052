export const ITEMS = [
	"Wrench",
	"Pencil",
	"Spade",
	"NovaGun",
	"NovaAssault",
	"NovaRifle",
	"ZapperPistol",
	"ZapperRifle",
	"ZapperAssault",
] as const;
