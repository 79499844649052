import { z } from "zod";
import { ACCEPTED_AUDIO_TYPES, MAX_AUDIO_FILE_SIZE } from "../constants/files";
import { formatBytes } from "../helpers/files";
import { AssetType, type IAudio } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

const SizeSchema = z
	.number()
	.min(0)
	.max(MAX_AUDIO_FILE_SIZE, {
		message: `Max audio file size is ${formatBytes(MAX_AUDIO_FILE_SIZE)}.`,
	});

const FormatSchema = z
	.enum(ACCEPTED_AUDIO_TYPES)
	.refine(
		(value) => ACCEPTED_AUDIO_TYPES.includes(value),
		`Only ${ACCEPTED_AUDIO_TYPES.join(", ")} formats are supported.`,
	);

export const file = (value: { size: unknown; type: unknown }) => {
	return {
		size: SizeSchema.parse(value.size),
		type: FormatSchema.parse(value.type),
	} as const;
};

export const AudioSchema = z.object({
	pk: assetKeySchema(AssetType.AUDIO),
	type: z.literal(AssetType.AUDIO),
	name: z.string(),
	resourcePk: assetKeySchema(AssetType.RESOURCE),
	packageId: PackageIdSchema.optional(),
});

export const all = (value: IAudio) => {
	AudioSchema.parse(value);
};
