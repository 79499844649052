import { Input, InputCommandType, InputCommands } from "@jamango/engine/Input.ts";
import * as trigger from "base/rete/modules/trigger";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { Character } from "base/world/entity/Character";
import { UI } from "client/dom/UI";
import * as PencilClient from "client/world/tools/Pencil";
import { ItemPencil } from "mods/defs/ItemPencil";
import { ItemSpawner } from "mods/defs/ItemSpawner";
import { ItemWrench } from "mods/defs/ItemWrench";
import { netState } from "router/Parallelogram";

export function playerButtonsUpdate(
	entity: Character,
	_stepDeltaTime: number,
	input: Input,
	cmd: InputCommands,
) {
	if (!canUpdateSystem(entity)) return;

	// some helper variables used by both branches
	const world = entity.world;
	const isMounted = entity.mount.state.parent;
	const item = entity.getEquippedItem();
	const viewRaycast = entity.viewRaycast.state;

	let doEmote: null | { name: string; loop: boolean } = null;

	let unuse = true;

	if (input.isPullingTrigger) {
		unuse = false;
	}

	for (const command of cmd) {
		if (Array.isArray(command)) {
			switch (command[0]) {
				case InputCommandType.EQUIP: {
					if (netState.isHost) {
						const doEquip = command[1];
						const permissions = getPermissions(entity);
						if (permissions) {
							if (doEquip === null) {
								if (item?.itemType.def.isTool) {
									//naive attempt at preventing disposing arbitrary items
									//really the test should be if(item was equipped via tool wheel/cycle)
									item.dispose();
								}
							} else {
								const def = entity.world.defs.get(doEquip);
								const canEquip =
									def &&
									((permissions.canUseIndividualBlocks && def.type === "block") ||
										(permissions.canUseWrench &&
											(def.name === ItemWrench.name ||
												def.name === ItemSpawner.name)) ||
										(permissions.canUsePencil && ItemPencil.isPencil(def.name)));

								if (canEquip) {
									entity.equipItem(doEquip);
									// TODO: make this one happen on the client side in some other way
									// if (isLocalTarget) UI.state.helpers().markCreatorToolKnown();
								} else {
									//if holding any tool and requested to hold a tool you don't have permission for, delete current tool
									//otherwise tool cycling gets stuck on the previous tool
									if (item?.itemType.def.isTool) item.dispose();
								}
							}
						}
					}

					break;
				}
				case InputCommandType.EMOTE: {
					doEmote = { name: command[1], loop: command[2] };
					break;
				}
				case InputCommandType.CHANGE_RAYCAST_MODE: {
					if (hasPencilPowers(entity)) {
						entity.viewRaycast.state.mode = command[1];
						if (netState.isClient && entity.isLocalInputTarget()) {
							PencilClient.onViewRaycastModeChange(world.client.pencil);
						}
					}
					break;
				}
				case InputCommandType.CHANGE_SCULPT_MODE: {
					if (hasPencilPowers(entity)) {
						entity.selector.state.sculptMode = command[1];
					}
					break;
				}
				case InputCommandType.CONTROL_PRESS: {
					if (netState.isHost) {
						const key = command[1];
						trigger.onControl(entity.world, entity, key, "press");
					}
					break;
				}
				case InputCommandType.CONTROL_RELEASE: {
					if (netState.isHost) {
						const key = command[1];
						trigger.onControl(entity.world, entity, key, "release");
					}
					break;
				}
				case InputCommandType.CHANGE_ZEN_MODE: {
					const zenModeEnabled = command[1];
					entity.characterHUD.state.zenMode = zenModeEnabled;
					break;
				}
			}
		} else {
			switch (command) {
				case InputCommandType.MELEE: {
					// melee attack - damage is server sided, item animation client sided
					if (!isMounted && (!item || (!item.itemType.def.isRanged && !item.itemType.def.isTool))) {
						if (netState.isHost && viewRaycast.character !== null) {
							viewRaycast.character.addHealth(-entity.getMeleeDamage(), entity.entityID);
						}
						// fire animation if item exists
						if (item && netState.isClient) item.fireAnimation();
					}
					break;
				}
				case InputCommandType.INTERACT: {
					if (netState.isHost && getPermissions(entity)?.canInteract) {
						entity.doInteract();
					}
					break;
				}
				case InputCommandType.RELOAD: {
					if (item) {
						item.reload();
					}
					break;
				}
				case InputCommandType.FORCE_RESPAWN: {
					if (getPermissions(entity)?.canForceRespawn) {
						entity.kill();
					}
					break;
				}
				case InputCommandType.PICK_BLOCK: {
					// strictly client sided logic - we can do this at will right now
					if (netState.isClient && entity.isLocalInputTarget() && input.pointerLocked) {
						// local target
						const firstPerson = world.client!.camera.is1stPerson();

						// pick up the block  we're aiming at
						if (firstPerson) {
							UI.state.inventorySelector().pickBlock();
						}
					}
					break;
				}
				case InputCommandType.ITEM_PRIMARY: {
					if (item !== undefined) {
						item.use(entity, true, false);
						unuse = false;
					}
					break;
				}
				case InputCommandType.ITEM_SECONDARY: {
					if (item !== undefined) {
						item.use(entity, false, true);
						unuse = false;
					}
					break;
				}
			}
		}
	}

	// item related code - both sides
	if (item !== undefined) {
		if (unuse) item.unuse();

		if (item.itemType?.def.isRanged) entity.setIronSights(input.isIronSights);
	}

	// swapping tool movement state
	entity.movement.state.isSwappingTool = input.isSwappingTool;

	// emote
	if (!entity.movement.state.isIdle) {
		entity.cancelEmote();
	} else if (doEmote) {
		const { name, loop } = doEmote;
		entity.setEmote(name, loop);
	}
}

function canUpdateSystem(entity: Character) {
	return (
		entity.type.def.isCharacter &&
		!entity.isDead() &&
		entity.input !== undefined &&
		entity.cmd !== undefined
	);
}

function getPermissions(player: Character) {
	const peer = player.world.playerToPeer.get(player);
	if (peer) return getPeerMetadata(peer).permissions;
}

function hasPencilPowers(character: Character) {
	const peer = character.world.playerToPeer.get(character);
	if (!peer) return false;
	return getPeerMetadata(peer).permissions.canUsePencil;
}
