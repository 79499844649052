import type { Result } from "./Result";

const RETRY_ATTEMPTS = 3;

export const doWithRetries = async <T, E>(fn: () => Promise<Result<T, E>>): Promise<Result<T, E>> => {
	let lastError: E | undefined = undefined;

	for (let i = 0; i < RETRY_ATTEMPTS; i++) {
		const result = await fn();

		if (result.ok) {
			return result;
		}

		if (!result.retryable) {
			return result;
		}

		lastError = result.error;

		// exponential backoff
		if (i < RETRY_ATTEMPTS - 1) {
			await new Promise((resolve) => setTimeout(resolve, 2 ** i * 1000));
		}
	}

	const result = {
		ok: false,
		data: null,
		retryable: false,
		error: lastError!,
	} as const;

	return result;
};
