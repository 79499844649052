import { netState } from "router/Parallelogram";
import type { Entity } from "../Entity";

export class ItemParticlesComponent {
	readonly name = "particles";

	readonly def: {
		name: string;
	};

	constructor(entity: Entity) {
		this.def = {
			name: entity.entityID + "-particles",
		};
	}

	dispose(entity: Entity) {
		if (netState.isClient) entity.world.client!.particleEngine.remove(this.def.name);
	}
}
