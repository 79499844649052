import { Mesh, PlaneGeometry } from "three";
import { VignetteMaterial } from "client/util/Shaders.js";

export class Vignette extends Mesh {
	constructor(world, o) {
		super(new PlaneGeometry(2, 2), new VignetteMaterial());

		this.world = world;

		this.name = "VignetteMesh";

		const u = this.material.uniforms;
		u.waterColor.value.set(world.scene.water?.color ?? 0);
		u.painColor.value.set(o?.painColor ?? "red");
		u.painSoftness.value = o?.painSoftness ?? 1.5;
		u.vignetteColor.value.set(o?.constantColor ?? 0x202020);
		u.vignetteOpacity.value = o?.constantOpacity ?? 1;
		u.vignetteSoftness.value = o?.contantSoftness ?? 1.5;
		u.vignetteStartRadius.value = o?.contantStartRadius ?? 1;

		this.renderOrder = 1;
		this.matrixAutoUpdate = false;

		world.client.sceneHUD.add(this);
	}

	update() {
		const target = this.world.client.camera.target;
		const health = target.health.state.current / target.health.def.max;
		const underwater =
			this.world.client.renderCamera.position.y - this.world.client.renderCamera.near <
			this.world.scene.water?.height;

		this.material.uniforms.painOpacity.value = (1 - health) * 0.3;
		this.material.uniforms.waterOpacity.value = underwater ? 0.1 : 0;
	}

	dispose() {
		this.removeFromParent();
		this.material.dispose();
		this.geometry.dispose();
	}
}
