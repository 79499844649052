import { isNullish } from "@jamango/helpers";
import { NODE } from "base/rete/InternalNameMap";
import * as listener from "base/rete/modules/listener";
import * as trigger from "base/rete/modules/trigger";
import { DEGRAD, VNZ, rand, randSign } from "base/util/math/Math.ts";
import { now, wait } from "base/util/Time.js";
import { onBlockItemUse } from "base/world/block/BlockItem";
import type { Character } from "base/world/entity/Character";
import type { CharacterViewRaycastComponent } from "base/world/entity/component/CharacterViewRaycast";
import { serDes as untypedSerDes } from "base/world/entity/component/Serialization";
import { desV3, serV3 } from "base/world/entity/component/Serialization.ts";
import { entityIsCharacter } from "base/world/entity/component/Type";
import type { Entity, EntityCreateOptions } from "base/world/entity/Entity";
import { PhysicalEntity } from "base/world/entity/PhysicalEntity.js";
import * as Physics from "base/world/Physics";
import { addProjectile, projectileCommand } from "base/world/ProjectileManager";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import { createMuzzleFlash } from "client/world/fx/MuzzleFlash";
import * as PencilClient from "client/world/tools/Pencil";
import * as SpawnerClient from "client/world/tools/Spawner";
import * as WrenchClient from "client/world/tools/Wrench";
import { NET_CLIENT } from "@jamango/ibs";
import { ItemPencil } from "mods/defs/ItemPencil";
import { ItemSpawner } from "mods/defs/ItemSpawner";
import type { ItemType } from "mods/defs/ItemType";
import { ItemWrench } from "mods/defs/ItemWrench";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import * as InputManagerServer from "server/world/InputManager";
import type { Box3, QuaternionLike, Vector3Like } from "three";
import { Euler, Quaternion, Vector3 } from "three";
import { removeItemMesh } from "base/world/entity/system/ItemMesh";

import { CameraShakeComponent } from "base/world/entity/component/CameraShake";
import { ItemAnimationsComponent } from "base/world/entity/component/ItemAnimations";
import { ItemBaseComponent } from "base/world/entity/component/ItemBase";
import { ItemBlockComponent } from "base/world/entity/component/ItemBlock";
import { ItemEjectComponent } from "base/world/entity/component/ItemEject";
import { ItemHitmarkerComponent } from "base/world/entity/component/ItemHitmarker";
import { ItemMeshComponent } from "base/world/entity/component/ItemMesh";
import { ItemParticlesComponent } from "base/world/entity/component/ItemParticles";
import { ItemPhysicsComponent } from "base/world/entity/component/ItemPhysics";
import { ItemReloadComponent } from "base/world/entity/component/ItemReload";
import { ItemSelectorBehaviorComponent } from "base/world/entity/component/ItemSelectorBehavior";
import { ItemTweenAnimationComponent } from "base/world/entity/component/ItemTweenAnimation";
import { ItemTypeComponent } from "base/world/entity/component/ItemType";
import { ItemWeaponComponent } from "base/world/entity/component/ItemWeapon";

const serDes = untypedSerDes<Item>;

const _spread = new Euler();
const _ejectVelocity = new Vector3();

//mirrors player.ts zustand store
const MODE = {
	default: "default",
	fighting: "fighting",
};

export class Item extends PhysicalEntity {
	base: ItemBaseComponent;
	itemType: ItemTypeComponent;
	weapon: ItemWeaponComponent;
	hitmarker: ItemHitmarkerComponent;
	particles: ItemParticlesComponent;
	itemPhysics: ItemPhysicsComponent;
	itemSelectorBehavior: ItemSelectorBehaviorComponent;
	tweenAnimation: ItemTweenAnimationComponent;
	block?: ItemBlockComponent;
	eject: ItemEjectComponent;
	cameraShake: CameraShakeComponent;
	itemReload: ItemReloadComponent;
	itemMesh: ItemMeshComponent;

	display: string;
	icon: string | undefined;

	fireButtonDown: boolean;
	isCurrentItem1stPerson: boolean;
	used: boolean;
	prvFire: number;

	aiShootHitPercentage: number | undefined;

	itemPk?: string;

	constructor(o: EntityCreateOptions, def: ItemType, world: World) {
		super(o, def, world);
		this.authority.state.public = 1;

		this.display = def.display;
		this.icon = def.icon;

		this.itemPk = o.def.startsWith("item#") ? o.def : undefined;

		this.base = this.addComponent(new ItemBaseComponent(def));
		this.itemType = this.addComponent(new ItemTypeComponent(def));
		// @ts-expect-error overriding threejs property
		this.animations = this.addComponent(new ItemAnimationsComponent(def));
		this.weapon = this.addComponent(new ItemWeaponComponent(def, this));
		this.tweenAnimation = this.addComponent(new ItemTweenAnimationComponent(def));
		if (this.itemType.def.isBlock) {
			this.block = this.addComponent(new ItemBlockComponent(def, this));
		}
		this.eject = this.addComponent(new ItemEjectComponent(def));
		this.cameraShake = this.addComponent(new CameraShakeComponent(def));
		this.particles = this.addComponent(new ItemParticlesComponent(this));
		this.itemSelectorBehavior = this.addComponent(new ItemSelectorBehaviorComponent(def));
		if (this.itemType.def.isRanged) {
			this.itemReload = this.addComponent(new ItemReloadComponent());
		}
		this.itemPhysics = this.addComponent(new ItemPhysicsComponent(def));
		if (netState.isClient) {
			this.hitmarker = this.addComponent(new ItemHitmarkerComponent(def, this));
		}

		this.itemMesh = this.addComponent(new ItemMeshComponent());

		if (this.itemPk !== undefined) {
			const itemAsset = world.content.state.items.get(this.itemPk);
			if (itemAsset) {
				this.itemMesh.updateMesh(structuredClone(itemAsset.mesh));
			}
		}

		this.itemPhysics.def.enabled = true;
		this.itemPhysics.def.motionType = Physics.MotionType.DYNAMIC;

		this.proximity.def.threshold = def.proximity ?? 7;

		this.interact.state.enable = true;
		this.interact.state.label = "Pick up";

		this.aiShootHitPercentage = def.aiShootHitPercentage; // TODO: NPC component

		this.fireButtonDown = false; // TODO: Refactor to use this.used.
		this.isCurrentItem1stPerson = false; // TODO: put this somewhere idk

		// setting used to true will call use() at the correct time
		this.used = false;

		this.postConstructor();
	}

	setPosition(pos: Vector3Like, isTeleport = false) {
		if (super.setPosition(pos, isTeleport)) return true;

		if (this.itemPhysics.state.body) {
			Physics.setBodyPosition(this.world.physics, this.itemPhysics.state.body, pos);
		}
	}

	setQuaternion(quat: QuaternionLike, isTeleport = false) {
		if (super.setQuaternion(quat, isTeleport)) return true;

		if (this.itemPhysics.state.body) {
			Physics.setBodyRotation(this.world.physics, this.itemPhysics.state.body, this.quaternion);
		}
	}

	physicsNeedsUpdate() {
		return (
			this.itemPhysics.def.enabled !== this.itemPhysics.state.enabled ||
			this.itemPhysics.def.motionType !== this.itemPhysics.state.motionType
		);
	}

	addPhysics() {
		if (this.itemPhysics.state.itemPhysics) return;

		if (this.itemPhysics.def.enabled) {
			const itemPhysics = Physics.createItemPhysics(
				this.world.physics,
				this.itemPhysics.def.geom,
				this.position,
				this.quaternion,
				this.itemPhysics.def.motionType,
				this.prophecy.state.isProphecy,
				this.entityID,
			);
			this.itemPhysics.state.body = itemPhysics.body;
			this.itemPhysics.state.itemPhysics = itemPhysics;
			this.itemPhysics.state.sensor = itemPhysics.sensor;
		}

		this.itemPhysics.state.enabled = this.itemPhysics.def.enabled;
		this.itemPhysics.state.motionType = this.itemPhysics.def.motionType;
	}

	removePhysics() {
		if (!this.itemPhysics.state.itemPhysics) return;

		Physics.disposeItemPhysics(this.world.physics, this.itemPhysics.state.itemPhysics);
		this.itemPhysics.state.body = undefined;
		this.itemPhysics.state.itemPhysics = undefined;
		this.itemPhysics.state.sensor = undefined;
	}

	getLinearVelocity(out: Vector3) {
		out.copy(this.itemPhysics.state.linearVelocity);

		return out;
	}

	setLinearVelocity(velocity: Vector3, isDesync = false) {
		this.itemPhysics.state.linearVelocity.copy(velocity);

		if (isDesync && netState.isHost) {
			InputManagerServer.markDesynced(this.world, this.entityID);
		}
	}

	//"armory mode" prevents the item from despawning and gives players a duplicate of this item when mounting
	//only call in constructor
	setArmoryMode() {
		this.base.def.armoryMode = true;

		this.itemPhysics.def.motionType = Physics.MotionType.STATIC;

		this.authority.state.public = 0;
		this.setOwner(netState.isHost);

		this.setDespawnTimer();
	}

	onProximityEnter() {
		if (this.mount.state.parent) return;
		if (!this.interact.state.enable) return;

		if (netState.isClient) {
			this.world.client.particleEngine.add({
				parent: this.object3D,
				name: this.particles.def.name,
				type: "basic",
				numParticles: 4,
				position: [0, 0, 0],
				colors: [
					0.2, 0.2, 1, 0, 0.2, 0.2, 1, 1, 0.2, 0.2, 1, 1, 0, 0, 1, 1, 0, 0, 1, 0.5, 0, 0, 1, 0,
				],
				lifeTime: 2,
				timeRange: 2,
				startSize: 2,
				endSize: 2,
				velocity: [0, 0.8, 0],
				gravity: [0, -0.2, 0],
				spinSpeedRange: 4,
			});
		}
	}

	onProximityLeave() {
		if (netState.isClient) this.world.client.particleEngine.remove(this.particles.def.name);
	}

	onInteract(evt: any) {
		super.onInteract(evt);

		const player = evt.triggeredBy;

		if (this.base.def.armoryMode && player.getEquippedItem()?.def !== this.def) {
			// TODO: revisit armory item logic
			player.equipItem(this.def.replace("Armory", ""));
		} else if (!this.base.def.armoryMode && player.hasLocalAuthority()) {
			if (player.getEquippedItem()) {
				player.unequipItem();
			}

			player.doMount(this, true);
		}
	}

	use(character: Character, primary: boolean, secondary: boolean) {
		this.base.state.used = primary || (secondary && this.itemType.def.isTool);

		if (this.base.state.used) {
			this.prvFire = now();

			if (!this.itemType.def.isRanged) this.fireAnimation();

			this.fireButtonDown = true;
			this.weapon.state.firingFirstShot = true;
		}

		trigger.onItemUse(this.world, this, character, this.def, primary, secondary);

		// the below code is used in modding / block placing. this is client sided for now.
		if (netState.isClient && (this.mount.state.parent as Character | undefined)?.isLocalInputTarget()) {
			if (this.itemType.def.isBlock) {
				onBlockItemUse(this.world, character, this.block!.def.block!.name, primary, secondary);
			}

			if (netState.isClient) {
				if (ItemPencil.isPencil(this.def)) {
					PencilClient.onItemUse(this.world, primary, secondary);
				} else if (this.def === ItemWrench.name) {
					WrenchClient.onItemUse(this.world, primary, secondary);
				} else if (this.def === ItemSpawner.name) {
					SpawnerClient.onItemUse(this.world, primary, secondary);
				}
			}

			if (primary || secondary) {
				this.modding.state.dispatcher.dispatchEvent(NET_CLIENT, {
					character,
					type: "use",
					entity: this,
					primary,
					secondary,
				});
			}
		}
	}

	consumeAmmo() {
		this.weapon.state.projectileAvailableCount--;
		// this does not work as shoot sometimes fires after remove projectile is run causing an issue
		if (this.weapon.state.projectileAvailableCount === 0) this.reload();

		if (netState.isClient && this.world.client.camera.target === this.mount.state.parent)
			UI.state.weapon().setAmmoCount(this.weapon.state.projectileAvailableCount); // update frontend
	}

	async reload() {
		if (
			this.weapon.def.projectileCartridgeLimit === this.weapon.state.projectileAvailableCount ||
			this.weapon.state.isReloading
		)
			return;

		let parent = this.mount.state.parent as Character | null; // player shooting weapon
		if (!parent?.type.def.isCharacter) parent = null;
		const localInputTarget = (parent as Character)?.isLocalInputTarget();

		this.weapon.state.isReloading = true;
		if (localInputTarget && netState.isClient)
			UI.state.weapon().setIsReloading(this.weapon.state.isReloading); // update frontend to let know its reloading

		this.cameraShake.state.shakeVertical = 0;
		this.cameraShake.state.shakeHorizontal = 0;

		parent?.setSprinting(false);
		parent?.setIronSights(false);

		const reloadSound = this.weapon.sounds?.reload;

		if (reloadSound) {
			this.world.sfxManager.playAtObj({ ...reloadSound, obj: this });
		}

		const reloadAnim = this.weapon.def.reloadAnimationName;
		const rig = parent?.locomotionInput.def.rigName as keyof NonNullable<typeof reloadAnim>;
		const itemAnim = reloadAnim?.item;
		const tpAnim = (reloadAnim as any)?.[rig]?.third;

		if (!isNullish(itemAnim)) this.playAnimation("mesh", itemAnim);
		if (!isNullish(tpAnim)) parent?.playAnimation("mesh", tpAnim);

		// mark item as reloading for duration of reload time
		await wait(this.weapon.def.reloadAnimationTime);

		if (this.disposed) return;

		this.weapon.state.projectileAvailableCount = this.weapon.def.projectileCartridgeLimit;
		this.weapon.state.isReloading = false;

		this.prvFire = now();

		if (localInputTarget && netState.isClient) {
			UI.state.weapon().setIsReloading(false); // update frontend to let know its no longer reloading
			UI.state.weapon().setAmmoCount(this.weapon.state.projectileAvailableCount); // update frontend
		}
	}

	aiSetShootHitPercentage(v: number) {
		this.aiShootHitPercentage = v;
	}

	unuse() {
		this.base.state.used = false;
		this.fireButtonDown = false;

		const character = this.mount.state.parent as Character | undefined;

		if (character) {
			trigger.onItemUnuse(this.world, this, character, this.def);
		}

		if (
			netState.isClient &&
			ItemPencil.isPencil(this.def) &&
			(this.mount.state.parent as Character | undefined)?.isLocalInputTarget()
		) {
			PencilClient.onItemUnuse(this.world);
		}
	}

	fire(makeProjectile: boolean, viewRaycast: CharacterViewRaycastComponent["state"], spread = 0) {
		const parent = this.mount.state.parent as Character;

		if (!this.weapon.def.isAutomatic) {
			if (this.weapon.state.cooldownTimer > 0) return false;
			this.weapon.state.cooldownTimer = this.weapon.def.cooldown;
		}

		if (!this.itemType.def.isRanged) return false;

		if (this.weapon.state.isReloading || this.weapon.state.projectileAvailableCount <= 0) {
			return false;
		}

		trigger.onItemRangedWeaponFire(this.world, this, parent, this.def);

		if (!this.weapon.def.projectile || !this.weapon.def.projectile.startsWith("Projectile")) return false;

		if (netState.isClient && this.weapon.sounds?.fire) {
			const fireSound = {
				...this.weapon.sounds.fire,
				detune: rand(-600, 300),
				volume: this.weapon.sounds.fire.volume * 0.35 + rand(-0.1, 0.1),
			};

			if (this.world.client.camera.target === parent) {
				fireSound.volume *= 0.6;
				this.world.sfxManager.play(fireSound);
			} else {
				this.world.sfxManager.playAtObj({ ...fireSound, obj: this });
			}
		}

		this.object3D.updateMatrixWorld();

		if (makeProjectile) {
			for (let i = 0; i < this.weapon.def.projectilesPerShot; ++i) {
				const dir = viewRaycast.rayDir.clone();
				const pos = viewRaycast.rayPos.clone();

				// recoil / spread
				_spread.set(
					rand(0, spread) * randSign() * DEGRAD,
					rand(0, spread) * randSign() * DEGRAD,
					rand(0, spread) * randSign() * DEGRAD,
				);
				dir.applyEuler(_spread);

				addProjectile(
					this.world,
					this.world.projectiles,
					this.weapon.def.projectile,
					parent.entityID,
					this.entityID,
					this.world.time,
					pos.clone(),
					dir.clone(),
				);

				projectileCommand.sendToAll([
					this.weapon.def.projectile,
					parent.entityID,
					this.entityID,
					pos.clone(),
					dir.clone(),
				]);
			}
		}

		if (netState.isClient) {
			this.fireAnimation();
			createMuzzleFlash(this, this.world.client.particleEngine);
		}

		this.consumeAmmo();

		return true;
	}

	async fireAnimation() {
		if (this.weapon.state.isReloading) return;

		const parent = this.mount.state.parent;

		if (parent && entityIsCharacter(parent)) {
			if (this.itemType.def.isBlock) {
				if (this.tweenAnimation.def.recoilDuration > 0 && this.tweenAnimation.def.idleDuration > 0) {
					// TODO: remove usages of tween.js
					// @ts-expect-error see Extensions.js :(
					await this.object3D.animateTween(
						this.tweenAnimation.def.recoilTransform,
						this.tweenAnimation.def.recoilDuration,
					);
					// TODO: remove usages of tween.js
					// @ts-expect-error see Extensions.js :(
					await this.object3D.animateTween(
						this.tweenAnimation.def.idleTransform,
						this.tweenAnimation.def.idleDuration,
					);
				}
			} else {
				const shootAnims = this.weapon.def.shootAnimationName;
				const rig = parent.locomotionInput.def.rigName as keyof NonNullable<typeof shootAnims>;
				const aim = parent.movement.state.isIronSights ? "sight" : "hip";

				const tpAnim = shootAnims?.[rig]?.third;
				const fpAnim = shootAnims?.[rig]?.first?.[aim];

				if (!isNullish(tpAnim)) parent.playAnimation("mesh", tpAnim);
				if (!isNullish(fpAnim)) parent.playAnimation("fpmesh", fpAnim);
			}
		}
	}

	isCurrentItem() {
		return netState.isClient && this.world.client.getEquippedItem() === this;
	}

	isEquipped(countNPC: boolean) {
		const parent = this.mount.state.parent;
		if (!parent) return false;

		const mountType = parent.type.def;
		return (
			(mountType.isPlayer || (countNPC && mountType.isNPC)) &&
			this.mount.state.index === (parent as Character).equipment.def.itemIndex
		);
	}

	onresize() {
		this.hitmarker?.def.hitmarker.centerXY();
	}

	setDespawnTimer() {
		super.setDespawnTimer();

		this.despawnTimerPause = this.base.def.armoryMode;
	}

	onGravityChange() {
		if (!this.itemPhysics.state.body) return;
		Physics.activateBody(this.world.physics, this.itemPhysics.state.body);
	}

	getAABB(out: Box3) {
		if (this.mount.state.parent || !this.itemPhysics.state.body) return out.makeEmpty();

		Physics.getBodyAABB(this.itemPhysics.state.body, out);
		return out;
	}

	onMount(parent: Entity, toggle: boolean, index: number) {
		this.itemPhysics.def.enabled = !toggle;

		let eject;

		if (toggle) {
			this.onresize();
		} else {
			this.base.state.used = false;
			eject =
				!toggle &&
				parent.type.def.isCharacter &&
				this.mount.state.index === (parent as Character).equipment.def.itemIndex;
		}

		const isTargetedItem = netState.isClient && this.world.client.camera.target === parent;

		if (parent.type.def.isCharacter && index === (parent as Character).equipment.def.itemIndex) {
			if (toggle) {
				this.authority.state.public = 0;

				this.setOwner(
					parent.hasLocalAuthority() ? (Net.getPeerId() ?? true) : parent.authority.state.owner,
				);
			} else {
				this.authority.state.public = 1;
				this.setOwner(netState.isHost);
			}

			if (this.weapon) {
				this.world.sfxManager.playAtObj({
					asset: "snd-ui-block-scroll",
					volume: 4,
					obj: this,
				});
			}

			if (isTargetedItem) {
				if (toggle && this.weapon) {
					UI.player.sync(parent, "mode", MODE.fighting);
					UI.state.weapon().init({
						type: this.itemType.def.type,
						ammoCount: this.weapon.state.projectileAvailableCount ?? 0,
						magazineSize: this.weapon.def.projectileCartridgeLimit ?? 0,
					});
				} else {
					UI.player.sync(parent, "mode", MODE.default);
					UI.state.weapon().init(null);
				}
			}

			this.modding.state.dispatcher.dispatchEvent(null, {
				type: "equip",
				entity: this,
				player: parent,
				toggle,
			});
		}

		super.onMount(parent, toggle, index);

		if (parent.type.def.isCharacter && index === (parent as Character).equipment.def.itemIndex) {
			listener.onEntityEvent(NODE.OnCharacterEquipItem, this.world, parent.entityID, {
				character: parent,
				prvItem: toggle ? null : this,
				curItem: toggle ? this : null,
			});

			if (isTargetedItem) {
				this.world.dispatcher.dispatchEvent(NET_CLIENT, {
					type: "itemchange",
					prvItem: parent.hasLocalAuthority() && toggle ? null : this,
					curItem: parent.hasLocalAuthority() && toggle ? this : null,
				});
			}
		}

		if (eject) {
			if ((parent as Character).aiDropItem) {
				const ejectVelocity = _ejectVelocity
					.copy(VNZ)
					.applyQuaternion(parent.object3D.getWorldQuaternion(new Quaternion()))
					.multiplyScalar(this.eject.def.impulse);

				this.itemPhysics.state.linearVelocity.copy(ejectVelocity);
			} else if (netState.isHost) {
				this.deferredDispose();
			}
		}

		if (netState.isClient && this.block)
			this.block.def.mesh!.frustumCulled = !(toggle && parent.type.def.isCharacter);
	}

	dispose(worldIsDisposed = false) {
		if (this.disposed) return true;

		if (super.dispose(worldIsDisposed)) return true;

		this.removePhysics();

		removeItemMesh(this);
	}

	serialize(includeMovement: boolean) {
		super.serialize(includeMovement);

		for (const id of Item.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}

		if (!this.mount.state.parent) {
			for (const id of Item.serDesIds_unmounted) {
				this.serialization.serialize(id, includeMovement);
			}
		}

		return this.serialization.diffs;
	}

	static serDesIds = [
		// ammo
		serDes(
			"item  ammo",
			(e) => e.weapon.state.projectileAvailableCount,
			(e, v) => (e.weapon.state.projectileAvailableCount = v),
		),
	];

	static serDesIds_unmounted = [
		serDes(
			"item linear velocity",
			(e) => serV3(e.itemPhysics.state.linearVelocity, 1000),
			(e, v) => e.setLinearVelocity(desV3(e.itemPhysics.state.linearVelocity, v, 1000), false),
			true,
		),
	];
}
