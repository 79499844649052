import { EventDispatcher } from "three";
import { NET_SERVER, NET_CLIENT } from "@jamango/ibs";
import { deferredCallback } from "base/util/Time.js";
import { isNullish } from "@jamango/helpers";
import escapeStringRegexp from "escape-string-regexp";
import { netState } from "router/Parallelogram";

//side is either NET_SERVER or NET_CLIENT
//don't store the event object
export class NetEventDispatcher extends EventDispatcher {
	addEventListener(side, type, listener) {
		super.addEventListener(type + side, listener);
	}

	hasEventListener(side, type, listener) {
		return super.hasEventListener(type + side, listener);
	}

	removeEventListener(side, type, listener) {
		super.removeEventListener(type + side, listener);
	}

	async dispatchEvent(side, event, defer = true) {
		if (defer) {
			await deferredCallback(); //force listener to be called asynchronously in the event loop
		}

		const type = event.type;
		if (isNullish(side)) {
			if (netState.isHost) {
				event.type = type + NET_SERVER;
				super.dispatchEvent(event);
			}

			if (netState.isClient) {
				event.type = type + NET_CLIENT;
				super.dispatchEvent(event);
			}
		} else if ((netState.isHost || side === NET_CLIENT) && (netState.isClient || side === NET_SERVER)) {
			event.type = type + side;
			super.dispatchEvent(event);
		}

		event.type = type;
	}

	//server does not know of client-sided events and vice versa
	hasEventType(type) {
		if (netState.isHost)
			for (const i in this._listeners)
				if (RegExp(`^${escapeStringRegexp(type)}${NET_SERVER}$`).test(i)) return true;

		if (netState.isClient)
			for (const i in this._listeners)
				if (RegExp(`^${escapeStringRegexp(type)}${NET_CLIENT}$`).test(i)) return true;

		return false;
	}
}
