import { z } from "zod";
import { ACCEPTED_IMAGE_TYPES, MAX_BANNER_FILE_SIZE } from "../constants/files";
import {
	MAX_DESCRIPTION_LENGTH,
	MAX_TITLE_LENGTH,
	MIN_DESCRIPTION_LENGTH,
	MIN_TITLE_LENGTH,
} from "../constants/custom-loader";
import { formatBytes } from "../helpers/files";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";

export const show = (value: boolean) => {
	const schema = z.boolean();
	schema.parse(value);
};

export const title = (value: string) => {
	const schema = z
		.string()
		.min(MIN_TITLE_LENGTH, {
			message: `Title must be at least ${MIN_TITLE_LENGTH} characters long.`,
		})
		.max(MAX_TITLE_LENGTH, {
			message: `Title must be at most ${MAX_TITLE_LENGTH} characters long.`,
		})
		.optional();
	schema.parse(value);
};

export const description = (value: string) => {
	const schema = z
		.string()
		.min(MIN_DESCRIPTION_LENGTH, {
			message: `Description must be at least ${MIN_DESCRIPTION_LENGTH} characters long.`,
		})
		.max(MAX_DESCRIPTION_LENGTH, {
			message: `Description must be at most ${MAX_DESCRIPTION_LENGTH} characters long.`,
		})
		.optional();
	schema.parse(value);
};

const SizeSchema = z
	.number()
	.min(0)
	.max(MAX_BANNER_FILE_SIZE, {
		message: `Max banner size is ${formatBytes(MAX_BANNER_FILE_SIZE)}.`,
	});
const FormatSchema = z.enum(ACCEPTED_IMAGE_TYPES, {
	message: `Only ${ACCEPTED_IMAGE_TYPES.join(", ")} formats are supported.`,
});

export const banner = (value: File) => {
	return {
		size: SizeSchema.parse(value.size),
		type: FormatSchema.parse(value.type),
	} as const;
};

export const CustomLoaderSchema = z.object({
	pk: z.string(),
	type: z.literal(AssetType.CUSTOM_LOADER),
	description: z.string(),
	title: z.string(),
	enabled: z.boolean(),
	banner: z
		.object({
			resourcePk: assetKeySchema(AssetType.RESOURCE, "Banner iimage resource is required."),
		})
		.optional(),
});
