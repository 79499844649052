import type { IEngineAsset, IEngineDef, JacyContent } from "@jamango/content-client";
import { ItemBuildTool } from "@jamango/frontend/ItemBuildTool.ts";
import { createLogger } from "@jamango/helpers";
import { ContentToDefConverter } from "base/content/ContentToDefConverter";
import { NODE } from "base/rete/InternalNameMap";
import { DEGRAD } from "base/util/math/Math";
import type { World } from "base/world/World";
import { BlockType } from "base/world/block/Type.js";
import { ITEM_BLOCK_SCL } from "base/world/entity/component/ItemBlock";
import { netState } from "router/Parallelogram";
import { Euler, Vector3 } from "three";

const logger = createLogger("BlockTypeRegistry");

const MAX_BLOCKS_DEFS = 65536;

/**
 * Block IDs may change after changing maps so don't store them anywhere except generator init().
 *
 * The missingBlocks set is cleared after every map change.
 */

export const init = () => {
	return {
		blockIdToType: new Map<number, BlockType>(),
		blockNameToType: new Map<string, BlockType>(),
		missingBlocks: new Set<BlockType>(),
		mapReorderBlocks: null as null | Record<string, number>,
		// maps and sets maintained for script parsing logic
		interactBlockTypes: new Map<number, { label: string }>(),
		interactBlockTypesUpdateTime: 0,
		spawnBlockTypes: new Set<number>(),
		// related to def parsing
		assets: [] as IEngineAsset[],
		blockBuildToolDefs: [] as IEngineDef[],
		blockDefs: [] as IEngineDef[],
		// dirty flags
		reteLastReloadTime: 0,
		blockTypePhysicalAttributesChanged: false,
	};
};

export type BlockTypeRegistryState = ReturnType<typeof init>;

export const MISSING_BLOCK_TYPE = new BlockType(-1, {
	name: "block#missing",
	display: "Missing block",
	faces: [
		{
			texture: { asset: "tex-missing" },
		},
	],
	transparency: "opaque",
	collision: "enabled",
});

export const createBlockTypes = (
	blockTypeRegistry: BlockTypeRegistryState,
	content: JacyContent,
	world: World,
) => {
	// clear existing state and block type defs
	blockTypeRegistry.blockNameToType.clear();
	blockTypeRegistry.blockIdToType.clear();

	for (const def of blockTypeRegistry.blockDefs) {
		world.defs.delete(def.name);
	}

	for (const def of blockTypeRegistry.blockBuildToolDefs) {
		world.defs.delete(def.name);
	}

	blockTypeRegistry.blockDefs = [];
	blockTypeRegistry.blockBuildToolDefs = [];

	// collect block defs
	const blockDefs = blockTypeRegistry.blockDefs;

	const converter = new ContentToDefConverter();

	const contentBlocksDef = converter.convertToDefBlocks(
		Object.values(content.state.blocks.assets),
		content.state.blockTextures.getAll(),
		content.state.resources.assets,
		content.state.blockSoundsPacks.getAll(),
	);

	blockTypeRegistry.assets = contentBlocksDef.assets;

	for (const def of contentBlocksDef.blocks) {
		world.defs.set(def.name, def);
		blockDefs.push(def);
	}

	if (blockDefs.length >= MAX_BLOCKS_DEFS) {
		// uint16 limit imposed by PxMaterialTableIndexArray and Chunk.storage.types
		throw RangeError(
			`You FOOL! Too many dingus block types (${blockDefs.length} requested/${MAX_BLOCKS_DEFS} limit)`,
		);
	}

	for (const def of blockDefs) {
		createBlockType(blockTypeRegistry, def, world);
	}

	// create missing block type name mapping
	blockTypeRegistry.blockNameToType.set(MISSING_BLOCK_TYPE.name, MISSING_BLOCK_TYPE);
};

export const createBlockType = (blockTypeRegistry: BlockTypeRegistryState, def: any, world: World) => {
	if (blockTypeRegistry.blockNameToType.has(def.name)) {
		throw Error(`Duplicate block type def ${def.name}`);
	}

	// block id is replaced by reorderBlockIDs
	const blockIndex = blockTypeRegistry.blockIdToType.size;
	const block = new BlockType(blockIndex, def);

	blockTypeRegistry.blockNameToType.set(block.name, block);
	blockTypeRegistry.blockIdToType.set(block.id, block);

	const blockItemDef = {
		name: ItemBuildTool.blockPrefix + block.name,
		block: block.name,
		geom: {
			type: "box",
			width: ITEM_BLOCK_SCL,
			height: ITEM_BLOCK_SCL,
			depth: ITEM_BLOCK_SCL,
		},

		type: "block",
		cooldown: 0,
		recoilTransform: {
			pos: new Vector3(0.9, -1.4, -1.5),
			rot: new Euler(-25 * DEGRAD, -20 * DEGRAD, 0),
		},
		recoilDuration: 0.07,
		idleTransform: {
			pos: new Vector3(0.9, -1.04, -1.5),
			rot: new Euler(0, -20 * DEGRAD, 0),
		},
		idleDuration: 0.07,
	};

	world.defs.set(blockItemDef.name, blockItemDef);
	blockTypeRegistry.blockBuildToolDefs.push(blockItemDef);

	return block;
};

export const clearMissing = (blockTypeRegistry: BlockTypeRegistryState) => {
	for (const block of blockTypeRegistry.missingBlocks) {
		blockTypeRegistry.blockNameToType.delete(block.name);
		blockTypeRegistry.blockIdToType.delete(block.id);
		if (blockTypeRegistry.mapReorderBlocks) {
			delete blockTypeRegistry.mapReorderBlocks[block.name];
		}

		block.dispose();
	}

	blockTypeRegistry.missingBlocks.clear();
};

type BlockIndexToName = Record<string, string>;
type BlockNameToIndex = Record<string, number>;

export type BlockIdsReorderMap = BlockNameToIndex;

export const getBlockIndexToNameMap = (blockTypeRegistry: BlockTypeRegistryState) => {
	const blocks: BlockIndexToName = {};

	for (const block of blockTypeRegistry.blockIdToType.values()) {
		blocks[block.id] = block.name;
	}

	return blocks;
};

export const createReorderBlockIdsMap = (content: JacyContent, currentBlockIds: BlockIndexToName) => {
	const blocks = content.state.blocks;

	const reorder: BlockNameToIndex = {};

	for (const id in currentBlockIds) {
		const blockName = currentBlockIds[id];
		if (!blocks.get(blockName)) {
			reorder[blockName] = Number(id);
		}
	}

	let minBlockID = 0;
	let nextBlockID = 0;

	for (const i in currentBlockIds) {
		minBlockID = Math.max(Number(i), minBlockID);
	}

	for (const block of blocks.getAll()) {
		let id = nextBlockID++ + minBlockID;
		for (const i in currentBlockIds) {
			if (currentBlockIds[i] === block.pk) {
				id = Number(i);
				break;
			}
		}

		reorder[block.pk] = id;
	}

	return reorder;
};

export const reorderBlockIDs = (
	blockTypeRegistry: BlockTypeRegistryState,
	world: World,
	reorder: BlockIdsReorderMap,
) => {
	blockTypeRegistry.blockIdToType.clear();

	blockTypeRegistry.mapReorderBlocks = {};

	for (const name in reorder) {
		// server sends copy of mapReorderBlocks to every new peer
		const id = (blockTypeRegistry.mapReorderBlocks[name] = reorder[name]);
		let block = blockTypeRegistry.blockNameToType.get(name);

		if (block) {
			block.id = id;
		} else {
			block = new BlockType(id, {
				name,
				display: `Missing block: ${name}`,
				faces: [
					{
						texture: { asset: "tex-missing" },
					},
				],
				transparency: "opaque",
				collision: "enabled",
			});

			blockTypeRegistry.blockNameToType.set(name, block);
			blockTypeRegistry.missingBlocks.add(block);

			if (netState.isHost) {
				logger.error(`Missing block def required by map file: "${name}"`);
			}
		}

		blockTypeRegistry.blockIdToType.set(id, block);
	}

	updateBlockTypeScripts(blockTypeRegistry, world);
};

export const dispose = (blockTypeRegistry: BlockTypeRegistryState) => {
	for (const block of blockTypeRegistry.blockNameToType.values()) {
		block.dispose();
	}
};

/**
 * finds block types with scripts that contain interact labels and spawn events
 */
export const updateBlockTypeScripts = (state: BlockTypeRegistryState, world: World) => {
	const prvInteractBlockTypes = state.interactBlockTypes;

	state.interactBlockTypes = new Map();
	state.spawnBlockTypes = new Set();

	const blockTriggers = world.rete.triggers.onBlock;

	const onBlockSpawnTriggers = blockTriggers[NODE.OnBlockSpawn];

	if (onBlockSpawnTriggers) {
		for (const { entryPoint } of Object.values(onBlockSpawnTriggers).flatMap((triggers) => triggers)) {
			if (entryPoint.type !== "block") continue;

			const blockIndex = state.blockNameToType.get(entryPoint.targetId)?.id;

			if (blockIndex !== undefined) {
				state.spawnBlockTypes.add(blockIndex);
			}
		}
	}

	const onInteractWithBlockTriggers = blockTriggers[NODE.OnInteractWithBlock];

	if (onInteractWithBlockTriggers) {
		for (const { entryPoint, data } of Object.values(onInteractWithBlockTriggers).flatMap(
			(triggers) => triggers,
		)) {
			if (entryPoint.type !== "block") continue;

			const blockIndex = state.blockNameToType.get(entryPoint.targetId)?.id;

			if (blockIndex !== undefined) {
				state.interactBlockTypes.set(blockIndex, { label: data.label });
			}
		}
	}

	const prvInteractBlockTypeIds = Array.from(prvInteractBlockTypes.keys());
	const interactBlockTypeIds = Array.from(state.interactBlockTypes.keys());

	const interactBlockTypesChanged =
		prvInteractBlockTypeIds.length !== interactBlockTypeIds.length ||
		new Set(interactBlockTypeIds).difference(new Set(prvInteractBlockTypeIds)).size > 0;

	if (interactBlockTypesChanged) {
		state.interactBlockTypesUpdateTime = world.time;
	}
};
