import { JSFRandom } from "base/util/math/Random.js";
import { PI2, PI, PIH, VY, DEGRAD } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";
import { BB } from "base/BB";
import { defLight, defCubeTexture } from "client/util/Defs.js";
import { FasterScene } from "base/util/FasterScene.js";
import { LightProbeGenerator } from "three/addons/lights/LightProbeGenerator.js";
import {
	Color,
	Group,
	Euler,
	MeshBasicMaterial,
	PlaneGeometry,
	Mesh,
	Points,
	PointsMaterial,
	ShaderMaterial,
	Vector3,
	BufferGeometry,
	SphereGeometry,
	IcosahedronGeometry,
	BackSide,
	WebGLCubeRenderTarget,
	CubeCamera,
	HalfFloatType,
	Spherical,
	AdditiveBlending,
	RepeatWrapping,
	MathUtils,
	BufferAttribute,
	SRGBColorSpace,
} from "three";
import { Layers } from "client/Layers.js";
import * as Resources from "client/Resources";

const tmpEuler = new Euler();

/**
 * @param {import("base/world/fx/Sky").SkyBase} sky
 * @param {import("base/world/World").World} world
 * @param {import("@jamango/content-client/lib/types/engine.ts").IEngineWorldData["sky"]} [o]
 */
export function init(sky, world, o) {
	// we can attach this info to the sky object for now
	// once we start working with isolated world states, this is a no-no
	// sky.enableLensFlare = false; // currently perma disabled
	sky.minGround = o?.minAmient ?? 0.1;
	sky.minGroundSunY = o?.minGroundSunY ?? -0.25;
	sky.maxGround = o?.maxGround ?? 1;
	sky.maxGroundSunY = o?.maxGroundSunY ?? 0;
	sky.maxDiffuse = o?.maxDiffuse ?? 0.75;
	sky.maxSunIntensity = o?.maxSunIntensity ?? 1.26;
	sky.sunScale = o?.sunScale ?? 0.3;
	sky.moonScale = o?.moonScale ?? 0.3;
	sky.starSize = o?.starSize ?? 3;
	sky.starCount = o?.starCount ?? 400;
	sky.azimuth = o?.azimuth ?? 21 * DEGRAD;

	if (!isNullish(o?.envMap)) {
		sky.isAutoSky = false;

		world.client.sceneSky.background = defCubeTexture(o.envMap, Resources.idToResource);
	} else {
		sky.isAutoSky = true;
	}

	sky.lightGroup = new Group(); //added to normal scene
	sky.skyGroup = new Group(); //added to sky scene
	sky.lensGroup = new Group(); //added to sky scene
	sky.skyGroup.rotation.order = "YXZ";

	sky.sunProbe = LightProbeGenerator.fromCubeTexture(
		defCubeTexture(
			{
				assets: [
					"tex-probe-nx",
					"tex-probe-px",
					"tex-probe-ny",
					"tex-probe-py",
					"tex-probe-nz",
					"tex-probe-pz",
				],
			},
			Resources.idToResource,
		),
	);
	sky.sunProbe.layers.enable(Layers.LIGHTS);

	sky.sunDirectional = defLight({ type: "directional", intensity: 3 }, 25);
	sky.sunDirectional.name = "SunDirectional";
	sky.sunDirectional.layers.enable(Layers.LIGHTS);

	sky.sunDirectional2 = defLight({ type: "directional", intensity: 3 }, 25);
	sky.sunDirectional2.name = "SunDirectional2";
	sky.sunDirectional2.layers.enable(Layers.LIGHTS);

	world.scene.add(sky.sunProbe);
	// keep the reference for swapping between main scene and FP scene
	world.router.sunProbeLink = sky.sunProbe;
	sky.lightGroup.add(sky.sunDirectional);
	sky.lightGroup.add(sky.sunDirectional2);

	sky.smGeom = new PlaneGeometry();

	sky.sunPosition = new Vector3(0, 1, 0);
	sky.sunDir = new Vector3(0, 1, 0);
	sky.lastMinute = -1;
	sky.nightLuminosity = 0.81;

	// var for sun color
	sky.sunCol = new Vector3(0.188, 0.458, 0.682);
	sky.sunTop = new Vector3(0, 0.99, 0);
	sky.sunMax = new Vector3(1.8, 1.8, 1.8);
	sky.sunSph = new Spherical(1);

	// TODO: this is gnarly, probably wanna use a map
	for (const s of ["sun", "moon"]) {
		const tex = Resources.idToResource.get(`tex-${s}`);
		sky[`${s}Tex`] = tex;

		sky[`${s}Mat`] = new MeshBasicMaterial({
			name: `${s}Material`,
			map: sky[`${s}Tex`],
			transparent: true,
			depthWrite: false,
			depthTest: false,
			blending: AdditiveBlending,
			toneMapped: false,
			opacity: s === "moon" ? 0.5 : 1.0,
		});

		sky[`${s}Mesh`] = new Mesh(sky.smGeom, sky[`${s}Mat`]);
		sky[`${s}Mesh`].name = `${s}Mesh`;
		sky[`${s}Mesh`].scale.setScalar(sky[`${s}Scale`] ?? 1);
		sky.skyGroup.add(sky[`${s}Mesh`]);
	}

	sky.sunMesh.position.z = -1;
	sky.moonMesh.position.z = 1;
	sky.moonMesh.rotation.set(0, PI, PI);

	const position = new Float32Array(sky.starCount * 3);
	const colors = new Float32Array(sky.starCount * 3);
	const sizes = new Float32Array(sky.starCount);
	const tmpVec = new Vector3();

	const random = new JSFRandom(Date.now(), "daycycle");
	for (let i = 0; i < sky.starCount; i++) {
		tmpVec
			.set(random.float64Range(-1, 1), random.float64Range(-1, 1), random.float64Range(-1, 1))
			.normalize()
			.toArray(position, i * 3);

		tmpVec
			.set(random.float64Range(0.6, 1), random.float64Range(0.6, 1), random.float64Range(0.6, 1))
			.normalize()
			.toArray(colors, i * 3);

		sizes[i] = random.float64Range(1, sky.starSize);
	}

	sky.starGeom = new BufferGeometry();
	sky.starGeom.setAttribute("position", new BufferAttribute(position, 3));
	sky.starGeom.setAttribute("color", new BufferAttribute(colors, 3));
	sky.starGeom.setAttribute("size", new BufferAttribute(sizes, 1));

	sky.starMat = new PointsMaterial({
		name: "StarMaterial",
		size: sky.starSize,
		vertexColors: true,
		sizeAttenuation: false,
		transparent: true,
		depthWrite: false,
		depthTest: false,
		blending: AdditiveBlending,
	});

	sky.starMat.onBeforeCompile = function (shader) {
		const uniforms = shader.uniforms;

		uniforms["angle"] = { value: 1.0 };

		shader.uniforms = uniforms;

		let vertex = shader.vertexShader;
		vertex = vertex.replace(
			"uniform float size;",
			`
				attribute float size;
				varying vec3 vPos;
			`,
		);
		vertex = vertex.replace(
			"gl_PointSize = size;",
			`
				gl_PointSize = size;
				vPos = position;
			`,
		);
		shader.vertexShader = vertex;

		let fragment = shader.fragmentShader;
		fragment = fragment.replace(
			"uniform float opacity;",
			`
				uniform float opacity;
				uniform float angle;
				varying vec3 vPos;
				const float pi = 3.141592653589793;
			`,
		);
		fragment = fragment.replace(
			"outgoingLight = diffuseColor.rgb;",
			`
				outgoingLight = diffuseColor.rgb;

				vec3 p = normalize( vPos );
				vec3 axis = vec3(1.0,0.0,0.0);
				vec3 pp = mix(dot(axis, p)*axis, p, cos(angle)) + cross(axis,p)*sin(angle);

				float uvy = acos( pp.y ) / pi;
				diffuseColor.a = uvy > 0.5 ? 0.0 : 1.0;
			`,
		);

		shader.fragmentShader = fragment;

		this.userData.shader = shader;
	};

	sky.starMesh = new Points(sky.starGeom, sky.starMat);
	sky.starMesh.name = "StarMesh";
	sky.starMesh.renderOrder = -1; //beneath moon/sun
	sky.skyGroup.add(sky.starMesh);

	sky.fogColor = new Color();
	sky.sunColor = new Color();
	sky.skyColor = new Color();
	sky.groundColor = new Color();

	sky.sunColorReal = new Color();

	world.scene.add(sky.lightGroup);
	// keep the reference for swapping between main scene and FP scene
	world.router.lightGroupLink = sky.lightGroup;
	world.client.sceneSky.add(sky.skyGroup);

	if (sky.isAutoSky) initShaderSky(sky, world);
	// ~ if (sky.enableLensFlare) initLensFlare(sky);
	world.router.lensGroupLink = sky.lensGroup;

	const water = world.scene.water;
	if (water?.client) water.client.mat.envMap = world.client.sceneSky.background;
}

/**
 * @param {import("base/world/fx/Sky").SkyBase} sky
 * @param {import("base/world/World").World} world
 */
export function initShaderSky(sky, world) {
	sky.skyOption = {
		cloud_size: 0.29,
		cloud_covr: 0.56,
		cloud_dens: 1,
		cloud_dist: 0.64,

		haze: 0.1,
		mixRatio: 0.76,

		sample: 128,
		step: 16,

		sunColor: 0xffffff,
		fogColor: 0xadd7ff,
		groundColor: 0xbb7831, //0x78e366,
		cloudColor: 0xfddfae,
		skyColor: 0x2e9bc8,

		saturation: 1.3,
	};

	sky.skyResolution = 256;

	sky.readPixel = new Uint16Array(4);

	sky.noiseMap = Resources.idToResource.get("tex-clouds");
	sky.noiseMap.wrapS = sky.noiseMap.wrapT = RepeatWrapping;
	sky.noiseMap.flipY = false;

	sky.materialSky = new ShaderMaterial({
		name: "SkyMaterial",
		defines: {
			USE_NOISE_MAP: true,
		},
		uniforms: {
			lightdir: { value: sky.sunPosition },
			sunTop: { value: sky.sunTop },
			noiseMap: { value: sky.noiseMap },

			mixRatio: { value: sky.skyOption.mixRatio }, // TODO: Implement

			cloud_size: { value: sky.skyOption.cloud_size },
			cloud_covr: { value: sky.skyOption.cloud_covr },
			cloud_dens: { value: sky.skyOption.cloud_dens },
			cloud_dist: { value: sky.skyOption.cloud_dist },
			nightLuminosity: { value: sky.nightLuminosity },
			haze: { value: sky.skyOption.haze },
			saturation: { value: sky.skyOption.saturation },

			SAMPLE: { value: sky.skyOption.sample },
			STEP: { value: sky.skyOption.step },
			fogy: { value: 0.05 },

			fogNear: { value: 1 },
			fogFar: { value: 100 },
			t: { value: 0 },
			// extra color
			fogColor: { value: new Color(sky.skyOption.fogColor) },
			groundColor: { value: new Color(sky.skyOption.groundColor) },
			cloudColor: { value: new Color(sky.skyOption.cloudColor) },
			skyColor: { value: new Color(sky.skyOption.skyColor) },
			sunColor: { value: sky.sunColor }, // TODO: Not Implement
		},
		vertexShader: SkyVert,
		fragmentShader: SkyShader,
		depthWrite: false,
		depthTest: false,
		side: BackSide,
	});

	sky.renderSkyScene = new FasterScene();

	sky.skySphere = new Mesh(new IcosahedronGeometry(1, 1), sky.materialSky);
	sky.skySphere.name = "SkySphereMesh";
	sky.renderSkyScene.add(sky.skySphere);

	sky.materialGround = new MeshBasicMaterial({
		name: "SkyGroundMaterial",
		side: BackSide,
		depthWrite: false,
		depthTest: false,
	});

	// hide bottom skymap
	const p = new SphereGeometry(10, 6, 3, 0, PI);
	p.rotateX(PI * 0.5);

	sky.cubeCameraRender = new WebGLCubeRenderTarget(sky.skyResolution, {
		type: HalfFloatType,
	});
	sky.cubeCamera = new CubeCamera(0.5, 2, sky.cubeCameraRender);
	sky.cubeCamera.position.y = 0.01;
	sky.renderSkyScene.add(sky.cubeCamera);

	if (sky.isAutoSky) {
		const envMap = sky.cubeCameraRender.texture;
		world.client.sceneSky.background = envMap;
	}
}

/**
 * @param {import("base/world/fx/Sky").SkyBase} sky
 */
export function clearShaderSky(sky) {
	if (!sky.isAutoSky) return;

	sky.skySphere.removeFromParent();
	sky.cubeCamera.removeFromParent();

	sky.skySphere.geometry.dispose();

	sky.cubeCameraRender.dispose();
	sky.materialSky.dispose();
	sky.materialGround.dispose();
	sky.noiseMap.dispose();
}

/**
 * @param {import("base/world/fx/Sky").SkyBase} sky
 * @param {number} frameDeltaTime
 */
export function renderSky(sky, frameDeltaTime) {
	if (sky.materialSky) {
		sky.materialSky.uniforms.lightdir.value = sky.sunPosition;
		sky.materialSky.uniforms.nightLuminosity.value = sky.nightLuminosity;
		sky.materialSky.uniforms.t.value += frameDeltaTime;
	}

	sky.cubeCamera.update(BB.client.renderer, sky.renderSkyScene);
	/*
	//const s = sky.skyResolution * 0.5;
	//const read = sky.readPixel;

	// get fog color
	//BB.renderer.readRenderTargetPixels(sky.cubeCameraRender, 0, s+5, 1, 1, read, 0);
	//sky.fogColor.setRGB(float16ToNumber(read[0]), float16ToNumber(read[1]), float16ToNumber(read[2]));
	//sky.fogColor.setRGB(float16ToNumber(read[0]), float16ToNumber(read[1]), float16ToNumber(read[2]), SRGBColorSpace);//, SRGBColorSpace
	//sky.fogColor.convertSRGBToLinear()
	//sky.fogColor.convertLinearToSRGB()

	// get sky color
	//BB.renderer.readRenderTargetPixels(sky.cubeCameraRender, s, s, 1, 1, read, 2);
	//sky.skyColor.setRGB(float16ToNumber(read[0]), float16ToNumber(read[1]), float16ToNumber(read[2]));

	// update colors
	//sky.sunHemisphere.color.copy( sky.skyColor );
	//sky.sunHemisphere.groundColor.copy( sky.groundColor );
	// world.scene.setFog( sky.fogColor );
	//sky.materialGround.color.copy( sky.fogColor );

	//sky.materialGround.uniforms.color.value = sky.fogColor;

	//if(sky.lightProbe) sky.lightProbe.copy( LightProbeGenerator.fromCubeRenderTarget( sky.renderer, sky.cubeCamera ) );
	*/
}

export function htmlToHex(v) {
	return v.toString().toUpperCase().replace("#", "0x");
}

export function update(world, frameDeltaTime) {
	const sky = world.sky;

	const hour = sky.time * 24;
	const hourStr = Math.floor(hour);
	const minute = (hour - hourStr) * 60;

	const inclination = hour * 15 - 90;

	sky.sunSph.phi = (inclination - 90) * DEGRAD;
	sky.sunSph.theta = sky.azimuth + PIH;
	sky.sunPosition.setFromSpherical(sky.sunSph).normalize();

	if (sky.lensGroup) {
		sky.lensGroup.position.copy(sky.sunPosition).multiplyScalar(sky.lensGroup.distance);
	}

	getSunColor(sky);
	sky.sunDirectional.color.copy(sky.sunColorReal);
	if (sky.sunDirectional2) sky.sunDirectional2.color.copy(sky.sunColorReal);
	sky.sunMat.color.copy(sky.sunColorReal);

	const lm = sky.sunTop.dot(sky.sunPosition);
	const day = MathUtils.clamp(lm * 4.0, 0.0, 1.0 - sky.nightLuminosity) + sky.nightLuminosity;

	// apply light change to particle
	world.client.particleEngine.luminosity = day;

	sky.sunDirectional.intensity = sky.maxSunIntensity;

	if (sky.sunDirectional2) {
		sky.sunDirectional.intensity = sky.maxSunIntensity * 0.5 * Math.PI;
		sky.sunDirectional2.intensity = sky.maxSunIntensity * 0.5 * Math.PI;
	} else {
		sky.sunDirectional.intensity = sky.maxSunIntensity * Math.PI;
	}

	if (sky.lastMinute !== minute) {
		sky.lastMinute = minute;

		if (sky.isAutoSky) {
			// copy fog color from shader and adjust for time of day
			BB.world.scene.fog.color.copy(sky.materialSky.uniforms["fogColor"].value).multiplyScalar(day);
			renderSky(sky, frameDeltaTime);
		}
	}

	const radTime = sky.time * PI2;
	const rot = sky.azimuth;
	const camPos = world.client.renderCamera.position;

	// correct the shadow shake
	const d = camPos.distanceTo(sky.lightGroup.position);
	if (d > 5) sky.lightGroup.position.copy(camPos);

	sky.sunDir.copy(VY).applyEuler(tmpEuler.set(0, rot, radTime));

	const suny = -sky.sunDir.y;
	if (suny < 0) sky.sunDir.y = 0;
	sky.sunDir.x *= sky.sunDir.y;
	sky.sunDir.z *= sky.sunDir.y;

	if (sky.isAutoSky) {
		sky.sunDirectional.target.position.copy(sky.sunPosition).negate();
		if (sky.sunDirectional2) sky.sunDirectional2.target.position.copy(sky.sunPosition).negate();
	} else {
		sky.sunDirectional.color.set(0xffffff).multiplyScalar(sky.sunDir.length() * sky.maxDiffuse);
	}

	sky.skyGroup.position.copy(camPos);
	sky.skyGroup.rotation.set(radTime - PIH, rot + PIH, 0);
	sky.starMat.opacity = Math.max(-suny, 0);
	if (sky.starMat.userData.shader) sky.starMat.userData.shader.uniforms.angle.value = radTime - PIH;
	sky.starMesh.visible = sky.starMat.opacity > 0;
}

export function getSunColor(sky) {
	const n = skyKColor(sky.sunTop, sky.sunPosition);
	const a = skyZColor(sky.sunCol, n, sky.sunMax, 0.028, sky.sunPosition);

	a.r = MathUtils.clamp(a.r, sky.nightLuminosity, 1.0);
	a.g = MathUtils.clamp(a.g, sky.nightLuminosity, 1.0);
	a.b = MathUtils.clamp(a.b, sky.nightLuminosity, 1.0);

	sky.sunColorReal.setRGB(a.r, a.g, a.b, SRGBColorSpace);
	sky.sunColorReal.multiply(sky.sunColor);
}

export function skyKColor(e, t) {
	const n = t.dot(t),
		a = 2 * t.dot(e),
		o = e.dot(e) - 1,
		r = a * a - 4 * n * o,
		i = Math.sqrt(r),
		l = (-a - i) * 0.5,
		s = o / l;
	return s;
}

export function skyZColor(c, e, t, n, a) {
	const r = a.y >= 0 ? 1 : 0;
	return {
		r: (t.x - t.x * Math.pow(c.x, n / e)) * r,
		g: (t.y - t.y * Math.pow(c.y, n / e)) * r,
		b: (t.z - t.z * Math.pow(c.z, n / e)) * r,
	};
}

export function dispose(sky) {
	sky.skyGroup.removeFromParent();
	sky.lightGroup.removeFromParent();
	sky.lensGroup.removeFromParent();
	sky.sunProbe.removeFromParent();

	sky.starMat.dispose();
	sky.starGeom.dispose();
	sky.moonMat.dispose();
	sky.sunMat.dispose();
	sky.smGeom.dispose();

	clearShaderSky(sky);
}

export const assets = [
	{ url: "assets/engine/textures/tex-moon.png", id: "tex-moon", type: "image/png" },
	{ url: "assets/engine/textures/tex-sun.png", id: "tex-sun", type: "image/png" },
	{
		url: "assets/engine/textures/tex-clouds.png",
		id: "tex-clouds",
		type: "image/png",
	},
	{
		id: "tex-lensflare0",
		url: "assets/engine/textures/lensflare0.png",
		type: "image/png",
	},
	{
		id: "tex-lensflare2",
		url: "assets/engine/textures/lensflare2.png",
		type: "image/png",
	},
	{
		id: "tex-probe-nx",
		url: "assets/engine/textures/probe/tex-probe-nx.png",
		type: "image/png",
	},
	{
		id: "tex-probe-px",
		url: "assets/engine/textures/probe/tex-probe-px.png",
		type: "image/png",
	},
	{
		id: "tex-probe-ny",
		url: "assets/engine/textures/probe/tex-probe-ny.png",
		type: "image/png",
	},
	{
		id: "tex-probe-py",
		url: "assets/engine/textures/probe/tex-probe-py.png",
		type: "image/png",
	},
	{
		id: "tex-probe-nz",
		url: "assets/engine/textures/probe/tex-probe-nz.png",
		type: "image/png",
	},
	{
		id: "tex-probe-pz",
		url: "assets/engine/textures/probe/tex-probe-pz.png",
		type: "image/png",
	},
];

const SkyVert = `
varying vec3 worldPosition;
void main()	{

	worldPosition = ( modelMatrix * vec4( position, 1.0 )).xyz;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

}
`;

const SkyShader = `
//precision highp float;
varying vec3 worldPosition;

uniform vec3 fogColor;
uniform vec3 groundColor;
uniform vec3 cloudColor;
uniform vec3 skyColor;
uniform vec3 sunColor;

uniform float saturation;

uniform float hue;
uniform float mixRatio;
uniform float fogy;

uniform vec3 sunTop;

uniform sampler2D noiseMap;
uniform vec3 lightdir;


uniform float cloud_size;
uniform float cloud_covr;
uniform float cloud_dens;
uniform float cloud_dist;

uniform float nightLuminosity;
uniform float haze;
uniform float t;

uniform int SAMPLE;
uniform int STEP;

//const float c = 6.36e6;
//const float d = 6.38e6;
const float c = 6.407e6;
const float d = 6.416e6;

//const float g = 0.76; // mix ratio
//const float h = g*g;
const float icc = 1.0/8e3;
const float jcc = 1.0/1200.0;
const float pi = 3.141592653589793;

const vec3 vm = vec3( 0,-c,0 );
//const vec3 vn = vec3( 2.1e-5 );
//const vec3 vo = vec3( 5.8e-6, 1.35e-5, 3.31e-5 );

//const vec3 vn = vec3( 0.000021 );
//const vec3 vo = vec3( 0.0000058, 0.0000135, 0.0000331 );// sky base color

//const vec3 vo = vec3( 0.000021 );// sky base color






#ifdef USE_NOISE_MAP

float noise( in vec3 x ){
    vec3 p = floor(x);
    vec3 f = fract(x);
    f = f*f*(3.0-2.0*f);
    vec2 uv = (p.xy+vec2(37.0,17.0)*p.z) + f.xy;
    vec2 rg = texture2D( noiseMap, (uv+0.5)/256.0, -16.0 ).yx;
    return mix( rg.x, rg.y, f.z );
}

#else

float hash( float n ) { return fract(sin(n)*753.5453123); }
float noise( in vec3 x ){
    vec3 p = floor(x);
    vec3 f = fract(x);
    f = f*f*(3.0-2.0*f);
    float n = p.x + p.y*157.0 + 113.0*p.z;
    return mix(mix(mix( hash(n+  0.0), hash(n+  1.0),f.x),
                   mix( hash(n+157.0), hash(n+158.0),f.x),f.y),
               mix(mix( hash(n+113.0), hash(n+114.0),f.x),
                   mix( hash(n+270.0), hash(n+271.0),f.x),f.y),f.z);
}

#endif

float NOISE( vec3 r )
{
	r.xz += t;
	r *= 0.5;
	float s;
	s = 0.5 * noise(r);
	r = r * 2.52;
	s += 0.25 * noise(r);
	r = r * 2.53;
	s += 0.125 * noise(r);
	r = r * 2.51;
	s += 0.0625 * noise(r);
	r = r * 2.53;
	s += 0.03125 * noise(r);
	r = r * 2.52;
	s += 0.015625 * noise(r);
	return s;
}

float MakeNoise( vec3 r )
{
	float s,tt;
	s = NOISE( r * 2e-4 * ( 1.0 - cloud_size ) );
	tt = ( 1.0 - cloud_covr ) * 0.5 + 0.2;
	s = smoothstep( tt, tt+.2 , s );
	s *= 0.5*(cloud_dens*100.0);
	return s;
}

void clouds( in vec3 r, out vec3 u )
{
	float v,w;
	v = length( r-vm ) - c;
	w = 0.0;
	if( 5e3 < v && v < 1e4 ) w = MakeNoise( r ) * (sin( pi*(v-5e3)/5e3 ));
	u = vec3( exp(-v*icc), exp(-v*jcc), w );
}

float ca( in vec3 r, in vec3 s, in float t )
{
	vec3 u = r - vm;
	float v,w,x,y,z,A;
	v = dot(u,s);
	w = dot(u,u)-t*t;
	x = v*v-w;
	if( x < 0.0 ) return -1.0;
	y = sqrt(x);
	z = -v-y;
	A = -v+y;
	return z >= 0.0 ? z : A;
}

vec3 czm_saturation(vec3 rgb, float adjustment)
{
    vec3 W = vec3(0.2125, 0.7154, 0.0721);
    vec3 intensity = vec3(dot(rgb, W));
    return mix(intensity, rgb, adjustment);
}


vec3 makeSky( in vec3 lightpos, in vec3 r, in vec3 world, out float mask )
{

	vec3 vn = vec3( 0.000021 );
	vec3 vo = skyColor;
	vo *= 0.00005;

	float u,v,w,x,y,z,m, M, N, S, H, F;
	vec3 p = lightpos;
	u = ca(r,world,d);
	v = dot(world,p);
	w = 1.0+v*v;

	float gg = mixRatio;
	float hh = gg*gg;

	x = 0.0596831*w;
	y = 0.0253662*(1.0-hh)*w/((2.0+hh)*pow(abs(1.0+hh-2.0*gg*v),1.5));
	z = 50.*pow(abs(1.+dot(world,-p)),2.0)*dot(vec3(0.,1.,0.),p)*(1.0-cloud_covr)*(1.0-min(fogy,1.0));

	m = 0.0;
	vec3 D,E, CB, CM, BB, BM, SX;

	F = u / float( SAMPLE );

	BB = vec3(0.0);
	BM = vec3(0.0);

	float count = 0.0;

	for( int G=0; G<SAMPLE; ++G ){

		H = float(G)*F;
		vec3 I = r + world * H;
		//CB = vec3(1.0);
		//BB = vec3(0.0);
		clouds( I, CB );
		CB += fogy;// add fog
		CB.y += CB.z;// add clound
		CB.xy *= F;
		BB += CB;

		M = ca(I,p,d);

		if( M > 0.0 ){

			N = M/float(STEP);
			BM = vec3(0.0);

			for( int R=0; R<STEP; ++R ){

				S = float(R)*N;
				vec3 T=I+p*S;
				clouds( T, CM );
				CM += fogy;// add fog
				CM.y += CM.z;// add clound
				BM += CM * N;

			}

			SX = exp(-(vo*(BM.x+BB.x)+vn*(BM.y+BB.y)* cloud_dist));

			m += CB.z;
			count += 1.0;
			D += SX*CB.x;
			E += (SX*CB.y)+z*m;
		}
		else return vec3(0.0);
	}
	//mask = m * 0.0125;
	//mask = m / count;
	mask = m / float( SAMPLE );

	return ((D * vo * x ) + (E * vn * y * sunColor)) * 15.0;
}


void main()
{
	vec3 light = normalize( lightdir );
	vec3 world = normalize( worldPosition.xyz );

	float uvy = acos( world.y ) / pi;

	//float luma = smoothstep(0.0, 4.0,  1.0-(abs(world.y)/0.8) );
    //float mid = smoothstep(0.0, 1.0,  abs(world.y) < haze ? 1.0-(abs(world.y)/(haze*1.0)) : 0.0 );
    //mid *= nightLuminosity;//pow(  mid, 1.0 );

    // ground reapeat sky
	if( world.y < -0.15) world.y = -0.15+((-world.y-0.15)*0.1);

	float high = smoothstep(1.0, 0.0, (uvy)*10000.0);
	float top =  smoothstep(1.0, 0.0, (uvy-0.5)*50.0);
	float middle = uvy > 0.5 ? high : smoothstep(0.0, 1.0, (0.5-uvy)*((1.0-haze)*100.0));

	vec3 s = sunTop;
	float lm = dot( s, light );
	float day = clamp((lm*4.0), 0.0, (1.0-nightLuminosity) )+nightLuminosity;

	if(lm <= 0.0) light *= -1.0;
	light.y = abs(light.y);

	//if(light.y < 0.1) light.y = 0.1;
	light.y = clamp(light.y, 0.1, 1.0 );
	//light.y += 0.5;

	float mask = 0.0;

	vec3 sky = makeSky( light, s, world, mask );
	mask = clamp(mask, 0.0, 1.0 );
	sky = mix( sky, cloudColor, mask ); //apply cloud color
	sky = mix( sky, fogColor, 1.0-middle ); // apply fog color
	
    //float dd = clamp(day+(nightLuminosity*0.5), 0.0, 1.0);
	//luma *= 1.0-dd;
	//clear = mix( clear, clear+skyColor, luma ); // extra luminosity on night

	sky *= day;
	sky = czm_saturation(sky, saturation);
    sky = clamp(sky, 0.0, 1.0 );


 	gl_FragColor = vec4( sky, 1.0 );

}`;

/*
const SkyFrag = `
varying vec3 worldPosition;
uniform vec3 fogColor;
uniform vec3 groundColor;
uniform vec3 cloudColor;
uniform vec3 skyColor;

uniform float saturation;

uniform vec3 sunTop;

uniform sampler2D noiseMap;
uniform vec3 lightdir;
uniform float fog;

uniform float cloud_size;
uniform float cloud_covr;
uniform float cloud_dens;
uniform float cloud_dist;

uniform float nightLuminosity;
uniform float haze;
uniform float t;

uniform int SAMPLE;
uniform int STEP;

const float c = 6.36e6;
const float d = 6.38e6;

const float g = 0.76;
const float h = g*g;
const float icc = 1.0/8e3;
const float jcc = 1.0/1200.0;
const float pi = 3.141592653589793;

const vec3 vm = vec3( 0,-c,0 );
const vec3 vn = vec3( 2.1e-5 );
const vec3 vo = vec3( 5.8e-6, 1.35e-5, 3.31e-5 );

#ifdef USE_NOISE_MAP

float noise( in vec3 x ){
    vec3 p = floor(x);
    vec3 f = fract(x);
    f = f*f*(3.0-2.0*f);
    vec2 uv = (p.xy+vec2(37.0,17.0)*p.z) + f.xy;
    vec2 rg = texture2D( noiseMap, (uv+0.5)/256.0, -16.0 ).yx;
    return mix( rg.x, rg.y, f.z );
}

#else

float hash( float n ) { return fract(sin(n)*753.5453123); }
float noise( in vec3 x ){
    vec3 p = floor(x);
    vec3 f = fract(x);
    f = f*f*(3.0-2.0*f);
    
    float n = p.x + p.y*157.0 + 113.0*p.z;
    return mix(mix(mix( hash(n+  0.0), hash(n+  1.0),f.x),
                   mix( hash(n+157.0), hash(n+158.0),f.x),f.y),
               mix(mix( hash(n+113.0), hash(n+114.0),f.x),
                   mix( hash(n+270.0), hash(n+271.0),f.x),f.y),f.z);
}

#endif

float NOISE( vec3 r )
{
	r.xz += t;
	r *= 0.5;
	float s;
	s = 0.5 * noise(r);
	r = r * 2.52;
	s += 0.25 * noise(r);
	r = r * 2.53;
	s += 0.125 * noise(r);
	r = r * 2.51;
	s += 0.0625 * noise(r);
	r = r * 2.53;
	s += 0.03125 * noise(r);
	r = r * 2.52;
	s += 0.015625 * noise(r);
	return s;
}

float MakeNoise( vec3 r )
{
	float s,t;
	s = NOISE( r * 2e-4 * ( 1.0 - cloud_size ) );
	t = ( 1.0 - cloud_covr ) * 0.5 + 0.2;
	s = smoothstep( t, t+.2 , s );
	s *= 0.5*(cloud_dens*100.0);
	return s;
}

void clouds( in vec3 r, out vec3 CL )
{
	float v,w;
	v = length( r-vm ) - c;
	w = 0.0;
	if( 5e3 < v && v < 1e4 ) w = MakeNoise( r ) * (sin( pi*(v-5e3)/5e3 ));
	CL = vec3( exp(-v*icc), exp(-v*jcc), w );
}

float ca( in vec3 r, in vec3 s, in float t )
{
	vec3 u = r - vm;
	float v,w,x,y,z,A;
	v = dot(u,s);
	w = dot(u,u)-t*t;
	x = v*v-w;
	if( x < 0.0 ) return -1.0;
	y = sqrt(x);
	z = -v-y;
	A = -v+y;
	return z >= 0.0 ? z : A;
}

vec3 makeSky( in vec3 lightpos, in vec3 r, in vec3 s, out float t, out vec3 cc)
{
	float u,v,w,x,y,z,m, M, N,S, H,F;
	vec3 p = lightpos;
	u = ca(r,s,d);
	v = dot(s,p);
	w = 1.0+v*v;
	x = 0.0596831*w;
	y = 0.0253662*(1.0-h)*w/((2.0+h)*pow(abs(1.0+h-2.0*g*v),1.5));
	z = 50.*pow(abs(1.+dot(s,-p)),2.0)*dot(vec3(0,1,0),p)*(1.0-cloud_covr)*(1.0-min(fog,1.0));

	m = 0.0;
	vec3 D,E;
	vec3 DD,EE;

	vec3 CB, CM, BB, BM, AB, AM, DB, DM;
	F = u / float( SAMPLE );

	BB = vec3(0.0);
	DB = vec3(0.0);

	for( int G=0; G<SAMPLE; ++G ){

		H = float(G)*F;
		vec3 I = r + s * H;

		clouds( I, CB );
		AB = CB;

		CB.y += CB.z;// add clound
		CB += fog;// add fog

		CB.xy *= F;
		AB.xy *= F;

		BB += CB;
		DB += AB;

		M = ca(I,p,d);

		if( M > 0.0 ){

			N = M/float(STEP);
			BM = vec3(0.0);
			DM = vec3(0.0);

			for( int R=0; R<STEP; ++R ){

				S = float(R)*N;
				vec3 T=I+p*S;

				clouds( T, CM );

				AM = CM;

				CM.y += CM.z;// add clound
				CM += fog;// add fog

				BM += CM * N;
				DM += AM * N;

			}

			vec3 S = exp(-(vo*(BM.x+BB.x)+vn*(BM.y+BB.y)* cloud_dist));
			vec3 SC = exp(-(vo*(DM.x+DB.x)+vn*(DM.y+DB.y)));

			m += CB.z;
			D += S*CB.x;
			E += (S*CB.y)+z*m;

			DD += SC*AB.x;
			EE += SC*AB.y+z;
		}
		else return vec3(0.0);
	}
	t = clamp( (m / float( SAMPLE )), 0.0, 1.0 );
	cc = ( (DD * vo * x) + (EE * vn * y)) * 15.0; // clear sky
	return ( (D * vo * x) + (E * vn * y)) * 15.0; // cloud sky
}

vec3 czm_saturation(vec3 rgb, float adjustment)
{
    vec3 W = vec3(0.2125, 0.7154, 0.0721);
    vec3 intensity = vec3(dot(rgb, W));
    return mix(intensity, rgb, adjustment);
}


vec3 filmicToneMapping(vec3 color)
{
	color = max(vec3(0.), color - vec3(0.004));
	color = (color * (6.2 * color + .5)) / (color * (6.2 * color + 1.7) + 0.06);
	color = czm_saturation(color, saturation);
	return color;
}



void main()
{
	vec3 light = lightdir;
	vec3 r = normalize( worldPosition );
	float uvy = acos( r.y ) / pi;

	float old = r.y;
	if(r.y < -0.15) r.y = -0.15+((-old-0.15)*0.1);

	float high = smoothstep(1.0, 0.0, (uvy-0.002)*10000.0);
	float top =  smoothstep(1.0, 0.0, (uvy-0.50)*1000.0);
	float top1 =  smoothstep(1.0, 0.0, (uvy-0.50)*30.0);
	float mid = uvy > 0.5 ? smoothstep(0.0, 1.0, (uvy-0.5)*(haze*100.0)) : smoothstep(0.0, 1.0, (0.5-uvy)*(haze*100.0));

	vec3 s = sunTop;
	float lm = dot( s, light );
	float day = clamp((lm*4.0), 0.0, (1.0-nightLuminosity) )+nightLuminosity;

	if(lm <= 0.0) light *= -1.0;
	light.y = abs(light.y);

	float midd = pow(  mid, abs(lm)-0.2 );
	midd = clamp( midd, 0.0, 1.0 );
	mid = clamp( mid, 0.0, 1.0 );

	float m = 0.0;
	vec3 cc = vec3(0.0);

	vec3 sky = makeSky( light, s, r, m, cc );

	m = pow( abs( m ), .9 );

	sky = filmicToneMapping(sky) * day;
	cc = filmicToneMapping(cc) * day;
	cc *= skyColor;

	sky = mix( cc, sky*cloudColor, m);
	sky = mix( cc, sky, top1 );

	//sky = mix( groundColor * day, sky, top);
	//sky = mix( fogColor * day, sky, top);

	//cc = mix( fogColor * day , cc, midd );
	//cc = mix( fogColor * day , cc, top );

	sky = mix( sky, cc, high);
	sky = mix( cc, sky, mid);

	gl_FragColor = vec4( sky, 1.0 );

}
`;


export function initLensFlare(sky) {
	const ts = 64;
	const m = ts * 0.5;
	const canvas = document.createElement("canvas");
	canvas.width = canvas.height = ts;
	const ctx = canvas.getContext("2d");
	const gradient = ctx.createRadialGradient(m, m, 0, m, m, m);
	gradient.addColorStop(0.8, "#333333");
	gradient.addColorStop(1, "black");
	ctx.fillStyle = gradient;
	ctx.fillRect(0, 0, ts, ts);

	sky.lensGroup.distance = 1000;

	world.scene.add(sky.lensGroup);
	world.router.lensGroupLink = sky.lensGroup;

	const s = 1;

	const textureFlare0 = Loaders.assets.get("lensflare0");
	const textureFlare3 = new CanvasTexture(canvas); //world.textures[`lensflare2`]

	sky.lensflare = new Lensflare();

	sky.lensflare.addElement(
		new LensflareElement(textureFlare0, 200 * s, 0, sky.sunColor),
	);
	sky.lensflare.addElement(
		new LensflareElement(textureFlare3, 60 * s, 0.6, sky.sunColor),
	);
	sky.lensflare.addElement(
		new LensflareElement(textureFlare3, 70 * s, 0.7, sky.sunColor),
	);
	sky.lensflare.addElement(
		new LensflareElement(textureFlare3, 120 * s, 0.9, sky.sunColor),
	);
	sky.lensflare.addElement(
		new LensflareElement(textureFlare3, 70 * s, 1, sky.sunColor),
	);

	sky.lensGroup.add(sky.lensflare);
} */
