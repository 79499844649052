export class CharacterHUDComponent {
	name = "characterHUD";

	state: {
		zenMode: boolean;
	};

	constructor() {
		this.state = {
			zenMode: false,
		};
	}
}
