import { isNullish } from "@jamango/helpers";
import { NetEventDispatcher } from "base/util/NetEventDispatcher.js";
import { Pathfinding } from "base/world/pathfinding/Pathfinding.js";
import { SFXManager } from "base/world/fx/SFXManager";
import { Matrix4 } from "three";
import { V0, VNZ, VX, VY } from "base/util/math/Math.ts";
import { ItemPathTester } from "mods/defs/ItemPathTester";

let worldID = 0;

export class World {
	/** @type {Map<import('ibs').Peer | import("ibs").LoopbackPeer, import('base/world/entity/Character').Character>} */
	peerToPlayer; //you can safely assert that .get() returns not null

	/** @type {Map<import('base/world/entity/Entity').Entity, import('ibs').Peer>} */
	playerToPeer;

	/** @type {import('base/world/block/Scene').ChunkScene} */
	scene;

	/** @type {import('base/world/block/BlockGroups').BlockGroupsState} */
	blockGroups;

	/** @type {import('base/world/block/BlockTypeRegistry').BlockTypeRegistryState} */
	blockTypeRegistry;

	/** @type {import('@jamango/content-client/lib/types/engine.ts').IEngineWorldData} */
	def;

	/** @type {Map<string,import('@jamango/content-client/lib/types/engine.ts').IEngineDef>}*/
	defs;

	/** @type {import('base/world/fx/Sky').SkyBase} */
	sky;

	/** @type {import('base/world/Quest').State} */
	quest;

	/** @type {number} */
	frame;

	/** @type {number} */
	time;

	/** @type {SFXManager} */
	sfxManager;

	/** @type {import('server/world/World').WorldServer} */
	server;

	/** @type {import('client/world/World').WorldClient} */
	client;

	/** @type {import('router/world/World').WorldRouter} */
	router;

	/** @type {import('base/world/entity/Entity').Entity[]} */
	entities;

	/** @type {ReturnType<import('base/world/InputManager').init>} */
	input;

	/** @type {import('base/world/Settings').Settings} */
	environmentSettings;

	/** @type {import('base/dom/CustomUI').CustomUIState} */
	customUI;

	/** @type {import("base/world/fx/VFXManager").VFXManager} */
	vfx;

	/** @type {import('base/world/ProjectileManager').ProjectileManager} */
	projectiles;

	/** @type {import('base/world/ConstraintManager').ConstraintManagerState} */
	constraints;

	/** @type {import('base/world/ChunkManager').ChunkManager} */
	chunks;

	/** @type {import('base/dom/HUDPopup').HUDPopup} */
	hudPopup;

	/** @type {import("base/rete/Types").ReteState} */
	rete;

	/** @type {Array<import("./entity/Entity").Entity>} */
	deferredDispose;

	/** @type {ReturnType<import('base/world/entity/system/JacyContentSyncer').init>} */
	jacySyncer;

	/** @type {import('base/world/WorldEditor').WorldEditorState} */
	editor;

	/** @type {import('@jamango/content-client').JacyContent} */
	content;

	/** @type {import('base/world/Physics').PhysicsState} */
	physics;

	/** @type {import('base/world/SceneTree').SceneTreeState} */
	sceneTree;

	/**
	 * @param {import('@jamango/content-client/lib/types/engine.ts').IEngineWorldData} o
	 * @param {import('@jamango/content-client').JacyContent} content
	 **/
	constructor(o, content) {
		this.def = o;
		this.content = content;

		this.frame = 0;
		this.step = 0;
		this.time = 0;

		/*
		enforces a 1:1 relationship between player entities and clients
		https://www.notion.so/jamango/Client-player-project-6d3db66f20f041cbb9edf8df35627ab1
		*/
		this.entityIdCounter = 0;
		this.entities = [];
		/** @type {Map<import("@jamango/engine/EntityID.ts").EntityID, import('base/world/entity/Entity').Entity>} */
		this.idToEntity = new Map();
		/** @type {Map<string, import('base/world/entity/Entity').Entity>} */
		this.sceneTreeNodeToEntity = new Map();

		this.peerToPlayer = new WeakMap();
		this.playerToPeer = new WeakMap();

		this.experiments = o.experiments ?? [];

		this.worldID = worldID++;
		this.dispatcher = new NetEventDispatcher();

		this.playerDef = o.playerDef ?? "PlayerDefault";
		this.despawnTime = o.despawnTime ?? 10;
		this.characterCollisions = o.characterCollisions ?? true;

		if (this.despawnTime === -1) this.despawnTime = Infinity;

		this.defs = new Map();
		if (!isNullish(o.defs)) {
			for (const def of o.defs) {
				if (isNullish(def)) continue;
				if (this.defs.has(def.name)) throw Error(`Duplicate def ${def.name}`);

				this.defs.set(def.name, def);
			}
		}

		this.timers = new Map();

		// this matrix describes the up and forward vector in relation to the gravity
		this.sphUpMatrix = new Matrix4();
	}

	init() {
		this.frame = 0;
		this.step = 0;
		this.time = 0;

		this.entities.length = 0;
		this.idToEntity.clear();
		this.sceneTreeNodeToEntity.clear();

		this.tmpDefID = 0;
		this.deferredDispose = [];

		this.sfxManager = new SFXManager(this);
		this.pathfinding = new Pathfinding(this);
	}

	initScene() {
		this.onGravityChange();
	}

	onGravityChange() {
		const up = this.physics.gravity.clone().negate().normalize();
		if (up.length() === 0) up.copy(VY);

		//find a "default forward" vector
		const fwd = up.clone().cross(VX);
		if (fwd.lengthSq() === 0) fwd.copy(up).cross(VNZ);

		// set the spherical up matrix
		this.sphUpMatrix.lookAt(V0, fwd, up);

		for (const e of this.entities) {
			if (e.onGravityChange === undefined) continue;

			e.onGravityChange(up);
		}
	}

	/**
	 * @param {import("@jamango/engine/EntityID.ts").EntityID | number} id
	 * @returns {import("base/world/entity/Entity").Entity | undefined}
	 */
	getEntity(id) {
		return this.idToEntity.get(id);
	}

	/**
	 * @param {import("base/world/entity/Entity").Entity} entity
	 */
	addEntity(entity) {
		this.idToEntity.set(entity.entityID, entity);
		this.entities.push(entity);

		if (entity.sceneTree?.state.sceneTreeNode !== undefined) {
			this.sceneTreeNodeToEntity.set(entity.sceneTree.state.sceneTreeNode, entity);
		}
	}

	/**
	 * @param {import("base/world/entity/Entity").Entity} entity
	 */
	removeEntity(entity) {
		const i = this.entities.indexOf(entity);
		if (i > -1) this.entities.splice(i, 1);
		this.idToEntity.delete(entity.entityID);

		if (entity.sceneTree?.state.sceneTreeNode !== undefined) {
			this.sceneTreeNodeToEntity.delete(entity.sceneTree.state.sceneTreeNode);
		}

		return i;
	}

	remapPeerPlayer(peer, player) {
		const prvPeer = this.playerToPeer.get(player);
		const prvPlayer = this.peerToPlayer.get(peer);

		this.playerToPeer.delete(prvPlayer);
		this.peerToPlayer.delete(prvPeer);

		this.peerToPlayer.set(peer, player);
		this.playerToPeer.set(player, peer);
	}

	clearPeerPlayer(peer) {
		const player = this.peerToPlayer.get(peer);
		this.playerToPeer.delete(player);
		this.peerToPlayer.delete(peer);
	}

	/** @param {string} username */
	getEntityByUsername(username) {
		for (const e of this.entities) {
			if (e.authority.state.public < 2) {
				if (!e.type.def.isPlayer) continue;
				if (!e.nameplate) continue;

				if (e.nameplate.def.nameplate.getText() === username) return e;
			}
		}
	}

	getSpawnableDefs() {
		return Array.from(this.defs.values())
			.filter(function (e) {
				if (e.name.startsWith("Player") && e.isNPC) return true;
				if (e.name.startsWith("Item") && e.type !== "block") return true;

				return false;
			})
			.map(function (e) {
				if (e.name.startsWith("Item")) return "Armory" + e.name;

				return e.name;
			});
	}

	getItemDefs() {
		// filter out internal items
		const internalItems = [ItemPathTester.name];

		const items = Array.from(this.defs)
			.filter(([k, v]) => k.startsWith("Item") && v.type !== "block")
			.map(([k]) => k)
			.filter((def) => !internalItems.includes(def));

		return items;
	}

	getCharacterDefs() {
		return Array.from(this.defs)
			.filter(([k]) => k.startsWith("Player"))
			.map(([k, v]) => ({ ...v.meta, defName: k }));
	}

	getNPCDefs() {
		return Array.from(this.defs)
			.filter(([k, v]) => k.startsWith("Player") && v.isNPC && !isNullish(v.characterName))
			.map(([k, v]) => ({ ...v.meta, defName: k }));
	}

	setTimeout(cb, ms, surviveMapChange) {
		const timer = setTimeout(() => {
			this.timers.delete(timer);
			cb();
		}, ms);

		this.timers.set(timer, surviveMapChange);
		return timer;
	}

	setInterval(cb, ms, surviveMapChange) {
		const timer = setInterval(cb, ms);

		this.timers.set(timer, surviveMapChange);
		return timer;
	}

	clearTimeout(id) {
		clearTimeout(id);
		this.timers.delete(id);
	}

	clearInterval(id) {
		this.clearTimeout(id);
	}

	clearAllTimers(isMapChange) {
		for (const [timer, surviveMapChange] of this.timers)
			if (!surviveMapChange || !isMapChange) clearTimeout(timer);
	}

	dispose() {
		this.clearAllTimers(false);
	}
}
