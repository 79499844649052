import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateDeathIdle extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("DeathIdle", animMesh, locomotionInput, transitions);

		this.transitionToLandingTime = 0.2;
	}

	get deathIdleAction() {
		return this.mesh.getAction("JMGO_Unarmed_Death_Idle");
	}

	startCompute(transitionData) {
		this.deathIdleAction.reset();

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.deathIdleAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.deathIdleAction.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.deathIdleAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.lastFrameFirstPerson) {
			if (this.inputState.isDead) {
				outTransitionToState.ptr = map.get("DeathStart");
				outTransitionToState.blendTime = 0;
			} else {
				outTransitionToState.ptr = map.get("Idle");
				outTransitionToState.blendTime = 0;
			}

			return true;
		}

		//transition to landing state
		if (!this.inputState.isDead) {
			outTransitionToState.ptr = map.get("DeathEnd");
			outTransitionToState.blendTime = this.transitionToLandingTime;
			return true;
		}

		return false;
	}
}
