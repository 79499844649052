import type { EntityCreateOptions } from "base/world/entity/Entity";
import { Entity } from "base/world/entity/Entity";
import type { World } from "base/world/World";
import { serDes as untypedSerDes } from "base/world/entity/component/Serialization";
import { ParticleSystemComponent } from "base/world/entity/component/ParticleSystem";
import { netState } from "router/Parallelogram";

const serDes = untypedSerDes<ParticleSystemEntity>;

export class ParticleSystemEntity extends Entity {
	particleSystem: ParticleSystemComponent;

	constructor(o: EntityCreateOptions, def: { name: string }, world: World) {
		super(o, def, world);

		this.particleSystem = this.addComponent(new ParticleSystemComponent(this.entityID));
	}

	createVisualSimulation() {
		if (!netState.isClient) return;

		if (this.particleSystem.obj) return;
		if (!this.particleSystem.config) return;

		this.particleSystem.obj = this.world.client!.particleEngineV2.add(this.particleSystem.config);
		this.object3D.add(this.particleSystem.obj);

		// TODO: use a more general entity -> entity mounting / parenting system
		const parentId = this.particleSystem.parentEntityId;
		const parent = this.world.getEntity(parentId) as Entity;
		if (parent && parent !== this) {
			parent.object3D.add(this.object3D);
		}
	}

	destroyVisualSimulation() {
		if (!netState.isClient) return;

		if (!this.particleSystem.obj) return;

		this.world.client!.particleEngineV2.remove(this.particleSystem.obj);
		this.particleSystem.obj = null;
	}

	restartVisualSimulation() {
		if (!netState.isClient) return;

		if (!this.particleSystem.obj) return;

		this.world.client!.particleEngineV2.restart(this.particleSystem.obj);
	}

	playVisualSimulation() {
		if (!netState.isClient) return;

		if (!this.particleSystem.obj) return;

		this.world.client!.particleEngineV2.play(this.particleSystem.obj);
	}

	pauseVisualSimulation() {
		if (!netState.isClient) return;

		if (!this.particleSystem.obj) return;

		this.world.client!.particleEngineV2.pause(this.particleSystem.obj);
	}

	stopVisualSimulation() {
		if (!netState.isClient) return;

		if (!this.particleSystem.obj) return;

		this.world.client!.particleEngineV2.stop(this.particleSystem.obj);
	}

	dispose(worldIsDisposed?: boolean): true | undefined {
		if (super.dispose(worldIsDisposed)) return true;

		this.destroyVisualSimulation();
		this.particleSystem.config = null;
	}

	serialize(includeMovement?: boolean) {
		super.serialize(includeMovement);

		for (const id of ParticleSystemEntity.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}

		return this.serialization.diffs;
	}

	static serDesIds: number[] = [
		serDes(
			"particleSystem psPK",
			(e) => {
				return e.particleSystem.psPK;
			},
			(e, v) => {
				if (!v) return;

				if (e.particleSystem.psPK !== v) {
					e.particleSystem.psPK = v;
					e.particleSystem.config = null;
					e.particleSystem.dirty = true;
				}
			},
		),
		serDes(
			"particleSystem parentEntityId",
			(e) => {
				return e.particleSystem.parentEntityId;
			},
			(e, v) => {
				e.particleSystem.parentEntityId = v;
				e.particleSystem.dirty = true;
			},
		),
		serDes(
			"particleSystem nextState",
			(e) => {
				return e.particleSystem.nextState;
			},
			(e, v) => {
				e.particleSystem.nextState = v;
				e.particleSystem.dirty = true;
			},
		),
	];
}
