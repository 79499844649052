import { Sprite, SpriteMaterial } from "three";
import { BB } from "base/BB";

//depth test is disabled must set renderOrder yourself
//must dispose texture yourself
export class EasySprite extends Sprite {
	constructor(tex, scene, scale = 1) {
		super(
			new SpriteMaterial({
				map: tex,
				depthTest: false,
				depthWrite: false,
				transparent: true,
			}),
		);

		const maxPixelRatio =
			(BB.client.inputPoll.isMobileBrowser()
				? Math.min(window.devicePixelRatio, 1.5)
				: window.devicePixelRatio) ?? 1;

		this.width = tex.image?.width * maxPixelRatio;
		this.height = tex.image?.height * maxPixelRatio;
		this.texture = tex;
		this.image = tex.image;
		this.scene = scene;
		this.esScale = scale;

		this.scale.set(this.width * scale, this.height * scale, 1);
		this.center.set(0, 0);

		scene.add(this);
	}

	//only use this to copy other easy sprites
	copy(source) {
		this.dispose();
		super.copy(source);

		this.material = source.material.clone();

		this.width = source.width;
		this.height = source.height;
		this.texture = source.texture;
		this.image = source.image;
		this.scene = source.scene;
		this.esScale = source.esScale;

		this.scene.add(this);

		return this;
	}

	setTexture(tex) {
		this.material.map = tex;

		const maxPixelRatio =
			(BB.client.inputPoll.isMobileBrowser()
				? Math.min(window.devicePixelRatio, 1.5)
				: window.devicePixelRatio) ?? 1;

		this.width = tex.image?.width * maxPixelRatio;
		this.height = tex.image?.height * maxPixelRatio;
		this.scale.set(this.width * this.esScale, this.height * this.esScale, 1);

		this.texture = tex;
		this.image = tex.image;
	}

	centerX() {
		this.position.x = Math.ceil((BB.client.canvas.width - this.scale.x) / 2);
		return this;
	}

	centerY() {
		this.position.y = Math.ceil((BB.client.canvas.height - this.scale.y) / 2);
		return this;
	}

	centerXY() {
		return this.centerX().centerY();
	}

	dispose() {
		this.removeFromParent();
		this.material.dispose();
	}
}
