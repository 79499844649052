import * as listener from "base/rete/modules/listener.ts";
import { netState } from "router/Parallelogram";
import { NODE } from "base/rete/InternalNameMap";

export function proximityPromisesUpdate(entity) {
	if (!netState.isHost) return;

	const { world, entityID } = entity;
	const enterChecks = world.rete.listeners[NODE.OnEntityProximityEnter]?.[entityID];
	const leaveChecks = world.rete.listeners[NODE.OnEntityProximityLeave]?.[entityID];

	// if this entity has any active proximity checks from rete
	if (enterChecks !== undefined || leaveChecks !== undefined) {
		// iterate all other entities
		for (const e of world.entities) {
			// discard self/non character checks
			if (e === entity || !e.type.def.isCharacter) continue;
			// get the distance
			const dst = entity.position.distanceTo(e.position);
			if (enterChecks !== undefined) checkProximity(entity, e, dst, true, world, enterChecks);
			if (leaveChecks !== undefined) checkProximity(entity, e, dst, false, world, leaveChecks);
		}
	}
}

function checkProximity(entity, e, dst, isEnter, world, checks) {
	for (const { options } of checks) {
		// check whether the entity can actually trigger this check
		if (!isMatch(e, options.canTrigger)) continue;
		// calculate distance
		const result = isInProximity(entity, e, dst, options.radius, options.viewAngle);
		const inRangeIndex = options.inRange.indexOf(e.entityID); // TODO: use sets for inRange
		if (result === true && inRangeIndex < 0) {
			// if in range, but wasnt previously - we've entered
			options.inRange.push(e.entityID);
			if (isEnter)
				listener.onEntityEvent(NODE.OnEntityProximityEnter, world, entity.entityID, {
					entity,
					character: e,
				});
		} else if (result === false && inRangeIndex >= 0) {
			// if not in range, but we were previously, we've exited
			options.inRange.splice(inRangeIndex, 1);
			if (!isEnter)
				listener.onEntityEvent(NODE.OnEntityProximityLeave, world, entity.entityID, {
					entity,
					character: e,
				});
		}
	}
}

function isInProximity(curEntity, potentialMatch, curDst, targetDst, targetAngle) {
	return (
		!potentialMatch.isDead() &&
		curDst < targetDst &&
		curEntity.checkIfEntityIsInViewAngle(potentialMatch, targetAngle)
	);
}

function isMatch(potentialMatch, searchCriteria) {
	if (Array.isArray(searchCriteria)) {
		return (
			(potentialMatch.type.def.isPlayer && searchCriteria.includes("player")) ||
			(potentialMatch.type.def.isNPC && searchCriteria.includes("npc"))
		);
	} else {
		return searchCriteria === potentialMatch;
	}
}
