import { NODE_TYPE_ID } from "base/rete/Constants";
import { BB } from "base/BB";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { node } from "base/rete/Types";
import type { Character } from "base/world/entity/Character";
import { isNullish } from "@jamango/helpers";
import { HAIR_COLORS, SKIN_COLORS, type IAvatarObject } from "@jamango/content-client";

export const AVATAR_NODES = [
	node({
		id: "0011-0000-0000",
		name: "Character Copy Avatar",
		type: NODE_TYPE_ID.avatar,
		description: "Set target characters avatar to that of the source character",
		inputs: {
			exec: { type: "exec" },
			target: { name: "Target Character", type: "entity" },
			source: { name: "Source Character", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const targetCharacter = inputs.target as Character;
			const sourceCharacter = inputs.source as Character;
			targetCharacter.setAvatar(structuredClone(sourceCharacter.avatarObject));
		},
	}),
	node({
		id: "0011-0000-0001",
		name: "Get NPC Definition Avatar",
		type: NODE_TYPE_ID.avatar,
		description: "Get a predefined NPC's avatar",
		inputs: {
			avatar: {
				name: "NPC",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => {
						return BB.world.content.state.characters.getAll().map((character) => ({
							value: character.pk,
							label: character.name,
						}));
					},
				},
			},
		},
		outputs: {
			avatarObject: { name: "Avatar Object", type: "object" },
		},
		resolve(inputs) {
			const avatar = BB.world.content.state.characters.get(inputs.avatar);
			if (!avatar) throw Error("NPC definition not found");

			const avatarObject = BB.world.content.state.avatars.convertConfigToAvatarObject(avatar.aiAvatar);
			return { avatarObject };
		},
	}),
	node({
		id: "0011-0000-0002",
		name: "Character Get Avatar",
		type: NODE_TYPE_ID.avatar,
		description: "Get a characters avatar",
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			avatarObject: { name: "Avatar Object", type: "object" },
		},
		resolve(inputs) {
			const targetCharacter = inputs.character as Character;
			return { avatarObject: targetCharacter.avatarObject as any };
		},
	}),
	node({
		id: "0011-0000-0003",
		name: "Character Set Avatar",
		type: NODE_TYPE_ID.avatar,
		description: "Set a characters avatar to an avatar object",
		inputs: {
			exec: { type: "exec" },
			avatarObject: { name: "Avatar Object", type: "object" },
			target: { name: "Character to update", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const targetCharacter = inputs.target as Character;
			targetCharacter.setAvatar(inputs.avatarObject as IAvatarObject);
		},
	}),
	node({
		id: "0011-0000-0004",
		name: "Player Reset Avatar",
		type: NODE_TYPE_ID.avatar,
		description: "Return the player avatar to their default profile avatar",
		inputs: {
			exec: { type: "exec" },
			player: { name: "Player", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			// get player peer metadata info
			const player = inputs.player as Character;
			const peer = ctx.world.playerToPeer.get(player);
			if (peer === undefined) return;

			const avatarID = getPeerMetadata(peer).avatarID;
			const avatarConfig = BB.world.content.state.avatars.get(avatarID);

			const avatarObject = avatarID
				? //@ts-expect-error ask john for help
					BB.world.content.state.avatars.convertConfigToAvatarObject(avatarConfig)
				: undefined;

			// avatarObject can be undefined here - that'd be fine, it would reset to base default avatar.
			player.setAvatar(avatarObject);
		},
	}),
	node({
		id: "0011-0000-0005",
		name: "Character Set Avatar Parts",
		type: NODE_TYPE_ID.avatar,
		description: "Set the avatar body parts of a given character.",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			bodyType: {
				name: "Body Type",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [
						{ value: "body_type_1", label: "TYPE 1" },
						{ value: "body_type_2", label: "TYPE 2" },
					],
				},
			},
			hairColor: {
				name: "Hair Color",
				type: "string",
				control: "select",
				optional: true,
				config: {
					autoSortOptions: () =>
						HAIR_COLORS.map((color) => ({
							value: String(color.id),
							label: color.name,
						})),
				},
			},
			skinColor: {
				name: "Skin Color",
				type: "string",
				control: "select",
				optional: true,
				config: {
					autoSortOptions: () =>
						SKIN_COLORS.map((color) => ({
							value: String(color.id),
							label: color.name,
						})),
				},
			},
			torso: {
				name: "Torso",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "DEFAULT", label: "Default Torso" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "torso")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			legs: {
				name: "Legs",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "DEFAULT", label: "Default Legs" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "legs")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			backpack: {
				name: "Backpack",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "EMPTY", label: "Removed" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "backpack")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			hair: {
				name: "Hair",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [
						{ value: "DEFAULT", label: "Default Hair" },
						{ value: "EMPTY", label: "Removed" },
					],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "hair")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			face: {
				name: "Face",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "DEFAULT", label: "Default Face" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "face")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			hat: {
				name: "Hat",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "EMPTY", label: "Removed" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "hat")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
			mask: {
				name: "Mask",
				type: "string",
				control: "select",
				optional: true,
				config: {
					explicitSortOptions: [{ value: "EMPTY", label: "Removed" }],
					autoSortOptions: () =>
						BB.world.content.state.avatarComponents
							.getAll()
							.filter((component) => component.category === "mask")
							.map((component) => ({
								value: component.id,
								label: component.name,
							})),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const character = inputs.character as Character;
			const changedAvatarObject = structuredClone(character.avatarObject);

			type InputsWithoutCharacter = Omit<typeof inputs, "character">;
			for (const [input, inputValue] of Object.entries(inputs).filter(
				([input]) => input !== "character",
			) as [keyof InputsWithoutCharacter, InputsWithoutCharacter[keyof InputsWithoutCharacter]][]) {
				if (isNullish(inputValue)) continue;
				const configValue = inputValue === "DEFAULT" ? null : inputValue;
				//@ts-expect-error ask john for help
				BB.world.content.state.avatars.customizeConfig(changedAvatarObject, input, configValue);
			}
			character.setAvatar(changedAvatarObject);
		},
	}),
	node({
		id: "0011-0000-0006",
		name: "Character Get Avatar Parts",
		type: NODE_TYPE_ID.avatar,
		description: "Return the avatar body parts of a given character.",
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			bodyType: { name: "Body Type", type: "string", config: { collapsible: true } },
			hairColor: { name: "Hair Color", type: "string", config: { collapsible: true } },
			skinColor: { name: "Skin Color", type: "string", config: { collapsible: true } },
			torso: { name: "Torso", type: "string", config: { collapsible: true } },
			legs: { name: "Legs", type: "string", config: { collapsible: true } },
			backpack: { name: "Backpack", type: "string", config: { collapsible: true } },
			hair: { name: "Hair", type: "string", config: { collapsible: true } },
			face: { name: "Face", type: "string", config: { collapsible: true } },
			hat: { name: "Hat", type: "string", config: { collapsible: true } },
			mask: { name: "Mask", type: "string", config: { collapsible: true } },
		},
		resolve: (inputs) => {
			const avatarObject = (inputs.character as Character).avatarObject as any;

			return {
				bodyType: avatarObject.bodyType,
				hairColor: avatarObject.hairColor,
				skinColor: avatarObject.skinColor,
				torso: avatarObject.torso && avatarObject.torso.id,
				legs: avatarObject.legs && avatarObject.legs.id,
				backpack: avatarObject.backpack && avatarObject.backpack.id,
				hair: BB.world.content.state.avatars.getAvatarObjectBodyPartId(avatarObject.hair)!,
				face: avatarObject.face && avatarObject.face.id,
				hat: BB.world.content.state.avatars.getAvatarObjectBodyPartId(avatarObject.hat)!,
				mask: BB.world.content.state.avatars.getAvatarObjectBodyPartId(avatarObject.mask)!,
			};
		},
	}),
];
