import type { BB } from "base/BB";
import { UI } from "client/dom/UI";
import * as Net from "router/Net";
import { DEGRAD, RADDEG } from "base/util/math/Math.ts";
import { JSFRandom } from "base/util/math/Random.js";
import { wait } from "base/util/Time.js";
import { getBuiltIn } from "mods/Builtins.js";
import { bulkSphere, bulkBox } from "base/world/block/Bulk.js";
import { BLOCK_AIR, BLOCK_CUBE } from "base/world/block/Util.js";
import {
	Vector2,
	Vector3,
	Spherical,
	Matrix4,
	Quaternion,
	Color,
	Euler,
	Box3,
	Sphere,
	Box2,
	MathUtils,
	Object3D,
	Mesh,
	BoxGeometry,
	SphereGeometry,
	MeshBasicMaterial,
} from "three";

//everything exported will be assigned to globalThis.DEBUG
export const DEBUG = {
	BB: null! as typeof BB,
	UI,
	Net,

	DEGRAD,
	RADDEG,
	JSFRandom,
	wait,
	getBuiltIn,
	bulkSphere,
	bulkBox,
	BLOCK_AIR,
	BLOCK_CUBE,

	Vector2,
	Vector3,
	Object3D,
	Spherical,
	Matrix4,
	Quaternion,
	Color,
	Euler,
	Box3,
	Sphere,
	Box2,
	Mesh,
	BoxGeometry,
	SphereGeometry,
	MeshBasicMaterial,
	MathUtils,
} as const;
