import * as ChatRouter from "router/dom/Chat";

import type { World } from "base/world/World";
import { sendChatToServer } from "router/dom/Chat";
import type { ChatMessage } from "@jamango/frontend/Chat.ts";
import { UI } from "client/dom/UI";
import * as Net from "router/Net";

//called by frontend
export function sendChat(world: World, message: string) {
	sendChatToServer(world, message);
}

export function displayChat(chat: ChatMessage) {
	if (chat.message) UI.state.chat().displayChat(chat);
}

//client joined
export function initCommandListeners() {
	Net.listen("chat_relaymsg", function (chat: ChatMessage) {
		ChatRouter.displayChat(chat);
	});
}
