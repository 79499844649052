import { FakeWater } from "base/world/fx/FakeWater.js";
import { FakeWaterClient } from "client/world/fx/FakeWater.js";
import { netState } from "router/Parallelogram";

export class FakeWaterRouter {
	constructor(scene, o) {
		const base = (this.base = new FakeWater(scene, o));
		if (netState.isClient) base.client = new FakeWaterClient(base, o);

		base.router = this;
	}
}
