//STOP USING THIS
//specialized 2D/3D map that creates little to no garbage. omit the z arg for 2D mode. also accepts vector3 in 3D mode
export class LegacyVectorMap {
	constructor() {
		this.map = new Map();
		this.size = 0;
		this.dim = 0;
	}

	get(x, y, z) {
		if (x.isVector3) {
			z = x.z;
			y = x.y;
			x = x.x;
		}

		let cur = this.map;

		cur = cur.get(x);
		if (!cur) return undefined;

		if (arguments.length === 2) {
			return cur.get(y);
		} else {
			cur = cur.get(y);
			if (!cur) return undefined;

			return cur.get(z);
		}
	}

	set(x, y, z, val) {
		if (x.isVector3) {
			val = y;
			z = x.z;
			y = x.y;
			x = x.x;
		}

		let prv,
			cur = this.map;

		prv = cur;
		cur = prv.get(x);

		if (!cur) {
			cur = new Map();
			prv.set(x, cur);
		}

		if (arguments.length === 3) {
			if (this.dim === 3) throw RangeError("Call .clear() before changing dimension");
			this.dim = 2;

			if (!cur.has(y)) this.size++;

			cur.set(y, z);
		} else {
			if (this.dim === 2) throw RangeError("Call .clear() before changing dimension");
			this.dim = 3;

			prv = cur;
			cur = prv.get(y);

			if (!cur) {
				cur = new Map();
				prv.set(y, cur);
			}

			if (!cur.has(z)) this.size++;

			cur.set(z, val);
		}
	}

	has(x, y, z) {
		if (x.isVector3) {
			z = x.z;
			y = x.y;
			x = x.x;
		}

		return this.get(x, y, z) !== undefined;
	}

	//remaining functions will create some amount of garbage. concurrent modification is probably bad idk lol
	//yields the value. in a loop, outVec will be set to to the current key
	*iterate(outVec) {
		const dim = this.dim;
		if (dim !== 2 && dim !== 3) return;

		for (const xi of this.map) {
			for (const yi of xi[1]) {
				if (dim === 3) {
					for (const zi of yi[1]) {
						outVec?.set(xi[0], yi[0], zi[0]);
						yield zi[1];
					}
				} else {
					outVec?.set(xi[0], yi[0]);
					yield yi[1];
				}
			}
		}
	}

	delete(x, y, z) {
		if (x.isVector3) {
			z = x.z;
			y = x.y;
			x = x.x;
		}

		const xmap = this.map;

		const ymap = xmap.get(x);
		if (!ymap) return;

		if (arguments.length === 2) {
			if (ymap.has(y)) {
				ymap.delete(y);
				this.size--;
			}
		} else {
			const zmap = ymap.get(y);
			if (zmap) {
				if (zmap.has(z)) {
					zmap.delete(z);
					this.size--;
				}

				if (zmap.size === 0) ymap.delete(y);
			}
		}

		if (ymap.size === 0) xmap.delete(x);
	}

	clear() {
		this.map.clear();
		this.size = 0;
		this.dim = 0;
	}
}
