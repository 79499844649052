import { wait } from "base/util/Time.js";

export const HUD_DEFAULT_TIME = 3;

export class HUDPopup {
	/** @type {import('client/dom/HUDPopup').HUDPopupClient | undefined} */
	client;

	/** @type {import('router/dom/HUDPopup').HUDPopupRouter} */
	router;

	constructor(world) {
		this.world = world;
		this.curPromise = null;
	}

	setTimer(popup, duration) {
		if (duration instanceof Promise) return (this.curPromise = duration);
		if (popup && duration !== -1) return (this.curPromise = wait(duration));

		this.curPromise = null;
	}
}
