export const PROXIMITY_ENTER = 0;
export const PROXIMITY_EXIT = 1;

export class ProximityComponent {
	constructor(o, entity) {
		this.name = "proximity";

		this.def = {
			threshold: o?.proximity ?? 0,
		};

		this.state = {
			camDistance: Infinity,
			inCamProximity: false,
			promises: new Set(),
		};
	}
}
