import { netState } from "router/Parallelogram";
import { MathUtils } from "three";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} deltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterCameraUpdate(entity, deltaTime) {
	if (!canUpdateSystem(entity)) return;

	const clampedDeltaTime = Math.min(deltaTime, 0.1);

	updateEyeHeight(entity, clampedDeltaTime);

	updateFOV(entity, clampedDeltaTime);

	updateItemSway(entity, clampedDeltaTime);

	updateCamera(entity);
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && entity === entity.world.client.camera.target;
}

function updateEyeHeight(entity, deltaTime) {
	let eyeHeight = entity.size.state.eyeHeight;

	if (entity.movement.state.isCrouching) {
		const co = entity.size.state.crouchOffset;
		eyeHeight -= co;
	}

	entity.characterCamera.state.eyeHeight = MathUtils.lerp(
		entity.characterCamera.state.eyeHeight,
		eyeHeight,
		1 - Math.pow(entity.characterCamera.def.crouchLerp, deltaTime),
	);
}

function updateFOV(entity, deltaTime) {
	if (entity.movement.state.isSprinting) {
		entity.characterCamera.state.fovTarget = entity.characterCamera.def.fovSprint;
	} else if (entity.movement.state.isIronSights) {
		entity.characterCamera.state.fovTarget =
			entity.getEquippedItem()?.weapon.def.fovAim ?? entity.characterCamera.def.fovAim;
	} else {
		entity.characterCamera.state.fovTarget = entity.characterCamera.def.fovWalk;
	}

	entity.cameraSettings.def.camera.fov = MathUtils.lerp(
		entity.cameraSettings.def.camera.fov,
		entity.characterCamera.state.fovTarget,
		1 - Math.pow(entity.characterCamera.def.fovLerp, deltaTime),
	);
}

function updateItemSway(entity, deltaTime) {
	const characterCameraDef = entity.characterCamera.def;
	const characterCameraState = entity.characterCamera.state;
	const movementState = entity.movement.state;

	const targetItemSwayVerticalOffset = entity.collision.state.hitFoot
		? 0
		: Math.sign(movementState.displacementActual.y) * -0.025;

	characterCameraState.itemSwayVerticalOffset = MathUtils.lerp(
		characterCameraState.itemSwayVerticalOffset,
		targetItemSwayVerticalOffset,
		deltaTime * 15,
	);

	const isIronSights = movementState.isIronSights;

	entity.cameraSettings.def.itemSwayMaxDisplacement = isIronSights
		? characterCameraDef.itemSwayMaxDisplacement.ironSights
		: characterCameraDef.itemSwayMaxDisplacement.default;
}

function updateCamera(entity) {
	const camera = entity.world.client.camera;

	if (entity.getEquippedItem()?.itemType.def.isBlock) {
		camera.itemSwayDecalOffset.copy(entity.characterCamera.state.bobItemSwayOffset);

		camera.itemSwayDecalOffset.y += entity.characterCamera.state.itemSwayVerticalOffset;
	} else {
		camera.itemSwayDecalOffset.set(0, 0, 0);
	}

	entity.cameraSettings.def.offset.set(0, 0, 0);

	entity.cameraSettings.def.offset.y = entity.characterCamera.state.eyeHeight;

	if (camera.is1stPerson()) {
		entity.cameraSettings.def.offset.add(entity.characterCamera.state.bobCameraOffset);
	}
}
