import { ws } from "rest-client";
import { NET_OFFLINE, NET_SERVER } from "../share/Const";
import { IBSBase } from "./Base";
import { Peer } from "./Peer";
import { delay } from "@jamango/helpers";
import type { ClientSubscription } from "@jamango/content-service/types/index.ts";

export class IBSServer extends IBSBase {
	readonly maxPeers: number;
	onmaster: null | ((success: boolean) => void);
	readonly reconnectTime: number;

	private firstCreate: boolean;

	private activeSub?: ClientSubscription<"ibs:login">;

	/**
	 * Constructor options for NetBase superclass are passed in here as well
	 * OPT String serverID - Server ID for the client to connect to. E.g. "wild-west"
	 * OPT Number maxPeers [Infinity] - Maximum number of peer connections allowed to connect
	 * OPT Function onmaster - Called when connection to master server is lost (arg will be false) or after reconnecting (arg will be true). If false, onmaster is awaited before attempting to reconnect in case loginData needs to be updated. Both onmaster(false) and reconnecting via WebSocket will happen repeatedly until they either resolve or are manually cancelled. onmaster() will NOT be called if the constructor hasn't resolved yet; instead use onfail() or catch the error from the constructor
	 * OPT Number reconnectTime [3] - If the connection to master is lost, this is how long to wait (in seconds) before retrying.
	 */
	constructor(
		readonly o: ConstructorParameters<typeof IBSBase>[0] & {
			onmaster?: () => void;
			reconnectTime?: number;
			dedicated: boolean;
		},
	) {
		super(o);

		this.maxPeers = o.maxPeers ?? Infinity;
		this.onmaster = o.onmaster ?? null;
		this.reconnectTime = o.reconnectTime ?? 3;

		this.firstCreate = true;
	}

	/**
	 * This is only meant to be called internally
	 */
	init() {
		const sub = ws.client.subscribe("ibs:login", {
			serverId: this.o.serverID,
			version: this.o.version,
			mode: NET_SERVER,
			worldShortCode: this.o.worldShortCode,
			worldVersionId: this.o.worldVersionId,
			dedicated: this.o.dedicated,
		});

		this.activeSub = sub;

		if (!this.firstCreate) this.onmaster?.(true);

		sub.listen(async (msg) => {
			const result = msg.result;
			console.log(`result:`, result);

			if (!result) {
				console.error(msg.error);
				return;
			}

			switch (result.type) {
				case "success": {
					if (this.firstCreate) {
						this.mode = NET_SERVER;
						this.peerID = result.peerId;
						this.accessToken = result.accessToken;
						this.firstCreate = false;
						this.initPromise.resolve();
					}
					return;
				}
				case "ice": {
					if (result.peerId) {
						this.peers.get(result.peerId)?.rtc.addIceCandidate(new RTCIceCandidate(result.rtc));
					}
					return;
				}
				case "answer": {
					return;
				}
				case "offer": {
					const peerId = result.peerId;

					if (!peerId) return;

					if (this.isServerFull()) {
						ws.client.call("ibs:full", { peerId, accessToken: this.accessToken! });
						return;
					}

					const client = new Peer(this, peerId);
					const rtc = client.rtc;

					try {
						await rtc.setRemoteDescription(new RTCSessionDescription(result.offer as any));
					} catch (oops) {
						console.error(oops);
						rtc.close();
						return;
					}

					const desc = await rtc.createAnswer();
					await rtc.setLocalDescription(desc);
					await ws.client.call("ibs:answer", { peerId, rtc: desc, accessToken: this.accessToken! });

					return;
				}
			}
		});

		// retry on disconnect
		sub.onDispose(async () => {
			this.activeSub = undefined;

			if (!this.onmaster) {
				console.log(`Server was closed`);
				return;
			}

			await delay(2000);

			console.log(`Try to reconnect`);

			this.init();
		});
	}

	/**
	 * Add +1 if you want to include yourself
	 */
	getPeerCount() {
		return this.peers.size;
	}

	isServerFull() {
		return this.getPeerCount() >= this.maxPeers;
	}

	close() {
		if (this.mode === NET_OFFLINE) return;

		this.onmaster = null;

		super.close();

		this.activeSub?.unsubscribe();
		this.activeSub = undefined;

		console.log(`Server closed`);
	}
}
