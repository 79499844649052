import { JSFRandom } from "base/util/math/Random.js";
import { MathUtils } from "three";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap.js";

export class PerlinNoiseGenerator {
	//SCALE MUST BE INTEGER, NO LONGER AFFECTS SPEED (much)
	constructor(scale, octaves, ...seed) {
		this.seed = seed;
		this.scale = scale;
		this.octaves = octaves;

		this.cache = new LegacyVectorMap();
	}

	getGradientChunk(cx, cy) {
		let chunk = this.cache.get(cx, cy);
		if (!chunk) {
			const s = this.seed;

			//include points from neighboring gradient chunks into this chunk
			//testing shows it is 5x faster than searching for points in neighboring gradient chunks for each perlin() call
			const randomc = new JSFRandom(...s, "x", cx, "y", cy, "perlinNoise");
			const randoml = new JSFRandom(...s, "x", cx, "y", cy, "perlinNoiseX");
			const randomr = new JSFRandom(...s, "x", cx + 1, "y", cy, "perlinNoiseX");
			const randomt = new JSFRandom(...s, "x", cx, "y", cy, "perlinNoiseY");
			const randomb = new JSFRandom(...s, "x", cx, "y", cy + 1, "perlinNoiseY");
			const randomtl = new JSFRandom(...s, "x", cx, "y", cy, "perlinNoiseXY");
			const randomtr = new JSFRandom(...s, "x", cx + 1, "y", cy, "perlinNoiseXY");
			const randombl = new JSFRandom(...s, "x", cx, "y", cy + 1, "perlinNoiseXY");
			const randombr = new JSFRandom(...s, "x", cx + 1, "y", cy + 1, "perlinNoiseXY");

			let frequency = 1;
			chunk = [];

			for (let i = 0; i < this.octaves; i++) {
				const gsize = frequency + 1;
				const gradient = new Float32Array(2 * gsize ** 2);

				for (let gy = 0; gy < gsize; gy++) {
					for (let gx = 0; gx < gsize; gx++) {
						var random;

						if (gx === 0) {
							if (gy === 0) random = randomtl;
							else if (gy === gsize - 1) random = randombl;
							else random = randoml;
						} else if (gx === gsize - 1) {
							if (gy === 0) random = randomtr;
							else if (gy === gsize - 1) random = randombr;
							else random = randomr;
						} else if (gy === 0) {
							random = randomt;
						} else if (gy === gsize - 1) {
							random = randomb;
						} else {
							random = randomc;
						}

						const j = (gy * gsize + gx) * 2;
						gradient[j] = random.float64() * 2 - 1;
						gradient[j + 1] = random.float64() * 2 - 1;
					}
				}

				frequency *= 2;
				chunk.push(gradient);
			}

			this.cache.set(cx, cy, chunk);
		}

		return chunk;
	}

	//returns number in range [-1, 1)
	//recommend caching the results if calculating the same ax/ay several times
	getNoise(ax, ay) {
		const s = this.scale;

		const cgx = Math.floor(ax / s);
		const cgy = Math.floor(ay / s);
		const rgx = ax - cgx * s;
		const rgy = ay - cgy * s;

		const gradients = this.getGradientChunk(cgx, cgy);
		let frequency = 1;

		let val = 0;
		for (let i = 0; i < this.octaves; i++) {
			val +=
				PerlinNoiseGenerator.perlin(
					(rgx * frequency) / s,
					(rgy * frequency) / s,
					frequency + 1,
					gradients[i],
				) / frequency;

			frequency *= 2;
		}

		return val;
	}

	clearCache() {
		this.cache.clear();
	}

	static perlin(x, y, gsize, gradient) {
		const x0 = Math.floor(x);
		const y0 = Math.floor(y);
		const x1 = x0 + 1;
		const y1 = y0 + 1;
		const dx = x - x0;
		const dy = y - y0;

		const { dot, sCurve } = PerlinNoiseGenerator;

		const v00 = dot(x0, y0, dx, dy, gsize, gradient);
		const v10 = dot(x1, y0, dx - 1, dy, gsize, gradient);
		const v01 = dot(x0, y1, dx, dy - 1, gsize, gradient);
		const v11 = dot(x1, y1, dx - 1, dy - 1, gsize, gradient);

		const vx0 = MathUtils.lerp(v00, v10, sCurve(dx));
		const vx1 = MathUtils.lerp(v01, v11, sCurve(dx));

		return MathUtils.lerp(vx0, vx1, sCurve(dy));
	}

	static dot(cx, cy, dx, dy, gsize, gradient) {
		const i = (cy * gsize + cx) * 2;
		return dx * gradient[i] + dy * gradient[i + 1];
	}

	static sCurve(x) {
		const x2 = x * x;
		return x2 * x * (6 * x2 - 15 * x + 10);
	}
}
