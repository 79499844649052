import { type BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import { ChunkBuilder } from "base/world/block/Builder";
import type { Chunk } from "base/world/block/chunk/Chunk";
import type { ChunkScene } from "base/world/block/Scene";
import type { BlockType } from "base/world/block/Type";
import {
	BLOCK_CUBE,
	BLOCK_TRANSPARENCY_TRANSLUCENT,
	BLOCK_TRANSPARENCY_TRANSPARENT,
} from "base/world/block/Util";
import type { World } from "base/world/World";
import { BBClient } from "client/BB";
import * as Resources from "client/Resources";
import { ShadowMesh } from "client/util/ShadowMesh";
import type { PaletteLayout } from "client/world/block/Palette";
import { updatePaletteCanvas, createPaletteLayout } from "client/world/block/Palette";
import { Vector3 } from "three";

export const init = () => {
	return {
		blockPaletteLayout: undefined as undefined | PaletteLayout,
		blockPaletteTextureNeedsUpdate: false,
		blockPaletteTextureLastUpdateTime: 0,
		blockTexturesLoading: new Set<string>(),
		blockTypesLoadingPriority: [] as string[],
		blockTypesLoadPriorityLastUpdateTime: 0,
		blockTextureResourcesToBlockTypes: new Map<string, string[]>(),
	};
};

export type BlockTypeRegistryClientState = ReturnType<typeof init>;

const LOADING_PRIORITIZATION_THROTTLE_SECONDS = 0.5;

const cameraBlock = new Vector3();

const updateLoadingPriority = (
	state: BlockTypeRegistryState,
	clientState: BlockTypeRegistryClientState,
	world: World,
) => {
	if (
		clientState.blockTexturesLoading.size === 0 ||
		world.time - clientState.blockTypesLoadPriorityLastUpdateTime <
			LOADING_PRIORITIZATION_THROTTLE_SECONDS
	) {
		return;
	}

	const camera = world.client!.camera;

	cameraBlock.copy(camera.position).round();
	const currentChunk = world.scene.getChunkAt(cameraBlock.x, cameraBlock.y, cameraBlock.z);

	const chunks: [Chunk, priority: number][] = [];

	for (const chunk of world.scene.chunks.values()) {
		if (!chunk.built) continue;

		const dx = chunk.position.x - currentChunk.position.x;
		const dy = chunk.position.y - currentChunk.position.y;
		const dz = chunk.position.z - currentChunk.position.z;

		const priority = Math.abs(dx) + Math.abs(dy) + Math.abs(dz);

		chunks.push([chunk, priority]);
	}

	const blockTypeToPriority = new Map<string, number>();

	for (const [chunk, priority] of chunks) {
		if (!chunk.blockTypes) continue;

		for (const type of chunk.blockTypes) {
			const name = state.blockIdToType.get(type)?.name;
			if (name === undefined) continue;

			const existing = blockTypeToPriority.get(name);

			if (existing === undefined || priority < existing) {
				blockTypeToPriority.set(name, priority);
			}
		}
	}

	const blockTypes = Array.from(blockTypeToPriority.entries())
		.sort((a, b) => a[1] - b[1])
		.map((a) => a[0]);

	clientState.blockTypesLoadingPriority = blockTypes;
	clientState.blockTypesLoadPriorityLastUpdateTime = world.time;
};

const PALETTE_UPDATE_THROTTLE_SECONDS = 1;

const updatePalette = (clientState: BlockTypeRegistryClientState, world: World) => {
	if (
		!clientState.blockPaletteTextureNeedsUpdate ||
		world.time - clientState.blockPaletteTextureLastUpdateTime < PALETTE_UPDATE_THROTTLE_SECONDS
	) {
		return;
	}

	const palette = clientState.blockPaletteLayout!;
	updatePaletteCanvas(palette);

	clientState.blockPaletteTextureNeedsUpdate = false;
	clientState.blockPaletteTextureLastUpdateTime = world.time;
};

export const update = (
	state: BlockTypeRegistryState,
	clientState: BlockTypeRegistryClientState,
	world: World,
) => {
	updatePalette(clientState, world);
	updateLoadingPriority(state, clientState, world);
};

const createBlockPaletteLayout = (state: BlockTypeRegistryState, world: World) => {
	const blocks = Array.from(state.blockNameToType.values());
	const blockPaletteLayout = createPaletteLayout(blocks, world, BBClient.renderer);
	updatePaletteCanvas(blockPaletteLayout);

	if (world.scene.mat) {
		world.scene.mat.map = blockPaletteLayout.texture;
		world.scene.mat.needsUpdate = true;
	}

	if (world.scene.alphaTestedMaterial) {
		world.scene.alphaTestedMaterial.map = blockPaletteLayout.texture;
		world.scene.alphaTestedMaterial.needsUpdate = true;
	}

	if (world.scene.translucentMaterial) {
		world.scene.translucentMaterial.map = blockPaletteLayout.texture;
		world.scene.translucentMaterial.needsUpdate = true;
	}
	return blockPaletteLayout;
};

export const disposeBlockPaletteLayout = (blockPaletteLayout: PaletteLayout) => {
	blockPaletteLayout.texture.dispose();
};

export const initMaterialsAndGeometry = (
	state: BlockTypeRegistryState,
	clientState: BlockTypeRegistryClientState,
	world: World,
) => {
	queueAssets(world.blockTypeRegistry, world.client!.blockTypeRegistry, world);

	if (clientState) {
		if (clientState.blockPaletteLayout) disposeBlockPaletteLayout(clientState.blockPaletteLayout);
		clientState.blockPaletteLayout = createBlockPaletteLayout(state, world);
	}

	const scene = world.scene;

	scene.createBlockMaterials(clientState.blockPaletteLayout!.texture);

	for (const blockType of state.blockIdToType.values()) {
		createBlockTypeGeometry(blockType, scene);
	}
};

export const queueAssets = (
	state: BlockTypeRegistryState,
	clientState: BlockTypeRegistryClientState,
	world: World,
) => {
	/* queue block texture assets */
	const textureAssets = state.assets
		.filter((asset) => asset.type.includes("image"))
		.map((asset) => {
			// find blocks that use this texture
			const blockTypes = world.content.state.blocks
				.getAll()
				.filter((block) =>
					Object.values(block.material).some(
						(m) => typeof m === "object" && m?.resourcePk === asset.id,
					),
				)
				.map((block) => block.pk);

			clientState.blockTextureResourcesToBlockTypes.set(asset.id, blockTypes);

			return {
				...asset,
				onLoaded: () => {
					clientState.blockPaletteTextureNeedsUpdate = true;
					clientState.blockTexturesLoading.delete(asset.id);
				},
			};
		})
		.filter((asset) => !Resources.isLoaded(asset.id));

	for (const asset of textureAssets) {
		clientState.blockTexturesLoading.add(asset.id);
	}

	Resources.queue(textureAssets);

	/* queue block sound assets */
	const soundAssets = state.assets
		.filter((asset) => asset.type.includes("audio"))
		.filter((asset) => !Resources.isLoaded(asset.id));

	Resources.queue(soundAssets);
};

const createBlockTypeGeometry = (blockType: BlockType, scene: ChunkScene) => {
	const { opaqueBuffer, alphaTestedBuffer, translucentBuffer } = ChunkBuilder.build(
		[BLOCK_CUBE],
		[blockType.id],
		1,
		1,
		1,
		scene.world.blockTypeRegistry,
		true,
		false,
	);

	let buffer;
	if (blockType.transparency === BLOCK_TRANSPARENCY_TRANSLUCENT) {
		buffer = translucentBuffer;
	} else if (blockType.transparency === BLOCK_TRANSPARENCY_TRANSPARENT) {
		buffer = alphaTestedBuffer;
	} else {
		buffer = opaqueBuffer;
	}

	const geometry = ChunkBuilder.buildGeometry(buffer);
	geometry.computeBoundingBox();
	geometry.computeBoundingSphere();

	// move geometry origin to the center of the block
	const posAttribute = geometry.attributes.position.array;
	for (let i = 0; i < posAttribute.length; i++) {
		posAttribute[i] -= 0.5;
	}

	blockType.geom = geometry;
};

export const createBlockTypeMesh = (blockType: BlockType, scene: ChunkScene) => {
	let material;

	if (blockType.transparency === BLOCK_TRANSPARENCY_TRANSLUCENT) {
		material = scene.translucentMaterial;
	} else if (blockType.transparency === BLOCK_TRANSPARENCY_TRANSPARENT) {
		material = scene.alphaTestedMaterial;
	} else {
		material = scene.mat;
	}

	const mesh = new ShadowMesh(blockType.geom, material);
	mesh.updateMatrix();
	mesh.matrixAutoUpdate = false;

	return mesh;
};

export const dispose = (state: BlockTypeRegistryClientState) => {
	if (state.blockPaletteLayout) disposeBlockPaletteLayout(state.blockPaletteLayout);
};
