/**
 * Group Array By Key
 * https://stackoverflow.com/questions/40774697/how-can-i-group-an-array-of-objects-by-key
 */
export function groupByKey(list: any[], key: any) {
	return list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});
}

export function searchArray<TArray extends any[]>(
	arr: TArray,
	query: string,
	selector: (item: TArray[number]) => string | Array<string | string[]>,
) {
	const normalizedQuery = query.toLowerCase().split(/\s+/);

	return arr.filter((item) => {
		let keywords = selector(item);

		if (!Array.isArray(keywords)) keywords = [keywords];

		const arrayOfStrings = keywords
			.map((arg) =>
				(Array.isArray(arg) ? arg.flat(Infinity) : [arg]).map((query) => {
					return query.toString().trim().toLowerCase();
				}),
			)
			.flat(Infinity);

		return normalizedQuery.every((word) => arrayOfStrings.some((keyword) => keyword.includes(word)));
	}) as TArray;
}

export const alphanumericCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: "variant" });

export function alphabetizeArray<T extends string>(array: T[]): T[];
export function alphabetizeArray<T>(array: T[], by: (item: T) => string): T[];

// Implementation
export function alphabetizeArray<T>(array: T[], by?: (item: T) => string): T[] {
	return array.sort((a, b) => {
		const compareA = by ? by(a) : (a as string);
		const compareB = by ? by(b) : (b as string);
		return alphanumericCollator.compare(compareA, compareB);
	});
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffleArray<T>(array: T[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

export function findPair<T extends [unknown, unknown], A, B>(
	pair: [...T],
	isA: (a: T[number]) => a is A,
	isB: (b: T[number]) => b is B,
): [A, B] | undefined {
	if (isA(pair[0]) && isB(pair[1])) return pair as [A, B];
	if (isA(pair[1]) && isB(pair[0])) return [pair[1], pair[0]] as [A, B];
	return undefined;
}
