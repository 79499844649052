export const WboitStages = {
	Normal: 0.0,
	Accumulation: 1.0,
	Revealage: 2.0,
};

/**
 * Used for copying base target to scene target, discarding fully transparent pixels
 */
export const CopyAlphaTestShader = {
	uniforms: {
		tDiffuse: { value: null },
	},

	vertexShader: /* glsl */ `
		varying vec2 vUv;
  
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

	fragmentShader: /* glsl */ `
		uniform sampler2D tDiffuse;
  
		varying vec2 vUv;
  
		void main() {
			vec4 color = texture2D( tDiffuse, vUv );
			if ( color.a == 0.0 ) discard;

			gl_FragColor = vec4(color.rgb, 1.0);
		}`,
};

export const DepthCopyShader = {
	name: "DepthCopyShader",

	uniforms: {
		depthTexture: { value: null },
	},

	vertexShader: /* glsl */ `
	  	varying vec2 vUv;
  
	  	void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
	  	}
	`,

	fragmentShader: /* glsl */ `
		#include <packing>

	  	uniform sampler2D depthTexture;
	  	varying vec2 vUv;

	  	float readDepth(sampler2D depthSampler, vec2 coord) {
			float fragCoordZ = texture2D(depthSampler, coord).x;
			return fragCoordZ;
		}
  
	  	void main() {
			float depth = readDepth(depthTexture, vUv);

			gl_FragColor.rgb = 1.0 - vec3(depth);
			gl_FragColor.a = 1.0;

			gl_FragDepth = depth;
	  	}`,
};

/**
 * Color fill shader
 */
export const CopyShader = {
	name: "CopyShader",

	uniforms: {
		tDiffuse: { value: null },
		opacity: { value: 1.0 },
	},

	vertexShader: /* glsl */ `
		varying vec2 vUv;
  
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

	fragmentShader: /* glsl */ `
		uniform float opacity;
		uniform sampler2D tDiffuse;
  
		varying vec2 vUv;
  
		void main() {
			gl_FragColor = texture2D( tDiffuse, vUv );
			gl_FragColor.a *= opacity;
		}`,
};

/**
 * Combine accumulation and revealage for weighted, blended order-independent transparency
 */
export const WboitCompositeShader = {
	name: "WboitCompositeShader",
	uniforms: {
		tAccumulation: { value: null },
		tRevealage: { value: null },
	},

	vertexShader: /* glsl */ `
		varying vec2 vUv;

		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

	fragmentShader: /* glsl */ `
		precision highp float;
		precision highp int;

		varying vec2 vUv;

		uniform sampler2D tAccumulation;
		uniform sampler2D tRevealage;

		float EPSILON = 0.00001;

		bool fuzzyEqual( float a, float b ) {
			return abs( a - b ) <= ( abs( a ) < abs( b ) ? abs( b ) : abs( a ) ) * EPSILON;
		}

		void main() {
			float reveal = texture2D( tRevealage, vUv ).r;
			if ( fuzzyEqual( reveal, 1.0 ) ) discard;

			vec4 accum = texture2D( tAccumulation, vUv );

			vec4 composite = vec4( accum.rgb / clamp( accum.a, 0.0001, 50000.0 ), reveal );
			vec4 color = clamp( composite, 0.01, 300.0 );
			
			gl_FragColor = color;
		}`,
};
