/**
 * Object representing the network state of the application.
 * @typedef {Object} NetState
 * @property {boolean} isClient - Indicates whether we're a frontend client or backend dedi. This is set once during main() and never changes
 *   true = frontend client (which can potentially be a p2p host)
 *   false = dedicated backend server.
 * @property {boolean} isHost - Indicates whether we're hosting. This can change when the current world changes
 *   true = either a dedicated server or a frontend p2p host
 *   false = peer connected to either a dedicated backend or a frontend p2p host
 */

/** @type {NetState} */
export const netState = {
	isClient: false,
	isHost: false,
};
