import * as QuestBase from "base/world/Quest";
import * as QuestRouter from "router/world/Quest";

import type { Vector3 } from "three";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { World } from "base/world/World";
import * as Net from "router/Net";

export function broadcastCreateObjective(state: QuestBase.State, world: World, o: QuestBase.ObjectiveDef) {
	if (state.objectives.has(o.name)) return;

	Net.sendToAll("quest_createobj", o);

	QuestRouter.localCreateObjective(state, o);
}

/**
 * o is Text3D.render options (a subset of Text3D)
 */
export function broadcastCreateWaypoint(
	state: QuestBase.State,
	world: World,
	name: QuestBase.ObjectiveName,
	entityID: EntityID | null,
	pos: Vector3,
) {
	const obj = state.objectives.get(name);
	if (!obj) return;

	Net.sendToAll("quest_waypoint", [name, entityID, pos]);

	QuestRouter.localCreateWaypoint(state, world, name, entityID, pos);
}

export function broadcastCompleteObjective(
	state: QuestBase.State,
	world: World,
	name: QuestBase.ObjectiveName,
) {
	const obj = state.objectives.get(name);
	if (!obj) return;

	Net.sendToAll("quest_endobj", name);

	QuestRouter.localCompleteObjective(state, name);
}

export function broadcastCompleteAllObjectives(state: QuestBase.State, world: World) {
	for (const name of state.objectives.keys()) broadcastCompleteObjective(state, world, name);
}

export function initCommandListeners() {
	Net.listen("multiplayer_uglystates", function (_, world, peer) {
		for (const obj of world.quest.objectives.values()) {
			Net.send("quest_createobj", obj, peer);

			if (QuestBase.hasWaypoint(obj)) {
				Net.send("quest_waypoint", [obj.name, obj.entityID, obj.pos], peer);
			}
		}
	});
}
