import { findPair } from "@jamango/helpers";
import { NODE } from "base/rete/InternalNameMap";
import * as listener from "base/rete/modules/listener";
import { entityIsCharacter, entityIsItem } from "base/world/entity/component/Type";
import type { Entity } from "base/world/entity/Entity";
import type { World } from "base/world/World";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

const _worldSpaceNormal = new Vector3();
const _worldSpaceNormalInverted = new Vector3();

export function physicsEntityCollisionsUpdate(world: World) {
	const [added, persisted, removed] = findContactPairs(world);
	handleContactPairs(world, added, persisted, removed);
}

type ContactPair = {
	a: Entity;
	b: Entity;
	worldSpaceNormal: Vector3;
	worldSpaceNormalInverted: Vector3;
};

type RemovedContactPair = {
	a: Entity;
	b: Entity;
};

function findContactPairs(
	world: World,
): [added: ContactPair[], persisted: ContactPair[], removed: RemovedContactPair[]] {
	const addedContactPairs = new Map<string, ContactPair>();
	const persistedContactPairs = new Map<string, ContactPair>();
	const removedContactPairs = new Map<string, RemovedContactPair>();

	// physics contacts
	for (const [type, map] of [
		["added", addedContactPairs],
		["persisted", persistedContactPairs],
	] as const) {
		for (const contact of world.physics.contacts[type]) {
			const aEntityId = contact.body1UserData?.entityId;
			const bEntityId = contact.body2UserData?.entityId;
			if (aEntityId === undefined || bEntityId === undefined) continue;

			const aEntity = world.getEntity(aEntityId);
			const bEntity = world.getEntity(bEntityId);
			if (!aEntity || !bEntity) continue;

			const worldSpaceNormal = _worldSpaceNormal.set(
				contact.worldSpaceNormalX,
				contact.worldSpaceNormalY,
				contact.worldSpaceNormalZ,
			);

			const worldSpaceNormalInverted = _worldSpaceNormalInverted.copy(worldSpaceNormal).negate();

			if (aEntity.entityID < bEntity.entityID) {
				map.set(`${aEntityId}-${bEntityId}`, {
					a: aEntity,
					b: bEntity,
					worldSpaceNormal: worldSpaceNormal.clone(),
					worldSpaceNormalInverted: worldSpaceNormalInverted.clone(),
				});
			} else {
				map.set(`${bEntityId}-${aEntityId}`, {
					a: bEntity,
					b: aEntity,
					worldSpaceNormal: worldSpaceNormalInverted.clone(),
					worldSpaceNormalInverted: worldSpaceNormal.clone(),
				});
			}
		}
	}

	for (const contact of world.physics.contacts.removed) {
		const aEntityId = contact.body1UserData?.entityId;
		const bEntityId = contact.body2UserData?.entityId;
		if (!aEntityId || !bEntityId) continue;

		const aEntity = world.getEntity(aEntityId);
		const bEntity = world.getEntity(bEntityId);
		if (!aEntity || !bEntity) continue;

		if (aEntity.entityID < bEntity.entityID) {
			removedContactPairs.set(`${aEntityId}-${bEntityId}`, {
				a: aEntity,
				b: bEntity,
			});
		} else {
			removedContactPairs.set(`${bEntityId}-${aEntityId}`, {
				a: bEntity,
				b: aEntity,
			});
		}
	}

	return [
		Array.from(addedContactPairs.values()),
		Array.from(persistedContactPairs.values()),
		Array.from(removedContactPairs.values()),
	] as const;
}

function handleContactPairs(
	world: World,
	added: ContactPair[],
	persisted: ContactPair[],
	removed: RemovedContactPair[],
) {
	// handle unique contact pairs
	for (const { a, b, worldSpaceNormal, worldSpaceNormalInverted } of added) {
		// item pickups
		if (netState.isHost) {
			const itemCharacterPair = findPair([a, b], entityIsItem, entityIsCharacter);

			if (itemCharacterPair) {
				const [item, character] = itemCharacterPair;

				const equippedItem = character.getEquippedItem();
				if (equippedItem && !equippedItem.itemType.def.isTool) return;

				if (item.base.def.armoryMode && equippedItem?.def !== item.def) {
					character.equipItem(item.def);
				} else if (!item.base.def.armoryMode) {
					if (character.getEquippedItem()) character.unequipItem();

					character.doMount(item, true);
				}
			}
		}

		// entity contact listeners
		listener.onEntityEvent(NODE.OnEntityCollisionStart, world, a.entityID, {
			inputEntity: a,
			entity: b,
			contactNormal: worldSpaceNormal,
		});

		listener.onEntityEvent(NODE.OnEntityCollisionStart, world, b.entityID, {
			inputEntity: b,
			entity: a,
			contactNormal: worldSpaceNormalInverted,
		});
	}

	// handle persisted contact pairs
	for (const { a, b, worldSpaceNormal, worldSpaceNormalInverted } of persisted) {
		// entity contact listeners
		listener.onEntityEvent(NODE.OnEntityCollisionPersisted, world, a.entityID, {
			inputEntity: a,
			entity: b,
			contactNormal: worldSpaceNormal,
		});

		listener.onEntityEvent(NODE.OnEntityCollisionPersisted, world, b.entityID, {
			inputEntity: b,
			entity: a,
			contactNormal: worldSpaceNormalInverted,
		});
	}

	// handle removed contact pairs
	for (const { a, b } of removed) {
		// entity contact listeners
		listener.onEntityEvent(NODE.OnEntityCollisionStop, world, a.entityID, {
			inputEntity: a,
			entity: b,
		});

		listener.onEntityEvent(NODE.OnEntityCollisionStop, world, b.entityID, {
			inputEntity: b,
			entity: a,
		});
	}
}
