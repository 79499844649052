import { Audio, type AudioListener, PositionalAudio } from "three";
import TWEEN from "@tweenjs/tween.js";
import { isNullish, createRequestPromise } from "@jamango/helpers";
import type { IPromise } from "@jamango/helpers";
import * as Resources from "client/Resources";
import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";
import * as trigger from "base/rete/modules/trigger";
import type { Entity } from "../entity/Entity";
import { wait } from "base/util/Time";
import { BB } from "base/BB";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import * as Net from "router/Net";

const SKIPPABLE_SFX_DURATION = 5; // in sec

type CommonAudioOptions = {
	asset: string;
	duration?: number;
	fadeInDuration?: number;
	loop?: boolean;
	volume?: number;
};

type PlayAudioOptions = CommonAudioOptions & { type: "play" };
type PlayAtPositionAudioOptions = CommonAudioOptions & {
	type: "playAtPos";
	x: number;
	y: number;
	z: number;
	refDistance?: number;
};
type PlayAtObjectAudioOptions = CommonAudioOptions & {
	type: "playAtObj";
	obj: number | Entity;
	refDistance?: number;
};

type PlayGloballyAudioOptions = CommonAudioOptions & {
	type: "playGlobally";
};

type AudioOptions =
	| PlayAudioOptions
	| PlayAtPositionAudioOptions
	| PlayAtObjectAudioOptions
	| PlayGloballyAudioOptions;

type SoundSpecific = {
	volume?: number;
	detune?: number;
	playbackRate?: number;
	refDistance?: number;
	rolloffFactor?: number;
	distanceModel?: string;
	maxDistance?: number;
	fadeInDuration?: number;
};

type AudioCommand = [number, AudioOptions];

class ServerAudio {
	duration: number;
	isPlaying = false;

	constructor(
		private readonly world: World,
		readonly id: number,
		readonly opts: CommonAudioOptions,
	) {
		this.duration = opts.duration ?? -1;
	}

	stop() {
		if (!this.isPlaying) return;

		this.isPlaying = false;
	}

	removeFromParent() {}

	play() {
		if (this.isPlaying) return;

		this.isPlaying = true;

		if (this.opts.loop) return;

		wait(this.duration).then(() => {
			this.onEnded();
		});
	}

	onEnded() {
		if (!this.isPlaying) return;

		this.isPlaying = false;

		trigger.onSoundEnd(this.world, this.id, this.opts.asset);
	}
}

let emptyBuffer: any = null;

class LazyAudio {
	disposed = false;

	constructor(
		readonly asset: string,
		readonly audio: Audio | PositionalAudio,
		readonly fadeInDuration?: number,
		readonly duration?: number,
	) {}

	shouldBeLoaded() {
		return this.audio.buffer === emptyBuffer;
	}

	canBeSkipped() {
		const duration = this.duration;

		if (!duration) return true;

		return duration <= SKIPPABLE_SFX_DURATION;
	}

	dispose() {
		this.audio.stop();
		this.audio.removeFromParent();
		this.disposed = true;
	}
}

function createLazyAudio(
	o: {
		asset: string;
		entityID?: number;
		start?: number;
		end?: number;
		duration?: number;
		loop?: boolean;
	} & SoundSpecific,
	audios: Map<string, any>,
	assets: Map<string, any>,
	listener: AudioListener,
	isPositional?: boolean,
) {
	if (!emptyBuffer) {
		emptyBuffer = listener.context.createBuffer(1, 1, 22050);
	}

	const buffer = assets.has(o.asset) ? assets.get(o.asset) : null;

	const isMobile = BB.client.inputPoll.isMobileBrowser();

	let audio: PositionalAudio | Audio | null = null;

	if (isMobile) {
		let assetName = isPositional ? `${o.asset}-positional` : o.asset;

		if (o.entityID) assetName = `${o.entityID}-audio`;

		audio = audios.get(assetName);
	}

	if (!audio) audio = new (isPositional ? PositionalAudio : Audio)(listener);

	audio.setBuffer(buffer || emptyBuffer);

	audio.loop = o.loop ?? false;

	audio.offset = o.start ?? 0;

	if (!isNullish(o.end)) audio.duration = o.end - audio.offset;
	if (!isNullish(o.duration) && !audio.loop) audio.duration = o.duration;

	audio.setVolume(o.volume ?? 1);
	audio.detune = o.detune ?? 0;
	audio.playbackRate = o.playbackRate ?? 1;

	if (isPositional) {
		const positionalAudio = audio as PositionalAudio;
		positionalAudio.setRefDistance(o.refDistance ?? 3);
		positionalAudio.setRolloffFactor(o.rolloffFactor ?? 10);
		positionalAudio.setDistanceModel(o.distanceModel ?? "inverse");
		positionalAudio.setMaxDistance(o.maxDistance ?? 10000);
	}

	return new LazyAudio(o.asset, audio, o.fadeInDuration, audio.duration);
}

export class SFXManager {
	serverSFX = new Map<number, AudioCommand>();
	clientSFX = new Map<number, LazyAudio>();
	soundTweens = new Map();
	nextID = 0;

	requestID = 0;

	clientWaitingForAssets = new Map<string, IPromise<void>>();

	constructor(readonly world: World) {
		Resources.onResourceLoaded.add(this.#onAssetLoaded);
	}

	serverAdd(recipients: "all" | any[], opts: AudioOptions) {
		const id = this.nextID++;
		const cmdArgs: AudioCommand = [id, structuredClone(opts)];

		if (recipients === "all") {
			this.#clientAdd(id, opts);
			Net.sendToAll("sfx_add", cmdArgs);
		} else {
			for (const recipient of recipients) {
				if (!recipient) {
					this.#clientAdd(id, opts);
				} else {
					Net.send("sfx_add", cmdArgs, recipient);
				}
			}
		}

		const sound = this.#createServerSound({ ...opts, id });

		this.#playServerSound(sound);

		this.serverSFX.set(id, cmdArgs);

		return { soundId: id };
	}

	#clientAdd(id: number, opts: AudioOptions) {
		if (!netState.isClient) return;
		let result;

		if (opts.type === "playAtPos") {
			result = this.playAtPos(opts);
		} else if (opts.type === "playAtObj") {
			result = this.playAtObj(opts);
		} else if (opts.type === "play") {
			result = this.play(opts);
		} else if (opts.type === "playGlobally") {
			result = this.playGlobally(opts);
		}

		if (!result) return;

		const [sfx, promise] = result;

		if (!sfx) return;

		this.clientSFX.set(id, sfx);

		return promise;
	}

	serverRemove(id: number, fadeOutDuration: number = -1) {
		Net.sendToAll("sfx_remove", [id, fadeOutDuration]);

		this.#clientRemove(id, fadeOutDuration);

		this.serverSFX.delete(id);
	}

	#clientRemove(id: number, fadeOutDuration = 0) {
		if (!netState.isClient) return;

		if (fadeOutDuration <= 0) {
			this.#stopSound(id);
		} else {
			this.#fadeOutSound(id, fadeOutDuration);
		}
	}

	serverRemoveAll() {
		for (const id of this.serverSFX.keys()) this.serverRemove(id);
	}

	play(o1: Omit<PlayAudioOptions, "type">, o2?: SoundSpecific) {
		if (!netState.isClient) return;

		const sound = this.#createSound(o1, o2, false);

		return [sound, this.#playSound(sound)] as const;
	}

	playAtObj(o1: Omit<PlayAtObjectAudioOptions, "type">, o2?: SoundSpecific) {
		if (!netState.isClient) return;

		const entity = typeof o1.obj === "number" ? this.world.getEntity(o1.obj as EntityID) : o1.obj;

		if (!entity) return [null, Promise.resolve()] as const;

		const sound = this.#createSound({ ...o1, entityId: entity.entityID }, o2);

		entity.object3D.add(sound.audio);

		return [sound, this.#playSound(sound)] as const;
	}

	playAtPos(o1: Omit<PlayAtPositionAudioOptions, "type">, o2?: SoundSpecific) {
		if (!netState.isClient) return;

		const sound = this.#createSound(o1, o2);

		sound.audio.position.set(o1.x, o1.y, o1.z);
		this.world.scene.add(sound.audio);

		return [sound, this.#playSound(sound)] as const;
	}

	playGlobally(o: CommonAudioOptions) {
		if (!netState.isClient) return;

		const audio = this.world.client!.globalAudio;

		if (!audio) return;

		const fadeInDuration = o.fadeInDuration ?? 0;

		const sound = new LazyAudio(o.asset, audio, fadeInDuration, o.duration);

		const buffer = Resources.get(o.asset);

		audio.offset = 0;
		audio.setBuffer(buffer ?? emptyBuffer);
		audio.setLoop(o.loop ?? false);
		audio.setVolume(o.volume ?? 0.5);

		return [sound, this.#playSound(sound)] as const;
	}

	#stopSound(id: number) {
		if (!this.clientSFX.has(id)) return;

		const sfx = this.clientSFX.get(id);
		this.clientSFX.delete(id);

		if (sfx) {
			sfx.dispose();
		}
	}

	#fadeInSound(sound: any, duration: number) {
		const targetVolume = sound.getVolume();
		sound.setVolume(0);

		const tween = new TWEEN.Tween({ volume: 0 }).to({ volume: targetVolume }, duration * 1000);

		this.soundTweens.set(sound, tween);

		tween
			.onUpdate(({ volume }) => {
				if (!sound.isPlaying) {
					tween.stop();
					return;
				}

				sound.setVolume(volume);
			})
			.onComplete(() => {
				this.soundTweens.delete(sound);
			})
			.start();
	}

	#fadeOutSound(id: number, duration: number) {
		const sfx = this.clientSFX.get(id);
		if (!sfx) return;

		const existingTween = this.soundTweens.get(sfx);
		if (existingTween) existingTween.stop();

		const tween = new TWEEN.Tween({ volume: sfx.audio.getVolume() }).to({ volume: 0 }, duration * 1000);

		this.soundTweens.set(sfx, tween);

		tween
			.onUpdate(({ volume }) => {
				if (!sfx.audio.isPlaying) {
					tween.stop();
					return;
				}

				sfx.audio.setVolume(volume);
			})
			.onComplete(() => {
				this.#clientRemove(id);

				this.soundTweens.delete(sfx);
			})
			.start();
	}

	#createSound(
		o1: { asset: string; entityId?: number; fadeInDuration?: number },
		o2?: SoundSpecific,
		positional = true,
	) {
		const fadeInDuration = o2?.fadeInDuration ?? o1.fadeInDuration ?? 0;

		const sound = createLazyAudio(
			{ ...o1, fadeInDuration },
			this.world.client!.audios!,
			Resources.idToResource,
			this.world.client!.listener!,
			positional,
		);

		if (isNullish(o2)) return sound;

		const audio = sound.audio;

		if (!isNullish(o2.volume)) {
			const volume = audio.getVolume();
			audio.setVolume(o2.volume * volume);
		}

		if (!isNullish(o2.detune)) {
			audio.detune = o2.detune + audio.getDetune();
		}

		if (!isNullish(o2.playbackRate)) {
			const playbackRate = audio.getPlaybackRate();
			audio.setPlaybackRate(o2.playbackRate * playbackRate);
		}

		const positionalAudio = audio as PositionalAudio;

		if (!isNullish(o2.refDistance)) {
			const refDistance = positionalAudio.getRefDistance();
			positionalAudio.setRefDistance(o2.refDistance + refDistance);
		}

		if (!isNullish(o2.rolloffFactor)) {
			const rolloffFactor = positionalAudio.getRolloffFactor();
			positionalAudio.setRolloffFactor(o2.rolloffFactor * rolloffFactor);
		}

		if (!isNullish(o2.distanceModel)) {
			positionalAudio.setDistanceModel(o2.distanceModel);
		}

		if (!isNullish(o2.maxDistance)) {
			const maxDistance = positionalAudio.getMaxDistance();
			positionalAudio.setMaxDistance(o2.maxDistance + maxDistance);
		}

		return sound;
	}

	#createServerSound(opts: { id: number } & CommonAudioOptions) {
		const audio = new ServerAudio(this.world, opts.id, opts);
		return audio;
	}

	#playSound(sound: LazyAudio) {
		const audio = sound.audio;

		const playAudio = () => {
			if (sound.fadeInDuration) {
				this.#fadeInSound(sound, sound.fadeInDuration);
			}
			audio.play();
		};

		return new Promise<void>((resolve) => {
			if (audio.isPlaying) return resolve();

			audio.onEnded = function () {
				Audio.prototype.onEnded.call(this);
				audio.removeFromParent();
				resolve();
			};

			if (!sound.shouldBeLoaded()) {
				playAudio();
				return;
			}

			if (this.#hasLoadedAsset(sound.asset)) {
				sound.audio.setBuffer(Resources.get(sound.asset));
				playAudio();
				return;
			}

			let assetLoadedPromise = this.clientWaitingForAssets.get(sound.asset);
			if (!assetLoadedPromise) {
				assetLoadedPromise = createRequestPromise();
				this.clientWaitingForAssets.set(sound.asset, assetLoadedPromise);
			}

			assetLoadedPromise.then(() => {
				if (sound.disposed) return;

				// We don't want to play short SFXs after they've been downloaded.
				if (sound.canBeSkipped()) {
					audio.removeFromParent();
					resolve();
					return;
				}

				sound.audio.setBuffer(Resources.get(sound.asset));
				playAudio();
			});
		});
	}

	#playServerSound(sound: ServerAudio) {
		return new Promise<void>((resolve) => {
			if (sound.isPlaying) return resolve();

			sound.onEnded = function () {
				ServerAudio.prototype.onEnded.call(this);
				sound.removeFromParent();
				resolve();
			};

			sound.play();
		});
	}

	initCommandListeners() {
		if (netState.isHost) {
			Net.listen("multiplayer_uglystates", (_data, _world, peer) => {
				for (const sfx of this.serverSFX.values())
					if (sfx)
						// if receipts are "all"
						Net.send("sfx_add", sfx, peer);
			});
		} else {
			Net.listen("sfx_add", (args: [number, AudioOptions]) => {
				this.#clientAdd(...args);
			});

			Net.listen("sfx_remove", (args: [number, number]) => {
				this.#clientRemove(...args);
			});
		}
	}

	#hasLoadedAsset(asset: string) {
		return Resources.isLoaded(asset);
	}

	#onAssetLoaded = ({ id }: { id: string; data: any }) => {
		const promise = this.clientWaitingForAssets.get(id);

		if (!promise) return;

		promise.resolve();

		this.clientWaitingForAssets.delete(id);
	};

	dispose() {
		for (const sfx of this.clientSFX.values()) {
			if (!sfx) continue;

			sfx.dispose();
		}

		this.clientSFX.clear();

		this.clientWaitingForAssets.clear();

		Resources.onResourceLoaded.remove(this.#onAssetLoaded);
	}
}
