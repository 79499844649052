import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { type PeerMetadata, getDefaultPermissions } from "@jamango/engine/PeerMetadata.ts";
import { assertNever, isNullish } from "@jamango/helpers";
import { changePermissions } from "server/world/Permissions";
import {
	CHARACTER_MOVEMENT_DEFAULTS,
	CharacterMovementMode,
} from "base/world/entity/component/CharacterMovement";
import type { Character } from "base/world/entity/Character";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { WorldUserRole, type IEnvironmentPresetSettings } from "@jamango/content-client";
import { netState } from "router/Parallelogram";
import { WorldEditorStatus } from "base/world/WorldEditor";
import { TONE_MAPPING } from "@jamango/content-client";

const NUMBER_VALIDATION = (allowEqualToMax: boolean, min = 0, max = 10) => {
	return [
		{
			condition: (value: number) => (allowEqualToMax ? value > max : value >= max),
			message: () => `Input value must be less than ${allowEqualToMax ? "or equal to " : ""}${max}`,
		},
		{
			condition: (value: number) => value < min,
			message: () => `Input value must be greater than or equal to ${min}`,
		},
	];
};

const STRING_VALIDATION = (str = "Input value") => {
	return [
		{
			condition: (value: string) => value === "",
			message: () => `${str} cannot be empty`,
		},
	];
};

type CharacterMovementModeString = "binary" | "momentum" | "dynamic";

const mapCharacterMovementModeFromString = (mode: CharacterMovementModeString) => {
	switch (mode) {
		case "binary":
			return CharacterMovementMode.BINARY;
		case "momentum":
			return CharacterMovementMode.KINEMATIC;
		case "dynamic":
			return CharacterMovementMode.DYNAMIC;
		default:
			assertNever(mode);
	}
};

export const Settings = [
	node({
		id: "44bee7bf-4834-40e3-8aa1-ccefb5789a8b",
		name: "Peer Set Permissions",
		type: NODE_TYPE_ID.function.player,
		description: "Sets which actions a peer is allowed to take",
		inputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
			cameraPOV: {
				name: "Camera Perspective",
				type: "string",
				optional: true,
				control: "select",
				config: {
					defaultValue: getDefaultPermissions().cameraPOV,
					explicitSortOptions: [
						{ value: "both", label: "1st and 3rd Person" },
						{ value: "first", label: "1st Person" },
						{ value: "third", label: "3rd Person" },
					],
				},
			},
			canUseIndividualBlocks: {
				name: "Can Use Individual Blocks",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: {
					defaultValue: getDefaultPermissions().canUseIndividualBlocks,
				},
			},
			canUseWrench: {
				name: "Can Use Wrench",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: getDefaultPermissions().canUseWrench },
			},
			canUsePencil: {
				name: "Can Use Pencil",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: getDefaultPermissions().canUsePencil },
			},
			canFly: {
				name: "Can Fly",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: getDefaultPermissions().canFly },
			},
			canInteract: {
				name: "Can Interact",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: getDefaultPermissions().canInteract },
			},
			canForceRespawn: {
				name: "Can Force Respawn",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: getDefaultPermissions().canForceRespawn },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const peer = ctx.world.playerToPeer.get(inputs.peer);
			if (!peer) throw Error("No peer found");

			//prevent creator(s) from locking themselves out of necessary permissions
			const role = getPeerMetadata(peer).role;
			if (
				(role === WorldUserRole.OWNER || role === WorldUserRole.COLLABORATOR) &&
				netState.isClient &&
				ctx.world.editor.status === WorldEditorStatus.EDITING
			)
				return;

			type Perms = PeerMetadata["permissions"];
			const filteredInputs: Partial<Perms> = {};

			if (!isNullish(inputs.cameraPOV))
				filteredInputs.cameraPOV = inputs.cameraPOV as Perms["cameraPOV"];
			if (!isNullish(inputs.canUseIndividualBlocks))
				filteredInputs.canUseIndividualBlocks = inputs.canUseIndividualBlocks;
			if (!isNullish(inputs.canUseWrench)) filteredInputs.canUseWrench = inputs.canUseWrench;
			if (!isNullish(inputs.canUsePencil)) filteredInputs.canUsePencil = inputs.canUsePencil;
			if (!isNullish(inputs.canFly)) filteredInputs.canFly = inputs.canFly;
			if (!isNullish(inputs.canInteract)) filteredInputs.canInteract = inputs.canInteract;
			if (!isNullish(inputs.canForceRespawn)) filteredInputs.canForceRespawn = inputs.canForceRespawn;

			changePermissions(ctx.world, peer, filteredInputs);
		},
	}),
	node({
		id: "75e52e50-7dbc-49f4-9e3b-7627d33c62f3",
		name: "Peer Get Permissions",
		type: NODE_TYPE_ID.function.player,
		description: "Gets which actions a peer is allowed to take",
		inputs: {
			peer: { name: "Peer", type: "entity" },
		},
		outputs: {
			cameraPOV: {
				name: "Camera Perspective",
				type: "string",
				config: { collapsible: true },
			},
			canUseIndividualBlocks: {
				name: "Can Use Individual Blocks",
				type: "boolean",
				config: { collapsible: true },
			},
			canUseWrench: {
				name: "Can Use Wrench",
				type: "boolean",
				config: { collapsible: true },
			},
			canUsePencil: {
				name: "Can Use Pencil",
				type: "boolean",
				config: { collapsible: true },
			},
			canFly: {
				name: "Can Fly",
				type: "boolean",
				config: { collapsible: true },
			},
			canInteract: {
				name: "Can Interact",
				type: "boolean",
				config: { collapsible: true },
			},
			canForceRespawn: {
				name: "Can Force Respawn",
				type: "boolean",
				config: { collapsible: true },
			},
		},
		resolve(inputs, ctx) {
			const peer = ctx.world.playerToPeer.get(inputs.peer);
			if (!peer) throw Error("No peer found");

			return structuredClone(getPeerMetadata(peer).permissions);
		},
	}),
	node({
		id: "75c74fcb-1f93-4834-bf77-d2ea821c5217",
		name: "Character Set Movement Settings",
		type: NODE_TYPE_ID.function.character,
		description:
			"Alters how a character moves. Time is measured in seconds, speed and velocity in blocks per second, acceleration in blocks per second squared.",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			walkSpeed: {
				name: "Walk Speed",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.walkSpeed,
					validation: [
						{
							condition: (value: number) => value > 100,
							message: () => "The maximum speed allowed is 100.",
						},
						{
							condition: (value: number) => value < -100,
							message: () => "The minimum speed allowed in -100.",
						},
					],
				},
			},
			crouchSpeed: {
				name: "Crouch Speed",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.crouchSpeed },
			},
			airSpeed: {
				name: "Air Speed",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.airSpeed },
			},
			flySpeed: {
				name: "Fly Speed",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.flySpeed },
			},
			jumpVelocity: {
				name: "Jump Velocity",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.jumpVelocity },
			},
			terminalVelocity: {
				name: "Terminal Velocity",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.terminalVelocity },
			},

			sprintSpeedMultiplier: {
				name: "Sprint Speed Multiplier",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.sprintSpeedMultiplier,
					validation: [
						{
							condition: (value: number) => value > 10,
							message: () => "The maximum speed multiplier is 10.",
						},
						{
							condition: (value: number) => value < -10,
							message: () => "The minimum speed multiplier in -10.",
						},
					],
				},
			},
			sightsSpeedMultiplier: {
				name: "Sights Speed Multiplier",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.sightsSpeedMultiplier,
				},
			},

			jumpCooldownTime: {
				name: "Jump Cooldown Time",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.jumpCooldownTime },
			},
			coyoteTime: {
				name: "Coyote Time",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.coyoteTime },
			},
			slidingGraceTime: {
				name: "Sliding Grace Time",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.slidingGraceTime },
			},

			canSprint: {
				name: "Can Sprint",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.canSprint },
			},
			canCrouch: {
				name: "Can Crouch",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.canCrouch },
			},
			canJump: {
				name: "Can Jump",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.canJump },
			},
			canMoveHorizontally: {
				name: "Can Move Horizontally",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.canMoveHorizontally,
				},
			},
			hasCrouchBarriers: {
				name: "Has Crouch Barriers",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.hasCrouchBarriers },
			},

			movementStyle: {
				name: "Movement Style",
				type: "string",
				optional: true,
				control: "select",
				config: {
					explicitSortOptions: [
						{ value: "momentum", label: "Momentum" },
						{ value: "binary", label: "Binary" },
						{ value: "dynamic", label: "Dynamic" },
					],
				},
			},
			maxHorizontalSpeedMultiplier: {
				name: "Max Horizontal Speed Multiplier",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.maxHorizontalSpeedMultiplier,
				},
			},
			groundAcceleration: {
				name: "Ground Acceleration",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.groundAcceleration,
				},
			},
			groundFriction: {
				name: "Ground Friction",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.groundFriction },
			},
			groundFrictionStopMultiplier: {
				name: "Ground Friction Stop Multiplier",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.groundFrictionStopMultiplier,
				},
			},
			groundStopSpeed: {
				name: "Ground Stop Speed",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.groundStopSpeed },
			},
			airAcceleration: {
				name: "Air Acceleration",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.airAcceleration },
			},
			airFriction: {
				name: "Air Friction",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: CHARACTER_MOVEMENT_DEFAULTS.airFriction },
			},
			airFrictionStopMultiplier: {
				name: "Air Friction Stop Multiplier",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: CHARACTER_MOVEMENT_DEFAULTS.airFrictionStopMultiplier,
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const character = inputs.character as Character;

			if (!isNullish(inputs.walkSpeed)) character.movement.def.walkSpeed = inputs.walkSpeed;
			if (!isNullish(inputs.crouchSpeed)) character.movement.def.crouchSpeed = inputs.crouchSpeed;
			if (!isNullish(inputs.airSpeed)) character.movement.def.airSpeed = inputs.airSpeed;
			if (!isNullish(inputs.flySpeed)) character.movement.def.flySpeed = inputs.flySpeed;
			if (!isNullish(inputs.jumpVelocity)) character.movement.def.jumpVelocity = inputs.jumpVelocity;
			if (!isNullish(inputs.terminalVelocity))
				character.movement.def.terminalVelocity = inputs.terminalVelocity;

			if (!isNullish(inputs.sprintSpeedMultiplier))
				character.movement.def.sprintSpeedMultiplier = inputs.sprintSpeedMultiplier;
			if (!isNullish(inputs.sightsSpeedMultiplier))
				character.movement.def.sightsSpeedMultiplier = inputs.sightsSpeedMultiplier;

			if (!isNullish(inputs.jumpCooldownTime))
				character.movement.def.jumpCooldownTime = inputs.jumpCooldownTime;
			if (!isNullish(inputs.coyoteTime)) character.movement.def.coyoteTime = inputs.coyoteTime;
			if (!isNullish(inputs.slidingGraceTime))
				character.movement.def.slidingGraceTime = inputs.slidingGraceTime;

			if (!isNullish(inputs.canSprint)) character.setCanSprint(inputs.canSprint);
			if (!isNullish(inputs.canCrouch)) character.setCanCrouch(inputs.canCrouch);
			if (!isNullish(inputs.canJump)) character.movement.def.canJump = inputs.canJump;
			if (!isNullish(inputs.canMoveHorizontally))
				character.setCanMoveHorizontally(inputs.canMoveHorizontally);
			if (!isNullish(inputs.hasCrouchBarriers))
				character.movement.def.hasCrouchBarriers = inputs.hasCrouchBarriers;

			if (!isNullish(inputs.movementStyle))
				character.movement.def.mode = mapCharacterMovementModeFromString(inputs.movementStyle);
			if (!isNullish(inputs.groundAcceleration))
				character.movement.def.groundAcceleration = inputs.groundAcceleration;
			if (!isNullish(inputs.groundFriction))
				character.movement.def.groundFriction = inputs.groundFriction;
			if (!isNullish(inputs.groundFrictionStopMultiplier))
				character.movement.def.groundFrictionStopMultiplier = inputs.groundFrictionStopMultiplier;
			if (!isNullish(inputs.groundStopSpeed))
				character.movement.def.groundStopSpeed = inputs.groundStopSpeed;
			if (!isNullish(inputs.airAcceleration))
				character.movement.def.airAcceleration = inputs.airAcceleration;
			if (!isNullish(inputs.airFriction)) character.movement.def.airFriction = inputs.airFriction;
			if (!isNullish(inputs.airFrictionStopMultiplier))
				character.movement.def.airFrictionStopMultiplier = inputs.airFrictionStopMultiplier;
		},
	}),
	node({
		id: "8ba044f1-ef39-46e0-a6be-468e3e0f4567",
		name: "Character Get Movement Settings",
		type: NODE_TYPE_ID.function.character,
		description:
			"Alters how a character moves. Time is measured in seconds, speed and velocity in blocks per second, acceleration in blocks per second squared.",
		predictable: true,
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			walkSpeed: {
				name: "Walk Speed",
				type: "number",
				config: { collapsible: true },
			},
			crouchSpeed: {
				name: "Crouch Speed",
				type: "number",
				config: { collapsible: true },
			},
			airSpeed: {
				name: "Air Speed",
				type: "number",
				config: { collapsible: true },
			},
			flySpeed: {
				name: "Fly Speed",
				type: "number",
				config: { collapsible: true },
			},
			jumpVelocity: {
				name: "Jump Velocity",
				type: "number",
				config: { collapsible: true },
			},
			terminalVelocity: {
				name: "Terminal Velocity",
				type: "number",
				config: { collapsible: true },
			},

			sprintSpeedMultiplier: {
				name: "Sprint Speed Multiplier",
				type: "number",
				config: { collapsible: true },
			},
			sightsSpeedMultiplier: {
				name: "Sights Speed Multiplier",
				type: "number",
				config: { collapsible: true },
			},

			jumpCooldownTime: {
				name: "Jump Cooldown Time",
				type: "number",
				config: { collapsible: true },
			},
			coyoteTime: {
				name: "Coyote Time",
				type: "number",
				config: { collapsible: true },
			},
			slidingGraceTime: {
				name: "Sliding Grace Time",
				type: "number",
				config: { collapsible: true },
			},

			canSprint: {
				name: "Can Sprint",
				type: "boolean",
				config: { collapsible: true },
			},
			canCrouch: {
				name: "Can Crouch",
				type: "boolean",
				config: { collapsible: true },
			},
			canJump: {
				name: "Can Jump",
				type: "boolean",
				config: { collapsible: true },
			},
			canMoveHorizontally: {
				name: "Can Move Horizontally",
				type: "boolean",
				config: { collapsible: true },
			},
			hasCrouchBarriers: {
				name: "Has Crouch Barriers",
				type: "boolean",
				config: { collapsible: true },
			},

			movementStyle: {
				name: "Movement Style",
				type: "string",
				config: { collapsible: true },
			},
			maxHorizontalSpeedMultiplier: {
				name: "Max Horizontal Speed Multiplier",
				type: "number",
				config: { collapsible: true },
			},
			groundAcceleration: {
				name: "Ground Acceleration",
				type: "number",
				config: { collapsible: true },
			},
			groundFriction: {
				name: "Ground Friction",
				type: "number",
				config: { collapsible: true },
			},
			groundFrictionStopMultiplier: {
				name: "Ground Friction Stop Multiplier",
				type: "number",
				config: { collapsible: true },
			},
			groundStopSpeed: {
				name: "Ground Stop Speed",
				type: "number",
				config: { collapsible: true },
			},
			airAcceleration: {
				name: "Air Acceleration",
				type: "number",
				config: { collapsible: true },
			},
			airFriction: {
				name: "Air Friction",
				type: "number",
				config: { collapsible: true },
			},
			airFrictionStopMultiplier: {
				name: "Air Friction Stop Multiplier",
				type: "number",
				config: { collapsible: true },
			},
		},
		resolve(inputs) {
			const character = inputs.character as Character;
			return {
				...character.movement.def,
				movementStyle: character.movement.def.mode ? "binary" : "momentum",
			};
		},
	}),
	node({
		id: "1db6b966-eb3d-4dc7-bc56-abbb9bb624f7",
		name: "Character Set Settings",
		type: NODE_TYPE_ID.function.character,
		description: "Sets miscellaneous character settings.",
		predictable: false, //i think this is only necessitated by setMaxHealth
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			nameplateText: {
				name: "Nameplate Text",
				type: "string",
				optional: true,
				control: "string",
			},
			nameplateVisible: {
				name: "Nameplate Visible",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: true },
			},
			fallDamage: {
				name: "Fall Damage",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: false },
			},
			invincible: {
				name: "Invincible",
				type: "boolean",
				optional: true,
				control: "boolean",
				config: { defaultValue: false },
			},
			maxHealth: {
				name: "Max Health",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: 100 },
			},
			scale: {
				name: "Scale",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1,
					validation: [
						{
							condition: (value: number) => value > 5,
							message: () => "The maximum scale allowed is 5.",
						},
						{
							condition: (value: number) => value < 0.2,
							message: () => "The minimum scale allowed is 0.2.",
						},
					],
				},
			},
			healthRegenRate: {
				name: "Health Regen Rate (HP/second)",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: 10 },
			},
			healthRegenDelay: {
				name: "Health Regen Delay (seconds)",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: 3 },
			},
			respawnTime: {
				name: "Respawn Time (seconds)",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: 3 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const character = inputs.character as Character;

			if (!isNullish(inputs.nameplateText))
				character.nameplate.def.nameplate.setText(inputs.nameplateText);
			if (!isNullish(inputs.nameplateVisible)) character.nameplate.state.show = inputs.nameplateVisible;
			if (!isNullish(inputs.fallDamage)) character.fallDamage.def.enable = inputs.fallDamage;
			if (!isNullish(inputs.invincible)) character.health.def.invincible = inputs.invincible;
			if (!isNullish(inputs.maxHealth)) character.setMaxHealth(inputs.maxHealth);
			if (!isNullish(inputs.scale)) character.setScale(inputs.scale);
			if (!isNullish(inputs.healthRegenRate)) character.regen.def.rate = inputs.healthRegenRate;
			if (!isNullish(inputs.healthRegenDelay)) character.regen.def.delay = inputs.healthRegenDelay;
			if (!isNullish(inputs.respawnTime)) character.respawnTime = inputs.respawnTime;
		},
	}),
	node({
		id: "dc017913-19f4-498a-80ed-338167f999a3",
		name: "Character Get Settings",
		type: NODE_TYPE_ID.function.character,
		description: "Gets miscellaneous character settings.",
		predictable: true,
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			nameplateText: {
				name: "Nameplate Text",
				type: "string",
				config: { collapsible: true },
			},
			nameplateVisible: {
				name: "Nameplate Visible",
				type: "boolean",
				config: { collapsible: true },
			},
			fallDamage: {
				name: "Fall Damage",
				type: "boolean",
				config: { collapsible: true },
			},
			invincible: {
				name: "Invincible",
				type: "boolean",
				config: { collapsible: true },
			},
			maxHealth: {
				name: "Max Health",
				type: "number",
				config: { collapsible: true },
			},
			scale: { name: "Scale", type: "number", config: { collapsible: true } },
			healthRegenRate: {
				name: "Health Regen Rate (HP/second)",
				type: "number",
				config: { collapsible: true },
			},
			healthRegenDelay: {
				name: "Health Regen Delay (seconds)",
				type: "number",
				config: { collapsible: true },
			},
			respawnTime: {
				name: "Respawn Time (seconds)",
				type: "number",
				config: { collapsible: true },
			},
		},
		resolve(inputs) {
			const character = inputs.character as Character;

			const ret = {
				nameplateText: character.nameplate.def.nameplate.getText(),
				nameplateVisible: character.nameplate.state.show,
				fallDamage: character.fallDamage.def.enable,
				invincible: character.health.def.invincible,
				maxHealth: character.health.def.max,
				scale: character.size.state.scale,
				healthRegenRate: character.regen.def.rate,
				healthRegenDelay: character.regen.def.delay,
				respawnTime: character.respawnTime,
			};

			return ret;
		},
	}),
	node({
		id: "97755cd1-87fd-497c-9f59-15e257cd046e",
		name: "World Set Environment Settings",
		type: NODE_TYPE_ID.function.environment,
		description: "Sets settings related to the environment, sky, and lighting.",
		inputs: {
			exec: { type: "exec" },

			//default values are taken from the default world package
			sunIntensity: {
				name: "Sun Intensity",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1.26,
					validation: NUMBER_VALIDATION(true),
				},
			},
			sunColor: {
				name: "Sun Color",
				type: "string",
				optional: true,
				control: "color",
				config: {
					defaultValue: "#ffffff",
					validation: STRING_VALIDATION(),
				},
			},
			nightLuminosity: {
				name: "Night Luminosity",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0.81,
					validation: NUMBER_VALIDATION(true),
				},
			},
			hour: {
				name: "Hour",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 17.29,
					validation: NUMBER_VALIDATION(false, 0, 24),
				},
			},
			azimuth: {
				name: "Azimuth",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 21,
					validation: NUMBER_VALIDATION(false, 0, 360),
				},
			},
			toneMapping: {
				name: "Tone Mapping",
				type: "string",
				optional: true,
				control: "select",
				config: {
					defaultValue: "ACESFilmicToneMapping",
					autoSortOptions: () =>
						Object.values(TONE_MAPPING).map((value) => {
							return {
								label: value,
								value,
							};
						}),
				},
			},
			toneMappingExposure: {
				name: "Toning Mapping Exposure",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1.26,
					validation: NUMBER_VALIDATION(true),
				},
			},
			saturation: {
				name: "Saturation",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1.3,
					validation: NUMBER_VALIDATION(true),
				},
			},
			haze: {
				name: "Haze",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0.1,
					validation: NUMBER_VALIDATION(true),
				},
			},
			fogy: {
				name: "Fog Y",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0,
					validation: NUMBER_VALIDATION(true),
				},
			},
			cloudSize: {
				name: "Cloud Size",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0.29,
					validation: NUMBER_VALIDATION(true, 0, 1),
				},
			},
			cloudDensity: {
				name: "Cloud Density",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1,
					validation: NUMBER_VALIDATION(true, 0, 1),
				},
			},
			cloudCoverage: {
				name: "Cloud Coverage",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0.56,
					validation: NUMBER_VALIDATION(true, 0, 1),
				},
			},
			cloudDistance: {
				name: "Cloud Distance",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 0.64,
					validation: NUMBER_VALIDATION(true, 0, 1),
				},
			},
			sample: {
				name: "Sample",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 128,
					validation: NUMBER_VALIDATION(true, 0, 128),
				},
			},
			step: {
				name: "Step",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 16,
					validation: NUMBER_VALIDATION(true, 0, 16),
				},
			},
			skyColor: {
				name: "Sky Color",
				type: "string",
				optional: true,
				control: "color",
				config: {
					defaultValue: "#2e9bc8",
					validation: STRING_VALIDATION(),
				},
			},
			cloudColor: {
				name: "Cloud Color",
				type: "string",
				optional: true,
				control: "color",
				config: {
					defaultValue: "#fddfae",
					validation: STRING_VALIDATION(),
				},
			},
			fogColor: {
				name: "Fog Color",
				type: "string",
				optional: true,
				control: "color",
				config: {
					defaultValue: "#add7ff",
					validation: STRING_VALIDATION(),
				},
			},
			groundColor: {
				name: "Ground Color",
				optional: true,
				type: "string",
				control: "color",
				config: {
					defaultValue: "#bb7831",
					validation: STRING_VALIDATION(),
				},
			},
			fogNear: {
				name: "Fog Near",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 200,
					validation: NUMBER_VALIDATION(true, 0, 200),
				},
			},
			fogFar: {
				name: "Fog Far",
				type: "number",
				optional: true,
				control: "number",
				config: {
					defaultValue: 1,
					validation: NUMBER_VALIDATION(true, 0, 200),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const filteredInputs: Partial<IEnvironmentPresetSettings> = {};
			for (const key in inputs) {
				const val = inputs[key];
				if (!isNullish(val)) filteredInputs[key as keyof IEnvironmentPresetSettings] = val;
			}

			ctx.world.environmentSettings.router.change(filteredInputs, true);
		},
	}),
	node({
		id: "fb5796ac-a57b-4aaa-9038-4bedf5a3ae49",
		name: "World Get Environment Settings",
		type: NODE_TYPE_ID.function.environment,
		description: "Gets settings related to the environment, sky, and lighting.",
		outputs: {
			//light
			sunIntensity: {
				name: "Sun Intensity",
				type: "number",
				config: { collapsible: true },
			},
			sunColor: {
				name: "Sun Color",
				type: "string",
				config: { collapsible: true },
			},
			nightLuminosity: {
				name: "Night Luminosity",
				type: "number",
				config: { collapsible: true },
			},

			//general
			hour: { name: "Hour", type: "number", config: { collapsible: true } },
			azimuth: {
				name: "Azimuth",
				type: "number",
				config: { collapsible: true },
			},

			//sky
			toneMapping: {
				name: "Tone Mapping",
				type: "string",
				config: { collapsible: true },
			},
			toneMappingExposure: {
				name: "Toning Mapping Exposure",
				type: "number",
				config: { collapsible: true },
			},
			saturation: {
				name: "Saturation",
				type: "number",
				config: { collapsible: true },
			},
			haze: { name: "Haze", type: "number", config: { collapsible: true } },
			fogy: { name: "Fog Y", type: "number", config: { collapsible: true } },
			cloudSize: {
				name: "Cloud Size",
				type: "number",
				config: { collapsible: true },
			},
			cloudDensity: {
				name: "Cloud Density",
				type: "number",
				config: { collapsible: true },
			},
			cloudCoverage: {
				name: "Cloud Coverage",
				type: "number",
				config: { collapsible: true },
			},
			cloudDistance: {
				name: "Cloud Distance",
				type: "number",
				config: { collapsible: true },
			},
			sample: { name: "Sample", type: "number", config: { collapsible: true } },
			step: { name: "Step", type: "number", config: { collapsible: true } },
			skyColor: {
				name: "Sky Color",
				type: "string",
				config: { collapsible: true },
			},
			cloudColor: {
				name: "Cloud Color",
				type: "string",
				config: { collapsible: true },
			},
			fogColor: {
				name: "Fog Color",
				type: "string",
				config: { collapsible: true },
			},
			groundColor: {
				name: "Ground Color",
				type: "string",
				config: { collapsible: true },
			},

			//fog
			fogNear: {
				name: "Fog Near",
				type: "number",
				config: { collapsible: true },
			},
			fogFar: {
				name: "Fog Far",
				type: "number",
				config: { collapsible: true },
			},
		},
		resolve(inputs, ctx) {
			const env = ctx.world.environmentSettings.environment;

			const ret = {
				sunIntensity: env.light.sunIntensity,
				sunColor: env.light.sunColor,
				nightLuminosity: env.light.nightLuminosity,

				hour: env.general.hour,
				azimuth: env.general.azimuth,

				toneMapping: env.sky.toneMapping,
				toneMappingExposure: env.sky.toneMappingExposure,
				saturation: env.sky.saturation,
				haze: env.sky.haze,
				fogy: env.sky.fogy,
				cloudSize: env.sky.cloudSize,
				cloudDensity: env.sky.cloudDensity,
				cloudCoverage: env.sky.cloudCoverage,
				cloudDistance: env.sky.cloudDistance,
				sample: env.sky.sample,
				step: env.sky.step,
				skyColor: env.sky.skyColor,
				cloudColor: env.sky.cloudColor,
				fogColor: env.sky.fogColor,
				groundColor: env.sky.groundColor,

				fogNear: env.fog.fogNear,
				fogFar: env.fog.fogFar,
			};

			return ret;
		},
	}),
];
