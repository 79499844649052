/**
 * Feature flags configured in the rails backend.
 */
export const FEATURE_FLAGS = {
	/**
	 * If enabled, displays the "Experiments" tab in the world inventory.
	 */
	WORLD_EXPERIMENTS: "world_experiments",

	/**
	 * If enabled, "internal" world experiments are presented in the world inventory.
	 */
	WORLD_EXPERIMENTS_INTERNAL: "world_experiments_internal",

	/**
	 * If enabled, `window.DEBUG` is set.
	 */
	CONSOLE_DEBUG: "console_debug",

	/**
	 * If enabled, displays the "World JSON Template" field in the Create World popup.
	 */
	WORLD_JSON_TEMPLATE: "world_json_template",

	/**
	 * If enabled, shows the "Create Package" button in the inventory
	 */
	CREATE_PACKAGE: "create_package",
};

/**
 * World-level experimental feature settings.
 */
export const WORLD_EXPERIMENTS = {
	WEAPON_NODES: {
		id: "weapon-nodes",
		label: "Weapon Nodes",
		description: "Nodes for weapons.",
		internal: true,
	},
};

export const WORLD_EXPERIMENTS_VALUES = Object.values(WORLD_EXPERIMENTS);
