import { Quaternion, Vector3, Matrix4 } from "three";
import type { Object3D, Euler } from "three";
import { V0, V1, E0 } from "base/util/math/Math.ts";
import { PerlinNoiseGenerator } from "base/util/math/Perlin.js";
import { isNullish } from "@jamango/helpers";

export type DefTransformOptions = {
	pos?: Vector3;
	rot?: Euler;
	scl?: Vector3 | number;
};

export function defTransform(o: DefTransformOptions, out: Matrix4): Matrix4;
export function defTransform(o: DefTransformOptions, out: Object3D): Object3D;

export function defTransform(o: DefTransformOptions, out: Matrix4 | Object3D) {
	if (out instanceof Matrix4) {
		const rot = new Quaternion();
		if (!isNullish(o?.rot)) rot.setFromEuler(o.rot);

		const scl = new Vector3();
		if (typeof o?.scl === "number") scl.setScalar(o.scl);
		else scl.copy(o?.scl ?? V1);

		out.compose(o?.pos ?? V0, rot, scl);
	} //Object3D
	else {
		out.position.copy(o?.pos ?? V0);
		out.rotation.copy(o?.rot ?? E0);

		if (typeof o?.scl === "number") out.scale.setScalar(o.scl);
		else out.scale.copy(o?.scl ?? V1);
	}

	return out;
}

export type DefPerlinOptions = {
	scale?: number;
	octaves?: number;
};

export function defPerlin(o: DefPerlinOptions, ...seed: (string | number)[]) {
	return new PerlinNoiseGenerator(o.scale ?? 1, o?.octaves ?? 1, ...seed);
}
