import type { CustomOptions } from "@jamango/content-client";
import { defPerlin } from "base/util/Defs";
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import type { PerlinNoiseGenerator } from "base/util/math/Perlin";
import { ChunkBuilder } from "base/world/block/Builder";
import type { Chunk } from "base/world/block/chunk/Chunk";
import {
	BLOCK_AIR,
	BLOCK_BL,
	BLOCK_BR,
	BLOCK_CUBE,
	BLOCK_SNY,
	BLOCK_SPY,
	BLOCK_TL,
	BLOCK_TR,
	BLOCK_VIS_LEFT,
	BLOCK_VIS_RIGHT,
} from "base/world/block/Util";
import { MathUtils } from "three";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import type { BlockID, ITerrain } from "./types";

export class GeneratorCustomTerrain implements ITerrain {
	static name = "bb.generator.terrain.custom" as const;
	static display = "Custom" as const;
	isTerrain = true as const;

	mprob: number;

	topBlock: BlockID = -1;
	midBlock: BlockID = -1;
	foundationBlock: BlockID = -1;

	sandBlock: BlockID = -1;
	mountainBlock: BlockID = -1;
	snowBlock: BlockID = -1;

	groundPerlin?: PerlinNoiseGenerator;
	mountainsPerlin?: PerlinNoiseGenerator;
	snowPerlin?: PerlinNoiseGenerator;

	heightCache?: LegacyVectorMap;
	mountainCache?: LegacyVectorMap;

	private isGroundEnabled = false;
	private isWaterEnabled = false;
	private isMountainsEnabled = false;
	private isSnowEnabled = false;

	private snowMin = 0;
	private snowMax = 0;

	private groundAmplitude = 0;
	private mountainsAmplitude = 0;
	private foundationLevel = 0;
	private waterLevel = 0;
	private groundTransition = 0;
	private chunkSize = 0;

	constructor(readonly options: CustomOptions) {
		this.isGroundEnabled = !!options.ground;
		this.groundTransition = -(options.ground?.vrTransition ?? 0);
		this.mprob = -2 * (options.mountains?.probability ?? 0) + 1;
		this.mountainsAmplitude = options.mountains?.amplitude ?? 0;
		this.groundAmplitude = options.ground?.amplitude ?? 0;
		this.foundationLevel = options.ground?.foundationLevel ?? 0;
		this.waterLevel = options.water?.level ?? 0;

		options.ground!.noiseOptions;

		this.isWaterEnabled = !!options.water;
		this.isMountainsEnabled = !!options.mountains;
		this.isSnowEnabled = !!options.snow;

		this.snowMin = options.snow?.min ?? 0;
		this.snowMax = options.snow?.max ?? 0;
	}

	init({
		seed,
		blockTypeRegistry,
		chunkSize,
	}: {
		seed: number;
		blockTypeRegistry: BlockTypeRegistryState;
		chunkSize: number;
	}) {
		this.chunkSize = chunkSize;
		const options = this.options;

		const blockNameToType = blockTypeRegistry.blockNameToType;

		const groundOptions = options.ground;
		if (groundOptions) {
			this.topBlock = blockNameToType.get(groundOptions.topBlock)?.id ?? -1;
			this.midBlock = blockNameToType.get(groundOptions.midBlock)?.id ?? -1;
			this.foundationBlock = blockNameToType.get(groundOptions.foundationBlock)?.id ?? -1;

			this.snowBlock = blockNameToType.get(options.snow?.snowBlock ?? groundOptions.topBlock)?.id ?? -1;
			this.mountainBlock =
				blockNameToType.get(options.mountains?.mountainBlock ?? groundOptions.foundationBlock)?.id ??
				-1;
			this.sandBlock =
				blockNameToType.get(options.water?.sandBlock ?? groundOptions.topBlock)?.id ?? -1;

			this.groundPerlin = defPerlin(
				groundOptions.noiseOptions,
				seed,
				"bb.generator.terrain.custom.ground",
			);
		}

		if (options.mountains) {
			this.mountainsPerlin = defPerlin(
				options.mountains.noiseOptions,
				seed,
				"bb.generator.terrain.custom.mountains",
			);
		}

		if (options.snow) {
			this.snowPerlin = defPerlin(options.snow.noiseOptions, seed, "bb.generator.terrain.custom.snow");
		}

		this.heightCache = new LegacyVectorMap();
		this.mountainCache = new LegacyVectorMap();
	}

	apply(chunk: Chunk) {
		const isWaterEnabled = this.isWaterEnabled;
		const isGroundEnabled = this.isGroundEnabled;
		const chunkSize = this.chunkSize;
		const shapes = chunk.storage.shapes;
		const types = chunk.storage.types;
		const cx = chunk.position.x;
		const cy = chunk.position.y;
		const cz = chunk.position.z;
		const tory = -cy * chunkSize;

		const getTopLayer = (y: number, isMountain: boolean, snowHeight: number): BlockID => {
			if (isMountain)
				return this.isSnowEnabled && y >= snowHeight ? this.snowBlock : this.mountainBlock;

			if (isWaterEnabled) {
				const rivery = this.waterLevel - 1 + tory;
				const beachy = rivery + 1;

				if (y <= rivery) return this.midBlock;
				if (y === beachy) return this.sandBlock;
			}

			return this.topBlock;
		};

		let rz = 0;
		let rx = 0;

		//single block spit out by the height map
		const buildLayer = (start: number, end: number, type: BlockID) => {
			for (let ry = start; ry < end; ry++) {
				const i = chunk.getIndex(rx, ry, rz);
				shapes[i] = BLOCK_CUBE;
				types[i] = type;
			}
		};

		let snowHeight = 0;

		for (rz = 0; rz < chunkSize; rz++) {
			for (rx = 0; rx < chunkSize; rx++) {
				if (!isGroundEnabled) continue;

				const ax = rx + cx * chunkSize;
				const az = rz + cz * chunkSize;

				const ryTL = this.getHeight(ax, az) + tory;
				const ryBL = this.getHeight(ax, az + 1) + tory;
				const ryTR = this.getHeight(ax + 1, az) + tory;
				const ryBR = this.getHeight(ax + 1, az + 1) + tory;

				let isMountain = this.isMountainsEnabled;

				if (isMountain) {
					const mountainTL = this.isMountain(ax, az);
					const mountainBL = this.isMountain(ax, az + 1);
					const mountainTR = this.isMountain(ax + 1, az);
					const mountainBR = this.isMountain(ax + 1, az + 1);
					isMountain = mountainTL && mountainBL && mountainTR && mountainBR;
				}

				if (isMountain) snowHeight = this.getSnowHeight(ax, az) + tory;

				let yMin = Math.min(ryTL, ryBL, ryTR, ryBR);
				const yMax = yMin + 1;
				const yBeneath = yMin - 1;
				const yPeak = yMax + 1;
				let sculpt = BLOCK_AIR;
				let shape = 0;

				if (ryTL >= yMax) sculpt |= BLOCK_TL;
				if (ryBL >= yMax) sculpt |= BLOCK_BL;
				if (ryTR >= yMax) sculpt |= BLOCK_TR;
				if (ryBR >= yMax) sculpt |= BLOCK_BR;

				if (sculpt === BLOCK_AIR) {
					shape = sculpt = BLOCK_CUBE;
					yMin--;
				} else {
					shape = sculpt | BLOCK_SPY;
				}

				if (yMin >= 0 && yMin < chunkSize) {
					const i = chunk.getIndex(rx, yMin, rz);
					shapes[i] = shape;
					types[i] = getTopLayer(yMin, isMountain, snowHeight);
				}

				//full block with a 1 up sliver piece on top: full block on bottom must be manually added
				const tris = ChunkBuilder.whichTriangles(shape, BLOCK_SNY);
				if (
					yBeneath >= 0 &&
					yBeneath < chunkSize &&
					(tris === BLOCK_VIS_LEFT || tris === BLOCK_VIS_RIGHT)
				) {
					yMin = yBeneath;
					const i = chunk.getIndex(rx, yMin, rz);
					shapes[i] = BLOCK_CUBE;
					types[i] = getTopLayer(yMin, isMountain, snowHeight);
				}

				//steep ramp blocks on top of each other: 1 up block on top must be manually added
				if (yMax >= 0 && yMax < chunkSize) {
					if (sculpt === (BLOCK_TL | BLOCK_BL | BLOCK_TR) && ryTL >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_TL;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_TL | BLOCK_BL | BLOCK_BR) && ryBL >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_BL;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_TL | BLOCK_TR | BLOCK_BR) && ryTR >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_TR;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_BL | BLOCK_TR | BLOCK_BR) && ryBR >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_BR;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					}
				}

				let columnStart;
				let columnEnd;

				if (isMountain) {
					columnStart = 0;
					columnEnd = Math.min(yMin, chunkSize);
					buildLayer(columnStart, columnEnd, this.mountainBlock);
				} else {
					//foundation layer
					columnStart = 0;
					columnEnd = Math.min(yMin + this.foundationLevel, chunkSize);
					buildLayer(columnStart, columnEnd, this.foundationBlock);

					//mid layer
					columnStart = Math.max(columnEnd, 0);
					columnEnd = Math.min(yMin, chunkSize);
					buildLayer(columnStart, columnEnd, this.midBlock);
				}
			}
		}
	}

	getHeight(ax: number, az: number) {
		const isGroundEnabled = this.isGroundEnabled;

		if (!isGroundEnabled) return 1;

		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);

		let cache = this.heightCache?.get(cx, cz) as Float64Array | undefined;
		if (!cache) {
			cache = new Float64Array(chunkSize ** 2);
			let height;

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const acx = rx + cx * chunkSize;
					const acz = rz + cz * chunkSize;

					const noiseM = this.isMountainsEnabled && this.getMountainHeight(acx, acz);
					const noiseGround = this.groundPerlin?.getNoise(acx, acz) ?? 0;
					const heightGround = Math.round(
						this.groundAmplitude * (1 - 2 ** (this.groundTransition * Math.abs(noiseGround))),
					);

					if (!!noiseM && noiseM > this.mprob) {
						height = Math.round(
							this.mountainsAmplitude *
								MathUtils.mapLinear(
									(noiseM + 1) / 2,
									(this.mprob + 1) / 2,
									1,
									heightGround / this.mountainsAmplitude,
									1,
								),
						);
					} else {
						height = heightGround;
					}

					cache[rz * chunkSize + rx] = height;
				}
			}

			this.heightCache?.set(cx, cz, cache);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return cache[rz * chunkSize + rx];
	}

	// height = [-1, 1)
	private getMountainHeight(ax: number, az: number): number {
		const noise = this.mountainsPerlin;

		if (noise === undefined) return 0;

		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);

		let cache = this.mountainCache?.get(cx, cz) as Float64Array | undefined;
		if (!cache) {
			cache = new Float64Array(chunkSize ** 2);

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const acx = rx + cx * chunkSize;
					const acz = rz + cz * chunkSize;
					cache[rz * chunkSize + rx] = noise.getNoise(acx, acz);
				}
			}

			this.mountainCache?.set(cx, cz, cache);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return cache[rz * chunkSize + rx];
	}

	private isMountain(ax: number, az: number) {
		return this.getMountainHeight(ax, az) > this.mprob;
	}

	private getSnowHeight(ax: number, az: number) {
		const noise = this.snowPerlin?.getNoise(ax, az) ?? 0;
		return Math.round(MathUtils.mapLinear(noise, -1, 1, this.snowMin, this.snowMax));
	}

	clearCache() {
		this.groundPerlin?.clearCache();
		this.mountainsPerlin?.clearCache();
		this.snowPerlin?.clearCache();

		this.heightCache?.clear();
		this.mountainCache?.clear();
	}
}
