import * as Physics from "base/world/Physics";
import type { Entity } from "base/world/entity/Entity";
import type { Prop } from "base/world/entity/Prop";

/**
 * Copies the resulting physics transforms and velocities to entity states,
 * which may be modified by nodes or other systems before the next physics step.
 */

export function propPhysicsAfterStepUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const prop = entity as Prop;
	const body = prop.propPhysics.state.body;

	if (body && prop.propPhysics.state.motionType !== Physics.MotionType.STATIC) {
		Physics.getBodyPositionAndRotation(body, prop.position, prop.quaternion);
		Physics.getBodyLinearVelocity(body, prop.propPhysics.state.linearVelocity);
		Physics.getBodyAngularVelocity(body, prop.propPhysics.state.angularVelocity);

		prop.propPhysics.state.active = Physics.getBodyIsActive(body);
	} else if (body) {
		prop.propPhysics.state.linearVelocity.set(0, 0, 0);
		prop.propPhysics.state.angularVelocity.set(0, 0, 0);

		prop.propPhysics.state.active = false;
	}
}

function canUpdateSystem(entity: Entity) {
	return entity.type.def.isProp;
}
