import { Box3 } from "three";
import { BlockModel } from "base/world/block/BlockModel";
import type {
	ObjectPlacerOptions,
	TerrainObject,
} from "@jamango/content-client/lib/types/terrainGeneration.ts";

export class ModelBuilder {
	readonly models: BlockModel[] = [];
	readonly modelsBounds = new Box3();
	readonly modelsChunkBounds = new Box3();

	constructor(private readonly chunkSize: number) {}

	buildObjects(objects: ObjectPlacerOptions["objects"]) {
		let blockModel: BlockModel;

		for (let i = 0; i < objects.length; i++) {
			const factory = objects[i].factory;

			if (factory.type === "static") {
				blockModel = this.buildStaticObjectModel(factory.template);
			} else throw new Error(`not implemented factory type: ${factory.type}`);

			const m = (this.models[i] = blockModel);
			this.expandBounds(m);
		}
	}

	expandBounds(m: BlockModel) {
		this.modelsBounds.expandByPoint(m.modelBounds.min);
		this.modelsBounds.expandByPoint(m.modelBounds.max);
		this.modelsChunkBounds.expandByPoint(m.chunkBounds.min);
		this.modelsChunkBounds.expandByPoint(m.chunkBounds.max);
	}

	private buildStaticObjectModel(object: TerrainObject) {
		const m = new BlockModel(this.chunkSize);

		object.forEach(([x, y, z, shape, type]) => m.setBlock(x, y, z, shape, type));

		return m;
	}
}
