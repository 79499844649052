export class CharacterRegenComponent {
	constructor(o, entity) {
		this.name = "regen";

		this.def = {
			rate: o?.healthRegenRate ?? 10,
			delay: o?.healthRegenDelay ?? 3,
		};

		this.state = {
			timer: 0,
		};
	}
}
