import { z } from "zod";
import { ACCEPTED_SKYBOX_TYPES, MAX_SKYBOX_FILE_SIZE } from "../constants/files";
import { formatBytes } from "../helpers/files";
import { assetKeySchema } from "./general";
import { AssetType } from "../types";
import { PackageIdSchema } from "./package";

const SizeSchema = z
	.number()
	.min(0)
	.max(MAX_SKYBOX_FILE_SIZE, {
		message: `Max skybox size is ${formatBytes(MAX_SKYBOX_FILE_SIZE)}.`,
	});
const FormatSchema = z.enum(ACCEPTED_SKYBOX_TYPES, {
	message: `Only ${ACCEPTED_SKYBOX_TYPES.join(", ")} formats are supported.`,
});

export const skyboxSide = (value: File) => {
	SizeSchema.parse(value.size);
	FormatSchema.parse(value.type);
};

export const SkyboxSchema = z.object({
	pk: assetKeySchema(AssetType.SKYBOX),
	type: z.literal(AssetType.SKYBOX),
	name: z.string(),
	nxResourcePk: assetKeySchema(AssetType.RESOURCE),
	nyResourcePk: assetKeySchema(AssetType.RESOURCE),
	nzResourcePk: assetKeySchema(AssetType.RESOURCE),
	pxResourcePk: assetKeySchema(AssetType.RESOURCE),
	pyResourcePk: assetKeySchema(AssetType.RESOURCE),
	pzResourcePk: assetKeySchema(AssetType.RESOURCE),
	isUsed: z.boolean(),
	packageId: PackageIdSchema.optional(),
});
