import type { ItemMesh, ItemMeshBlockStructure } from "@jamango/content-client";
import { ItemMeshType } from "@jamango/content-client";
import type { BufferGeometry, Group, Material, Mesh } from "three";

const hashTransform = (transform: ItemMeshBlockStructure["transform"]) => {
	const [x, y, z] = transform.offset;
	const [rx, ry, rz, order] = transform.rotation;
	const [sx, sy, sz] = transform.scale;

	return `${x},${y},${z}#${rx},${ry},${rz}#${order}#${sx},${sy},${sz}`;
};

const hashMesh = (mesh: ItemMesh) => {
	if (mesh.type === ItemMeshType.BLOCK_STRUCTURE) {
		return `${ItemMeshType.BLOCK_STRUCTURE}#${mesh.blockStructurePk}#${hashTransform(mesh.transform)}`;
	}

	return ItemMeshType.NONE.toString();
};

export class ItemMeshComponent {
	name = "itemMesh";

	def: {
		mesh: ItemMesh;
		meshHash: string;
	};

	state: {
		meshHash: string | undefined;
		group: Group | undefined;
		meshes: Mesh[];
		geometries: BufferGeometry[];
		materials: Material[];
		mounted: boolean | undefined;
	};

	constructor() {
		this.def = {
			mesh: {
				type: ItemMeshType.NONE,
			},
			meshHash: "",
		};

		this.state = {
			meshHash: undefined,
			group: undefined,
			meshes: [],
			geometries: [],
			materials: [],
			mounted: undefined,
		};
	}

	updateMesh(mesh: ItemMesh) {
		this.def.mesh = mesh;
		this.def.meshHash = hashMesh(mesh);
	}
}
