// for easing curve style look
// https://sole.github.io/tween.js/examples/03_graphs.html

export const ParticleSetting = {
	addBlock: {
		type: "cube",
		colors: [1, 1, 1, 1, 1, 1, 1, 0],
		numParticles: 30,
		lifeTime: 1.5,
		endTime: 1.5,
		startTime: 0,
		startSize: 0.25,
		endSize: 0.5,
		sizeRange: 0.25,
		spinSpeedRange: 2,
		radius: 0.25,
		velocity: [1, 0, 1],
		velocityRange: [0.25, 0, 0.25],
		acceleration: [1, 0, 1],
		accelerationRange: [0.25, 0, 0.25],
		gravity: [0, 0.05, 0],
		tween: "outQuad",
		blending: "normal",
		alphaTest: 0.1,
	},

	removeBlock: {
		type: "cube",
		colors: [1, 1, 1, 1, 1, 1, 1, 0],
		lifeTime: 1.5,
		endTime: 1.5,
		startTime: 0,
		startSize: 0.5,
		sizeRange: 0.25,
		endSize: 0.1,
		spinSpeedRange: 2,
		accelerationRange: [0.5, 0.5, 0.5],
		gravity: [0, -0.1, 0],
		tween: "outQuad",
		blending: "normal",
		alphaTest: 0.1,
	},

	////// TRAIL //////

	playerMove: {
		colors: [1, 1, 1, 1, 0.2, 0.2, 0.2, 0],
		type: "round",
		blending: "normal",
		numParticles: 4,
		maxParticles: 1000,
		positionRange: [0.2, 0, 0.2],
		lifeTime: 1.5,
		startSize: 0.5,
		endSize: 1.0,
		sizeRange: 0.25,
		velocityRange: [0.6, 0, 0.6],
		gravity: [0, 0.1, 0],
	},
} as const;
