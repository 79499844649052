import { PropMeshComponent } from "base/world/entity/component/PropMesh";
import { PropPhysicsComponent } from "base/world/entity/component/PropPhysics";
import { desV3, serDes as serDesImpl, serV3 } from "base/world/entity/component/Serialization";
import type { EntityCreateOptions } from "base/world/entity/Entity";
import { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import { removeMesh } from "base/world/entity/system/PropMesh";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import { netState } from "router/Parallelogram";
import * as InputManagerServer from "server/world/InputManager";
import type { QuaternionLike, Vector3, Vector3Like } from "three";

const serDes = serDesImpl<Prop>;

export class Prop extends PhysicalEntity {
	propPhysics: PropPhysicsComponent;
	propMesh: PropMeshComponent;

	constructor(o: EntityCreateOptions, def: { name: "Prop" }, world: World) {
		super(o, def, world);

		this.propPhysics = this.addComponent(new PropPhysicsComponent(def, this));
		this.propMesh = this.addComponent(new PropMeshComponent(def, this));
	}

	shouldInvokeChunks() {
		return (
			this.propPhysics.state.motionType === Physics.MotionType.DYNAMIC && this.propPhysics.state.active
		);
	}

	physicsNeedsUpdate() {
		return (
			this.propPhysics.state.desiredCollisionEnabled !== this.propPhysics.state.collisionEnabled ||
			this.propPhysics.def.colliderHash !== this.propPhysics.state.colliderHash ||
			this.propPhysics.def.mass !== this.propPhysics.state.mass ||
			this.propPhysics.def.friction !== this.propPhysics.state.friction ||
			this.propPhysics.def.restitution !== this.propPhysics.state.restitution ||
			this.propPhysics.def.scale !== this.propPhysics.state.scale ||
			this.propPhysics.def.dof !== this.propPhysics.state.dof
		);
	}

	addPhysics() {
		if (this.propPhysics.state.body) return;

		const collider = this.propPhysics.def.collider;
		const colliderHash = this.propPhysics.def.colliderHash;

		const allocated = Physics.allocatePropColliderShape(
			this.world.physics,
			this.world.content,
			this.world.blockTypeRegistry,
			collider,
			colliderHash,
		);

		if (!allocated) return;

		const propPhysics = Physics.createPropPhysics(
			this.world.physics,
			collider,
			colliderHash,
			this.propPhysics.state.desiredCollisionEnabled,
			this.position,
			this.quaternion,
			this.propPhysics.state.desiredMotionType,
			this.propPhysics.def.mass,
			this.propPhysics.def.friction,
			this.propPhysics.def.restitution,
			this.propPhysics.def.scale,
			this.entityID,
		);

		if (!propPhysics) return;

		this.propPhysics.state.propPhysics = propPhysics;
		this.propPhysics.state.body = propPhysics.body;
		this.propPhysics.state.colliderHash = this.propPhysics.def.colliderHash;
		this.propPhysics.state.collisionEnabled = this.propPhysics.state.desiredCollisionEnabled;
		this.propPhysics.state.motionType = this.propPhysics.state.desiredMotionType;
		this.propPhysics.state.mass = this.propPhysics.def.mass;
		this.propPhysics.state.friction = this.propPhysics.def.friction;
		this.propPhysics.state.restitution = this.propPhysics.def.restitution;
		this.propPhysics.state.scale = this.propPhysics.def.scale;
	}

	removePhysics() {
		if (this.propPhysics.state.propPhysics) {
			Physics.disposePropPhysics(this.world.physics, this.propPhysics.state.propPhysics);
			this.propPhysics.state.propPhysics = undefined;
		}

		this.propPhysics.state.body = undefined;
		this.propPhysics.state.dof = Physics.DegreesOfFreedom.ALL;
	}

	setPosition(pos: Vector3Like, isTeleport = false) {
		if (super.setPosition(pos, isTeleport)) return true;

		if (this.propPhysics.state.body) {
			Physics.setBodyPosition(this.world.physics, this.propPhysics.state.body, pos);
		}
	}

	setQuaternion(quat: QuaternionLike, isTeleport = false) {
		if (super.setQuaternion(quat, isTeleport)) return true;

		if (this.propPhysics.state.body) {
			Physics.setBodyRotation(this.world.physics, this.propPhysics.state.body, this.quaternion);
		}
	}

	setScale(scale: number) {
		super.setScale(scale);
		this.propPhysics.def.scale = scale;
	}

	getLinearVelocity(out: Vector3) {
		out.copy(this.propPhysics.state.linearVelocity);

		return out;
	}

	getAngularVelocity(out: Vector3) {
		out.copy(this.propPhysics.state.angularVelocity);

		return out;
	}

	setLinearVelocity(velocity: Vector3Like, isDesync = false) {
		this.propPhysics.state.linearVelocity.copy(velocity);

		if (isDesync && netState.isHost) {
			InputManagerServer.markDesynced(this.world, this.entityID);
		}
	}

	setAngularVelocity(angularVelocity: Vector3Like, isDesync = false) {
		this.propPhysics.state.angularVelocity.copy(angularVelocity);

		if (isDesync && netState.isHost) {
			InputManagerServer.markDesynced(this.world, this.entityID);
		}
	}

	dispose(worldIsDisposed: boolean) {
		if (this.disposed) return true;

		if (super.dispose(worldIsDisposed)) return true;

		this.removePhysics();

		removeMesh(this);
	}

	serialize(includeMovement: boolean) {
		super.serialize(includeMovement);
		for (const id of Prop.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}
		return this.serialization.diffs;
	}

	static serDesIds: number[] = [
		// scale
		serDes(
			"prop scale",
			(e) => e.propPhysics.def.scale,
			(e, v) => e.setScale(v),
		),
		serDes(
			"prop motionType",
			(e) => e.propPhysics.def.motionType,
			(e, v) => (e.propPhysics.def.motionType = v),
		),
		// TODO: dirty flagging
		serDes(
			"prop collider",
			(e) => JSON.stringify(e.propPhysics.def.collider),
			(e, v) => e.propPhysics.updateCollider(JSON.parse(v)),
		),
		// TODO: dirty flagging
		serDes(
			"prop mesh",
			(e) => JSON.stringify(e.propMesh.def.mesh),
			(e, v) => e.propMesh.updateMesh(JSON.parse(v)),
		),
		serDes(
			"prop linearVelocity",
			(e) => serV3(e.propPhysics.state.linearVelocity, 1000),
			(e, v) => desV3(e.propPhysics.state.linearVelocity, v, 1000),
			true,
		),
		serDes(
			"prop angularVelocity",
			(e) => serV3(e.propPhysics.state.angularVelocity, 1000),
			(e, v) => desV3(e.propPhysics.state.angularVelocity, v, 1000),
			true,
		),
		serDes(
			"prop mass",
			(e) => e.propPhysics.def.mass,
			(e, v) => (e.propPhysics.def.mass = v),
		),
		serDes(
			"prop friction",
			(e) => e.propPhysics.def.friction,
			(e, v) => (e.propPhysics.def.friction = v),
		),
		serDes(
			"prop restitution",
			(e) => e.propPhysics.def.restitution,
			(e, v) => (e.propPhysics.def.restitution = v),
		),
	];
}
