import { strFromU8, strToU8, unzlibSync, zlibSync } from "fflate";
import type { MapFileData } from "../types";

export const serializeMapFile = (data: MapFileData) => {
	return zlibSync(strToU8(JSON.stringify(data))).buffer;
};

export const deserializeMapFile = (data: ArrayBuffer | Uint8Array): MapFileData => {
	return JSON.parse(strFromU8(unzlibSync(new Uint8Array(data))));
};
