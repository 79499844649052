import { hermiteCubic } from "base/util/math/Math.ts";

export class LocomotionOutputState {
	constructor(name, animMesh, locomotionInput, transitions) {
		this.name = name;
		this.mesh = animMesh;
		this.inputState = locomotionInput.state;
		this.transitions = transitions;

		this.startTime = 0;
		this.endTransitionTime = 0;
		this.startWeight = 0;
		this.weight = 0;
		this.state = "off";

		this.animationTracks = [];
	}

	onStateOn() {}
	onStateOff() {}
	compute() {}
	shouldTransitionCustom() {}

	shouldTransition(outTransitionToState, map) {
		if (this.transitions)
			for (const transition of this.transitions)
				if (transition.shouldTransition(outTransitionToState, map, this.inputState)) return true;

		return this.shouldTransitionCustom(outTransitionToState, map);
	}

	startCompute(_transitionData) {}

	startFadeIn(transitionData) {
		this.startWeight = this.weight;

		if (transitionData.blendTime > 0) {
			this.startTime = this.mesh.totalTime;
			this.endTransitionTime = this.startTime + transitionData.blendTime;
			this.state = "transitionIn";
		} else {
			this.state = "on";
			this.weight = 1;
			this.onStateOn();
		}

		this.updateWeight();
	}

	startFadeOut(transitionData) {
		this.startWeight = this.weight;

		if (transitionData.blendTime > 0) {
			this.startTime = this.mesh.totalTime;
			this.endTransitionTime = this.startTime + transitionData.blendTime;
			this.state = "transitionOut";
		} else {
			this.state = "off";
			this.weight = 0.0;
			this.onStateOff();
		}

		this.updateWeight();
	}

	updateWeight() {
		const currentTime = this.mesh.totalTime;

		if (this.state === "transitionIn") {
			if (currentTime >= this.endTransitionTime) {
				this.state = "on";
				this.weight = 1.0;
				this.onStateOn();
			} else {
				const t = (currentTime - this.startTime) / (this.endTransitionTime - this.startTime);
				this.weight = this.startWeight + (1 - this.startWeight) * hermiteCubic(t);
			}
		} else if (this.state === "transitionOut") {
			if (currentTime >= this.endTransitionTime) {
				this.state = "off";
				this.weight = 0.0;
				this.onStateOff();
			} else {
				const t = (currentTime - this.startTime) / (this.endTransitionTime - this.startTime);
				this.weight = this.startWeight * (1 - hermiteCubic(t));
			}
		}
	}
}
