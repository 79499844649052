import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { Character } from "base/world/entity/Character";
import { desQuat, desV3 } from "base/world/entity/component/Serialization";
import { entityIsCharacter, entityIsNPC, entityIsProp } from "base/world/entity/component/Type";
import type { Entity } from "base/world/entity/Entity";
import { npcMovementInputUpdate } from "base/world/entity/system/NPCMovementInput.js";
import * as InputManagerBase from "base/world/InputManager";
import * as Metrics from "base/world/MetricsManager";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import * as InputManagerClient from "client/world/InputManager";
import * as Net from "router/Net";
import { Quaternion, Vector3 } from "three";

const _velocity = new Vector3();

export type State = InputManagerBase.State & {
	serialized: Map<EntityID, string>;
	desynced: Map<EntityID, number>;
	lastUpdate: Map<EntityID, number>;
	lastDesync: Map<EntityID, number>;
	desyncStart: Map<EntityID, number>;
	commands: Array<InputManagerBase.HostInputCommand>; // TODO: may not be the right place, but is required for ordering to be sent here
};

export function init(state: InputManagerBase.State) {
	const hostState = state as State;
	hostState.serialized = new Map(); // entityID -> a serialized (for now, stringified) version of the inputs, for diff checks
	hostState.desynced = new Map(); // entityID -> list of entity state that requires re-broadcast
	hostState.lastUpdate = new Map(); // entityID -> list of times when entity state was last re-broadcast
	hostState.lastDesync = new Map();
	hostState.desyncStart = new Map();
	hostState.commands = [];
}

function setPeerInput(entity: Entity, input: string) {
	// we merge discrete changes here - this is currently necessary due to FPS fluctioations.
	// basically, the input we receive from a client could overwrite the old discrete state - swallowing up a command
	// before it gets consumed
	if (entity.input !== undefined && entity.cmd !== undefined) {
		InputManagerBase.deserializeInput(entity.input, entity.cmd, input);
	}
	return entity;
}

export function markDesynced(world: World, entityID: EntityID) {
	// we reject the clients value, therefore we re-broadcast
	const input = world.input as State;
	const ack = (input.lastDesync.get(entityID) || 0) + 1;
	input.desynced.set(entityID, ack);
	input.lastDesync.set(entityID, ack);
}

export function initCommandListeners() {
	Net.listen("peer_input", function (a, world, peer) {
		const input = world.input as State;

		// if diff, store on peers
		const entity = world.peerToPlayer.get(peer) as Character;
		if (entity === undefined) return;
		const id = entity.entityID;
		if (a.input !== undefined) setPeerInput(entity, a.input);

		// accept client volatile state if it is within reasonable boundaries
		const pos = desV3(new Vector3(), a.movement.pos, 10000);
		const quat = desQuat(new Quaternion(), a.movement.quat, 10000);
		const vel = desV3(new Vector3(), a.movement.vel, 10000);

		if (!input.lastDesync.has(id) || a.ack >= input.lastDesync.get(id)!) {
			if (InputManagerBase.getDesyncValue(pos, quat, vel, entity) < 0.5) {
				entity.setPosition(pos, false);
				entity.setQuaternion(quat, false);
				entity.setLinearVelocity(vel);
				input.desyncStart.delete(id);
			} else {
				// if a non-agreeable position was received, we will keep on accepting inputs while discarding positions
				// this way, if a player predicts a movement node and sends its predicted position before the server moves the entity (like with a teleport)
				// we allow the server to "catch up" locally, instead of immediately telling the client it is wrong, forcing a paradoxical desync despite both predicting the same
				if (!input.desyncStart.has(id)) {
					input.desyncStart.set(id, world.time);
				} else {
					const timeSinceDesync = world.time - input.desyncStart.get(id)!;
					if (timeSinceDesync >= 0.5) {
						markDesynced(world, entity.entityID);
						input.desyncStart.delete(id);
					}
				}
			}
		}
	});
}

export function tick(world: World, time: number, dt: number) {
	const { commands, serialized, desynced, lastUpdate, lastDesync } = world.input as State; //  dirtyState

	// "poll" the inputs from the peers
	const broadcast: InputManagerClient.InputsCommandArgs = {
		cmd: [],
		inputs: [],
		diffs: [],
	};

	// collect entity state that needs to be broadcast
	for (const e of world.entities) {
		// if NPC, tick input system here. unfortunate hack
		// reason why this is being done is that if we modify the input inside of the system pipeline
		// then clients cant properly understand the "do" type discrete commands, as they will be reset before distributing input
		if (entityIsNPC(e)) npcMovementInputUpdate(e, dt);

		// check if input is dirty
		if (e.input !== undefined && e.cmd !== undefined) {
			const stringified = InputManagerBase.serializeInput(e.input, e.cmd);
			const isDirtyInput = stringified !== serialized.get(e.entityID);
			if (isDirtyInput) {
				broadcast.inputs.push([e.entityID, stringified]);
				serialized.set(e.entityID, stringified);
			}
		}

		// entity state is distributed if some time has passed, or if it is flagged as dirty
		// with a heartbeat, we may send some state that is usually only sent as "diff"
		const last = lastUpdate.get(e.entityID) || 0;
		const ack = lastDesync.get(e.entityID) || 0;
		let isHeartbeat = false;

		if (entityIsCharacter(e)) {
			// send heartbeat for characters based on movement speed
			let heartbeatInterval =
				10 / (2 + e.movement.state.velocity.lengthSq() + (e.movement.state.isFlying ? 10 : 0));
			heartbeatInterval = Math.min(1, Math.max(0.1, heartbeatInterval));
			isHeartbeat = time > last + heartbeatInterval;
		} else if (entityIsProp(e)) {
			// send heartbeat for props based on motion type and movement speed
			const motionType = e.propPhysics.state.motionType;
			if (motionType !== Physics.MotionType.STATIC) {
				const linVelSq = e.getLinearVelocity(_velocity).lengthSq();
				const rotVelSq = e.getAngularVelocity(_velocity).lengthSq();
				// Compute actual velocities
				const v = Math.sqrt(linVelSq); // Linear velocity
				const w = Math.sqrt(rotVelSq); // Angular velocity

				//TODO: CHANGE HOW THIS WORKS - its overly complicated
				// most likely, the best heuristic is just to send regularly (1-10 times per second for example)
				// but then stop sending at all if the speed is exactly 0.0 - however the engine doesnt currently do that, objects seem endlessly restless
				if (v > 1e-5 || w > 1e-6) {
					const MAX_INTERVAL = 10;
					const MIN_INTERVAL = 0.1;

					// Compute candidate intervals based on velocities
					const intervalLin = v > 0 ? 1 / v : MAX_INTERVAL;
					const intervalRot = w > 0 ? 0.1 / w : MAX_INTERVAL;

					// Take the smaller of the two intervals
					const relevantInterval = Math.min(intervalLin, intervalRot);

					// Clamp the interval between MIN_INTERVAL and MAX_INTERVAL
					const interval = Math.max(MIN_INTERVAL, Math.min(MAX_INTERVAL, relevantInterval));
					isHeartbeat = time > last + interval;
				}
			}
		}

		const desync = desynced.get(e.entityID) || 0;
		const diff = e.serialize(isHeartbeat || desync > 0);
		if (diff.length > 1) {
			if (isHeartbeat) lastUpdate.set(e.entityID, time);
			broadcast.diffs.push({ ack, desync, diff });
		}
		e.serialization.reset();
	}

	// add entity creation data
	broadcast.cmd = [...commands];
	commands.length = 0;

	// TODO: REMOVE THESE TWO / REMOVE THE STRINGIFY - TOO EXPENSIVE
	broadcast.cmd.forEach((c) => {
		Metrics.increment("entity cmd", c[0], [1, JSON.stringify(c).length]);
	});
	broadcast.inputs.forEach((i) => {
		Metrics.increment("entity cmd", "input", [1, JSON.stringify(i).length]);
	});

	if (broadcast.cmd.length > 0 || broadcast.inputs.length > 0 || broadcast.diffs.length > 0) {
		InputManagerClient.inputsCommand.sendToAll(broadcast);
	}

	desynced.clear();
}
