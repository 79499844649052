import type { Entity } from "base/world/entity/Entity";

export function entityObject3DTransformUpdate(entity: Entity, alpha: number) {
	const object3D = entity.object3D;

	if (!entity.mount.state.parent) {
		object3D.position.lerpVectors(entity.prevPosition, entity.position, alpha);
		object3D.quaternion.slerpQuaternions(entity.prevQuaternion, entity.quaternion, alpha);
	}

	object3D.scale.copy(entity.scale);

	object3D.updateMatrix();
}
