import { netState } from "router/Parallelogram";

/**
 * @param {import("base/world/entity/Entity").Entity} entity
 * @param {number} stepDeltaTime
 */
export function animationMixerUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	if (entity.mesh?.mesh.visible) entity.mesh.update(stepDeltaTime);
	if (entity.fpmesh?.mesh.parent) entity.fpmesh.update(stepDeltaTime);
}

/**
 * @param {import("base/world/entity/Entity").Entity} entity
 */
function canUpdateSystem(entity) {
	return netState.isClient && !entity.canSkipUpdate;
}
