import type { EntityID } from "@jamango/engine/EntityID.ts";
import { NODE } from "base/rete/InternalNameMap";
import * as trigger from "base/rete/modules/trigger";
import * as listener from "base/rete/modules/listener";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import type { Character } from "base/world/entity/Character";
import type { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import { createProjectileHitEffects } from "client/world/fx/ProjectileHit";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";
import { findHitBlock } from "./Raycast";
import { createDamageText } from "client/world/fx/Damage";
import { LoopbackPeer } from "@jamango/ibs";

export type Projectile = {
	def: any; // TODO: make projectile def type
	shooterID: EntityID;
	startTime: number;
	rayStartPos: Vector3; // the start position of the ray scan
	visStartPos: Vector3; // the start position of the projectile visual (may be offset to weapon muzzle)
	endTime: number;
	endPos: Vector3;
	scanFront: Vector3;
	scanBack: Vector3;
	dir: Vector3;
	hasHit: boolean;
	wallHitNormal: Vector3 | null; //if this is not null, a block was shot
};

export type ProjectileManager = {
	projectiles: Array<Projectile>;
};

export function init(): ProjectileManager {
	return {
		projectiles: [],
	};
}

/**
 * Updates all active projectiles in the game world for the current frame.

 * 1. Calculates the projectile's raycast range to check this frame based on time elapsed
 * 2. Performs a ray scan between the start and end positions of this range
 * 3. Removes the projectile if it hits something or reaches its end time
 */

export function update(world: World, pm: ProjectileManager, t: number, dt: number) {
	const { projectiles } = pm;

	for (let i = projectiles.length - 1; i >= 0; i--) {
		const p = projectiles[i];

		// projectiles check the distance they've moved since last frame, or at least 2 meters
		const checkTime = Math.max(dt, 2.5 / p.def.velocity);
		const duration = p.endTime - p.startTime;

		// calculate the fraction of the projectiles path that is ray-cast in this frame
		const timeFront = t - p.startTime;
		const timeBack = Math.max(0, timeFront - checkTime);
		const fracBack = Math.max(0, timeBack / duration);
		const fracFront = Math.min(1, timeFront / duration);

		// set scan front and back vectors
		p.scanFront.lerpVectors(p.rayStartPos, p.endPos, fracFront);
		p.scanBack.lerpVectors(p.rayStartPos, p.endPos, fracBack);

		const shooter = world.getEntity(p.shooterID);

		if (!p.hasHit) {
			const len = p.scanFront.distanceTo(p.scanBack);
			const hitEntityId = Physics.raycastEntity(
				world.physics,
				p.scanBack,
				p.dir,
				len,
				shooter,
				_hitPos,
				_hitNormal,
			);
			if (hitEntityId !== undefined) {
				const hitEntity = world.getEntity(hitEntityId as EntityID);

				if (hitEntity) {
					// Debug visualization for hits
					// world.client.addDebugLine(p.visStartPos.clone(), p.endPos.clone(), new Color(0x00ffff)); // green <- projectile visual path
					// world.client.addDebugLine(p.rayStartPos.clone(), p.endPos.clone(), new Color(0x0000ff)); // BLUE <- Projectile ray scan
					// world.client.addDebugLine(p.scanFront.clone(), p.scanBack.clone(), new Color(0xff0000)); // RED <- scan range at impact
					// world.client.addDebugBox(0.3, 0.3, 0.3, _hitPos.clone(), new Color(0x00ff00)); // Green box <- Physics.raycastEntity hitPos

					projectileEntityHit(world, p, hitEntity as PhysicalEntity);

					// if we collided with an entity
					p.endTime = t;
					p.endPos.copy(_hitPos);
					p.hasHit = true;
				}
			} else if (fracFront >= 1) {
				// if the front has crossed the maximum path
				p.hasHit = true;
				if (p.wallHitNormal !== null) {
					if (netState.isHost) {
						const hitBlock = new Vector3();
						findHitBlock(p.rayStartPos, p.endPos, p.wallHitNormal, hitBlock);
						trigger.onBlockEvent(
							NODE.OnProjectileHitBlock,
							world,
							hitBlock.x,
							hitBlock.y,
							hitBlock.z,
							{
								character: shooter as Character, // TODO: typemismatch here
								hitPosition: p.endPos.clone(),
								hitNormal: p.wallHitNormal.clone(),
							},
						);
					}

					if (netState.isClient)
						createProjectileHitEffects(world, p, null, null, false, p.endPos, p.wallHitNormal);
				}
			}
		}

		// the projectile is deleted once the back fraction has fully "submerged" the goal, not directly on impact
		if (fracBack >= 1) {
			projectiles.splice(i, 1);
		}
	}
}

const _raycastResult = Physics.initRaycastResult();

/**
 * Creates and adds a new projectile to the ProjectileManager.

 * The function calculates the end position based on MAX_PROJECTILE_DIST and
 * the projectile's velocity from its definition. It hit tests against static geometry
 * and limits the lifetime accordingly, so that world checks are not necessary during lifetime
 */
export function addProjectile(
	world: World,
	pm: ProjectileManager,
	defId: string,
	shooterID: EntityID,
	weaponID: EntityID | null,
	time: number,
	rayStartPos: Vector3,
	startDir: Vector3,
): void {
	const def = world.defs.get(defId);
	if (!def) return;

	let travelPathLength = def.distance ?? 300;

	const dir = new Vector3().copy(startDir).normalize();
	const endPos = dir.clone().multiplyScalar(travelPathLength).add(rayStartPos);

	// do a raycast along full path to check for world impacts (static geometry)
	let wallHitNormal = null;
	const raycastResult = Physics.raycastStaticCollidableWorld(
		world.physics,
		rayStartPos,
		dir,
		travelPathLength,
		_raycastResult,
	);

	if (raycastResult.hit) {
		endPos.copy(raycastResult.hitPosition);
		travelPathLength = endPos.distanceTo(rayStartPos);
		wallHitNormal = raycastResult.hitNormal.clone();
	}

	// muzzle offset if appropriate
	const visStartPos = new Vector3().copy(rayStartPos).clone();
	const weapon = weaponID !== null ? world.getEntity(weaponID) : undefined;
	if (netState.isClient && weapon !== undefined && weapon.weapon !== undefined) {
		const projPosOffset = weapon.weapon.def.projectilePos;
		if (projPosOffset) visStartPos.copy(projPosOffset).applyMatrix4(weapon.object3D.matrixWorld);
	}

	const travelTime = travelPathLength / def.velocity;

	const p: Projectile = {
		def,
		shooterID,
		startTime: time,
		rayStartPos,
		visStartPos,
		endTime: time + travelTime,
		endPos,
		dir,
		scanFront: new Vector3(),
		scanBack: new Vector3(),
		hasHit: false,
		wallHitNormal,
	};

	pm.projectiles.push(p);
}

const _hitPos = new Vector3();
const _hitNormal = new Vector3();

/** Hitting an entity */
function projectileEntityHit(world: World, p: Projectile, e: PhysicalEntity) {
	const shooter = world.getEntity(p.shooterID);

	// if shot by ai and entity hit is an AI, don't deal damage (no friendly fire)
	if (shooter && shooter.type.def.isNPC && !(shooter as Character).aiCanKillNPCs && e.type.def.isNPC)
		return;
	let damage = p.def.entityDamage;
	let crit = false;

	// character specific
	if (e.type.def.isCharacter) {
		const char = e as Character;
		if (char.type.def.isNPC) char.aiReactToHit();
		char.animateOnHit(p.dir, 0.3);

		// check for headshot
		const localPosition = _hitPos.clone();
		char.object3D.worldToLocal(localPosition);
		localPosition.multiplyScalar(char.size.state.scale);
		crit = localPosition.y >= char.size.state.headshotHeight;
		if (crit) damage = p.def.headshotDamage;
	}

	// modify damage according to falloff
	if (p.def.damageFade && p.def.maxTravelDistance) {
		const distance = _hitPos.distanceTo(p.rayStartPos);
		damage *= Math.max(0, 1 - distance / p.def.maxTravelDistance);
	}

	if (netState.isClient && e.type.def.isCharacter) {
		createProjectileHitEffects(world, p, e, "character", crit, _hitPos, _hitNormal);
	}

	// deal damage and handle events, host only
	if (netState.isHost) {
		e.addHealth(-damage, p.shooterID);
		// check if a peer can be found who is responsible for shooting, and might want a dmg text
		if (shooter) {
			const peer = world.playerToPeer.get(shooter);
			if (peer !== undefined) {
				if (peer instanceof LoopbackPeer) {
					createDamageText(world, e, damage, crit);
				} else {
					// TODO: preferably not use a command for this but rather batch it in with world state updates as an array
					damageTextCommand.send([e.entityID, damage, crit], peer);
				}
			}
		}

		listener.onEntityEvent(NODE.OnProjectileHitEntity, world, e.entityID, {
			shot: e,
			shooter: shooter,
			hitPosition: _hitPos.clone(),
			hitNormal: _hitNormal.clone(),
		});
	}

	// TODO: physics impact
}

/** networking for projectiles */
export const projectileCommand = Net.command(
	"projectile",
	Net.ANY,
	(
		a: [def: string, entityID: EntityID, weaponID: EntityID | null, rayStartPos: Vector3, dir: Vector3],
		world,
		peer,
	) => {
		const pm = world.projectiles;
		let [def, shooterID, weaponID, rayStartPos, aimDir] = a;

		// on the host, we dont do any validation for now, but at the very least we can sanitize the shooter ID
		// TODO: here we want to do more checks - e.g. was this position vaguely plausible
		if (netState.isHost) shooterID = world.peerToPlayer.get(peer)!.entityID;

		addProjectile(world, pm, def, shooterID, weaponID, world.time, rayStartPos, aimDir);

		if (netState.isHost) {
			projectileCommand.sendToAll(a, peer);
		}
	},
);

export const damageTextCommand = Net.command(
	"damageText",
	Net.CLIENT,
	(a: [entityID: EntityID, damage: number, critical: boolean], world, _) => {
		const [entityID, damage, critical] = a;
		const entity = world.getEntity(entityID);
		if (entity !== undefined) createDamageText(world, entity, damage, critical);
	},
);
