import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import {
	LOCOMOTION_MOVEMENTINPUT_LEFT,
	LOCOMOTION_MOVEMENTINPUT_RIGHT,
	LOCOMOTION_MOVEMENTINPUT_FORWARD,
	LOCOMOTION_MOVEMENTINPUT_BACKWARD,
} from "base/world/entity/component/CharacterLocomotionInput";

export class LocomotionStateJogging extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Jogging", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.directionsBlendTime = 0.3;
		this.transitionToFallingTime = 0.2;
		this.transitionToJumpingTime = 0.1;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.forwardWeight = 1.0;
		this.backwardWeight = 0.0;
		this.rightWeight = 0.0;
		this.leftWeight = 0.0;

		this.forwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Jog_Forward", this);
		this.backwardTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Jog_Backward", this);
		this.leftTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Jog_Left", this);
		this.rightTrack = new AnimationTrack(animMesh, "JMGO_Unarmed_Jog_Right", this);

		this.lastTrack = this.forwardTrack;
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;

		this.forwardTrack.action.reset();
		this.backwardTrack.action.reset();
		this.rightTrack.action.reset();
		this.leftTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Idle");
			this.forwardTrack.action.syncWith(idleAction);
			this.rightTrack.action.syncWith(idleAction);
			this.leftTrack.action.syncWith(idleAction);
			this.backwardTrack.action.syncWith(idleAction);

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();
		this.backwardTrack.action.play();
		this.leftTrack.action.play();
		this.rightTrack.action.play();

		this.startFadeIn(transitionData);

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_FORWARD;
			this.forwardTrack.state = "on";
			this.forwardTrack.currentWeight = 1;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.lastTrack = this.forwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_BACKWARD;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "on";
			this.backwardTrack.currentWeight = 1;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.lastTrack = this.backwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
			const weightExceptLeft =
				this.forwardTrack.currentWeight +
				this.rightTrack.currentWeight +
				this.backwardTrack.currentWeight;
			this.leftTrack.currentWeight = 1 - weightExceptLeft;
			this.leftTrack.startFadeIn(this.directionsBlendTime);
			this.leftTrack.isLeft = true;
			this.rightTrack.startFadeOut(this.directionsBlendTime);
			this.forwardTrack.startFadeOut(this.directionsBlendTime);
			this.backwardTrack.startFadeOut(this.directionsBlendTime);

			this.lastTrack = this.leftTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_RIGHT;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "on";
			this.rightTrack.currentWeight = 1;
			this.lastTrack = this.rightTrack;
		}

		this.updateWeightsInternal();
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.updateWeightsInternal();

		this.prvMovementInput = this.inputState.movementInput;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("CrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (!this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.item) {
			outTransitionToState.ptr = map.get("WeaponsJogging");
			outTransitionToState.blendTime = this.transitionToDeathTime;
			return true;
		}

		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Running");
				outTransitionToState.blendTime = this.transitionToRunningTime;
				return true;
			} else {
				return false;
			}
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
		this.backwardTrack.update();
		this.leftTrack.update();
		this.rightTrack.update();
	}

	updateWeightsInternal() {
		if (
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT
		) {
			if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_FORWARD &&
					this.lastTrack !== this.forwardTrack
				) {
					const weightOthers =
						this.leftTrack.currentWeight +
						this.rightTrack.currentWeight +
						this.backwardTrack.currentWeight;
					this.forwardTrack.currentWeight = 1 - weightOthers;
					this.forwardTrack.action.setEffectiveWeight(1 - weightOthers);
					this.leftTrack.startFadeOut(this.directionsBlendTime);
					this.rightTrack.startFadeOut(this.directionsBlendTime);
					this.backwardTrack.startFadeOut(this.directionsBlendTime);

					this.forwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.forwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_BACKWARD &&
					this.lastTrack !== this.backwardTrack
				) {
					const weightOthers =
						this.leftTrack.currentWeight +
						this.rightTrack.currentWeight +
						this.forwardTrack.currentWeight;
					this.backwardTrack.currentWeight = 1 - weightOthers;
					this.backwardTrack.action.setEffectiveWeight(1 - weightOthers);
					this.leftTrack.startFadeOut(this.directionsBlendTime);
					this.rightTrack.startFadeOut(this.directionsBlendTime);
					this.forwardTrack.startFadeOut(this.directionsBlendTime);

					this.backwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.backwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_LEFT &&
					this.lastTrack !== this.leftTrack
				) {
					const weightOthers =
						this.backwardTrack.currentWeight +
						this.rightTrack.currentWeight +
						this.forwardTrack.currentWeight;
					this.leftTrack.currentWeight = 1 - weightOthers;
					this.leftTrack.action.setEffectiveWeight(1 - weightOthers);
					this.backwardTrack.startFadeOut(this.directionsBlendTime);
					this.rightTrack.startFadeOut(this.directionsBlendTime);
					this.forwardTrack.startFadeOut(this.directionsBlendTime);

					this.leftTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.leftTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_RIGHT &&
					this.lastTrack !== this.rightTrack
				) {
					const weightOthers =
						this.backwardTrack.currentWeight +
						this.leftTrack.currentWeight +
						this.forwardTrack.currentWeight;
					this.rightTrack.currentWeight = 1 - weightOthers;
					this.rightTrack.action.setEffectiveWeight(1 - weightOthers);
					this.backwardTrack.startFadeOut(this.directionsBlendTime);
					this.leftTrack.startFadeOut(this.directionsBlendTime);
					this.forwardTrack.startFadeOut(this.directionsBlendTime);

					this.rightTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.rightTrack;
			}

			this.updateBlendPoses();

			this.forwardTrack.action.setEffectiveWeight(this.forwardTrack.currentWeight * this.weight);
			this.backwardTrack.action.setEffectiveWeight(this.backwardTrack.currentWeight * this.weight);
			this.leftTrack.action.setEffectiveWeight(this.leftTrack.currentWeight * this.weight);
			this.rightTrack.action.setEffectiveWeight(this.rightTrack.currentWeight * this.weight);
		} else {
			this.forwardTrack.action.setEffectiveWeight(this.forwardTrack.currentWeight * this.weight);
			this.backwardTrack.action.setEffectiveWeight(this.backwardTrack.currentWeight * this.weight);
			this.leftTrack.action.setEffectiveWeight(this.leftTrack.currentWeight * this.weight);
			this.rightTrack.action.setEffectiveWeight(this.rightTrack.currentWeight * this.weight);
		}
	}
}
