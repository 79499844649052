import { BB } from "base/BB";
import { NODE } from "base/rete/InternalNameMap";
import { NODE_TYPE, NODE_TYPE_ID } from "base/rete/Constants";
import { defs } from "base/rete/Nodes";

function combineDefItem(item = {}, dynamicItem = {}, isRoot = false) {
	for (const [key, value] of Object.entries(item ?? {})) {
		if (isRoot && item[key].dynamic && !dynamicItem.hasOwnProperty(key)) {
			delete item[key];
			continue;
		}

		if (typeof value === "object") {
			combineDefItem(value, dynamicItem[key]);
		} else if (typeof value !== "function") {
			item[key] = dynamicItem.hasOwnProperty(key) ? dynamicItem[key] : value;
		}
	}

	for (const key of Object.keys(dynamicItem ?? {})) {
		if (item.hasOwnProperty(key)) continue;

		item[key] = dynamicItem[key];
	}
}

function checkCompatibleDefItemType(inputs) {
	for (const inputName of Object.keys(inputs)) {
		const input = inputs[inputName];

		if (input.control == null) continue;

		// Check compatibility of type and control
		if (input.type === "entity" && Object.hasOwn(input, "control")) {
			delete input.control;
		} else if (input.type === "number" && input.control !== "number") {
			input.control = "number";
		} else if (
			input.type === "string" &&
			!["select", "string", "color", "textarea"].includes(input.control)
		) {
			input.control = "string";
		} else if (input.type === "boolean" && input.control !== "boolean") {
			input.control = "boolean";
		} else if (input.type === "vector3" && input.control !== "vector3") {
			input.control = "vector3";
		}
	}
}

/**
 * extracts nodes and connections relevant for parsing from an events object
 * ignores ui concerns like frames, positions, etc.
 *
 * @param {import("@jamango/content-client").IScript} script
 * @param {string} templateId
 * @returns {import("@jamango/content-client").TemplateEvents}
 */
export const getScriptEvents = (script) => {
	const events = script.events;

	const scriptEvents = {
		nodes: {},
		connections: [],
		entryPoints: [],
		exitPoints: [],
		controls: [],
	};

	for (const node of Object.values(events.nodes)) {
		scriptEvents.nodes[node.id] = node;
	}

	for (const node of Object.values(events.nodes)) {
		if (node.defId === NODE.Start) {
			scriptEvents.entryPoints.push(node.id);
		} else if (node.defId === NODE.End) {
			scriptEvents.exitPoints.push(node.id);
		} else if (node.defId === NODE.Controls) {
			scriptEvents.controls.push(node.id);
		}
	}

	const nodeIds = Object.keys(scriptEvents.nodes);

	for (const connection of events.connections) {
		if (nodeIds.includes(connection.source) && nodeIds.includes(connection.target)) {
			scriptEvents.connections.push(connection);
		}
	}

	return structuredClone(scriptEvents);
};

/**
 *
 * @param {import("@jamango/content-client").IScript} script
 * @param {Array<import("@jamango/content-client").AnyNodeDef> | null} defs
 */
export const createScriptDef = (script, defs = null) => {
	let startNode;
	const endNodes = [];
	const controlsNodes = [];

	for (const node of Object.values(script.events.nodes)) {
		if (node.defId === NODE.Start) {
			startNode = node;
		} else if (node.defId === NODE.End) {
			endNodes.push(node);
		} else if (node.defId === NODE.Controls) {
			controlsNodes.push(node);
		}
	}

	const controls = {};
	const inputs = {};
	const outputs = {};

	const internalToExternalSockets = {};

	for (const controlNode of controlsNodes) {
		const controlNodeDef = nodeDefRegistry.getDef(controlNode.defId, controlNode.def, defs);

		for (const [controlId, control] of Object.entries(controlNodeDef.controls ?? {})) {
			controls[controlId] = control;
		}
	}

	if (startNode) {
		const entryPointDef = nodeDefRegistry.getDef(startNode.defId, startNode.def, defs);

		for (const [inputId, input] of Object.entries(entryPointDef.inputs ?? {})) {
			inputs[inputId] = input;
		}
	}

	if (endNodes.length === 1) {
		const [exitPoint] = endNodes;
		const exitPointDef = nodeDefRegistry.getDef(exitPoint.defId, exitPoint.def, defs);

		for (const [outputId, output] of Object.entries(exitPointDef.outputs ?? {})) {
			outputs[outputId] = output;
		}
	} else if (endNodes.length > 1) {
		// add exec outputs for each end node
		let outputIndex = 0;

		for (let i = 0; i < endNodes.length; i++) {
			const exitPoint = endNodes[i];
			const exitPointDef = nodeDefRegistry.getDef(exitPoint.defId, exitPoint.def, defs);

			const mainExecOutput = Object.entries(exitPointDef.outputs).find(([id]) => id === "exec");

			if (mainExecOutput) {
				const linkNodeSocketId = `${exitPoint.id}_exec`;

				internalToExternalSockets[exitPoint.id] = {
					exec: linkNodeSocketId,
				};

				outputs[linkNodeSocketId] = {
					name: exitPointDef.name,
					type: "exec",
					index: outputIndex++,
				};
			}

			const otherOutputsSorted = Object.entries(exitPointDef.outputs)
				.filter(([id]) => id !== "exec")
				.sort(([, a], [, b]) => (b?.index ?? 0) - (a?.index ?? 0));

			for (const [outputId, output] of otherOutputsSorted) {
				outputs[outputId] = {
					...output,
					index: outputIndex++,
				};
			}
		}
	}

	/**
	 * @type {import("@jamango/content-client").AnyNodeDef & {
	 * 	internalToExternalSockets: Record<string, string>;
	 * }}
	 */
	const def = {
		...NODE_TYPE[NODE_TYPE_ID.scripts.script],
		id: script.pk,
		name: script.name,
		description: script.description,
		custom: true,
		controls,
		inputs,
		outputs,
		internalToExternalSockets,
	};

	return def;
};

class NodeDefRegistry {
	constructor() {
		this.defs = {};
		for (const def of defs.values()) this.addDef(def);
	}

	/**
	 * @param {string} defId
	 * @param {Record<string, Partial<import("@jamango/content-client").AnyNodeDef>> | null} dynamicDef
	 * @param {Record<string, import("@jamango/content-client").AnyNodeDef>} defs
	 * @param {Record<string, import("@jamango/content-client").IScript> | null} scripts
	 * @returns
	 */
	getDef(defId, dynamicDef = null, defs, scripts = null) {
		if (!defs) {
			defs = this.defs;
		}

		const def = defs[defId];

		if (def) {
			if (!def.dynamic) {
				return def;
			}

			if (!dynamicDef) {
				return this.cloneDef(def);
			}

			const newDef = this.cloneDef(def);

			if (!newDef.controls) {
				newDef.controls = {};
			}

			if (!newDef.inputs) {
				newDef.inputs = {};
			}

			if (!newDef.outputs) {
				newDef.outputs = {};
			}

			if (dynamicDef.name) {
				newDef.name = dynamicDef.name;
			}

			combineDefItem(newDef.controls, dynamicDef.controls, true);
			combineDefItem(newDef.inputs, dynamicDef.inputs, true);
			combineDefItem(newDef.outputs, dynamicDef.outputs, true);

			checkCompatibleDefItemType(newDef.inputs);

			return this.cloneDef(newDef);
		}

		if (!scripts) return null;

		const script = scripts[defId];

		if (script) {
			return createScriptDef(script, defs);
		}

		return null;
	}

	isEmpty(events) {
		return (
			events == null ||
			(events &&
				(events.id == null ||
					(Object.keys(events.nodes || {}).length === 0 &&
						(events.comments || []).length === 0 &&
						Object.keys(events.templateNodes || {}).length === 0 &&
						Object.keys(events.frameNodes || {}).length === 0)))
		);
	}

	cloneTemplateDef(templateID, def) {
		const clonedDef = this.cloneDef(this.getDef(templateID));
		const clonedOriginalDef = this.cloneDef(def);

		for (const key of Object.keys(clonedDef)) {
			if (typeof clonedDef[key] === "function") continue;

			clonedDef[key] = clonedOriginalDef[key];
		}

		for (const key of Object.keys(clonedOriginalDef)) {
			if (key in clonedDef) continue;

			clonedDef[key] = clonedOriginalDef[key];
		}

		return clonedDef;
	}

	cloneDef(value) {
		if (typeof value === "function") {
			return value;
		}

		if (Array.isArray(value)) {
			return value.map((item) => this.cloneDef(item));
		}

		if (value === null || typeof value !== "object") {
			return value;
		}

		const clone = Object.assign({}, value);

		for (const [key, value] of Object.entries(clone)) {
			clone[key] = this.cloneDef(value);
		}

		return clone;
	}

	addDef(def) {
		const outputKeys = Object.keys(def.outputs ?? {});

		if (outputKeys.includes("exec") && outputKeys.includes("callback")) {
			throw Error(
				`Def ${def.id} has both exec and callback outputs. You can only have one or the other.`,
			);
		}

		if (!(def.type in NODE_TYPE)) {
			throw Error(
				`Def ${def.id} has an invalid type of ${def.type}. See NODE_TYPE in base/rete/Constants for valid types.`,
			);
		}

		const existingDef = this.getDef(def.id, def.dynamicDef);
		if (existingDef) throw Error(`Duplicate def ID ${def.id} for ${def.name} and ${existingDef.name}`);

		this.defs[def.id] = { ...NODE_TYPE[def.type], ...def };
	}

	getEventType(entryPointNode) {
		const def = this.getDef(entryPointNode.def, entryPointNode.dynamicDef);

		return typeof def.meta.name === "function"
			? def.meta.name(entryPointNode.inputs ?? {})
			: def.meta.name;
	}
}

export const nodeDefRegistry = new NodeDefRegistry();

export class EditorClient {
	/**
	 * @type {import('base/world/World').World}
	 */
	world;

	constructor(world) {
		this.world = world;

		this.defs = {};

		for (const def of Object.values(nodeDefRegistry.defs)) {
			this.defs[def.id] = def;
		}
	}

	/**
	 * @param {string} defId
	 * @param {Partial<import("@jamango/content-client").AnyNodeDef>} dynamicDef
	 * @returns {import("@jamango/content-client").AnyNodeDef}
	 */
	getDef(defId, dynamicDef = null) {
		return nodeDefRegistry.getDef(defId, dynamicDef, this.defs, BB.world.content.state.scripts.scripts);
	}

	getMenuDefs() {
		let menuDefs = [];

		for (const def of Object.values(this.defs)) {
			if (def.experiment && !this.world.experiments.includes(def.experiment)) {
				continue;
			}

			if (def.menu === false) {
				continue;
			}

			menuDefs.push(def);
		}

		const scripts = {};

		for (const script of BB.world.content.state.scripts.getAll()) {
			scripts[script.pk] = script;
		}

		const scriptNodes = Object.values(scripts).map((script) => {
			return {
				...NODE_TYPE[NODE_TYPE_ID.scripts.script],
				id: script.pk,
				name: script.name,
			};
		});

		menuDefs = menuDefs.concat(scriptNodes);

		return menuDefs;
	}
}
