import { getPeerMetadata } from "base/util/PeerMetadata";
import { BLOCK_CUBE, BLOCK_MASK_SIDE, ChunkUtil } from "base/world/block/Util";
import {
	CharacterViewRaycastMode,
	DEFAULT_VIEW_RAYCAST_DISTANCE,
} from "base/world/entity/component/CharacterViewRaycast";
import * as Physics from "base/world/Physics";
import { findHitBlock } from "base/world/Raycast.js";
import { Vector3 } from "three";

const _raycastResult = Physics.initRaycastResult();
const _airBlock = new Vector3();

const HAND_RAYCAST_DISTANCE = 3;
const LASER_RAYCAST_DISTANCE = 1000;

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} dt
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterViewRaycast(entity, _dt, input) {
	if (!canUpdateSystem(entity, input)) return;

	const world = entity.world;
	const scene = world.scene;

	// reset state
	const state = entity.viewRaycast.state;
	state.character = null;
	state.item = null;
	state.prop = null;
	state.block = null;

	// validate the character view raycast mode and distance based on current permissions
	if (state.mode !== CharacterViewRaycastMode.DEFAULT && !hasPencilPowers(entity)) {
		state.mode = CharacterViewRaycastMode.DEFAULT;
	}
	if (state.distance !== DEFAULT_VIEW_RAYCAST_DISTANCE && !hasPencilPowers(entity)) {
		state.distance = DEFAULT_VIEW_RAYCAST_DISTANCE;
	}

	// determine raycast distance
	let dist;
	if (state.mode === CharacterViewRaycastMode.LASER) {
		dist = LASER_RAYCAST_DISTANCE;
	} else if (state.mode === CharacterViewRaycastMode.AIR_BLOCKS) {
		dist = HAND_RAYCAST_DISTANCE;
	} else {
		dist = state.distance;
	}

	// figure out the position from which we cast
	const { rayDir, rayPos, hitPos, hitNormal } = state;
	setCharacterViewRay(entity, rayPos, rayDir, input);

	// NPCS for now only set their view ray, no raycasts
	if (entity.type.def.isNPC) return;

	const result = Physics.raycastCharacterViewRaycast(
		world.physics,
		rayPos,
		rayDir,
		dist,
		entity,
		_raycastResult,
	);

	if (result.hit) {
		hitPos.copy(result.hitPosition);
		hitNormal.copy(result.hitNormal);
		state.hitDistance = hitPos.distanceTo(rayPos);

		const objectLayer = result.bodyObjectLayer;

		if (
			objectLayer === Physics.OBJECT_LAYER_CHUNK ||
			objectLayer === Physics.OBJECT_LAYER_CHUNK_COLLISION_DISABLED
		) {
			state.block = new Vector3();
			findHitBlock(rayPos, hitPos, hitNormal, state.block);

			state.blockHitPos = hitPos.clone().sub(state.block);
			state.blockHitShape = scene.getShape(state.block);
			state.blockHitTypeIndex = scene.getTypeID(state.block);
			state.blockHitType = world.blockTypeRegistry.blockIdToType.get(scene.getTypeID(state.block));

			if (state.blockHitShape === BLOCK_CUBE) {
				state.blockHitSide = ChunkUtil.getHitSide(state.blockHitPos);
				state.blockHitShape |= state.blockHitSide;
			} else {
				state.blockHitSide = state.blockHitShape & BLOCK_MASK_SIDE;
			}
		} else if (result.userData?.isEntity && result.userData.entityId !== undefined) {
			const hitEntityId = result.userData.entityId;
			const hitEntity = entity.world.getEntity(hitEntityId);

			if (hitEntity) {
				const type = hitEntity.type.def;
				if (type.isCharacter) {
					state.character = hitEntity;
				} else if (type.isItem) {
					state.item = hitEntity;
				} else if (type.isProp) {
					state.prop = hitEntity;
				}
			}
		}
	} else if (state.mode === CharacterViewRaycastMode.AIR_BLOCKS) {
		const selected = _airBlock.copy(rayDir).multiplyScalar(HAND_RAYCAST_DISTANCE).add(rayPos);

		state.hitPos.copy(selected);
		state.hitDistance = HAND_RAYCAST_DISTANCE;
		state.block = selected.clone().floor();
		state.blockHitSide = 0;
	} else {
		state.hitDistance = dist;
	}
}

function canUpdateSystem(entity, input) {
	return input !== undefined && entity.type.def.isCharacter && !entity.mount.state.parent;
}

function setCharacterViewRay(entity, pos, dir, input) {
	pos.copy(entity.position);
	let eyeHeight = entity.size.state.eyeHeight;
	if (entity.movement.state.isCrouching) {
		const co = entity.size.state.crouchOffset;
		eyeHeight -= co;
	}
	pos.y += eyeHeight;
	dir.setFromSpherical(input.camera).normalize().negate();
}

// TODO: blanket creator permission for now
function hasPencilPowers(character) {
	const peer = character.world.playerToPeer.get(character);
	if (!peer) return false;
	return getPeerMetadata(peer).permissions.canUsePencil;
}
