import type { ParticleParameters } from "client/world/fx/ParticleEngine";

type PS = Partial<ParticleParameters> & { velocityHitNormalIntensity: number };

export const OnHitParticlesDefault: PS[] = [
	// smoke
	{
		colors: [1, 1, 1, 0.1, 1, 1, 1, 0],
		type: "cube",
		numParticles: 10,
		positionRange: [0.2, 0.1, 0.2],
		lifeTime: 3,
		endTime: 3,
		startTime: 0,
		startSize: 0.3,
		endSize: -0.15,
		sizeRange: 0.15,
		spinSpeedRange: 2,
		radius: 0.15,
		velocity: [0, 0, 0],
		velocityRange: [0.2, 0.05, 0.2],
		velocityHitNormalIntensity: 1.5,
		acceleration: [0, 0.05, 0],
		accelerationRange: [0.3, 0, 0.3],
		gravity: [0, 0, 0],
		tween: "outQuad",
		blending: "normal",
		alphaTest: 0.05,
	},
	// impact
	{
		colors: [1, 1, 1, 1, 1, 1, 1, 0],
		type: "round",
		blending: "normal",
		numParticles: 10,
		positionRange: [0.1, 0, 0.1],
		lifeTime: 0.3,
		startTime: 0,
		startSize: 0.5,
		endTime: 0.3,
		endSize: -0.15,
		sizeRange: 0.15,
		velocityHitNormalIntensity: 3.5,
		velocityRange: [1, 1, 1],
	},
];

export const OnHitPlayerParticlesDefault = [
	{
		colors: [0.43, 0.03, 0.03, 1],
		type: "cloud",
		numParticles: 40,
		positionRange: [0.2, 0.1, 0.2],
		lifeTime: 0.1,
		lifeTimeRange: 0.1,
		startSize: 0.3,
		endSize: -0.15,
		sizeRange: 0.15,
		acceleration: [1, -1, 1],
		accelerationRange: [0.25, 2, 0.25],
		spinSpeedRange: 2,
		blending: "normal",
	},
];
