export const clientConst = {
	selector: {
		sculptMode: {
			CUBE: 0,
			SCULPT: 1,
			INVERT: 2,
			COUNT: 3,
		},

		selectionMode: {
			DEFAULT: 0,
			LASER: 1,
			AIR: 2,
			COUNT: 3,
		},

		bulkMode: {
			DISABLED: 0,
			ENABLED: 1,
		},

		bulkBrushMode: {
			DISABLED: 0,
			NO_CORNERS: 1,
			ONE_CORNER: 2,
			PREVIEW: 3,
		},
	},
};
