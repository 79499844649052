export class ProphecyComponent {
	name = "prophecy";

	state: {
		isProphecy: boolean;
	};

	constructor(_o: any, _entity: any) {
		this.state = {
			isProphecy: false,
		};
	}
}
