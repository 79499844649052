import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { getEntity } from "base/rete/modules/validate";
import { entityIsPlayer } from "base/world/entity/component/Type";

export const HUD_NODES = [
	node({
		id: "22561b95-11ab-4dc5-96f4-1092da7ec00e",
		name: "Player Get Zen Mode Enabled",
		type: NODE_TYPE_ID.function.player,
		predictable: true,
		description: "Get whether zen mode is enabled for the player.",
		inputs: {
			entity: { name: "Player", type: "entity" },
		},
		outputs: {
			enabled: {
				name: "Enabled",
				type: "boolean",
			},
		},
		resolve(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity || !entityIsPlayer(entity)) {
				throw new Error("Provided entity is not a Player Character");
			}

			return {
				enabled: entity.characterHUD.state.zenMode,
			};
		},
	}),
	node({
		id: "0d29ca1d-eb52-4460-9f4e-3f04c764abc5",
		name: "Player Set Zen Mode Enabled",
		type: NODE_TYPE_ID.function.player,
		predictable: true,
		description: "Change whether zen mode is enabled for the player.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Player", type: "entity" },
			enabled: {
				name: "Enabled",
				type: "boolean",
				config: { defaultValue: false },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");
			if (!entity || !entityIsPlayer(entity)) {
				throw new Error("Provided entity is not a Player Character");
			}

			entity.characterHUD.state.zenMode = inputs.enabled;
		},
	}),
];
