import { DEGRAD } from "base/util/math/Math.ts";
import { CAM_FOV_DEFAULT } from "client/world/Camera.js";
import { Vector3 } from "three";

export class CharacterCameraComponent {
	constructor(o, _entity) {
		this.name = "characterCamera";

		this.def = {
			fovWalk: o?.fovWalk ?? CAM_FOV_DEFAULT,
			fovSprint: o?.fovSprint ?? 80 * DEGRAD,
			fovAim: o?.fovAim ?? 55 * DEGRAD,
			fovLerp: o?.fovLerp ?? 0.00001,

			crouchLerp: o?.crouchLerp ?? 0.0000005,

			itemSwayMaxDisplacement: {
				default: 0.05,
				ironSights: 0.01,
			},
		};

		this.state = {
			fovTarget: 0,

			eyeHeight: 0,

			itemSwayVerticalOffset: 0,

			bobCameraOffset: new Vector3(),
			bobItemSwayOffset: new Vector3(),
		};
	}
}
