import { Vector3 } from "three";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { V0 } from "base/util/math/Math";
import { node } from "../Types";

const _vector3 = new Vector3();

export const VECTOR3 = [
	node({
		id: "c230d34a-02f4-4e32-b8c3-14510ce12b2e",
		name: "Vector3 Add",
		type: NODE_TYPE_ID.vector3,
		description: "Adds two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3(val1.x, val1.y, val1.z);
			result.add(val2);

			return { result };
		},
	}),
	node({
		id: "b6d036e6-58f0-4ec0-bde6-63900db66c54",
		name: "Vector3 Subtract",
		type: NODE_TYPE_ID.vector3,
		description: "Subtracts two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3(val1.x, val1.y, val1.z);
			result.sub(val2);

			return { result };
		},
	}),
	node({
		id: "1a347588-495e-4271-a4dc-02537f4fe1b1",
		name: "Vector3 Multiply",
		type: NODE_TYPE_ID.vector3,
		description: "Multiplies two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3(val1.x, val1.y, val1.z);
			result.multiply(val2);

			return { result };
		},
	}),
	node({
		id: "0a363583-ccf3-45da-95ea-0cd61a180941",
		name: "Vector3 Divide",
		type: NODE_TYPE_ID.vector3,
		description: "Divides two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3(val1.x, val1.y, val1.z);
			result.divide(val2);

			return { result };
		},
	}),
	node({
		id: "be268082-ed51-4997-9924-b99f0cf86e5e",
		name: "Vector3 Add Scalar",
		type: NODE_TYPE_ID.vector3,
		description: "Adds a scalar to a vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
			scalar: { name: "Scalar", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;
			const scalar = inputs.scalar ?? 0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.addScalar(scalar);

			return { result };
		},
	}),
	node({
		id: "72512b66-70a1-47c4-b027-466bfb35ff05",
		name: "Vector3 Subtract Scalar",
		type: NODE_TYPE_ID.vector3,
		description: "Subtracts a scalar from a vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
			scalar: { name: "Scalar", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;
			const scalar = inputs.scalar ?? 0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.subScalar(scalar);

			return { result };
		},
	}),
	node({
		id: "9a696ed8-83f0-41eb-8828-bcd58663c701",
		name: "Vector3 Multiply Scalar",
		type: NODE_TYPE_ID.vector3,
		description: "Multiplies a vector by a scalar",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
			scalar: { name: "Scalar", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;
			const scalar = inputs.scalar ?? 0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.multiplyScalar(scalar);

			return { result };
		},
	}),
	node({
		id: "58f94ada-9619-4836-b21a-940019dd25cb",
		name: "Vector3 Divide Scalar",
		type: NODE_TYPE_ID.vector3,
		description: "Divides a vector by a scalar",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
			scalar: { name: "Scalar", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;
			const scalar = inputs.scalar ?? 0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.divideScalar(scalar);

			return { result };
		},
	}),
	node({
		id: "9c191021-2ae7-4d3a-aaf6-3d0e2f0614d3",
		name: "Vector3 Round",
		type: NODE_TYPE_ID.vector3,
		description: "Rounds the components of a vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Rounded Vector", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const result = new Vector3(Math.round(vector.x), Math.round(vector.y), Math.round(vector.z));

			return { result };
		},
	}),
	node({
		id: "1fad2e7d-0d44-48ff-87e7-079e9bdee2ab",
		name: "Vector3 Floor",
		type: NODE_TYPE_ID.vector3,
		description: "Floors the components of a vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Floored Vector", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const result = new Vector3(Math.floor(vector.x), Math.floor(vector.y), Math.floor(vector.z));

			return { result };
		},
	}),
	node({
		id: "42f219f9-835c-4362-9d21-8b080a59fcd0",
		name: "Vector3 Ceil",
		type: NODE_TYPE_ID.vector3,
		description: "Ceils the components of a vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Ceiled Vector", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const result = new Vector3(Math.ceil(vector.x), Math.ceil(vector.y), Math.ceil(vector.z));

			return { result };
		},
	}),
	node({
		id: "3ce64df3-8f99-4aeb-b4dd-602bf631ebd8",
		name: "Vector3 Get Cross Product",
		type: NODE_TYPE_ID.vector3,
		description: "Cross Product of two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Cross Product", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3(val1.x, val1.y, val1.z);
			result.cross(val2);

			return { result };
		},
	}),
	node({
		id: "dbd41dd1-3617-4f70-9ebb-7048de7f8bdf",
		name: "Vector3 Get Dot Product",
		type: NODE_TYPE_ID.vector3,
		description: "Dot Product of two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Value", type: "vector3", control: "vector3" },
			val2: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Dot Product", type: "number" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const dotProduct = _vector3.copy(val1).dot(val2);

			return { result: dotProduct };
		},
	}),
	node({
		id: "a01cffdb-7b13-4239-ad5e-ea0d1f09a38f",
		name: "Vector3 Normalize",
		type: NODE_TYPE_ID.vector3,
		description: "Makes this vector have a magnitude of 1",
		predictable: true,
		inputs: {
			vector: { name: "Value", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "Normalized Vector", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.normalize();

			return { result };
		},
	}),
	node({
		id: "049087e1-bcab-42ea-8dab-2696d455008d",
		name: "Vector3 Get Length",
		type: NODE_TYPE_ID.vector3,
		description: "The length of this vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			length: { name: "Length", type: "number" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const length = _vector3.copy(vector).length();

			return { length };
		},
	}),
	node({
		id: "bb1223ca-cc09-4e0d-9543-cd24cfc1ffb8",
		name: "Vector3 Set Length",
		type: NODE_TYPE_ID.vector3,
		description: "Sets the length of this vector",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
			length: { name: "Length", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;
			const length = inputs.length ?? 0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.setLength(length);

			return { result };
		},
	}),
	node({
		id: "8ee42a56-4f2c-40be-9445-38621ccd6675",
		name: "Vector3 Get Distance Between",
		type: NODE_TYPE_ID.vector3,
		description: "The distance between two vectors",
		predictable: true,
		inputs: {
			val1: { name: "Vector", type: "vector3", control: "vector3" },
			val2: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			distance: { name: "Distance", type: "number" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const distance = _vector3.copy(val1).distanceTo(val2);

			return { distance };
		},
	}),
	node({
		id: "c7511f55-6d9b-4a82-9fad-e6b4f76a5135",
		name: "Vector3 Lerp",
		type: NODE_TYPE_ID.vector3,
		description: "Interpolates between two vectors",
		info: [
			"Alpha is the interpolation factor between the two vectors, usually a number between 0 and 1.",
		],
		predictable: true,
		inputs: {
			val1: { name: "Vector", type: "vector3", control: "vector3" },
			val2: { name: "Vector", type: "vector3", control: "vector3" },
			alpha: { name: "Alpha", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "vector3" },
		},
		resolve(inputs) {
			const val1 = inputs.val1 ?? V0;
			const val2 = inputs.val2 ?? V0;

			const result = new Vector3().lerpVectors(val1, val2, inputs.alpha);

			return { result };
		},
	}),
	node({
		id: "86b8157a-9e7a-4c37-8a80-c8df395d3672 ",
		name: "Vector3 Block Coordinate To World Position",
		type: NODE_TYPE_ID.vector3,
		description: "Converts a block coordinate to a world position",
		predictable: true,
		inputs: {
			vector: { name: "Vector", type: "vector3", control: "vector3" },
		},
		outputs: {
			result: { name: "World Position", type: "vector3" },
		},
		resolve(inputs) {
			const vector = inputs.vector ?? V0;

			const result = new Vector3(vector.x, vector.y, vector.z);
			result.x += 0.5;
			result.y += 1;
			result.z += 0.5;

			return { result };
		},
	}),
];
