import { netState } from "router/Parallelogram";
import { defTransform } from "base/util/Defs";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterVisualTweaksUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const cam = entity.world.client.camera;
	const item = entity.getEquippedItem();
	const firstPerson = cam?.is1stPerson() && cam.target === entity;

	//mesh visibility
	if (entity.mesh) entity.mesh.mesh.visible = !firstPerson;

	const itemNode = entity.equipment.def.itemNode;
	const fpItemNode = entity.equipment.def.fpItemNode;

	//adjust item mount
	if (firstPerson) {
		if (item?.itemType.def.isBlock) {
			entity.fpmesh?.mesh.removeFromParent();
			if (!cam.isSwayMesh(item.object3D)) {
				cam.setSwayMesh(item.object3D);
				defTransform(item.tweenAnimation.def.idleTransform, item.object3D);
			}
		} else if (item && fpItemNode) {
			if (!cam.isSwayMesh(entity.fpmesh.mesh)) cam.setSwayMesh(entity.fpmesh.mesh);
			if (item.parent !== fpItemNode) {
				fpItemNode.add(item.object3D);
				if (item.mesh) {
					item.mesh.toggleTransform(false);
				}
			}
		} else {
			//nowhere to put the item
			entity.fpmesh?.mesh.removeFromParent();
			item?.object3D.removeFromParent();
		}
	} else {
		entity.fpmesh?.mesh.removeFromParent();
		if (item && itemNode && !item.itemType.def.isBlock) {
			if (item.parent !== itemNode) {
				itemNode.add(item.object3D);
				item.mesh?.toggleTransform(false);
			}
		} else {
			//nowhere to put the item
			item?.object3D.removeFromParent();
		}
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && !entity.canSkipUpdate;
}
