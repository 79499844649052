// @ts-nocheck
import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import { BlockModel, type BlockCallback } from "base/world/block/BlockModel";
import { JSFRandom } from "base/util/math/Random";
import { defPerlin } from "base/util/Defs";
import {
	BLOCK_SNX,
	BLOCK_SPX,
	BLOCK_SNY,
	BLOCK_SPY,
	BLOCK_SNZ,
	BLOCK_SPZ,
	BLOCK_TL,
	BLOCK_BL,
	BLOCK_TR,
	BLOCK_BR,
	BLOCK_CUBE,
} from "base/world/block/Util";
import type { PerlinNoiseGenerator } from "base/util/math/Perlin";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import type { Chunk } from "base/world/block/chunk/Chunk";
import { ModelBuilder } from "./ModelBuilder";
import type { PerlinOptions } from "@jamango/content-client/lib/types/terrainGeneration.ts";

export const TREE_REVISIONS = [0, 1, 2];

const TREE_LEAF_TYPES = ["default", "pyramid", "balloon", "matchstick"] as const;

const TREE_MIN_HEIGHT = 2;

export class GeneratorTree {
	static name = "bb.generator.tree" as const;
	static display = "Trees" as const;

	private tcache = new LegacyVectorMap();

	private perlinOptions: PerlinOptions;
	private perlin: PerlinNoiseGenerator;
	private revision = 0;

	private tprob = 0;
	private sprob = 0;
	private bprob = 0;
	private fprob: number;
	private tnfprob: number;
	private tfprob: number;
	private minHeight = 0;
	private maxHeight = 0;

	private seed = 0;
	private chunkSize = 0;

	private dirt: string;
	private leaves: string;
	private grass: string;
	private wood: string;

	private callbacks: {
		def: (typeof BlockModel)["noOverwriteCB"];
		terrainIsFlat: (chunk: Chunk, rx: number, rz: number) => boolean;
		//change grass underneath to dirt
		killTrunkGrass: BlockCallback;
		killStumpGrass: (i: number) => BlockCallback;

		//only build stumps on top of flat terrain, and if jsfrandom wills it to exist
		stump: (i: number) => BlockCallback;

		//randomly generate branches
		branch: (i: number) => BlockCallback;
	};

	private modelBuilder: ModelBuilder;

	/**
	 * OPT Number revision [0] - Choose which version of the generator to use
	 *
	 * 2 (016ff2e8 faster perlin)
	 * 1 (9349a80 new leaf shapes, perlin forests)
	 * OPT Object perlin [{ scale: 100 }] - defPerlin options to determine tree probability in any given area
	 * OPT Number forestProbability [0.42] - Percentage of terrain containing forests, range [0, 1]
	 * OPT Number treeProbabilityNonForest [0.0004] - Chance of generating a tree at any given XZ coordinate not in a forest, range [0, 1]
	 * OPT Number treeProbabilityForest [0.006] - Chance of generating a tree at any given XZ coordinate inside a forest, range [0, 1]
	 * OPT Number stumpProbability [0.7] - Chance of generating a stump at any given XZ coordinate, range [0, 1]
	 * OPT Number branchProbability [0.1] - Chance of each side of a tree growing a branch, range [0, 1]
	 * OPT Integer minHeight [-Infinity] - Minimum height to generate trees at
	 * OPT Integer maxHeight [Infinity] - Maximum height to generate trees at
	 * OPT String blockGrass ["block#CC96F3CD5C5EA294CD581B7F69E5609B"]
	 * OPT String blockDirt ["block#1243423C9831127C92653DD89AE610EF"]
	 * OPT String blockWood ["block#625478D387114C476997337AEF54402D"]
	 * OPT String blockLeaves ["block#03602E99DA07FBA89E03154EEB0D0A74"]
	 *
	 * 0 (eeb5fa1 original)
	 * OPT Number treeProbability [0.0004] - Chance of generating a tree at any given XZ coordinate, range [0, 1]
	 * OPT Number branchProbability [0.1] - Chance of each side of a tree growing a branch, range [0, 1]
	 * OPT Integer minHeight [-Infinity] - Minimum height to generate trees at
	 * OPT Integer maxHeight [Infinity] - Maximum height to generate trees at
	 * OPT String blockGrass ["block#CC96F3CD5C5EA294CD581B7F69E5609B"]
	 * OPT String blockDirt ["block#1243423C9831127C92653DD89AE610EF"]
	 * OPT String blockWood ["block#625478D387114C476997337AEF54402D"]
	 * OPT String blockLeaves ["block#03602E99DA07FBA89E03154EEB0D0A74"]
	 */
	constructor(o) {
		this.revision = o?.revision ?? 0;

		if (!TREE_REVISIONS.includes(this.revision)) {
			throw Error(`Unknown generator revision "${this.revision}"`);
		}

		if (this.revision >= 1) this.perlinOptions = o?.perlin ?? { scale: 150 };
		if (this.revision >= 1) this.fprob = -2 * (o?.forestProbability ?? 0.42) + 1;
		if (this.revision >= 1) this.tnfprob = o?.treeProbabilityNonForest ?? 0.0004;
		if (this.revision >= 1) this.tfprob = o?.treeProbabilityForest ?? 0.006;
		if (this.revision === 0) this.tprob = o?.treeProbability ?? 0.0004;

		this.sprob = o?.stumpProbability ?? 0.7;
		this.bprob = o?.branchProbability ?? 0.1;
		this.minHeight = o?.minHeight ?? -Infinity;
		this.maxHeight = o?.maxHeight ?? Infinity;

		this.grass = o?.blockGrass ?? "block#CC96F3CD5C5EA294CD581B7F69E5609B";
		this.dirt = o?.blockDirt ?? "block#1243423C9831127C92653DD89AE610EF";
		this.wood = o?.blockWood ?? "block#625478D387114C476997337AEF54402D";
		this.leaves = o?.blockLeaves ?? "block#03602E99DA07FBA89E03154EEB0D0A74";
	}

	init({
		seed,
		chunkSize,
	}: { seed: number; blockTypeRegistry: BlockTypeRegistryState; chunkSize: number }) {
		this.seed = seed;
		this.chunkSize = chunkSize;
		this.tcache = new LegacyVectorMap(); //tree cache

		this.modelBuilder = new ModelBuilder(chunkSize);

		if (this.revision === 1) {
			this.perlin = defPerlin(this.perlinOptions, seed - 101);
		} else if (this.revision > 1) {
			this.perlin = defPerlin(this.perlinOptions, seed, GeneratorTree.name);
		}

		//model building callbacks
		this.callbacks = {
			def: BlockModel.noOverwriteCB, //default callback

			terrainIsFlat: (chunk, rx, rz) => {
				const ter = chunk.scene.terrain;
				if (!ter) return true;

				const c = chunk.position;
				const s = this.chunkSize;
				const ax = rx + c.x * s;
				const az = rz + c.z * s;

				const ayTL = ter.getHeight(ax, az);
				const ayBL = ter.getHeight(ax, az + 1);
				const ayTR = ter.getHeight(ax + 1, az);
				const ayBR = ter.getHeight(ax + 1, az + 1);

				return ayBL === ayTL && ayTR === ayTL && ayBR === ayTL;
			},

			//change grass underneath to dirt
			killTrunkGrass: (chunk, rx, ry, rz) =>
				chunk.getShape(rx, ry, rz) === BLOCK_CUBE && chunk.getType(rx, ry, rz) === this.grass,
			killStumpGrass: (i) => (chunk, rx, ry, rz, treeData) =>
				treeData.stumps[i] &&
				this.callbacks.killTrunkGrass(chunk, rx, ry, rz) &&
				this.callbacks.terrainIsFlat(chunk, rx, rz),

			//only build stumps on top of flat terrain, and if jsfrandom wills it to exist
			stump: (i) => (chunk, rx, ry, rz, treeData) =>
				treeData.stumps[i] &&
				this.callbacks.def(chunk, rx, ry, rz) &&
				this.callbacks.terrainIsFlat(chunk, rx, rz),

			//randomly generate branches
			branch: (i) => (chunk, rx, ry, rz, treeData) =>
				treeData.branches[i] && this.callbacks.def(chunk, rx, ry, rz),
		};

		for (const leafType of TREE_LEAF_TYPES) {
			const m = (this.modelBuilder.models[leafType] = this.buildTreeModel(leafType));
			this.modelBuilder.expandBounds(m);
		}
	}

	private buildTreeModel(type: (typeof TREE_LEAF_TYPES)[number]) {
		const {
			dirt,
			wood,
			leaves,
			callbacks: { def, killTrunkGrass, killStumpGrass, stump, branch },
		} = this;

		const m = new BlockModel(this.chunkSize);

		const height = TREE_MIN_HEIGHT;

		//soil
		m.setBlock(0, -1, 0, BLOCK_CUBE, dirt, killTrunkGrass);
		m.setBlock(-1, -1, 0, BLOCK_CUBE, dirt, killStumpGrass(0));
		m.setBlock(1, -1, 0, BLOCK_CUBE, dirt, killStumpGrass(1));
		m.setBlock(0, -1, -1, BLOCK_CUBE, dirt, killStumpGrass(2));
		m.setBlock(0, -1, 1, BLOCK_CUBE, dirt, killStumpGrass(3));

		//stump
		m.setBlock(-1, 0, 0, BLOCK_SNX | BLOCK_BL | BLOCK_BR, wood, stump(0));
		m.setBlock(1, 0, 0, BLOCK_SPX | BLOCK_BL | BLOCK_BR, wood, stump(1));
		m.setBlock(0, 0, -1, BLOCK_SNZ | BLOCK_TL | BLOCK_TR, wood, stump(2));
		m.setBlock(0, 0, 1, BLOCK_SPZ | BLOCK_BL | BLOCK_BR, wood, stump(3));

		//log
		for (let y = 0; y <= height; y++) m.setBlock(0, y, 0, BLOCK_CUBE, wood);

		//branches
		m.setBlock(-1, height, 0, BLOCK_SNX | BLOCK_TL | BLOCK_TR, wood, branch(0));
		m.setBlock(1, height, 0, BLOCK_SPX | BLOCK_TL | BLOCK_TR, wood, branch(1));
		m.setBlock(0, height, -1, BLOCK_SNZ | BLOCK_BL | BLOCK_BR, wood, branch(2));
		m.setBlock(0, height, 1, BLOCK_SPZ | BLOCK_TL | BLOCK_TR, wood, branch(3));

		let level = Math.floor(height) + 1;

		switch (type) {
			case "pyramid": {
				for (let y = level; y <= level + 1; y++) {
					m.setBlock(0, y, 0, BLOCK_CUBE, wood, def);
					for (let z = -2; z <= 2; z++)
						for (let x = -2; x <= 2; x++) m.setBlock(x, y, z, BLOCK_CUBE, leaves, def);
				}

				level += 2;

				m.setBlock(0, level, 0, BLOCK_CUBE, wood, def);
				for (let z = -1; z <= 1; z++)
					for (let x = -1; x <= 1; x++) m.setBlock(x, level, z, BLOCK_CUBE, leaves, def);

				level += 1;

				m.setBlock(0, level, 0, BLOCK_CUBE, leaves, def);
				m.setBlock(0, level, 1, BLOCK_CUBE, leaves, def);
				m.setBlock(0, level, -1, BLOCK_CUBE, leaves, def);
				m.setBlock(1, level, 0, BLOCK_CUBE, leaves, def);
				m.setBlock(-1, level, 0, BLOCK_CUBE, leaves, def);

				break;
			}
			case "balloon": {
				for (let y = level; y <= level + 2; y++) {
					m.setBlock(0, y, 0, BLOCK_CUBE, wood, def);
					for (let z = -2; z <= 2; z++)
						for (let x = -2; x <= 2; x++)
							if (
								(z === 2 && x === 2) ||
								(z === -2 && x === -2) ||
								(z === -2 && x === 2) ||
								(z === 2 && x === -2)
							)
								continue;
							else m.setBlock(x, y, z, BLOCK_CUBE, leaves, def);
				}

				level += 3;

				m.setBlock(0, level, 0, BLOCK_CUBE, leaves, def);
				m.setBlock(0, level, 1, BLOCK_CUBE, leaves, def);
				m.setBlock(0, level, -1, BLOCK_CUBE, leaves, def);
				m.setBlock(1, level, 0, BLOCK_CUBE, leaves, def);
				m.setBlock(-1, level, 0, BLOCK_CUBE, leaves, def);

				break;
			}
			case "matchstick": {
				for (let y = level; y <= level + 2; y++) {
					m.setBlock(0, y, 0, BLOCK_CUBE, wood, def);
					m.setBlock(0, y, 1, BLOCK_CUBE, leaves, def);
					m.setBlock(0, y, -1, BLOCK_CUBE, leaves, def);
					m.setBlock(1, y, 0, BLOCK_CUBE, leaves, def);
					m.setBlock(-1, y, 0, BLOCK_CUBE, leaves, def);
				}

				level += 3;

				m.setBlock(0, level, 0, BLOCK_CUBE, leaves, def);

				break;
			}
			default: {
				for (let z = -1; z <= 1; z++)
					for (let x = -1; x <= 1; x++) m.setBlock(x, level, z, BLOCK_CUBE, leaves, def);

				m.setBlock(0, level, 0, BLOCK_CUBE, wood);
				m.setBlock(-2, level, -1, BLOCK_SNY | BLOCK_BR | BLOCK_TR, leaves, def);
				m.setBlock(-2, level, 0, BLOCK_SNY | BLOCK_BR | BLOCK_TR, leaves, def);
				m.setBlock(-2, level, 1, BLOCK_SNY | BLOCK_BR | BLOCK_TR, leaves, def);
				m.setBlock(2, level, -1, BLOCK_SNY | BLOCK_BL | BLOCK_TL, leaves, def);
				m.setBlock(2, level, 0, BLOCK_SNY | BLOCK_BL | BLOCK_TL, leaves, def);
				m.setBlock(2, level, 1, BLOCK_SNY | BLOCK_BL | BLOCK_TL, leaves, def);
				m.setBlock(-1, level, -2, BLOCK_SNY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(0, level, -2, BLOCK_SNY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(1, level, -2, BLOCK_SNY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(-1, level, 2, BLOCK_SNY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(0, level, 2, BLOCK_SNY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(1, level, 2, BLOCK_SNY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(-2, level, -2, BLOCK_SNY | BLOCK_TR, leaves, def);
				m.setBlock(-2, level, 2, BLOCK_SNY | BLOCK_BR, leaves, def);
				m.setBlock(2, level, -2, BLOCK_SNY | BLOCK_TL, leaves, def);
				m.setBlock(2, level, 2, BLOCK_SNY | BLOCK_BL, leaves, def);

				//middle leaves
				level += 1;
				for (let y = level; y <= level + 1; y++) {
					for (let z = -2; z <= 2; z++)
						for (let x = -2; x <= 2; x++)
							if ((x !== 0 || z !== 0) && (Math.abs(x) !== 2 || Math.abs(z) !== 2))
								m.setBlock(x, y, z, BLOCK_CUBE, leaves, def);

					m.setBlock(-2, y, -2, BLOCK_SNZ | BLOCK_TR | BLOCK_BR, leaves, def);
					m.setBlock(2, y, -2, BLOCK_SNZ | BLOCK_TL | BLOCK_BL, leaves, def);
					m.setBlock(-2, y, 2, BLOCK_SPZ | BLOCK_TR | BLOCK_BR, leaves, def);
					m.setBlock(2, y, 2, BLOCK_SPZ | BLOCK_TL | BLOCK_BL, leaves, def);
				}

				level;
				m.setBlock(0, level, 0, BLOCK_CUBE, wood, def);

				level += 1;
				m.setBlock(0, level, 0, BLOCK_CUBE, leaves, def);

				//top leaves
				level += 1;
				for (let z = -1; z <= 1; z++)
					for (let x = -1; x <= 1; x++) m.setBlock(x, level, z, BLOCK_CUBE, leaves, def);

				m.setBlock(-2, level, -1, BLOCK_SPY | BLOCK_TR | BLOCK_BR, leaves, def);
				m.setBlock(-2, level, 0, BLOCK_SPY | BLOCK_TR | BLOCK_BR, leaves, def);
				m.setBlock(-2, level, 1, BLOCK_SPY | BLOCK_TR | BLOCK_BR, leaves, def);
				m.setBlock(2, level, -1, BLOCK_SPY | BLOCK_TL | BLOCK_BL, leaves, def);
				m.setBlock(2, level, 0, BLOCK_SPY | BLOCK_TL | BLOCK_BL, leaves, def);
				m.setBlock(2, level, 1, BLOCK_SPY | BLOCK_TL | BLOCK_BL, leaves, def);
				m.setBlock(-1, level, -2, BLOCK_SPY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(0, level, -2, BLOCK_SPY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(1, level, -2, BLOCK_SPY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(-1, level, 2, BLOCK_SPY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(0, level, 2, BLOCK_SPY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(1, level, 2, BLOCK_SPY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(-2, level, -2, BLOCK_SPY | BLOCK_BR, leaves, def);
				m.setBlock(-2, level, 2, BLOCK_SPY | BLOCK_TR, leaves, def);
				m.setBlock(2, level, -2, BLOCK_SPY | BLOCK_BL, leaves, def);
				m.setBlock(2, level, 2, BLOCK_SPY | BLOCK_TL, leaves, def);

				//very top leaves
				level += 1;
				m.setBlock(0, level, 0, BLOCK_CUBE, leaves, def);
				m.setBlock(-1, level, 0, BLOCK_SPY | BLOCK_TR | BLOCK_BR, leaves, def);
				m.setBlock(1, level, 0, BLOCK_SPY | BLOCK_TL | BLOCK_BL, leaves, def);
				m.setBlock(0, level, -1, BLOCK_SPY | BLOCK_BL | BLOCK_BR, leaves, def);
				m.setBlock(0, level, 1, BLOCK_SPY | BLOCK_TL | BLOCK_TR, leaves, def);
				m.setBlock(-1, level, -1, BLOCK_SPY | BLOCK_BR, leaves, def);
				m.setBlock(-1, level, 1, BLOCK_SPY | BLOCK_TR, leaves, def);
				m.setBlock(1, level, -1, BLOCK_SPY | BLOCK_BL, leaves, def);
				m.setBlock(1, level, 1, BLOCK_SPY | BLOCK_TL, leaves, def);

				break;
			}
		}

		return m;
	}

	apply(chunk: Chunk) {
		const s = this.chunkSize;
		const ter = chunk.scene.terrain;

		const cx = chunk.position.x;
		const cy = chunk.position.y;
		const cz = chunk.position.z;

		const min = this.modelBuilder.modelsChunkBounds.min;
		const max = this.modelBuilder.modelsChunkBounds.max;

		for (let rz = min.z; rz < max.z; rz++) {
			for (let rx = min.x; rx < max.x; rx++) {
				const ax = rx + cx * s;
				const az = rz + cz * s;
				let ay;

				if (ter) {
					const ayTL = ter.getHeight(ax, az, false);
					const ayBL = ter.getHeight(ax, az + 1, false);
					const ayTR = ter.getHeight(ax + 1, az, false);
					const ayBR = ter.getHeight(ax + 1, az + 1, false);
					ay = Math.min(ayTL, ayBL, ayTR, ayBR);
				} else {
					ay = 0;
				}

				if (ay > this.minHeight && ay <= this.maxHeight) {
					const ry = ay - cy * s;
					if (ry >= min.y && ry < max.y) {
						const treeData = this.getTreeExcludingOverlaps(ax, az);
						if (treeData)
							this.modelBuilder.models[treeData.leafType].build(
								chunk,
								rx,
								ry,
								rz,
								false,
								treeData,
							);
					}
				}
			}
		}
	}

	//same as getTree, but filters out overlapping trees to prevent it from looking terrible
	private getTreeExcludingOverlaps(ax: number, az: number) {
		const w = this.modelBuilder.modelsBounds.max.x - this.modelBuilder.modelsBounds.min.x;
		const d = this.modelBuilder.modelsBounds.max.z - this.modelBuilder.modelsBounds.min.z;
		const axMin = ax - w;
		const azMin = az - d;
		const axMax = ax + w;
		const azMax = az + d;

		//keep trees away from spawn
		if (axMin <= 0 && axMax >= 0 && azMin <= 0 && azMax >= 0) return;

		const branches = this.getTree(ax, az);
		if (!branches) return;

		for (let asz = azMin; asz <= azMax; asz++) {
			for (let asx = axMin; asx <= axMax; asx++) {
				if (asx === ax && asz === az) return branches; //safe to stop searching here
				if (this.getTree(asx, asz)) return; //overlap detected; cancel tree
			}
		}
	}

	//returns tree's branch data if there is a tree at (ax, az), or undefined
	private getTree(ax: number, az: number) {
		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);
		let t = this.tcache.get(cx, cz);

		if (!t) {
			t = new LegacyVectorMap();
			const random = new JSFRandom(this.seed, "x", cx, "z", cz, "tree");

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const atx = cx * chunkSize + rx;
					const atz = cz * chunkSize + rz;

					if (random.probability(this.getTreeProbability(atx, atz))) {
						//if tree exists at this position in the chunk
						const treeData = {
							leafType:
								TREE_LEAF_TYPES[
									this.revision === 0
										? 0
										: random.float64IntRange(0, TREE_LEAF_TYPES.length - 1)
								],
							stumps: [],
							branches: [],
						};

						for (let i = 0; i < 4; i++) {
							treeData.stumps[i] = this.revision === 0 ? true : random.probability(this.sprob);
							treeData.branches[i] = random.probability(this.bprob);
						}

						t.set(rx, rz, treeData);
					}
				}
			}

			this.tcache.set(cx, cz, t);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return t.get(rx, rz);
	}

	private getTreeProbability(ax: number, az: number) {
		if (this.revision === 0) return this.tprob;

		const noise = this.perlin.getNoise(ax, az);
		return noise > this.fprob ? this.tfprob : this.tnfprob;
	}

	clearCache() {
		this.tcache.clear();
		this.perlin?.clearCache();
	}
}
