import { ClientContext, type ClientMessages } from "@jamango/content-service/types/index.ts";
import { createLogger, delay, getRandomIntegerFromRange } from "@jamango/helpers";
import { apiClient } from "./index";
import { generateShortId } from "@jamango/content-client";

const logger = createLogger("WSClient");

let wsCounter = 0;

function openWebSocketConnection() {
	const ws = apiClient.api.ws.index.subscribe();

	(ws as any).__id = wsCounter++;

	return ws as typeof ws & { __id: number };
}

class WSClient {
	readonly client: ClientContext;
	#connection?: ReturnType<typeof openWebSocketConnection>;
	#sessionCounter = 0;

	readonly #outgoingMessages: ClientMessages[] = [];

	readonly #offlineConnection = {
		type: "offline",
		send: (msg: ClientMessages) => {
			this.#outgoingMessages.push(msg);
			logger.debug(
				`Offline, msg saved: ${msg.type}(${msg.id}). Count: ${this.#outgoingMessages.length}`,
			);
		},
	} as const;

	constructor() {
		this.client = new ClientContext(this.#offlineConnection);

		// problem with version?
		this.client.onConnectFailed.add(() => {});
	}

	// call it after login/logout operations
	reconnect() {
		this.#sessionCounter++;
		const sessionId = this.#sessionCounter;
		this.#tryOpenConnection(sessionId, 0);
	}

	#tryOpenConnection(sessionId: number, nAttemp: number) {
		if (this.#sessionCounter !== sessionId) return;

		if (this.#connection) {
			this.#dispose();
		}

		this.#connection = openWebSocketConnection();
		const conn = this.#connection;
		logger.debug(`[${sessionId},${conn.__id}] created`);

		conn.subscribe((data) => {
			if (this.#sessionCounter !== sessionId) return;
			const msg = data.data;
			this.client.receiveMessage(msg);
		});

		conn.on("open", () => {
			if (this.#sessionCounter !== sessionId) return;

			logger.debug(`[${sessionId},${conn.__id}] opened, waiting for session id...`);
			nAttemp = 0;

			const disposer = this.client.onConnectSuccessed.add(() => {
				disposer();

				if (this.#sessionCounter !== sessionId) return;

				logger.debug(
					`[${sessionId},${conn.__id}] connected, outgoing messages: ${this.#outgoingMessages.length}, sending login...`,
				);

				conn.send({
					type: "login",
					id: generateShortId(),
					token: apiClient.headers["API_TOKEN"],
				} satisfies ClientMessages);

				this.#outgoingMessages.forEach((msg) => conn.send(msg));
				this.#outgoingMessages.length = 0;

				this.client.setConnection({
					type: "online",
					send: (msg) => conn.send(msg),
				});
			});
		});

		conn.on("close", async (event) => {
			if (this.#sessionCounter !== sessionId) return;

			logger.debug(`[${sessionId},${conn.__id}] close:`, event);

			if (!this.#connection) return;

			this.#dispose();

			await delay((nAttemp + 1) * getRandomIntegerFromRange(2, 5) * 1000);

			this.#tryOpenConnection(sessionId, nAttemp + 1);
		});

		conn.on("error", async (error) => {
			if (this.#sessionCounter !== sessionId) return;

			logger.debug(`[${sessionId},${conn.__id}] error:`, error);

			if (!this.#connection) return;

			this.#dispose();

			await delay((nAttemp + 1) * getRandomIntegerFromRange(2, 5) * 1000);

			this.#tryOpenConnection(sessionId, nAttemp + 1);
		});
	}

	#dispose() {
		if (this.#connection) {
			this.#connection.close();
			logger.debug(`WS ${this.#connection.__id} closed`);
			this.#connection = undefined;
			this.client.dispose();
			this.client.setConnection(this.#offlineConnection);
		}
	}
}

export const ws = new WSClient();
