import { v4 as uuid } from "uuid";
import { NET_IS_BROWSER } from "./Const";
import type { Peer } from "../slave/Peer";

const DEFAULT_MAX_MESSAGE_SIZE_BYTES = 16 * 1024;

/**
 * Ignore commands with no listeners
 */
export const SOCKET_BEHAVIOR_IGNORE = 0;

/**
 * Log a warning when an unknown command is received
 */
export const SOCKET_BEHAVIOR_LOG = 1;

/**
 * Store unknown commands in a list and execute as soon as a listener for the command is registered using setCommandListener
 * This can cause a memory leak as unknown commands are stored in a "wait list".
 */
export const SOCKET_BEHAVIOR_WAIT = 2;

/**
 * Socket can be RTCDataChannel, WebSocket, or Worker
 * OPT Bool batching [false] - When batching is true, you must call socket.flushBatchDownToilet() manually to actually send all the commands. Recipients will process the entire batch synchronously. N/A for worker sockets
 * OPT Integer unknownCommandBehavior [SOCKET_BEHAVIOR_LOG] - How to handle unknown commands. Use one of the SOCKET_BEHAVIOR_ constants.
 * OPT Number fakePing [0] - Fake latency for testing purposes, in seconds. To calculate RTT, add this socket's fake ping + recipient's fake ping + the actual RTT ping.
 * OPT Number fakePacketLoss [0] - Percentage of packets to drop in range [0, 1]. Only applicable to sockets who are unreliable RTCDataChannels.
 * OPT Object/Array fakeLagSpike [false] - Either false for disabled, or an array of 2 numbers that represent how long to let messages through and then how long to block them, in seconds. For example, [4, 1] = 4 seconds of good connection, 1 second of bad.
 */

type Command = number | string;

export interface ISocketExtensions {
	onmessage: (msg: any) => void;
	readyState?: string | number;
	ordered?: boolean;
	reliable?: boolean;
	name?: string;
	peer?: Peer;
	setCommandListener: (f: Command, listener: any) => void;
	handleWaitList: () => void;
	sendCommand: (f: Command, a: any, transfer?: any) => void;
	postMessage: Worker["postMessage"];
	updateSettings: (newSettings: any) => void;
	flushBatchDownToilet: () => void;
	close?: () => void;
	send: (msg: any) => void;
}

export function createCommandSocket<T extends Worker | RTCDataChannel | any>(
	_s: T,
	o: {
		batching?: boolean;
		unknownCommandBehavior?:
			| typeof SOCKET_BEHAVIOR_IGNORE
			| typeof SOCKET_BEHAVIOR_LOG
			| typeof SOCKET_BEHAVIOR_WAIT;
		fakePing?: number;
		fakePacketLoss?: number;
		fakeLagSpike?: false | [number, number];
	} = {},
): T & ISocketExtensions {
	const socket = _s as T & ISocketExtensions;

	const commandListeners = {};
	const isWorker =
		NET_IS_BROWSER &&
		//@ts-ignore
		((typeof WorkerGlobalScope !== "undefined" && socket instanceof WorkerGlobalScope) ||
			socket instanceof Worker);

	const settings = {
		batching: o.batching ?? false,
		unknownCommandBehavior: o.unknownCommandBehavior ?? SOCKET_BEHAVIOR_LOG,
		fakePing: o.fakePing ?? 0,
		fakePacketLoss: o.fakePacketLoss ?? 0,
		fakeLagSpike: o.fakeLagSpike ?? false,
	};

	const queue: string[] = [];
	const waitList: any[] = [];

	let lagSpikeGood: { cancel: () => any } | null = null; //good period where messages are sending normally
	let lagSpikeBad: { cancel: () => any } | null = null; //bad period where messages are building up

	const messageChunks = new Map();

	socket.onmessage = function (e) {
		if (NET_IS_BROWSER) {
			if (
				socket.readyState === WebSocket.CLOSING ||
				socket.readyState === "closing" ||
				socket.readyState === WebSocket.CLOSED ||
				socket.readyState === "closed"
			)
				return;
		}

		let received = e.data;

		if (!isWorker) {
			try {
				received = JSON.parse(received);

				if (typeof received === "object" && "chunk" in received) {
					const { id, total, index, data } = received.chunk;

					let chunks = messageChunks.get(id);

					if (!chunks) {
						chunks = [];
						messageChunks.set(id, chunks);
					}

					chunks[index] = data;

					let allChunksReceived = true;
					for (let i = 0; i < total; i++) {
						if (!(i in chunks)) {
							allChunksReceived = false;
							break;
						}
					}

					if (!allChunksReceived) {
						return;
					}

					const message = chunks.join("");
					messageChunks.delete(id);

					received = JSON.parse(message);
				}
			} catch {
				console.error("Failed to parse json");
				return;
			}
		}

		if (Array.isArray(received)) {
			for (const m of received) receiveImpl(m);
		} else {
			receiveImpl(received);
		}
	};

	socket.setCommandListener = function (f, listener) {
		//@ts-ignore
		const listeners = (commandListeners[f] ??= []);
		listeners.push(listener);
	};

	socket.handleWaitList = function () {
		for (let i = 0; i < waitList.length; i++) {
			const m = waitList[i];
			receiveImpl(m);
		}
		waitList.length = 0;
	};

	socket.sendCommand = function (f, a, transfer) {
		const m = { f, a };
		if (isWorker) {
			socket.postMessage(m, transfer);
		} else {
			const str = JSON.stringify(m); //stringify now in case something else modifies a while it's sitting in the batch

			if (settings.batching) queue.push(str);
			else sendImpl(str);
		}
	};

	//if a key is missing from newSettings, the current value will remain unchanged
	//note changing any of the fake latency settings on an active connection can ho*nk with the order that packets are received in
	socket.updateSettings = function (newSettings) {
		for (const setting in newSettings) {
			const val = newSettings[setting];
			//@ts-ignore
			settings[setting] = val;
			switch (setting) {
				case "batching":
					if (!val) socket.flushBatchDownToilet();
					break;
				case "fakeLagSpike":
					cancelLagSpikes();
					if (val) startLagSpikes(val);
			}
		}
	};

	socket.flushBatchDownToilet = function () {
		const maxMessageBytes = socket.peer?.rtc?.sctp?.maxMessageSize ?? DEFAULT_MAX_MESSAGE_SIZE_BYTES;
		const chunkBytes = Math.floor(maxMessageBytes * 0.9);

		const messageBatches: string[] = [];

		let currentBatch: string[] = [];
		let currentBatchSize = 0;

		const pushBatch = () => {
			if (currentBatch.length > 0) {
				messageBatches.push(`[${currentBatch.join(",")}]`);
				currentBatch = [];
				currentBatchSize = 0;
			}
		};

		while (queue.length > 0) {
			const m = queue.shift()!;

			// assume 2 bytes per character
			const messageBytes = m.length * 2;

			const messageExceedsChunkSize = messageBytes > chunkBytes;

			if (messageExceedsChunkSize) {
				// if an individual message exceeds the chunk size, split it into chunks
				if (!socket.ordered) {
					console.error(
						`Data exceeding chunk size cannot be sent over unreliable data channels, dropping message. First 100 characters of message: [${m.slice(0, 100)}]`,
					);
					continue;
				}

				const messageId = uuid();
				const chunks = Math.ceil(messageBytes / chunkBytes);
				const chunkCharacters = Math.ceil(m.length / chunks);

				for (let i = 0; i < chunks; i++) {
					const chunkData = m.slice(i * chunkCharacters, (i + 1) * chunkCharacters);
					const chunkMessage = {
						chunk: { id: messageId, total: chunks, index: i, data: chunkData },
					};
					messageBatches.push(JSON.stringify(chunkMessage));
				}
			} else {
				// if adding this message will exceed the chunk size, start a new batch
				const willExceedChunkSize = currentBatchSize + messageBytes > chunkBytes;

				if (willExceedChunkSize) {
					pushBatch();
				}

				// add to the current batch
				currentBatch.push(m);
				currentBatchSize += messageBytes;
			}
		}

		pushBatch();

		for (const m of messageBatches) {
			sendImpl(m);
		}
	};

	async function sendImpl(jsonString: string) {
		const state = socket.readyState;

		if (settings.fakePing > 0 || settings.fakeLagSpike) {
			if (socket.reliable === false && lagSpikeBad) return;

			const promises: Promise<any>[] = [];

			if (settings.fakePing > 0)
				promises.push(new Promise((resolve) => setTimeout(resolve, settings.fakePing * 1000)));

			if (settings.fakeLagSpike) promises.push(lagSpikeBad as any);

			await Promise.all(promises);

			if (socket.readyState !== state) return;
		}

		if (
			socket.reliable === false &&
			settings.fakePacketLoss !== 0 &&
			1 - Math.random() <= settings.fakePacketLoss
		) {
			return;
		}

		try {
			socket.send(jsonString);
		} catch (oops) {
			console.error(oops);
			socket.close?.();
		}
	}

	function receiveImpl(m: { f: string | number; a: any }) {
		//@ts-ignore
		const listeners = commandListeners[m.f];
		if (listeners) {
			for (const listener of listeners) {
				try {
					listener.call(socket, m.a);
				} catch (oops) {
					console.error(`command: ${m.f}, socket.readyState: ${socket.readyState}\n`, oops);
				}
			}
		} else {
			if (settings.unknownCommandBehavior === SOCKET_BEHAVIOR_LOG)
				console.warn(`Unknown command "${m.f}"`);
			else if (settings.unknownCommandBehavior === SOCKET_BEHAVIOR_WAIT) waitList.push(m);
		}
	}

	async function startLagSpikes(spike: [number, number]) {
		while (true) {
			// biome-ignore lint/style/noVar: <explanation>
			var timeoutGood: Timer;
			//@ts-ignore
			lagSpikeGood = new Promise(function (resolve) {
				timeoutGood = setTimeout(resolve, spike[0] * 1000);
			});

			//@ts-ignore
			lagSpikeGood.cancel = function () {
				clearTimeout(timeoutGood);
			};

			await lagSpikeGood;

			// biome-ignore lint/style/noVar: <explanation>
			var timeoutBad: Timer;
			//@ts-ignore
			// biome-ignore lint/style/noVar: <explanation>
			var res;
			//@ts-ignore
			lagSpikeBad = new Promise(function (resolve) {
				timeoutBad = setTimeout(() => resolve(false), spike[1] * 1000);
				res = resolve;
			});

			//@ts-ignore
			lagSpikeBad.cancel = function () {
				clearTimeout(timeoutBad);
				//@ts-ignore
				res(true);
			};

			const needBreak = await lagSpikeBad;

			lagSpikeBad = null;
			if (needBreak) break;
		}
	}

	function cancelLagSpikes() {
		lagSpikeGood?.cancel();
		lagSpikeBad?.cancel();
	}

	if (settings.fakeLagSpike) startLagSpikes(settings.fakeLagSpike);

	return socket;
}
