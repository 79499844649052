import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateJumping extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Jumping", animMesh, locomotionInput, transitions);

		this.transitionToFallingTime = 0.2;
		this.transitionToDeathTime = 0.2;
		this.transitionToLandingTime = 0.2;
	}

	get jumpingAction() {
		return this.mesh.getAction("JMGO_Unarmed_Jump_Kickoff_Static_Forward");
	}

	startCompute(transitionData) {
		this.jumpingAction.reset();
		this.jumpingAction.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.jumpingAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.jumpingAction.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.jumpingAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (!this.inputState.isFalling) {
			if (this.inputState.lastFrameFirstPerson) {
				outTransitionToState.ptr = map.get("Idle");
				outTransitionToState.blendTime = 0.0;
			} else {
				outTransitionToState.ptr = map.get("Landing");
				outTransitionToState.blendTime = this.transitionToLandingTime;
			}

			return true;
		}

		if (this.jumpingAction.getRemainingTime() < this.transitionToFallingTime) {
			outTransitionToState.ptr = map.get("Falling");
			outTransitionToState.blendTime = this.transitionToFallingTime;
			return true;
		}

		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		return false;
	}
}
