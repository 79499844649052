import { zlibSync, unzlibSync } from "fflate";
import { toBase64, fromBase64 } from "base/util/math/Base64.js";
import { Vector3 } from "three";

//shape bits:
//bit 1: is inverted?
//bit 2-4: sculpt side
//bit 5-8: corners

//which side was clicked/which direction to rotate the shape. positive/negative x/y/z
export const BLOCK_SNX = 0b00110000;
export const BLOCK_SPX = 0b01000000;
export const BLOCK_SNY = 0b01010000;
export const BLOCK_SPY = 0b00100000; //default
export const BLOCK_SNZ = 0b01100000;
export const BLOCK_SPZ = 0b00010000;

//which shape corners are "up"/sculpted
export const BLOCK_AIR = 0b00000000;
export const BLOCK_TL = 0b00000001;
export const BLOCK_BL = 0b00000010;
export const BLOCK_TR = 0b00000100;
export const BLOCK_BR = 0b00001000;
export const BLOCK_CUBE = 0b00001111; //cube cannot have a sculpted side or inversion

export const BLOCK_MASK_INVERT = 0b10000000;
export const BLOCK_MASK_SIDE = 0b01110000;
export const BLOCK_MASK_CORNERS = 0b00001111;

//which triangles of a shape's side to render
export const BLOCK_VIS_NONE = 0b00;
export const BLOCK_VIS_LEFT = 0b01;
export const BLOCK_VIS_RIGHT = 0b10;
export const BLOCK_VIS_BOTH = 0b11;

export const BLOCK_TRANSPARENCY_OPAQUE = 0;
export const BLOCK_TRANSPARENCY_TRANSPARENT = 1;
export const BLOCK_TRANSPARENCY_TRANSLUCENT = 2;

export const BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED = 0;
export const BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE = 1;
export const BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN = 2;

export const BLOCK_COLLISION_ENABLED = 0;
export const BLOCK_COLLISION_DISABLED = 1;

// define clockwise side rotations for axis and side
// const BLOCK_SIDE_TRANSFORM_CLOCKWISE_ROTATION = ;

const BLOCK_CORNERS_CLOCKWISE_ROTATION = {
	[BLOCK_TL]: BLOCK_TR,
	[BLOCK_TR]: BLOCK_BR,
	[BLOCK_BR]: BLOCK_BL,
	[BLOCK_BL]: BLOCK_TL,
};

const BLOCK_CORNERS_COUNTER_CLOCKWISE_ROTATION = {
	[BLOCK_TL]: BLOCK_BL,
	[BLOCK_BL]: BLOCK_BR,
	[BLOCK_BR]: BLOCK_TR,
	[BLOCK_TR]: BLOCK_TL,
};

const BLOCK_CORNERS_OPPOSITE_ROTATION = {
	[BLOCK_TL]: BLOCK_BR,
	[BLOCK_TR]: BLOCK_BL,
	[BLOCK_BR]: BLOCK_TL,
	[BLOCK_BL]: BLOCK_TR,
};

const BLOCK_CORNERS_HORIZONTAL_FLIP = {
	[BLOCK_TL]: BLOCK_TR,
	[BLOCK_TR]: BLOCK_TL,
	[BLOCK_BL]: BLOCK_BR,
	[BLOCK_BR]: BLOCK_BL,
};

const BLOCK_CORNERS_VERTICAL_FLIP = {
	[BLOCK_TL]: BLOCK_BL,
	[BLOCK_BL]: BLOCK_TL,
	[BLOCK_TR]: BLOCK_BR,
	[BLOCK_BR]: BLOCK_TR,
};

const TRANSFORM_CLOCKWISE_ROTATION = {
	SIDE: {
		x: {
			[BLOCK_SPZ]: BLOCK_SPY,
			[BLOCK_SPY]: BLOCK_SNZ,
			[BLOCK_SNZ]: BLOCK_SNY,
			[BLOCK_SNY]: BLOCK_SPZ,
		},
		y: {
			[BLOCK_SPX]: BLOCK_SPZ,
			[BLOCK_SPZ]: BLOCK_SNX,
			[BLOCK_SNX]: BLOCK_SNZ,
			[BLOCK_SNZ]: BLOCK_SPX,
		},
		z: {
			[BLOCK_SPY]: BLOCK_SPX,
			[BLOCK_SPX]: BLOCK_SNY,
			[BLOCK_SNY]: BLOCK_SNX,
			[BLOCK_SNX]: BLOCK_SPY,
		},
	},
	CORNERS: {
		x: {
			[BLOCK_SNX]: BLOCK_CORNERS_COUNTER_CLOCKWISE_ROTATION,
			[BLOCK_SPX]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
		},
		y: {
			[BLOCK_SPY]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SNY]: BLOCK_CORNERS_COUNTER_CLOCKWISE_ROTATION,
			[BLOCK_SNX]: BLOCK_CORNERS_OPPOSITE_ROTATION,
			[BLOCK_SNZ]: BLOCK_CORNERS_OPPOSITE_ROTATION,
		},
		z: {
			[BLOCK_SPX]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SNX]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SPY]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SNY]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SPZ]: BLOCK_CORNERS_CLOCKWISE_ROTATION,
			[BLOCK_SNZ]: BLOCK_CORNERS_COUNTER_CLOCKWISE_ROTATION,
		},
	},
};

const TRANSFORM_FLIP = {
	SIDE: {
		x: {
			[BLOCK_SPX]: BLOCK_SNX,
			[BLOCK_SNX]: BLOCK_SPX,
		},
		y: {
			[BLOCK_SPY]: BLOCK_SNY,
			[BLOCK_SNY]: BLOCK_SPY,
		},
		z: {
			[BLOCK_SPZ]: BLOCK_SNZ,
			[BLOCK_SNZ]: BLOCK_SPZ,
		},
	},
	CORNERS: {
		x: {
			[BLOCK_SPX]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SNX]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SPY]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SNY]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SPZ]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SNZ]: BLOCK_CORNERS_HORIZONTAL_FLIP,
		},
		y: {
			[BLOCK_SPX]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SNX]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SPY]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SNY]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SPZ]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SNZ]: BLOCK_CORNERS_VERTICAL_FLIP,
		},
		z: {
			[BLOCK_SPX]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SNX]: BLOCK_CORNERS_HORIZONTAL_FLIP,
			[BLOCK_SPY]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SNY]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SPZ]: BLOCK_CORNERS_VERTICAL_FLIP,
			[BLOCK_SNZ]: BLOCK_CORNERS_VERTICAL_FLIP,
		},
	},
};

export class ShapeUtil {
	static isSculpted(shape) {
		if (shape === BLOCK_AIR) return false;

		const corners = shape & BLOCK_MASK_CORNERS;

		return corners !== BLOCK_CUBE;
	}

	static rotate(shape, xRotations, yRotations, zRotations) {
		const axes = ["x", "y", "z"];

		const xClockwiseRotations = ((xRotations % 4) + 4) % 4;
		const yClockwiseRotations = ((yRotations % 4) + 4) % 4;
		const zClockwiseRotations = ((zRotations % 4) + 4) % 4;

		let rotatedShape = shape;

		for (const axis of axes) {
			const nClockwiseRotations = [xClockwiseRotations, yClockwiseRotations, zClockwiseRotations][
				axes.indexOf(axis)
			];

			for (let i = 0; i < nClockwiseRotations; i++) {
				const side = rotatedShape & BLOCK_MASK_SIDE;
				const corners = rotatedShape & BLOCK_MASK_CORNERS;

				let newSide = side;
				let newCorners = corners;

				const sideRotations = TRANSFORM_CLOCKWISE_ROTATION.SIDE[axis];

				if (sideRotations) {
					newSide = sideRotations[side] ?? side;
				}

				const cornersRotation =
					TRANSFORM_CLOCKWISE_ROTATION.CORNERS[axis] &&
					TRANSFORM_CLOCKWISE_ROTATION.CORNERS[axis][side];

				if (cornersRotation) {
					newCorners =
						(cornersRotation[rotatedShape & BLOCK_TL] ?? 0) |
						(cornersRotation[rotatedShape & BLOCK_TR] ?? 0) |
						(cornersRotation[rotatedShape & BLOCK_BR] ?? 0) |
						(cornersRotation[rotatedShape & BLOCK_BL] ?? 0);
				}

				rotatedShape = (rotatedShape & BLOCK_MASK_INVERT) | newSide | newCorners;
			}
		}

		return rotatedShape;
	}

	/**
	 * @param {number} shape
	 * @param {'x' | 'y' | 'z'} axis
	 */
	static flip(shape, axis) {
		const side = shape & BLOCK_MASK_SIDE;
		const corners = shape & BLOCK_MASK_CORNERS;

		const newSide = TRANSFORM_FLIP.SIDE[axis][side] ?? side;
		let newCorners = corners;

		const flippedCorners = TRANSFORM_FLIP.CORNERS[axis] && TRANSFORM_FLIP.CORNERS[axis][side];

		if (flippedCorners) {
			newCorners =
				(flippedCorners[shape & BLOCK_TL] ?? 0) |
				(flippedCorners[shape & BLOCK_TR] ?? 0) |
				(flippedCorners[shape & BLOCK_BR] ?? 0) |
				(flippedCorners[shape & BLOCK_BL] ?? 0);
		}

		return (shape & BLOCK_MASK_INVERT) | newSide | newCorners;
	}
}

const tmpVec = new Vector3();

export class ChunkUtil {
	static getVertexCount(buffer) {
		return buffer.position.length / 3;
	}

	//this function ignores texture/uv coords because otherwise there would be no alternative shapes
	//function creates garbage
	//there is probably a way to do this algorithmically, but nah don't feel like it
	static getAlternativeShapes(shape) {
		const alternatives = [
			//1 up
			[0b00100001, 0b01001000, 0b00010010],
			[0b00100010, 0b01000010, 0b01100001],
			[0b00100100, 0b00110010, 0b00011000],
			[0b00101000, 0b00111000, 0b01100100],
			[0b01010001, 0b01000001, 0b01100010],
			[0b01010010, 0b01000100, 0b00010001],
			[0b01010100, 0b00110100, 0b01101000],
			[0b01011000, 0b00110001, 0b00010100],

			//2 up
			[0b00100011, 0b01001010, 0b10100011, 0b11001010],
			[0b00101100, 0b00111010, 0b10101100, 0b10111010],
			[0b00100101, 0b00011010, 0b10100101, 0b10011010],
			[0b00101010, 0b01100101, 0b10101010, 0b11100101],
			[0b01010011, 0b01000101, 0b11010011, 0b11000101],
			[0b01011100, 0b00110101, 0b11011100, 0b10110101],
			[0b01010101, 0b01101010, 0b11010101, 0b11101010],
			[0b01011010, 0b00010101, 0b11011010, 0b10010101],

			//3 up
			[0b00101110, 0b00111110, 0b01101101],
			[0b00101101, 0b00111011, 0b00011110],
			[0b00101011, 0b01001011, 0b01100111],
			[0b00100111, 0b01001110, 0b00011011],
			[0b01011110, 0b00110111, 0b00011101],
			[0b01011101, 0b00111101, 0b01101110],
			[0b01011011, 0b01001101, 0b00010111],
			[0b01010111, 0b01000111, 0b01101011],

			//4 up aka cube
			//[0b01001111, 0b00111111, 0b00101111, 0b01011111, 0b00011111, 0b01101111],

			//no match
			[shape],
		];

		for (const list of alternatives) if (list.includes(shape)) return list;
	}

	//flatten 3D coordinates to 1D array index
	//note: h argument is not actually used
	static getIndex(w, h, d, rx, ry, rz) {
		return w * (ry * d + rz) + rx;
	}

	//opposite of above
	static getPosition(w, h, d, i, out) {
		out.y = Math.trunc(i / (w * d));
		i -= out.y * w * d;
		out.z = Math.trunc(i / w);
		out.x = i % w;

		return out;
	}

	static getElement(data, w, h, d, rx, ry, rz) {
		return data[ChunkUtil.getIndex(w, h, d, rx, ry, rz)];
	}

	// encodes normal signedness into a 5 bit integer. allows to represent all possible normal states including noxels
	// requires nested ternaries or becomes very bulky
	static encodeNormal(x, y, z) {
		return (
			(x > 0 ? 2 : x < 0 ? 0 : 1) * 9 + (y > 0 ? 2 : y < 0 ? 0 : 1) * 3 + (z > 0 ? 2 : z < 0 ? 0 : 1)
		);
	}

	//radius of a chunk inscribed inside of a sphere
	//assumes equal w/h/d
	static getRadius(chunkSize) {
		return Math.sqrt(0.75 * chunkSize ** 2);
	}

	//shape should be inverted?
	static flipDiagonal(shape) {
		const flip = (shape & BLOCK_MASK_INVERT) === BLOCK_MASK_INVERT;
		const corners = shape & BLOCK_MASK_CORNERS;

		//which shapes are inverted automatically to make terrain pretty (despite not having their inversion bit set)
		const invertByDefault =
			corners === BLOCK_BL ||
			corners === BLOCK_TR ||
			corners === (BLOCK_BL | BLOCK_TR) ||
			corners === (BLOCK_TL | BLOCK_BL | BLOCK_BR) ||
			corners === (BLOCK_TL | BLOCK_TR | BLOCK_BR);

		return invertByDefault ? !flip : flip;
	}

	//shape should not be built?
	static discardShape(shape) {
		const side = shape & BLOCK_MASK_SIDE;
		const corners = shape & BLOCK_MASK_CORNERS; //gets the block shape, ignoring the side

		return (
			corners === BLOCK_AIR || //is air
			(corners === BLOCK_CUBE && !!side) || //is cube but has side
			(corners !== BLOCK_CUBE && !side) || //is sculpted but doesn't have side
			side === BLOCK_MASK_SIDE || //invalid side
			(corners === BLOCK_CUBE && ChunkUtil.flipDiagonal(shape))
		); //inverted cube?
	}

	static selectFace(faces, sculptSide, side) {
		if (!faces) return;
		if (faces.length === 1) return faces[0];

		switch (ChunkUtil.rotateSide(sculptSide, side)) {
			case BLOCK_SNX:
				return faces[0];
			case BLOCK_SPX:
				return faces[1];
			case BLOCK_SNY:
				return faces[2];
			case BLOCK_SPY:
				return faces[3];
			case BLOCK_SNZ:
				return faces[4];
			case BLOCK_SPZ:
				return faces[5];
		}
	}

	//which side was hit in a raycast test
	static getHitSide(hitPos) {
		const anx = Math.abs(hitPos.x);
		const any = Math.abs(hitPos.y);
		const anz = Math.abs(hitPos.z);
		const apx = Math.abs(1 - hitPos.x);
		const apy = Math.abs(1 - hitPos.y);
		const apz = Math.abs(1 - hitPos.z);
		const winner = Math.min(anx, any, anz, apx, apy, apz);

		if (winner === anx) return BLOCK_SNX;
		else if (winner === apx) return BLOCK_SPX;
		else if (winner === any) return BLOCK_SNY;
		else if (winner === apy) return BLOCK_SPY;
		else if (winner === anz) return BLOCK_SNZ;
		//if(winner === apz)
		else return BLOCK_SPZ;
	}

	//from BLOCK_SPY to side
	static rotateVec(side, vec, isPosition) {
		if (!side || side === BLOCK_SPY) return vec;
		if (isPosition) vec.sub(tmpVec.set(0.5, 0.5, 0.5));

		switch (side) {
			case BLOCK_SNX:
				vec.set(-vec.y, -vec.z, vec.x);
				break;
			case BLOCK_SPX:
				vec.set(vec.y, -vec.z, -vec.x);
				break;
			case BLOCK_SNY:
				vec.set(vec.x, -vec.y, -vec.z);
				break;
			case BLOCK_SNZ:
				vec.set(vec.x, vec.z, -vec.y);
				break;
			case BLOCK_SPZ:
				vec.set(vec.x, -vec.z, vec.y);
				break;
		}

		if (isPosition) vec.add(tmpVec);
		return vec;
	}

	//from side to BLOCK_SPY
	static unrotateVec(side, vec, isPosition) {
		if (!side || side === BLOCK_SPY) return vec;
		if (isPosition) vec.sub(tmpVec.set(0.5, 0.5, 0.5));

		switch (side) {
			case BLOCK_SNX:
				vec.set(vec.z, -vec.x, -vec.y);
				break;
			case BLOCK_SPX:
				vec.set(-vec.z, vec.x, -vec.y);
				break;
			case BLOCK_SNY:
				vec.set(vec.x, -vec.y, -vec.z);
				break;
			case BLOCK_SNZ:
				vec.set(vec.x, -vec.z, vec.y);
				break;
			case BLOCK_SPZ:
				vec.set(vec.x, vec.z, -vec.y);
				break;
		}

		if (isPosition) vec.add(tmpVec);
		return vec;
	}

	//from BLOCK_SPY to sculptSide
	//think of this like multiplying the sides together. it returns the side of the block as it appears in the scene
	static rotateSide(sculptSide, side) {
		if (!sculptSide || sculptSide === BLOCK_SPY) return side;

		if (sculptSide === BLOCK_SNX)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNZ;
				case BLOCK_SPX:
					return BLOCK_SPZ;
				case BLOCK_SNY:
					return BLOCK_SPX;
				case BLOCK_SPY:
					return BLOCK_SNX;
				case BLOCK_SNZ:
					return BLOCK_SPY;
				case BLOCK_SPZ:
					return BLOCK_SNY;
			}
		else if (sculptSide === BLOCK_SPX)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SPZ;
				case BLOCK_SPX:
					return BLOCK_SNZ;
				case BLOCK_SNY:
					return BLOCK_SNX;
				case BLOCK_SPY:
					return BLOCK_SPX;
				case BLOCK_SNZ:
					return BLOCK_SPY;
				case BLOCK_SPZ:
					return BLOCK_SNY;
			}
		else if (sculptSide === BLOCK_SNY)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SPY;
				case BLOCK_SPY:
					return BLOCK_SNY;
				case BLOCK_SNZ:
					return BLOCK_SPZ;
				case BLOCK_SPZ:
					return BLOCK_SNZ;
			}
		else if (sculptSide === BLOCK_SNZ)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SPZ;
				case BLOCK_SPY:
					return BLOCK_SNZ;
				case BLOCK_SNZ:
					return BLOCK_SNY;
				case BLOCK_SPZ:
					return BLOCK_SPY;
			}
		else if (sculptSide === BLOCK_SPZ)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SNZ;
				case BLOCK_SPY:
					return BLOCK_SPZ;
				case BLOCK_SNZ:
					return BLOCK_SPY;
				case BLOCK_SPZ:
					return BLOCK_SNY;
			}
	}

	//from sculptSide to BLOCK_SPY
	//undoes rotateSide
	static unrotateSide(sculptSide, side) {
		if (!sculptSide || sculptSide === BLOCK_SPY) return side;

		if (sculptSide === BLOCK_SNX)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SPY;
				case BLOCK_SPX:
					return BLOCK_SNY;
				case BLOCK_SNY:
					return BLOCK_SPZ;
				case BLOCK_SPY:
					return BLOCK_SNZ;
				case BLOCK_SNZ:
					return BLOCK_SNX;
				case BLOCK_SPZ:
					return BLOCK_SPX;
			}
		else if (sculptSide === BLOCK_SPX)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNY;
				case BLOCK_SPX:
					return BLOCK_SPY;
				case BLOCK_SNY:
					return BLOCK_SPZ;
				case BLOCK_SPY:
					return BLOCK_SNZ;
				case BLOCK_SNZ:
					return BLOCK_SPX;
				case BLOCK_SPZ:
					return BLOCK_SNX;
			}
		else if (sculptSide === BLOCK_SNY)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SPY;
				case BLOCK_SPY:
					return BLOCK_SNY;
				case BLOCK_SNZ:
					return BLOCK_SPZ;
				case BLOCK_SPZ:
					return BLOCK_SNZ;
			}
		else if (sculptSide === BLOCK_SNZ)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SNZ;
				case BLOCK_SPY:
					return BLOCK_SPZ;
				case BLOCK_SNZ:
					return BLOCK_SPY;
				case BLOCK_SPZ:
					return BLOCK_SNY;
			}
		else if (sculptSide === BLOCK_SPZ)
			switch (side) {
				case BLOCK_SNX:
					return BLOCK_SNX;
				case BLOCK_SPX:
					return BLOCK_SPX;
				case BLOCK_SNY:
					return BLOCK_SPZ;
				case BLOCK_SPY:
					return BLOCK_SNZ;
				case BLOCK_SNZ:
					return BLOCK_SNY;
				case BLOCK_SPZ:
					return BLOCK_SPY;
			}
	}

	//returns base64 string
	static compress(shapes, types) {
		//most chunks are filled with the same exact block, and therefore in most cases it's faster
		//to test for this case by looping through the entire array than it is to use zlib.
		//we call these chunks "SUPERCOMPRESSED"

		const shape0 = shapes[0];
		if (shape0 !== BLOCK_AIR && shape0 !== BLOCK_CUBE) return ChunkUtil.zlibCompress(shapes, types); //only supercompress air/cube chunks

		const type0 = types[0];
		for (let i = 1; i < shapes.length; i++)
			if (shapes[i] !== shape0 || types[i] !== type0) return ChunkUtil.zlibCompress(shapes, types); //all blocks must be identical to supercompress

		if (shape0 === BLOCK_AIR && type0 === 0) return 0; //completely empty air chunk
		return `%${shape0 === BLOCK_CUBE ? 1 : 0},${type0}`; //% is used because it isn't one of the characters used in base64
	}

	static decompress(data, shapes, types) {
		if (data === 0) {
			shapes.fill(0);
			types.fill(0);
			return;
		}

		if (data.startsWith("%")) {
			const fill = data
				.slice(1)
				.split(",")
				.map((int) => parseInt(int));
			shapes.fill(fill[0] ? BLOCK_CUBE : BLOCK_AIR);
			types.fill(fill[1]);
			return;
		}

		return ChunkUtil.zlibDecompress(data, shapes, types);
	}

	//dedi overrides these with node.js native zlib
	static zlibCompress(shapes, types) {
		const merge = new Uint8Array(shapes.byteLength + types.byteLength);
		merge.set(shapes);
		merge.set(new Uint8Array(types.buffer), shapes.length);

		return toBase64(zlibSync(merge));
	}

	static zlibDecompress(data, shapes, types) {
		data = unzlibSync(fromBase64(data));
		shapes.set(data.slice(0, shapes.length));
		new Uint8Array(types.buffer).set(data.slice(shapes.length));
	}
}
