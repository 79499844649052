import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { WorldEditorStatus } from "base/world/WorldEditor";

export const EDITOR_NODES = [
	node({
		id: "a30081b2-9723-47a4-9f0b-854ddd40f7b6",
		name: "Get Editor Status",
		type: NODE_TYPE_ID.function.world,
		description: "Returns the current editor status",
		keywords: ["testing"],
		outputs: {
			testing: { name: "Testing", type: "boolean" },
		},
		resolve(_inputs, ctx) {
			return {
				testing:
					ctx.world.editor.status === WorldEditorStatus.TESTING_ACTIVE ||
					ctx.world.editor.status === WorldEditorStatus.TESTING_PAUSED,
			};
		},
	}),
];
