import { Mesh } from "three";

class OcclusionQueryMesh extends Mesh {
	/**
	 * @type {import('base/world/entity/Character').Character | null}
	 */
	characterOwner = null;

	constructor(o) {
		super(o);

		this.type = "OcclusionQueryMesh";

		this.occlusionQueryCallback = null;
		this.cameraFilter = null; // TODO: evgeny.muralev can be used for other cameras e.g. shadow maps
		this.isOcclusionQueryMesh = true;
	}
}

export { OcclusionQueryMesh };
