import type { ISelectorSlot } from "@jamango/content-client";
import { Vector3Set } from "base/util/math/VectorStorage";
import { bulkBox } from "base/world/block/Bulk";
import { Box3, Vector3 } from "three";

export enum SelectorTargetType {
	NEW_GROUP,
	GROUP,
	BLOCK_TYPE,
	ITEM_SCENE_TREE_NODE,
	PROP_SCENE_TREE_NODE,
	CHARACTER_SCENE_TREE_NODE,
	ENTITY_INSTANCE,
}

export enum BulkSelectionBlocksBrushState {
	DISABLED,
	SELECTING_FIRST_CORNER,
	SELECTING_SECOND_CORNER,
	PREVIEWING,
}

export type SelectorTarget =
	| {
			type:
				| SelectorTargetType.NEW_GROUP
				| SelectorTargetType.GROUP
				| SelectorTargetType.BLOCK_TYPE
				| SelectorTargetType.ITEM_SCENE_TREE_NODE
				| SelectorTargetType.PROP_SCENE_TREE_NODE
				| SelectorTargetType.CHARACTER_SCENE_TREE_NODE;
			id: string;
			name: string;
	  }
	| {
			type: SelectorTargetType.ENTITY_INSTANCE;
			id: number;
			name: string;
	  };

export enum CharacterSculptMode {
	CUBE,
	SCULPT,
	INVERT,
}

export const CHARACTER_SCULPT_MODE_CYCLE = [
	CharacterSculptMode.CUBE,
	CharacterSculptMode.SCULPT,
	CharacterSculptMode.INVERT,
];

export class CharacterSelectorComponent {
	name: string;

	state: {
		selectedSlot: ISelectorSlot | null;
		sculptMode: CharacterSculptMode;

		// inspect targets based on the current selection or view raycast result
		targets: SelectorTarget[] | null;
		currentTarget: SelectorTarget | null;
		chosenTarget: SelectorTarget | null;

		// the bulk selector brush is used to add blocks to the bulk selection
		// currently it's only a box, but this could change in future to support other brush "shapes"
		bulkSelectionBlocksBrush: Box3;
		bulkSelectionBlocksBrushState: BulkSelectionBlocksBrushState;
		bulkSelectionBlocksBrushVersion: number;

		// the bulk selection - can consist of blocks and entities
		bulkSelectionBlocks: Vector3Set;
		bulkSelectionBlocksVersion: number;
		bulkSelectionEntities: Set<number>;

		// entities that are in some way selected - whether manipulating, in selection, or in view
		selectorEntities: Set<number>;
	};

	constructor() {
		this.name = "selector";

		this.state = {
			selectedSlot: null,
			sculptMode: CharacterSculptMode.CUBE,
			targets: null,
			currentTarget: null,
			chosenTarget: null,
			bulkSelectionBlocksBrush: new Box3(),
			bulkSelectionBlocksBrushState: BulkSelectionBlocksBrushState.DISABLED,
			bulkSelectionBlocksBrushVersion: 0,
			bulkSelectionBlocks: new Vector3Set(),
			bulkSelectionBlocksVersion: 0,
			bulkSelectionEntities: new Set(),
			selectorEntities: new Set(),
		};
	}
}

const _block = new Vector3();

export const resetSelector = (selector: CharacterSelectorComponent) => {
	selector.state.targets = null;
	selector.state.currentTarget = null;
	selector.state.chosenTarget = null;

	selector.state.bulkSelectionBlocksBrushState = BulkSelectionBlocksBrushState.DISABLED;
	selector.state.bulkSelectionBlocksBrush.makeEmpty();
	selector.state.bulkSelectionBlocksBrushVersion++;

	selector.state.bulkSelectionBlocks.clear();
	selector.state.bulkSelectionEntities.clear();
	selector.state.bulkSelectionBlocksVersion++;
};

export const setBulkSelectionBrush = (
	selector: CharacterSelectorComponent,
	corner1: Vector3,
	corner2: Vector3,
	state: BulkSelectionBlocksBrushState,
) => {
	selector.state.bulkSelectionBlocksBrushState = state;
	selector.state.bulkSelectionBlocksBrush.setFromPoints([corner1, corner2]);
	selector.state.bulkSelectionBlocksBrushVersion++;
};

export const addBrushToSelection = (selector: CharacterSelectorComponent) => {
	// update bulk selection blocks
	for (const block of bulkBox(selector.state.bulkSelectionBlocksBrush, true, _block)) {
		selector.state.bulkSelectionBlocks.add(block);
	}
	selector.state.bulkSelectionBlocksVersion++;
};

export const removePositionFromSelection = (selector: CharacterSelectorComponent, position: Vector3) => {
	// remove the block from the bulk selection
	selector.state.bulkSelectionBlocks.delete(position);
	selector.state.bulkSelectionBlocksVersion++;
}

export const resetBulkSelectionBrush = (selector: CharacterSelectorComponent) => {
	selector.state.bulkSelectionBlocksBrushState = BulkSelectionBlocksBrushState.DISABLED;
	selector.state.bulkSelectionBlocksBrush.makeEmpty();
	selector.state.bulkSelectionBlocksBrushVersion++;
};

export const resetBulkSelection = (selector: CharacterSelectorComponent) => {
	selector.state.bulkSelectionBlocks.clear();
	selector.state.bulkSelectionBlocksVersion++;
	selector.state.bulkSelectionEntities.clear();
};

export const nudgeBulkSelection = (selector: CharacterSelectorComponent, delta: Vector3) => {
	const newBulkSelectionBlocks = new Vector3Set();

	for (const block of selector.state.bulkSelectionBlocks.iterate(_block)) {
		newBulkSelectionBlocks.add(block.add(delta));
	}

	selector.state.bulkSelectionBlocks = newBulkSelectionBlocks;

	selector.state.bulkSelectionBlocksVersion++;
};

export const addEntityToBulkSelection = (selector: CharacterSelectorComponent, entityId: number) => {
	selector.state.bulkSelectionEntities.add(entityId);
};
export const removeEntityFromBulkSelection = (selector: CharacterSelectorComponent, entityId: number) => {
	selector.state.bulkSelectionEntities.delete(entityId);
};
