import { BB, BB_CLIENT_BG_INTERVAL } from "base/BB";
import { WebGLRenderer, SRGBColorSpace, LinearSRGBColorSpace } from "three";
import { createThread } from "client/Threads";
import * as Resources from "client/Resources";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import { LoadingScreenClient } from "client/dom/LoadingScreen.js";
import { UI } from "client/dom/UI";
import { SettingsClient } from "client/dom/Settings.js";
import { DebugClient } from "client/debug/Debug";
import { HudClient } from "client/dom/Hud.js";
import { InputPoll } from "client/input/Poll.js";
import { netState } from "router/Parallelogram";
import { createLogger } from "@jamango/helpers";
import { AA_SETTING } from "@jamango/content-client";

const logger = createLogger("BBClient");

export class BBClient {
	/**
	 * @type {WebGLRenderer}
	 */
	static renderer;

	/**
	 * @type {HTMLCanvasElement}
	 */
	static canvas = null;

	/**
	 * @type {InputPoll}
	 */
	static inputPoll = null;

	/**
	 * @type {DebugClient}
	 */
	static debug = null;

	/**
	 * @type {SettingsClient}
	 */
	static settings = null;

	/**
	 * @param {import("../types").JamangoEngineOptions['ui']} ui
	 */
	static async init(ui) {
		await UI.init(ui);
		LoadingScreenClient.init();
	}

	static async main(args) {
		BBClient.canvas = document.getElementById("gameCanvas");
		BBClient.canvas.addEventListener("webglcontextlost", () =>
			UI.state.errorNotification().setError(Error("Your OS yeeted the WebGL context")),
		);

		const gl = BBClient.canvas.getContext("webgl2", {
			antialias: UI.state.settings().aaSetting === AA_SETTING.MSAA,
			preserveDrawingBuffer: true,
		});

		if (!gl) throw Error("Couldn't create a WebGL2 context. Your browser is likely blocking WebGL.");

		const r = (BBClient.renderer = new WebGLRenderer({
			canvas: BBClient.canvas,
			context: gl,
		}));
		r.outputColorSpace = LinearSRGBColorSpace;
		r.outputPassOutputColorSpace = SRGBColorSpace;
		r.outputPassToneMapping = 4;
		r.outputPassToneMappingExposure = 1.26;
		r.alpha = true;
		r.autoClear = false;
		r.preserveDrawingBuffer = true;
		r.debug.checkShaderErrors = false;
		r.info.autoReset = false;

		BBClient.inputPoll = new InputPoll(BBClient.canvas);

		BBClient.settings = new SettingsClient();
		BBClient.debug = new DebugClient();
		BBClient.hud = new HudClient();

		const ops = await Promise.all([createThread(args.workers.ao, "ao"), Resources.initLoaders()]);

		BBClient.thread = {};
		for (const op of ops) if (op instanceof Worker) BBClient.thread[op.name] = op;

		window.onresize = window.onorientationchange = BBClient.resize;

		UI.state.engine().setAsLoaded();
	}

	static screenshotCanvas() {
		const strMime = "image/jpeg";
		const screenshotDataUrl = BBClient.canvas.toDataURL(strMime);

		const link = document.createElement("a");
		if (typeof link.download === "string") {
			// Firefox requires the link to be in the body
			document.body.appendChild(link);
			const filename = "screenshot" + ".jpg";
			link.download = filename;
			link.href = screenshotDataUrl.replace(strMime, "image/octet-stream");
			link.click();
			document.body.removeChild(link);
		}
	}

	static async createWorldStage2() {
		await LoadingScreen.setText("Downloading assets");
		LoadingScreen.showProgress(true);
		await BB.world.client.downloadAssets();
	}

	static async createWorldStage3() {
		BB.client.debug.init();
		BB.client.hud.init();
		BB.client.settings.init();
		BB.world.environmentSettings.client.init();

		if (netState.isHost) {
			try {
				await UI.gameMultiplayer.hostWorld();
			} catch (oops) {
				// TODO: Show an alert dialog here.
				logger.error(oops);
			}
		}

		window.onresize();
	}

	static initUpdateLoop(cb) {
		// tracks rack / timeout ids
		let currentRaf = -1;
		let currentTimeout = -1;

		function loop() {
			currentTimeout = currentRaf = -1;

			try {
				cb();
			} catch (e) {
				BB.router.stopUpdateLoop();
				UI.state.errorNotification().setError(e);
				throw e;
			}

			if (!document.hidden) {
				// request a tick at refresh rate
				currentRaf = requestAnimationFrame(loop);
			} else {
				//keep updating even while tab is minimized
				currentTimeout = setTimeout(loop, 1000 * BB_CLIENT_BG_INTERVAL);
			}
		}

		// handle visibility state switches
		document.addEventListener("visibilitychange", () => {
			// cancel RAF and query a timeout
			if (currentRaf >= 0) cancelAnimationFrame(currentRaf);
			if (currentTimeout < 0) loop();
		});

		loop();
	}

	static update(_deltaTime) {
		BBClient.hud.update();
	}

	static async downloadChunks(promises) {
		LoadingScreen.show(false);
		await LoadingScreen.setText("Downloading chunks");
		LoadingScreen.showProgress(true);

		const progressPromises = [];
		let currentPromise = 0;

		for (const promise of promises) {
			progressPromises.push(
				promise.then(function (isCancelled) {
					if (isCancelled) return true;
					LoadingScreen.setProgress("", ++currentPromise / promises.length);
				}),
			);
		}

		const isCancelled = (await Promise.all(progressPromises)).includes(true);

		return isCancelled;
	}

	static requestFullscreen(element) {
		if (document.fullscreenEnabled) {
			const bodyEl = document.body;
			const rfs =
				bodyEl.requestFullscreen ||
				bodyEl.webkitRequestFullScreen ||
				bodyEl.mozRequestFullScreen ||
				bodyEl.msRequestFullscreen;
			rfs.call(element);
		}
	}

	static resize() {
		if (!BB.world?.router.initComplete) return;

		const height = window.innerHeight;
		let width = window.innerWidth;

		const editorEl = document.getElementById("world-editor");
		if (editorEl !== null) width -= editorEl.clientWidth;

		const r = BBClient.renderer.getPixelRatio();
		const hr = height * r;
		const wr = width * r;

		BBClient.canvas.width = wr;
		BBClient.canvas.height = hr;
		BBClient.renderer.setSize(width, height, false);
		BB.world?.client.resize(wr, hr);
	}
}
