import { z } from "zod";
import { MAX_CHARACTER_NAME, MIN_CHARACTER_NAME } from "../constants/characters";
import type { ICharacter } from "../types";
import { AIType, AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

const nameSchema = z
	.string()
	.min(MIN_CHARACTER_NAME, {
		message: `Character name must be at least ${MIN_CHARACTER_NAME} characters long.`,
	})
	.max(MAX_CHARACTER_NAME, {
		message: `Character name must be at most ${MAX_CHARACTER_NAME} characters long.`,
	});

export const name = (value: string) => {
	nameSchema.parse(value);
};

const aiTypeSchema = z.nativeEnum(AIType, {
	message: "Invalid block collision.",
});

export const aiType = (value: AIType) => {
	aiTypeSchema.parse(value);
};

const showNametagSchema = z.boolean();

export const showNametag = (value: boolean) => {
	showNametagSchema.parse(value);
};

const dropItemSchema = z.boolean();

export const dropItem = (value: boolean) => {
	dropItemSchema.parse(value);
};

const canKillNPCsSchema = z.boolean();

export const canKillNPCs = (value: boolean) => {
	canKillNPCsSchema.parse(value);
};

const aiAvatarSchema = assetKeySchema(AssetType.AVATAR, "Avatar is required.");

export const aiAvatar = (value: string) => {
	aiAvatarSchema.parse(value);
};

export const create = (character: {
	name: string;
	aiType: AIType;
	aiAvatar?: string | null | undefined;
	showNametag: boolean;
	dropItem: boolean;
	canKillNPCs: boolean;
}) => {
	const schema = z.object({
		name: nameSchema,
		aiType: aiTypeSchema,
		aiAvatar: aiAvatarSchema.nullish(),
		showNametag: showNametagSchema,
		dropItem: dropItemSchema,
		canKillNPCs: canKillNPCsSchema,
	});
	schema.parse(character);
};

export const CharacterScriptAttachmentSchema = z.object({
	script: assetKeySchema(AssetType.SCRIPT),
	data: z.record(z.any()).optional(),
});

export const CharacterSchema = z.object({
	id: z.string(),
	pk: assetKeySchema(AssetType.CHARACTER),
	type: z.literal(AssetType.CHARACTER),
	name: nameSchema,
	scripts: z.array(CharacterScriptAttachmentSchema),
	aiType: aiTypeSchema,
	aiAvatar: aiAvatarSchema.nullish(),
	showNametag: showNametagSchema,
	dropItem: dropItemSchema,
	canKillNPCs: canKillNPCsSchema,
	createdAt: z.string(),
	updatedAt: z.string(),
	packageId: PackageIdSchema.optional(),
	needsReload: z.boolean().optional(), //client side only
});

export const update = (character: ICharacter) => {
	CharacterSchema.parse(character);
};

export const all = (character: ICharacter) => {
	CharacterSchema.parse(character);
};
