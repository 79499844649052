import type { Quaternion } from "three";
import { Vector2, Spherical, type Vector3 } from "three";
import type { World } from "base/world/World";
import type { Input, InputCommands } from "@jamango/engine/Input.ts";
import { calculateMaxSpeed } from "./entity/system/CharacterMovementVelocity";
import type { Character } from "./entity/Character";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { Constraint, ConstraintState } from "base/world/ConstraintManager";

export type State = ReturnType<typeof init>;

export const init = () => {
	return {};
};

export type HostInputCommand =
	| [
			"create",
			mapId: any,
			def: string,
			x: number,
			y: number,
			z: number,
			qx: number,
			qy: number,
			qz: number,
			qw: number,
			entityId: EntityID,
			sceneNodeId: string | null,
	  ]
	| ["dispose", entityId: EntityID]
	| ["mount", childEntityId: EntityID, parentEntityId: EntityID, toggle: boolean, mountIndex: number]
	| ["createConstraint", constraintId: number, constraint: Constraint]
	| ["updateConstraint", constraintId: number, constraintState: ConstraintState]
	| ["disposeConstraint", constraintId: number]
	| ["characterSetCamera", entityId: EntityID, phi: number, theta: number];

export function getDesyncValue(pos: Vector3, _quat: Quaternion, _vel: Vector3, entity: Character) {
	// this is extremely crude, simple version to get something out of the door
	// compare two entity states, a and b - return an abstract value of "desynchronization"
	// server calls this function to check if the clients values are acceptable to take locally
	// it should compare against stored histories / path splines ("get entity position at T")
	// TODO: this also needs to understand velocity - i.e. reconciliate velocity properly to handle velocity differences
	// that is best done when reconciliation gets a client-prediction on rete
	const dist = pos.distanceTo(entity.position);
	const maxSpeed = Math.max(1, calculateMaxSpeed(entity));
	const result = dist / maxSpeed;
	return result;
}

export function reset(world: World) {
	// resets all discrete inputs for every input we track
	// this is called for clients and hosts after the main update pipeline
	for (const e of world.entities) {
		if (e.cmd !== undefined) e.cmd.length = 0;
	}
}

export function createInput() {
	return {
		// general (always send)
		pointerLocked: false,
		nipple: new Vector2(0, 0),
		camera: new Spherical(1, Math.PI / 2, 0),
		hold: null,

		// continuous (only send if pointerLock true):
		isFlyingDown: false,
		isFlyingUp: false,
		isSprinting: false,
		isCrouching: false,
		isJumping: false,
		isPullingTrigger: false,
		isIronSights: false,
		isSwappingTool: false,
	} as Input;
}

export function serializeInput(input: Input, cmd: InputCommands) {
	const parts = [];

	// Use bitmasking for boolean flags - much more compact
	let bitmask = 0;
	if (input.pointerLocked) bitmask |= 1 << 0;
	if (input.isFlyingDown) bitmask |= 1 << 1;
	if (input.isFlyingUp) bitmask |= 1 << 2;
	if (input.isSprinting) bitmask |= 1 << 3;
	if (input.isCrouching) bitmask |= 1 << 4;
	if (input.isJumping) bitmask |= 1 << 5;
	if (input.isPullingTrigger) bitmask |= 1 << 6;
	if (input.isIronSights) bitmask |= 1 << 7;
	if (input.isSwappingTool) bitmask |= 1 << 8;
	if (input.requestFlying) bitmask |= 1 << 9;
	parts.push(bitmask.toString(36)); // Base36 for compact representation

	// Nipple Vector2 and Camera Spherical
	parts.push(input.nipple.x.toFixed(1));
	parts.push(input.nipple.y.toFixed(1));
	parts.push(input.camera.theta.toFixed(3));
	parts.push(input.camera.phi.toFixed(3));

	// Hold command (continuous)
	parts.push(input.hold === null ? "n" : JSON.stringify(input.hold));

	// Discrete commands
	parts.push(cmd.length === 0 ? "n" : JSON.stringify(cmd));

	return parts.join(";");
}

export function deserializeInput(
	input: Input,
	cmd: InputCommands,
	serialized: string,
): [Input, InputCommands] {
	const parts = serialized.split(";");
	let index = 0;

	// Handle boolean flags via bitmask
	const bitmask = parseInt(parts[index++], 36);

	// Continuous (non-discrete) boolean properties - reset to false, then set from bitmask
	input.pointerLocked = (bitmask & (1 << 0)) !== 0;
	input.isFlyingDown = (bitmask & (1 << 1)) !== 0;
	input.isFlyingUp = (bitmask & (1 << 2)) !== 0;
	input.isSprinting = (bitmask & (1 << 3)) !== 0;
	input.isCrouching = (bitmask & (1 << 4)) !== 0;
	input.isJumping = (bitmask & (1 << 5)) !== 0;
	input.isPullingTrigger = (bitmask & (1 << 6)) !== 0;
	input.isIronSights = (bitmask & (1 << 7)) !== 0;
	input.isSwappingTool = (bitmask & (1 << 8)) !== 0;
	input.requestFlying = (bitmask & (1 << 9)) !== 0;

	// Parse remaining numeric values
	input.nipple.x = parseFloat(parts[index++]);
	input.nipple.y = parseFloat(parts[index++]);
	input.camera.theta = parseFloat(parts[index++]);
	input.camera.phi = parseFloat(parts[index++]);

	const holdValue = parts[index++];
	input.hold = holdValue === "n" ? null : JSON.parse(holdValue);

	// parse commands
	const cmdValue = parts[index++];
	if (cmdValue !== "n") {
		cmd.push(...JSON.parse(cmdValue));
	}

	return [input, cmd];
}
