import { AGGREGATE_EMPTY } from "base/world/block/chunk/StorageComponent";
import { Vector3 } from "three";
import type { ChunkScene } from "../Scene";

// precalculate the spherical expansion chunk order
const order: { x: number; y: number; z: number; distSq: number }[] = [];
const radius = 20;
const radiusSq = radius * radius;
for (let z = -radius; z <= radius; z++) {
	for (let y = -radius; y <= radius; y++) {
		const sy = y * 2; // we scale the vertical distance twice. this way, we expand faster horizontally
		for (let x = -radius; x <= radius; x++) {
			const distSq = x * x + sy * sy + z * z;
			if (distSq >= radiusSq) continue;
			order.push({ x, y, z, distSq });
		}
	}
}
order.sort((a, b) => a.distSq - b.distSq);

export type DiscoveryState = {
	pos: Vector3;
	progress: number;
	visited: Set<string>;
	queue: [number, number, number, number][];
};

export function init(): DiscoveryState {
	return { progress: 0, pos: new Vector3(), visited: new Set(), queue: [] };
}

export const update = (scene: ChunkScene, worldPos: Vector3, prio: DiscoveryState) => {
	const chunkSize = scene.chunkSize;

	// the position in chunk-space (integers)
	const pos = worldPos.divideScalar(chunkSize).round();
	if (!prio.pos.equals(pos)) {
		prio.progress = 0;
		prio.pos.copy(pos);
	}

	// iterate order from last lowest entry
	let queued = 0;
	while (prio.progress < order.length) {
		const p = order[prio.progress++];

		// get chunk world id + hash
		const x = p.x + pos.x;
		const y = p.y + pos.y;
		const z = p.z + pos.z;
		const hash = `${x},${y},${z}`;

		// check if this discovery has previously seen this hash
		if (prio.visited.has(hash)) continue;
		prio.visited.add(hash);

		// if not seen, and aggregate is not fully air, add to queue
		// TODO: WE SHOULD CHECK chunk.isVisibleInAggregate here - and dont even proceed if it isnt
		// in order to do this, we must maintain an accurate map of this information
		const chunk = scene.getChunk(x, y, z);
		const agg = chunk.storage.getAggregate();
		prio.queue.push([x, y, z, agg]);
		queued++;
		if (agg !== AGGREGATE_EMPTY || queued >= 10) {
			break;
		}
	}
};

export const reset = (prio: DiscoveryState) => {
	prio.progress = 0;
	prio.visited.clear();
	prio.queue.length = 0;
};
