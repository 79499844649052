import type { z } from "zod";
import type { CharacterSchema } from "../validations/character";
import type { IParsedAvatar } from "./avatars";

export type ICharacter = z.infer<typeof CharacterSchema>;

export type IParsedCharacter = Omit<ICharacter, "aiAvatar"> & {
	aiAvatar: IParsedAvatar;
};

export enum AIType {
	NEUTRAL = "neutral",
	ALLY = "ally",
	ENEMY = "enemy",
	PLAYER_BOT = "player_bot",
}
