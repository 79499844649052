import { DEGRAD } from "base/util/math/Math.ts";
import { Euler, Vector3 } from "three";
import { OnHitParticlesDefault, OnHitPlayerParticlesDefault } from "./DefaultParticles";

const s = new Vector3(0.5, 0.5, 2); //collider size

export const ItemNovaRifle = {
	name: "ItemNovaRifle",
	display: "Wild West Rifle",
	assets: [
		{
			id: "mdl-item-nova-rifle",
			url: "assets/engine/mods/ItemNovaRifle/mdl-item-nova-rifle.glb",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-nova-rifle",
		node: "item",
		animated: true,
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	muzzleFlashNode: "muzzle_flash_GEO",
	geom: { type: "box", width: s.x, height: s.y, depth: s.z },
	type: "ranged",
	weapon: true,
	automatic: false,
	animations: "NovaRifle",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_NovaRifle_FP_NotAim_Shoot",
				sight: "JMGO_NovaRifle_FP_Aim_Shoot",
			},
			third: "JMGO_NovaRifle_Shoot_Forward",
		},
	},
	reloadAnimationName: {
		item: "JMGO_NovaRifle_Reload_rig",
		RigDefault: {
			third: "JMGO_NovaRifle_Reload",
			first: "JMGO_NaRifle_FP_NotAim_Reload",
		},
	},
	reloadAnimationTime: 2.47,
	cooldown: 0.5,
	projectile: "ProjectileNovaRifle",
	projectilePos: new Vector3(0, 85, -13),
	projectileCartridgeLimit: 6,
	projectilesPerShot: 1,
	shakeVertical: 6,
	shakeVerticalMax: 12,
	shakeRecovery: 0.9,
	spread: 1.1,
	recoil: 0.1,
	fovAim: 45 * DEGRAD,
	sound: {
		fire: { asset: "snd-item-nova-rifle-fire", volume: 2 },
	},
	muzzleFlashParticles: [
		{
			colors: [1, 1, 1, 0.2],
			type: "cube",
			numParticles: 30,
			positionRange: [0.2, 0.2, 0.2],
			lifeTime: 1.5,
			endTime: 1.5,
			startTime: 0,
			startSize: 0.25,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			velocityAimDirectionIntensity: 3,
			velocityRange: [1, 1, 1],
			velocity: [0, 0, 0],
			acceleration: [0, 0, 0],
			accelerationRange: [1, 0.1, 1],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.1,
		},
	],
};

export const ProjectileNovaRifle = {
	name: "ProjectileNovaRifle",
	assets: [
		{
			url: "assets/engine/mods/ItemNovaRifle/snd-item-nova-rifle-fire.ogg",
			id: "snd-item-nova-rifle-fire",
			type: "audio/ogg",
		},
	],
	velocity: 200,
	entityDamage: 90,
	headshotDamage: 210,
	bulletTrail: true,
	bulletTrailColor: "#fff58a",
	onHitParticles: OnHitParticlesDefault,
	onHitPlayerParticles: OnHitPlayerParticlesDefault,
};
