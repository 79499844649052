import { LoopOnce } from "three";
import { ITEMS } from "@jamango/content-client";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";

export class LocomotionStateWeaponsCrouchStart extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsCrouchStart", animMesh, locomotionInput, transitions);

		this.transitionToCrouchIdleTime = 0.2;
		this.transitionToCrouchWalkingTime = 0.2;
		this.transitionToDeathTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Crouch_Start`,
				new AnimationTrack(animMesh, `JMGO_${item}_Crouch_Entry`, this),
			);
		});

		this.crouchStartAction = this.trackMap.get("Spade_Crouch_Start");
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;

		if (currentItemAnimations) {
			this.crouchStartAction = this.trackMap.get(`${currentItemAnimations}_Crouch_Start`);
		} else {
			this.crouchStartAction = this.trackMap.get("Spade_Crouch_Start");
		}

		this.crouchStartAction.action.reset();
		this.crouchStartAction.action.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchStartAction.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.crouchStartAction.action.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.crouchStartAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("WeaponsCrouchWalking");
			outTransitionToState.blendTime = this.transitionToCrouchWalkingTime;
			return true;
		} else {
			if (this.crouchStartAction.action.getRemainingTime() < this.transitionToCrouchIdleTime) {
				outTransitionToState.ptr = map.get("WeaponsCrouchIdle");
				outTransitionToState.blendTime = this.transitionToCrouchIdleTime;
				return true;
			}
		}

		return false;
	}
}
