import { getStaticFile } from "@jamango/content-client";
import { DEGRAD } from "base/util/math/Math.ts";
import { Euler } from "three";

const genericName = "ItemPencil";

export const ItemPencil = {
	name: genericName,
	display: "Pencil",
	icon: getStaticFile("assets/frontend/items/pencil-small.webp"),
	assets: [
		{
			url: "assets/engine/mods/ItemPencil/mdl-item-pencil.glb",
			id: "mdl-item-pencil",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-pencil",
		animated: true,
		node: "item",
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	geom: { type: "box", width: 0.25, height: 0.25, depth: 1 },
	type: "tool",
	ejectImpulse: 0.3,
	animations: "Pencil",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_Pencil_FP_NotAim_Shoot",
			},
			third: "JMGO_Pencil_Shoot_Forward",
		},
	},
	cooldown: 0,
	bulkSelectorColor: 0x126cf3,

	blockStructurePrefix: `${genericName}_`,
	isPencil(defName) {
		return !!defName?.startsWith(ItemPencil.name);
	},
	isBlockStructure(defName) {
		return !!defName?.startsWith(ItemPencil.blockStructurePrefix);
	},
};
