import { Vector3Map, Vector3Set } from "base/util/math/VectorStorage";
import { BLOCK_AIR } from "base/world/block/Util";
import type { World } from "base/world/World";
import * as GameWorldUI from "client/dom/GameWorldUI";
import type { Camera } from "three";
import { Vector3 } from "three";
import { NODE } from "base/rete/InternalNameMap";
import { entityIsCharacter } from "base/world/entity/component/Type";

const VISIBLE_PROXIMITY = 10;

const _chunkPosition = new Vector3();
const _position = new Vector3();
const _center = new Vector3();
const _size = new Vector3();
const _cameraBlock = new Vector3();

export type InteractLabelsClientState = ReturnType<typeof init>;

export const init = () => {
	const dirtyBlockGroups = new Set<string>();
	const blockTypesDirtyPositions = new Vector3Set();

	const groupIdToVfx = new Map<string, { label: string; interactLabelIds: string[] }>();
	const blockTypePositionToVfx = new Vector3Map<{ interactLabelId: string }>();

	const entityIdToVfx = new Map<number, string>();

	return {
		dirtyBlockGroups,
		groupIdToVfx,
		blockTypePositionToVfx,
		entityIdToVfx,
		interactBlockTypesUpdateTime: 0,
		interactBlockTypes: new Set<number>(),
		blockTypesLastUpdateTime: 0,
		blockTypesDirtyPositions,
		blockTypesLastCameraChunk: new Vector3(),
		blockTypesChunksInRange: new Vector3Set(),
		reteLastReloadTime: 0,
	};
};

export const markGroupDirty = (state: InteractLabelsClientState, blockGroupId: string) => {
	state.dirtyBlockGroups.add(blockGroupId);
};

export const markPositionDirty = (
	state: InteractLabelsClientState,
	world: World,
	x: number,
	y: number,
	z: number,
) => {
	const s = world.scene.chunkSize;
	const cx = Math.floor(x / s);
	const cy = Math.floor(y / s);
	const cz = Math.floor(z / s);

	if (state.blockTypesChunksInRange.has(_chunkPosition.set(cx, cy, cz))) {
		state.blockTypesDirtyPositions.add(_position.set(x, y, z));
	}
};

export const update = (state: InteractLabelsClientState, world: World) => {
	updateBlockGroupLabels(state, world);
	updateBlockTypeLabels(state, world);
	updateEntityLabels(state, world);

	const camera = world.client!.camera;
	updateGroupProximityVisibility(state, world, camera);
	updateBlockTypeProximityVisibility(state, world, camera);
	updateEntityProximityVisibility(state, world, camera);
};

const createInteractLabel = (world: World, id: string, label: string, position: Vector3) => {
	const labelPosition = position.clone().addScalar(0.5);

	GameWorldUI.add(world.client!.gameWorldUI, id, "interactLabel", label, labelPosition);
};

const updateEntityLabels = (state: InteractLabelsClientState, world: World) => {
	for (const entity of world.entities) {
		if (!entity.interact) continue;

		const wants = entity.interact.state.enable && entity.interact.state.label !== null;

		let element = entity.interact.state.element
			? GameWorldUI.get(world.client.gameWorldUI, entity.interact.state.element)
			: undefined;

		const dirty = element && element.data !== entity.interact.state.label;

		if (entity.interact.state.element && (!wants || dirty)) {
			GameWorldUI.remove(world.client!.gameWorldUI, entity.interact.state.element);
			entity.interact.state.element = undefined;
			element = undefined;

			state.entityIdToVfx.delete(entity.entityID);
		}

		if (wants && !element) {
			const newElement = GameWorldUI.add(
				world.client!.gameWorldUI,
				`interact-label-${entity.entityID}`,
				"interactLabel",
				entity.interact.state.label!,
			);
			entity.object3D.add(newElement.object3D);

			if (entityIsCharacter(entity)) {
				newElement.object3D.position.set(0, entity.size.def.height * 0.6, 0);
			}

			entity.interact.state.element = newElement.id;

			state.entityIdToVfx.set(entity.entityID, newElement.id);

			element = newElement;
		}
	}

	for (const [entityId, labelId] of state.entityIdToVfx.entries()) {
		const entity = world.getEntity(entityId);
		if (!entity) {
			// remove orphaned
			GameWorldUI.remove(world.client!.gameWorldUI, labelId);
			state.entityIdToVfx.delete(entityId);
		}
	}
};

const updateBlockGroupLabels = (state: InteractLabelsClientState, world: World) => {
	if (state.reteLastReloadTime === world.rete.lastReloadTime && state.dirtyBlockGroups.size === 0) return;

	const onInteractWithBlockTriggers = world.rete.triggers.onBlock[NODE.OnInteractWithBlock];

	const groupsWithOnInteract = new Map<string, { label: string }>();

	if (onInteractWithBlockTriggers) {
		for (const { entryPoint, data } of Object.values(onInteractWithBlockTriggers).flatMap(
			(triggers) => triggers,
		)) {
			if (entryPoint.type !== "group") continue;

			if (groupsWithOnInteract.has(entryPoint.targetId)) continue;

			groupsWithOnInteract.set(data.group, { label: data.label });
		}
	}

	const checkGroupIds = new Set([
		...Array.from(groupsWithOnInteract.keys()),
		...Array.from(state.groupIdToVfx.keys()),
		...Array.from(state.dirtyBlockGroups),
	]);

	for (const groupId of checkGroupIds) {
		const group = world.blockGroups.groups.get(groupId);

		const existingVfx = state.groupIdToVfx.get(groupId);
		const needsVfx = groupsWithOnInteract.has(groupId) && group && group.blocks.size() > 0;

		const recreate =
			existingVfx &&
			needsVfx &&
			(existingVfx.label !== groupsWithOnInteract.get(groupId)!.label ||
				state.dirtyBlockGroups.has(groupId));

		if ((existingVfx && !needsVfx) || recreate) {
			for (const id of existingVfx.interactLabelIds) {
				GameWorldUI.remove(world.client!.gameWorldUI, id);
			}

			state.groupIdToVfx.delete(groupId);
		}

		if ((!existingVfx && needsVfx) || recreate) {
			const interactLabelIds: string[] = [];

			const label = groupsWithOnInteract.get(groupId)!.label;

			for (const island of group.islands) {
				const position = island.bounds.getCenter(new Vector3());

				const id = `${groupId}-${position.x}-${position.y}-${position.z}`;

				createInteractLabel(world, id, label, position), interactLabelIds.push(id);
			}

			state.groupIdToVfx.set(groupId, { label, interactLabelIds });
		}
	}

	state.reteLastReloadTime = world.rete.lastReloadTime;
	state.dirtyBlockGroups.clear();
};

const updateInRangeChunkPositions = (state: InteractLabelsClientState, currentChunk: Vector3) => {
	state.blockTypesChunksInRange.clear();

	for (let dx = -1; dx <= 1; dx++) {
		for (let dy = -1; dy <= 1; dy++) {
			for (let dz = -1; dz <= 1; dz++) {
				state.blockTypesChunksInRange.add(
					_chunkPosition.set(currentChunk.x + dx, currentChunk.y + dy, currentChunk.z + dz),
				);
			}
		}
	}
};

const destroyBlockTypeLabel = (state: InteractLabelsClientState, world: World, position: Vector3) => {
	const vfx = state.blockTypePositionToVfx.get(position);
	if (!vfx) return;

	GameWorldUI.remove(world.client!.gameWorldUI, vfx.interactLabelId);
	state.blockTypePositionToVfx.delete(position);
};

const createBlockTypeLabel = (
	state: InteractLabelsClientState,
	world: World,
	position: Vector3,
	label: string,
) => {
	const interactLabelId = `block-type-${position.x}-${position.y}-${position.z}`;
	createInteractLabel(world, interactLabelId, label, position);

	state.blockTypePositionToVfx.set(position, { interactLabelId });
};

const updateBlockTypePosition = (
	state: InteractLabelsClientState,
	world: World,
	shape: number,
	typeID: number,
	position: Vector3,
) => {
	const interactBlockTypes = world.blockTypeRegistry.interactBlockTypes.get(typeID);
	const hasVfx = state.blockTypePositionToVfx.has(position);

	const shouldHaveVfx = shape !== BLOCK_AIR && interactBlockTypes;

	if (shouldHaveVfx && !hasVfx) {
		createBlockTypeLabel(state, world, position, interactBlockTypes.label);
	} else if (!shouldHaveVfx && hasVfx) {
		destroyBlockTypeLabel(state, world, position);
	}
};

const UPDATE_BLOCK_TYPE_LABELS_THROTTLE_SECONDS = 1;

const updateBlockTypeLabels = (state: InteractLabelsClientState, world: World) => {
	const canUpdate = world.time - state.blockTypesLastUpdateTime > UPDATE_BLOCK_TYPE_LABELS_THROTTLE_SECONDS;
	if (!canUpdate) return;

	const camera = world.client!.camera;
	const cameraBlock = _cameraBlock.copy(camera.position).round();
	const currentChunk = world.scene.getChunkAt(cameraBlock.x, cameraBlock.y, cameraBlock.z);

	const cameraChunkChanged = !currentChunk.position.equals(state.blockTypesLastCameraChunk);
	const interactBlockTypesChanged =
		world.blockTypeRegistry.interactBlockTypesUpdateTime !== state.interactBlockTypesUpdateTime;

	updateInRangeChunkPositions(state, currentChunk.position);

	const needsUpdate =
		state.blockTypesDirtyPositions.size() > 0 || cameraChunkChanged || interactBlockTypesChanged;

	if (!needsUpdate) return;

	state.interactBlockTypesUpdateTime = world.blockTypeRegistry.interactBlockTypesUpdateTime;
	state.blockTypesLastUpdateTime = world.time;
	state.blockTypesLastCameraChunk.copy(currentChunk.position);

	const interactBlockTypes = world.blockTypeRegistry.interactBlockTypes;

	// set of block types to check - current and previous interact block types
	const blockTypesToUpdate = interactBlockTypesChanged
		? new Set<number>([...interactBlockTypes.keys(), ...state.interactBlockTypes])
		: state.interactBlockTypes;

	if (interactBlockTypesChanged) {
		state.interactBlockTypes = new Set<number>(interactBlockTypes.keys());
	}

	// check nearby chunks for block types with interact labels
	if (interactBlockTypesChanged || cameraChunkChanged || state.blockTypesDirtyPositions.size() > 20) {
		const chunkSize = world.scene.chunkSize;

		for (const _ of state.blockTypesChunksInRange.iterate(_chunkPosition)) {
			const chunk = world.scene.getChunk(_chunkPosition.x, _chunkPosition.y, _chunkPosition.z);

			if (
				!chunk ||
				!chunk.blockTypes ||
				!Array.from(chunk.blockTypes).some((type) => blockTypesToUpdate.has(type))
			) {
				continue;
			}

			const shapes = chunk.storage.shapes;
			const types = chunk.storage.types;

			if (!shapes || !types) continue;

			for (let ry = 0; ry < chunkSize; ry++) {
				for (let rz = 0; rz < chunkSize; rz++) {
					for (let rx = 0; rx < chunkSize; rx++) {
						const blockIndex = chunk.getIndex(rx, ry, rz);

						const wx = chunk.worldPositionOffset.x + rx;
						const wy = chunk.worldPositionOffset.y + ry;
						const wz = chunk.worldPositionOffset.z + rz;

						_position.set(wx, wy, wz);

						updateBlockTypePosition(
							state,
							world,
							shapes[blockIndex],
							types[blockIndex],
							_position,
						);
					}
				}
			}
		}
	} else {
		// check dirty positions only
		for (const _ of state.blockTypesDirtyPositions.iterate(_position)) {
			const shape = world.scene.getShape(_position.x, _position.y, _position.z);
			const type = world.scene.getTypeID(_position.x, _position.y, _position.z);

			updateBlockTypePosition(state, world, shape, type, _position);
		}
	}

	// clear dirty positions
	state.blockTypesDirtyPositions.clear();

	// destroy labels that are not in the surrounding chunks
	for (const _ of state.blockTypePositionToVfx.keys(_position)) {
		const chunk = world.scene.getChunkAt(_position.x, _position.y, _position.z);

		if (chunk && state.blockTypesChunksInRange.has(chunk.position)) {
			continue;
		}

		destroyBlockTypeLabel(state, world, _position);
	}
};

const updateGroupProximityVisibility = (state: InteractLabelsClientState, world: World, camera: Camera) => {
	for (const [id, vfx] of state.groupIdToVfx) {
		const group = world.blockGroups.groups.get(id);
		if (!group) continue;

		const bounds = group.bounds;
		bounds.getCenter(_center);
		bounds.getSize(_size);

		const halfDiagonal = _size.length() / 2;
		const distance = camera.position.distanceTo(_center);

		const visible = distance < halfDiagonal + VISIBLE_PROXIMITY;

		for (const id of vfx.interactLabelIds) {
			const label = GameWorldUI.get(world.client!.gameWorldUI, id);
			label.visible = visible;
		}
	}
};

const updateBlockTypeProximityVisibility = (
	state: InteractLabelsClientState,
	world: World,
	camera: Camera,
) => {
	for (const vfx of state.blockTypePositionToVfx.entries(_position)) {
		const distance = camera.position.distanceTo(_position);

		const visible = distance < 1.5 + VISIBLE_PROXIMITY;

		const label = GameWorldUI.get(world.client!.gameWorldUI, vfx.interactLabelId);

		label.visible = visible;
	}
};

const updateEntityProximityVisibility = (state: InteractLabelsClientState, world: World, camera: Camera) => {
	for (const [entityId, labelId] of state.entityIdToVfx.entries()) {
		const entity = world.getEntity(entityId);
		if (!entity) continue;

		const distance = camera.position.distanceTo(entity.position);

		const visible = distance < 1.5 + VISIBLE_PROXIMITY;

		const element = GameWorldUI.get(world.client!.gameWorldUI, labelId);
		element.visible = visible;
	}
};
