import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsCrouchTurnRight extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsCrouchTurnRight", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Turn_Crouch_Right`,
				new AnimationTrack(animMesh, `JMGO_${item}_Turn_Crouch_Right`, this),
			);
		});

		this.crouchTurnRightAction = this.trackMap.get("Spade_Turn_Crouch_Right");
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;

		if (currentItemAnimations) {
			this.crouchTurnRightAction = this.trackMap.get(`${currentItemAnimations}_Turn_Crouch_Right`);
		} else {
			this.crouchTurnRightAction = this.trackMap.get("Spade_Turn_Crouch_Right");
		}
		this.crouchTurnRightAction.action.reset();
		this.crouchTurnRightAction.action.loop = LoopOnce;
		this.crouchTurnRightAction.action.setEffectiveTimeScale(2.0);

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchTurnRightAction.action.play();
		this.startFadeIn(transitionData);
	}

	onStateOff() {}

	compute() {
		this.updateWeight();

		if (this.state === "off") {
			this.crouchTurnRightAction.action.setEffectiveWeight(0);

			return;
		}

		this.crouchTurnRightAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (
			this.crouchTurnRightAction.action.getRemainingTime() < this.transitionToIdleTime &&
			!this.inputState.isMoving
		) {
			outTransitionToState.ptr = map.get("WeaponsCrouchIdle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("WeaponsCrouchWalking");
			outTransitionToState.blendTime = this.transitionToWalkingTime;
			return true;
		}

		return false;
	}
}
