import { VY } from "base/util/math/Math";
import type { Projectile, ProjectileManager } from "base/world/ProjectileManager";
import type { World } from "base/world/World";
import { netState } from "router/Parallelogram";
import type { Scene } from "three";
import {
	BatchedMesh,
	BoxGeometry,
	BufferGeometry,
	Color,
	MathUtils,
	Matrix4,
	MeshBasicMaterial,
	Vector3,
} from "three";

const MAX_BULLET_TRAILS = 1000;
const TRAIL_MIN_SIZE = 2.5;

const _matrix = new Matrix4();
const _scale = new Vector3();
const _color = new Color();

type Trail = {
	instanceId: number;
	updatedThisFrame: boolean;
};

export type BulletTrails = {
	projectileToTrail: Map<Projectile, Trail>;
	geometry: BufferGeometry;
	material: MeshBasicMaterial;
	batchedMesh: BatchedMesh;
	batchedMeshGeometryId: number;
};

export const init = (scene: Scene): BulletTrails => {
	const geometry = new BoxGeometry(0.03, 0.03, 1);

	const material = new MeshBasicMaterial({
		color: "#fff",
	});

	const maxVertexCount = geometry.attributes.position.count;
	const maxIndexCount = geometry.index!.count;

	const batchedMesh = new BatchedMesh(MAX_BULLET_TRAILS, maxVertexCount, maxIndexCount, material);
	batchedMesh.frustumCulled = false;

	const batchedMeshGeometryId = batchedMesh.addGeometry(geometry);

	scene.add(batchedMesh);

	return {
		projectileToTrail: new Map(),
		geometry: new BufferGeometry(),
		material: new MeshBasicMaterial({
			color: 0xff0000,
			transparent: true,
			opacity: 0.5,
		}),
		batchedMesh,
		batchedMeshGeometryId,
	};
};

export const dispose = (bulletTrails: BulletTrails) => {
	bulletTrails.batchedMesh.removeFromParent();
	bulletTrails.batchedMesh.dispose();
};

export const update = (
	world: World,
	pm: ProjectileManager,
	bulletTrails: BulletTrails,
	_deltaTime: number,
) => {
	if (!netState.isClient) return;

	const { projectiles } = pm;

	for (const p of projectiles) {
		if (!p.def.bulletTrail) continue;

		const trail = bulletTrails.projectileToTrail.get(p);

		if (!trail) {
			onProjectileFire(bulletTrails, p);
		} else {
			updateTrail(bulletTrails, trail, p);
		}
	}

	for (const [projectile, trail] of bulletTrails.projectileToTrail.entries()) {
		if (!trail.updatedThisFrame) {
			onProjectileHit(bulletTrails, trail, projectile);
		} else {
			trail.updatedThisFrame = false;
		}
	}
};

const onProjectileFire = (bulletTrails: BulletTrails, projectile: Projectile) => {
	if (bulletTrails.projectileToTrail.size >= MAX_BULLET_TRAILS) {
		return;
	}

	const projectileColor = projectile.def.bulletTrailColor;
	const instanceId = bulletTrails.batchedMesh.addInstance(bulletTrails.batchedMeshGeometryId);
	bulletTrails.batchedMesh.setColorAt(instanceId, _color.set(projectileColor));

	const trail: Trail = {
		instanceId,
		updatedThisFrame: false,
	};

	bulletTrails.projectileToTrail.set(projectile, trail);

	updateTrail(bulletTrails, trail, projectile);
};

const updateTrail = (bulletTrails: BulletTrails, trail: Trail, projectile: Projectile): void => {
	trail.updatedThisFrame = true;

	// max distance is gun muzze dist, or distance to wall
	const frontToStartDist = projectile.scanFront.distanceTo(projectile.visStartPos);
	const backToEndDist = projectile.scanBack.distanceTo(projectile.endPos);
	const maxLength = Math.min(frontToStartDist, backToEndDist);
	// min distance is TRAIL_MIN_SIZE but never longer than max
	const minLength = Math.min(TRAIL_MIN_SIZE, maxLength);
	// scan length is the distance the game scanned this frame
	const scanLength = projectile.scanFront.distanceTo(projectile.scanBack);
	// visually, try scan-len*2, but stay in minmax
	const visualLength = MathUtils.clamp(scanLength, minLength, maxLength);

	// direction from start to end
	const visualPos = projectile.dir.clone();
	// multiplied by total length / 2
	visualPos.multiplyScalar(-visualLength / 2);
	// add to start
	visualPos.add(projectile.scanFront);

	const matrix = _matrix.identity();
	matrix.setPosition(visualPos);
	matrix.lookAt(projectile.scanFront, projectile.scanBack, VY);
	matrix.scale(_scale.set(1, 1, visualLength));

	bulletTrails.batchedMesh.setMatrixAt(trail.instanceId, matrix);
};

const onProjectileHit = (bulletTrails: BulletTrails, trail: Trail, projectile: Projectile): void => {
	bulletTrails.batchedMesh.deleteInstance(trail.instanceId);
	bulletTrails.projectileToTrail.delete(projectile);
};
