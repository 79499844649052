import { BB } from "base/BB";
import { UI } from "client/dom/UI";
import {
	DEGRAD,
	RADDEG,
	PI,
	wrapAngle,
	V0,
	VNZ,
	VX,
	VY,
	EPSILON,
	sInterpTo,
	aInterpTo,
} from "base/util/math/Math.ts";
import { NET_CLIENT } from "@jamango/ibs";
import {
	PerspectiveCamera,
	Vector2,
	Vector3,
	Euler,
	MathUtils,
	Matrix4,
	Quaternion,
	Frustum,
	Group,
	Spherical,
} from "three";
import { cancelDoubleTaps } from "client/world/InputManager";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as Physics from "base/world/Physics";

export const CAM_NEAR = 0.05;
export const CAM_FOV_DEFAULT = 67 * DEGRAD;
export const CAMERA_THETA_SPEED_TP = 0;
export const CAMERA_PHI_SPEED_TP = 0;
export const CAMERA_THETA_SPEED_FP = 0;
export const CAMERA_PHI_SPEED_FP = 0;

export const CameraPerspective = {
	FIRST_PERSON: "FIRST_PERSON",
	THIRD_PERSON_BACK: "THIRD_PERSON_BACK",
	THIRD_PERSON_FRONT: "THIRD_PERSON_FRONT",
};

const CAMERA_PERSPECTIVES = [
	CameraPerspective.FIRST_PERSON,
	CameraPerspective.THIRD_PERSON_BACK,
	CameraPerspective.THIRD_PERSON_FRONT,
];

const THIRD_PERSON_CAMERA_PERSPECTIVES = [
	CameraPerspective.THIRD_PERSON_BACK,
	CameraPerspective.THIRD_PERSON_FRONT,
];

const BACK_VIEWS = [CameraPerspective.THIRD_PERSON_BACK, CameraPerspective.FIRST_PERSON];
const FRONT_VIEW = CameraPerspective.THIRD_PERSON_FRONT;

const tmpVec1 = new Vector3();
const tmpVec3 = new Vector3();
const tmpVec4 = new Vector3();
const tmpMat = new Matrix4();
const tmpQuat1 = new Quaternion();
const tmpQuat2 = new Quaternion();
const tmpEuler = new Euler();

const _cameraBoxHalfExtents = new Vector3();
const _entityPosWithOffset = new Vector3();
const _boxcastDirection = new Vector3();

const _itemSwayMovement = new Vector2();

export class MouseLookCamera extends PerspectiveCamera {
	/** @type {import('base/world/entity/Entity').Entity} */
	target;

	#raycastResult = Physics.initRaycastResult();

	/**
	 * @param {import('base/world/World').World} world
	 * @param {import('base/world/entity/Entity').Entity} target
	 */
	constructor(world, target) {
		super();

		/**
		 * @type {import('base/world/World').World}
		 */
		this.world = world;

		this.near = CAM_NEAR;
		this.matrixAutoUpdate = false;
		this.sph = new Spherical(1, 0, 0);
		this.dst = 0;
		this.perspective = CameraPerspective.FIRST_PERSON;
		this.offset = new Vector3();
		this.frustum = new Frustum();

		this.dirPointer = 0;
		this.prvdx = 0;
		this.prvdy = 0;

		this.zoom = 1;

		// TODO: Add to Hub Settings
		this.inverted = false;

		this.swayGroup = new Group();
		this.swayMesh = null;
		this.add(this.swayGroup);

		//gun sway settings
		this.swayMaxDisplacement = 0.05; // max displacement that the item will sway
		this.swayMultiplier = 3; // multiplier to increase sway displacement
		this.slerpSpeed = 0.0001; // speed at which sway happens

		//good position and rotation without extra move
		this.steadyQuat = new Quaternion();
		this.steadyPos = new Vector3();
		this.tmpNormalizedOffset = new Vector3();

		this.targetQuat = new Quaternion();
		this.targetPos = new Vector3();
		this.targetTheta = 0;
		this.targetPhi = 0;
		this.targetResultLen = 0;
		this.resultLen = 1;

		// camera shake
		this.shake = new Vector3();
		this.shakeQuat = new Quaternion();
		this.shakeQuatTarget = new Quaternion();
		this.shakeRecovery = 0.92;

		// item sway decal
		this.itemSwayDecalOffset = new Vector3(0, 0, 0);

		world.scene.add(this.world.client.listener);

		// add to FP scene by default
		world.client.sceneFP.add(this);

		this.CAMERA_THETA_SPEED_FP = UI.state.settings().thetaCameraFP ?? CAMERA_THETA_SPEED_FP;
		this.CAMERA_PHI_SPEED_FP = UI.state.settings().phiCameraFP ?? CAMERA_PHI_SPEED_FP;
		this.CAMERA_THETA_SPEED_TP = UI.state.settings().thetaCameraTP ?? CAMERA_THETA_SPEED_TP;
		this.CAMERA_PHI_SPEED_TP = UI.state.settings().phiCameraTP ?? CAMERA_PHI_SPEED_TP;

		this.set(target);
	}

	set(target) {
		this.world.dispatcher.dispatchEvent(NET_CLIENT, {
			type: "cameratargetchange",
			entity: target,
		});

		cancelDoubleTaps(this.world);

		const prvItem =
			this.target?.hasLocalAuthority() && this.target.type.def.isCharacter
				? this.target.getEquippedItem()
				: null;
		const curItem =
			target.hasLocalAuthority() && target.type.def.isCharacter ? target.getEquippedItem() : null;

		const prvTarget = this.target;
		this.target = target;
		this.reset();

		const o = target.cameraSettings.def.camera;
		this.dst = o?.defaultDst ?? this.getMinDst();

		prvTarget?.onUntarget();
		target.onTarget(this);
		this.world.dispatcher.dispatchEvent(NET_CLIENT, {
			type: "itemchange",
			prvItem,
			curItem,
		});

		this.itemSwayDecalOffset.set(0, 0, 0);
	}

	//convenience wrapper around .set() to handle falling back to the current player if target ID doesn't exist
	//returns new target
	returnTo(targetID) {
		const fallback = this.world.client.getPeerPlayer();
		const requested = this.world.getEntity(targetID);

		if (!requested) {
			this.set(fallback);
			return fallback;
		} else {
			this.set(requested);
			return requested;
		}
	}

	reset() {
		this.sph.setFromVector3(
			tmpVec1.copy(VNZ).applyQuaternion(this.target.object3D.getWorldQuaternion(tmpQuat1)),
		).theta += PI;
		this.targetTheta = this.sph.theta;
		this.targetPhi = this.sph.phi;
	}

	//preupdate: rotation
	preUpdate(stepDeltaTime) {
		const o = this.target.cameraSettings.def.camera;
		const poll = BB.client.inputPoll;

		this.minPitch = (o?.minPitch ?? 0) + EPSILON;
		this.maxPitch = (o?.maxPitch ?? PI) - EPSILON;
		this.flingDeceleration = o?.flingDeceleration ?? 180;

		let dyaw = 0;
		let dpitch = 0;

		if (poll.isPointerLocked()) {
			let id = this.dirPointer;
			if (poll.isMobileBrowser() && !poll.isPointerDown(id)) {
				//attempt to find a new camera-dragging pointer

				id = poll.getNewPointer();
				if (id !== undefined) {
					this.prvdx = 0;
					this.prvdy = 0;
				}

				this.dirPointer = id;
			}

			let dx = poll.getPointerDX(id) ?? 0;
			let dy = poll.getPointerDY(id) ?? 0;
			const r = BB.client.renderer.getPixelRatio();
			const sensitivity = BB.client.settings.sensitivity;
			const d = this.flingDeceleration * r;

			if (dx || dy) {
				UI.state.helpers().markLookKnown();
			}

			//deceleration after lifting pointer
			if (poll.isMobileBrowser()) {
				if (dx === 0 && this.prvdx !== 0) {
					if (this.prvdx > 0) dx = Math.max(this.prvdx - sensitivity * d, 0);
					else dx = Math.min(this.prvdx + sensitivity * d, 0);
				}

				if (dy === 0 && this.prvdy !== 0) {
					if (this.prvdy > 0) dy = Math.max(this.prvdy - sensitivity * d, 0);
					else dy = Math.min(this.prvdy + sensitivity * d, 0);
				}

				this.prvdx = dx;
				this.prvdy = dy;
			}

			dyaw = (dx * sensitivity) / r;
			dpitch = (dy * sensitivity) / r;

			// invert pitch for 3rd person front perspective
			if (this.perspective === CameraPerspective.THIRD_PERSON_FRONT) {
				dpitch *= -1;
			}

			const inverted = this.inverted ? -1 : 1;
			dyaw *= inverted;
			dpitch *= inverted;

			this.targetTheta = wrapAngle(this.targetTheta - dyaw);
			this.targetPhi = MathUtils.clamp(this.targetPhi + dpitch, this.minPitch, this.maxPitch);

			let thetaInterpSpeed = o?.smoothing;
			if (thetaInterpSpeed === undefined) {
				thetaInterpSpeed = this.is1stPerson()
					? this.CAMERA_THETA_SPEED_FP
					: this.CAMERA_THETA_SPEED_TP;
			}

			let phiInterpSpeed = o?.smoothing;
			if (phiInterpSpeed === undefined) {
				phiInterpSpeed = this.is1stPerson() ? this.CAMERA_PHI_SPEED_FP : this.CAMERA_PHI_SPEED_TP;
			}

			this.sph.theta = aInterpTo(this.sph.theta, this.targetTheta, stepDeltaTime, thetaInterpSpeed);

			this.sph.phi = sInterpTo(this.sph.phi, this.targetPhi, stepDeltaTime, phiInterpSpeed);
		}

		this.sway(dyaw, dpitch, stepDeltaTime); // sway the swayMesh in the swayGroup for sway effect

		this.dst = MathUtils.clamp(this.dst, this.getMinDst(), this.getMaxDst());

		// update perspective based on distance, which may have changed from scrolling
		this.setCameraPerspectiveFromDistance(this.dst);

		let scaledDistance = this.dst;

		const distanceScale = this.target.cameraSettings.def?.distanceScale;
		if (distanceScale) {
			scaledDistance *= distanceScale;
		}

		if (this.perspective === CameraPerspective.THIRD_PERSON_FRONT) {
			// invert the distance for front view
			scaledDistance *= -1;
		}

		this.sph.radius = scaledDistance + EPSILON; //add epsilon because lookAt won't work with 0 radius
		this.matrix.lookAt(
			V0,
			tmpVec1.setFromSpherical(this.sph).negate().applyMatrix4(this.world.sphUpMatrix),
			this.up,
		);
		this.steadyPos.copy(tmpVec1).negate();
		this.steadyQuat.setFromRotationMatrix(this.matrix);
	}

	//postupdate: projection, position, shake, gap
	postUpdate(stepDeltaTime) {
		const o = this.target.cameraSettings.def.camera;
		this.fov = (o?.fov ?? CAM_FOV_DEFAULT) * RADDEG;
		this.zoom = o?.zoom ?? 1;
		this.offset
			.copy(this.target.cameraSettings.def.offset)
			.applyQuaternion(this.target.object3D.getWorldQuaternion(tmpQuat1));

		this.far = (BB.client.settings.renderDistance * this.world.scene.chunkSize) / 2;
		this.aspect = BB.client.canvas.width / BB.client.canvas.height;
		this.updateProjectionMatrix();

		const entityPos = tmpVec1.copy(this.target.object3D.position);
		const steadyPosDir = tmpVec3.copy(this.steadyPos);
		const steadyPosOrbitPoint = tmpVec4.copy(this.offset).add(entityPos);

		// shake camera on hurt
		this.makeCameraShake(stepDeltaTime);
		this.quaternion.copy(this.steadyQuat).multiply(this.shakeQuat);

		if (!this.is1stPerson()) {
			// Adjust camera to avoid clipping. Only for 3rd person
			// calculate near plane size
			// todo: how important is an accurate boxcast box shape?
			const cameraBoxHalfExtents = _cameraBoxHalfExtents;
			cameraBoxHalfExtents.x = Math.abs(Math.tan(this.fov / 2) * this.near);
			cameraBoxHalfExtents.y = cameraBoxHalfExtents.x * this.aspect;
			cameraBoxHalfExtents.z = 0.5;

			// HACK: add small vertical offset. otherwise in maximum angle when camera looks below
			// there is not enough preciosion and cast misses ground intersection (cast points start slightly below ground plane)
			const entityPosWithOffset = _entityPosWithOffset;
			entityPosWithOffset.copy(entityPos);
			entityPosWithOffset.y += 0.01;

			const boxcastDirection = _boxcastDirection.copy(steadyPosDir).normalize();
			const currentLen = steadyPosDir.length();

			// shapecast using the absolute radius as the distance - with the THIRD_PERSON_FRONT perspective the radius is negative
			const boxCastLength = Math.abs(this.sph.radius);

			// if collision found, adjust camera position
			const result = Physics.boxCast(
				this.world.physics,
				cameraBoxHalfExtents,
				steadyPosOrbitPoint,
				this.quaternion,
				boxcastDirection,
				boxCastLength,
				this.world.physics.noCharactersCollidableObjectLayerFilter,
				this.#raycastResult,
			);

			if (result.hit) {
				const distanceTravelled = result.hitPosition.distanceTo(steadyPosOrbitPoint);

				this.targetResultLen = distanceTravelled;
			} else {
				this.targetResultLen = Infinity;
			}

			if (this.targetResultLen < Infinity) {
				this.resultLen = sInterpTo(this.resultLen, this.targetResultLen, stepDeltaTime, 8);
			} else {
				this.resultLen = sInterpTo(this.resultLen, currentLen, stepDeltaTime, 10);
			}

			steadyPosDir.setLength(this.resultLen);
		}

		this.steadyPos.copy(steadyPosDir).add(steadyPosOrbitPoint);
		this.targetPos.copy(this.steadyPos);

		this.position.copy(this.steadyPos);

		//update frustum
		this.updateMatrix();
		this.updateMatrixWorld();
		this.frustum.setFromProjectionMatrix(
			tmpMat.multiplyMatrices(this.projectionMatrix, this.matrixWorldInverse),
		);

		for (const e of this.world.entities) {
			const dst = e.position.distanceTo(entityPos);
			const shouldBeRendered =
				dst > BB.client.settings.entityRenderDistance * (this.world.scene.chunkSize / 2);
			e.visible = !shouldBeRendered;
		}

		const listener = this.world.client.listener;
		listener.quaternion.copy(this.quaternion);

		if (this.is1stPerson()) {
			listener.position.copy(this.position);
		} else {
			listener.position.addVectors(
				this.position,
				tmpVec1.subVectors(this.target.object3D.position, this.position).multiplyScalar(2 / 3),
			);
		}
	}

	onGravityChange(up) {
		this.up.copy(up);
	}

	// called outside of camera.js
	makeShake(o) {
		if (!this.is1stPerson()) return;

		this.shake.copy(o.shake ?? V0);
		this.shakeRecovery = o?.shakeRecovery;
	}

	// called by camera update | trigger camera shake for both recoil and pain
	makeCameraShake(deltaTime) {
		if (this.shake.length() !== 0) {
			if (this.shake.length() < 0.1) {
				this.shake.set(0, 0, 0);
			}

			this.shakeQuatTarget.setFromEuler(
				tmpEuler.set(this.shake.x * DEGRAD, this.shake.y * DEGRAD, this.shake.z * DEGRAD, "XYZ"),
			);

			this.shake.multiplyScalar(Math.pow(this.shakeRecovery, deltaTime * 60));

			this.shakeQuat.slerp(this.shakeQuatTarget, 1 - Math.pow(this.shakeRecovery, deltaTime * 60));
		}
	}

	isSwayMesh(o3d) {
		return o3d?.parent === this.swayGroup;
	}

	setSwayMesh(o3d) {
		this.swayMesh = o3d;
		this.swayGroup.add(o3d);

		if (!this.world.client.getEquippedItem()?.itemType.def.isBlock) this.swayGroup.position.copy(V0);
	}

	sway(dyaw, dpitch, stepDeltaTime) {
		if (!this.isSwayMesh(this.swayMesh)) return;

		const itemSwayMovement = _itemSwayMovement.set(dyaw, dpitch);

		itemSwayMovement.set(dyaw, dpitch);

		const swayMaxDisplacement =
			this.target.cameraSettings.def?.itemSwayMaxDisplacement ?? this.swayMaxDisplacement;

		itemSwayMovement.clampLength(-swayMaxDisplacement, swayMaxDisplacement);

		itemSwayMovement.multiplyScalar(this.swayMultiplier);

		const swayRotX = tmpQuat1.setFromAxisAngle(VX, -itemSwayMovement.y);
		const swayRotY = tmpQuat2.setFromAxisAngle(VY, itemSwayMovement.x);
		const swayRot = swayRotX.multiply(swayRotY);

		this.swayGroup.quaternion.slerp(swayRot, 1 - Math.pow(this.slerpSpeed, stepDeltaTime));

		this.swayGroup.position.x = this.itemSwayDecalOffset.x;
		this.swayGroup.position.y = this.itemSwayDecalOffset.y;

		this.swayGroup.rotation.x = this.swayGroup.rotation.x + this.itemSwayDecalOffset.y * 0.2;
	}

	setCameraPerspectiveFromDistance(distance) {
		if (distance === 0 && this.perspective !== CameraPerspective.FIRST_PERSON) {
			this.setCameraPerspective(CameraPerspective.FIRST_PERSON);
		} else if (distance !== 0 && this.perspective === CameraPerspective.FIRST_PERSON) {
			this.setCameraPerspective(CameraPerspective.THIRD_PERSON_BACK);
		}
	}

	canSetCameraPerspective(perspective) {
		const minDst = this.getMinDst();
		const maxDst = this.getMaxDst();

		if (perspective === CameraPerspective.FIRST_PERSON && minDst !== 0) {
			return false;
		}

		if (THIRD_PERSON_CAMERA_PERSPECTIVES.includes(perspective) && maxDst === 0) {
			return false;
		}

		return true;
	}

	setCameraPerspective(perspective) {
		if (!this.canSetCameraPerspective(perspective)) {
			return;
		}

		const oldPerspective = this.perspective;
		this.perspective = perspective;

		const isSwitchingBetweenBackAndFront =
			(BACK_VIEWS.includes(oldPerspective) && perspective === FRONT_VIEW) ||
			(oldPerspective === FRONT_VIEW && BACK_VIEWS.includes(perspective));

		if (isSwitchingBetweenBackAndFront) {
			// mirror pitch
			this.targetPhi = Math.PI - this.targetPhi;
			this.sph.phi = Math.PI - this.sph.phi;
		}

		if (perspective === CameraPerspective.FIRST_PERSON) {
			this.dst = this.getMinDst();
		} else if (THIRD_PERSON_CAMERA_PERSPECTIVES.includes(perspective) && this.dst === 0) {
			this.dst = this.getMaxDst();
		}
	}

	toggleCameraPerspective() {
		const currentPerspectiveIndex = CAMERA_PERSPECTIVES.indexOf(this.perspective);
		let cameraPerspective;
		// cycle through perspectives until we find one that can be set
		for (let i = 0; i < CAMERA_PERSPECTIVES.length - 1; i++) {
			const newPerspectiveIndex = (currentPerspectiveIndex + i + 1) % CAMERA_PERSPECTIVES.length;

			if (this.canSetCameraPerspective(CAMERA_PERSPECTIVES[newPerspectiveIndex])) {
				cameraPerspective = CAMERA_PERSPECTIVES[newPerspectiveIndex];
				this.setCameraPerspective(cameraPerspective);
				break;
			}
		}

		return cameraPerspective;
	}

	is1stPerson() {
		return this.perspective === CameraPerspective.FIRST_PERSON;
	}

	getMinDst() {
		if (getPeerMetadata(this.world.client.loopbackPeer).permissions.cameraPOV === "third")
			return this.getMaxDst();

		return this.target.cameraSettings.def.camera?.minDst ?? 0;
	}

	getMaxDst() {
		if (getPeerMetadata(this.world.client.loopbackPeer).permissions.cameraPOV === "first")
			return this.getMinDst();

		return this.target.cameraSettings.def.camera?.maxDst ?? Infinity;
	}

	getDirection(out, steady) {
		return out.copy(VNZ).applyQuaternion(steady ? this.steadyQuat : this.quaternion);
	}

	getRight(out, steady) {
		return this.getDirection(out, steady).cross(this.up).normalize();
	}

	//same as direction vector, but without spherical's phi
	getForward(out, steady) {
		return this.getRight(out, steady).cross(this.up).normalize().negate();
	}

	//returns orthonormal up, not this.up
	getUp(out, steady) {
		return this.getRight(out, steady).cross(this.getDirection(tmpVec1, steady));
	}

	dispose() {
		this.removeFromParent();
	}
}
