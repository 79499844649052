import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { Quaternion } from "three";

const _quaternion = new Quaternion();
const _quaternionA = new Quaternion();
const _quaternionB = new Quaternion();

export const QUATERNION_NODES = [
	node({
		id: "c1b5eb70-0bd3-4f9d-8be9-3121798fb40b",
		name: "Quaternion Identity",
		type: NODE_TYPE_ID.quaternion,
		description: "Returns a identity quaternion (0, 0, 0, 1)",
		predictable: true,
		outputs: {
			quaternion: { name: "Identity Quaternion", type: "quaternion" },
		},
		resolve(_inputs) {
			return {
				quaternion: new Quaternion().identity(),
			};
		},
	}),
	node({
		id: "4bc43d7b-29a8-4d68-beff-ba76e4b25efc",
		name: "Quaternion Angle To",
		type: NODE_TYPE_ID.quaternion,
		description: "Returns the angle between two quaternions in radians",
		predictable: true,
		inputs: {
			a: {
				name: "A",
				type: "quaternion",
				control: "quaternion",
			},
			b: {
				name: "B",
				type: "quaternion",
				control: "quaternion",
			},
		},
		outputs: {
			angle: { name: "Angle", type: "number" },
		},
		resolve(inputs) {
			_quaternionA.copy(inputs.a);
			_quaternionB.copy(inputs.b);

			return {
				angle: _quaternionA.angleTo(_quaternionB),
			};
		},
	}),
	node({
		id: "4bc43d7b-29a8-4d68-beff-ba76e4b25efc",
		name: "Quaternion Invert",
		type: NODE_TYPE_ID.quaternion,
		description:
			"Returns the inverted quaternion that faces in the opposite direction. Calculaes the rotational conjugate of the quaternion, which quaternion represents the same rotation in the opposite direction about the rotational axis.",
		predictable: true,
		inputs: {
			quaternion: {
				name: "Quaternion",
				type: "quaternion",
				control: "quaternion",
			},
		},
		outputs: {
			result: {
				name: "Result",
				type: "quaternion",
			},
		},
		resolve(inputs) {
			return {
				result: _quaternion.copy(inputs.quaternion).conjugate().clone(),
			};
		},
	}),
	node({
		id: "bc48d753-5bf0-45d1-bd73-5f6ce933ab82",
		name: "Quaternion Dot",
		type: NODE_TYPE_ID.vector3,
		description: "Returns the dot product of two quaternions",
		predictable: true,
		inputs: {
			a: { name: "A", type: "quaternion", control: "quaternion" },
			b: { name: "B", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Dot Product", type: "number" },
		},
		resolve(inputs) {
			const result = new Quaternion().copy(inputs.a).dot(_quaternionB.copy(inputs.b));
			return { result };
		},
	}),
	node({
		id: "b9e43d0a-65cf-48c0-9fd9-c63780166606",
		name: "Quaternion Length",
		type: NODE_TYPE_ID.quaternion,
		description:
			"Returns the length of a quaternion, the Euclidean length (straight-line length) of this quaternion, considered as a 4 dimensional vector.",
		predictable: true,
		inputs: {
			quaternion: { name: "Quaternion", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Length", type: "number" },
		},
		resolve(inputs) {
			const result = _quaternion.copy(inputs.quaternion).length();
			return { result };
		},
	}),
	node({
		id: "d56e6bc9-e78c-47a9-977b-7f8a6a471005",
		name: "Quaternion Length Squared",
		type: NODE_TYPE_ID.quaternion,
		description: "Returns the squared length of a quaternion.",
		predictable: true,
		inputs: {
			quaternion: { name: "Quaternion", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Length Squared", type: "number" },
		},
		resolve(inputs) {
			const result = _quaternion.copy(inputs.quaternion).lengthSq();
			return { result };
		},
	}),
	node({
		id: "79fedb1b-3d38-4a75-ba1b-8089f90c6b47",
		name: "Quaternion Normalize",
		type: NODE_TYPE_ID.quaternion,
		description: "Normalizes a quaternion.",
		predictable: true,
		inputs: {
			quaternion: { name: "Quaternion", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Normalized Quaternion", type: "quaternion" },
		},
		resolve(inputs) {
			const result = _quaternion.copy(inputs.quaternion).normalize().clone();
			return { result };
		},
	}),
	node({
		id: "28e0e3ab-60d1-4530-940c-c7fae2fe90e9",
		name: "Quaternion Multiply",
		type: NODE_TYPE_ID.quaternion,
		description: "Multiplies two quaternions.",
		predictable: true,
		inputs: {
			a: { name: "A", type: "quaternion", control: "quaternion" },
			b: { name: "B", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Result", type: "quaternion" },
		},
		resolve(inputs) {
			const result = _quaternionA.copy(inputs.a).multiply(inputs.b).clone();
			return { result };
		},
	}),
	node({
		id: "9ad24a62-42e0-4853-8128-48230387ee8f",
		name: "Quaternion Premultiply",
		type: NODE_TYPE_ID.quaternion,
		description: "Premultiplies two quaternions.",
		predictable: true,
		inputs: {
			a: { name: "A", type: "quaternion", control: "quaternion" },
			b: { name: "B", type: "quaternion", control: "quaternion" },
		},
		outputs: {
			result: { name: "Result", type: "quaternion" },
		},
		resolve(inputs) {
			const result = _quaternionA.copy(inputs.a).premultiply(inputs.b).clone();
			return { result };
		},
	}),
	node({
		id: "69f91a7c-1975-485a-b211-cdd833a413d0",
		name: "Quaternion Rotate Towards",
		type: NODE_TYPE_ID.quaternion,
		description: "Rotates a quaternion towards another quaternion.",
		predictable: true,
		inputs: {
			a: { name: "A", type: "quaternion", control: "quaternion" },
			b: { name: "B", type: "quaternion", control: "quaternion" },
			step: { name: "Step", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "quaternion" },
		},
		resolve(inputs) {
			const result = _quaternionA.copy(inputs.a).rotateTowards(inputs.b, inputs.step).clone();
			return { result };
		},
	}),
	node({
		id: "55da2f46-588f-47f7-a8d6-ef7a242512f2",
		name: "Quaternion Slerp",
		type: NODE_TYPE_ID.quaternion,
		description: "Spherically interpolates between two quaternions.",
		predictable: true,
		inputs: {
			a: { name: "A", type: "quaternion", control: "quaternion" },
			b: { name: "B", type: "quaternion", control: "quaternion" },
			t: { name: "T", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "quaternion" },
		},
		resolve(inputs) {
			const result = new Quaternion().slerpQuaternions(inputs.a, inputs.b, inputs.t);
			return { result };
		},
	}),
	node({
		id: "2555159b-cc37-4850-aa9c-5a7e795b9ffe",
		name: "Quaternion From Axis Angle",
		type: NODE_TYPE_ID.quaternion,
		description: "Creates a quaternion from an axis and an angle.",
		predictable: true,
		inputs: {
			axis: { name: "Axis", type: "vector3", control: "vector3" },
			angle: { name: "Angle", type: "number", control: "number" },
		},
		outputs: {
			result: { name: "Result", type: "quaternion" },
		},
		resolve(inputs) {
			const result = new Quaternion().setFromAxisAngle(inputs.axis, inputs.angle);
			return { result };
		},
	}),
];
