export class ItemTweenAnimationComponent {
	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.name = "tweenAnimation";

		this.def = {
			idleTransform: o.idleTransform,
			idleDuration: o.idleDuration ?? 0,
			recoilTransform: o.recoilTransform,
			recoilDuration: o.recoilDuration ?? 0,
		};
	}
}
