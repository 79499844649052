import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";
import {
	LOCOMOTION_MOVEMENTINPUT_LEFT,
	LOCOMOTION_MOVEMENTINPUT_RIGHT,
	LOCOMOTION_MOVEMENTINPUT_FORWARD,
	LOCOMOTION_MOVEMENTINPUT_BACKWARD,
} from "base/world/entity/component/CharacterLocomotionInput";

export class LocomotionStateWeaponsJogging extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsJogging", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.directionsBlendTime = 0.1;
		this.transitionToFallingTime = 0.2;
		this.transitionToJumpingTime = 0.1;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.forwardWeight = 1.0;
		this.backwardWeight = 0.0;
		this.rightWeight = 0.0;
		this.leftWeight = 0.0;

		this.trackMap = new Map();

		const movement = "Jog";

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Forward`,
				new AnimationTrack(animMesh, `JMGO_${item}_${movement}_Forward`, this),
			);
			this.trackMap.set(
				`${item}_Backward`,
				new AnimationTrack(animMesh, `JMGO_${item}_${movement}_Backward`, this),
			);
			this.trackMap.set(
				`${item}_Left`,
				new AnimationTrack(animMesh, `JMGO_${item}_${movement}_Left`, this),
			);
			this.trackMap.set(
				`${item}_Right`,
				new AnimationTrack(animMesh, `JMGO_${item}_${movement}_Right`, this),
			);
		});

		this.forwardTrack = this.trackMap.get(`Spade_Forward`);
		this.backwardTrack = this.trackMap.get(`Spade_Backward`);
		this.leftTrack = this.trackMap.get(`Spade_Left`);
		this.rightTrack = this.trackMap.get(`Spade_Right`);
		this.lastTrack = this.forwardTrack;
		this.trackToSyncWith = this.lastTrack;
	}

	startCompute(transitionData) {
		this.startTime = this.mesh.totalTime;
		const currentItem = this.inputState.item;
		this.previousItem = transitionData.prvState.inputState.item;

		if (currentItem) {
			this.forwardTrack = this.trackMap.get(`${currentItem}_Forward`);
			this.backwardTrack = this.trackMap.get(`${currentItem}_Backward`);
			this.leftTrack = this.trackMap.get(`${currentItem}_Left`);
			this.rightTrack = this.trackMap.get(`${currentItem}_Right`);
		} else {
			this.forwardTrack = this.trackMap.get(`Spade_Forward`);
			this.backwardTrack = this.trackMap.get(`Spade_Backward`);
			this.leftTrack = this.trackMap.get(`Spade_Left`);
			this.rightTrack = this.trackMap.get(`Spade_Right`);
		}

		this.forwardTrack.action.reset();
		this.backwardTrack.action.reset();
		this.rightTrack.action.reset();
		this.leftTrack.action.reset();

		if (transitionData.prvState) {
			const idleAction = this.mesh.getAction("JMGO_Unarmed_Idle");
			this.forwardTrack.action.syncWith(idleAction);
			this.rightTrack.action.syncWith(idleAction);
			this.leftTrack.action.syncWith(idleAction);
			this.backwardTrack.action.syncWith(idleAction);

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.forwardTrack.action.play();
		this.backwardTrack.action.play();
		this.leftTrack.action.play();
		this.rightTrack.action.play();

		this.startFadeIn(transitionData);

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_FORWARD;
			this.forwardTrack.state = "on";
			this.forwardTrack.currentWeight = 1;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.lastTrack = this.forwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_BACKWARD;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "on";
			this.backwardTrack.currentWeight = 1;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "off";
			this.rightTrack.currentWeight = 0;
			this.lastTrack = this.backwardTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
			const weightExceptLeft =
				this.forwardTrack.currentWeight +
				this.rightTrack.currentWeight +
				this.backwardTrack.currentWeight;
			this.leftTrack.currentWeight = 1 - weightExceptLeft;
			this.leftTrack.startFadeIn(this.directionsBlendTime);
			this.leftTrack.isLeft = true;
			this.rightTrack.startFadeOut(this.directionsBlendTime);
			this.forwardTrack.startFadeOut(this.directionsBlendTime);
			this.backwardTrack.startFadeOut(this.directionsBlendTime);

			this.lastTrack = this.leftTrack;
		}

		if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
			this.prvMovementInput = LOCOMOTION_MOVEMENTINPUT_RIGHT;
			this.forwardTrack.state = "off";
			this.forwardTrack.currentWeight = 0;
			this.backwardTrack.state = "off";
			this.backwardTrack.currentWeight = 0;
			this.leftTrack.state = "off";
			this.leftTrack.currentWeight = 0;
			this.rightTrack.state = "on";
			this.rightTrack.currentWeight = 1;
			this.lastTrack = this.rightTrack;
		}

		this.updateWeightsInternal();
	}

	onStateOff() {
		super.onStateOff();

		this.trackMap.forEach((track) => {
			track.action.setEffectiveWeight(0);
		});
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.updateWeightsInternal();

		this.prvMovementInput = this.inputState.movementInput;
		this.previousItem = this.inputState.item;
	}

	shouldTransitionCustom(outTransitionToState, map) {
		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = 0.2;
			return true;
		}

		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("WeaponsCrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (!this.inputState.isMoving) {
			if (!this.inputState.item) {
				outTransitionToState.ptr = map.get("Idle");
				outTransitionToState.blendTime = this.transitionToIdleTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsIdle");
				outTransitionToState.blendTime = this.transitionToIdleTime;
			}
			return true;
		}

		if (!this.inputState.item) {
			outTransitionToState.ptr = map.get("Jogging");
			outTransitionToState.blendTime = 0.2;
			return true;
		}

		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("WeaponsRunning");
				outTransitionToState.blendTime = this.transitionToRunningTime;
				return true;
			} else {
				return false;
			}
		}

		return false;
	}

	updateBlendPoses() {
		this.forwardTrack.update();
		this.backwardTrack.update();
		this.leftTrack.update();
		this.rightTrack.update();
	}

	updateWeightsInternal() {
		if (this.inputState.item) {
			if (this.previousItem !== this.inputState.item) {
				const currentItemAnimations = this.inputState.item;
				this.previousItem = currentItemAnimations;

				this.forwardTrack.action.fadeOut(0.2);
				this.backwardTrack.action.fadeOut(0.2);
				this.leftTrack.action.fadeOut(0.2);
				this.rightTrack.action.fadeOut(0.2);

				if (currentItemAnimations) {
					this.forwardTrack = this.trackMap.get(`${currentItemAnimations}_Forward`);
					this.backwardTrack = this.trackMap.get(`${currentItemAnimations}_Backward`);
					this.leftTrack = this.trackMap.get(`${currentItemAnimations}_Left`);
					this.rightTrack = this.trackMap.get(`${currentItemAnimations}_Right`);
				} else {
					this.forwardTrack = this.trackMap.get(`Spade_Forward`);
					this.backwardTrack = this.trackMap.get(`Spade_Backward`);
					this.leftTrack = this.trackMap.get(`Spade_Left`);
					this.rightTrack = this.trackMap.get(`Spade_Right`);
				}

				this.trackToSyncWith = this.lastTrack;

				if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
					this.lastTrack = this.forwardTrack;
				} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
					this.lastTrack = this.backwardTrack;
				} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
					this.lastTrack = this.leftTrack;
				} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
					this.lastTrack = this.rightTrack;
				}

				if (this.lastTrack) {
					this.lastTrack.action.reset();

					this.forwardTrack.action.syncWith(this.trackToSyncWith.action);
					this.backwardTrack.action.syncWith(this.trackToSyncWith.action);
					this.leftTrack.action.syncWith(this.trackToSyncWith.action);
					this.rightTrack.action.syncWith(this.trackToSyncWith.action);

					this.lastTrack.action.play();
					this.lastTrack.startFadeIn(0.2);
				}
			}

			this.lastTrack.update();
		}

		if (
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT ||
			this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT
		) {
			if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_FORWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_FORWARD &&
					this.lastTrack !== this.forwardTrack
				) {
					this.forwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.forwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_BACKWARD) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_BACKWARD &&
					this.lastTrack !== this.backwardTrack
				) {
					this.backwardTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.backwardTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_LEFT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_LEFT &&
					this.lastTrack !== this.leftTrack
				) {
					this.leftTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.leftTrack;
			} else if (this.inputState.movementInput === LOCOMOTION_MOVEMENTINPUT_RIGHT) {
				if (
					this.prvMovementInput !== LOCOMOTION_MOVEMENTINPUT_RIGHT &&
					this.lastTrack !== this.rightTrack
				) {
					this.rightTrack.startFadeIn(this.directionsBlendTime);
					if (this.lastTrack) this.lastTrack.startFadeOut(this.directionsBlendTime);
				}

				this.lastTrack = this.rightTrack;
			}
			this.updateBlendPoses();

			this.forwardTrack.action.setEffectiveWeight(this.forwardTrack.currentWeight * this.weight);
			this.backwardTrack.action.setEffectiveWeight(this.backwardTrack.currentWeight * this.weight);
			this.leftTrack.action.setEffectiveWeight(this.leftTrack.currentWeight * this.weight);
			this.rightTrack.action.setEffectiveWeight(this.rightTrack.currentWeight * this.weight);
		} else {
			this.forwardTrack.action.setEffectiveWeight(this.forwardTrack.currentWeight * this.weight);
			this.backwardTrack.action.setEffectiveWeight(this.backwardTrack.currentWeight * this.weight);
			this.leftTrack.action.setEffectiveWeight(this.leftTrack.currentWeight * this.weight);
			this.rightTrack.action.setEffectiveWeight(this.rightTrack.currentWeight * this.weight);
		}
	}
}
