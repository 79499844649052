//dev-legible internal names for nodes
export const NODE = {
	//triggers
	OnGameStart: "8223e04e-89a3-418a-9830-3d8b1f25b237",
	OnGameTick: "9aa1181a-a303-4007-8e33-54b10654e0e6",
	OnWorldStateChange: "0007-00-0001",
	OnEntityStateChange: "0007-00-0005",
	OnPeerPersistentStateChange: "8610b88a-6d8d-489d-9ca8-ca76b0db9f69",
	OnChatSent: "0007-00-0006",
	OnControlPress: "0007-10-0001",
	OnControlRelease: "0007-10-0003",
	OnCharacterRespawn: "0007-00-0004",
	OnPeerJoin: "0007-00-0002",
	OnPeerLeave: "0007-00-0003",
	OnEntityKilled: "0001-00-0002",
	OnEntityDeath: "0001-00-0003",
	OnEntityProximityEnter: "0001-00-0004",
	OnEntityProximityLeave: "0001-00-0005",
	OnInteractWithEntity: "0001-00-0006",
	OnInteractWithBlock: "0000-00-0001",
	OnBlockSculpt: "0000-00-0002",
	OnBlockBreak: "0000-00-0003",
	OnBlockBuild: "0000-00-0004",
	OnBlockSpawn: "0000-00-0005",
	OnAllPlayerFeetEnter: "0000-00-0006",
	OnEntityCollisionStart: "d1411031-29d6-44d7-abc9-bf37b238c61b",
	OnEntityCollisionPersisted: "4d224f87-fa98-4278-aa82-41b7f706c5aa",
	OnEntityCollisionStop: "464e54a8-cf92-4bee-aaa5-c4f3d542b3c2",
	OnBlockCollisionStart: "0000-00-0008",
	OnBlockCollisionEnd: "0000-00-0007",
	OnBlockZoneEnter: "cf10f6a8-b5a8-4827-bc23-5f5f74efe810",
	OnBlockZoneLeave: "1a8bc8fe-5b45-43b8-8980-3e8fb558d24c",
	OnCharacterEquipItem: "2e724f22-cac3-49b2-815c-c1a0c11f9852",
	OnEntityDamaged: "cee68a1b-9ebf-46ce-99db-eac490d75ff0",
	OnProjectileHitBlock: "5487771b-bb88-48aa-a9e6-161945e122ea",
	OnProjectileHitEntity: "2da6456d-594e-4450-96a8-ce975d1131de",
	OnSoundEnd: "0002-08-0006",
	OnItemUse: "88181622-c94f-4786-85f1-e89f1710caca",
	OnItemUnuse: "22d71e11-aca9-46f4-be32-343ca8fbb9b9",
	OnItemRangedWeaponFire: "5b54250d-017c-47f5-8617-0b98fb04e039",

	GetCharacter: "3ac48c82-3d7c-43f6-8971-c7be6ca263e4",
	GetProp: "d160e19f-a9db-40a6-b33e-e400c8e81fa2",

	//special
	Controls: "2af8d334-6e46-4d81-b7c8-889f01adddbf",
	Start: "e75eb0b2-cdd2-47d9-a7b1-d962762247af",
	End: "50b6ebdd-e461-44d9-9c59-2dfe3b717434",
	NPCSpawn: "0100-00-0001",
} as const;
