import { Group, Vector3 } from "three";
import { V0 } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";

export class MountComponent {
	/**
	 * 	@type {{points:Array<{mount:import("three").Group,originalMount:import("three").Group,dismount:import("three").Vector3,entity:import("base/world/entity/Entity").Entity|null}>}}
	 */
	def = {
		points: [],
	};

	/**
	 * @type {{parent:import("base/world/entity/Entity").Entity|null,index:number}}
	 */
	state = {
		parent: null,
		index: 0,
	};

	/**
	 * Creates a MountComponent.
	 * @param {{mountPoints?: Array<{mount: import("three").Vector3, dismount?: import("three").Vector3}>}} o - Component options.
	 * @param {import("base/world/entity/Entity").Entity} entity - Entity to attach the component to.
	 */
	constructor(o, entity) {
		this.name = "mount";

		if (!isNullish(o?.mountPoints)) {
			for (const mountPoint of o.mountPoints) {
				const mount = new Group();
				mount.position.copy(mountPoint.mount);
				entity.object3D.add(mount);

				this.def.points.push({
					mount,
					originalMount: mount, // when overriding, reset mount.mount or dismount breaks
					dismount: new Vector3().copy(mountPoint?.dismount ?? V0),
					entity: null,
				});
			}
		}
	}
}
