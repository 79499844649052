import { getWorldRole, getUserAvatar, ws } from "rest-client";
import { DEBUG_ENABLED, LONG_HASH } from "@jamango/generated";
import { createLogger } from "@jamango/helpers";
import { BB } from "base/BB";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import * as trigger from "base/rete/modules/trigger";
import { UI } from "client/dom/UI";
import * as ChatRouter from "router/dom/Chat";
import { broadcastPrivateChat } from "server/dom/Chat";
import * as BlockGroups from "base/world/block/BlockGroups.ts";
import * as SceneTree from "base/world/SceneTree.ts";
import * as PersistentDataServer from "server/world/persistent-data/PersistentData";
import { setPeerMetadata, getPeerMetadata } from "base/util/PeerMetadata";
import {
	IBSClient,
	IBSServer,
	NET_AWAIT,
	NET_CLIENT,
	NET_OFFLINE,
	NET_SERVER,
	NET_TURN_OFF,
	NET_TURN_ON,
	SOCKET_BEHAVIOR_IGNORE,
	SOCKET_BEHAVIOR_LOG,
	SOCKET_BEHAVIOR_WAIT,
} from "@jamango/ibs";
import { netState } from "router/Parallelogram";
import { WorldUserRole } from "@jamango/content-client";
import * as Net from "router/Net";
import * as WorldEditor from "router/world/WorldEditor";

const logger = createLogger("Multiplayer");

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

/**
 * Called when hosting and a client leaves
 * @typedef {(peer: import('@jamango/ibs').Peer, worldId: string) => void} OnPeerLeave
 */
/**
 * Called when hosting and a client joins
 * @typedef {(peer: import('@jamango/ibs').Peer, worldId: string) => void} OnPeerJoin
 */

/**
 * @typedef {{
 *   onPeerMultiplayerAuth: OnPeerMultiplayerAuthCallback | undefined,
 *   onPeerLeave: OnPeerLeave | undefined,
 *   onPeerJoin: OnPeerJoin | undefined,
 *   worldId: string | undefined,
 * }} ServerMultiplayerState
 */

/**
 * @typedef {{
 *   onClientMultiplayerAuth: OnClientMultiplayerAuthCallback | undefined,
 *   onClientLeave: OnClientLeaveCallback | undefined,
 *   worldId: string | undefined,
 * }} ServerMultiplayerState
 */

/**
 * @returns {ServerMultiplayerState}
 */
export const initServer = () => {
	return {
		onPeerMultiplayerAuth: undefined,
		onPeerLeave: undefined,
		onPeerJoin: undefined,
		worldShortCode: undefined,
	};
};

const beginConnection = (o) => {
	BB.router.stopUpdateLoop();
	LoadingScreen.show(true);
	LoadingScreen.setText("Multiplayer magic");

	const ret = {
		...o,
		version: LONG_HASH,
		iceServers: [
			{
				urls: ["turn:master.jamango.io:3478"],
				username: "turnuser",
				credential: "turnpass",
			},
		],
		channels: {
			// volatile: {
			// 	ordered: false,
			// 	maxPacketLifeTime: 1500 * BB_DEDI_INTERVAL,
			// },
			// chunk: { ordered: false },
		},

		//turnMode: NET_TURN_ONLY, //for testing purposes only
		turnMode: netState.isClient ? NET_TURN_ON : NET_TURN_OFF,

		socketSettings: {
			batching: true,
			unknownCommandBehavior: o.mode === NET_SERVER ? SOCKET_BEHAVIOR_LOG : SOCKET_BEHAVIOR_IGNORE,
		},

		//"this" refers to ibs object
		async onfail({ type, error }) {
			const ibs = Net.impl.ibs;

			const disconnectedDuringGameplay = this.mode === NET_CLIENT && BB.world?.router.initComplete;

			if (disconnectedDuringGameplay) {
				BB.router.endWorld();
			}

			let errorMessage;
			if (type === "master") {
				errorMessage = error ?? "Couldn't connect to master server";
			} else if (type === "auth") {
				errorMessage = error ?? "Multiplayer authentication failed";
			} else if (disconnectedDuringGameplay) {
				errorMessage = "Lost connection to game server";
			} else {
				errorMessage = "Couldn't connect to game server";
			}

			let retry;
			if (netState.isClient) {
				retry = await UI.state.confirmPrompt().confirmAsync(`${errorMessage}. Try again?`);
			} else {
				logger.error(errorMessage);
			}

			if (retry) {
				if (o.mode === NET_SERVER) {
					await UI.gameMultiplayer.hostWorld({ restartUpdateLoop: true });
				} else if (o.mode === NET_CLIENT) {
					await UI.gameMultiplayer.joinWorld(o.host);
				} else {
					Multiplayer.matchmake(o.worldShortCode);
				}
			} else if (
				BB.world &&
				(o.mode === "matchmaker" || (o.mode === NET_CLIENT && ibs.mode !== NET_OFFLINE))
			) {
				// resume autohost if it became offline
				if (netState.isHost && (!ibs || ibs.mode !== NET_SERVER)) {
					await UI.gameMultiplayer.hostWorld(); //this will not throw an error
				}

				// resume previous world
				BB.router.startUpdateLoop();
			} else if (o.mode !== NET_SERVER) {
				UI.gameLifecycle.onFailLoadWorld();
			}
		},
	};

	return ret;
};

export class Multiplayer {
	/**
	 * @param {{
	 *   serverID: string,
	 *   worldShortCode?: string,
	 *   dedicated: boolean,
	 *   maxPeers?: number,
	 * 	 username?: string,
	 *   worldVersionID?: string,
	 *   restartUpdateLoop: boolean,
	 * 	 bundle: import('@jamango/content-client').IWorldBundle,
	 *   onPeerMultiplayerAuth?: OnPeerMultiplayerAuthCallback,
	 *   onPeerLeave?: OnPeerLeave,
	 *   onPeerJoin?: onPeerJoin,
	 * }} param0
	 * @returns
	 */
	static async host({
		serverID,
		worldShortCode,
		dedicated,
		username,
		worldVersionID,
		maxPeers,
		restartUpdateLoop,
		bundle,
		onPeerMultiplayerAuth,
		onPeerLeave,
		onPeerJoin,
	}) {
		BB.world.server.multiplayer.worldId = bundle.id;

		BB.world.server.multiplayer.onPeerMultiplayerAuth = onPeerMultiplayerAuth;
		BB.world.server.multiplayer.onPeerLeave = onPeerLeave;
		BB.world.server.multiplayer.onPeerJoin = onPeerJoin;

		let ibs;

		try {
			const serverOptions = await beginConnection({
				mode: NET_SERVER,
				serverID,
				maxPeers,
				worldShortCode,
				worldVersionID,
				username,
				dedicated,

				onmaster(connected) {
					if (netState.isClient) {
						UI.state.multiplayer().setStatus(connected ? null : "Master server disconnected");
					} else {
						if (connected) {
							logger.info("Master server reconnected");
						} else {
							logger.info("Master server disconnected");
						}
					}
				},
			});

			ibs = new IBSServer(serverOptions);
			await ibs.init();

			Net.setImpl(ibs);

			Multiplayer.initHostCommandListeners(worldShortCode);
			Multiplayer.initCommandListeners();
			BB.world.router.onMultiplayerChange(true);
		} finally {
			if (restartUpdateLoop) {
				LoadingScreen.hide();
				BB.router.startUpdateLoop();
			}
		}

		return ibs;
	}

	/**
	 * @param {{
	 *   serverID: string;
	 * 	 username?: string;
	 *   playerMatchmakingToken?:string;
	 *   onJoinLoadWorld?: any;
	 * }} param0
	 * @returns
	 */
	static async join({ serverID, playerMatchmakingToken, username = "anonymous", onJoinLoadWorld }) {
		const clientOptions = await beginConnection({
			mode: NET_CLIENT,
			serverID,
			username,
			oniceselected(type) {
				UI.state.multiplayer().setStatus(type === "relay" ? "TURN relay server in use" : null);
			},
		});

		const ibs = new IBSClient(clientOptions);
		await ibs.init();

		Net.setImpl(ibs);

		await LoadingScreen.setText("Downloading world");

		ibs.setPeerSocketSettings({
			unknownCommandBehavior: SOCKET_BEHAVIOR_WAIT,
		});

		Net.send("multiplayer_auth", {
			username: username,
			isGuest: !ibs.playerAuthToken,
			displayPhotoURL: UI.user.displayPhotoURL(),
			avatarURL: UI.user.avatarThumbnailURL(),
			avatarID: UI.user.avatarId(),
			accountID: UI.user.accountId(),
			playerID: ibs.playerID,
			playerMatchmakingToken,
		});

		Net.flush(); // need to do this manually because update loop hasn't started yet

		await Multiplayer.initClientCommandListeners(onJoinLoadWorld);
		await Multiplayer.initCommandListeners();

		Net.handleWaitList();

		ibs.setPeerSocketSettings({ unknownCommandBehavior: SOCKET_BEHAVIOR_LOG });

		return {
			peerId: Net.getPeerId(),
		};
	}

	static async matchmake(worldID, region) {
		const connection = await beginConnection({
			mode: "matchmaker",
			worldID,
		});

		let matchmakeResult;

		try {
			if (DEBUG_ENABLED) {
				throw Error("Matchmaking is only available in a production environment");
			}
			matchmakeResult = await ws.client.call("dedi:matchmake", {
				worldId: worldID,
				region,
				version: LONG_HASH,
			});
		} catch (oops) {
			connection.mode = NET_AWAIT;
			connection.onfail({ type: "master", error: oops.message });
			throw oops;
		}

		const { serverId, playerMatchmakingToken } = matchmakeResult;

		await UI.gameMultiplayer.joinWorld(serverId, { playerMatchmakingToken });
	}

	/*
	only happens by user request
	- entering offline mode
	- leave world
	*/
	static disconnect(worldIsDisposed) {
		const ibs = Net.impl.ibs;

		if (!ibs) return;

		if (netState.isClient) UI.state.multiplayer().setStatus(null);

		const prvMode = ibs.mode;
		ibs.close();
		Net.setOffline();

		if (prvMode === NET_SERVER && !worldIsDisposed) {
			BB.world.router.onMultiplayerChange(false);
		}
	}

	static isConnected() {
		return Net.isConnected();
	}

	static updateWorldShortcode(shortCode) {
		if (!Multiplayer.isConnected()) return;

		const ibs = Net.impl.ibs;

		if (shortCode !== ibs.o.worldShortCode) {
			ibs.o.worldShortCode = shortCode;
			ws.client.call("ibs:update-shortcode", { shortCode, accessToken: ibs.accessToken });
		}
	}

	static initHostCommandListeners(contentWorldId) {
		Net.initCommandListeners();

		Net.listen("multiplayer_auth", async function (a, world, peer) {
			const {
				username,
				isGuest,
				displayPhotoURL,
				avatarURL,
				accountID,
				avatarID,
				playerMatchmakingToken,
			} = a;

			const onPeerMultiplayerAuth = world.server.multiplayer.onPeerMultiplayerAuth;

			if (onPeerMultiplayerAuth) {
				const { success, error } = await onPeerMultiplayerAuth(peer, playerMatchmakingToken);

				if (!success) {
					Net.send("multiplayer_auth_failure", { error }, peer);
					peer.close();
					return;
				}
			}

			let role;

			if (!netState.isClient || isGuest) {
				role = WorldUserRole.PLAYER;
			} else if (!contentWorldId) {
				role =
					netState.isClient && accountID === getPeerMetadata(world.client.loopbackPeer).accountID
						? WorldUserRole.OWNER
						: WorldUserRole.PLAYER;
			} else {
				role = await getWorldRole({
					shortCode: contentWorldId,
					accountID,
				});
			}

			// fetch this users avatar package
			if (accountID && avatarID) {
				const avatarResult = await getUserAvatar(accountID, avatarID);
				if (avatarResult.data) {
					BB.world.content.importPackage(avatarResult.data.avatar);
				}
			}

			const player = world.router.createPlayer(username, avatarID, peer);
			const targetID = player.entityID;
			player.setOwner(peer.peerID);
			world.remapPeerPlayer(peer, player);

			// fully serialize player, this is so that all information is sent immediately
			// otherwise, any post-constructor data is not part of the serialization state because no net-tick will have happened
			player.serialize(true);

			const entities = [];
			for (const e of world.entities) {
				if (e.authority.state.public >= 2) continue;

				const def = e.def;

				const posX = e.position.x;
				const posY = e.position.y;
				const posZ = e.position.z;
				const quatX = e.quaternion.x;
				const quatY = e.quaternion.y;
				const quatZ = e.quaternion.z;
				const quatW = e.quaternion.w;

				const data = [
					def,
					posX,
					posY,
					posZ,
					quatX,
					quatY,
					quatZ,
					quatW,
					e.entityID,
					e.sceneTree?.state.sceneTreeNode ?? null,
					e.serialization.getSerializedState(),
				];

				const mount = e.mount.state;
				if (mount.parent) {
					data.push(mount.parent.entityID, mount.index);
				}

				entities.push(data);
			}

			const content = BB.world.content.export();
			const worldDef = deepCopy(world.def ?? {}); // TODO should be replaced by structuredClone as soon as we remove all functions from definitions
			worldDef.defs = [];

			for (const def of world.defs.values()) {
				worldDef.defs.push(deepCopy(def)); // TODO same here as above
			}

			// sync preset with the current changes by the env preset nodes
			worldDef.environment = world.environmentSettings.router.getEnvironment();

			const blockGroups = BlockGroups.save(world.blockGroups);
			const sceneTree = SceneTree.save(world.sceneTree);

			const editor = WorldEditor.createPatch(world.editor);

			const bundle = {
				role,
				content,
				world: {
					...worldDef,
					entities,
					targetID,
					chunkScene: {
						...worldDef.chunkScene,
						mapID: world.scene.mapID,
					},
					blockGroups,
					sceneTree,
					blockTypeRegistry: {
						mapReorderBlocks: world.blockTypeRegistry.mapReorderBlocks,
					},
					sky: {
						...worldDef.sky,
						time: world.sky.time,
					},
					editor,
				},
			};

			Net.send("multiplayer_world", bundle, peer);

			const peerMetadata = {
				username,
				accountID: accountID ?? peer.peerID,
				isGuest,
				role,
				avatarID,

				//frontend-specifc
				peerID: peer.peerID,
				isHost: false,
				displayPhotoURL,
				avatarURL,
			};

			setPeerMetadata(peer, peerMetadata);

			// dedi hooks
			if (world.server.multiplayer.onPeerJoin)
				world.server.multiplayer.onPeerJoin(peer, world.server.multiplayer.worldId);

			if (netState.isClient) {
				UI.gameLifecycle.onPeerJoin(peerMetadata);
			}

			await PersistentDataServer.onPeerJoin(
				world.rete,
				world.server.persistentData,
				world.server.multiplayer.worldId,
				peer,
			);

			trigger.onPeerJoin(world, player);

			Net.sendToAll("multiplayer_join", peerMetadata, peer); //newly joined peer will receive multiplayer_peerdata instead

			broadcastPrivateChat(
				world,
				world.router.getPeers().filter((p) => p !== peer),
				`${username} joined`,
			);

			peer.authComplete = true;
		});

		Net.listen("multiplayer_uglystates", function (_, world, peer) {
			if (!peer.authComplete) return;
			if (peer.ready) return;

			peer.ready = true;

			Net.send(
				"multiplayer_peerdata",
				world.router.getPeers().map((peer) => getPeerMetadata(peer)),
				peer,
			);
		});

		Net.listen("close", async function (_data, world, peer) {
			if (!peer.authComplete) return;

			// TODO: setOwner peerID
			for (const e of world.entities) if (e.isOwnedBy(peer.peerID)) e.setOwner(Net.getPeerId());

			const peerID = peer.peerID;
			const player = world.peerToPlayer.get(peer);

			if (netState.isClient) {
				UI.gameLifecycle.onPeerLeave(peerID);
			}

			trigger.onPeerLeave(world, player);

			// Write and then remove persistent data for the peer.
			// - This must be done AFTER running onPeerLeave rete triggers so nodes that use persistent data
			//   can react to the peer leaving.
			// - This must be done BEFORE `onClientLeave`, as rivet will stop dedicated instances when
			//   there are no more peers in the lobby. We want to make sure we've written persistent data
			//   before rivet stops dedicated servers.
			await PersistentDataServer.onPeerLeave(
				world.rete,
				world.server.persistentData,
				world.server.multiplayer.worldId,
				peer,
			);

			Net.sendToAll("multiplayer_leave", peerID, peer);

			if (world.server.multiplayer.onPeerLeave)
				world.server.multiplayer.onPeerLeave(peer, world.server.multiplayer.worldId);

			player.dispose();

			broadcastPrivateChat(
				world,
				world.router.getPeers().filter((p) => p !== peer),
				`${getPeerMetadata(peer).username} left`,
			);

			world.clearPeerPlayer(peer);
		});
	}

	static async initClientCommandListeners(onJoinLoadWorld) {
		Net.listen("multiplayer_auth_failure", function ({ error }, _world, peer) {
			const sender = peer.peerID;

			// ignore if the sender is not the server
			if (sender !== "server") {
				return;
			}

			Net.impl.ibs.onfail({ type: "auth", error });
		});

		// NOTE: onJoinLoadWorld will call engine joinWorld, populating BB.world
		// TODO: refactor to be far more explicit
		await new Promise(function (resolve) {
			Net.listen("multiplayer_world", async function (a) {
				await onJoinLoadWorld(a);
				resolve();
			});
		});

		Net.initCommandListeners();

		Net.listen("multiplayer_peerdata", function (peers) {
			UI.gameMultiplayer.setPeers(peers);
		});

		Net.listen("multiplayer_join", function (data) {
			UI.gameLifecycle.onPeerJoin(data);
		});

		Net.listen("multiplayer_leave", function (peerID) {
			UI.gameLifecycle.onPeerLeave(peerID);
		});

		Net.send("multiplayer_uglystates");
	}

	static async initCommandListeners() {
		BB.world.router.initCommandListeners();
		await 0; //wait for any async command listeners from mods to init

		ChatRouter.initCommandListeners();
	}
}
