import { isNullish } from "@jamango/helpers";
import { serDes as untypedSerDes } from "base/world/entity/component/Serialization";
import { Text3DComponent } from "base/world/entity/component/Text3D";
import type { EntityCreateOptions } from "base/world/entity/Entity";
import { Entity } from "base/world/entity/Entity";
import type { World } from "base/world/World";

const serDes = untypedSerDes<Text3DEntity>;

export class Text3DEntity extends Entity {
	text3D: Text3DComponent;

	constructor(o: EntityCreateOptions, def: { name: string }, world: World) {
		super(o, def, world);

		this.text3D = this.addComponent(
			new Text3DComponent({
				parent: this.object3D,
				text: "",
			}),
		);
	}

	serialize(includeMovement?: boolean) {
		super.serialize(includeMovement);

		for (const id of Text3DEntity.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}

		return this.serialization.diffs;
	}

	static serDesIds: number[] = [
		serDes(
			"text3d text",
			(e) => e.text3D.state.text,
			(e, v) => !isNullish(v) && (e.text3D.state.text = v),
		),
		serDes(
			"text3d faceCamera",
			(e) => Number(e.text3D.state.options.faceCamera),
			(e, v) => (e.text3D.state.options.faceCamera = !!v),
		),
		serDes(
			"text3d proximity",
			(e) => (!isNullish(e.text3D.state.options.proximity) ? e.text3D.state.options.proximity : -1),
			(e, v) => !isNullish(v) && (e.text3D.state.options.proximity = v),
		),
		serDes(
			"text3d sizeAttenuation",
			(e) => Number(e.text3D.state.options.sizeAttenuation ?? false),
			(e, v) => (e.text3D.state.options.sizeAttenuation = !!v),
		),
		serDes(
			"text3d scl",
			(e) => e.text3D.state.options.scl,
			(e, v) => (e.text3D.state.options.scl = v),
		),
		serDes(
			"text3d fontsize",
			(e) => e.text3D.state.renderOptions.fontSize,
			(e, v) => (e.text3D.state.renderOptions.fontSize = v),
		),
		serDes(
			"text3d backgroundColor",
			(e) => e.text3D.state.renderOptions.backgroundColor,
			(e, v) => (e.text3D.state.renderOptions.backgroundColor = v),
		),
		serDes(
			"text3d fontColor",
			(e) => e.text3D.state.renderOptions.fontColor,
			(e, v) => (e.text3D.state.renderOptions.fontColor = v),
		),
		serDes(
			"text3d fontWeight",
			(e) => e.text3D.state.renderOptions.fontWeight,
			(e, v) => (e.text3D.state.renderOptions.fontWeight = v),
		),
		serDes(
			"text3d fontOutlineWidth",
			(e) => e.text3D.state.renderOptions.fontOutlineWidth,
			(e, v) => (e.text3D.state.renderOptions.fontOutlineWidth = v),
		),
		serDes(
			"text3d margin",
			(e) => e.text3D.state.renderOptions.margin,
			(e, v) => (e.text3D.state.renderOptions.margin = v),
		),
		serDes(
			"text3d linePadding",
			(e) => e.text3D.state.renderOptions.linePadding,
			(e, v) => (e.text3D.state.renderOptions.linePadding = v),
		),
		serDes(
			"text3d radius",
			(e) => e.text3D.state.renderOptions.radius,
			(e, v) => (e.text3D.state.renderOptions.radius = v),
		),
	];
}
