import type { SocketsDef } from "@jamango/content-client";
import { assertNever, createLogger } from "@jamango/helpers";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { DEGRAD } from "base/util/math/Math";
import * as ConstraintManager from "base/world/ConstraintManager";
import { Vector3 } from "three";

const logger = createLogger("Constraints Nodes");

type MotorStatusString = "off" | "velocity" | "position";

const mapMotorStatus = (v: MotorStatusString) => {
	switch (v) {
		case "off":
			return ConstraintManager.MotorStatus.OFF;
		case "velocity":
			return ConstraintManager.MotorStatus.VELOCITY;
		case "position":
			return ConstraintManager.MotorStatus.POSITION;
		default:
			assertNever(v);
	}
};

type PointSpaceString = "world" | "local";

const mapPointSpace = (v: PointSpaceString) => {
	switch (v) {
		case "world":
			return ConstraintManager.PointSpace.WORLD;
		case "local":
			return ConstraintManager.PointSpace.LOCAL;
		default:
			assertNever(v);
	}
};

type AxisString = "x" | "y" | "z";

const AXIS_X = new Vector3(1, 0, 0);
const AXIS_Y = new Vector3(0, 1, 0);
const AXIS_Z = new Vector3(0, 0, 1);

const mapAxisString = (axis: AxisString) => {
	switch (axis) {
		case "x":
			return AXIS_X;
		case "y":
			return AXIS_Y;
		case "z":
			return AXIS_Z;
	}
};

const addEntitiesInputs = () => {
	return {
		entityA: {
			name: "Entity A",
			type: "entity",
		},
		entityB: {
			name: "Entity B",
			type: "entity",
		},
	} satisfies SocketsDef<"I">;
};

const addPointsInputs = () => {
	return {
		pointSpace: {
			name: "Point Space",
			type: "string",
			control: "select",
			config: {
				defaultValue: "local",
				explicitSortOptions: [
					{ value: "world", label: "World" },
					{ value: "local", label: "Local" },
				],
			},
		},
		pointA: {
			name: "Point A",
			type: "vector3",
			control: "vector3",
			icon: "MapPin",
		},
		pointB: {
			name: "Point B",
			type: "vector3",
			control: "vector3",
			icon: "MapPin",
		},
	} satisfies SocketsDef<"I">;
};

export const CONSTRAINT_NODES = [
	node({
		id: "cb722a27-4b96-4f80-a7b9-ea57bd44df3c",
		name: "Create Fixed Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a fixed constraint between two physical entities.",
		info: ["Currently only supports fixed constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.FIXED,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "b81bac6c-a6c1-4686-96bb-8d9772365348",
		name: "Create Point Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a point constraint between two physical entities.",
		info: ["Currently only supports point constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
			...addPointsInputs(),
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.POINT,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
				pointSpace: mapPointSpace(inputs.pointSpace as PointSpaceString),
				pointA: inputs.pointA,
				pointB: inputs.pointB,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "56fd0706-ec85-4ae5-920a-a2b4f7cf8814",
		name: "Create Distance Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a distance constraint between two physical entities.",
		info: ["Currently only supports distance constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
			...addPointsInputs(),
			minDistance: {
				name: "Min Distance",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			maxDistance: {
				name: "Max Distance",
				type: "number",
				control: "number",
				config: { defaultValue: 1 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.DISTANCE,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
				pointSpace: mapPointSpace(inputs.pointSpace as PointSpaceString),
				pointA: inputs.pointA,
				pointB: inputs.pointB,
			};

			const state: ConstraintManager.DistanceConstriantState = {
				type: ConstraintManager.ConstraintType.DISTANCE,
				minDistance: inputs.minDistance,
				maxDistance: inputs.maxDistance,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def, state);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "fdc8e340-0910-4707-b3f8-2c5df8e1b7ba",
		name: "Get Distance Constraint Settings",
		type: NODE_TYPE_ID.function.world,
		description: "Gets the state of a distance constraint.",
		inputs: {
			physicsConstraint: { name: "Distance Constraint", type: "physicsConstraint" },
		},
		outputs: {
			minDistance: { name: "Min Distance", type: "number" },
			maxDistance: { name: "Max Distance", type: "number" },
		},
		resolve(inputs, ctx, _nodeId, _scope) {
			const world = ctx.world;

			const constraint = world.constraints.constraints.get(inputs.physicsConstraint);

			if (!constraint) {
				throw new Error("Constraint not found");
			}

			const state = constraint.state as ConstraintManager.DistanceConstriantState | null;

			if (!state) {
				throw new Error("Distance constraint state not defined");
			}

			return {
				minDistance: state.minDistance,
				maxDistance: state.maxDistance,
			};
		},
	}),
	node({
		id: "148f9c1c-b080-44ba-8740-6c7318daf36a",
		name: "Update Distance Constraint Settings",
		type: NODE_TYPE_ID.function.world,
		description: "Updates a distance constraint's settings.",
		inputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Distance Constraint", type: "physicsConstraint" },
			minDistance: {
				name: "Min Distance",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 0 },
			},
			maxDistance: {
				name: "Max Distance",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 1 },
			},
		},

		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const world = ctx.world;

			const constraint = world.constraints.constraints.get(inputs.physicsConstraint);

			if (!constraint) {
				logger.warn("Constraint not found", inputs.physicsConstraint);
				return;
			}

			const existing = constraint.state as ConstraintManager.DistanceConstriantState | null;

			if (!existing) {
				logger.warn("Distance constraint state not found", inputs.physicsConstraint);
				return;
			}

			ConstraintManager.updateConstraint(world.constraints, world, inputs.physicsConstraint, {
				type: ConstraintManager.ConstraintType.DISTANCE,
				minDistance: inputs.minDistance ?? existing.minDistance,
				maxDistance: inputs.maxDistance ?? existing.maxDistance,
			});
		},
	}),
	node({
		id: "0c78eca4-773c-4392-9b46-6537c17f1b91",
		name: "Create Hinge Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a hinge constraint between two physical entities.",
		info: ["Currently only supports hinge constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
			...addPointsInputs(),
			hingeAxisA: {
				name: "Hinge Axis A",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			hingeAxisB: {
				name: "Hinge Axis B",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			normalAxisA: {
				name: "Normal Axis A",
				type: "string",
				control: "select",
				config: {
					defaultValue: "x",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			normalAxisB: {
				name: "Normal Axis B",
				type: "string",
				control: "select",
				config: {
					defaultValue: "z",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			limitMin: {
				name: "Limit Min (degrees)",
				type: "number",
				control: "number",
				config: { defaultValue: -180 },
			},
			limitMax: {
				name: "Limit Max (degrees)",
				type: "number",
				control: "number",
				config: { defaultValue: 180 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.HINGE,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
				pointSpace: mapPointSpace(inputs.pointSpace as PointSpaceString),
				pointA: inputs.pointA,
				pointB: inputs.pointB,
				hingeAxisA: mapAxisString(inputs.hingeAxisA as AxisString),
				hingeAxisB: mapAxisString(inputs.hingeAxisB as AxisString),
				normalAxisA: mapAxisString(inputs.normalAxisA as AxisString),
				normalAxisB: mapAxisString(inputs.normalAxisB as AxisString),
				limitMin: inputs.limitMin * DEGRAD,
				limitMax: inputs.limitMax * DEGRAD,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "b46cb4d3-72e0-4bf8-8e0c-5965dced798b",
		name: "Update Hinge Constraint Settings",
		type: NODE_TYPE_ID.function.world,
		description: "Updates a hinge constraint's settings.",
		inputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Hinge Constraint", type: "physicsConstraint" },
			motorState: {
				name: "Motor State",
				type: "string",
				control: "select",
				config: {
					defaultValue: "off",
					explicitSortOptions: [
						{ value: "off", label: "Off" },
						{ value: "velocity", label: "Angular Velocity" },
						{ value: "position", label: "Angle" },
					],
				},
			},
			motorTarget: {
				name: "Motor Target",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			minForceLimit: {
				name: "Min Force Limit",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: undefined },
			},
			maxForceLimit: {
				name: "Max Force Limit",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: undefined },
			},
			minTorqueLimit: {
				name: "Min Torque Limit",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: undefined },
			},
			maxTorqueLimit: {
				name: "Max Torque Limit",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: undefined },
			},
		},

		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const world = ctx.world;

			const constraint = world.constraints.constraints.get(inputs.physicsConstraint);

			if (!constraint) {
				logger.warn("Constraint not found", inputs.physicsConstraint);
				return;
			}

			const existing = (constraint.state ??
				ConstraintManager.DEFAULT_HINGE_CONSTRAINT_STATE) as ConstraintManager.HingeConstraintState;

			const motorStatus = mapMotorStatus(inputs.motorState as MotorStatusString);
			const motorSettings = {
				minForceLimit: inputs.minForceLimit ?? existing.motorSettings.minForceLimit,
				maxForceLimit: inputs.maxForceLimit ?? existing.motorSettings.maxForceLimit,
				minTorqueLimit: inputs.minTorqueLimit ?? existing.motorSettings.minTorqueLimit,
				maxTorqueLimit: inputs.maxTorqueLimit ?? existing.motorSettings.maxTorqueLimit,
			};

			ConstraintManager.updateConstraint(world.constraints, world, inputs.physicsConstraint, {
				type: ConstraintManager.ConstraintType.HINGE,
				motorStatus,
				motorSettings,
				motorTarget: inputs.motorTarget,
			});
		},
	}),
	node({
		id: "4ce2d7c7-80d2-44a4-95b7-fe3d21d7f26a",
		name: "Create Slider Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a slider constraint between two physical entities.",
		info: ["Currently only supports slider constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
			...addPointsInputs(),
			sliderAxisA: {
				name: "Slider Axis A",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			sliderAxisB: {
				name: "Slider Axis B",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			normalAxisA: {
				name: "Normal Axis A",
				type: "string",
				control: "select",
				config: {
					defaultValue: "x",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			normalAxisB: {
				name: "Normal Axis B",
				type: "string",
				control: "select",
				config: {
					defaultValue: "z",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			limitMin: {
				name: "Limit Min",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			limitMax: {
				name: "Limit Max",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.SLIDER,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
				pointSpace: mapPointSpace(inputs.pointSpace as PointSpaceString),
				pointA: inputs.pointA,
				pointB: inputs.pointB,
				sliderAxisA: mapAxisString(inputs.sliderAxisA as AxisString),
				sliderAxisB: mapAxisString(inputs.sliderAxisB as AxisString),
				normalAxisA: mapAxisString(inputs.normalAxisA as AxisString),
				normalAxisB: mapAxisString(inputs.normalAxisB as AxisString),
				limitMin: inputs.limitMin,
				limitMax: inputs.limitMax,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "c5f10300-5b54-4bd9-8364-1520ac671fef",
		name: "Create Cone Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Creates a cone constraint between two physical entities.",
		info: ["Currently only supports cone constraints between props."],
		inputs: {
			exec: { type: "exec" },
			...addEntitiesInputs(),
			...addPointsInputs(),
			coneAxisA: {
				name: "Cone Axis A",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			coneAxisB: {
				name: "Cone Axis B",
				type: "string",
				control: "select",
				config: {
					defaultValue: "y",
					explicitSortOptions: [
						{ value: "x", label: "X" },
						{ value: "y", label: "Y" },
						{ value: "z", label: "Z" },
					],
				},
			},
			halfConeAngle: {
				name: "Half Cone Angle",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const def: ConstraintManager.ConstraintDef = {
				type: ConstraintManager.ConstraintType.CONE,
				entityA: inputs.entityA.entityID,
				entityB: inputs.entityB.entityID,
				pointSpace: mapPointSpace(inputs.pointSpace as PointSpaceString),
				pointA: inputs.pointA,
				pointB: inputs.pointB,
				coneAxisA: mapAxisString(inputs.coneAxisA as AxisString),
				coneAxisB: mapAxisString(inputs.coneAxisB as AxisString),
				halfConeAngle: inputs.halfConeAngle,
			};

			const id = ConstraintManager.createConstraint(world.constraints, world, def);

			scope[nodeId] = id;
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return { physicsConstraint: scope[nodeId]! };
		},
	}),
	node({
		id: "d46c0dc7-f38b-49ec-8046-a536b5085961",
		name: "Remove Constraint",
		type: NODE_TYPE_ID.function.world,
		description: "Removes a constraint.",
		inputs: {
			exec: { type: "exec" },
			physicsConstraint: { name: "Constraint", type: "physicsConstraint" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const world = ctx.world;

			ConstraintManager.disposeConstraint(world.constraints, world, inputs.physicsConstraint);
		},
	}),
];
