import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateCrouchStart extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("CrouchStart", animMesh, locomotionInput, transitions);

		this.transitionToCrouchIdleTime = 0.2;
		this.transitionToCrouchWalkingTime = 0.2;
		this.transitionToDeathTime = 0.2;
	}

	get crouchStartAction() {
		return this.mesh.getAction("JMGO_Unarmed_Crouch_Entry");
	}

	startCompute(transitionData) {
		this.crouchStartAction.reset();
		this.crouchStartAction.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchStartAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.crouchStartAction.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.crouchStartAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("CrouchWalking");
			outTransitionToState.blendTime = this.transitionToCrouchWalkingTime;
			return true;
		} else {
			if (this.crouchStartAction.getRemainingTime() < this.transitionToCrouchIdleTime) {
				outTransitionToState.ptr = map.get("CrouchIdle");
				outTransitionToState.blendTime = this.transitionToCrouchIdleTime;
				return true;
			}
		}

		return false;
	}
}
