import type { Quaternion, Vector3 } from "three";
import type { Entity } from "base/world/entity/Entity";
import * as Metrics from "base/world/MetricsManager";

export class SerializationComponent {
	name = "serialization";
	state = new Map<number, any>();
	diffs: any[] = [];
	entity: Entity;

	constructor(entity: Entity) {
		this.entity = entity;
		this.reset();
	}

	getSerializedState(): any[] {
		const full: number[] = [this.entity.entityID];
		for (const [key, val] of this.state) {
			full.push(props.get(key)!.id, val);
		}
		return full;
	}

	reset(): void {
		// flushes diffs and inserts entity ID at the start
		this.diffs = [];
		this.diffs.push(this.entity.entityID);
	}

	serialize(key: number, includeMovement = false): void {
		const { serialize, isMovement, name } = props.get(key)!;
		// we may not always want movement related states (they are usually sent for heartbeats)
		if (isMovement && !includeMovement) return;
		const serialized = serialize(this.entity);
		// check if there is a difference. if so, track in diff array
		// some properties (like movement related values) are by default diff
		const isDiff = isMovement || this.state.get(key) !== serialized;
		if (isDiff) {
			this.diffs.push(key, serialized);
			this.state.set(key, serialized);

			// TODO: significant performance impact here just for the measurement
			const len = JSON.stringify(serialized).length;
			Metrics.increment("entity diff", name, [1, len]);
			Metrics.increment("entity diff", "sum", [1, len]);
		}
	}

	deserialize(values: any[], includeMovement = true): void {
		// deserializes all values in a serialized array, with filters
		for (let i = 1; i < values.length; i += 2) {
			const key = values[i];
			const { deserialize, isMovement } = props.get(key)!;
			if (isMovement && !includeMovement) continue;
			deserialize(this.entity, values[i + 1]);
		}
	}
}

// We create a map of possible properties to serialize, including their serializer functions
// this is necessary for now in order to have serial IDs - we could colocate this code in the actual classes, but it may be a bit more complicated
// fundamnetally the serializer works by having hardcoded IDs for properties
interface SerializationProperty<TEntity extends Entity> {
	id: number;
	name: string;
	serialize: (entity: TEntity) => any;
	deserialize: (entity: TEntity, value: any) => void;
	isMovement: boolean;
}
export const props = new Map<number, SerializationProperty<any>>();
let PROP_COUNT = 0;

/**
 * Registers a serialization and deserialization function pair with an optional movement flag.
 */
export const serDes = <TEntity extends Entity>(
	name: string,
	serialize: SerializationProperty<TEntity>["serialize"],
	deserialize: SerializationProperty<TEntity>["deserialize"],
	isMovement = false,
): number => {
	const id = PROP_COUNT++;
	const p: SerializationProperty<TEntity> = { id, serialize, deserialize, isMovement, name };
	props.set(id, p);
	return id;
};

/**
 * Each component is multiplied by `steps` and then rounded to the nearest integer.
 */
export const serV3 = <T extends Vector3>(v: T, steps: number): [number, number, number] => [
	Math.round(v.x * steps),
	Math.round(v.y * steps),
	Math.round(v.z * steps),
];

export const desV3 = <T extends Vector3>(v: T, s: [number, number, number], steps: number): T => {
	return v.set(s[0] / steps, s[1] / steps, s[2] / steps) as T;
};

export const serQuat = <T extends Quaternion>(v: T, steps: number): [number, number, number, number] => [
	Math.round(v.x * steps),
	Math.round(v.y * steps),
	Math.round(v.z * steps),
	Math.round(v.w * steps),
];

export const desQuat = <T extends Quaternion>(
	v: T,
	s: [number, number, number, number],
	steps: number,
): T => {
	return v.set(s[0] / steps, s[1] / steps, s[2] / steps, s[3] / steps);
};
