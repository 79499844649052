import { alphabetizeArray, isNullish } from "@jamango/helpers";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { Vector3 } from "three";

//ALL HARDCODED NODES
import { AVATAR_NODES } from "base/rete/nodes/Avatar";
import { BLOCK_GROUP_NODES } from "base/rete/nodes/BlockGroups";
import { BLOCK_TRIGGERS } from "base/rete/nodes/BlockTriggers";
import { BLUEPRINT_NODES } from "base/rete/nodes/Blueprint";
import { CharacterNodes } from "base/rete/nodes/Character";
import { COLLISION_NODES } from "base/rete/nodes/Collision";
import { CONSTRAINT_NODES } from "base/rete/nodes/Constraints";
import { CONVERSION_NODES } from "base/rete/nodes/Conversion";
import { CUSTOM_UI_NODES } from "base/rete/nodes/CustomUI";
import { EDITOR_NODES } from "base/rete/nodes/Editor";
import { ENTITY_NODES } from "base/rete/nodes/Entity";
import { ENTITY_TRIGGERS } from "base/rete/nodes/EntityTriggers";
import { EULER_NODES } from "base/rete/nodes/Euler";
import { GLOBAL_TRIGGERS } from "base/rete/nodes/GlobalTriggers";
import { LIST } from "base/rete/nodes/List";
import { LOGIC } from "base/rete/nodes/Logic.js";
import { MATH } from "base/rete/nodes/Math.js";
import { NATIVE_FUNCTIONS } from "base/rete/nodes/NativeFunctions";
import { PARTICLE_SYSTEM_NODES } from "base/rete/nodes/ParticleSystem";
import { PERSISTENT_DATA_NODES } from "base/rete/nodes/PersistentData";
import { PHYSICS_NODES } from "base/rete/nodes/Physics";
import { PROJECTILE_NODES } from "base/rete/nodes/ProjectileNodes";
import { PROP_NODES } from "base/rete/nodes/Props";
import { QUATERNION_NODES } from "base/rete/nodes/Quaternion";
import { SCENE_TREE_NODES } from "base/rete/nodes/SceneTree";
import { SCRIPT_NODES } from "base/rete/nodes/Script.js";
import { Settings } from "base/rete/nodes/Settings";
import { Spawner } from "base/rete/nodes/Spawner";
import { STRINGS_NODES } from "base/rete/nodes/Strings.js";
import { TEXT_3D_NODES } from "base/rete/nodes/Text3D";
import { UTILS } from "base/rete/nodes/Utils.js";
import { VECTOR3 } from "base/rete/nodes/Vector3";
import type { InterpreterExecInput, NodeDef } from "./Types";
import { ITEM_NODES } from "base/rete/nodes/Items";
import { HUD_NODES } from "base/rete/nodes/HUD";
import { WORLD_PORTAL_NODES } from "base/rete/nodes/WorldPortal";
import { ANALYTICS_NODES } from "base/rete/nodes/Analytics";

// make a set of trigger node types
export const triggers = new Set(Object.values(NODE_TYPE_ID.entryPointTrigger));

// take all of our node implementations, triggers, etc. and put them into a map
export const defs = new Map<string, NodeDef>();
BLOCK_TRIGGERS.forEach((f) => defs.set(f.id, f));
COLLISION_NODES.forEach((f) => defs.set(f.id, f));
GLOBAL_TRIGGERS.forEach((f) => defs.set(f.id, f));
ENTITY_TRIGGERS.forEach((f) => defs.set(f.id, f));
NATIVE_FUNCTIONS.forEach((f) => defs.set(f.id, f));
CUSTOM_UI_NODES.forEach((f) => defs.set(f.id, f));
AVATAR_NODES.forEach((f) => defs.set(f.id, f));
LOGIC.forEach((f) => defs.set(f.id, f));
MATH.forEach((f) => defs.set(f.id, f));
STRINGS_NODES.forEach((f) => defs.set(f.id, f));
LIST.forEach((f) => defs.set(f.id, f));
SCRIPT_NODES.forEach((f) => defs.set(f.id, f));
UTILS.forEach((f) => defs.set(f.id, f));
Spawner.forEach((f) => defs.set(f.id, f));
PERSISTENT_DATA_NODES.forEach((f) => defs.set(f.id, f));
VECTOR3.forEach((f) => defs.set(f.id, f));
TEXT_3D_NODES.forEach((f) => defs.set(f.id, f));
PARTICLE_SYSTEM_NODES.forEach((f) => defs.set(f.id, f));
CharacterNodes.forEach((f) => defs.set(f.id, f));
BLOCK_GROUP_NODES.forEach((f) => defs.set(f.id, f));
Settings.forEach((f) => defs.set(f.id, f));
PROP_NODES.forEach((f) => defs.set(f.id, f));
PHYSICS_NODES.forEach((f) => defs.set(f.id, f));
ENTITY_NODES.forEach((f) => defs.set(f.id, f));
CONSTRAINT_NODES.forEach((f) => defs.set(f.id, f));
EDITOR_NODES.forEach((f) => defs.set(f.id, f));
BLUEPRINT_NODES.forEach((f) => defs.set(f.id, f));
SCENE_TREE_NODES.forEach((f) => defs.set(f.id, f));
PROJECTILE_NODES.forEach((f) => defs.set(f.id, f));
QUATERNION_NODES.forEach((f) => defs.set(f.id, f));
EULER_NODES.forEach((f) => defs.set(f.id, f));
CONVERSION_NODES.forEach((f) => defs.set(f.id, f));
ITEM_NODES.forEach((f) => defs.set(f.id, f));
HUD_NODES.forEach((f) => defs.set(f.id, f));
WORLD_PORTAL_NODES.forEach((f) => defs.set(f.id, f));
ANALYTICS_NODES.forEach((f) => defs.set(f.id, f));

// for each node, we generate default inputs if it has any
export const defaultInputs = new Map<string, InterpreterExecInput>();
defs.forEach((def, id) => {
	const inputs: InterpreterExecInput = {};
	// this is old code
	const defInputs = def.controls ?? def.inputs;
	for (const inputName in defInputs) {
		const inputDef = defInputs[inputName];

		if (!inputDef) {
			continue;
		}

		if (inputDef.optional) continue;

		if (inputDef.control === "select") {
			//can only validate the current value if the node def's options are both arrays (as opposed to getter functions)

			let explicit = inputDef.config.explicitSortOptions;
			let auto = inputDef.config.autoSortOptions;

			if (!Array.isArray(explicit)) explicit = [];
			if (!Array.isArray(auto)) auto = [];

			const options = explicit.concat(alphabetizeArray(auto.slice()));
			const selectedOption = options.find((option: any) => option.value === inputs[inputName]);

			if (!selectedOption) {
				if (options.length > 0) {
					inputs[inputName] = options[0].value;
				} else {
					inputs[inputName] = undefined;
				}
			}

			continue;
		}

		const nodeDefault = inputDef.config?.defaultValue;
		if (!isNullish(nodeDefault)) {
			inputs[inputName] = nodeDefault;

			//deep clone - assumes that there aren't any nodes whose default value has nested arrays
			if (Array.isArray(nodeDefault)) {
				inputs[inputName] = nodeDefault.slice();
				if (nodeDefault[0]?.isVector3)
					for (let i = 0; i < nodeDefault.length; i++)
						inputs[inputName][i] = nodeDefault[i].clone();
			} else if (nodeDefault.isVector3) {
				inputs[inputName] = nodeDefault.clone();
			}
		} else {
			if (inputDef.structure === "any" || inputDef.type === "any") {
				inputs[inputName] = undefined;
			} else if (inputDef.structure === "list") {
				inputs[inputName] = [];
			} else if (inputDef.type === "vector3") {
				inputs[inputName] = new Vector3();
			} else if (inputDef.type === "boolean") {
				inputs[inputName] = false;
			} else if (inputDef.type === "string") {
				inputs[inputName] = "";
			} else if (inputDef.type === "number") {
				inputs[inputName] = 0;
			}
			// else {
			// 	inputs[inputName] = undefined;
			// }
		}
	}

	defaultInputs.set(id, inputs);
});
