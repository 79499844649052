import { addProjectile, projectileCommand } from "base/world/ProjectileManager";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import type { World } from "base/world/World";
import { addSelectGroup } from "base/rete/NodeSharedUtil";
import { Vector3 } from "three";

export const PROJECTILE_NODES = [
	node({
		id: "fd86160c-52fb-472b-885e-cafa51a042f1",
		name: "Spawn Projectile",
		type: NODE_TYPE_ID.function.world,
		predictable: true,
		description: "Spawns a projectile",
		keywords: ["spawn", "create", "projectile"],
		inputs: {
			exec: { type: "exec" },
			projectile: {
				name: "Projectile",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) =>
						Array.from(ctx.world.defs.values())
							.filter((def) => def.name.startsWith("Projectile"))
							.map((def) => ({
								label: def.name,
								value: def.name,
							})),
				},
			},
			shooter: { name: "Shooter", type: "entity" },
			weapon: { name: "Weapon", type: "entity", optional: true },
			origin: { name: "Origin", type: "vector3" },
			direction: { name: "Direction", type: "vector3" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute: (inputs, ctx) => {
			const origin = new Vector3().copy(inputs.origin);
			const direction = new Vector3().copy(inputs.direction).normalize();

			const shooter = inputs.shooter.entityID;
			const weapon = inputs.weapon?.entityID ?? null;

			addProjectile(
				ctx.world,
				ctx.world.projectiles,
				inputs.projectile,
				shooter,
				weapon ?? null,
				ctx.world.time,
				origin,
				direction,
			);

			projectileCommand.sendToAll([inputs.projectile, shooter, weapon, origin, direction]);
		},
	}),
	node({
		id: "5487771b-bb88-48aa-a9e6-161945e122ea",
		name: "On Projectile Hit Block",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description: "Triggered when a block is hit by a projectile",
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Coordinates", type: "vector3" },
			character: { name: "Shooter Entity", type: "entity" },
			hitPosition: { name: "Hit Position", type: "vector3" },
			hitNormal: { name: "Hit Normal", type: "vector3" },
		},
		resolve(input, ctx) {
			return {
				character: ctx.info.character,
				position: ctx.info.position,
				hitPosition: ctx.info.hitPosition,
				hitNormal: ctx.info.hitNormal,
			};
		},
	}),
	node({
		id: "2da6456d-594e-4450-96a8-ce975d1131de",
		name: "On Projectile Hit Entity",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		description: "Triggered when an entity is hit by a projectile",
		keywords: ["Entity", "Projectile"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Target entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			shot: { name: "Shot Entity", type: "entity" },
			shooter: { name: "Shooter Entity", type: "entity" },
			hitPosition: { name: "Hit Position", type: "vector3" },
			hitNormal: { name: "Hit Normal", type: "vector3" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				shot: scope[nodeId].shot,
				shooter: scope[nodeId].shooter,
				hitPosition: scope[nodeId].hitPosition,
				hitNormal: scope[nodeId].hitNormal,
			};
		},
	}),
];
