import { z } from "zod";
import { MAX_WORLD_NAME_LENGTH, MIN_WORLD_NAME_LENGTH } from "../constants/world";
import { AssetType } from "../types";
import { TerrainGenerationOptionsSchema } from "./terrainGeneration";
import { WORLD_EXPERIMENTS_VALUES } from "../constants/experiments";

const TagSchema = z.string().min(3).max(20);

export const tag = (tagName: string) => TagSchema.parse(tagName);

const WorldNameSchema = z
	.string()
	.min(MIN_WORLD_NAME_LENGTH, {
		message: `Name must be at least ${MIN_WORLD_NAME_LENGTH} characters long.`,
	})
	.max(MAX_WORLD_NAME_LENGTH, {
		message: `Name must be at most ${MAX_WORLD_NAME_LENGTH} characters long.`,
	});

export const name = (value: string) => {
	WorldNameSchema.parse(value);
};

const WorldExperimentSchema = z
	.string()
	.min(3, {
		message: "Experiment must be at least 3 characters long",
	})
	.refine(
		(value) => WORLD_EXPERIMENTS_VALUES.map((exp) => exp.id).includes(value),
		(value) => ({
			message: `${value} is not a supported experiment value.`,
		}),
	);

const WorldExperimentsSchema = z.array(WorldExperimentSchema);

export const experiment = (value: string) => {
	WorldExperimentSchema.parse(value);
};

export const experiments = (value: string[]) => {
	WorldExperimentsSchema.parse(value);
};

const InstalledPackageSchema = z.object({
	id: z.string(),
	version: z.string(),
});

export const PLAY_WORLD_MODE = 0;
export const EDIT_WORLD_MODE = 1;

export type WorldMode = typeof PLAY_WORLD_MODE | typeof EDIT_WORLD_MODE;

export const WorldDataSchema = z.object({
	pk: z.string(),
	type: z.literal(AssetType.WORLD_DATA),
	id: z.string(),
	shortCode: z.string(),
	experiments: z.array(z.string()),
	name: WorldNameSchema,
	mode: z.union([z.literal(PLAY_WORLD_MODE), z.literal(EDIT_WORLD_MODE)]),
	allowRemix: z.boolean(),
	tags: z.array(TagSchema),
	packages: z.array(InstalledPackageSchema),
	exportedAssets: z.array(z.string()).optional(),
});

export const MapSchema = z.object({
	pk: z.string(),
	type: z.literal(AssetType.MAP),
	files: z.array(
		z.object({
			name: z.string(),
			resourcePk: z.string(),
		}),
	),
	terrainGenerationOptions: TerrainGenerationOptionsSchema,
});
