import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { BB } from "base/BB";
import type { World } from "./World";

// Metric types
export type StaticMetric = { type: "static"; val: string; host: boolean };
export type DynamicMetric = {
	type: "dynamic";
	count: number[];
	startTime: number | null;
	countPerSecond: number[]; // Added to type
	host: boolean;
};
export type Metric = StaticMetric | DynamicMetric;

// Global metrics storage with nested structure. we do not use the .metrics = init() paradigm here
export let entries: Record<string, Record<string, Metric>> = {};
export const getMetrics = () => {
	return entries;
};
export const resetMetrics = () => {
	return (entries = {});
};

// Sets a string metric
export const setStatic = (category: string, key: string, val: string | number) => {
	const categoryMetrics = (entries[category] ??= {});
	categoryMetrics[key] = { type: "static", val: String(val), host: netState.isHost };
};

// Creates a new dynamic metric
const makeDynamic = (): DynamicMetric => {
	return {
		type: "dynamic",
		count: [],
		startTime: performance.now(),
		countPerSecond: [],
		host: netState.isHost,
	};
};

export const perfTimes: Record<string, { start: number; count: number; sum: number }> = {};
export const perfStart = (category: string, key: string) => {
	key = (netState.isClient ? "" : "dedi ") + key;
	const k = category + "," + key;
	if (!perfTimes[k]) perfTimes[k] = { start: 0, count: 0, sum: 0 };
	perfTimes[k].start = performance.now();
};

export const perfEnd = (category: string, key: string) => {
	key = (netState.isClient ? "" : "dedi ") + key;
	const perf = perfTimes[category + "," + key];
	if (perf) {
		const duration = performance.now() - perf.start;
		perf.count++;
		perf.sum += duration;
		if (perf.count > 100) {
			perf.sum = duration;
			perf.count = 1;
		}
		const avg = perf.sum / perf.count;
		setStatic(category, key, avg.toFixed(2) + "ms");
	}
};

// Increments a counter metric
export const increment = (category: string, key: string, delta: number[]) => {
	const categoryMetrics = (entries[category] ??= {});
	const entry = (categoryMetrics[key] ??= makeDynamic()) as DynamicMetric;
	for (let i = 0; i < delta.length; ++i) entry.count[i] = (entry.count[i] || 0) + delta[i];
};

const updateMetrics = () => {
	// use local timer as opposed to world-game time because the world timer is resetting, whereas these metrics are "process"-lifetimed
	const now = performance.now();
	for (const categoryMetrics of Object.values(entries)) {
		for (const metric of Object.values(categoryMetrics)) {
			// metric.host === netState.isHost ->
			// ^ this means you only update the countPerSecond values on metrics that are local to you, not those received via network
			if (metric.type === "dynamic" && metric.host === netState.isHost) {
				const sinceStart = (now - (metric.startTime || 0)) / 1000;
				metric.countPerSecond = metric.count.map((c) => (c > 0 ? c / sinceStart : 0));
			}
		}
	}
};

const getCountsMetrics = (world: World) => {
	let characters = 0;
	let items = 0;
	let props = 0;
	let particleSystems = 0;
	let text3d = 0;

	for (let i = 0; i < world.entities.length; ++i) {
		const e = world.entities[i];
		const type = e.type.def;
		if (type.isCharacter) {
			characters++;
		} else if (type.isItem) {
			items++;
		} else if (type.isProp) {
			props++;
		} else if (e.particleSystem) {
			particleSystems++;
		} else if (e.text3D) {
			text3d++;
		}
	}

	setStatic("counts", "total entities", world.entities.length);
	setStatic("counts", "characters", characters);
	setStatic("counts", "items", items);
	setStatic("counts", "props", props);
	setStatic("counts", "particle systems", particleSystems);
	setStatic("counts", "text3d", text3d);
	setStatic("counts", "3JS Objects", world.scene.children.length);
};

let timer = 1;
export const update = (world: World, dt: number) => {
	updateMetrics();

	// simple world-state / init independent timer that survives any kind of world-reloads etc.
	timer -= dt;

	if (timer < 0) {
		timer = 1;
		getCountsMetrics(world);

		// TODO: omega gnarly, fix how we detect whether the debugger is open (Debugger refactor)
		if (!netState.isHost && BB.client.debug.enabled) {
			Net.send("metrics_request", 0);
		}
	}
};

export const initCommandListeners = () => {
	// send / request metrics from host
	// TODO: make this a bit cleaner and scale better (perf is terrible)
	// note: this cant be done as Net.command because the Net module itself includes Metrics manager
	if (netState.isHost) {
		Net.listen("metrics_request", (_a, _w, peer) => {
			const cats = ["update", "entity diff", "entity cmd", "send", "send to all", "memory"];
			const netData = {} as typeof entries;
			cats.forEach((c) => {
				netData[c] = entries[c];
			});
			Net.send("metrics", netData, peer);
		});
	} else {
		Net.listen("metrics", (val, _w) => {
			for (const cat in val) {
				entries[cat] = { ...entries[cat], ...val[cat] };
			}
		});
	}
};
