import { BB } from "base/BB";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import { FasterScene } from "base/util/FasterScene.js";
import { createRequestPromise, isNullish } from "@jamango/helpers";
import * as Physics from "base/world/Physics";
import { VZ } from "base/util/math/Math.ts";
import * as ChunkManager from "base/world/ChunkManager.ts";
import { BLOCK_AIR, ChunkUtil } from "base/world/block/Util.js";
import * as Rete from "base/rete/rete";
import { CHUNK_DISPOSE_BYEBYE, CHUNK_DISPOSE_DEFAULT, Chunk } from "base/world/block/chunk/Chunk.js";
import { JamangoMaterial } from "client/util/Shaders.js";
import { NET_SERVER } from "@jamango/ibs";
import { FakeWaterRouter } from "router/world/fx/FakeWater.js";
import { Color, Fog, Vector3 } from "three";
import * as BlockTypeRegistry from "base/world/block/BlockTypeRegistry";
import * as trigger from "base/rete/modules/trigger";
import { createTerrainGenerator } from "mods/generators/createTerrainGenerator";
import * as CustomUIManager from "base/dom/CustomUI";
import { netState } from "router/Parallelogram";
import { deserializeMapFile, serializeMapFile } from "@jamango/content-client";
import { NODE } from "base/rete/InternalNameMap";
import { Vector3Map } from "base/util/math/VectorStorage";
import * as BlockGroups from "base/world/block/BlockGroups.ts";
import * as SceneTree from "base/world/SceneTree";
import * as Net from "router/Net";
import { getPeerMetadata } from "base/util/PeerMetadata.ts";
import * as Metrics from "../MetricsManager";

export const SCENE_MAP_VERSION = 16;
export const SCENE_BUILD_FAIL = 0; //due to not having chunks downloaded yet, but the call to build() has started the download including neighbors
export const SCENE_BUILD_SUCCESS = 1;
export const SCENE_BUILD_ALREADY = 2; //means chunk was already built to begin with. no action taken

const tmpVec = new Vector3();

export class ChunkScene extends FasterScene {
	/**
	 * @type import("mods/generators/types").ITerrain|null
	 */
	terrain = null;

	/**
	 * @type import("mods/generators/types").IChunkGenerator[]
	 */
	generators = [];

	/**
	 * @type import("mods/generators/createTerrainGenerator").TerrainGenerationOptions|null
	 */
	#terrainGenerationOptions = null;

	/**
	 * @private
	 * @type {number}
	 */
	seed = -1;

	/**
	 * @type {import("base/world/World").World}
	 */
	world;

	/**
	 *
	 * @param {import("base/world/World").World} world
	 * @param {import("@jamango/content-client/lib/types/engine.ts").IEngineWorldData["chunkScene"]} o
	 */
	constructor(world, o) {
		super();
		this.matrixWorldAutoUpdate = false; //three.js only does this when you render() the scene, but we don't always render the scene on each frame tick

		this.world = world;

		this.useInitialBlankMap = o?.useInitialBlankMap ?? false;
		this.chunkSize = o?.chunkSize ?? 16;

		this.raycastLength = o?.raycastLength ?? 6;
		if (this.raycastLength === -1) this.raycastLength = Infinity;

		//add scene fog
		if (o?.fog ?? true) {
			this.fog = new Fog(o?.fogColor ? new Color(o.fogColor).getHex() : 0xedecd7);
			this.fog.nearFactor = 1 - (o?.fogFactor ?? 0.5);
		}

		this.chunks = new Vector3Map();
		this.generators = [];

		this.rebuildBatch = new Set();

		this.setMapID(o?.mapID ?? 0);

		this.searchSpawnAboveground = o?.searchSpawnAboveground ?? false;
		this.searchSpawnLimit = o?.searchSpawnLimit ?? 250;
		this.ignoreIncorrectGeneratorError = o?.ignoreIncorrectGeneratorError ?? false;

		if (o?.waterEnabled ?? true) {
			this.water = new FakeWaterRouter(this, o?.fakeWater).base;
		}
	}

	createBlockMaterials(texture) {
		if (this.mat) this.mat.dispose();
		if (this.alphaTestedMaterial) this.alphaTestedMaterial.dispose();
		if (this.translucentMaterial) this.translucentMaterial.dispose();

		this.mat = new JamangoMaterial({
			map: texture,
			ao: true,
		});
		this.alphaTestedMaterial = new JamangoMaterial({
			map: texture,
			ao: true,
			alphaTest: 0.5,
		});
		this.translucentMaterial = new JamangoMaterial({
			map: texture,
			ao: true,
			transparent: true,
			depthWrite: false,
			wboitEnabled: true,
		});
	}

	//generators must implement static name, static display, def, constructor(o), init(scene), apply(chunk), and optionally clearCache()
	//constructor must save the o arg to the def property so that saveMap() can save it
	//init(scene) can be async and it will be awaited
	//up to one generator may have a static "isTerrain" property set to true and must then implement getHeight()
	//server
	/**
	 * @private
	 * @param {(import("mods/generators/types").IChunkGenerator|import("mods/generators/types").ITerrain)[]} generators
	 * @param {boolean} showLoadingScreen
	 */
	#preReset(generators, showLoadingScreen) {
		BB.router.stopUpdateLoop();

		if (showLoadingScreen) {
			LoadingScreen.show(true);
			LoadingScreen.setText("Loading map");
		}

		this.disposeChunks();
		this.setMapID(this.mapID + 1);

		this.chunks.clear();
		this.generators.length = 0;
		this.terrain = null;

		if (!isNullish(generators)) {
			for (const gen of generators) {
				gen.init({
					seed: this.seed,
					chunkSize: this.chunkSize,
					blockTypeRegistry: this.world.blockTypeRegistry,
				});
				this.generators.push(gen);

				if (gen.isTerrain) {
					if (this.terrain) throw Error("Multiple terrain generators detected");

					this.terrain = gen;
				}
			}
		}
	}

	//server
	#midReset(changeSpawn, reorder) {
		const world = this.world;

		Net.sendToAll("scene_map", reorder);

		if (changeSpawn) {
			const s = world.server.spawn.position;
			let attempts = 0;
			s.copy(VZ);

			const isSpawnAreaFlat = () => {
				for (let x = -1; x <= 1; x++) {
					for (let z = -1; z <= 1; z++) {
						if (x === 0 && z === 0) continue;

						const y = this.getTerrainHeight(s.x + x, s.z + z);
						if (y !== s.y) return false;
					}
				}

				return true;
			};

			do {
				s.y = this.getTerrainHeight(s.x, --s.z);
				attempts++;
			} while (
				this.searchSpawnAboveground &&
				attempts <= this.searchSpawnLimit &&
				!!this.water &&
				(s.y < this.water?.height || !isSpawnAreaFlat(s))
			);
		}

		this.world.server.clearAll(true);
	}

	postReset() {
		const initialBlankMap = this.useInitialBlankMap;
		if (netState.isHost && this.mapID === 0 && !initialBlankMap) return; //wait for world script to load the map before doing anything

		Rete.loadMap(BB.world.content, this.world, false);
		CustomUIManager.load(this.world.customUI, BB.world.content.state.customUI.getAllComponents());

		// OnPeerJoin Event
		if (netState.isHost)
			for (const e of this.world.entities) {
				if (e.type.def.isPlayer && this.world.playerToPeer.has(e)) trigger.onPeerJoin(this.world, e);
			}

		BB.router.startUpdateLoop();
	}

	setMapID(mapID) {
		this.mapID = mapID;
		if (!netState.isHost) {
			this.requests = {};
			this.requestID = 0;
		}
	}

	//server
	/**
	 * @param {{seed:number|null, generation?:import("@jamango/content-client/lib/types/terrainGeneration.ts").TerrainGenerationOptions}} options
	 */
	newMap(options) {
		this.seed = options.seed ?? Date.now();
		this.#terrainGenerationOptions = options.generation;

		BlockTypeRegistry.clearMissing(this.world.blockTypeRegistry);

		const generators = createTerrainGenerator(this.#terrainGenerationOptions);
		this.#preReset(generators, true);
		this.#midReset(true);
		this.postReset();
	}

	//server
	/**
	 * @param {ArrayBuffer} data
	 * @param {import("@jamango/content-client/lib/types/terrainGeneration.ts").TerrainGenerationOptions|null} terrainGenerationOptions
	 * @param {boolean} changeSpawn
	 */
	loadMap(data, terrainGenerationOptions, changeSpawn = true, showLoadingScreen = true) {
		if (showLoadingScreen) {
			LoadingScreen.show(true);
			LoadingScreen.setText("Parsing map");
		}
		Metrics.resetMetrics();
		let save;
		try {
			save = deserializeMapFile(data);
		} catch (oops) {
			LoadingScreen.hide();
			throw oops;
		}

		this.seed = save.seed;
		this.#terrainGenerationOptions = terrainGenerationOptions;

		BlockTypeRegistry.clearMissing(this.world.blockTypeRegistry);

		const reorder = BlockTypeRegistry.createReorderBlockIdsMap(this.world.content, save.blocks);

		BlockTypeRegistry.reorderBlockIDs(this.world.blockTypeRegistry, this.world, reorder);

		const generators = createTerrainGenerator(this.#terrainGenerationOptions);
		this.#preReset(generators, showLoadingScreen);

		BlockGroups.load(this.world.blockGroups, this.world.rete, save.groups);

		SceneTree.load(this.world.sceneTree, save.sceneTree);

		for (const i in save.chunks) {
			const pos = i.split(" ");
			this.getChunk(Number(pos[0]), Number(pos[1]), Number(pos[2]), save.chunks[i]);
		}

		this.#midReset(changeSpawn, reorder);

		//all spawn events should be completed now
		this.postReset();
	}

	//server
	loadMapSameGenerators(data, changeSpawn = true) {
		this.loadMap(data, this.#terrainGenerationOptions, changeSpawn, false);
	}

	//server
	saveMap() {
		const chunks = {};
		let blocks = {};
		let groups = {};
		let sceneTree = {};

		if (netState.isHost) {
			//make it a tiny bit harder to steal saves
			const cpos = tmpVec;
			for (const c of this.chunks.entries(cpos)) {
				if (c.modified) {
					chunks[`${cpos.x} ${cpos.y} ${cpos.z}`] = c.save();
				}
			}

			blocks = BlockTypeRegistry.getBlockIndexToNameMap(this.world.blockTypeRegistry);

			groups = BlockGroups.save(this.world.blockGroups);

			sceneTree = SceneTree.save(this.world.sceneTree);
		}

		const save = {
			version: SCENE_MAP_VERSION,
			seed: this.seed,
			chunks,
			groups,
			blocks,
			sceneTree,
		};

		return serializeMapFile(save);
	}

	postUpdate() {
		for (const c of this.rebuildBatch) {
			c.build(true, false);
		}

		this.rebuildBatch.clear();
	}

	/**
	 * @returns {Chunk}
	 */
	getChunk(cx, cy, cz, data) {
		if (cx.isVector3) {
			data = cy;
			cz = cx.z;
			cy = cx.y;
			cx = cx.x;
		}

		let c = this.chunks.getRaw(cx, cy, cz);
		if (!c) c = new Chunk(this, cx, cy, cz, data);

		return c;
	}

	//returns promise if need to download and needPromise is true
	//otherwise returns a SCENE_BUILD constant
	buildChunk(cx, cy, cz, aoMainThread) {
		if (cx.isVector3) {
			cz = cx.z;
			cy = cx.y;
			cx = cx.x;
		}

		const chunk = this.getChunk(cx, cy, cz);

		if (chunk.built) {
			return SCENE_BUILD_ALREADY;
		} else {
			const buildResult = chunk.build(aoMainThread);

			return buildResult ? SCENE_BUILD_SUCCESS : SCENE_BUILD_FAIL;
		}
	}

	getChunkAt(ax, ay, az) {
		if (ax.isVector3) {
			az = ax.z;
			ay = ax.y;
			ax = ax.x;
		}

		const s = this.chunkSize;
		const cx = Math.floor(ax / s);
		const cy = Math.floor(ay / s);
		const cz = Math.floor(az / s);

		return this.getChunk(cx, cy, cz);
	}

	//change fog setting
	setFog(color, nearFactor = 0.5) {
		if (!this.fog) return;
		this.fog.color.set(color);
		this.fog.nearFactor = nearFactor;
	}

	getShape(ax, ay, az) {
		if (ax.isVector3) {
			az = ax.z;
			ay = ax.y;
			ax = ax.x;
		}

		const c = this.getChunkAt(ax, ay, az);
		const s = this.chunkSize;

		const rx = ax - c.position.x * s;
		const ry = ay - c.position.y * s;
		const rz = az - c.position.z * s;

		return c.getShape(rx, ry, rz);
	}

	getType(ax, ay, az) {
		if (ax.isVector3) {
			az = ax.z;
			ay = ax.y;
			ax = ax.x;
		}

		const c = this.getChunkAt(ax, ay, az);
		const s = this.chunkSize;

		const rx = ax - c.position.x * s;
		const ry = ay - c.position.y * s;
		const rz = az - c.position.z * s;

		return c.getType(rx, ry, rz);
	}

	getTypeID(ax, ay, az) {
		const c = this.getChunkAt(ax, ay, az);
		const s = this.chunkSize;

		const rx = ax - c.position.x * s;
		const ry = ay - c.position.y * s;
		const rz = az - c.position.z * s;

		return c.getTypeID(rx, ry, rz);
	}

	/**
	 * @returns {import("base/world/block/Type").BlockType}
	 */
	getBlockType(ax, ay, az) {
		return this.world.blockTypeRegistry.blockNameToType.get(this.getType(ax, ay, az));
	}

	//returns true if successful
	//use -1 for shape/type to mean no change to the current value
	setBlock(ax, ay, az, shape, type, triggerEvents, triggerCharacter, isNet) {
		if (ax.isVector3) {
			triggerCharacter = type;
			triggerEvents = shape;
			type = az;
			shape = ay;
			az = ax.z;
			ay = ax.y;
			ax = ax.x;
		}

		if (shape === -1 && type === -1) return true;

		const s = this.chunkSize;
		const cx = Math.floor(ax / s);
		const cy = Math.floor(ay / s);
		const cz = Math.floor(az / s);

		const c = this.getChunk(cx, cy, cz);

		const rx = ax - cx * s;
		const ry = ay - cy * s;
		const rz = az - cz * s;

		const curShape = c.getShape(rx, ry, rz);
		const curType = c.getType(rx, ry, rz);
		if (shape === -1) shape = curShape;
		if (type === -1) type = curType;
		if (ChunkUtil.discardShape(shape)) shape = BLOCK_AIR;
		if (type === "bb.block.air") {
			shape = BLOCK_AIR;
			type = curType;
		}

		if (shape === curShape && type === curType) return true;

		if (!isNet)
			Net.sendToAll("scene_block", [
				this.mapID,
				ax,
				ay,
				az,
				shape,
				type,
				triggerEvents,
				triggerCharacter?.entityID,
			]);

		if (
			!c.setBlock(
				rx,
				ry,
				rz,
				shape,
				type,
				triggerEvents && (netState.isHost || !isNet),
				triggerCharacter,
			)
		)
			return false;

		const rebuildBatch = this.rebuildBatch;

		if (c.built) {
			rebuildBatch.add(c);
		}

		let neighbor;

		if (rx === 0) {
			neighbor = this.getChunk(cx - 1, cy, cz);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		if (rx === s - 1) {
			neighbor = this.getChunk(cx + 1, cy, cz);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		if (ry === 0) {
			neighbor = this.getChunk(cx, cy - 1, cz);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		if (ry === s - 1) {
			neighbor = this.getChunk(cx, cy + 1, cz);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		if (rz === 0) {
			neighbor = this.getChunk(cx, cy, cz - 1);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		if (rz === s - 1) {
			neighbor = this.getChunk(cx, cy, cz + 1);
			if (neighbor.built) rebuildBatch.add(neighbor);
		}

		return true;
	}

	respawnBlock(ax, ay, az, container, mapLoad) {
		// fires rete "spawn" event for blocks
		trigger.onBlockTypeEvent(NODE.OnBlockSpawn, this.world, ax, ay, az);

		// TODO: this is still required to keep alive the current non-rete spawn events (guns and such)
		this.dispatchBlockEvent(
			NET_SERVER,
			{ type: "spawn", container, x: ax, y: ay, z: az, mapLoad },
			null,
			null,
			false,
		);
	}

	async dispatchBlockEvent(side, e, blockType, blockGroup, dispatchGroup = true) {
		const promises = [];

		if (!isNullish(e.x))
			promises.push((blockType ?? this.getBlockType(e.x, e.y, e.z)).dispatcher.dispatchEvent(side, e));

		if (dispatchGroup && (blockGroup || e.group || !isNullish(e.x)))
			promises.push(this.dispatchBlockGroupEvent(side, e, blockGroup));

		await Promise.all(promises);
	}

	dispatchBlockGroupEvent(side, e, blockGroup) {
		return Promise.resolve(
			(blockGroup ?? e.group) /* ?? this.getGroup(e.x, e.y, e.z)*/?.dispatcher
				.dispatchEvent(side, e),
		);
	}

	//this returns the terrain height at (ax, az), not the block y position
	getTerrainHeight(ax, az) {
		return this.terrain?.getHeight(ax, az) ?? 0;
	}

	setGravity(x, y, z) {
		// TODO: move setGravity out of ChunkScene
		Physics.setGravity(this.world.physics, x, y, z);

		this.world.onGravityChange();
	}

	initCommandListeners() {
		const ext = this;

		Net.listen("scene_block", function (a, _world, peer) {
			blockModRX(peer, "scene_block", a, function () {
				a[a.length - 1] = ext.world.getEntity(a[a.length - 1]);
				ext.setBlock(...a, true);
			});
		});

		function blockModRX(peer, f, a, cb) {
			//ignore straggling blocks sent/received around the time of a map change
			if (a[0] !== ext.mapID) return;

			if (netState.isHost) {
				const perms = getPeerMetadata(peer).permissions;
				if (!perms.canUseIndividualBlocks && !perms.canUsePencil) return;

				Net.sendToAll(f, a, peer);
			} else {
				if (!ext.chunks.get(tmpVec.fromArray(a, 1).divideScalar(ext.chunkSize).floor())) return; //don't bother because the chunk hasn't been downloaded
			}

			a.shift();
			cb();
		}

		if (!netState.isHost) {
			Net.listen("scene_map", function (reorder) {
				ext.disposeChunks();
				ext.world.clearAllTimers(true);

				for (let i = ext.world.entities.length - 1; i >= 0; i--) {
					const e = ext.world.entities[i];
					if (e.authority.state.public >= 2) e.dispose();
				}

				BlockTypeRegistry.clearMissing(ext.world.blockTypeRegistry);

				ext.setMapID(ext.mapID + 1);

				if (reorder) {
					BlockTypeRegistry.reorderBlockIDs(ext.world.blockTypeRegistry, ext.world, reorder);
				}
			});
		}
	}

	createRequestPromise() {
		const promise = createRequestPromise();
		const id = this.requestID++;
		this.requests[id] = promise;

		promise.then((v) => {
			delete this.requests[id];
			return v;
		});

		return { promise, id };
	}

	listenRequestPromise(name, cb) {
		const ext = this;

		Net.listen(name, function (a, _world, peer) {
			const mapID = a.shift();
			if (mapID !== ext.mapID) return;

			const requestID = a.shift();
			if (netState.isHost) {
				Net.send(name, [mapID, requestID, cb(...a)], peer);
			} else {
				ext.requests[requestID].resolve(a[0]);
			}
		});
	}

	rebuildChunks() {
		for (const c of this.chunks.values()) c.dispose(CHUNK_DISPOSE_DEFAULT);
		this.world.chunks = ChunkManager.init();
		// TODO: remove below funciton once invoke has been moved to ChunkManager
		this.resetChunkInvokes();
	}

	disposeChunks() {
		for (const c of this.chunks.values()) c.dispose(CHUNK_DISPOSE_BYEBYE);
		this.world.chunks = ChunkManager.init();
		// TODO: remove below funciton once invoke has been moved to ChunkManager
		this.resetChunkInvokes();
	}

	resetChunkInvokes() {
		// this function exists so that chunk invokation by entities can be re-triggered if chunks have been rebuilt
		// if this function does not run after rebuild/disposeChunks, then an entitiy may not force reinvokes on nearby chunks, and not get a loading screen
		// as a result, players dropped through the ground when updating a block definition
		// TODO: move chunk invoker out of chunk manager, then the chunkManager.init() fixes this automatically. can remove ths function once done.
		const entities = this.world.entities;
		for (const e of entities) {
			if (e.chunkInvoker === undefined) continue;
			const inv = e.chunkInvoker.state;
			inv.lastX = inv.lastY = inv.lastZ = Infinity;
		}
	}

	dispose() {
		this.water?.client.dispose();
		this.disposeChunks();
		this.mat.dispose();
		this.alphaTestedMaterial.dispose();
		this.translucentMaterial.dispose();

		this.palette?.dispose();
	}
}
