const COMMIT_HASH = "06bf1e5"; // Update the commit hash to force invalidation of the CDN cache in the user's browsers.

/**
 * getStaticFile
 * For usage in the frontend, make sure to add `crossorigin="anonymous"` to the image tag, and / or `mode: "cors"` to the fetch request.
 * So that headers will be the same when the resource is later on refetched by the engine.
 *
 * @param {string} path URL path to be processed
 * @param {boolean} enableCacheControl Whether to enable cache control for S3 URLs
 * @returns {string}
 * - processed URL for S3 URLs
 * - original URL for other URLs
 */
export function getStaticFile(path: string, enableCacheControl = true) {
	if (enableCacheControl) {
		if (/^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)*amazonaws\.com/.test(path)) {
			const url = new URL(path);

			if (!url.searchParams.has("hash")) {
				// S3 CORS fix:
				// Adding a query param will prevent 'misbehaving' behavior of Chrome
				// to allow proper headers to be present on both preflight and actual request for S3 requests.
				// Details:
				//https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
				//https://stackoverflow.com/a/77830298/14621380
				//https://bugs.chromium.org/p/chromium/issues/detail?id=409090#c30
				//https://web.archive.org/web/20210628084018/https://forums.aws.amazon.com/thread.jspa?threadID=342401
				url.searchParams.append("hash", COMMIT_HASH);
			}
		}
	}

	if (path.startsWith("http:") || path.startsWith("https:")) {
		return path;
	}

	return `${(globalThis as any).globalEnv?.FRONTEND_URL}/${path}`;
}
