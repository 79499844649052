import type {
	CustomUIComponent,
	OptionType,
	PrebuiltCustomUI,
	PrebuiltUIComponentIndexes,
	TextAlignmentOption,
	UIPosition,
} from "../types";

export const CUSTOM_UI_COMPONENTS_SELECTIONS: PrebuiltCustomUI[] = [
	{ name: "Basic Indicator", value: "basic-indicator", id: 1 },
	{ name: "Leaderboard (Local State)", value: "leaderboard-local", id: 2 },
	{ name: "Health Bar", value: "health-bar", id: 3 },
	{ name: "Progress Bar", value: "progress-bar", id: 4 },
];

export const UI_POSITIONS: OptionType<UIPosition>[] = [
	{
		id: 1,
		name: "Top",
		value: "top",
	},
	{
		id: 2,
		name: "Left",
		value: "left",
	},
	{
		id: 3,
		name: "Right",
		value: "right",
	},
	{
		id: 4,
		name: "Bottom",
		value: "bottom",
	},
	{
		id: 5,
		name: "Top Left",
		value: "top-left",
	},
	{
		id: 6,
		name: "Top Right",
		value: "top-right",
	},
	{
		id: 7,
		name: "Bottom Left",
		value: "bottom-left",
	},
	{
		id: 8,
		name: "Bottom Right",
		value: "bottom-right",
	},
	{
		id: 9,
		name: "Center",
		value: "center",
	},
];

export const FONT_OPTIONS: OptionType<string>[] = [
	{ id: 1, name: "Clash Grotesk", value: `"ClashGrotesk"` },
	{ id: 2, name: "Midfield Rounded", value: `"Midfield Rounded"` },
	{ id: 3, name: "Inter Tight", value: `"Inter Tight"` },
	{ id: 4, name: "Orbitron", value: `"Orbitron"` },
	{ id: 5, name: "Space Mono", value: `"Space Mono"` },
	{ id: 6, name: "Electrolize", value: `"Electrolize"` },
	{ id: 7, name: "Archivo", value: `"Archivo"` },
	{ id: 8, name: "Anek Devanagari", value: `"Anek Devanagari"` },
	{ id: 9, name: "Anton", value: `"Anton"` },
	{ id: 10, name: "Black Ops One", value: `"Black Ops One"` },
	{ id: 11, name: "Forum", value: `"Forum"` },
	{ id: 12, name: "Jersey 10", value: `"Jersey 10"` },
	{ id: 13, name: "Luckiest Guy", value: `"Luckiest Guy"` },
	{ id: 14, name: "New Amsterdam", value: `"New Amsterdam"` },
	{ id: 15, name: "Righteous", value: `"Righteous"` },
	{ id: 16, name: "Urbanist", value: `"Urbanist"` },
];

export const FONT_WEIGHT_OPTIONS: OptionType<number>[] = [
	{ id: 1, name: "Light", value: 300 },
	{ id: 2, name: "Normal", value: 400 },
	{ id: 3, name: "Semibold", value: 600 },
	{ id: 4, name: "Bold", value: 700 },
];

export const TEXT_ALIGNMENT_OPTIONS: Omit<OptionType<TextAlignmentOption>, "id">[] = [
	{ name: "Left", value: "left" },
	{ name: "Right", value: "right" },
	{ name: "Center", value: "center" },
	{ name: "Justify", value: "justify" },
];

const DEFAULT_QUAD_VALUE = {
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
};

const DEFAULT_BORDER_RADIUS_VALUE = {
	top: 12,
	bottom: 12,
	left: 12,
	right: 12,
};

export const CORE_DEFAULT_STYLES = {
	fontFamily: {
		name: "Font Family",
		defaultValue: FONT_OPTIONS[12].value,
		value: {
			lg: FONT_OPTIONS[12].value,
			sm: FONT_OPTIONS[12].value,
		},
		type: "string",
		styleName: "fontFamily",
		tailwindName: "font-",
		options: FONT_OPTIONS,
		group: "typography",
	},
	fontSize: {
		name: "Font Size",
		defaultValue: 36,
		value: {
			lg: 36,
			sm: 12,
		},
		type: "number",
		styleName: "fontSize",
		tailwindName: "text-",
		group: "typography",
	},
	fontWeight: {
		name: "Font Weight",
		defaultValue: 400,
		value: {
			lg: 400,
			sm: 400,
		},
		type: "number",
		styleName: "fontWeight",
		tailwindName: "font-",
		options: FONT_WEIGHT_OPTIONS,
		group: "typography",
	},
	color: {
		name: "Text Color",
		defaultValue: "#fff",
		value: { lg: "#fff", sm: "#fff" },
		type: "color",
		styleName: "color",
		tailwindName: "text-",
		group: "typography",
	},
	backgroundColor: {
		name: "Background Color",
		defaultValue: "transparent",
		value: { lg: "transparent", sm: "transparent" },
		type: "color",
		styleName: "backgroundColor",
		tailwindName: "bg-",
		removable: true,
		group: "box-style",
	},
	padding: {
		name: "Padding",
		defaultValue: DEFAULT_QUAD_VALUE,
		value: { lg: DEFAULT_QUAD_VALUE, sm: DEFAULT_QUAD_VALUE },
		type: "number",
		styleName: "padding",
		tailwindName: "p-",
		quadValue: true,
		group: "box-style",
	},
	borderRadius: {
		name: "Border Radius",
		defaultValue: DEFAULT_BORDER_RADIUS_VALUE,
		value: { lg: DEFAULT_BORDER_RADIUS_VALUE, sm: DEFAULT_BORDER_RADIUS_VALUE },
		type: "number",
		styleName: "borderRadius",
		tailwindName: "rounded-",
		quadValue: true,
		group: "box-style",
	},
	margin: {
		name: "Margin",
		defaultValue: DEFAULT_QUAD_VALUE,
		value: { lg: DEFAULT_QUAD_VALUE, sm: DEFAULT_QUAD_VALUE },
		type: "number",
		styleName: "margin",
		tailwindName: "m-",
		quadValue: true,
		group: "box-style",
	},
	position: {
		name: "Position",
		defaultValue: DEFAULT_QUAD_VALUE,
		value: { lg: DEFAULT_QUAD_VALUE, sm: DEFAULT_QUAD_VALUE },
		type: "number",
		styleName: "position",
		tailwindName: "",
		quadValue: true,
		group: "box-style",
	},
	gap: {
		name: "Gap between items",
		defaultValue: 16,
		value: {
			lg: 16,
			sm: 16,
		},
		type: "number",
		styleName: "gap",
		tailwindName: "gap-",
		group: "box-style",
	},
	textShadow: {
		name: "Text Shadow",
		defaultValue: 3,
		value: {
			lg: 3,
			sm: 3,
		},
		type: "number",
		styleName: "textShadow",
		tailwindName: "text-shadow-",
		group: "typography",
	},
	textShadowColor: {
		name: "Text Shadow Color",
		defaultValue: "#000000",
		value: { lg: "#000000", sm: "#000000" },
		type: "color",
		styleName: "textShadowColor",
		group: "typography",
	},
	textStroke: {
		name: "Text Stroke",
		defaultValue: 0,
		value: {
			lg: 0,
			sm: 0,
		},
		type: "number",
		styleName: "textStroke",
		tailwindName: "text-stroke-",
		group: "typography",
	},
	textStrokeColor: {
		name: "Text Stroke Color",
		defaultValue: "#000000",
		value: { lg: "#000000", sm: "#000000" },
		type: "color",
		styleName: "textStrokeColor",
		group: "typography",
	},
} as const;

export const DEFAULT_CUSTOM_UI_COMPONENTS: Record<
	PrebuiltUIComponentIndexes,
	Omit<CustomUIComponent, "id">
> = {
	"basic-indicator": {
		name: "Basic Indicator",
		type: "basic-indicator",
		show: false,
		position: {
			value: "top-right",
			options: UI_POSITIONS,
			type: "string",
		},
		inputs: {
			text: {
				name: "Text",
				type: "string",
				defaultValue: "",
				value: "Text:",
				inputKey: "text",
			},
			value: {
				name: "Value",
				type: "string",
				defaultValue: "",
				value: "",
				inputKey: "value",
			},
		},
		styles: {
			...CORE_DEFAULT_STYLES,
			textAlign: {
				name: "Text Alignment",
				defaultValue: "left",
				value: { lg: "left", sm: "left" },
				options: TEXT_ALIGNMENT_OPTIONS,
				type: "string",
				styleName: "textAlign",
				tailwindName: "text-",
				group: "typography",
			},
		},
	},
	"health-bar": {
		name: "Health Bar",
		type: "health-bar",
		show: false,
		position: {
			value: "top-right",
			options: UI_POSITIONS,
			type: "string",
		},
		inputs: {
			text: {
				name: "Text",
				type: "string",
				defaultValue: "Health",
				value: "Health",
				inputKey: "text",
			},
		},
		styles: {
			...CORE_DEFAULT_STYLES,
			backgroundColorOut: {
				name: "Background Color (outside)",
				defaultValue: "#111827",
				value: { lg: "#111827", sm: "#111827" },
				type: "color",
				styleName: "backgroundColorOut",
				group: "box-style",
			},
			backgroundColorIn: {
				name: "Background Color (inside)",
				defaultValue: "#03ff00",
				value: { lg: "#03ff00", sm: "#03ff00" },
				type: "color",
				styleName: "backgroundColorIn",
				group: "box-style",
			},
			height: {
				name: "Height",
				defaultValue: 16,
				value: { lg: 16, sm: 12 },
				type: "number",
				styleName: "height",
				group: "box-style",
			},
			width: {
				name: "Width",
				defaultValue: 256,
				value: { lg: 256, sm: 120 },
				type: "number",
				styleName: "width",
				group: "box-style",
			},
		},
	},
	"progress-bar": {
		name: "Progress Bar",
		type: "progress-bar",
		show: false,
		position: {
			value: "top-right",
			options: UI_POSITIONS,
			type: "string",
		},
		inputs: {
			text: {
				name: "Text",
				type: "string",
				defaultValue: "Loading",
				value: "Progress:",
				inputKey: "text",
			},
			value: {
				name: "Value",
				type: "number",
				defaultValue: 50,
				value: 50,
				inputKey: "value",
			},
			maxValue: {
				name: "Max Value",
				type: "number",
				defaultValue: 100,
				value: 100,
				inputKey: "maxValue",
			},
		},
		styles: {
			...CORE_DEFAULT_STYLES,
			backgroundColorOut: {
				name: "Background Color (outside)",
				defaultValue: "#111827",
				value: { lg: "#111827", sm: "#111827" },
				type: "color",
				styleName: "backgroundColorOut",
				group: "box-style",
			},
			backgroundColorIn: {
				name: "Background Color (inside)",
				defaultValue: "#22c55e",
				value: { lg: "#22c55e", sm: "#22c55e" },
				type: "color",
				styleName: "backgroundColorIn",
				group: "box-style",
			},
			height: {
				name: "Height",
				defaultValue: 16,
				value: { lg: 16, sm: 12 },
				type: "number",
				styleName: "height",
				group: "box-style",
			},
			width: {
				name: "Width",
				defaultValue: 256,
				value: { lg: 256, sm: 120 },
				type: "number",
				styleName: "width",
				group: "box-style",
			},
		},
	},
	"leaderboard-local": {
		name: "Leaderboard from Local State",
		type: "leaderboard-local",
		show: false,
		position: {
			value: "top",
			options: UI_POSITIONS,
			type: "string",
		},
		inputs: {
			title: {
				name: "Title",
				type: "string",
				defaultValue: "Leaderboard",
				value: "Leaderboard",
				inputKey: "title",
			},
			rank: {
				name: "Rank Label",
				type: "string",
				defaultValue: "Rank",
				value: "Rank",
				inputKey: "rank",
			},
			player: {
				name: "User Label",
				type: "string",
				defaultValue: "Name",
				value: "Name",
				inputKey: "player",
			},
			stateData: {
				name: "State Data",
				type: "array",
				defaultValue: {
					columns: ["Rank", "Name", "Score"],
					rows: [
						[1, "Emmet", 100],
						[2, "Missy", 60],
						[3, "Mango Bro", 30],
					],
				},
				value: null,
				inputKey: "stateData",
				hidden: true,
			},
		},
		styles: {
			...CORE_DEFAULT_STYLES,
			padding: {
				name: "Padding",
				defaultValue: { top: 16, bottom: 16, left: 16, right: 16 },
				value: {
					lg: { top: 16, bottom: 16, left: 16, right: 16 },
					sm: { top: 16, bottom: 16, left: 16, right: 16 },
				},
				type: "number",
				styleName: "padding",
				tailwindName: "p-",
				quadValue: true,
				group: "box-style",
			},
			borderRadius: {
				name: "Border Radius",
				defaultValue: { top: 16, bottom: 16, left: 16, right: 16 },
				value: {
					lg: { top: 16, bottom: 16, left: 16, right: 16 },
					sm: { top: 16, bottom: 16, left: 16, right: 16 },
				},
				type: "number",
				styleName: "borderRadius",
				tailwindName: "rounded-",
				quadValue: true,
				group: "box-style",
			},
			margin: {
				name: "Margin",
				defaultValue: DEFAULT_QUAD_VALUE,
				value: { lg: DEFAULT_QUAD_VALUE, sm: DEFAULT_QUAD_VALUE },
				type: "number",
				styleName: "margin",
				tailwindName: "m-",
				quadValue: true,
				group: "box-style",
			},
		},
	},
};

export const formatCustomUIStyles = (styles: Record<string, any>, customUI: string) => {
	const formattedTailwindStyles = [];
	const styleConfiguration = {} as Record<string, string>;

	for (const styleProperty in styles) {
		const currentProperty = styles[styleProperty];
		const generalVarName = `--${customUI}-${currentProperty.styleName}`;

		if (currentProperty.styleName === "position") {
			styleConfiguration[`${generalVarName}-top`] =
				currentProperty.value["lg"]["top"] !== 0
					? `${currentProperty.value["lg"]["top"]}px`
					: "initial";
			styleConfiguration[`${generalVarName}-bottom`] =
				currentProperty.value["lg"]["bottom"] !== 0
					? `${currentProperty.value["lg"]["bottom"]}px`
					: "initial";
			styleConfiguration[`${generalVarName}-left`] =
				currentProperty.value["lg"]["left"] !== 0
					? `${currentProperty.value["lg"]["left"]}px`
					: "initial";
			styleConfiguration[`${generalVarName}-right`] =
				currentProperty.value["lg"]["right"] !== 0
					? `${currentProperty.value["lg"]["right"]}px`
					: "initial";

			formattedTailwindStyles.push(`${generalVarName}-top`);
			formattedTailwindStyles.push(`${generalVarName}-bottom`);
			formattedTailwindStyles.push(`${generalVarName}-left`);
			formattedTailwindStyles.push(`${generalVarName}-right`);
		} else if (["margin", "padding", "borderRadius"].includes(currentProperty.styleName)) {
			styleConfiguration[generalVarName] = `
				${currentProperty.value["lg"]["top"]}px
				${currentProperty.value["lg"]["right"]}px
				${currentProperty.value["lg"]["bottom"]}px
				${currentProperty.value["lg"]["left"]}px
			`;
		} else if (
			["fontSize", "width", "height", "gap", "textShadow", "textStroke"].includes(
				currentProperty.styleName,
			)
		) {
			styleConfiguration[generalVarName] = currentProperty.value["lg"] + "px";
		} else {
			styleConfiguration[generalVarName] = currentProperty.value["lg"];
		}

		formattedTailwindStyles.push(generalVarName);

		for (const breakpoint in currentProperty.value) {
			const varName = `--${customUI}-${currentProperty.styleName}-${breakpoint}`;
			const cssVarName = `[var(${varName})]`;

			if (["margin", "padding", "borderRadius"].includes(currentProperty.styleName)) {
				styleConfiguration[varName] = `
					${currentProperty.value[breakpoint]["top"]}px
					${currentProperty.value[breakpoint]["right"]}px
					${currentProperty.value[breakpoint]["bottom"]}px
					${currentProperty.value[breakpoint]["left"]}px
				`;
			} else if (
				["fontSize", "width", "height", "gap", "textShadow", "textStroke"].includes(
					currentProperty.styleName,
				)
			) {
				styleConfiguration[varName] = currentProperty.value[breakpoint] + "px";
			} else {
				styleConfiguration[varName] = currentProperty.value[breakpoint];
			}

			formattedTailwindStyles.push(`${breakpoint}:${currentProperty.tailwindName}${cssVarName}`);
		}
	}

	return { ...styleConfiguration, paintOrder: "stroke fill" };
};

export const getCustomUIPosition = (position: UIPosition, isLivePreview = false) => {
	switch (position) {
		case "top":
			return "left-1/2 -translate-x-1/2";
		case "bottom":
			if (isLivePreview) return "bottom-24 left-1/2 -translate-x-1/2";
			return "bottom-0 left-1/2 -translate-x-1/2";
		case "left":
			return "left-0 top-1/2 -translate-y-1/2";
		case "right":
			return "right-0 top-1/2 -translate-y-1/2";
		case "top-left":
			return "left-0 top-0";
		case "bottom-left":
			if (isLivePreview) return "left-4 bottom-12";
			return "left-0 bottom-0";
		case "top-right":
			if (isLivePreview) return "right-4 top-12";
			return "right-0 top-0";
		case "bottom-right":
			if (isLivePreview) return "right-4 bottom-12";
			return "right-0 bottom-0";
		case "center":
			return "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2";
	}
};
