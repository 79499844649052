//network state
export const NET_OFFLINE = 0;
export const NET_AWAIT = 1;
export const NET_SERVER = 2;
export const NET_CLIENT = 3;

//turn settings
export const NET_TURN_OFF = 0; //Disable use of a TURN relay server (use on a dedicated server). Using this on one side will also disable it on the other side
export const NET_TURN_ON = 1; //Allow but don't force use of a TURN SERVER. This is default behavior.
export const NET_TURN_ONLY = 2; //Force use of a TURN relay server (bad idea; for testing only). Must be enabled on both server and client sides

//misc
export const NET_IS_BROWSER = !globalThis.process; //not very robust but seems to do the trick. node+bun both have this global
export const NET_TIMEOUT = 10 * 1000; //in ms
