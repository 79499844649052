import { NODE_TYPE_ID } from "base/rete/Constants";
import { NODE } from "../InternalNameMap";
import { node } from "../Types";
import { getItemSelectOptions } from "./Items";
import type { World } from "base/world/World";

export const GLOBAL_TRIGGERS = [
	// General
	node({
		id: "8223e04e-89a3-418a-9830-3d8b1f25b237",
		name: "On Game Start",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when the game starts.",
		outputs: {
			exec: { type: "exec" },
		},
	}),
	node({
		id: "9aa1181a-a303-4007-8e33-54b10654e0e6",
		name: "On Game Tick",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when the game ticks.",
		outputs: {
			exec: { type: "exec" },
			deltaTime: { name: "Delta Time", type: "number" },
		},
		resolve: (_inputs, ctx) => {
			return {
				deltaTime: ctx.info.deltaTime,
			};
		},
	}),
	node({
		id: "0007-00-0001",
		name: "On World State Change",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when a world state changes.",
		inputs: {
			name: {
				name: "State",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			exec: { type: "exec" },
			value: { name: "Value", type: "any", structure: "any" },
		},
		resolve(_inputs, ctx) {
			return { value: ctx.info.value };
		},
	}),
	node({
		id: "0007-00-0002",
		name: "On Peer Join",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when a peer joins the world.",
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return { peer: ctx.info.peer };
		},
	}),
	node({
		id: "0007-00-0003",
		name: "On Peer Leave",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when a peer leaves the world.",

		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return { peer: ctx.info.peer };
		},
	}),
	node({
		id: "0007-00-0005",
		name: "On Entity State Change",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when an entities state value changes.",
		inputs: {
			name: {
				name: "State",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Entity", type: "entity" },
			value: { name: "Value", type: "any", structure: "any" },
		},
		resolve(_inputs, ctx) {
			// TODO: migrate this "peer" output to "entity" on nodes
			return { peer: ctx.info.entity, value: ctx.info.value };
		},
	}),
	node({
		id: "8610b88a-6d8d-489d-9ca8-ca76b0db9f69",
		name: "On Peer Persistent State Change",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when a peer's persistent state changes.",
		inputs: {
			name: {
				name: "State",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
			value: { name: "Value", type: "any" },
		},
		resolve(_inputs, ctx) {
			return { peer: ctx.info.peer, value: ctx.info.value };
		},
	}),
	node({
		id: NODE.OnChatSent,
		name: "On Chat Sent",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: `Triggered when a peer sends a message in the chat that matches the given text. You can add a variable by using "{}".`,
		info: [
			"Use proper spacing between variables",
			"Example chat phrase => Example chat message",
			"spawn: {gun} {type} => spawn: rifle modern",
			"command {type} => command killall",
		],
		dynamic: true,
		controls: {
			name: {
				name: "Chat Phrase",
				type: "string",
				control: "string",
				config: {
					onChange: ({ value, node, editor }: { value: string; node: any; editor: any }) => {
						const placeholders = String(value).match(/{(.*?)}/g);

						const outputs: Record<string, unknown> = {};

						if (placeholders) {
							for (const placeholder of placeholders) {
								const name = placeholder.replace(/{|}/g, "");

								outputs[`_${name}`] = { name, type: "string" };
							}
						}

						editor.setDynamicDefNodeSockets(node.id, { outputs });
					},
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
		},
		resolve: (_inputs, ctx) => {
			const dynamicOutputs = Object.fromEntries(
				Object.entries(ctx.info.obj).map(([key, value]) => {
					return [`_${key}`, value];
				}),
			);

			return { peer: ctx.info.peer, ...dynamicOutputs };
		},
	}),

	// Control shortcuts
	node({
		id: "0007-10-0001",
		name: "On Control Press",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description:
			"Triggered when a peer presses a certain key combination. You can combine multiple keys by using '+' sign (e.g. 'shift + p'). In the future, counterpart for mobile inputs will be added.",
		inputs: {
			name: {
				name: "Key",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return { peer: ctx.info.peer };
		},
	}),
	node({
		id: "0007-10-0003",
		name: "On Control Release",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description:
			"Triggered when a peer releases a certain key combination. You can combine multiple keys by using '+' sign (e.g. 'shift + p'). In the future, counterpart for mobile inputs will be added.",
		controls: {
			name: {
				name: "Key",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return { peer: ctx.info.peer };
		},
	}),

	// Item use triggers
	node({
		id: "88181622-c94f-4786-85f1-e89f1710caca",
		name: "On Item Use",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when an item is used.",
		inputs: {
			item: {
				name: "Item",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) => getItemSelectOptions(ctx.world),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			item: { name: "Item", type: "entity" },
			primary: { name: "Primary", type: "boolean" },
			secondary: { name: "Secondary", type: "boolean" },
		},
		resolve(_inputs, ctx) {
			return {
				item: ctx.info.item,
				character: ctx.info.character,
				primary: ctx.info.primary,
				secondary: ctx.info.secondary,
			};
		},
	}),
	node({
		id: "22d71e11-aca9-46f4-be32-343ca8fbb9b9",
		name: "On Item Unuse",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when an item is un-used.",
		inputs: {
			item: {
				name: "Item",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) => getItemSelectOptions(ctx.world),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			item: { name: "Item", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return {
				item: ctx.info.item,
				character: ctx.info.character,
			};
		},
	}),
	node({
		id: "5b54250d-017c-47f5-8617-0b98fb04e039",
		name: "On Item Ranged Weapon Fire",
		type: NODE_TYPE_ID.entryPointTrigger.global,
		description: "Triggered when a ranged weapon is fired.",
		inputs: {
			item: {
				name: "Item",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) => getItemSelectOptions(ctx.world),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			item: { name: "Item", type: "entity" },
		},
		resolve(_inputs, ctx) {
			return {
				item: ctx.info.item,
				character: ctx.info.character,
			};
		},
	}),
];
