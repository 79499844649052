import { Vector3 } from "three";

export class CharacterSpawnComponent {
	name = "spawn";

	def: {
		pos: Vector3;
		angle: number;
	};

	/**
	 * Initializes a new instance of the SpawnComponent.
	 *
	 * @param x - The x-coordinate of the spawn position.
	 * @param y - The y-coordinate of the spawn position.
	 * @param z - The z-coordinate of the spawn position.
	 * @param angle - the angle of the spawn position in radians
	 */
	constructor(x: number, y: number, z: number, angle: number) {
		this.def = {
			pos: new Vector3(x, y, z),
			angle,
		};
	}
}
