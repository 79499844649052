import type { EventSourceType } from "@jamango/content-service/types/index.ts";
import { createLogger, delay } from "@jamango/helpers";

type EventName = "game:dedi:peerjoined" | "game:dedi:peerleave" | `game:world:${string}:nodes:${string}`;

const logger = createLogger("Analytics");

const DEFAULT_SEND_INTERVAL = 5_000; //ms
const DEFAULT_BATCH_SIZE = 50;

type Options = {
	url: string;
	buildVersion: string;
	batchSize?: number;
	sendInterval?: number;
	clientTrackingImpl?: (events: AnalyticsEvent[]) => void;
};

export type AnalyticsEvent = [
	ts: number,
	name: string,
	value: number,
	meta: { userId?: string; worldId?: string; serverId?: string; sourceType?: EventSourceType } & Record<
		string,
		string | number
	>,
];

const _queue: AnalyticsEvent[] = [];

let _options: Options | null = null;

const _identityData: { userId?: string; serverId?: string; sourceType?: EventSourceType } = {};
let _sending = false;

async function trySend() {
	const url = _options?.url;

	if (!url) throw new Error(`No url provided`);

	const apiEndpoint = (url.endsWith("/") ? url.slice(0, -1) : url) + "/api/analytics/track";

	if (_sending) return;
	if (_queue.length === 0) return;

	const batchSize = _options?.batchSize ?? DEFAULT_BATCH_SIZE;
	const events = _queue.slice(0, batchSize);

	_sending = true;

	try {
		const res = await fetch(apiEndpoint, {
			method: "POST",
			body: JSON.stringify({ data: events }),
			headers: { "Content-Type": "application/json", Accept: "application/json" },
		});

		if (res.status !== 200) {
			return;
		}

		if (_options?.clientTrackingImpl) {
			_options.clientTrackingImpl(events);
		}

		_queue.splice(0, events.length);
	} catch {
		//TODO repeat X times
	} finally {
		_sending = false;
	}
}

async function start() {
	while (true) {
		try {
			await trySend();
		} finally {
			await delay(_options?.sendInterval ?? DEFAULT_SEND_INTERVAL);
		}
	}
}

function flush() {
	trySend();
}

function init(options: Options) {
	if (_options) throw new Error(`Analytics has already been initialized`);
	_options = options;

	logger.info("Analytics initialized:", options);

	start();
}

function identity(data: { userId?: string; serverId?: string; sourceType?: EventSourceType }) {
	_identityData.userId = data.userId;
	_identityData.serverId = data.serverId;
	_identityData.sourceType = data.sourceType;
}

function track(name: EventName, value: number, meta: AnalyticsEvent[3] = {}) {
	if (!_options) {
		throw new Error("Analytics has not been initialized");
	}

	meta.userId ??= _identityData.userId;
	meta.serverId ??= _identityData.serverId;
	meta.sourceType ??= _identityData.sourceType;
	meta.buildVersion = _options.buildVersion;

	_queue.push([Date.now(), name, value, meta]);
}

export const Analytics = {
	init,
	identity,
	track,
	flush,
};
