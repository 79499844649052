import type { Input } from "@jamango/engine/Input.ts";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { VY } from "base/util/math/Math";
import * as Physics from "base/world/Physics";
import type { Character } from "base/world/entity/Character";
import { UI } from "client/dom/UI";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

const _raycastPosition = new Vector3();
const _raycastBlockResult = Physics.initRaycastBlockResult();

export function playerMovementInputUpdate(entity: Character, stepDeltaTime: number, input: Input) {
	if (!canUpdateSystem(entity, input)) return;

	let stopCrouch = true;

	if (input.pointerLocked && !entity.isDead()) {
		//sprinting
		entity.setSprinting(input.isSprinting);

		//toggle flying
		if (!input.requestFlying && entity.movement.state.isFlying) {
			entity.setFlying(false);
		} else if (input.requestFlying && !entity.movement.state.isFlying) {
			const peer = entity.world.playerToPeer.get(entity);
			if (peer && getPeerMetadata(peer).permissions.canFly) entity.setFlying(true);
		}

		//horizontal movement
		if (entity.movement.def.canMoveHorizontally || entity.movement.state.isFlying) {
			entity.movement.state.inputDisplacement.x += input.nipple.x;
			entity.movement.state.inputDisplacement.z -= input.nipple.y;
		}

		if (entity.movement.state.isFlying) {
			if (input.isFlyingDown)
				entity.movement.state.inputDisplacement.y--; //fly down
			else if (input.isFlyingUp) entity.movement.state.inputDisplacement.y++; //fly up
		} else {
			if (entity.movement.def.canCrouch && input.isCrouching) {
				//crouch
				entity.setCrouch(true);
				stopCrouch = false;
			} else if (input.isJumping) {
				//jump - all checks are taken care of in here
				entity.jump();
			}
		}
	}

	if (entity.movement.state.isCrouching && stopCrouch) {
		let ableToStopCrouching = !entity.isDead();

		//the code below prevents character from standing up when crouching underneath obstacle
		if (ableToStopCrouching && !entity.movement.state.isFlying) {
			const raycastPosition = _raycastPosition.copy(entity.position);

			const { hit, worldPos } = Physics.raycastBlock(
				entity.world.physics,
				raycastPosition,
				VY,
				entity.size.state.height,
				entity,
				true,
				_raycastBlockResult,
			);

			if (hit && worldPos.y > entity.position.y) {
				ableToStopCrouching = false;
			}

			if (ableToStopCrouching) {
				entity.setCrouch(false);
			}
		}
	}

	// if we're the local entity, update UI tutorial stuff
	if (netState.isClient && entity.isLocalInputTarget()) {
		if (input.isJumping) UI.state.helpers().markJumpKnown();
		if (entity.movement.state.isSprinting) UI.state.helpers().markSprintKnown();
		// TODO: flying helpers currently not working, move this into input handling
		// if (input.playerRequestFlying) {
		// 	if (entity.movement.state.isFlying) {
		// 		UI.state.helpers().markFlyKnown();
		// 	} else {
		// 		UI.state.helpers().markStopFlyingKnown();
		// 	}
		// }
	}
}

function canUpdateSystem(entity: Character, input: Input) {
	return entity.type.def.isCharacter && !entity.mount.state.parent && input !== undefined;
}
