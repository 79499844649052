import { Color } from "three";
import { BB } from "base/BB";
import { DEGRAD } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";

const TONE_MAPPING = {
	NoToneMapping: 0,
	LinearToneMapping: 1,
	ReinhardToneMapping: 2,
	CineonToneMapping: 3,
	ACESFilmicToneMapping: 4,
	CustomToneMapping: 5,
};

const excludedOptions = ["t", "lightdir"];
const colors = ["sunColor", "fogColor", "groundColor", "cloudColor", "skyColor"];

function formatSettings(settings, skyOptions) {
	if (!isNullish(settings.cloudSize)) {
		settings.cloud_size = settings.cloudSize;
		delete settings.cloudSize;
	}
	if (!isNullish(settings.cloudCoverage)) {
		settings.cloud_covr = settings.cloudCoverage;
		delete settings.cloudCoverage;
	}
	if (!isNullish(settings.cloudDensity)) {
		settings.cloud_dens = settings.cloudDensity;
		delete settings.cloudDensity;
	}
	if (!isNullish(settings.cloudDistance)) {
		settings.cloud_dist = settings.cloudDistance;
		delete settings.cloudDistance;
	}
	settings = { ...skyOptions, ...settings };
}

export class SettingsClient {
	constructor(base, world) {
		this.base = base;
		this.sky = world.sky;
		this.dom = {
			themeColor: document.getElementById("themeColor"),
		};
	}

	/**
	 * change - applies the settings on the client. does not save anything. Only implements.
	 * @param {Object} settings - Environment settings
	 */
	change(settings = {}) {
		formatSettings(settings, this.sky.skyOption);

		this.sky.lastMinute = -1; // trigger update
		this.dom.themeColor.setAttribute("content", this.sky.skyColor.getStyle());

		if (!isNullish(settings.hour)) this.sky.time = settings.hour / 24;
		if (!isNullish(settings.azimuth)) {
			this.sky.lastMinute = -1;
			this.sky.azimuth = settings.azimuth * DEGRAD;
		}
		if (!isNullish(settings.sunIntensity)) this.sky.maxSunIntensity = settings.sunIntensity;
		if (!isNullish(settings.nightLuminosity)) this.sky.nightLuminosity = settings.nightLuminosity;
		if (Object.keys(TONE_MAPPING).includes(settings.toneMapping)) {
			BB.client.renderer.outputPassToneMapping = TONE_MAPPING[settings.toneMapping];
		}
		if (!isNullish(settings.toneMappingExposure))
			BB.client.renderer.outputPassToneMappingExposure = settings.toneMappingExposure;

		// fog
		if (!isNullish(settings.fogNear)) BB.world.scene.fog.near = settings.fogNear;
		if (!isNullish(settings.fogFar)) BB.world.scene.fog.far = settings.fogFar;

		if (isNullish(this.sky.materialSky)) return;

		for (const setting in settings) {
			if (excludedOptions.indexOf(setting) !== -1) continue;

			const uniforms = this.sky.materialSky.uniforms;
			if (!uniforms) continue;

			this.sky.skyOption[setting] = settings[setting];
			if (uniforms[setting]) {
				if (colors.indexOf(setting) !== -1) {
					const hexColor = new Color(settings[setting]).getHex();
					uniforms[setting].value.setHex(hexColor);
				} else uniforms[setting].value = settings[setting];
			} else if (setting === "step") uniforms.STEP.value = settings[setting];
			else if (setting === "sample") uniforms.SAMPLE.value = settings[setting];
		}
	}

	flattenSettings(preset) {
		return {
			...preset.general,
			...preset.light,
			...preset.sky,
			...preset.fog,
		};
	}

	init() {
		this.change(this.flattenSettings(this.base.environment));
	}
}
