import { BODY_TYPE_VALUE } from "@jamango/content-client";
import { getRandomIntegerFromRange } from "@jamango/helpers";

export class CharacterSoundsComponent {
	constructor(o) {
		this.name = "sounds";

		this.def = {
			jump: structuredClone(o?.sound?.jump ?? { asset: "snd-jump", start: 0.03, volume: 0.5 }),
			pain: structuredClone(o?.sound?.pain ?? { asset: "snd-damage" }),
			death: structuredClone(
				o?.sound?.death ?? { asset: "snd-default", start: 0.397415, end: 0.701315 },
			),
		};
	}

	deathSound(bodyType = BODY_TYPE_VALUE.BODY_TYPE_1) {
		let count;

		if (bodyType === BODY_TYPE_VALUE.BODY_TYPE_1) {
			// 1 to 3
			count = getRandomIntegerFromRange(1, 3);
		} else if (bodyType === BODY_TYPE_VALUE.BODY_TYPE_2) {
			// 4 to 6
			count = getRandomIntegerFromRange(4, 6);
		}

		return structuredClone({
			asset: `snd-grunt-${count}`,
		});
	}
}
