import { Vector3 } from "three";
import type { Character } from "base/world/entity/Character";

type CharacterDef = any;

export enum CharacterMovementMode {
	BINARY,
	KINEMATIC,
	DYNAMIC,
}

export const CHARACTER_MOVEMENT_DEFAULTS = {
	//basic speed
	walkSpeed: 6,
	crouchSpeed: 2,
	airSpeed: 6,
	flySpeed: 12,
	jumpVelocity: 11,
	terminalVelocity: 60,

	//speed multipliers
	sprintSpeedMultiplier: 1.5,
	sightsSpeedMultiplier: 0.6,

	//timing
	jumpCooldownTime: 0.2,
	coyoteTime: 0.14,
	slidingGraceTime: 0.05,

	//abilities
	canSprint: true, //has setter
	canCrouch: true, //has setter
	canJump: true,
	canMoveHorizontally: true, //has setter
	hasCrouchBarriers: true,

	//momentum
	mode: CharacterMovementMode.KINEMATIC,
	maxHorizontalSpeedMultiplier: 5,
	groundAcceleration: 35,
	groundFriction: 10,
	groundFrictionStopMultiplier: 1.5,
	groundStopSpeed: 5,
	airAcceleration: 3,
	airFriction: 1,
	airFrictionStopMultiplier: 1.5,

	//misc/not exposed
	autoStepHeight: 0.4,
	stickToFloorStepDown: -0.5,
	crouchBarrierSize: 0.15,
};

export const CHARACTER_BOB_STATE_VALUES = {
	default: {
		itemSwayAmplitude: 0.04,
		horizontalAmplitude: 0,
		verticalAmplitude: 0,
	},
	crouch: {
		itemSwayAmplitude: 0.04,
		horizontalAmplitude: 0,
		verticalAmplitude: 0,
	},
	walk: {
		itemSwayAmplitude: 0.04,
		horizontalAmplitude: 0,
		verticalAmplitude: 0.05,
	},
	run: {
		itemSwayAmplitude: 0.06,
		horizontalAmplitude: 0.05,
		verticalAmplitude: 0.05,
	},
} as const;

export class CharacterMovementComponent {
	name: string;
	def: typeof CHARACTER_MOVEMENT_DEFAULTS;
	state: {
		isIdle: boolean;
		isIronSights: boolean;
		isSprinting: boolean;
		isFlying: boolean;
		isCrouching: boolean;
		isSwappingTool: boolean;

		speed: number;
		horizontalSpeed: number;
		omnidirectionalAngle: number;

		wishSpeed: number;
		wishDirection: Vector3;

		friction: number;
		isSliding: boolean;
		slidingDirection: Vector3;
		slidingGraceTimer: number;
		isSlipperySurface: boolean;

		inputDisplacement: Vector3;
		velocity: Vector3;
		velocityRequest: Vector3;
		displacementActual: Vector3;
		prvVelocityRequest: Vector3;
		prvDisplacementActual: Vector3;

		coyoteTimer: number;
		jumpTimer: number;

		bobPhaseTime: number;
		bobSineValue: number;
		bobSineValuePrevious: number;

		autoStepHeight: number;

		bobItemSwayAmplitude: number;
		bobHorizontalAmplitude: number;
		bobVerticalAmplitude: number;
	};

	constructor(o: CharacterDef, _entity: Character) {
		this.name = "movement";

		const d = CHARACTER_MOVEMENT_DEFAULTS;
		this.def = {
			walkSpeed: o?.walkSpeed ?? d.walkSpeed,
			crouchSpeed: o?.crouchSpeed ?? d.crouchSpeed,
			airSpeed: o?.airSpeed ?? d.airSpeed,
			flySpeed: o?.flySpeed ?? d.flySpeed,
			jumpVelocity: o?.jumpVelocity ?? d.jumpVelocity,
			terminalVelocity: o?.terminalVelocity ?? d.terminalVelocity,

			sprintSpeedMultiplier: o?.sprintSpeedMultiplier ?? d.sprintSpeedMultiplier,
			sightsSpeedMultiplier: o?.sightsSpeedMultiplier ?? d.sightsSpeedMultiplier,

			jumpCooldownTime: o?.jumpCooldownTime ?? d.jumpCooldownTime,
			coyoteTime: o?.coyoteTime ?? d.coyoteTime,
			slidingGraceTime: o?.slidingGraceTime ?? d.slidingGraceTime,

			canSprint: o?.canSprint ?? d.canSprint,
			canCrouch: o?.canCrouch ?? d.canCrouch,
			canJump: o?.canJump ?? d.canJump,
			canMoveHorizontally: o?.canMoveHorizontally ?? d.canMoveHorizontally,
			hasCrouchBarriers: o?.hasCrouchBarriers ?? d.hasCrouchBarriers,

			mode: o?.mode ?? d.mode,
			maxHorizontalSpeedMultiplier: o?.maxHorizontalSpeedMultiplier ?? d.maxHorizontalSpeedMultiplier,
			groundAcceleration: o?.groundAcceleration ?? d.groundAcceleration,
			groundFriction: o?.groundFriction ?? d.groundFriction,
			groundFrictionStopMultiplier: o?.groundFrictionStopMultiplier ?? d.groundFrictionStopMultiplier,
			groundStopSpeed: o?.groundStopSpeed ?? d.groundStopSpeed,
			airAcceleration: o?.airAcceleration ?? d.airAcceleration,
			airFriction: o?.airFriction ?? d.airFriction,
			airFrictionStopMultiplier: o?.airFrictionStopMultiplier ?? d.airFrictionStopMultiplier,

			autoStepHeight: o?.autoStepHeight ?? 0.2,
			stickToFloorStepDown: o?.stickToFloorStepDown ?? -0.5,
			crouchBarrierSize: o?.crouchBarrierSize ?? d.crouchBarrierSize,
		};

		this.state = {
			isIdle: true, //doesn't count movement due to velocity
			isIronSights: false,
			isSprinting: false, //sprinting
			isFlying: false, //flying
			isCrouching: false, //crouch
			isSwappingTool: false,

			speed: 0,
			horizontalSpeed: 0, //doesn't count up/down while flying
			omnidirectionalAngle: 0, //only valid when horizontalSpeed > 0

			wishSpeed: 0,
			wishDirection: new Vector3(),

			friction: 1,
			isSliding: false,
			slidingDirection: new Vector3(),
			slidingGraceTimer: 0,
			isSlipperySurface: false,

			inputDisplacement: new Vector3(),
			velocity: new Vector3(), // velocity due to input
			velocityRequest: new Vector3(), //per-frame controller velocity request sent to jolt
			displacementActual: new Vector3(), //actual displacement after jolt step
			prvVelocityRequest: new Vector3(),
			prvDisplacementActual: new Vector3(),

			coyoteTimer: this.def.coyoteTime, // how long since leaving ground
			jumpTimer: 0, // how long since last jump

			bobPhaseTime: 0,
			bobSineValue: 0,
			bobSineValuePrevious: 0,

			autoStepHeight: 0,

			bobItemSwayAmplitude: 0,
			bobHorizontalAmplitude: 0,
			bobVerticalAmplitude: 0,
		};
	}
}
