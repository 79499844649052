import { createCommandSocket } from "@jamango/ibs";
import { Chunk } from "base/world/block/chunk/Chunk.js";

const VALID_THREAD_TYPES = ["ao"] as const;

type ThreadType = (typeof VALID_THREAD_TYPES)[number];

export function createThread(threadJsUrl: string | Worker, type: ThreadType) {
	if (!VALID_THREAD_TYPES.includes(type)) {
		throw new Error(`Unable to start thread, invalid type specified: ${type}`);
	}

	const worker =
		typeof threadJsUrl === "string" ? new Worker(threadJsUrl, { type: "module" }) : threadJsUrl;
	const thread = createCommandSocket(worker);

	thread.name = type;

	switch (type) {
		case "ao":
			Chunk.aoMainThread(thread);
			break;
	}

	return thread;
}
