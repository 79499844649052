import { CHAT_MSG_LIMIT, formatTime } from "@jamango/frontend/Chat.ts";
import type { ChatMessage } from "@jamango/frontend/Chat.ts";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("Chat");

export function trimChat(msg: string) {
	return msg.trim().substring(0, CHAT_MSG_LIMIT).trim();
}

export function displayChat({ time, username, message }: ChatMessage) {
	if (username) {
		logger.info(`${formatTime(time)} ${username}: ${message}`);
	} else {
		logger.info(`${formatTime(time)} ${message}`);
	}
}
