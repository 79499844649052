import type { PeerMetadata } from "@jamango/engine/PeerMetadata.ts";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { UI } from "client/dom/UI";
import { Peer, type LoopbackPeer } from "@jamango/ibs";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";

export function sendPermissionsToClient(peer: Peer | LoopbackPeer) {
	const permissions = getPeerMetadata(peer).permissions;
	if (peer instanceof Peer) {
		Net.send("permissions_change", permissions, peer);
	}
	//loopback
	else UI.state.permission().setPermissions(permissions);
}

export function initCommandListeners() {
	if (!netState.isHost) {
		Net.listen("permissions_change", function (permissions: PeerMetadata["permissions"], world) {
			getPeerMetadata(world.client!.loopbackPeer).permissions = permissions;
			UI.state.permission().setPermissions(permissions);
		});
	}
}
