import { z } from "zod";
import type { IEnvironmentPreset } from "../types";
import { AssetType, type ToneMapping, TONE_MAPPING } from "../types";
import {
	SLIDER_MAX_AZIMUTH,
	SLIDER_MAX_HOUR,
	SLIDER_MAX_NIGHT_LUMINOSITY,
	SLIDER_MAX_SUN_INTENSITY,
	SLIDER_MIN_AZIMUTH,
	SLIDER_MIN_HOUR,
	SLIDER_MIN_NIGHT_LUMINOSITY,
	SLIDER_MIN_SUN_INTENSITY,
	SLIDER_MIN_CLOUD_COVERAGE,
	SLIDER_MAX_CLOUD_COVERAGE,
	SLIDER_MAX_CLOUD_DENSITY,
	SLIDER_MIN_CLOUD_DENSITY,
	SLIDER_MIN_CLOUD_DISTANCE,
	SLIDER_MAX_CLOUD_DISTANCE,
	SLIDER_MIN_CLOUD_SIZE,
	SLIDER_MAX_CLOUD_SIZE,
	SLIDER_MIN_FOG_NEAR,
	SLIDER_MAX_FOG_NEAR,
	SLIDER_MIN_FOG_FAR,
	SLIDER_MAX_FOG_FAR,
	SLIDER_MIN_HAZE,
	SLIDER_MAX_HAZE,
	SLIDER_MIN_FOGY,
	SLIDER_MAX_FOGY,
	SLIDER_MIN_SAMPLE,
	SLIDER_MAX_SAMPLE,
	SLIDER_MIN_SATURATION,
	SLIDER_MAX_SATURATION,
	SLIDER_MIN_STEP,
	SLIDER_MAX_STEP,
	SLIDER_MAX_TONE_MAPPING_EXPOSURE,
	SLIDER_MIN_TONE_MAPPING_EXPOSURE,
} from "../constants/environment-presets";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

const nameSchema = z
	.string()
	.min(3, { message: "Title must be at least 3 characters long." })
	.max(120, { message: "Title must be at most 120 characters long." });
const hourSchema = z
	.number()
	.min(SLIDER_MIN_HOUR, { message: `Hour must be at least ${SLIDER_MIN_HOUR}` })
	.max(SLIDER_MAX_HOUR, { message: `Hour must be at most ${SLIDER_MAX_HOUR}` });
const azimuthSchema = z
	.number()
	.min(SLIDER_MIN_AZIMUTH, { message: `Hour must be at least ${SLIDER_MIN_HOUR}` })
	.max(SLIDER_MAX_AZIMUTH, { message: `Hour must be at most ${SLIDER_MAX_HOUR}` });
const sunIntensitySchema = z
	.number()
	.min(SLIDER_MIN_SUN_INTENSITY, {
		message: `Sun intensity must be at least ${SLIDER_MIN_SUN_INTENSITY}`,
	})
	.max(SLIDER_MAX_SUN_INTENSITY, {
		message: `Sun intensity must be at most ${SLIDER_MAX_SUN_INTENSITY}`,
	});
const nightLuminositySchema = z
	.number()
	.min(SLIDER_MIN_NIGHT_LUMINOSITY, {
		message: `Night luminosity must be at least ${SLIDER_MIN_NIGHT_LUMINOSITY}`,
	})
	.max(SLIDER_MAX_NIGHT_LUMINOSITY, {
		message: `Night luminosity must be at most ${SLIDER_MAX_NIGHT_LUMINOSITY}`,
	});
const sunColorSchema = z.string();
const toneMappingSchema = z.nativeEnum(TONE_MAPPING, {
	message: "Invalid tone mapping",
});
const toneMappingExposureSchema = z
	.number()
	.min(SLIDER_MIN_TONE_MAPPING_EXPOSURE, {
		message: `Tone mapping exposure must be at least ${SLIDER_MIN_TONE_MAPPING_EXPOSURE}`,
	})
	.max(SLIDER_MAX_TONE_MAPPING_EXPOSURE, {
		message: `Tone mapping exposure must be at most ${SLIDER_MAX_TONE_MAPPING_EXPOSURE}`,
	});
const saturationSchema = z
	.number()
	.min(SLIDER_MIN_SATURATION, {
		message: `Saturation must be at least ${SLIDER_MIN_SATURATION}`,
	})
	.max(SLIDER_MAX_SATURATION, {
		message: `Saturation must be at most ${SLIDER_MAX_SATURATION}`,
	});
const hazeSchema = z
	.number()
	.min(SLIDER_MIN_HAZE, {
		message: `Haze must be at least ${SLIDER_MIN_HAZE}`,
	})
	.max(SLIDER_MAX_HAZE, {
		message: `Haze must be at most ${SLIDER_MAX_HAZE}`,
	});
const fogySchema = z
	.number()
	.min(SLIDER_MIN_FOGY, {
		message: `Fogy must be at least ${SLIDER_MIN_FOGY}`,
	})
	.max(SLIDER_MAX_FOGY, {
		message: `Fogy must be at most ${SLIDER_MAX_FOGY}`,
	});
const cloudSizeSchema = z
	.number()
	.min(SLIDER_MIN_CLOUD_SIZE, {
		message: `Cloud size must be at least ${SLIDER_MIN_CLOUD_SIZE}`,
	})
	.max(SLIDER_MAX_CLOUD_SIZE, {
		message: `Cloud size must be at most ${SLIDER_MAX_CLOUD_SIZE}`,
	});
const cloudCoverageSchema = z
	.number()
	.min(SLIDER_MIN_CLOUD_COVERAGE, {
		message: `Cloud coverage must be at least ${SLIDER_MIN_CLOUD_COVERAGE}`,
	})
	.max(SLIDER_MAX_CLOUD_COVERAGE, {
		message: `Cloud coverage must be at most ${SLIDER_MAX_CLOUD_COVERAGE}`,
	});
const cloudDensitySchema = z
	.number()
	.min(SLIDER_MIN_CLOUD_DENSITY, {
		message: `Cloud density must be at least ${SLIDER_MIN_CLOUD_DENSITY}`,
	})
	.max(SLIDER_MAX_CLOUD_DENSITY, {
		message: `Cloud density must be at most ${SLIDER_MAX_CLOUD_DENSITY}`,
	});
const cloudDistanceSchema = z
	.number()
	.min(SLIDER_MIN_CLOUD_DISTANCE, {
		message: `Cloud distance must be at least ${SLIDER_MIN_CLOUD_DISTANCE}`,
	})
	.max(SLIDER_MAX_CLOUD_DISTANCE, {
		message: `Cloud distance must be at most ${SLIDER_MAX_CLOUD_DISTANCE}`,
	});
const sampleSchema = z
	.number()
	.min(SLIDER_MIN_SAMPLE, {
		message: `Sample must be at least ${SLIDER_MIN_SAMPLE}`,
	})
	.max(SLIDER_MAX_SAMPLE, {
		message: `Sample must be at most ${SLIDER_MAX_SAMPLE}`,
	});
const stepSchema = z
	.number()
	.min(SLIDER_MIN_STEP, {
		message: `Step must be at least ${SLIDER_MIN_STEP}`,
	})
	.max(SLIDER_MAX_STEP, {
		message: `Step must be at most ${SLIDER_MAX_STEP}`,
	});
const skyColorSchema = z.string();
const cloudColorSchema = z.string();
const fogColorSchema = z.string();
const groundColorSchema = z.string();
const fogNearSchema = z
	.number()
	.min(SLIDER_MIN_FOG_NEAR, {
		message: `Fog near must be at least ${SLIDER_MIN_FOG_NEAR}`,
	})
	.max(SLIDER_MAX_FOG_NEAR, {
		message: `Fog near must be at most ${SLIDER_MAX_FOG_NEAR}`,
	});
const fogFarSchema = z
	.number()
	.min(SLIDER_MIN_FOG_FAR, {
		message: `Fog far must be at least ${SLIDER_MIN_FOG_FAR}`,
	})
	.max(SLIDER_MAX_FOG_FAR, {
		message: `Fog far must be at most ${SLIDER_MAX_FOG_FAR}`,
	});

export const name = (value: string) => {
	nameSchema.parse(value);
};

export const hour = (value: number) => {
	hourSchema.parse(value);
};

export const azimuth = (value: number) => {
	azimuthSchema.parse(value);
};

export const sunIntensity = (value: number) => {
	sunIntensitySchema.parse(value);
};

export const nightLuminosity = (value: number) => {
	nightLuminositySchema.parse(value);
};

export const sunColor = (value: string) => {
	sunColorSchema.parse(value);
};

export const toneMapping = (value: ToneMapping) => {
	toneMappingSchema.parse(value);
};

export const toneMappingExposure = (value: number) => {
	toneMappingExposureSchema.parse(value);
};

export const saturation = (value: number) => {
	saturationSchema.parse(value);
};

export const haze = (value: number) => {
	hazeSchema.parse(value);
};

export const fogy = (value: number) => {
	fogySchema.parse(value);
};

export const cloudSize = (value: number) => {
	cloudSizeSchema.parse(value);
};

export const cloudCoverage = (value: number) => {
	cloudCoverageSchema.parse(value);
};

export const cloudDensity = (value: number) => {
	cloudDensitySchema.parse(value);
};

export const cloudDistance = (value: number) => {
	cloudDistanceSchema.parse(value);
};

export const sample = (value: number) => {
	sampleSchema.parse(value);
};

export const step = (value: number) => {
	stepSchema.parse(value);
};

export const skyColor = (value: string) => {
	skyColorSchema.parse(value);
};

export const cloudColor = (value: string) => {
	cloudColorSchema.parse(value);
};

export const fogColor = (value: string) => {
	fogColorSchema.parse(value);
};

export const groundColor = (value: string) => {
	groundColorSchema.parse(value);
};

export const fogNear = (value: number) => {
	fogNearSchema.parse(value);
};

export const fogFar = (value: number) => {
	fogFarSchema.parse(value);
};

const PresetSchema = z.object({
	general: z.object({
		hour: hourSchema,
		azimuth: azimuthSchema,
	}),
	light: z.object({
		sunIntensity: sunIntensitySchema,
		nightLuminosity: nightLuminositySchema,
		sunColor: sunColorSchema,
	}),
	sky: z.object({
		toneMapping: toneMappingSchema,
		toneMappingExposure: toneMappingExposureSchema,
		saturation: saturationSchema,
		haze: hazeSchema,
		fogy: fogySchema,
		cloudSize: cloudSizeSchema,
		cloudCoverage: cloudCoverageSchema,
		cloudDensity: cloudDensitySchema,
		cloudDistance: cloudDistanceSchema,
		sample: sampleSchema,
		step: stepSchema,
		skyColor: skyColorSchema,
		cloudColor: cloudColorSchema,
		fogColor: fogColorSchema,
		groundColor: groundColorSchema,
	}),
	fog: z.object({ fogNear: fogNearSchema, fogFar: fogFarSchema }),
});

export const EnvironmentPresetSchema = z.object({
	pk: assetKeySchema(AssetType.ENVIRONMENT_PRESET),
	type: z.literal(AssetType.ENVIRONMENT_PRESET),
	id: z.string(),
	name: nameSchema,
	preset: PresetSchema,
	originalPreset: PresetSchema,
	priority: z.number().optional(),
	isDefault: z.boolean().optional(),
	playerId: z.string().nullish().optional(),
	isUsed: z.boolean(),
	packageId: PackageIdSchema.optional(),
});

export const all = (envPreset: IEnvironmentPreset) => {
	EnvironmentPresetSchema.parse(envPreset);
};
