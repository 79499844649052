import type { JacyContent } from "@jamango/content-client";
import { isNullish } from "@jamango/helpers";
import { BB } from "base/BB";

export const initBuiltInDefs = (content: JacyContent) => {
	const engineCharacters = Array.from(BB.world.defs.values()).filter(
		(def) => def.name.startsWith("Player") && def.isNPC && !isNullish(def.characterName),
	);

	for (const character of engineCharacters) {
		if (!character.meta) continue;

		const isBuiltIn = content.state.characters.isBuiltIn(character.meta.name);
		if (!isBuiltIn) continue;

		content.state.characters.setBuiltIn({
			pk: character.meta.pk,
			type: character.meta.type,
			id: character.meta.id,
			name: character.meta.name,
			scripts: character.meta.scripts,
			aiType: character.meta.aiType,
			aiAvatar: character.meta.aiAvatar,
			showNametag: character.meta.showNametag,
			dropItem: character.meta.dropItem,
			canKillNPCs: character.meta.canKillNPCs,
			createdAt: character.meta.createdAt,
			updatedAt: character.meta.updatedAt,
		});
	}
};
