import * as QuestBase from "base/world/Quest";
import * as QuestClient from "client/world/Quest";
import * as QuestServer from "server/world/Quest";

import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { Vector3 } from "three";

export function init(): QuestBase.State {
	const quest = QuestBase.init();
	if (netState.isClient) QuestClient.init(quest);
	return quest;
}

/**
 * REQ String name
 * OPT String message - leave blank to hide this objective in the objectives panel
 */
export function localCreateObjective(state: QuestBase.State, o: QuestBase.ObjectiveDef) {
	QuestBase.localCreateObjective(state, o);
	if (netState.isClient) QuestClient.localCreateObjective(state as QuestClient.State);
}

/**
 * o is Text3D.render options (a subset of Text3D)
 */
export function localCreateWaypoint(
	state: QuestBase.State,
	world: World,
	name: QuestBase.ObjectiveName,
	entityID: EntityID | null,
	pos: Vector3,
) {
	const obj = state.objectives.get(name)!; //verified to exist by server

	QuestBase.localCreateWaypoint(obj, entityID, pos);
	if (netState.isClient) QuestClient.localCreateWaypoint(state as QuestClient.State, world, obj);
}

export function localCompleteObjective(state: QuestBase.State, name: QuestBase.ObjectiveName) {
	const obj = state.objectives.get(name)!; //verified to exist by server

	QuestBase.localCompleteObjective(state, obj);
	if (netState.isClient)
		QuestClient.localCompleteObjective(state as QuestClient.State, obj as QuestClient.ObjectiveState);
}

export function initCommandListeners() {
	if (netState.isHost) {
		QuestServer.initCommandListeners();
	} else {
		QuestClient.initCommandListeners();
	}
}
