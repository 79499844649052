import type { z } from "zod";
import type {
	AvatarComponentSchema,
	AvatarConfigSchema,
	AvatarMonitoringConfigSchema,
	AvatarObjectSchema,
	AvatarSchema,
	BodyPartConfigSchema,
	BodyPartSchema,
	CostumeSchema,
	OptionalBodyPartConfigSchema,
} from "../validations/avatar";
import type { AvatarPhotoResource, AvatarThumbnailResource } from "./resources";

export enum AvatarType {
	USER_AVATAR = "user_avatar",
	CHARACTER = "world_character",
}

export type IAvatar = z.infer<typeof AvatarSchema> & {
	needsReload?: boolean; // virtual column - computed based on create / update asset.
};

export type IParsedAvatar = IAvatar & {
	thumbnailResource: AvatarThumbnailResource;
	displayPhotoResource: AvatarPhotoResource;
};

export type IBodyPartConfig = {
	isUniversal?: boolean;
	body_type_1?: string | null;
	body_type_2?: string | null;
};

// Keep in sync with rest-client/src/types/avatars.ts -> BODY_TYPE_VALUE
export enum BodyTypeValue {
	BODY_TYPE_1 = "body_type_1",
	BODY_TYPE_2 = "body_type_2",
}

export type ICostume = z.infer<typeof CostumeSchema>;

export type IParsedCostume = ICostume & {
	thumbnailResource: AvatarThumbnailResource;
	displayPhotoResource: AvatarPhotoResource | null;
};

export type IAvatarMonitoringConfig = z.infer<typeof AvatarMonitoringConfigSchema>;

export type IAvatarConfig = z.infer<typeof AvatarConfigSchema>;

export type IAvatarComponent = z.infer<typeof AvatarComponentSchema>;

export type IAvatarObject = z.infer<typeof AvatarObjectSchema>;

export type IAvatarObjectBodyPartConfig =
	| z.infer<typeof BodyPartConfigSchema>
	| z.infer<typeof OptionalBodyPartConfigSchema>;

export type IAvatarObjectBodyPart = z.infer<typeof BodyPartSchema>;

export enum BodyType {
	BODY_TYPE_1 = "bodyType1",
	BODY_TYPE_2 = "bodyType2",
}

export enum AvatarMainTabs {
	BASE,
	HEAD,
	BODY,
}

export enum AvatarBaseTabs {
	BODY_TYPE = "body type",
	SKIN = "skin",
	AVATAR_SETS = "costume sets",
}

export enum AvatarHeadTabs {
	HAIR = "hair",
	FACE = "face",
	HAT = "hat",
	MASK = "mask",
}

export enum AvatarBodyTabs {
	TORSO = "torso",
	LEGS = "legs",
	BACKPACK = "backpack",
}
