import { err } from "@jamango/helpers";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as BlockGroups from "base/world/block/BlockGroups";
import type { Character } from "base/world/entity/Character";
import { type Peer } from "@jamango/ibs";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import { Vector3 } from "three";

const _teleportPosition = new Vector3();
const _teleportTarget = new Vector3();

export const teleportCreatorToSceneTreeNode = Net.rpc(
	"scene tree node teleport",
	(nodeId: string, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		const sceneTreeNode = world.sceneTree.nodes[nodeId];
		if (!sceneTreeNode) return err("Scene tree node not found.");

		let targetPeer;
		if (!peer && netState.isClient) {
			targetPeer = world.client!.loopbackPeer;
		} else if (peer) {
			targetPeer = peer;
		} else {
			return err("No player to teleport.");
		}

		const player: Character = world.peerToPlayer.get(targetPeer)!;

		// todo: find a safe spot to teleport to, and make the player face the group

		const playerPosition = _teleportPosition.fromArray(sceneTreeNode.position);

		player.setPosition(playerPosition, true);
		player.setFlying(true);
	},
);

export const teleportCreatorToBlockGroup = Net.rpc("block group teleport", (groupId: string, world, peer) => {
	if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

	const group = world.blockGroups.groups.get(groupId);
	if (!group) return err("Group not found.");

	let targetPeer;
	if (!peer && netState.isClient) {
		targetPeer = world.client!.loopbackPeer;
	} else if (peer) {
		targetPeer = peer;
	} else {
		return err("No player to teleport.");
	}

	const player: Character = world.peerToPlayer.get(targetPeer)!;

	if (group.blocks.size() === 0) return err("Group has no blocks.");

	const blockGroupCenter = BlockGroups.getSpawnPosition(world.blockGroups, groupId, _teleportTarget);

	// todo: find a safe spot to teleport to, and make the player face the group

	const playerPosition = _teleportPosition.copy(blockGroupCenter);

	player.setPosition(playerPosition, true);
	player.setFlying(true);
});

export const hasWrenchPowers = (peer: Peer) => {
	return getPeerMetadata(peer).permissions.canUseWrench;
};

export const NON_WRENCHY_MESSAGE = "This action requires wrench permissions";
