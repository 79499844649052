import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { LoopOnce } from "three";

export class LocomotionStateReloadFP extends LocomotionOutputState {
	constructor(animMesh, locomotionInput) {
		super("ReloadFP", animMesh, locomotionInput);

		this.transitionToIdleTime = 0.2;

		this.itemNovaGunTrack = new AnimationTrack(animMesh, "JMGO_NovaGun_FP_NotAim_Reload", this);

		this.itemZapperPistolTrack = new AnimationTrack(animMesh, "JMGO_ZapperPistol_FP_NotAim_Reload", this);

		this.itemNovaRifleTrack = new AnimationTrack(animMesh, "JMGO_NovaRifle_FP_NotAim_Reload", this);

		this.itemNovaAssaultTrack = new AnimationTrack(animMesh, "JMGO_NovaAssault_FP_NotAim_Reload", this);

		this.itemZapperRifleTrack = new AnimationTrack(animMesh, "JMGO_ZapperRifle_FP_NotAim_Reload", this);

		this.itemZapperAssaultTrack = new AnimationTrack(
			animMesh,
			"JMGO_ZapperAssault_FP_NotAim_Reload",
			this,
		);

		this.idleTrackMap = new Map();
		this.idleTrackMap.set("NovaGun", this.itemNovaGunTrack);
		this.idleTrackMap.set("ZapperPistol", this.itemZapperPistolTrack);
		this.idleTrackMap.set("NovaRifle", this.itemNovaRifleTrack);
		this.idleTrackMap.set("NovaAssault", this.itemNovaAssaultTrack);
		this.idleTrackMap.set("ZapperRifle", this.itemZapperRifleTrack);
		this.idleTrackMap.set("ZapperAssault", this.itemZapperAssaultTrack);

		this.lastTrack = this.itemNovaGunTrack;
	}

	startCompute(transitionData) {
		if (
			this.lastTrack !== this.idleTrackMap.get(this.inputState.item) &&
			this.idleTrackMap.get(this.inputState.item)
		) {
			this.lastTrack.startFadeOut(0.2);
		}

		this.lastTrack = this.idleTrackMap.get(this.inputState.item);
		if (!this.lastTrack) {
			this.lastTrack = this.itemNovaGunTrack;
		}
		this.lastTrack.action.reset();
		this.lastTrack.action.loop = LoopOnce;
		this.lastTrack.startFadeIn(0.2);

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.lastTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.lastTrack.action.setEffectiveWeight(0);

			// hack
			this.itemNovaGunTrack.action.setEffectiveWeight(0);
			this.itemZapperPistolTrack.action.setEffectiveWeight(0);
			this.itemNovaRifleTrack.action.setEffectiveWeight(0);
			this.itemNovaAssaultTrack.action.setEffectiveWeight(0);
			this.itemZapperRifleTrack.action.setEffectiveWeight(0);
			this.itemZapperAssaultTrack.action.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();

		this.itemNovaGunTrack.update();
		this.itemZapperPistolTrack.update();
		this.itemZapperRifleTrack.update();
		this.itemZapperAssaultTrack.update();
		this.itemNovaAssaultTrack.update();
		this.itemNovaRifleTrack.update();
	}

	shouldTransitionCustom(outTransitionToState, map) {
		const track = this.idleTrackMap.get(this.inputState.item);

		if (
			!this.inputState.isReloading ||
			this.lastTrack !== track ||
			this.lastTrack.action.getRemainingTime() < this.transitionToIdleTime
		) {
			outTransitionToState.ptr = map.get("IdleFP");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}
}
