import { NODE_TYPE_ID } from "base/rete/Constants";
import { DEGRAD } from "base/util/math/Math";
import { Vector3 } from "three";
import type { Text3DEntity } from "base/world/entity/Text3D";
import { node } from "base/rete/Types";
import { isNullish } from "@jamango/helpers";
import { interpreterAnyToString } from "base/rete/NodeSharedUtil";

export const TEXT_3D_NODES = [
	node({
		id: "5a7a42c4-9c4f-4c19-91c4-3c1cdaa9c3a9",
		name: "Text 3D Spawn",
		type: NODE_TYPE_ID.text3d,
		description: "Spawn a 3D text in the world.",
		inputs: {
			exec: { type: "exec" },
			text: { name: "Text", type: "string", control: "textarea", config: { defaultValue: "" } },
			fontSize: { name: "Font Size", type: "number", control: "number", config: { defaultValue: 32 } },
			maxVisibleDistance: {
				name: "Max Visible Distance",
				type: "number",
				control: "number",
				config: { defaultValue: 32 },
			},
			faceCamera: {
				name: "Look At Camera",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: true },
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "vector3",
				control: "vector3",
			},
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const inputPos = inputs.position;
			const world = ctx.world;

			const x = (inputPos?.x ?? 0) + 0.5;
			const y = (inputPos?.y ?? 0) + 0.5;
			const z = (inputPos?.z ?? 0) + 0.5;

			const inputRot = inputs.rotation;
			const rotX = inputRot?.x ?? 0;
			const rotY = inputRot?.y ?? 0;
			const rotZ = inputRot?.z ?? 0;
			const rotation = new Vector3(rotX, rotY, rotZ);
			rotation.multiplyScalar(DEGRAD);

			const fontSize = inputs.fontSize ?? 32;
			const faceCamera = inputs.faceCamera ?? true;
			const maxVisibleDistance = inputs.maxVisibleDistance ?? 32;

			const entity = world.router.createEntity({
				def: "Text3D",
				x,
				y,
				z,
				qx: 0,
				qy: 0,
				qz: 0,
				qw: 1,
			}) as Text3DEntity;

			entity.setRotation(rotation, true);

			if (entity.text3D) {
				entity.text3D.state.text = interpreterAnyToString(inputs.text ?? "");
				entity.text3D.state.options = {
					proximity: maxVisibleDistance,
					faceCamera,
					sizeAttenuation: false,
					scl: 1 / 750,
				};
				entity.text3D.state.renderOptions = {
					fontSize,
					backgroundColor: `#00000000`,
					fontColor: "#FFFFFF",
					fontWeight: 400,
					fontOutlineWidth: 4,
					margin: 0,
					linePadding: 0,
					radius: 0,
				};
			}

			scope[nodeId] = entity.entityID;
		},
		resolve(inputs, ctx, nodeID, scope) {
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
	node({
		id: "b4491226-5dd3-467c-90fc-260701c4f629",
		name: "Text 3D Update",
		type: NODE_TYPE_ID.text3d,
		description: "Update a 3D text entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Text Entity", type: "entity" },
			text: {
				name: "Text",
				type: "string",
				optional: true,
				control: "textarea",
				config: { defaultValue: "" },
			},
			fontSize: {
				name: "Font Size",
				type: "number",
				optional: true,
				control: "number",
				config: { defaultValue: 32 },
			},
			position: {
				name: "Position",
				type: "vector3",
				optional: true,
				control: "vector3",
				icon: "MapPin",
				config: { defaultValue: { x: 0, y: 0, z: 0 } },
			},
			rotation: {
				name: "Rotation",
				type: "vector3",
				optional: true,
				control: "vector3",
				config: { defaultValue: { x: 0, y: 0, z: 0 } },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const entity = inputs.entity as Text3DEntity;

			if (!entity.text3D) return;

			if (!isNullish(inputs.text)) {
				entity.text3D.state.text = inputs.text;
			}

			if (!isNullish(inputs.fontSize)) {
				entity.text3D.state.renderOptions.fontSize = inputs.fontSize;
			}

			if (!isNullish(inputs.position)) {
				const inputPos = inputs.position;

				const x = inputPos.x + 0.5;
				const y = inputPos.y + 0.5;
				const z = inputPos.z + 0.5;

				entity.setPosition(new Vector3(x, y, z), true);
			}

			if (!isNullish(inputs.rotation)) {
				const inputRot = inputs.rotation;

				const rotX = inputRot.x;
				const rotY = inputRot.y;
				const rotZ = inputRot.z;

				const rotation = new Vector3(rotX, rotY, rotZ);
				rotation.multiplyScalar(DEGRAD);

				entity.setRotation(rotation, true);
			}
		},
	}),
];
