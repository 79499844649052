//frontend-only concept: this "mod" represents all block type items + ItemSpawner. together they form "the build tool"
//ItemSpawner to the frontend is part of the build tool, but to the permissions system it requires wrench permission

import type { nullish } from "@jamango/helpers";
import { ItemSpawner } from "@jamango/engine/Runtime/mods/defs/ItemSpawner.ts";

const genericName = "ItemBuildTool";

export const ItemBuildTool = {
	name: genericName,
	display: "Build",
	type: "tool",

	blockPrefix: `${genericName}_`,
	isBuildTool(defName: string | nullish) {
		return defName?.startsWith(ItemBuildTool.blockPrefix) || defName === ItemSpawner.name;
	},
} as const;
