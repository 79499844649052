import { PropMeshType } from "@jamango/content-client";
import type { World } from "base/world/World";
import { createBlockStructureMesh } from "base/world/block/BlockStructureMesh";
import type { Entity } from "base/world/entity/Entity";
import type { Prop } from "base/world/entity/Prop";
import type { PropMesh } from "base/world/entity/component/PropMesh";
import { netState } from "router/Parallelogram";
import type { Object3D } from "three";

export function propMeshUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const prop = entity as Prop;

	const needsUpdate = prop.propMesh.def.meshHash !== prop.propMesh.state.meshHash;

	if (needsUpdate) {
		removeMesh(prop);
		addMesh(prop);
	}
}

function canUpdateSystem(entity: Entity) {
	return netState.isClient && entity.type.def.isProp;
}

export function createPropMesh(world: World, propMeshDesc: PropMesh, parent: Object3D) {
	if (propMeshDesc.type === PropMeshType.BLOCK_STRUCTURE) {
		const blockStructure = world.content.state.blockStructures.get(propMeshDesc.blockStructurePk);
		if (!blockStructure) return false;

		return createBlockStructureMesh(world, blockStructure, parent);
	}

	return false;
}

export function removeMesh(prop: Prop) {
	for (const mesh of prop.propMesh.state.meshes) {
		mesh.removeFromParent();
	}
	for (const geometry of prop.propMesh.state.geometries) {
		geometry.dispose();
	}
	for (const material of prop.propMesh.state.materials) {
		material.dispose();
	}
	prop.propMesh.state.meshes.length = 0;
	prop.propMesh.state.geometries.length = 0;
	prop.propMesh.state.materials.length = 0;
}

function addMesh(prop: Prop) {
	const world = prop.world;

	const mesh = prop.propMesh.def.mesh;

	const result = createPropMesh(world, mesh, prop.object3D);
	if (!result) return;

	prop.propMesh.state.meshSize.copy(result.worldSize);

	for (const geometry of result.geometries) {
		prop.propMesh.state.geometries.push(geometry);
	}

	for (const mesh of result.meshes) {
		prop.propMesh.state.meshes.push(mesh);
	}

	prop.propMesh.state.meshHash = prop.propMesh.def.meshHash;
}
