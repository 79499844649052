import { Euler, Quaternion, Vector3 } from "three";
import { STYLE, NODE_TYPE_ID } from "base/rete/Constants";
import { interpreterAnyToString } from "base/rete/NodeSharedUtil";
import { node } from "base/rete/Types";
import type { SelectOptionsContext } from "@jamango/content-client";
import { EULER_ORDERS } from "base/util/math/Math";

export const UTILS = [
	// Conversion
	node({
		id: "0006-01-0001",
		name: "Any to String",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.string,
		description: "Converts any value to a String",
		predictable: true,
		inputs: {
			value: { name: "Value", type: "any", structure: "any" },
		},
		outputs: {
			string: { name: "String", type: "string" },
		},
		resolve(inputs) {
			return { string: interpreterAnyToString(inputs.value) };
		},
	}),
	node({
		id: "0006-01-0004",
		name: "Any to Number",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.number,
		description: "Converts any value to a Number",
		predictable: true,
		inputs: {
			value: { name: "Value", type: "any", structure: "any" },
		},
		outputs: {
			number: { name: "Number", type: "number" },
		},
		resolve(inputs) {
			return {
				number: inputs.value === null ? NaN : Number(inputs.value),
			};
		},
	}),
	node({
		id: "0006-01-0005",
		name: "Any to Boolean",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.boolean,
		description: "Converts any value to a Boolean",
		predictable: true,
		inputs: {
			value: { name: "Value", type: "any", structure: "any" },
		},
		outputs: {
			boolean: { name: "Boolean", type: "boolean" },
		},
		resolve(inputs) {
			return { boolean: Boolean(inputs.value) };
		},
	}),
	node({
		id: "0006-01-0002",
		name: "Vector3 to Components",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.vector3,
		description: "Creates individual components from a Vector3",
		predictable: true,
		inputs: {
			vector3: {
				name: "Vector3",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			x: { name: "X", type: "number" },
			y: { name: "Y", type: "number" },
			z: { name: "Z", type: "number" },
		},
		resolve(inputs) {
			return { x: inputs.vector3?.x, y: inputs.vector3?.y, z: inputs.vector3?.z };
		},
	}),
	node({
		id: "561fd59f-14df-4d40-bb22-035c26bd4b0c",
		name: "Euler to Components",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.vector3,
		description: "Creates individual components from a Euler",
		predictable: true,
		inputs: {
			euler: {
				name: "Euler",
				type: "euler",
				control: "euler",
			},
		},
		outputs: {
			x: { name: "X", type: "number" },
			y: { name: "Y", type: "number" },
			z: { name: "Z", type: "number" },
			order: { name: "Order", type: "string" },
		},
		resolve(inputs) {
			return {
				x: inputs.euler?.x,
				y: inputs.euler?.y,
				z: inputs.euler?.z,
				order: inputs.euler?.order ?? "XYZ",
			};
		},
	}),
	node({
		id: "e263be25-8b2f-4f38-a7ed-cf393d9bb969",
		name: "Quaternion to Components",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.vector3,
		description: "Creates individual components from a Quaternion",
		predictable: true,
		inputs: {
			quaternion: {
				name: "Quaternion",
				type: "quaternion",
				control: "quaternion",
			},
		},
		outputs: {
			x: { name: "X", type: "number" },
			y: { name: "Y", type: "number" },
			z: { name: "Z", type: "number" },
			w: { name: "W", type: "number" },
		},
		resolve(inputs) {
			return {
				x: inputs.quaternion?.x,
				y: inputs.quaternion?.y,
				z: inputs.quaternion?.z,
				w: inputs.quaternion?.w,
			};
		},
	}),
	node({
		id: "0006-01-0003",
		name: "Components to Vector3",
		type: NODE_TYPE_ID.util.conversion,
		style: STYLE.vector3,
		description: "Creates a Vector3 from individual components",
		predictable: true,
		inputs: {
			x: { name: "X", type: "number", control: "number" },
			y: { name: "Y", type: "number", control: "number" },
			z: { name: "Z", type: "number", control: "number" },
		},
		outputs: {
			vector3: { name: "Vector3", type: "vector3" },
		},
		resolve(inputs) {
			return { vector3: new Vector3(inputs.x ?? 0, inputs.y ?? 0, inputs.z ?? 0) };
		},
	}),
	node({
		id: "0006-01-0008",
		name: "String to List",
		type: NODE_TYPE_ID.util.conversion,
		description: "Splits a String into a List",
		predictable: true,
		inputs: {
			string: { name: "String", type: "string" },
			separator: {
				name: "Separator",
				type: "string",
				control: "string",
			},
		},
		outputs: {
			list: { name: "List", type: "string", structure: "list" },
		},
		resolve(inputs) {
			if (!inputs.string) return { list: [] };
			return { list: inputs.string.split(inputs.separator) };
		},
	}),
	// Literals
	node({
		id: "0006-02-0001",
		name: "String",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.string,
		description: "String literal",
		predictable: true,
		controls: {
			value: { type: "string" },
		},
		outputs: {
			value: { name: "String", type: "string" },
		},
		resolve(inputs) {
			return { value: inputs.value };
		},
	}),
	node({
		id: "0c7f1ebf-f2ea-4640-a32a-e86168710bac",
		name: "Multi-line String",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.string,
		description: "String literal",
		predictable: true,
		controls: {
			value: { type: "string", control: "textarea" },
		},
		outputs: {
			value: { name: "String", type: "string" },
		},
		resolve(inputs) {
			return { value: inputs.value };
		},
	}),
	node({
		id: "0006-02-0002",
		name: "Number",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.number,
		description: "Number literal",
		predictable: true,
		controls: {
			value: { type: "number" },
		},
		outputs: {
			value: { name: "Number", type: "number" },
		},
		resolve(inputs) {
			return { value: inputs.value };
		},
	}),
	node({
		id: "0006-02-0003",
		name: "Boolean",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.boolean,
		description: "Boolean literal",
		predictable: true,
		controls: {
			value: { type: "boolean" },
		},
		outputs: {
			value: { name: "Boolean", type: "boolean" },
		},
		resolve(inputs) {
			return { value: !!inputs.value };
		},
	}),
	node({
		id: "0006-02-0004",
		name: "Vector3",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.vector3,
		description: "Vector3 literal",
		predictable: true,
		inputs: {
			x: { name: "X", type: "number" },
			y: { name: "Y", type: "number" },
			z: { name: "Z", type: "number" },
		},
		outputs: {
			vector3: { name: "Vector3", type: "vector3" },
		},
		resolve(inputs) {
			return { vector3: new Vector3(inputs.x ?? 0, inputs.y ?? 0, inputs.z ?? 0) };
		},
	}),
	node({
		id: "a17a062b-4acb-4dda-b055-49f30081a3a3",
		name: "Euler",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.euler,
		description: "Euler literal",
		predictable: true,
		inputs: {
			x: { name: "X", type: "number" },
			y: { name: "Y", type: "number" },
			z: { name: "Z", type: "number" },
			order: {
				name: "Order",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () =>
						EULER_ORDERS.map((o) => ({
							label: o,
							value: o,
						})),
				},
			},
		},
		outputs: {
			euler: {
				name: "Euler",
				type: "euler",
			},
		},
		resolve(inputs) {
			return { euler: new Euler(inputs.x ?? 0, inputs.y ?? 0, inputs.z ?? 0, inputs.order ?? "XYZ") };
		},
	}),
	node({
		id: "1c5b15a5-0bcb-4530-979c-89d6e2757b77",
		name: "Quaternion",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.quaternion,
		description: "Quaternion literal",
		predictable: true,
		inputs: {
			x: { name: "X", type: "number", config: { defaultValue: 0 } },
			y: { name: "Y", type: "number", config: { defaultValue: 0 } },
			z: { name: "Z", type: "number", config: { defaultValue: 0 } },
			w: { name: "W", type: "number", config: { defaultValue: 1 } },
		},
		outputs: {
			quaternion: { name: "Quaternion", type: "quaternion" },
		},
		resolve(inputs) {
			return { quaternion: new Quaternion(inputs.x ?? 0, inputs.y ?? 0, inputs.z ?? 0, inputs.w ?? 1) };
		},
	}),
	// Constants
	node({
		id: "0006-02-0005",
		name: "Block Type",
		type: NODE_TYPE_ID.util.literal,
		style: STYLE.string,
		description: `Returns the internal name of a block type`,
		predictable: true,
		controls: {
			block: {
				name: "Block",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: SelectOptionsContext) => {
						const blockList = Array.from(ctx.world.blockTypeRegistry.blockNameToType.values());
						return blockList.map((def) => ({
							label: def.display,
							value: def.name,
						}));
					},
				},
			},
		},
		outputs: {
			type: { name: "Type", type: "string" },
		},
		resolve(inputs) {
			return { type: inputs.block };
		},
	}),
];
