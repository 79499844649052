import { DEFAULT_BLOCK_SOUNDS } from "@jamango/content-client";
import { NODE } from "base/rete/InternalNameMap";
import * as trigger from "base/rete/modules/trigger";
import { randInt } from "base/util/math/Math.ts";
import { NetEventDispatcher } from "base/util/NetEventDispatcher.js";
import {
	BLOCK_AIR,
	BLOCK_COLLISION_DISABLED,
	BLOCK_COLLISION_ENABLED,
	BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED,
	BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN,
	BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE,
	BLOCK_TRANSPARENCY_OPAQUE,
	BLOCK_TRANSPARENCY_TRANSLUCENT,
	BLOCK_TRANSPARENCY_TRANSPARENT,
} from "base/world/block/Util.js";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import * as InteractLabelsRouter from "router/world/fx/InteractLabels";

//"container" is ChunkScene
//both have world property and have methods: getShape, getType, getBlockType, setBlock, getMetadata, setMetadata

/**
 * @typedef {{
 *   name: string,
 *   display?: string,
 *   meta?: { [key: string]: any },
 *   transparency: "opaque" | "transparent" | "translucent",
 *   transparencyBehaviour?: "single" | "join",
 *   collision: "enabled" | "disabled",
 *   faces?: unknown,
 *   sound?: {
 *     build?: { asset: string, start: number, end: number } | { asset: string, start: number, end: number }[],
 *     break?: { asset: string, start: number, end: number } | { asset: string, start: number, end: number }[],
 *     footstep?: { asset: string, start: number, end: number, volume?: number } | { asset: string, start: number, end: number, volume?: number }[],
 *   },
 *   events?: import('@jamango/content-client').Events,
 * }} BlockTypeOptions
 */

export class BlockType {
	static assets = [
		{
			id: "tex-missing",
			url: "assets/engine/textures/tex-missing.png",
			type: "image/png",
		},
	];

	/** @type {any[]|undefined} */
	faceDefs;

	/** @type {any[] | undefined} */
	faces;

	/**
	 * @param {number} id
	 * @param {BlockTypeOptions} o
	 */
	constructor(id, o) {
		this.id = id;

		this.dispatcher = new NetEventDispatcher();
		this.metadata = new Map();

		/**
		 * @type {import('three').BufferGeometry | null}
		 */
		this.geom = null;

		if (o.name === "bb.default" || o.name === "bb.block.air")
			throw Error(
				`Illegal block type name "${o.name}". The police have been dispatched to your location. beedo beedo"`,
			);

		this.name = o.name;
		this.display = o.display;

		/**
		 * used for generating faces with uv coordinate data structure.
		 * also used for fetching face asset in the frontend - See usage at JacyEngine.ts
		 * @type {Array<any>}
		 */
		this.faceDefs = o.faces;

		this.friction = o.friction ?? 1;

		switch (o.transparency) {
			case "transparent":
				this.transparency = BLOCK_TRANSPARENCY_TRANSPARENT;
				this.transparencyBehaviour =
					o.transparencyBehaviour === "single"
						? BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE
						: BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN;
				break;
			case "translucent":
				this.transparency = BLOCK_TRANSPARENCY_TRANSLUCENT;
				this.transparencyBehaviour =
					o.transparencyBehaviour === "single"
						? BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE
						: BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN;
				break;
			default:
				this.transparency = BLOCK_TRANSPARENCY_OPAQUE;
				this.transparencyBehaviour = BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED;
				break;
		}

		switch (o.collision) {
			case "disabled":
				this.collision = BLOCK_COLLISION_DISABLED;
				break;
			default:
				this.collision = BLOCK_COLLISION_ENABLED;
				break;
		}

		this.sound = {
			build: structuredClone(o.sound?.build ?? DEFAULT_BLOCK_SOUNDS.build),
			break: structuredClone(o.sound?.break ?? DEFAULT_BLOCK_SOUNDS.break),
			footstep: structuredClone(o.sound?.footstep ?? DEFAULT_BLOCK_SOUNDS.footstep),
		};
	}

	getFootstepSound() {
		if (Array.isArray(this.sound.footstep))
			return this.sound.footstep[randInt(0, this.sound.footstep.length - 1)];

		return this.sound.footstep;
	}

	getBuildSound() {
		if (Array.isArray(this.sound.build)) return this.sound.build[randInt(0, this.sound.build.length - 1)];

		return this.sound.build;
	}

	getBreakSound() {
		if (Array.isArray(this.sound.break)) return this.sound.break[randInt(0, this.sound.break.length - 1)];

		return this.sound.break;
	}

	dispose() {
		this.model?.dispose();
	}
}

export function triggerBlockChangeEvents(
	container,
	x,
	y,
	z,
	prvShape,
	newShape,
	prvType,
	newType,
	character,
) {
	const prvIsAir = prvShape === BLOCK_AIR;
	const newIsAir = newShape === BLOCK_AIR;

	if (prvIsAir && !newIsAir) {
		BlockGroupsRouter.onBuild(container.world.blockGroups, container.world, x, y, z);
	}

	if (prvIsAir !== newIsAir || prvType !== newType) {
		InteractLabelsRouter.markPositionDirty(container.world, x, y, z);
	}

	if ((prvIsAir && !newIsAir) || (!newIsAir && prvType !== newType)) {
		trigger.onBlockTypeEvent(NODE.OnBlockSpawn, container.world, x, y, z);
	}

	if ((!prvIsAir && newIsAir) || (!prvIsAir && newType !== prvType)) {
		trigger.onBlockEvent(NODE.OnBlockBreak, container.world, x, y, z, { character });
	}

	if ((prvIsAir && !newIsAir) || newType !== prvType) {
		trigger.onBlockEvent(NODE.OnBlockBuild, container.world, x, y, z, { character });
	}

	if (!prvIsAir && !newIsAir && prvShape !== newShape && prvType === newType) {
		trigger.onBlockEvent(NODE.OnBlockSculpt, container.world, x, y, z, { character });
	}
}
