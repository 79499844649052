import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { Input, InputCommands } from "@jamango/engine/Input.ts";
import { isNullish } from "@jamango/helpers";
import type { EulerLike } from "base/util/math/Math.ts";
import { getYawFromQuaternion, VY } from "base/util/math/Math.ts";
import type { Character } from "base/world/entity/Character";
import type { World } from "base/world/World";
import type { AnimatableMesh } from "client/world/entity/util/AnimatableMesh";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";
import * as InputManagerServer from "server/world/InputManager";
import type { QuaternionLike } from "three";
import { Euler, Object3D, Quaternion } from "three";
import { type Box3, MathUtils, Vector3, type Vector3Like } from "three";

import { AuthorityComponent } from "base/world/entity/component/Authority";
import type { CharacterDisabledInteractionsComponent } from "base/world/entity/component/CharacterDisabledInteractions";
import type { CharacterMovementComponent } from "base/world/entity/component/CharacterMovement";
import type { CharacterSelectorComponent } from "base/world/entity/component/CharacterSelector";
import type { CharacterSizeComponent } from "base/world/entity/component/CharacterSize";
import type { CharacterViewRaycastComponent } from "base/world/entity/component/CharacterViewRaycast";
import type { ChunkInvokerComponent } from "base/world/entity/component/ChunkInvoker";
import type { HealthComponent } from "base/world/entity/component/Health";
import type { InteractComponent } from "base/world/entity/component/Interact";
import type { ItemBaseComponent } from "base/world/entity/component/ItemBase";
import type { ItemTypeComponent } from "base/world/entity/component/ItemType";
import type { ItemWeaponComponent } from "base/world/entity/component/ItemWeapon";
import type { ModdingComponent } from "base/world/entity/component/Modding";
import { MountComponent } from "base/world/entity/component/Mount";
import type { NameplateComponent } from "base/world/entity/component/Nameplate";
import type { ParticleSystemComponent } from "base/world/entity/component/ParticleSystem";
import type { ProphecyComponent } from "base/world/entity/component/Prophecy";
import type { ProximityComponent } from "base/world/entity/component/Proximity";
import type { SceneTreeComponent } from "base/world/entity/component/SceneTreeNode";
import {
	SerializationComponent,
	desV3,
	desQuat,
	serDes,
	serV3,
	serQuat,
} from "base/world/entity/component/Serialization";
import type { CharacterSpawnComponent } from "base/world/entity/component/CharacterSpawn";
import type { Text3DComponent } from "base/world/entity/component/Text3D";
import { TypeComponent } from "base/world/entity/component/Type";

export type EntityCreateOptions = {
	def: string;
	id: EntityID;
	sceneTreeNode?: string;
	x: number;
	y: number;
	z: number;
	qx: number;
	qy: number;
	qz: number;
	qw: number;
};

const _dismountQuaternion = new Quaternion();
const _euler = new Euler();
const _quaternion = new Quaternion();

//methods are meant to be overridden
export class Entity {
	type: TypeComponent;
	mount: MountComponent;
	authority: AuthorityComponent;
	serialization: SerializationComponent;

	position: Vector3;
	prevPosition: Vector3;
	quaternion: Quaternion;
	prevQuaternion: Quaternion;
	scale: Vector3;

	object3D: Object3D;
	mesh?: AnimatableMesh;

	// continuous input
	input?: Input;

	// discrete input commands
	cmd?: InputCommands;

	//components that exist on subclasses but never the base entity
	nameplate?: NameplateComponent;
	health?: HealthComponent;
	chunkInvoker?: ChunkInvokerComponent;
	interact?: InteractComponent;
	modding?: ModdingComponent;
	movement?: CharacterMovementComponent;
	fpmesh?: any;
	weapon?: ItemWeaponComponent;
	proximity?: ProximityComponent;
	base?: ItemBaseComponent;
	itemType?: ItemTypeComponent;
	spawn?: CharacterSpawnComponent;
	size?: CharacterSizeComponent;
	viewRaycast?: CharacterViewRaycastComponent;
	selector?: CharacterSelectorComponent;
	text3D?: Text3DComponent;
	particleSystem?: ParticleSystemComponent;
	prophecy?: ProphecyComponent;
	sceneTree?: SceneTreeComponent;
	disabledInteractions?: CharacterDisabledInteractionsComponent;

	entityID: EntityID;
	disposeRequested: boolean;
	disposed: boolean;
	def: string;
	components: any[];

	canSkipUpdate: boolean;
	despawnTimer: number;
	despawnTimerPause: boolean;

	/**
	 * xyz position in constructor can't be a vector
	 * all subclass constructors should have these same arguments
	 * call postConstructor() at end of constructor
	 */
	constructor(
		o: EntityCreateOptions,
		def: Record<string, any>,
		readonly world: World,
	) {
		this.entityID = o.id;
		this.disposeRequested = false;
		this.disposed = false;
		this.def = o.def;
		this.components = []; //this array exists solely for dispose() to iterate through in reverse order of construction

		//base components
		this.object3D = new Object3D();
		this.type = this.addComponent(new TypeComponent({ name: def.name, isNPC: def.isNPC }));
		this.authority = this.addComponent(new AuthorityComponent());
		this.mount = this.addComponent(new MountComponent(def, this));
		this.serialization = this.addComponent(new SerializationComponent(this));

		this.position = new Vector3(o.x, o.y, o.z);
		this.prevPosition = this.position.clone();
		this.quaternion = new Quaternion(o.qx, o.qy, o.qz, o.qw);
		this.prevQuaternion = this.quaternion.clone();
		this.scale = new Vector3(1, 1, 1);

		this.canSkipUpdate = false;

		this.despawnTimer = -1;
		this.despawnTimerPause = true;
	}

	postConstructor() {
		this.setOwner(netState.isHost);
		this.setDespawnTimer();
	}

	addComponent<T extends { name: string }>(component: T) {
		// @ts-expect-error set component
		this[component.name] = component;
		this.components.push(component);
		return component;
	}

	//must be host or have ownership
	//when overriding, always start with call to super.setDespawnTimer
	setDespawnTimer() {
		if (this.shouldInvokeChunks()) return;

		this.despawnTimer = this.world.despawnTime;
		this.despawnTimerPause = false;
	}

	// must not be mounted to anything
	// when overriding, always start with call to super.setPosition
	// if this returns true, cancel the rest of the child.setPosition
	setPosition(pos: Vector3Like, isTeleport?: boolean): true | undefined {
		if (this.beforeTransformChange(isTeleport)) return true;

		this.position.copy(pos);

		if (isTeleport) {
			this.prevPosition.copy(pos);
		}
	}

	// when overriding, always start with call to super.setQuaternion
	// if this returns true, cancel the rest of the child.setQuaternion
	setQuaternion(quaternion: QuaternionLike, isTeleport = false): true | undefined {
		if (this.beforeTransformChange(isTeleport)) return true;

		this.quaternion.copy(quaternion);

		if (isTeleport) {
			this.prevQuaternion.copy(quaternion);
		}
	}

	// override setQuaternion instead of setRotation
	setRotation(rot: EulerLike, isTeleport = false): true | undefined {
		_euler.set(rot.x, rot.y, rot.z, rot.order ?? "XYZ");
		_quaternion.setFromEuler(_euler);
		return this.setQuaternion(_quaternion, isTeleport);
	}

	beforeTransformChange(isTeleport: boolean = false) {
		if (this.mount.state.parent || this.disposed) return true;

		if (isTeleport && netState.isHost) {
			// when teleporting we flag for hard broadcasting of state
			InputManagerServer.markDesynced(this.world, this.entityID);
		}

		return false;
	}

	setScale(scale: number) {
		this.scale.setScalar(scale);
	}

	resetCamera() {
		const world = this.world;
		if (world.router.initComplete && netState.isClient && world.client) {
			const cam = world.client.camera;
			if (cam.target === this) cam.reset();
		}
	}

	serialize(includeMovement?: boolean) {
		for (const id of Entity.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}
		if (!this.mount.state.parent) {
			for (const id of Entity.serDesIds_unmounted) {
				this.serialization.serialize(id, includeMovement);
			}
		}

		return this.serialization.diffs;
	}

	deserialize(values: any[], includeMovement?: boolean) {
		this.serialization.deserialize(values, includeMovement);
	}

	//server side only, returns false when publicly owned, do not use to check if host owns something (use hasLocalAuthority() instead)
	isOwnedBy(peerID: string | boolean) {
		return !this.authority.state.public && this.authority.state.owner === peerID;
	}

	//returns bool, will be true if publicly owned
	hasLocalAuthority() {
		// TODO: stop using 'ibs' here
		const ibs = Net.impl.ibs;

		if (!ibs || this.authority.state.public) return true;
		return this.authority.state.owner === true;
	}

	//when overriding, always start with call to super.setOwner
	//v is the peer ID, not a boolean, with the exception of the host setting itself as owner
	setOwner(v: string | boolean) {
		// TODO: stop using 'ibs' here
		const ibs = Net.impl.ibs;

		if (!ibs) this.authority.state.owner = true;
		else if (netState.isHost) this.authority.state.owner = v === (ibs as any).peerID ? true : v;
		else this.authority.state.owner = v === (ibs as any).peerID;

		if (this.hasLocalAuthority()) this.setDespawnTimer();
	}

	//checks if entities can be mounted and handles multiplayer
	//implementation is in onMount; override onMount instead of this
	doMount(child: Entity, toggle: boolean, index?: number) {
		if (child === this) return false;
		if (this.authority.state.public >= 2 || child.authority.state.public >= 2) return false;

		if (!netState.isHost) {
			return;
		}

		if (!toggle && child.mount.state.parent !== this) return false;

		const mountPoints = this.mount.def.points;
		const indices: number[] = []; //list of mount point indices to check their availability
		if (isNullish(index)) {
			for (let i = 0; i < mountPoints.length; i++) indices.push(i);
		} else {
			indices.push(index);
		}

		for (const i of indices) {
			const mount = mountPoints[i];

			if ((toggle && !mount.entity) || (!toggle && mount.entity === child)) {
				if (toggle && child.mount.state.parent) this.doMount(child, false);

				child.onMount(this, toggle, i);
				(this.world.input as InputManagerServer.State).commands.push([
					"mount",
					child.entityID,
					this.entityID,
					toggle,
					i,
				]);
				// Net.sendToAll("entity_mount", [child.entityID, this.entityID, toggle, i]);
				return true; //cancel the rest of player's update loop
			}
		}

		return false;
	}

	//subclasses are meant to override this in order to toggle physics when mounting/dismounting
	onMount(parent: Entity, toggle: boolean, index: number) {
		const mount = parent.mount.def.points[index];
		mount.entity = toggle ? this : null;
		this.mount.state.parent = toggle ? parent : null;
		this.mount.state.index = index;

		if (toggle) {
			this.position.copy(parent.position);
			this.prevPosition.copy(parent.position);
			this.quaternion.copy(parent.quaternion);
			this.prevQuaternion.copy(parent.quaternion);
		} else {
			mount.mount = mount.originalMount;
			mount.mount.updateMatrixWorld();
			this.setPosition(mount.dismount.clone().applyMatrix4(mount.mount.matrixWorld), true);
			this.setQuaternion(
				_dismountQuaternion.setFromAxisAngle(VY, getYawFromQuaternion(parent.quaternion)),
				true,
			);
			this.resetCamera();
		}

		(toggle ? mount.mount : this.world.scene).add(this.object3D); //three.js will also auto remove entity from its current parent

		this.setDespawnTimer();
	}

	getActiveMountPoints() {
		let clark = 0;
		for (const mount of this.mount.def.points) if (mount.entity) clark++;

		return clark;
	}

	shouldInvokeChunks() {
		return false;
	}

	checkIfEntityIsInViewAngle(entity: Entity, viewAngle: number) {
		if (viewAngle >= 360) return true;

		const entityPosition = new Vector3();
		entityPosition.copy(entity.position);
		const entityPositionAsLocalPosition = this.object3D.worldToLocal(entityPosition);
		// Ignore any height, just "radar" rotation for now
		entityPosition.y = 0;
		const forwardVector = new Vector3(0, 0, -1);
		const angleBetween = forwardVector.angleTo(entityPositionAsLocalPosition);

		// Keep it in degrees for now, for easier debugging
		const angleInDegrees = MathUtils.radToDeg(angleBetween);
		// console.log('checkIfEntityIsInViewAngle ' + angleInDegrees + ': ' + viewAngle);
		// view angle is halfed - angle is betwen 0 ( front ) and 180 ( either side )
		if (angleInDegrees <= viewAngle / 2.0) {
			return true;
		}

		return false;
	}

	getAABB(out: Box3) {
		return out.makeEmpty();
	}

	defError(oops: any) {
		throw RangeError(`Invalid property on def "${this.def}": ${oops}`);
	}

	deferredDispose() {
		if (!this.world.deferredDispose.includes(this)) this.world.deferredDispose.push(this);

		this.disposeRequested = true;
	}

	//when overriding, always start with call to super.dispose
	//if this returns true, cancel the rest of the child.dispose
	dispose(worldIsDisposed?: boolean) {
		if (this.disposed) return true;

		this.disposeRequested = true;

		const world = this.world;

		if (!worldIsDisposed) {
			for (const otherEntity of this.world.entities) {
				if (otherEntity === this) continue;
				if (!otherEntity.type.def.isCharacter) continue;

				const otherChar = otherEntity as Character;
				for (const disabledInteraction of Object.values(otherChar.disabledInteractions.state))
					disabledInteraction.delete(this.entityID);
			}

			if (netState.isHost) {
				for (const mountPoint of this.mount.def.points)
					if (mountPoint.entity) this.doMount(mountPoint.entity, false);

				this.mount.state.parent?.doMount(this, false);
			}

			if (netState.isHost && this.authority.state.public < 2) {
				(this.world.input as InputManagerServer.State).commands.push(["dispose", this.entityID]);
			}
		}

		this.object3D.removeFromParent();

		for (let i = this.components.length - 1; i >= 0; i--) {
			this.components[i].dispose?.(this, worldIsDisposed);
		}

		if (netState.isHost || this.authority.state.public >= 2) {
			const i = world.deferredDispose.indexOf(this);
			if (i > -1) world.deferredDispose.splice(i, 1);
		}

		world.removeEntity(this);

		this.disposed = true;
	}

	static serDesIds: number[] = [];

	static serDesIds_unmounted: number[] = [
		// position
		serDes(
			"position",
			(e) => serV3(e.position, 1000),
			(e, v) => e.setPosition(desV3(e.position, v, 1000), false),
			true,
		),
		// quaternion
		serDes(
			"quaternion",
			(e) => serQuat(e.quaternion, 1000),
			(e, v) => e.setQuaternion(desQuat(e.quaternion, v, 1000), false),
			true,
		),
	];
}
