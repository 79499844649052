import { isNullish } from "./Nullish";

export function mergeObject<Value>(existingData: Value, defaultData: Value) {
	const result = structuredClone(defaultData);

	for (const key in defaultData) {
		if (typeof defaultData[key] === "object") {
			result[key] = isNullish(existingData[key])
				? defaultData[key]
				: mergeObject(existingData[key], defaultData[key]);
		} else {
			result[key] = existingData[key] ?? defaultData[key];
		}
	}

	for (const key in existingData) {
		if (result[key] === undefined) {
			result[key] = existingData[key];
		}
	}

	return result;
}

export function deepEqual<Value>(obj1: Value, obj2: Value): boolean {
	// Check if both are strictly equal
	if (obj1 === obj2) return true;

	// Check if both are not objects or one of them is null
	if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
		return false;
	}

	// Get the keys from both objects
	const keys1 = Object.keys(obj1) as Array<keyof Value>;
	const keys2 = Object.keys(obj2) as Array<keyof Value>;

	// Check if they have the same number of keys
	if (keys1.length !== keys2.length) return false;

	// Check if every key in obj1 exists in obj2 and is deeply equal
	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}
