import { z } from "zod";
import { MAX_WORLD_NAME_LENGTH, MIN_WORLD_NAME_LENGTH } from "../constants/world";
import { AssetType } from "../types";

const NameSchema = z
	.string()
	.min(MIN_WORLD_NAME_LENGTH, {
		message: `Name must be at least ${MIN_WORLD_NAME_LENGTH} characters long.`,
	})
	.max(MAX_WORLD_NAME_LENGTH, {
		message: `Name must be at most ${MAX_WORLD_NAME_LENGTH} characters long.`,
	});

export const name = (value: string) => {
	NameSchema.parse(value);
};

export const PackageDataSchema = z.object({
	pk: z.string(),
	type: z.literal(AssetType.PACKAGE_DATA),
	id: z.string(),
	version: z.string(),
	name: NameSchema,
	tags: z.array(z.string()),
	updatedAt: z.string(),
	createdAt: z.string(),
});

export const PackageIdSchema = z.string();
