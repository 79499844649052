import { netState } from "router/Parallelogram";

/**
 * @param {import("base/world/entity/Character").Character} entity
 * @param {number} stepDeltaTime
 * @param {import("@jamango/engine/Input.ts").Input} input
 */
export function characterAnimationPPResetUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	const cam = entity.world.client.camera;
	const firstPerson = cam.is1stPerson() && cam.target === entity;
	const locomotionInput = entity.locomotionInput;

	// revert manual additive bone changes
	// necessary as THREE.AnimationMixer we can't assume that updating the animation mixer will update all bones states
	if (!firstPerson) {
		if (
			entity.type.def.isPlayer &&
			locomotionInput.def.pelvisBone &&
			locomotionInput.def.tpLeftArmBone &&
			locomotionInput.def.tpRightArmBone &&
			locomotionInput.state.beforePPLeftArmBoneX !== null &&
			locomotionInput.state.beforePPRightArmBoneX !== null &&
			locomotionInput.state.beforePPPelvisBoneY !== null
		) {
			const pelvisBone = locomotionInput.def.pelvisBone;
			const leftArmBone = locomotionInput.def.tpLeftArmBone;
			const rightArmBone = locomotionInput.def.tpRightArmBone;

			leftArmBone.rotation.x = locomotionInput.state.beforePPLeftArmBoneX;
			rightArmBone.rotation.x = locomotionInput.state.beforePPRightArmBoneX;
			pelvisBone.rotation.y = locomotionInput.state.beforePPPelvisBoneY;
		}
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter && !entity.canSkipUpdate;
}
