import { z } from "zod";
import type { Events } from "../types";
import { AssetType, ScriptType, type IAudio } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

const events = z.custom<Events>((_value) => true);

export const ScriptSchema = z.object({
	pk: assetKeySchema(AssetType.SCRIPT),
	type: z.literal(AssetType.SCRIPT),
	scriptType: z.nativeEnum(ScriptType),
	enabled: z.boolean(),
	name: z.string(),
	description: z.string(),
	starred: z.boolean().optional(),
	icon: z.string(),
	events,
	packageId: PackageIdSchema.optional(),
});

export const all = (value: IAudio) => {
	ScriptSchema.parse(value);
};
