import { isNullish } from "@jamango/helpers";
import { NODE } from "base/rete/InternalNameMap";
import * as listener from "base/rete/modules/listener.ts";
import { VZ, rand, randInt } from "base/util/math/Math.ts";
import type { Character } from "base/world/entity/Character";
import { serDes as untypedSerDes } from "base/world/entity/component/Serialization";
import { entityIsCharacter } from "base/world/entity/component/Type";
import type { EntityCreateOptions } from "base/world/entity/Entity";
import { Entity } from "base/world/entity/Entity";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import * as Resources from "client/Resources";
import { CameraPerspective } from "client/world/Camera.js";
import { AnimatableMesh } from "client/world/entity/util/AnimatableMesh.js";
import { cancelDoubleTaps } from "client/world/InputManager";
import { netState } from "router/Parallelogram";
import type { Camera, Vector3 } from "three";
import { MathUtils } from "three";

import { CameraSettingsComponent } from "base/world/entity/component/CameraSettings";
import { ChunkInvokerComponent } from "base/world/entity/component/ChunkInvoker";
import { HealthComponent } from "base/world/entity/component/Health";
import { InteractComponent } from "base/world/entity/component/Interact";
import { ModdingComponent } from "base/world/entity/component/Modding";
import { NameplateComponent } from "base/world/entity/component/Nameplate";
import { ProphecyComponent } from "base/world/entity/component/Prophecy";
import { ProximityComponent } from "base/world/entity/component/Proximity";
import { SceneTreeComponent } from "base/world/entity/component/SceneTreeNode";

const serDes = untypedSerDes<PhysicalEntity>;

//methods are meant to be overridden
export class PhysicalEntity extends Entity {
	sceneTree: SceneTreeComponent;
	prophecy: ProphecyComponent;
	interact: InteractComponent;
	proximity: ProximityComponent;
	cameraSettings: CameraSettingsComponent;
	chunkInvoker: ChunkInvokerComponent;
	modding: ModdingComponent;
	health: HealthComponent;
	nameplate: NameplateComponent;

	constructor(o: EntityCreateOptions, def: Record<string, any>, world: World) {
		super(o, def, world);

		//base components
		this.sceneTree = this.addComponent(new SceneTreeComponent(o.sceneTreeNode));
		this.prophecy = this.addComponent(new ProphecyComponent(def, this));
		this.interact = this.addComponent(new InteractComponent(def, this));
		this.proximity = this.addComponent(new ProximityComponent(def, this));
		this.cameraSettings = this.addComponent(new CameraSettingsComponent(def));
		this.chunkInvoker = this.addComponent(new ChunkInvokerComponent(def));
		this.modding = this.addComponent(new ModdingComponent());
		this.nameplate = this.addComponent(new NameplateComponent(this));

		// health
		this.health = this.addComponent(new HealthComponent(def));

		if (netState.isClient && !isNullish(def.mesh))
			this.setMesh(new AnimatableMesh(def.mesh, Resources.idToResource));
	}

	postConstructor() {
		super.postConstructor();
	}

	setHealth(
		health: number,
		overrideInvincible: boolean,
		damagedByEntityID: number | undefined = undefined,
	) {
		const prvHealth = this.health.state.current;

		// Return early if mounted and taking damage
		if (this.mount.state.parent && health < prvHealth) return;

		//invincible is implemented as unable to subtract health
		const requestedDelta = health - prvHealth;
		if (requestedDelta < 0 && this.health.def.invincible && !overrideInvincible) return;

		// Clamp health values
		health = MathUtils.clamp(health, 0, this.health.def.max);
		if (health === prvHealth) return;

		//permissions check
		const attacker =
			damagedByEntityID !== undefined ? this.world.getEntity(damagedByEntityID) : undefined;
		if (
			attacker &&
			entityIsCharacter(attacker) &&
			attacker.disabledInteractions.state.cantAttack.has(this.entityID)
		)
			return;

		// Update health state
		this.health.state.current = health;

		// Handle health change events
		if (requestedDelta < 0) {
			// Cancel double taps for local input target
			if (this.isLocalInputTarget()) {
				cancelDoubleTaps(this.world);

				// Handle camera shake if not dead
				if (health > 0) {
					this.world.client.camera.makeShake({
						shakeRecovery: 0.85,
						shakeAngle: rand(5, 10) * (randInt(0, 1) === 0 ? -1 : 1),
						shakeAxis: VZ,
					});
				}
			}

			listener.onEntityEvent(NODE.OnEntityDamaged, this.world, this.entityID, {
				entity: this,
				attacker,
				amount: -requestedDelta,
				killed: health <= 0,
			});

			if (health > 0) {
				// Damage logic
				this.onDamaged(damagedByEntityID);
			} else {
				// Death logic
				this.onDeath(damagedByEntityID);
			}
		}

		// Sync health with UI
		if (netState.isClient) UI.player.sync(this, "health", health);

		return;
	}

	onDamaged(_damagedByEntityID: number | undefined = undefined) {}

	onDeath(damagedByEntityID: number | undefined = undefined) {
		if (netState.isClient) {
			const cam = this.world.client.camera;
			if (cam && cam.target === this) {
				cam.setCameraPerspective(CameraPerspective.THIRD_PERSON_BACK);
			}
		}

		if (netState.isHost) {
			const killer =
				damagedByEntityID !== undefined ? this.world.getEntity(damagedByEntityID) : undefined;

			listener.onEntityEvent(NODE.OnEntityDeath, this.world, this.entityID, {
				entity: this,
				killer,
			});

			if (killer) {
				listener.onEntityEvent(NODE.OnEntityKilled, this.world, killer.entityID, {
					entity: killer,
					victim: this,
				});
			}
		}
	}

	//this will send a command to the owner to set health on their end
	addHealth(amount: number, damagedByEntityID: number | undefined = undefined) {
		if (amount === 0) return;
		if (netState.isHost) this.setHealth(this.health.state.current + amount, false, damagedByEntityID);
	}

	kill() {
		this.setHealth(0, true);
	}

	setMaxHealth(value: number) {
		// only execute this on the host. should never run on a non-host
		// max health will be distributed via serialization system
		this.health.def.max = value;
		if (netState.isClient) UI.player.sync(this, "maxHealth", value);
	}

	isDead() {
		return this.health.state.current <= 0;
	}

	setMesh(animMesh: AnimatableMesh | undefined) {
		this.mesh?.dispose();

		this.mesh = animMesh;
		if (animMesh) this.object3D.add(animMesh.mesh);
	}

	setInteract(interactable: boolean, label: string | null = null) {
		this.interact.state.enable = interactable;
		this.interact.state.label = label;
	}

	onInteract(evt: any) {
		listener.onEntityEvent(NODE.OnInteractWithEntity, this.world, evt.entity.entityID, {
			entity: evt.entity,
			triggeredBy: evt.triggeredBy,
		});
	}

	serialize(includeMovement: boolean) {
		super.serialize(includeMovement);

		for (const id of PhysicalEntity.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}

		return this.serialization.diffs;
	}

	isLocalInputTarget() {
		// returns whether this is the entity currently controlled by the player
		return (
			netState.isClient && (this as PhysicalEntity | Character) === this.world.client.getPeerPlayer()
		);
	}

	//subclasses are meant to override this in order to toggle physics when mounting/dismounting
	onMount(parent: Entity, toggle: boolean, index: number) {
		super.onMount(parent, toggle, index);

		if (!toggle) {
			this.mesh?.toggleTransform(true);
		}

		this.setInteract(false);

		if (toggle) this.onProximityLeave();
		else this.onProximityEnter();
	}

	shouldInvokeChunks() {
		if (super.shouldInvokeChunks()) return true;
		return this.chunkInvoker.def.enableForType;
	}

	//mode is PROXMITY_ENTER or PROXIMITY_EXIT
	//searchFor either an entity type (array containing one or both of ["player", "npc"]) or a specific entity
	async waitCharacterProximity(distance: number, viewAngle = 360, mode: any, searchFor: any) {
		let res;
		const promise = new Promise((resolve) => (res = resolve)) as any;
		promise.resolve = res;

		promise.dst = distance;
		promise.viewAngle = viewAngle;
		promise.mode = mode;
		promise.searchFor = searchFor;

		this.proximity.state.promises.add(promise);
		return await promise;
	}

	//multiplayer version of AnimatableMesh.playBasicAnimation
	playAnimation(meshName: string, actionName: string) {
		// TODO: better solution than passing a property name as an arg
		(this as any)[meshName]?.playBasicAnimation(actionName);
	}

	//to be implemented by subclasses
	//first process input, then set mesh's position/quaternion from physics. keep values of hasLocalAuthority() and mount parent in mind
	onTarget(_camera: Camera) {} // on camera target entity
	onUntarget() {} // on camera untarget entity
	onProximityEnter() {}
	onProximityLeave() {}
	onGravityChange(_up: Vector3) {}

	//when overriding, always start with call to super.dispose
	//if this returns true, cancel the rest of the child.dispose
	dispose(worldIsDisposed = false) {
		if (super.dispose(worldIsDisposed)) return true;

		this.mesh?.dispose();

		this.modding.state.dispatcher.dispatchEvent(null, {
			entity: this,
			type: "dispose",
		});
	}

	static serDesIds = [
		serDes(
			"nameplate text",
			(e) => e.nameplate.def.nameplate.getText(),
			(e, v) => e.nameplate.def.nameplate.setText(v),
		),
		serDes(
			"nameplate show",
			(e) => Number(e.nameplate.state.show),
			(e, v) => (e.nameplate.state.show = Boolean(v)),
		),
		serDes(
			"is invincible",
			(e) => Math.round(e.health.def.invincible),
			(e, v) => (e.health.def.invincible = v),
		),
		serDes(
			"health max",
			(e) => Math.round(e.health.def.max),
			(e, v) => (e.health.def.max = v),
		),

		serDes(
			"interact enable",
			(e) => Number(e.interact.state.enable),
			(e, v) => (e.interact.state.enable = Boolean(v)),
		),
		serDes(
			"interact label",
			(e) => e.interact.state.label,
			(e, v) => (e.interact.state.label = v),
		),

		serDes(
			"health current",
			(e) => Math.round(e.health.state.current),
			(e, v) => e.setHealth(v, true),
		),
	];
}
