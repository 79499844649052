import { DEBUG_ENABLED } from "@jamango/generated";
import { createLogger } from "@jamango/helpers";
import PARTICLE_SYSTEMS_ASSETS from "./particle-systems.json";
import type { IPackageBundle, IParticleSystem } from "../../lib/types";
import { LATEST_BUNDLE_VERSION } from "../../lib/validations/bundle";
import { ParticleSystemSchema } from "../../lib/validations/particle-system";
import { getStaticFile } from "../../lib/helpers/cdn";

if (DEBUG_ENABLED) {
	const logger = createLogger("ParticleSystemsPackageValidation");

	for (const asset of Object.values(PARTICLE_SYSTEMS_ASSETS)) {
		const result = ParticleSystemSchema.safeParse(asset);

		if (result.error) {
			logger.error("Particle system does not match schema", result.error);
		}
	}
}

export const PARTICLE_SYSTEMS_PACKAGE = {
	version: LATEST_BUNDLE_VERSION,
	type: "package",
	id: "PARTICLE_SYSTEMS",
	assets: {
		particleSystems: PARTICLE_SYSTEMS_ASSETS as Record<string, IParticleSystem>,
	},
} satisfies IPackageBundle;

Object.values(PARTICLE_SYSTEMS_PACKAGE.assets.particleSystems).forEach((ps) => {
	ps.config.images.forEach((image) => {
		image.url = getStaticFile(image.url);
	});
});
