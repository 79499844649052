import { BodyTypeValue, type IAvatarObject } from "@jamango/content-client";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import type { Input, InputCommands } from "@jamango/engine/Input.ts";
import { isNullish } from "@jamango/helpers";
import TWEEN from "@tweenjs/tween.js";
import { NODE } from "base/rete/InternalNameMap";
import * as listener from "base/rete/modules/listener.ts";
import * as trigger from "base/rete/modules/trigger";
import { rand, VY } from "base/util/math/Math.ts";
import * as InputManagerBase from "base/world/InputManager";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import type { Entity, EntityCreateOptions } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";
import { PhysicalEntity } from "base/world/entity/PhysicalEntity.js";
import { desV3, serDes as serDesImpl, serV3 } from "base/world/entity/component/Serialization";
import { entityIsNPC } from "base/world/entity/component/Type";
import { resetCollisionState } from "base/world/entity/system/CharacterAnyCollision";
import {
	endOcclusionQuery,
	OCCLUSION_ENABLE_CUSTOM_BV,
	startOcclusionQuery,
} from "client/OcclusionCulling.js";
import * as Resources from "client/Resources";
import { UI } from "client/dom/UI";
import { defMesh } from "client/util/Defs.js";
import { cancelDoubleTaps } from "client/world/InputManager";
import { LocomotionStateMachine } from "client/world/entity/locomotion/StateMachine.js";
import { AnimatableMesh } from "client/world/entity/util/AnimatableMesh.js";
import { OcclusionQueryMesh } from "client/world/entity/util/OcclusionQueryMesh.js";
import { NET_CLIENT } from "@jamango/ibs";
import { setAvatarMesh } from "mods/defs/CharacterDefault/Character";
import { netState } from "router/Parallelogram";
import * as InputManagerServer from "server/world/InputManager";
import type { Box3, QuaternionLike, Vector3Like } from "three";
import { CylinderGeometry, Quaternion, Vector3 } from "three";

import { CharacterCameraComponent } from "base/world/entity/component/CharacterCamera";
import { CharacterCollisionComponent } from "base/world/entity/component/CharacterCollision";
import { CharacterDisabledInteractionsComponent } from "base/world/entity/component/CharacterDisabledInteractions";
import { CharacterEquipmentComponent } from "base/world/entity/component/CharacterEquipment";
import { CharacterFallDamageComponent } from "base/world/entity/component/CharacterFallDamage";
import { CharacterFightingComponent } from "base/world/entity/component/CharacterFighting";
import { CharacterFootstepsComponent } from "base/world/entity/component/CharacterFootsteps";
import { CharacterLocomotionInputComponent } from "base/world/entity/component/CharacterLocomotionInput";
import { CharacterMovementComponent } from "base/world/entity/component/CharacterMovement";
import { CharacterPhysicsComponent } from "base/world/entity/component/CharacterPhysics";
import { CharacterRegenComponent } from "base/world/entity/component/CharacterRegen";
import { CharacterSelectorComponent } from "base/world/entity/component/CharacterSelector";
import { CharacterSizeComponent } from "base/world/entity/component/CharacterSize";
import { CharacterSoundsComponent } from "base/world/entity/component/CharacterSounds";
import { CharacterSpawnComponent } from "base/world/entity/component/CharacterSpawn";
import { CharacterViewRaycastComponent } from "base/world/entity/component/CharacterViewRaycast";
import { CharacterHUDComponent } from "base/world/entity/component/CharacterHUD";

const _testPos = new Vector3();
const _verticalVelocity = new Vector3();
const _hipRotation = new Vector3();
const _worldQuat = new Quaternion();

const _quaternion = new Quaternion();
const _direction = new Vector3();

const serDes = serDesImpl<Character>;

export class Character extends PhysicalEntity {
	movement: CharacterMovementComponent;
	size: CharacterSizeComponent;
	characterPhysics: CharacterPhysicsComponent;
	viewRaycast: CharacterViewRaycastComponent;
	selector: CharacterSelectorComponent;
	regen: CharacterRegenComponent;
	fallDamage: CharacterFallDamageComponent;
	collision: CharacterCollisionComponent;
	equipment: CharacterEquipmentComponent;
	locomotionInput: CharacterLocomotionInputComponent;
	avatarObject: IAvatarObject | undefined;
	disabledInteractions: CharacterDisabledInteractionsComponent;
	characterFootsteps: CharacterFootstepsComponent;
	fighting: CharacterFightingComponent;
	sounds: CharacterSoundsComponent;
	characterCamera: CharacterCameraComponent;
	characterHUD: CharacterHUDComponent;
	spawn: CharacterSpawnComponent;

	input: Input;
	cmd: InputCommands;

	meshLocomotion: LocomotionStateMachine | null;
	fpmeshLocomotion: LocomotionStateMachine | null;

	occlusionMesh: OcclusionQueryMesh | null;
	isRemoved: boolean;

	edgeDetection: boolean;
	characterName: string | null;
	respawnTime: number;
	respawnTimer: number;
	aiAttackTime: number;
	aiWanderTime: number;
	aiMoveToTime: number;
	aiApproachDistance: number;
	aiFireRateMultiplier: number;
	aiType: string;
	aiEvasionDir: number;
	aiNeedsEvade: boolean;
	aiIsEvadingTimer: number;
	aiBehavior: string;
	aiTarget: any;
	aiTargetPosition: Vector3 | null;
	aiShootHitPercentage: number;
	aiAttackTimer: number;
	aiWanderOrigin: Vector3;
	aiWanderTimer: number;
	aiShootAtTimer: number;
	aiShootAtCooldownMaxJitter: number;
	aiShootAtCooldownJitterSkew: number;
	aiDropItem: boolean;
	aiCanKillNPCs: boolean;
	avatarThumbnail: string | null;
	weaponSpread: number;
	canPickUpItem: boolean;
	onHitTween: any;

	constructor(o: EntityCreateOptions, def: Record<string, any>, world: World) {
		super(o, def, world);

		this.chunkInvoker.def.enableForType = true;

		const initialQuaternion = _quaternion.set(o.qx, o.qy, o.qz, o.qw);
		const direction = _direction.set(0, 0, 1).applyQuaternion(initialQuaternion);
		const yaw = Math.atan2(direction.x, direction.z);

		//core
		this.movement = this.addComponent(new CharacterMovementComponent(def, this));
		this.size = this.addComponent(new CharacterSizeComponent(def, this));
		this.characterPhysics = this.addComponent(new CharacterPhysicsComponent(def, this));
		this.viewRaycast = this.addComponent(new CharacterViewRaycastComponent());
		this.selector = this.addComponent(new CharacterSelectorComponent());
		this.spawn = this.addComponent(new CharacterSpawnComponent(o.x, o.y, o.z, yaw));

		//need to have fpmesh already set up for the equipment component
		this.meshLocomotion = null;
		this.fpmeshLocomotion = null;

		if (netState.isClient && !isNullish(def.fpmesh)) {
			this.setFPMesh(new AnimatableMesh(def.fpmesh, Resources.idToResource));
		}

		this.regen = this.addComponent(new CharacterRegenComponent(def, this));
		this.fallDamage = this.addComponent(new CharacterFallDamageComponent(def));
		this.fighting = this.addComponent(new CharacterFightingComponent(def));
		this.sounds = this.addComponent(new CharacterSoundsComponent(def));
		this.equipment = this.addComponent(new CharacterEquipmentComponent(def, this));
		this.collision = this.addComponent(new CharacterCollisionComponent());
		this.locomotionInput = this.addComponent(new CharacterLocomotionInputComponent(def, this, yaw));
		this.characterCamera = this.addComponent(new CharacterCameraComponent(def, this));
		this.characterHUD = this.addComponent(new CharacterHUDComponent());
		this.characterFootsteps = this.addComponent(new CharacterFootstepsComponent());
		this.disabledInteractions = this.addComponent(new CharacterDisabledInteractionsComponent());

		// NARRATOR: ... and in an unexpected turn of events, "for now" actually meant a very long time ...
		// quick hack for now
		if (this.type.def.isNPC && netState.isClient) {
			// todo: types
			let geometry = {} as any;
			if (OCCLUSION_ENABLE_CUSTOM_BV) {
				const mesh = defMesh({ asset: "occlusion-character-volume" }, Resources.idToResource);
				geometry = mesh.children[0].geometry.clone();
			} else {
				geometry = new CylinderGeometry(0.9, 0.9, 3.7, 6, 6);
			}
			const occlusionMesh = new OcclusionQueryMesh(geometry);

			// this is really TERRIBLE hack, but it solves the problem of three.js
			// not having refrence to rendered mesh in onBeforeRender/onAfterRender
			geometry.userData = {};
			geometry.userData.meshReference = occlusionMesh;

			this.occlusionMesh = occlusionMesh;
			this.occlusionMesh.renderOrder = Infinity;
			this.occlusionMesh.characterOwner = this;

			this.occlusionMesh.occlusionQueryCallback = function (result: boolean) {
				this.characterOwner!.canSkipUpdate = !result;
				if (!result) {
					if (!this.characterOwner!.isRemoved) {
						world.scene.remove(this.characterOwner!.object3D!);
						this.characterOwner!.isRemoved = true;
					}
				} else {
					if (this.characterOwner!.isRemoved) {
						world.scene.add(this.characterOwner!.object3D!);
						this.characterOwner!.isRemoved = false;
					}
				}
			};

			this.isRemoved = false;

			this.occlusionMesh.onBeforeRender = (renderer, scene, camera, geometry) =>
				startOcclusionQuery(renderer, scene, camera, geometry, world.frame);
			this.occlusionMesh.onAfterRender = (renderer, scene, camera, geometry) =>
				endOcclusionQuery(renderer, scene, camera, geometry, world.frame);

			world.client.sceneOcclusion!.add(this.occlusionMesh);
		}

		this.characterName = def?.characterName ?? null;

		this.respawnTime = def?.respawnTime ?? 3;
		this.respawnTimer = -1;

		this.characterPhysics.def.enabled = true;

		this.cameraSettings.def.camera.fov = this.characterCamera.state.fovTarget =
			this.characterCamera.def.fovWalk;

		this.setCanPickUpItem(def?.canPickUpItem ?? true);

		this.edgeDetection = true;

		this.aiAttackTime = def?.aiAttackTime ?? 0.5;
		this.aiWanderTime = def?.aiWanderTime ?? 5;
		this.aiMoveToTime = def?.aiMoveToTime ?? 15;
		this.aiApproachDistance = def?.aiApproachDistance ?? 5;
		this.aiFireRateMultiplier = def?.aiFireRateMultiplier ?? 0.5; // 1 is fullspeed
		this.aiType = def?.aiType ?? "neutral";
		this.aiEvasionDir = 0;
		this.aiNeedsEvade = false;
		this.aiIsEvadingTimer = 0;
		this.aiBehavior = "idle";
		this.aiTarget = null;
		this.aiTargetPosition = null;
		this.aiShootHitPercentage = def?.aiShootHitPercentage ?? 1;
		this.aiAttackTimer = 0;
		this.aiWanderOrigin = new Vector3();
		this.aiWanderTimer = 0;
		this.aiShootAtTimer = rand(0.5, 2); // delay before ai shooting begin
		this.aiShootAtCooldownMaxJitter = 2; // max jitter in seconds between shots
		this.aiShootAtCooldownJitterSkew = 2.5; // skew exponent for jitter
		this.aiDropItem = def?.aiDropItem ?? true;
		this.aiCanKillNPCs = def?.aiCanKillNPCs ?? false;
		this.avatarThumbnail = def?.avatarThumbnail ?? null;
		this.weaponSpread = 0; // max degrees of spread
		this.avatarObject = undefined;

		if (this.type.def.isNPC && netState.isHost) {
			if (this.aiType === "enemy") this.aiSetBehavior("attack");
			// NPCs set meshes upon instantiation. Real players receive them from other sources, post creation
			// non-hosts dont need this, they receive it via serialization
			this.setAvatar(def.meta?.avatarObject);
		}

		// input is not a component because inputs may be an array for time based signals in the future
		this.input = InputManagerBase.createInput();
		this.cmd = [];

		this.postConstructor();
	}

	postConstructor() {
		super.postConstructor();
		this.respawn(true);
	}

	setSpawn(pos: Vector3, angle: number) {
		this.spawn.def.pos.copy(pos);
		this.spawn.def.angle = angle;
	}

	moveToSpawn() {
		this.setPosition(this.spawn.def.pos, true);
		this.setQuaternion(_quaternion.setFromAxisAngle(VY, this.spawn.def.angle), true);
	}

	respawn(isInitialSpawn: boolean) {
		this.setHealth(this.health.def.max, true);
		this.resetCamera();

		if (!isInitialSpawn) {
			listener.onEntityEvent(NODE.OnCharacterRespawn, this.world, this.entityID, {
				entity: this,
			});
		}
		this.moveToSpawn();

		if (!isInitialSpawn) this.resetActionStates();
	}

	onDamaged(damagedByEntityID: EntityID | undefined = undefined) {
		super.onDamaged(damagedByEntityID);

		const sound = this.sounds?.def.pain;
		if (sound) {
			this.world.sfxManager.playAtObj({ ...sound, obj: this });
		}

		if (this.type.def.isNPC) this.aiNeedsEvade = true;
		this.regen.state.timer = this.regen.def.delay;
	}

	onDeath(damagedByEntityID: EntityID | undefined = undefined) {
		super.onDeath(damagedByEntityID);

		// @ts-expect-error TODO: this.bodyType doesn't exist on Character?
		const sound = this.sounds?.deathSound(this.bodyType);
		if (sound) {
			this.world.sfxManager.playAtObj({ ...sound, obj: this });
		}

		this.resetActionStates();

		if (netState.isHost) {
			const item = this.getEquippedItem();
			if (item && !item.itemType?.def.isTool) this.doMount(item, false);
			this.setDespawnTimer();
		}
	}

	resetActionStates() {
		this.setSprinting(false);
		this.setFlying(false);
		this.setCrouch(true);
		this.setIronSights(false);
	}

	/**
	 * Sets the position and orientation of the character. Inherits behavior
	 * from the base class and performs additional updates related to physics
	 * and collision. If teleportation is involved, it resets character collisions.
	 *
	 * @param pos - The new position of the character.
	 * @param isTeleport - Specifies if the movement is a teleportation.
	 * @returns Returns true if the position change should be
	 * canceled in further processing by child classes.
	 */
	setPosition(pos: Vector3Like, isTeleport?: boolean) {
		if (super.setPosition(pos, isTeleport)) return true;

		if (isTeleport) resetCollisionState(this, true);
	}

	setQuaternion(quaternion: QuaternionLike, isTeleport?: boolean) {
		if (super.setQuaternion(quaternion, isTeleport)) return true;

		if (isTeleport) {
			this.setCameraInputFromQuaternion();
		}
	}

	setCameraInputFromQuaternion() {
		const direction = _direction.set(0, 0, 1).applyQuaternion(this.quaternion);
		const yaw = Math.atan2(direction.x, direction.z);

		// on hard resets enforced by the server, set the input object to face in the targeted direction.
		this.input.camera.theta = yaw;
		if (this.isLocalInputTarget()) {
			this.world.client.camera.sph.theta = yaw;
			this.world.client.camera.targetTheta = yaw;
		}
	}

	// implementation is in onSetCamera
	setCamera(phi: number, theta: number) {
		this.onSetCamera(phi, theta);

		if (netState.isHost) {
			(this.world.input as InputManagerServer.State).commands.push([
				"characterSetCamera",
				this.entityID,
				phi,
				theta,
			]);
		}
	}

	// implementation, triggered by calling setCamera
	onSetCamera(phi: number, theta: number) {
		this.input.camera.phi = phi;
		this.input.camera.theta = theta;

		if (this.isLocalInputTarget()) {
			this.world.client.camera.sph.phi = phi;
			this.world.client.camera.sph.theta = theta;

			this.world.client.camera.targetPhi = phi;
			this.world.client.camera.targetTheta = theta;
		}
	}

	setAvatar(o: IAvatarObject | undefined = undefined) {
		const avatarObject: IAvatarObject = o ?? {
			bodyType: BodyTypeValue.BODY_TYPE_1,
			arms: null,
			backpack: null,
			hat: null,
			mask: null,
			face: null,
			hair: null,
			legs: null,
			torso: null,
			skinColor: "#FFC4A1",
			hairColor: "#352214",
		};

		this.avatarObject = avatarObject;

		if (netState.isClient) setAvatarMesh(this, avatarObject);
	}

	//nodes: itemNode, rootBone, neckBone
	setMesh(animMesh: AnimatableMesh, nodes: Record<string, any> | undefined = undefined) {
		super.setMesh(animMesh);
		if (animMesh) {
			this.meshLocomotion = new LocomotionStateMachine(this, animMesh, this.locomotionInput, true);

			if (this.equipment) this.equipment.def.itemNode = nodes?.itemNode;

			if (this.locomotionInput) {
				this.locomotionInput.def.rootBone = nodes?.rootBone;
				this.locomotionInput.def.pelvisBone = nodes?.pelvisBone;
				this.locomotionInput.def.spineBone01 = nodes?.spineBone01;
				this.locomotionInput.def.spineBone02 = nodes?.spineBone02;
				this.locomotionInput.def.neckBone = nodes?.neckBone;
				this.locomotionInput.def.tpLeftArmBone = nodes?.tpLeftArmBone;
				this.locomotionInput.def.tpRightArmBone = nodes?.tpRightArmBone;
			}
		} else {
			this.meshLocomotion = null;
		}
	}

	//nodes: fpItemNode
	setFPMesh(animMesh: AnimatableMesh, nodes: Record<string, any> | undefined = undefined) {
		this.fpmesh?.dispose();

		if (animMesh) {
			this.fpmesh = animMesh;
			animMesh.disableFrustumCulling();
			this.fpmeshLocomotion = new LocomotionStateMachine(this, animMesh, this.locomotionInput, false);

			if (this.locomotionInput) {
				this.locomotionInput.def.fpLeftArmBone = nodes?.fpLeftArmBone;
				this.locomotionInput.def.fpRightArmBone = nodes?.fpRightArmBone;
			}

			if (this.equipment) {
				this.equipment.def.fpItemNode = nodes?.fpItemNode;
			}
		} else {
			this.fpmeshLocomotion = null;
		}
	}

	aiSetBehaviourToAttack() {
		const item = this.getEquippedItem();

		if (item && item.itemType.def.isRanged) this.aiSetBehavior("shootat");
		else this.aiSetBehavior("attack");
	}

	// make hips rotate backwards to indicate hit by bullet
	animateOnHit(worldHitDirection: Vector3, recoveryTime: number) {
		if (!netState.isClient) return;

		if (this.onHitTween) {
			this.onHitTween.stop();
			this.onHitTween = null;
		}

		const decalHipRotation = _hipRotation
			.copy(worldHitDirection)
			.applyQuaternion(this.object3D.getWorldQuaternion(_worldQuat).invert())
			.normalize()
			.multiplyScalar(-0.5);

		const decalHipRotationTarget = {
			x: decalHipRotation.x,
			y: decalHipRotation.z,
		};

		const backTime = 400 * recoveryTime;
		const recoverTime = 600 * recoveryTime;

		const backTween = new TWEEN.Tween(this.locomotionInput.state.decalHipRotationTarget)
			.to(decalHipRotationTarget, backTime)
			.easing(TWEEN.Easing.Exponential.Out);

		const recoverTween = new TWEEN.Tween(this.locomotionInput.state.decalHipRotationTarget)
			.to({ x: 0, y: 0 }, recoverTime)
			.easing(TWEEN.Easing.Exponential.In);

		this.onHitTween = backTween
			.chain(recoverTween)
			.start()
			.onComplete(() => {
				this.onHitTween = null;
			});
	}

	aiReactToHit() {
		if (this.aiType !== "enemy") return;
		this.aiSetBehaviourToAttack();
		this.aiNeedsEvade = true;
		this.aiCheckForNearbyAIToaiReactToHit();
	}

	aiCheckForNearbyAIToaiReactToHit() {
		for (const e of this.world.entities) {
			if (e !== this) {
				if (entityIsNPC(e) && e.aiType === "enemy" && !e.isDead()) {
					const distance = this.position.distanceTo(e.position);
					if (distance < 25) {
						e.aiSetBehaviourToAttack();
					}
				}
			}
		}
	}

	setDespawnTimer() {
		this.despawnTimerPause = !this.isDead();

		if (this.type.def.isPlayer) this.respawnTimer = this.respawnTime;
		else super.setDespawnTimer();
	}

	setIronSights(toggle: boolean) {
		if (toggle === this.movement.state.isIronSights) return;

		const equippedItem = this.getEquippedItem();

		if (toggle && (!equippedItem?.itemType.def.isRanged || equippedItem.weapon.state.isReloading)) return;

		this.movement.state.isIronSights = toggle;

		if (toggle) this.setSprinting(false);
	}

	serialize(includeMovement: boolean) {
		super.serialize(includeMovement);
		// TODO: the are diffed every frame (very cheap) - good to keep things very bug free and codebase clean
		// however, a technically superior solution would be to serialize these inside of the functions (e.g. setSpeedAir)
		// but then we need to get more sophisticated around serialization.reset()
		for (const id of Character.serDesIds) {
			this.serialization.serialize(id, includeMovement);
		}
		if (!this.mount.state.parent) {
			for (const id of Character.serDesIds_unmounted) {
				this.serialization.serialize(id, includeMovement);
			}
		}
		if (this.type.def.isPlayer) {
			for (const id of Character.serDesIds_player) {
				this.serialization.serialize(id, includeMovement);
			}
		}

		this.serialization.serialize(Character.serDesIds_avatar, includeMovement);

		return this.serialization.diffs;
	}

	//unlikely to ever happen. equates to mind control
	setOwner(v: boolean) {
		const prvOwner = this.hasLocalAuthority();
		super.setOwner(v);
		const newOwner = this.hasLocalAuthority();

		if (
			netState.isClient &&
			this.world.router.initComplete &&
			this.world.client.camera.target === this &&
			prvOwner !== newOwner
		) {
			const item = this.getEquippedItem();
			this.world.dispatcher.dispatchEvent(NET_CLIENT, {
				type: "itemchange",
				prvItem: prvOwner ? item : null,
				curItem: newOwner ? item : null,
			});
		}
	}

	onMount(parent: Entity, mounted: boolean, index: number) {
		this.characterPhysics.state.enabled = !mounted;

		if (mounted) {
			this.setSprinting(false);
			this.setCrouch(false);

			resetCollisionState(this, true);
		}

		if (this.isLocalInputTarget()) cancelDoubleTaps(this.world);

		super.onMount(parent, mounted, index);
	}

	shouldInvokeChunks() {
		return super.shouldInvokeChunks() && !this.isDead() && !this.movement.state.isFlying;
	}

	doInteract() {
		const item = this.getEquippedItem();
		const viewRaycast = this.viewRaycast.state;

		const entity = viewRaycast.character ?? viewRaycast.item ?? viewRaycast.prop;
		if (entity) {
			item?.fireAnimation();
			entity.onInteract({ entity, triggeredBy: this });
		} else if (viewRaycast.block !== null) {
			item?.fireAnimation();
			const { x, y, z } = viewRaycast.block;
			trigger.onBlockEvent(NODE.OnInteractWithBlock, this.world, x, y, z, { character: this });
		}
	}

	physicsNeedsUpdate() {
		return (
			this.characterPhysics.def.enabled !== this.characterPhysics.state.enabled ||
			this.size.state.scale !== this.characterPhysics.state.scale
		);
	}

	addPhysics() {
		if (this.characterPhysics.state.controller) {
			return;
		}

		if (this.characterPhysics.def.enabled) {
			this.size.state.currentHeight = this.size.state.height;
			if (this.movement.state.isCrouching) {
				this.size.state.currentHeight -= this.size.state.crouchOffset;
			}

			const padding = this.size.def.padding;
			const co = this.size.state.contactOffset;
			const radius = this.size.state.radius - co;

			const heightStanding = this.size.state.height;
			const heightCrouching = this.size.state.height - this.size.state.crouchOffset;

			this.size.state.currentHeight = this.movement.state.isCrouching
				? heightCrouching
				: heightStanding;

			this.characterPhysics.state.controller = Physics.createCharacterController(
				this.world.physics,
				radius,
				padding,
				heightStanding,
				heightCrouching,
				this.movement.state.isCrouching,
				this.prophecy.state.isProphecy,
				this.entityID,
			);
		}

		this.characterPhysics.state.scale = this.size.state.scale;
		this.characterPhysics.state.enabled = true;
		this.characterPhysics.state.isCrouching = this.movement.state.isCrouching;
	}

	removePhysics() {
		if (!this.characterPhysics.state.controller) {
			return;
		}

		Physics.disposeCharacterController(this.characterPhysics.state.controller);
		this.characterPhysics.state.controller = undefined;

		this.characterPhysics.state.enabled = false;
		this.characterPhysics.state.isCrouching = undefined;
		this.characterPhysics.state.scale = undefined;
	}

	getAABB(out: Box3) {
		if (this.characterPhysics.state.controller === undefined) {
			return out.makeEmpty();
		}

		Physics.getCharacterControllerAABB(this.characterPhysics.state.controller, out);

		return out;
	}

	setSprinting(v: boolean) {
		const item = this.getEquippedItem();
		if (
			v &&
			(!this.movement.def.canSprint ||
				!this.movement.def.canMoveHorizontally ||
				this.movement.state.isCrouching ||
				this.movement.state.isIronSights ||
				(item?.itemType.def.isRanged && (item.weapon.state.isReloading || item.base.state.used)))
		) {
			if (this.isLocalInputTarget()) cancelDoubleTaps(this.world);
			return;
		}

		this.movement.state.isSprinting = v;
	}

	setCanSprint(v: boolean) {
		if (!v) this.setSprinting(false);

		this.movement.def.canSprint = v;
	}

	setFlying(v: boolean) {
		if (this.movement.state.isFlying === v) return;

		this.setCrouch(false);
		resetCollisionState(this, true);

		if (netState.isClient) UI.player.sync(this, "flying", v);
		this.movement.state.isFlying = v;
	}

	setCrouch(v: boolean) {
		if (this.movement.state.isCrouching === v) return;

		this.movement.state.isCrouching = v;

		if (this.disposed) return;

		if (v) {
			this.setSprinting(false);
		}

		this.cancelEmote();

		if (netState.isClient) UI.player.sync(this, "crouching", v);
	}

	setCanCrouch(v: boolean) {
		if (!v) this.setCrouch(false);

		this.movement.def.canCrouch = v;
	}

	//determined by comparing angle of gravity vector to velocity vector. 0 velocity is counted as falling
	isFalling() {
		const verticalVelocity = _verticalVelocity.set(0, this.movement.state.velocity.y, 0);

		const falling = verticalVelocity.dot(this.world.physics.gravity) >= 0;

		return falling;
	}

	isCrouching() {
		return this.movement.state.isCrouching === true;
	}

	//velocity is limited by terminal velocity. can be negative
	jump(velocity = this.movement.def.jumpVelocity, force = false, isDesync = false) {
		if (
			force ||
			(this.movement.def.canJump &&
				!this.movement.state.isFlying &&
				this.isFalling() &&
				!this.movement.state.isCrouching &&
				!this.isDead() &&
				this.movement.state.jumpTimer >= this.movement.def.jumpCooldownTime &&
				(this.collision.state.hitFoot ||
					(this.movement.state.coyoteTimer < this.movement.def.coyoteTime && this.isFalling())))
		) {
			this.movement.state.velocity.y = velocity;
			if (isDesync && netState.isHost) {
				InputManagerServer.markDesynced(this.world, this.entityID);
			}

			const jumpSound = this.sounds?.def.jump;

			if (jumpSound) {
				if (netState.isClient && this === this.world.client.camera.target) {
					this.world.sfxManager.play(jumpSound);
				} else {
					this.world.sfxManager.playAtObj({ ...jumpSound, obj: this });
				}
			}

			this.cancelEmote();

			this.movement.state.coyoteTimer = this.movement.def.coyoteTime;
			this.movement.state.jumpTimer = 0;
			this.movement.state.isSliding = false;
			this.collision.state.hitFoot = false;
			this.collision.state.onGround = false;
		}
	}

	getLinearVelocity(out: Vector3) {
		out.copy(this.movement.state.velocity);

		return out;
	}

	setLinearVelocity(velocity: Vector3, isDesync = false) {
		this.movement.state.velocity.copy(velocity);

		if (isDesync && netState.isHost) {
			InputManagerServer.markDesynced(this.world, this.entityID);
		}
	}

	setScale(v: number) {
		if (v === this.size.state.scale) return;

		// update size def
		this.size.updateScale(v);

		// update visuals
		this.scale.setScalar(v);

		// update camera settings
		if (this.cameraSettings) {
			this.cameraSettings.def.distanceScale = v;
		}
	}

	setCanMoveHorizontally(v: boolean) {
		if (!v) this.setSprinting(false);
		this.movement.def.canMoveHorizontally = v;
	}

	setCanPickUpItem(v: boolean) {
		if (!v) this.unequipItem();
		this.canPickUpItem = v;
	}

	setEmote(emote: string | null = null, loop = false) {
		const wantEmote = !isNullish(emote);

		if (this.movement.state.isCrouching && wantEmote) return;

		if (!this.meshLocomotion || this.meshLocomotion.setEmote(emote, loop)) {
			this.locomotionInput.state.isEmoteLooping = wantEmote && loop;
			this.locomotionInput.state.emote = emote;
		}
	}

	cancelEmote() {
		if (this.hasLocalAuthority() && !isNullish(this.locomotionInput.state.emote)) this.setEmote(null);
	}

	getAngleToPosition(x: number, y: number, z: number) {
		const targetPosition = new Vector3(x, y, z);
		const targetPositionAsLocalPosition = this.object3D.worldToLocal(targetPosition);
		// Ignore any height, just "radar" rotation for now
		targetPosition.y = 0;
		const forwardVector = new Vector3(0, 0, -1);
		const angleBetween = forwardVector.angleTo(targetPositionAsLocalPosition);

		return angleBetween;
	}

	/**
	 * @param {} behavior
	 * @param {import('base/world/entity/Entity')} [target]
	 * @param {boolean|string} [attachItem]
	 */
	aiSetBehavior(
		behavior: "idle" | "wander" | "attack" | "look" | "approach" | "follow" | "shootat",
		target: Entity | undefined = undefined,
		attachItem: string | undefined = undefined,
	) {
		this.aiBehavior = behavior;
		this.edgeDetection = false;
		this.aiTargetPosition = null;

		// Attach an item no matter what behaviour - check if weapon already exists in hand
		if (attachItem) {
			let needsToEquipWeapon = true;
			const existingWeapon = this.getEquippedItem();
			const weaponToAttach = attachItem ? attachItem : existingWeapon?.def;
			if (existingWeapon) {
				if (existingWeapon.def === weaponToAttach) {
					needsToEquipWeapon = false;
				}
			}
			if (needsToEquipWeapon && weaponToAttach !== undefined) {
				this.equipItem(weaponToAttach);
			}
		}

		if (behavior === "attack") {
			this.edgeDetection = true;
		}

		switch (behavior) {
			case "wander":
				this.aiWanderOrigin.copy(this.position);
				this.aiWanderTimer = this.aiWanderTime / 2;
				break;
			case "attack":
			case "look":
			case "approach":
			case "shootat":
			case "follow":
				this.aiTarget = target;
				break;
			default:
				this.aiBehavior = "idle";
				break;
		}
	}

	aiSetFireRate(fireRate: number) {
		this.aiFireRateMultiplier = fireRate;
	}

	aiSetShootHitPercentage(v: number) {
		this.aiShootHitPercentage = v;
	}

	aiGetTarget() {
		let target = null;
		let dst = Number.POSITIVE_INFINITY;
		const aimAtPos = new Vector3();

		if (this.aiTarget) {
			if (this.world.entities.includes(this.aiTarget)) {
				target = this.aiTarget;
				dst = this.position.distanceTo(aimAtPos.copy(target.position));
			} else {
				this.aiTarget = null;
				this.aiSetBehavior("idle");
			}
		} else {
			const testPos = _testPos;
			for (const e of this.world.entities) {
				if (e.type.def.isPlayer && !(e as Character).isDead()) {
					const eDst = this.position.distanceTo(testPos.copy(e.position));
					if (eDst < dst) {
						target = e;
						aimAtPos.copy(testPos); // aim at the feet of the player
						dst = eDst;
					}
				}
			}
		}

		return { target, dst, aimAtPos };
	}

	equipItem(def: string) {
		const itemDefExists =
			(def.startsWith("Item") && this.world.defs.has(def)) ||
			(def.startsWith("item#") && this.world.content.state.items.get(def));

		if (!netState.isHost || !itemDefExists || this.isDead()) {
			return;
		}

		const curItem = this.getEquippedItem();
		if (curItem?.def === def) return;
		if (curItem) this.unequipItem();

		const newItem = this.world.router.createEntity({
			def: def,
			x: 0,
			y: 0,
			z: 0,
			qx: 0,
			qy: 0,
			qz: 0,
			qw: 1,
		});
		this.doMount(newItem, true, this.equipment.def.itemIndex);

		if (newItem.weapon && newItem.mount.state.parent?.type.def.isNPC) {
			this.aiSetShootHitPercentage(this.aiShootHitPercentage);
			this.weaponSpread = newItem.weapon.def.spread;
		}
	}

	getEquippedItem(): Item | undefined {
		const equipped = this.mount.def.points[this.equipment.def.itemIndex].entity;
		if (equipped?.type.def.isItem) return equipped as Item;
		return undefined;
	}

	getMeleeDamage() {
		return Math.max(
			this.fighting.def.fistingDamage,
			this.getEquippedItem()?.weapon.def.entityDamage ?? 0,
		);
	}

	unequipItem() {
		this.setIronSights(false);

		if (netState.isHost) {
			const item = this.getEquippedItem();

			if (item?.weapon && this.type.def.isNPC) {
				this.weaponSpread = 0;
			}

			item?.dispose();
		}
	}

	dispose(worldIsDisposed = false) {
		if (this.disposed) return true;

		if (!worldIsDisposed) {
			resetCollisionState(this, true);
		}

		if (super.dispose(worldIsDisposed)) return true;

		this.removePhysics();

		this.fpmesh?.dispose();
	}

	static serDesIds = [
		serDes(
			"walkSpeed",
			(e) => e.movement.def.walkSpeed,
			(e, v) => (e.movement.def.walkSpeed = v),
		),
		serDes(
			"crouchSpeed",
			(e) => e.movement.def.crouchSpeed,
			(e, v) => (e.movement.def.crouchSpeed = v),
		),
		serDes(
			"airSpeed",
			(e) => e.movement.def.airSpeed,
			(e, v) => (e.movement.def.airSpeed = v),
		),
		serDes(
			"flySpeed",
			(e) => e.movement.def.flySpeed,
			(e, v) => (e.movement.def.flySpeed = v),
		),
		serDes(
			"jumpVelocity",
			(e) => e.movement.def.jumpVelocity,
			(e, v) => (e.movement.def.jumpVelocity = v),
		),
		serDes(
			"terminalVelocity",
			(e) => e.movement.def.terminalVelocity,
			(e, v) => (e.movement.def.terminalVelocity = v),
		),

		serDes(
			"sprintSpeedMultiplier",
			(e) => e.movement.def.sprintSpeedMultiplier,
			(e, v) => (e.movement.def.sprintSpeedMultiplier = v),
		),
		serDes(
			"sightsSpeedMultiplier",
			(e) => e.movement.def.sightsSpeedMultiplier,
			(e, v) => (e.movement.def.sightsSpeedMultiplier = v),
		),

		serDes(
			"jumpCooldownTime",
			(e) => e.movement.def.jumpCooldownTime,
			(e, v) => (e.movement.def.jumpCooldownTime = v),
		),
		serDes(
			"coyoteTime",
			(e) => e.movement.def.coyoteTime,
			(e, v) => (e.movement.def.coyoteTime = v),
		),
		serDes(
			"slidingGraceTime",
			(e) => e.movement.def.slidingGraceTime,
			(e, v) => (e.movement.def.slidingGraceTime = v),
		),

		serDes(
			"canSprint",
			(e) => Number(e.movement.def.canSprint),
			(e, v) => (e.movement.def.canSprint = Boolean(v)),
		),
		serDes(
			"canCrouch",
			(e) => Number(e.movement.def.canCrouch),
			(e, v) => (e.movement.def.canCrouch = Boolean(v)),
		),
		serDes(
			"canJump",
			(e) => Number(e.movement.def.canJump),
			(e, v) => (e.movement.def.canJump = Boolean(v)),
		),
		serDes(
			"canMoveHorizontally",
			(e) => Number(e.movement.def.canMoveHorizontally),
			(e, v) => (e.movement.def.canMoveHorizontally = Boolean(v)),
		),
		serDes(
			"hasCrouchBarriers",
			(e) => Number(e.movement.def.hasCrouchBarriers),
			(e, v) => (e.movement.def.hasCrouchBarriers = Boolean(v)),
		),

		serDes(
			"movement mode",
			(e) => e.movement.def.mode,
			(e, v) => (e.movement.def.mode = v),
		),
		serDes(
			"maxHorizontalSpeedMultiplier",
			(e) => e.movement.def.maxHorizontalSpeedMultiplier,
			(e, v) => (e.movement.def.maxHorizontalSpeedMultiplier = v),
		),
		serDes(
			"groundAcceleration",
			(e) => e.movement.def.groundAcceleration,
			(e, v) => (e.movement.def.groundAcceleration = v),
		),
		serDes(
			"groundFriction",
			(e) => e.movement.def.groundFriction,
			(e, v) => (e.movement.def.groundFriction = v),
		),
		serDes(
			"groundFrictionStopMultiplier",
			(e) => e.movement.def.groundFrictionStopMultiplier,
			(e, v) => (e.movement.def.groundFrictionStopMultiplier = v),
		),
		serDes(
			"groundStopSpeed",
			(e) => e.movement.def.groundStopSpeed,
			(e, v) => (e.movement.def.groundStopSpeed = v),
		),
		serDes(
			"airAcceleration",
			(e) => e.movement.def.airAcceleration,
			(e, v) => (e.movement.def.airAcceleration = v),
		),
		serDes(
			"airFriction",
			(e) => e.movement.def.airFriction,
			(e, v) => (e.movement.def.airFriction = v),
		),
		serDes(
			"airFrictionStopMultiplier",
			(e) => e.movement.def.airFrictionStopMultiplier,
			(e, v) => (e.movement.def.airFrictionStopMultiplier = v),
		),

		serDes(
			"fall dmg enabled",
			(e) => Number(e.fallDamage.def.enable),
			(e, v) => (e.fallDamage.def.enable = Boolean(v)),
		),
		serDes(
			"scale",
			(e) => e.size.state.scale,
			(e, v) => e.setScale(v),
		),
		serDes(
			"regen rate",
			(e) => e.regen.def.rate,
			(e, v) => (e.regen.def.rate = v),
		),
		serDes(
			"regen delay",
			(e) => e.regen.def.delay,
			(e, v) => (e.regen.def.delay = v),
		),
		serDes(
			"respawnTime",
			(e) => e.respawnTime,
			(e, v) => (e.respawnTime = v),
		),

		serDes(
			"cantAttack",
			(e) => Array.from(e.disabledInteractions.state.cantAttack).join(","),
			(e, v) => (e.disabledInteractions.state.cantAttack = new Set(v.split(","))),
		),

		serDes(
			"view raycast distance",
			(e) => e.viewRaycast.state.distance,
			(e, v) => (e.viewRaycast.state.distance = v),
		),
		serDes(
			"view raycast mode",
			(e) => e.viewRaycast.state.mode,
			(e, v) => (e.viewRaycast.state.mode = v),
		),

		serDes(
			"selector scupt mode",
			(e) => e.selector.state.sculptMode,
			(e, v) => (e.selector.state.sculptMode = v),
		),
	];

	// these serialization ids are only ran while we're not mounted
	static serDesIds_unmounted = [
		// velocity
		serDes(
			"velocity",
			(e) => serV3(e.movement.state.velocity, 1000),
			(e, v) => desV3(e.movement.state.velocity, v, 1000),
			true,
		),
		serDes(
			"isCrouching",
			(e) => Number(e.movement.state.isCrouching),
			(e, v) => e.setCrouch(Boolean(v)),
		),
		serDes(
			"isFlying",
			(e) => Number(e.movement.state.isFlying),
			(e, v) => e.setFlying(Boolean(v)),
		),
		serDes(
			"isSprinting",
			(e) => Number(e.movement.state.isSprinting),
			(e, v) => e.setSprinting(Boolean(v)),
		),
		// character emote + looping, serialized into string
		// TODO: note on emote state - this can theoretically be offloaded to the input pipeline. state would still need serialization, but
		// it wont require sending it around regularly or frequent checks
		serDes(
			"emote",
			(e) =>
				JSON.stringify([
					e.locomotionInput.state.emote,
					Number(e.locomotionInput.state.isEmoteLooping),
				]),
			(e, v) => {
				const [emote, loop] = JSON.parse(v);
				e.setEmote(emote, !!loop);
			},
		),
	];

	static serDesIds_player = [
		serDes(
			"hud zen mode",
			(e) => e.characterHUD.state.zenMode,
			(e, v) => (e.characterHUD.state.zenMode = v),
		),
	];

	static serDesIds_avatar = serDes(
		"avatar",
		(e) => JSON.stringify(e.avatarObject),
		(e, v) => {
			const avatarObject = JSON.parse(v);
			e.setAvatar(avatarObject);
		},
	);
}
