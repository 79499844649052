import { ScriptType, type JacyContent } from "@jamango/content-client";
import * as BlockTypeRegistry from "base/world/block/BlockTypeRegistry.ts";
import type { World } from "base/world/World.js";
import { execute, interpret } from "./modules/interpreter.ts";
import { createAttachment, gather, parseScripts } from "./modules/parser";
import * as trigger from "./modules/trigger";
import { createLogger } from "@jamango/helpers";
import type { ReteState } from "./Types.ts";
import { SceneNodeType } from "../world/SceneTree.ts";

const logger = createLogger("Rete");

export const init = (
	state: ReteState["state"] | null,
	peerPersistent: ReteState["state"]["peerPersistent"] | null,
): ReteState => {
	// this is the rete related state on our world object
	const reteState = {
		triggerQueue: [],
		contextQueue: [],
		listeners: {},
		state: state ?? {
			nodes: {},
			world: {}, // world state
			entity: {}, // entityID -> state (temporary, reset when game ends / reloads)
			peerPersistent: {}, // accID -> state (persistent, synced by external PersistentDataServer system)
		},
		triggers: {
			onGameStart: [],
			onGameTick: [],
			onChat: {},
			onBlock: {},
			onControl: {},
			onWorldStateChange: {},
			onEntityStateChange: {},
			onPeerPersistentStateChange: {},
			onPeerLeave: [],
			onPeerJoin: [],
			onSoundEnd: [],
			onItemUse: {},
			onItemUnuse: {},
			onItemRangedWeaponFire: {},
		},
		attachments: {},

		// parser related state (non-serializable)
		nodes: new Map(),
		sources: new Map(), // output -> input nodes map
		targets: new Map(), // input -> output node map
		scripts: new Map(),

		// debug information
		compilationLogs: [],

		needsReload: false,
		lastReloadTime: performance.now(),
	};

	if (peerPersistent) reteState.state.peerPersistent = peerPersistent;

	return reteState;
};

export const loadMap = (content: JacyContent, world: World, isReload: boolean) => {
	// if we are reloading, we wipe the old rete object
	world.rete = init(isReload ? world.rete.state : null, world.rete.state.peerPersistent);

	// the parser holds some temporary state used for parsing exclusively
	gather(world.rete, content.state.scripts.getAll());

	// we parse the script graphs and generate entry points, stored on each script
	parseScripts(world.rete);

	const attach = (
		scripts: {
			script: string;
			data?: Record<string, any> | undefined;
		}[],
		target: string,
		type: string,
	) => {
		for (const { script, data } of scripts)
			try {
				const scriptData = world.rete.scripts.get(script);
				if (scriptData === undefined) throw "Script not found";
				createAttachment(world.rete, scriptData, target, data, type, {}, 0);
			} catch (e: any) {
				logger.error("Error while creating attachment", type, target, script);
				logger.error(e);

				world.rete.compilationLogs.push({
					type: "error",
					message: e.toString(),
					script,
				});
			}
	};

	// world scripts "attachment"
	const worldScripts = content.state.scripts.getAll().filter((s) => s.scriptType === ScriptType.WORLD);
	if (worldScripts.length > 0) {
		attach(
			worldScripts.map((s) => ({ script: s.pk })),
			"world_id",
			"world",
		);
	}

	// block type script attachments
	for (const parsedBlock of world.content.state.blocks.getAll()) {
		if (parsedBlock.scripts === undefined || parsedBlock.scripts.length === 0) continue;
		const blockPK = parsedBlock.pk;
		attach(parsedBlock.scripts, blockPK, "block");
	}

	// block group script attachments
	for (const [name, group] of world.blockGroups.groups) {
		if (group.scripts !== undefined) attach(group.scripts, name, "group");
	}

	// prop and character scene tree script attachments
	for (const node of Object.values(world.sceneTree.nodes)) {
		if (node.type === SceneNodeType.PROP) {
			const prop = world.content.state.props.get(node.propPk);
			if (!prop) {
				logger.warn(`Prop [${node.propPk}] not found, skipping script attachment.`);
				continue;
			}

			const assetScripts = prop.scripts;
			const nodeScripts = node.scripts;

			const scripts: Array<{ script: string; data?: Record<string, any> }> = [];

			scripts.push(...assetScripts);
			scripts.push(...nodeScripts);

			attach(scripts, node.id, "propSceneTreeNode");
		} else if (node.type === SceneNodeType.CHARACTER) {
			const character = world.content.state.characters.get(node.characterPk);
			if (!character) {
				logger.warn(`Character [${node.characterPk}] not found, skipping script attachment.`);
				continue;
			}

			const assetScripts = character.scripts;
			const nodeScripts = node.scripts;

			const scripts: Array<{ script: string; data?: Record<string, any> }> = [];

			scripts.push(...assetScripts);
			scripts.push(...nodeScripts);

			attach(scripts, node.id, "characterSceneTreeNode");
		}
	}

	// after parsing, update the block type registry before spawning blocks in chunks as part of onMapLoad
	BlockTypeRegistry.updateBlockTypeScripts(world.blockTypeRegistry, world);

	// triggers all events that should be initiated on map loading/parse - primarily "spawn" for now
	trigger.onMapLoad(world);
};

export const update = (content: JacyContent, world: World, deltaTime: number, time: number) => {
	if (world.rete.needsReload) {
		// replaces the rete state object, resetting needsReload to false
		loadMap(content, world, true);
	}

	if (world.editor.paused) return;

	// on game tick trigger
	trigger.onGameTick(world, deltaTime);

	// iterate queued triggers. any triggers caused by the game world will be handled in proper order here
	for (let i = 0; i < world.rete.triggerQueue.length; ++i) {
		const trigger = world.rete.triggerQueue[i];
		world.rete.contextQueue.push(interpret(trigger.info, trigger.entryPoint, world));
	}

	// once done, we empty the queue
	world.rete.triggerQueue.length = 0;

	// we now process the contexts - keep processing until return false, then pop
	for (let i = 0; i < world.rete.contextQueue.length; i++) {
		const context = world.rete.contextQueue[i];
		let suspend = false;
		try {
			suspend = execute(context.closure, context, time, performance.now());
		} catch (e) {
			logger.error(e);
		}
		// if the context isnt suspending (e.g. timed out) we remove from queue
		if (!suspend) world.rete.contextQueue.splice(i--, 1);
	}
};
