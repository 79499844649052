import { z } from "zod";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export enum PropMeshType {
	NONE = 0,
	BLOCK_STRUCTURE = 1,
}

export enum PropColliderType {
	NONE = 0,
	BLOCK_STRUCTURE = 1,
}

export enum BlockStructureColliderType {
	ACCURATE = 0,
	CONVEX_HULL = 1,
}

export enum PropMotionType {
	STATIC = 0,
	DYNAMIC = 1,
	KINEMATIC = 2,
}

export const PropScriptAttachmentSchema = z.object({
	script: assetKeySchema(AssetType.SCRIPT),
	data: z.record(z.any()).optional(),
});

export const PropSchema = z.object({
	pk: assetKeySchema(AssetType.PROP),
	type: z.literal(AssetType.PROP),
	name: z.string(),
	scripts: z.array(PropScriptAttachmentSchema),
	mesh: z.discriminatedUnion("type", [
		z.object({
			type: z.literal(PropMeshType.NONE),
		}),
		z.object({ type: z.literal(PropMeshType.BLOCK_STRUCTURE), blockStructurePk: z.string() }),
	]),
	physics: z.object({
		collider: z.discriminatedUnion("type", [
			z.object({
				type: z.literal(PropColliderType.NONE),
			}),
			z.object({
				type: z.literal(PropColliderType.BLOCK_STRUCTURE),
				blockStructurePk: z.string(),
				blockStructureColliderType: z.nativeEnum(BlockStructureColliderType),
			}),
		]),
		mass: z.number(),
		friction: z.number(),
		restitution: z.number(),
		motionType: z.nativeEnum(PropMotionType),
	}),
	scale: z.number(),
	packageId: PackageIdSchema.optional(),
});
