export type IPromise<Value> = Promise<Value> & {
	resolve: (value: Value) => void;
	reject: (reason: unknown) => void;
};

type IPartialPromise<Value, Error> = Promise<Value> & {
	resolve?: (value: Value) => void;
	reject?: (reason: Error) => void;
};

export function createRequestPromise<TValue>(): IPromise<TValue> {
	let res, rej;
	const promise: IPartialPromise<TValue, Error> = new Promise<TValue>((resolve, reject) => {
		res = resolve;
		rej = reject;
	});
	promise.resolve = res;
	promise.reject = rej;
	return promise as IPromise<TValue>;
}
