import { NODE_TYPE_ID } from "base/rete/Constants";
import { isNullish } from "@jamango/helpers";
import type { NodeDef } from "../Types";

export const LOGIC: NodeDef[] = [
	// Boolean
	{
		id: "0003-01-0001",
		name: "And",
		type: NODE_TYPE_ID.logic.boolean,
		description:
			"Outputs true when all inputs are true. If any of the inputs are false, it outputs false",
		predictable: true,
		inputs: {
			input1: { name: "Input", type: "boolean", control: "boolean" },
			input2: { name: "Input", type: "boolean", control: "boolean" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			return { result: inputs.input1 && inputs.input2 };
		},
	},
	{
		id: "0003-01-0003",
		name: "Or",
		type: NODE_TYPE_ID.logic.boolean,
		description: "Outputs true if any of the inputs are true. If all inputs are false, it outputs false",
		predictable: true,
		inputs: {
			input1: { name: "Input", type: "boolean", control: "boolean" },
			input2: { name: "Input", type: "boolean", control: "boolean" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			return { result: inputs.input1 || inputs.input2 };
		},
	},
	{
		id: "0003-01-0005",
		name: "Not",
		type: NODE_TYPE_ID.logic.boolean,
		description:
			"Outputs the opposite of the input boolean. If input is true, output is false and vice versa",
		predictable: true,
		inputs: {
			input: { name: "Input", type: "boolean", control: "boolean" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			return { result: !inputs.input };
		},
	},

	// Comparison
	{
		id: "3c65846d-3e77-461a-b8c2-2604753e90f5",
		name: "Equal",
		type: NODE_TYPE_ID.logic.comparison,
		description: "Checks if the two inputs are equal.",
		keywords: ["equals", "same"],
		predictable: true,
		inputs: {
			val1: { name: "Input", type: "any" },
			val2: { name: "Input", type: "any" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			if (inputs.val1?.isVector3 && inputs.val2?.isVector3) {
				return { result: inputs.val1.equals(inputs.val2) };
			}

			if (inputs.val1?.isQuaternion && inputs.val2?.isQuaternion) {
				return { result: inputs.val1.equals(inputs.val2) };
			}

			return { result: inputs.val1 === inputs.val2 };
		},
	},
	{
		id: "d9ae178c-a084-4e69-b567-e9f1378c2a93",
		name: "Not Equal",
		type: NODE_TYPE_ID.logic.comparison,
		description: "Checks if the two inputs are not equal.",
		keywords: ["not", "equals", "different"],
		predictable: true,
		inputs: {
			val1: { name: "Input", type: "any" },
			val2: { name: "Input", type: "any" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			if (inputs.val1?.isVector3 && inputs.val2?.isVector3) {
				return { result: !inputs.val1.equals(inputs.val2) };
			}

			if (inputs.val1?.isQuaternion && inputs.val2?.isQuaternion) {
				return { result: !inputs.val1.equals(inputs.val2) };
			}

			return { result: inputs.val1 !== inputs.val2 };
		},
	},
	{
		id: "0003-02-0001",
		name: "Compare",
		type: NODE_TYPE_ID.logic.comparison,
		description: "Compares the two input values.",
		keywords: ["equals", "greater", "more", "less", "comparison", "not"],
		predictable: true,
		inputs: {
			val1: { name: "Input", type: "any" },
			op: {
				name: "Operation",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						{ value: "==", label: "Equals (=)" },
						{ value: "!=", label: "Doesn't Equal (!=)" },
						{ value: "<", label: "Is Less Than (<)" },
						{ value: ">", label: "Is Greater Than (>)" },
						{ value: "<=", label: "Is Less Than Or Equal To (≤)" },
						{ value: ">=", label: "Is Greater Than Or Equal To (≥)" },
					],
				},
			},
			val2: { name: "Input", type: "any" },
		},
		outputs: {
			result: { name: "Result", type: "boolean" },
		},
		resolve(inputs) {
			if (inputs.val1?.isVector3 && inputs.val2?.isVector3) {
				if (inputs.op === "==") return { result: inputs.val1.equals(inputs.val2) };
				if (inputs.op === "!=") return { result: !inputs.val1.equals(inputs.val2) };
			}

			if (inputs.val1?.isQuaternion && inputs.val2?.isQuaternion) {
				if (inputs.op === "==") return { result: inputs.val1.equals(inputs.val2) };
				if (inputs.op === "!=") return { result: !inputs.val1.equals(inputs.val2) };
			}

			const val1 = inputs.val1;
			const val2 = inputs.val2;

			switch (inputs.op) {
				default:
				case "==":
					return { result: val1 === val2 };
				case "!=":
					return { result: val1 !== val2 };
				case "<":
					return { result: val1 < val2 };
				case ">":
					return { result: val1 > val2 };
				case "<=":
					return { result: val1 <= val2 };
				case ">=":
					return { result: val1 >= val2 };
			}
		},
	},

	// Control Flow
	{
		id: "0003-03-0001",
		name: "If",
		type: NODE_TYPE_ID.logic.controlFlow,
		description:
			'If the input condition is true, it will execute the "Then" output. If it is false, it will execute the "Else" output',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			condition: { name: "Condition", type: "boolean" },
		},
		outputs: {
			exec: { type: "exec" },
			then: { name: "Then", type: "exec" },
			else: { name: "Else", type: "exec" },
		},
		controlFlow(type, scope, inputs) {
			return Boolean(inputs.condition) === (type === "then");
		},
	},
	{
		id: "0003-03-0008",
		name: "If Value Exists",
		type: NODE_TYPE_ID.logic.controlFlow,
		description:
			'If the value exists, it will execute the "Then" output. If it\'s missing (undefined or null), it will execute the "Else" output',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			value: { name: "Value", type: "any" },
		},
		outputs: {
			exec: { type: "exec" },
			then: { name: "Then", type: "exec" },
			else: { name: "Else", type: "exec" },
		},
		controlFlow(type, scope, inputs) {
			return !isNullish(inputs.value) === (type === "then");
		},
	},
	{
		id: "0003-03-0002",
		name: "While Loop",
		type: NODE_TYPE_ID.logic.controlFlow,
		description:
			'Repeatedly execute the "During loop" output as long as the condition is true. The condition is checked BEFORE each iteration of the loop.',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			condition: { name: "Condition", type: "boolean" },
		},
		outputs: {
			exec: { type: "exec" },
			during: { name: "During loop", type: "exec" },
		},
		shouldLoop(inputs) {
			return inputs.condition === true;
		},
		controlFlow(id, scope, inputs) {
			return inputs.condition === true;
		},
	},

	{
		id: "0003-03-0003",
		name: "Do While Loop",
		type: NODE_TYPE_ID.logic.controlFlow,
		description:
			'Repeatedly execute the "During loop" output as long as the condition is true. The condition is checked AFTER each iteration of the loop.',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			condition: { name: "Condition", type: "boolean" },
		},
		outputs: {
			exec: { type: "exec" },
			during: { name: "During loop", type: "exec" },
		},
		execute(inputs, ctx, nodeId, scope) {
			// evaluates whether the input condition is true, and stores current as well as last
			const lastEval = scope[nodeId] !== undefined ? scope[nodeId] : true;
			scope[nodeId] = inputs.condition === true;
			scope[`${nodeId}-last`] = lastEval;
		},
		shouldLoop(inputs, scope, nodeId) {
			// whether to enter the next loop - look at current evaluation of condition
			return scope[nodeId];
		},
		controlFlow(type, scope, inputs, ctx, nodeId) {
			// enter the inner exec if the last eval was true
			return scope[`${nodeId}-last`];
		},
	},
	{
		id: "0003-03-0004",
		name: "Counting Loop",
		type: NODE_TYPE_ID.logic.controlFlow,
		description:
			'Repeatedly execute the "During loop" output with a different "Current value" on each iteration. Similar to a classic "for" loop.',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			from: {
				name: "From (inclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			to: {
				name: "To (exclusive)",
				type: "number",
				control: "number",
				config: { defaultValue: 10 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			during: { name: "During loop", type: "exec" },
			current: { name: "Current value", type: "number" },
		},
		execute(inputs, ctx, nodeId, scope) {
			scope[nodeId] = (scope[nodeId] ?? -1) + 1;
		},
		shouldLoop(inputs, scope, nodeId) {
			return scope[nodeId] < Math.abs(inputs.to - inputs.from);
		},
		controlFlow(type, scope, inputs, ctx, nodeId) {
			// calculate how many iterations we will do in total. from is inclusive, to is exclusive
			// positive: from 3 to 6 = 3,4,5 ( 3 iterations ) = 6 - 3
			// negative: from 6 to 3 = 6,5,4 ( 3 iterations ) = 3 - 6 = Math.abs(-3)
			const iters = Math.abs(inputs.to - inputs.from);
			return scope[nodeId] < iters;
		},
		resolve(inputs, ctx, nodeId, scope) {
			// iteration is always positive, account for signage
			const sign = Math.sign(inputs.to - inputs.from);
			return { current: inputs.from + scope[nodeId] * sign };
		},
	},
	{
		id: "0003-03-0005",
		name: "Continue Jump Statement",
		type: NODE_TYPE_ID.function.general,
		description: "Jumps back to the start of the loop and continues into the next iteration",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
		},
		jump: "continue",
	},
	{
		id: "0003-03-0006",
		name: "Break Jump Statement",
		type: NODE_TYPE_ID.function.general,
		description: 'Breaks out of the loop and triggers the "After loop" script',
		predictable: true,
		inputs: {
			exec: { type: "exec" },
		},
		jump: "break",
	},

	{
		id: "0003-03-0007",
		name: "List Loop",
		type: NODE_TYPE_ID.logic.controlFlow,
		description: "Loop through each items in a given list.",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			exec: { type: "exec" },
			during: { name: "During loop", type: "exec" },
			index: { name: "Index", type: "number" },
			item: { name: "Item", type: "any" },
		},
		execute(inputs, ctx, nodeId, scope) {
			scope[nodeId] = (scope[nodeId] ?? -1) + 1;
		},
		shouldLoop(inputs, scope, nodeId) {
			return scope[nodeId] < inputs.list.length;
		},
		controlFlow(type, scope, inputs, ctx, nodeId) {
			return scope[nodeId] < inputs.list.length;
		},
		resolve(inputs, ctx, nodeId, scope) {
			const i = scope[nodeId];
			return { index: i, item: inputs.list[i] };
		},
	},

	// Others
	{
		id: "0003-04-0001",
		name: "Ternary",
		type: NODE_TYPE_ID.logic.boolean,
		description:
			"Outputs either the truthy or falsy experession based on the given condition. An alternative to the if node.",
		predictable: true,
		inputs: {
			condition: { name: "Condition", type: "boolean" },
			truthy: { name: "Truthy", type: "any", structure: "any" },
			falsy: { name: "Falsy", type: "any", structure: "any" },
		},
		outputs: {
			result: { name: "Result", type: "any", structure: "any" },
		},
		resolve(inputs) {
			return { result: inputs.condition ? inputs.truthy : inputs.falsy };
		},
	},
];
