/*
    Simple memory manager to realocations in batch rendenring:
    TODO: Tracking memory fragmentation
*/

/*
    Pointer to GPU memory block.
    Offset: offset in bytes in GPU memory block
    Size: Size in bytes to block of memory in pointer
*/

const INVALID_POINTER = -1;

class FreeListPointer {
	constructor(offset, size) {
		this.offset = offset;
		this.size = size;
	}

	isValid() {
		return this.offset != INVALID_POINTER;
	}
}

class FreeListNode {
	constructor(offset, size, next) {
		this.offset = offset;
		this.size = size;
		this.next = next;
	}
}

class FreeListNodelState {
	static size;
	static maxEntries;

	static head;
}

export const KILOBYTE = 1024;
export const MEGABYTE = KILOBYTE * KILOBYTE;

export class FreeList {
	constructor(size) {
		/* Memeory page size */
		// TODO: alocar toda a pagina e memoria de uma unica vez para facilitar o realloc
		const _64kb = 64 * 1024;
		const maxEntries = Math.floor(size / _64kb);

		this.state = new FreeListNodelState();
		this.state.size = size;
		this.state.maxEntries = maxEntries;
		this.state.head = new FreeListNode(0, size, null);

		this.pointers = [];
	}

	allocBlock(size) {
		if (size == 0) {
			console.log("Invalid allocation ...");
			return new FreeListPointer(INVALID_POINTER, 0);
		}

		let node = this.state.head;
		let previous = null;

		while (node != null) {
			if (node.size == size) {
				var offset = node.offset;

				if (previous != null) {
					previous.next = node.next;
				} else {
					this.state.head = node.next;
				}

				return new FreeListPointer(offset, size);
			} else if (node.size > size) {
				var offset = node.offset;
				node.size -= size;
				node.offset += size;

				return new FreeListPointer(offset, size);
			}

			previous = node;
			node = node.next;
		}

		return new FreeListPointer(INVALID_POINTER, 0);
	}

	freeBlock(pointer) {
		if (pointer.size == 0 || pointer.offset == INVALID_POINTER) {
			return false;
		}

		const state = this.state;
		let node = state.head;
		let previous = null;

		if (node == null) {
			var newNode = new FreeListNode();
			newNode.offset = pointer.offset;
			newNode.size = pointer.size;
			newNode.next = null;
			state.head = newNode;

			return true;
		}

		while (node) {
			if (node.offset == pointer.offset) {
				console.log("Block is free ...");
				return false;
			} else if (node.offset > pointer.offset) {
				var newNode = new FreeListNode();

				if (newNode == null) {
					console.log("Freelist node not found ...");
					return false;
				}

				newNode.offset = pointer.offset;
				newNode.size = pointer.size;

				if (previous) {
					previous.next = newNode;
					newNode.next = node;
				} else {
					newNode.next = node;
					state.head = newNode;
				}

				if (newNode.next && newNode.offset + newNode.size == node.offset) {
					newNode.size += node.size;
					var next = node;
					newNode.next = next.next;
				}

				if (previous && previous.offset + previous.size == newNode.offset) {
					previous.size += newNode.size;
					var next = newNode;
					previous.next = newNode.next;
				}

				return true;
			}

			previous = node;
			node = node.next;
		}

		/* Maior que o offset do ultimo no */
		if (previous && pointer.offset > previous.offset) {
			var newNode = new FreeListNode();

			newNode.offset = pointer.offset;
			newNode.size = pointer.size;

			previous.next = newNode;
			newNode.next = null;

			if (previous && previous.offset + previous.size == newNode.offset) {
				previous.size += newNode.size;
				var next = newNode;
				previous.next = newNode.next;
			}

			return true;
		}

		console.log("Unable to find block to be freed. Corruption possible?");
		return false;
	}

	reallocBlock(pointer) {
		this.free(pointer);
		return this.alloc(pointer.size);
	}

	alloc(size) {
		const ptr = this.allocBlock(size); // Track allocations in debug mode only
		this.pointers.push(ptr);
		return ptr;
	}

	free(pointer) {
		return this.freeBlock(pointer);
	}

	realloc(pointer) {
		return this.reallocBlock(pointer);
	}

	freeSpace() {
		let total = 0;
		const state = this.state;
		let node = state.head;

		while (node != null) {
			total += node.size;
			node = node.next;
		}

		return total;
	}

	dump() {
		let node = this.state.head;
		console.log("Free space");
		while (node != null) {
			console.log("Offset: " + node.offset + "\nSize: " + node.size);
			node = node.next;
		}

		// for(let i = 0; i < this.pointers.length; i++) {
		//     console.log("Offset: " + this.pointers[i].offset + "\nSize: " + this.pointers[i].size);
		// }
	}
}
