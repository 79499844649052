import { netState } from "router/Parallelogram";

import { BB } from "base/BB";
import { checkOcclusionQuery } from "client/OcclusionCulling.js";

export function skipUpdateStatusUpdate(entity) {
	if (!canUpdateSystem(entity)) return;

	if (entity.type.def.isNPC && BB.client.settings.occlusionCulling === 1) {
		if (entity.occlusionMesh.isInFrustum) {
			if (entity.occlusionMesh.occlusionQuery) {
				checkOcclusionQuery(entity.occlusionMesh, BB.client.renderer.getContext());
			}
		} else {
			entity.canSkipUpdate = true;
			entity.world.scene.remove(entity);
			entity.isRemoved = true;
		}
	} else {
		if (entity.isRemoved) {
			entity.world.scene.add(entity);
			entity.isRemoved = false;
		}

		entity.canSkipUpdate = false;
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isCharacter;
}
