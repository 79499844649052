import { Vector3, MathUtils } from "three";
import { BB } from "base/BB";
import { UI } from "client/dom/UI";
import { BLOCK_AIR } from "base/world/block/Util";
import { getYawFromQuaternion } from "base/util/math/Math";

const tmpVec = new Vector3();

export class HudClient {
	constructor() {}

	init() {
		/*ChatRouter.registerClientCommand(
			(cmd) => cmd[0] === "/get",
			(cmd) => {
				switch (cmd[1]) {
					case "position":
						const position = this.getPosition();
						const message = `Current Position: ${position.x}X, ${position.y}Y, ${position.z}Z\nFacing Angle: ${position.angle}`;
						ChatRouter.displayChat({ message });
						break;
				}
			},
		);*/
	}

	getPosition() {
		const player = BB.world?.client.camera?.target;
		if (!player) return;

		const position = tmpVec.copy(player.position);
		position.x = position.x.toFixed(2);
		position.y = position.y.toFixed(2);
		position.z = position.z.toFixed(2);

		const playerYaw = getYawFromQuaternion(player.quaternion);
		const radians = playerYaw > 0 ? playerYaw : 2 * Math.PI + playerYaw;
		const angle = MathUtils.radToDeg(radians).toFixed(2);

		return {
			x: position.x,
			y: position.y,
			z: position.z,
			angle,
		};
	}

	getFootBlock() {
		const player = BB.world?.client.camera?.target;
		if (!player || !player.type.def.isPlayer) return null;

		return player.collision.state.footBlock.found ? player.collision.state.footBlock.pos : null;
	}

	update() {
		const world = BB.world;
		if (!world) return;

		const player = world.client.camera.target;
		if (!player) return;

		if (UI.state.gameHud().zenMode !== player.characterHUD.state.zenMode) {
			UI.state.gameHud().setZenMode(player.characterHUD.state.zenMode);
		}

		if (!UI.state.gameHud().showPlayerContextualInfo) return;

		const viewRaycast = player.viewRaycast?.state;
		if (!viewRaycast) return;

		const updatedState = {};

		const position = this.getPosition();
		const footBlock = this.getFootBlock();
		const playerPosition = UI.state.gameHud().playerPosition;
		const playerFootBlock = UI.state.gameHud().playerFootBlock;
		const playerChunk = UI.state.gameHud().playerChunk;
		const hitBlock = UI.state.gameHud().hitBlock || {};

		if (
			playerPosition.x !== position.x ||
			playerPosition.y !== position.y ||
			playerPosition.z !== position.z ||
			playerPosition.angle !== position.angle
		)
			updatedState.playerPosition = {
				x: position.x,
				y: position.y,
				z: position.z,
				angle: position.angle,
			};

		if (!footBlock) {
			if (playerFootBlock) updatedState.playerFootBlock = undefined;
		} else if (
			!playerFootBlock ||
			playerFootBlock.x !== footBlock.x ||
			playerFootBlock.y !== footBlock.y ||
			playerFootBlock.z !== footBlock.z
		) {
			updatedState.playerFootBlock = {
				x: footBlock.x,
				y: footBlock.y,
				z: footBlock.z,
			};
		}

		const chunk = world.scene.getChunkAt(player.position.x, player.position.y, player.position.z);

		if (chunk) {
			const chunkPos = chunk.position;
			if (
				!playerChunk ||
				playerChunk.x !== chunkPos.x ||
				playerChunk.y !== chunkPos.y ||
				playerChunk.z !== chunkPos.z
			) {
				updatedState.playerChunk = { x: chunkPos.x, y: chunkPos.y, z: chunkPos.z };
			}
		}

		if (
			viewRaycast.block &&
			(viewRaycast.block.x !== hitBlock.x ||
				viewRaycast.block.y !== hitBlock.y ||
				viewRaycast.block.z !== hitBlock.z)
		) {
			updatedState.hitBlock = {
				x: viewRaycast.block.x,
				y: viewRaycast.block.y,
				z: viewRaycast.block.z,
			};
		}

		if (viewRaycast.block) {
			const shape = world.scene.getShape(viewRaycast.block);

			if (shape !== BLOCK_AIR) {
				const type = world.scene.getBlockType(viewRaycast.block);

				updatedState.hitBlockTypeName = type.name;
				updatedState.hitBlockTypeDisplay = type.display;
				updatedState.hitBlockShape = shape;
			} else {
				updatedState.hitBlockTypeName = undefined;
				updatedState.hitBlockTypeDisplay = undefined;
				updatedState.hitBlockShape = undefined;
			}
		} else {
			updatedState.hitBlockTypeName = undefined;
			updatedState.hitBlockTypeDisplay = undefined;
			updatedState.hitBlockShape = undefined;
		}

		if (Object.keys(updatedState).length) UI.state.gameHud().setGameHud(updatedState);
	}

	dispose() {}
}
