import { BB } from "base/BB";
import { World } from "base/world/World";
import { netState } from "router/Parallelogram";
import { WorldEditorStatus } from "base/world/WorldEditor";
import { EDIT_WORLD_MODE, PLAY_WORLD_MODE } from "@jamango/content-client";
import { createLogger } from "@jamango/helpers";
import * as Net from "router/Net";

const logger = createLogger("AutoSave");

const DEFAULT_AUTO_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

export const init = () => {
	return {
		nextAutoSaveTime: performance.now() + DEFAULT_AUTO_SAVE_INTERVAL,
		autoSaveInProgress: false,
		autoSavePromise: undefined as Promise<void> | undefined,
	};
};

type AutoSaveState = ReturnType<typeof init>;

export const update = (state: AutoSaveState, world: World) => {
	// only hosts with a saving impl should proceed
	if (!netState.isHost || !BB.contentImpl) return;

	const shouldSave =
		// auto save is not in progress already
		!state.autoSaveInProgress &&
		// reached next auto save time
		state.nextAutoSaveTime < performance.now() &&
		// dedi / frontend specified "canAutoSave" conditions - e.g. is the frontend loading
		BB.contentImpl.canAutoSave() &&
		// prevent auto saving when we are a client, the page is not visible, and there are no peers connected
		// TODO: better "is active" check to prevent idle tab autosaving
		((netState.isClient && document.visibilityState === "visible") || Net.getPeers().length > 0) &&
		// are we in a world editor state where we can save?
		((world.content.state.worldData.mode === PLAY_WORLD_MODE &&
			world.editor.status !== WorldEditorStatus.PREVIEWING) ||
			(world.content.state.worldData.mode === EDIT_WORLD_MODE &&
				world.editor.status === WorldEditorStatus.EDITING));

	if (!shouldSave) return;

	state.autoSaveInProgress = true;

	BB.contentImpl
		.autoSave()
		.then(() => {
			logger.info("Auto-save completed successfully");
		})
		.catch((error) => {
			logger.error("Auto-save failed", error);
		})
		.finally(() => {
			state.autoSaveInProgress = false;
			state.nextAutoSaveTime = performance.now() + DEFAULT_AUTO_SAVE_INTERVAL;
		});
};
