import * as ChatBase from "base/dom/Chat";
import * as ChatServer from "server/dom/Chat";
import * as ChatClient from "client/dom/Chat";

import { getPeerMetadata } from "base/util/PeerMetadata";
import { netState } from "router/Parallelogram";
import type { World } from "base/world/World";
import type { UnstampedChatMessage } from "@jamango/frontend/Chat.js";
import type { Peer } from "@jamango/ibs";
import * as Net from "router/Net";

export function sendChatToServer(world: World, message: string) {
	const peer = world.client!.loopbackPeer;

	if (netState.isHost) {
		ChatServer.receiveChat(world, peer as Peer, message);
	} else {
		Net.send("chat_sendmsg", message);

		//hack: server.receiveChat already calls this, so only need to handle it in this branch
		//the goal is for joined clients to see their chat message display immediately without waiting for the server
		//the server will not relay the chat back to this client (using ibs except arg)
		displayChat({
			username: getPeerMetadata(peer).username,
			message,
		});
	}
}

export function displayChat(chat: UnstampedChatMessage) {
	const stampedChat = { ...chat, time: chat.time ?? Date.now() };

	ChatBase.displayChat(stampedChat);
	if (netState.isClient && !chat.message.startsWith("/")) ChatClient.displayChat(stampedChat);
}

export function initCommandListeners() {
	if (netState.isHost) {
		ChatServer.initCommandListeners();
	} else {
		ChatClient.initCommandListeners();
	}
}
