import { ws } from "rest-client";
import { NET_TIMEOUT, NET_SERVER, NET_TURN_OFF, NET_TURN_ONLY } from "../share/Const";
import { createCommandSocket, type ISocketExtensions } from "../share/Socket";
import type { IBSBase } from "./Base";

export class Peer {
	rtc: RTCPeerConnection;
	peerID: string;

	channels: Record<string, RTCDataChannel & ISocketExtensions> = {};
	metadata = new Map();
	timeout: Timer;

	constructor(ibs: IBSBase, peerID: string) {
		this.rtc = new RTCPeerConnection({ iceServers: ibs.o.iceServers });
		const rtc = this.rtc;
		this.peerID = ibs.mode === NET_SERVER ? peerID : "server";
		this.timeout = setTimeout(() => rtc.close(), NET_TIMEOUT); //peer sometimes does not time out on its own due to large emmet

		const template = ibs.listenerTemplate;
		let id = 0;

		for (const i in ibs.channels) {
			const channel = createCommandSocket(
				rtc.createDataChannel(i, { negotiated: true, id: id++, ...ibs.channels[i] }),
				ibs.socketSettings,
			);
			channel.peer = this;
			this.channels[i] = channel;

			for (const f in template)
				if (f !== "close" && f !== "open")
					for (const listener of template[f]) channel.setCommandListener(f, listener);
		}

		const std = this.channels.standard;

		std.onopen = () => {
			if (template.open) {
				for (const f of template.open) {
					try {
						f.call(std);
					} catch (oops) {
						console.error(oops);
					}
				}
			}
		};

		std.onclose = () => {
			std.onclose = null;

			ibs.peers.delete(this.peerID);
			rtc.close();

			if (template.close) {
				for (const f of template.close) {
					try {
						f.call(std);
					} catch (oops) {
						console.error(oops);
					}
				}
			}
		};

		//if an individual channel closes, the whole peer should close
		for (const i in this.channels) {
			const channel = this.channels[i];
			if (channel !== std) {
				channel.onclose = (ev) => std.onclose?.(ev);
			}
		}

		rtc.onicecandidate = (e) => {
			const ice = e.candidate;

			if (!ice) return;
			if (ibs.turnMode === NET_TURN_OFF && ice.candidate.includes("typ relay")) return;
			if (ibs.turnMode === NET_TURN_ONLY && !ice.candidate.includes("typ relay")) return;

			ws.client.call("ibs:ice", { peerId: peerID, rtc: ice, accessToken: ibs.accessToken! });
		};

		rtc.onconnectionstatechange = (ev) => {
			clearTimeout(this.timeout);
			switch (rtc.connectionState) {
				case "disconnected":
					this.timeout = setTimeout(() => rtc.close(), NET_TIMEOUT);
					break;
				case "failed":
				case "closed":
					std.onclose?.(ev);
					break;
			}
		};

		//close the peer synchronously to avoid emmet
		rtc.close = () => {
			const before = rtc.connectionState;
			RTCPeerConnection.prototype.close.call(rtc);
			//@ts-ignore
			if (rtc.connectionState !== before) rtc.onconnectionstatechange?.();

			for (const i in this.channels) this.channels[i].close();
		};

		ibs.peers.set(this.peerID, this);
	}

	async sendCommand(f: number | string, a: any, channelName = "standard") {
		const channel = this.channels[channelName];
		if (channel.readyState === "connecting")
			await new Promise((resolve) => channel.addEventListener("open", resolve));

		channel.sendCommand(f, a);
	}

	close() {
		this.rtc.close();
	}
}
