export const CHAT_MSG_LIMIT = 200;

export type UnstampedChatMessage = {
	time?: number;
	username?: string;
	message: string;
	color?: string;
};

export type ChatMessage = UnstampedChatMessage & {
	time: number;
};

export function formatTime(time: number) {
	return new Date(time).toLocaleString("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});
}
