import { defs, defaultInputs } from "base/rete/Nodes";
import { validateInput as validateAndTransform } from "base/rete/modules/validate";
import { NODE } from "base/rete/InternalNameMap";
import { netState } from "router/Parallelogram";
import type {
	ParsedNode,
	ParsedNodeInput,
	ParsedNodeExec,
	InterpreterClosure,
	InterpreterContext,
	NodeDef,
	EntryPoint,
	InterpretInfo,
	InterpreterExecInput,
	InterpreterScope,
	ListenerSetup,
	ReteState,
} from "base/rete/Types";
import type { World } from "base/world/World";

const resolve = (
	target: ParsedNode,
	inputs: ParsedNodeInput[],
	context: InterpreterContext,
	closure: InterpreterClosure,
): InterpreterExecInput => {
	const targetDef = defs.get(target.defId);
	if (targetDef !== undefined && !targetDef.predictable) {
		closure.predictable = false;
		if (!netState.isHost) return {};
	}
	if (target.defId === NODE.Start) {
		// if we are a start node, read the scopes scriptinput values instead
		return closure.scope.scriptInputs;
	} else if (target.defId === NODE.Controls) {
		// if we are a control node, we read the entry points data
		return structuredClone(closure.scope.controls);
	}

	// accumulate inputs of a inputs into an object
	// we merge this with the default data of the node
	// TODO: megamorphic - an array with ids may be more performant
	const result: InterpreterExecInput = {
		...structuredClone(defaultInputs.get(target.defId)),
		...structuredClone(target.data),
	};

	for (let i = 0; i < inputs.length; ++i) {
		const { node, prev, sourceOutput, targetInput } = inputs[i];

		let output;
		if (node.custom) {
			// use scope based input if custom node
			output = closure.scope[node.id];
		} else {
			const def = defs.get(node.defId);
			if (def === undefined || def.resolve === undefined)
				throw "trying to resolve from def: " + node.defId;

			// from all inputs up the chain, gather their results
			const prevInputs = resolve(node, prev, context, closure);
			if (closure.predictable || netState.isHost) {
				output = def.resolve(prevInputs, context, node.id, closure.scope, node.def);
			} else {
				output = {};
			}
		}
		result[targetInput] = output[sourceOutput];
	}

	// validate inputs - potentially transform it (for example primitive to [primitive] list)
	for (const id in target.inTypes) {
		result[id] = validateAndTransform(result[id], target.inTypes[id], context);
	}

	return result;
};

let execCounter = 0;

export const execute = (
	closure: InterpreterClosure,
	ctx: InterpreterContext,
	worldTime: number,
	perfTime: number,
): boolean => {
	const { node, stack, scope } = closure;
	const def = defs.get(node.node.defId) as NodeDef;

	// prevent exec if not predictable
	if (def !== undefined && !def.predictable) {
		closure.predictable = false;
		if (!netState.isHost) return false;
	}

	redo: do {
		// timeout after 5 seconds of execution on the current frame. prevents infinite loops
		if (++execCounter % 1000 === 0 && performance.now() - perfTime > 5_000) {
			execCounter = 0;
			throw "Aborting rete execution due to timeout";
		}

		// if we are currently suspended, we stay on the stack but stop traversal
		if (closure.suspend > worldTime) return true;

		// if we have nodes on the stack, then this is a re-visit
		// either due to it being a looping node, or previous suspension
		if (stack.length > 0) {
			// first, we handle the stack, and remove any nodes that are no longer needed
			for (let i = stack.length - 1; i >= 0; i--) {
				if (!execute(stack[i], ctx, worldTime, perfTime)) {
					closure.stack.splice(i, 1);
				}
				// if a jump statement happened,
				if (checkJumpStatement(ctx, closure)) break redo;
			}

			// if after handling child nodes our stack is not empty, exit but remain on parent stack
			if (stack.length > 0) return true;
			// if we are empty, and we've executed before then we are not a loop. exit and pop off stack
			if (closure.executed) return false;
		}

		// get the inputs. if reading from a non-predictable node, false is returned. in that case, we prevent execution
		const inputs = resolve(node.node, node.inputs, ctx, closure);
		// after resolving, the closure may be non-predictable. exit if not host.
		if (!closure.predictable && !netState.isHost) return false;

		if (!closure.executed) {
			// enter or exit a custom script
			if (
				node.script &&
				enterCustomScript(node, scope, stack, inputs, closure, ctx, worldTime, perfTime)
			) {
				return true;
			} else if (node.node.defId === NODE.End) {
				return returnFromCustomScript(node, scope, inputs, closure, ctx, worldTime, perfTime);
			}

			if (def !== undefined) {
				// some nodes create listeners that stop execution until triggered
				if (def.listen !== undefined) {
					// the listen function returns a key (string/number - like entityID or soundID)
					// can also return undefined, in which case no listener is set up
					const listener = def.listen(inputs, ctx, node.node.id);
					if (listener !== undefined)
						makeListener(listener, ctx.world.rete.listeners, node.node.defId, ctx, closure);
					// pop off of the stack
					return false;
				}

				// runs the basic execution code. if execute explicitly returns false, stop execution.
				// do not use this node-execution interruption for node design - see "all player feet enter" node.
				if (
					def.execute !== undefined &&
					def.execute(inputs, ctx, node.node.id, scope, closure) === false
				)
					return false;

				// some nodes have jump statements (continue and break). they stop execution
				if (def.jump !== undefined) {
					ctx.jump = def.jump;
					return false;
				}

				// some nodes will not pop off the stack - e.g. loops. they will be re-executed
				if (def.shouldLoop !== undefined) {
					closure.executed = !def.shouldLoop(inputs, scope, node.node.id);
				} else {
					closure.executed = true;
				}

				// some nodes will suspend (wait nodes), they halt the stack
				if (def.suspend !== undefined) {
					closure.suspend = worldTime + def.suspend(inputs);
					return true;
				}
			}
		}

		// execute children and add them to stack
		// do not execute children of custom nodes, entry and exit is handled by enterCustomScript and returnFromCustomScript.
		if (!node.node.custom) {
			for (const type in node.exec) {
				// we do not do the regular exec here. we do the internal ones first (loop, or if/else)
				if (type === "exec") continue;

				const next = node.exec[type];

				// control flow - optional chaining! can be undefined, in which case we do execute
				if (def?.controlFlow?.(type, scope, inputs, ctx, node.node.id) === false) continue;

				pushExecChain(closure, next, scope, stack, ctx, worldTime, perfTime);
			}
		}
	} while (!closure.executed);

	//  we add the final "exec" output. dont do this if we're a script node (done by returnFromCustomScript)
	if (node.script === undefined && node.exec.exec !== undefined && stack.length === 0) {
		pushExecChain(closure, node.exec.exec, scope, stack, ctx, worldTime, perfTime);
	}

	// we stay on the stack as long as our stack is not empty
	return stack.length > 0; //|| scope.returns?.length > 0;
};

const enterCustomScript = (
	node: ParsedNodeExec,
	scope: InterpreterScope,
	stack: InterpreterClosure[],
	inputs: InterpreterExecInput,
	closure: InterpreterClosure,
	ctx: InterpreterContext,
	worldTime: number,
	perfTime: number,
): boolean => {
	// defined, check happens outside
	const entryNode = node.script!.entryNodes[node.script!.entryId];

	// enter a custom script function. copy the local scope
	const clone = { ...scope };

	// keep a reference to the parent closure here. this is used to push things to the parent stack
	clone.parentClosure = closure;
	// add input information that is used inside of the custom function
	clone.scriptInputs = inputs;
	// add controls information
	clone.controls = structuredClone(node.node.data);
	// push to stack
	pushExecChain(closure, [entryNode], clone, stack, ctx, worldTime, perfTime);
	closure.executed = true;
	return true;
};

const returnFromCustomScript = (
	node: ParsedNodeExec,
	scope: InterpreterScope,
	inputs: InterpreterExecInput,
	closure: InterpreterClosure,
	ctx: InterpreterContext,
	worldTime: number,
	perfTime: number,
): boolean => {
	// when leaving a custom script, we add the resulting execs to the parent closure's stack
	const parentClosure = scope.parentClosure;
	const parentNode = parentClosure.node;
	const execId = node.node.id + "_exec";
	const exec = parentNode.exec[execId] || parentNode.exec.exec;

	// exec will be undefined if there are no actual outgoing connections
	if (exec === undefined) return false;

	// they all get the inputs accumulated for this exec on the scope
	const clone = { ...parentClosure.scope };
	clone[parentNode.node.id] = inputs;
	closure.executed = true;
	pushExecChain(closure, exec, clone, closure.stack, ctx, worldTime, perfTime);
	return closure.stack.length > 0;
};

const makeListener = (
	{ key, options }: ListenerSetup,
	listeners: ReteState["listeners"],
	defId: string,
	ctx: InterpreterContext,
	closure: InterpreterClosure,
): void => {
	// key = is a direct lookup for the listener, such as an entityID
	// options = may be configurables, such as "range" on onEntityProximityEnter
	// take the closure and add it to an array for this node definition
	if (listeners[defId] === undefined) listeners[defId] = {};
	if (listeners[defId][key] === undefined) listeners[defId][key] = [];
	listeners[defId][key].push({ ctx, closure, options });
	// prevent further execution
	closure.executed = true;
};

const checkJumpStatement = (ctx: InterpreterContext, parentClosure: InterpreterClosure): boolean => {
	// if a jump statement was triggered in our stack, we either bubble it or consume it
	// early exit if no jump, or if we're not a loop
	if (ctx.jump === undefined || parentClosure.executed) return false;

	// in case of break, it means we no longer re-enter
	if (ctx.jump === "break") parentClosure.executed = true;

	// consume the jump statement
	parentClosure.stack.length = 0;
	ctx.jump = undefined;
	return true;
};

const pushExecChain = (
	closure: InterpreterClosure,
	chain: ParsedNodeExec[],
	scope: InterpreterScope,
	stack: InterpreterClosure[],
	ctx: InterpreterContext,
	worldTime: number,
	perfTime: number,
): void => {
	// clone the input scope
	const newScope = { ...scope };
	for (const n of chain) {
		const e = makeClosure(n, newScope, closure.predictable);
		if (execute(e, ctx, worldTime, perfTime)) stack.push(e);
		if (checkJumpStatement(ctx, closure)) return;
	}
};

export const makeClosure = (
	node: ParsedNodeExec,
	scope: InterpreterScope,
	predictable: boolean,
): InterpreterClosure => {
	// this struct describes the execution of a node as well as its variable scope and subsequent call stack
	return {
		node,
		executed: false,
		jump: undefined,
		predictable,
		suspend: 0,
		scope,
		stack: [],
	};
};

export const interpret = (info: InterpretInfo, entryPoint: EntryPoint, world: World): InterpreterContext => {
	// TODO: this is mostly unnecessary, room for improvement
	return {
		info,
		world,
		state: world.rete.state,
		entryPoint,
		closure: makeClosure(
			entryPoint,
			{
				...entryPoint.scope,
				controls: entryPoint.controls,
			},
			true,
		),
		jump: undefined,
	};
};
