import type { DefTransformOptions } from "base/util/Defs";
import { defTransform } from "base/util/Defs";
import { Object3DUpdateMatrixWorld } from "base/util/Extensions.js";
import type { nullish } from "@jamango/helpers";
import { isNullish } from "@jamango/helpers";
import { Layers } from "client/Layers.js";
import type { Object3D, Material, Scene } from "three";
import {
	CanvasTexture,
	DoubleSide,
	Group,
	Mesh,
	PlaneGeometry,
	SRGBColorSpace,
	Sprite,
	Vector3,
} from "three";
import { UIMeshBasicMaterial, UISpriteMaterial } from "client/util/Shaders.js";
import type { World } from "base/world/World.js";

const TEXT_CACHE_SIZE = 100; //clear cache when it gets phat

const alphaCache = new Map<Text3DRenderOptions["backgroundColor"], number>();

const tmpVec = new Vector3();

export type Text3DRenderOptions = {
	font?: string;
	fontSize?: number;
	fontWeight?: number;
	offsetTop?: number;
	backgroundColor?: string;
	fontColor?: string;
	fontOutlineColor?: string;
	fontOutlineWidth?: number;
	margin?: number;
	linePadding?: number;
	radius?: number;
	align?: CanvasTextAlign;
};

export type Text3DOptions = Text3DRenderOptions & {
	faceCamera?: boolean;
	scl?: number;
	sizeAttenuation?: boolean;
	proximity?: number;
	metadata?: { [key: string]: any };
	transform?: DefTransformOptions;
	debugName?: string;
};

const TEXT_DEFAULT_FONT = "ClashGrotesk, sans-serif";
const TEXT_DEFAULT_SIZE = 20;
const TEXT_DEFAULT_WEIGHT = 300;

export interface Text3D extends ReturnType<typeof Text3D.render> {}

export class Text3D {
	readonly faceCamera: boolean;
	readonly scl: number;
	readonly sizeAttenuation: boolean;
	readonly proximity: number;
	readonly metadata: { [key: string]: any } | null;

	// hierarchy: parent -> obj -> proximityGroup -> child
	readonly child: Sprite | Mesh;
	readonly obj: Group;
	private readonly proximityGroup: Group;

	static render(
		o: Text3DRenderOptions | nullish,
		text: string,
		drawContext?: { cavas: HTMLCanvasElement; ctx: CanvasRenderingContext2D },
	) {
		const font = o?.font ?? TEXT_DEFAULT_FONT;
		const fontSize = o?.fontSize ?? TEXT_DEFAULT_SIZE;
		const fontWeight = o?.fontWeight ?? TEXT_DEFAULT_WEIGHT;
		const offsetTop = o?.offsetTop ?? 0;
		const backgroundColor = o?.backgroundColor ?? "#11182780";
		const fontColor = o?.fontColor ?? "white";
		const fontOutlineColor = o?.fontOutlineColor ?? "black";
		const fontOutlineWidth = o?.fontOutlineWidth ?? 0;

		const margin = o?.margin ?? 10;
		const linePadding = o?.linePadding ?? 6;
		const radius = o?.radius ?? 6;
		const align = o?.align ?? "center";

		const canvas = drawContext?.cavas ?? document.createElement("canvas");
		const ctx = drawContext?.ctx ?? canvas.getContext("2d");
		if (!ctx) throw Error("Can't render 2D text (context is null)");

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		const fontStyle = fontWeight + " " + fontSize + "px " + font;

		// get the alpha value of backgroundColor [0, 255]
		let backgroundAlpha = alphaCache.get(backgroundColor);
		if (backgroundAlpha === undefined) {
			ctx.fillStyle = backgroundColor;
			ctx.fillRect(0, 0, 1, 1);
			backgroundAlpha = ctx.getImageData(0, 0, 1, 1).data[3];
			alphaCache.set(backgroundColor, backgroundAlpha);
		}

		ctx.font = fontStyle;
		let metrics = ctx.measureText(text);

		const baseline = metrics.actualBoundingBoxAscent;
		const lineHeight = baseline + metrics.actualBoundingBoxDescent + linePadding + fontOutlineWidth;

		let left = 0;
		let right = 0;
		const texts = text.split("\n");

		for (const line of texts) {
			metrics = ctx.measureText(line);
			const curLeft = metrics.actualBoundingBoxLeft;
			const curRight = metrics.actualBoundingBoxRight;

			if (curLeft > left) left = curLeft;
			if (curRight > right) right = curRight;
		}

		const margin2 = 2 * margin;
		const rectw = left + right + margin2 + fontOutlineWidth;
		const recth = lineHeight * texts.length + margin2 - linePadding;

		//resize to nearest power of 2
		canvas.width = 2 ** Math.ceil(Math.log2(rectw));
		canvas.height = 2 ** Math.ceil(Math.log2(recth));

		if (backgroundAlpha > 0) {
			ctx.fillStyle = backgroundColor;

			if (radius === 0) {
				ctx.fillRect(0, 0, rectw, recth);
			} else {
				let r = radius;
				if (rectw < 2 * r) r = rectw / 2;
				if (recth < 2 * r) r = recth / 2;

				ctx.beginPath();
				ctx.moveTo(r, 0);
				ctx.arcTo(rectw, 0, rectw, recth, r);
				ctx.arcTo(rectw, recth, 0, recth, r);
				ctx.arcTo(0, recth, 0, 0, r);
				ctx.arcTo(0, 0, rectw, 0, r);
				ctx.closePath();
				ctx.fill();
			}
		}

		ctx.font = fontStyle;
		ctx.strokeStyle = fontOutlineColor;
		ctx.fillStyle = fontColor;
		ctx.lineWidth = fontOutlineWidth;

		for (let i = 0; i < texts.length; i++) {
			const text = texts[i];
			const y = baseline + margin + i * lineHeight + offsetTop + fontOutlineWidth / 2;

			ctx.textAlign = align;

			// prevent jagged artifacts: https://stackoverflow.com/questions/31224680/canvas-stroke-text-sharp-artifacts
			ctx.lineJoin = "miter";
			ctx.miterLimit = 2;

			switch (align) {
				case "start":
				case "end": {
					//implement me
					const x = left + margin + fontOutlineWidth / 2;

					if (fontOutlineWidth > 0) ctx.strokeText(text, x, y);

					ctx.fillText(text, x, y);
					break;
				}
				default: {
					const x = rectw / 2;

					if (fontOutlineWidth > 0) ctx.strokeText(text, x, y);

					ctx.fillText(text, x, y);
					break;
				}
			}
		}

		const texture = new CanvasTexture(canvas);
		texture.colorSpace = SRGBColorSpace;

		const ret = {
			texture,
			canvas,
			ctx,
			text,
			pixelWidth: rectw,
			pixelHeight: recth,
			canvasWidth: canvas.width,
			canvasHeight: canvas.height,
			backgroundAlpha,

			font,
			fontSize,
			fontWeight,
			offsetTop,
			backgroundColor,
			fontColor,
			fontOutlineColor,
			fontOutlineWidth,
			margin,
			linePadding,
			radius,
			align,
		};

		return ret;
	}

	static clearCache() {
		if (alphaCache.size > TEXT_CACHE_SIZE) alphaCache.clear();
	}

	constructor(
		readonly world: World,
		parent: Object3D | Scene | undefined,
		o: Text3DOptions,
		text: string,
	) {
		this.faceCamera = o.faceCamera ?? false;
		this.scl = o.scl ?? 1 / 150;
		this.sizeAttenuation = o.sizeAttenuation ?? true;
		this.proximity = o.proximity ?? -1;
		this.metadata = structuredClone(o.metadata ?? null);

		const debugName = o.debugName ?? "Text3D";

		if (this.proximity < 0) this.proximity = Infinity;

		Object.assign(this, Text3D.render(o, text));

		//set material's alphaTest according to background color's alpha
		let alphaTest;
		switch (this.backgroundAlpha) {
			case 0:
			case 255:
				alphaTest = 0.5;
				break;
			default:
				alphaTest = this.backgroundAlpha / 255 / 2;
				break;
		}

		if (this.faceCamera) {
			this.child = new Sprite(
				new UISpriteMaterial({
					map: this.texture,
					transparent: true,
					alphaTest,
					sizeAttenuation: this.sizeAttenuation,
					depthTest: false,
					depthWrite: false,
				}),
			);
			this.child.name = debugName + "_Sprite";
		} else {
			this.child = new Mesh(
				new PlaneGeometry(),
				new UIMeshBasicMaterial({
					map: this.texture,
					transparent: true,
					alphaTest,
					side: DoubleSide,
					polygonOffset: true,
					polygonOffsetFactor: -4,
				}),
			);
			this.child.name = debugName + "_Mesh";
		}
		this.child.matrixAutoUpdate = false;

		this.obj = new Group();
		this.obj.matrixAutoUpdate = false;

		this.proximityGroup = new Group();
		this.proximityGroup.add(this.child);
		this.proximityGroup.matrixAutoUpdate = false;

		this.obj.add(this.proximityGroup);

		if (this.proximity < Infinity) {
			// need to update this even if visible is false
			this.proximityGroup.updateMatrixWorld = Object3DUpdateMatrixWorld;

			let closeEnough: boolean;
			let prvFrame: number;

			Object.defineProperty(this.proximityGroup, "visible", {
				configurable: true,
				get: () => {
					const curFrame = world.frame;
					if (curFrame !== prvFrame) {
						closeEnough =
							tmpVec
								.setFromMatrixPosition(this.obj.matrixWorld)
								.distanceTo(world.client!.camera.position) < this.proximity;
						prvFrame = curFrame;
					}

					return closeEnough;
				},
			});
		}

		if (this.faceCamera) {
			this.setScreenSpace();
		} else {
			this.setWorldSpace();
		}

		this.setCenter();

		if (!isNullish(o.transform)) {
			defTransform(o.transform, this.obj);
			this.obj.updateMatrix();
		}

		if (parent) {
			parent.add(this.obj);
		}
	}

	rerender(renderOptions: Text3DRenderOptions, text: string) {
		const material = this.child.material as UISpriteMaterial | UIMeshBasicMaterial;
		material.map = null;

		if (this.texture) this.texture.dispose();

		Object.assign(this, Text3D.render(renderOptions, text, { cavas: this.canvas, ctx: this.ctx }));

		material.map = this.texture;

		if (this.faceCamera) {
			this.setScreenSpace();
		} else {
			this.setWorldSpace();
		}

		this.setCenter();
	}

	get scaleFactor() {
		if (this.faceCamera) {
			return this.scl;
		}

		return this.scl * 10;
	}

	setScreenSpace() {
		this.child.layers.set(Layers.UI);
		this.child.renderOrder = 2;
		this.child.scale.set(this.canvasWidth * this.scaleFactor, this.canvasHeight * this.scaleFactor, 1);
		this.child.updateMatrix();
	}

	setWorldSpace() {
		this.child.layers.set(Layers.DEFAULT);
		this.child.renderOrder = 0;
		this.child.scale.set(this.canvasWidth * this.scaleFactor, this.canvasHeight * this.scaleFactor, 1);
		this.child.updateMatrix();
	}

	setCenter(offx = 0, offy = 0) {
		if (this.faceCamera) {
			(this.child as Sprite).center.set(
				(this.pixelWidth + offx * 2) / this.canvasWidth / 2,
				1 - (this.pixelHeight + offy * 2) / this.canvasHeight / 2,
			);
		} else {
			this.child.position.x = (this.scaleFactor * (this.canvasWidth - this.pixelWidth - offx)) / 2;
			this.child.position.y =
				this.scaleFactor * (1 - (this.canvasHeight - this.pixelHeight - offy) / 2);
			this.child.updateMatrix();
		}
	}

	dispose() {
		this.obj.removeFromParent();
		if (!this.faceCamera) this.child.geometry.dispose();
		(this.child.material as Material).dispose();
		this.texture.dispose();
	}
}
