import { V0 } from "base/util/math/Math.ts";
import type { Input } from "@jamango/engine/Input.ts";
import type { Character } from "base/world/entity/Character";
import { dispatchCollisionEvents } from "base/world/entity/system/CharacterBlockCollision";

export function characterAnyCollisionUpdate(entity: Character, deltaTime: number, _input: Input) {
	if (!canUpdateSystem(entity)) return;

	if (entity.collision.state.hitFoot) {
		if (!entity.collision.state.prvHitFoot) {
			// fall damage
			const fallImpactVel = -entity.movement.state.velocity.y;

			if (entity.fallDamage.def.enable)
				if (fallImpactVel >= entity.fallDamage.def.painThreshold)
					entity.addHealth(
						(entity.fallDamage.def.painThreshold - fallImpactVel) * entity.fallDamage.def.amount,
					);
		}

		entity.movement.state.coyoteTimer = 0;
	} else {
		entity.movement.state.coyoteTimer += deltaTime;
	}

	entity.movement.state.jumpTimer += deltaTime;

	// if on ground and not sliding, or if player hit head on the ceiling
	if (
		(entity.collision.state.hitFoot && !entity.movement.state.isSliding) ||
		entity.collision.state.hitHead
	) {
		entity.movement.state.velocity.y = 0;
	}

	// kill entities if they have fallen through the ground significantly
	// TODO: define this system in a more understandable way. probably a world bound configuration.
	if (
		entity.movement.state.velocity.length() >= entity.movement.def.terminalVelocity &&
		entity.position.y < -200 &&
		entity.movement.state.velocity.y < 0
	) {
		if (!entity.isDead()) entity.kill();
	}
}

function canUpdateSystem(entity: Character) {
	return entity.type.def.isCharacter && !entity.mount.state.parent && !entity.movement.state.isFlying;
}

//characterModeSwitch true = some infrequent dramatic state change that should fully reset everything
//characterModeSwitch false = per-tick character controller update
export function resetCollisionState(character: Character, characterModeSwitch: boolean) {
	const collisionState = character.collision.state;

	//back up the results of the previous tick
	collisionState.prvHitFoot = collisionState.hitFoot;
	collisionState.prvOnGround = collisionState.onGround;

	collisionState.prvFootBlock.found = collisionState.footBlock.found;
	if (collisionState.footBlock.found) {
		const prv = collisionState.prvFootBlock;
		const cur = collisionState.footBlock;
		prv.pos.copy(cur.pos);
		prv.type = cur.type;
		prv.groups = cur.groups;
	}

	collisionState.prvSideBlocks = collisionState.sideBlocks;

	collisionState.prvHeadBlock.found = collisionState.headBlock.found;
	if (collisionState.headBlock.found) {
		const prv = collisionState.prvHeadBlock;
		const cur = collisionState.headBlock;
		prv.pos.copy(cur.pos);
		prv.type = cur.type;
		prv.groups = cur.groups;
	}

	collisionState.prvZoneBlock.found = collisionState.zoneBlock.found;
	if (collisionState.zoneBlock.found) {
		const prv = collisionState.prvZoneBlock;
		const cur = collisionState.zoneBlock;
		prv.pos.copy(cur.pos);
		prv.groups = cur.groups;
	}

	//reset current tick
	collisionState.hitFoot = false;
	collisionState.onGround = false;
	collisionState.hitSide = false;
	collisionState.hitHead = false;
	collisionState.footBlock.found = false;
	collisionState.sideBlocks = [];
	collisionState.headBlock.found = false;
	collisionState.zoneBlock.found = false;
	collisionState.isOnEdge = false;

	character.characterPhysics.state.activeContacts.length = 0;

	if (characterModeSwitch) {
		dispatchCollisionEvents(character);

		//reset previous tick
		collisionState.prvHitFoot = false;
		collisionState.prvOnGround = false;
		collisionState.prvFootBlock.found = false;
		collisionState.prvSideBlocks.length = 0;
		collisionState.prvHeadBlock.found = false;
		collisionState.prvZoneBlock.found = false;

		//when exiting flying mode, make sure movement is reset too
		const movementState = character.movement.state;
		movementState.velocity.copy(V0);
		movementState.jumpTimer = 0;
		movementState.coyoteTimer = character.movement.def.coyoteTime;
	}
}
