import { Vector3 } from "three";

export enum BlockCollisionBodyPart {
	//right side = node display names
	any = "Any", //special: does not dispatch directly
	foot = "Foot",
	side = "Side",
	head = "Head",
}

export type BlockCollision = {
	found: boolean; //remaining fields only valid if(BlockCollision.found)
	pos: Vector3;
	nor: Vector3;
	type: string | null;
	groups: string[] | null;
};

function emptyBlockCollision(): BlockCollision {
	return { found: false, pos: new Vector3(), nor: new Vector3(), type: null, groups: null };
}

export type ZoneCollision = {
	found: boolean;
	pos: Vector3; //always valid. only an air block if(ZoneCollision.found)
	groups: null | string[]; //only valid if(ZoneCollision.found)
};

function emptyZoneCollision(): ZoneCollision {
	return { found: false, pos: new Vector3(), groups: null };
}

export class CharacterCollisionComponent {
	name = "collision";

	state = {
		//updated by movement finalize system. not necessarily hitting a block
		hitFoot: false,
		hitSide: false,
		hitHead: false,
		prvHitFoot: false, //use in conjunction with hitFoot to check if the state has changed since prv tick

		onGround: false,
		prvOnGround: false,

		//updated by block collision system
		footBlock: emptyBlockCollision(),
		sideBlocks: [] as BlockCollision[],
		headBlock: emptyBlockCollision(),
		zoneBlock: emptyZoneCollision(),

		//prv tick collision state used for dispatching events based on diff
		prvFootBlock: emptyBlockCollision(),
		prvSideBlocks: [] as BlockCollision[],
		prvHeadBlock: emptyBlockCollision(),
		prvZoneBlock: emptyZoneCollision(),

		//edge detection/crouch barriers
		isOnEdge: false,
	};
}
