export class CharacterFightingComponent {
	constructor(o) {
		this.name = "fighting";

		this.def = {
			fistingDamage: o?.pvpDamage ?? 25,
			fistingDistance: o?.pvpDistance ?? 3,
		};
	}
}
