import { err } from "@jamango/helpers";
import * as SceneTree from "base/world/SceneTree";
import * as Net from "router/Net";
import { hasWrenchPowers, NON_WRENCHY_MESSAGE } from "./Wrench";

export const createSceneTreeNode = Net.rpc(
	"create scene tree node",
	(node: Omit<SceneTree.PropSceneNode, "id"> | Omit<SceneTree.CharacterSceneNode, "id">, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		SceneTree.createNode(world.sceneTree, world, node);
	},
);

export const updateSceneTreeNode = Net.rpc(
	"update scene tree node",
	(node: SceneTree.SceneNode, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		if (!world.sceneTree.nodes[node.id]) return err("Scene tree node not found.");

		SceneTree.updateNode(world.sceneTree, world, node);
	},
);

export const deleteSceneTreeNode = Net.rpc("delete scene tree node", (nodeId: string, world, peer) => {
	if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

	if (!world.sceneTree.nodes[nodeId]) return err("Scene tree node not found.");

	delete world.sceneTree.nodes[nodeId];
	world.sceneTree.dirty.add(nodeId);
});

export const setSceneTreeNodeName = Net.rpc(
	"set scene tree node name",
	({ nodeId, name }: { nodeId: string; name: string | null }, world, peer) => {
		if (peer && !hasWrenchPowers(peer)) return err(NON_WRENCHY_MESSAGE);

		const sceneTreeNode = world.sceneTree.nodes[nodeId];
		if (!sceneTreeNode) return err("Scene tree node not found.");

		sceneTreeNode.name = name;
		world.sceneTree.dirty.add(nodeId);
	},
);
