import type { IWorldBundle } from "../lib/types";
import type { JacyContent } from "./JacyContent";
import {
	getWorldBundlePatches,
	applyWorldBundlePatches,
	type WorldBundlePatch,
	patchJacyState,
} from "./state/JacyWorldBundleDiff";

type State = {
	bundle: IWorldBundle; // serialized state of JacyContent.state
	contentVersion: number;
};

export type BatchCommand = {
	contentVersion: number;
	patches: WorldBundlePatch[];
	t: number;
};

let TICK = 0;

export class JacyContentSyncer {
	#lastState: State | null = null;

	ingoingPatches: BatchCommand[] = [];
	outgoingPatches: BatchCommand[] = [];
	waitingPatches: BatchCommand[] = [];

	constructor(private readonly content: JacyContent) {}

	applyIngoingPatches() {
		const successCommands: number[] = [];

		if (this.#lastState === null) {
			return successCommands;
		}

		for (const { contentVersion, patches, t } of this.ingoingPatches) {
			const nextState = applyWorldBundlePatches(this.#lastState.bundle, patches);
			this.#lastState = {
				bundle: nextState,
				contentVersion,
			};
			successCommands.push(t);

			patchJacyState(this.content.state, patches);
			this.content.events.emit("jacy_sync_patches", patches);
		}

		this.ingoingPatches.length = 0;

		return successCommands;
	}

	createOutgoingPatches() {
		if (!this.content.state.isDirty) return;
		this.content.state.markClean();

		const nextState = structuredClone(this.content.state.export());

		if (this.#lastState === null) {
			this.#lastState = { bundle: nextState, contentVersion: 0 };
			return;
		}

		const patches = getWorldBundlePatches(this.#lastState.bundle, nextState);

		if (patches.length === 0) {
			return;
		}

		this.outgoingPatches.push({
			contentVersion: this.#lastState.contentVersion,
			patches,
			t: TICK++,
		});

		this.#lastState = {
			bundle: nextState,
			contentVersion: this.#lastState.contentVersion + 1,
		};
	}

	createForSending() {
		if (this.outgoingPatches.length === 0) return [];

		const toSend = [...this.outgoingPatches];

		this.waitingPatches.push(...toSend);

		this.outgoingPatches.length = 0;

		return toSend;
	}

	ack(ids: number[]) {
		this.waitingPatches = this.waitingPatches.filter((p) => !ids.includes(p.t));
	}
}
