export class InteractComponent {
	constructor(_o, _entity) {
		this.name = "interact";

		this.state = {
			// serialized
			enable: false,
			/** @type {null | string} */
			label: null,

			// non-serialized
			/** @type {import("client/dom/GameWorldUI").GameWorldUIElement["id"] | undefined} */
			element: undefined,
		};
	}
}
