import type { IAvatar } from "@jamango/content-client";
import { apiClient, apiResponseToResult } from "./index";
import { ws } from "./ws";

export async function getUserAvatars() {
	return apiResponseToResult(await apiClient.api.avatars.get_all.get());
}

export async function getUserAvatar(accountId: string, avatarId: string) {
	return apiResponseToResult(await ws.client.safeCall("server:get_avatar", { accountId, avatarId }));
}

export async function createUserAvatar({
	thumbnailResourceId,
	photoResourceId,
	config,
}: {
	thumbnailResourceId: string;
	photoResourceId: string;
	config: IAvatar["config"];
}) {
	return apiResponseToResult(
		await apiClient.api.avatars.create.post({
			thumbnailResourceId,
			photoResourceId,
			config,
		}),
	);
}

export async function updateUserAvatar({
	id,
	thumbnailResourceId,
	photoResourceId,
	config,
	name,
}: {
	id: string;
	thumbnailResourceId?: string;
	photoResourceId?: string;
	config?: IAvatar["config"];
	name?: string;
}) {
	return apiResponseToResult(
		await apiClient.api.avatars.update.post({
			avatarId: id,
			thumbnailResourceId,
			photoResourceId,
			config,
			name,
		}),
	);
}

export async function setAsUserAvatar(avatarId: string) {
	return apiResponseToResult(await apiClient.api.avatars.set_active.post({ avatarId }));
}

export async function deleteUserAvatar(avatarId: string) {
	return apiResponseToResult(await apiClient.api.avatars.delete.post({ avatarId }));
}
