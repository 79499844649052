import type { Vector2, Spherical, Vector4Tuple } from "three";
import type { CharacterViewRaycastMode } from "base/world/entity/component/CharacterViewRaycast";
import type { CharacterSculptMode } from "base/world/entity/component/CharacterSelector";

export enum InputCommandType {
	EQUIP,
	EMOTE,
	MELEE,
	INTERACT,
	RELOAD,
	FORCE_RESPAWN,
	PICK_BLOCK,
	ITEM_PRIMARY,
	ITEM_SECONDARY,
	CONTROL_PRESS,
	CONTROL_RELEASE,
	CHANGE_RAYCAST_MODE,
	CHANGE_SCULPT_MODE,
	CHANGE_ZEN_MODE,
}

export type InputCommand =
	| InputCommandType.INTERACT
	| InputCommandType.RELOAD
	| InputCommandType.FORCE_RESPAWN
	| InputCommandType.PICK_BLOCK
	| InputCommandType.ITEM_PRIMARY
	| InputCommandType.ITEM_SECONDARY
	| InputCommandType.MELEE
	| [InputCommandType.EQUIP, item: string | null]
	| [InputCommandType.EMOTE, emote: string, loop: boolean] // TODO: could have enums for emotes in the engine
	| [InputCommandType.CONTROL_PRESS, control: string]
	| [InputCommandType.CONTROL_RELEASE, control: string]
	| [InputCommandType.CHANGE_RAYCAST_MODE, mode: CharacterViewRaycastMode]
	| [InputCommandType.CHANGE_SCULPT_MODE, mode: CharacterSculptMode]
	| [InputCommandType.CHANGE_ZEN_MODE, zenModeEnabled: boolean];

export type InputCommands = Array<InputCommand>;

export type Input = {
	pointerLocked: boolean;
	nipple: Vector2;
	camera: Spherical;
	isFlyingDown: boolean;
	isFlyingUp: boolean;
	isSprinting: boolean;
	isCrouching: boolean;
	isJumping: boolean;
	isPullingTrigger: boolean;
	isIronSights: boolean;
	isSwappingTool: boolean;

	// TODO: isFlying
	requestFlying: boolean;

	hold:
		| null
		| [
				entityId: number,
				distance: number,
				hitOffsetLocal: [number, number, number],
				playerStartQuaternion: Vector4Tuple,
				entityStartQuaternion: Vector4Tuple,
		  ];
};

export type ManagerClientState = {
	local: { input: Input; cmd: InputCommands };
	doubleTapDetection: {
		flyTimer: number;
		sprintTimer: number;
	};
	dedicatedSprintKey: boolean;
	lastSerialized: string;
};
