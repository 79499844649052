import type * as Physics from "base/world/Physics";
import type { Character } from "base/world/entity/Character";

export class CharacterPhysicsComponent {
	name = "characterPhysics";

	def: {
		enabled: boolean;
	};

	state: {
		enabled: boolean | undefined;
		scale: number | undefined;
		isCrouching: boolean | undefined;
		controller: Physics.CharacterController | undefined;
		activeContacts: Physics.CharacterControllerContact[];
	};

	constructor(_o: any, _entity: Character) {
		this.def = {
			enabled: false,
		};

		this.state = {
			enabled: undefined,
			scale: undefined,
			isCrouching: undefined,
			controller: undefined,
			activeContacts: [],
		};
	}
}
