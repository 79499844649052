import { netState } from "router/Parallelogram";
import { isNullish } from "@jamango/helpers";
import { Text3D } from "client/world/fx/Text3D";
import type { Text3DEntity } from "base/world/entity/Text3D";

export function text3DUpdate(entity: Text3DEntity) {
	if (!canUpdateSystem(entity)) return;

	const world = entity.world;
	const text3D = entity.text3D;

	if (!needsRerender(entity)) return;

	if (!text3D.label) {
		text3D.label = new Text3D(
			world,
			text3D.state.parent,
			{ ...text3D.state.renderOptions, ...text3D.state.options },
			text3D.state.text,
		);
	} else {
		text3D.label.rerender(text3D.state.renderOptions, text3D.state.text);
	}

	// const newScale = entity.text3D.state.options.scl;
	// if (newScale !== undefined) {
	// 	entity.scale.setScalar(newScale);
	// }
}

function canUpdateSystem(entity: Text3DEntity) {
	return netState.isClient && !isNullish(entity.text3D);
}

function needsRerender(entity: Text3DEntity) {
	return (
		entity.text3D.label === undefined ||
		entity.text3D.state.text !== entity.text3D.label.text ||
		entity.text3D.state.renderOptions.fontSize !== entity.text3D.label.fontSize
	);
}
