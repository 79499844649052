import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { angleDistance } from "base/util/math/Math.ts";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsCrouchIdle extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsCrouchIdle", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToWalkingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToTurningTime = 0.2;
		this.transitionToFallingTime = 0.9;
		this.transitionToJumpingTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Crouch_Idle`,
				new AnimationTrack(animMesh, `JMGO_${item}_Crouch_Idle`, this),
			);
		});

		this.crouchIdleAction = this.trackMap.get("Spade_Crouch_Idle");
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;

		if (currentItemAnimations) {
			this.crouchIdleAction = this.trackMap.get(`${currentItemAnimations}_Crouch_Idle`);
		} else {
			this.crouchIdleAction = this.trackMap.get("Spade_Crouch_Idle");
		}

		this.crouchIdleAction.action.reset();

		if (transitionData.prvState) {
			const walkAction = this.mesh.getAction("JMGO_Unarmed_Crouch_Forward");
			if (walkAction) {
				this.crouchIdleAction.action.syncWith(walkAction);
			}

			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchIdleAction.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.crouchIdleAction.action.setEffectiveWeight(0);
			return;
		}

		this.updateWeight();
		this.crouchIdleAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (!this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("WeaponsCrouchEnd");
			outTransitionToState.blendTime = this.transitionToIdleTime;

			return true;
		}

		//transition to turning state
		if (this.inputState.rotateToCamera) {
			//transition to turning state
			const shortestDistance = angleDistance(
				this.inputState.cameraRotation,
				this.inputState.meshRotation,
			);
			const turningLeft = map.get("WeaponsCrouchTurnLeft");
			if (shortestDistance > 0 && turningLeft.state !== "transitionOut") {
				outTransitionToState.ptr = turningLeft;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
			const turningRight = map.get("WeaponsCrouchTurnRight");
			if (shortestDistance < 0 && turningRight.state !== "transitionOut") {
				outTransitionToState.ptr = turningRight;
				outTransitionToState.blendTime = this.transitionToTurningTime;
				return true;
			}
		}

		// transition to walking state
		if (this.inputState.isMoving) {
			outTransitionToState.ptr = map.get("WeaponsCrouchWalking");
			outTransitionToState.blendTime = this.transitionToRunningTime;
			return true;
		}

		return false;
	}
}
