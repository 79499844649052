import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateDeathEnd extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("DeathEnd", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
	}

	get deathEndAction() {
		return this.mesh.getAction("JMGO_Unarmed_Death_End");
	}

	startCompute(transitionData) {
		this.deathEndAction.reset();
		this.deathEndAction.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.deathEndAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.deathEndAction.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.deathEndAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.deathEndAction.getRemainingTime() < this.transitionToIdleTime) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		return false;
	}
}
