export class CharacterFallDamageComponent {
	constructor(o) {
		this.name = "fallDamage";

		this.def = {
			enable: o?.fallDamage ?? false,
			landThreshold: o?.landThreshold ?? 9.9,
			painThreshold: o?.fallDamageThreshold ?? 17.7,
			amount: o?.fallDamageAmount ?? 6,
		};
	}
}
