import { z } from "zod";

export const PerlinOptionsSchema = z.object({
	scale: z.number().min(0),
	octaves: z.number().min(1),
});

export const BlockDataSchema = z.tuple([z.number(), z.number(), z.number(), z.number(), z.string()]);

export const TerrainObjectSchema = z.array(BlockDataSchema);

const StaticObjectFactorySchema = z.object({
	type: z.literal("static"),
	template: TerrainObjectSchema,
});

const ObjectFactorySchema = StaticObjectFactorySchema;

export const PerlinNoiseObjectPlacerOptionsSchema = z.object({
	type: z.literal("perlinNoise"),
	minHeight: z.number(),
	maxHeight: z.number(),
	perlinOptions: PerlinOptionsSchema,
	forestProbability: z.number(),
	probabilityNonForest: z.number(),
	probabilityForest: z.number(),
	objects: z.array(
		z.object({
			factory: ObjectFactorySchema,
			probability: z.number(), // 0..1
		}),
	),
});

export const StaticObjectPlacerOptionsSchema = z.object({
	type: z.literal("static"),
	objects: z.array(
		z.object({
			factory: ObjectFactorySchema,
			positions: z.array(z.tuple([z.number(), z.number(), z.number()])), // global
		}),
	),
});

export const ObjectPlacerOptionsSchema = z.union([
	PerlinNoiseObjectPlacerOptionsSchema,
	StaticObjectPlacerOptionsSchema,
]);

const BlankOptionsSchema = z.object({
	type: z.literal("blank"),
});

const MVROptionsSchema = z.object({
	type: z.literal("mvr"),
});

export const CustomOptionsSchema = z.object({
	type: z.literal("custom"),
	version: z.literal(1),
	ground: z
		.object({
			amplitude: z.number(),
			vrTransition: z.number(),
			noiseOptions: PerlinOptionsSchema,
			foundationLevel: z.number(),
			topBlock: z.string(),
			midBlock: z.string(),
			foundationBlock: z.string(),
		})
		.optional(),
	mountains: z
		.object({
			amplitude: z.number(),
			noiseOptions: PerlinOptionsSchema,
			probability: z.number(),
			mountainBlock: z.string(),
		})
		.optional(),
	snow: z
		.object({
			noiseOptions: PerlinOptionsSchema,
			min: z.number(),
			max: z.number(),
			snowBlock: z.string(),
		})
		.optional(),
	water: z
		.object({
			level: z.number(),
			sandBlock: z.string(),
		})
		.optional(),
	objectsPlacers: z.array(ObjectPlacerOptionsSchema).optional(),
});

export const TerrainGenerationOptionsSchema = z.union([
	BlankOptionsSchema,
	MVROptionsSchema,
	CustomOptionsSchema,
]);

export function parseTerrainGenerationOptions(val: unknown) {
	return TerrainGenerationOptionsSchema.parse(val);
}
