import { z } from "zod";
import type { AssetType } from "../types";

export const urlSchema = z.string();

export const colorSchema = z.string().refine((value) => /^#[0-9A-F]{6}$/i.test(value), "Invalid color.");

export const assetKeySchema = (assetType: AssetType, message?: string) =>
	z
		.string({ message })
		.min(assetType.length + 1)
		.includes("#")
		.startsWith(assetType)
		.refine(
			(value) => {
				if (!value) return false;
				if (!value.includes("#")) return false;
				const [type, identifier] = value.split("#");

				if (type !== assetType) return false;
				if (!identifier) return false;
				return true;
			},
			{ message: `Invalid asset key for ${assetType}.` },
		);

export const collaboratorsSchema = z.array(
	z.object({
		displayPhoto: z.string().nullish(),
		pk: z.string(),
		playerId: z.string(),
		username: z.string(),
	}),
);

export const isValidAssetKey = (type: AssetType, value: string) => {
	const result = assetKeySchema(type).safeParse(value);
	return result.success;
};

export const checkAssetKey = (type: AssetType, value: string) => {
	assetKeySchema(type).parse(value);
};

export const AssetFileSchema = z.object({
	name: z.string(),
	size: z.number(),
	mimeType: z.string(),
	url: z.string(),
});
