import { z } from "zod";
import { assetKeySchema } from "./general";
import { AssetType, CustomUIComponent } from "../types";
import { PackageIdSchema } from "./package";

export const CustomUISchema = z.object({
	pk: assetKeySchema(AssetType.CUSTOM_UI),
	id: z.string(),
	type: z.literal(AssetType.CUSTOM_UI),
	// TODO: define better schema
	ui: z.custom<CustomUIComponent>((_value) => true),
	packageId: PackageIdSchema.optional(),
});
