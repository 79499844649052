import { z } from "zod";
import {
	MAX_DESCRIPTION,
	MAX_DISPLAY_NAME,
	MAX_FRICTION,
	MIN_DESCRIPTION,
	MIN_DISPLAY_NAME,
	MIN_FRICTION,
} from "../constants/blocks";
import { MATERIAL_SIDES } from "../constants/files";
import {
	AssetType,
	BlockCollision,
	BlockTransparency,
	BlockTransparencyBehaviour,
	type IBlock,
	type IParsedBlock,
} from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";
import { isNullish } from "@jamango/helpers";

const displayNameSchema = z
	.string()
	.min(MIN_DISPLAY_NAME, {
		message: `Block name must be at least ${MIN_DISPLAY_NAME} characters long.`,
	})
	.max(MAX_DISPLAY_NAME, {
		message: `Block name must be at most ${MAX_DISPLAY_NAME} characters long.`,
	});

export const displayName = (value: string) => {
	displayNameSchema.parse(value);
};

const descriptionSchema = z
	.string()
	.min(MIN_DESCRIPTION, {
		message: `Description must be at least ${MIN_DESCRIPTION} characters long.`,
	})
	.max(MAX_DESCRIPTION, {
		message: `Description must be at most ${MAX_DESCRIPTION} characters long.`,
	})
	.optional()
	.or(z.literal(""));

export const description = (value: string) => {
	descriptionSchema.parse(value);
};

const transparencySchema = z.nativeEnum(BlockTransparency, {
	message: "Invalid block transparency.",
});

export const transparency = (value: BlockTransparency) => {
	transparencySchema.parse(value);
};

const transparencyBehaviourSchema = z.nativeEnum(BlockTransparencyBehaviour, {
	message: "Invalid block transparency behaviour.",
});

export const transparencyBehaviour = (value: BlockTransparencyBehaviour) => {
	transparencyBehaviourSchema.parse(value);
};

const collisionSchema = z.nativeEnum(BlockCollision, {
	message: "Invalid block collision.",
});

export const collision = (value: BlockCollision) => {
	collisionSchema.parse(value);
};

const frictionSchema = z
	.number()
	.min(MIN_FRICTION, {
		message: "Surface friction must be at least -1.",
	})
	.max(MAX_FRICTION, {
		message: "Surface friction must be at most 1.",
	});

export const friction = (value: number) => {
	frictionSchema.parse(value);
};

const materialSchema = (isTexture?: boolean) =>
	z.any().refine(
		(material: IBlock["material"] | IParsedBlock["material"]) => {
			if (!isNullish(material.default)) return true;

			const isMissingSides = MATERIAL_SIDES.some((side) =>
				isNullish(material[side as keyof (IBlock | IParsedBlock)["material"]]),
			);

			return !isMissingSides;
		},
		{
			message: isTexture ? "Missing material texture side." : "Missing material color.",
		},
	);

export const material = (material: IBlock["material"] | IParsedBlock["material"], isTexture?: boolean) => {
	materialSchema(isTexture).parse(material);
};

export const BlockOptionsSchema = z.object({
	transparency: transparencySchema.optional(),
	transparencyBehaviour: transparencyBehaviourSchema.optional(),
	collision: collisionSchema.optional(),
	friction: frictionSchema.optional(),
});

export const BlockScriptAttachmentSchema = z.object({
	script: assetKeySchema(AssetType.SCRIPT),
	data: z.record(z.any()).optional(),
});

export const BlockSchema = z.object({
	pk: assetKeySchema(AssetType.BLOCK),
	type: z.literal(AssetType.BLOCK),
	displayName: displayNameSchema,
	description: descriptionSchema.nullable(),
	material: z.object({
		default: z.string().nullish(),
		nx: z.string().nullish(),
		ny: z.string().nullish(),
		nz: z.string().nullish(),
		px: z.string().nullish(),
		py: z.string().nullish(),
		pz: z.string().nullish(),
	}),
	options: BlockOptionsSchema.nullish(),
	createdAt: z.string(),
	updatedAt: z.string(),
	scripts: z.array(BlockScriptAttachmentSchema).optional(),
	packageId: PackageIdSchema.optional(),
	soundsPackId: z.string().optional(),
});

export const create = (block: IBlock | IParsedBlock, isTexture?: boolean) => {
	const schema = z.object({
		displayName: displayNameSchema,
		description: descriptionSchema,
		material: materialSchema(isTexture),
		options: BlockOptionsSchema,
	});

	schema.parse(block);
};

export const update = (block: IBlock | IParsedBlock, isTexture?: boolean) => {
	const schema = z.object({
		displayName: displayNameSchema,
		description: descriptionSchema,
		material: materialSchema(isTexture),
		options: BlockOptionsSchema,
	});

	schema.parse(block);
};
