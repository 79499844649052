import {
	getAssetType,
	type IBlockStructure,
	JacyContentSyncer,
	type AssetType,
	type BatchCommand,
} from "@jamango/content-client";
import { BB } from "base/BB";
import { ContentToDefConverter } from "base/content/ContentToDefConverter";
import type { World } from "base/world/World";
import { netState } from "router/Parallelogram";
import * as SelectorVfxClient from "client/world/fx/SelectorVfx";
import * as Net from "router/Net";
import * as CustomUIManager from "base/dom/CustomUI";

function runBlockSideEffect() {
	onBlockTypesScriptChange();

	// future: consider diffing the change to determine if visual or physics attributes changed
	BB.world.blockTypeRegistry.blockTypePhysicalAttributesChanged = true;
}

function runAudioSideEffect(pk: string) {
	if (!netState.isClient) return;

	const bundle = BB.world.content.export();
	const assets = bundle.assets;

	const resources = assets.resources ?? {};
	const audios = assets.audios ?? {};

	const audioAsset = audios[pk];

	if (!audioAsset) return;

	const resource = resources[audioAsset.resourcePk];

	if (!resource) return;

	const converter = new ContentToDefConverter();
	const engineAsset = converter.convertResourceToAsset(resource);

	BB.world.client?.downloadLazyAssets([engineAsset]);
}

function runEngineCharacterSideEffect(pk: string, mode: "update" | "remove") {
	const avatarComponents = BB.world.content.state.avatarComponents.getAll();
	const bundle = BB.world.content.export();
	const assets = bundle.assets;

	const resources = assets.resources ?? {};
	const characters = assets.characters ?? {};

	const characterAsset = characters[pk];

	if (!characterAsset) return;

	const converter = new ContentToDefConverter();
	const engineAsset = converter.convertToDefCharacter(
		characterAsset,
		assets.avatars ? Object.values(assets.avatars) : [],
		avatarComponents,
		resources,
	);

	if (mode === "remove") {
		BB.world.defs.delete(engineAsset.name);
	} else if (mode === "update") {
		BB.world.defs.set(engineAsset.name, engineAsset);
	}

	onCharactersScriptChange();
}

function runEnginePropSideEffect() {
	onPropsScriptChange();
}

function runEngineScriptSideEffect(pk: string, type: "add" | "update" | "remove") {
	BB.world.rete.needsReload = true;

	if (netState.isClient && type === "update") {
		SelectorVfxClient.markScriptDirty(BB.world, pk);
	}

	onBlockTypesScriptChange();
}

function onBlockTypesScriptChange() {
	BB.world.rete.needsReload = true;
}

function onCharactersScriptChange() {
	BB.world.rete.needsReload = true;
}

function onPropsScriptChange() {
	BB.world.rete.needsReload = true;
}

function runCustomUISideEffects() {
	// a brute force solution here is superior until we realize it isnt
	CustomUIManager.load(BB.world.customUI, BB.world.content.state.customUI.getAllComponents());
}

function runBlockStructureSideEffect(blockStructure: IBlockStructure) {
	const converter = new ContentToDefConverter();
	const def = converter.convertToDefBlockStructure(blockStructure);
	BB.world.defs.set(def.name, def);
}

function runEngineSideEffects(patches: BatchCommand["patches"]) {
	patches.forEach((patch) => {
		const assetType = getAssetType(patch.pk) as AssetType;

		if (assetType === "block") {
			runBlockSideEffect();
		} else if (assetType === "audio") {
			if (patch.type === "add") {
				runAudioSideEffect(patch.pk);
			}
		} else if (assetType === "character") {
			if (patch.type === "add" || patch.type === "update") {
				runEngineCharacterSideEffect(patch.pk, "update");
			} else if (patch.type === "remove") {
				runEngineCharacterSideEffect(patch.pk, "remove");
			}
		} else if (assetType === "prop") {
			runEnginePropSideEffect();
		} else if (assetType === "script") {
			runEngineScriptSideEffect(patch.pk, patch.type);
		} else if (assetType === "customUI") {
			runCustomUISideEffects();
		} else if (assetType === "blockStructure") {
			if (patch.type === "add" || patch.type === "update") {
				runBlockStructureSideEffect(patch.value);
			}
		}

		if (patch.type === "remove") {
			BB.world.content.state.worldData.removeExportedAsset(patch.pk);
		}
	});
}

export function init() {
	BB.world.content.events.on("jacy_sync_patches", (patches: BatchCommand["patches"]) => {
		runEngineSideEffects(patches);
	});
	return {
		syncer: new JacyContentSyncer(BB.world.content),
	};
}

export function updateJacyContentSyncer(world: World) {
	const syncer = world.jacySyncer.syncer;

	syncer.createOutgoingPatches();

	const forSending = syncer.createForSending();

	if (forSending.length > 0) {
		if (netState.isHost) {
			Net.sendToAll("jacy_cmd", forSending);

			forSending.forEach((batch) => {
				runEngineSideEffects(batch.patches);
			});
			syncer.ack(forSending.map((c) => c.t));
		} else if (netState.isClient) {
			Net.send("jacy_cmd", forSending);
		}
	}
}

export function initCommandListeners() {
	if (netState.isHost) {
		initHostCommandListeners();
	} else {
		initClientCommandListeners();
	}
}

function initHostCommandListeners() {
	Net.listen("jacy_cmd", (cmds: BatchCommand[], world, peer) => {
		const syncer = world.jacySyncer.syncer;

		syncer.ingoingPatches.push(...cmds);

		const result = syncer.applyIngoingPatches();

		Net.sendToAll("jacy_cmd", cmds);

		Net.send("jacy_ack", result, peer);
	});
}

function initClientCommandListeners() {
	Net.listen("jacy_cmd", function (cmds: BatchCommand[], world) {
		const syncer = world.jacySyncer.syncer;

		syncer.ingoingPatches.push(...cmds);

		syncer.applyIngoingPatches();
	});

	Net.listen("jacy_ack", function (result: number[], world) {
		const syncer = world.jacySyncer.syncer;
		syncer.ack(result);
	});
}
