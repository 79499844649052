import { V0, VNX, VNY, VNZ, VX, VZ } from "base/util/math/Math.ts";
import { calculateAO } from "base/world/block/AmbientOcclusion.js";
import { MISSING_BLOCK_TYPE } from "base/world/block/BlockTypeRegistry";
import {
	BLOCK_AIR,
	BLOCK_BL,
	BLOCK_BR,
	BLOCK_COLLISION_DISABLED,
	BLOCK_COLLISION_ENABLED,
	BLOCK_CUBE,
	BLOCK_MASK_INVERT,
	BLOCK_MASK_SIDE,
	BLOCK_SNX,
	BLOCK_SNY,
	BLOCK_SNZ,
	BLOCK_SPX,
	BLOCK_SPY,
	BLOCK_SPZ,
	BLOCK_TL,
	BLOCK_TR,
	BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED,
	BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN,
	BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE,
	BLOCK_TRANSPARENCY_OPAQUE,
	BLOCK_TRANSPARENCY_TRANSLUCENT,
	BLOCK_TRANSPARENCY_TRANSPARENT,
	BLOCK_VIS_BOTH,
	BLOCK_VIS_LEFT,
	BLOCK_VIS_NONE,
	BLOCK_VIS_RIGHT,
	ChunkUtil,
} from "base/world/block/Util.js";
import { BufferAttribute, BufferGeometry, Vector3 } from "three";

/**
 * @typedef {{
 *   position, Vector3
 *   normal: Vector3,
 * }} ChunkBuilderVertex
 */

/**
 * @typedef {{
 *   index: number[], // 3 per triangle
 * 	 position: number[], // 3 per vertex
 * }} ChunkBuilderPhysicsBuffer
 */

/**
 * @typedef {{
 * 	 index: number[], // 3 per triangle
 * 	 blockIndex: number[], // 1 per triangle, only used for ao
 * 	 position: number[], // 3 per vertex
 * 	 normal: number[], // 3 per vertex but they are identical for every vertex in the triangle
 * 	 uv: number[], // 2 per vertex
 * }} ChunkBuilderVisualBuffer
 */

/**
 * @typedef {{
 *   physicsCollisionEnabledBuffer: ChunkBuilderPhysicsBuffer,
 *   physicsCollisionDisabledBuffer: ChunkBuilderPhysicsBuffer,
 *   opaqueBuffer: ChunkBuilderVisualBuffer,
 *   translucentBuffer: ChunkBuilderVisualBuffer,
 * }} ChunkBuilderBuffers
 */

const _calculateTriNormalsVec3 = new Vector3();

export class ChunkBuilder {
	//---GEOMETRY BUILDING---//

	/**
	 * @param {ArrayLike<number>} shapes
	 * @param {ArrayLike<number>} types
	 * @param {number} w
	 * @param {number} h
	 * @param {number} d
	 * @param {import('base/world/block/BlockTypeRegistry').BlockTypeRegistryState} blockTypeRegistry
	 * @param {boolean} ao
	 * @param {Array<import('base/world/block/chunk/Chunk.js').Chunk> | false} neighborChunks
	 */
	static build(shapes, types, w, h, d, blockTypeRegistry, ao, neighborChunks = false) {
		/**
		 * @type {ChunkBuilderPhysicsBuffer}
		 */
		const physicsCollisionEnabledBuffer = {
			index: [],
			position: [],
		};

		/**
		 * @type {ChunkBuilderPhysicsBuffer}
		 */
		const physicsCollisionDisabledBuffer = {
			index: [],
			position: [],
		};

		/**
		 * @type {ChunkBuilderVisualBuffer}
		 */
		const opaqueBuffer = {
			index: [],
			blockIndex: [],
			position: [],
			normal: [],
			uv: [],
		};

		/**
		 * @type {ChunkBuilderVisualBuffer}
		 */
		const alphaTestedBuffer = {
			index: [],
			blockIndex: [],
			position: [],
			normal: [],
			uv: [],
		};

		/**
		 * @type {ChunkBuilderVisualBuffer}
		 */
		const translucentBuffer = {
			index: [],
			blockIndex: [],
			position: [],
			normal: [],
			uv: [],
		};

		const blockTypes = new Set();

		for (let ry = 0; ry < h; ry++) {
			for (let rz = 0; rz < d; rz++) {
				for (let rx = 0; rx < w; rx++) {
					ChunkBuilder.buildBlock(
						rx,
						ry,
						rz,
						physicsCollisionEnabledBuffer,
						physicsCollisionDisabledBuffer,
						opaqueBuffer,
						alphaTestedBuffer,
						translucentBuffer,
						blockTypes,
						shapes,
						types,
						w,
						h,
						d,
						blockTypeRegistry,
						neighborChunks,
					);
				}
			}
		}

		if (ao) {
			if (opaqueBuffer.index.length > 0) {
				calculateAO(opaqueBuffer, w, h, d);
			}

			if (alphaTestedBuffer.index.length > 0) {
				calculateAO(alphaTestedBuffer, w, h, d);
			}

			if (translucentBuffer.index.length > 0) {
				calculateAO(translucentBuffer, w, h, d);
			}
		}

		return {
			physicsCollisionEnabledBuffer,
			physicsCollisionDisabledBuffer,
			opaqueBuffer,
			alphaTestedBuffer,
			translucentBuffer,
			blockTypes,
		};
	}

	/**
	 * @param {ChunkBuilderVisualBuffer} buffer
	 * @returns {BufferGeometry}
	 */
	static buildGeometry(buffer) {
		const geom = new BufferGeometry();

		geom.setIndex(new BufferAttribute(new Uint32Array(buffer.index), 1));
		geom.setAttribute("position", new BufferAttribute(new Float32Array(buffer.position), 3));
		geom.setAttribute("normal", new BufferAttribute(new Float32Array(buffer.normal), 3));
		geom.setAttribute("uv", new BufferAttribute(new Float32Array(buffer.uv), 2));
		geom.setAttribute(
			"ao",
			new BufferAttribute(buffer.ao ?? new Float32Array(ChunkUtil.getVertexCount(buffer)), 1),
		);

		return geom;
	}

	//build a single block

	/**
	 * Build a single block
	 * @param {number} rx
	 * @param {number} ry
	 * @param {number} rz
	 * @param {ChunkBuilderPhysicsBuffer} physicsBuffer
	 * @param {ChunkBuilderPhysicsBuffer} physicsCollisionDisabledBuffer
	 * @param {ChunkBuilderVisualBuffer} opaqueBuffer
	 * @param {ChunkBuilderVisualBuffer} translucentBuffer
	 * @param {Set<number>} blockTypes
	 * @param {ArrayLike<number>} shapes
	 * @param {ArrayLike<number>} types
	 * @param {number} w
	 * @param {number} h
	 * @param {number} d
	 * @param {import('base/world/block/BlockTypeRegistry').BlockTypeRegistryState} blockTypeRegistry
	 * @param {Array<import('base/world/block/chunk/Chunk.js').Chunk> | false} neighborChunks
	 * @returns {void}
	 */
	static buildBlock(
		rx,
		ry,
		rz,
		physicsCollisionEnabledBuffer,
		physicsCollisionDisabledBuffer,
		opaqueBuffer,
		alphaTestedBuffer,
		translucentBuffer,
		blockTypes,
		shapes,
		types,
		w,
		h,
		d,
		blockTypeRegistry,
		neighborChunks,
	) {
		const blockIdToType = blockTypeRegistry.blockIdToType;

		const blockIndex = ChunkUtil.getIndex(w, h, d, rx, ry, rz);

		const shape = shapes[blockIndex];
		if (ChunkUtil.discardShape(shape)) return;

		const typeID = types[blockIndex];
		blockTypes.add(typeID);

		let type = blockIdToType.get(typeID);

		if (!type) {
			// fall back to the missing block type - we don't want to omit meshing faces and make holes in the world!
			type = MISSING_BLOCK_TYPE;
		}

		const faces = type.faces;
		const transparency = type.transparency;
		const transparencyBehaviour = type.transparencyBehaviour;
		const collision = type.collision;

		// seperate buffers for collision enabled vs disabled blocks
		let physicsBuffer;

		if (collision === BLOCK_COLLISION_ENABLED) {
			physicsBuffer = physicsCollisionEnabledBuffer;
		} else {
			physicsBuffer = physicsCollisionDisabledBuffer;
		}

		// seperate buffers for opaque and transparent vs translucent blocks
		let visualBuffer;

		if (transparency === BLOCK_TRANSPARENCY_OPAQUE) {
			visualBuffer = opaqueBuffer;
		} else if (transparency === BLOCK_TRANSPARENCY_TRANSPARENT) {
			visualBuffer = alphaTestedBuffer;
		} else if (transparency === BLOCK_TRANSPARENCY_TRANSLUCENT) {
			visualBuffer = translucentBuffer;
		}

		const physicsVertexCountBefore = ChunkUtil.getVertexCount(physicsBuffer);
		const visualVertexCountBefore = ChunkUtil.getVertexCount(visualBuffer);

		// which side was sculpted
		const sculptSide = shape & BLOCK_MASK_SIDE;

		/**
		 * which side is currently being built
		 * @type {number}
		 */
		let side;

		/**
		 * @type {number}
		 */
		let vertexTopLeft;

		/**
		 * @type {number}
		 */
		let vertexBottomLeft;

		/**
		 * @type {number}
		 */
		let vertexTopRight;

		/**
		 * @type {number}
		 */
		let vertexBottomRight;

		/**
		 * @type {number}
		 */
		let visible;

		/**
		 * @type {number}
		 */
		let face;

		// left side
		side = BLOCK_SNX;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			vertexTopLeft = ChunkBuilder.vertex(new Vector3(0, 1, 0), VNX);
			vertexBottomLeft = ChunkBuilder.vertex(new Vector3(0, 0, 0), VNX);
			vertexTopRight = ChunkBuilder.vertex(new Vector3(0, 1, 1), VNX);
			vertexBottomRight = ChunkBuilder.vertex(new Vector3(0, 0, 1), VNX);
			face = ChunkUtil.selectFace(faces, sculptSide, side);
			ChunkBuilder.buildQuad(
				vertexTopLeft,
				vertexBottomLeft,
				vertexTopRight,
				vertexBottomRight,
				true,
				blockIndex,
				face,
				physicsBuffer,
				visualBuffer,
				sculptSide,
				side,
				visible,
			);
		}

		// right side
		side = BLOCK_SPX;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			vertexTopLeft = ChunkBuilder.vertex(new Vector3(1, 1, 1), VX);
			vertexBottomLeft = ChunkBuilder.vertex(new Vector3(1, 0, 1), VX);
			vertexTopRight = ChunkBuilder.vertex(new Vector3(1, 1, 0), VX);
			vertexBottomRight = ChunkBuilder.vertex(new Vector3(1, 0, 0), VX);
			face = ChunkUtil.selectFace(faces, sculptSide, side);
			ChunkBuilder.buildQuad(
				vertexTopLeft,
				vertexBottomLeft,
				vertexTopRight,
				vertexBottomRight,
				true,
				blockIndex,
				face,
				physicsBuffer,
				visualBuffer,
				sculptSide,
				side,
				visible,
			);
		}

		// back side
		side = BLOCK_SNZ;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			vertexTopLeft = ChunkBuilder.vertex(new Vector3(1, 1, 0), VNZ);
			vertexBottomLeft = ChunkBuilder.vertex(new Vector3(1, 0, 0), VNZ);
			vertexTopRight = ChunkBuilder.vertex(new Vector3(0, 1, 0), VNZ);
			vertexBottomRight = ChunkBuilder.vertex(new Vector3(0, 0, 0), VNZ);
			face = ChunkUtil.selectFace(faces, sculptSide, side);
			ChunkBuilder.buildQuad(
				vertexTopLeft,
				vertexBottomLeft,
				vertexTopRight,
				vertexBottomRight,
				true,
				blockIndex,
				face,
				physicsBuffer,
				visualBuffer,
				sculptSide,
				side,
				visible,
			);
		}

		// front side
		side = BLOCK_SPZ;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			vertexTopLeft = ChunkBuilder.vertex(new Vector3(0, 1, 1), VZ);
			vertexBottomLeft = ChunkBuilder.vertex(new Vector3(0, 0, 1), VZ);
			vertexTopRight = ChunkBuilder.vertex(new Vector3(1, 1, 1), VZ);
			vertexBottomRight = ChunkBuilder.vertex(new Vector3(1, 0, 1), VZ);
			face = ChunkUtil.selectFace(faces, sculptSide, side);
			ChunkBuilder.buildQuad(
				vertexTopLeft,
				vertexBottomLeft,
				vertexTopRight,
				vertexBottomRight,
				true,
				blockIndex,
				face,
				physicsBuffer,
				visualBuffer,
				sculptSide,
				side,
				visible,
			);
		}

		// bottom side
		side = BLOCK_SNY;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			vertexTopLeft = ChunkBuilder.vertex(new Vector3(0, 0, 1), VNY);
			vertexBottomLeft = ChunkBuilder.vertex(new Vector3(0, 0, 0), VNY);
			vertexTopRight = ChunkBuilder.vertex(new Vector3(1, 0, 1), VNY);
			vertexBottomRight = ChunkBuilder.vertex(new Vector3(1, 0, 0), VNY);
			face = ChunkUtil.selectFace(faces, sculptSide, side);

			if (ChunkUtil.flipDiagonal(shape)) {
				ChunkBuilder.buildQuad(
					vertexTopRight,
					vertexTopLeft,
					vertexBottomRight,
					vertexBottomLeft,
					false,
					blockIndex,
					face,
					physicsBuffer,
					visualBuffer,
					sculptSide,
					side,
					visible,
				);
			} else {
				ChunkBuilder.buildQuad(
					vertexTopLeft,
					vertexBottomLeft,
					vertexTopRight,
					vertexBottomRight,
					false,
					blockIndex,
					face,
					physicsBuffer,
					visualBuffer,
					sculptSide,
					side,
					visible,
				);
			}
		}

		//top side
		side = BLOCK_SPY;
		visible = ChunkBuilder.visibilityCheck(
			rx,
			ry,
			rz,
			shape,
			typeID,
			sculptSide,
			side,
			transparencyBehaviour,
			shapes,
			types,
			blockIdToType,
			w,
			h,
			d,
			neighborChunks,
		);
		if (visible) {
			const vTLl = ChunkBuilder.vertex(new Vector3(0, Number((shape & BLOCK_TL) === BLOCK_TL), 0), V0);
			const vBLl = ChunkBuilder.vertex(new Vector3(0, Number((shape & BLOCK_BL) === BLOCK_BL), 1), V0);
			const vTRl = ChunkBuilder.vertex(new Vector3(1, Number((shape & BLOCK_TR) === BLOCK_TR), 0), V0);
			const vBRl = ChunkBuilder.vertex(new Vector3(1, Number((shape & BLOCK_BR) === BLOCK_BR), 1), V0);
			const vTRr = ChunkBuilder.vertex(vTRl);
			const vBRr = ChunkBuilder.vertex(vBRl);
			face = ChunkUtil.selectFace(faces, sculptSide, side);

			const L = (visible & BLOCK_VIS_LEFT) === BLOCK_VIS_LEFT;
			const R = (visible & BLOCK_VIS_RIGHT) === BLOCK_VIS_RIGHT;
			let flatQuad;

			if (ChunkUtil.flipDiagonal(shape)) {
				const vTLr = ChunkBuilder.vertex(vTLl);
				ChunkBuilder.calculateTriNormals(vBLl, vBRl, vTLl);
				ChunkBuilder.calculateTriNormals(vTRr, vTLr, vBRr);
				flatQuad = vBLl.normal.equals(vTRr.normal);

				if (flatQuad) {
					ChunkBuilder.buildQuad(
						vTRr,
						vTLl,
						vBRl,
						vBLl,
						false,
						blockIndex,
						face,
						physicsBuffer,
						visualBuffer,
						sculptSide,
						side,
						visible,
					);
				} else {
					if (L) {
						ChunkBuilder.buildTri(
							vBLl,
							vBRl,
							vTLl,
							blockIndex,
							face,
							physicsBuffer,
							visualBuffer,
							sculptSide,
							side,
						);
					}
					if (R) {
						ChunkBuilder.buildTri(
							vTRr,
							vTLr,
							vBRr,
							blockIndex,
							face,
							physicsBuffer,
							visualBuffer,
							sculptSide,
							side,
						);
					}
				}
			} else {
				const vBLr = ChunkBuilder.vertex(vBLl);
				ChunkBuilder.calculateTriNormals(vTLl, vBLl, vTRl);
				ChunkBuilder.calculateTriNormals(vBRr, vTRr, vBLr);
				flatQuad = vTLl.normal.equals(vBRr.normal);

				if (flatQuad) {
					ChunkBuilder.buildQuad(
						vTLl,
						vBLl,
						vTRl,
						vBRr,
						false,
						blockIndex,
						face,
						physicsBuffer,
						visualBuffer,
						sculptSide,
						side,
						visible,
					);
				} else {
					if (L) {
						ChunkBuilder.buildTri(
							vTLl,
							vBLl,
							vTRl,
							blockIndex,
							face,
							physicsBuffer,
							visualBuffer,
							sculptSide,
							side,
						);
					}
					if (R) {
						ChunkBuilder.buildTri(
							vBRr,
							vTRr,
							vBLr,
							blockIndex,
							face,
							physicsBuffer,
							visualBuffer,
							sculptSide,
							side,
						);
					}
				}
			}
		}

		const physicsBufferVertexCountAfter = ChunkUtil.getVertexCount(physicsBuffer);
		for (let vi = physicsVertexCountBefore; vi < physicsBufferVertexCountAfter; vi++) {
			const i = vi * 3;
			physicsBuffer.position[i] += rx;
			physicsBuffer.position[i + 1] += ry;
			physicsBuffer.position[i + 2] += rz;
		}

		const visualBufferVertexCountAfter = ChunkUtil.getVertexCount(visualBuffer);
		for (let vi = visualVertexCountBefore; vi < visualBufferVertexCountAfter; vi++) {
			const i = vi * 3;
			visualBuffer.position[i] += rx;
			visualBuffer.position[i + 1] += ry;
			visualBuffer.position[i + 2] += rz;
		}
	}

	/**
	 * @param {ChunkBuilderVertex} v1
	 * @param {ChunkBuilderVertex} v2
	 * @param {ChunkBuilderVertex} v3
	 * @param {ChunkBuilderVertex} v4
	 * @param {boolean} isSide
	 * @param {number} blockIndex
	 * @param {number} face
	 * @param {ChunkBuilderPhysicsBuffer} physicsBuffer
	 * @param {ChunkBuilderVisualBuffer} visualBuffer
	 * @param {number} sculptSide
	 * @param {number} side
	 * @param {number} visible
	 */
	static buildQuad(
		v1,
		v2,
		v3,
		v4,
		isSide,
		blockIndex,
		face,
		physicsBuffer,
		visualBuffer,
		sculptSide,
		side,
		visible,
	) {
		const L = (visible & BLOCK_VIS_LEFT) === BLOCK_VIS_LEFT;
		const R = (visible & BLOCK_VIS_RIGHT) === BLOCK_VIS_RIGHT;

		if (L && R) {
			const physicsVertexCount = ChunkUtil.getVertexCount(physicsBuffer);
			const visualVertexCount = ChunkUtil.getVertexCount(visualBuffer);

			ChunkBuilder.rotateVertices(sculptSide, v2, v4, v1, v3);

			// physics
			ChunkBuilder.pushPositions(physicsBuffer, v2, v4, v1, v3);

			ChunkBuilder.pushTriangleIndices(
				physicsBuffer,
				physicsVertexCount,
				physicsVertexCount + 1,
				physicsVertexCount + 2,
			);

			ChunkBuilder.pushTriangleIndices(
				physicsBuffer,
				physicsVertexCount + 3,
				physicsVertexCount + 2,
				physicsVertexCount + 1,
			);

			// visuals
			ChunkBuilder.pushPositions(visualBuffer, v2, v4, v1, v3);

			ChunkBuilder.pushVisualAttributes(visualBuffer, sculptSide, side, face, v2, v4, v1, v3);

			ChunkBuilder.pushTriangleIndices(
				visualBuffer,
				visualVertexCount,
				visualVertexCount + 1,
				visualVertexCount + 2,
			);

			ChunkBuilder.pushTriangleIndices(
				visualBuffer,
				visualVertexCount + 3,
				visualVertexCount + 2,
				visualVertexCount + 1,
			);

			ChunkBuilder.pushTriangleBlockIndex(visualBuffer, blockIndex);
			ChunkBuilder.pushTriangleBlockIndex(visualBuffer, blockIndex);

			//the 6 consecutive vi + x patttern is what creates the 0 1 2 3 2 1 mentioned in calculateAO
		} else if (L || R) {
			if (L) {
				ChunkBuilder.buildTri(
					v2,
					v4,
					v1,
					blockIndex,
					face,
					physicsBuffer,
					visualBuffer,
					sculptSide,
					side,
				);
			} else if (isSide) {
				ChunkBuilder.buildTri(
					v4,
					v3,
					v2,
					blockIndex,
					face,
					physicsBuffer,
					visualBuffer,
					sculptSide,
					side,
				);
			} else {
				ChunkBuilder.buildTri(
					v3,
					v1,
					v4,
					blockIndex,
					face,
					physicsBuffer,
					visualBuffer,
					sculptSide,
					side,
				);
			}
		}
	}

	/**
	 * @param {ChunkBuilderVertex} v1
	 * @param {ChunkBuilderVertex} v2
	 * @param {ChunkBuilderVertex} v3
	 * @param {number} blockIndex
	 * @param {number} face
	 * @param {ChunkBuilderPhysicsBuffer} physicsBuffer
	 * @param {ChunkBuilderVisualBuffer} visualBuffer
	 * @param {number} sculptSide
	 * @param {number} side
	 */
	static buildTri(v1, v2, v3, blockIndex, face, physicsBuffer, visualBuffer, sculptSide, side) {
		const physicsVertexCount = ChunkUtil.getVertexCount(physicsBuffer);
		const visualVertexCount = ChunkUtil.getVertexCount(visualBuffer);

		ChunkBuilder.rotateVertices(sculptSide, v1, v2, v3);

		// physics
		ChunkBuilder.pushPositions(physicsBuffer, v1, v2, v3);

		ChunkBuilder.pushTriangleIndices(
			physicsBuffer,
			physicsVertexCount,
			physicsVertexCount + 1,
			physicsVertexCount + 2,
		);

		// visuals
		ChunkBuilder.pushPositions(visualBuffer, v1, v2, v3);

		ChunkBuilder.pushVisualAttributes(visualBuffer, sculptSide, side, face, v1, v2, v3);

		ChunkBuilder.pushTriangleIndices(
			visualBuffer,
			visualVertexCount,
			visualVertexCount + 1,
			visualVertexCount + 2,
			blockIndex,
		);

		ChunkBuilder.pushTriangleBlockIndex(visualBuffer, blockIndex);
	}

	/**
	 * @param {number} sculptSide
	 * @param  {...ChunkBuilderVertex} vertices
	 */
	static rotateVertices(sculptSide, ...vertices) {
		for (const v of vertices) {
			const pos = v.position;
			const nor = v.normal;

			ChunkUtil.rotateVec(sculptSide, pos, true);
			ChunkUtil.rotateVec(sculptSide, nor, false);
		}
	}

	/**
	 * @param {ChunkBuilderVisualBuffer | ChunkBuilderPhysicsBuffer} buffer
	 * @param  {...ChunkBuilderVertex} vertices
	 */
	static pushPositions(buffer, ...vertices) {
		for (const v of vertices) {
			const pos = v.position;

			buffer.position.push(pos.x, pos.y, pos.z);
		}
	}

	/**
	 * @param {ChunkBuilderVisualBuffer} visualBuffer
	 * @param {*} sculptSide
	 * @param {*} side
	 * @param {*} face
	 * @param  {...ChunkBuilderVertex} vertices
	 */
	static pushVisualAttributes(visualBuffer, sculptSide, side, face, ...vertices) {
		for (const v of vertices) {
			const pos = v.position;
			const nor = v.normal;

			visualBuffer.normal.push(nor.x, nor.y, nor.z);

			if (face) {
				let uTex, vTex;
				switch (ChunkUtil.rotateSide(sculptSide, side)) {
					case BLOCK_SNX:
						uTex = pos.z;
						vTex = pos.y;
						break;
					case BLOCK_SPX:
						uTex = 1 - pos.z;
						vTex = pos.y;
						break;
					case BLOCK_SNY:
						uTex = pos.x;
						vTex = pos.z;
						break;
					case BLOCK_SPY:
						uTex = pos.x;
						vTex = 1 - pos.z;
						break;
					case BLOCK_SNZ:
						uTex = 1 - pos.x;
						vTex = pos.y;
						break;
					case BLOCK_SPZ:
						uTex = pos.x;
						vTex = pos.y;
						break;
				}

				visualBuffer.uv.push(face.layout.uv.u1 + uTex * (face.layout.uv.u2 - face.layout.uv.u1));
				visualBuffer.uv.push(face.layout.uv.v1 + vTex * (face.layout.uv.v2 - face.layout.uv.v1));
			}
		}
	}

	/**
	 * @param {ChunkBuilderVisualBuffer} buffer
	 * @param {number} blockIndex
	 */
	static pushTriangleBlockIndex(buffer, blockIndex) {
		buffer.blockIndex.push(blockIndex);
	}

	/**
	 * @param {ChunkBuilderPhysicsBuffer | ChunkBuilderVisualBuffer} buffer
	 * @param {number} i1
	 * @param {number} i2
	 * @param {number} i3
	 */
	static pushTriangleIndices(buffer, i1, i2, i3) {
		buffer.index.push(i1, i2, i3);
	}

	/**
	 * @param {ChunkBuilderVertex} v1
	 * @param {ChunkBuilderVertex} v2
	 * @param {ChunkBuilderVertex} v3
	 */
	static calculateTriNormals(v1, v2, v3) {
		v1.normal
			.copy(v1.position)
			.sub(v2.position)
			.cross(_calculateTriNormalsVec3.copy(v2.position).sub(v3.position))
			.normalize();
		v2.normal.copy(v1.normal);
		v3.normal.copy(v1.normal);
	}

	/**
	 * @param {Vector3} position
	 * @param {Vector3} normal
	 * @returns {ChunkBuilderVertex}
	 */
	static vertex(position, normal) {
		let ret;

		if (position.isVector3) {
			ret = {
				position, // position is always a new vector already; no need to clone
				normal: normal.clone(),
			};
		} else {
			ret = {
				position: position.position.clone(),
				normal: position.normal.clone(),
			};
		}

		return ret;
	}

	//---VISIBILITY CHECKS---//

	// returns a 2-bit bitfield telling which triangles of a shape's side to render/cull
	static visibilityCheck(
		rx,
		ry,
		rz,
		shape,
		type,
		sculptSide,
		side,
		transparencyBehaviour,
		shapes,
		types,
		blockTypes,
		w,
		h,
		d,
		neighborChunks,
	) {
		/**
		 * @type {number}
		 */
		let neighborShape;

		/**
		 * @type {number}
		 */
		let neighborType;

		/**
		 * @type {number}
		 */
		let neighborSide;

		switch (ChunkUtil.rotateSide(sculptSide, side)) {
			case BLOCK_SNX:
				if (rx === 0) {
					const neighborChunk = neighborChunks?.[0];
					neighborShape = neighborChunk?.getShape(w - 1, ry, rz) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(w - 1, ry, rz);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx - 1, ry, rz);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx - 1, ry, rz);
				}

				neighborSide = BLOCK_SPX;
				break;
			case BLOCK_SPX:
				if (rx === w - 1) {
					const neighborChunk = neighborChunks?.[1];
					neighborShape = neighborChunk?.getShape(0, ry, rz) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(0, ry, rz);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx + 1, ry, rz);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx + 1, ry, rz);
				}

				neighborSide = BLOCK_SNX;
				break;
			case BLOCK_SNY:
				if (ry === 0) {
					const neighborChunk = neighborChunks?.[2];
					neighborShape = neighborChunk?.getShape(rx, h - 1, rz) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(rx, h - 1, rz);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx, ry - 1, rz);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx, ry - 1, rz);
				}

				neighborSide = BLOCK_SPY;
				break;
			case BLOCK_SPY:
				if (ry === h - 1) {
					const neighborChunk = neighborChunks?.[3];
					neighborShape = neighborChunk?.getShape(rx, 0, rz) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(rx, 0, rz);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx, ry + 1, rz);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx, ry + 1, rz);
				}

				neighborSide = BLOCK_SNY;
				break;
			case BLOCK_SNZ:
				if (rz === 0) {
					const neighborChunk = neighborChunks?.[4];
					neighborShape = neighborChunk?.getShape(rx, ry, d - 1) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(rx, ry, d - 1);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx, ry, rz - 1);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx, ry, rz - 1);
				}

				neighborSide = BLOCK_SPZ;
				break;
			case BLOCK_SPZ:
				if (rz === d - 1) {
					const neighborChunk = neighborChunks?.[5];
					neighborShape = neighborChunk?.getShape(rx, ry, 0) ?? BLOCK_AIR;
					neighborType = neighborChunk?.getTypeID(rx, ry, 0);
				} else {
					neighborShape = ChunkUtil.getElement(shapes, w, h, d, rx, ry, rz + 1);
					neighborType = ChunkUtil.getElement(types, w, h, d, rx, ry, rz + 1);
				}

				neighborSide = BLOCK_SNZ;
				break;
		}

		const alternateSide = side === BLOCK_SPY ? BLOCK_SNY : side; // see the note for whichTriangles
		const neighborBlockType = blockTypes.get(neighborType);

		const neighborTransparencyBehaviour =
			neighborBlockType?.transparencyBehaviour ?? BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED;

		const neighborCollision = neighborBlockType?.collision ?? BLOCK_COLLISION_ENABLED;

		if (
			(neighborTransparencyBehaviour === BLOCK_TRANSPARENCY_BEHAVIOUR_JOIN &&
				(transparencyBehaviour === BLOCK_TRANSPARENCY_BEHAVIOUR_DISABLED || type !== neighborType)) ||
			neighborTransparencyBehaviour === BLOCK_TRANSPARENCY_BEHAVIOUR_SINGLE ||
			(neighborCollision === BLOCK_COLLISION_DISABLED && type !== neighborType)
		) {
			return ChunkBuilder.whichTriangles(shape, alternateSide);
		}

		neighborSide = ChunkUtil.unrotateSide(neighborShape & BLOCK_MASK_SIDE, neighborSide);

		let tris = ChunkBuilder.whichTriangles(shape, side);

		const neighborTris = ChunkBuilder.whichTriangles(neighborShape, neighborSide);

		// cull: neighbor side is a full square and (this side is either a full square or (a triangle and is not the BLOCK_SPY side))
		const minTris = side === BLOCK_SPY ? BLOCK_VIS_BOTH : BLOCK_VIS_NONE;
		let visible = tris >= minTris && neighborTris === BLOCK_VIS_BOTH ? BLOCK_VIS_NONE : BLOCK_VIS_BOTH;

		// cull: ramps whose triangle sides are touching each other
		if (visible && ChunkBuilder.areRampSidesJoined(shape, side, neighborShape)) {
			visible = BLOCK_VIS_NONE;
		}

		// see the note for whichTriangles
		if (side === BLOCK_SPY) {
			tris = ChunkBuilder.whichTriangles(shape, alternateSide);
		}

		return tris & visible;
	}

	//returns a 2-bit bitfield telling which triangles of a shape's side are normally visible, assuming there are no surrounding blocks
	//note the triangles of side BLOCK_SPY only return visible if all 3 corners are up. if you need to check if the sloped portion is visible, pass in side BLOCK_SNY instead
	//side is expected to be unrotated
	static whichTriangles(shape, side) {
		if (shape === BLOCK_AIR) return BLOCK_VIS_NONE;
		if (shape === BLOCK_CUBE) return BLOCK_VIS_BOTH;

		let ret = BLOCK_VIS_NONE;

		const TL = (shape & BLOCK_TL) === BLOCK_TL;
		const BL = (shape & BLOCK_BL) === BLOCK_BL;
		const TR = (shape & BLOCK_TR) === BLOCK_TR;
		const BR = (shape & BLOCK_BR) === BLOCK_BR;

		switch (side) {
			case BLOCK_SNX:
				if (TL) ret |= BLOCK_VIS_LEFT;
				if (BL) ret |= BLOCK_VIS_RIGHT;
				break;
			case BLOCK_SPX:
				if (BR) ret |= BLOCK_VIS_LEFT;
				if (TR) ret |= BLOCK_VIS_RIGHT;
				break;
			case BLOCK_SNZ:
				if (TR) ret |= BLOCK_VIS_LEFT;
				if (TL) ret |= BLOCK_VIS_RIGHT;
				break;
			case BLOCK_SPZ:
				if (BL) ret |= BLOCK_VIS_LEFT;
				if (BR) ret |= BLOCK_VIS_RIGHT;
				break;
			case BLOCK_SNY:
				if (ChunkUtil.flipDiagonal(shape)) {
					if (BL || BR || TL) ret |= BLOCK_VIS_LEFT;
					if (TR || TL || BR) ret |= BLOCK_VIS_RIGHT;
				} else {
					if (TL || BL || TR) ret |= BLOCK_VIS_LEFT;
					if (BR || TR || BL) ret |= BLOCK_VIS_RIGHT;
				}
				break;
			case BLOCK_SPY:
				if ((shape & BLOCK_MASK_INVERT) === 0) {
					if (ChunkUtil.flipDiagonal(shape)) {
						if (BL && BR && TL) ret |= BLOCK_VIS_LEFT;
						if (TR && TL && BR) ret |= BLOCK_VIS_RIGHT;
					} else {
						if (TL && BL && TR) ret |= BLOCK_VIS_LEFT;
						if (BR && TR && BL) ret |= BLOCK_VIS_RIGHT;
					}
				}
				break;
		}

		return ret;
	}

	//side is expected to be unrotated
	static areRampSidesJoined(shape, side, neighbor) {
		return (
			(shape & BLOCK_MASK_SIDE) === (neighbor & BLOCK_MASK_SIDE) &&
			((side === BLOCK_SNX &&
				(((shape & BLOCK_TL) === BLOCK_TL &&
					(shape & BLOCK_BL) === 0 &&
					(neighbor & BLOCK_TR) === BLOCK_TR &&
					(neighbor & BLOCK_BR) === 0) ||
					((shape & BLOCK_BL) === BLOCK_BL &&
						(shape & BLOCK_TL) === 0 &&
						(neighbor & BLOCK_BR) === BLOCK_BR &&
						(neighbor & BLOCK_TR) === 0))) ||
				(side === BLOCK_SPX &&
					(((shape & BLOCK_TR) === BLOCK_TR &&
						(shape & BLOCK_BR) === 0 &&
						(neighbor & BLOCK_TL) === BLOCK_TL &&
						(neighbor & BLOCK_BL) === 0) ||
						((shape & BLOCK_BR) === BLOCK_BR &&
							(shape & BLOCK_TR) === 0 &&
							(neighbor & BLOCK_BL) === BLOCK_BL &&
							(neighbor & BLOCK_TL) === 0))) ||
				(side === BLOCK_SNZ &&
					(((shape & BLOCK_TR) === BLOCK_TR &&
						(shape & BLOCK_TL) === 0 &&
						(neighbor & BLOCK_BR) === BLOCK_BR &&
						(neighbor & BLOCK_BL) === 0) ||
						((shape & BLOCK_TL) === BLOCK_TL &&
							(shape & BLOCK_TR) === 0 &&
							(neighbor & BLOCK_BL) === BLOCK_BL &&
							(neighbor & BLOCK_BR) === 0))) ||
				(side === BLOCK_SPZ &&
					(((shape & BLOCK_BR) === BLOCK_BR &&
						(shape & BLOCK_BL) === 0 &&
						(neighbor & BLOCK_TR) === BLOCK_TR &&
						(neighbor & BLOCK_TL) === 0) ||
						((shape & BLOCK_BL) === BLOCK_BL &&
							(shape & BLOCK_BR) === 0 &&
							(neighbor & BLOCK_TL) === BLOCK_TL &&
							(neighbor & BLOCK_TR) === 0))))
		);
	}

	//side is expected to be rotated
	static isSideFullyFlat(shape, side) {
		return (
			ChunkBuilder.whichTriangles(shape, ChunkUtil.unrotateSide(shape & BLOCK_MASK_SIDE, side)) ===
			BLOCK_VIS_BOTH
		);
	}

	//side is expected to be rotated
	//also returns true on fully flat
	static isSidePartiallyFlat(shape, side) {
		return (
			ChunkBuilder.whichTriangles(shape, ChunkUtil.unrotateSide(shape & BLOCK_MASK_SIDE, side)) >
			BLOCK_VIS_NONE
		);
	}
}
