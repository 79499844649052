import type { ProphecyComponent } from "base/world/entity/component/Prophecy";
import type { Entity } from "base/world/entity/Entity";
import { WorldEditorStatus } from "../../WorldEditor";

export function prophecyUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const world = entity.world;

	const isProphecy =
		world.editor.status === WorldEditorStatus.EDITING &&
		entity.sceneTree?.state.sceneTreeNode !== undefined;

	entity.prophecy.state.isProphecy = isProphecy;
}

function canUpdateSystem(entity: Entity): entity is Entity & { prophecy: ProphecyComponent } {
	return !!entity.prophecy;
}
