import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";

export class LocomotionStateCrouchEnd extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("CrouchEnd", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToJoggingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToDeathTime = 0.2;
	}

	get crouchEndAction() {
		return this.mesh.getAction("JMGO_Unarmed_Crouch_Exit");
	}

	startCompute(transitionData) {
		this.crouchEndAction.reset();
		this.crouchEndAction.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.crouchEndAction.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.crouchEndAction.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.crouchEndAction.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (
			this.crouchEndAction.getRemainingTime() < this.transitionToIdleTime &&
			!this.inputState.isMoving
		) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Running");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("Jogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
			}
			return true;
		}

		return false;
	}
}
