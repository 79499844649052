import type { Chunk } from "base/world/block/chunk/Chunk";
import { BLOCK_CUBE } from "base/world/block/Util";
import type { BlockID, ITerrain } from "./types";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";

const BLANK_REVISIONS = [0, 1] as const;

export class GeneratorBlankTerrain implements ITerrain {
	static name = "bb.generator.terrain.blank" as const;
	static display = "Blank Terrain" as const;
	isTerrain = true as const;

	private revision: (typeof BLANK_REVISIONS)[number];
	private height: number;

	private blockStoneName: string;
	private blockStone: BlockID = 0;

	private chunkSize = 0;

	/**
	 * OPT Number revision [1] - Choose which version of the generator to use
	 *
	 * 1 (6bde0c5 changed to blank gray instead of world blocks)
	 * OPT Number height [0] - Height of the terrain
	 * OPT Integer stoney [-10] - Depth of stone below dirt, AKA number of dirt layers (excluding the top grass layer)
	 * OPT String block ["block#D0897CCFDA86436FB32A0F5ABCCB9D52"]
	 */
	constructor(o: { revision: 1; height?: number; blockStone?: string }) {
		this.revision = o?.revision ?? 1;

		if (!BLANK_REVISIONS.includes(this.revision))
			throw Error(`Unknown generator revision "${this.revision}"`);

		this.height = o?.height ?? 0;
		this.blockStoneName = o?.blockStone ?? "block#D0897CCFDA86436FB32A0F5ABCCB9D52";
	}

	init({
		chunkSize,
		blockTypeRegistry,
	}: {
		seed: number;
		blockTypeRegistry: BlockTypeRegistryState;
		chunkSize: number;
	}) {
		this.chunkSize = chunkSize;

		const blockNameToType = blockTypeRegistry.blockNameToType;
		this.blockStone = blockNameToType.get(this.blockStoneName)?.id ?? 0;
	}

	apply(chunk: Chunk) {
		const chunkSize = this.chunkSize;
		const shapes = chunk.storage.shapes;
		const types = chunk.storage.types;
		const cy = chunk.position.y;
		const tory = -cy * chunkSize;

		let rz = 0;
		let rx = 0;

		//single block spit out by the height map
		const buildLayer = (start: number, end: number, type: BlockID) => {
			for (let ry = start; ry < end; ry++) {
				const i = chunk.getIndex(rx, ry, rz);
				shapes[i] = BLOCK_CUBE;
				types[i] = type;
			}
		};

		let columnStart = 0;
		let columnEnd = 0;

		for (rz = 0; rz < chunkSize; rz++) {
			for (rx = 0; rx < chunkSize; rx++) {
				columnStart = 0;
				columnEnd = Math.min(this.getHeight() + tory, chunkSize);
				buildLayer(columnStart, columnEnd, this.blockStone);
			}
		}
	}

	getHeight() {
		return this.height;
	}
}
