import { NODE_TYPE_ID } from "base/rete/Constants";
import { DEGRAD, VY } from "base/util/math/Math.ts";
import { PlayerAI } from "mods/defs/CharacterDefault/Character";
import { Quaternion } from "three";
import { node } from "base/rete/Types";
import type { InterpreterContext, InterpreterExecInput } from "base/rete/Types";
import type { World } from "base/world/World";
import type { PhysicalEntity } from "base/world/entity/PhysicalEntity";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("Spawner");

const _quaternion = new Quaternion();

export const Spawner = [
	node({
		id: "0002-02-0001",
		name: "Spawn Entity",
		type: NODE_TYPE_ID.function.world,
		description: "Creates an entity and adds it to the scene",
		keywords: ["spawn", "create", "entity"],
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) =>
						ctx.world.getSpawnableDefs().map((def) => ({
							label: def,
							value: def,
						})),
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			executeCreateEntity(this.name, false, inputs, ctx, nodeId, scope);
		},
		resolve(_inputs, ctx, nodeID, scope) {
			// TODO: dek this can be undefined
			return { entity: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
	node({
		id: "0100-00-0001",
		name: "Spawn Character",
		type: NODE_TYPE_ID.function.npc,
		description: "Creates a NPC character and adds it to the scene.",
		keywords: ["spawn", "create", "npc", "character"],
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) => {
						return (ctx.world.getNPCDefs() ?? []).map((character) => {
							return {
								value: character.id,
								label: character.name,
							};
						});
					},
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			npc: { name: "NPC", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			executeCreateEntity(this.name, true, inputs, ctx, nodeId, scope);
		},
		resolve(_inputs, ctx, nodeID, scope) {
			// TODO: dek this can be undefined
			return { npc: ctx.world.getEntity(scope[nodeID])! };
		},
	}),
];

export function executeCreateEntity(
	nodeName: string,
	npcOnly: boolean,
	inputs: InterpreterExecInput,
	ctx: InterpreterContext,
	nodeId: string,
	scope: Record<string, any>,
) {
	const inputName = inputs.name;

	if (!inputName) return;

	const inputPos = inputs.position;
	const world = ctx.world;

	// set position of entity so it spawns centered above the block using entity height
	const x = (inputPos?.x ?? 0) + 0.5;
	const y = (inputPos?.y ?? 0) + 1;
	const z = (inputPos?.z ?? 0) + 0.5;
	const rot = (inputs.rotation ?? 0) * DEGRAD;

	//retrieve
	let def, defCreateName;

	if (npcOnly) {
		def = world.defs.values().find(function (def) {
			const character = def.meta;
			return (
				def.isNPC &&
				character &&
				(character.name === inputName ||
					character.defName === inputName ||
					character.id === inputName)
			);
		});

		defCreateName = def?.name;
	} else {
		// TODO: wtf?
		let defMapName = inputName;
		if (defMapName?.startsWith("ArmoryItem")) defMapName = defMapName.replace("ArmoryItem", "Item");

		def = world.defs.get(defMapName);
		defCreateName = inputName;
	}

	// validate
	if (def === undefined) {
		if (npcOnly) {
			def = world.defs.get(PlayerAI.name)!;
			defCreateName = def.name;

			logger.warn(
				`[${nodeName}] Entity def "${inputName}" not found. Falling back to the default NPC.`,
			);
		} else {
			throw Error(`[${nodeName}] Entity def "${inputName}" not found.`);
		}
	} else if (inputName.startsWith("Player") && !def.isNPC) {
		throw Error(`[${nodeName}] Entity def "${inputName}" not allowed.`);
	}

	//spawn
	const quaternion = _quaternion.setFromAxisAngle(VY, rot);
	const entity = world.router.createEntity({
		def: defCreateName!,
		x,
		y,
		z,
		qx: quaternion.x,
		qy: quaternion.y,
		qz: quaternion.z,
		qw: quaternion.w,
	}) as PhysicalEntity;

	if (entity.type.def.isNPC) {
		entity.nameplate.def.nameplate.setText(def.characterName);
		entity.nameplate.state.show = Boolean(def.meta?.showNametag);
	}

	// stores the most recent NPC id in the node state, this is what is returned upon resolve
	scope[nodeId] = entity.entityID;
}
