import { isNullish } from "@jamango/helpers";
import type { Object3D } from "three";
import { Vector2 } from "three";
import type { Entity } from "base/world/entity/Entity";

export const LOCOMOTION_MOVEMENTINPUT_IDLE = 0;
export const LOCOMOTION_MOVEMENTINPUT_LEFT = 1;
export const LOCOMOTION_MOVEMENTINPUT_RIGHT = 2;
export const LOCOMOTION_MOVEMENTINPUT_DOWN = 3;
export const LOCOMOTION_MOVEMENTINPUT_UP = 4;
export const LOCOMOTION_MOVEMENTINPUT_FORWARD = 5;
export const LOCOMOTION_MOVEMENTINPUT_BACKWARD = 6;

export class CharacterLocomotionInputComponent {
	name = "locomotionInput";

	def: {
		rigName: string | undefined;
		rootBone: Object3D | undefined;
		neckBone: Object3D | undefined;
		pelvisBone: Object3D | undefined;
		spineBone01: Object3D | undefined;
		spineBone02: Object3D | undefined;
		fpLeftArmBone: Object3D | undefined;
		fpRightArmBone: Object3D | undefined;
		tpLeftArmBone: Object3D | undefined;
		tpRightArmBone: Object3D | undefined;
		rootLerp: number;
	};

	state: {
		isFalling: boolean;
		isJumping: boolean;
		isFlying: boolean;
		isAiming: boolean;
		isReloading: boolean;
		isCrouching: boolean;
		isDead: boolean;
		isSprinting: boolean;
		isMoving: boolean;
		isEmoteLooping: boolean;
		isSwappingTool: boolean;
		movementInput: number;
		cameraRotation: number;
		speed: number;
		meshRotation: number;
		item: string | null;
		emote: string | null;
		rotation: number;
		rotationTheta: number;
		omnidirectionalAngle: number;
		rootRotation: number;
		spine01Rotation: number;
		spine02Rotation: number;
		decalHipRotation: Vector2;
		decalHipRotationTarget: Vector2;
		beforePPLeftArmBoneX: number | null;
		beforePPRightArmBoneX: number | null;
		beforePPPelvisBoneY: number | null;
		swappingToolAnimationProgress: number;
		leftArmBoneX?: number;
		rightArmBoneX?: number;
		pelvisBoneY?: number;
		leftArmBoneY?: number;
		rightArmBoneZ?: number;
		rightArmBoneY?: number;
		leftArmBoneZ?: number;
		rotationNeck: number;
		rotationNeckTheta: number;
		rotationLeftArm: number;
		rotationRightArm: number;
		rotationPelvis: number;
		rotationThetaPelvis: number;
		rotateToCamera: boolean;
	};

	constructor(o: Record<string, any>, entity: Entity, angle: number) {
		const object3D = entity.object3D;

		this.def = {
			rigName: o?.rigName,
			rootBone: isNullish(o?.rootBone) ? undefined : object3D.getObjectByName(o.rootBone),
			neckBone: isNullish(o?.neckBone) ? undefined : entity.mesh?.mesh.getObjectByName(o.neckBone),
			pelvisBone: isNullish(o?.pelvisBone) ? undefined : object3D.getObjectByName(o.pelvisBone),
			spineBone01: isNullish(o?.spineBone01) ? undefined : object3D.getObjectByName(o.spineBone01),
			spineBone02: isNullish(o?.spineBone02) ? undefined : object3D.getObjectByName(o.spineBone02),
			fpLeftArmBone: isNullish(o?.leftArmBone)
				? undefined
				: entity.fpmesh?.mesh.getObjectByName(o.leftArmBone),
			fpRightArmBone: isNullish(o?.rightArmBone)
				? undefined
				: entity.fpmesh?.mesh.getObjectByName(o.rightArmBone),
			tpLeftArmBone: isNullish(o?.leftArmBone)
				? undefined
				: entity.mesh?.mesh.getObjectByName(o.leftArmBone),
			tpRightArmBone: isNullish(o?.rightArmBone)
				? undefined
				: entity.mesh?.mesh.getObjectByName(o.rightArmBone),
			rootLerp: o?.rootLerp ?? 10,
		};

		this.state = {
			//bools
			isFalling: false,
			isJumping: false,
			isFlying: false,
			isAiming: false,
			isReloading: false,
			isCrouching: false, //redundant over wire
			isDead: false, //redundant over wire
			isSprinting: false, //redundant over wire
			isMoving: false, //redundant over wire
			isEmoteLooping: false, //never send this
			isSwappingTool: false, //never send this

			//not bools
			movementInput: LOCOMOTION_MOVEMENTINPUT_IDLE, //int
			cameraRotation: angle, //angle, round degree
			speed: 0, //float, round hundreth
			meshRotation: angle, //angle, redundant over wire
			item: null, //string, redundant over wire
			emote: null, //string, never send this

			//used by visual tweaks only
			rotation: 0, //angle, round degree
			rotationTheta: 0, //angle, round degree
			omnidirectionalAngle: 0, //angle, round degree

			// never send the below
			rootRotation: 0, //this is a lerp of omnidirectional angle, never send this
			rotationThetaPelvis: 0,
			spine01Rotation: 0,
			spine02Rotation: 0,
			decalHipRotation: new Vector2(),
			decalHipRotationTarget: new Vector2(),
			rotationNeck: 0,
			rotationNeckTheta: 0,
			rotationLeftArm: 0,
			rotationRightArm: 0,
			rotationPelvis: 0,
			swappingToolAnimationProgress: 0,

			beforePPLeftArmBoneX: null,
			beforePPRightArmBoneX: null,
			beforePPPelvisBoneY: null,

			//this is special in that locomotion states write to this rather than characterLocomotionInputStateUpdate
			rotateToCamera: false, //bool, never send this
		};
	}
}
