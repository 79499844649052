import { netState } from "router/Parallelogram";

import * as ConstraintsManager from "base/world/ConstraintManager";
import * as Physics from "base/world/Physics";
import { animationMixerUpdate } from "base/world/entity/system/AnimationMixer.js";
import { characterAnimationPPUpdate } from "base/world/entity/system/CharacterAnimationPP.js";
import { characterAnimationPPResetUpdate } from "base/world/entity/system/CharacterAnimationPPReset.js";
import { characterAnyCollisionUpdate } from "base/world/entity/system/CharacterAnyCollision";
import { characterBlockCollisionUpdate } from "base/world/entity/system/CharacterBlockCollision";
import { characterCameraUpdate } from "base/world/entity/system/CharacterCameraUpdate.js";
import { characterFootstepEffectsUpdate } from "base/world/entity/system/CharacterFootstepEffectsUpdate.js";
import { characterHealthRegenUpdate } from "base/world/entity/system/CharacterHealthRegen.js";
import { characterHoldUpdate } from "base/world/entity/system/CharacterHold";
import { characterLocomotionInputStateUpdate } from "base/world/entity/system/CharacterLocomotionInputState.js";
import { characterLocomotionStateMachineUpdate } from "base/world/entity/system/CharacterLocomotionStateMachine.js";
import { characterPhysicsAfterStepUpdate } from "base/world/entity/system/CharacterMovementAfterPhysicsStep";
import { characterMovementBobUpdate } from "base/world/entity/system/CharacterMovementBob";
import { characterMovementControllerUpdate } from "base/world/entity/system/CharacterMovementController";
import { characterMovementInitUpdate } from "base/world/entity/system/CharacterMovementInit.js";
import { characterMovementVelocityUpdate } from "base/world/entity/system/CharacterMovementVelocity.js";
import { characterOrientationUpdate } from "base/world/entity/system/CharacterOrientation.js";
import { characterSelectorUpdate } from "base/world/entity/system/CharacterSelector";
import { characterSurfaceMovementUpdate } from "base/world/entity/system/CharacterSurfaceMovement";
import { characterViewRaycast } from "base/world/entity/system/CharacterViewRaycast.js";
import { characterVisualTweaksUpdate } from "base/world/entity/system/CharacterVisualTweaks.js";
import { despawnUpdate } from "base/world/entity/system/Despawn.js";
import { entityMountedTransformUpdate } from "base/world/entity/system/EntityMountedTransform";
import { entityPreviousTransformUpdate } from "base/world/entity/system/EntityPreviousTransform";
import { itemCooldownUpdate } from "base/world/entity/system/ItemCooldown.js";
import { itemFireUpdate } from "base/world/entity/system/ItemFire.js";
import { itemMeshUpdate } from "base/world/entity/system/ItemMesh";
import { itemPhysicsAfterStepUpdate } from "base/world/entity/system/ItemPhysicsAfterStep";
import { itemPhysicsBeforeStepUpdate } from "base/world/entity/system/ItemPhysicsBeforeStep";
import { itemUIUpdate } from "base/world/entity/system/ItemUI.js";
import { npcOcclusionUpdate } from "base/world/entity/system/NPCOcclusion.js";
import { nameplateUpdate } from "base/world/entity/system/Nameplate.js";
import { particleSystemUpdate } from "base/world/entity/system/ParticleSystem";
import { physicsEntityCollisionsUpdate } from "base/world/entity/system/PhysicsEntityCollisions";
import { playerButtonsUpdate } from "base/world/entity/system/PlayerButtons";
import { playerMovementInputUpdate } from "base/world/entity/system/PlayerMovementInput";
import { playerWeaponSpreadUpdate } from "base/world/entity/system/PlayerWeaponSpread.js";
import { propMeshUpdate } from "base/world/entity/system/PropMesh";
import { propPhysicsAfterStepUpdate } from "base/world/entity/system/PropPhysicsAfterStep";
import { propPhysicsBeforeStepUpdate } from "base/world/entity/system/PropPhysicsBeforeStep";
import { prophecyUpdate } from "base/world/entity/system/Prophecy";
import { proximityUpdate } from "base/world/entity/system/Proximity.js";
import { proximityPromisesUpdate } from "base/world/entity/system/ProximityPromises.js";
import { skipUpdateStatusUpdate } from "base/world/entity/system/SkipUpdateStatus.js";
import { text3DUpdate } from "base/world/entity/system/Text3D";

function physicsReset(world, _stepDeltaTime) {
	Physics.resetContacts(world.physics);
}

function constraintsUpdate(world, _stepDeltaTime) {
	ConstraintsManager.update(world.constraints, world);
}

function physicsUpdate(world, stepDeltaTime) {
	Physics.update(world.physics, stepDeltaTime, world.editor.paused);
}

function cameraPostUpdate(world, stepDeltaTime) {
	if (netState.isClient) world.client.camera.postUpdate(stepDeltaTime);
}

export const fixedUpdatePipeline = [
	{ f: prophecyUpdate },

	{ f: entityPreviousTransformUpdate },

	{ f: physicsReset, once: true },

	{ f: characterOrientationUpdate },
	{ f: characterMovementInitUpdate },
	{ f: playerMovementInputUpdate },

	{ f: characterMovementVelocityUpdate },
	{ f: characterMovementControllerUpdate },

	{ f: itemPhysicsBeforeStepUpdate },
	{ f: propPhysicsBeforeStepUpdate },
	{ f: constraintsUpdate, once: true },
	{ f: physicsUpdate, once: true },
	{ f: itemPhysicsAfterStepUpdate },
	{ f: propPhysicsAfterStepUpdate },
	{ f: characterPhysicsAfterStepUpdate },

	{ f: characterBlockCollisionUpdate },
	{ f: characterSurfaceMovementUpdate },
	{ f: characterAnyCollisionUpdate },

	{ f: physicsEntityCollisionsUpdate, once: true },

	{ f: characterViewRaycast },
	{ f: characterSelectorUpdate },

	{ f: characterHoldUpdate },

	{ f: playerButtonsUpdate },

	{ f: itemFireUpdate },
];

export const deltaUpdatePipeline = [
	// TODO: revisit this "localPlayer" option
	{ f: characterViewRaycast, localPlayer: true },
	{ f: characterSelectorUpdate, localPlayer: true },
	{ f: characterHoldUpdate, localPlayer: true },

	{ f: entityMountedTransformUpdate },

	{ f: characterHealthRegenUpdate },
	{ f: playerWeaponSpreadUpdate },
	{ f: itemCooldownUpdate },

	{ f: despawnUpdate },

	{ f: proximityUpdate },
	{ f: proximityPromisesUpdate },

	{ f: characterLocomotionInputStateUpdate },

	{ f: characterVisualTweaksUpdate },
	{ f: characterMovementBobUpdate },
	{ f: characterCameraUpdate },
	{ f: characterFootstepEffectsUpdate },

	{ f: cameraPostUpdate, once: true },

	{ f: itemUIUpdate },

	{ f: skipUpdateStatusUpdate },
	{ f: characterLocomotionStateMachineUpdate },
	{ f: characterAnimationPPResetUpdate },
	{ f: animationMixerUpdate },
	{ f: characterAnimationPPUpdate },
	{ f: nameplateUpdate },
	{ f: npcOcclusionUpdate },
	{ f: text3DUpdate },
	{ f: particleSystemUpdate },
	{ f: propMeshUpdate },
	{ f: itemMeshUpdate },
];

// note: for now we simply pass entities their own input and cmd objects
// in the future we may tick on different inputs, which is why this structure exists.
export const runUpdatePipeline = (world, pipeline, dt) => {
	for (const { f, once, localPlayer } of pipeline) {
		if (once) {
			f(world, dt);
			continue;
		}

		if (localPlayer) {
			if (netState.isClient) {
				const player = world.client.getPeerPlayer();
				f(player, dt, player.input, player.cmd);
			}

			continue;
		}

		for (const e of world.entities) {
			f(e, dt, e.input, e.cmd);
		}
	}
};
