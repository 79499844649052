import { doWithRetries, createLogger } from "@jamango/helpers";
import type { ReteState } from "base/rete/Types";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { Peer } from "@jamango/ibs";
import type { EnginePersistentDataImpl } from "@jamango/engine/PersistentData.ts";

const logger = createLogger("PersistentDataServer");

let persistentDataImpl: EnginePersistentDataImpl | undefined = undefined;

export type PersistentDataServerState = {
	lastWriteTime: number;
	writeJobInProgress: boolean;
	previousAccountStates: ReteState["state"]["peerPersistent"];
};

export const init = (): PersistentDataServerState => ({
	lastWriteTime: 0,
	writeJobInProgress: false,
	previousAccountStates: {},
});

export const setPersistentDataImpl = (impl: EnginePersistentDataImpl) => {
	persistentDataImpl = impl;
};

const syncPeer = async (
	reteState: ReteState,
	persistentDataServerState: PersistentDataServerState,
	worldId: string,
	peer: Peer,
) => {
	if (!persistentDataImpl) return;

	const metadata = getPeerMetadata(peer);
	if (!metadata) {
		logger.error(`Failed to get metadata for peer [${peer.peerID}]`);
		return;
	}

	const { accountID, username, isGuest } = metadata;

	if (isGuest) {
		logger.debug(
			`Skipping writing persistent data for guest accountID [${accountID}], username [${username}]`,
		);
		return;
	}

	const latest = reteState.state.peerPersistent[accountID];
	const previous = persistentDataServerState.previousAccountStates[accountID];

	if (!latest) {
		logger.error(
			`Failed to sync persistent data for accountID [${accountID}], username [${username}], missing accountPersistent state entry`,
		);
		return;
	}

	if (JSON.stringify(latest) === JSON.stringify(previous)) {
		logger.debug(
			`Skipping writing persistent data for accountID [${accountID}], username [${username}], no changes between latest and previous`,
		);
		return;
	}

	const result = await doWithRetries(() =>
		persistentDataImpl!.setWorldAccountPersistentData(worldId, accountID, latest),
	);

	if (result.ok) {
		logger.debug(
			`Successfully synced persistent data for accountID [${accountID}], username [${username}]`,
			{
				data: latest,
			},
		);

		persistentDataServerState.previousAccountStates[accountID] = structuredClone(latest);
	} else {
		logger.error(
			`Failed to sync persistent data for accountID [${accountID}], username [${username}]`,
			result.error,
		);
	}
};

export const onPeerJoin = async (
	reteState: ReteState,
	persistentDataServerState: PersistentDataServerState,
	worldId: string,
	peer: Peer,
) => {
	const { accountID, username, isGuest } = getPeerMetadata(peer);

	if (isGuest || !persistentDataImpl) {
		logger.debug(
			`Skipping persistent data retrieval for guest accountID [${accountID}], username [${username}], initialized with empty object`,
		);

		reteState.state.peerPersistent[accountID] = {};

		return;
	}

	const result = await doWithRetries(() =>
		persistentDataImpl!.getWorldAccountPersistentData(worldId, accountID),
	);

	if (result.ok) {
		reteState.state.peerPersistent[accountID] = result.data.value ?? {};

		persistentDataServerState.previousAccountStates[accountID] = structuredClone(
			reteState.state.peerPersistent[accountID],
		);

		logger.debug(
			`Successfully retrieved persistent data for accountID [${accountID}], username [${username}]`,
			{
				data: reteState.state.peerPersistent[accountID],
			},
		);
	} else {
		// TODO: how should we handle this?
		// We don't want to let players join and overwrite their accumulated progress in cases where this fails.
		throw new Error(
			`Failed to get persistent data for accountID [${accountID}], username [${username}]`,
			{
				cause: result.error,
			},
		);
	}
};

export const onPeerLeave = async (
	reteState: ReteState,
	persistentDataServerState: PersistentDataServerState,
	worldId: string,
	peer: Peer,
) => {
	const { accountID, username } = getPeerMetadata(peer);

	logger.debug(
		`Peer is leaving, checking if persistent data needs writing for accountID [${accountID}], username [${username}]`,
	);

	await syncPeer(reteState, persistentDataServerState, worldId, peer);

	delete reteState.state.peerPersistent[accountID];
};

const WRITE_INTERVAL_MS = 1000 * 10; // 10 seconds

export const updateBackgroundJobs = async (
	reteState: ReteState,
	persistentDataServerState: PersistentDataServerState,
	worldId: string,
	peers: Peer[],
) => {
	if (!persistentDataImpl) return;

	if (
		persistentDataServerState.writeJobInProgress ||
		persistentDataServerState.lastWriteTime + WRITE_INTERVAL_MS > Date.now()
	) {
		return;
	}

	if (peers.length > 0) logger.debug(`Syncing persistent data for [${peers.length}] peers`);

	persistentDataServerState.writeJobInProgress = true;

	try {
		for (const peer of peers) {
			await syncPeer(reteState, persistentDataServerState, worldId, peer);
		}
		persistentDataServerState.lastWriteTime = Date.now();
	} finally {
		persistentDataServerState.writeJobInProgress = false;
	}
};
