import { defMesh } from "client/util/Defs.js";
import { AnimationMixer, LoopOnce } from "three";
import { isNullish } from "@jamango/helpers";
import { defTransform } from "base/util/Defs";

export const ACTION_PURPOSE_LOCOMOTION = 0;
export const ACTION_PURPOSE_BASIC = 1;
export const ACTION_PURPOSE_EMOTE = 2;

//takes the same arguments as a mesh def but with extra utilities for animation
export class AnimatableMesh {
	/** @type {import("three").Object3D} */
	mesh;

	constructor(o, assets) {
		this.mesh = defMesh(o, assets);
		this.transform = structuredClone(o?.transform ?? null);
		this.totalTime = 0;

		if (o?.animated) {
			this.mixer = new AnimationMixer(this.mesh);
			this.clips = {};

			//clipping an action is expensive. only do it when an action is requested to play
			this.actionCache = new Map([
				[ACTION_PURPOSE_LOCOMOTION, new Map()],
				[ACTION_PURPOSE_BASIC, new Map()],
				[ACTION_PURPOSE_EMOTE, new Map()],
			]);

			const sources = isNullish(o?.animationSources) ? [] : o.animationSources.slice();
			sources.unshift(o.asset);

			for (const sourceName of sources) {
				const asset = assets.get(sourceName);
				for (const clip of asset.animations) this.clips[clip.name] = clip;
			}
		}
	}

	getAction(name, purpose = ACTION_PURPOSE_LOCOMOTION) {
		const purposeMap = this.actionCache.get(purpose);
		let action = purposeMap.get(name);

		if (!action) {
			let clip = this.clips[name];
			if (!clip) throw Error(`Missing AnimationClip: ${name}`);
			if (purpose !== ACTION_PURPOSE_LOCOMOTION) clip = clip.clone(); //locomotion is the most common action purpose so we'll give it access to the raw uncloned clips for perf

			purposeMap.set(name, (action = this.mixer.clipAction(clip)));
		}

		return action;
	}

	playBasicAnimation(actionName) {
		this.getAction(actionName, ACTION_PURPOSE_BASIC).setLoop(LoopOnce).reset().play();
	}

	update(deltaTime) {
		this.totalTime += deltaTime;
		this.mixer?.update(deltaTime);
	}

	toggleTransform(toggle, updateMatrix = true) {
		defTransform(toggle ? this.transform : null, this.mesh);
		if (updateMatrix) this.mesh.updateMatrix();
	}

	disableFrustumCulling() {
		this.mesh.traverse((node) => (node.frustumCulled = false));
	}

	dispose() {
		this.mesh.removeFromParent();
		this.mesh.traverse(function (node) {
			if (node.isSkinnedMesh) node.skeleton.dispose();
		});
	}
}
