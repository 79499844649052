import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import {
	addSelectCharacterSceneTreeNode,
	addSelectPropSceneTreeNode,
	NODE_THIS_SCENE_TREE_NODE,
} from "base/rete/NodeSharedUtil";

export const SCENE_TREE_NODES = [
	node({
		id: "d160e19f-a9db-40a6-b33e-e400c8e81fa2",
		name: "Get Prop",
		type: NODE_TYPE_ID.function.entity,
		description: "Returns a prop entity for a prop scene tree node.",
		controls: {
			prop: addSelectPropSceneTreeNode("Prop"),
		},
		outputs: {
			entity: {
				name: "Prop",
				type: "entity",
				optional: true,
			},
		},
		resolve(inputs, ctx) {
			let sceneTreeNode: string | undefined = inputs.prop;

			if (inputs.prop === NODE_THIS_SCENE_TREE_NODE) {
				if (ctx.entryPoint.type === "propSceneTreeNode") {
					sceneTreeNode = ctx.entryPoint.targetId;
				}
			} else {
				sceneTreeNode = inputs.prop;
			}

			const entity =
				sceneTreeNode !== undefined ? ctx.world.sceneTreeNodeToEntity.get(sceneTreeNode) : undefined;

			return {
				entity,
			};
		},
	}),
	node({
		id: "3ac48c82-3d7c-43f6-8971-c7be6ca263e4",
		name: "Get Character",
		type: NODE_TYPE_ID.function.entity,
		description: "Returns a character entity for a character scene tree node.",
		controls: {
			character: addSelectCharacterSceneTreeNode("Character"),
		},
		outputs: {
			entity: {
				name: "Character",
				type: "entity",
				optional: true,
			},
		},
		resolve(inputs, ctx) {
			let sceneTreeNode: string | undefined = inputs.character;

			if (inputs.character === NODE_THIS_SCENE_TREE_NODE) {
				if (ctx.entryPoint.type === "characterSceneTreeNode") {
					sceneTreeNode = ctx.entryPoint.targetId;
				}
			} else {
				sceneTreeNode = inputs.character;
			}

			const entity =
				sceneTreeNode !== undefined ? ctx.world.sceneTreeNodeToEntity.get(sceneTreeNode) : undefined;

			return {
				entity,
			};
		},
	}),
];
