import { Vector3 } from "three";
import type { World } from "base/world/World";

const _particlesPosition = new Vector3();

export const onBuildBlock = (world: World, type: string, x: number, y: number, z: number) => {
	const particlesPosition = _particlesPosition.set(x, y, z);
	particlesPosition.addScalar(0.5);

	const blockType = world.blockTypeRegistry.blockNameToType.get(type);

	const blockTypeBuildSound = blockType?.getBuildSound();
	if (blockTypeBuildSound) {
		world.sfxManager.playAtPos({
			...blockTypeBuildSound,
			x: particlesPosition.x,
			y: particlesPosition.y,
			z: particlesPosition.z,
		});
	}

	world.client.particleEngine.addBlock([particlesPosition.x, particlesPosition.y, particlesPosition.z]);
};

export const onBreakBlock = (world: World, type: string, x: number, y: number, z: number) => {
	const particlesPosition = _particlesPosition.set(x, y, z);
	particlesPosition.addScalar(0.5);

	const blockType = world.blockTypeRegistry.blockNameToType.get(type);

	const blockTypeBreakSound = blockType?.getBreakSound();
	if (blockTypeBreakSound) {
		world.sfxManager.playAtPos({
			...blockTypeBreakSound,
			x: particlesPosition.x,
			y: particlesPosition.y,
			z: particlesPosition.z,
		});
	}

	world.client.particleEngine.delBlock([particlesPosition.x, particlesPosition.y, particlesPosition.z]);
};
