import TWEEN from "@tweenjs/tween.js";
import { DEGRAD, rand } from "base/util/math/Math";
import type { World } from "base/world/World";
import type { Entity } from "base/world/entity/Entity";
import type { Character } from "base/world/entity/Character";

import { Euler, Vector3 } from "three";
import { Text3D } from "./Text3D";

const _cameraRight = new Vector3();
const _damageTextStartPosition = new Vector3();
const _damageTextDirection = new Vector3();
const _horizontalStartOffsetVector = new Vector3();
const _horizontalTargetOffsetVector = new Vector3();

export const createDamageText = (world: World, entity: Entity, damage: number, critical: boolean) => {
	const camera = world.client!.camera;
	const scene = world.scene;

	const cameraDistanceToEntity = camera.position.distanceTo(entity.position);

	const cameraRight = camera.getWorldDirection(_cameraRight);
	cameraRight.cross(camera.up).normalize();

	const direction = Math.random() < 0.5 ? -1 : 1;

	const damageTextDirection = _damageTextDirection.copy(cameraRight).multiplyScalar(direction);

	const horizontalStartOffset = rand(0.2, 0.4);
	const horizontalTargetOffset = rand(0.1, 0.4);

	const horizontalStartOffsetVector = _horizontalStartOffsetVector
		.copy(damageTextDirection)
		.multiplyScalar(horizontalStartOffset);

	const horizontalTargetOffsetVector = _horizontalTargetOffsetVector
		.copy(damageTextDirection)
		.multiplyScalar(horizontalTargetOffset);

	let yStartOffset = rand(0, 0.5);
	let yTargetOffset = critical ? rand(1.1, 1.6) : rand(1, 1.3);
	if (cameraDistanceToEntity < 5) {
		const distanceMultiplier = cameraDistanceToEntity / 10;
		yStartOffset *= distanceMultiplier;
		yTargetOffset *= distanceMultiplier;
	}

	const startPosition = _damageTextStartPosition.set(
		entity.position.x,
		entity.position.y + yStartOffset,
		entity.position.z,
	);

	if (entity.type.def.isCharacter) {
		startPosition.y += (entity as unknown as Character).size.state.currentHeight * 1.1;
	}

	startPosition.add(horizontalStartOffsetVector);

	const targetPosition = {
		x: startPosition.x + horizontalTargetOffsetVector.x,
		y: startPosition.y + horizontalTargetOffsetVector.y + yTargetOffset,
		z: startPosition.z + horizontalTargetOffsetVector.z,
	};

	const damageTextTargetScale = { x: 0.7, y: 0.7, z: 0.7 };

	const damageTextFontSize = 32 * (critical ? rand(1.75, 2) : rand(0.9, 1.2));

	const damageText = new Text3D(
		world,
		scene,
		{
			fontSize: damageTextFontSize,
			faceCamera: true,
			sizeAttenuation: false,
			backgroundColor: "#0000",
			fontColor: critical ? "#f3af19" : "#FFFFFF",
			fontWeight: 600,
			fontOutlineWidth: 6,
			margin: 30,
			linePadding: 10,
			radius: 10,
			scl: 1 / 750,
			transform: {
				pos: startPosition,
				rot: new Euler(0, Math.PI, 0),
			},
		},
		damage.toString(),
	);
	damageText.child.renderOrder = 3;

	const damageTextDuration = 800;

	const opacityInTween = new TWEEN.Tween(damageText.child.material)
		.to({ opacity: 1 }, damageTextDuration / 2)
		.easing(TWEEN.Easing.Exponential.Out);

	const opacityOutTween = new TWEEN.Tween(damageText.child.material).to(
		{ opacity: 0 },
		damageTextDuration / 2,
	);

	const opacityTween = opacityInTween.chain(opacityOutTween);

	const positionAndScaleTween = new TWEEN.Tween(damageText.obj)
		.to(
			{
				position: targetPosition,
				scale: damageTextTargetScale,
			},
			damageTextDuration,
		)
		.easing(critical ? TWEEN.Easing.Exponential.Out : TWEEN.Easing.Linear.None)
		.onUpdate(() => {
			damageText.obj.updateMatrix();
		})
		.onComplete(() => {
			damageText.dispose();
		});

	positionAndScaleTween.start();
	opacityTween.start();

	if (critical) {
		const targetRotation = rand(0, 10) * DEGRAD * -direction;

		new TWEEN.Tween(damageText.child.material)
			.to({ rotation: targetRotation }, damageTextDuration)
			.easing(TWEEN.Easing.Exponential.Out)
			.start();
	}
};
