import { hermiteCubic } from "base/util/math/Math.ts";

export class AnimationTrack {
	constructor(animMesh, actionName, parent) {
		this.mesh = animMesh;
		this.actionName = actionName;
		this.parent = parent;

		this.state = "off";
		this.startWeight = 0.0;
		this.startTime = 0.0;
		this.currentWeight = 0;
		this.endWeight = 0.0;

		if (parent) {
			parent.animationTracks.push(this);
		}
	}

	get action() {
		return this.mesh.getAction(this.actionName);
	}

	setCurrentWeight(weight) {
		this.currentWeight = weight;
		if (this.parent) {
			this.action.setEffectiveWeight(this.currentWeight * this.parent.weight);
		} else {
			this.action.setEffectiveWeight(this.currentWeight);
		}
	}

	updateCurrentWeight() {
		if (this.parent) {
			this.action.setEffectiveWeight(this.currentWeight * this.parent.weight);
		} else {
			this.action.setEffectiveWeight(this.currentWeight);
		}
	}

	switchOn() {
		this.state = "on";
		this.setCurrentWeight(1.0);
	}

	switchOff() {
		this.state = "off";
		this.setCurrentWeight(0.0);
	}

	startFadeIn(blendTime) {
		if (blendTime > 0 /*&& this.currentWeight < 0.99*/) {
			this.startTime = this.mesh.totalTime;
			this.endTransitionTime = this.startTime + blendTime;
			this.startWeight = this.currentWeight;
			this.endWeight = 1.0;
			this.state = "transitionIn";
		} else {
			this.switchOn();
		}
	}

	startFadeOut(blendTime) {
		if (blendTime > 0 /*&& this.currentWeight > 0.01 */) {
			this.startTime = this.mesh.totalTime;
			this.endTransitionTime = this.startTime + blendTime;
			this.startWeight = this.currentWeight;
			this.endWeight = 0.0;
			this.state = "transitionOut";
		} else {
			this.switchOff();
		}
	}

	update() {
		if (this.state !== "on" && this.state !== "off") {
			const currentTime = this.mesh.totalTime;

			if (this.state === "transitionIn") {
				if (currentTime >= this.endTransitionTime) {
					this.switchOn();

					return;
				}

				const t = (currentTime - this.startTime) / (this.endTransitionTime - this.startTime);
				const weight = hermiteCubic(t);
				const distance = this.endWeight - this.startWeight;
				const currentDistance = distance * weight;
				this.setCurrentWeight(this.startWeight + currentDistance);

				return;
			}

			if (this.state === "transitionOut") {
				if (currentTime >= this.endTransitionTime) {
					this.switchOff();

					return;
				}

				const t = (currentTime - this.startTime) / (this.endTransitionTime - this.startTime);
				const weight = hermiteCubic(t);
				const distance = this.endWeight - this.startWeight;
				const currentDistance = distance * weight;
				this.setCurrentWeight(this.startWeight + currentDistance);
			}
		} else {
			this.updateCurrentWeight();
		}
	}
}
