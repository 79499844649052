import { VNX, VNY, VNZ, VX, VY, VZ } from "base/util/math/Math.ts";
import { Vector3 } from "three";

const _hitPos = new Vector3();
/**
 * useful if you've already done a raycast and just need to know which block was hit
 * @param {import("three").Vector3} rayPos
 * @param {import("three").Vector3} inHitPos
 * @param {import("three").Vector3} hitNor
 * @param {import("three").Vector3} outBlock
 */
export const findHitBlock = (rayPos, inHitPos, hitNor, outBlock) => {
	const hitPos = _hitPos.copy(inHitPos);

	const hitX = hitNor.equals(VNX) || hitNor.equals(VX);
	const hitY = hitNor.equals(VNY) || hitNor.equals(VY);
	const hitZ = hitNor.equals(VNZ) || hitNor.equals(VZ);

	if (hitX) {
		hitPos.x = Math.round(hitPos.x);
	} else if (hitY) {
		hitPos.y = Math.round(hitPos.y);
	} else if (hitZ) {
		hitPos.z = Math.round(hitPos.z);
	}

	outBlock.copy(hitPos).floor();

	if (hitX && rayPos.x > hitPos.x) {
		outBlock.x--;
	} else if (hitY && rayPos.y > hitPos.y) {
		outBlock.y--;
	} else if (hitZ && rayPos.z > hitPos.z) {
		outBlock.z--;
	}
};
