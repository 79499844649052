import { z } from "zod";
import { assetKeySchema } from "./general";
import { AssetType } from "../types";

export const dayCycle = (value: boolean) => {
	const schema = z.boolean();
	schema.parse(value);
};

export const SettingsSchema = z.object({
	pk: assetKeySchema(AssetType.SETTINGS),
	type: z.literal(AssetType.SETTINGS),
	dayCycle: z.boolean().optional(),
	dayCycleTime: z.number().optional(),
});
