import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";

export class LocomotionStateLanding extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("Landing", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.3;
		this.transitionToJoggingTime = 0.2;
		this.transitionToFallingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.trackMap = new Map();

		this.trackMap.set(
			"Unarmed_Land",
			new AnimationTrack(animMesh, "JMGO_Unarmed_Jump_Land_Static_Forward", this),
		);

		this.trackMap.set(
			"Jog_Land",
			new AnimationTrack(animMesh, "JMGO_Unarmed_Jump_Land_Jog_Forward", this),
		);

		this.landingAction = this.trackMap.get("Unarmed_Land");
	}

	startCompute(transitionData) {
		if (this.inputState.isMoving) {
			this.landingAction = this.trackMap.get("Jog_Land");
		} else {
			this.landingAction = this.trackMap.get("Unarmed_Land");
		}

		this.startTime = this.mesh.totalTime;

		this.landingAction.action.reset();
		this.landingAction.action.loop = LoopOnce;

		if (transitionData.prvState) transitionData.prvState.startFadeOut(transitionData);

		this.landingAction.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.landingAction.action.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.landingAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		// transition to flyingIdle state
		if (this.inputState.isFlying) {
			outTransitionToState.ptr = map.get("FlyingIdle");
			outTransitionToState.blendTime = this.transitionToFlyingIdleTime;
			return true;
		}

		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("Jumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("Falling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}
			return true;
		}

		if (
			this.landingAction.action.getRemainingTime() < this.transitionToIdleTime &&
			!this.inputState.isMoving
		) {
			outTransitionToState.ptr = map.get("Idle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("CrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (
			this.inputState.isMoving &&
			this.landingAction.action.getRemainingTime() < this.transitionToRunningTime
		) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("Running");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("Jogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
			}
			return true;
		}

		return false;
	}
}
