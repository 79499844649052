import { Input, InputCommandType, InputCommands } from "@jamango/engine/Input.ts";
import { VY, randInt, skewedRand } from "base/util/math/Math.ts";
import * as Physics from "base/world/Physics";
import { Character } from "base/world/entity/Character";
import { Entity } from "base/world/entity/Entity";
import { entityIsNPC } from "base/world/entity/component/Type";
import { netState } from "router/Parallelogram";
import { Box3, Euler, Matrix4, Vector3 } from "three";

const tmpVec = new Vector3();

export function npcMovementInputUpdate(entity: Entity, dt: number) {
	if (!canUpdateSystem(entity)) return;

	const input = entity.input!;
	const cmd = entity.cmd!;

	// required for downstream movement systems
	input.pointerLocked = true;

	if (entity.isDead()) {
		// if down, set to be crouching
		input.isCrouching = true;
	} else if (entity.movement.def.canMoveHorizontally) {
		// behavior specific move and aimsets
		const b = entity.aiBehavior;
		if (b === "wander") {
			behaviorWander(entity, input, dt);
		} else if (b === "attack") {
			behaviorAttack(entity, input, cmd, dt);
		} else if (b === "look") {
			behaviorLook(entity, input, dt);
		} else if (b === "approach") {
			behaviorApproach(entity, input, dt);
		} else if (b === "follow") {
			behaviorFollow(entity, input, dt);
		} else if (b === "moveto") {
			behaviorMoveTo(entity, input, dt);
		} else if (b === "shootat") {
			behaviorShootAt(entity, input, cmd, dt);
		} else {
			behaviorIdle(entity, input, dt);
		}

		// no crouching (for now) - some systems may do this later?
		input.isCrouching = false;
	}
}

function canUpdateSystem(entity: Entity): entity is Character {
	return (
		!entity.world.editor.paused &&
		entity.input !== undefined &&
		entity.cmd !== undefined &&
		entityIsNPC(entity) &&
		!entity.prophecy.state.isProphecy &&
		netState.isHost &&
		!entity.mount.state.parent
	);
}

function behaviorIdle(_entity: Entity, input: Input, _dt: number) {
	// stop moving and look straight ahead - do not change rotation angle.
	input.nipple.set(0, 0);
	input.camera.phi = Math.PI / 2;
}

function behaviorWander(entity: Character, input: Input, dt: number) {
	// counts down a timer, once finished, move to a random position near the wander origin
	entity.aiWanderTimer -= dt;
	if (entity.aiWanderTimer <= 0) {
		entity.aiWanderTimer = entity.aiWanderTime;
		// get a wander position that is on the plane of the wander origin
		const wanderTarget = new Vector3(Math.random() - 0.5, 0, Math.random() - 0.5);
		wanderTarget.setLength(Math.random() * 4 + 3).add(entity.aiWanderOrigin);
		entity.aiTargetPosition = wanderTarget;
	}
	// target pos can be null when the behavior initially starts. timer is counting down first
	if (
		entity.aiTargetPosition !== null &&
		steerTowardsPosition(entity, entity.aiTargetPosition, 1.5, input, dt)
	) {
		// once goal is reached, explicitly set it to null so we do not keep checking
		entity.aiTargetPosition = null;
	}
}

function behaviorAttack(entity: Character, input: Input, cmd: InputCommands, dt: number) {
	const t = entity.aiGetTarget();
	if (t.target === null) return;

	const item = entity.getEquippedItem();
	const approachDist = item?.itemType.def.isRanged ? 10 : 1.5;
	// 10 for ranged, 2 for melee
	steerTowardsPosition(entity, t.aimAtPos, approachDist, input, dt);
	if (item?.itemType.def.isRanged) {
		shootAtTarget(entity, input, cmd, dt, t);
	} else {
		//very hacky method of melee attacking without doing a raycast. allows ai to attack through walls
		entity.aiAttackTimer -= dt;
		if (entity.aiAttackTimer <= 0 && t.dst < entity.fighting.def.fistingDistance) {
			entity.aiAttackTimer = entity.aiAttackTime;
			item?.fireAnimation();

			if (!t.target.type.def.isNPC || entity.aiCanKillNPCs) {
				const damage = entity.getMeleeDamage();
				t.target.addHealth(-damage, entity.entityID);
			}
		}
	}
}

function behaviorLook(entity: Character, input: Input, _dt: number) {
	// simply looks at .aiTarget, or its nearest auto target
	const { target, aimAtPos } = entity.aiGetTarget();
	if (target !== null) lookAt(entity, aimAtPos, input, true);
}

function behaviorFollow(entity: Character, input: Input, dt: number) {
	// follows .aiTarget or the nearest auto target. looks at target if close enough, but resumes following if distance increases
	const t = entity.aiGetTarget();
	if (t.target !== null) {
		const reached = steerTowardsPosition(entity, t.aimAtPos, entity.aiApproachDistance, input, dt);
		if (reached) lookAt(entity, t.aimAtPos, input, true);
		return reached;
	}
	return false;
}

function behaviorApproach(entity: Character, input: Input, dt: number) {
	// the approach behavior is just "follow", but it stops if the target is reached
	if (behaviorFollow(entity, input, dt)) {
		// when approaching, we change behavior to "look" once we've arrived
		entity.aiSetBehavior("look");
	}
}

function behaviorMoveTo(entity: Character, input: Input, dt: number) {
	// moves to .aiTargetPosition
	const target = entity.aiTargetPosition;
	if (target !== null) {
		// Don't check height
		target.y = entity.position.y;
		const hasReached = steerTowardsPosition(entity, target, 1, input, dt);
		if (hasReached) entity.aiTargetPosition = null;
	}
}

function behaviorShootAt(entity: Character, input: Input, cmd: InputCommands, dt: number) {
	// shoots at aiTarget, but doesnt move
	const t = entity.aiGetTarget();
	if (!t.target) return;
	const item = entity.getEquippedItem();
	if (item?.itemType.def.isRanged) shootAtTarget(entity, input, cmd, dt, t);
}

/*
  --------------------------------------------------------------------------------
  Generic Re-usable functions below  ----
  These are used by the more macro behavior code
*/

function steerTowardsPosition(
	entity: Character,
	goal: Vector3,
	approachDistance: number,
	input: Input,
	dt: number,
) {
	// jump if we're sideways colliding with something
	input.isJumping = entity.collision.state.hitSide && entity.movement.state.jumpTimer > 3;

	// check if we are close enough to the goal. if so, we stop moving and exit
	const distance = entity.position.distanceTo(goal);
	if (distance < approachDistance) {
		// we're close enough. stop moving
		input.nipple.set(0, 0);
		return true;
	}

	lookAt(entity, goal, input, false);
	input.nipple.y = 1;

	// evasion
	if (entity.aiNeedsEvade) {
		// if an evade maneuver was triggered, set an evade direction
		entity.aiEvasionDir = Math.random() < 0.5 ? -1 : 1;
		entity.aiNeedsEvade = false;
	}

	if (entity.aiEvasionDir !== 0 && entity.aiIsEvadingTimer < 1) {
		// switch directions if you are about to evade off a drop
		if (entity.collision.state.isOnEdge) {
			entity.aiEvasionDir = 0;
		}
		input.nipple.x = entity.aiEvasionDir;
		entity.aiIsEvadingTimer += dt;
	} else {
		// once finished evading, reset evade timer
		if (entity.aiIsEvadingTimer > 1) {
			entity.aiIsEvadingTimer = 0;
		}
		// stop evasion movement
		input.nipple.x = 0;
		entity.aiEvasionDir = 0;
		entity.aiNeedsEvade = false;
	}

	return false;
}

const _rayPos = new Vector3();

function shootAtTarget(entity: Character, input: Input, cmd: InputCommands, dt: number, t: any) {
	const { target, aimAtPos } = t;
	lookAt(entity, aimAtPos, input, true);

	const targetEyeHeight = target.size.state.eyeHeight;
	const targetBodyHeight = target.size.state.height / 2;
	const targetFootHeight = targetBodyHeight / 2;

	// Raycast to see if something is in front of ai player
	// Calculate the ray from the ai's head area to the players head area
	const rayPos = _rayPos.copy(entity.position);
	// Raise the position up from the floor to the head
	rayPos.y += entity.getAABB(new Box3()).getSize(new Vector3()).y * 0.75;

	function calculateRayDirection(vec: Vector3, targetPlayer: Character, targetHeight: number) {
		// calculate ray from pos to player
		vec.copy(targetPlayer.position);
		vec.y += targetHeight;
		vec.sub(rayPos);
		vec.normalize();

		return vec;
	}

	// technically will return the previous frame's eye position here, but who care
	const rayLen = 10000;

	// check if body is visible
	const bodyDir = calculateRayDirection(tmpVec, target, targetBodyHeight);
	const bodyHitEntity = Physics.raycastEntity(entity.world.physics, rayPos, bodyDir, rayLen, entity);

	// shoot at body if visible
	if (bodyHitEntity !== undefined && target.entityID === bodyHitEntity) {
		aimAtPos.y += targetBodyHeight;
	} else {
		const headDir = calculateRayDirection(tmpVec, target, targetEyeHeight);
		const headHitEntity = Physics.raycastEntity(entity.world.physics, rayPos, headDir, rayLen, entity);

		// if body not visible but head is visible, shoot at head
		// updating this so there is a 1/3 chance they aim directly at your head as a temp fix to rebalance
		if (headHitEntity !== undefined && target.entityID === headHitEntity) {
			const headAimChance = randInt(0, 3);
			if (headAimChance === 0) {
				aimAtPos.y += targetEyeHeight;
			} else {
				aimAtPos.y += targetBodyHeight;
			}
		} else {
			// if head and body are not visible, shoot at legs
			const footDir = calculateRayDirection(tmpVec, target, targetFootHeight);
			const footHitEntity = Physics.raycastEntity(
				entity.world.physics,
				rayPos,
				footDir,
				rayLen,
				entity,
			);

			if (footHitEntity !== undefined && target.entityID === footHitEntity) {
				aimAtPos.y += targetFootHeight;
			} else {
				// if head, body and feet and invisible, give up
				return;
			}
		}
	}

	entity.aiShootAtTimer -= dt;
	if (entity.aiShootAtTimer <= 0) {
		const item = entity.getEquippedItem();

		if (item) {
			// determine if the ai should miss
			const hitChance = Math.random();
			if (hitChance > entity.aiShootHitPercentage) {
				entity.weaponSpread = 30;
			} else {
				entity.weaponSpread = item.weapon.def.spread;
			}
			cmd.push(InputCommandType.ITEM_PRIMARY);

			// ai cooldown for weapon
			// apply a small random jitter for non-automatic weapons
			const aiShootAtCooldownJitter = !item.weapon.def.isAutomatic
				? skewedRand(0, entity.aiShootAtCooldownMaxJitter, entity.aiShootAtCooldownJitterSkew)
				: 0;
			entity.aiShootAtTimer =
				item.weapon.def.cooldown / entity.aiFireRateMultiplier + aiShootAtCooldownJitter;
		}
	}
}

const tmpMat = new Matrix4();
const tmpEuler = new Euler();
function lookAt(entity: Character, pos: Vector3, input: Input, rotateNeck = false) {
	tmpMat.lookAt(entity.position, pos, VY);
	tmpEuler.setFromRotationMatrix(tmpMat, "YXZ");
	input.camera.theta = Math.round(360 * tmpEuler.y) / 360;
	if (rotateNeck) {
		// makes the npc vertically look at the position
		input.camera.phi = Math.round(360 * tmpEuler.x) / 360 + Math.PI / 2;
	} else {
		input.camera.phi = Math.PI / 2;
	}
}
