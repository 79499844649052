import * as ChatBase from "base/dom/Chat";
import * as ChatRouter from "router/dom/Chat";

import { LoopbackPeer, type Peer } from "@jamango/ibs";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { World } from "base/world/World";
import type { ChatMessage } from "@jamango/frontend/Chat.ts";
import * as trigger from "base/rete/modules/trigger";
import * as Net from "router/Net";

export function receiveChat(world: World, peer: Peer, message: string) {
	if (!message) return;

	const chat: ChatMessage = {
		time: Date.now(),
		username: getPeerMetadata(peer).username,
		message,
	};

	if (message.startsWith("/")) {
		/*const cmd = message.split(/\s(?=(?:(?:[^"]*"){2})*[^"]*$)/g);

		// attempt to parse the command as json
		// the first item will be a string command name, skip it
		for (let i = 1; i < cmd.length; i++) {
			try {
				cmd[i] = JSON.parse(cmd[i]);
			} catch (swallow) {} //leave as string
		}

		// find and evaluate a matching client command
		for (let i = 0; i < clientCommands.length; i++) {
			const clientCommand = clientCommands[i];

			if (clientCommand.predicate(cmd)) {
				clientCommand.handler(cmd);
			}
		}*/
	} else {
		Net.sendToAll("chat_relaymsg", chat, peer);
	}

	ChatRouter.displayChat(chat);
	const entity = world.peerToPlayer.get(peer);
	if (entity !== undefined) trigger.onChat(world, entity, message);
}

export function broadcastPrivateChat(world: World, recips: Peer[], message: string, color?: string) {
	const chat: ChatMessage = {
		time: Date.now(),
		message,
		color,
	};

	let displaySecretChat = true;
	for (const peer of recips) {
		if (peer instanceof LoopbackPeer) {
			displaySecretChat = false; //no longer a secret. display chat normally
			ChatRouter.displayChat(chat);
		} else {
			Net.send("chat_relaymsg", chat, peer);
		}
	}

	//let the host see the message in console even if their loopback peer wasn't specified as a target
	if (displaySecretChat) ChatBase.displayChat(chat);
}

export function initCommandListeners() {
	Net.listen("chat_sendmsg", (msg: string, world, peer) => {
		receiveChat(world, peer, ChatBase.trimChat(msg));
	});
}

//move this state to world.server
/*const clientCommands: {
	predicate: (msg: string[]) => boolean;
	handler: (cmd: string | object) => void;
}[] = [];


export function registerClientCommand(predicate: (msg: string) => boolean, handler: (cmd: string | object) => void) {
	clientCommands.push({ predicate, handler });
}
*/
