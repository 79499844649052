import { NODE_TYPE_ID } from "base/rete/Constants";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as trigger from "base/rete/modules/trigger";
import { getPlayer } from "base/rete/modules/validate.js";
import { node } from "base/rete/Types";

const type = NODE_TYPE_ID.function.player;

export const PERSISTENT_DATA_NODES = [
	node({
		name: "Peer Get Persistent State",
		description: "Gets the value of a given peer's persistent account state",
		type,
		id: "d60ae2a1-8314-4be4-b7b0-651e5747f92a",
		inputs: {
			peer: { name: "Peer", type: "entity" },
			name: { name: "State", type: "string", control: "string" },
		},
		outputs: {
			state: { name: "State", type: "any" },
		},
		resolve(inputs, ctx) {
			const peer = ctx.world.playerToPeer.get(inputs.peer);

			if (!peer) {
				throw Error("[PeerGetPersistentState] No peer found");
			}

			const accountID = getPeerMetadata(peer).accountID;
			const accountEntry = ctx.world.rete.state.peerPersistent[accountID];

			if (!accountEntry) {
				throw Error("[PeerGetPersistentState] No account entry found");
			}

			const value = accountEntry[inputs.name] as string | number | boolean;

			return { state: value };
		},
	}),
	node({
		name: "Peer Set Persistent State",
		description: "Gets the value of a given peer's persistent account state",
		info: ["Only strings, numbers, and booleans are supported as values for persistent states."],
		type,
		id: "81b550ee-5dfe-4618-a9cb-5f056bee7808",
		inputs: {
			exec: { type: "exec" },
			peer: { name: "Peer", type: "entity" },
			name: { name: "State", type: "string", control: "string" },
			value: { name: "Value", type: "any", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const player = getPlayer(inputs, ctx, "peer");
			if (!inputs.name || !player) {
				throw Error("[PeerSetPersistentState]: No state name or player provided");
			}

			const peer = ctx.world.playerToPeer.get(player);
			if (!peer) {
				throw Error("[PeerSetPersistentState] No peer found");
			}

			const value = inputs.value;
			const valueType = typeof value;

			if (valueType !== "string" && valueType !== "number" && valueType !== "boolean") {
				throw new Error(
					"[PeerSetPersistentState] Persistent state value must be a string, number, or boolean.",
				);
			}

			const accountID = getPeerMetadata(peer).accountID;
			const accountEntry = ctx.world.rete.state.peerPersistent[accountID];

			if (!accountEntry) {
				throw Error("[PeerSetPersistentState] No account entry found");
			}

			accountEntry[inputs.name] = value;

			trigger.onPeerPersistentStateChange(ctx.world, player, inputs.name, value);
		},
	}),
];
