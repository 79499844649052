import { BB } from "base/BB";

export const FPS_INTERVAL = 1; //how often to sample fps in seconds, must be at least 1
export const WORDS_PER_MINUTE = 200;

export class DeltaTimeTracker {
	#prvTime;
	#prvFPSTime;
	#frames;

	constructor() {
		this.deltaTime = 0;
		this.fpsTime = 0;

		this.#prvTime = 0;
		this.#prvFPSTime = 0;
		this.#frames = 0;
	}

	//call when update loop is started
	reset() {
		this.deltaTime = 0;
		this.fpsTime = 0;

		this.#prvTime = this.#prvFPSTime = now();
		this.#frames = 0;
	}

	//call at the end of each tick
	update() {
		const curTime = now();
		this.deltaTime = curTime - this.#prvTime;

		this.#frames++;

		if (curTime >= this.#prvFPSTime + FPS_INTERVAL) {
			//is squaring the FPS_INTERVAL actually correct? not sure but it doesn't matter as long as it's 1
			this.fpsTime = Math.round((this.#frames * (curTime - this.#prvFPSTime)) / FPS_INTERVAL ** 2);
			this.#prvFPSTime = curTime;
			this.#frames = 0;
		}

		this.#prvTime = curTime;
	}
}

//attaches "clearTimeout" function to the promise so that you can can prevent the promise from resolving
export function wait(s) {
	const type = BB.world ?? window;

	let timeout;
	const promise = new Promise((resolve) => (timeout = type.setTimeout(resolve, s * 1000)));

	promise.clearTimeout = () => type.clearTimeout(timeout);
	return promise;
}

export async function eventLoop() {
	await new Promise(setImmediate);
}

export async function deferredCallback() {
	await 0;
}

export function getReadingTime(text) {
	const numberOfWords = text.split(/\s/g).length;
	return Math.ceil(numberOfWords / WORDS_PER_MINUTE);
}

export function now() {
	return performance.now() / 1000;
}

export function executeEveryNthCall(n, funcToExecute, ...args) {
	let callID = Math.floor(Math.random() * 4);
	return function () {
		callID = (callID + 1) % n;
		if (callID === 0) {
			funcToExecute(...args);
			return true;
		}

		return false;
	};
}
