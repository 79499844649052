import { BB } from "base/BB";

import { Layers } from "client/Layers.js";
import { findBestFloatTargetType } from "client/util/FloatTargetType.js";
import {
	CopyShader,
	DepthCopyShader,
	WboitCompositeShader,
	WboitStages,
} from "client/util/OrderIndependentTransparency.js";
import { OutputPass } from "client/util/OutputPass.js";
import {
	AddEquation,
	Color,
	CustomBlending,
	DepthFormat,
	DepthTexture,
	EqualDepth,
	FloatType,
	FramebufferTexture,
	LessEqualDepth,
	NearestFilter,
	NoToneMapping,
	OneFactor,
	OneMinusSrcAlphaFactor,
	RGBAFormat,
	SRGBColorSpace,
	SrcAlphaFactor,
	Vector2,
	WebGLRenderTarget,
	ZeroFactor,
} from "three";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";
import { FXAAShader } from "three/addons/shaders/FXAAShader.js";
import {
	DebugAOMaterial,
	DepthOnlyMaterial,
	DepthOnlyAphaTestedMaterial,
	DepthOnlyCharacterMaterial,
} from "client/util/Shaders.js";
import { AA_SETTING } from "@jamango/content-client";

const CLEAR_COLOR_ZERO = new Color(0.0, 0.0, 0.0);
const CLEAR_COLOR_ONE = new Color(1.0, 1.0, 1.0);

const VECTOR_2_ZERO = new Vector2(0, 0);

/**
 * @typedef {{
 *   renderer: import('three').WebGLRenderer,
 *   camera: import('three').Camera,
 *   camera2D: import('three').Camera,
 *   water: import('client/world/fx/FakeWater.js').FakeWaterClient,
 *   sceneDebug: import('three').Scene,
 *   sceneSky: import('three').Scene,
 *   sceneFP: import('three').Scene,
 *   sceneHUD: import('three').Scene,
 *   sceneOcclusion: import('three').Scene,
 *   sceneWorld: import('three').Scene,
 *   opaqueChunksBatchManager: import('base/world/block/chunk/BatchChunk.js').BatchManager,
 * 	 alphaTestedChunksBatchManager: import('base/world/block/chunk/BatchChunk.js').BatchManager,
 *   translucentChunksBatchManager: import('base/world/block/chunk/BatchChunk.js').BatchManager,
 *   lights: Array<import('three').Object3D>,
 * }} RenderPipelineParams
 */

export class RenderPipeline {
	/**
	 * @param {RenderPipelineParams} params
	 */
	constructor(params) {
		this.renderer = params.renderer;

		this.camera = params.camera;
		this.camera2D = params.camera2D;

		this.water = params.water;

		this.sceneDebug = params.sceneDebug;
		this.sceneSky = params.sceneSky;
		this.sceneFP = params.sceneFP;
		this.sceneHUD = params.sceneHUD;
		this.sceneOcclusion = params.sceneOcclusion;
		this.sceneWorld = params.sceneWorld;

		this.opaqueChunksBatchManager = params.opaqueChunksBatchManager;
		this.alphaTestedChunksBatchManager = params.alphaTestedChunksBatchManager;
		this.translucentChunksBatchManager = params.translucentChunksBatchManager;

		this.lights = params.lights;

		// caches for order independent transparency
		this.beforeOITClearColor = new Color();
		this.beforeOITBlendingCache = new Map();
		this.beforeOITBlendEquationCache = new Map();
		this.beforeOITBlendSrcCache = new Map();
		this.beforeOITBlendDstCache = new Map();

		this.wboitMeshes = [];

		this.frameTexture = null;

		this.debugAOMaterial = null;
	}

	init() {
		const size = this.renderer.getSize(new Vector2());
		const pixelRatio = this.renderer.getPixelRatio();
		const effectiveWidth = size.width * pixelRatio;
		const effectiveHeight = size.height * pixelRatio;

		// passes for order independent transparency

		this.wboitCopyDepthPass = new ShaderPass(DepthCopyShader);
		this.wboitCopyDepthPass.material.extensions.fragDepth = true;

		this.wboitCopyPass = new ShaderPass(CopyShader);
		this.wboitCopyPass.material.depthTest = false;
		this.wboitCopyPass.material.depthWrite = false;
		this.wboitCopyPass.material.blending = CustomBlending;
		this.wboitCopyPass.material.blendEquation = AddEquation;
		this.wboitCopyPass.material.blendSrc = OneFactor;
		this.wboitCopyPass.material.blendDst = ZeroFactor;

		this.wboitCompositePass = new ShaderPass(WboitCompositeShader);
		this.wboitCompositePass.material.transparent = true;
		this.wboitCompositePass.material.blending = CustomBlending;
		this.wboitCompositePass.material.blendEquation = AddEquation;
		this.wboitCompositePass.material.blendSrc = OneMinusSrcAlphaFactor;
		this.wboitCompositePass.material.blendDst = SrcAlphaFactor;

		// antialiasing pass
		this.fxaaPass = new ShaderPass(FXAAShader);
		this.fxaaPass.material.uniforms.resolution.value.set(1 / effectiveWidth, 1 / effectiveHeight);

		// output pass
		this.outputPass = new OutputPass();

		// render targets

		// find the best render target type
		// gl.getExtension( 'EXT_color_buffer_float' ) - lacking support, see:
		// https://stackoverflow.com/questions/28827511/webgl-ios-render-to-floating-point-texture
		const targetType = findBestFloatTargetType(this.renderer);

		// Render targets
		this.sceneTarget = new WebGLRenderTarget(effectiveWidth, effectiveHeight, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetType,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: true,
		});

		this.sceneTarget.depthTexture = new DepthTexture();
		this.sceneTarget.depthTexture.format = DepthFormat;
		this.sceneTarget.depthTexture.type = FloatType;

		this.outputTarget = new WebGLRenderTarget(effectiveWidth, effectiveHeight, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetType,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: true,
		});

		const halfResolutionWidth = Math.round(effectiveWidth / 2);
		const halfResolutionHeight = Math.round(effectiveHeight / 2);

		this.wboitBaseTarget = new WebGLRenderTarget(halfResolutionWidth, halfResolutionHeight, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetType,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: true,
			colorSpace: SRGBColorSpace,
		});

		this.wboitAccumulationTarget = new WebGLRenderTarget(halfResolutionWidth, halfResolutionHeight, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetType,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: false,
		});

		this.depthPrepassTarget = new WebGLRenderTarget(effectiveWidth, effectiveHeight, {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			type: targetType,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: true,
		});
		this.depthPrepassTarget.depthTexture = new DepthTexture();
		this.depthPrepassTarget.depthTexture.format = DepthFormat;
		this.depthPrepassTarget.depthTexture.type = FloatType;

		this.depthPrepassMaterial = new DepthOnlyMaterial();

		this.depthAphaTestedPrepassMaterial = new DepthOnlyAphaTestedMaterial();
		this.depthAphaTestedPrepassMaterial.map = this.sceneWorld.alphaTestedMaterial.map;

		this.depthCharacterPrepassMaterial = new DepthOnlyCharacterMaterial();

		this.frameTexture = new FramebufferTexture();
	}

	dispose() {
		if (this.wboitCopyDepthPass.dispose) {
			this.wboitCopyDepthPass.dispose();
		}

		if (this.wboitCopyPass.dispose) {
			this.wboitCopyPass.dispose();
		}

		if (this.outputPass.dispose) {
			this.outputPass.dispose();
		}

		this.sceneTarget.dispose();
		this.outputTarget.dispose();
		this.wboitBaseTarget.dispose();
		this.wboitAccumulationTarget.dispose();
		this.depthPrepassTarget.dispose();
		this.frameTexture.dispose();

		if (this.debugAOMaterial) {
			this.debugAOMaterial.dispose();
		}
	}

	setSize(width, height) {
		this.sceneTarget.setSize(width, height);
		this.outputTarget.setSize(width, height);

		const halfResolutionWidth = Math.round(width / 2);
		const halfResolutionHeight = Math.round(height / 2);

		this.wboitBaseTarget.setSize(halfResolutionWidth, halfResolutionHeight);
		this.wboitAccumulationTarget.setSize(halfResolutionWidth, halfResolutionHeight);

		this.depthPrepassTarget.setSize(width, height);

		this.fxaaPass.material.uniforms.resolution.value.set(1 / width, 1 / height);

		if (this.frameTexture !== null) {
			this.frameTexture.dispose();
		}

		this.frameTexture = new FramebufferTexture(width, height);
	}

	render() {
		const renderer = this.renderer;
		renderer.info.reset();

		// decide whether we should render directly to framebuffer canvas or offscreen texture
		const renderToCanvas =
			BB.client.settings.aaSetting === AA_SETTING.MSAA ||
			(renderer.outputPassToneMapping === NoToneMapping &&
				BB.client.settings.aaSetting !== AA_SETTING.FXAA);

		// clear the canvas
		renderer.setRenderTarget(null);
		renderer.clear();

		// debug
		if (BB.client.debug.debugRendererMode > 0) {
			if (this.debugAOMaterial === null) {
				this.debugAOMaterial = new DebugAOMaterial({
					ao: true,
				});
			}
			this.sceneWorld.overrideMaterial = this.debugAOMaterial;
		} else {
			this.sceneWorld.overrideMaterial = null;
		}

		// gather meshes for order independent transparency

		this.wboitMeshes.length = 0;

		this.sceneWorld.traverse((object) => {
			if (!object.material) return;
			if (!object.visible) return;

			const materials = Array.isArray(object.material) ? object.material : [object.material];
			let isTransparent = true;
			let isWboitCapable = true;

			for (let i = 0; i < materials.length; i++) {
				isTransparent = isTransparent && materials[i].transparent;
				isWboitCapable = isWboitCapable && isTransparent && materials[i].wboitEnabled;
			}

			if (isWboitCapable) {
				this.wboitMeshes.push(object);

				for (let i = 0; i < materials.length; i++) {
					this.beforeOITBlendingCache.set(materials[i], materials[i].blending);
					this.beforeOITBlendEquationCache.set(materials[i], materials[i].blendEquation);
					this.beforeOITBlendSrcCache.set(materials[i], materials[i].blendSrc);
					this.beforeOITBlendDstCache.set(materials[i], materials[i].blendDst);
				}
			}
		});

		let anyWboitMeshVisible = false;
		for (const mesh of this.wboitMeshes) {
			if (mesh.visible) {
				anyWboitMeshVisible = true;
				break;
			}
		}

		// set the render target to the scene target for rendering the sky, water and main world scene
		if (!renderToCanvas) {
			renderer.setRenderTarget(this.sceneTarget);
		}
		renderer.setClearColor(CLEAR_COLOR_ZERO, 0.0);
		renderer.clear();

		/* render sky scene */
		// renderOrders:
		// -1. sky stars
		//  0. sky sun/moon

		renderer.render(this.sceneSky, this.camera);

		/* render main 3d world scene */
		// renderOrders:
		// -2. underwater particles+fading chunks
		// -1. fake water
		//  0. default
		//  1. text vfx
		//  2. entity healthbar

		// causes scene to be rendered a second time for foam effect
		this.camera.layers.enableAll();
		this.camera.layers.disable(Layers.SELECTOR);
		this.water?.client.render();
		this.camera.layers.enableAll();

		// frustum culling

		for (const batchManager of [
			this.opaqueChunksBatchManager,
			this.alphaTestedChunksBatchManager,
			this.translucentChunksBatchManager,
		]) {
			for (const batch of batchManager.batches) {
				batch.visible = this.camera.frustum.intersectsBox(batch.geometry.boundingBox);
			}
		}

		this.camera.layers.set(Layers.DEFAULT);

		if (BB.client.settings.zPrepassEnabled || (anyWboitMeshVisible && renderToCanvas)) {
			renderer.getContext().colorMask(false, false, false, false);

			if (renderToCanvas) {
				renderer.setRenderTarget(this.depthPrepassTarget);
				renderer.setClearColor(CLEAR_COLOR_ZERO, 0.0);
				renderer.clear();
			}

			let cachedWaterVisibility = false;
			if (this.sceneWorld.water && this.sceneWorld.water.client) {
				cachedWaterVisibility = this.sceneWorld.water.client.mesh.visible;
				this.sceneWorld.water.client.mesh.visible = false;
			}

			this.sceneWorld.overrideMaterial = this.depthPrepassMaterial;
			this.sceneWorld.alphaTestedMaterial.visible = false;
			this.sceneWorld.translucentMaterial.visible = false;

			// write depth for all except objects with alpha-tested and translucent materials
			// TODO later need skip character and other spacial objects by layer or visibility
			renderer.render(this.sceneWorld, this.camera);

			// temporary solution to write depth for character. only for when renderToCanvas=true
			if (renderToCanvas) {
				this.sceneWorld.overrideMaterial = this.depthAphaTestedPrepassMaterial;
				this.sceneWorld.mat.visible = false;
				this.sceneWorld.alphaTestedMaterial.visible = true;

				// write depth for alpha-tested objects
				renderer.render(this.sceneWorld, this.camera);

				this.sceneWorld.overrideMaterial = this.depthCharacterPrepassMaterial;
				this.sceneWorld.alphaTestedMaterial.visible = false;

				// write depth for characters
				renderer.render(this.sceneWorld, this.camera);
				this.sceneWorld.mat.visible = true;
			}

			if (this.sceneWorld.water && this.sceneWorld.water.client)
				this.sceneWorld.water.client.mesh.visible = cachedWaterVisibility;

			if (BB.client.debug.debugRendererMode > 0) {
				this.sceneWorld.overrideMaterial = this.debugAOMaterial;
			} else {
				this.sceneWorld.overrideMaterial = null;
			}
			this.sceneWorld.alphaTestedMaterial.visible = true;
			this.sceneWorld.translucentMaterial.visible = true;

			renderer.getContext().colorMask(true, true, true, true);
			if (renderToCanvas) {
				renderer.setRenderTarget(null);
			}

			// if we render to canvas we can't get depth from canvas
			// hence depth-prepass is rendering to seperate target and only used for wboit
			if (renderToCanvas) {
				this.sceneWorld.mat.depthFunc = LessEqualDepth;
				this.sceneWorld.mat.depthWrite = true;
			} else {
				this.sceneWorld.mat.depthFunc = EqualDepth;
				this.sceneWorld.mat.depthWrite = false;
			}
		} else {
			this.sceneWorld.mat.depthFunc = LessEqualDepth;
			this.sceneWorld.mat.depthWrite = true;
		}

		this.sceneWorld.alphaTestedMaterial.depthFunc = LessEqualDepth;
		this.sceneWorld.alphaTestedMaterial.depthWrite = true;
		this.sceneWorld.translucentMaterial.depthFunc = LessEqualDepth;
		this.sceneWorld.translucentMaterial.depthWrite = false;

		this.camera.layers.enable(Layers.SELECTOR);

		renderer.render(this.sceneWorld, this.camera);

		this.camera.layers.enableAll();

		this.sceneWorld.mat.depthFunc = LessEqualDepth;
		this.sceneWorld.mat.depthWrite = true;

		/* occulusion culling */
		if (BB.client.settings.occlusionCulling === 1) {
			if (BB.client.settings.occlusionCullingDebug) {
				this.sceneOcclusion.overrideMaterial.colorWrite = true;
			} else {
				this.sceneOcclusion.overrideMaterial.colorWrite = false;
			}

			this.sceneOcclusion.traverse(function (child) {
				child.isInFrustum = false;
			});

			// disable sorting for occlusion queries
			const oldSortState = renderer.sortObjects;
			renderer.sortObjects = false;
			renderer.render(this.sceneOcclusion, this.camera);
			renderer.sortObjects = oldSortState;
		}

		// if any wboit meshes are visible, do wboit passes
		if (anyWboitMeshVisible) {
			// copy scene target depth buffer to wboit base target
			renderer.setRenderTarget(this.wboitBaseTarget);
			renderer.clear();
			if (renderToCanvas) {
				this.wboitCopyDepthPass.material.uniforms.depthTexture.value =
					this.depthPrepassTarget.depthTexture;
				this.wboitCopyDepthPass.render(renderer, this.wboitBaseTarget, null);
			} else {
				this.wboitCopyDepthPass.material.uniforms.depthTexture.value = this.sceneTarget.depthTexture;
				this.wboitCopyDepthPass.render(renderer, this.wboitBaseTarget, this.sceneTarget);
			}
			renderer.clearColor();

			// render wboit objects to wboit base target, accumulation pass
			this.#prepareWboitBlending(WboitStages.Acummulation);
			this.camera.layers.disableAll();
			this.camera.layers.enable(Layers.ORDER_INDEPENDENT_TRANSPARENCY);
			this.camera.layers.enable(Layers.LIGHTS);
			renderer.render(this.sceneWorld, this.camera);
			this.camera.layers.enableAll();

			// copy wboit base target to wboit accumulation target
			this.wboitCopyPass.render(renderer, this.wboitAccumulationTarget, this.wboitBaseTarget);

			// save current renderer clear color and alpha
			const beforeOITClearAlpha = renderer.getClearAlpha();
			renderer.getClearColor(this.beforeOITClearColor);

			// clear color from wboit base target, retain depth buffer
			renderer.setRenderTarget(this.wboitBaseTarget);
			renderer.setClearColor(CLEAR_COLOR_ONE, 1.0);
			renderer.clearColor();

			// render wboit objects, revealage pass
			this.#prepareWboitBlending(WboitStages.Revealage);
			this.camera.layers.disableAll();
			this.camera.layers.enable(Layers.ORDER_INDEPENDENT_TRANSPARENCY);
			this.camera.layers.enable(Layers.LIGHTS);
			renderer.render(this.sceneWorld, this.camera);
			this.camera.layers.enableAll();

			// composite wboit objects
			// this.wboitAccumulationTarget.texture holds accumulation render
			this.wboitCompositePass.uniforms["tAccumulation"].value = this.wboitAccumulationTarget.texture;

			// this.wboitBaseTarget.texture now holds revealage render
			this.wboitCompositePass.uniforms["tRevealage"].value = this.wboitBaseTarget.texture;

			// render to scene target
			if (renderToCanvas) this.wboitCompositePass.render(renderer, null);
			else this.wboitCompositePass.render(renderer, this.sceneTarget);

			// set wboit stage to normal
			this.#prepareWboitBlending(WboitStages.Normal);

			// restore original renderer clear color and alpha before OIT
			renderer.setClearColor(this.beforeOITClearColor, beforeOITClearAlpha);
		}

		// set render target to the scene target
		if (renderToCanvas) renderer.setRenderTarget(null);
		else renderer.setRenderTarget(this.sceneTarget);

		// clear oit object caches
		this.beforeOITBlendingCache.clear();
		this.beforeOITBlendEquationCache.clear();
		this.beforeOITBlendSrcCache.clear();
		this.beforeOITBlendDstCache.clear();

		/* debug draw meshes */
		if (BB.client.debug.debugVisualsEnabled) {
			renderer.render(this.sceneDebug, this.camera);
		}

		if (this.camera.is1stPerson()) {
			/* render first person scene */

			// move lights objects to FP scene as three.js doesn't allow same object to be in multiple scenes
			this.#moveLightsToScene(this.sceneFP);

			renderer.clearDepth();

			renderer.render(this.sceneFP, this.camera);

			// move lights back to world scene
			this.#moveLightsToScene(this.sceneWorld);
		}

		if (!renderToCanvas) {
			// copy scene target to canvas without any opacity, do srgb conversion and tonemapping

			const isFXAA = BB.client.settings.aaSetting === AA_SETTING.FXAA;
			this.outputPass.render(renderer, isFXAA ? this.outputTarget : null, this.sceneTarget.texture);

			if (isFXAA) {
				// render antialiasing pass to canvas
				// FXAA is engineered to be applied towards the end of engine post processing
				// after conversion to low dynamic range and conversion to the sRGB color space for display

				this.fxaaPass.render(renderer, null, this.outputTarget);
			}
		} else {
			renderer.copyFramebufferToTexture(this.frameTexture, VECTOR_2_ZERO);

			this.outputPass.render(renderer, null, this.frameTexture);
		}

		/* render hud scene */

		// render 2D scene on top
		// renderOrders
		// 0. default
		// 1. vignette
		// 2. crosshair
		renderer.clearDepth();
		renderer.render(this.sceneHUD, this.camera2D);

		/* render ui */

		renderer.clearDepth();

		this.camera.layers.set(Layers.UI);

		renderer.render(this.sceneWorld, this.camera);

		this.camera.layers.enableAll();

		const debugClient = BB.client.debug;
		debugClient.setRendererInfo(renderer.info);
	}

	#prepareWboitBlending(stage) {
		this.wboitMeshes.forEach((mesh) => {
			const materials = Array.isArray(mesh.material) ? mesh.material : [mesh.material];

			for (let i = 0; i < materials.length; i++) {
				if (materials[i].wboitEnabled !== true || materials[i].transparent !== true) continue;

				if (materials[i].uniforms && materials[i].uniforms["wboitRenderStage"]) {
					materials[i].uniforms["wboitRenderStage"].value = stage.toFixed(1);
				}

				switch (stage) {
					case WboitStages.Accumulation:
						materials[i].blending = CustomBlending;
						materials[i].blendEquation = AddEquation;
						materials[i].blendSrc = OneFactor;
						materials[i].blendDst = OneFactor;
						materials[i].depthWrite = false;
						materials[i].depthTest = true;

						break;

					case WboitStages.Revealage:
						materials[i].blending = CustomBlending;
						materials[i].blendEquation = AddEquation;
						materials[i].blendSrc = ZeroFactor;
						materials[i].blendDst = OneMinusSrcAlphaFactor;
						materials[i].depthWrite = false;
						materials[i].depthTest = true;

						break;

					default:
						materials[i].blending = this.beforeOITBlendingCache.get(materials[i]);
						materials[i].blendEquation = this.beforeOITBlendEquationCache.get(materials[i]);
						materials[i].blendSrc = this.beforeOITBlendSrcCache.get(materials[i]);
						materials[i].blendDst = this.beforeOITBlendDstCache.get(materials[i]);
				}
			}
		});
	}

	#moveLightsToScene(scene) {
		for (const light of this.lights) {
			scene.add(light);
		}
	}
}
