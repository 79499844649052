import { LoopOnce } from "three";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateWeaponsLanding extends LocomotionOutputState {
	constructor(animMesh, locomotionInput, transitions) {
		super("WeaponsLanding", animMesh, locomotionInput, transitions);

		this.transitionToIdleTime = 0.2;
		this.transitionToJoggingTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToCrouchTime = 0.1;
		this.transitionToDeathTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			this.trackMap.set(
				`${item}_Land`,
				new AnimationTrack(animMesh, `JMGO_${item}_Jump_Land_Static_Forward`, this),
			);
		});

		this.landingAction = this.trackMap.get("Spade_Land");
	}

	startCompute(transitionData) {
		const currentItemAnimations = this.inputState.item;

		if (currentItemAnimations) {
			this.landingAction = this.trackMap.get(`${currentItemAnimations}_Land`);
		} else {
			this.landingAction = this.trackMap.get("Spade_Land");
		}
		this.landingAction.action.reset();
		this.landingAction.action.loop = LoopOnce;

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.landingAction.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			this.landingAction.action.setEffectiveWeight(0);

			return;
		}

		this.updateWeight();
		this.landingAction.action.setEffectiveWeight(this.weight);
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.inputState.isFalling) {
			if (this.inputState.isJumping) {
				outTransitionToState.ptr = map.get("WeaponsJumping");
				outTransitionToState.blendTime = this.transitionToJumpingTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsFalling");
				outTransitionToState.blendTime = this.transitionToFallingTime;
			}

			return true;
		}

		if (
			this.landingAction.action.getRemainingTime() < this.transitionToIdleTime &&
			!this.inputState.isMoving
		) {
			outTransitionToState.ptr = map.get("WeaponsIdle");
			outTransitionToState.blendTime = this.transitionToIdleTime;
			return true;
		}

		if (this.inputState.isCrouching) {
			outTransitionToState.ptr = map.get("WeaponsCrouchStart");
			outTransitionToState.blendTime = this.transitionToCrouchTime;
			return true;
		}

		if (this.inputState.isMoving) {
			if (this.inputState.isSprinting) {
				outTransitionToState.ptr = map.get("WeaponsRunning");
				outTransitionToState.blendTime = this.transitionToRunningTime;
			} else {
				outTransitionToState.ptr = map.get("WeaponsJogging");
				outTransitionToState.blendTime = this.transitionToJoggingTime;
			}
			return true;
		}

		return false;
	}
}
