import { inferAdditionalFields } from "better-auth/client/plugins";
import { createAuthClient } from "better-auth/client";
import { adminClient } from "better-auth/client/plugins";
import { treaty } from "@elysiajs/eden";
import type { Result } from "@jamango/helpers";
import { err, ok } from "@jamango/helpers";
import type { Auth, AppAPI } from "@jamango/content-service/types/index.ts";

const headers: Record<string, string> = {
	"Content-Type": "application/json",
	Accept: "application/json",
};

let _baseUrl = "http://localhost:3010";

let _authClient = createAuthClient({
	plugins: [inferAdditionalFields<typeof Auth>(), adminClient()],
	baseURL: _baseUrl, // the base url of your auth server
});

let _apiClient = treaty<AppAPI>(_baseUrl, {
	fetch: {
		credentials: "include",
	},
	headers() {
		return headers;
	},
});

export const apiClient = {
	get baseUrl() {
		return _baseUrl;
	},

	get api() {
		return _apiClient.api;
	},

	get auth() {
		return _authClient;
	},
	get headers() {
		return headers;
	},
};

export const isResponseSuccess = (response: { status: number }) =>
	(response.status >= 200 && response.status < 300) || response.status === 304;

/**
 * Sets the base URL that all request client request will target.
 *
 * Enabling dev mode adds a header that allows requests to bypass basic auth that
 * is enabled on any public facing but internal/private servers (ie dev/test/staging).
 *
 * @param {string} baseURL The URL of the app server that rest-client should target
 * @param {string | undefined} bypassBasicAuthToken Header token that allows requests to bypass basic auth
 */
export function setBaseURL(baseURL: string, bypassBasicAuthToken?: string, apiToken?: string) {
	if (bypassBasicAuthToken) {
		headers["x-jamango-bypass-basic-auth"] = bypassBasicAuthToken;
	}

	if (apiToken) {
		headers["API_TOKEN"] = apiToken;
	}

	_baseUrl = baseURL;

	_apiClient = treaty<AppAPI>(baseURL, {
		fetch: {
			credentials: "include",
		},
		headers() {
			return headers;
		},
	});

	_authClient = createAuthClient({
		plugins: [inferAdditionalFields<typeof Auth>(), adminClient()],
		baseURL: baseURL,
	});
}

const RETRYABLE_STATUS_CODES = [408, 502, 503, 504];

export type ApiResult<T> = Result<T, { status: number; message: string; cause: unknown }>;

export function apiResponseToResult<T>(
	response:
		| { status: number; data: T; error: null }
		| { status: number; data: null; error: { status: unknown; value: any } },
): ApiResult<T> {
	const success = (response.status >= 200 && response.status < 300) || response.status === 304;

	if (success && !response.error) {
		return ok(response.data);
	}

	const message = JSON.stringify(response.data ?? response.error?.value);

	const error = {
		status: response.status,
		message,
		cause: response,
	};

	return err(error, { retryable: RETRYABLE_STATUS_CODES.includes(response.status) });
}

export type GetSuccessData<TApiMethod extends (...args: any) => Promise<ApiResult<any>>> = NonNullable<
	Awaited<ReturnType<TApiMethod>>["data"]
>;

export function nonNullishQuery<T extends Record<string, unknown>>(query: T) {
	return Object.fromEntries(
		Object.entries(query).filter(([, value]) => value !== undefined && value !== null),
	) as T;
}
