import { netState } from "router/Parallelogram";
import { LoopbackPeer } from "@jamango/ibs";
import { HUDPopup, HUD_DEFAULT_TIME } from "base/dom/HUDPopup.js";
import { HUDPopupClient } from "client/dom/HUDPopup.js";
import { isNullish } from "@jamango/helpers";
import * as Net from "router/Net";

export class HUDPopupRouter {
	constructor(world) {
		const base = (this.base = new HUDPopup(world));
		if (netState.isClient) base.client = new HUDPopupClient(base);
		base.router = this;
	}

	//returns a promise if duration !== -1
	set(recips, popup, duration = HUD_DEFAULT_TIME) {
		const data = [popup, duration];
		let promise;
		const isCharacter = !isNullish(popup?.entityID);

		for (const recip of recips) {
			if (recip instanceof LoopbackPeer) {
				promise = this.setLocal(popup, duration);
			} else {
				Net.send("popup_set", data, recip);
			}
		}

		if (isCharacter) return; //all players go through the dialogue at their own pace so there's no way of knowing when to resolve
		return promise ?? this.setTimerAndClear(popup, duration);
	}

	setLocal(popup, duration = HUD_DEFAULT_TIME) {
		let dialoguePromise;
		if (netState.isClient) dialoguePromise = this.base.client.set(popup, duration);

		return this.setTimerAndClear(popup, dialoguePromise ?? duration);
	}

	setTimerAndClear(popup, duration) {
		const promise = this.base.setTimer(popup, duration);
		return promise?.then(() => {
			if (netState.isClient && promise === this.base.curPromise) this.base.client.clear();
		});
	}

	initCommandListeners() {
		if (netState.isHost) return;

		Net.listen("popup_set", (a) => {
			this.setLocal(...a, true);
		});
	}
}
