import { Vector3, Euler } from "three";
import { DEGRAD } from "base/util/math/Math.ts";

export const ItemSpade = {
	name: "ItemSpade",
	display: "Wild West Spade",
	assets: [
		{
			url: "assets/engine/mods/ItemSpade/mdl-item-spade.glb",
			id: "mdl-item-spade",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-spade",
		animated: true,
		node: "item",
		transform: {
			pos: new Vector3(-0.076, 0, 0),
			rot: new Euler(90 * DEGRAD, -16 * DEGRAD, 90 * DEGRAD),
			scl: 0.01,
		},
	},
	animations: "Spade",
	shootAnimationName: {
		RigDefault: {
			first: { hip: "JMGO_Spade_FP_NotAim_Shoot" },
			third: "JMGO_Spade_Shoot_Forward",
		},
	},
	geom: { type: "box", width: 0.25, height: 1.2, depth: 0.25 },
	type: "melee",
	weapon: true,
	ejectImpulse: 0.3,
	cooldown: 0.5,
	entityDamage: 20,
	labelOffsetY: 1,
};
