import type { BlockType } from "base/world/block/Type";
import type { Entity } from "base/world/entity/Entity";
import * as BlockTypeRegistryClient from "client/world/block/BlockTypeRegistry";
import type { ItemType } from "mods/defs/ItemType";
import { netState } from "router/Parallelogram";
import type { Mesh } from "three";

export const ITEM_BLOCK_SCL = 0.8;

export class ItemBlockComponent {
	name = "block";

	def: {
		block: BlockType;
		mesh: Mesh | null;
	};
	/**
	 * @param {import('mods/defs/ItemType').ItemType} o
	 * @param {import('base/world/entity/Entity').Entity} entity
	 */
	constructor(o: ItemType, entity: Entity) {
		this.def = {
			block: entity.world.blockTypeRegistry.blockNameToType.get(o.block!)!,
			mesh: null,
		};

		if (netState.isClient) {
			const mesh = BlockTypeRegistryClient.createBlockTypeMesh(this.def.block, entity.world.scene);
			mesh.scale.setScalar(ITEM_BLOCK_SCL);
			mesh.updateMatrix();
			mesh.matrixAutoUpdate = false;
			entity.object3D.add(mesh);
			this.def.mesh = mesh;
		}
	}
}
