export function camelCasetoTitleCase(text: string) {
	const result = text.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export function camelCaseToCapitalize(text: string) {
	const titleCase = camelCasetoTitleCase(text);
	return titleCase.charAt(0).toUpperCase() + titleCase.slice(1).toLowerCase();
}

export function toTitleCase(str: string) {
	return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}

/**
 * Snake Case to Title Case
 * Source: https://stackoverflow.com/questions/64489395/converting-snake-case-string-to-title-case
 */
export function snakeCaseToTitleCase(text: string) {
	return text
		.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
		.replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
}

export function snakeCaseToCamelCase(text: string) {
	return text.replace(/([-_][a-z0-9])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
}

export function camelCaseToSnakeCase(text: string) {
	return text.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export function isColor(str?: string | null) {
	return /^#[0-9A-F]{6}$/i.test(str ?? "");
}
