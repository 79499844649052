import { GlobalTransitionCondition } from "client/world/entity/locomotion/GlobalTransitionCondition.js";

export class TransitionToDeathStartTP extends GlobalTransitionCondition {
	constructor() {
		super({
			destinationState: "DeathStart",
			transitionTime: 0.2,
		});
	}

	shouldTransition(outTransitionToState, map, inputState) {
		if (inputState.isDead) {
			outTransitionToState.ptr = map.get(this.destinationStateName);
			outTransitionToState.blendTime = this.transitionTime;
			return true;
		}
	}
}
