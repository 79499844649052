export class DOMListener {
	static EVENT_TYPE = {
		state: "engineState",
	};

	static sendEvent(eventType, args = {}) {
		document.dispatchEvent(new CustomEvent(eventType, { detail: args }));
	}

	// Events
	static triggerState(state, resolve, reject) {
		DOMListener.sendEvent(DOMListener.EVENT_TYPE.state, {
			state,
			resolve,
			reject,
		});
	}

	static removeEngineListeners() {
		if (typeof window === "undefined") return;
		if (window._listeners == null) return;

		Object.values(window._listeners).forEach((listener) =>
			window.removeEventListener(listener.type, listener.listener),
		);
		delete window._listeners;
	}
}
