import {
	assertNever,
	createLogger,
	createRequestPromise,
	delay,
	Topic,
	type IPromise,
} from "@jamango/helpers";
import type {
	ClientMessages,
	MethodNames,
	MethodParamsByName,
	MethodParamsByNameAsArgs,
	MethodResultByName,
	PubEventByName,
	PubNames,
	PubParamsByName,
	PubParamsByNameAsArgs,
	ServerMessages,
} from "./protocol";
import { ProtocolError } from "./protocol";
import { generateShortId } from "@jamango/content-client";
import { SHORT_HASH } from "@jamango/generated";

const logger = createLogger("ClientContext");

type ClientSubContext<TName extends PubNames> = {
	id: string;
	name: TName;
	params: PubParamsByName<TName>;
	error?: ProtocolError;
	listeners: Set<(event: PubEventByName<TName>) => void>;
	disposers: Set<(error?: ProtocolError) => void>;
	errorListeners: Set<(error: ProtocolError) => void>;
	disposedPromise: Promise<ProtocolError | undefined>;
};

type ClientMethodContext<TName extends MethodNames> = {
	id: string;
	name: TName;
	params: MethodParamsByName<TName>;
	error?: ProtocolError;
	result?: MethodResultByName<TName>;
	resolvedPromise: Promise<MethodResultByName<TName>>;
};

type Disposer = () => void;

export type ClientSubscription<TPubName extends PubNames> = Pick<
	ClientSubContext<TPubName>,
	"id" | "name" | "params" | "disposedPromise"
> & {
	unsubscribe: () => void;
	listen: (listener: (event: PubEventByName<TPubName>) => void) => Disposer;
	onDispose: (cb: () => void) => Disposer;
	onError: (cb: (error: ProtocolError) => void) => Disposer;
};

type Connection = { type: "online" | "offline"; send: (data: ClientMessages) => void };

export class ClientContext {
	session?: string;
	buildVersion?: string;

	readonly onConnectionChanged = new Topic();
	readonly onConnectSuccessed = new Topic();
	readonly onVersionMismatch = new Topic();
	readonly onConnectFailed = new Topic();
	readonly onEventReceived = new Topic<
		[
			{
				sub: ClientSubContext<PubNames>;
				result: PubEventByName<PubNames>;
			},
		]
	>();
	readonly onSubErrorOccured = new Topic<
		[
			{
				sub: ClientSubContext<PubNames>;
				error: ProtocolError;
			},
		]
	>();
	readonly onMethodErrorOccured = new Topic<
		[
			{
				method: ClientMethodContext<MethodNames>;
				error: ProtocolError;
			},
		]
	>();

	readonly #subs: Map<string, ClientSubContext<PubNames>> = new Map();
	readonly #calls: Map<string, ClientMethodContext<MethodNames>> = new Map();

	#messagesQueue: ServerMessages[] = [];

	get connectionType() {
		return this.connection.type;
	}

	constructor(private connection: Connection) {
		this.#handleQueue();
	}

	setConnection(connection: Connection) {
		logger.debug(`Set connection '${connection.type}'`);
		this.connection = connection;

		this.onConnectionChanged.emit();
	}

	receiveMessage(msg: ServerMessages) {
		this.#messagesQueue.push(msg);
	}

	call<TName extends MethodNames, TParams extends MethodParamsByNameAsArgs<TName>>(
		name: TName,
		...params: TParams
	): Promise<MethodResultByName<TName>> {
		const resolvedPromise = createRequestPromise<MethodResultByName<TName>>();

		const call = {
			id: generateShortId(),
			name,
			params: params[0] as MethodParamsByName<TName>,
			resolvedPromise,
		} satisfies ClientMethodContext<TName>;

		logger.debug(`Make call ${call.name}(${call.id}):`, params);

		resolvedPromise
			.then((result) => {
				logger.debug(
					`Call result ${call.name}(${call.id}):`,
					result === null
						? "null"
						: result === undefined
							? "void"
							: JSON.stringify(result).substring(0, 100),
				);
			})
			.catch((error) => {
				logger.debug(`Call error ${call.name}(${call.id}):`, error);
			});

		this.#calls.set(call.id, call);

		this.#send({
			type: "method",
			id: call.id,
			name: call.name,
			params: call.params,
		});

		return resolvedPromise;
	}

	async safeCall<TName extends MethodNames, TParams extends MethodParamsByNameAsArgs<TName>>(
		name: TName,
		...params: TParams
	) {
		try {
			const result = await this.call(name, ...params);

			return {
				status: 200,
				error: null,
				data: result,
			} as const;
		} catch (e: any) {
			return {
				status: 400,
				data: null,
				error: {
					status: 400,
					value: e.toString(),
				},
			} as const;
		}
	}

	subscribe<TName extends PubNames, TParams extends PubParamsByNameAsArgs<TName>>(
		name: TName,
		...params: TParams
	): ClientSubscription<TName> {
		const disposedPromise = createRequestPromise<undefined | ProtocolError>();

		const sub = {
			id: generateShortId(),
			name,
			params: params[0] ?? {},
			listeners: new Set(),
			disposers: new Set([disposedPromise.resolve]),
			errorListeners: new Set(),
			disposedPromise,
		} satisfies ClientSubContext<TName>;

		this.#subs.set(sub.id, sub);

		logger.debug(`Subscribe to ${sub.name}(${sub.id}):`, params);

		this.#send({
			type: "sub",
			id: sub.id,
			params: sub.params,
			name: sub.name,
		});

		return {
			id: sub.id,
			name: sub.name,
			params: sub.params,
			disposedPromise: sub.disposedPromise,
			unsubscribe: () => {
				this.unsubscribe(sub.id);
			},
			listen: (listener: (event: PubEventByName<TName>) => void) => {
				sub.listeners.add(listener);

				return () => sub.listeners.delete(listener);
			},
			onDispose: (cb: () => void) => {
				sub.disposers.add(cb);

				return () => sub.disposers.delete(cb);
			},
			onError: (cb: (error: ProtocolError) => void) => {
				sub.errorListeners.add(cb);

				return () => sub.errorListeners.delete(cb);
			},
		};
	}

	unsubscribe(id: string) {
		const sub = this.#subs.get(id);
		if (!sub) return;

		logger.debug(`Sub ${sub.name}(${id}) has been disposed!`);

		this.#subs.delete(id);
		sub.listeners.clear();
		for (const disposer of sub.disposers) {
			disposer(sub.error);
		}
		sub.disposers.clear();
		sub.errorListeners.clear();

		this.#send({
			type: "unsub",
			id,
		});
	}

	dispose() {
		for (const id of this.#subs.keys()) {
			this.unsubscribe(id);
		}
		this.#subs.clear();

		logger.debug(`Session ${this.session} Disposed. Lost messages: ${this.#messagesQueue.length}`);
		this.#messagesQueue.length = 0;
		this.session = undefined;
		this.buildVersion = undefined;
	}

	#send(msg: ClientMessages) {
		this.connection.send(msg);
	}

	async #handleQueue() {
		while (true) {
			const msg = this.#messagesQueue.shift();
			if (msg) {
				try {
					await this.#onMessage(msg);
				} catch (e) {
					logger.error(e);
				}
			}

			if (this.#messagesQueue.length === 0) {
				await delay(50);
			}
		}
	}

	#onMessage(msg: ServerMessages) {
		const msgType = msg.type;
		switch (msgType) {
			case "connected": {
				this.session = msg.session;
				this.buildVersion = msg.buildVersion;
				this.onConnectSuccessed.emit();

				if (this.buildVersion !== SHORT_HASH) {
					this.onVersionMismatch.emit();
				}
				return;
			}
			case "failed": {
				this.onConnectFailed.emit();
				logger.error(`Can't connect to Protocol`);
				return;
			}
			case "event": {
				const sub = this.#subs.get(msg.id);
				if (sub) {
					this.onEventReceived.emit({
						sub,
						result: msg.result,
					});

					for (const listener of sub.listeners) {
						listener(msg.result);
					}
				} else {
					// TODO ignore?
					//	throw new Error(`Unknown sub ${msg.id}`);
				}

				return;
			}

			case "sub": {
				const sub = this.#subs.get(msg.id);
				if (sub) {
					if (msg.error) {
						const e = new ProtocolError(msg.error.error, msg.error.message, msg.error.reason);
						e.name = msg.error.name;
						sub.error = e;

						this.onSubErrorOccured.emit({
							sub,
							error: e,
						});

						for (const listener of sub.errorListeners) {
							listener(e);
						}
					}
				}

				if (msg.msg === "disposed" || msg.msg === "no-sub") {
					this.unsubscribe(msg.id);
				}
				return;
			}
			case "result": {
				const call = this.#calls.get(msg.id);
				if (!call) {
					// we don't have any listeners for it
					return;
				}

				const promise = call.resolvedPromise as IPromise<any>;

				if (msg.error) {
					const e = new ProtocolError(msg.error.error, msg.error.message, msg.error.reason);
					e.name = msg.error.name;
					call.error = e;

					this.onMethodErrorOccured.emit({
						method: call,
						error: e,
					});

					promise.reject(e);
				} else {
					promise.resolve(msg.result);
				}
				return;
			}
			case "ping": {
				return;
			}
			default:
				assertNever(msgType);
		}
	}
}
