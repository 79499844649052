import { z } from "zod";
import type { IBlockTexture } from "../types";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export const BlockTextureSchema = z.object({
	pk: assetKeySchema(AssetType.BLOCK_TEXTURE),
	type: z.literal(AssetType.BLOCK_TEXTURE),
	id: z.string(),
	name: z.string(),
	resourcePk: assetKeySchema(AssetType.RESOURCE),
	updatedAt: z.string(),
	createdAt: z.string(),
	packageId: PackageIdSchema.optional(),
});

export const all = (value: IBlockTexture) => {
	BlockTextureSchema.parse(value);
};
