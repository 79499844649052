import type { IProp } from "@jamango/content-client";
import { PropColliderType, PropMotionType } from "@jamango/content-client";
import * as Physics from "base/world/Physics";
import { Vector3 } from "three";

export type PropCollider = IProp["physics"]["collider"];

const hashCollider = (collider: PropCollider) => {
	if (collider.type === PropColliderType.BLOCK_STRUCTURE) {
		return `${PropColliderType.BLOCK_STRUCTURE}#${collider.blockStructurePk}#${collider.blockStructureColliderType}`;
	}

	return PropColliderType.NONE.toString();
};

export const mapPropMotionType = (propMotionType: PropMotionType) => {
	switch (propMotionType) {
		case PropMotionType.STATIC:
			return Physics.MotionType.STATIC;
		case PropMotionType.DYNAMIC:
			return Physics.MotionType.DYNAMIC;
		case PropMotionType.KINEMATIC:
			return Physics.MotionType.KINEMATIC;
		default:
			throw new Error(`Invalid prop motion type: ${propMotionType}`);
	}
};

export class PropPhysicsComponent {
	name = "propPhysics";

	def: {
		// serialized
		collider: PropCollider;
		motionType: Physics.MotionType;
		mass: number;
		friction: number;
		restitution: number;
		scale: number;
		dof: Physics.DegreesOfFreedom;

		// non-serialized
		colliderHash: string;
	};

	state: {
		// serialized
		linearVelocity: Vector3;
		angularVelocity: Vector3;

		// non-serialized
		colliderHash: string;
		propPhysics?: Physics.PropPhysics | undefined;
		body: Physics.PhysicsBody | undefined;
		desiredCollisionEnabled: boolean;
		collisionEnabled: boolean | null;
		desiredMotionType: Physics.MotionType;
		motionType: Physics.MotionType | null;
		mass: number;
		friction: number;
		restitution: number;
		scale: number;
		dof: number;
		active: boolean;
	};

	constructor(_o: any, _entity: any) {
		const collider: PropCollider = {
			type: PropColliderType.NONE,
		};

		this.def = {
			collider,
			colliderHash: hashCollider(collider),
			motionType: Physics.MotionType.STATIC,
			mass: 0,
			friction: 0.5,
			restitution: 0,
			scale: 1,
			dof: Physics.DegreesOfFreedom.ALL,
		};

		this.state = {
			colliderHash: "",
			desiredCollisionEnabled: false,
			collisionEnabled: null,
			desiredMotionType: Physics.MotionType.STATIC,
			motionType: null,
			propPhysics: undefined,
			body: undefined,
			linearVelocity: new Vector3(),
			angularVelocity: new Vector3(),
			mass: this.def.mass,
			friction: this.def.friction,
			restitution: this.def.restitution,
			scale: 1,
			dof: Physics.DegreesOfFreedom.ALL,
			active: true,
		};
	}

	updateCollider(collider: PropCollider) {
		this.def.collider = collider;
		this.def.colliderHash = hashCollider(collider);
	}
}
