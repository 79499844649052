import type { Entity } from "base/world/entity/Entity";

// Simply set mounted entity positions to the parent position for now.
// Do this so that nodes like "Entity Get Position" return reasonable results, not 0,0,0
// We will likely revisit this behaviour in future when adding support for more general entity
// parenting support.
// When items are mounted to characters, the position of the entity and the position of the object3d will be different.
// Dedi isn't aware of character skeletons currently, so the exact positioning is currently just a client sided concern.

export function entityMountedTransformUpdate(entity: Entity) {
	if (!entity.mount.state.parent) return;

	entity.position.copy(entity.mount.state.parent.position);
	entity.quaternion.copy(entity.mount.state.parent.quaternion);
}
