import { DEGRAD } from "base/util/math/Math.ts";
import { Color, Euler, Vector3 } from "three";
import { OnHitPlayerParticlesDefault } from "./DefaultParticles";

const s = new Vector3(0.5, 0.5, 2); //collider size

export const ItemZapperRifle = {
	name: "ItemZapperRifle",
	display: "Zapper Rifle",
	assets: [
		{
			id: "mdl-item-zapper-rifle",
			url: "assets/engine/mods/ItemZapperRifle/mdl-item-zapper-rifle.glb",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-zapper-rifle",
		node: "item",
		animated: true,
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	muzzleFlashNode: "muzzle_flash_GEO",
	geom: { type: "box", width: s.x, height: s.y, depth: s.z },
	type: "ranged",
	weapon: true,
	automatic: false,
	animations: "ZapperRifle",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_ZapperRifle_FP_NotAim_Shoot",
				sight: "JMGO_ZapperRifle_FP_Aim_Shoot",
			},
			third: "JMGO_ZapperRifle_Shoot_Forward",
		},
	},
	reloadAnimationName: {
		item: "JMGO_ZapperRifle_Reload_rig",
		RigDefault: {
			third: "JMGO_ZapperRifle_Reload",
			first: "JMGO_ZapperRifle_FP_NotAim_Reload",
		},
	},
	reloadAnimationTime: 2.5,
	cooldown: 0.5,
	projectile: "ProjectileZapperRifle",
	projectilePos: new Vector3(0, 85, -10),
	projectileCartridgeLimit: 6,
	projectilesPerShot: 1,
	shakeVertical: 6,
	shakeVerticalMax: 12,
	shakeRecovery: 0.9,
	spread: 1,
	recoil: 0.07,
	sound: {
		fire: { asset: "snd-item-zap-rifle-fire", volume: 5 },
		reload: { asset: "snd-item-zap-rifle-reload", volume: 2 },
	},
	/** @type {(Partial<import("client/world/fx/ParticleEngine").ParticleParameters> & {velocityAimDirectionIntensity:number;})[]} */
	muzzleFlashParticles: [
		{
			colors: [...new Color("#fb75ff").toArray(), 0.5],
			type: "cube",
			numParticles: 50,
			positionRange: [0.2, 0.2, 0.2],
			lifeTime: 1.5,
			endTime: 1.5,
			startTime: 0,
			startSize: 0.25,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			velocityAimDirectionIntensity: 3,
			velocityRange: [1, 1, 1],
			velocity: [0, 0, 0],
			acceleration: [0, 0, 0],
			accelerationRange: [1, 0.1, 1],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.1,
		},
	],
};

const hitParticlesColor = new Color("#fb75ff");

export const ProjectileZapperRifle = {
	name: "ProjectileZapperRifle",
	velocity: 200,
	entityDamage: 90,
	headshotDamage: 210,
	sound: {
		impact: { asset: "snd-laser-impact" },
	},
	bulletTrail: true,
	bulletTrailColor: "#fb75ff",
	/** @type {(Partial<import("client/world/fx/ParticleEngine").ParticleParameters> & {velocityHitNormalIntensity?:number})[]} */
	onHitParticles: [
		// smoke
		{
			colors: [
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0.1,
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0,
			],
			type: "cube",
			numParticles: 10,
			positionRange: [0.2, 0.1, 0.2],
			lifeTime: 3,
			endTime: 3,
			startTime: 0,
			startSize: 0.5,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			radius: 0.15,
			velocity: [0, 0, 0],
			velocityRange: [0.2, 0.05, 0.2],
			acceleration: [0, 0.05, 0],
			accelerationRange: [0.3, 0, 0.3],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.05,
		},
		// impact
		{
			colors: [
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				1,
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0,
			],
			type: "round",
			blending: "normal",
			numParticles: 10,
			positionRange: [0.1, 0, 0.1],
			lifeTime: 0.3,
			startTime: 0,
			startSize: 0.5,
			endTime: 0.3,
			endSize: -0.15,
			sizeRange: 0.15,
			velocityHitNormalIntensity: 3.5,
			velocityRange: [1, 1, 1],
		},
	],
	onHitPlayerParticles: OnHitPlayerParticlesDefault,
};
