import { node } from "base/rete/Types";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { addSelectGroup, addWhoCanTrigger } from "base/rete/NodeSharedUtil";

export const BLOCK_TRIGGERS = [
	node({
		id: "0000-00-0001",
		name: "On Interact With Block",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description: "Triggered once the character has interacted with a block.",
		controls: {
			...addWhoCanTrigger(),
		},
		inputs: {
			group: addSelectGroup(),
			label: {
				name: "Label Text",
				type: "string",
				control: "string",
				optional: true,
				config: {
					defaultValue: "Interact",
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return { character: ctx.info.character, position: ctx.info.position };
		},
	}),
	node({
		id: "0000-00-0002",
		name: "On Block Sculpt",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description:
			"Block's shape was set to something other than air. If a block's type instantly changes (without breaking it first), both break and build will fire in that order",
		controls: {
			...addWhoCanTrigger(),
		},
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return { character: ctx.info.character, position: ctx.info.position };
		},
	}),
	node({
		id: "0000-00-0003",
		name: "On Block Break",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description:
			"Block's shape was set to something other than air. If a block's type instantly changes (without breaking it first), both break and build will fire in that order",
		controls: {
			...addWhoCanTrigger(),
		},
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return { character: ctx.info.character, position: ctx.info.position };
		},
	}),
	node({
		id: "0000-00-0004",
		name: "On Block Build",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description:
			"Block's shape was set to something other than air. If a block's type instantly changes (without breaking it first), both break and build will fire in that order",
		controls: {
			...addWhoCanTrigger(),
		},
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return { character: ctx.info.character, position: ctx.info.position };
		},
	}),
	node({
		id: "0000-00-0005",
		name: "On Block Spawn",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description:
			'Triggered when the world first loads, after saving events, after a block is placed, or manually via a "Block Respawn" node.',
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block/Group Position", type: "vector3" },
		},
		resolve(input, ctx) {
			return { position: ctx.info.position };
		},
	}),
];
