import { BB } from "base/BB";
import { CONTROL_CONTEXTS, evaluateContext } from "client/world/PlayerContext.js";
import { UI } from "client/dom/UI";
import { isNullish } from "@jamango/helpers";

export class SettingsClient {
	constructor() {
		this.renderDistance = UI.state.settings().renderDistance;
		this.entityRenderDistance = UI.state.settings().entityRenderDistance;
		this.sensitivity = UI.state.settings().sensitivity;
		this.pixelRatio = UI.state.settings().pixelRatio;
		this.askBeforeQuitting = UI.state.settings().askBeforeQuitting;
		this.occlusionCulling = UI.state.settings().occlusionCulling;
		this.zPrepassEnabled = UI.state.settings().zPrepassEnabled;
		this.aaSetting = UI.state.settings().aaSetting;
		this.key = UI.state.keyboard().init();
		this.sortKeys();
	}

	init() {
		this.sync(this.getState());
	}

	getState() {
		return {
			renderDistance: this.renderDistance,
			entityRenderDistance: this.entityRenderDistance,
			sensitivity: this.sensitivity,
			pixelRatio: this.pixelRatio,
			askBeforeQuitting: this.askBeforeQuitting,
			occlusionCulling: this.occlusionCulling,
			zPrepassEnabled: this.zPrepassEnabled,
			aaSetting: this.aaSetting,
		};
	}

	sync(settings) {
		if (!isNullish(settings.renderDistance)) this.renderDistance = settings.renderDistance;
		if (!isNullish(settings.entityRenderDistance))
			this.entityRenderDistance = settings.entityRenderDistance;
		if (!isNullish(settings.sensitivity)) this.sensitivity = settings.sensitivity;
		if (!isNullish(settings.pixelRatio)) {
			this.pixelRatio = settings.pixelRatio;

			const maxPixelRatio =
				(BB.client.inputPoll.isMobileBrowser()
					? Math.min(window.devicePixelRatio, 1.5)
					: window.devicePixelRatio) ?? 1;

			const combined = this.pixelRatio * maxPixelRatio;
			BB.client.renderer.setPixelRatio(combined);
			BB.client.inputPoll.setPixelRatio(combined);
		}
		if (!isNullish(settings.zPrepassEnabled)) {
			this.zPrepassEnabled = !!settings.zPrepassEnabled;
		}
		if (!isNullish(settings.aaSetting)) {
			this.aaSetting = settings.aaSetting;
		}
		if (!isNullish(settings.askBeforeQuitting)) {
			this.askBeforeQuitting = !!settings.askBeforeQuitting;
			window.onbeforeunload = function () {
				return settings.askBeforeQuitting ? true : null;
			};
		}
		if (!isNullish(settings.occlusionCulling)) this.occlusionCulling = settings.occlusionCulling;
	}

	bindKeys(keys) {
		for (const [key, value] of Object.entries(keys)) {
			this.key[key] = value;
		}

		this.sortKeys();
	}

	sortKeys() {
		const sortedByComboLength = Object.entries(this.key).sort(
			([, aKeys], [, bKeys]) => bKeys.length - aKeys.length,
		);

		this.sortedKeys = [];

		for (const [control, keys] of sortedByComboLength) {
			this.sortedKeys.push({ control, keys });
		}
	}

	useKeyControl(control) {
		switch (control) {
			case "fullscreen": {
				UI.state.settings().toggleFullscreen();
				break;
			}
			case "camera": {
				UI.state.controls().toggleCameraPerspective();
				break;
			}
			case "sculpt": {
				UI.state.controls().toggleSculptMode();
				break;
			}
			case "selectorToggleSelectionMode": {
				UI.state.controls().selectorToggleSelectionMode(true);
				break;
			}
			case "chat": {
				UI.state.chat().openChat();
				break;
			}
			case "hub": {
				UI.state.hub().toggle();
				break;
			}
			case "editor": {
				UI.state.editorModal().toggle();
				break;
			}
			case "inventory": {
				UI.state.inventory().toggle();
				break;
			}
			// TODO: items/defs should be able to define their own controls
			// https://www.notion.so/novablocksgame/Contextual-Controls-383396fc59c94f55a3214d894434ab0f
			case "wrenchRemoveBlockGroups": {
				UI.state.controls().wrenchRemoveBlockGroups();
				break;
			}
			case "selectorCycleScriptTargets": {
				UI.state.controls().selectorCycleScriptTargets();
				break;
			}
			case "cut": {
				UI.state.controls().cut();
				break;
			}
			case "copy": {
				UI.state.controls().copy();
				break;
			}
			case "paste": {
				UI.state.controls().paste();
				break;
			}
			case "pasteImmediately": {
				UI.state.controls().pasteImmediately();
				break;
			}
			case "pencilSolidFill": {
				UI.state.controls().pencilSolidFill();
				break;
			}
			case "pencilHollowFill": {
				UI.state.controls().pencilHollowFill();
				break;
			}
			case "pencilRemoveAllBlocks": {
				UI.state.controls().pencilRemoveAllBlocks();
				break;
			}
			case "pencilRotateClipboard": {
				UI.state.controls().pencilRotateClipboard();
				break;
			}
			case "pencilFlipClipboard": {
				UI.state.controls().pencilFlipClipboard();
				break;
			}
			case "pencilCreateBlockStructure": {
				UI.state.controls().pencilCreateBlockStructure();
				break;
			}
			case "selectorNudgeLeft": {
				UI.state.controls().selectorNudgeLeft();
				break;
			}
			case "selectorNudgeRight": {
				UI.state.controls().selectorNudgeRight();
				break;
			}
			case "selectorNudgeUp": {
				UI.state.controls().selectorNudgeUp();
				break;
			}
			case "selectorNudgeDown": {
				UI.state.controls().selectorNudgeDown();
				break;
			}
			case "selectorNudgeForward": {
				UI.state.controls().selectorNudgeForward();
				break;
			}
			case "selectorNudgeBack": {
				UI.state.controls().selectorNudgeBack();
				break;
			}
			case "selectorResetBulkSelection": {
				UI.state.controls().selectorResetBulkSelection();
				break;
			}
			case "undo": {
				UI.state.controls().undo();
				break;
			}
			case "redo": {
				UI.state.controls().redo();
				break;
			}
			case "zenMode": {
				UI.state.controls().toggleZenMode();
				break;
			}
			case "context": {
				UI.state.gameHud().toggleShowPlayerContextureInfo();
				break;
			}
			case "screenshotCanvas": {
				BB.client.screenshotCanvas();
				break;
			}
			case "worldEditorToggleStartStop": {
				UI.state.worldEditor().toggleStartStop();
				break;
			}
			case "worldEditorStartFromSpawn": {
				UI.state.worldEditor().startTestingFromSpawn();
				break;
			}
			case "worldEditorTogglePauseResume": {
				UI.state.worldEditor().togglePauseResume();
				break;
			}
			case "worldEditorRestart": {
				UI.state.worldEditor().restart();
				break;
			}
		}
	}

	updateKeyboardInput() {
		if (UI.state.controls().shouldIgnoreInput()) return;

		const equippedItem = BB.world.client.getEquippedItem();
		const cameraTarget = BB.world.client.camera.target;

		/**
		 * @type {Array<Array<string>>}
		 */
		const matchedControlKeys = [];

		for (const { control, keys } of this.sortedKeys) {
			if (!this.#keysMatch(keys, matchedControlKeys)) {
				continue;
			}

			const context = CONTROL_CONTEXTS[control];

			if (context) {
				const inContext = evaluateContext({ context, world: BB.world, equippedItem, cameraTarget });
				if (!inContext) continue;
			}

			this.useKeyControl(control);

			matchedControlKeys.push(keys);
		}
	}

	/**
	 * For controls keys to match, the following must be true:
	 * - control keys are just pressed
	 * - for other controls evaluated in the same frame (evaluated in descending order of combo length), there must either be:
	 *   - no overlap between the keys of the controls
	 *   - an exact match for the keys of the controls
	 *
	 * @param {Array<string>} keys keys to check
	 * @param {Array<Array<string>>} matchedControlKeys keys of controls that have already been matched this frame
	 * @returns {boolean}
	 */
	#keysMatch(keys, matchedControlKeys) {
		if (!BB.client.inputPoll.keysAreJustPressed(keys)) {
			return false;
		}

		for (const controlKeys of matchedControlKeys) {
			const keysOverlap = keys.some((key) => controlKeys.includes(key));

			if (!keysOverlap) continue;

			const exactMatch =
				keys.length === controlKeys.length && keys.every((key) => controlKeys.includes(key));

			if (!exactMatch) {
				return false;
			}
		}

		return true;
	}
}
