import { z } from "zod";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export const ParticleSystemConfigSchema = z.object({
	duration: z.number().positive(),
	isContinuous: z.boolean(),
	metadata: z.record(z.any()),
	images: z.array(z.object({ url: z.string(), uuid: z.string() })),
	geometries: z.array(z.any()),
	materials: z.array(z.any()),
	textures: z.array(z.any()),
	object: z.record(z.any()),
});

export const ParticleSystemSchema = z.object({
	pk: assetKeySchema(AssetType.PARTICLE_SYSTEM),
	id: z.string(),
	type: z.literal(AssetType.PARTICLE_SYSTEM),
	name: z.string(),
	config: ParticleSystemConfigSchema,
	packageId: PackageIdSchema.optional(),
});
