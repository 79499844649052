export class ItemAnimationsComponent {
	name = "animations";

	/** @param {import('mods/defs/ItemType').ItemType} o */
	constructor(o) {
		this.def = {
			animations: o.animations,
		};
	}
}
