import {
	AUDIO_FILE_PREFIX,
	AUDIO_TYPES,
	BLOCK_TEXTURE_FILE_PREFIX,
	CUSTOM_LOADER_FILE_PREFIX,
	IMAGE_TYPES,
	SKYBOX_FILE_PREFIX,
	THUMBNAIL_FILE_PREFIX,
	UNKNOWN_FILE_EXTENSION,
} from "../constants/files";

export function extractName(filename: string): [string, string | null] {
	let prefix: string | null = null;

	if (filename.includes(BLOCK_TEXTURE_FILE_PREFIX)) {
		prefix = BLOCK_TEXTURE_FILE_PREFIX;
	} else if (filename.includes(SKYBOX_FILE_PREFIX)) {
		prefix = SKYBOX_FILE_PREFIX;
	} else if (filename.includes(THUMBNAIL_FILE_PREFIX)) {
		prefix = THUMBNAIL_FILE_PREFIX;
	} else if (filename.includes(CUSTOM_LOADER_FILE_PREFIX)) {
		prefix = CUSTOM_LOADER_FILE_PREFIX;
	} else if (filename.includes(AUDIO_FILE_PREFIX)) {
		prefix = AUDIO_FILE_PREFIX;
	}

	if (prefix) {
		const regex = new RegExp(`.*${prefix}`);

		return [
			filename.replace(regex, "").split("-").slice(0, -5).join("-") || filename.replace(regex, ""),
			prefix,
		];
	}

	return [filename, prefix];
}

export function getFileType(extension: string) {
	const isFileType = (type: string) => type.split("/").at(-1) === extension;

	if (IMAGE_TYPES.some(isFileType)) {
		return `image/${extension}`;
	}

	if (AUDIO_TYPES.some(isFileType)) {
		return `audio/${extension}`;
	}

	if (extension === "bb") {
		return "application/octet-stream";
	}

	// todo
	return "application/octet-stream";
}

/**
 * See https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
 */
export function renameFile(originalFile: File, name: string) {
	const splitFileName = originalFile.name.split(".");
	const extension = splitFileName[splitFileName.length - 1];
	const parameterizeName = parameterizeString(name);
	const newName = `${parameterizeName}.${extension}`;
	const blob = originalFile.slice(0, originalFile.size, originalFile.type);
	return new File([blob], newName, {
		type: originalFile.type,
		lastModified: originalFile.lastModified,
	});
}

/**
 * Resizes texture to the power of 2 with the same height and width
 */
export async function resizeTexture(texture: File) {
	return await new Promise<File | undefined>((resolve) => {
		const img = new Image();
		const reader = new FileReader();
		reader.onload = (e) => {
			img.src = e.target?.result as string;
			img.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				if (!ctx) return;
				const width = Math.pow(2, Math.ceil(Math.log2(img.width)));
				const height = Math.pow(2, Math.ceil(Math.log2(img.height)));
				const size = Math.min(width, height);
				canvas.width = size;
				canvas.height = size;
				ctx.drawImage(img, 0, 0, size, size);
				canvas.toBlob((blob) => {
					if (!blob) return;
					const resizedTexture = new File([blob], texture.name, {
						type: texture.type,
						lastModified: texture.lastModified,
					});

					resolve(resizedTexture);
				}, texture.type);
			};
		};
		reader.readAsDataURL(texture);
	});
}

/**
 * Get file name without extension
 */
export function getFileNameWithoutExtension(filename: string) {
	if (filename.startsWith("blob:")) {
		const url = new URL(filename);
		return url.searchParams.get("filename") ?? "";
	}

	const fileInDirs = filename.split("/");
	const file = fileInDirs.at(-1);
	if (!file) return filename;

	const names = file.split(".");

	if (names.length < 2) return file;

	names.pop();

	return names.join(".");
}

/**
 * Get file extension
 */
export function getExtension(filename: string) {
	if (filename.startsWith("blob:")) {
		const url = new URL(filename);
		return url.searchParams.get("extension") ?? UNKNOWN_FILE_EXTENSION;
	}

	return filename.split("?").at(0)?.split(".").pop()?.toLowerCase() ?? UNKNOWN_FILE_EXTENSION;
}

/**
 * Format file name without extension
 */
export function formatFileName(filename: string) {
	return parameterizeString(getFileNameWithoutExtension(filename) ?? "");
}

export function parameterizeString(string: string) {
	return string
		.trim()
		.toLowerCase()
		.replace(/\s/g, "-")
		.replace(/[^a-zA-Z0-9-_]/, "");
}

/**
 * Formats bytes to human readable format
 */
export function formatBytes(bytes: number, decimals = 2) {
	if (!+bytes) return "0 bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Calculates byte size of a data
 */
export function getDataByteSize(value: unknown): number {
	if (value instanceof Blob) {
		return value.size;
	} else if (value instanceof ArrayBuffer) {
		return value.byteLength;
	} else if (value instanceof File) {
		return value.size;
	} else if (Array.isArray(value)) {
		return value.reduce((acc, val) => acc + getDataByteSize(val), 0);
	} else if (value === null || value === undefined) {
		return 0;
	} else if (typeof value === "object") {
		return Object.keys(value).reduce((acc, key) => {
			return acc + getDataByteSize(value[key as keyof typeof value]);
		}, 0);
	} else {
		return new Blob([JSON.stringify(value)]).size;
	}
}

export function createUrlFromFile(file: File, name?: string, extension?: string) {
	const fileName = file.name;
	name = name ?? getFileNameWithoutExtension(fileName) ?? "";
	extension = extension ?? getExtension(fileName) ?? "";

	return URL.createObjectURL(file) + `?filename=${name}&extension=${extension}`;
}
