export class GlobalTransitionCondition {
	constructor(o) {
		this.destinationStateName = o.destinationState;
		this.transitionTime = o.transitionTime;
	}

	shouldTransition(_transitionToState, _map) {
		return false;
	}
}
