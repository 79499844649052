import { z } from "zod";
import { SLIDER_MAX_PLAYERS, SLIDER_MIN_PLAYERS } from "../constants/game-mechanics";
import type { IGameMechanics } from "../types";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";

const maxPlayersSchema = z
	.number()
	.min(SLIDER_MIN_PLAYERS, {
		message: `Maximum players must be at least ${SLIDER_MIN_PLAYERS} players.`,
	})
	.max(SLIDER_MAX_PLAYERS, {
		message: "Minimum players cannot exceed set max players.",
	});

const characterCollisionsSchema = z.boolean();

export const maxPlayers = (value: number) => {
	maxPlayersSchema.parse(value);
};

export const characterCollisions = (value: boolean) => {
	const schema = characterCollisionsSchema;

	schema.parse(value);
};

export const GameMechanicsSchema = z.object({
	pk: assetKeySchema(AssetType.GAME_MECHANICS),
	type: z.literal(AssetType.GAME_MECHANICS),
	characterCollisions: characterCollisionsSchema,
	maxPlayers: z.number(),
});

export const all = (gameMechanics: IGameMechanics) => {
	GameMechanicsSchema.parse(gameMechanics);
};
