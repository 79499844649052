import { LoopbackPeer, type Peer } from "@jamango/ibs";
import type { World } from "base/world/World";
import * as ChunkDiscovery from "./block/chunk/ChunkDiscovery";
import { netState } from "router/Parallelogram";
import * as Net from "router/Net";
import { Chunk } from "base/world/block/chunk/Chunk";
import { type BlockStorageComponent } from "./block/chunk/StorageComponent";
import { Vector3 } from "three";
import { type ChunkScene } from "./block/Scene";

export type ChunkManager = {
	discovery: Map<Peer, ChunkDiscovery.DiscoveryState>;
};

export function init(_w: World): ChunkManager {
	return { discovery: new Map() };
}

export const update = (world: World, chunks: ChunkManager, _t: number, _dt: number) => {
	if (!netState.isHost) return;
	const scene = world.scene;
	// on hosts, run the discovery on all peers and network the data
	const peers = world.router.getPeersMap();
	for (const [_, peer] of peers) {
		// alloc if nonexistant
		if (!chunks.discovery.has(peer)) chunks.discovery.set(peer, ChunkDiscovery.init());
	}

	for (const [peer, discovery] of chunks.discovery) {
		// if peer has been deleted in the meanwhile, stop and delete this tracking
		if (!peers.has(peer.peerID)) {
			chunks.discovery.delete(peer);
			continue;
		}

		// get the player. these can be undefined at some times potentially, so simply ignore that state for now.
		const player = world.peerToPlayer.get(peer);
		if (player === undefined) continue;

		const worldPos = player.position.clone();
		ChunkDiscovery.update(scene, worldPos, discovery);

		if (discovery.queue.length === 0) continue;

		// actual building happens here - scene.buildChunk contains early exit if chunk already exists
		discovery.queue.forEach(([x, y, z]) => {
			scene.buildChunk(x, y, z, false);
		});

		if (!(peer instanceof LoopbackPeer)) {
			// if peer, send to local unbuilt-queue
			const payload: ChunkPayload[] = [];
			discovery.queue.forEach(([x, y, z, agg]) => {
				const c = [x, y, z, agg, scene.getChunk(x, y, z).storage.compress()] as ChunkPayload;
				payload.push(c);
			});
			sendChunk.send(payload, peer);
		}

		discovery.queue = [];
	}
};

type ChunkPayload = [number, number, number, number, ReturnType<BlockStorageComponent["compress"]>];

const updateNeighborChunks = (scene: ChunkScene, x: number, y: number, z: number) => {
	const chunk = scene.getChunk(x, y, z);
	if (chunk.neighborCount < 6) {
		// TODO: ideally this should not have to wait for unnecessary chunks. we will have to ship an integer here that tells you how many chunks you should await.
		if (++chunk.neighborCount === 6) {
			chunk.build(false);
		}
	}
};

const sendChunk = Net.command("send chunk", Net.CLIENT, (val: ChunkPayload[], world) => {
	const scene = world.scene;
	const tmpVec = new Vector3();

	// on the client, take incoming chunks data from queue and build them
	for (const [x, y, z, agg, dataString] of val) {
		const data = { data: dataString };
		let c = scene.chunks.get(tmpVec.set(x, y, z));
		if (!c) {
			c = new Chunk(scene, x, y, z, data);
		} else if (data !== null) {
			c.load(data);
		}
		c.storage.aggregate = agg;
		// for every chunk that is added, check all neighbors and increase their neighbor count by 1. if this moves a chunk to 6, we can build that chunk now.
		updateNeighborChunks(scene, x + 1, y, z);
		updateNeighborChunks(scene, x - 1, y, z);
		updateNeighborChunks(scene, x, y + 1, z);
		updateNeighborChunks(scene, x, y - 1, z);
		updateNeighborChunks(scene, x, y, z + 1);
		updateNeighborChunks(scene, x, y, z - 1);
	}
});

// TODO: stop sending all chunks - only those that are relevant, or those 6 neighbors of relevant chunks., which  could be easily pre-computed
// TODO: move data to reside on peer, remove weakmap peer tracking

/* NOTE!
This system is currently responsible for running the refactored "chunk prioritizer" for each peer.
In the future, this system will more extensively handle chunks in general, and slowly replace the multi-purpose "scene"
In general, it will be concerned with the loading and lifetimes of chunks. for now, it will just be networking and discovery.
*/
