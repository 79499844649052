import { isNullish } from "@jamango/helpers";

export class CharacterEquipmentComponent {
	constructor(options, entity) {
		this.name = "equipment";
		this.entity = entity;

		this.def = {
			itemIndex: options?.itemIndex ?? 0,
			itemNode: isNullish(options?.itemNode)
				? null
				: entity.mesh?.mesh.getObjectByName(options.itemNode),
			fpItemNode: isNullish(options?.fpItemNode)
				? null
				: entity.fpmesh?.mesh.getObjectByName(options.fpItemNode),
		};

		if (!entity.mount.def.points[this.def.itemIndex]) {
			entity.defError(`Mount point ${this.def.itemIndex} specified by itemIndex does not exist`);
		}
	}
}
