import { AssetType } from "../types/assets";

export const DEFAULT_CUSTOM_LOADER = {
	type: AssetType.CUSTOM_LOADER,
	title: "",
	description: "",
} as const;

export const MIN_TITLE_LENGTH = 3;
export const MAX_TITLE_LENGTH = 120;
export const MIN_DESCRIPTION_LENGTH = 3;
export const MAX_DESCRIPTION_LENGTH = 240;
