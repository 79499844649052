import { shuffleArray } from "@jamango/helpers";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { CONTROL, generateUUID } from "@jamango/content-client";
import type { NodeDef } from "../Types";

export const LIST: NodeDef[] = [
	// General
	{
		id: "0008-01-0000",
		name: "List",
		type: NODE_TYPE_ID.list,
		description: "List literal",
		predictable: true,
		dynamic: {
			addText: "Add Item",
			inputs: {
				item0: {
					name: "Item",
					type: "string",
					control: "string",
					dynamic: true,
					index: 0,
					reorderable: true,
					removable: true,
				},
			},
			outputs: {
				list: { name: "List", type: "string", structure: "list", dynamic: true },
			},
			add: (index, _def, data) => {
				const id = "item-" + generateUUID();

				const type = (data.type ?? "string") as keyof typeof CONTROL;

				return {
					inputs: {
						[id]: {
							name: "Item",
							type,
							control: CONTROL[type],
							index,
							reorderable: true,
							removable: true,
							dynamic: true,
						},
					},
				};
			},
		},
		controls: {
			type: {
				name: "Type",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						{ label: "String", value: "string" },
						{ label: "Number", value: "number" },
						{ label: "Boolean", value: "boolean" },
						{ label: "Vector3", value: "vector3" },
						{ label: "Entity", value: "entity" },
						{ label: "Any", value: "any" },
					],
					defaultValue: "string",
					onChange: ({ value, node, editor }: any) => {
						const dynamicInputIds = Object.keys(node.def.inputs);
						const dynamicOutputIds = Object.keys(node.def.outputs);

						editor.changeDynamicDefSocketsDataType(
							node.id,
							dynamicInputIds,
							dynamicOutputIds,
							value,
						);
					},
				},
			},
		},
		resolve: (inputs, _ctx, _nodeId, _scope, def) => {
			const list = Object.keys(inputs)
				.filter((key) => key !== "item" && key.startsWith("item"))
				.sort((a, b) => (def.inputs[a].index ?? 0) - (def.inputs[b].index ?? 0))
				.map((key) => inputs[key]);
			return { list };
		},
	},
	{
		id: "0008-01-0001",
		name: "List Get Item By Index",
		type: NODE_TYPE_ID.list,
		description: "Find element in a list by index",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			index: { name: "Index", type: "number", control: "number" },
		},
		outputs: {
			item: { name: "Item", type: "any" },
		},
		resolve(inputs) {
			const { list, index } = inputs;
			const targetElement = list.at(index);

			if (typeof targetElement === "undefined") {
				console.error("Index is out of bounds with the given list");
				return { item: null };
			}

			return { item: targetElement };
		},
	},
	{
		id: "0008-01-0002",
		name: "List Get Index By Item",
		type: NODE_TYPE_ID.list,
		description: "Find index of a given element in a list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			item: { name: "Item", type: "any" },
		},
		outputs: {
			index: { name: "Index", type: "number" },
		},
		resolve(inputs) {
			const { list, item } = inputs;
			const targetIndex = list.indexOf(item);

			if (targetIndex === -1) {
				console.error("Element not found in the given list");
				return { index: null };
			}

			return { index: targetIndex };
		},
	},
	{
		id: "0008-01-0003",
		name: "List Get Random Item",
		type: NODE_TYPE_ID.list,
		description: "Returns a random item in the given list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			item: { name: "Item", type: "any" },
		},
		resolve(inputs) {
			const { list } = inputs;
			const randomItem = list[Math.floor(Math.random() * list.length)];
			return { item: randomItem };
		},
	},
	{
		id: "0008-01-0004",
		name: "List Get Size",
		type: NODE_TYPE_ID.list,
		description: "Find the total count / length of elements inside the list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			count: { name: "Count", type: "number" },
		},
		resolve(inputs) {
			const count = inputs.list.length;
			return { count };
		},
	},
	{
		id: "0008-01-0006",
		name: "List Add",
		type: NODE_TYPE_ID.list,
		description: "Add an element to a list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			item: { name: "Item", type: "any" },
			order: {
				name: "Order",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						{ value: "prepend", label: "Prepend" },
						{ value: "append", label: "Append" },
					],
				},
			},
		},
		outputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list, item, order } = inputs;
			if (order === "prepend") {
				return { list: [item, ...list] };
			} else {
				return { list: [...list, item] };
			}
		},
	},
	{
		id: "0008-01-0008",
		name: "List Remove Index",
		type: NODE_TYPE_ID.list,
		description: "Remove an element to a list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			index: { name: "Index", type: "number", control: "number" },
		},
		outputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list, index } = inputs;

			if (list.length - 1 < index) {
				console.error("Index is out of bounds with the given list");
				return { list };
			}

			const filteredList = list.filter((_item: any, currentIndex: number) => currentIndex !== index);
			return { list: filteredList };
		},
	},
	{
		id: "0008-01-0005",
		name: "List Replace Index",
		type: NODE_TYPE_ID.list,
		description: "Replace an element in a list with a given index",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			index: { name: "Index", type: "number", control: "number" },
			value: { name: "Value", type: "any" },
		},
		outputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list, index, value } = inputs;

			const newList = list.map((item: any, currentIndex: number) => {
				if (currentIndex === index) {
					return value;
				}
				return item;
			});

			return { list: newList };
		},
	},

	// Conversion
	{
		id: "0008-02-0000",
		name: "List To String",
		type: NODE_TYPE_ID.list,
		description: "Joins a list into a single string.",
		predictable: true,
		inputs: {
			list: { name: "List", type: "string", structure: "list" },
			separator: {
				name: "Separator",
				type: "string",
				control: "string",
				config: { defaultValue: ", " },
			},
		},
		outputs: {
			string: { name: "String", type: "string" },
		},
		resolve(inputs) {
			if (!inputs.list?.length) return { string: "" };
			return { string: inputs.list.join(inputs.separator) };
		},
	},

	// Numbers
	{
		id: "0008-03-0000",
		name: "List Get Sum",
		type: NODE_TYPE_ID.list,
		description: "Adds the total sum of numbers inside the list",
		predictable: true,
		keywords: ["Add", "Sum", "Total"],
		inputs: {
			list: { name: "List", type: "number", structure: "list" },
		},
		outputs: {
			total: { name: "Total", type: "number" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { total: 0 };
			}
			let sum = 0;
			for (let i = 0; i < list.length; i++) {
				sum += list[i];
			}

			return { total: sum };
		},
	},
	{
		id: "0008-03-0001",
		name: "List Min",
		type: NODE_TYPE_ID.list,
		description: "Returns the smallest number inside the list.",
		predictable: true,
		inputs: {
			list: { name: "List", type: "number", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: 0 };
			}
			return { value: Math.min(...list) };
		},
	},
	{
		id: "0008-03-0002",
		name: "List Max",
		type: NODE_TYPE_ID.list,
		description: "Returns the biggest number inside the list.",
		predictable: true,
		inputs: {
			list: { name: "List", type: "number", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "number" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: 0 };
			}
			return { value: Math.max(...list) };
		},
	},
	// BOOLEAN
	{
		id: "0008-04-0001",
		name: "List Includes True",
		type: NODE_TYPE_ID.list,
		description: "Checks if the list has a true boolean inside",
		predictable: true,
		inputs: {
			list: { name: "List", type: "boolean", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "boolean" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: null };
			}
			return { value: list.some((item: any) => item === true) };
		},
	},
	{
		id: "0008-04-0002",
		name: "List Includes False",
		type: NODE_TYPE_ID.list,
		description: "Checks if the list has a false boolean inside",
		predictable: true,
		inputs: {
			list: { name: "List", type: "boolean", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "boolean" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: null };
			}
			return { value: list.some((item: any) => item === false) };
		},
	},
	{
		id: "0008-04-0003",
		name: "List Is All True",
		type: NODE_TYPE_ID.list,
		description: "Checks whether all elements in the list are true",
		predictable: true,
		inputs: {
			list: { name: "List", type: "boolean", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "boolean" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: null };
			}
			return { value: list.every((item: any) => item === true) };
		},
	},
	{
		id: "0008-04-0004",
		name: "List Is All False",
		type: NODE_TYPE_ID.list,
		description: "Checks whether all elements in the list are false",
		predictable: true,
		inputs: {
			list: { name: "List", type: "boolean", structure: "list" },
		},
		outputs: {
			value: { name: "Value", type: "boolean" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No number item inside the list");
				return { value: null };
			}
			return { value: list.every((item: any) => item === false) };
		},
	},
	// ANY
	{
		id: "0008-06-0000",
		name: "List Contains",
		type: NODE_TYPE_ID.list,
		description: "Checks if whether a value is inside the list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			target: { name: "Target", type: "any" },
		},
		outputs: {
			value: { name: "Value", type: "boolean" },
		},
		resolve(inputs) {
			const { list, target } = inputs;
			if (list?.length === 0) {
				console.error("List is empty: No item inside the list");
				return { value: null };
			}
			return { value: list.includes(target) };
		},
	},
	{
		id: "0008-06-0001",
		name: "Lists Merge",
		type: NODE_TYPE_ID.list,
		description: "Combines two list into one",
		predictable: true,
		inputs: {
			listA: { name: "List A", type: "any", structure: "list" },
			listB: { name: "List B", type: "any", structure: "list" },
		},
		outputs: {
			newList: { name: "Combined List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { listA, listB } = inputs;
			if (listA?.length === 0 || listB?.length === 0) {
				console.error("List is empty: Cannot combine empty lists");
				return { value: [] };
			}
			return { newList: listA.concat(listB) };
		},
	},
	{
		id: "0008-06-0002",
		name: "List Clear",
		type: NODE_TYPE_ID.list,
		description: "Remove all item inside the list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			value: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list.length === 0) {
				console.error("List is empty: Cannot clear an empty list");
				return { value: [] };
			}
			list.length = 0;
			return { value: list };
		},
	},
	{
		id: "0008-06-0003",
		name: "List Copy",
		type: NODE_TYPE_ID.list,
		description: "Returns a shallow copy of the given list",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			value: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list.length === 0) {
				console.error("List is empty: Cannot copy an empty list");
				return { value: [] };
			}
			return { value: list.map((item: any) => item) };
		},
	},
	{
		id: "0008-06-0004",
		name: "List Remove Each Of",
		type: NODE_TYPE_ID.list,
		description: "Remove all item inside the list based on the given item",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			target: { name: "Target", type: "any" },
		},
		outputs: {
			value: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list, target } = inputs;
			if (list.length === 0) {
				console.error("List is empty: No item inside the list");
				return { value: [] };
			}
			return { value: list.filter((item: any) => item !== target) };
		},
	},
	{
		id: "0008-06-0005",
		name: "List Remove First Of",
		type: NODE_TYPE_ID.list,
		description: "Remove the first element that matches the given value",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
			target: { name: "Target", type: "any" },
		},
		outputs: {
			value: { name: "List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list, target } = inputs;
			if (list.length === 0) {
				console.error("List is empty: No item inside the list");
				return { value: [] };
			}
			const targetIndex = list.indexOf(target);
			return { value: list.filter((_: any, index: number) => index !== targetIndex) };
		},
	},
	{
		id: "0008-06-0006",
		name: "List Shuffle",
		type: NODE_TYPE_ID.list,
		description: "Shuffles the given array",
		predictable: true,
		inputs: {
			list: { name: "List", type: "any", structure: "list" },
		},
		outputs: {
			value: { name: "Shuffled List", type: "any", structure: "list" },
		},
		resolve(inputs) {
			const { list } = inputs;
			if (list.length === 0) {
				console.error("List is empty: No item inside the list");
				return { value: [] };
			}

			return { value: shuffleArray(list) };
		},
	},
];
