import { DEGRAD } from "base/util/math/Math.ts";
import { Euler, Vector3 } from "three";
import { OnHitParticlesDefault, OnHitPlayerParticlesDefault } from "./DefaultParticles";

const s = new Vector3(0.5, 0.5, 2); //collider size

export const ItemNovAssaultRifle = {
	name: "ItemNovAssaultRifle",
	display: "Wild West Assault Rifle",
	assets: [
		{
			id: "mdl-item-novassault-rifle",
			url: "assets/engine/mods/ItemNovAssaultRifle/mdl-item-novassault-rifle.glb",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-novassault-rifle",
		node: "item",
		animated: true,
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
	},
	muzzleFlashNode: "muzzle_flash_GEO",
	geom: { type: "box", width: s.x, height: s.y, depth: s.z },
	type: "ranged",
	weapon: true,
	automatic: true,
	animations: "NovaAssault",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_NovaAssault_FP_NotAim_Shoot",
				sight: "JMGO_NovaAssault_FP_Aim_Shoot",
			},
			third: "JMGO_NovaAssault_Shoot_Forward",
		},
	},
	reloadAnimationName: {
		item: "JMGO_NovaAssault_Reload_rig",
		RigDefault: {
			third: "JMGO_NovaAssault_Reload",
			first: "JMGO_NovaAssault_FP_NotAim_Reload",
		},
	},
	reloadAnimationTime: 2.93,
	cooldown: 0.1,
	projectile: "ProjectileNovAssaultRifle",
	projectilePos: new Vector3(0, 70, -10),
	projectileCartridgeLimit: 50,
	projectilesPerShot: 1,
	shakeVertical: 0.05,
	shakeVerticalMax: 0.5,
	shakeHorizontal: 0.2,
	shakeHorizontalMax: 1,
	shakeRecovery: 0.8,
	spread: 1.7,
	recoil: 0.02,
	sound: {
		fire: { asset: "snd-item-novassault-rifle-fire", volume: 1 },
	},
	muzzleFlashParticles: [
		{
			colors: [1, 1, 1, 0.2],
			type: "cube",
			numParticles: 30,
			positionRange: [0.1, 0.1, 0.1],
			lifeTime: 1,
			endTime: 1,
			startTime: 0,
			startSize: 0.15,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			velocityAimDirectionIntensity: 1,
			velocity: [0, 0, 0],
			velocityRange: [0.2, 0.2, 0.2],
			acceleration: [0, 0, 0],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.1,
		},
	],
};

export const ProjectileNovAssaultRifle = {
	name: "ProjectileNovAssaultRifle",
	assets: [
		{
			url: "assets/engine/mods/ItemNovAssaultRifle/snd-item-novassault-rifle-fire.ogg",
			id: "snd-item-novassault-rifle-fire",
			type: "audio/ogg",
		},
	],
	velocity: 200,
	entityDamage: 22,
	headshotDamage: 60,
	bulletTrail: true,
	bulletTrailColor: "#fff58a",
	onHitParticles: OnHitParticlesDefault,
	onHitPlayerParticles: OnHitPlayerParticlesDefault,
};
