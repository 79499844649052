import type { EntityID } from "@jamango/engine/EntityID.ts";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import type { Entity } from "base/world/entity/Entity";
import { netState } from "router/Parallelogram";
import type { Vector3Like } from "three";
import type * as InputManagerServer from "server/world/InputManager";

export type ConstraintManagerState = {
	// contraints data is made up of def and state structs:
	// - def: data that won't change during the lifetime of the constraint
	// - state: data that can change, e.g. constraint motor settings
	constraintIdCounter: number;
	constraints: Map<number, Constraint>;

	// map of constraint id to physics constraint instance id
	constraintInstanceIds: Map<number, number>;

	// constraints that need updates to mutable properties
	dirty: Set<number>;
};

export enum ConstraintType {
	FIXED,
	POINT,
	DISTANCE,
	HINGE,
	SLIDER,
	CONE,
	SWING_TWIST,
	SIX_DOF,
}

export enum PointSpace {
	WORLD,
	LOCAL,
}

export type ConstraintDef = {
	type: ConstraintType;
	entityA: EntityID;
	entityB: EntityID;
} & (
	| {
			type: ConstraintType.FIXED;
	  }
	| {
			type: ConstraintType.POINT;
			pointSpace: PointSpace;
			pointA: Vector3Like;
			pointB: Vector3Like;
	  }
	| {
			type: ConstraintType.DISTANCE;
			pointSpace: PointSpace;
			pointA: Vector3Like;
			pointB: Vector3Like;
	  }
	| {
			type: ConstraintType.HINGE;
			pointSpace: PointSpace;
			pointA: Vector3Like;
			pointB: Vector3Like;
			hingeAxisA: Vector3Like;
			hingeAxisB: Vector3Like;
			normalAxisA: Vector3Like;
			normalAxisB: Vector3Like;
			limitMin: number;
			limitMax: number;
	  }
	| {
			type: ConstraintType.SLIDER;
			pointSpace: PointSpace;
			pointA: Vector3Like;
			pointB: Vector3Like;
			sliderAxisA: Vector3Like;
			sliderAxisB: Vector3Like;
			normalAxisA: Vector3Like;
			normalAxisB: Vector3Like;
			limitMin: number;
			limitMax: number;
	  }
	| {
			type: ConstraintType.CONE;
			pointSpace: PointSpace;
			pointA: Vector3Like;
			pointB: Vector3Like;
			coneAxisA: Vector3Like;
			coneAxisB: Vector3Like;
			halfConeAngle: number;
	  }
);

export enum MotorStatus {
	OFF,
	VELOCITY,
	POSITION,
}

type MotorSettings = {
	minForceLimit: number;
	maxForceLimit: number;
	minTorqueLimit: number;
	maxTorqueLimit: number;
};

export type HingeConstraintState = {
	type: ConstraintType.HINGE;
	motorSettings: MotorSettings;
	motorStatus: MotorStatus;
	motorTarget: number;
};

export type DistanceConstriantState = {
	type: ConstraintType.DISTANCE;
	minDistance: number;
	maxDistance: number;
};

export type ConstraintState = HingeConstraintState | DistanceConstriantState;

export type Constraint = {
	def: ConstraintDef;
	state: ConstraintState | null;
};

const MAX_FORCE_DEFAULT = 1000000;
const MAX_TORQUE_DEFAULT = 1000000;

export const DEFAULT_HINGE_CONSTRAINT_STATE: HingeConstraintState = {
	type: ConstraintType.HINGE,
	motorStatus: MotorStatus.OFF,
	motorSettings: {
		minForceLimit: -MAX_FORCE_DEFAULT,
		maxForceLimit: MAX_FORCE_DEFAULT,
		minTorqueLimit: -MAX_TORQUE_DEFAULT,
		maxTorqueLimit: MAX_TORQUE_DEFAULT,
	},
	motorTarget: 0,
};

export const init = (): ConstraintManagerState => {
	return {
		constraintIdCounter: 0,
		constraints: new Map(),
		constraintInstanceIds: new Map(),
		dirty: new Set(),
	};
};

export const dispose = (state: ConstraintManagerState, world: World) => {
	for (const defId of state.constraintInstanceIds.keys()) {
		removeConstraintInstance(state, world, defId);
	}
};

export const createConstraint = (
	state: ConstraintManagerState,
	world: World,
	constraintDef: ConstraintDef,
	constraintState: ConstraintState | null = null,
	existingId?: number,
) => {
	const id = existingId ?? state.constraintIdCounter++;

	const newConstraint: Constraint = {
		def: constraintDef,
		state: constraintState,
	};
	state.constraints.set(id, newConstraint);

	if (netState.isHost) {
		const input = world.input as InputManagerServer.State;
		input.commands.push(["createConstraint", id, newConstraint]);
	}

	return id;
};

export const updateConstraint = (
	state: ConstraintManagerState,
	world: World,
	id: number,
	constraintState: ConstraintState,
) => {
	const constraint = state.constraints.get(id);
	if (!constraint) return;

	constraint.state = constraintState;
	state.dirty.add(id);

	if (netState.isHost) {
		const input = world.input as InputManagerServer.State;
		input.commands.push(["updateConstraint", id, constraintState]);
	}
};

export const disposeConstraint = (state: ConstraintManagerState, world: World, id: number) => {
	state.constraints.delete(id);

	if (netState.isHost) {
		const input = world.input as InputManagerServer.State;
		input.commands.push(["disposeConstraint", id]);
	}
};

const createConstraintInstance = (
	state: ConstraintManagerState,
	world: World,
	constraintDefId: number,
	constraintDef: ConstraintDef,
	constraintState: ConstraintState | null,
	entityA: Entity,
	entityB: Entity,
) => {
	const bodyA = Physics.getEntityBody(world.physics, entityA);
	const bodyB = Physics.getEntityBody(world.physics, entityB);

	if (!bodyA || !bodyB) {
		return undefined;
	}

	let constraint: Physics.Constraint;

	if (constraintDef.type === ConstraintType.FIXED) {
		constraint = Physics.createFixedConstraint(world.physics, bodyA, bodyB);
	} else if (constraintDef.type === ConstraintType.POINT) {
		constraint = Physics.createPointConstraint(
			world.physics,
			bodyA,
			bodyB,
			constraintDef.pointA,
			constraintDef.pointB,
		);
	} else if (constraintDef.type === ConstraintType.DISTANCE) {
		const distanceConstraintState = constraintState as DistanceConstriantState | null;
		if (!distanceConstraintState) return undefined;

		constraint = Physics.createDistanceConstraint(
			world.physics,
			bodyA,
			bodyB,
			constraintDef.pointA,
			constraintDef.pointB,
			distanceConstraintState.minDistance,
			distanceConstraintState.maxDistance,
		);
	} else if (constraintDef.type === ConstraintType.HINGE) {
		constraint = Physics.createHingeConstraint(
			world.physics,
			bodyA,
			bodyB,
			constraintDef.pointA,
			constraintDef.pointB,
			constraintDef.hingeAxisA,
			constraintDef.hingeAxisB,
			constraintDef.normalAxisA,
			constraintDef.normalAxisB,
			constraintDef.limitMin,
			constraintDef.limitMax,
		);
	} else if (constraintDef.type === ConstraintType.SLIDER) {
		constraint = Physics.createSliderConstraint(
			world.physics,
			bodyA,
			bodyB,
			constraintDef.pointA,
			constraintDef.pointB,
			constraintDef.sliderAxisA,
			constraintDef.sliderAxisB,
			constraintDef.normalAxisA,
			constraintDef.normalAxisB,
			constraintDef.limitMin,
			constraintDef.limitMax,
		);
	} else if (constraintDef.type === ConstraintType.CONE) {
		constraint = Physics.createConeConstraint(
			world.physics,
			bodyA,
			bodyB,
			constraintDef.pointA,
			constraintDef.pointB,
			constraintDef.coneAxisA,
			constraintDef.coneAxisB,
			constraintDef.halfConeAngle,
		);
	} else {
		return undefined;
	}

	state.constraintInstanceIds.set(constraintDefId, constraint.id);

	return constraint.id;
};

const updateConstraintInstance = (
	state: ConstraintManagerState,
	world: World,
	constraintId: number,
	constraint: Constraint,
) => {
	const constraintInstanceId = state.constraintInstanceIds.get(constraintId);
	if (constraintInstanceId === undefined) return;

	const constraintState = constraint.state;
	if (!constraintState) return;

	if (constraint.def.type === ConstraintType.HINGE && constraintState.type === ConstraintType.HINGE) {
		Physics.updateHingeConstraintMotorSettings(
			world.physics,
			constraintInstanceId,
			constraintState.motorSettings.minForceLimit,
			constraintState.motorSettings.maxForceLimit,
			constraintState.motorSettings.minTorqueLimit,
			constraintState.motorSettings.maxTorqueLimit,
		);

		if (constraintState.motorStatus === MotorStatus.OFF) {
			Physics.disableHingeConstraintMotor(world.physics, constraintInstanceId);
		} else if (constraintState.motorStatus === MotorStatus.VELOCITY) {
			Physics.setHingeConstraintMotorTargetAngularVelocity(
				world.physics,
				constraintInstanceId,
				constraintState.motorTarget,
			);
		} else if (constraintState.motorStatus === MotorStatus.POSITION) {
			Physics.setHingeConstraintMotorTargetAngle(
				world.physics,
				constraintInstanceId,
				constraintState.motorTarget,
			);
		}
	} else if (
		constraint.def.type === ConstraintType.DISTANCE &&
		constraintState.type === ConstraintType.DISTANCE
	) {
		Physics.setDistanceConstraintDistance(
			world.physics,
			constraintInstanceId,
			constraintState.minDistance,
			constraintState.maxDistance,
		);
	}
};

const removeConstraintInstance = (state: ConstraintManagerState, world: World, defId: number) => {
	const constraintInstanceId = state.constraintInstanceIds.get(defId);

	if (constraintInstanceId !== undefined) {
		state.constraintInstanceIds.delete(defId);

		Physics.disposeConstraint(world.physics, constraintInstanceId);
	}
};

export const update = (state: ConstraintManagerState, world: World) => {
	// update constraint defs
	for (const [id, constraint] of state.constraints.entries()) {
		const entityA = world.getEntity(constraint.def.entityA);
		const entityB = world.getEntity(constraint.def.entityB);

		// if referenced entities have been removed, remove the constraint
		if (!entityA || !entityB) {
			disposeConstraint(state, world, id);
			continue;
		}

		let created = false;

		// if the constraint instance doesn't exist, try to create it
		let constraintInstanceId = state.constraintInstanceIds.get(id);
		if (constraintInstanceId === undefined) {
			constraintInstanceId = createConstraintInstance(
				state,
				world,
				id,
				constraint.def,
				constraint.state,
				entityA,
				entityB,
			);

			// creation may have failed if the entities don't have physics bodies
			// in this case, continue to the next constraint
			// TODO: remove the constraint def?
			if (constraintInstanceId === undefined) {
				continue;
			}

			created = true;
		}

		// if the constraint instance is dirty, update mutable properties
		const needsUpdate = created || state.dirty.has(id);

		if (needsUpdate) {
			updateConstraintInstance(state, world, id, constraint);
		}
	}

	// if a constrant instance's def no longer exists, remove the instance
	for (const constraintId of state.constraintInstanceIds.keys()) {
		if (!state.constraints.has(constraintId)) {
			removeConstraintInstance(state, world, constraintId);
		}
	}

	state.dirty.clear();
};
