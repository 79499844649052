export const SKY_MIDNIGHT = 0;
export const SKY_SUNRISE = 0.25;
export const SKY_NOON = 0.5;
export const SKY_SUNSET = 0.75;

/**
 * @typedef {Object} SkyBase
 * @property {number} time
 * @property {number} [dayLength]
 * @property {number} [minGround]
 * @property {number} [minGroundSunY]
 * @property {number} [maxGround]
 * @property {number} [maxGroundSunY]
 * @property {number} [maxDiffuse]
 * @property {number} [maxSunIntensity]
 * @property {number} [sunScale]
 * @property {number} [moonScale]
 * @property {number} [starSize]
 * @property {number} [starCount]
 * @property {number} [azimuth]
 * @property {boolean} [isAutoSky]
 */

/**
 * @param {import("@jamango/content-client/lib/types/engine.ts").IEngineWorldData["sky"]} [options]
 * @returns {SkyBase}
 */
export function init(options) {
	return { time: options.time ?? 0 };
}

/**
 * @param {SkyBase} sky
 * @returns {string}
 */
export function get24HrTime(sky) {
	const hour = sky.time * 24;
	let hourStr = Math.floor(hour);
	const minute = (hour - hourStr) * 60;

	hourStr = hourStr.toString().padStart(2, "0");
	const minuteStr = Math.floor(minute).toString().padStart(2, "0");
	return `${hourStr}:${minuteStr}`;
}

/**
 * @param {SkyBase} sky
 * @returns {string}
 */
export function get12HrTime(sky) {
	const hour = sky.time * 24;
	let hourStr = Math.floor(hour);
	const minute = (hour - hourStr) * 60;

	let m = "";
	if (hourStr >= 12) {
		m = "PM";
		if (hourStr > 12) hourStr -= 12;
	} else {
		m = "AM";
		if (hourStr === 0) hourStr = 12;
	}

	const minuteStr = Math.floor(minute).toString().padStart(2, "0");
	return `${hourStr}:${minuteStr} ${m}`;
}
