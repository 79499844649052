import { V0, VNZ, DEGRAD } from "base/util/math/Math.ts";
import { isNullish } from "@jamango/helpers";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import { defTransform } from "base/util/Defs";
import { DirectionalLight, PointLight, SpotLight, CubeTexture, SRGBColorSpace } from "three";

//common classes between different def types

//client
export function defLight(o, range = 100) {
	let light;

	switch (o.type) {
		case "directional":
			light = new DirectionalLight();
			light.intensity = o?.intensity ?? 1;
			break;
		case "point":
			light = new PointLight();
			light.distance = o?.distance ?? 0;
			light.decay = o?.decay ?? 1;
			break;
		case "spot":
			light = new SpotLight();
			light.distance = o?.distance ?? 32;
			light.decay = o?.decay ?? 1;
			light.intensity = o?.intensity ?? 1;
			light.angle = o?.angle ?? 30 * DEGRAD;
			light.penumbra = o?.penumbra ?? 0.5;
			break;
		default:
			throw Error(`Unknown light type "${o.type}"`);
	}

	light.position.copy(o?.pos ?? V0);
	light.target.position.copy(o?.dir ?? VNZ);
	light.color.set(o?.color ?? "white");

	light.add(light.target); //this turns light.target.position into the light's direction vector

	const s = light.shadow;
	const c = s.camera;

	if (light.isSpotLight) {
		s.mapSize.set(256, 256);
		s.radius = 4;
		s.bias = -0.002;
		c.near = 4;
		c.far = range * 2;
	} else if (light.isDirectionalLight) {
		s.mapSize.set(1024, 1024); //three.js default is 512x512
		s.radius = 2;
		s.bias = -0.0005;
		const hr = range * 2;
		c.left = c.bottom = -hr;
		c.right = c.top = hr;
		c.near = -hr * 0.5;
		c.far = hr * 0.5;
	}

	c.updateProjectionMatrix();

	return light;
}

//client
//if mesh is animated, need to manually dispose of the skeletons
export function defMesh(o, assets) {
	let mesh = assets.get(o.asset).scene;

	if (!isNullish(o.node)) {
		const theNode = mesh.getObjectByName(o.node);
		mesh = theNode ?? mesh;
	}

	mesh = clone(mesh);

	defTransform(o.transform, mesh);
	mesh.traverse(function (node) {
		if (node.isBone) return;

		node.matrixAutoUpdate = false;
		node.updateMatrix();
	});

	return mesh;
}

//client
export function defCubeTexture(o, assets) {
	const tex = new CubeTexture([
		assets.get(o.assets[1]).image,
		assets.get(o.assets[0]).image,
		assets.get(o.assets[3]).image,
		assets.get(o.assets[2]).image,
		assets.get(o.assets[5]).image,
		assets.get(o.assets[4]).image,
	]);

	tex.colorSpace = SRGBColorSpace;
	tex.needsUpdate = true;
	return tex;
}
