export const OCCLUSION_ENABLE_CUSTOM_BV = false;

function startOcclusionQuery(renderer, scene, camera, geometry, frameID) {
	geometry.userData.meshReference.isInFrustum = true;

	if (!geometry.userData.meshReference.occlusionQuery) {
		const glContext = renderer.getContext();
		const query = glContext.createQuery();
		glContext.beginQuery(glContext.ANY_SAMPLES_PASSED_CONSERVATIVE, query);

		// index is a custom big hack (look Character constructor)
		geometry.userData.meshReference.occlusionQuery = query;
		geometry.userData.meshReference.startFrame = frameID;
	}
}

function endOcclusionQuery(renderer, scene, camera, geometry, frameID) {
	if (geometry.userData.meshReference.startFrame === frameID) {
		const glContext = renderer.getContext();
		glContext.endQuery(glContext.ANY_SAMPLES_PASSED_CONSERVATIVE);
	}
}

function checkOcclusionQuery(occlusionQueryMesh, glContext) {
	if (occlusionQueryMesh.occlusionQuery) {
		let success = false;
		let available = true;

		success = glContext.getQueryParameter(occlusionQueryMesh.occlusionQuery, glContext.QUERY_RESULT) > 0;
		if (!success) {
			available = glContext.getQueryParameter(
				occlusionQueryMesh.occlusionQuery,
				glContext.QUERY_RESULT_AVAILABLE,
			);
		}

		if (occlusionQueryMesh.occlusionQueryCallback && available) {
			occlusionQueryMesh.occlusionQueryCallback(success);
		}

		if (available) {
			occlusionQueryMesh.occlusionQuery = null;
		}
	}
}

export { startOcclusionQuery, endOcclusionQuery, checkOcclusionQuery };
