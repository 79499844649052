import * as InputManagerBase from "base/world/InputManager.ts";

import type { EntityID } from "@jamango/engine/EntityID.ts";
import { InputCommandType, type Input, type InputCommands } from "@jamango/engine/Input.ts";
import { BB } from "base/BB";
import * as ConstraintManager from "base/world/ConstraintManager";
import type { SerializationComponent } from "base/world/entity/component/Serialization.ts";
import { serQuat, serV3 } from "base/world/entity/component/Serialization.ts";
import { entityIsCharacter } from "base/world/entity/component/Type";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import { BUTTON_LEFT, BUTTON_MIDDLE, BUTTON_RIGHT } from "client/input/Poll.js";
import * as PencilClient from "client/world/tools/Pencil";
import * as Net from "router/Net";
import { netState } from "router/Parallelogram";

const DESKTOP_DOUBLE_TAP_LIMIT = 0.25; // maximum number of seconds between key presses for detecting fly/sprint double tap
const DESKTOP_TOOL_WHEEL_TIME = 300; // how long in ms to hold the tool cycle button in order to open the wheel

export type State = InputManagerBase.State & {
	local: { input: Input; cmd: InputCommands };
	doubleTapDetection: {
		flyTimer: number;
		sprintTimer: number;
	};
	dedicatedSprintKey: boolean;
	lastSerialized: string;
	sendInterval: number;
	desyncAck: number;
	keyCombos: any[];
};

export const init = (input: InputManagerBase.State) => {
	const state = input as State;

	state.local = { input: InputManagerBase.createInput(), cmd: [] };

	//from DesktopListeners.js, probably doesn't belong here
	state.doubleTapDetection = {
		flyTimer: 0,
		sprintTimer: 0,
	};

	state.dedicatedSprintKey = false; //whether sprinting using the dedicated sprint key or double tap

	// for very crude diff checks
	state.lastSerialized = "";
	state.sendInterval = 0;
	state.desyncAck = 0;
	state.keyCombos = [];
};

type PeerInputData = {
	ack: number | undefined;
	movement: {
		pos: ReturnType<typeof serV3>;
		quat: ReturnType<typeof serQuat>;
		vel: ReturnType<typeof serV3>;
	};
	input?: string;
};

export function pollInputs(world: World, stepDeltaTime: number) {
	const state = world.input as State;

	// this functions polls the input of the local client and writes it into the local input object
	const entity = world.client.getPeerPlayer();
	if (entity === undefined) return;

	const cam = world.client.camera;
	if (cam.target !== entity) cam.set(entity);

	const input = (state.local.input = entity.input);
	const cmd = (state.local.cmd = entity.cmd);
	const poll = BB.client.inputPoll;

	input.pointerLocked = poll.isPointerLocked();

	if (poll.isMobileBrowser()) {
		pollMobileInputs(world, stepDeltaTime);
	} else {
		pollDesktopInputs(world, stepDeltaTime);
	}

	if (
		world.client.pencil.pencilMode === PencilClient.PencilMode.MANIPULATING_ENTITIES &&
		world.client.pencil.manipulatingState
	) {
		const manipulatingState = world.client.pencil.manipulatingState;
		input.hold = [
			manipulatingState.entityId,
			manipulatingState.distance,
			manipulatingState.hitOffsetLocal.toArray(),
			manipulatingState.playerStartQuaternion.toArray(),
			manipulatingState.entityStartQuaternion.toArray(),
		];
	} else {
		input.hold = null;
	}

	// regardless of HID type, these are individual buttons instead of nipple-like, so block both from being pressed
	if (input.isFlyingUp && input.isFlyingDown) {
		input.isFlyingUp = false;
		input.isFlyingDown = false;
	}

	// do a crude diff check to avoid sending input if no changes
	let sendInput = false;
	const serialized = InputManagerBase.serializeInput(input, cmd);
	if (serialized !== state.lastSerialized) {
		// send if input is different
		state.lastSerialized = serialized;
		state.sendInterval = 0;
		sendInput = true;
	} else if (!netState.isHost && (state.sendInterval! += stepDeltaTime) > 0.05) {
		// send if a timer has run out (frequent position updates necessary in our engine)
		state.sendInterval = 0;
	}

	if (!netState.isHost && state.sendInterval === 0) {
		const data: PeerInputData = {
			ack: state.desyncAck,
			movement: {
				pos: serV3(entity.position, 10000),
				quat: serQuat(entity.quaternion, 10000),
				vel: serV3(entity.movement.state.velocity, 10000),
			},
		};
		if (sendInput) data.input = serialized;

		Net.send("peer_input", data);
	}
}

function pollDesktopInputs(world: World, deltaTime: number) {
	const state = world.input as State;

	const localState = state.local!;
	const poll = BB.client.inputPoll;
	const keyBinding = BB.client.settings.key;

	const toolHeld = poll.keysAreHeldForTime(keyBinding.tool, DESKTOP_TOOL_WHEEL_TIME);
	const emoteWheelJustPressed = poll.keysAreJustPressed(keyBinding.emote);

	if (toolHeld) {
		UI.state.controls().toggleToolWheel(true);
	}

	if (emoteWheelJustPressed) {
		UI.state.controls().toggleEmotesWheel();
	}

	const localInput = localState.input;
	const localCmd = localState.cmd;
	if (!localInput.pointerLocked) return; //all gameplay input is disabled (except for clicking on the inventory)

	const leftDown = poll.keysAreDown(keyBinding.left);
	const rightDown = poll.keysAreDown(keyBinding.right);
	const forwardDown = poll.keysAreDown(keyBinding.forward);
	const backwardDown = poll.keysAreDown(keyBinding.backward);
	const downDown = poll.keysAreDown(keyBinding.down);
	const upDown = poll.keysAreDown(keyBinding.up);
	const sprintDown = poll.keysAreDown(keyBinding.sprint);

	const upPressed = poll.keysAreJustPressed(keyBinding.up);
	const forwardPressed = poll.keysAreJustPressed(keyBinding.forward);
	const sprintPressed = poll.keysAreJustPressed(keyBinding.sprint);
	const interactPressed = poll.keysAreJustPressed(keyBinding.interact);
	const reloadPressed = poll.keysAreJustPressed(keyBinding.reload);
	const forceRespawnPressed = poll.keysAreJustPressed(keyBinding.forceRespawn);
	const pickBlockPressed = poll.keysAreJustPressed(keyBinding.pickBlock);

	const toolPressed = poll.keysArePressedAndReleasedWithinTime(keyBinding.tool, DESKTOP_TOOL_WHEEL_TIME);
	const toolDown = poll.keysAreDown(keyBinding.tool);

	const leftButtonDown = poll.isPointerDown(BUTTON_LEFT);
	const rightButtonDown = poll.isPointerDown(BUTTON_RIGHT);

	const leftButtonPressed = poll.isPointerJustPressed(BUTTON_LEFT);
	const rightButtonPressed = poll.isPointerJustPressed(BUTTON_RIGHT);
	const middleButtonPressed = poll.isPointerJustPressed(BUTTON_MIDDLE);

	localInput.camera.copy(world.client.camera.sph);
	localInput.nipple.x = Number(rightDown) - Number(leftDown);
	localInput.nipple.y = Number(forwardDown) - Number(backwardDown);

	localInput.isCrouching = localInput.isFlyingDown = downDown;
	localInput.isJumping = localInput.isFlyingUp = upDown;
	localInput.isSwappingTool = toolDown;

	if (interactPressed) localCmd.push(InputCommandType.INTERACT);
	if (reloadPressed) localCmd.push(InputCommandType.RELOAD);
	if (forceRespawnPressed) localCmd.push(InputCommandType.FORCE_RESPAWN);

	localInput.isPullingTrigger = leftButtonDown;
	localInput.isIronSights = rightButtonDown;

	if (rightButtonPressed) localCmd.push(InputCommandType.ITEM_SECONDARY);
	if (leftButtonPressed) {
		// TODO: we probably don't need to send melee if we are already sending item primary
		localCmd.push(InputCommandType.ITEM_PRIMARY);
		localCmd.push(InputCommandType.MELEE);
	}

	if (middleButtonPressed || pickBlockPressed) {
		localCmd.push(InputCommandType.PICK_BLOCK);
	}

	const doubleTap = state.doubleTapDetection!;

	//fly toggle
	doubleTap.flyTimer -= deltaTime;
	if (upPressed) {
		//detect double tap
		if (doubleTap.flyTimer > 0) localState.input.requestFlying = !localState.input.requestFlying;
		else doubleTap.flyTimer = DESKTOP_DOUBLE_TAP_LIMIT;
	}

	//sprint
	doubleTap.sprintTimer -= deltaTime;
	if ((sprintPressed && forwardDown) || (sprintDown && forwardPressed)) {
		localInput.isSprinting = true;
		state.dedicatedSprintKey = true;
	} else if (forwardPressed) {
		//detect double tab
		if (doubleTap.sprintTimer > 0) localInput.isSprinting = true;
		else doubleTap.sprintTimer = DESKTOP_DOUBLE_TAP_LIMIT;
	} else if (!forwardDown || (!sprintDown && state.dedicatedSprintKey)) {
		localInput.isSprinting = false;
		state.dedicatedSprintKey = false;
	}

	//equip - tool cycle button
	if (toolPressed) {
		UI.state.item().cycleTool();
	}

	//custom triggers
	for (const { combo, split, press } of state.keyCombos!) {
		if (press && poll.keysAreJustPressed(split)) {
			localCmd.push([InputCommandType.CONTROL_PRESS, combo]);
		} else if (!press && poll.keysAreJustReleased(split)) {
			localCmd.push([InputCommandType.CONTROL_RELEASE, combo]);
		}
	}
}

function pollMobileInputs(world: World, _deltaTime: number) {
	const localState = (world.input as State).local;
	const localInput = localState.input;
	const poll = BB.client.inputPoll;

	localInput.camera.copy(world.client.camera.sph);

	const leftStick = poll.getLeftStickVec();
	localInput.nipple.x = leftStick.x;
	localInput.nipple.y = leftStick.y;

	localInput.isSprinting = poll.getIsStickInASprintPosition();
}

export type InputsCommandArgs = {
	cmd: Array<InputManagerBase.HostInputCommand>;
	inputs: Array<[entityId: EntityID, input: string]>;
	diffs: Array<{
		ack: number;
		desync: number;
		diff: SerializationComponent["diffs"];
	}>;
};

export const inputsCommand = Net.command(
	"inputs",
	Net.CLIENT,
	({ cmd, inputs, diffs }: InputsCommandArgs, world) => {
		const state = world.input as State;

		// handle commands
		// TODO: below is major slop, this should live somewhere else. it was necessary to be done hackily due to 01-24 launch
		for (const dat of cmd) {
			const cmd = dat[0];
			if (cmd === "create") {
				const [_cmd, mapID, def, x, y, z, qx, qy, qz, qw, eId, sceneTreeNode] = dat;
				if (mapID !== world.scene.mapID) continue;
				if (!world.idToEntity.has(eId)) {
					world.router.createEntity({
						def,
						x,
						y,
						z,
						qx,
						qy,
						qz,
						qw,
						id: eId,
						sceneTreeNode: sceneTreeNode ?? undefined,
					});
				}
			} else if (cmd === "mount") {
				const [_cmd, childEntityId, parentEntityId, toggle, mountIndex] = dat;
				const child = world.getEntity(childEntityId);
				const parent = world.getEntity(parentEntityId);
				if (child && parent) {
					child.onMount(parent, toggle, mountIndex);
				}
			} else if (cmd === "dispose") {
				const [_cmd, eId] = dat;
				const entity = world.getEntity(eId);
				if (entity) {
					entity.dispose(false);
				}
			} else if (cmd === "createConstraint") {
				const [_cmd, constraintId, constraint] = dat;
				ConstraintManager.createConstraint(
					world.constraints,
					world,
					constraint.def,
					constraint.state,
					constraintId,
				);
			} else if (cmd === "updateConstraint") {
				const [_cmd, constraintId, constraintState] = dat;
				ConstraintManager.updateConstraint(world.constraints, world, constraintId, constraintState);
			} else if (cmd === "disposeConstraint") {
				const [_cmd, constraintId] = dat;
				ConstraintManager.disposeConstraint(world.constraints, world, constraintId);
			} else if (cmd === "characterSetCamera") {
				const [_cmd, eId, phi, theta] = dat;
				const entity = world.getEntity(eId);
				if (entity && entityIsCharacter(entity)) {
					entity.onSetCamera(phi, theta);
				}
			}
		}

		const localID = world.client.getPeerPlayer()!.entityID;

		for (const [entityID, input] of inputs) {
			// skip ourselves
			if (entityID === localID) continue;
			const entity = world.getEntity(entityID);
			if (entity === undefined) continue;
			InputManagerBase.deserializeInput(entity.input!, entity.cmd!, input);
		}

		for (const { ack, desync, diff } of diffs) {
			// if it has a state, deserialize it here
			const entityID = diff[0];
			const ent = world.getEntity(entityID);
			if (ent === undefined) continue;

			const isLocal = entityID === localID;

			if (isLocal) state.desyncAck = desync || ack;

			// if we are the local player, we ignore positional updates unless they are "desyncs"
			ent.deserialize(diff, !isLocal || desync > 0);
		}
	},
);

export function cancelDoubleTaps(world: World) {
	const state = world.input as State;

	state.local.input.isSprinting = false;
	if (BB.client.inputPoll.isMobileBrowser()) {
		UI.state.movement().cancelDoubleTaps();
	} else state.doubleTapDetection.flyTimer = 0;
	state.doubleTapDetection.sprintTimer = 0;
}
