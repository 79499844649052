import { isNullish } from "@jamango/helpers";
import { Layers } from "client/Layers.js";
import { Label } from "client/world/entity/util/Label.js";
import { Sprite } from "three";
import { UISpriteMaterial } from "client/util/Shaders.js";

export const NAMEPLATE_STYLE = {
	fontSize: 32,
	backgroundColor: "#0000",
	fontWeight: 400,
	fontOutlineWidth: 8,
	faceCamera: true,
	sizeAttenuation: false,
};

export class Nameplate extends Label {
	/**
	 * OPT Object label [NAMEPLATE_STYLE] - Default label style to pass to the label superclass
	 * OPT Number scl [0.000830078125] - How big the nameplate will appear on screen
	 * OPT Number healthbarWidth [200] - Default width when there is no nameplate to size to
	 * OPT Number healthbarHeight [10]
	 * OPT Color healthbarColor [0x00ff00]
	 * OPT Number healthbarOpacity [1]
	 */
	constructor(world, o) {
		if (isNullish(o)) o = {};

		o.label ??= NAMEPLATE_STYLE;

		super(world, o);

		this.world = world;

		this.scaleFactor = o?.scl ?? 0.000830078125;
		this.healthbarWidth = o?.healthbarWidth ?? 200;
		this.healthbarHeight = o?.healthbarHeight ?? 10;
		this.healthbarColor = o?.healthbarColor ?? 0x00ff00;
		this.healthbarOpacity = o?.healthbarOpacity ?? 1;

		this.scale.setScalar(this.scaleFactor);
		this.labelOptions.scl = 1;
	}

	setText(text) {
		super.setText(text);
		this.updateTransform();
	}

	removeText() {
		const hadNameplate = this.label;
		super.removeText();
		if (hadNameplate) this.updateTransform();
	}

	//v in range [0, 1]
	setHealthBar(v) {
		if (!this.label) {
			this.removeHealthBar();
			return;
		}

		if (!this.healthbar) {
			this.healthbar = new Sprite(
				new UISpriteMaterial({
					sizeAttenuation: false,
					transparent: true,
					depthTest: false,
					depthWrite: false,
					color: this.healthbarColor,
					opacity: this.healthbarOpacity,
				}),
			);

			this.healthbar.layers.set(Layers.UI);

			this.add(this.healthbar);
			this.updateTransform();
		}

		this.healthbar.scale.x = v * (this.label ? this.label.pixelWidth : this.healthbarWidth);
		this.healthbar.scale.y = this.healthbarHeight;
		this.healthbar.center.x = 0.5 / v;
		this.healthbar.center.y = 0;
		this.healthbar.renderOrder = 2;
	}

	removeHealthBar() {
		if (!this.healthbar) return;

		this.remove(this.healthbar);
		this.healthbar.material.dispose();
		delete this.healthbar;

		this.updateTransform();
	}

	updateTransform() {
		if (this.isEmpty()) return;

		const np = this.label;
		const hb = this.healthbar;

		const totalPixelHeight = (np?.pixelHeight ?? 0) + (hb ? this.healthbarHeight : 0);

		if (hb) this.setHealthBar(1); //corrects scale.x

		np?.setCenter(0, (hb ? this.healthbarHeight / 2 : 0) + totalPixelHeight / 2);
	}

	isEmpty() {
		return super.isEmpty() && !this.healthbar;
	}

	dispose() {
		this.removeHealthBar();
		this.removeText();
	}
}
