import type { SocketDef } from "@jamango/content-client";
import { BlockStructureColliderType, PropColliderType, PropMeshType } from "@jamango/content-client";
import { assertNever } from "@jamango/helpers";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import * as Physics from "base/world/Physics";
import type { World } from "base/world/World";
import type { Prop } from "base/world/entity/Prop";
import type { PropMesh } from "base/world/entity/component/PropMesh";
import type { PropCollider } from "base/world/entity/component/PropPhysics";
import { mapPropMotionType } from "base/world/entity/component/PropPhysics";
import { Euler, Quaternion, Vector3 } from "three";
import { getEntity } from "../modules/validate";

const _position = new Vector3();
const _rotation = new Vector3();
const _euler = new Euler();
const _quaternion = new Quaternion();

type MotionTypeString = "static" | "dynamic" | "kinematic";

const mapMotionType = (v: MotionTypeString) => {
	switch (v) {
		case "static":
			return Physics.MotionType.STATIC;
		case "dynamic":
			return Physics.MotionType.DYNAMIC;
		case "kinematic":
			return Physics.MotionType.KINEMATIC;
		default:
			assertNever(v);
	}
};

const addMotionType = (optional = false) => {
	const input = {
		name: "Motion Type",
		type: "string",
		control: "select",
		optional,
		config: {
			defaultValue: "dynamic",
			explicitSortOptions: [
				{ value: "static", label: "Static" },
				{ value: "dynamic", label: "Dynamic" },
				{ value: "kinematic", label: "Kinematic" },
			],
		},
	} satisfies SocketDef<"C", "string">;

	return input;
};

type BlockStructureColliderTypeString = "accurate" | "simplified";

const mapBlockStructureColliderType = (v: BlockStructureColliderTypeString) => {
	switch (v) {
		case "accurate":
			return BlockStructureColliderType.ACCURATE;
		case "simplified":
			return BlockStructureColliderType.CONVEX_HULL;
		default:
			assertNever(v);
	}
};

const addBlockStructureColliderType = () => {
	const input = {
		name: "Collider Type",
		type: "string",
		control: "select",
		config: {
			defaultValue: "simplified",
			explicitSortOptions: [
				{ value: "simplified", label: "Simplified" },
				{ value: "accurate", label: "Accurate" },
			],
		},
	} satisfies SocketDef<"C", "string">;

	return input;
};

export const PROP_NODES = [
	node({
		id: "74463a74-d846-4053-85a3-1bf4bbde2558",
		name: "Blueprint Collider Type",
		type: NODE_TYPE_ID.util.literal,
		description: "Returns a blueprint collider type value",
		controls: {
			blockStructureColliderType: addBlockStructureColliderType(),
		},
		outputs: {
			blockStructureColliderType: { name: "Blueprint Collider Type", type: "string" },
		},
		resolve(inputs) {
			return { blockStructureColliderType: inputs.blockStructureColliderType };
		},
	}),
	node({
		id: "5ff26dca-f9f6-4ccc-9aa2-601858dcc640",
		name: "Motion Type",
		type: NODE_TYPE_ID.util.literal,
		description: "Returns a motion type value",
		controls: {
			motionType: addMotionType(),
		},
		outputs: {
			motionType: { name: "Motion Type", type: "string" },
		},
		resolve(inputs) {
			return { motionType: inputs.motionType };
		},
	}),
	node({
		id: "dffe579f-4719-4831-acaf-c39c7b228167",
		name: "Spawn Prop",
		type: NODE_TYPE_ID.function.entity,
		description: "Creates a prop from a prop in the world inventory",
		inputs: {
			exec: { type: "exec" },
			prop: {
				name: "Prop",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) =>
						ctx.world.content.state.props.getAll().map((prop) => ({
							label: prop.name,
							value: prop.pk,
						})),
				},
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
			rotation: {
				name: "Rotation",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
			motionType: addMotionType(),
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Prop", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const propAsset = world.content.state.props.get(inputs.prop);

			if (!propAsset) {
				throw Error(`Prop "${inputs.prop}" not found.`);
			}

			const position = _position.copy(inputs.position);
			const rotation = _rotation.copy(inputs.rotation);
			const quaternion = _quaternion.setFromEuler(_euler.setFromVector3(rotation));

			// spawn prop
			const entity = world.router.createEntity({
				def: "Prop",
				x: position.x,
				y: position.y,
				z: position.z,
				qx: quaternion.x,
				qy: quaternion.y,
				qz: quaternion.z,
				qw: quaternion.w,
			}) as Prop;

			// set prop physics and mesh properties from asset
			entity.setScale(propAsset.scale);
			entity.propPhysics.def.motionType = mapPropMotionType(propAsset.physics.motionType);
			entity.propPhysics.def.mass = propAsset.physics.mass;
			entity.propPhysics.def.friction = propAsset.physics.friction;
			entity.propPhysics.def.restitution = propAsset.physics.restitution;

			entity.propPhysics.updateCollider(propAsset.physics.collider);
			entity.propMesh.updateMesh(propAsset.mesh);

			// store the most recent prop entity id in the node state, this is what is returned upon resolve
			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeId, scope) {
			return { entity: ctx.world.getEntity(scope[nodeId])! };
		},
	}),
	node({
		id: "a2702472-7ff9-47af-ab9a-4a4b9c551cee",
		name: "Spawn Prop From Blueprint",
		type: NODE_TYPE_ID.function.entity,
		description: "Creates a prop from a blueprint and adds it to the scene",
		inputs: {
			exec: { type: "exec" },
			blockStructure: {
				name: "Blueprint",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) =>
						ctx.world.content.state.blockStructures.getAll().map((blockStructure) => ({
							label: blockStructure.name,
							value: blockStructure.pk,
						})),
				},
			},
			motionType: addMotionType(),
			blockStructureColliderType: addBlockStructureColliderType(),
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
			rotation: {
				name: "Rotation",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
			scale: {
				name: "Scale",
				type: "number",
				control: "number",
				config: { defaultValue: 1 },
			},
			mass: {
				name: "Mass",
				type: "number",
				control: "number",
				config: { defaultValue: 1 },
			},
			friction: {
				name: "Friction",
				type: "number",
				control: "number",
				config: { defaultValue: 0.5 },
			},
			restitution: {
				name: "Restitution",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Prop", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const blockStructure = world.content.state.blockStructures.get(inputs.blockStructure);

			if (!blockStructure) {
				throw Error(`Blueprint "${inputs.blockStructure}" not found.`);
			}

			const position = _position.copy(inputs.position);
			const rotation = _rotation.copy(inputs.rotation);
			const quaternion = _quaternion.setFromEuler(_euler.setFromVector3(rotation));
			const blockStructureColliderType = mapBlockStructureColliderType(
				inputs.blockStructureColliderType as BlockStructureColliderTypeString,
			);
			const motionType = mapMotionType(inputs.motionType as MotionTypeString);

			// spawn prop
			const entity = world.router.createEntity({
				def: "Prop",
				x: position.x,
				y: position.y,
				z: position.z,
				qx: quaternion.x,
				qy: quaternion.y,
				qz: quaternion.z,
				qw: quaternion.w,
			}) as Prop;

			// set prop physics and mesh properties
			entity.setScale(inputs.scale);

			entity.propPhysics.def.motionType = motionType;
			entity.propPhysics.def.mass = inputs.mass;
			entity.propPhysics.def.friction = inputs.friction;
			entity.propPhysics.def.restitution = inputs.restitution;

			const collider: PropCollider = {
				type: PropColliderType.BLOCK_STRUCTURE,
				blockStructurePk: blockStructure.pk,
				blockStructureColliderType,
			};

			entity.propPhysics.updateCollider(collider);

			const mesh: PropMesh = {
				type: PropMeshType.BLOCK_STRUCTURE,
				blockStructurePk: blockStructure.pk,
			};

			entity.propMesh.updateMesh(mesh);

			// store the most recent prop entity id in the node state, this is what is returned upon resolve
			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeId, scope) {
			return { entity: ctx.world.getEntity(scope[nodeId])! };
		},
	}),
	node({
		id: "6d6e3daf-d02a-4c08-9acb-9886eb927d63",
		name: "Spawn Invisible Prop",
		type: NODE_TYPE_ID.function.entity,
		description: "Creates an invisible prop and adds it to the scene.",
		info: [
			"Useful for creating constraints, for example, you can create a constraint between a dynamic prop and a static invisible prop.",
		],
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
				config: {
					defaultValue: { x: 0, y: 0, z: 0 },
				},
			},
			motionType: {
				name: "Motion Type",
				type: "string",
				control: "select",
				config: {
					defaultValue: "static",
					explicitSortOptions: [
						{ value: "static", label: "Static" },
						{ value: "kinematic", label: "Kinematic" },
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			entity: { name: "Prop", type: "entity" },
		},
		execute(inputs, ctx, nodeId, scope) {
			const world = ctx.world;

			const position = _position.copy(inputs.position);

			const entity = world.router.createEntity({
				def: "Prop",
				x: position.x,
				y: position.y,
				z: position.z,
				qx: 0,
				qy: 0,
				qz: 0,
				qw: 1,
			}) as Prop;

			const collider: PropCollider = {
				type: PropColliderType.NONE,
			};

			entity.propPhysics.updateCollider(collider);

			entity.propPhysics.def.motionType = mapMotionType(inputs.motionType as MotionTypeString);
			entity.propPhysics.def.mass = 0;
			entity.propPhysics.def.friction = 0;
			entity.propPhysics.def.restitution = 0;

			const mesh: PropMesh = {
				type: PropMeshType.NONE,
			};

			entity.propMesh.updateMesh(mesh);

			// store the most recent prop entity id in the node state, this is what is returned upon resolve
			scope[nodeId] = entity.entityID;
		},
		resolve(_inputs, ctx, nodeId, scope) {
			return { entity: ctx.world.getEntity(scope[nodeId])! };
		},
	}),
	node({
		id: "5aad4494-00a5-41bf-aabf-e07508d07a9e",
		name: "Update Prop Physics",
		type: NODE_TYPE_ID.function.entity,
		description: "Updates a prop's physics properties",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Prop", type: "entity" },
			motionType: addMotionType(true),
			scale: {
				name: "Scale",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 1 },
			},
			mass: {
				name: "Mass",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 1 },
			},
			friction: {
				name: "Friction",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 0.5 },
			},
			restitution: {
				name: "Restitution",
				type: "number",
				control: "number",
				optional: true,
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "entity");

			if (!entity) {
				throw Error(`Prop "${inputs.entity}" not found.`);
			}

			if (!entity.type.def.isProp) {
				throw Error(`Entity "${inputs.entity}" is not a prop.`);
			}

			const prop = entity as Prop;

			if (inputs.motionType !== undefined) {
				prop.propPhysics.def.motionType = mapMotionType(inputs.motionType as MotionTypeString);
			}

			if (inputs.mass !== undefined) {
				prop.propPhysics.def.mass = inputs.mass;
			}

			if (inputs.friction !== undefined) {
				prop.propPhysics.def.friction = inputs.friction;
			}

			if (inputs.restitution !== undefined) {
				prop.propPhysics.def.restitution = inputs.restitution;
			}

			if (inputs.scale !== undefined) {
				prop.setScale(inputs.scale);
			}
		},
	}),
];
