import { NODE_TYPE_ID } from "base/rete/Constants";
import { addSelectGroup, addWhoCanTrigger } from "base/rete/NodeSharedUtil";
import { BlockCollisionBodyPart } from "base/world/entity/component/CharacterCollision";
import { type SocketsDef } from "@jamango/content-client";
import { node } from "base/rete/Types";
import type { Character } from "base/world/entity/Character";
import type { Vector3 } from "three";
import { NODE } from "base/rete/InternalNameMap";
import * as Physics from "base/world/Physics";

function addBodyPart() {
	return {
		bodyPart: {
			name: "Body Part",
			type: "string",
			control: "select",
			config: {
				defaultValue: BlockCollisionBodyPart.any,
				explicitSortOptions: Object.values(BlockCollisionBodyPart).map((v) => ({
					label: v,
					value: v,
				})),
			},
		},
	} satisfies SocketsDef<"C">;
}

export const COLLISION_NODES = [
	node({
		id: "0000-00-0008",
		name: "On Block Collision Start",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		predictable: true,
		description: "Triggered when a character collides with a block",
		controls: {
			...addWhoCanTrigger(),
			...addBodyPart(),
		},
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			blockNormal: { name: "Block Surface Normal", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return {
				character: ctx.info.character,
				position: ctx.info.position,
				blockNormal: ctx.info.blockNormal,
			};
		},
	}),
	node({
		id: "0000-00-0007",
		name: "On Block Collision End",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		predictable: true,
		description: "Triggered when a character collides with a block",
		controls: {
			...addWhoCanTrigger(),
			...addBodyPart(),
		},
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			blockNormal: { name: "Block Surface Normal", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return {
				character: ctx.info.character,
				position: ctx.info.position,
				blockNormal: ctx.info.blockNormal,
			};
		},
	}),
	node({
		id: "0000-00-0006",
		name: "On All Player Feet Enter",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		description: "Triggered once all players stand on a block group",
		outputs: {
			exec: { type: "exec" },
		},
		execute(_inputs, ctx, nodeId) {
			// TODO: this is fundamentally hacky, and currently the *only* node that returns something in its .execute() function
			// the return value of this function is used to check whether execution should continue - this is contrary to all other node design
			// we do this because we chose to track whether all players are inside of this group inside the node exec call
			// the proper way to solve this would be to never actually trigger this node at all unless all players are in
			// this would require moving the detection for this mechanism onto the world-level, where it likely belongs
			// TODO: once this logic has been outsource into the world, the interpreters ".execute() === false" check can be removed!

			// if it is "enter", increment the counter. otherwise, decrease
			const isEnter = ctx.info.eventType === NODE.OnBlockCollisionStart;
			if (ctx.state.nodes[nodeId] === undefined) ctx.state.nodes[nodeId] = 0;
			ctx.state.nodes[nodeId] = Math.max(0, ctx.state.nodes[nodeId] + (isEnter ? 1 : -1));
			return isEnter && ctx.state.nodes[nodeId] >= ctx.world.router.getPeers().length;
		},
	}),
	node({
		id: "cf10f6a8-b5a8-4827-bc23-5f5f74efe810",
		name: "On Block Zone Enter",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		predictable: true,
		description: "Triggered when a character enters a zone of air blocks",
		controls: addWhoCanTrigger(),
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return {
				character: ctx.info.character,
				position: ctx.info.position,
			};
		},
	}),
	node({
		id: "1a8bc8fe-5b45-43b8-8980-3e8fb558d24c",
		name: "On Block Zone Leave",
		type: NODE_TYPE_ID.entryPointTrigger.block,
		predictable: true,
		description: "Triggered when a character leaves a zone of air blocks",
		controls: addWhoCanTrigger(),
		outputs: {
			exec: { type: "exec" },
			position: { name: "Block Position", type: "vector3" },
			character: { name: "Character", type: "entity" },
		},
		resolve(inputs, ctx) {
			return {
				character: ctx.info.character,
				position: ctx.info.position,
			};
		},
	}),
	node({
		id: "a16e8c4b-b910-449b-8cec-d4eba0ad88fd",
		name: "Character Get Block Collisions",
		type: NODE_TYPE_ID.function.character,
		description: "Get the positions of blocks the character is currently colliding with",
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			head: {
				name: "Head",
				type: "vector3",
			},
			side: {
				name: "Side",
				type: "vector3",
				structure: "list",
			},
			foot: {
				name: "Foot",
				type: "vector3",
			},
		},
		resolve(inputs, _ctx) {
			const collisionState = (inputs.character as Character).collision.state;

			let foot: Vector3 | undefined;
			let head: Vector3 | undefined;

			if (collisionState.footBlock.found) foot = collisionState.footBlock.pos.clone();
			const side = collisionState.sideBlocks.map((e) => e.pos.clone());
			if (collisionState.headBlock.found) head = collisionState.headBlock.pos.clone();

			return {
				head: head!,
				side,
				foot: foot!,
			};
		},
	}),
	node({
		id: "c9c58fc4-5f5f-4309-b5cd-7c385dd29ea9",
		name: "Character Get Block Zone",
		type: NODE_TYPE_ID.function.character,
		description:
			"Get the name and position of the block that the character's feet are currently inside of",
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			groups: {
				name: "Groups",
				// todo: block group type
				type: "string",
				structure: "list",
			},
			position: {
				name: "Position",
				type: "vector3",
			},
		},
		resolve(inputs, _ctx) {
			const zone = (inputs.character as Character).collision.state.zoneBlock;

			let groups;
			if (zone.found) groups = zone.groups;

			return { groups: groups!, position: zone.pos.clone() };
		},
	}),
	node({
		id: "d1411031-29d6-44d7-abc9-bf37b238c61b",
		name: "On Entity Collision Start",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		predictable: true,
		description: "Triggered when an entity starts contacting another entity",
		keywords: ["contact", "hit"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Collision Entity", type: "entity" },
			inputEntity: { name: "Input Entity", type: "entity" },
			contactNormal: { name: "Collision Normal", type: "vector3" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				inputEntity: scope[nodeId].inputEntity,
				entity: scope[nodeId].entity,
				contactNormal: scope[nodeId].contactNormal,
			};
		},
	}),
	node({
		id: "4d224f87-fa98-4278-aa82-41b7f706c5aa",
		name: "On Entity Collision Persisted",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		predictable: true,
		description:
			"Triggered when a contact between entities has persisted, they have been in contact for two ticks.",
		keywords: ["contact", "hit"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Collision Entity", type: "entity" },
			inputEntity: { name: "Input Entity", type: "entity" },
			contactNormal: { name: "Collision Normal", type: "vector3" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				inputEntity: scope[nodeId].inputEntity,
				entity: scope[nodeId].entity,
				contactNormal: scope[nodeId].contactNormal,
			};
		},
	}),
	node({
		id: "464e54a8-cf92-4bee-aaa5-c4f3d542b3c2",
		name: "On Entity Collision End",
		type: NODE_TYPE_ID.callbackTrigger.entity,
		predictable: true,
		description: "Triggered when an entity stops contacting another entity",
		keywords: ["contact", "hit", "stop", "removed"],
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			callback: { type: "exec" },
			entity: { name: "Collision Entity", type: "entity" },
			inputEntity: { name: "Input Entity", type: "entity" },
		},
		listen(inputs, _ctx, _nodeId) {
			if (inputs.entity !== undefined) return { key: inputs.entity.entityID };
		},
		resolve(_inputs, _ctx, nodeId, scope) {
			return {
				inputEntity: scope[nodeId].inputEntity,
				entity: scope[nodeId].entity,
			};
		},
	}),
	node({
		id: "350d4d46-e4e0-4ba9-863e-400387f1fea6",
		name: "Are Entities Colliding",
		type: NODE_TYPE_ID.function.entity,
		description: "Check if two entities are colliding",
		inputs: {
			entityA: { name: "Entity A", type: "entity" },
			entityB: { name: "Entity B", type: "entity" },
		},
		outputs: {
			colliding: { name: "Colliding", type: "boolean" },
		},
		resolve(inputs, ctx) {
			const entityA = inputs.entityA;
			const entityB = inputs.entityB;

			return {
				colliding: Physics.areEntitiesInContact(ctx.world.physics, entityA, entityB),
			};
		},
	}),
];
