import type { MapResource, IMap, IParsedMap } from "../../lib/types";
import { AssetType } from "../../lib/types";
import type { JacyContentState } from "../JacyContentState";
import { getFileType } from "../../lib/helpers/files";
import { generateId } from "../../lib/helpers/general";
import { parseTerrainGenerationOptions } from "../../lib/validations/terrainGeneration";

const DEFAULT_FILE = "map";

const FILE_NAME = "map";
const FILE_EXTENSION = "bb";
const FILE_NAME_WITH_EXTENSION = `${FILE_NAME}.${FILE_EXTENSION}`;

const FILE_TYPE = getFileType(FILE_EXTENSION);

export class JacyMapState {
	#state: JacyContentState;
	#map: IMap;

	constructor(state: JacyContentState) {
		this.#state = state;

		const pk = `${AssetType.MAP}#${generateId()}`;

		this.#map = {
			pk,
			type: AssetType.MAP,
			terrainGenerationOptions: { type: "blank" },
			files: [],
		};
	}

	import(data?: IMap) {
		if (!data) return;

		this.set(data);
	}

	export(): IMap {
		return this.#map;
	}

	async getMapArrayBuffer() {
		const map = this.get();
		const resource = map.files[0]?.resource;

		if (!resource) {
			return null;
		}

		if (resource.file.buffer) {
			return await resource.file.buffer.arrayBuffer();
		}

		return await fetch(resource.file.url).then((response) => response.arrayBuffer());
	}

	get resource() {
		const map = this.get();
		return map.files[0]?.resource;
	}

	get(): IParsedMap {
		const parsedFiles = this.#map.files.map((file) => {
			const resource = this.#state.resources.get(file.resourcePk);

			if (!resource) {
				throw new Error(`Resource ${file.resourcePk} not found for mapfile ${file.name}`);
			}

			return {
				...file,
				resource: resource as MapResource,
			};
		});

		return {
			...this.#map,
			files: parsedFiles,
		};
	}

	async update(buffer: ArrayBuffer) {
		const file = this.#createMapFile(buffer);

		const binary = await this.#state.resources.uploadMap(file);

		this.#clearMapFile();

		this.#map.files = [
			{
				name: DEFAULT_FILE,
				resourcePk: binary.pk,
			},
		];
	}

	set(data: IMap) {
		this.#clearMapFile();

		this.#map = structuredClone(data);
	}

	#clearMapFile() {
		const previousMapFile = this.#map.files[0];

		if (previousMapFile) {
			this.#state.resources.remove(previousMapFile.resourcePk);
		}

		this.#map.files = [];
	}

	setOptions({ terrainGenerationOptions }: Pick<IMap, "terrainGenerationOptions">) {
		const map = this.get();

		terrainGenerationOptions = parseTerrainGenerationOptions(terrainGenerationOptions);

		this.set({
			...map,
			terrainGenerationOptions,
		});
	}

	#createMapFile(buffer: ArrayBuffer) {
		const blob = new Blob([buffer], {
			type: FILE_TYPE,
		});

		return new File([blob], FILE_NAME_WITH_EXTENSION, {
			type: getFileType(FILE_EXTENSION),
		});
	}
}
