import { generateUUID } from "@jamango/content-client";
import { createLogger } from "@jamango/helpers";
import { BB } from "base/BB";
import * as Metrics from "base/world/MetricsManager";
import type { World } from "base/world/World";
import { NET_AWAIT, type IBSBase, type IBSClient, type IBSServer, type Peer } from "@jamango/ibs";
import { netState } from "router/Parallelogram";

const logger = createLogger("Net");

export const HOST = 0;
export const CLIENT = 1;
export const ANY = 2;

export type NetEnvironment = typeof HOST | typeof CLIENT | typeof ANY;

type Command = string | number;

type Listener<T = any> = (data: T, world: World, peer: Peer) => Promise<void> | void;

const listeners = new Map<Command, { fn: Listener; env: NetEnvironment }>();

const commandLabels = {} as Record<Command, string>;

export const impl = {
	ibs: null as IBSBase | null,
};

export const setImpl = (ibs: IBSBase) => {
	impl.ibs = ibs;
};

export const initCommandListeners = () => {
	for (const [command, { fn, env: env }] of listeners) {
		if (env === ANY || (env === HOST && netState.isHost) || (env === CLIENT && !netState.isHost)) {
			listen(command, fn);
		}
	}
};

export const handleWaitList = () => {
	impl.ibs?.handleWaitList();
};

/**
 * Adds a listener to the current ibs implementation. Does not survive impl changes.
 *
 *
 * NOTE: Must be called inside setCommandListener fns.  New code should prefer use of 'register' to declare listeners at module scope.
 */
export const listen = <T = any>(command: Command, listener: Listener<T>) => {
	if (!impl.ibs) {
		logger.error("listen called but Net has no impl");
		return;
	}

	impl.ibs.setCommandListener(command, function (data) {
		listener(data, BB.world, this.peer);
	});
};

let commandCounter = 0;

/**
 * Registers a listener, with an argument for the environment it should be active in.
 */
const register = <T = any>(env: NetEnvironment, label: string, listener: Listener<T>) => {
	const command = commandCounter++;
	listeners.set(command, { fn: listener, env });
	commandLabels[command] = label;
	return command;
};

export const send = (command: Command, data: any, to?: Peer, channel?: string) => {
	if (!impl.ibs) return;
	// TODO: this is only for short/medium term testing. the JSON.stringify here is borderline unacceptable
	// we need to figure out a more efficient way to get the length here - hook it directly into the sendImpl (refactor needed)
	if (data !== undefined) {
		Metrics.increment("send", commandLabels[command] ?? command + "", [1, JSON.stringify(data).length]);
	}

	impl.ibs.sendCommand(command, data, to, channel);
};

export const sendToAll = (command: Command, data: any, except?: Peer, channel?: string) => {
	if (!impl.ibs) return;
	// TODO: this is only for short/medium term testing. the JSON.stringify here is borderline unacceptable
	// we need to figure out a more efficient way to get the length here - hook it directly into the sendImpl (refactor needed)
	if (data !== undefined) {
		Metrics.increment("send to all", commandLabels[command] ?? command + "", [
			1,
			JSON.stringify(data).length,
		]);
	}
	impl.ibs.sendCommandToAll(command, data, except, channel);
};

export const setOffline = () => {
	impl.ibs = null;
};

export const getPeers = () => {
	if (!impl.ibs) return [];

	return Array.from(impl.ibs.peers.values());
};

export const getPeersMap = () => {
	if (!impl.ibs) return new Map();

	return impl.ibs.peers;
};

export const getMaxPeers = (): number => {
	if (!impl.ibs) return 0;

	return (impl.ibs as IBSServer).maxPeers;
};

export const flush = () => {
	if (!impl.ibs) return;

	impl.ibs.flushBatchDownToilet();
};

export const getPeerId = () => {
	if (!impl.ibs) return "loopback";

	return (impl.ibs as IBSClient).peerID;
};

export const setBatchingEnabled = (enabled: boolean) => {
	if (!impl.ibs) return;

	impl.ibs.setPeerSocketSettings({ batching: enabled });
};

export const isConnected = () => {
	return !!impl.ibs && impl.ibs.mode > NET_AWAIT;
};

export const isOffline = () => {
	return !impl.ibs;
};

export const rpc = <Request = void, Response extends void | Array<unknown> | Record<string, unknown> = void>(
	label: string,
	serverFn: (data: Request, world: World, peer: Peer | null) => Promise<Response> | Response,
) => {
	const responseResolvers = new Map<string, (response: Response) => void>();

	const responseCommand = command(label, CLIENT, (data: [requestId: string, response: Response]) => {
		const [requestId, response] = data;

		const resolver = responseResolvers.get(requestId);

		if (!resolver) {
			logger.error(`No resolver found for response with requestId: ${requestId}`);
			return;
		}

		resolver(response);

		responseResolvers.delete(requestId);
	});

	const requestCommand = command(
		label,
		HOST,
		async ([requestId, data]: [requestId: string, data: Request], world: World, peer: Peer) => {
			const response = await serverFn(data, world, peer);

			responseCommand.send([requestId, response], peer);
		},
	);

	const handler = async (data: Request) => {
		if (netState.isHost) {
			return await serverFn(data, BB.world, null);
		} else {
			const requestId = generateUUID();

			requestCommand.send([requestId, data]);

			const response = await new Promise<Response>((resolve) => {
				responseResolvers.set(requestId, resolve);
			});

			return response;
		}
	};

	return handler;
};

export const command = <T>(
	label: string,
	env: NetEnvironment,
	fn: (data: T, world: World, peer: Peer) => Promise<void> | void,
) => {
	const id = register(env, label, fn);

	return {
		send: (data: T, to?: Peer, channel?: string) => {
			send(id, data, to, channel);
		},
		sendToAll: (data: T, except?: Peer, channel?: string) => {
			sendToAll(id, data, except, channel);
		},
	};
};
