import { Matrix4, Scene } from "three";

export const sceneMatrix = {
	copy: () => {},
	decompose: (position, quaternion, scale) => {
		position.set(0, 0, 0);
		quaternion.set(0, 0, 0, 1);
		scale.set(1, 1, 1);
	},
	elements: new Matrix4().identity().elements,
};

export class FasterScene extends Scene {
	constructor() {
		super();
		this.matrixAutoUpdate = false;

		/**
		 * @type {Matrix4}
		 */
		this.matrixWorld = sceneMatrix;
	}
}
