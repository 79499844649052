import { type PeerMetadata, getDefaultPermissions } from "@jamango/engine/PeerMetadata.ts";
import type { LoopbackPeer, Peer } from "@jamango/ibs";

const key: unique symbol = Symbol("Peer Metadata");

export function setPeerMetadata(peer: Peer | LoopbackPeer, data: Omit<PeerMetadata, "permissions">) {
	peer.metadata.set(key, {
		...data,
		permissions: getDefaultPermissions(),
	} satisfies PeerMetadata);
}

export function getPeerMetadata(peer: Peer | LoopbackPeer) {
	return peer.metadata.get(key) as PeerMetadata;
}
