/**
 * @param {import("base/world/fx/Sky").SkyBase} sky
 * @param {import("base/world/World").World} world
 * @param {import("@jamango/content-client/lib/types/engine.ts").IEngineWorldData["sky"]} [o]
 */
export function init(sky, world, o) {
	sky.dayLength = o?.dayLength ?? -1;
	if (sky.dayLength === -1) sky.dayLength = Infinity;

	sky.time = o?.time ?? 17.29 / 24;
}

/**
 * @param {import("base/world/World").World} world
 * @param {number} stepDeltaTime
 */
export function update(world, stepDeltaTime) {
	world.sky.time = (world.sky.time + stepDeltaTime / world.sky.dayLength) % 1;
}
