//top level
import type { BB } from "base/BB";

// Base
import { BLOCK_AIR } from "base/world/block/Util.js";
import { bulkBox } from "base/world/block/Bulk.js";
import { wait } from "base/util/Time.js";
import { ChunkUtil, ShapeUtil } from "base/world/block/Util.js";
import * as BlockGroups from "base/world/block/BlockGroups";

// Client
import { clientConst } from "client/util/Const.js";
import { PathfindingHelper } from "client/world/debug/PathfindingHelper.js";
import { sendChat } from "client/dom/Chat";

// Router
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import { Multiplayer } from "base/Multiplayer.js";

// IBS
import { NET_SERVER, NET_CLIENT } from "@jamango/ibs";

// Mods`
import { getBuiltIn } from "mods/Builtins.js";

/*
  Exposed BB API for:
  - React usage.
  - Rete Editor inputs function usage at EditorClient.jsx.
  - Rete Interpreter usage at Interpreter.js.
*/
export const EXPOSE = {
	BB: null! as typeof BB,
	DEBUG: null as any, //need for feature flag impl
	gbi: {
		base: {
			Multiplayer,
			bulkBox,
			BLOCK_AIR,
			wait,
			ChunkUtil,
			ShapeUtil,
			BlockGroups,
		},
		server: {},
		client: {
			clientConst,
			PathfindingHelper,
			sendChat,
		},
		router: {
			BlockGroupsRouter,
		},
		ibs: {
			NET_SERVER,
			NET_CLIENT,
		},
		mods: {
			getBuiltIn,
		},
	},
} as const;
