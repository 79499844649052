export async function* prioritizedAsyncPool<T, R>(
	concurrency: number,
	queue: Array<T>,
	iteratorFn: (item: T, iterable: Iterable<T>) => Promise<R>,
	prioritizeFn?: (a: T, b: T) => number,
) {
	const executing = new Set<Promise<[Promise<any>, any]>>();

	async function consume() {
		const [promise, value] = await Promise.race(executing);
		executing.delete(promise);
		return value;
	}

	while (queue.length > 0) {
		if (prioritizeFn) {
			queue.sort((a, b) => prioritizeFn(a, b));
		}

		const availableConcurrency = concurrency - executing.size;

		for (let i = 0; i < availableConcurrency && queue.length > 0; i++) {
			const item = queue.shift()!;

			// Wrap iteratorFn() in an async fn to ensure we get a promise.
			// Then expose such promise, so it's possible to later reference and
			// remove it from the executing pool.
			const promise: Promise<[Promise<any>, any]> = (async () => await iteratorFn(item, queue))().then(
				(value) => [promise, value],
			);

			executing.add(promise);
		}

		if (executing.size >= concurrency) {
			yield await consume();
		}
	}

	while (executing.size) {
		yield await consume();
	}
}
