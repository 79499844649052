import { LegacyVectorMap } from "base/util/math/LegacyVectorMap";
import { defPerlin } from "base/util/Defs";
import { MathUtils } from "three";
import { ChunkBuilder } from "base/world/block/Builder";
import {
	BLOCK_AIR,
	BLOCK_SNY,
	BLOCK_SPY,
	BLOCK_TL,
	BLOCK_BL,
	BLOCK_TR,
	BLOCK_BR,
	BLOCK_CUBE,
	BLOCK_VIS_LEFT,
	BLOCK_VIS_RIGHT,
} from "base/world/block/Util";
import type { PerlinNoiseGenerator } from "base/util/math/Perlin";
import type { Chunk } from "base/world/block/chunk/Chunk";
import type { BlockTypeRegistryState } from "base/world/block/BlockTypeRegistry";
import type { BlockID, ITerrain } from "./types";
import type { PerlinOptions } from "@jamango/content-client/lib/types/terrainGeneration.ts";

const MVR_REVISIONS = [0, 1] as const;

export class GeneratorMVRTerrain implements ITerrain {
	static name = "bb.generator.terrain.mvr" as const;
	static display = "Mountains, Valleys, and Rivers" as const;
	isTerrain = true as const;

	private revision: (typeof MVR_REVISIONS)[number];
	private perlinM: PerlinOptions;
	private perlinVR: PerlinOptions;
	private perlinSnow: PerlinOptions;

	readonly amp: {
		M: number;
		VR: number;
	};

	private snowMin: number;
	private snowMax: number;
	private vrTransition: number;
	private mprob: number;
	readonly rivery: number;
	private stoney: number;

	private blockGrassName: string;
	private blockDirtName: string;
	private blockSandName: string;
	private blockStoneName: string;
	private blockSnowName: string;

	private blockGrass: BlockID = 0;
	private blockDirt: BlockID = 0;
	private blockSand: BlockID = 0;
	private blockStone: BlockID = 0;
	private blockSnow: BlockID = 0;

	private perlin: {
		M: PerlinNoiseGenerator;
		VR: PerlinNoiseGenerator;
		snow: PerlinNoiseGenerator;
	};

	private heightCache: LegacyVectorMap;
	private mountainCache: LegacyVectorMap;

	private chunkSize = 0;

	/**
	 * OPT Number revision [0] - Choose which version of the generator to use
	 *
	 * 1 (016ff2e8 change perlin seed)
	 * 0 (287faca original)
	 * OPT Object perlinM [{ scale: 250, octaves: 4 }] - defPerlin options for mountainous terrain
	 * OPT Object perlinVR [{ scale: 250, octaves: 5 }] - defPerlin options for valley/river terrain
	 * OPT Object perlinSnow [{ scale: 50, octaves: 3 }] - defPerlin options for snow height
	 * OPT Number ampM [200] - Perlin noise amplifier ("hilliness") for mountainous terrain
	 * OPT Number ampVR [13] - Perlin noise amplifier ("hilliness") for valley/river terrain
	 * OPT Array snowVariance [[50, 80]] - Range of height values that snow will begin to generate at
	 * OPT Number vrTransition [15] - How short of a transition between the bottoms of rivers and the tops of valleys. Higher = shorter
	 * OPT Number mProbability [0.35] - Percentage of the entire terrain covered by mountains, in range [0, 1]
	 * OPT Integer rivery [4] - Highest position of dirt blocks where rivers will form
	 * OPT Integer stoney [-10] - Depth of stone below dirt, AKA number of dirt layers (excluding the top grass layer)
	 * OPT String blockGrass ["block#CC96F3CD5C5EA294CD581B7F69E5609B"]
	 * OPT String blockDirt ["block#1243423C9831127C92653DD89AE610EF"]
	 * OPT String blockSand ["block#374B61FB60D607C88332FA4FBE1F5D12"]
	 * OPT String blockStone ["block#48561BFA726F47B170ED10E76EACFBB9"]
	 * OPT String blockSnow ["block#7F5554819711994B04E484913CA6A8C3"]
	 */
	constructor(o?: {
		revision?: 1;
		perlinM?: PerlinOptions;
		perlinVR?: PerlinOptions;
		perlinSnow?: PerlinOptions;

		ampM?: number;
		ampVR?: number;

		snowVariance?: [number, number];

		vrTransition?: number;
		mProbability?: number;

		rivery?: number;
		stoney?: number;

		blockGrass?: string;
		blockDirt?: string;
		blockSand?: string;
		blockStone?: string;
		blockSnow?: string;
	}) {
		this.revision = o?.revision ?? 1;
		if (!MVR_REVISIONS.includes(this.revision))
			throw Error(`Unknown generator revision "${this.revision}"`);

		this.perlinM = o?.perlinM ?? { scale: 250, octaves: 4 };
		this.perlinVR = o?.perlinVR ?? { scale: 250, octaves: 5 };
		this.perlinSnow = o?.perlinSnow ?? { scale: 50, octaves: 3 };

		this.amp = {
			M: o?.ampM ?? 200,
			VR: o?.ampVR ?? 13,
		};

		this.snowMin = o?.snowVariance?.[0] ?? 50;
		this.snowMax = o?.snowVariance?.[1] ?? 80;

		this.vrTransition = -(o?.vrTransition ?? 15);
		this.mprob = -2 * (o?.mProbability ?? 0.35) + 1;
		this.rivery = o?.rivery ?? 4;
		this.stoney = o?.stoney ?? -10;

		this.blockGrassName = o?.blockGrass ?? "block#CC96F3CD5C5EA294CD581B7F69E5609B";
		this.blockDirtName = o?.blockDirt ?? "block#1243423C9831127C92653DD89AE610EF";
		this.blockSandName = o?.blockSand ?? "block#374B61FB60D607C88332FA4FBE1F5D12";
		this.blockStoneName = o?.blockStone ?? "block#48561BFA726F47B170ED10E76EACFBB9";
		this.blockSnowName = o?.blockStone ?? "block#7F5554819711994B04E484913CA6A8C3";
	}

	init({
		seed,
		blockTypeRegistry,
		chunkSize,
	}: {
		seed: number;
		blockTypeRegistry: BlockTypeRegistryState;
		chunkSize: number;
	}) {
		this.chunkSize = chunkSize;

		const blockNameToType = blockTypeRegistry.blockNameToType;
		this.blockGrass = blockNameToType.get(this.blockGrassName)!.id;
		this.blockDirt = blockNameToType.get(this.blockDirtName)!.id;
		this.blockSand = blockNameToType.get(this.blockSandName)!.id;
		this.blockStone = blockNameToType.get(this.blockStoneName)!.id;
		this.blockSnow = blockNameToType.get(this.blockSnowName)!.id;

		//use 3 different variations on the seed to prevent getting similar/identical noise from each generator
		this.perlin = {
			M: defPerlin(this.perlinM, seed, "bb.generator.terrain.mvr.m"),
			VR: defPerlin(this.perlinVR, seed, "bb.generator.terrain.mvr.vr"),
			snow: defPerlin(this.perlinSnow, seed, "bb.generator.terrain.mvr.snow"),
		};

		this.heightCache = new LegacyVectorMap();
		this.mountainCache = new LegacyVectorMap();
	}

	apply(chunk: Chunk) {
		const chunkSize = this.chunkSize;
		const shapes = chunk.storage.shapes;
		const types = chunk.storage.types;
		const cx = chunk.position.x;
		const cy = chunk.position.y;
		const cz = chunk.position.z;
		const tory = -cy * chunkSize;

		const rivery = this.rivery - 1 + tory;
		const beachy = rivery + 1;
		const stoney = this.stoney;

		const getTopLayer = (y: number, isMountain: boolean, snowHeight: number): BlockID => {
			if (isMountain) return y >= snowHeight ? this.blockSnow : this.blockStone;
			if (y <= rivery) return this.blockDirt;
			if (y === beachy) return this.blockSand;

			return this.blockGrass;
		};

		let rz = 0;
		let rx = 0;

		//single block spit out by the height map
		const buildLayer = (start: number, end: number, type: BlockID) => {
			for (let ry = start; ry < end; ry++) {
				const i = chunk.getIndex(rx, ry, rz);
				shapes[i] = BLOCK_CUBE;
				types[i] = type;
			}
		};

		let snowHeight = 0;

		for (rz = 0; rz < chunkSize; rz++) {
			for (rx = 0; rx < chunkSize; rx++) {
				const ax = rx + cx * chunkSize;
				const az = rz + cz * chunkSize;

				const ryTL = this.getHeight(ax, az) + tory;
				const ryBL = this.getHeight(ax, az + 1) + tory;
				const ryTR = this.getHeight(ax + 1, az) + tory;
				const ryBR = this.getHeight(ax + 1, az + 1) + tory;

				const mountainTL = this.isMountain(ax, az);
				const mountainBL = this.isMountain(ax, az + 1);
				const mountainTR = this.isMountain(ax + 1, az);
				const mountainBR = this.isMountain(ax + 1, az + 1);
				const isMountain = mountainTL && mountainBL && mountainTR && mountainBR;

				if (isMountain) snowHeight = this.getSnowHeight(ax, az) + tory;

				let yMin = Math.min(ryTL, ryBL, ryTR, ryBR);
				const yMax = yMin + 1;
				const yBeneath = yMin - 1;
				const yPeak = yMax + 1;
				let sculpt = BLOCK_AIR;
				let shape = 0;

				if (ryTL >= yMax) sculpt |= BLOCK_TL;
				if (ryBL >= yMax) sculpt |= BLOCK_BL;
				if (ryTR >= yMax) sculpt |= BLOCK_TR;
				if (ryBR >= yMax) sculpt |= BLOCK_BR;

				if (sculpt === BLOCK_AIR) {
					shape = sculpt = BLOCK_CUBE;
					yMin--;
				} else {
					shape = sculpt | BLOCK_SPY;
				}

				if (yMin >= 0 && yMin < chunkSize) {
					const i = chunk.getIndex(rx, yMin, rz);
					shapes[i] = shape;
					types[i] = getTopLayer(yMin, isMountain, snowHeight);
				}

				//full block with a 1 up sliver piece on top: full block on bottom must be manually added
				const tris = ChunkBuilder.whichTriangles(shape, BLOCK_SNY);
				if (
					yBeneath >= 0 &&
					yBeneath < chunkSize &&
					(tris === BLOCK_VIS_LEFT || tris === BLOCK_VIS_RIGHT)
				) {
					yMin = yBeneath;
					const i = chunk.getIndex(rx, yMin, rz);
					shapes[i] = BLOCK_CUBE;
					types[i] = getTopLayer(yMin, isMountain, snowHeight);
				}

				//steep ramp blocks on top of each other: 1 up block on top must be manually added
				if (yMax >= 0 && yMax < chunkSize) {
					if (sculpt === (BLOCK_TL | BLOCK_BL | BLOCK_TR) && ryTL >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_TL;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_TL | BLOCK_BL | BLOCK_BR) && ryBL >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_BL;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_TL | BLOCK_TR | BLOCK_BR) && ryTR >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_TR;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					} else if (sculpt === (BLOCK_BL | BLOCK_TR | BLOCK_BR) && ryBR >= yPeak) {
						const i = chunk.getIndex(rx, yMax, rz);
						shapes[i] = BLOCK_SPY | BLOCK_BR;
						types[i] = getTopLayer(yMax, isMountain, snowHeight);
					}
				}

				let columnStart;
				let columnEnd;

				if (isMountain) {
					columnStart = 0;
					columnEnd = Math.min(yMin, chunkSize);
					buildLayer(columnStart, columnEnd, this.blockStone);
				} else {
					//stone layer
					columnStart = 0;
					columnEnd = Math.min(yMin + stoney, chunkSize);
					buildLayer(columnStart, columnEnd, this.blockStone);

					//dirt layer
					columnStart = Math.max(columnEnd, 0);
					columnEnd = Math.min(yMin, chunkSize);
					buildLayer(columnStart, columnEnd, this.blockDirt);
				}
			}
		}
	}

	getHeight(ax: number, az: number) {
		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);

		let cache: Float64Array | undefined = this.heightCache.get(cx, cz);
		if (!cache) {
			cache = new Float64Array(chunkSize ** 2);
			let height;

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const acx = rx + cx * chunkSize;
					const acz = rz + cz * chunkSize;

					const noiseM = this.getMountainHeight(acx, acz);
					const noiseVR = this.perlin.VR.getNoise(acx, acz);
					const heightVR = Math.round(
						this.amp.VR * (1 - 2 ** (this.vrTransition * Math.abs(noiseVR))),
					);

					if (noiseM > this.mprob) {
						height = Math.round(
							this.amp.M *
								MathUtils.mapLinear(
									(noiseM + 1) / 2,
									(this.mprob + 1) / 2,
									1,
									heightVR / this.amp.M,
									1,
								),
						);
					} else {
						height = heightVR;
					}

					cache[rz * chunkSize + rx] = height;
				}
			}

			this.heightCache.set(cx, cz, cache);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return cache[rz * chunkSize + rx];
	}

	private getMountainHeight(ax: number, az: number) {
		const chunkSize = this.chunkSize;
		const cx = Math.floor(ax / chunkSize);
		const cz = Math.floor(az / chunkSize);

		let cache: Float64Array | undefined = this.mountainCache.get(cx, cz);
		if (!cache) {
			cache = new Float64Array(chunkSize ** 2);

			for (let rz = 0; rz < chunkSize; rz++) {
				for (let rx = 0; rx < chunkSize; rx++) {
					const acx = rx + cx * chunkSize;
					const acz = rz + cz * chunkSize;
					cache[rz * chunkSize + rx] = this.perlin.M.getNoise(acx, acz);
				}
			}

			this.mountainCache.set(cx, cz, cache);
		}

		const rx = ax - cx * chunkSize;
		const rz = az - cz * chunkSize;
		return cache[rz * chunkSize + rx];
	}

	private isMountain(ax: number, az: number) {
		return this.getMountainHeight(ax, az) > this.mprob;
	}

	private getSnowHeight(ax: number, az: number) {
		const noise = this.perlin.snow.getNoise(ax, az);
		return Math.round(MathUtils.mapLinear(noise, -1, 1, this.snowMin, this.snowMax));
	}

	clearCache() {
		for (const cache of Object.values(this.perlin)) cache.clearCache();

		this.heightCache.clear();
		this.mountainCache.clear();
	}
}
