import { LONG_HASH } from "@jamango/generated";
import { createLogger } from "@jamango/helpers";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import { initExtensions } from "base/util/Extensions";
import type { World } from "base/world/World";
import { BBClient } from "client/BB";
import { UI } from "client/dom/UI";

import { netState } from "router/Parallelogram";
import { BBServerDedi } from "server/BB";

import * as PersistentDataServer from "server/world/persistent-data/PersistentData";
import type { JamangoEngineOptions } from "@jamango/engine/EngineOptions.ts";
import { BBRouter } from "router/BB";

import { EXPOSE } from "../Expose";
import { DEBUG } from "../Debug";
import { initJoltModule } from "./world/Physics";

//export const BB_CLIENT_INTERVAL = 1 / ?;  // the clients tick at local refresh rate, fixed time steps are done in-between
export const BB_CLIENT_BG_INTERVAL = 1 / 30; // the clients tick rate when alt tabbed / minimized / in background
export const BB_DEDI_INTERVAL = 1 / 60; // the general dedi interval - this is how often net updates are sent. fixed timesteps are done in-between

export class BB {
	static world: World = null!;
	static logger = createLogger(null);

	static dedicated: boolean;

	static router = BBRouter;
	static serverDedi = BBServerDedi;
	static client = BBClient;

	static joltPhysicsWasmUrl: string;

	static contentImpl: JamangoEngineOptions["content"];

	static async init(options: JamangoEngineOptions): Promise<typeof EXPOSE> {
		try {
			BB.logger.info("Jamango!");
			BB.logger.info("Revision", LONG_HASH);

			this.joltPhysicsWasmUrl = options.joltPhysicsWasmUrl;
			this.contentImpl = options.content;

			initExtensions();

			(globalThis as any).chuckNorris = () => BB.world.router.dispose();

			netState.isClient = !options.dedicated;

			if (options.persistentDataImpl) {
				PersistentDataServer.setPersistentDataImpl(options.persistentDataImpl);
			}

			if (netState.isClient) {
				await BBClient.init(options.ui);
				await LoadingScreen.setText("Loading engine");
			}

			await initJoltModule();

			if (options.dedicated) {
				BBServerDedi.main();
			} else {
				await BBClient.main(options);
			}

			BBRouter.initUpdateLoop();

			//horrid circular dependency workaround hack
			//replace frontend's engine store with "import from @engine/runtime"?
			//or better yet, big decouple session
			return { ...EXPOSE, DEBUG: { ...DEBUG, BB }, BB };
		} catch (e) {
			if (!options.dedicated) UI.state.errorNotification?.().setError(e);
			throw e;
		}
	}
}
