import { DEGRAD } from "base/util/math/Math.ts";
import { Color, Euler, Vector3 } from "three";
import { OnHitPlayerParticlesDefault } from "./DefaultParticles";

const s = new Vector3(0.5, 0.5, 2); //collider size

export const ItemZapperAssault = {
	name: "ItemZapperAssault",
	display: "Zapper Assault Rifle",
	assets: [
		{
			id: "mdl-item-zapper-assault",
			url: "assets/engine/mods/ItemZapperAssault/mdl-item-zapper-assault.glb",
			type: "glb",
		},
	],
	mesh: {
		asset: "mdl-item-zapper-assault",
		node: "item",
		transform: { rot: new Euler(-90 * DEGRAD, 180 * DEGRAD, 0), scl: 0.01 },
		animated: true,
	},
	muzzleFlashNode: "muzzle_flash_GEO",
	geom: { type: "box", width: s.x, height: s.y, depth: s.z },
	type: "ranged",
	weapon: true,
	automatic: true,
	animations: "ZapperAssault",
	shootAnimationName: {
		RigDefault: {
			first: {
				hip: "JMGO_ZapperAssault_FP_NotAim_Shoot",
				sight: "JMGO_ZapperAssault_FP_Aim_Shoot",
			},
			third: "JMGO_ZapperAssault_Shoot_Forward",
		},
	},
	reloadAnimationName: {
		item: "JMGO_ZapperAssault_Reload_rig",
		RigDefault: {
			third: "JMGO_ZapperAssault_Reload",
			first: "JMGO_ZapperAssault_FP_NotAim_Reload",
		},
	},
	reloadAnimationTime: 2.5,
	cooldown: 0.08,
	projectile: "ProjectileZapperAssault",
	projectilePos: new Vector3(0, 50, -10),
	projectileCartridgeLimit: 30,
	projectilesPerShot: 1,
	shakeVertical: 0.025,
	shakeVerticalMax: 0.25,
	shakeHorizontal: 0.015,
	shakeHorizontalMax: 0.15,
	shakeRecovery: 0.8,
	spread: 1.5,
	recoil: 0.016,
	sound: {
		fire: { asset: "snd-item-zap-assault-fire", volume: 2 },
		reload: { asset: "snd-item-zap-assault-reload", volume: 2 },
	},
	muzzleFlashParticles: [
		{
			colors: [...new Color("#87ffa7").toArray(), 0.2],
			type: "cube",
			numParticles: 30,
			positionRange: [0.1, 0.1, 0.1],
			lifeTime: 1,
			endTime: 1,
			startTime: 0,
			startSize: 0.15,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			radius: 0.15,
			velocityAimDirectionIntensity: 1,
			velocity: [0, 0, 0],
			velocityRange: [1, 0.5, 1],
			acceleration: [0, 0, 0],
			accelerationRange: [1, 0.1, 1],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.1,
		},
	],
};

const hitParticlesColor = new Color("#3dffac");

export const ProjectileZapperAssault = {
	name: "ProjectileZapperAssault",
	velocity: 200,
	entityDamage: 22,
	headshotDamage: 60,
	sound: {
		impact: { asset: "snd-laser-impact" },
	},
	bulletTrail: true,
	bulletTrailColor: "#3dffac",
	onHitParticles: [
		// smoke
		{
			colors: [
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0.1,
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0,
			],
			type: "cube",
			numParticles: 10,
			positionRange: [0.2, 0.1, 0.2],
			lifeTime: 3,
			endTime: 3,
			startTime: 0,
			startSize: 0.5,
			endSize: -0.15,
			sizeRange: 0.15,
			spinSpeedRange: 2,
			radius: 0.15,
			velocity: [0, 0, 0],
			velocityRange: [0.2, 0.05, 0.2],
			acceleration: [0, 0.05, 0],
			accelerationRange: [0.3, 0, 0.3],
			gravity: [0, 0, 0],
			tween: "outQuad",
			blending: "normal",
			alphaTest: 0.05,
		},
		// impact
		{
			colors: [
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				1,
				hitParticlesColor.r,
				hitParticlesColor.g,
				hitParticlesColor.b,
				0,
			],
			type: "round",
			blending: "normal",
			numParticles: 10,
			positionRange: [0.1, 0, 0.1],
			lifeTime: 0.3,
			startTime: 0,
			startSize: 0.5,
			endTime: 0.3,
			endSize: -0.15,
			sizeRange: 0.15,
			velocityHitNormalIntensity: 3.5,
			velocityRange: [1, 1, 1],
		},
	],
	onHitPlayerParticles: OnHitPlayerParticlesDefault,
};
