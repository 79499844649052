import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { LoopOnce } from "three";
import { ITEMS } from "@jamango/content-client";

export class LocomotionStateJumpingFP extends LocomotionOutputState {
	constructor(animMesh, locomotionInput) {
		super("JumpingFP", animMesh, locomotionInput);

		this.transitionToIdleTime = 0.2;
		this.transitionToRunningTime = 0.2;
		this.transitionToWalkingTime = 0.2;

		this.trackMap = new Map();

		ITEMS.forEach((item) => {
			const notAimActionName = `JMGO_${item}_FP_NotAim_Jump`;
			const aimActionName = `JMGO_${item}_FP_Aim_Jump`;

			if (animMesh.clips[notAimActionName]) {
				this.trackMap.set(
					`${item}_Jump_NotAim`,
					new AnimationTrack(animMesh, notAimActionName, this),
				);
			}

			if (animMesh.clips[aimActionName]) {
				this.trackMap.set(`${item}_Jump_Aim`, new AnimationTrack(animMesh, aimActionName, this));
			}
		});

		this.defaultJumpingTrack = this.trackMap.get("NovaGun_Jump_NotAim");

		this.lastTrack = this.defaultJumpingTrack;
	}

	getAimType() {
		return this.inputState.isAiming ? "Aim" : "NotAim";
	}

	getTrack() {
		const aimType = this.getAimType();
		return this.trackMap.get(`${this.currentItem}_Jump_${aimType}`);
	}

	onStateOff() {
		super.onStateOff();
		for (const track of this.animationTracks) {
			track.setCurrentWeight(0);
		}
	}

	startCompute(transitionData) {
		const itemAnimations = this.inputState.item;
		const aimType = this.getAimType();

		if (itemAnimations) {
			this.lastTrack = this.trackMap.get(`${itemAnimations}_Jump_${aimType}`);
		}

		if (!this.lastTrack) {
			this.lastTrack = this.defaultJumpingTrack;
		}

		this.currentItem = itemAnimations;

		this.lastTrack.action.reset();
		this.lastTrack.action.loop = LoopOnce;
		this.lastTrack.switchOn();

		if (transitionData.prvState) {
			transitionData.prvState.startFadeOut(transitionData);
		}

		this.lastTrack.action.play();
		this.startFadeIn(transitionData);
	}

	compute() {
		if (this.state === "off") {
			return;
		}

		const previousTrack = this.lastTrack;
		const newTrack = this.getTrack();

		if (newTrack && previousTrack !== newTrack) {
			previousTrack.startFadeOut(0.2);

			newTrack.action.reset();
			newTrack.action.loop = LoopOnce;
			newTrack.startFadeIn(0.2);
			newTrack.action.syncWith(this.lastTrack.action);
			newTrack.action.play();

			this.lastTrack = newTrack;
		}

		for (const track of this.animationTracks) {
			if (track !== previousTrack && track !== newTrack && track.state === "on") {
				track.switchOff();
			}
		}

		this.updateWeight();

		for (const [, value] of this.trackMap) {
			value.update();
		}
	}

	shouldTransitionCustom(outTransitionToState, map) {
		if (this.lastTrack.action.getRemainingTime() < this.transitionToIdleTime) {
			if (this.inputState.isMoving) {
				if (this.inputState.isSprinting) {
					outTransitionToState.ptr = map.get("RunningFP");
					outTransitionToState.blendTime = this.transitionToRunningTime;
				} else {
					outTransitionToState.ptr = map.get("WalkingFP");
					outTransitionToState.blendTime = this.transitionToWalkingTime;
				}
			} else {
				outTransitionToState.ptr = map.get("IdleFP");
				outTransitionToState.blendTime = this.transitionToIdleTime;
			}

			return true;
		}

		return false;
	}
}
