import type { Character } from "base/world/entity/Character";
import * as Physics from "base/world/Physics";
import { CharacterMovementMode } from "base/world/entity/component/CharacterMovement";
import { entityIsCharacter } from "base/world/entity/component/Type";
import type { Entity } from "base/world/entity/Entity";
import { Vector3 } from "three";

const _previousPosition = new Vector3();
const _relativeContactPosition = new Vector3();
const _bodyPosition = new Vector3();

export function characterPhysicsAfterStepUpdate(entity: Entity, _stepDeltaTime: number) {
	if (!canUpdateSystem(entity)) return;

	// checked in canUpdateSystem
	const controller = entity.characterPhysics.state.controller!;

	/* update entity position and displacement */
	const previousPosition = _previousPosition.copy(entity.position);

	// if dynamic - the inner rigid body is dynamic and updates the virtual character controller
	// if kinematic - the inner rigid body is kinematic and controlled by the virtual character controller
	if (entity.movement.def.mode === CharacterMovementMode.DYNAMIC) {
		// the inner rigid body is the source of truth, it "teleports" the virtual character
		const innerBody = Physics.getCharacterControllerBody(entity.world.physics, controller);
		Physics.getBodyPosition(innerBody, _bodyPosition);
		Physics.setCharacterControllerPosition(controller, _bodyPosition);
		Physics.refreshCharacterControllerContacts(
			entity.world.physics,
			controller,
			entity.world.content.state.gameMechanics.characterCollisions,
		);
		Physics.updateCharacterControllerActiveContacts(entity.world.physics, controller);
	}

	// set the entity position to the character controller position
	Physics.getCharacterControllerPosition(controller, entity.position);

	// update contacts list state
	entity.characterPhysics.state.activeContacts = controller.activeContacts;

	// update displacement difference
	const dispActual = entity.movement.state.displacementActual;
	dispActual.copy(entity.position).sub(previousPosition);

	// if the character is in dynamic mode, update the velocity from the post-step velocity
	if (entity.movement.def.mode === CharacterMovementMode.DYNAMIC) {
		const body = Physics.getEntityBody(entity.world.physics, entity);
		if (body) {
			Physics.getBodyLinearVelocity(body, entity.movement.state.velocity);
		}
	}

	// determine whether the character is on the ground
	const collisionState = entity.collision.state;
	collisionState.hitFoot = Physics.getIsCharacterControllerSupported(controller);
	collisionState.onGround = Physics.getIsCharacterControllerOnGround(controller);

	// determine whether the character's side and head was hit from the contact normal and position
	collisionState.hitSide = false;
	collisionState.hitHead = false;

	const headHitPositionLowerLimit = entity.size.state.height - entity.size.state.eyeHeight;
	const headHitPositionUpperLimit = entity.size.state.height + entity.size.def.padding + 0.1;

	for (const contact of entity.characterPhysics.state.activeContacts) {
		const relativeContactPosition = _relativeContactPosition.copy(contact.position).sub(entity.position);

		if (
			contact.normal.y < -0.5 &&
			headHitPositionLowerLimit < relativeContactPosition.y &&
			relativeContactPosition.y < headHitPositionUpperLimit
		) {
			collisionState.hitHead = true;
		} else if (Math.abs(contact.normal.x) > 0.5 || Math.abs(contact.normal.z) > 0.5) {
			collisionState.hitSide = true;
		}
	}
}

function canUpdateSystem(entity: Entity): entity is Character {
	return (
		entityIsCharacter(entity) &&
		!entity.prophecy.state.isProphecy &&
		!entity.mount.state.parent &&
		!!entity.characterPhysics.state.controller
	);
}
