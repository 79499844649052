import { ChunkUtil } from "base/world/block/Util.js";
import { ChunkBuilder } from "base/world/block/Builder.js";

export const AGGREGATE_EMPTY = 3;
export const AGGREGATE_FULL = 2;
export const AGGREGATE_MIXED = 1;
export const AGGREGATE_DIRTY = 0;

export class BlockStorageComponent {
	/**
	 * @type {Uint8Array}
	 */
	shapes;
	/**
	 * @type {Uint16Array}
	 */
	types;

	constructor(chunk) {
		this.chunk = chunk;

		this.shapes = null; //raw data
		this.types = null; //raw data
		this.compressedData = null;
		this.volume = chunk.scene.chunkSize ** 3;

		this.aggregate = AGGREGATE_DIRTY; // flag that describes the sum of all shapes
	}

	createEmptyData() {
		this.shapes = new Uint8Array(this.volume);
		this.types = new Uint16Array(this.volume);
		this.aggregate = AGGREGATE_DIRTY;
	}

	decompress() {
		if (!this.isCompressed()) return; // nothing to decompress
		if (this.isDecompressed()) return; // already decompressed

		this.shapes ??= new Uint8Array(this.volume);
		this.types ??= new Uint16Array(this.volume);
		this.aggregate = AGGREGATE_DIRTY;
		ChunkUtil.decompress(this.compressedData, this.shapes, this.types);
	}

	compress() {
		if (this.isCompressed()) return this.compressedData;
		return (this.compressedData = ChunkUtil.compress(this.shapes, this.types));
	}

	//compressed and decompressed are not mutually exclusive. if they are both false, the chunk is still downloading
	isDecompressed() {
		return this.shapes !== null;
	}

	isCompressed() {
		return this.compressedData !== null;
	}

	build(neighborChunks, aoMainThread) {
		this.onReadOp(); // neighbor chunks will also do onReadOp in the visibility check

		const scene = this.chunk.scene;
		const chunkSize = scene.chunkSize;
		const buffers = ChunkBuilder.build(
			this.shapes,
			this.types,
			chunkSize,
			chunkSize,
			chunkSize,
			scene.world.blockTypeRegistry,
			aoMainThread,
			neighborChunks,
		);

		return buffers;
	}

	//must call read/write before the operation to assert that the data is decompressed and ready to rumble
	onReadOp() {
		this.decompress();
	}

	onWriteOp() {
		this.onReadOp();
		this.compressedData = null;
		this.aggregate = AGGREGATE_DIRTY;
	}

	clearRawData() {
		this.compress();
		this.shapes = null;
		this.types = null;
	}

	getAggregate() {
		if (this.aggregate === AGGREGATE_DIRTY) {
			this.compileAggregate();
		}

		return this.aggregate;
	}

	compileAggregate() {
		this.onReadOp();
		let solids = 0;
		for (let i = 0; i < this.shapes.length; ++i) {
			if (this.shapes[i] > 0) solids++;
		}
		if (solids === 0) {
			this.aggregate = AGGREGATE_EMPTY;
		} else if (solids === this.shapes.length) {
			this.aggregate = AGGREGATE_FULL;
		} else {
			this.aggregate = AGGREGATE_MIXED;
		}
	}
}
