import { LocomotionOutputState } from "client/world/entity/locomotion/OutputState.js";
import { AnimationNode } from "client/world/entity/locomotion/AnimationNode.js";
import { BB } from "base/BB";
import { isNullish } from "@jamango/helpers";
import { LoopRepeat, LoopOnce } from "three";
import { ACTION_PURPOSE_EMOTE } from "client/world/entity/util/AnimatableMesh.js";
import { createLogger } from "@jamango/helpers";

const logger = createLogger("LocomotionStateMachine");

export const STATEMACHINE_EMOTE_BLEND = 0.2; //in seconds

export class LocomotionStateMachine {
	constructor(entity, animMesh, locomotionInput, playEmotes) {
		this.entity = entity;
		this.mesh = animMesh;
		this.inputState = locomotionInput.state;
		this.playEmotes = playEmotes;

		this.outputStates = new Map();
		this.animationNodes = [];

		this.initialStateBlendTime = 0;
		this.initialState = null;
		this.currentLocomotionState = null;

		//resume existing looping emotes
		if (playEmotes && this.inputState.isEmoteLooping) {
			try {
				animMesh
					.getAction(this.inputState.emote, ACTION_PURPOSE_EMOTE)
					.reset()
					.play().clampWhenFinished = true;
			} catch (oops) {
				logger.error(oops);
				this.inputState.emote = null;
			}
		}
	}

	setInitialState(state) {
		this.initialState = state;
		return this.add(state);
	}

	add(...states) {
		for (const state of states) {
			if (state instanceof LocomotionOutputState) {
				if (!state.name) throw Error("Locomotion output state has no name");
				if (this.outputStates.has(state.name))
					throw Error(`Duplicate locomotion output state: ${state.name}`);

				this.outputStates.set(state.name, state);
			} else if (state instanceof AnimationNode) {
				if (this.animationNodes.includes(state)) throw Error("Duplicate animation node");

				this.animationNodes.push(state);
			} else {
				throw Error("Woah bro can't add this!");
			}
		}

		return this;
	}

	//you probably want to call character.syncEmote() instead. this does not write to the locomotionInput
	setEmote(name, loop) {
		const oldEmote = this.inputState.emote;
		if (name !== null && name === oldEmote) {
			this.mesh.getAction(name, ACTION_PURPOSE_EMOTE).setLoop(loop ? LoopRepeat : LoopOnce);
			return;
		}

		if (!isNullish(oldEmote)) {
			this.mesh.getAction(oldEmote, ACTION_PURPOSE_EMOTE).fadeOut(STATEMACHINE_EMOTE_BLEND);
		}

		if (isNullish(name)) {
			this.initialStateBlendTime = STATEMACHINE_EMOTE_BLEND;
		} else {
			this.currentLocomotionState?.startFadeOut({
				blendTime: STATEMACHINE_EMOTE_BLEND,
			});
			this.currentLocomotionState = null; //when the emote ends, the state machine will restart from its initialState

			try {
				this.mesh
					.getAction(name, ACTION_PURPOSE_EMOTE)
					.reset()
					.setLoop(loop ? LoopRepeat : LoopOnce)
					.fadeIn(STATEMACHINE_EMOTE_BLEND)
					.play().clampWhenFinished = true;
			} catch (oops) {
				console.error(oops);
				return false;
			}
		}

		return true;
	}

	update() {
		if (this.inputState.emote) {
			const curEmote = this.inputState.emote;

			if (
				this.playEmotes &&
				!isNullish(curEmote) &&
				this.mesh.getAction(curEmote, ACTION_PURPOSE_EMOTE).paused
			) {
				this.setEmote(null);
				this.inputState.emote = null;
			}
		}

		if (!this.inputState.emote) {
			const transitionToState = { ptr: null };

			if (!this.currentLocomotionState) {
				this.currentLocomotionState = this.initialState;

				transitionToState.blendTime = this.initialStateBlendTime;
				this.initialStateBlendTime = 0;

				this.currentLocomotionState.startCompute(transitionToState);
			} else if (
				this.currentLocomotionState.shouldTransition(transitionToState, this.outputStates, this.state)
			) {
				transitionToState.prvState = this.currentLocomotionState;
				this.currentLocomotionState = transitionToState.ptr;
				this.currentLocomotionState.startCompute(transitionToState);
			}
		}

		for (const state of this.outputStates.values()) state.compute();

		for (let i = 0; i < this.animationNodes.length; ++i) {
			this.animationNodes[i].update();
		}

		if (BB.client.settings.locomotionLogsEnabled && this.currentLocomotionState)
			logger.debug(
				"Entity:",
				this.entity.entityID,
				"LocomotionState:",
				this.currentLocomotionState.name,
			);
	}
}
