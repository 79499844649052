import { Nameplate } from "client/world/entity/util/Nameplate.js";

export class NameplateComponent {
	constructor(entity) {
		this.name = "nameplate";

		this.def = {
			nameplate: new Nameplate(entity.world),
		};

		this.state = {
			show: true,
		};

		entity.world.scene.add(this.def.nameplate);
	}

	dispose() {
		this.def.nameplate.removeFromParent();
		this.def.nameplate.dispose();
	}
}
