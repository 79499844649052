import type { PeerMetadata } from "@jamango/engine/PeerMetadata.ts";
import { getPeerMetadata } from "base/util/PeerMetadata";
import type { World } from "base/world/World";
import type { LoopbackPeer, Peer } from "@jamango/ibs";
import { ItemPencil } from "mods/defs/ItemPencil";
import { ItemSpawner } from "mods/defs/ItemSpawner";
import { ItemWrench } from "mods/defs/ItemWrench";
import { sendPermissionsToClient } from "router/world/Permissions";

export function changePermissions(
	world: World,
	peer: Peer | LoopbackPeer,
	newPermissions: Partial<PeerMetadata["permissions"]>,
) {
	const metadata = getPeerMetadata(peer);
	metadata.permissions = { ...metadata.permissions, ...newPermissions };
	sendPermissionsToClient(peer);

	//server authoritative side effects
	const player = world.peerToPlayer.get(peer)!;

	const item = player.getEquippedItem();
	if (item) {
		if (
			(newPermissions.canUseIndividualBlocks === false && item.itemType.def.isBlock) ||
			(newPermissions.canUseWrench === false &&
				(item.def === ItemWrench.name || item.def === ItemSpawner.name)) ||
			(newPermissions.canUsePencil === false && ItemPencil.isPencil(item.def))
		)
			item.dispose();
	}

	if (newPermissions.canFly === false) {
		player.setFlying(false);
	}
}
