export const NODE_TYPE_ID = {
	entryPointTrigger: {
		block: "trigger_block",
		global: "trigger_global",
		sound: "trigger_sound",
	},
	callbackTrigger: {
		entity: "trigger_entity",
	},
	scripts: {
		script: "script_node",
		controls: "script_controls",
		entry: "script_entry",
		exit: "script_exit",
	},
	function: {
		general: "function",
		world: "function_world",
		entity: "function_entity",
		npc: "function_npc",
		character: "function_character",
		player: "function_player",
		block: "function_block",
		game: "function_game",
		sound: "function_sound",
		environment: "function_environment",
		analytics: "function_analytics",
	},
	logic: {
		boolean: "logic_boolean",
		comparison: "logic_comparison",
		controlFlow: "logic_control-flow",
	},
	math: "math",
	string: "string",
	list: "list",
	object: "object",
	customUI: "custom-ui",
	avatar: "avatar",
	util: {
		conversion: "util_conversion",
		literal: "util_literal",
		other: "util_other",
	},
	vector3: "vector3",
	euler: "euler",
	quaternion: "quaternion",
	text3d: "text3d",
	particleSystem: "particleSystem",
	blueprint: "blueprint",
} as const;

export const STYLE = {
	exec: {
		border: "border-rose-400",
		title: "bg-rose-100",
		folder: "text-rose-600",
		icon: "bg-rose-600",
		indicator: "bg-rose-300",
	},
	trigger: {
		border: "border-rose-400",
		title: "bg-rose-100",
		folder: "text-rose-600",
		icon: "bg-rose-600",
		indicator: "bg-rose-300",
	},
	script: {
		border: "border-violet-400",
		title: "bg-violet-100",
		text: "text-violet-400",
		folder: "text-violet-600",
		icon: "bg-violet-600",
		indicator: "bg-violet-400",
	},
	function: {
		border: "border-teal-400",
		title: "bg-teal-100",
		folder: "text-teal-600",
		icon: "bg-teal-600",
		indicator: "bg-teal-300",
	},
	string: {
		border: "border-yellow-400",
		title: "bg-yellow-100",
		text: "text-yellow-400",
		folder: "text-yellow-600",
		icon: "bg-yellow-600",
		indicator: "bg-yellow-300",
	},
	boolean: {
		border: "border-blue-400",
		title: "bg-blue-100",
		text: "text-blue-400",
		folder: "text-blue-600",
		icon: "bg-blue-600",
		indicator: "bg-blue-300",
	},
	number: {
		border: "border-green-400",
		title: "bg-green-100",
		text: "text-green-400",
		folder: "text-green-600",
		icon: "bg-green-600",
		indicator: "bg-green-300",
	},
	vector3: {
		border: "border-orange-400",
		title: "bg-orange-100",
		text: "text-orange-400",
		folder: "text-orange-600",
		icon: "bg-orange-600",
		indicator: "bg-orange-300",
	},
	euler: {
		border: "border-orange-400",
		title: "bg-orange-100",
		text: "text-orange-400",
		folder: "text-orange-600",
		icon: "bg-orange-600",
		indicator: "bg-orange-300",
	},
	quaternion: {
		border: "border-orange-400",
		title: "bg-orange-100",
		text: "text-orange-400",
		folder: "text-orange-600",
		icon: "bg-orange-600",
		indicator: "bg-orange-300",
	},
	entity: {
		border: "border-yellow-400",
		title: "bg-yellow-100",
		text: "text-yellow-400",
		folder: "text-yellow-600",
		icon: "bg-yellow-600",
		indicator: "bg-yellow-300",
	},
	sound: {
		border: "border-yellow-400",
		title: "bg-yellow-100",
		text: "text-yellow-400",
		folder: "text-yellow-600",
		icon: "bg-yellow-600",
		indicator: "bg-yellow-300",
	},
	any: {
		border: "border-violet-400",
		title: "bg-violet-100",
		text: "text-violet-400",
		folder: "text-violet-600",
		icon: "bg-violet-600",
		indicator: "bg-violet-300",
	},
	list: {
		border: "border-gray-400",
		title: "bg-gray-100",
		text: "text-gray-400",
		folder: "text-gray-600",
		icon: "bg-gray-600",
		indicator: "bg-gray-300",
	},
	object: {
		border: "border-pink-400",
		title: "bg-pink-100",
		text: "text-pink-400",
		folder: "text-pink-600",
		icon: "bg-pink-600",
		indicator: "bg-pink-300",
	},
	customUI: {
		border: "border-purple-400",
		title: "bg-purple-100",
		text: "text-purple-400",
		folder: "text-purple-600",
		icon: "bg-purple-600",
		indicator: "bg-purple-300",
	},
	avatar: {
		border: "border-purple-400",
		title: "bg-purple-100",
		text: "text-purple-400",
		folder: "text-purple-600",
		icon: "bg-purple-600",
		indicator: "bg-purple-300",
	},
} as const;

/**
 * - type - used for grouping node types
 * - folders - specifies what folder the node is in.
 * - priority - ordering of folders based on priority.
 * - style - class name to use for the node title.
 */
export const NODE_TYPE = {
	// Nodes that are created by the user.
	[NODE_TYPE_ID.scripts.script]: {
		type: NODE_TYPE_ID.scripts.script,
		folder: "Scripts",
		expanded: true,
		priority: 100,
		style: STYLE.script,
	},
	[NODE_TYPE_ID.scripts.controls]: {
		type: NODE_TYPE_ID.scripts.controls,
		folder: "Script Utilities",
		expanded: true,
		priority: 99,
		style: STYLE.script,
	},
	[NODE_TYPE_ID.scripts.entry]: {
		type: NODE_TYPE_ID.scripts.entry,
		folder: "Script Utilities",
		expanded: true,
		priority: 99,
		style: STYLE.script,
	},
	[NODE_TYPE_ID.scripts.exit]: {
		type: NODE_TYPE_ID.scripts.exit,
		folder: "Script Utilities",
		expanded: true,
		priority: 99,
		style: STYLE.script,
	},
	// Nodes that are event dispatchers based on the current block (ex. collision start).
	[NODE_TYPE_ID.entryPointTrigger.block]: {
		type: NODE_TYPE_ID.entryPointTrigger.block,
		folder: "Block Triggers",
		expanded: true,
		priority: 9,
		style: STYLE.trigger,
	},
	[NODE_TYPE_ID.entryPointTrigger.global]: {
		type: NODE_TYPE_ID.entryPointTrigger.global,
		folder: "Global Triggers",
		expanded: true,
		priority: 8.95,
		style: STYLE.trigger,
	},
	// Nodes that are event dispatchers based on a sound
	[NODE_TYPE_ID.entryPointTrigger.sound]: {
		type: NODE_TYPE_ID.entryPointTrigger.sound,
		folder: "Sound Triggers",
		expanded: true,
		priority: 8.9,
		style: STYLE.trigger,
	},
	// Nodes that are event dispatchers based on an entity (ex. On Entity Kill).
	[NODE_TYPE_ID.callbackTrigger.entity]: {
		type: NODE_TYPE_ID.callbackTrigger.entity,
		folder: "Entity Triggers",
		expanded: true,
		priority: 8.9,
		style: STYLE.trigger,
	},
	// Nodes that uses the BB API.
	[NODE_TYPE_ID.function.general]: {
		type: NODE_TYPE_ID.function.general,
		folder: "General Events",
		expanded: true,
		priority: 8.8,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.world]: {
		type: NODE_TYPE_ID.function.world,
		folder: "World Events",
		expanded: true,
		priority: 8.7,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.entity]: {
		type: NODE_TYPE_ID.function.entity,
		folder: "Entity Events",
		expanded: true,
		priority: 8.6,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.npc]: {
		type: NODE_TYPE_ID.function.npc,
		folder: "NPC Events",
		expanded: true,
		priority: 8.5,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.character]: {
		type: NODE_TYPE_ID.function.character,
		folder: "Character (NPC or Player) Events",
		expanded: true,
		priority: 8.4,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.player]: {
		type: NODE_TYPE_ID.function.player,
		folder: "Player Character Events",
		expanded: true,
		priority: 8.35,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.block]: {
		type: NODE_TYPE_ID.function.block,
		folder: "Block Events",
		expanded: true,
		priority: 8.3,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.sound]: {
		type: NODE_TYPE_ID.function.sound,
		folder: "Sound Events",
		expanded: true,
		priority: 8.2,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.game]: {
		type: NODE_TYPE_ID.function.game,
		folder: "Game Logic",
		expanded: false,
		priority: 8.1,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.environment]: {
		type: NODE_TYPE_ID.function.environment,
		folder: "Environment",
		expanded: false,
		priority: 8.1,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.function.analytics]: {
		type: NODE_TYPE_ID.function.analytics,
		folder: "Analytics Events",
		expanded: true,
		priority: -1,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.vector3]: {
		type: NODE_TYPE_ID.vector3,
		folder: "Vector3",
		expanded: false,
		priority: 6,
		style: STYLE.vector3,
	},
	[NODE_TYPE_ID.euler]: {
		type: NODE_TYPE_ID.euler,
		folder: "Euler",
		expanded: false,
		priority: 6,
		style: STYLE.euler,
	},
	[NODE_TYPE_ID.quaternion]: {
		type: NODE_TYPE_ID.quaternion,
		folder: "Quaternion",
		expanded: false,
		priority: 6,
		style: STYLE.quaternion,
	},
	// Node Expressions
	[NODE_TYPE_ID.math]: {
		type: NODE_TYPE_ID.math,
		folder: "Math",
		expanded: false,
		priority: 6,
		style: STYLE.number,
	},
	[NODE_TYPE_ID.text3d]: {
		type: NODE_TYPE_ID.text3d,
		folder: "Text3D",
		expanded: false,
		priority: 6,
		style: STYLE.number,
	},
	[NODE_TYPE_ID.particleSystem]: {
		type: NODE_TYPE_ID.particleSystem,
		folder: "ParticleSystem",
		expanded: false,
		priority: 6,
		style: STYLE.number,
	},
	[NODE_TYPE_ID.blueprint]: {
		type: NODE_TYPE_ID.blueprint,
		folder: "Blueprint",
		expanded: false,
		priority: 6,
		style: STYLE.number,
	},
	[NODE_TYPE_ID.logic.boolean]: {
		type: NODE_TYPE_ID.logic.boolean,
		folder: "Boolean Logic",
		expanded: false,
		priority: 5,
		style: STYLE.boolean,
	},
	[NODE_TYPE_ID.logic.comparison]: {
		type: NODE_TYPE_ID.logic.comparison,
		folder: "Comparison Logic",
		expanded: false,
		priority: 4,
		style: STYLE.boolean,
	},
	// Node statements
	[NODE_TYPE_ID.logic.controlFlow]: {
		type: NODE_TYPE_ID.logic.controlFlow,
		folder: "Control Flow Logic",
		expanded: false,
		priority: 3,
		style: STYLE.function,
	},
	[NODE_TYPE_ID.string]: {
		type: NODE_TYPE_ID.string,
		folder: "String",
		expanded: false,
		priority: 2,
		style: STYLE.string,
	},
	[NODE_TYPE_ID.list]: {
		type: NODE_TYPE_ID.list,
		folder: "List",
		expanded: false,
		priority: 2,
		style: STYLE.list,
	},
	[NODE_TYPE_ID.object]: {
		type: NODE_TYPE_ID.object,
		folder: "Object",
		expanded: false,
		priority: 2,
		style: STYLE.object,
	},
	[NODE_TYPE_ID.customUI]: {
		type: NODE_TYPE_ID.customUI,
		folder: "Custom UI",
		expanded: false,
		priority: 2,
		style: STYLE.customUI,
	},
	[NODE_TYPE_ID.avatar]: {
		type: NODE_TYPE_ID.avatar,
		folder: "Avatar",
		expanded: false,
		priority: 2,
		style: STYLE.avatar,
	},

	// Others
	[NODE_TYPE_ID.util.conversion]: {
		type: NODE_TYPE_ID.util.conversion,
		folder: "Utils",
		priority: 0,
		style: STYLE.any,
	},
	[NODE_TYPE_ID.util.literal]: {
		type: NODE_TYPE_ID.util.literal,
		folder: "Literal",
		priority: 0,
		style: STYLE.any,
	},
} as const;
