import { netState } from "router/Parallelogram";

export function despawnUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	// respawn timer (for player characters only)
	if (entity.type.def.isPlayer && entity.respawnTimer > 0) {
		entity.respawnTimer -= stepDeltaTime;
		if (entity.respawnTimer <= 0) entity.respawn(false);
	}

	if (entity.despawnTimer > 0) {
		entity.despawnTimer -= stepDeltaTime;
		if (entity.despawnTimer <= 0) entity.deferredDispose();
	}
}

function canUpdateSystem(entity) {
	return netState.isHost && !entity.despawnTimerPause && !entity.mount.state.parent;
}
