import { freeRAMAfterMeshGeometryUpload } from "base/util/ThreeJS";
import { ChunkBuilder } from "base/world/block/Builder.js";
import { ChunkUtil } from "base/world/block/Util.js";
import { BLOCK_TRANSPARENCY_OPAQUE } from "base/world/block/Util.js";
import { Mesh, Vector3 } from "three";

export class OverlayBuilder {
	/**
	 * @param {{
	 *   material: THREE.Material,
	 *   world: import("base/world/World.js").World,
	 *   blocks: import("base/util/math/LegacyVectorMap.js").LegacyVectorMap,
	 *   box: THREE.Box3,
	 * }} options
	 * @returns {THREE.Mesh}
	 */
	static createMesh({ world, blocks, box, material }) {
		// use first opaque block
		let anyBlockType;
		for (const [, blockType] of world.blockTypeRegistry.blockNameToType.entries()) {
			if (blockType.transparency === BLOCK_TRANSPARENCY_OPAQUE) {
				anyBlockType = blockType.id;
				break;
			}
		}

		// fallback to any block type
		if (!anyBlockType) {
			anyBlockType = world.blockTypeRegistry.blockNameToType.keys().next().value;
		}

		const size = box.getSize(new Vector3()).addScalar(1);
		const volume = size.x * size.y * size.z;

		const shapes = new Uint8Array(volume);
		const types = new Uint16Array(volume);

		const currentBlockPosition = new Vector3();

		for (const block of blocks.iterate(currentBlockPosition)) {
			/**
			 * @type {{ shape: number, type: string }}
			 */
			const { shape } = block;

			// convert to local coordinates
			currentBlockPosition.sub(box.min);
			const index = ChunkUtil.getIndex(
				size.x,
				size.y,
				size.z,
				currentBlockPosition.x,
				currentBlockPosition.y,
				currentBlockPosition.z,
			);

			shapes[index] = shape;
			types[index] = anyBlockType;
		}

		const { opaqueBuffer } = ChunkBuilder.build(
			shapes,
			types,
			size.x,
			size.y,
			size.z,
			world.blockTypeRegistry,
			true,
		);

		const opaqueGeometry = ChunkBuilder.buildGeometry(opaqueBuffer);
		opaqueGeometry.computeBoundingSphere();

		const mesh = new Mesh(opaqueGeometry, material);
		freeRAMAfterMeshGeometryUpload(mesh);

		return mesh;
	}
}
