import * as Physics from "base/world/Physics";
import type { Entity } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";

/**
 * Copies the entity linear velocity physics to the physics body before the physics step,
 * as may have been modified by nodes or other systems.
 *
 * Also moves the sensor body to track the item entity.
 *
 * NOTE: if setting body velocity every frame is found to be expensive, we can add dirty flagging.
 */

export function itemPhysicsBeforeStepUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const item = entity as Item;

	const physicsNeedsUpdate = item.physicsNeedsUpdate();

	if (physicsNeedsUpdate) {
		item.removePhysics();
		item.addPhysics();
	}

	if (!entity.mount.state.parent) {
		const body = item.itemPhysics.state.body;

		if (body) {
			Physics.setBodyLinearVelocity(entity.world.physics, body, item.itemPhysics.state.linearVelocity);
		}

		const sensor = item.itemPhysics.state.sensor;

		if (sensor) {
			Physics.setBodyPositionAndRotation(entity.world.physics, sensor, item.position, item.quaternion);
		}
	}
}

function canUpdateSystem(entity: Entity) {
	return entity.type.def.isItem;
}
