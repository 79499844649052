import { Vector3 } from "three";
import type { Character } from "base/world/entity/Character";
import type { Item } from "base/world/entity/Item";
import type { Prop } from "base/world/entity/Prop";

export enum CharacterViewRaycastMode {
	DEFAULT,
	LASER,
	AIR_BLOCKS,
}

export const CHARACTER_VIEW_RAYCAST_MODE_CYCLE = [
	CharacterViewRaycastMode.DEFAULT,
	CharacterViewRaycastMode.AIR_BLOCKS,
	CharacterViewRaycastMode.LASER,
];

export const DEFAULT_VIEW_RAYCAST_DISTANCE = 6;

export class CharacterViewRaycastComponent {
	name: string;

	state: {
		distance: 6;
		mode: CharacterViewRaycastMode;
		rayDir: Vector3;
		rayPos: Vector3;
		hitPos: Vector3;
		hitDistance: number;
		hitNormal: Vector3;
		block: Vector3 | null;
		blockHitPos: Vector3 | null;
		blockHitSide: number;
		blockHitShape: number;
		blockHitType: string;
		blockHitTypeIndex: number;
		character: Character | null;
		item: Item | null;
		prop: Prop | null;
	};

	constructor() {
		this.name = "viewRaycast";

		this.state = {
			distance: DEFAULT_VIEW_RAYCAST_DISTANCE,
			mode: CharacterViewRaycastMode.DEFAULT,
			rayDir: new Vector3(),
			rayPos: new Vector3(),
			hitPos: new Vector3(),
			hitDistance: 0,
			hitNormal: new Vector3(),
			block: null,
			blockHitPos: null,
			blockHitSide: 0,
			blockHitShape: 0,
			blockHitType: "",
			blockHitTypeIndex: 0,
			character: null,
			item: null,
			prop: null,
		};
	}
}
