import { z } from "zod";
import { AssetType } from "../types";
import { assetKeySchema } from "./general";
import { PackageIdSchema } from "./package";

export const BlockStructureSchema = z.object({
	pk: assetKeySchema(AssetType.BLOCK_STRUCTURE),
	type: z.literal(AssetType.BLOCK_STRUCTURE),
	name: z.string(),
	data: z.object({
		// [x,y,z,shape,blockTypeIndex]
		blocks: z.array(z.tuple([z.number(), z.number(), z.number(), z.number(), z.number()])),
		blockTypes: z.array(z.string()),
		// [x,y,z,groups]
		blockGroups: z.array(z.tuple([z.number(), z.number(), z.number(), z.array(z.string())])).optional(),
		// { groupId: { name, scripts } }
		blockGroupDefs: z.record(z.object({
			name: z.string().optional(),
			scripts: z.array(z.object({
				script: z.string(),
				data: z.record(z.unknown()).optional(),
			})).optional(),
		})).optional(),
	}),
	packageId: PackageIdSchema.optional(),
});

export const all = (value: unknown) => BlockStructureSchema.parse(value);
