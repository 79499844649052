import * as Physics from "base/world/Physics";
import type { Entity } from "base/world/entity/Entity";
import type { Item } from "base/world/entity/Item";

/**
 * Copies the resulting physics transforms and velocities to entity states,
 * which may be modified by nodes or other systems before the next physics step.
 */

export function itemPhysicsAfterStepUpdate(entity: Entity) {
	if (!canUpdateSystem(entity)) return;

	const item = entity as Item;
	const body = item.itemPhysics.state.body;

	if (body && item.itemPhysics.state.motionType !== Physics.MotionType.STATIC) {
		Physics.getBodyPositionAndRotation(body, item.position, item.quaternion);
		Physics.getBodyLinearVelocity(body, item.itemPhysics.state.linearVelocity);
	} else {
		item.itemPhysics.state.linearVelocity.set(0, 0, 0);
	}
}

function canUpdateSystem(entity: Entity) {
	return entity.type.def.isItem && !entity.mount.state.parent;
}
