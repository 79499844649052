export const IMAGE_TYPES = ["jpeg", "jpg", "png", "gif", "webp", "avif"] as const;
export const AUDIO_TYPES = ["mp3", "ogg", "wav"] as const;

export const UNKNOWN_FILE_EXTENSION = "bin";

const ONE_MB_IN_BYTES = 1024 * 1024;

export const MAX_ALLOWABLE_CHANGESET_SIZE = 1 * ONE_MB_IN_BYTES; // 1 MB in bytes - note: allow allowance for the map size data.

const GENERA_FILE_SIZE = 10 * ONE_MB_IN_BYTES; // 10 MB
export const MAX_BANNER_FILE_SIZE = GENERA_FILE_SIZE;
export const MAX_THUMBNAIL_FILE_SIZE = GENERA_FILE_SIZE;
export const MAX_IMAGE_FILE_SIZE = GENERA_FILE_SIZE;
export const MAX_SKYBOX_FILE_SIZE = GENERA_FILE_SIZE;
export const MAX_BACKGROUND_MUSIC_FILE_SIZE = 15 * ONE_MB_IN_BYTES;
export const MAX_BLOCK_TEXTURE_FILE_SIZE = 32 * ONE_MB_IN_BYTES;
export const MAX_TEXTURE_FILE_SIZE = 32 * ONE_MB_IN_BYTES;
export const MAX_AVATAR_THUMBNAIL_FILE_SIZE = GENERA_FILE_SIZE;
export const MAX_AUDIO_FILE_SIZE = GENERA_FILE_SIZE;

export const MAX_BLOCK_TEXTURE_RESOLUTION = 1024;
export const MAX_THUMBNAIL_RESOLUTION = 1024;
export const MAX_BANNER_RESOLUTION = 2048;

const GENERAL_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif"] as const;
export const ACCEPTED_SKYBOX_TYPES = GENERAL_IMAGE_TYPES;
export const ACCEPTED_BLOCK_TEXTURE_TYPES = GENERAL_IMAGE_TYPES;
export const ACCEPTED_TEXTURE_TYPES = GENERAL_IMAGE_TYPES;
export const ACCEPTED_AVATAR_THUMBNAIL_TYPES = GENERAL_IMAGE_TYPES;
export const ACCEPTED_IMAGE_TYPES = [
	...GENERAL_IMAGE_TYPES,
	"image/gif",
	"image/webp",
	"image/avif",
] as const;

export const ACCEPTED_MUSIC_TYPES = ["audio/mp3", "audio/mpeg", "audio/ogg", "audio/wav"] as const;
export const ACCEPTED_AUDIO_TYPES = [...ACCEPTED_MUSIC_TYPES] as const;

export const SKYBOX_FILE_PREFIX = "tex-sky-";
export const BLOCK_TEXTURE_FILE_PREFIX = "tex-blocks-";
export const BACKGROUND_MUSIC_FILE_PREFIX = "snd-world-bg-";
export const AUDIO_FILE_PREFIX = "snd-";
export const THUMBNAIL_FILE_PREFIX = "img-thumbnail-";
export const CUSTOM_LOADER_FILE_PREFIX = "img-custom-loader-";

export const MATERIAL_SIDES = ["nx", "px", "ny", "py", "nz", "pz"] as const;
export const ALL_MATERIAL_SIDES = ["default", ...MATERIAL_SIDES];

export const JAMANGO_DEHYDRATED_URL_PREFIX = `jfiles://`;
