import type { Brand, nullish } from "@jamango/helpers";
import type { ObjectiveType } from "@jamango/frontend/Quest.ts";
import type { EntityID } from "@jamango/engine/EntityID.ts";
import { Vector3 } from "three";

export type State = {
	objectives: Map<ObjectiveName, ObjectiveState>;
};

export type ObjectiveName = Brand<string, "Objective Name ID">;

export type ObjectiveDef = {
	name: ObjectiveName;
	uiType: ObjectiveType;
	message?: string;
};

export type ObjectiveState = ObjectiveDef & {
	hasWaypoint: boolean;
};

export type ObjectiveStateWithWaypoint = ObjectiveState & {
	hasWaypoint: true;

	entityID: EntityID | nullish;
	pos: Vector3;
};

export function init(): State {
	return { objectives: new Map() };
}

export function localCreateObjective(state: State, o: ObjectiveDef) {
	state.objectives.set(o.name, {
		...structuredClone(o),
		hasWaypoint: false,
	});
}

export function localCreateWaypoint(obj: ObjectiveState, entityID: EntityID | null, pos: Vector3) {
	const objWithWaypoint = obj as ObjectiveStateWithWaypoint;
	objWithWaypoint.entityID = entityID;
	objWithWaypoint.pos = new Vector3().copy(pos);

	objWithWaypoint.hasWaypoint = true;
}

export function localCompleteObjective(state: State, obj: ObjectiveState) {
	state.objectives.delete(obj.name);
}

export function hasWaypoint(obj: ObjectiveState): obj is ObjectiveStateWithWaypoint {
	return obj.hasWaypoint;
}
