import type {
	IBodyPartConfig,
	IAvatarConfig,
	IAvatarMonitoringConfig,
	IAvatarObjectBodyPartConfig,
	IAvatarObject,
	IAvatarComponent,
	IAvatar,
	IEngineAsset,
	BodyTypeValue,
} from "../types";
import { AvatarHeadTabs } from "../types";
import {
	AVATAR_CONFIG_RESOURCE_TYPES,
	AVATAR_CONFIG_KEYS_WITHOUT_RESOURCE,
	DEFAULT_BODY_PARTS,
} from "../constants/avatars";
import { isNullish } from "@jamango/helpers";

export function getAvatarComponentConfig(component: IAvatarComponent) {
	if (component.category === AvatarHeadTabs.FACE) {
		return {
			isUniversal: component.isUniversal,
			body_type_1: component.thumbnail.bodyType1,
			body_type_2: component.thumbnail.bodyType2,
		} satisfies IBodyPartConfig;
	}

	return {
		isUniversal: component.isUniversal,
		body_type_1: component.glbFile.bodyType1,
		body_type_2: component.glbFile.bodyType2,
	} satisfies IBodyPartConfig;
}

export function getBodyPart(
	config: IAvatarMonitoringConfig,
	bodyPart: keyof IAvatarMonitoringConfig,
	components: IAvatarComponent[],
) {
	const component = components.find((component) => component.pk === config[bodyPart]);

	if (!component) return null;

	return component;
}

function getBodyPartConfig(
	config: IAvatarMonitoringConfig,
	bodyPart: keyof IAvatarMonitoringConfig,
	components: IAvatarComponent[],
): any {
	if (config[bodyPart] === "EMPTY") {
		return "EMPTY";
	}

	const component = getBodyPart(config, bodyPart, components);

	if (!component) {
		return DEFAULT_BODY_PARTS[bodyPart as keyof typeof DEFAULT_BODY_PARTS];
	}

	return getAvatarComponentConfig(component);
}

export function getTorsoConfig(config: IAvatarMonitoringConfig, components: IAvatarComponent[]) {
	let torsoConfig, armsConfig;
	const torso = getBodyPart(config, "torso", components);

	if (torso) {
		const options = typeof torso.options === "string" ? JSON.parse(torso.options) : torso.options;

		const armsConnection = options.attached?.arms;

		const arms = components.find((item) => item.id === armsConnection);

		torsoConfig = getBodyPartConfig(config, "torso", components);

		if (arms) {
			armsConfig = getBodyPartConfig({ arms: arms.pk } as IAvatarMonitoringConfig, "arms", components);
		}
	}

	return [torsoConfig, armsConfig];
}

export function getAvatarConfig(
	avatar: Pick<IAvatar, "config">,
	components: IAvatarComponent[],
): IAvatarConfig {
	const hairComponents = components.filter((avatarComponent) => avatarComponent.category === "hair");

	const [torsoConfig, armsConfig] = getTorsoConfig(avatar.config, components);

	return {
		bodyType: avatar.config.bodyType,
		hairColor: avatar.config.hairColor,
		skinColor: avatar.config.skinColor,
		hat: getBodyPartConfig(avatar.config, "hat", components),
		backpack: getBodyPartConfig(avatar.config, "backpack", components),
		face: getBodyPartConfig(avatar.config, "face", components),
		hair: getBodyPartConfig(avatar.config, "hair", hairComponents),
		legs: getBodyPartConfig(avatar.config, "legs", components),
		mask: getBodyPartConfig(avatar.config, "mask", components),
		arms: armsConfig,
		torso: torsoConfig,
	} satisfies IAvatarConfig;
}

export function getAvatarConfigAssets(config: IAvatarConfig) {
	const bodyType = config.bodyType;
	const assets = new Map<string, IEngineAsset>();

	for (const key in config) {
		if (AVATAR_CONFIG_KEYS_WITHOUT_RESOURCE.includes(key as any)) continue;

		const value = config[key as keyof IAvatarConfig] as
			| string
			| (IBodyPartConfig & { isUniversal?: boolean });
		if (typeof value === "string" || isNullish(value)) continue;

		const bodyTypeKey = value.isUniversal ? "body_type_1" : bodyType;
		const bodyTypeValue = value[bodyTypeKey];
		if (!bodyTypeValue) continue;

		assets.set(bodyTypeValue, {
			id: bodyTypeValue,
			url: bodyTypeValue,
			type: AVATAR_CONFIG_RESOURCE_TYPES[key as keyof typeof AVATAR_CONFIG_RESOURCE_TYPES],
		});
	}

	return Array.from(assets.values());
}

function getAvatarObjectBodyPartConfig(
	config: IAvatarMonitoringConfig,
	bodyPart: keyof IAvatarMonitoringConfig,
	components: IAvatarComponent[],
) {
	if (config[bodyPart] === "EMPTY") {
		return "EMPTY";
	}

	const component = getBodyPart(config, bodyPart, components);

	if (!component) return null;

	return {
		id: component.id,
		name: component.name,
	} satisfies IAvatarObjectBodyPartConfig;
}

export function getAvatarObjectNodeConfig(avatar: IAvatar, components: IAvatarComponent[]) {
	return {
		bodyType: avatar.config.bodyType,
		hairColor: avatar.config.hairColor,
		skinColor: avatar.config.skinColor,
		hat: getAvatarObjectBodyPartConfig(avatar.config, "hat", components),
		arms: getAvatarObjectBodyPartConfig(avatar.config, "arms", components),
		backpack: getAvatarObjectBodyPartConfig(avatar.config, "backpack", components),
		face: getAvatarObjectBodyPartConfig(avatar.config, "face", components),
		hair: getAvatarObjectBodyPartConfig(avatar.config, "hair", components),
		legs: getAvatarObjectBodyPartConfig(avatar.config, "legs", components),
		mask: getAvatarObjectBodyPartConfig(avatar.config, "mask", components),
		torso: getAvatarObjectBodyPartConfig(avatar.config, "torso", components),
	} satisfies IAvatarObject;
}

export function convertAvatarObjectBodyPartToPK(
	avatarBodyPart: IAvatarObjectBodyPartConfig,
	components: IAvatarComponent[],
) {
	if (!avatarBodyPart) return null;

	if (typeof avatarBodyPart === "string" && avatarBodyPart === "EMPTY") return avatarBodyPart;

	const targetComponent = components.find(
		(component) => typeof avatarBodyPart === "object" && component.id === avatarBodyPart.id,
	);
	if (!targetComponent) return null;

	return targetComponent?.pk;
}

export function convertAvatarObjectToConfig(avatarObject: IAvatarObject, components: IAvatarComponent[]) {
	return {
		bodyType: avatarObject.bodyType,
		hairColor: avatarObject.hairColor,
		skinColor: avatarObject.skinColor,
		hat: convertAvatarObjectBodyPartToPK(avatarObject.hat, components),
		arms: convertAvatarObjectBodyPartToPK(avatarObject.arms, components),
		backpack: convertAvatarObjectBodyPartToPK(avatarObject.backpack, components),
		face: convertAvatarObjectBodyPartToPK(avatarObject.face, components),
		hair: convertAvatarObjectBodyPartToPK(avatarObject.hair, components),
		legs: convertAvatarObjectBodyPartToPK(avatarObject.legs, components),
		mask: convertAvatarObjectBodyPartToPK(avatarObject.mask, components),
		torso: convertAvatarObjectBodyPartToPK(avatarObject.torso, components),
	} satisfies IAvatarMonitoringConfig;
}

export function getAvatarBodyPart(bodyType: BodyTypeValue, config?: IBodyPartConfig | "EMPTY" | null) {
	if (isNullish(config) || config === "EMPTY") {
		return null;
	}

	if (config.isUniversal) {
		return config.body_type_1;
	}

	return config[bodyType];
}
