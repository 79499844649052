import type { BlockType } from "base/world/block/Type";
import { BLOCK_TRANSPARENCY_OPAQUE } from "base/world/block/Util.js";
import type { World } from "base/world/World";
import type { WebGLRenderer } from "three";
import {
	CanvasTexture,
	Color,
	NearestFilter,
	NearestMipmapLinearFilter,
	RepeatWrapping,
	RGBAFormat,
	SRGBColorSpace,
	UnsignedByteType,
	UVMapping,
} from "three";
import * as Resources from "client/Resources";

const N_MIPMAPS = 4;
const MIN_TEXTURE_SIZE = Math.pow(2, N_MIPMAPS);
const TEXTURE_SIZE = 128;

const tmpCol = new Color();

type ColorData = number;
type TextureData = { asset: string };

type LayoutData = {
	uv: { u1: number; v1: number; u2: number; v2: number };
	canvas: { x: number; y: number; width: number; height: number };
};

type Face = {
	color?: ColorData;
	texture?: TextureData;
	layout?: LayoutData;
};

type EmptyTile = {
	empty: true;
	faces: Face[];
};

type Tile = {
	isTex: boolean;
	data: ColorData | TextureData;
	layout: undefined | LayoutData;
	dim: number;
	actualDim: number;
	faces: Face[];
	transparency: boolean;
};

export const createPaletteLayout = (blocks: BlockType[], _world: World, renderer: WebGLRenderer) => {
	/* gather tiles that are required for the texture atlas */

	// keep track of block sides that have no texture or color, these will assigned the empty tile
	const emptyTile: EmptyTile = { faces: [], empty: true };

	const tiles: Tile[] = [];

	function addTile(
		data: ColorData | TextureData,
		face: Face,
		isTex: boolean,
		dim: number,
		transparency: boolean,
	) {
		// search for an existing tile that matches
		for (const tile of tiles) {
			if (isTex && tile.isTex) {
				const a = tile.data as TextureData;
				const b = data as TextureData;

				if (a.asset === b.asset && tile.transparency === transparency) {
					tile.faces.push(face);
					return;
				}
			}
		}

		// add new tile
		tiles.push({ data, faces: [face], isTex, dim, actualDim: dim, transparency, layout: undefined });
	}

	for (const block of blocks) {
		// for now, faces will be a copy of faceDefs, but later on it will be changed to a structure for holding uv coords
		block.faces = [];

		if (!block.faceDefs?.length) {
			block.faceDefs = [{}];
		}

		for (const faceDef of block.faceDefs) {
			const face: Face = {};

			block.faces.push(face);

			const transparency = block?.transparency !== BLOCK_TRANSPARENCY_OPAQUE;

			if (faceDef.color) {
				const color = tmpCol.set(faceDef.color).convertLinearToSRGB().getHex();
				face.color = color;

				addTile(face.color, face, false, MIN_TEXTURE_SIZE, false);
			} else if (faceDef.texture) {
				face.texture = { ...faceDef.texture };
				addTile(face.texture as TextureData, face, true, TEXTURE_SIZE, transparency);
			} else {
				emptyTile.faces.push(face);
			}
		}
	}

	// sort the tiles from largest to smallest
	tiles.sort((a, b) => b.dim - a.dim);

	const gl = renderer.getContext();
	const maxRes = gl.getParameter(gl.MAX_TEXTURE_SIZE);

	// graphics driver bug?
	if (!maxRes) {
		throw RangeError(`WebGL reported MAX_TEXTURE_SIZE of "${maxRes}"`);
	}

	// unlikely to ever hit this. limit should be in the tens or hundreds of millions
	const maxTiles = maxRes ** 2;
	if (tiles.length > maxTiles) {
		throw RangeError(
			`You FOOL! Too many dangus block textures+colors (${tiles.length} requested/${maxTiles} limit)`,
		);
	}

	/* create the layout for the texture atlas */

	// determine the texture atlas width and height. resize textures if atlas is too large for the gpu
	let width = 1;
	let height = 1;

	// TODO
	while (true) {
		let totalPixels = 0;

		for (const tile of tiles) {
			totalPixels += tile.dim ** 2;
		}

		let alternator = true;

		while (width * height < totalPixels) {
			if (alternator) {
				width *= 2;
			} else {
				height *= 2;
			}

			alternator = !alternator;
		}

		if (width <= maxRes && height <= maxRes) break;

		// need to resize next largest texture
		tiles[tiles.findIndex((e) => e.dim < tiles[0].dim) - 1].dim /= 2;
	}

	if (width > maxRes || height > maxRes) {
		throw RangeError(
			`Block texture atlas is too large (requested ${width}x${height}, max ${maxRes}x${maxRes})`,
		);
	}

	// calculate texture atlas layout

	const tilesWithLayouts: Array<Tile | EmptyTile> = [...tiles];

	let stack: {
		x: number;
		y: number;
		dim: number;
		parent?: typeof stack;
	} = { x: 0, y: 0, dim: (tilesWithLayouts[0] as Tile)?.dim };

	tilesWithLayouts.push(emptyTile);

	for (const tile of tilesWithLayouts) {
		if ("empty" in tile) {
			const missingTextureLayout = {
				uv: { u1: -1, v1: -1, u2: -1, v2: -1 },
				canvas: { x: -1, y: -1, width: -1, height: -1 },
			};

			for (const face of tile.faces) {
				face.layout = missingTextureLayout;
			}

			continue;
		}

		let x = 0;
		let y = 0;
		const dim = tile.dim;
		let transformStack = stack;

		do {
			x += transformStack.x;
			y += transformStack.y;
			transformStack = transformStack.parent!;
		} while (transformStack);

		const uv = {
			u1: x / width, //L
			v1: (height - y - dim) / height, //B
			u2: (x + dim) / width, //R
			v2: (height - y) / height, //T
		};

		const canvas = { x, y, width: dim, height: dim };

		const layout: LayoutData = {
			uv,
			canvas,
		};

		tile.layout = layout;

		if (tile.faces) {
			for (const face of tile.faces) {
				face.layout = layout;
			}
		}

		while (stack.dim > dim) stack = { x: 0, y: 0, dim: stack.dim / 2, parent: stack }; // stack push

		calculateNextCoords();
	}

	function calculateNextCoords() {
		stack.x += stack.dim;
		if (stack.x === stack.parent?.dim || (!stack.parent && stack.x === width)) {
			stack.x = 0;
			stack.y += stack.dim;
		}

		if (stack.parent && stack.y === stack.dim * 2) {
			stack = stack.parent; // stack pop
			calculateNextCoords();
		}
	}

	// allocate canvas,
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;

	// allocate mipmaps
	const mipmaps: HTMLCanvasElement[] = [];
	let w = canvas.width;
	let h = canvas.height;
	for (let i = 0; i < N_MIPMAPS; i++) {
		if (w <= 1 && h <= 1) break;
		// half the size
		w = Math.max(1, Math.floor(w / 2));
		h = Math.max(1, Math.floor(h / 2));
		// make mipmap canvas
		const canvas = document.createElement("canvas");
		canvas.width = w;
		canvas.height = h;

		mipmaps.push(canvas);
	}

	// allocate texture
	const texture = createPaletteCanvasTexture(canvas, mipmaps);

	const palette = {
		tiles: tilesWithLayouts,
		width,
		height,
		canvas,
		mipmaps,
		drawnLoaded: new Set<Tile>(),
		drawnPlaceholder: new Set<Tile>(),
		texture,
	};
	return palette;
};

export type PaletteLayout = ReturnType<typeof createPaletteLayout>;

export const updatePaletteCanvas = (paletteLayout: PaletteLayout) => {
	const { canvas, tiles, drawnLoaded, drawnPlaceholder, mipmaps } = paletteLayout;
	const ctx = canvas.getContext("2d")!;
	ctx.imageSmoothingEnabled = false;

	// add to the texture atlas
	for (const tile of tiles) {
		if ("empty" in tile) continue;
		if (drawnLoaded.has(tile)) continue;
		const { x, y, width, height } = tile.layout!.canvas;

		if (tile.isTex) {
			// if is texture, draw the texture
			const { asset } = tile.data as TextureData;
			const loaded = Resources.idToResource.has(asset);
			if (loaded) {
				drawnLoaded.add(tile);
				const image = Resources.idToResource.get(asset).image;
				if (drawnPlaceholder.has(tile)) ctx.clearRect(x, y, width, height);
				ctx.drawImage(image, 0, 0, image.width, image.height, x, y, width, height);
			} else {
				if (!drawnPlaceholder.has(tile)) {
					// placeholder color
					ctx.fillStyle = `#ffffff`;
					ctx.fillRect(x, y, width, height);
					drawnPlaceholder.add(tile);
				}
			}
		} else {
			// if is solid, draw pixel
			tmpCol.set(tile.data as ColorData);
			ctx.fillStyle = `rgb(${~~(tmpCol.r * 255)}, ${~~(tmpCol.g * 255)}, ${~~(tmpCol.b * 255)})`;
			ctx.fillRect(x, y, width, height);
			drawnLoaded.add(tile);
		}
	}

	// generate mipmaps
	let last = canvas;
	for (let i = 0; i < mipmaps.length; i++) {
		const next = mipmaps[i];
		const ctx = next.getContext("2d")!;
		// copy previous canvas into mipmap
		ctx.clearRect(0, 0, next.width, next.height);
		ctx.drawImage(last, 0, 0, next.width, next.height);
		last = next;
	}

	paletteLayout.texture.needsUpdate = true;
};

export const createPaletteCanvasTexture = (canvas: HTMLCanvasElement, mipmaps: HTMLCanvasElement[]) => {
	const texture = new CanvasTexture(canvas);

	texture.colorSpace = SRGBColorSpace;
	texture.mapping = UVMapping;
	texture.wrapS = RepeatWrapping;
	texture.wrapT = RepeatWrapping;
	texture.magFilter = NearestFilter;
	// todo: NearestMipMapNearestFilter, NearestMipMapNearestFilter, other?
	texture.minFilter = NearestMipmapLinearFilter;
	texture.format = RGBAFormat;
	texture.type = UnsignedByteType;

	texture.generateMipmaps = false;
	texture.mipmaps = mipmaps;

	return texture;
};
