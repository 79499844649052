import { LoopRepeat } from "three";
import { AnimationTrack } from "client/world/entity/locomotion/AnimationTrack.js";
import { AnimationNode } from "client/world/entity/locomotion/AnimationNode.js";

export class TPAimOffsetNode extends AnimationNode {
	constructor(animMesh, locomotionInput) {
		super(animMesh, locomotionInput);

		this.wrenchAimOffset = new AnimationTrack(animMesh, "JMGO_Wrench_AO_CC_pose");
		this.pencilAimOffset = new AnimationTrack(animMesh, "JMGO_Pencil_AO_CC_pose");
		this.novaGunAimOffset = new AnimationTrack(animMesh, "JMGO_NovaGun_AO_CC_pose");
		this.spadeAimOffset = new AnimationTrack(animMesh, "JMGO_Spade_AO_CC_pose");
		this.zapperPistolAimOffset = new AnimationTrack(animMesh, "JMGO_ZapperPistol_AO_CC_pose");
		this.novaRifleAimOffset = new AnimationTrack(animMesh, "JMGO_NovaRifle_AO_CC_pose");
		this.novaAssaultAimOffset = new AnimationTrack(animMesh, "JMGO_NovaAssault_AO_CC_pose");
		this.zapperAssaultAimOffset = new AnimationTrack(animMesh, "JMGO_ZapperAssault_AO_CC_pose");
		this.zapperRifleAimOffset = new AnimationTrack(animMesh, "JMGO_ZapperRifle_AO_CC_pose");

		this.idleTrackMap = new Map();
		this.idleTrackMap.set("ItemPencil", this.pencilAimOffset);
		this.idleTrackMap.set("ItemNovaGun", this.novaGunAimOffset);
		this.idleTrackMap.set("ItemSpade", this.spadeAimOffset);
		this.idleTrackMap.set("ItemWrench", this.wrenchAimOffset);
		this.idleTrackMap.set("ItemZapperPistol", this.zapperPistolAimOffset);
		this.idleTrackMap.set("ItemNovaRifle", this.novaRifleAimOffset);
		this.idleTrackMap.set("ItemNovAssaultRifle", this.novaAssaultAimOffset);
		this.idleTrackMap.set("ItemZapperRifle", this.zapperRifleAimOffset);
		this.idleTrackMap.set("ItemZapperAssault", this.zapperAssaultAimOffset);

		this.lastTrack = null;
	}

	stopCompute() {
		if (this.lastTrack) {
			this.lastTrack.startFadeOut(0.2);
		}
	}

	shouldUpdate() {
		const shouldUpdate =
			this.idleTrackMap.get(this.inputState.item) !== undefined && !this.inputState.isSprinting;

		if (!shouldUpdate) {
			if (this.lastTrack) {
				if (this.lastTrack.state !== "off") {
					if (this.lastTrack.state === "on") {
						this.stopCompute();
					}

					return true;
				}
			}
		}

		return shouldUpdate;
	}

	updateImplementation() {
		const currentTrack = this.idleTrackMap.get(this.inputState.item);
		if (currentTrack !== this.lastTrack && currentTrack) {
			if (this.lastTrack) {
				this.lastTrack.startFadeOut(0.2);
				this.lastTrack.action.setEffectiveWeight(0);
			}

			this.lastTrack = currentTrack;
			this.lastTrack.action.reset();

			this.lastTrack.action.loop = LoopRepeat;
			this.lastTrack.startFadeIn(0.2);
			this.lastTrack.action.play();
		}

		this.wrenchAimOffset.update();
		this.pencilAimOffset.update();
		this.novaGunAimOffset.update();
		this.spadeAimOffset.update();
		this.zapperPistolAimOffset.update();
		this.novaRifleAimOffset.update();
		this.novaAssaultAimOffset.update();
		this.zapperAssaultAimOffset.update();
		this.zapperRifleAimOffset.update();
	}
}
