import type { LoopbackPeer, Peer } from "@jamango/ibs";
import type { Vector3 } from "three";

export type WorldEditorSnapshot = {
	map: ArrayBufferLike;
	playerPositions:
		| undefined
		| { peer: Peer | LoopbackPeer; position: Vector3; sph: { phi: number; theta: number } }[];
};

export enum WorldEditorStatus {
	EDITING,

	TESTING_PAUSED,
	TESTING_ACTIVE,

	/**
	 * Used when starting the game from the inventory "play" tab.
	 * Distinct from PLAYING as saving is disabled in this mode, and the game can be stopped, restoring from a snapshot.
	 * Distinct from TESTING_ACTIVE as edit mode controls are not available - it is more closely emulating play mode.
	 */
	PREVIEWING,

	/**
	 * Used when the world is in "play" mode, and when the dedicated server starts a world (as it is the default mode).
	 */
	PLAYING,
}

export enum WorldEditorAction {
	ENTER_EDIT_MODE,
	ENTER_PLAY_MODE,
	START_GAME,
	START_TESTING_FROM_POSITION,
	START_TESTING_FROM_SPAWN,
	STOP,
	RESTART,
	PAUSE,
	RESUME,
}

export const init = () => {
	return {
		status: WorldEditorStatus.PLAYING as WorldEditorStatus,
		paused: false,
		snapshot: null as null | WorldEditorSnapshot,
		restartTestingSnapshot: null as null | WorldEditorSnapshot,
	};
};

export type WorldEditorState = ReturnType<typeof init>;

export const updateStatus = (state: WorldEditorState, status: WorldEditorStatus) => {
	state.status = status;

	// derived flags (the rest of the engine doesn't need to know about specific statuses)
	state.paused = status === WorldEditorStatus.EDITING || status === WorldEditorStatus.TESTING_PAUSED;
};
