import { getStaticFile } from "@jamango/content-client";
import { createLogger, isIOS, prioritizedAsyncPool, Topic } from "@jamango/helpers";
import { BB } from "base/BB";
import { LoadingScreen } from "base/dom/LoadingScreen.js";
import { freeRAMAfterMeshGeometryUpload } from "base/util/ThreeJS";
import { deferredCallback } from "base/util/Time.js";
import { ShadowMesh } from "client/util/ShadowMesh.js";
import type { InstancedMesh, Material, Mesh, SkinnedMesh } from "three";
import { AudioContext, AudioLoader, MeshLambertMaterial, SRGBColorSpace, TextureLoader } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import type { GLTFParser } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

const logger = createLogger("Resources");

enum ResourcePriority {
	REQUIRED_MODEL,
	USED_WORLD_BLOCK_TEXTURE,
	AVATAR_COMPONENT,
	SOUND,
	INVENTORY_BLOCK_TEXTURE,
	OTHER,
}

export type ResourceRequest = {
	id: string;
	url: string;
	type: string;
	onLoaded?: (result: unknown) => void;
};

const downloadQueue: Array<string> = [];

export const idToResource = new Map<string, any>();
export const idToPromise = new Map<string, Promise<any>>();
const urlToPromise = new Map<
	string,
	{ promise: Promise<any>; resolve: (value: any) => void; reject: (error: any) => void }
>();

const urlToRequests = new Map<string, ResourceRequest[]>();
const idToRequests = new Map<string, ResourceRequest[]>();

// for loading screen
let loaded = 0;
let total = 0;

const LOADERS_CONCURRENCY = 10;

// number of seconds in between gltf load jobs before cache clearing the draco workers
const LOADERS_DRACO_TIMEOUT = 10;

let downloadingPromise: Promise<void> | undefined = undefined;

let textureLoader: TextureLoader;
let audioLoader: AudioLoader;
let dracoLoader: DRACOLoader;
let gltfLoader: GLTFLoader;
let gltfJobs: number = 0;
let dracoTimer: Timer | number | undefined;
let appleDeborker: Promise<any> | null;
let appleDeborkQueue: Promise<any>;

export const onResourceLoaded = new Topic<[{ id: string; data: any; mimeType: string; url: string }]>();

export const get = (id: string) => {
	const resource = idToResource.get(id);

	if (!resource) throw Error(`Resource not found or not loaded yet: ${id}`);

	return resource;
};

export const isLoaded = (id: string) => idToResource.has(id);

export const initLoaders = () => {
	textureLoader = new TextureLoader();
	audioLoader = new AudioLoader();

	dracoLoader = new DRACOLoader().setDecoderPath(getStaticFile(`static/draco/`));

	gltfLoader = new GLTFLoader().setDRACOLoader(dracoLoader);
	gltfLoader.register(function (parser: GLTFParser) {
		deferredCallback().then(async function () {
			const nodeCache = (parser as any).nodeCache;
			let hasGeom = false;
			for (const i in nodeCache) {
				const o3d = await nodeCache[i];
				if (o3d.geometry) {
					hasGeom = true;
					break;
				}
			}

			if (
				!parser.extensions["KHR_binary_glTF"] ||
				(hasGeom && !parser.extensions["KHR_draco_mesh_compression"])
			) {
				logger.error(
					`The following GLB is not compressed, but should be:
${parser.options.path}

1. https://gltf.report
2. Drag 'n' drop glb onto the page
3. Visit the script tab on the left ( <> button )
4. Click run, wait patiently
5. Select export -> compression -> draco in the top right panel
6. Click export, wait patiently`,
				);
			}
		});

		return { name: "delicious draco detection done delicately" };
	});

	gltfJobs = 0;
	dracoTimer = undefined;
	appleDeborker = null;
	appleDeborkQueue = Promise.resolve();

	dracoLoader.preload();
};

const download = async (url: string) => {
	const requests = urlToRequests.get(url);

	if (!requests) return;

	try {
		LoadingScreen.setProgress(requests[0].id, loaded / total);

		const mimeType = requests[0].type;

		let promise;
		if (mimeType.includes("glb") || mimeType.includes("gltf")) {
			// prefer draco-compressed glb
			promise = gltfLoadAsync(url, false);
		} else if (
			mimeType.includes("audio/ogg") ||
			mimeType.includes("audio/mp3") ||
			mimeType.includes("audio/mpeg") ||
			mimeType.includes("audio/wav")
		) {
			// prefer ogg
			promise = audioLoadAsync(url, mimeType.includes("ogg"));
		} else {
			// assume anything else is an image. too many extensions to keep track of. prefer png or jpg
			promise = textureLoadAsync(url);
		}

		const result = await promise;

		const promiseWithResolvers = urlToPromise.get(url);

		if (promiseWithResolvers) {
			promiseWithResolvers.resolve(result);
		}

		for (const request of requests) {
			idToResource.set(request.id, result);

			onResourceLoaded.emit({ id: request.id, data: result, mimeType, url });

			if (request.onLoaded) {
				request.onLoaded(result);
			}
		}

		loaded++;
	} catch (oops) {
		let errorStr;
		if (oops instanceof Event) {
			errorStr = "";
		} else {
			errorStr = "\n\n" + ((oops as { message?: string })?.message ?? oops ?? "mystery error");
		}

		throw Error(`Failed to download resource: ${url}${errorStr}`);
	}
};

const startDownloading = async () => {
	if (downloadingPromise) return;

	// todo: bit of a hack
	const world = BB.world;
	const blockTypeRegistryClient = world.client!.blockTypeRegistry;

	const getBlockTexturePriority = (blockTypes: string[]) => {
		return blockTypes
			.map((blockType) => blockTypeRegistryClient.blockTypesLoadingPriority.indexOf(blockType))
			.filter((index) => index !== -1)
			.reduce((min, index) => Math.min(min, index), Number.MAX_SAFE_INTEGER);
	};

	function getPriority(_url: string, requests: ResourceRequest[]) {
		const request = requests[0];

		if (request.id.includes("avatarComponent#")) {
			return ResourcePriority.AVATAR_COMPONENT;
		}

		if (request.type.includes("glb") || request.type.includes("gltf")) {
			return ResourcePriority.REQUIRED_MODEL;
		}

		if (request.type.includes("audio")) {
			return ResourcePriority.SOUND;
		}

		const resource = BB.world.content.state.resources.get(request.id);

		if (!resource) return ResourcePriority.OTHER;

		if (resource.resourceType === "blockTexture") {
			const blockTypes = blockTypeRegistryClient.blockTextureResourcesToBlockTypes.get(request.id);

			if (
				blockTypes &&
				blockTypes.some((blockType) =>
					blockTypeRegistryClient.blockTypesLoadingPriority.includes(blockType),
				)
			) {
				return ResourcePriority.USED_WORLD_BLOCK_TEXTURE;
			} else {
				return ResourcePriority.INVENTORY_BLOCK_TEXTURE;
			}
		}

		return ResourcePriority.OTHER;
	}

	const prioritize = (a: string, b: string) => {
		const aRequests = urlToRequests.get(a)!;
		const bRequests = urlToRequests.get(b)!;

		const aPriority = getPriority(a, aRequests);
		const bPriority = getPriority(b, bRequests);

		if (
			aPriority === ResourcePriority.USED_WORLD_BLOCK_TEXTURE &&
			bPriority === ResourcePriority.USED_WORLD_BLOCK_TEXTURE
		) {
			const aBlockTypes = blockTypeRegistryClient.blockTextureResourcesToBlockTypes.get(
				aRequests[0].id,
			)!;
			const aBlockTexturePriority = getBlockTexturePriority(aBlockTypes);

			const bBlockTypes = blockTypeRegistryClient.blockTextureResourcesToBlockTypes.get(
				bRequests[0].id,
			)!;
			const bBlockTexturePriority = getBlockTexturePriority(bBlockTypes);

			return aBlockTexturePriority - bBlockTexturePriority;
		}

		return aPriority - bPriority;
	};

	downloadingPromise = (async () => {
		for await (const _ of prioritizedAsyncPool(
			LOADERS_CONCURRENCY,
			downloadQueue,
			download,
			prioritize,
		)) {
		}
	})();

	await downloadingPromise;

	downloadingPromise = undefined;
};

export const queue = (requests: ResourceRequest[]) => {
	const queuePromises: Promise<void>[] = [];

	// preprocessing urls + checking for duplicates
	for (const unprocessedRequest of requests) {
		const processedRequest = { ...unprocessedRequest };

		processedRequest.url = getStaticFile(processedRequest.url);

		// ensure all requests for the same id have the same url
		const existingRequestsForId = idToRequests.get(processedRequest.id);

		if (existingRequestsForId) {
			for (const existingRequest of existingRequestsForId) {
				if (existingRequest.url !== processedRequest.url) {
					throw Error(
						`Resource id conflict: there is another resource with id "${processedRequest.id}" with a different URL.`,
					);
				}
			}

			existingRequestsForId.push(processedRequest);
		} else {
			idToRequests.set(processedRequest.id, [processedRequest]);
		}

		// update url -> promise and id -> promise maps
		let promiseWithResolvers = urlToPromise.get(processedRequest.url);

		if (!promiseWithResolvers) {
			promiseWithResolvers = Promise.withResolvers<any>();

			urlToPromise.set(processedRequest.url, promiseWithResolvers);
		}

		if (!idToPromise.has(processedRequest.id)) {
			idToPromise.set(processedRequest.id, promiseWithResolvers.promise);
		}

		// update url -> requests map
		let existingUrlRequests = urlToRequests.get(processedRequest.url);

		if (!existingUrlRequests) {
			existingUrlRequests = [];
			urlToRequests.set(processedRequest.url, existingUrlRequests);

			downloadQueue.push(processedRequest.url);

			total++;
		}

		existingUrlRequests.push(processedRequest);

		// add to promises to resolve before queue request has been handled
		queuePromises.push(promiseWithResolvers.promise);
	}

	startDownloading();

	return Promise.all(queuePromises);
};

export const dispose = () => {
	for (const [, resource] of idToResource) {
		if ("dispose" in resource && typeof resource.dispose === "function") {
			resource.dispose();
		}
	}

	idToResource.clear();
	idToPromise.clear();
	urlToPromise.clear();
	idToRequests.clear();
	urlToRequests.clear();
	downloadQueue.length = 0;
	loaded = 0;
	total = 0;
	downloadingPromise = undefined;
};

// drop in replacement for gltfLoader.loadAsync
export const gltfLoadAsync = async (url: string, freeRAM = true) => {
	clearTimeout(dracoTimer);
	gltfJobs++;

	try {
		// hack to pass the url to the draco detector
		gltfLoader.resourcePath = url;

		const gltf = await gltfLoader.loadAsync(url);

		// todo: revsit...
		(gltf as any).parser = null; //massive waste of memory

		const disposables = new Set();

		// Below are some commented lines that would show redundant node hierarchies. use this to detect less-than-optimal glbs.
		// This should not always be active because it is inactionable log spam for developers.
		// let hasGeom = false;
		// gltf.scene.traverse(function (node) {
		// 	if ((node as Mesh).geometry) hasGeom = true;
		// });

		gltf.scene.updateMatrixWorld();
		gltf.scene.traverse(function (node) {
			if (!(node as Mesh).isMesh) {
				// if (hasGeom && node !== gltf.scene && !(node as Bone).isBone && node.children.length === 1)
				// 	logger.error(`Redundant node hierarchy detected: ${node.name}\n${url}`);

				return;
			}

			const nodeMesh = node as Mesh;

			//convert material to MeshLambertMaterial
			const oldMat = nodeMesh.material as Material;
			const newMat = new MeshLambertMaterial();

			// todo: material.copy?

			for (const i in oldMat) {
				//there may be some situations where some but not all the defines need to be copied to the new material. for now, skipping it twerks good enough
				if (
					Object.prototype.hasOwnProperty.call(oldMat, i) &&
					Object.prototype.hasOwnProperty.call(newMat, i) &&
					!(typeof (oldMat as any)[i] === "function") &&
					i !== "type" &&
					i !== "defines"
				) {
					// @ts-expect-error hack
					newMat[i] = oldMat[i];
				}

				// @ts-expect-error hack
				if (newMat[i]?.isTexture) disposables.add(newMat[i]);
			}

			nodeMesh.material = newMat;

			//convert mesh to shadow
			ShadowMesh.make(node);

			if (freeRAM) {
				//workaround for three.js bug trying to read from morph attributes after running onUploadCallback
				freeRAMAfterMeshGeometryUpload(nodeMesh, true);
			}

			//https://github.com/mrdoob/three.js/issues/25960
			if ((nodeMesh as SkinnedMesh).isSkinnedMesh || (nodeMesh as InstancedMesh).isInstancedMesh) {
				(node as InstancedMesh | SkinnedMesh).computeBoundingSphere();
			}

			disposables.add(nodeMesh.geometry).add(nodeMesh.material);
		});

		// @ts-expect-error todo
		gltf.dispose = function () {
			// @ts-expect-error todo
			for (const d of disposables) d.dispose();
		};

		return gltf;
	} finally {
		if (--gltfJobs === 0)
			dracoTimer = setTimeout(function () {
				// @ts-expect-error todo: private
				for (const worker of dracoLoader.workerPool) worker.terminate();

				// @ts-expect-error todo: private
				dracoLoader.workerPool.length = 0;
			}, LOADERS_DRACO_TIMEOUT * 1000);
	}
};

// (nearly) drop in replacement for audioLoader.loadAsync. tell it whether it's an ogg
const audioLoadAsync = async (url: string, isOGG: boolean) => {
	if (isIOS() && isOGG) {
		//apple does not support ogg. making the bold assumption here that this is ogg vorbis and not ogg opus. requires a different decoder
		const [decoder, vorbisBuffer] = await Promise.all([
			getAppleDeborker(),
			fetch(url)
				.then((response) => response.arrayBuffer())
				.then((arrayBuffer) => new Uint8Array(arrayBuffer)),
		]);

		const { channelData, samplesDecoded, sampleRate } = await (appleDeborkQueue = appleDeborkQueue.then(
			() => decoder.decodeFile(vorbisBuffer),
		));

		const audioBuffer = AudioContext.getContext().createBuffer(
			channelData.length,
			samplesDecoded,
			sampleRate,
		);

		for (let i = 0; i < channelData.length; i++) audioBuffer.copyToChannel(channelData[i], i);

		return audioBuffer;
	} else {
		return await audioLoader.loadAsync(url);
	}
};

export const textureLoadAsync = async (url: string) => {
	const tex = await textureLoader.loadAsync(url);
	tex.colorSpace = SRGBColorSpace;
	return tex;
};

export const isLazyLoadable = (mimeType: string) => {
	return (
		mimeType.includes("audio/ogg") ||
		mimeType.includes("audio/mp3") ||
		mimeType.includes("audio/mpeg") ||
		mimeType.includes("audio/wav")
	);
};

const getAppleDeborker = async () => {
	if (appleDeborker) return appleDeborker;

	return await (appleDeborker = new Promise(async function (resolve, reject) {
		try {
			await import(/* @vite-ignore */ getStaticFile(`static/ogg-vorbis-decoder.min.js`));

			// @ts-expect-error untyped
			const decoder = new window["ogg-vorbis-decoder"].OggVorbisDecoderWebWorker();
			await decoder.ready;
			resolve(decoder);
		} catch (oops) {
			reject(oops);
		}
	}));
};
