import { BB } from "base/BB";
import { getPeerMetadata } from "base/util/PeerMetadata";
import * as BlockGroups from "base/world/block/BlockGroups";
import type { Item } from "base/world/entity/Item";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import { ItemWrench } from "mods/defs/ItemWrench";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import type { Vector3Tuple } from "three";
import { Vector3 } from "three";
import {
	addBrushToSelection,
	BulkSelectionBlocksBrushState,
	nudgeBulkSelection,
	resetBulkSelectionBrush,
	resetSelector,
	SelectorTargetType,
	setBulkSelectionBrush,
} from "base/world/entity/component/CharacterSelector";

const _block = new Vector3();

export enum WrenchMode {
	NO_CORNERS_SELECTED,
	ONE_CORNER_SELECTED,
	TWO_CORNERS_SELECTED,
	SELECT_BLOCK_GROUPS,
}

export const init = () => {
	return {
		wrenchMode: WrenchMode.NO_CORNERS_SELECTED,
		cornerOne: null as Vector3 | null,
		cornerTwo: null as Vector3 | null,
	};
};

export type WrenchState = ReturnType<typeof init>;

export const update = (state: WrenchState, world: World) => {
	const wrench = world.client.getEquippedItem();
	if (wrench?.def !== ItemWrench.name) return;

	const camera = world.client.camera;

	const mode = state.wrenchMode;

	const firstPerson = camera.is1stPerson();
	if (!firstPerson && mode !== WrenchMode.NO_CORNERS_SELECTED) {
		resetWrench(state, world);
		return;
	}

	const selector = camera.target?.selector;
	const viewRaycast = camera.target?.viewRaycast;
	if (!selector || !viewRaycast) return;

	if (mode === WrenchMode.NO_CORNERS_SELECTED) {
		if (!viewRaycast.state.block) {
			resetBulkSelectionBrush(selector);
		} else {
			setBulkSelectionBrush(
				selector,
				viewRaycast.state.block,
				viewRaycast.state.block,
				BulkSelectionBlocksBrushState.SELECTING_FIRST_CORNER,
			);
		}

		return;
	}

	if (!viewRaycast.state.block) {
		return;
	}

	if (mode === WrenchMode.ONE_CORNER_SELECTED) {
		setBulkSelectionBrush(
			selector,
			state.cornerOne!,
			viewRaycast.state.block,
			BulkSelectionBlocksBrushState.SELECTING_SECOND_CORNER,
		);
	} else if (mode === WrenchMode.TWO_CORNERS_SELECTED) {
		setBulkSelectionBrush(
			selector,
			viewRaycast.state.block,
			viewRaycast.state.block,
			BulkSelectionBlocksBrushState.SELECTING_FIRST_CORNER,
		);
	}
};

export const onItemChange = (world: World, prvItem: Item | null, curItem: Item | null) => {
	const state = world.client.wrench;

	//possible but very unlikely that both prvItem and item are both wrenches
	//more likely one of them will be null and we attempt to reset both either way
	if (prvItem?.def === ItemWrench.name) {
		resetWrench(state, world);
	}

	if (curItem?.def === ItemWrench.name) {
		resetWrench(state, world);
	}
};

const setWrenchMode = (state: WrenchState, wrenchMode: WrenchMode) => {
	state.wrenchMode = wrenchMode;

	UI.state.wrench().setMode(wrenchMode);
};

export const onItemUse = (world: World, primary: boolean, secondary: boolean) => {
	if (!getPeerMetadata(world.client.loopbackPeer).permissions.canUseWrench) return;

	const state = world.client.wrench;

	const camera = world.client.camera;
	const selector = camera.target?.selector;
	const viewRaycast = camera.target?.viewRaycast;
	if (!selector || !viewRaycast) return;

	const mode = state.wrenchMode;

	// TODO: bad!
	const input = BB.client.inputPoll;

	if (mode === WrenchMode.NO_CORNERS_SELECTED) {
		if (primary && selector.state.currentTarget) {
			if (selector.state.currentTarget.type === SelectorTargetType.NEW_GROUP) {
				// create new group
				if (viewRaycast.state.block) {
					const blocks = [
						[viewRaycast.state.block.x, viewRaycast.state.block.y, viewRaycast.state.block.z],
					];
					UI.state.wrench().createNewGroup(blocks);
				}
			} else {
				UI.state.wrench().inspect(selector.state.currentTarget);
			}

			resetWrench(state, world);
		} else if (secondary && viewRaycast.state.block) {
			// select first corner
			state.cornerOne = viewRaycast.state.block.clone();

			setWrenchMode(state, WrenchMode.ONE_CORNER_SELECTED);

			world.sfxManager.play({
				asset: "snd-ui-basic-selection-start",
				volume: 5,
				loop: false,
			});
		}
	} else if (mode === WrenchMode.ONE_CORNER_SELECTED) {
		if (primary) {
			// cancel brush selection
			resetBulkSelectionBrush(selector);

			if (selector.state.bulkSelectionBlocks.size() <= 0) {
				setWrenchMode(state, WrenchMode.NO_CORNERS_SELECTED);
			} else {
				setWrenchMode(state, WrenchMode.TWO_CORNERS_SELECTED);
			}
		} else if (secondary && viewRaycast.state.block) {
			// select second corner
			state.cornerTwo = viewRaycast.state.block.clone();
			setWrenchMode(state, WrenchMode.TWO_CORNERS_SELECTED);

			setBulkSelectionBrush(
				selector,
				state.cornerOne!,
				state.cornerTwo!,
				BulkSelectionBlocksBrushState.SELECTING_SECOND_CORNER,
			);
			addBrushToSelection(selector);
			resetBulkSelectionBrush(selector);

			world.sfxManager.play({
				asset: "snd-ui-basic-selection-end",
				volume: 5,
				loop: false,
			});
		}
	} else if (mode === WrenchMode.TWO_CORNERS_SELECTED) {
		if (primary) {
			// check if all selected blocks are already grouped together
			const blocks = [];
			const selectedGroups = new Map();

			for (const block of selector.state.bulkSelectionBlocks.iterate(_block)) {
				blocks.push([block.x, block.y, block.z]);

				const groups = BlockGroups.getIdsAtPosition(world.blockGroups, block.x, block.y, block.z);

				if (groups) {
					for (const group of groups) {
						const groupCount = selectedGroups.get(group) ?? 0;
						selectedGroups.set(group, groupCount + 1);
					}
				}
			}

			if (
				selectedGroups.size === 0 ||
				selector.state.currentTarget?.type === SelectorTargetType.NEW_GROUP
			) {
				// create new group
				UI.state.wrench().createNewGroup(blocks);
			} else if (selectedGroups.size > 0) {
				// edit group
				UI.state.wrench().editGroup(selector.state.currentTarget);
			}

			resetWrench(state, world);
		} else if (secondary && input.keysAreDown(["control"]) && viewRaycast.state.block) {
			// select another corner
			state.cornerOne = viewRaycast.state.block.clone();
			setWrenchMode(state, WrenchMode.ONE_CORNER_SELECTED);
		} else if (secondary) {
			UI.state.wrench().showSelectionOptions();
		}
	}
};

export const resetWrench = (state: WrenchState, world: World) => {
	state.cornerOne = null;
	state.cornerTwo = null;

	setWrenchMode(state, WrenchMode.NO_CORNERS_SELECTED);

	const selector = world.client.camera.target?.selector;

	if (selector) {
		resetSelector(selector);
	}
};

export const removeBlockGroups = (state: WrenchState, world: World) => {
	if (state.wrenchMode !== WrenchMode.TWO_CORNERS_SELECTED) return;

	const selector = world.client.camera.target?.selector;
	if (!selector) return;

	const positions: Vector3Tuple[] = [];

	for (const block of selector.state.bulkSelectionBlocks.iterate(_block)) {
		positions.push([block.x, block.y, block.z]);
	}

	BlockGroupsRouter.clearAtPositions(positions);

	resetWrench(state, world);
};

export const cycleTarget = (state: WrenchState, world: World) => {
	const selector = world.client.camera.target?.selector;
	if (!selector || !selector.state.targets) return;

	const index = selector.state.currentTarget
		? selector.state.targets.findIndex((g) => g.id === selector.state.currentTarget?.id)
		: -1;

	let nextIndex;

	if (index === -1) {
		nextIndex = 0;
	} else {
		nextIndex = index + (1 % selector.state.targets.length);
	}

	selector.state.chosenTarget = selector.state.targets[nextIndex];
};

export const resetBulkSelection = (state: WrenchState, world: World) => {
	resetWrench(state, world);
};

export const nudge = (state: WrenchState, world: World, direction: Vector3) => {
	const camera = world.client.camera;

	const selector = camera.target?.selector;
	if (!selector) return;

	const cameraWorldDirection = camera.getWorldDirection(new Vector3()).multiplyScalar(-1);

	const compassDirection = new Vector3();
	if (Math.abs(cameraWorldDirection.x) > Math.abs(cameraWorldDirection.z)) {
		compassDirection.x = Math.sign(cameraWorldDirection.x);
	} else {
		compassDirection.z = Math.sign(cameraWorldDirection.z);
	}

	const worldNudge = direction
		.clone()
		.applyAxisAngle(new Vector3(0, 1, 0), Math.atan2(compassDirection.x, compassDirection.z))
		.round();

	nudgeBulkSelection(selector, worldNudge);
};
