import * as QuestBase from "base/world/Quest";
import * as QuestRouter from "router/world/Quest";

import type { EntityID } from "@jamango/engine/EntityID.ts";
import { isNullish } from "@jamango/helpers";
import { BB } from "base/BB";
import type { World } from "base/world/World";
import { UI } from "client/dom/UI";
import { EasySprite } from "client/util/EasySprite.js";
import { Text3D } from "client/world/fx/Text3D";
import * as Net from "router/Net";
import { MathUtils, Vector3, type Texture } from "three";
import * as Resources from "client/Resources";

export const QUEST_WAYPOINT_RADIUS = 0.65;
export const QUEST_ARROW_RADIUS = 0.85;

const tmpVec1 = new Vector3();
const tmpVec2 = new Vector3();
const tmpVec3 = new Vector3();

export const assets = [
	{
		id: "tex-waypoint",
		url: "assets/engine/textures/tex-waypoint.png",
		type: "image/png",
	},
];

export type State = Omit<QuestBase.State, "objectives"> & {
	objectives: Map<QuestBase.ObjectiveName, ObjectiveState>;
	arrowTex: Texture;
};

export type ObjectiveState = QuestBase.ObjectiveState & {
	arrow: EasySprite;
	hideWaypoint: boolean;

	spr: EasySprite | null;
	text: ReturnType<typeof Text3D.render> | null;
	prvDst: number | null;

	dispose?: () => void;
};

export function init(state: QuestBase.State) {
	const clientState = state as State;
	clientState.arrowTex = Resources.get("tex-waypoint") as Texture;
}

export function localCreateObjective(state: State) {
	UI.state.game().setObjectives(new Map(state.objectives));
}

export function localCreateWaypoint(state: State, world: World, obj: QuestBase.ObjectiveState) {
	const clientObj = obj as ObjectiveState;

	clientObj.dispose?.();

	clientObj.arrow = new EasySprite(state.arrowTex, world.client!.sceneHUD, 1);
	clientObj.arrow.center.set(0.5, 0.5);

	//values copied from Text3D defaults
	clientObj.arrow.material.color.setHex(0x111827);
	clientObj.arrow.material.opacity = 0.5;

	//these will be created later during the render loop
	clientObj.spr = clientObj.text = clientObj.prvDst = null;

	clientObj.dispose = function () {
		clientObj.spr?.dispose();
		clientObj.spr?.texture.dispose();
		clientObj.arrow.dispose();
	};

	clientObj.hideWaypoint = false;
}

export function localHideWaypoint(state: State, name: QuestBase.ObjectiveName) {
	const obj = state.objectives.get(name);
	if (!obj) return;

	obj.dispose?.();
	obj.hideWaypoint = true;
}

export function localCompleteObjective(state: State, obj: ObjectiveState) {
	obj.dispose?.();
	UI.state.game().setObjectives(new Map(state.objectives));
}

export function update(state: State, world: World) {
	const cam = world.client!.camera;
	const dir = cam.getDirection(tmpVec1, false);

	const cw = BB.client.canvas.width;
	const ch = BB.client.canvas.height;

	for (const obj of state.objectives.values()) {
		if (!QuestBase.hasWaypoint(obj)) continue;
		if (!obj.hasWaypoint || obj.hideWaypoint) continue;

		const pos = tmpVec2;
		if (isNullish(obj.entityID)) {
			pos.copy(obj.pos);
		} else {
			const entity = world.getEntity(obj.entityID);
			if (entity) {
				pos.copy(entity.position).add(obj.pos);
			}
		}

		const dst = Math.round(pos.distanceTo(cam.target.position));

		if (!obj.spr || obj.prvDst !== dst) {
			obj.text = Text3D.render(null, dst.toString());

			if (!obj.spr) {
				obj.spr = new EasySprite(obj.text.texture, world.client!.sceneHUD, 1);
			} else if (obj.prvDst !== dst) {
				obj.spr.texture.dispose();
				obj.spr.setTexture(obj.text.texture);
			}

			obj.spr.center.set(
				obj.text.pixelWidth / obj.text.canvasWidth / 2,
				1 - obj.text.pixelHeight / obj.text.canvasHeight,
			);
		}

		const prj = obj.spr.position;
		prj.copy(pos).project(world.client!.camera).z = 0;

		//if behind camera
		if (dir.dot(tmpVec3.copy(pos).sub(cam.position)) < 0) prj.y = -1;

		if (prj.length() > QUEST_WAYPOINT_RADIUS) {
			prj.normalize();
			obj.arrow.position.copy(prj);
			prj.multiplyScalar(QUEST_WAYPOINT_RADIUS);
			obj.arrow.position.multiplyScalar(QUEST_ARROW_RADIUS);

			obj.arrow.visible = true;
			obj.arrow.material.rotation = Math.atan2(prj.y, prj.x);
		} else {
			obj.arrow.visible = false;
		}

		prj.x = MathUtils.mapLinear(prj.x, -1, 1, 0, cw);
		prj.y = MathUtils.mapLinear(prj.y, -1, 1, 0, ch);

		if (obj.arrow.visible) {
			obj.arrow.position.x = MathUtils.mapLinear(obj.arrow.position.x, -1, 1, 0, cw);
			obj.arrow.position.y =
				MathUtils.mapLinear(obj.arrow.position.y, -1, 1, 0, ch) + obj.text!.pixelHeight / 2;
		}

		obj.prvDst = dst;
	}
}

export function dispose(state: State) {
	for (const obj of state.objectives.values()) obj.dispose?.();
}

//client joined
export function initCommandListeners() {
	Net.listen("quest_createobj", function (o: QuestBase.ObjectiveDef, world) {
		QuestRouter.localCreateObjective(world.quest, o);
	});

	Net.listen("quest_waypoint", function (a: [QuestBase.ObjectiveName, EntityID, Vector3], world) {
		QuestRouter.localCreateWaypoint(world.quest, world, ...a);
	});

	Net.listen("quest_endobj", function (name: QuestBase.ObjectiveName, world) {
		QuestRouter.localCompleteObjective(world.quest, name);
	});
}
