import { NODE_TYPE_ID } from "base/rete/Constants";
import { node } from "base/rete/Types";
import { entityIsPlayer } from "base/world/entity/component/Type";
import { showWorldPortal, showWorldPortalCommand } from "router/WorldPortal";
import { LoopbackPeer } from "@jamango/ibs";

export const WORLD_PORTAL_NODES = [
	node({
		id: "5f1dede4-6692-4225-877a-a79a0b32649c",
		name: "Player Show World Portal",
		type: NODE_TYPE_ID.function.world,
		description: "Prompts a player to join another world.",
		inputs: {
			exec: { type: "exec" },
			player: { type: "entity", name: "Player" },
			world: { name: "World Shortcode", type: "string" },
		},
		outputs: {
			exec: { name: "Exec", type: "exec" },
		},
		execute(inputs, ctx) {
			const player = inputs.player;
			if (!player || !entityIsPlayer(player)) {
				throw new Error("A player entity was not provided.");
			}

			const worldShortcode = inputs.world;

			if (!/^[a-zA-Z0-9]+$/.test(worldShortcode)) {
				throw new Error("World shortcode must be a alphanumeric string.");
			}

			const peer = ctx.world.playerToPeer.get(player);
			if (!peer) {
				throw new Error("Peer for Player entity not found.");
			}

			if (peer instanceof LoopbackPeer) {
				showWorldPortal(worldShortcode);
			} else {
				showWorldPortalCommand.send(worldShortcode, peer);
			}
		},
	}),
];
