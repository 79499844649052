import { BB } from "base/BB";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { ObjectiveType } from "@jamango/frontend/Quest.ts";
import { BLOCK_AIR } from "base/world/block/Util.js";
import { UI } from "client/dom/UI";
import * as trigger from "base/rete/modules/trigger";
import { addSelectGroup, interpreterAnyToString, entitiesToPeers } from "base/rete/NodeSharedUtil";
import { getEntity, getNPC, getPlayer } from "base/rete/modules/validate.js";
import { DEGRAD, V0 } from "base/util/math/Math.ts";
import { getPeerMetadata } from "base/util/PeerMetadata";
import { netState } from "router/Parallelogram";
import { broadcastPrivateChat } from "server/dom/Chat";
import * as QuestServer from "server/world/Quest";
import { Box3, Vector3 } from "three";
import * as BlockGroups from "base/world/block/BlockGroups";
import * as BlockGroupsRouter from "router/world/block/BlockGroups";
import { DEBUG_ENABLED } from "@jamango/generated";
import { NODE } from "../InternalNameMap";
import * as Net from "router/Net";
import { ItemNovaGun } from "mods/defs/ItemNovaGun";
import { ItemNovaRifle } from "mods/defs/ItemNovaRifle";
import { ItemNovAssaultRifle } from "mods/defs/ItemNovAssaultRifle";
import { ItemSpade } from "mods/defs/ItemSpade";
import * as SpawnServer from "server/world/Spawn";
import type { NodeDef } from "../Types";
import type { Character } from "base/world/entity/Character";
import type { World } from "base/world/World";
import type { Entity } from "base/world/entity/Entity";
import { entityIsCharacter } from "base/world/entity/component/Type";
import { createLogger } from "@jamango/helpers";
import { getItemSelectOptions } from "./Items";

const logger = createLogger("NativeFunctions");

const tmpVec = new Vector3();

const getAudioOptions = () =>
	BB.world.content.state.audios.getAll().map((audio) => ({
		value: audio.pk,
		label: audio.resource.name,
	}));

export const NATIVE_FUNCTIONS: NodeDef[] = [
	// General - Actions
	{
		id: "0002-01-0001",
		name: "Wait",
		type: NODE_TYPE_ID.logic.controlFlow,
		description: "Adds a delay in seconds",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			duration: {
				name: "Duration",
				type: "number",
				control: "number",
				config: { defaultValue: 3 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		suspend: (inputs) => inputs.duration,
	},
	{
		id: "0002-01-0002",
		name: "HUD Show Pop-Up",
		type: NODE_TYPE_ID.function.general,
		description: "Displays a pop-up notification in the HUD.",
		inputs: {
			exec: { type: "exec" },
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			position: {
				name: "Position",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						{ value: "top", label: "Top" },
						{ value: "bottom", label: "Bottom" },
						{ value: "left", label: "Left" },
						{ value: "right", label: "Right" },
						{ value: "center", label: "Center" },
					],
				},
			},
			title: { name: "Title", type: "any", structure: "any", control: "string" },
			message: { name: "Message", type: "any", structure: "any", control: "string" },
			imageURL: { name: "Image URL", type: "string", control: "string" },
			duration: {
				name: "Duration",
				type: "number",
				control: "number",
				config: {
					label: "Duration",
					defaultValue: 3,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Value must be positive",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		suspend: (inputs) => inputs.duration,
		execute(inputs, ctx) {
			const recip = entitiesToPeers(inputs.peers, ctx.world);
			ctx.world.hudPopup.router.set(
				recip,
				{
					position: inputs.position,
					title: interpreterAnyToString(inputs.title),
					message: interpreterAnyToString(inputs.message),
					imageURL: inputs.imageURL,
				},
				inputs.duration,
			);
		},
	},
	{
		id: "0002-01-0005",
		name: "HUD Clear Pop-Up",
		type: NODE_TYPE_ID.function.general,
		description:
			"Clears any pop-up notification currently in the HUD, including character dialogue. This will not cause any events to be cancelled.",
		inputs: {
			exec: { type: "exec" },
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const recip = entitiesToPeers(inputs.peers, ctx.world);
			ctx.world.hudPopup.router.set(recip);
		},
	},
	{
		id: "0002-01-0003",
		name: "Console Log",
		type: NODE_TYPE_ID.function.general,
		description: "Logs a message into the console.",
		predictable: true,
		inputs: {
			exec: { type: "exec" },
			message: { name: "Message", type: "any", structure: "any", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			let msg = inputs.message;
			if (!DEBUG_ENABLED) msg = interpreterAnyToString(msg);

			console.log(msg);
		},
	},
	{
		id: "0002-01-0004",
		name: "HUD Show NPC Dialogue",
		type: NODE_TYPE_ID.function.general,
		description: "Sets a character dialogue at the bottom of the screen for given peer",
		info: ["You can write [next] in your message to split the dialogue into multiple parts."],
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			message: { name: "Message", type: "any", structure: "any", control: "textarea" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const character = getEntity(inputs, ctx, "character");
			if (!character) return;

			const recip = ((inputs.peers ?? []) as Entity[])
				.map((player) => ctx.world.playerToPeer.get(player))
				.filter((peer) => peer); //ignore entities with no peer

			const popup = {
				entityID: character.entityID,
				message: interpreterAnyToString(inputs.message),
			};

			ctx.world.hudPopup.router.set(recip, popup);
		},
	},
	// General - Getters
	{
		id: "0002-01-1003",
		name: "World Get State",
		type: NODE_TYPE_ID.function.general,
		description: "Gets the value of a world state",
		inputs: {
			name: { name: "State", type: "string", control: "string" },
		},
		outputs: {
			value: { name: "Value", type: "any", structure: "any" },
		},
		resolve(inputs, ctx) {
			if (!inputs.name) {
				return { value: null };
			}

			return {
				value: ctx.world.rete.state.world[inputs.name],
			};
		},
	},
	{
		id: "0002-01-1004",
		name: "World Get Peer Count",
		type: NODE_TYPE_ID.function.general,
		description: "Gets the number of players connected to the current session",
		predictable: true,
		outputs: {
			players: { name: "Players", type: "number" },
		},
		resolve(_inputs, _ctx) {
			if (netState.isClient) return { players: UI.state.multiplayer().players.length };
			else return { players: Net.getPeers().length };
		},
	},
	// General - Setters
	{
		id: "0002-01-2003",
		name: "World Set State",
		type: NODE_TYPE_ID.function.general,
		description: "Sets the value of a world state",
		inputs: {
			exec: { type: "exec" },
			name: { name: "State", type: "string", control: "string" },
			value: { name: "Value", type: "any", structure: "any" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			if (!inputs.name) return;

			ctx.world.rete.state.world[inputs.name] = inputs.value;
			trigger.onWorldStateChange(ctx.world, inputs.name, inputs.value);
		},
	},

	// World - Getters
	// World - Setters
	{
		id: "0002-02-2001",
		name: "Block Set By Position",
		type: NODE_TYPE_ID.function.world,
		description: "Set block at given position",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			shape: {
				name: "Shape",
				type: "number",
				control: "select",
				config: {
					explicitSortOptions: [
						{ label: "(No change)", value: -1 },
						{ label: "Cube", value: 15 },
						{ label: "Air", value: 0 },
					],
				},
			},
			type: {
				name: "Type",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [{ label: "(No change)", value: -1 }],
					autoSortOptions: (ctx: { world: World }) =>
						Array.from(ctx.world.blockTypeRegistry.blockNameToType.values()).map((def) => ({
							label: def.display,
							value: def.name,
						})),
				},
			},
			triggerEvents: {
				name: "Trigger Events",
				type: "boolean",
				control: "boolean",
				config: {
					label: "Trigger build/break/sculpt events",
					defaultValue: true,
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const position = tmpVec.copy(inputs.position ?? V0).floor();
			ctx.world.scene.setBlock(
				position,
				inputs.shape ?? BLOCK_AIR,
				inputs.type,
				!!inputs.triggerEvents,
			);
		},
	},
	{
		id: "0002-03-1003",
		name: "Entity Get State",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the value of a given entities state",
		inputs: {
			peer: { name: "Entity", type: "entity" },
			name: { name: "State", type: "string", control: "string" },
		},
		outputs: {
			value: { name: "Value", type: "any", structure: "any" },
		},
		resolve(inputs, ctx) {
			// TODO: migrate to "entity"
			const ent = getEntity(inputs, ctx, "peer");
			if (!inputs.name || !ent) {
				throw Error("[Entity Get State]: No state name or entity provided");
			}
			return {
				value: ctx.world.rete.state.entity[ent.entityID]?.[inputs.name],
			};
		},
	},
	{
		id: "0002-03-1005",
		name: "Entity Get Current Health",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the current health of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			health: { name: "Health", type: "number" },
		},
		resolve(inputs) {
			return {
				health: inputs.entity.health.state.current,
			};
		},
	},
	{
		id: "1cad6484-6a33-4e24-8a83-e8078711e132",
		name: "Entity Get Direction",
		type: NODE_TYPE_ID.function.entity,
		description: "Gets the forward directional vector of the entity.",
		inputs: {
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			direction: { name: "Direction", type: "vector3", icon: "MapPin" },
		},
		resolve(inputs, ctx) {
			const result = new Vector3();

			const entity = getEntity(inputs, ctx, "entity");

			const direction = entity?.object3D.getWorldDirection(result) ?? result;

			return {
				direction,
			};
		},
	},
	{
		id: "c3217661-f78f-48cf-8330-4466e389151a",
		name: "Character Get Equipped Item",
		type: NODE_TYPE_ID.function.character,
		description: "Get the character's equipped item if it's holding one",
		inputs: {
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			item: { name: "Item", type: "entity" },
			itemType: {
				name: "Item Type",
				type: "string",
			},
		},
		resolve(inputs, ctx) {
			const equippedItem = (getEntity(inputs, ctx, "character") as Character)?.getEquippedItem();

			return {
				item: equippedItem,
				itemType: equippedItem?.def,
			};
		},
	},
	{
		id: "0002-03-2004",
		name: "Entity Set State",
		type: NODE_TYPE_ID.function.entity,
		description: "Sets the value of a given entities state",
		inputs: {
			exec: { type: "exec" },
			peer: { name: "Entity", type: "entity" },
			name: { name: "State", type: "string", control: "string" },
			value: { name: "Value", type: "any", structure: "any" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = getEntity(inputs, ctx, "peer");
			if (!inputs.name || !entity) {
				throw Error("[Entity Set State]: No state name or entity provided");
			}
			ctx.world.rete.state.entity[entity.entityID] ??= {};
			ctx.world.rete.state.entity[entity.entityID][inputs.name] = inputs.value;
			trigger.onEntityStateChange(ctx.world, entity, inputs.name, inputs.value);
		},
	},
	{
		id: "0002-03-2008",
		name: "Entity Set Current Health",
		type: NODE_TYPE_ID.function.character,
		description: "Set current health of entity, even if it's invincible.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			health: {
				name: "Health",
				type: "number",
				control: "number",
				config: { defaultValue: 100 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			inputs.entity.setHealth(inputs.health, true);
		},
	},
	// Entity - Actions
	{
		id: "0002-03-0001",
		name: "Entity Kill",
		type: NODE_TYPE_ID.function.entity,
		description: "Insta-kills entity, even if it's invincible.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			inputs.entity.kill();
		},
	},
	{
		id: "0002-03-0002",
		name: "Respawn Character",
		type: NODE_TYPE_ID.function.entity,
		description: "Respawns a character",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Character", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			if (!inputs.entity || !entityIsCharacter(inputs.entity)) {
				logger.warn("Attempted to respawn an entity that is not a character, ignoring...");
			}

			inputs.entity.respawn(false);
		},
	},
	{
		id: "0002-03-0003",
		name: "Entity Remove",
		type: NODE_TYPE_ID.function.entity,
		description: "Removes entity from the world",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const e = inputs.entity;
			if (e === undefined) return;
			if (ctx.world.playerToPeer.has(e))
				throw Error("[Entity Remove] Can't remove a peer's current active player");

			e.dispose();
		},
	},
	{
		id: "0002-03-0004",
		name: "Entity Add Health",
		type: NODE_TYPE_ID.function.entity,
		description: "Add health to entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			amount: { name: "Amount", type: "number", control: "number" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			(getEntity(inputs, ctx, "entity") as Character)?.addHealth(inputs.amount);
		},
	},
	{
		id: "0002-03-2005",
		name: "Entity Subtract Health",
		type: NODE_TYPE_ID.function.entity,
		description: "Subtract health from entity",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			amount: { name: "Amount", type: "number", control: "number" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			(getEntity(inputs, ctx, "entity") as Character)?.addHealth(-inputs.amount);
		},
	},
	// NPC - Getters
	{
		id: "0002-04-1001",
		name: "NPC Find By Nameplate",
		type: NODE_TYPE_ID.function.npc,
		description:
			"Returns NPC object by name. Returns first NPC if there are multiple NPC's with same name.",
		inputs: {
			name: { name: "Name", type: "string", control: "string" },
		},
		outputs: {
			npc: { name: "NPC", type: "entity" },
		},
		resolve(inputs, ctx) {
			const npc =
				ctx.world.entities.find((entity) => {
					if (!entity.type.def.isNPC) return false;
					if (!entity.nameplate) return false;

					return entity.nameplate.def.nameplate.getText() === inputs.name;
				}) ?? null;

			return { npc };
		},
	},
	// NPC - Setters
	{
		id: "0002-04-2002",
		name: "NPC Set Behavior",
		type: NODE_TYPE_ID.function.npc,
		description: "Sets behavior of NPC",
		inputs: {
			exec: { type: "exec" },
			npc: { name: "NPC", type: "entity" },
			target: { name: "Target", type: "entity" },
			shootHitPercentage: {
				name: "Shoot Hit Percentage",
				type: "number",
				control: "number",
			},
			behavior: {
				name: "Behavior",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: [
						{ value: "idle", label: "idle" },
						{ value: "wander", label: "wander" },
						{ value: "attack", label: "attack" },
						{ value: "look", label: "look" },
						{ value: "approach", label: "approach" },
						{ value: "follow", label: "follow" },
						{ value: "shootat", label: "shootat" },
					],
				},
			},
			attachItem: {
				name: "Attach Item",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: [
						{ value: false, label: "No Attachment" },
						{ value: ItemNovaGun.name, label: "Nova Gun" },
						{ value: ItemNovaRifle.name, label: "Nova Rifle" },
						{ value: ItemNovAssaultRifle.name, label: "NovAssault Rifle" },
						{ value: ItemSpade.name, label: "Spade" },
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			if (!inputs.npc) return;

			const target = getEntity(inputs, ctx, "target") || null;

			inputs.npc.aiSetShootHitPercentage(inputs.shootHitPercentage / 100);
			inputs.npc.aiSetBehavior(inputs.behavior, target, inputs.attachItem);
		},
	},
	{
		id: "0002-04-2003",
		name: "NPC Set Fire Rate",
		type: NODE_TYPE_ID.function.npc,
		description: "Sets weapon fire rate of NPC",
		inputs: {
			exec: { type: "exec" },
			npc: { name: "NPC", type: "entity" },
			fireRate: {
				name: "Fire Rate %",
				type: "number",
				control: "number",
				config: {
					defaultValue: 100,
					validation: [
						{
							condition: (value: any) => value > 100,
							message: () => "Input value must be less than 100",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			getNPC(inputs, ctx, "npc")?.aiSetFireRate(inputs.fireRate / 100);
		},
	},
	{
		id: "0002-04-2005",
		name: "NPC Set Shoot Hit Percentage",
		type: NODE_TYPE_ID.function.npc,
		description: "Sets NPC Shoot Hit Percentage",
		inputs: {
			exec: { type: "exec" },
			npc: { name: "NPC", type: "entity" },
			shootHitPercentage: {
				name: "Shoot Hit %",
				type: "number",
				control: "number",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			getNPC(inputs, ctx, "npc")?.aiSetShootHitPercentage(inputs.shootHitPercentage);
		},
	},
	// NPC - Actions
	{
		id: "0002-04-0001",
		name: "NPC Move To",
		type: NODE_TYPE_ID.function.npc,
		description: "Make NPC walk to position and stop",
		inputs: {
			exec: { type: "exec" },
			npc: { name: "NPC", type: "entity" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const npc = getNPC(inputs, ctx, "npc");
			if (!npc || !inputs.position) return;

			npc.aiTargetPosition = inputs.position;
			npc.aiBehavior = "moveto";
		},
	},
	// Character - Actions
	{
		id: "0002-05-0001",
		name: "Character Jump",
		type: NODE_TYPE_ID.function.character,
		predictable: true,
		description:
			"Makes a character jump. If force is true, then character can jump while not on the ground. Velocity can't be higher than terminal velocity.",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			velocity: {
				name: "Velocity",
				type: "number",
				control: "number",
				config: { defaultValue: 12 },
			},
			force: {
				name: "Force",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: false },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx, _id, _scope, closure) {
			const velocity = Number.parseFloat(inputs.velocity);

			if (!Number.isFinite(velocity)) return;

			// desync if non-predictable
			(getEntity(inputs, ctx, "character") as Character)?.jump(
				velocity,
				inputs.force,
				!closure.predictable,
			);
		},
	},
	{
		id: "0002-05-0002",
		name: "Character Equip Item",
		type: NODE_TYPE_ID.function.character,
		description: "Creates an item entity and mounts it to a character's hand",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			def: {
				name: "Def",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: (ctx: { world: World }) => getItemSelectOptions(ctx.world),
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const char = getEntity(inputs, ctx, "character");
			if (char !== undefined) (char as Character).equipItem(inputs.def);
		},
	},
	{
		id: "0002-05-0005",
		name: "Character Unequip Item",
		type: NODE_TYPE_ID.function.character,
		description: "Unequips an item from a character's hand",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const char = getEntity(inputs, ctx, "character");
			if (char !== undefined) (char as Character).unequipItem();
		},
	},
	{
		id: "0002-05-0003",
		name: "Character Play Emote Animation",
		type: NODE_TYPE_ID.function.character,
		description: "Plays an emote animation from the CharacterDefault mod",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
			animation: {
				name: "Animation",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						//general
						{ value: "JMGO_Unarmed_Emotes_AirPunch", label: "Fist Pump" },
						{ value: "JMGO_Unarmed_Emotes_RaisedArms", label: "Raised Arms" },
						{ value: "JMGO_Unarmed_Emotes_TalkingV1", label: "Talk" },
						{ value: "JMGO_Unarmed_Emotes_Thinking", label: "Think" },
						{ value: "JMGO_Unarmed_Emotes_ThumbsUp", label: "Thumbs Up" },
						{ value: "JMGO_Unarmed_Emotes_Waving", label: "Wave" },
						{ value: "JMGO_Unarmed_Emotes_Beckoning", label: "Beckon" },
						{ value: "JMGO_Unarmed_Emotes_Clapping", label: "Clap" },
						{ value: "JMGO_Unarmed_Emotes_Measuring", label: "Measure" },
						{ value: "JMGO_Unarmed_Emotes_Salut", label: "Salute" },
						{ value: "JMGO_Unarmed_Emotes_Showing", label: "Show" },
						{ value: "JMGO_Unarmed_Emotes_Freezing", label: "Freeze" },
						{ value: "JMGO_Unarmed_Emotes_JumpingJacks", label: "Jumping Jacks" },
						{ value: "JMGO_Unarmed_Emotes_Lying", label: "Lay Down" },
						{ value: "JMGO_Unarmed_Emotes_PushUp", label: "Push Ups" },
						{ value: "JMGO_Unarmed_Emotes_Sit", label: "Sit" },
						{ value: "JMGO_Unarmed_Emotes_Stretching", label: "Stretch" },

						//dances
						{ value: "JMGO_Unarmed_Emotes_DanceV1", label: "Dance" },
						{ value: "JMGO_Unarmed_Emotes_DjDance", label: "DJ Dance" },
						{ value: "JMGO_Unarmed_Emotes_HopakDance", label: "Hopak Dance" },
						{ value: "JMGO_Unarmed_Emotes_CowboyDance", label: "Cowgirl Dance" },
						{ value: "JMGO_Unarmed_Emotes_LegsDance", label: "Mango Dance" },
						{ value: "JMGO_Unarmed_Emotes_MistyFlying", label: "Misty - Flying" },
						{
							value: "JMGO_Unarmed_Emotes_MistyRaisedArms",
							label: "Misty - Raised Arms",
						},
						{ value: "JMGO_Unarmed_Emotes_MistyEnergy", label: "Misty - Energy" },

						//gameplay
						{ value: "JMGO_Unarmed_Death", label: "Death (Start)" },
						{ value: "JMGO_Unarmed_Death_Idle", label: "Death (Idle)" },

						//flying
						{ value: "JMGO_Unarmed_Fly_Idle", label: "Fly (Idle)" },
						{ value: "JMGO_Unarmed_Fly_Forward", label: "Fly Forward" },
						{ value: "JMGO_Unarmed_Fly_Backward", label: "Fly Backward" },
						{ value: "JMGO_Unarmed_Fly_Left", label: "Fly Left" },
						{ value: "JMGO_Unarmed_Fly_Right", label: "Fly Right" },
						{ value: "JMGO_Unarmed_Fly_Down", label: "Fly Down" },
						{ value: "JMGO_Unarmed_Fly_Up", label: "Fly Up" },
						{ value: "JMGO_Unarmed_Fly_Sprint", label: "Fly Sprint" },
					],
				},
			},
			loop: { name: "Loop", type: "boolean", control: "boolean" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const character = getEntity(inputs, ctx, "character");
			if (!character) return;

			(character as Character).setEmote(inputs.animation, inputs.loop);
		},
	},
	{
		id: "0002-05-0004",
		name: "Character Stop Emote Animation",
		type: NODE_TYPE_ID.function.character,
		description: "Stops any emote animation currently playing",
		inputs: {
			exec: { type: "exec" },
			character: { name: "Character", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const character = getEntity(inputs, ctx, "character");
			if (!character) return;

			(character as Character).setEmote(null);
		},
	},
	// Player - Getters
	{
		id: "0002-09-1002",
		name: "World Get Peers List",
		type: NODE_TYPE_ID.function.player,
		description: "Returns a list of all peers currently in the world",
		outputs: {
			peers: { name: "Peers", type: "entity", structure: "list" },
		},
		resolve(inputs, ctx) {
			return {
				peers: ctx.world.router.getPeers().map((peer) => ctx.world.peerToPlayer.get(peer)),
			};
		},
	},
	{
		id: "0002-09-1003",
		name: "World List All NPC's",
		type: NODE_TYPE_ID.function.player,
		description: "Get All NPC in the world",
		outputs: {
			npcList: { name: "NPC List", type: "entity", structure: "list" },
		},
		resolve(inputs, ctx) {
			const npcList = ctx.world.entities.filter((entity) => entity.type.def.isNPC);
			return { npcList };
		},
	},
	{
		id: "0002-09-1004",
		name: "World List All Items",
		type: NODE_TYPE_ID.function.player,
		description: "Get All Items in the world",
		outputs: {
			itemList: { name: "Item List", type: "entity", structure: "list" },
		},
		resolve(inputs, ctx) {
			const itemList = ctx.world.entities.filter(
				(entity) => !entity.type.def.isNPC && !entity.type.def.isPlayer,
			);
			return { itemList };
		},
	},
	{
		id: "0002-09-1007",
		name: "Peer Get Username",
		type: NODE_TYPE_ID.function.player,
		description: "Returns the username of the peer",
		inputs: {
			peer: { name: "Peer", type: "entity" },
		},
		outputs: {
			username: { name: "Username", type: "string" },
		},
		resolve(inputs, ctx) {
			const player = getPlayer(inputs, ctx, "peer");

			if (!player) {
				throw Error(
					"Missing player entity. You might have passed nothing or a different entity type.",
				);
			}

			const peer = ctx.world.playerToPeer.get(player);
			if (!peer) {
				throw Error("No peer found");
			}

			return { username: getPeerMetadata(peer).username };
		},
	},
	// Player - Setters
	// Player - Actions
	// Block - Getters
	{
		id: "0002-06-1006",
		name: "Block Get Shape",
		type: NODE_TYPE_ID.function.block,
		description:
			"Gets the shape bitmask of a block by position. 0 indicates air, 15 indicates cube, and anything else is a sculpted block",
		predictable: true,
		inputs: {
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			shape: { name: "Type", type: "number" },
		},
		resolve(inputs, ctx) {
			const position = tmpVec.copy(inputs.position ?? V0).floor();
			return { shape: ctx.world.scene.getShape(position) };
		},
	},
	{
		id: "0002-06-1005",
		name: "Block Get Type",
		type: NODE_TYPE_ID.function.block,
		description: "Gets the type of a block by position.",
		predictable: true,
		inputs: {
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			type: { name: "Type", type: "string" },
		},
		resolve(inputs, ctx) {
			const position = tmpVec.copy(inputs.position ?? V0).floor();
			let type;
			const shape = ctx.world.scene.getShape(position);
			if (shape === BLOCK_AIR) type = "bb.block.air";
			else type = ctx.world.scene.getType(position);

			return { type };
		},
	},
	// Block - Actions
	{
		id: "0002-06-0002",
		name: "Block Respawn By Position",
		type: NODE_TYPE_ID.function.block,
		description: "Trigger's a block's spawn events",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const position = tmpVec.copy(inputs.position ?? V0).floor();
			ctx.world.scene.respawnBlock(position.x, position.y, position.z, ctx.world.scene);
		},
	},
	{
		id: "0002-06-0003",
		name: "Block Respawn By Name",
		type: NODE_TYPE_ID.function.block,
		description: "Trigger's a block group's spawn events",
		inputs: {
			exec: { type: "exec" },
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute: (inputs, ctx) => {
			BlockGroupsRouter.respawn(ctx.world.blockGroups, ctx.world.scene, inputs.group);
		},
	},
	{
		id: "0002-06-0004",
		name: "Blocks Move By Offset",
		type: NODE_TYPE_ID.function.block,
		description: "Move a group of blocks by some amount",
		inputs: {
			exec: { type: "exec" },
			group: addSelectGroup(),
			amount: {
				name: "Amount",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			triggerEvents: {
				name: "Trigger Events",
				type: "boolean",
				control: "boolean",
				config: {
					label: "Trigger build/break/sculpt events",
					defaultValue: true,
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute: (inputs, ctx) => {
			BlockGroups.moveBlocks(
				ctx.world.blockGroups,
				ctx.world.scene,
				inputs.group,
				inputs.amount,
				inputs.triggerEvents,
			);
		},
	},
	// Game - Getters
	// Game - Setters
	{
		id: "0002-07-2012",
		name: "World Set Spawn Position",
		type: NODE_TYPE_ID.function.game,
		description: "Set a the global spawn position for the world",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			rotation: {
				name: "Rotation",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const position = inputs.position;
			const rotation = inputs.rotation * DEGRAD;

			SpawnServer.setGlobalSpawn(
				ctx.world.server.spawn,
				ctx.world,
				position.x,
				position.y,
				position.z,
				rotation,
			);
		},
	},
	{
		id: "0002-07-2013",
		name: "Character Set Respawn Spawn Point",
		type: NODE_TYPE_ID.function.game,
		description: "Set the spawn position for the target player.",
		inputs: {
			exec: { type: "exec" },
			player: { name: "Player", type: "entity" },
			rotation: {
				name: "Rotation",
				type: "number",
				control: "number",
				config: { defaultValue: 0 },
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs) {
			const player = inputs.player;
			const rotation = (inputs.rotation ?? 0) * DEGRAD;
			const position = inputs.position;

			if (!player) return;

			if (!position) return;

			const newSpawnPos = new Vector3(position.x + 0.5, position.y + 1, position.z + 0.5);

			player.setSpawn(newSpawnPos, rotation);
		},
	},
	// Game - Actions
	{
		id: "0002-07-0005",
		name: "Quest Create Objective",
		type: NODE_TYPE_ID.function.game,
		description: "Assigns a new objective",
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "string",
				config: { defaultValue: "objective1" },
			},
			uiType: {
				name: "UI Type",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [
						{ label: "Simple", value: ObjectiveType.SIMPLE },
						{ label: "Quest Objectives", value: ObjectiveType.QUEST },
					],
				},
			},
			message: { name: "Message", type: "string", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			QuestServer.broadcastCreateObjective(ctx.world.quest, ctx.world, {
				name: inputs.name,
				message: inputs.message,
				uiType: inputs.uiType,
			});
		},
	},
	{
		id: "0002-07-0007",
		name: "Quest Complete Objective",
		type: NODE_TYPE_ID.function.game,
		description: "Removes a previously assigned objective",
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "string",
				config: { defaultValue: "objective1" },
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			QuestServer.broadcastCompleteObjective(ctx.world.quest, ctx.world, inputs.name);
		},
	},
	{
		id: "0002-07-0008",
		name: "Quest Complete Quest",
		type: NODE_TYPE_ID.function.game,
		description: "Completes all open, unfinished objectives in the quest",
		inputs: {
			exec: { type: "exec" },
			message: { name: "Message", type: "string", control: "string" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			QuestServer.broadcastCompleteAllObjectives(ctx.world.quest, ctx.world);
		},
	},
	{
		id: "0002-07-0009",
		name: "Quest Create Waypoint At Position",
		type: NODE_TYPE_ID.function.game,
		description: "Display an objective waypoint at a position",
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "string",
				config: { defaultValue: "objective1" },
			},
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const x = (inputs.position?.x ?? 0) + 0.5;
			const y = (inputs.position?.y ?? 0) + 0.5;
			const z = (inputs.position?.z ?? 0) + 0.5;

			QuestServer.broadcastCreateWaypoint(
				ctx.world.quest,
				ctx.world,
				inputs.name,
				null,
				new Vector3(x, y, z),
			);
		},
	},
	{
		id: "0002-07-0010",
		name: "Quest Create Waypoint At Entity",
		type: NODE_TYPE_ID.function.game,
		description: "Display an objective waypoint at an entity",
		inputs: {
			exec: { type: "exec" },
			name: {
				name: "Name",
				type: "string",
				control: "string",
				config: { defaultValue: "objective1" },
			},
			entity: { name: "Entity", type: "entity" },
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const entity = inputs.entity;
			if (!entity) return;

			const position = new Vector3();
			const height = entity.getAABB(new Box3()).getSize(tmpVec).y;

			if (entity.type.def.isCharacter) position.set(0, height * 1.5, 0);
			else position.set(0, height, 0);

			QuestServer.broadcastCreateWaypoint(
				ctx.world.quest,
				ctx.world,
				inputs.name,
				entity.entityID,
				position,
			);
		},
	},

	// Sounds - Getters
	// Sounds - Setters
	// Sounds - Actions
	{
		id: "0002-08-0004",
		name: "Sound 2D Play",
		type: NODE_TYPE_ID.function.sound,
		description: "Plays audio",
		inputs: {
			exec: { type: "exec" },
			audio: {
				name: "Audio",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => getAudioOptions(),
				},
			},
			volume: {
				name: "Volume",
				type: "number",
				control: "number",
				config: {
					defaultValue: 1,
					validation: [
						{
							condition: (value: any) => value < 0 || value > 3,
							message: () => "Volume must be between 0 to 3",
						},
					],
				},
			},
			loop: {
				name: "Loop",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: false },
			},
			fadeInDuration: {
				name: "Fade In Duration",
				type: "number",
				control: "number",
				config: {
					defaultValue: 0,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Duration cannot be negative",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			sound: { name: "Sound", type: "sound" },
		},
		/**
		 * @function
		 * @param {{audio:string; loop?:boolean; volume?:number; fadeInDuration?:number}} inputs
		 * @param {{world:import('base/world/World').World}} ctx
		 * @param {string} nodeID
		 * @param {{}} scope
		 */
		execute(inputs, ctx, nodeID, scope) {
			if (!inputs.audio) return;

			const audio = BB.world.content.state.audios.get(inputs.audio);

			if (!audio) return;

			const resource = audio.resource;

			if (!resource) return;

			const { soundId } = ctx.world.sfxManager.serverAdd("all", {
				type: "play",
				asset: resource.pk,
				loop: inputs.loop ?? false,
				volume: inputs.volume ?? 0.5,
				fadeInDuration: inputs.fadeInDuration ?? 0,
				duration: resource.duration,
			});

			scope[nodeID] = soundId;
		},
		resolve(_inputs, _ctx, nodeID, scope) {
			return {
				sound: scope[nodeID],
			};
		},
	},
	{
		id: "0002-08-0002",
		name: "Sound 3D Play At Position",
		type: NODE_TYPE_ID.function.sound,
		description: "Plays an audio at a given position",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			audio: {
				name: "Audio",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => getAudioOptions(),
				},
			},
			refDistance: {
				name: "Ref Distance",
				type: "number",
				control: "number",
				config: {
					defaultValue: 3,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Distance must be a positive value.",
						},
					],
				},
			},
			volume: {
				name: "Volume",
				type: "number",
				control: "number",
				config: {
					defaultValue: 1,
					validation: [
						{
							condition: (value: any) => value < 0 || value > 3,
							message: () => "Volume must be between 0 to 3",
						},
					],
				},
			},
			loop: {
				name: "Loop",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: false },
			},
			fadeInDuration: {
				name: "Fade In Duration",
				type: "number",
				control: "number",
				config: {
					defaultValue: 0,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Duration cannot be negative",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			sound: { name: "Sound", type: "sound" },
		},

		execute(inputs, ctx, nodeID, scope) {
			if (!inputs.audio) return;

			const audio = BB.world.content.state.audios.get(inputs.audio);

			if (!audio) return;

			const resource = audio.resource;

			if (!resource) return;

			const { x, y, z } = inputs.position;

			const { soundId } = ctx.world.sfxManager.serverAdd("all", {
				type: "playAtPos",
				asset: resource.pk,
				x,
				y,
				z,
				refDistance: inputs.refDistance ?? 0.5,
				loop: inputs.loop ?? false,
				volume: inputs.volume ?? 0.5,
				fadeInDuration: inputs.fadeInDuration ?? 0,
				duration: resource.duration,
			});

			scope[nodeID] = soundId;
		},
		resolve(_inputs, _ctx, nodeID, scope) {
			return {
				sound: scope[nodeID],
			};
		},
	},
	{
		id: "0002-08-0003",
		name: "Sound 3D Play At Entity",
		type: NODE_TYPE_ID.function.sound,
		description: "Plays an audio attached to an entity.",
		inputs: {
			exec: { type: "exec" },
			entity: { name: "Entity", type: "entity" },
			audio: {
				name: "Audio",
				type: "string",
				control: "select",
				config: {
					autoSortOptions: () => getAudioOptions(),
				},
			},
			refDistance: {
				name: "Ref Distance",
				type: "number",
				control: "number",
				config: {
					defaultValue: 3,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Distance must be a positive value.",
						},
					],
				},
			},
			volume: {
				name: "Volume",
				type: "number",
				control: "number",
				config: {
					defaultValue: 1,
					validation: [
						{
							condition: (value: any) => value < 0 || value > 3,
							message: () => "Volume must be between 0 to 3",
						},
					],
				},
			},
			loop: {
				name: "Loop",
				type: "boolean",
				control: "boolean",
				config: { defaultValue: false },
			},
			fadeInDuration: {
				name: "Fade In Duration",
				type: "number",
				control: "number",
				config: {
					defaultValue: 0,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Duration cannot be negative",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
			sound: { name: "Sound", type: "sound" },
		},

		execute(inputs, ctx, nodeID, scope) {
			if (!inputs.audio || !inputs.entity) return;

			const audio = BB.world.content.state.audios.get(inputs.audio);

			if (!audio) return;

			const resource = audio.resource;

			if (!resource) return;

			const { soundId } = ctx.world.sfxManager.serverAdd("all", {
				type: "playAtObj",
				asset: resource.pk,
				obj: inputs.entity.entityID,
				refDistance: inputs.refDistance ?? 0.5,
				loop: inputs.loop ?? false,
				volume: inputs.volume ?? 0.5,
				fadeInDuration: inputs.fadeInDuration ?? 0,
				duration: resource.duration,
			});

			scope[nodeID] = soundId;
		},
		resolve(_inputs, _ctx, nodeID, scope) {
			return {
				sound: scope[nodeID],
			};
		},
	},
	{
		id: "0002-08-0005",
		name: "Sound Stop",
		type: NODE_TYPE_ID.function.sound,
		description: "Stops audio",
		inputs: {
			exec: { type: "exec" },
			sound: {
				name: "Sound",
				type: "sound",
			},
			fadeOutDuration: {
				name: "Fade Out Duration",
				type: "number",
				control: "number",
				config: {
					defaultValue: 0,
					validation: [
						{
							condition: (value: any) => value < 0,
							message: () => "Fade out duration must be a positive value.",
						},
					],
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},

		execute(inputs, ctx) {
			if (inputs.sound === undefined) return;

			ctx.world.sfxManager.serverRemove(inputs.sound, inputs.fadeOutDuration);
		},
	},
	{
		id: NODE.OnSoundEnd,
		name: "On Sound End",
		type: NODE_TYPE_ID.entryPointTrigger.sound,
		description: "Triggered when a sound ends",
		outputs: {
			exec: { type: "exec" },
			sound: { name: "Sound", type: "sound" },
			soundName: { name: "Sound Name", type: "string" },
		},

		resolve(_, ctx) {
			return { sound: ctx.info.soundId, soundName: ctx.info.soundName };
		},
	},
	// Send Chat Node
	{
		id: "0002-07-0011",
		name: "Chat Send",
		type: NODE_TYPE_ID.function.game,
		description: `Sends a chat message`,
		inputs: {
			exec: { type: "exec" },
			peers: { name: "Peer(s)", type: "entity", structure: "list" },
			message: { name: "Message", type: "any", structure: "any", control: "textarea" },
			textColor: {
				name: "Text Color",
				type: "any",
				control: "color",
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			const { peers, message, textColor } = inputs;
			const recip = entitiesToPeers(peers, ctx.world);
			broadcastPrivateChat(ctx.world, recip, interpreterAnyToString(message), textColor);
		},
	},
];
