import { Vector3 } from "three";

const tmpVec = new Vector3();

//shamelessly plagiarized from https://github.com/EngineHub/WorldEdit/blob/97a4df96c3b9bb0068318dd17ac10e3631a5d541/worldedit-core/src/main/java/com/sk89q/worldedit/EditSession.java#L1762
export function* bulkSphere(pos, radius, filled, out = new Vector3()) {
	pos = pos.clone().round();
	radius = radius.clone().addScalar(0.5);

	const invRadiusX = 1 / radius.x;
	const invRadiusY = 1 / radius.y;
	const invRadiusZ = 1 / radius.z;

	const ceilRadiusX = Math.ceil(radius.x);
	const ceilRadiusY = Math.ceil(radius.y);
	const ceilRadiusZ = Math.ceil(radius.z);

	let nextXn = 0;

	forX: for (let x = 0; x <= ceilRadiusX; x++) {
		const xn = nextXn;
		nextXn = (x + 1) * invRadiusX;
		let nextYn = 0;

		forY: for (let y = 0; y <= ceilRadiusY; y++) {
			const yn = nextYn;
			nextYn = (y + 1) * invRadiusY;
			let nextZn = 0;

			forZ: for (let z = 0; z <= ceilRadiusZ; z++) {
				const zn = nextZn;
				nextZn = (z + 1) * invRadiusZ;

				const distanceSq = tmpVec.set(xn, yn, zn).lengthSq();
				if (distanceSq > 1) {
					if (z === 0) {
						if (y === 0) break forX;

						break forY;
					}

					break forZ;
				}

				if (!filled)
					if (
						tmpVec.set(nextXn, yn, zn).lengthSq() <= 1 &&
						tmpVec.set(xn, nextYn, zn).lengthSq() <= 1 &&
						tmpVec.set(xn, yn, nextZn).lengthSq() <= 1
					)
						continue;

				yield out.set(pos.x + x, pos.y + y, pos.z + z);
				yield out.set(pos.x - x, pos.y + y, pos.z + z);
				yield out.set(pos.x + x, pos.y - y, pos.z + z);
				yield out.set(pos.x + x, pos.y + y, pos.z - z);
				yield out.set(pos.x - x, pos.y - y, pos.z + z);
				yield out.set(pos.x + x, pos.y - y, pos.z - z);
				yield out.set(pos.x - x, pos.y + y, pos.z - z);
				yield out.set(pos.x - x, pos.y - y, pos.z - z);
			}
		}
	}
}

export function* bulkBox(box, filled, out = new Vector3()) {
	for (let y = box.min.y; y <= box.max.y; y++)
		for (let z = box.min.z; z <= box.max.z; z++)
			for (let x = box.min.x; x <= box.max.x; x++)
				if (
					filled ||
					x === box.min.x ||
					x === box.max.x ||
					y === box.min.y ||
					y === box.max.y ||
					z === box.min.z ||
					z === box.max.z
				)
					yield out.set(x, y, z);
}
