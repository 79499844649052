import * as SkyBase from "base/world/fx/Sky.js";
import * as SkyServer from "server/world/fx/Sky.js";
import * as SkyClient from "client/world/fx/Sky.js";
import { netState } from "router/Parallelogram";

/**
 * @param {import("base/world/World").World} world
 * @param {import("@jamango/content-client/lib/types/engine.ts").IEngineWorldData["sky"]} [options]
 */
export function init(world, options) {
	const sky = SkyBase.init(options);
	if (netState.isHost) SkyServer.init(sky, world, options);
	if (netState.isClient) SkyClient.init(sky, world, options);
	return sky;
}

/**
 * @param {import("base/world/World").World} world
 * @param {number} stepDeltaTime
 */
export function update(world, stepDeltaTime) {
	if (netState.isHost) SkyServer.update(world, stepDeltaTime);
	if (netState.isClient) SkyClient.update(world, stepDeltaTime); //technically this is the step delta not the frame delta that the method asks for
}
