import type { SelectOptionsContext } from "@jamango/content-client";
import { NODE_TYPE_ID } from "base/rete/Constants";
import { addSelectGroup } from "base/rete/NodeSharedUtil";
import { node } from "base/rete/Types";
import * as BlockGroups from "base/world/block/BlockGroups";
import { BLOCK_AIR } from "base/world/block/Util";
import { Vector3 } from "three";

const type = NODE_TYPE_ID.function.block;

export const BLOCK_GROUP_NODES = [
	node({
		id: "38682803-af00-45b6-93c7-dea1151b8b9c",
		name: "Block Group Get By Name",
		type,
		description: "Get a block group by name",
		inputs: {
			name: { name: "Name", type: "string", control: "string" },
		},
		outputs: {
			group: { name: "Group", type: "blockGroup" },
		},
		resolve(inputs, ctx) {
			const group = BlockGroups.getByName(ctx.world.blockGroups, inputs.name);

			const groupId = group?.id ?? null;

			return { group: groupId! };
		},
	}),
	node({
		id: "c569c7cc-0a62-491e-94d1-e5287f284eaa",
		name: "Block Group Get Name",
		type,
		description: "Gets a block group's name",
		inputs: {
			group: { name: "Group", type: "blockGroup" },
		},
		outputs: {
			name: { name: "Group Name", type: "string" },
		},
		resolve(inputs, ctx) {
			const group = BlockGroups.getById(ctx.world.blockGroups, inputs.group);

			const groupName = group?.name ?? null;

			return { name: groupName! };
		},
	}),
	node({
		id: "0002-02-2002",
		name: "Block Group Set Blocks",
		type: NODE_TYPE_ID.function.world,
		description: "Sets every block in a group",
		inputs: {
			exec: { type: "exec" },
			group: addSelectGroup(),
			shape: {
				name: "Shape",
				type: "number",
				control: "select",
				config: {
					explicitSortOptions: [
						{ label: "(No change)", value: -1 },
						{ label: "Cube", value: 15 },
						{ label: "Air", value: 0 },
					],
				},
			},
			type: {
				name: "Type",
				type: "string",
				control: "select",
				config: {
					explicitSortOptions: [{ label: "(No change)", value: -1 }],
					autoSortOptions: (ctx: SelectOptionsContext) =>
						Array.from(ctx.world.blockTypeRegistry.blockNameToType.values()).map((def) => ({
							label: def.display!,
							value: def.name,
						})),
				},
			},
			triggerEvents: {
				name: "Trigger Events",
				type: "boolean",
				control: "boolean",
				config: {
					defaultValue: true,
				},
			},
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			BlockGroups.setBlocks(
				ctx.world.blockGroups,
				ctx.world.scene,
				inputs.group,
				inputs.shape ?? BLOCK_AIR,
				inputs.type,
				!!inputs.triggerEvents,
			);
		},
	}),
	// Block - Setters
	node({
		id: "0002-06-2001",
		name: "Block Add Block Group",
		type,
		description: "Adds a block to a group",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			BlockGroups.addBlock(
				ctx.world.blockGroups,
				inputs.group,
				inputs.position.x,
				inputs.position.y,
				inputs.position.z,
			);
		},
	}),
	node({
		id: "0002-06-2002",
		name: "Block Group Remove Block",
		type,
		description: "Removes a block from a group",
		inputs: {
			exec: { type: "exec" },
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
			group: addSelectGroup(),
		},
		outputs: {
			exec: { type: "exec" },
		},
		execute(inputs, ctx) {
			BlockGroups.removeBlock(
				ctx.world.blockGroups,
				inputs.group,
				inputs.position.x,
				inputs.position.y,
				inputs.position.z,
			);
		},
	}),
	node({
		id: "e9f8g7h6-i5j4-k3l2-m1n0-o9p8q7r6s5t4",
		name: "Block Group List Blocks",
		type,
		description: "Get All Blocks in the group",
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			blocksList: { name: "Blocks List", type: "vector3", structure: "list" },
		},
		resolve(inputs, ctx) {
			const blocksList = [];
			const group = ctx.world.blockGroups.groups.get(inputs.group);
			if (group) {
				const curBlock = new Vector3();
				for (const _ of group.blocks.iterate(curBlock)) blocksList.push(curBlock.clone());
			}

			return { blocksList };
		},
	}),
	node({
		id: "0002-06-1002",
		name: "Block Group Get Spawn Position",
		type,
		description: "Gets the spawn position of a block group by its name.",
		predictable: true,
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			position: { name: "Position", type: "vector3", icon: "MapPin" },
		},
		resolve(inputs, ctx) {
			return {
				position: BlockGroups.getSpawnPosition(ctx.world.blockGroups, inputs.group, new Vector3()),
			};
		},
	}),
	node({
		id: "0002-06-1003",
		name: "Block Group Get Center Position",
		type,
		description: "Gets the center position of a block group by its name.",
		predictable: true,
		inputs: {
			group: addSelectGroup(),
		},
		outputs: {
			position: { name: "Position", type: "vector3", icon: "MapPin" },
		},
		resolve(inputs, ctx) {
			return {
				position: BlockGroups.getCenterPosition(ctx.world.blockGroups, inputs.group, new Vector3()),
			};
		},
	}),
	node({
		id: "0002-06-1004",
		name: "Block Get Block Groups",
		type,
		description: "Gets a list of block groups at a position.",
		predictable: true,
		inputs: {
			position: {
				name: "Position",
				type: "vector3",
				control: "vector3",
				icon: "MapPin",
			},
		},
		outputs: {
			// todo: group type
			groups: { name: "Block Groups", type: "blockGroup", structure: "list" },
		},
		resolve: (inputs, ctx) => {
			const groups =
				BlockGroups.getIdsAtPosition(
					ctx.world.blockGroups,
					inputs.position?.x ?? 0,
					inputs.position?.y ?? 0,
					inputs.position?.z ?? 0,
				) ?? [];

			return {
				groups,
			};
		},
	}),
];
