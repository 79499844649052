import { createRequestPromise } from "@jamango/helpers";
import type { NET_TURN_OFF, NET_SERVER, NET_TURN_ONLY } from "../share/Const";
import { NET_OFFLINE, NET_CLIENT, NET_AWAIT, NET_TURN_ON } from "../share/Const";
import type { Peer } from "./Peer";

type TURN_MODE = typeof NET_TURN_ON | typeof NET_TURN_OFF | typeof NET_TURN_ONLY;
type NET_MODE = typeof NET_AWAIT | typeof NET_CLIENT | typeof NET_SERVER | typeof NET_OFFLINE;

export class IBSBase {
	peerID?: string;
	accessToken?: string;

	readonly peers = new Map<string, Peer>();
	readonly listenerTemplate: Record<string, any> = {};

	readonly turnMode: TURN_MODE;

	readonly channels: Record<string, any>;
	readonly socketSettings: Record<string, any>;

	onfail: null | ((error?: string) => any);

	mode: NET_MODE;

	readonly initPromise = createRequestPromise<void>();

	/**
	 * OPT String version - App's version number or some other string, to allow client and server to do a compatibility check
	 * OPT Array iceServers - Array of RTCIceServer objects https://developer.mozilla.org/en-US/docs/Web/API/RTCIceServer
	 * OPT Object channels - Arguments to RTCPeerConnection.createDataChannel(). For each entry in the channels object, key is the label and value is the options. "negotiated" option will always be true. There is always a default channel called "standard" in addition to your custom ones.
	 * OPT Boolean turnMode [NET_TURN_ON] - One of the NET_TURN_X constants that will determine when TURN relay servers can be used as ICE candidates.
	 * OPT Object socketSettings - This is what's passed into createCommandSocket settings argument for each new RTCDataChannel that opens
	 * OPT Function onfail - Called either if the constructor fails to resolve, or if the client loses connection sometime after init() resolves. One arg tells you which connection: either string "master" or "slave". In case of master server failing, check the NetBase.masterError property for a reason (will not always be provided). net.mode will not be set to NET_OFFLINE until after this callback is called.
	 */
	constructor(
		readonly o: {
			version: string;
			worldShortCode?: string;
			worldVersionId?: string;
			serverID: string;
			iceServers: any[];
			channels: any;
			turnMode?: TURN_MODE;
			socketSettings?: Record<string, any>;
			maxPeers?: number;
			onfail?: (error?: string) => any;
		},
	) {
		this.channels = { standard: {}, ...o.channels };
		this.turnMode = o.turnMode ?? NET_TURN_ON;
		this.socketSettings = o.socketSettings ?? {};
		this.onfail = o.onfail ?? null;

		this.mode = NET_AWAIT;
	}

	rejectConstructor() {
		this.initPromise.reject(Error("IBS connection failed. Sorry bubs"));
	}

	/**
	 * Register a custom command listener callback. This function should be called immediately after the constructor resolves to avoid missing any messages.
	 * @param {number | string} f - Command name to register. "open" and "close" are special and can be set multiple times, for multiple open/close listeners.
	 * @param {(this: any, a: Exclude<any, undefined>) => void} listener - Callback function. One arg, for the (already parsed) JSON data that was received. Inside this function, use "this.peer" to refer to the peer whomst sent this command. You can also use "this.sendCommand(f, a)" to send a response back through the same channel and to the same peer.
	 */
	setCommandListener(f: number | string, listener: (this: any, a: Exclude<any, undefined>) => void) {
		//@ts-ignore
		const listeners = (this.listenerTemplate[f] ??= []);
		listeners.push(listener);

		if (f === "open" || f === "close") return;

		for (const peer of this.peers.values()) {
			for (const j in peer.channels) {
				//@ts-ignore
				peer.channels[j].setCommandListener(f, listener);
			}
		}
	}

	handleWaitList() {
		// call this function after setting up all command listeners for a peer.
		// this will call the listeners on data that has been received in the meanwhile
		for (const peer of this.peers.values()) {
			for (const j in peer.channels) {
				//@ts-ignore
				peer.channels[j].handleWaitList();
			}
		}
	}

	/**
	 * Send data to a peer. The data must be encodable to JSON.
	 * @param {string | number} f - Command name, associated with a command listener.
	 * @param {Exclude<any, undefined>} a - Data to send. Can be array, object literal, string, number, undefined, null, etc. Do not send functions, class instances, infinity, etc. Anything that can't be encoded to JSON.
	 * @param {Peer} [to] - Recipient. In client mode this is ignored because the server is always the recipient.
	 * @param {string} [channel] - Name of the channel to send through. Defaults to "standard". These are the same channel names passed into the constructor options.
	 */
	sendCommand(f: number | string, a: Exclude<any, undefined>, to?: Peer, channel?: string) {
		if (this.mode === NET_CLIENT) to = this.peers.get("server");

		to?.sendCommand(f, a, channel);
	}

	/**
	 * Send data to all peers. The data must be encodable to JSON. In client mode, this has the same effect as sendCommand; it will not automatically send to other clients.
	 * @param {string | number} f - Command name, associated with a command listener.
	 * @param {Exclude<any, undefined>} a - Data to send. Can be array, object literal, string, number, undefined, null, etc. Do not send functions, class instances, infinity, etc. Anything that can't be encoded to JSON.
	 * @param {Peer} [except] - Optionally exclude a peer. May potentially weaken their self esteem.
	 * @param {string} [channel] - Name of the channel to send through. Defaults to "standard". These are the same channel names passed into the constructor options.
	 */
	sendCommandToAll(f: string | number, a: Exclude<any, undefined>, except?: Peer, channel?: string) {
		for (const peer of this.peers.values()) {
			if (peer === except) continue;

			peer.sendCommand(f, a, channel);
		}
	}

	flushBatchDownToilet() {
		for (const peer of this.peers.values()) {
			for (const i in peer.channels) {
				//@ts-ignore
				peer.channels[i].flushBatchDownToilet();
			}
		}
	}

	/**
	 * Change peers' RTCDataChannel socket settings. Applies to all new and existing channels of all peers. Calls socket.updateSettings internally
	 */
	setPeerSocketSettings(settings: any) {
		for (const setting in settings) this.socketSettings[setting] = settings[setting];

		for (const peer of this.peers.values()) {
			for (const i in peer.channels) {
				//@ts-ignore
				peer.channels[i].updateSettings(settings);
			}
		}
	}

	/**
	 * Calling this before the constructor has resolved results in undefined behavior. May want to fix this someday
	 */
	close() {
		if (this.mode === NET_OFFLINE) return;

		this.onfail = null;

		for (const peer of this.peers.values()) peer.close();

		this.mode = NET_OFFLINE;
	}
}
