import type { Text3D, Text3DRenderOptions } from "client/world/fx/Text3D";
import type { Object3D } from "three";

type State = {
	renderOptions: Text3DRenderOptions;
	text: string;
	parent: Object3D;
	options: {
		faceCamera?: boolean;
		scl?: number;
		sizeAttenuation?: boolean;
		proximity?: number;
	};
};
export class Text3DComponent {
	readonly name = "text3D";

	state: State = {
		renderOptions: {},
		text: "",
		options: {},
		parent: null as any,
	};

	label?: Text3D;

	constructor(options: Pick<State, "parent" | "text">) {
		this.state.text = options.text;
		this.state.parent = options.parent;
	}
}
