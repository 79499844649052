import { netState } from "router/Parallelogram";

export class AuthorityComponent {
	/** @type {{ owner: boolean|string, public: number }} */
	state = {
		owner: netState.isHost,

		//publicly owned entities are simulated in physics by all players on their own
		//must set this in the subclass's constructor before calling postConstructor
		//probably going to remove level 1 later on when reworking ownership transfer
		//level 0: disabled, only one owner at a time
		//level 1: enabled, states don't transfer but everything else does (dispose, health, mount) (ie items)
		//level 2: enabled, nothing transfers, new players aren't told of existing level 2 entities, can only set in this in constructor (ie projectiles)
		public: 0,
	};

	constructor() {
		this.name = "authority";
	}
}
