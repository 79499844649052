import { netState } from "router/Parallelogram";
import { isNullish } from "@jamango/helpers";
import type { ParticleSystemEntity } from "base/world/entity/ParticleSystem";
import { BB } from "base/BB";
import { ParticleSystemState } from "base/world/entity/component/ParticleSystem";

const VISIBILITY_THRESHOLD_SQUARED = 100 ** 2;

export function particleSystemUpdate(entity: ParticleSystemEntity, dt: number) {
	if (!canUpdateSystem(entity)) return;

	const ps = entity.particleSystem;

	if (!ps.psPK) return;

	if (!ps.config) {
		const config = BB.world.content.state.particleSystems.getConfig(ps.psPK);

		if (!config) return;

		ps.config = { configId: ps.psPK, ...config };
	}

	if (!ps.config) return;

	const currentState = ps.currentState;
	const nextState = ps.nextState;

	switch (currentState) {
		case ParticleSystemState.Running:
		case ParticleSystemState.Restart:
			ps.time += dt;
			break;
	}

	// Remove non-continuous particle systems
	if (ps.time >= ps.config.duration) {
		if (ps.config.isContinuous) {
			ps.time = 0;
		} else {
			if (netState.isHost) {
				entity.dispose();
			}
			return;
		}
	}

	if (netState.isClient) {
		const old = ps.inCamProximity;
		updateProximity(entity);

		const hasActivePS = !!ps.obj;

		if (ps.inCamProximity) {
			if (!old) {
				updateVisual(currentState, nextState, entity);
			}
		} else if (hasActivePS) {
			entity.destroyVisualSimulation();
		}
	}

	if (!ps.dirty) return;

	ps.dirty = false;

	ps.currentState = nextState;

	if (currentState === nextState) return;

	switch (nextState) {
		case ParticleSystemState.Running: {
			const shouldResume = currentState === ParticleSystemState.Paused;
			if (!shouldResume) {
				ps.time = 0;
			}
			break;
		}
		case ParticleSystemState.Restart:
		case ParticleSystemState.Created:
		case ParticleSystemState.Stopped:
			ps.time = 0;
			break;
	}

	if (!netState.isClient || !ps.inCamProximity) return;

	updateVisual(currentState, nextState, entity);
}

function updateVisual(
	currentState: ParticleSystemState,
	nextState: ParticleSystemState,
	entity: ParticleSystemEntity,
) {
	const ps = entity.particleSystem;
	const hasActivePS = Boolean(ps.obj);

	switch (nextState) {
		case ParticleSystemState.Created:
			break;
		case ParticleSystemState.Running: {
			const shouldResume = currentState === ParticleSystemState.Paused;
			if (shouldResume && hasActivePS) {
				entity.playVisualSimulation();
			} else {
				entity.destroyVisualSimulation();
				entity.createVisualSimulation();
			}
			break;
		}
		case ParticleSystemState.Restart:
			if (!hasActivePS) {
				entity.createVisualSimulation();
			} else {
				entity.restartVisualSimulation();
			}
			break;
		case ParticleSystemState.Paused:
			if (hasActivePS) {
				entity.pauseVisualSimulation();
			}
			break;
		case ParticleSystemState.Stopped:
			if (hasActivePS) {
				entity.stopVisualSimulation();
			}
			break;
	}
}

function canUpdateSystem(entity: ParticleSystemEntity) {
	return !isNullish(entity.particleSystem);
}

function updateProximity(particles: ParticleSystemEntity) {
	const state = particles.particleSystem;
	const target = particles.world.client!.camera.target;

	if (target === particles) {
		state.camDistanceSquared = 0;
		return;
	}

	const parent = particles.world.getEntity(state.parentEntityId);
	if (parent) {
		state.camDistanceSquared = parent.position.distanceToSquared(target.position);
	} else {
		state.camDistanceSquared = 0;
		state.inCamProximity = false;
		return;
	}

	const threshold = VISIBILITY_THRESHOLD_SQUARED;
	const inProximity = state.camDistanceSquared < threshold;

	if (inProximity !== state.inCamProximity) {
		state.inCamProximity = inProximity;
	}
}
