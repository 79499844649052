import { netState } from "router/Parallelogram";

/**
 * Updates item UI elements.
 * @param {import("base/world/entity/PhysicalEntity").PhysicalEntity} entity
 * @param {number} stepDeltaTime
 */
export function itemUIUpdate(entity, stepDeltaTime) {
	if (!canUpdateSystem(entity)) return;

	const isCurrentItem = entity.isCurrentItem();

	if (entity.hitmarker?.def.hitmarker) {
		entity.hitmarker.state.hitmarkerTimer -= stepDeltaTime;

		if (entity.hitmarker.state.hitmarkerTimer > 0 && isCurrentItem) {
			entity.hitmarker.def.hitmarker.visible = true;

			const opacity = entity.hitmarker.state.hitmarkerTimer / entity.hitmarker.def.hitmarkerTime;
			entity.hitmarker.def.hitmarker.material.opacity = opacity;
		} else {
			entity.hitmarker.def.hitmarker.visible = false;
		}
	}

	if (entity.nameplate && entity.base) {
		entity.nameplate.def.nameplate.position.y =
			entity.base.def.geometryHeight + entity.base.def.nameplateOffsetY;
	}
}

function canUpdateSystem(entity) {
	return netState.isClient && entity.type.def.isItem;
}
