import { z } from "zod";
import { ACCEPTED_IMAGE_TYPES, MAX_THUMBNAIL_FILE_SIZE } from "../constants/files";
import { formatBytes } from "../helpers/files";
import { assetKeySchema } from "./general";
import { AssetType } from "../types";

const SizeSchema = z
	.number()
	.min(0)
	.max(MAX_THUMBNAIL_FILE_SIZE, {
		message: `Max thumbnail size is ${formatBytes(MAX_THUMBNAIL_FILE_SIZE)}.`,
	});

const FormatSchema = z.enum(ACCEPTED_IMAGE_TYPES, {
	message: `Only ${ACCEPTED_IMAGE_TYPES.join(", ")} formats are supported.`,
});

export const thumbnailFile = (value: File) => {
	return {
		size: SizeSchema.parse(value.size),
		type: FormatSchema.parse(value.type),
	} as const;
};

export const ThumbnailSchema = z.object({
	pk: assetKeySchema(AssetType.THUMBNAIL),
	type: z.literal(AssetType.THUMBNAIL),
	resourcePk: assetKeySchema(AssetType.RESOURCE, "Thumbnail resource is required."),
});
